import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  useParams
} from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
// import {salesPriceWithUpgradeCalc} from "../../../../../../../utlis/productCalculations";
import { formatter, removeChars, formatUpperCase } from "../../../../../../../utlis/formatters";
import { getOrderLevelAddresses, updateAddressOptions } from "../../../../../../../utlis/documentHelpers";
import { useOrder } from "../../../../../../../redux/orders/order";
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { AZ5011GlobalAutofill, AZ5011AutofillProductDetails, preProcessPayload } from "../DocumentAutofill/AZ5011AF"

const AZ5011 = ({document_id, getDocuments, passedDocumentSlug, locations, location, setDocumentFocus}) => {
  const documentSlug = "az-5011";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  
  // App Logic & Data //
  let { id } = useParams();
  const order = useOrder();
  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);

  // Data Structure & Init //
  
  var initialDocumentStructure = {
    seller_name: undefined, 
    year: undefined, 
    make: undefined, 
    model: undefined, 
    vin: undefined, 
    adot_90_permit_number: undefined, 
    sales_price: undefined, 
    price_adjustment: undefined, 
    net_price: undefined, 
    taxes_paid: undefined, 
    state_issued: undefined, 
    expires_date: undefined, 
    drivers_license_no: undefined, 
    purchaser_name: undefined, 
    purchaser_address: undefined, 
    purchaser_insurance_company: undefined, 
    purchaser_policy_number: undefined, 
    purchaser_phone: undefined, 
    non_resident_entity_name: undefined, 
    non_resident_address: undefined, 
    non_resident_phone: undefined, 
    fein: undefined, 
    affirmation_state: undefined, 
    q_1_false_cb: undefined, 
    q_1_true_cb: undefined, 
    q_2_true_cb: undefined, 
    q_2_false_cb: undefined, 
    q_3_true_cb: undefined, 
    q_3_false_cb: undefined, 
    q_4_true_cb: undefined, 
    q_4_false_cb: undefined, 
    q_5_true_cb: undefined, 
    q_5_false_cb: undefined, 
    q_6_true_cb: undefined, 
    q_6_false_cb: undefined, 
    q_7_true_cb: undefined, 
    q_7_false_cb: undefined, 
    q_8_true_cb: undefined, 
    q_8_false_cb: undefined, 
    q_9_true_cb: undefined, 
    q_9_false_cb: undefined, 
    q_10_true_cb: undefined, 
    q_10_false_cb: undefined, 
    q_11_true_cb: undefined, 
    q_11_false_cb: undefined, 
    q_12_true_cb: undefined, 
    q_12_false_cb: undefined
  }

  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  // X1
  const removeFormatting = e =>{
    const {name,value}=e.target;
    setDocumentValues({...documentValues,[name]:removeChars(value)});
  }

  // X1 Reference Arrays - TODO: Possibly move these to back end as part of the template stucture
  const moneyFields = useMemo(()=>{return["sales_price","price_adjustment","net_price","taxes_paid",]},[]);
  const phoneFields = useMemo(()=>{return["purchaser_phone", "non_resident_phone"]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "model"]},[]);
  const checkboxes = useMemo(()=>{return[
    "",
  ]},[]);
  // const radios = [
  //   "q1_toggle", 
  //   "q2_toggle", 
  //   "q3_toggle", 
  //   "q4_toggle", 
  //   "q5_toggle", 
  //   "q6_toggle", 
  //   "q7_toggle", 
  //   "q8_toggle", 
  //   "q9_toggle", 
  //   "q10_toggle", 
  //   "q11_toggle", 
  //   "q12_toggle"
  // ];

  // X1- Current General On Change
  const handleDocumentValuesChange = e =>{
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  
  const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try{
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      }catch(err) {
      recordFrontEndError('AZ5011.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, documentLoaded, loadDocument ])
  const [assignedProduct, setAssignedProduct] = useState(undefined);
  // const [productVal, setproductVal] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  // const [tradeinVal, setTradeinVal] = useState(undefined);
  // const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  // const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);

  const [selectedAddress1, setSelectedAddress1] = useState(undefined);
  const [selectedAddress2, setSelectedAddress2] = useState(undefined);
  // const [selectedAddress3, setSelectedAddress3] = useState(undefined);
  // const [selectedAddress4, setSelectedAddress4] = useState(undefined);
  const [addressOptions, setAddressOptions] = useState(undefined);
  const [inputAddressVal, setinputAddressVal] = useState(undefined);

  const [locationVal, setLocationVal] = useState(undefined);
  const [selectedLocation, setselectedLocation] = useState(undefined);
  const [inputLocationVal, setinputLocationVal] = useState(undefined);
  const [locationOptions, setLocationOptions] = useState(undefined);
  const [locationToggleAutoFill, setLocationToggleAutoFill] = useState(false);

// Dropdowns //colorOptions

// if(order && order.products && order.products.length > 0 && productOptions === undefined){
//   let getProductOptions = [];
//   order.products.map(product => {
//     let str = `${product.stockNumber.toString().toUpperCase()}`;
//     let obj = {id: product.id, name: str}
//     getProductOptions.push(obj);
//     return product;
//   })
//   setproductOptions(getProductOptions);
// }

// if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
//   let getTradeinOptions = [];
//   order.tradeins.map(tradein => {
//     let str = `${tradein.year} ${tradein.make} ${tradein.model.toString().toUpperCase()}`;
//     let obj = {id: tradein.id, name: str}
//     getTradeinOptions.push(obj);
//     return tradein;
//   })
//   setTradeinOptions(getTradeinOptions);
// }

  if(locations && locations.length > 0 && locationOptions === undefined){
    let getLocationOptions = [];
    locations.map(location => {
      let str = `${location.description}`;
      let obj = {locationCode: location.locationCode, description: str}
      getLocationOptions.push(obj);
      return location;
    })
    setLocationOptions(getLocationOptions);
  }


 // Autofill //

const GlobalAutofill = useCallback(async () => {

  let newValues = AZ5011GlobalAutofill(documentValues, order);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  // setAddressOptions(getOrderLevelAddresses(order));

  // var newValues = documentValues;
  
  // if(order && order.customer && order.customer.name){
  //     newValues.printed = order.customer.name;
  // }

  // if(order && order.customer){
  //   if(order.customer.type.toLowerCase() === "entity"){
  //     newValues.non_resident_entity_name = order.customer.name;
  //     newValues.non_resident_address = order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
  //     newValues.non_resident_phone = formatPhoneNumber(order.customer.phone);
  //   }else{
  //     newValues.purchaser_name = order.customer.name;
  //     newValues.purchaser_address = order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
  //     newValues.purchaser_phone = formatPhoneNumber(order.customer.phone);
  //   }
  // }

  // if(order && order.documentationDate && documentValues.delivery_date === undefined){
  //   newValues.delivery_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  // }

  // newValues.q4_toggle = "false";
  // newValues.q5_toggle = "false";
  // newValues.q6_toggle = "false";
  // newValues.q7_toggle = "false";
  // newValues.q8_toggle = "false";
  // newValues.q9_toggle = "false";
  // newValues.q10_toggle = "false";
  // newValues.q11_toggle = "false";
  // newValues.q12_toggle = "false";

  // setDocumentValues(newValues);
  if(location && locations && locations.length > 0 && locationOptions !== undefined){
    setLocationVal(locations.find((loc) => loc.locationCode === location.locationCode));
    setselectedLocation(locations.find((loc) => loc.locationCode === location.locationCode));
    setLocationToggleAutoFill(true);
  }
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ documentValues, order, location, locationOptions, locations ])

// const preProcessPayload = useCallback((payload) => {



//   if(payload.q1_toggle){
//     // Reset all
//     payload.q_1_true_cb = "no";
//     payload.q_1_false_cb = "no";

//     switch(payload.q1_toggle){
//       case "true":
//         payload.q_1_true_cb = "yes";
//         break;
//       case "false":
//         payload.q_1_false_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.q2_toggle){
//     // Reset all
//     payload.q_2_true_cb = "no";
//     payload.q_2_false_cb = "no";

//     switch(payload.q2_toggle){
//       case "true":
//         payload.q_2_true_cb = "yes";
//         break;
//       case "false":
//         payload.q_2_false_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.q3_toggle){
//     // Reset all
//     payload.q_3_true_cb = "no";
//     payload.q_3_false_cb = "no";

//     switch(payload.q3_toggle){
//       case "true":
//         payload.q_3_true_cb = "yes";
//         break;
//       case "false":
//         payload.q_3_false_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.q4_toggle){
//     // Reset all
//     payload.q_4_true_cb = "no";
//     payload.q_4_false_cb = "no";

//     switch(payload.q4_toggle){
//       case "true":
//         payload.q_4_true_cb = "yes";
//         break;
//       case "false":
//         payload.q_4_false_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.q5_toggle){
//     // Reset all
//     payload.q_5_true_cb = "no";
//     payload.q_5_false_cb = "no";

//     switch(payload.q5_toggle){
//       case "true":
//         payload.q_5_true_cb = "yes";
//         break;
//       case "false":
//         payload.q_5_false_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.q6_toggle){
//     // Reset all
//     payload.q_6_true_cb = "no";
//     payload.q_6_false_cb = "no";

//     switch(payload.q6_toggle){
//       case "true":
//         payload.q_6_true_cb = "yes";
//         break;
//       case "false":
//         payload.q_6_false_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.q7_toggle){
//     // Reset all
//     payload.q_7_true_cb = "no";
//     payload.q_7_false_cb = "no";

//     switch(payload.q7_toggle){
//       case "true":
//         payload.q_7_true_cb = "yes";
//         break;
//       case "false":
//         payload.q_7_false_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.q8_toggle){
//     // Reset all
//     payload.q_8_true_cb = "no";
//     payload.q_8_false_cb = "no";

//     switch(payload.q8_toggle){
//       case "true":
//         payload.q_8_true_cb = "yes";
//         break;
//       case "false":
//         payload.q_8_false_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.q9_toggle){
//     // Reset all
//     payload.q_9_true_cb = "no";
//     payload.q_9_false_cb = "no";

//     switch(payload.q9_toggle){
//       case "true":
//         payload.q_9_true_cb = "yes";
//         break;
//       case "false":
//         payload.q_9_false_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.q10_toggle){
//     // Reset all
//     payload.q_10_true_cb = "no";
//     payload.q_10_false_cb = "no";

//     switch(payload.q10_toggle){
//       case "true":
//         payload.q_10_true_cb = "yes";
//         break;
//       case "false":
//         payload.q_10_false_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.q11_toggle){
//     // Reset all
//     payload.q_11_true_cb = "no";
//     payload.q_11_false_cb = "no";

//     switch(payload.q11_toggle){
//       case "true":
//         payload.q_11_true_cb = "yes";
//         break;
//       case "false":
//         payload.q_11_false_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.q12_toggle){
//     // Reset all
//     payload.q_12_true_cb = "no";
//     payload.q_12_false_cb = "no";

//     switch(payload.q12_toggle){
//       case "true":
//         payload.q_12_true_cb = "yes";
//         break;
//       case "false":
//         payload.q_12_false_cb = "yes";
//         break;
//       default:
//     }
//   }




//   // X1.2- Field Type Specific Preview Formatting
//   Object.keys(payload).forEach(key => {
//     if(moneyFields.indexOf(key) >= 0){
//         payload[key] = formatMoneyValue(payload[key]);
//     }else if(phoneFields.indexOf(key) >= 0){
//         payload[key] = formatPhoneNumber(payload[key]);
//     }else if(upperCaseFields.indexOf(key) >= 0){
//         payload[key] = formatUpperCase(payload[key]);
//     }
//   });
//   setDocumentValues(JSON.parse(JSON.stringify(payload)));
//   Object.keys(payload).forEach(key => {
//     if(checkboxes.indexOf(key) >= 0){
//       payload[key] = payload[key] ? "yes": "no";
//     }
//   });

//   // Data point formatting
//   if(payload.expires_date){
//     payload.expires_date = moment.utc(payload.expires_date).format("MM/DD/YY");
//   }

//   if(payload.delivery_date){
//     payload.delivery_date = moment.utc(payload.delivery_date).format("MM/DD/YY");
//   }

//   return payload;
// },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])

const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });
    
    if(document){
      var docCopy = JSON.parse(JSON.stringify(document));
      docCopy.payload =  JSON.parse(JSON.stringify(payload));
      docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
      docCopy.payload =  JSON.stringify(docCopy.payload);
      delete docCopy.documentStatus;
    try {
      let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
      const file = new Blob(
        [response.data], 
        {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      setdocumentUrl(fileURL)
      } catch (err) {
        recordFrontEndError('AZ5011;jsx', 'Error previewing document payload: ' + err)
      };  
    }
},[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


const AutofillProductDetails = useCallback(async (selectedProduct) => {

  let newValues = AZ5011AutofillProductDetails(selectedProduct, documentValues);

  if(newValues){
    setDocumentValues(newValues);
    previewPayloadDocument(documentValues);
    setHasUnsavedChanges(true);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setSaveSuccess(false);
  }

  // var newValues = documentValues;
  // newValues.vin = (selectedProduct.vin).toString().toUpperCase();
  // newValues.year = (selectedProduct.year).trim();
  // newValues.make = (selectedProduct.make).trim();
  // newValues.model = (selectedProduct.model).toString().toUpperCase();

  // newValues.body_type = selectedProduct.vehicleType;

  // newValues.state_issued = selectedProduct.registrationAddress?.state;

  // newValues.sales_price = salesPriceWithUpgradeCalc(selectedProduct);
  // if(selectedProduct.lineItemFee && selectedProduct.lineItemFee.applyFet && selectedProduct.lineItemFee.applyFet === true && selectedProduct.lineItemFee.fetTotal && selectedProduct.lineItemFee.fetTotal > 0){
  //   newValues.sales_price += Number(selectedProduct.lineItemFee.fetTotal);
  // }
  
  // setDocumentValues(newValues);
  // // setProductToggleAutoFill(false);
  // if(document){
  //   previewPayloadDocument(documentValues);
  // }
  // // setAddressOptions(updateAddressOptions(selectedProduct, order));
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ document, documentValues, previewPayloadDocument ]);

  // Document BREAD + Preview // TODO: Move to Redux

  useEffect(() => {
    if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){
  
      existingPayload = true;

      if(documentPayload.expires_date){
        documentPayload.expires_date = moment.utc(documentPayload.expires_date).format("MM/DD/YY");
      }
  
      if(documentPayload.delivery_date){
        documentPayload.delivery_date = moment.utc(documentPayload.delivery_date).format("YYYY-MM-DD");
      }

      // Loading Use Effect
      if(document.additionalData){
        const methodMap = { 
          selectedAddress1: setSelectedAddress1, 
          selectedAddress2: setSelectedAddress2, 
          selectedLocation: setselectedLocation, 
        };

        let parsedAdditionalData = JSON.parse(document.additionalData);
        Object.keys(parsedAdditionalData).forEach(function(key) {
          if(methodMap[key]){
            if(key === "selectedLocation"){
              setLocationVal(parsedAdditionalData[key]);
              if(parsedAdditionalData[key].id){
                setselectedLocation(locations.find((location) => location.id === parsedAdditionalData[key].id));
              }
            }else{
              methodMap[key](parsedAdditionalData[key]);
            }
          }
        });
      }
            
  
      setDocumentValues(documentPayload);
    }

    var foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }

    setAddressOptions(getOrderLevelAddresses(order));
    if(foundAssignedProduct){
      setAssignedProduct(foundAssignedProduct);
      setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
    }

  
      
      if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
      }else{
        // if(document && document.itemIds){
        //   var itemIds = document.itemIds.split(',');
        //     var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
        //     let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
        //     let obj = {id: foundProduct.id, name: str}
        //     setproductVal(obj);
        //     if(foundProduct){
        //       setAddressOptions(updateAddressOptions(foundProduct, order));
        //     }
        //     streamDocument();
        // }
  
      }
      
      setDataLoaded(true);
    }
  },[ locations, documentValues, document, dataLoaded, AutofillProductDetails, GlobalAutofill, order, previewPayloadDocument ])

    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
    const handleSubmit = async event => {
      event.preventDefault();

      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
  
      //Save Dropdown Selections
      let dropdownData = {};
      if(selectedAddress1){
        dropdownData.selectedAddress1 = {
          id: selectedAddress1.id,
          name: selectedAddress1.name
        };
      }
      if(selectedAddress2){
        dropdownData.selectedAddress2 = {
          id: selectedAddress2.id,
          name: selectedAddress2.name
        };
      }
      if(selectedLocation){
        dropdownData.selectedLocation = {
          locationCode: selectedLocation.locationCode,
          description: selectedLocation.description
        };
      }
      let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, additionalData, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });

//       try{
//       let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
//       if (res) {
//         await axios.post(`/document/${document.id}/generate`)
//           getDocuments();
//           setSaveSuccess(true);
//           setHasUnsavedChanges(false);
//         };
//       } catch (err) {
// recordFrontEndError('AZ5011', 'Error saving document: ' + err)
//       };
      
    }

// Component Helpers //
// useEffect(() => {
  //Autofill Product Details After Selection

// },[ selectedProduct, productToggleAutoFill ])

// useEffect(() => {
//   //Autofill Tradein Details After Selection
//   if(selectedTradein !== undefined && tradeinToggleAutoFill){
//     var newValues = documentValues;
//     newValues.trade_year = (selectedTradein.year);
//     newValues.trade_make = (selectedTradein.make);
//     newValues.trade_model = (selectedTradein.model);
//     newValues.trade_body_type = (selectedTradein.seriesBodyType);
//     newValues.trade_serial_number = (selectedTradein.serialNumber);
//     newValues.balance_owner = (order.customer.name); //Need specific field for TRADE IN OWNER?
//     newValues.balance_owner_address = (order.customer.deliveryAddress.addressLine1);
//     newValues.amount = (selectedTradein.balanceOwed);
//     newValues.trade_allowance = (selectedTradein.tradeAllowanceAtTerms);
//     newValues.balance_owed = (selectedTradein.balanceOwed);
//     newValues.cash_refund = (selectedTradein.cashRefundToCustomer);
//     newValues.net_trade_allowance = (selectedTradein.finalTradeAllowance);
//     // newValues.cash_down_payment = (selectedTradein);

//     setDocumentValues(newValues);
//     setTradeinToggleAutoFill(false);
//     if(document){
//       previewPayloadDocument(documentValues);
//     }
//   }
// },[ selectedTradein, tradeinToggleAutoFill ])

useEffect(() => {
  //Autofill Tradein Details After Selection
  if(selectedLocation !== undefined && locationToggleAutoFill){
    var newValues = documentValues;
    // newValues.seller_address = selectedLocation.address+" "+selectedLocation.city+", "+selectedLocation.state+", "+selectedLocation.zip;
    // newValues.seller_city_state_zip = ;
    // newValues.seller_area_code = getAreaCode(selectedLocation.phone);
    // newValues.seller_phone = getPhoneNumberAlt(selectedLocation.phone);

    if(selectedLocation.state === "CA"){
      newValues.seller_name = "TEC OF CALIFORNIA, INC "+selectedLocation.address+" "+selectedLocation.city+", "+selectedLocation.state+", "+selectedLocation.zip;
    }else{
      newValues.seller_name = "TEC EQUIPMENT, INC. "+selectedLocation.address+" "+selectedLocation.city+", "+selectedLocation.state+", "+selectedLocation.zip;
    }

    setDocumentValues(newValues);
    setLocationToggleAutoFill(false);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
},[ selectedLocation, locationToggleAutoFill, document, documentValues, previewPayloadDocument ])

//Autofill Address Details After Selection
function AutoFillAddress(addressSelected, selectionInstance){
  var newValues = documentValues;
  if(selectionInstance){
    if(selectionInstance === "purchaser-address"){
      newValues.purchaser_name = addressSelected.label;
      newValues.purchaser_address = addressSelected.address+" "+addressSelected.city+", "+addressSelected.state+" "+addressSelected.zip;
    }
    if(selectionInstance === "entity-address"){
      newValues.non_resident_entity_name = addressSelected.label;
      newValues.non_resident_address = addressSelected.address+" "+addressSelected.city+", "+addressSelected.state+" "+addressSelected.zip;
      // newValues.non_resident_phone = addressSelected.phone;
    }

  }
  setDocumentValues(newValues);
  if(document){
    previewPayloadDocument(documentValues);
  }
  setHasUnsavedChanges(true);
  setSaveSuccess(false);
}

const onCloseClick = e => {
  e.preventDefault()
  setDataLoaded(false);
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setAssignedProduct(undefined);
  setdocumentUrl("");
  setDocument(undefined);
  setDocumentFocus(undefined);
  setSelectedAddress1(undefined);
  setSelectedAddress2(undefined);
  setAddressOptions(undefined);
  setinputAddressVal(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
  setLocationVal(undefined);
  setselectedLocation(undefined);
  setinputLocationVal(undefined);
  setLocationOptions(undefined);
  setLocationToggleAutoFill(false);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

  return (
    <>
      <div
        className="modal fade"
        // id="wa-title-app"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                AZ - 5011 - CERTIFICATE TO ESTABLISH RESIDENCY IN ANOTHER
              </h5>
              <br />
              { hasClickedClose ? (
                <div className="d-flex">
                    <p className="m-1 pt-1 text-white">Save Changes?</p>
                    <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                    <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}
                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ fontSize: '12px', overflowY: 'scroll', height: 'inherit'}}>
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill();
                      AutofillProductDetails(assignedProduct);
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>
                  <br />
                  { assignedProduct ? (
                      <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                          Product Snapshot
                          <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                          <h5>SN: { assignedProduct.stockNumber }</h5>
                          <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                          <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                          </div>
                      </div>
                  ):(
                      <div className="m-2 p-2 border">
                          <span>No Assigned Product</span>
                      </div>
                  )}

                  <div className="row">

                  { locationOptions && locationOptions.length > 0 ? (
                    <div className="d-flex justify-content-evenly">
                      <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                          style={{ width: '100%'}}
                          value={ (locationVal && locationVal.description) ?? null}
                          onChange={(event, newValue) => {
                              setLocationVal(newValue);
                              if(newValue && newValue.locationCode){
                                setselectedLocation(locations.find((location) => location.locationCode === newValue.locationCode));
                                setLocationToggleAutoFill(true);
                              }
                              // resetVariables();
                          }}
                          inputValue={inputLocationVal}
                          onInputChange={(event, newInputValue) => {
                              setinputLocationVal(newInputValue);
                          }}
                          //id="controllable-states-demo"
                          options={locationOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="SELLING LOCATION" />}
                          renderOption={(props, option) => {
                              return (
                              <li {...props} key={option.locationCode}>
                                  {option.description}
                              </li>
                              );
                          }}
                          />
                      </div>
                    </div>
                    ):(
                      <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Locations Loaded</h5>
                    </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Sellers Name and Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_name ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Model</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="model"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.model ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-8">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">90 Day Permit Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="adot_90_permit_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.adot_90_permit_number ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Sales Price</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="sales_price"
                          onFocus={removeFormatting}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.sales_price ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Price Adjustments</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="price_adjustment"
                          onFocus={removeFormatting}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.price_adjustment ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">NET Price</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="net_price"
                          onFocus={removeFormatting}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.net_price ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Total Taxes Paid</label>
                        <input
                          type="text"
                          onFocus={removeFormatting}
                          className="form-control rounded-pill mt-1"
                          name="taxes_paid"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.taxes_paid ?? '' }
                          />
                      </div>
                    </div>
                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Drivers License No.</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="drivers_license_no"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.drivers_license_no ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State of Issuance</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="state_issued"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.state_issued ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Expires</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="expires_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.expires_date ?? '' }
                          />
                      </div>
                    </div>

                    
                    { addressOptions && addressOptions.length > 0 ? (
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress1 && selectedAddress1.name) ?? null}
                          name="delivery-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress1(newValue);
                            AutoFillAddress(newValue, "purchaser-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Purchaser Address" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>
                    ):null}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Full Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_name ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Full Residential Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_address ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Telephone No. Of Residence Address</label>
                        <input
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          onFocus={removeFormatting}
                          className="form-control rounded-pill mt-1"
                          name="purchaser_phone"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_phone ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Auto Insurance Company</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_insurance_company"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_insurance_company ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Policy No.</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_policy_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_policy_number ?? '' }
                          />
                      </div>
                    </div>

                    { addressOptions && addressOptions.length > 0 ? (
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress2 && selectedAddress2.name) ?? null}
                          name="delivery-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress2(newValue);
                            AutoFillAddress(newValue, "entity-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Entity" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>
                    ):null}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Entity Name</label>
                        <input
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          className="form-control rounded-pill mt-1"
                          name="non_resident_entity_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.non_resident_entity_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">FEIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="fein"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.fein ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Full Business Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="non_resident_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.non_resident_address ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Telephone No.</label>
                        <input
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          onFocus={removeFormatting}
                          className="form-control rounded-pill mt-1"
                          name="non_resident_phone"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.non_resident_phone ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-9 mt-2">
                          <p style={{ fontSize: '1rem'}} htmlFor="stock">1. I am physically present in Arizona for at least seven (7) months of the year.</p>
                      </div>
                    <div className="p-2 col-md-3">
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                        <RadioGroup   
                          row                         
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="q1_toggle"
                          value={(documentValues.q1_toggle ?? "")}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                        >
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="true" control={<Radio />} label="True" />
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="false" control={<Radio />} label="False" />
                        </RadioGroup>
                      </FormControl>
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-9 mt-2">
                          <p style={{ fontSize: '1rem'}} htmlFor="stock">2. I am engaged in a trade, profession or occupation in Arizona in other than seasonal agricultural work or temporary seasonal work.</p>
                      </div>
                    <div className="p-2 col-md-3">
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                        <RadioGroup   
                          row                         
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="q2_toggle"
                          value={(documentValues.q2_toggle ?? "")}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                        >
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="true" control={<Radio />} label="True" />
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="false" control={<Radio />} label="False" />
                        </RadioGroup>
                      </FormControl>
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-9 mt-2">
                          <p style={{ fontSize: '1rem'}} htmlFor="stock">3. I am employed in the State of Arizona.</p>
                      </div>
                    <div className="p-2 col-md-3">
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                        <RadioGroup   
                          row                         
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="q3_toggle"
                          value={(documentValues.q3_toggle ?? "")}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                        >
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="true" control={<Radio />} label="True" />
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="false" control={<Radio />} label="False" />
                        </RadioGroup>
                      </FormControl>
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-9 mt-2">
                          <p style={{ fontSize: '1rem'}} htmlFor="stock">4. I have placed my children in an Arizona public school (K-12) without payment of nonresident tuition.</p>
                      </div>
                    <div className="p-2 col-md-3">
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                        <RadioGroup   
                          row                         
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="q4_toggle"
                          value={(documentValues.q4_toggle ?? "")}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                        >
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="true" control={<Radio />} label="True" />
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="false" control={<Radio />} label="False" />
                        </RadioGroup>
                      </FormControl>
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-9 mt-2">
                          <p style={{ fontSize: '1rem'}} htmlFor="stock">5. I attend an Arizona educational institution and pay resident status tuition rates.</p>
                      </div>
                    <div className="p-2 col-md-3">
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                        <RadioGroup   
                          row                         
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="q5_toggle"
                          value={(documentValues.q5_toggle ?? "")}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                        >
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="true" control={<Radio />} label="True" />
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="false" control={<Radio />} label="False" />
                        </RadioGroup>
                      </FormControl>
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-9 mt-2">
                          <p style={{ fontSize: '1rem'}} htmlFor="stock">6. I am an individual, partnership, company, ﬁrm, corporation or association that maintains a main ofﬁce, a branch ofﬁce or warehouse facilities in this state and that bases and operates motor vehicles in this state.</p>
                      </div>
                    <div className="p-2 col-md-3">
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                        <RadioGroup   
                          row                         
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="q6_toggle"
                          value={(documentValues.q6_toggle ?? "")}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                        >
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="true" control={<Radio />} label="True" />
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="false" control={<Radio />} label="False" />
                        </RadioGroup>
                      </FormControl>
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-9 mt-2">
                          <p style={{ fontSize: '1rem'}} htmlFor="stock">7. I am an individual, partnership, company, ﬁrm, corporation or association that operates motor vehicles in intrastate transportation, for other than seasonal agricultural work.</p>
                      </div>
                    <div className="p-2 col-md-3">
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                        <RadioGroup   
                          row                         
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="q7_toggle"
                          value={(documentValues.q7_toggle ?? "")}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                        >
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="true" control={<Radio />} label="True" />
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="false" control={<Radio />} label="False" />
                        </RadioGroup>
                      </FormControl>
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-9 mt-2">
                          <p style={{ fontSize: '1rem'}} htmlFor="stock">8. I am purchasing this vehicle for transfer to a resident of Arizona.</p>
                      </div>
                    <div className="p-2 col-md-3">
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                        <RadioGroup   
                          row                         
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="q8_toggle"
                          value={(documentValues.q8_toggle ?? "")}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                        >
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="true" control={<Radio />} label="True" />
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="false" control={<Radio />} label="False" />
                        </RadioGroup>
                      </FormControl>
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-9 mt-2">
                          <p style={{ fontSize: '1rem'}} htmlFor="stock">9. I have a valid driver’s license issued by the State of Arizona.</p>
                      </div>
                    <div className="p-2 col-md-3">
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                        <RadioGroup   
                          row                         
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="q9_toggle"
                          value={(documentValues.q9_toggle ?? "")}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                        >
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="true" control={<Radio />} label="True" />
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="false" control={<Radio />} label="False" />
                        </RadioGroup>
                      </FormControl>
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-9 mt-2">
                          <p style={{ fontSize: '1rem'}} htmlFor="stock">10. I am registered to vote in the State of Arizona.</p>
                      </div>
                    <div className="p-2 col-md-3">
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                        <RadioGroup   
                          row                         
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="q10_toggle"
                          value={(documentValues.q10_toggle ?? "")}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                        >
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="true" control={<Radio />} label="True" />
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="false" control={<Radio />} label="False" />
                        </RadioGroup>
                      </FormControl>
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-9 mt-2">
                          <p style={{ fontSize: '1rem'}} htmlFor="stock">11. I ﬁ le Resident status income tax returns, or business tax returns in the State of Arizona.</p>
                      </div>
                    <div className="p-2 col-md-3">
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                        <RadioGroup   
                          row                         
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="q11_toggle"
                          value={(documentValues.q11_toggle ?? "")}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                        >
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="true" control={<Radio />} label="True" />
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="false" control={<Radio />} label="False" />
                        </RadioGroup>
                      </FormControl>
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-9 mt-2">
                          <p style={{ fontSize: '1rem'}} htmlFor="stock">12. The insurance policy listed above (other than a temporary binder) is issued under the laws of Arizona.</p>
                      </div>
                    <div className="p-2 col-md-3">
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                        <RadioGroup   
                          row                         
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="q12_toggle"
                          value={(documentValues.q12_toggle ?? "")}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                        >
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="true" control={<Radio />} label="True" />
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="false" control={<Radio />} label="False" />
                        </RadioGroup>
                      </FormControl>
                      </div>
                    </div>
                    <hr></hr>



                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">I will register this vehicle in the state of...</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="affirmation_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.affirmation_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                      </div>
                    </div>
                

<hr/>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Date of Signature</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="delivery_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.delivery_date ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6"></div>
                    </div>

                  </div>
                <hr></hr>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default AZ5011
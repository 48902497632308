import moment from 'moment'

export const TECSoldAsIsGlobalAutofill  = (documentValues, order, property=null, value=null, location) => {
    var newValues = documentValues;

    if(property ==='customer.name'){
      newValues.purchaser_name = value;
      if(order.customer.dba){
        newValues.purchaser_name += " dba "+order.customer.dba;
      }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          newValues.purchaser_name += " AND "+cosigner.name;
        }
      }
  }
    if(property ==='customer.dba'){
      newValues.purchaser_name = order.customer.name+ " dba "+value;
  }
    if(property ==='customer.cosigners'){
      newValues.purchaser_name = order.customer.name;
      if(value && value.length > 0){
        let cosigner = value[0];
        if(cosigner && cosigner.name){
          newValues.purchaser_name += " AND "+cosigner.name;
        }
      }
  }
    if(property ==='customer.deliveryAddress.addressLine1'){
      newValues.address = value
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        newValues.address +=" "+order.customer.deliveryAddress.addressLine2;
      }
  }
    if(property ==='customer.deliveryAddress.addressLine2'){
      newValues.address = order.customer.deliveryAddress.addressLine1+" "+value;
  }
    if(property ==='customer.deliveryAddress.city'){
      newValues.city_state_zip = (value+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
      newValues.city = (value);
  }
    if(property ==='customer.deliveryAddress.state'){
      newValues.city_state_zip = (order.customer.deliveryAddress.city+", "+value+" "+order.customer.deliveryAddress.zip);
      newValues.state = (value);
  }
    if(property ==='customer.deliveryAddress.zip'){
      newValues.city_state_zip = (order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+value);
      newValues.zip = (value);
  }
    if(property ==='documentationDate'){
      newValues.delivery_date = moment.utc(value).format("YYYY-MM-DD");
      newValues.delivery_date_2 = moment.utc(value).format("YYYY-MM-DD");
  }

    if(property !== null && value !== null){
      return newValues;
  }

      if(order && order.customer && order.customer.name){
        newValues.purchaser_name = order.customer.name;
  
        
        if(order.customer.dba){
          newValues.purchaser_name += " dba "+order.customer.dba;
        }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner && cosigner.name){
            newValues.purchaser_name += " AND "+cosigner.name;
          }
        }
      }
  
      if(order && order.customer && order.customer.deliveryAddress){
        newValues.address = (order.customer.deliveryAddress.addressLine1+" "+(order.customer.deliveryAddress.addressLine2 ?? ''));
      }
  
      if(order && order.customer && order.customer.deliveryAddress){
        newValues.city_state_zip = (order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
      }
  
      if(order && order.customer && order.customer.deliveryAddress){
        newValues.city = (order.customer.deliveryAddress.city);
      }
  
      if(order && order.customer && order.customer.deliveryAddress){
        newValues.state = (order.customer.deliveryAddress.state);
      }
  
      if(order && order.customer && order.customer.deliveryAddress){
        newValues.zip = (order.customer.deliveryAddress.zip);
      }
  
      if(location !== undefined){
        newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
      }
    
      if(location && location.phone){
        newValues.location_phone = location.phone;
      }
      
      if(location && location.email){
        newValues.location_email = location.email;
      }
    
      if(location){
        if(location.state === "CA"){
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
        }else{
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
        }
      }
  
      if(order && order.documentationDate && documentValues.delivery_date === undefined){
        newValues.delivery_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
      }
  
      if(order && order.documentationDate && documentValues.delivery_date_2 === undefined){
        newValues.delivery_date_2 = moment.utc(order.documentationDate).format("YYYY-MM-DD");
      }
  
      return newValues
}

export const TECSoldAsIsAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
    var newValues = documentValues;

    if(property === 'year' && value !== null){
      newValues.year = (value).toString().trim().replace(/['"]+/g, '');
  }

  if(property === 'make' && value !== null){
      newValues.make = (value).toString().trim();
  }

  if(property === 'model' && value !== null){
      newValues.model = (value).toString().trim();
  }

  if(property === 'mileage' && value !== null){
      newValues.mileage = (value);
  }


  if(property !== null && value !== null){
      return newValues;
  }

    newValues.year = (selectedProduct.year).trim().replace('"', '').replace('"', '');
    newValues.make = (selectedProduct.make).trim().toString().toUpperCase();
    newValues.model = (selectedProduct.model).trim().toString().toUpperCase();
    newValues.stock_number = (selectedProduct.stockNumber);
    newValues.mileage = (selectedProduct.mileage);
    newValues.serial_number = (selectedProduct.serialNumber);
    newValues.vin = (selectedProduct.vin); 
    return newValues
}
import React, { useState, useEffect, useCallback } from "react";
import {
  useParams
} from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import { formatter } from "../../../../../../../utlis/formatters";
import { useOrder } from "../../../../../../../redux/orders/order"
import {  Checkbox, FormGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { TECPowerOfAttorneyGlobalAutofill, TECPowerOfAttorneyAutofillProductDetails, TECPowerOfAttorneyAutofillTradeDetails } from "../DocumentAutofill/TECPowerOfAttorney"

const TECPowerOfAttorney = ({document_id, location, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "tec-poa";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();

  const order = useOrder();

  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);
  
  var initialDocumentStructure = {
    location_address: undefined, // Header Location Details / System Autofill
    location_phone: undefined, // Header Location Details / System Autofill
    location_email: undefined, // Header Location Details / System Autofill
    tec_footer_label: undefined, // Footer / System Autofill
    pg_no: undefined, // Footer / System Autofill

    make: undefined,
    vehicle_type: undefined, // Model?
    id: undefined, // IS VIN - Update Template field handle

    attorney_name: undefined,
    name: undefined,
    representative_name: undefined,
    state_of: undefined,
    county_of: undefined
  }

  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  const handleDocumentValuesChange = e =>{
    e.preventDefault();
    const {name,value}=e.target;
    if(name === 'id') {
      let newStr = value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
      newStr = newStr.length > 17 ? newStr.slice(0, -1) : newStr;
      setDocumentValues({...documentValues,[name]:newStr});
    }else if(name === 'toggle_notary'){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
      updateDocumentId(e.target.checked);
    } else {
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  const getDocumentTemplateBySlug = useCallback((slug) => {
    return axios.get(`/document-template/${slug}/by-slug`);
  },[ axios ])

  const previewPayloadDocument = useCallback(async (documentValues) => {
    // updateDocumentId(documentValues.toggle_notary);

    var payload = documentValues;
    if(payload){
      Object.keys(payload).forEach(key => {
        if (payload[key] === null || payload[key] === undefined) {
          delete payload[key];
        }
      });
      if(document){
        if(document.documentTemplate.slug === "tec-poa" 
        || document.documentTemplate.slug === 'tec-poa-no-notary'
        ){
          
          var docCopy = JSON.parse(JSON.stringify(document));
          docCopy.payload =  JSON.stringify(payload);
          delete docCopy.documentStatus;
          
          try {
            let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
            const file = new Blob(
              [response.data], 
              {type: 'application/pdf'});
              const fileURL = URL.createObjectURL(file);
              // window.open(fileURL);
              setdocumentUrl(fileURL)
            } catch (err) {
              recordFrontEndError('TECPowerOfAttorney.jsx', 'Error previewing document payload: ' + err)
            };  
          }
      }
    }
  },[ axios, document ])

  const updateDocumentId = useCallback((notary_toggle=true) => {


    let slug = 'tec-poa';
    if(notary_toggle === false){
      slug = 'tec-poa-no-notary';
    }

    if(document && document.documentTemplate && document.documentTemplate.slug && document.documentTemplate.slug !== slug){
      return getDocumentTemplateBySlug(slug).then(async response => {
        let template = response?.data;
        if(template && template.id){
          if(document && document.id && template.id){
            try {
              await axios.put(`/document/${document.id}/update-document-template/${template.id}`)
              let updatedObject = document;
              updatedObject.documentTemplateId = template.id;
              updatedObject.documentTemplate = template;
              setDocument(updatedObject);
              previewPayloadDocument();
            } catch (err) {
              recordFrontEndError('TECPowerOfAttorney.jsx', 'Error updating document id: ' + err)
            };
          }
        }
      });
    }
  },[ axios, document, getDocumentTemplateBySlug, previewPayloadDocument ])
      
const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('TECPowerOfAttorney.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])

  const [previewLoaded, setPreviewLoaded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug){
      if(
        passedDocumentSlug === "tec-poa" ||
        passedDocumentSlug === "tec-poa-no-notary"){
          setdocumentUrl("");
          setPreviousDocumentId(document_id);
          // setDocumentLoaded(true);
          loadDocument(document_id);
      }
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, loadDocument ])

  const [assignedProduct, setAssignedProduct] = useState(undefined);
  const [assignedTrade, setAssignedTrade] = useState(undefined);

  // const [productVal, setproductVal] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  // const [tradeinVal, setTradeinVal] = useState(undefined);
  // const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  // const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);

// Dropdowns //

  // if(order && order.products && order.products.length > 0 && productOptions === undefined){
  //   let getProductOptions = [];
  //   order.products.map(product => {
  //     // let str = `${product.year.replace('"', '').replace('"', '')} ${product.make} ${product.model.toString().toUpperCase()} - ${product.stockNumber.toString().toUpperCase()}`;
  //     let str = `${product.stockNumber.toString().toUpperCase()}`;
  //     let obj = {id: product.id, name: str}
  //     getProductOptions.push(obj);
  //   })
  //   setproductOptions(getProductOptions);
  // }

  // if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
  //   let getTradeinOptions = [];
  //   order.tradeins.map(tradein => {
  //     let str = `${tradein.year} ${tradein.make} ${tradein.model}`;
  //     let obj = {id: tradein.id, name: str}
  //     getTradeinOptions.push(obj);
  //   })
  //   setTradeinOptions(getTradeinOptions);
  // }

 // Autofill //

  // if(globalAutofill){
    const GlobalAutofill = useCallback(async () => {
      let newValues = TECPowerOfAttorneyGlobalAutofill(documentValues, order, null, null, location);
      if(newValues){
        setDocumentValues(newValues);
        setHasUnsavedChanges(true);
        setSaveSuccess(false);
      }
    

    //   var newValues = documentValues;

    // if(order){
    //   if(order.customer && order.customer.type.toString().toLowerCase() === 'entity'){
    //     var finalName = order.customer.name.toUpperCase();
    //     // if(order.customer.dba){
    //     //   finalName = finalName + " dba " + order.customer.dba.toUpperCase()
    //     // }

    //     newValues.representative_name = finalName;
    //   }else{
    //     newValues.name = order.customer.name.toUpperCase();
    //     if(order.customer.dba){
    //       newValues.representative_name = order?.customer?.dba?.toUpperCase();
    //     } 
    //     if(order.customer.cosigners && order.customer.cosigners.length > 0){
    //       let cosigner = order.customer.cosigners[0];
    //       if(cosigner && cosigner.name){
    //         newValues.name += " AND "+cosigner.name.toUpperCase();
    //       }
    //     }
    //   }
    // }

      if(location){
        if(location.state === "CA"){
          newValues.attorney_name = "TEC OF CALIFORNIA, INC.";
        }else{
          newValues.attorney_name = "TEC EQUIPMENT, INC.";
        }
      }
      
      // Get Location Address & Autofill Address
      if(location !== undefined){
        newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
      }
    
      if(location && location.phone){
        newValues.location_phone = location.phone;
      }
      
      if(location && location.email){
        newValues.location_email = location.email;
      }
    
      if(location){
        if(location.state === "CA"){
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
        }else{
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
        }
      }

    // if(document && document.documentTemplate && document.documentTemplate.slug){
    //   if(document.documentTemplate.slug === "tec-poa"){
    //     newValues.toggle_notary = true;
    //   }else{
    //     newValues.toggle_notary = false;
    //   }
    // }

    // newValues.pg_no = "PGg#1";

    setDocumentValues(newValues);
    // setGlobalAutofill(false);
    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  },[documentValues, order, location])

  const AutofillProductDetails = useCallback(async (selectedProduct, autofillProductDetails=true) => {
    let newValues = TECPowerOfAttorneyAutofillProductDetails(selectedProduct, documentValues, null, null, autofillProductDetails);

    if(newValues){
      setDocumentValues(newValues);
      setHasUnsavedChanges(true);
      setSaveSuccess(false);
    }
    
    // var newValues = documentValues;
    // newValues.make = (selectedProduct.year.replace('"', '').replace('"', '')).trim()+" "+(selectedProduct.make).trim().toUpperCase();
    // if(selectedProduct.vehicleType){
    //   newValues.vehicle_type = (selectedProduct.vehicleType).trim().toUpperCase();
    // }

    // if(autofillProductDetails){
    //   if(selectedProduct.vin){
    //     newValues.vin = (selectedProduct.vin).trim().toUpperCase();
    //   }  
    // }
    // setDocumentValues(newValues);

    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  },[ documentValues ]);

  const AutofillTradeDetails = useCallback(async (selectedTradein) => {
    let newValues = TECPowerOfAttorneyAutofillTradeDetails(selectedTradein, documentValues);

    if(newValues){
      setDocumentValues(newValues);
      setHasUnsavedChanges(true);
      setSaveSuccess(false);
    }

    // var newValues = documentValues;
    // newValues.make = (selectedTradein.year.replace('"', '').replace('"', '')).trim()+" "+(selectedTradein.make).trim().toUpperCase();
    // if(selectedTradein.vin){
    //   newValues.vin = (selectedTradein.vin).trim().toUpperCase();
    // }  
    // setDocumentValues(newValues);

    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  },[ documentValues ]);

  const previewDocument = useCallback( async () => {
    try{
      let response = await axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
        setdocumentUrl(fileURL)
      } catch (err) {
        recordFrontEndError('TECPowerOfAttorney.jsx', 'Error previewing document: ' + err)
      }
  },[axios, document]);

  useEffect(() => {

    if(document && document.payload && !dataLoaded){
      var existingPayload = false;
      var documentPayload = JSON.parse(document.payload);
      if(documentPayload && Object.keys(documentPayload).length > 0){
        existingPayload = true;
        setDocumentValues(documentPayload);
      }

      // if(document.assignedObjectType && document.assignedObjectId){
      //   setVehicleTypeSelection(document.assignedObjectType)
      // }

      var foundAssignedOrder = null;
      if(document.assignedObjectType && document.assignedObjectType === 'order'){
        foundAssignedOrder = true;
      }

      var foundAssignedProduct = null;
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
        foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
      }

      if(foundAssignedProduct){
        setAssignedProduct(foundAssignedProduct);
      }

      var foundAssignedTradein = null;
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'tradein'){
        foundAssignedTradein = order.tradeins.find((trade) => trade.id === document.assignedObjectId);
      }

      if(foundAssignedTradein){
        setAssignedTrade(foundAssignedTradein);
      }


      // setAddressOptions(getOrderLevelAddresses(order));
      if(!existingPayload){
        GlobalAutofill();
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
        if(foundAssignedTradein){
          AutofillTradeDetails(foundAssignedTradein)
        }
        if(foundAssignedOrder){
          // Autofill "See Schedule A"
          var copyDocumentValues = documentValues;
          copyDocumentValues.vin = "See Schedule A";
          setDocumentValues(copyDocumentValues);
          setHasUnsavedChanges(true);
          setSaveSuccess(false);

          //Get First Product
          if(order && order.products && order.products.length > 0){
            let firstProduct = order.products[0];
            AutofillProductDetails(firstProduct, false);
          }
        }        
      }else{
        // if(document && document.itemIds){
        //   var itemIds = document.itemIds.split(',');
        //     var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
        //     let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
        //     let obj = {id: foundProduct.id, name: str}
        //     setproductVal(obj);
        //     // if(foundProduct){
        //     //   setAddressOptions(updateAddressOptions(foundProduct, order));
        //     // }
        // }

        previewDocument();
      }
      
      setDataLoaded(true);
    }else if(document && document.payload && dataLoaded && !previewLoaded){
      setPreviewLoaded(true);
      previewPayloadDocument(documentValues);
    }
  },[ documentValues, document, dataLoaded, AutofillProductDetails, AutofillTradeDetails, previewDocument, GlobalAutofill, order, previewPayloadDocument, previewLoaded ])

  // Document BREAD + Preview // TODO: Move to Redux


  // Component Events

  const updatePreview = e => {
    e.preventDefault();
    previewPayloadDocument(documentValues);
    // saveDocument().then(r => { 
    //   regenerateDocument().then(res => {
    //     streamDocument()
    //   })
    // })
  }


  const handleSubmit = async event => {
    event.preventDefault();

    let payload = JSON.parse(JSON.stringify(documentValues));
    // var docCopy = document;
    // docCopy.payload = payloadString;
    // delete docCopy.documentStatus;
    DocumentHandleSubmit(document, payload, null, () => {
      getDocuments();
      setSaveSuccess(true);
      setHasUnsavedChanges(false);
    });
    // if(selectedProduct && selectedProduct.id){
    //   docCopy.itemIds = `${selectedProduct.id}`;
    // }
    // try{
    //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
    //   if (res) {
    //     await axios.post(`/document/${document.id}/generate`) 
    //     getDocuments();
    //     setSaveSuccess(true);
    //     setHasUnsavedChanges(false);
    //   };
    // } catch (err) {
    //   recordFrontEndError('TECPowerOfAttorney.jsx', 'Error saving document: ' + err)
    // };
    
  };

// Component Helpers //

  //Autofill Product Details After Selection


  //Autofill Product Details After Selection


const onCloseClick = e => {
  e.preventDefault()
  setPreviewLoaded(false);
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setdocumentUrl("");
  setDocument(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
  // setDocumentLoaded(false);
  setDataLoaded(false);
  setAssignedProduct(undefined);
  setAssignedTrade(undefined);
  setDocumentFocus(undefined);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

return (
    <>
      <div
        className="modal fade"
        // id="document-edit-modal"
        // id="tec-tax-waiver"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                TEC Power of Attorney
              </h5>
              <br />
              { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1 pt-1 text-white">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                  </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}
                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill();
                      AutofillProductDetails(assignedProduct);
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>
                  <br />

                  <FormGroup className="m-2">
                    <FormControlLabel 
                      control={<Checkbox color="default" 
                      name="toggle_notary"
                      checked={(documentValues && documentValues.toggle_notary ? documentValues.toggle_notary : false)}
                      onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                      onBlur={(e) => updatePreview(e)}
                      label="Enable/Disable Notary Section" 
                    />
                  </FormGroup>

                  { assignedProduct ? (
                      <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                          Product Snapshot
                          <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                          <h5>SN: { assignedProduct.stockNumber }</h5>
                          <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                          <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                          </div>
                      </div>
                  ):null}

                  { assignedTrade ? (
                      <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                          Trade In Snapshot
                          <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                            <h5>VIN: { assignedTrade.vin.toUpperCase() }</h5>
                            <h5>YMM: { assignedTrade.year+" "+assignedTrade.make+" "+assignedTrade.model.toUpperCase() }</h5>
                          </div>
                      </div>
                  ):null}

                  <div className="row">
                  <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">DBA / Company</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="representative_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.representative_name ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly pb-3">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Attorney Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="attorney_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.attorney_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6"></div>
                    </div>
                    <hr></hr>

                    <h5>Vehicle Information</h5>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Vehicle Type</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vehicle_type"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vehicle_type ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        {/* <label htmlFor="stock">Day</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="day"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.day ?? '' }
                          /> */}
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">State Of</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="state_of"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.state_of ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">County Of</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="county_of"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.county_of ?? '' }
                          />
                      </div>
                    </div>
                    {/* <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Month</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="month"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.month ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year ?? '' }
                          />
                      </div>
                    </div> */}
                    {/* <div className="d-flex justify-content-evenly">

                      <div className="p-2 col-md-6"></div>
                    </div> */}
                </div>
                <hr></hr>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={document  && document !== undefined && documentUrl !== undefined ? documentUrl : ""} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default TECPowerOfAttorney

import React, { useState, useEffect } from "react";
import { recordFrontEndError } from "../../../../../../../utlis/api";
import { useDispatch, useSelector} from "react-redux";
// import {
//   useParams
// } from "react-router-dom";
import { Autocomplete, TextField, CircularProgress, Button } from '@mui/material';
import States from './States';
import { editProduct, editProductRegistrationAddress } from "../../../../../../../redux/products";
import { useOrder } from "../../../../../../../redux/orders/order";
import { saveProductSection, newProductRegistration, updateProductRegistration ,getOtherProductsInGroup, filterDocumentsForProduct, filterProductDocumentsForOrder } from "../../../../../../../utlis/dataSaveAndCopyLibrary";
import { updateCopySection, updateCopySubSection, updateObjectId, updateObjectGroupId, updateChangesArray, updateGroupChangesArray, updateStatus } from "../../../../../../../redux/saveAndCopy"
import { setActiveModal, setActiveModalTab } from "../../../../../../../redux/app/appSaver";
import { addTestingTag } from "../../../../../../../utlis/testingHelpers";


const RegistrationAddressTab = ({ product, preCloseClose, documents }) => {
  const dispatch        = useDispatch()
  // let { id }      = useParams();

  const order               = useOrder();
  const appData = useSelector((state) => state?.appData);
  const changesArray = useSelector((state) => state?.copyChangesToDocumentsRedux.changesArray);

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addressOptions, setAddressOptions] = useState(null);

  function copySelectedAddress(e){
    e.preventDefault()

    if(selectedAddress !== null){
      console.log('copySelectedAddress', selectedAddress)
      dispatch(editProduct({prop:'registrationAddress', value:selectedAddress?.address}))
      dispatch(updateStatus('unsaved'))
    }
  }

  useEffect(() => {

    if(appData?.activeModal === 'product' && appData?.activeModalTab === 'registration-address'){
      if(addressOptions === null){
        let addresses = [];

        let deliveryAddress = {
          label: "Customer Delivery Address",
          address: order?.customer?.deliveryAddress
        }
        addresses.push(deliveryAddress)


        if(order?.customer?.mailingAddress){
          let mailingAddress = {
            label: "Customer Mailing Address",
            address: order?.customer?.mailingAddress
          }
          addresses.push(mailingAddress)
        }

        if(product?.fobAddress){
          let fobAddress = {
            label: "Product FOB Address",
            address: product?.fobAddress
          }
          addresses.push(fobAddress)
        }

        setAddressOptions(addresses);
      }
    }

  }, [addressOptions, appData?.activeModal, appData?.activeModalTab, dispatch, order?.customer?.deliveryAddress, order?.customer?.mailingAddress, product?.fobAddress])



  // let { id }      = useParams();


 
  const [invalidDataEntry, setInvalidDataEntry] = useState(false);
  
  // const locations = JSON.parse(localStorage.getItem("alllocations"));

  const saveStatus          = useSelector((state) => state?.copyChangesToDocumentsRedux.status);

  // const changesArray = useSelector((state) => state?.copyChangesToDocumentsRedux.changesArray);
  
  // eslint-disable-next-line no-unused-vars
  const [dataChangeArray, setDataChangeArray]   = useState([]);
  // function updateDataChangeArray(e, passedProp=null, passedValue=null){
  //   e.preventDefault();

  //   let { name: prop, value} = e.target;

  //   if(passedProp){
  //     prop = passedProp;
  //   }

  //   if(passedValue){
  //     value = passedValue;
  //   }

  //   let doNotCopyProperties = [];

  //   if(dataChangeArray && !doNotCopyProperties.includes(prop) && prop !== undefined && prop !== null){

  //     if(dataChangeArray["registrationAddress."+prop]?.oldValue){

  //       setDataChangeArray((prev)=>({
  //         ...prev,
  //         ["registrationAddress."+prop]: {
  //           "newValue": value,
  //           "oldValue": dataChangeArray["registrationAddress."+prop].oldValue
  //         }
  //       }))
        
  //     }else{
  //       const oldValue = product?.registrationAddress[prop];

  //       setDataChangeArray((prev)=>({
  //         ...prev,
  //         ["registrationAddress."+prop]: {
  //           "newValue": value,
  //           "oldValue": oldValue ? oldValue : '--'
  //         }
  //       }))

  //     }
  //   }
  // }

  useEffect(() => {

    if(appData?.activeModal === 'product' && appData?.activeModalTab === 'registration-address'){
      dispatch(updateChangesArray(dataChangeArray));
    }

  }, [ appData, dataChangeArray, dispatch ])

  // useEffect(() => {

  //   if(appData?.activeModal === 'product' && appData?.activeModalTab === 'registration-address'){
  //     if(product?.registrationAddress?.addressLine1 === null || product?.registrationAddress?.city === null || product?.registrationAddress?.state === null || product?.registrationAddress?.zip === null){
  //       setInvalidDataEntry(true);
  //     }
  //   }

  // }, [appData, product?.registrationAddress])

  // const copyDealerLocation= (e) => {
  //   e.preventDefault()
  //   const foundLocation = locations.find(loc=>loc.locationCode===order.tecLocationCode);
  //   if(!foundLocation || !foundLocation?.address){
  //     return;
  //   }

  //   // let payload = {
  //   //   addressLine1 : foundLocation.address,
  //   //   addressLine2 : '',
  //   //   city: foundLocation.city,
  //   //   zip : foundLocation.zip,
  //   //   state : foundLocation.state,
  //   // }
    
  //   // dispatch(editProduct({prop:'registrationAddress', value:{...product.registrationAddress, ...payload}}))
  //   dispatch(updateStatus('unsaved'))

  //   dispatch(updateChangesArray( (prev) => ({
  //     ...prev,
  //     addressLine1: {
  //       "newValue": foundLocation.address,
  //       "oldValue": product?.registrationAddress?.addressLine1 ? product?.registrationAddress?.addressLine1 : "--"
  //     },
  //     addressLine2: {
  //       "newValue": "--",
  //       "oldValue": product?.registrationAddress?.addressLine2 ? product?.registrationAddress?.addressLine2 : "--"
  //     },
  //     city: {
  //       "newValue": foundLocation.city,
  //       "oldValue": product?.registrationAddress?.city ? product?.registrationAddress?.city : "--"
  //     },
  //     zip: {
  //       "newValue": foundLocation.zip,
  //       "oldValue": product?.registrationAddress?.zip ? product?.registrationAddress?.zip : "--"
  //     },
  //     state: {
  //       "newValue": foundLocation.state,
  //       "oldValue": product?.registrationAddress?.state ? product?.registrationAddress?.state : "--"
  //     },
  //   })));

  // }

  const stateAbbrev = States.map(state => {
    return state.abbreviation;
  });
  const formatZipCode = (value) => {
    if(value && value !== undefined && value !== null) {
      let zipCode = value.replace(/[^\d]/g, "");
      zipCode = zipCode.trim().replace(/(\d{5})/gi,"$1-").replace(/-$/gi,"");
      if(zipCode.length > 10) {
        zipCode = zipCode.slice(0, 10);
      }
      return zipCode;
    }
  }

  const onEdit = (e, newValue=null, passedName=null )=>{

      let {value, name} = e.target
      let prop = name;

      if (newValue){
        value = newValue;
      }

      if(passedName){
        prop = passedName;
      }

      if(prop === 'zip') value = formatZipCode(value);

      console.log('onEdit', prop, value, product.registrationAddress)

      // updateDataChangeArray(e, passedName, newValue);

      dispatch(editProductRegistrationAddress({value: value, prop: prop}));

      dispatch(updateStatus('unsaved'));
    }

  const handleSubmit = async event => {
    event.preventDefault();

    dispatch(updateStatus('saving'));
    
    let registrationAddressObj = {
      addressLine1: product?.registrationAddress?.addressLine1?.toUpperCase()??'',
      addressLine2: product?.registrationAddress?.addressLine2?.toUpperCase()??'',
      city: product?.registrationAddress?.city?.toUpperCase()??'',
      state: product?.registrationAddress?.state ?? '',
      zip:  product?.registrationAddress?.zip ?? '',
      county:  product?.registrationAddress?.county ?? '',
    }

    if(
      registrationAddressObj?.addressLine1 === "" ||
      registrationAddressObj?.city === "" ||
      registrationAddressObj?.state === "" ||
      registrationAddressObj?.zip === "" || (Number(registrationAddressObj?.zip?.length) !== 5 && Number(registrationAddressObj?.zip?.length !== 9)) ||
      registrationAddressObj?.county === ""
    ){
      dispatch(updateStatus('failed'));
      setInvalidDataEntry(true);
    } else {
      if (registrationAddressObj) {
        dispatch(updateCopySection("product"));
        dispatch(updateCopySubSection("registrationAddress"));
        dispatch(updateObjectId(product.id));
        dispatch(updateObjectGroupId(product.productGroupId));
        try {
          if (product.registrationAddress && product.registrationAddress.id) {
            // dispatch(updateSaveFunction(updateProductFob));
            saveProductSection(
              event,
              order,
              product,
              "registration",
              changesArray,
              updateProductRegistration,
              () => {
                dispatch(updateStatus("saved"));
              },
            );
          } else {
            // dispatch(updateSaveFunction(newProductFob));
            newProductRegistration(order, product, () => {
              dispatch(updateStatus("saved"));
            });
          }
        } catch (err) {
          recordFrontEndError(
            "RegistrationAddressTab.js",
            "Error saving registration address: " + err,
          );
        }
      }
    }
  };

  return (
    <>
      { appData?.activeModal === 'product' && appData?.activeModalTab === 'registration-address' ? (
        <form>
          <div className="container">
            <div className="row border-bottom">
              <div className="text-capitalize m-2 d-flex">
                <div className="mb-3 col-md-12 mt-3" style={{ width: '300px'}}>
                  <h5>Product Registration Address</h5>
                </div>
                { saveStatus === 'unsaved' ? (
                  <div style={{ float: 'right', marginLeft: 'auto', order: 2, marginTop: '1rem'}}>
                      <p className="mx-1">Save Changes?</p>
                      <SaveChangesYesButton onClick={handleSubmit} product={product} datatestid="product.registrationAddress.control.saveYes" />
                      <button className="mx-1 btn btn-sm btn-danger" aria-label="cancel" data-testid="product.registrationAddress.control.saveNo" onClick={preCloseClose}>No</button>
                    </div>
                  ):(
                  <div style={{ float: 'right', marginLeft: 'auto', order: 2, marginTop: '1rem'}}>
                    {saveStatus === 'saving' ? (
                      <CircularProgress style={{ placeSelf: "center" }} />
                      ):( saveStatus === 'saved' ? (
                        <SavedProductButton product={product} documents={documents} />
                        ):(
                          <SaveProductButton onClick={handleSubmit} product={product} datatestid="product.registrationAddress.control.saveTop" />
                          ))}
                  </div>
                  )}
              </div>
            </div>
            <br />
            <div className="row">

            <div className="mb-3 col-md-6">
                <Autocomplete
                  fullWidth
                  value={selectedAddress ?? ''}
                  aria-label='registration address state'
                  onChange={(e, newValue) => {
                    setSelectedAddress(newValue);
                  }}
                  options={addressOptions??[]}
                  sx={{ width: '-webkit-fill-available', marginX: '1rem' }}
                  name="copyAddressDropdown"
                  renderInput={(params) => <TextField {...addTestingTag(params, 'product.registrationAddress.copyAddressDropdown')} label="Address Options" />}
                />
                <span style={{display: (invalidDataEntry && (!Boolean(product?.registrationAddress?.state)||(product?.registrationAddress?.state===''))) ? 'block' : 'none'}} className="text-danger small">State is required.</span>
                <br />
                <Button fullWidth sx={{ width: '-webkit-fill-available', marginX: '1rem' }} variant="contained" disabled={selectedAddress === null} className="btn btn-primary " data-testid="product.registrationAddress.control.copyDealerLocation" onClick={e=> copySelectedAddress(e)} >Apply Selected Address</Button>
            </div>

            <div className="mb-3 col-md-6">
              <div>
                <div>
                  <label htmlFor="address1" className="form-label">
                    Address Line 1
                  </label>
                  <input
                    type="text"
                    aria-label="registration address line 1"
                    className="rounded-pill form-control "
                    value={product?.registrationAddress?.addressLine1 ??''}
                    onChange={(e) => onEdit(e)}
                    data-testid="product.registrationAddress.addressLine1"
                    name="addressLine1"
                    />
                    <span style={{display: (invalidDataEntry && (!Boolean(product?.registrationAddress?.addressLine1)||(product?.registrationAddress?.addressLine1===''))) ? 'block' : 'none'}} className="text-danger small">Address is required.</span>
                </div>
                <div>
                  <label htmlFor="address2" className="form-label">
                    Address Line 2
                  </label>
                  <input
                    type="text"
                    className="rounded-pill form-control "
                    //onChange={e => dispatch(updateDeliveryAddress2(e.target.value))}
                    aria-label="registration address line 2"
                    value={product?.registrationAddress?.addressLine2 ?? ''}
                    data-testid="product.registrationAddress.addressLine2"
                    onChange={(e) => onEdit(e)}
                    name="addressLine2"
                    />
                </div>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="city" className="rounded-pill form-label">
                      City
                    </label>
                    <input
                      type="text"
                      className="rounded-pill form-control "
                      aria-label="registration address city"
                      data-testid="product.registrationAddress.city"
                      value={product?.registrationAddress?.city ?? ''}
                      onChange={(e) => onEdit(e)}
                      name="city"
                      />
                      <span style={{display: (invalidDataEntry && (!Boolean(product?.registrationAddress?.city)||(product?.registrationAddress?.city===''))) ? 'block' : 'none'}} className="text-danger small">City is required.</span>
                  </div>
                  <div className="mb-3 col-md-6 mt-3">
                    <Autocomplete
                      value={product?.registrationAddress?.state ?? ''}
                      aria-label='registration address state'
                      onChange={(e, newValue) => {
                        onEdit(e, newValue, 'state')
                      }}
                      options={stateAbbrev??[]}
                      sx={{ width: 150 }}
                      name="state"
                      renderInput={(params) => <TextField {...addTestingTag(params, 'product.registrationAddress.state')} label="STATE" />}
                    />
                    <span style={{display: (invalidDataEntry && (!Boolean(product?.registrationAddress?.state)||(product?.registrationAddress?.state===''))) ? 'block' : 'none'}} className="text-danger small">State is required.</span>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="zip" className="form-label">
                      Zip
                    </label>
                    <input
                      type="text"
                      aria-label='registration address zip'
                      className="rounded-pill form-control"
                      name="zip"
                      data-testid="product.registrationAddress.zip"
                      value={formatZipCode(product?.registrationAddress?.zip) ?? ''}
                      onChange={(e) => onEdit(e)}
                    />
                    <span style={{display: (invalidDataEntry && (!Boolean(product?.registrationAddress?.zip)||(product?.registrationAddress?.zip==='') ||(product?.registrationAddress?.zip?.length !==5 && product?.registrationAddress?.zip?.length !==9))) ? 'block' : 'none'}} className="text-danger small">Zip is required and must be 5 or 9 digits long.</span>
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="county" className="form-label">
                      County
                    </label>
                    <input
                      type="text"
                      aria-label='registration address county'
                      className="rounded-pill form-control"
                      name="county"
                      data-testid="product.registrationAddress.county"
                      value={product?.registrationAddress?.county ?? ''}
                      onChange={(e) => onEdit(e)}
                    />
                    <span style={{display: (invalidDataEntry && (!Boolean(product?.registrationAddress?.county)||(product?.registrationAddress?.county===''))) ? 'block' : 'none'}} className="text-danger small">County is required.</span>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div className="text-center">
            { saveStatus === 'saving' ?
            <CircularProgress style={{ placeSelf: "center" }} />
            :( saveStatus === 'saved' ? (
              <SavedProductButton product={product} documents={documents} />
            ):(
              <SaveProductButton onClick={handleSubmit} product={product} datatestid="product.registrationAddress.control.saveBottom" />
            ))}
          </div>
        </form>
      ):null}
    </>
  );
}

const SaveProductButton = ({ onClick, product, datatestid }) => {
  return (
    <>
      <button aria-label='Save' onClick={onClick} data-testid={datatestid} className="btn btn-primary text-uppercase">Save</button>
    </>
  )
}

const SaveChangesYesButton = ({ onClick, product, datatestid }) => {
  return (
    <>
      <button aria-label='Yes' onClick={onClick} data-testid={datatestid} className="btn btn-success text-uppercase">Yes</button>
    </>
  )
}

const SavedProductButton = ({ product, documents }) => {

  const order   = useOrder();
  const dispatch  = useDispatch();
  const changesArray = useSelector((state) => state?.copyChangesToDocumentsRedux.changesArray);

  function filterDocuments(){
    let productDocuments = filterDocumentsForProduct(product.id, documents);
    let orderDocuments = filterProductDocumentsForOrder(product.id, documents);

    let allFilteredDocuments = orderDocuments.concat(productDocuments);
    return allFilteredDocuments;
  }

  function prepRedux(){
    dispatch(updateCopySection('product'));
    dispatch(updateCopySubSection('registrationAddress'));
    dispatch(setActiveModalTab(null));
  }

  function prepGroupCopy(){
    let copyChangesArray = [];
    copyChangesArray.id = product.id;
    copyChangesArray.stockNumber = product.stockNumber;
    copyChangesArray.propertySaved = false;
    copyChangesArray.docsSavedCount = 0;
    Object.entries(changesArray).forEach( value => {
      copyChangesArray[value[0]] = value[1];
    });


    dispatch(updateGroupChangesArray([copyChangesArray]));
    dispatch(updateStatus('copy-to-group'));
    dispatch(setActiveModal('copy-to-product-group'));
  }

  function prepDocumentCopy(){
    let copyChangesArray = [];
    copyChangesArray.id = product.id;
    copyChangesArray.stockNumber = product.stockNumber;
    copyChangesArray.propertySaved = false;
    copyChangesArray.docsSavedCount = 0;
    Object.entries(changesArray).forEach( value => {
      copyChangesArray[value[0]] = value[1];
    });
    dispatch(updateGroupChangesArray([copyChangesArray]));
    dispatch(updateStatus('copy'));
    dispatch(setActiveModal('copy-to-documents'));
  }

  return (
    <>
      <span className="btn btn-sm btn-success text-uppercase mx-2">Product Saved</span>
      { getOtherProductsInGroup(order, product.id, product.productGroupId)?.length > 0 ? (
        <>
          <button aria-label='Copy To Group' 
            onClick={ (e)=>{
              e.preventDefault()
              prepRedux();
              prepGroupCopy();
            }} data-bs-dismiss="modal" data-bs-toggle="modal" data-testid="product.registrationAddress.control.copyToGroup" data-bs-target="#copychangestogroupmodal" className="btn btn-primary text-uppercase mx-2">Copy to Group</button>
        </>       
        ): filterDocuments()?.length > 0 ? (
        <>
          <button aria-label='Copy to Documents' 
            onClick={ (e)=>{
              e.preventDefault();
              prepRedux();
              prepDocumentCopy();
            }} data-bs-dismiss="modal" data-bs-toggle="modal"  data-testid="product.registrationAddress.control.copyToDocument" data-bs-target="#copychangestodocuments" className="btn btn-sm btn-primary text-uppercase mx-2">Copy to Documents</button>
        </>
      ):null}
    </>
  )
}

export default RegistrationAddressTab;
import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import "./Style/productmodal.css"
// import { setProductFocus } from "../../../../../../../redux/products/productFocusReducer";
import { useOrder } from "../../../../../../../redux/orders/order"
import { recordFrontEndError } from "../../../../../../../utlis/api"
// import TabPanel from "./reusable_components/TabPanel";
// import { Button,Autocomplete,TextField,Input } from "@mui/material";
import * as api from "../../../../../../../utlis/api";
import { matchAndGroupProducts } from '../../../../../../../utlis/dataSaveAndCopyLibrary.js';
import { refreshOrder } from '../../../../../../../redux/orders/order';

function DuplicateProductModal() {
  let { id }      = useParams();
  const dispatch  = useDispatch();

  let product     = {};
  const product_id = useSelector(state => state?.productFocus.product_focus_id );
  const order   = useOrder();

  const [duplicateSuccess, setDuplicateSuccess] = useState(false);
  const [duplicateInProgress, setDuplicateInProgress] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [copyDetailsArr, setCopyDetailsArr] = useState([{stockNum:'', vin:''}])


  if ( order.products) {
    const {products} = order;
    if(products.length) {
      product = (products.find(p => product_id === p.id));
      if(product){
      }
    }
  }

  const styles = {
    suggestions: {
      border: '1px solid #999',
      borderTopWidth: 0,
      listStyle: 'none',
      marginTop: 0,
      maxHeight: 143,
      overflowY: 'auto',
      paddingLeft: 0,
      width: 'calc(300px + 1rem)',
      "& li": {
        padding: '0.5rem'
      },
      "& li:hover": {
        backgroundColor: '#0d6efd',
        color: '#fff',
        cursor: 'pointer',
        fontWeight: 700
      }
    },
    suggestionActive: {
      backgroundColor: '#0d6efd',
      color: '#fff',
      cursor: 'pointer',
      fontWeight: 700
    },
  };
  
  const [highlightListItem, setHighlightListItem] = useState(0);
  // const [suggestionSelectedIndex, setSuggestionSelectedIndex] = useState('');
  // const [suggestions, setSuggestions] = useState([]);
  // const [suggestionsObjs, setSuggestionsObjs] = useState([]);

  const handleListToggle = (index) => {
    setHighlightListItem(index);
  }

  

  
    
  const onVinChange = (e,i) => {
    //validate Vin length
    let newStr = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
    newStr = newStr.length > 17 ? newStr.slice(0, -1) : newStr;
    ;
    // setSelectedVin(newStr);
    setCopyDetailsArr((prevArr) =>
      prevArr.map((detail,index) =>
        i=== index ? { ...detail, vin:newStr, vinError:(newStr.length >= 17 ? 'none': 'block') } : detail
      )
    );
  }
  const resetProduct=()=>{
    // setSelectedStockNo('');
    // setSelectedVin('');
    // setvinErrorShow('none');
    setDuplicateSuccess(false);
    setHighlightListItem(0);
    // setSuggestions([]);
    // setSuggestionsObjs([]);
    setDuplicateInProgress(false);
    setQuantity(1);
    setCopyDetailsArr([{}])
  }

  const duplicateProduct= async ()=>{
    setDuplicateInProgress(true);
    if (quantity <= 150 && quantity >= 1 ){
    try {
      copyDetailsArr.forEach((obj, i)=>{
        api.duplicateProduct(id,product.id, obj.stockNum, obj.vin)    //axios.post(`/order/${id}/product/${product.id}/duplicate`, {stockNumber: selectedStockNo, vin: selectedVin})
      })



      matchAndGroupProducts(order, () => {
        dispatch(refreshOrder(order.id, true))
      })


      // dispatch(refreshOrder(order.id, true))

      setDuplicateSuccess(true);
      setDuplicateInProgress(false);
    } catch (err) {
      setDuplicateSuccess(false);
      setDuplicateInProgress(false);
      recordFrontEndError('DuplicateProductModal.js', 'Error duplicating product')
    }
  }
  }
  

  // const isValidCopyArray= 
  const handleChangeQuantity=(e)=>{
    const parsed = parseInt(quantity,10)
    if(parsed >= 150) {
      setQuantity(150)
      setCopyDetailsArr(Array(150).fill({stockNum:'', vin:'', suggestions:[]}))
    }
    else if(parsed < 1) {
      setQuantity(1)
      setCopyDetailsArr(Array(1).fill({stockNum:'', vin:'', suggestions:[]}))
    }
    else {
      setQuantity(parsed.toString())
      setCopyDetailsArr(Array(parsed).fill({stockNum:'', vin:'', suggestions:[]}))
    }
  }


  return (
    <>
      <div
        className="modal fade"
        id="duplicateProductModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header bg-primary my-0 py-1">
              <div className="col-12 d-flex" style={{ justifyContent: 'space-between'}}>
                <h5
                  className="modal-title text-white col-3 mt-2"
                  id="exampleModalLabel"
                >
                  Duplicate Product
                </h5>
                  <button
                    type="button"
                    className="btn-close bg-white mt-2"
                    id="closeBtn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={resetProduct}
                  />
              </div>
            </div>
            <div className="modal-body tab-content" style={{padding: "0"}}>
                  <section className="container w-100">
                    <div className="col-12 d-flex justify-content-center pt-2 align-items-center">
                    <div className="col-4">
                        <label htmlFor="stock">Quantity#: </label>
                        <input
                          type="number"
                          className="form-control mt-1 text-uppercase"
                          style={{marginTop:'25px'}}
                          value={quantity}
                          aria-label='quantity to copy'
                          disabled={duplicateSuccess || duplicateInProgress}
                          min={1}
                          max={150}
                          onKeyDown={e=>e.key==='Enter'&& handleChangeQuantity(e)}
                          onChange={(e) => {
                            setQuantity(e.target.value)
                          }}
                          onBlur={(e)=>handleChangeQuantity(e)}
                          // onSubmit={(e)=>handleChangeQuantity(e)}
                        />
                        <span style={{display:Number(quantity) > 150 ? 'block' : 'none' }} className="text-danger small">Max quantity: 150</span>
                      </div>
                      </div>
                    <div className="row justify-content-around py-4">
                      {copyDetailsArr?.length > 0 && copyDetailsArr.map((copy, i)=>{

                        const setSuggestionSelectionIndex = (index) => {
                          const {stockno, vin} = copy.suggestionObjs[index];

                          setCopyDetailsArr((prevArr) =>
                            prevArr.map((detail,index) =>
                              i=== index ? { ...detail, stockNum:stockno, vin:vin, suggestions:[], suggestionObjs:[] } : detail
                            )
                          );

                        }

                        const addSuggestionList = (suggestions) => {
                          if(suggestions?.length) {
                            return(
                              <ul style={styles.suggestions}>
                                {suggestions.map((suggestion, index) => {
                                  return (
                                    <li
                                      onMouseOver={() => handleListToggle(index)}
                                      style={highlightListItem === index ? styles.suggestionActive : null}
                                      onClick={e => setSuggestionSelectionIndex(index)}
                                    >
                                      {suggestion}
                                    </li>
                                  );
                                })}
                              </ul>
                            );
                          }
                        }

                        const searchByStockNo= async (event,i) =>{
                          const {value}=event.target
                            try{
                              let suggestions=[];
                              let suggestionObjs=[];

                              if(value.length >= 3){
                                let res = await api.getInventorySelection(value)
                                suggestionObjs = [...res.data.data]
                                suggestions = suggestionObjs.map(el => {
                                  return el.stockno;
                                });
                              }

                              setCopyDetailsArr((prevArr) =>
                                prevArr.map((detail,index) =>
                                  i=== index ? { ...detail, stockNum:value, suggestions:suggestions, suggestionObjs:suggestionObjs } : detail
                                )
                              );
                            } catch (err){
                              recordFrontEndError('DuplicateProductModal.js', 'Error getting stock numbers: ' + err)
                            }
                        }

                        return (
                          <>
                            <div className="col-6">
                              <label htmlFor="stock">Stock #</label>
                                <input
                                  type="text"
                                  aria-label="product to copy stock number"
                                  className="form-control rounded-pill mt-1 text-uppercase"
                                  name={"productDetailStock"+i}
                                  disabled={duplicateSuccess || duplicateInProgress}
                                  onChange={(e) => searchByStockNo(e,i)}
                                  value={ (copy?.stockNum ?? '') }
                                />
                                {addSuggestionList(copy?.suggestions)}
                            </div>
                            <div className="col-6">
                              <label htmlFor="stock">VIN</label>
                              <input
                                className="form-control rounded-pill mt-1 text-uppercase"
                                style={{marginTop:'25px'}}
                                value={copy?.vin??''}
                                aria-label={'product to copy vin' +i}
                                disabled={duplicateSuccess || duplicateInProgress}
                                onChange={(e) => onVinChange(e,i)}
                                maxLength="17"
                              />
                              <span style={{display: copy?.vinError??'none'}} className="text-danger small">Vin must be 17 digits long.</span>
                            </div>
                        </>
                      )
                    }
                    )
                    }
                      {/* <div className="col-6">
                        <label htmlFor="stock">Stock #</label>
                        <input
                          type="text"
                          aria-label="product to copy stock number"
                          className="form-control rounded-pill mt-1 text-uppercase"
                          name="productDetailStock"
                          disabled={duplicateSuccess || duplicateInProgress}
                          onChange={(e) => searchByStockNo(e)}
                          value={ (selectedStockNo ?? null) }
                        />
                        {addSuggestionList(suggestions)}
                      </div>    
                      <div className="col-6">
                        <label htmlFor="stock">VIN</label>
                        <input
                          className="form-control rounded-pill mt-1 text-uppercase"
                          style={{marginTop:'25px'}}
                          value={selectedVin??''}
                          aria-label='product to copy vin'
                          disabled={duplicateSuccess || duplicateInProgress}
                          onChange={(e) => onVinChange(e)}
                          maxLength="17"
                        />
                        <span style={{display: vinErrorShow}} className="text-danger small">Vin must be 17 digits long.</span>
                      </div> */}
                    </div>
                    <div className="row justify-content-center py-4">
                      { !duplicateSuccess ? (
                        <div className="col-auto">
                          <button onClick={duplicateProduct} disabled={!(copyDetailsArr.every(obj=>obj?.stockNum?.length>0&& obj?.vin?.length===17)&& !duplicateInProgress)} className="btn btn-primary text-uppercase" aria-label='duplicate product'>Duplicate Product</button>
                        </div>
                      ):(
                        <div className="col-auto">
                          <button className="btn btn-success text-uppercase" aria-label="success">Product Duplicated</button>
                        </div>
                      )}
                    </div>
                  </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DuplicateProductModal;

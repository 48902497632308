import React, { useState, useEffect } from "react";
import { useFetch } from "../../../../../../../../utlis/api"
import { useParams } from "react-router-dom";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import moment from "moment";
import { Autocomplete, TextField } from '@mui/material';
import { addProductDeposit,editProductDeposit,deleteProductDeposit } from './../../../../../../../../redux/products';
import NumberFormat from "react-number-format";
import { useDispatch, useSelector} from "react-redux";
import * as api from "../../../../../../../../utlis/api"
import { removeChars, formatter } from "../../../../../../../../utlis/formatters";
import { updateChangesArray, updateStatus } from "../../../../../../../../redux/saveAndCopy"
import { addTestingTag } from "../../../../../../../../utlis/testingHelpers";

const Deposits = ({ product, updateNewDeposits,updateinputeditDepositIds, updateEditDeposits, updateDeleteDepositIds, updateIsValidDepositsAddForm, updateIsValidDepositsEditForm, resetDepositFunc, resetDepositsFunc }) => {
  let { id } = useParams();
  const dispatch = useDispatch()
  const axios = useFetch()
  const [paymentMethods, setPaymentMethods] = useState([]);
  const changesArray = useSelector((state) => state?.copyChangesToDocumentsRedux.changesArray);

  useEffect(() => {

    if(paymentMethods && paymentMethods.length <1){
      axios.get('/payment-method').then( res => {
        setPaymentMethods(res.data)
      })
    }

  },[paymentMethods, setPaymentMethods, axios]);


  function updateChangeArrayFunction(id, prop, value){
   let newArray = JSON.parse(JSON.stringify(changesArray));
   console.log('updateChangeArrayFunction 1', newArray, id);

        if(!newArray?.deposits || newArray?.deposits?.length === 0){
          newArray.deposits = [];
        }

        let foundDeposit = newArray?.deposits?.length > 0 ? newArray.deposits.find(d => d.id === id) : null;

        // If no deposits array, create it.
        if(!foundDeposit){
          foundDeposit = {id: id};
          foundDeposit[prop] = value;
          console.log('updateChangeArrayFunction 2', newArray, foundDeposit);
          newArray.deposits.push(foundDeposit);
          // newArray.deposits = [];
          dispatch(updateChangesArray(newArray));
        }

        // Add a position to the change array ~ deposit array.
        // if(!newArray.deposits[index]){
        //   console.log('updateChangeArrayFunction 3', newArray);
        //   // newArray.deposits.push([]);
        //   dispatch(updateChangesArray(newArray));
        // }

        // Add prop to arrary
        // if(newArray.deposits[index] && !newArray.deposits[index][prop]){
        //   console.log('updateChangeArrayFunction 4', newArray);
        //   newArray.deposits[index][prop] = {};
        //   dispatch(updateChangesArray(newArray));
        // }


      // if((newArray?.deposits?.length - 1) >= index){
        // console.log('updateChangeArrayFunction 5', newArray);

        if(foundDeposit[prop]?.oldValue){
          console.log('updateChangeArrayFunction 6', newArray);

          let updatedDeposits = newArray["deposits"];

          foundDeposit[prop] = {
            "newValue": value,
            "oldValue": foundDeposit[prop].oldValue
          }

          updatedDeposits.forEach(deposit => {
            if(deposit?.id === foundDeposit.id){
              deposit[prop] = foundDeposit[prop];
            }
          });

          dispatch(updateChangesArray({
            ...changesArray,
            "deposits": updatedDeposits
          }));  
        }else{
          console.log('updateChangeArrayFunction 7', newArray);

          // if(foundDeposit[prop]){
            // console.log('updateChangeArrayFunction 8', newArray);

            let updatedDeposits = JSON.parse(JSON.stringify(newArray["deposits"]));

            foundDeposit[prop] = {
              "newValue": value,
              "oldValue":  foundDeposit[prop]
            }
            
            updatedDeposits.forEach(deposit => {
              if(deposit?.id === foundDeposit.id){
                deposit[prop] = foundDeposit[prop];
              }
            });

            dispatch(updateChangesArray({
              ...changesArray,
              "deposits": updatedDeposits
            }));          
          // }
        }

      // }
  }
  
  const onEditDepositChange = (e, newValue=undefined, passedIndex=undefined) => {
    let {value, name:prop, checked, index, id} = e.target

    if(index === undefined && passedIndex !== undefined){
      index = passedIndex;
    }

    console.log('onEditDepositChange', id, index, newValue, passedIndex)

    if (newValue!==undefined){
      dispatch(editProductDeposit({index:Number(index), prop:'paymentMethodId', value:newValue.id}))
    }else{
      if (prop === 'applyToPurchase'){
          value=checked
      }
      dispatch(editProductDeposit({index, prop:prop, value:value}))
    }

    // console.log('onEditDepositChange 1', changesArray);
    if(changesArray && Array.isArray(changesArray)){
      // console.log('onEditDepositChange 2', changesArray);
      updateChangeArrayFunction(id, prop, value);  



      //   let newArray = JSON.parse(JSON.stringify(changesArray));

      //   // If no deposits array, create it.
      //   if(!newArray.deposits){
      //     newArray.deposits = [];
      //     dispatch(updateChangesArray(newArray));
      //   }

      //   // ??
      //   if(!newArray.deposits[index]){
      //     newArray.deposits.push([]);
      //     dispatch(updateChangesArray(newArray));
      //   }

      //   if(newArray.deposits[index] && !newArray.deposits[index][prop]){
      //     newArray.deposits[index][prop] = {};
      //     dispatch(updateChangesArray(newArray));
      //   }


      // if((newArray?.deposits?.length - 1) >= index){
      //   console.log('deposit in change array?', newArray["deposits"][index], newArray);

      //   if(newArray["deposits"][index][prop]?.oldValue){
      //     newArray["deposits"][index][prop] = {
      //       "newValue": value,
      //       "oldValue":  newArray["deposits"][index][prop].oldValue
      //     }
      //     dispatch(updateChangesArray(newArray));
      //   }else{
      //     if(newArray["deposits"][index][prop]){

      //       newArray["deposits"][index][prop] = {
      //         "newValue": value,
      //         "oldValue":  product?.deposits && product?.deposits.length > 0 && product.deposits[index] ? product.deposits[index][prop] : '--'
      //       }
      //       dispatch(updateChangesArray(newArray));
      //     }
      //   }

      // }
    }

    dispatch(updateStatus('unsaved'))
  }

  const addNewDeposit = async (e) => {
    e.preventDefault();
      let initDeposit = {
        id: null,
        amount: 0,
        appliedAmount: '',
        applyToPurchase: false,
        dateReceived: '',
        depositStatus: '',
        depositStatusId: '',
        documentNumber: '',
        note: '',
        paymentMethodId: null,
        productId: product.id,
        refundAmount: '',
        status: '',
        invalid:false
      }

      if(changesArray && Array.isArray(changesArray)){


        let newArray = JSON.parse(JSON.stringify(changesArray));

        if(!newArray.deposits){
          newArray.deposits = [initDeposit];
          dispatch(updateChangesArray(newArray));
        }else{
          newArray.deposits.push(initDeposit);
          dispatch(updateChangesArray(newArray));
        }

      }

      dispatch(addProductDeposit({depositObject:initDeposit, orderId:id, productId:product.id}))
      dispatch(updateStatus('unsaved'))
  }

  const deleteDeposit = async (e, depositIndex) => {
    e.preventDefault();
    let {id:depositId} = e.target
    let depositsCopy = [...product.deposits]
    let filteredArr = depositsCopy.filter((deposit, i)=>{ 
      let filtered
      if (deposit?.id){
        filtered = (deposit?.id.toString()!==depositId)
        if (!filtered) api.deleteProductDeposit(id,product.id, (deposit?.id ?? null))
      } else {
        filtered =  i !== depositIndex
      } 
      return filtered
    })
    dispatch(deleteProductDeposit(filteredArr))
    dispatch(updateStatus('unsaved'))
  }

  const onPriceChange= (options, sourceInfo, passedIndex=undefined)=>{
    if ( sourceInfo.source === 'prop' ) {
      return;
    }

    let value;

    const { floatValue: float, value: formatted } = options;

    let {name:prop, id, index} = sourceInfo.event.target;
    
    if(prop==='appliedAmount'){
      value = formatted;
    } else if(prop==='amount'){
      value = float
    } 
    
    if(index === undefined && passedIndex !== undefined){
      index = passedIndex;
    }
    
    value = removeChars(formatter.format(Number(removeChars(value))));
    
    console.log('onPriceChange 1', formatted, float, value);
    
    dispatch(editProductDeposit({index, prop, value}));
    
    if(changesArray && (Array.isArray(changesArray) || typeof changesArray === 'object')){
      updateChangeArrayFunction(id, prop, value);  
    }
    
    
    dispatch(updateStatus('unsaved'))
  }

  const calcDepositsRefundToCustomer = (applyToPurchase, amount, appliedAmount, passedIndex) => {
    let formattedAmount = Number(removeChars(amount))
    let formattedAppliedAmount = Number(removeChars(appliedAmount))
    let refund = applyToPurchase === true ? formattedAmount - formattedAppliedAmount : formattedAmount;
    
    if(refund<0) refund= '0.00'
    dispatch(editProductDeposit({index: passedIndex, prop: 'refundAmount', value: removeChars(formatter.format(refund)).toString()}));

    return refund
  }

  return (
    <div>
        {/* ------------------------------ Row 9 ------------------------------------ */}
        <div className="row">
          <div className="col-12 bg-primary py-3 text-white d-flex" style={{marginLeft: "-20px"}}>
            <h6 style={{marginRight: "10px", marginLeft: "50px"}}>Deposits</h6>
              <AddCircleOutlineIcon 
                style={{cursor: "pointer"}} 
                aria-label='add new deposit'
                onClick={e => addNewDeposit(e)}
                data-testid="product.addition.control.addDepositButton"
              />
          </div>
          { product?.deposits?.length>0 && product?.deposits.map((deposit, index) => {
        return (
            <div  key={index}>
            <div className="d-flex mx-5 my-3 ">
            <div className="col-5 mx-3">
                <label htmlFor="stock">Amount</label>
                <NumberFormat
                          className="rounded-pill form-control"
                          value={ deposit?.amount?? ''}
                          aria-label='deposit amount'
                          name="amount"
                          index={index}
                          id={deposit?.id}
                          thousandSeparator={true}
                          decimalScale={2}
                          prefix={'$'}
                          data-testid="product.deposit.amount"
                          onBlur={()=>{dispatch(editProductDeposit({index, prop:'amount',  value: formatter.format(Number(removeChars(deposit?.amount)))}))}}
                          // format={format}
                          // onBlur={()=>{dispatch(editProductDeposit({index, prop:'amount',  value: Number(deposit?.amount).toFixed(2)}))}}
                          onValueChange={ (values, sourceInfo)=> onPriceChange(values, sourceInfo, index) }
                        />
                <span style={{display: (deposit?.invalid && !Boolean(deposit?.amount))? 'block': 'none'}} className="text-danger small">Amount is required.</span>
            </div>
            <div className="col-5 mx-3">
                <label htmlFor="type">Status</label>
                <input
                type="text"
                aria-label='deposit status'
                className="form-control rounded-pill mt-1 "
                name="status"
                index={index}
                data-testid="product.deposit.status"
                id={deposit?.id}
                value={deposit?.status?? ''}
                onChange={(e) => onEditDepositChange(e, undefined, index)}
                />
                <span style={{display: (deposit?.invalid&&(!Boolean(deposit?.status) || deposit?.status === ''))? 'block': 'none'}} className="text-danger small">Status is required.</span>
            </div>
        </div>
        <div className="d-flex mx-5 my-3 ">
        <div className="col-5 mx-3">
            { paymentMethods && paymentMethods.length > 0 ? (
              <Autocomplete
                className=""
                value={ paymentMethods.find( method=> method.id === deposit?.paymentMethodId) ?? null }
                aria-label='deposit payment method'
                // onInputChange={(e, newValue) => {
                //   onEditDepositChange(e,(deposit?.id ?? null),newValue)
                // }}
                index={index}
                id={deposit?.id}
                onChange={ (e, newValue) => onEditDepositChange(e, newValue, index) }
                options={paymentMethods ?? []}
                getOptionLabel={ option => {
                  return option?.name??'' }}
                renderInput={(params) => <TextField {...addTestingTag(params, 'product.deposit.paymentMethod')} label="PAYMENT METHOD" />}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }
            }
                />
              ):null}
            <span style={{display:(deposit?.invalid && !Boolean(deposit?.paymentMethodId))? 'block': 'none'}} className="text-danger small">Payment method is required.</span>
        </div>
        <div className="col-5 mx-3">
            <label htmlFor="type">Document #</label>
            <input
            type="text"
            className="form-control rounded-pill mt-1 "
            name="documentNumber"
            index={index}
            data-testid="product.deposit.documentNumber"
            id={deposit?.id}
            aria-label='deposit document number'
            value={deposit?.documentNumber ?? ''}
            onChange={(e) => onEditDepositChange(e, undefined, index)}
            />
            <span style={{display: (deposit?.invalid && (!Boolean(deposit?.documentNumber) || deposit?.documentNumber ===''))? 'block': 'none'}} className="text-danger small">Document number is required.</span>
        </div>
        </div>
        <div className="d-flex mx-5 my-3 ">
        <div className="col-5 mx-3">
            <label htmlFor="stock">Notes (Internal Use Only)</label>
            <textarea
            maxLength="250"
            className="form-control rounded-lg mt-1 "
            rows="5"
            name="note"
            index={index}
            data-testid="product.deposit.notes"
            id={deposit?.id}
            aria-label='deposit note'
            value={deposit?.note ?? ''}
            onChange={(e) => onEditDepositChange(e, undefined, index)}
            ></textarea>
            <br />
            <label htmlFor="stock">Date Received</label>
            <input
              type="date"
              className="form-control rounded-pill mt-1 "
              name="dateReceived"
              data-testid="product.deposit.dateReceived"
              index={index}
              id={deposit?.id}
              aria-label='deposit date received'
              value={moment.utc(deposit?.dateReceived).format("YYYY-MM-DD") ?? ''}
              onChange={(e) => onEditDepositChange(e, undefined, index)}
            />
        </div>
        <div className="col-5 mx-3 border rounded px-2 ">
            <div className=" d-flex mt-4">
            <label htmlFor="stock">
                <input
                    type="checkbox"
                    style={{padding:'10px'}}
                    className="custom-control-input-group-lg"
                    name='applyToPurchase'
                    aria-label="deposit apply to purchase"
                    checked ={ deposit?.applyToPurchase ?? ''}
                    index={index}
                    data-testid="product.deposit.applyToPurchase"
                    id={deposit?.id}
                    onChange={(e) =>{ 
                    onEditDepositChange(e, undefined, index)}}
                />
            </label>
            <label className="text-secondary"  style={{paddingLeft:'10px'}}>Apply to Purchase</label>
            </div>
            <div className="d-flex mx-2">
            <div className="mt-4 col-6">
                <p>Amount</p>
                <NumberFormat
                          className="rounded-pill form-control"
                          value={ deposit?.appliedAmount ?? ''}
                          name="appliedAmount"
                          aria-label='deposit applied amount'
                          index={index}
                          id={deposit?.id}
                          data-testid="product.deposit.appliedAmount"
                          thousandSeparator={true}
                          decimalScale={2}
                          prefix={'$'}
                          disabled={!deposit?.applyToPurchase}
                          // format={format}
                          // onBlur={()=>{dispatch(editProductDeposit({index, prop:'appliedAmount',  value: Number(deposit?.appliedAmount).toFixed(2)}))}}
                          onBlur={()=>{dispatch(editProductDeposit({index, prop:'appliedAmount',  value: formatter.format(Number(removeChars(deposit?.appliedAmount)))}))}}

                          onValueChange={ (values, sourceInfo)=> onPriceChange(values, sourceInfo, index) }
                        />
            </div>
            <div className=" ms-3 mt-4 col-6 my-2">
                <p>Refund to customer </p>
                { deposit?.applyToPurchase && deposit?.amount && deposit?.appliedAmount ? (
                  <p data-testid="product.deposit.calculated.refundToCustomer" >{formatter.format(calcDepositsRefundToCustomer(deposit?.applyToPurchase, deposit?.amount, deposit?.appliedAmount, index))}</p>
                ):<p data-testid="product.deposit.calculated.refundToCustomer" ></p>}
            </div>
            </div>
        </div>
        </div>
        <div className="text-center mt-3">
        <button className="btn btn-primary"
          key={index}
          id={ (deposit?.id ?? null) } 
          aria-label='delete deposit'
          data-testid="product.addition.control.deleteDepositButton"
          onClick={e => deleteDeposit(e, index)}
          >
            DELETE DEPOSIT
        </button>
        </div>
        </div>
    );
    })
}
          {/* {showNewDeposits(newDeposits)} */}
          <hr className="my-3" style={{width: "98%"}} />
        </div>
    </div>
  );
}

export default Deposits

import React, { useState, useEffect,useCallback, useMemo } from "react";
import _ from "lodash"
import { useParams } from "react-router-dom";
import moment from 'moment'
import * as MaterialComponents from '@mui/material';
import { removeChars, formatter } from "../../../../../../utlis/formatters";
import { CircularProgress } from "@material-ui/core";

import { useFetch, recordFrontEndError } from "../../../../../../utlis/api";
import { useOrder } from "../../../../../../redux/orders/order"

// import * as ProductCalculators from "../../../../../../utlis/productCalculations";

const DocumentFundsReceivedScheduleModal = ({ initializeDocumentModal, setInitializeDocumentModal, document_id, location, getDocuments, passedDocumentSlug, locations, setDocumentFocus }) => {

  const documentSlug = "tec-funds-received-schedule";

  let { id }        = useParams();
  const order       = useOrder();
  const axios       = useFetch();

  const previewEmbeded = React.useRef(null);

  var initialDocumentValues = useMemo(()=>{return initialDocumentValues={
    customer_name: undefined,
    admin_fee_sum: 0,
    gap_sum: 0,
    funding_reserve_sum: 0,
    applied_amount_sum: 0,
    refunded_amount_sum: 0,
    
    location_address: undefined, // Header Location Details / System Autofill
    location_phone: undefined, // Header Location Details / System Autofill
    location_email: undefined, // Header Location Details / System Autofill
    tec_footer_label: undefined, // Footer / System Autofill
    pg_no: undefined, // Footer / System Autofill
  }},[]);

  const [ document, setDocument ]                               = useState(undefined);
  const [ documentValues, setDocumentValues ]                   = useState(initialDocumentValues);
  const [ documentValuesPaginated, setDocumentValuesPaginated ] = useState([]);
  const [ documentUrl, setdocumentUrl ]                         = useState("");
  const [ selectedProducts, setSelectedProducts ]               = useState([]);
  // const [ selectedTradeins, setSelectedTradeins ]               = useState([]);
  const [ previewChanges, setPreviewChanges ]                   = useState(false);
  const [ unsavedChanges, setUnsavedChanges ]                   = useState(false);
  const [ invoiceProductOptions, setInvoiceProductOptions ]     = useState([])
  const [ invoiceTradeinOptions, setInvoiceTradeinOptions ]     = useState([])
  const [saveSuccess, setSaveSuccess]                           = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [autofillLoaded, setAutofillLoaded] = useState(false);
  const [updatingPreview, setUpdatingPreview] = useState(false);
  const [savingDocument, setSavingDocument] = useState(false);
  // const [rotatedSuccess, setRotatedSuccess] = useState(false);
  const [updateForm, setUpdateForm] = useState(0);

  // var formatter = new Intl.NumberFormat('en-US', {
  //   style: 'currency',
  //   currency: 'USD',
  // });

  const Autofill=useCallback(()=>{
    let newValues = documentValues;
    if(order){
      if(order.customer){
        newValues['customer_name'] = order.customer.name;
        if(order.customer.dba){
          newValues['customer_name'] = order.customer.name+' dba '+order.customer.dba;
        }
        // newValues['date'] =  moment.utc().format("YYYY-MM-DD");    
        if(order && order.documentationDate && documentValues.date === undefined){
          documentValues['date'] = moment.utc(order.documentationDate).format("YYYY-MM-DD");
        }
      
        if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          newValues['co_signer'] = cosigner.name;
          newValues['date_2'] =  moment.utc().format("YYYY-MM-DD");
        }
      }
    }
    setDocumentValues(newValues);
    setAutofillLoaded(true);
  },[ documentValues, order ])

  function reloadCustomerValues(){
    let newValues = documentValues;
    if(order){
      if(order.customer){
          newValues['customer_name'] = order.customer.name;
          if(order.customer.dba){
            newValues['customer_name'] = order.customer.name+' dba '+order.customer.dba;
          }
          
          if(order && order.documentationDate){
            newValues['date'] = moment.utc(order.documentationDate).format("YYYY-MM-DD");
          }
                
        if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          newValues['co_signer'] = cosigner.name;
          newValues['date_2'] =  moment.utc().format("YYYY-MM-DD");
        }
      }
    }
    setDocumentValues(newValues);
    setPreviewChanges(true);
    setUpdateForm((Number(updateForm) + 1));
  }


  const loadDocument=useCallback( async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
          setDataLoaded(false);
          setInvoiceProductOptions([]);
          setInvoiceTradeinOptions([]);
        }
      } catch (err) {
        recordFrontEndError('DocumentFundsReceivedScheduleModal.jsx', 'Error loading document: ', )
      }
      
    }
  },[axios,id])


  const streamDocument=useCallback(async ()=>{
    try {
      let response = await axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
      const file = new Blob(
        [response.data], 
        {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
      setdocumentUrl(fileURL)
    } catch (err) {
      recordFrontEndError('DocumentFundsReceivedScheduleModal.jsx', 'Error streaming document: ' + err)
    }
  },[axios,document])

  const loadData=useCallback(()=>{
    if(document){
      if(document.payload){
        var documentPayload = JSON.parse(document.payload);
        if(documentPayload && Object.keys(documentPayload).length > 0){
          setDocumentValues(documentPayload);
        }
      }
      streamDocument();
    }

    setDataLoaded(true);
  },[ document, streamDocument ])




  //Each time the documentId we receive from our parent changes,
  //load the document...
  useEffect(() => {
    if(document_id && passedDocumentSlug && (passedDocumentSlug === "tec-funds-received-schedule") && ( !document || document === undefined || (document && document.id !== document_id))){
      setdocumentUrl("");
      setInvoiceProductOptions([]);
      setInvoiceTradeinOptions([]);
      setDocumentValues(initialDocumentValues);
      loadDocument(document_id);
    }else if(passedDocumentSlug && passedDocumentSlug === documentSlug && initializeDocumentModal){
      setdocumentUrl("");
      setInvoiceProductOptions([]);
      setInvoiceTradeinOptions([]);
      setInitializeDocumentModal(false);
      Autofill();
    }

  }, [ order, document_id, loadDocument, document, Autofill, autofillLoaded, documentSlug, initialDocumentValues, initializeDocumentModal, setInitializeDocumentModal, passedDocumentSlug ])

  //Effect to load the data from the document each time the document changes.
  useEffect(() => {
    if ( document && !dataLoaded ){
      loadData();

      if(document && document.itemIds){
        var itemIds = document.itemIds.split(',');
        var sproducts = [];
        itemIds.forEach(itemId => {
          var foundProduct = order.products.find((product) => Number(product.id) === Number(itemId));
          if(foundProduct && foundProduct.stockNumber){
            let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
            let obj = {id: foundProduct.id, name: str}
            sproducts.push(obj);
          }
        })
        setSelectedProducts(sproducts);
      }

      // if(document && document.tradeinIds){
      //   var tradeinIds = document.tradeinIds.split(',');
      //   var selected_tradeins = [];
      //   tradeinIds.forEach(tradeId => {
      //     var foundTrade = order.tradeins.find((trade) => Number(trade.id) === Number(tradeId));
      //     let str = `${foundTrade.vin.toString().toUpperCase()}`;
      //     let obj = {id: foundTrade.id, name: str}
      //     selected_tradeins.push(obj);
      //   })
      //   setSelectedTradeins(selected_tradeins);
      // }
    }
  }, [ document, loadData, order, dataLoaded ])

  //Effect to calculate the invoiceProductOptions
  //based on products in the order.
  useEffect(() => {
    if ( _.isEmpty( order.products ) === false && invoiceProductOptions && invoiceProductOptions.length === 0 ) {

      const newinvoiceProductOptions =
        order.products.map(el => {
          if ( !el.invoiceId ) {
            return { id: el.id, name: (el.stockNumber.toString().toUpperCase())};
          }
          return el;
        })

      setInvoiceProductOptions(newinvoiceProductOptions)
    }


    if( _.isEmpty( order.tradeins ) === false && invoiceTradeinOptions && invoiceTradeinOptions.length === 0) {


      const newinvoiceTradeinOptions = order.tradeins.map(el => {
        if(! el.invoiceId ) {
          return { id: el.id, name: (el.vin.toString().toUpperCase()) };
        }
        return el;
      })

      setInvoiceTradeinOptions(newinvoiceTradeinOptions)
    }
    
  }, [ order, invoiceProductOptions, invoiceTradeinOptions ])



  const onSaveClick = event => {
    event.preventDefault();
    setSavingDocument(true);
    updateDocument(document_id);
  }

  const onCustomerNameChange = (newValue) => {
    let updatedObject = JSON.parse(JSON.stringify(documentValues));
    updatedObject.customer_name = newValue;
    setDocumentValues(updatedObject);
    setSaveSuccess(false);
  }

  const onCoSignerChange = (newValue) => {
    let updatedObject = JSON.parse(JSON.stringify(documentValues));
    updatedObject.co_signer = newValue;
    setDocumentValues(updatedObject);
    setSaveSuccess(false);
    // setDocumentValues({...documentValues,['co_signer']:newValue});
  }

  const onDateChange = (newValue) => {
    let updatedObject = JSON.parse(JSON.stringify(documentValues));
    updatedObject.date = newValue;
    setDocumentValues(updatedObject);
    setSaveSuccess(false);
    // setDocumentValues({...documentValues,['date']:newValue});
  }

  const onDate2Change = (newValue) => {
    let updatedObject = JSON.parse(JSON.stringify(documentValues));
    updatedObject.date_2 = newValue;
    setDocumentValues(updatedObject);
    setSaveSuccess(false);
    // setDocumentValues({...documentValues,['date_2']:newValue});
  }

  const onTotalInvoicedAmountChange = (newValue) => {
    let updatedObject = JSON.parse(JSON.stringify(documentValues));
    updatedObject.total_invoiced_amount = newValue;
    setDocumentValues(updatedObject);
    setSaveSuccess(false);
  }
  
  async function getDocumentTemplateBySlug(slug){
    return await axios.get(`/document-template/${slug}/by-slug`);
  }
  
  // come back to refactor nested axios calls
  async function updateDocument(documentId){
    if ( documentId == null || documentId === undefined ){
      
      getDocumentTemplateBySlug(documentSlug).then(response => {
        let invoiceTemplate = response?.data;
        if(invoiceTemplate && invoiceTemplate.id){
          let documentDraftData = {
            DocumentStatusId: 5, //Draft
            DocumentTemplateId: invoiceTemplate.id, //TODO Improve: Change out with found template tec-sales-order-schedule-a                                                                                
            OrderId: id,
            Payload: JSON.stringify({}),
          }
          
       
          axios.post(`/document`, documentDraftData).then((res) => {
            setDocument(res?.data);
            //Rotate Document?

            documentValuesPaginated.forEach(page => {
              if(page.date){
                page['date'] =  moment.utc(page.date).format("MM/DD/YYYY");
              }
            
              if(page.co_signer && page.co_signer !== ""){
                if(page.date_2){
                  page['date_2'] =  moment.utc(page.date_2).format("MM/DD/YYYY");
                }
              }else{
                page['date_2'] =  "";
              }
            })
            
            clearLines();
            const payloadString = JSON.stringify(documentValues);
            var docCopy = JSON.parse(JSON.stringify(res?.data));
            docCopy.payload = payloadString;
            delete docCopy.documentStatus;
            
            if(selectedProducts && selectedProducts.length > 0){
              var itemIds = [];
              selectedProducts.forEach(product => {
                itemIds.push(product.id);
              })
              // var itemIds = selectedProducts.filter(({ id }) => selectedProducts.includes(id));
              docCopy.itemIds = `${itemIds}`;
            }

            // if(selectedTradeins && selectedTradeins.length > 0){
            //   var tradeIds = [];
            //   selectedTradeins.forEach(trade => {
            //     tradeIds.push(trade.id);
            //   })
            //   docCopy.tradeinIds = `${tradeIds}`;
            // }
  
            axios.put(`/document/${res?.data.id}/prepare`, docCopy).then(res => {
              axios.post(`/document/${docCopy.id}/generate-schedule`, documentValuesPaginated).then(resp => {
                setUnsavedChanges(false);
                setSaveSuccess(true);
                setSavingDocument(false);

                streamDocument();
              
                if(documentValues){
                  if(documentValues.date === undefined){
                    documentValues['date'] =  moment.utc().format("YYYY-MM-DD");
                  }else{
                    documentValues['date'] =  moment.utc(documentValues.date).format("YYYY-MM-DD");
                  }
                  
                  if(documentValues.co_signer && documentValues.co_signer !== ""){
                    if(documentValues.date_2 === undefined){
                      // documentValues['date_2'] =  moment.utc().format("YYYY-MM-DD");
                    }else{
                      documentValues['date_2'] =  moment.utc(documentValues.date_2).format("YYYY-MM-DD");
                    }
                  }
                }
            });
            }).catch(err => {
            });
          });
          
        }else{
          // Throw Error (Mark)
        }
      });



    }else{
      // Update Document Prepare & Generate PDF

      documentValuesPaginated.forEach(page => {
        if(page.date){
          page['date'] =  moment.utc(page.date).format("MM/DD/YYYY");
        }
        
        if(page.co_signer && page.co_signer !== ""){
          if(page.date_2){
            page['date_2'] =  moment.utc(page.date_2).format("MM/DD/YYYY");
          }
        }
      })

      // Update Document
      if(document){

        clearLines();
        const payloadString = JSON.stringify(documentValues);
        var docCopy = JSON.parse(JSON.stringify(document));
        docCopy.payload = payloadString;
        delete docCopy.documentStatus;
       
        if(selectedProducts && selectedProducts.length > 0){
          var itemIds = [];
          selectedProducts.forEach(product => {
            itemIds.push(product.id);
          })
          // var itemIds = selectedProducts.filter(({ id }) => selectedProducts.includes(id));
          docCopy.itemIds = `${itemIds}`;
        }

        // if(selectedTradeins && selectedTradeins.length > 0){
        //   var tradeIds = [];
        //   selectedTradeins.forEach(trade => {
        //     tradeIds.push(trade.id);
        //   })
        //   docCopy.tradeinIds = `${tradeIds}`;
        // }

        await axios.put(`/document/${document.id}/prepare`, docCopy).then(res => {
          axios.post(`/document/${document.id}/generate-schedule`, documentValuesPaginated).then(resp => {
            setSavingDocument(false);
            setUnsavedChanges(false);
            setUpdatingPreview(false);
            setSaveSuccess(true);

            streamDocument();

            if(documentValues){
              if(documentValues.date === undefined){
                documentValues['date'] =  moment.utc().format("YYYY-MM-DD");
              }else{
                documentValues['date'] =  moment.utc(documentValues.date).format("YYYY-MM-DD");
              }
              
              if(documentValues.co_signer && documentValues.co_signer !== ""){
                if(documentValues.date_2 === undefined){
                  // documentValues['date_2'] =  moment.utc().format("YYYY-MM-DD");
                }else{
                  documentValues['date_2'] =  moment.utc(documentValues.date_2).format("YYYY-MM-DD");
                }
              }
            }
          });
        }).catch(err => {
        });
      }
    }
  }

  const generateInvoice = async event => {
    event.preventDefault();
    setdocumentUrl('');

    setUpdatingPreview(true);

    function clearLinesLocal(){
      for (let i=1; i<=18; i++){
        documentValues[`date_${i}`] = "";
        documentValues[`status_${i}`] = "";
        documentValues[`payment_method_${i}`] = "";
        documentValues[`doc_number__${i}`] = "";
        documentValues[`admin_fee_${i}`] = "";
        documentValues[`gap_${i}`] = "";
        documentValues[`funding_reserve_${i}`] = "";
        documentValues[`applied_amount_${i}`] = "";
        documentValues[`refunded_amount_${i}`] = "";
      }
    }

    documentValues[`admin_fee_sum`] = 0;
    documentValues[`funding_reserve_sum`] = 0;
    documentValues[`gap_sum`] = 0;
    documentValues[`applied_amount_sum`] = 0;
    documentValues[`refunded_amount_sum`] = 0;


     // Get Location Address & Autofill Address
    if(location && location.address){
      documentValues['location_address'] = location.address+" "+location.city+" "+location.state+" "+location.zip;
    }
      
    if(location && location.phone){
      documentValues['location_phone'] = location.phone;
    }

    if(location && location.email){
      documentValues['location_email'] = location.email;
    }

    if(location && location.state){
      if(location.state === "CA"){
          documentValues['tec_footer_label'] = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
      }else{
          documentValues['tec_footer_label'] = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC";
      }
    }

    // let salesTaxRemainderCents = 0;
    // let adminFeeRemainderCents = 0;

    let currentWriteLine = 0;
    // let lastProductLine = 0;

    // let customer = (order && order.customer) ? order.customer : null;
    // let customerState = null;
    // if(customer && customer.deliveryAddress){
    //   customerState = customer.deliveryAddress.state;
    // }

    // let lastProduct = null;
    let documentPagesArray=[]
    selectedProducts.forEach((item, i) => {

      // currentWriteLine++;
      
      let selectedProduct = item;
      let product = (order.products.filter(item => {
        return item.id === selectedProduct.id
      }));
      if(product && product.length === 1) {
        product = product[0];
      };


      if(product && product.deposits && product.deposits.length > 0){

        product.deposits.forEach((deposit, d) => {
          currentWriteLine++;          
          documentValues[`type_${currentWriteLine}`] = "Deposit";
          documentValues[`date_received_${currentWriteLine}`] = moment.utc(deposit.dateReceived).format("MM/DD/YYYY");
          documentValues[`status_${currentWriteLine}`] = deposit.status;
          documentValues[`payment_method_${currentWriteLine}`] = deposit.paymentMethodObj.name;
          documentValues[`doc_number__${currentWriteLine}`] = deposit.documentNumber;
          documentValues[`applied_amount_${currentWriteLine}`] = formatter.format(deposit.appliedAmount);
          documentValues[`refunded_amount_${currentWriteLine}`] = formatter.format(deposit.refundAmount);
         
          documentValues[`admin_fee_${currentWriteLine}`] = formatter.format(0);
          documentValues[`gap_${currentWriteLine}`] = formatter.format(0);
          documentValues[`funding_reserve_${currentWriteLine}`]  = formatter.format(0);
         
          if(currentWriteLine === 18){
            documentPagesArray.push({...documentValues});
            clearLinesLocal();
            currentWriteLine = 0;
          }
        });

      }

    });


    if(order && order.downPayments && order.downPayments.length > 0){

      order.downPayments.forEach((downpayment, p) => {
        currentWriteLine++;
          
        documentValues[`type_${currentWriteLine}`] = "Down Payment";
        documentValues[`date_received_${currentWriteLine}`] = moment.utc(downpayment.dateReceived).format("MM/DD/YYYY");
        documentValues[`status_${currentWriteLine}`] = downpayment.status;
        documentValues[`payment_method_${currentWriteLine}`] = downpayment.paymentMethod.name;
        documentValues[`doc_number__${currentWriteLine}`] = downpayment.documentNumber;
        // documentValues[`amount_${currentWriteLine}`] = formatter.format(downpayment.amount);
        documentValues[`applied_amount_${currentWriteLine}`] = formatter.format(downpayment.amount);
        documentValues[`refunded_amount_${currentWriteLine}`] = formatter.format(0);

        documentValues[`admin_fee_${currentWriteLine}`] = formatter.format(0);
        documentValues[`gap_${currentWriteLine}`] = formatter.format(0);
        documentValues[`funding_reserve_${currentWriteLine}`]  = formatter.format(0);


        if(currentWriteLine === 18){
          documentPagesArray.push({...documentValues});
          clearLinesLocal();
          currentWriteLine = 0;
        }
      });

    }

    if(order && order.fundingPayments && order.fundingPayments.length > 0){

      order.fundingPayments.forEach((fundingPayment, f) => {
        currentWriteLine++;
          
        documentValues[`type_${currentWriteLine}`] = "Funding Payment";
        documentValues[`date_received_${currentWriteLine}`] = moment.utc(fundingPayment.dateReceived).format("MM/DD/YYYY");
        documentValues[`status_${currentWriteLine}`] = fundingPayment.status;
        documentValues[`payment_method_${currentWriteLine}`] = fundingPayment.paymentMethod.name;
        documentValues[`doc_number__${currentWriteLine}`] = fundingPayment.documentNumber;
        // documentValues[`amount_${currentWriteLine}`] = formatter.format(fundingPayment.amount);
        documentValues[`admin_fee_${currentWriteLine}`] = formatter.format(fundingPayment.adminFee);
        documentValues[`gap_${currentWriteLine}`] = formatter.format(fundingPayment.gapAmount);
        documentValues[`funding_reserve_${currentWriteLine}`] = formatter.format(fundingPayment.fundingReserveAmount);
        documentValues[`applied_amount_${currentWriteLine}`] = formatter.format(fundingPayment.amount);
        documentValues[`refunded_amount_${currentWriteLine}`] = formatter.format(0);
        if(currentWriteLine === 18){
          documentPagesArray.push({...documentValues});
          clearLinesLocal();
          currentWriteLine = 0;
        }
      });
      

    }

    documentPagesArray.push({...documentValues});


/////////////////////////// 

      // let productTaxableAmount = 0;
      // let productSalesTax = 0;
      // let roundedSalesTax = 0;
      // let nonVehicleSalesTax = 0;

      let lastPageIndex = (documentPagesArray.length - 1) >= 0 ? (documentPagesArray.length - 1) : 0;
      let totalRows = 18;

    // let unitPriceSum = 0;
    // let fetSum = 0;
    // let gapSum = 0;
    // let warrantySum = 0;

    // documentPagesArray.forEach(page => {
    //   for(let x = 1; x <= totalRows; x++){
    //     if(page[`unit_price_${x}`]){
    //       unitPriceSum += Number(removeChars(page[`unit_price_${x}`]));
    //     }

    //     if(page[`fet_${x}`]){
    //       fetSum += Number(removeChars(page[`fet_${x}`]));
    //     }

    //     if(page[`gap_${x}`]){
    //       gapSum += Number(removeChars(page[`gap_${x}`]));
    //     }

    //     if(page[`warranty_${x}`]){
    //       warrantySum += Number(removeChars(page[`warranty_${x}`]));
    //     }
    //   }
    // })

    // documentPagesArray.forEach(page => {
      // documentPagesArray[lastPageIndex].unit_price_sum = formatter.format(unitPriceSum);
      // documentPagesArray[lastPageIndex].fet_sum = formatter.format(fetSum);
      // documentPagesArray[lastPageIndex].gap_sum = formatter.format(gapSum);
      // documentPagesArray[lastPageIndex].warranty_sum = formatter.format(warrantySum);
    // });

    // if(customerState && customerState !== "OR"){
    //   let taxableSum = 0;
    //   let salesTaxSum = 0;

    //   documentPagesArray.forEach(page => {
    //     for(let x = 1; x <= totalRows; x++){
    //       if(page[`taxable_${x}`]){
    //         taxableSum += Number(removeChars(page[`taxable_${x}`]));
    //       }

    //       if(page[`sales_tax_${x}`]){
    //         salesTaxSum += Number(removeChars(page[`sales_tax_${x}`]));
    //       }
    //     }
    //   })

    //   // documentPagesArray.forEach(page => {
    //     documentPagesArray[lastPageIndex].taxable_sum = formatter.format(taxableSum);
    //     documentPagesArray[lastPageIndex].sales_tax_sum = formatter.format(Number(salesTaxSum));
    //   // })

    //   // Reconciles last line to adjust sales tax & total. - Deprecating.
    //   // if(salesTaxSum !== order.orderSalesTaxTotal && selectedProducts.length === order.products.length){
    //   //   let difference = Number(order.orderSalesTaxTotal) - Number(salesTaxSum);
        
    //   //   let currentLastLineSalesTax = removeChars(documentPagesArray[lastPageIndex][`sales_tax_${lastProductLine}`]);
    //   //   let currentLastLineTotalPrice = removeChars(documentPagesArray[lastPageIndex][`total_price_${lastProductLine}`]);
        
    //   //   let adjSalesTax = Number(currentLastLineSalesTax) + (difference);
    //   //   let adjTotalPrice = Number(currentLastLineTotalPrice) + (difference);
        
    //   //   documentPagesArray[lastPageIndex][`sales_tax_${lastProductLine}`] = formatter.format(adjSalesTax);
    //   //   documentPagesArray[lastPageIndex][`total_price_${lastProductLine}`] = formatter.format(adjTotalPrice);

    //   //   salesTaxSum = 0;

    //   //   documentPagesArray.forEach(page => {
    //   //     for(let x = 1; x <= totalRows; x++){
    //   //       if(page[`sales_tax_${x}`]){
    //   //         salesTaxSum += Number(removeChars(page[`sales_tax_${x}`]));
    //   //       }
    //   //     }
    //   //   })
  
    //   //   // documentPagesArray.forEach(page => {
    //   //     documentPagesArray[lastPageIndex].sales_tax_sum = formatter.format(Number(salesTaxSum));
    //   //   // })
  
    //   // }

    // }
    
    // if(customerState && customerState === "WA"){

    //   let nonVehicleSalesTaxSum = 0;
    //   documentPagesArray.forEach(page => {
    //     for(let x = 1; x <= totalRows; x++){
    //       if(page[`non_sales_tax_${x}`]){
    //         nonVehicleSalesTaxSum += Number(removeChars(page[`non_sales_tax_${x}`]));
    //       }
    //     }
    //   })

    //   // documentPagesArray.forEach(page => {
    //     documentPagesArray[lastPageIndex].non_sales_tax_sum = formatter.format(nonVehicleSalesTaxSum);
    //   // })
    // }

    // let amountSum = 0;
    let adminFeeSum = 0;
    let gapAmountSum = 0;
    let fundingReserveAmountSum = 0;
    let refundedAmountSum = 0;
    let appliedAmountSum = 0;
    documentPagesArray.forEach(page => {
      for(let x = 1; x <= totalRows; x++){
        // if(page[`amount_${x}`]){
        //   amountSum += Number(removeChars(page[`amount_${x}`]));
        // }

        if(page[`admin_fee_${x}`]){
          adminFeeSum += Number(removeChars(page[`admin_fee_${x}`]));
        }

        if(page[`gap_${x}`]){
          gapAmountSum += Number(removeChars(page[`gap_${x}`]));
        }

        if(page[`funding_reserve_${x}`]){
          fundingReserveAmountSum += Number(removeChars(page[`funding_reserve_${x}`]));
        }

        if(page[`refunded_amount_${x}`]){
          refundedAmountSum += Number(removeChars(page[`refunded_amount_${x}`]));
        }

        if(page[`applied_amount_${x}`]){
          appliedAmountSum += Number(removeChars(page[`applied_amount_${x}`]));
        }
      }
    });


    documentPagesArray[lastPageIndex][`admin_fee_sum`] = formatter.format(adminFeeSum);
    documentPagesArray[lastPageIndex][`gap_sum`] = formatter.format(gapAmountSum);
    documentPagesArray[lastPageIndex][`funding_reserve_sum`] = formatter.format(fundingReserveAmountSum);
    documentPagesArray[lastPageIndex][`refunded_amount_sum`] = formatter.format(refundedAmountSum);
    documentPagesArray[lastPageIndex][`applied_amount_sum`] = formatter.format(appliedAmountSum);


    if(documentValues.total_invoiced_amount && documentValues.total_invoiced_amount > 0){
      documentPagesArray[lastPageIndex]['remaining_balance'] = formatter.format(documentValues.total_invoiced_amount - appliedAmountSum);
      documentPagesArray[lastPageIndex]['total_invoiced_amount'] = formatter.format(documentValues.total_invoiced_amount);

    }

    documentPagesArray.forEach(page => {
      if(page.date){
        page['date'] =  moment.utc(page.date).format("MM/DD/YYYY");
      }
      
      if(page.co_signer && page.co_signer !== ""){
        if(page.date_2){
          page['date_2'] =  moment.utc(page.date_2).format("MM/DD/YYYY");
        }
      }
    });
    
      //TODO: Move API calls to utils/api
      setDocumentValuesPaginated(documentPagesArray);
          try {
            let response = await axios.post(`/order/${id}/preview-schedule-paginated/${documentSlug}`,
            documentPagesArray,
              { responseType: 'blob' }
            )
            setUpdatingPreview(false);
  
            const file = new Blob(
              [response.data], 
              {type: 'application/pdf'},
            );
            const fileURL = URL.createObjectURL(file);
            setdocumentUrl(fileURL);
            setPreviewChanges(false);
            setUnsavedChanges(true);
            setSaveSuccess(false);
  
            if(documentValues){
              if(documentValues.date === undefined){
                documentValues['date'] =  moment.utc().format("YYYY-MM-DD");
              }else{
                documentValues['date'] =  moment.utc(documentValues.date).format("YYYY-MM-DD");
              }
              
              if(documentValues.co_signer && documentValues.co_signer !== ""){
                if(documentValues.date_2 !== undefined){
                  documentValues['date_2'] =  moment.utc(documentValues.date_2).format("YYYY-MM-DD");
                }
              }
            }
          } catch (err) {
            recordFrontEndError('DocumentFundsReceivedScheduleModal.jsx', ' Error generating invoice: ' + err)
          }
      
          
  }

  function clearLines(){
    let newValues = {...documentValues};
    for (let i=1; i<=18; i++){
      newValues[`date_${i}`] = "";
      newValues[`status_${i}`] = "";
      newValues[`payment_method_${i}`] = "";
      newValues[`doc_number__${i}`] = "";
      newValues[`applied_amount_${i}`] = "";
      newValues[`admin_fee_${i}`] = "";
      newValues[`gap_${i}`] = "";
      newValues[`funding_reserve_${i}`] = "";
      newValues[`refunded_amount_${i}`] = "";
    }
    setDocumentValues(newValues);
    return newValues;
  }

  const onSelectedProductChange = (e, newValue) => {
    clearLines();
    setSelectedProducts(newValue);
    setPreviewChanges(true);
    setSaveSuccess(false);
    // generateInvoice(e);
  }

  const resetVals = (e) => {
    // e.preventDefault();
    setDocumentValuesPaginated([]);
    setInvoiceProductOptions([]);
    setInvoiceTradeinOptions([]);
    setDocument(undefined);
    setdocumentUrl("");
    setAutofillLoaded(false);
    setDataLoaded(false);
    setSelectedProducts([]);
    // setSelectedTradeins([]);
    setPreviewChanges(false);
    setUnsavedChanges(false);
    setDocumentValues(initialDocumentValues);
    getDocuments(true);
    setUpdatingPreview(false);
    // setAttemptingRotate(false);
    // setRotatedSuccess(false);
    setDocumentFocus(undefined);
    setSaveSuccess(false);
    setSavingDocument(false);
  }

  
  return (
    <>
      <div
        className="modal fade"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        {/* <div className="modal-dialog modal-dialog-centered modal-lg"> */}
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              { document ? (
                <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
                >
                  Edit Funds Received Schedule
                </h5>
              ):(
                <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
                >
                  Generate Funds Received Schedule
                </h5>
              )}
              <br />
              <button
                type="button"
                className="btn-close bg-white"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => resetVals(e)}
                disabled={savingDocument}
              ></button>
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
              <div className="container-fluid mt-0">
                <div className="row" style={{ height: '70vh'}}>
                  <div className="col-md-3 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}} key={updateForm}>
                    <div>
                      <button 
                      type="button"
                      className="btn btn-primary mx-auto"
                      disabled={savingDocument}
                      onClick={() => {
                        reloadCustomerValues();
                      }}>Reapply Customer Values</button>
                    </div>
                    <br />
                    <Autocomplete
                      disabled={savingDocument}
                      value={ selectedProducts ?? null}
                      multiple
                      id="tags-outlined"
                      onChange={(event, newValue) => {
                        setSaveSuccess(false);
                        onSelectedProductChange(event, newValue);
                      }}
                      options={(invoiceProductOptions ?? [])}
                      getOptionLabel={(option) => option.name}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Products"
                        />
                      )}
                    />

                    <div className="row">
                      <div className="col-md-12">
                        <label htmlFor="stock">Customer Name</label>
                        <input
                          disabled={savingDocument}
                          value={ ((documentValues && documentValues.customer_name) ?? '') }
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="customer_name"
                          onChange={(e) => {
                            onCustomerNameChange(e.target.value);
                          }}
                          onBlur={(e) => {
                            setPreviewChanges(true);}}
                          />
                      </div>
                      </div>
                      <div className="row">
                      <div className="col-md-12">
                        <label htmlFor="stock">Date</label>
                        <input
                          disabled={savingDocument}
                          value={ ((documentValues && documentValues.date) ?? '') }
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date"
                          onChange={(e, newValue) => {
                            onDateChange(e.target.value);
                          }}                        
                          onBlur={(e) => {
                            setPreviewChanges(true);}}
                          />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <label htmlFor="stock">Co-Signer Name</label>
                        <input
                          disabled={savingDocument}
                          value={ ((documentValues && documentValues.co_signer) ?? '') }
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="co_signer"
                          onChange={(e, newValue) => {
                            onCoSignerChange(e.target.value);
                          }}
                          onBlur={(e) => {
                            setPreviewChanges(true);}}
                          />
                      </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <label htmlFor="stock">Date</label>
                          <input
                            disabled={savingDocument}
                            value={ ((documentValues && documentValues.date_2) ?? '') }
                            type="date"
                            className="form-control rounded-pill mt-1"
                            name="date_2"
                            onChange={(e, newValue) => {
                              onDate2Change(e.target.value);
                            }}                        
                            onBlur={(e) => {
                              setPreviewChanges(true);}}
                            />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <label htmlFor="stock">Total Invoiced Amount</label>
                          <input
                            disabled={savingDocument}
                            value={ ((documentValues && documentValues.total_invoiced_amount) ?? '') }
                            type="text"
                            className="form-control rounded-pill mt-1"
                            name="total_invoiced_amount"
                            onChange={(e, newValue) => {
                              onTotalInvoicedAmountChange(e.target.value);
                            }}                        
                            onBlur={(e) => {
                              setPreviewChanges(true);}}
                            />
                        </div>
                      </div>
                  </div>
                  <div className="col-md-9" style={{ overflowY: 'scroll'}}>
                    <h5>Invoice Preview               
                      { previewChanges && selectedProducts.length > 0 ? (
                      <button className="btn btn-small btn-primary mx-3" 
                          type="button"
                          onClick={generateInvoice}
                          disabled={updatingPreview}
                          // data-bs-dismiss="modal"
                        >
                          Update Preview
                        </button>
                       ):null}
                    </h5>
                    { documentUrl !== "" && documentUrl !== undefined && documentUrl && !savingDocument ? ( 
                      <embed ref={previewEmbeded} id="previewEmbeded" width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                    ): updatingPreview ? (
                      <div class="mx-auto mt-5 text-center" style={{ width: '100%' }}>
                        <br />
                        <br />
                        <br />
                        <label>Loading Preview</label>
                        <br />
                        <br />
                        <div class="mx-auto text-center">
                          <CircularProgress color="#0d6efd" style={{ placeSelf: "center" }} />
                        </div>
                      </div>
                    ): savingDocument ? (
                      <div class="mx-auto mt-5 text-center" style={{ width: '100%' }}>
                        <br />
                        <br />
                        <br />
                        <label>Saving Document</label>
                        <br />
                        <br />
                        <div class="mx-auto text-center">
                          <CircularProgress color="#0d6efd" style={{ placeSelf: "center" }} />
                        </div>
                      </div>
                    ):null}
                  </div>
                </div>
              </div>
            </div>
            { unsavedChanges ? (
            <div className="text-center m-3 mb-4">
              <button className="btn btn-danger mx-3" 
                type="button"
                // onClick={generateInvoice}
                data-bs-dismiss="modal"
                disabled={savingDocument}
                onClick={e => {
                  resetVals();
                }}
              >
                Cancel
              </button>
              {saveSuccess ? (
              <button className="btn btn-success mx-3" >
                Saved Invoice
              </button>
              ) : (
              <button className="btn btn-primary mx-3" 
                type="button"
                onClick={e => onSaveClick(e)}
                disabled={savingDocument}
                // data-bs-dismiss="modal"
              >
                Save
              </button>
              ) }
            </div>
            ): (
              <div className="text-center m-3 mb-4">
              <button className="btn btn-primary mx-3" 
                type="button"
                // onClick={generateInvoice}
                data-bs-dismiss="modal"
                onClick={e => {
                  resetVals();
                }}
              >
                Close
              </button>
            </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const {
  Autocomplete,
  TextField,
} = MaterialComponents

export default DocumentFundsReceivedScheduleModal
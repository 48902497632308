import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { formatter, formatPhoneNumber } from "../../../../../../../utlis/formatters";
import { useOrder } from "../../../../../../../redux/orders/order"
import FASTFormElement from "../FASTFormElement"
import  { processValuesChange } from "../documentHelpers"
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { IRS8300GlobalAutofill, preProcessPayload } from "../DocumentAutofill/IRS8300"


const IRS8300 = ({document_id, location, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "irs-8300";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  // const dispatch = useDispatch();

  // const stateAbbrev = States.map(state => {
  //   return state.abbreviation;
  // });

  // const removeFormatting = e =>{
  //   const {name,value}=e.target;
  //   setDocumentValues({...documentValues,[name]:removeChars(value)});
  // }

  const order = useOrder();

  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);

  // Data Structure & Init //
  
  var initialDocumentStructure = {}
  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  
  // const documentTitle = "WA - Wholesale";
  const dateFields = useMemo(()=>{return["date", "dob", "dob_2", "dob_3","date_received","date_of_sig"]},[]);
  const moneyFields = useMemo(()=>{return["sale_price"]},[]);
  const phoneFields = useMemo(()=>{return[]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "model"]},[]);
  const checkboxes = useMemo(()=>{return[
    "amend_report_selected",
    "suspicious_transaction_selected",
    "multi_people_selected",
    "part_3_15_selected",
    "part_3_30_selected",
    "part_33_a_selected",
    "part_33_b_selected",
    "part_33_c_selected",
    "part_33_d_selected",
    "part_33_e_selected",
    "part_33_f_selected",
    "part_33_g_selected",
    "part_33_h_selected",
    "part_33_i_selected",
    "part_33_j_selected",
  ]},[]);
  // const radios = useMemo(()=>{return[{
  //                 sale_of_toggle: [
  //                   {label: "Vehicle", value: "sold_to_selected"}, 
  //                   {label: "Vessel", value: "bought_from_selected"},
  //                   {label: "None", value: "clear"}
  //                 ],
  //                 gift_toggle: [
  //                   {label: "Yes", value: "sold_to_selected"}, 
  //                   {label: "No", value: "bought_from_selected"},
  //                   {label: "None", value: "clear"}
  //                 ],
  //             }]},[]);

// const handleDocumentValuesChange = e =>{
//   const {name, value, checked}=e.target;

//   if(moneyFields.indexOf(name) >= 0){
//     // To eliminate text being added
//     setDocumentValues({...documentValues,[name]:removeChars(value)});
//   }else if(phoneFields.indexOf(name) >= 0){
//     setDocumentValues({...documentValues,[name]:removeChars(value)});
//   }else if(upperCaseFields.indexOf(name) >= 0){
//     const savedPos = e.target.selectionStart;
//     setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
//     setTimeout(() => {
//       e.target.setSelectionRange(savedPos, savedPos);
//     }, 0)
//   }else if(checkboxes.indexOf(name) >= 0){
//     setDocumentValues({...documentValues,[name]:(checked)});
//   }else{
//     setDocumentValues({...documentValues,[name]:value});
//   }
//   setHasUnsavedChanges(true);
//   setSaveSuccess(false);
// }
  
  const handleDocumentValuesChange = e =>{
    // Primary Form Handler
    const {name} = e.target;
    const savedPos = e.target.selectionStart;
    
    setDocumentValues({...documentValues,[name]:processValuesChange(e.target, moneyFields, phoneFields, upperCaseFields, checkboxes)});
      
    if(upperCaseFields.indexOf(name) >= 0){
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }
  
  setHasUnsavedChanges(true);
  setSaveSuccess(false);
}


  // const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('IRS8300.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])

  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      // setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, loadDocument ])
  const [assignedProduct, setAssignedProduct] = useState(undefined);
  const [assignedTrade, setAssignedTrade] = useState(undefined);

  // const [productVal, setproductVal] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  // const [tradeinVal, setTradeinVal] = useState(undefined);
  // const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  // const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState(undefined);
  // const [addressOptions, setAddressOptions] = useState(undefined);
  // const [inputAddressVal, setinputAddressVal] = useState(undefined);

  // const [locationVal, setLocationVal] = useState(undefined);
  const [selectedLocation, setselectedLocation] = useState(undefined);
  // const [inputLocationVal, setinputLocationVal] = useState(undefined);
  const [locationOptions, setLocationOptions] = useState(undefined);
  const [locationToggleAutoFill, setLocationToggleAutoFill] = useState(false);

// Dropdowns //

if(locations && locations.length > 0 && locationOptions === undefined){
  let getLocationOptions = [];
  locations.map(location => {
    let str = `${location.description}`;
    let obj = {locationCode: location.locationCode, description: str}
    getLocationOptions.push(obj);
    return null;
  })
  setLocationOptions(getLocationOptions);
}

 // Autofill //

const GlobalAutofill = useCallback(async () => {
  let newValues = IRS8300GlobalAutofill(documentValues, order, null, null, null, locations);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

},[documentValues, locations, order])

const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });

    if(document){
      var docCopy = JSON.parse(JSON.stringify(document));
      docCopy.payload =  JSON.parse(JSON.stringify(payload));
      docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields, dateFields);
      docCopy.payload =  JSON.stringify(docCopy.payload);
      delete docCopy.documentStatus;

      try {
        let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
          const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
          setdocumentUrl(fileURL)
      } catch (err) {
        recordFrontEndError('IRS8300.jsx', 'Error previewing document payload: ' + err)
      }
    }
},[axios, checkboxes, dateFields, document, moneyFields, phoneFields, upperCaseFields])


// const AutofillProductDetails = useCallback(async (selectedProduct) => {
//   var newValues = documentValues;
//   // newValues.year = (selectedProduct.year).trim();
//   newValues.model = (selectedProduct.model).trim().toUpperCase();
//   newValues.make = (selectedProduct.make).trim();
//   newValues.year = (selectedProduct.year);
//   newValues.vin = (selectedProduct.vin.toUpperCase());
//   newValues.sale_price = formatter.format(selectedProduct.price);

//   setDocumentValues(newValues);
//   // setProductToggleAutoFill(false);
//   if(document){
//     previewPayloadDocument(documentValues);
//   }
//   // setAddressOptions(updateAddressOptions(selectedProduct, order));
//   setHasUnsavedChanges(true);
//   setSaveSuccess(false);
// },[ document, documentValues, previewPayloadDocument ]);

// const AutofillTradeDetails = useCallback(async (selectedTradein) => {
//   var newValues = documentValues;
//   // newValues.year = (selectedProduct.year).trim();
//   newValues.make = (selectedTradein.make).trim();
//   newValues.model = (selectedTradein.year)
//   newValues.identification_number = (selectedTradein.vin.toUpperCase());

//   newValues.copy_make = (selectedTradein.make).trim();
//   newValues.copy_model = (selectedTradein.year)
//   newValues.copy_identification_number = (selectedTradein.vin.toUpperCase());
  

//   setDocumentValues(newValues);
//   // setProductToggleAutoFill(false);
//   if(document){
//     previewPayloadDocument(documentValues);
//   }
//   setHasUnsavedChanges(true);
//   setSaveSuccess(false);
// },[ document, documentValues, previewPayloadDocument ]);

  // Document BREAD + Preview // TODO: Move to Redux
  useEffect(() => {
    if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){
  
      existingPayload = true;
  
      // Format dateFields for the form
      dateFields?.forEach(datefield => {
        if(documentPayload[datefield]){
          documentPayload[datefield] = moment.utc(documentPayload[datefield]).format("YYYY-MM-DD");
        }
      });
      setDocumentValues(documentPayload);

      // Loading Use Effect
      if(document.additionalData){
        const methodMap = { 
          selectedAddress: setSelectedAddress, 
          selectedLocation: setselectedLocation, 
        };

        let parsedAdditionalData = JSON.parse(document.additionalData);
        Object.keys(parsedAdditionalData).forEach(function(key) {
          if(methodMap[key]){
            if(key === "selectedLocation"){
              // setLocationVal(parsedAdditionalData[key]);
              if(parsedAdditionalData[key].locationCode){
                setselectedLocation(locations.find((location) => location.locationCode === parsedAdditionalData[key].locationCode));
              }
            }else{
              methodMap[key](parsedAdditionalData[key]);
            }
          }
        });
      }
    }

    var foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }
    
    // setAddressOptions(getOrderLevelAddresses(order));
    if(foundAssignedProduct){
        setAssignedProduct(foundAssignedProduct);
        // setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
    }
    
    var foundAssignedTradein = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'tradein'){
      foundAssignedTradein = order.tradeins.find((trade) => trade.id === document.assignedObjectId);
    }
    
    if(foundAssignedTradein){
      setAssignedTrade(foundAssignedTradein);
    }
    
    
    if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        // if(foundAssignedProduct){
        //   AutofillProductDetails(foundAssignedProduct)
        // }
        // if(foundAssignedTradein){
        //   AutofillTradeDetails(foundAssignedTradein)
        // }
      }else{
        // if(document && document.itemIds){
        //   var itemIds = document.itemIds.split(',');
        //   var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
        //   let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
        //   let obj = {id: foundProduct.id, name: str}
        //   setproductVal(obj);
        //   if(foundProduct){
        //     setAddressOptions(updateAddressOptions(foundProduct, order));
        //   }
        //   streamDocument();
        // }
        
      }
      
      setDataLoaded(true);
    }
  },[ locations, documentValues, document, dataLoaded, order, GlobalAutofill, previewPayloadDocument, dateFields ])


  
    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();

      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields, dateFields);
    
  
      // const payload = JSON.parse(JSON.stringify(documentValues));
      // var docCopy = document;
      // docCopy.payload = payload;
      // docCopy.payload = preProcessPayload(docCopy.payload);
      // docCopy.payload =  JSON.stringify(docCopy.payload);
      // delete docCopy.documentStatus;
      
      // if(selectedProduct && selectedProduct.id){
      //   docCopy.itemIds = `${selectedProduct.id}`;
      // }
      //Save Dropdown Selections
      let dropdownData = {};
      if(selectedAddress){
        dropdownData.selectedAddress = {
          id: selectedAddress.id,
          name: selectedAddress.name
        };
      }
      if(selectedLocation){
        dropdownData.selectedLocation = {
          locationCode: selectedLocation.locationCode,
          description: selectedLocation.description
        };
      }

      let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, additionalData, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });

      // docCopy.additionalData = JSON.stringify(dropdownData);

  
      // //TODO: Add TradeId's to save.
      // try {
      //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
      //   if (res) {
      //     await axios.post(`/document/${document.id}/generate`)
      //     getDocuments();
      //     setSaveSuccess(true);
      //     setHasUnsavedChanges(false);
      //   }
      // } catch (err) {
      //   recordFrontEndError('IRS8300.jsx', 'Error saving document: ' + err)
      // }
    }

// Component Helpers //
// useEffect(() => {
  //Autofill Product Details After Selection

// },[ selectedProduct, productToggleAutoFill ])

// useEffect(() => {
//   //Autofill Tradein Details After Selection
//   if(selectedTradein !== undefined && tradeinToggleAutoFill){
//     var newValues = documentValues;
//     newValues.trade_year = (selectedTradein.year);
//     newValues.trade_make = (selectedTradein.make);
//     newValues.trade_model = (selectedTradein.model);
//     newValues.trade_body_type = (selectedTradein.seriesBodyType);
//     newValues.trade_serial_number = (selectedTradein.serialNumber);
//     newValues.balance_owner = (order.customer.name); //Need specific field for TRADE IN OWNER?
//     newValues.balance_owner_address = (order.customer.deliveryAddress.addressLine1);
//     newValues.amount = (selectedTradein.balanceOwed);
//     newValues.trade_allowance = (selectedTradein.tradeAllowanceAtTerms);
//     newValues.balance_owed = (selectedTradein.balanceOwed);
//     newValues.cash_refund = (selectedTradein.cashRefundToCustomer);
//     newValues.net_trade_allowance = (selectedTradein.finalTradeAllowance);
//     // newValues.cash_down_payment = (selectedTradein);

//     setDocumentValues(newValues);
//     setTradeinToggleAutoFill(false);
//     if(document){
//       previewPayloadDocument(documentValues);
//     }
//   }
// },[ selectedTradein, tradeinToggleAutoFill ])

useEffect(() => {
  //Autofill Tradein Details After Selection
  if(selectedLocation !== undefined && locationToggleAutoFill){
    var newValues = documentValues;
    newValues.seller_address = selectedLocation.address;
    newValues.seller_city = selectedLocation.city;
    newValues.seller_state = selectedLocation.state;
    newValues.seller_zip = selectedLocation.zip;
    newValues.seller_phone = formatPhoneNumber(selectedLocation.phone);

    // newValues.seller_id = "31422";

    // if(selectedLocation.state === "CA"){
    //   newValues.seller_name_signature = "TEC OF CALIFORNIA, INC";
    //   newValues.copy_seller_name_signature = "TEC OF CALIFORNIA, INC";
    // }else{
    //   newValues.seller_name_signature = "TEC EQUIPMENT, INC.";
    //   newValues.copy_seller_name_signature = "TEC EQUIPMENT, INC.";
    // }

    setDocumentValues(newValues);
    setLocationToggleAutoFill(false);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
},[ selectedLocation, locationToggleAutoFill, document, documentValues, previewPayloadDocument ])

//Autofill Address Details After Selection
// function AutoFillAddress(addressSelected, selectionInstance){
//   var newValues = documentValues;
//   if(selectionInstance){
//     if(selectionInstance === "buyer-address"){
//       newValues.buyer_address = addressSelected.address;
//       newValues.buyer_city = addressSelected.city;
//       newValues.buyer_state = addressSelected.state;
//       newValues.buyer_zip = addressSelected.zip;
//       if(order && order.customer){
//         newValues.buyer_print_name = order.customer.name;
//       }
//     }
//   }
//   setDocumentValues(newValues);
//   if(document){
//     previewPayloadDocument(documentValues);
//   }
//   setHasUnsavedChanges(true);
//   setSaveSuccess(false);
// }

const onCloseClick = e => {
  e.preventDefault()
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setdocumentUrl("");
  setDocument(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
  // setDocumentLoaded(false);
  setDataLoaded(false);
  setAssignedProduct(undefined);
  setAssignedTrade(undefined);
  setSelectedAddress(undefined);
  // setAddressOptions(undefined);
  // setinputAddressVal(undefined);
  // setLocationVal(undefined);
  setselectedLocation(undefined);
  // setinputLocationVal(undefined);
  setLocationOptions(undefined);
  setLocationToggleAutoFill(false);
  setDocumentFocus(undefined);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

  return (
    <>
      <div
        className="modal fade"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                IRS 8300
              </h5>
              <br />
              { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1 pt-1 text-white">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                  </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}
                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                  
                <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                    GlobalAutofill();
                    previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                </div>

                <br />

                { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Product Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                        </div>
                    </div>
                ):null}

                { assignedTrade ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Trade In Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                          <h5>VIN: { assignedTrade.vin.toUpperCase() }</h5>
                          <h5>YMM: { assignedTrade.year+" "+assignedTrade.make+" "+assignedTrade.model.toUpperCase() }</h5>
                        </div>
                    </div>
                ):null}

                  <div className="row">
                    <h5>Equipment</h5>
                  </div>

{/* /////// */}

  <div className="d-flex justify-content-evenly">
    <div className="p-2 col-md-6">
      <FASTFormElement 
          type="Checkbox" 
          property="amend_report_selected" 
          value={documentValues.amend_report_selected} 
          label="a. Amends prior report"
          changeValueFunction={handleDocumentValuesChange}
          onBlurFunction={updatePreview}
          />
    </div>
    <div className="p-2 col-md-6">
      <FASTFormElement 
          type="Checkbox" 
          property="suspicious_transaction_selected" 
          value={documentValues.suspicious_transaction_selected} 
          label="b. Suspicious transaction"
          changeValueFunction={handleDocumentValuesChange}
          onBlurFunction={updatePreview}
          />
    </div>
  </div> 

  <h3>Part 1</h3>

  <div className="d-flex justify-content-evenly">

    <div className="p-2 col-md-12">
      <FASTFormElement 
          type="Checkbox" 
          property="multi_people_selected" 
          value={documentValues.multi_people_selected} 
          label="More than one individual is involved"
          changeValueFunction={handleDocumentValuesChange}
          onBlurFunction={updatePreview}
          />
    </div>

  </div>  

  <div className="d-flex justify-content-evenly">

    <div className="p-2 col-md-3">
      <label htmlFor="stock">Last Name</label>
      <input
        type="text"
        className="form-control rounded-pill mt-1"
        name="last_name"
        onChange={handleDocumentValuesChange}
        onBlur={(e) => updatePreview(e)}
        value={ documentValues.last_name ?? '' }
        />
    </div>
    <div className="p-2 col-md-3">
      <label htmlFor="stock">First Name</label>
      <input
        type="text"
        className="form-control rounded-pill mt-1"
        name="first_name"
        onChange={handleDocumentValuesChange}
        onBlur={(e) => updatePreview(e)}
        value={ documentValues.first_name ?? '' }
        />
    </div>
   <div className="p-2 col-md-3">
      <label htmlFor="stock">M.I.</label>
      <input
        type="text"
        className="form-control rounded-pill mt-1"
        name="mi"
        onChange={handleDocumentValuesChange}
        onBlur={(e) => updatePreview(e)}
        value={ documentValues.mi ?? '' }
        />
    </div>
    <div className="p-2 col-md-3">
      <label htmlFor="stock">Taxpayer ID</label>
      <input
        type="text"
        className="form-control rounded-pill mt-1"
        name="tax_id_number"
        onChange={handleDocumentValuesChange}
        onBlur={(e) => updatePreview(e)}
        value={ documentValues.tax_id_number ?? '' }
        />
    </div>

  </div>

  <div className="d-flex justify-content-evenly">

    <div className="p-2 col-md-6">
      <label htmlFor="stock">Address</label>
      <input
        type="text"
        className="form-control rounded-pill mt-1"
        name="address"
        onChange={handleDocumentValuesChange}
        onBlur={(e) => updatePreview(e)}
        value={ documentValues.address ?? '' }
        />
    </div>

    <div className="p-2 col-md-6">
      <label htmlFor="stock">Date of birth</label>
      <input
        type="date"
        className="form-control rounded-pill mt-1"
        name="dob"
        onChange={handleDocumentValuesChange}
        onBlur={(e) => updatePreview(e)}
        value={ documentValues.dob ?? '' }
        />
    </div>

  </div>

  <div className="d-flex justify-content-evenly">

    <div className="p-2 col-md-4">
      <label htmlFor="stock">City</label>
      <input
        type="text"
        className="form-control rounded-pill mt-1"
        name="city"
        onChange={handleDocumentValuesChange}
        onBlur={(e) => updatePreview(e)}
        value={ documentValues.city ?? '' }
        />
    </div>
   <div className="p-2 col-md-4">
      <label htmlFor="stock">State</label>
      <input
        type="text"
        className="form-control rounded-pill mt-1"
        name="state"
        onChange={handleDocumentValuesChange}
        onBlur={(e) => updatePreview(e)}
        value={ documentValues.state ?? '' }
        />
    </div>
    <div className="p-2 col-md-4">
      <label htmlFor="stock">ZIP code</label>
      <input
        type="text"
        className="form-control rounded-pill mt-1"
        name="zip"
        onChange={handleDocumentValuesChange}
        onBlur={(e) => updatePreview(e)}
        value={ documentValues.zip ?? '' }
        />
    </div>
    
  </div>


  <div className="d-flex justify-content-evenly">

    <div className="p-2 col-md-6">
      <label htmlFor="stock">Country (If not U.S.)</label>
      <input
        type="text"
        className="form-control rounded-pill mt-1"
        name="country"
        onChange={handleDocumentValuesChange}
        onBlur={(e) => updatePreview(e)}
        value={ documentValues.country ?? '' }
        />
    </div>
   <div className="p-2 col-md-6">
      <label htmlFor="stock">Occupation, profession or business</label>
      <input
        type="text"
        className="form-control rounded-pill mt-1"
        name="occ_pro_bus"
        onChange={handleDocumentValuesChange}
        onBlur={(e) => updatePreview(e)}
        value={ documentValues.occ_pro_bus ?? '' }
        />
    </div>

  </div>

  <div className="d-flex justify-content-evenly">

    <div className="p-2 col-md-4">
      <label htmlFor="stock">Describe ID</label>
      <input
        type="text"
        className="form-control rounded-pill mt-1"
        name="describe_id"
        onChange={handleDocumentValuesChange}
        onBlur={(e) => updatePreview(e)}
        value={ documentValues.describe_id ?? '' }
        />
    </div>
   <div className="p-2 col-md-4">
      <label htmlFor="stock">Number</label>
      <input
        type="text"
        className="form-control rounded-pill mt-1"
        name="id_number"
        onChange={handleDocumentValuesChange}
        onBlur={(e) => updatePreview(e)}
        value={ documentValues.id_number ?? '' }
        />
    </div>
    <div className="p-2 col-md-4">
      <label htmlFor="stock">Issued by</label>
      <input
        type="text"
        className="form-control rounded-pill mt-1"
        name="id_issued_by"
        onChange={handleDocumentValuesChange}
        onBlur={(e) => updatePreview(e)}
        value={ documentValues.id_issued_by ?? '' }
        />
    </div>

  </div>

  <h3>Part 2</h3>

  <div className="d-flex justify-content-evenly">

    <div className="p-2 col-md-12">
      <FASTFormElement 
        type="Checkbox" 
        property="part_3_15_selected" 
        value={documentValues.part_3_15_selected} 
        label="More than one individual is involved"
        changeValueFunction={handleDocumentValuesChange}
        onBlurFunction={updatePreview}
        />
    </div>

  </div>  

<div className="d-flex justify-content-evenly">

  <div className="p-2 col-md-3">
    <label htmlFor="stock">Last Name</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="customer_last_name"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.customer_last_name ?? '' }
      />
  </div>
  <div className="p-2 col-md-3">
    <label htmlFor="stock">First Name</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="customer_first_name"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.customer_first_name ?? '' }
      />
  </div>
  <div className="p-2 col-md-2">
    <label htmlFor="stock">M.I.</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="customer_mi"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.customer_mi ?? '' }
      />
  </div>
  <div className="p-2 col-md-4">
    <label htmlFor="stock">Taxpayer ID</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="customer_tax_id_number"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.customer_tax_id_number ?? '' }
      />
  </div>

</div>

<div className="d-flex justify-content-evenly">

  <div className="p-2 col-md-6">
    <label htmlFor="stock">DBA</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="customer_dba"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.customer_dba ?? '' }
      />
  </div>

  <div className="p-2 col-md-6">
    <label htmlFor="stock">EIN</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="customer_ein"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.customer_ein ?? '' }
      />
  </div>

</div>

<div className="d-flex justify-content-evenly">

  <div className="p-2 col-md-6">
    <label htmlFor="stock">Address</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="customer_address"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.customer_address ?? '' }
      />
  </div>

  <div className="p-2 col-md-6">
    <label htmlFor="stock">Occupation, profession, business</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="customer_occ_pro_bus"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.customer_occ_pro_bus ?? '' }
      />
  </div>

</div>

<div className="d-flex justify-content-evenly">

  <div className="p-2 col-md-4">
    <label htmlFor="stock">City</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="customer_city"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.customer_city ?? '' }
      />
  </div>
  <div className="p-2 col-md-4">
    <label htmlFor="stock">State</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="customer_state"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.customer_state ?? '' }
      />
  </div>
  <div className="p-2 col-md-4">
    <label htmlFor="stock">ZIP code</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="customer_zip"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.customer_zip ?? '' }
      />
  </div>

</div>


<div className="d-flex justify-content-evenly">

  <div className="p-2 col-md-12">
    <label htmlFor="stock">Country (If not U.S.)</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="customer_country"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.customer_country ?? '' }
      />
  </div>

</div>

<div className="d-flex justify-content-evenly">

  <div className="p-2 col-md-4">
    <label htmlFor="stock">Describe ID</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="customer_describe_id"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.customer_describe_id ?? '' }
      />
  </div>
  <div className="p-2 col-md-4">
    <label htmlFor="stock">Number</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="customer_id_number"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.customer_id_number ?? '' }
      />
  </div>
  <div className="p-2 col-md-4">
    <label htmlFor="stock">Issued by</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="customer_id_issued_by"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.customer_id_issued_by ?? '' }
      />
  </div>

</div>

<h3>Part 3</h3>

<div className="d-flex justify-content-evenly">

  <div className="p-2 col-md-6">
    <label htmlFor="stock">Date cash received</label>
    <input
      type="date"
      className="form-control rounded-pill mt-1"
      name="date_received"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.date_received ?? '' }
      />
  </div>
  <div className="p-2 col-md-6">
    <label htmlFor="stock">Total cash received</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="total_cash_received"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.total_cash_received ?? '' }
      />
  </div>
</div>

<div className="d-flex justify-content-evenly">
  <div className="p-2 col-md-6">
   <FASTFormElement 
    type="Checkbox" 
    property="part_3_30_selected" 
    value={documentValues.part_3_30_selected} 
    label="If cash was received in more than one payment."
    changeValueFunction={handleDocumentValuesChange}
    onBlurFunction={updatePreview}
    />
  </div>
  <div className="p-2 col-md-6">
    <label htmlFor="stock">Total price if different from item 29</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="total_price_diff"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.total_price_diff ?? '' }
      />
  </div>

</div>

<div className="d-flex justify-content-evenly">

  <div className="p-2 col-md-4">
    <label htmlFor="stock">U.S. Currency</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="us_currency"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.us_currency ?? '' }
      />
  </div>
  <div className="p-2 col-md-4">
    <label htmlFor="stock">Amount in 100 bills or higher</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="amount_over_100"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.amount_over_100 ?? '' }
      />
  </div>
  <div className="p-2 col-md-4">
    <label htmlFor="stock">Country</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="p3_country"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.p3_country ?? '' }
      />
  </div>
</div>

<div className="d-flex justify-content-evenly">

  <div className="p-2 col-md-12">
    <label htmlFor="stock">b. Foreign currency</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="foreign_currency"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.foreign_currency ?? '' }
      />
  </div>

</div>

<div className="d-flex justify-content-evenly">

  <div className="p-2 col-md-12">
    <label htmlFor="stock">c. Cashier's check(s)</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="cashiers_check"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.cashiers_check ?? '' }
      />
  </div>

</div>

<div className="d-flex justify-content-evenly">

  <div className="p-2 col-md-12">
    <label htmlFor="stock">d. Money Order(s)</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="money_order"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.money_order ?? '' }
      />
  </div>

</div>

<div className="d-flex justify-content-evenly">

  <div className="p-2 col-md-12">
    <label htmlFor="stock">e. Bank Draft(s)</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="bank_draft"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.bank_draft ?? '' }
      />
  </div>

</div>

<div className="d-flex justify-content-evenly">

  <div className="p-2 col-md-12">
    <label htmlFor="stock">f. Traveler's check(s)</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="travelers_check"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.travelers_check ?? '' }
      />
  </div>

</div>

<div className="d-flex justify-content-evenly">

  <div className="p-2 col-md-12">
    <label htmlFor="stock">1. Issuer's name and serial numbers of money instruments (Line 1)</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="issuer_1"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.issuer_1 ?? '' }
      />
  </div>

</div>

<div className="d-flex justify-content-evenly">

  <div className="p-2 col-md-12">
    <label htmlFor="stock">2. Issuer's name and serial numbers of money instruments (Line 2)</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="issuer_2"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.issuer_2 ?? '' }
      />
  </div>

</div>

<div className="d-flex justify-content-evenly">

  <div className="p-2 col-md-12">
    <label htmlFor="stock">3. Issuer's name and serial numbers of money instruments (Line 3)</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="issuer_3"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.issuer_3 ?? '' }
      />
  </div>

</div>

<div className="d-flex justify-content-evenly">
  <div className="p-2 col-md-12">
    <FASTFormElement 
      type="Checkbox" 
      property="part_33_a_selected" 
      value={documentValues.part_33_a_selected} 
      label="a. Personal property purchased"
      changeValueFunction={handleDocumentValuesChange}
      onBlurFunction={updatePreview}
      />

    <FASTFormElement 
        type="Checkbox" 
        property="part_33_b_selected" 
        value={documentValues.part_33_b_selected} 
        label="b. Real property purchased"
        changeValueFunction={handleDocumentValuesChange}
        onBlurFunction={updatePreview}
        />

    <FASTFormElement 
        type="Checkbox" 
        property="part_33_c_selected" 
        value={documentValues.part_33_c_selected} 
        label="c. Personal services provided"
        changeValueFunction={handleDocumentValuesChange}
        onBlurFunction={updatePreview}
        />

    <FASTFormElement 
        type="Checkbox" 
        property="part_33_d_selected" 
        value={documentValues.part_33_d_selected} 
        label="d. Business services provided"
        changeValueFunction={handleDocumentValuesChange}
        onBlurFunction={updatePreview}
        />

    <FASTFormElement 
        type="Checkbox" 
        property="part_33_e_selected" 
        value={documentValues.part_33_e_selected} 
        label="e. Intangible property purchased"
        changeValueFunction={handleDocumentValuesChange}
        onBlurFunction={updatePreview}
        />


    <FASTFormElement 
        type="Checkbox" 
        property="part_33_f_selected" 
        value={documentValues.part_33_f_selected} 
        label="f. Debt obligations paid"
        changeValueFunction={handleDocumentValuesChange}
        onBlurFunction={updatePreview}
        />

    <FASTFormElement 
        type="Checkbox" 
        property="part_33_g_selected" 
        value={documentValues.part_33_g_selected} 
        label="g. Exchange of cash"
        changeValueFunction={handleDocumentValuesChange}
        onBlurFunction={updatePreview}
        />

    <FASTFormElement 
        type="Checkbox" 
        property="part_33_h_selected" 
        value={documentValues.part_33_h_selected} 
        label="h. Escrow or trust funds"
        changeValueFunction={handleDocumentValuesChange}
        onBlurFunction={updatePreview}
        />

    <FASTFormElement 
        type="Checkbox" 
        property="part_33_i_selected" 
        value={documentValues.part_33_i_selected} 
        label="i. Bail received by court clerts"
        changeValueFunction={handleDocumentValuesChange}
        onBlurFunction={updatePreview}
        />

    <FASTFormElement 
        type="Checkbox" 
        property="part_33_j_selected" 
        value={documentValues.part_33_j_selected} 
        label="j. Other (Specify in item 34)"
        changeValueFunction={handleDocumentValuesChange}
        onBlurFunction={updatePreview}
        />
  </div>
</div>

{ documentValues && documentValues.part_33_j_selected === true ? (
  <div className="d-flex justify-content-evenly">

    <div className="p-2 col-md-12">
      <label htmlFor="stock">34. Specific description of property or service...</label>
      <input
        type="text"
        className="form-control rounded-pill mt-1"
        name="spec_desc_1"
        onChange={handleDocumentValuesChange}
        onBlur={(e) => updatePreview(e)}
        value={ documentValues.spec_desc_1 ?? '' }
        />

      <input
        type="text"
        className="form-control rounded-pill mt-1"
        name="spec_desc_2"
        onChange={handleDocumentValuesChange}
        onBlur={(e) => updatePreview(e)}
        value={ documentValues.spec_desc_2 ?? '' }
        />

      <input
        type="text"
        className="form-control rounded-pill mt-1"
        name="spec_desc_3"
        onChange={handleDocumentValuesChange}
        onBlur={(e) => updatePreview(e)}
        value={ documentValues.spec_desc_3 ?? '' }
        />
    </div>

  </div>
  ):null}
      

<h3>Part 4</h3>

<div className="d-flex justify-content-evenly">

  <div className="p-2 col-md-8">
    <label htmlFor="stock">35. Name of business that received cash</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="seller_name"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.seller_name ?? '' }
      />
  </div>


<div className="p-2 col-md-4">
    <label htmlFor="stock">36. Employer identification number</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="ein"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.ein ?? '' }
      />
  </div>

</div>

<div className="d-flex justify-content-evenly">

  <div className="p-2 col-md-8">
    <label htmlFor="stock">37. Address</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="seller_address"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.seller_address ?? '' }
      />
  </div>


  <div className="p-2 col-md-4">
    <label htmlFor="stock">36. Social security number</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="ssn"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.ssn ?? '' }
      />
  </div>

</div>

<div className="d-flex justify-content-evenly">

  <div className="p-2 col-md-4">
    <label htmlFor="stock">38. City</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="seller_city"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.seller_city ?? '' }
      />
  </div>


  <div className="p-2 col-md-4">
    <label htmlFor="stock">39. State</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="seller_state"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.seller_state ?? '' }
      />
  </div>

  <div className="p-2 col-md-4">
    <label htmlFor="stock">40. Zip Code</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="seller_zip"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.seller_zip ?? '' }
      />
  </div>

</div>

<div className="d-flex justify-content-evenly">

  <div className="p-2 col-md-12">
    <label htmlFor="stock">41. Nature of your business</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="seller_nature_of_business"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.seller_nature_of_business ?? '' }
      />
  </div>

</div>

<div className="d-flex justify-content-evenly">

  <div className="p-2 col-md-12">
    <label htmlFor="stock">Title</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="title"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.title ?? '' }
      />
  </div>

</div>

<div className="d-flex justify-content-evenly">

  <div className="p-2 col-md-4">
    <label htmlFor="stock">43. Date of Signature</label>
    <input
      type="date"
      className="form-control rounded-pill mt-1"
      name="date_of_sig"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.date_of_sig ?? '' }
      />
  </div>

  <div className="p-2 col-md-4">
    <label htmlFor="stock">44. Name of contact person</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="contact_name"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.contact_name ?? '' }
      />
  </div>

  <div className="p-2 col-md-4">
    <label htmlFor="stock">45. Contact telephone number</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="contact_phone"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.contact_phone ?? '' }
      />
  </div>

</div>

<hr></hr>

<h2>Page 2</h2>

<h3>Part 1 - Continued if box 2 on page 1 is checked</h3> 

<div className="d-flex justify-content-evenly">

<div className="p-2 col-md-3">
  <label htmlFor="stock">Last Name</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="last_name_2"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.last_name_2 ?? '' }
    />
</div>
<div className="p-2 col-md-3">
  <label htmlFor="stock">First Name</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="first_name_2"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.first_name_2 ?? '' }
    />
</div>
<div className="p-2 col-md-2">
  <label htmlFor="stock">M.I.</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="mi_2"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.mi_2 ?? '' }
    />
</div>
<div className="p-2 col-md-4">
  <label htmlFor="stock">Taxpayer ID</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="tin_2"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.tin_2 ?? '' }
    />
</div>

</div>

<div className="d-flex justify-content-evenly">

<div className="p-2 col-md-6">
  <label htmlFor="stock">Address</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="address_2"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.address_2 ?? '' }
    />
</div>

<div className="p-2 col-md-6">
  <label htmlFor="stock">Date of birth</label>
  <input
    type="date"
    className="form-control rounded-pill mt-1"
    name="dob_2"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.dob_2 ?? '' }
    />
</div>

</div>

<div className="d-flex justify-content-evenly">

<div className="p-2 col-md-4">
  <label htmlFor="stock">City</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="city_2"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.city_2 ?? '' }
    />
</div>
<div className="p-2 col-md-4">
  <label htmlFor="stock">State</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="state_2"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.state_2 ?? '' }
    />
</div>
<div className="p-2 col-md-4">
  <label htmlFor="stock">ZIP code</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="zip_2"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.zip_2 ?? '' }
    />
</div>

</div>


<div className="d-flex justify-content-evenly">

<div className="p-2 col-md-6">
  <label htmlFor="stock">Country (If not U.S.)</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="country_2"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.country_2 ?? '' }
    />
</div>
<div className="p-2 col-md-6">
  <label htmlFor="stock">Occupation, profession or business</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="occupation_2"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.occupation_2 ?? '' }
    />
</div>

</div>

<div className="d-flex justify-content-evenly">

<div className="p-2 col-md-4">
  <label htmlFor="stock">Describe ID</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="described_id_2"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.described_id_2 ?? '' }
    />
</div>
<div className="p-2 col-md-4">
  <label htmlFor="stock">Number</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="id_number_2"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.id_number_2 ?? '' }
    />
</div>
<div className="p-2 col-md-4">
  <label htmlFor="stock">Issued by</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="issued_by_2"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.issued_by_2 ?? '' }
    />
</div>

</div>

<h4>Additional Buyer</h4>

<div className="d-flex justify-content-evenly">

<div className="p-2 col-md-3">
  <label htmlFor="stock">Last Name</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="last_name_3"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.last_name_3 ?? '' }
    />
</div>
<div className="p-2 col-md-3">
  <label htmlFor="stock">First Name</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="first_name_3"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.first_name_3 ?? '' }
    />
</div>
<div className="p-2 col-md-2">
  <label htmlFor="stock">M.I.</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="mi_3"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.mi_3 ?? '' }
    />
</div>
<div className="p-2 col-md-4">
  <label htmlFor="stock">Taxpayer ID</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="tin_3"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.tin_3 ?? '' }
    />
</div>

</div>

<div className="d-flex justify-content-evenly">

<div className="p-2 col-md-6">
  <label htmlFor="stock">Address</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="address_3"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.address_3 ?? '' }
    />
</div>

<div className="p-2 col-md-6">
  <label htmlFor="stock">Date of birth</label>
  <input
    type="date"
    className="form-control rounded-pill mt-1"
    name="dob_3"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.dob_3 ?? '' }
    />
</div>

</div>

<div className="d-flex justify-content-evenly">

<div className="p-2 col-md-4">
  <label htmlFor="stock">City</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="city_3"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.city_3 ?? '' }
    />
</div>
<div className="p-2 col-md-4">
  <label htmlFor="stock">State</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="state_3"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.state_3 ?? '' }
    />
</div>
<div className="p-2 col-md-4">
  <label htmlFor="stock">ZIP code</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="zip_3"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.zip_3 ?? '' }
    />
</div>

</div>


<div className="d-flex justify-content-evenly">

<div className="p-2 col-md-6">
  <label htmlFor="stock">Country (If not U.S.)</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="country_3"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.country_3 ?? '' }
    />
</div>
<div className="p-2 col-md-6">
  <label htmlFor="stock">Occupation, profession or business</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="occupation_3"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.occupation_3 ?? '' }
    />
</div>

</div>

<div className="d-flex justify-content-evenly">

<div className="p-2 col-md-4">
  <label htmlFor="stock">Describe ID</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="described_id_3"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.described_id_3 ?? '' }
    />
</div>
<div className="p-2 col-md-4">
  <label htmlFor="stock">Number</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="id_number_3"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.id_number_3 ?? '' }
    />
</div>
<div className="p-2 col-md-4">
  <label htmlFor="stock">Issued by</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="issued_by_3"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.issued_by_3 ?? '' }
    />
</div>

</div>

<h3>Part 2 - Continued - Complete if box 15 on page 1 is checked</h3>

<div className="d-flex justify-content-evenly">

<div className="p-2 col-md-3">
  <label htmlFor="stock">Last Name</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="last_name_4"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.last_name_4 ?? '' }
    />
</div>
<div className="p-2 col-md-3">
  <label htmlFor="stock">First Name</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="first_name_4"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.first_name_4 ?? '' }
    />
</div>
<div className="p-2 col-md-2">
  <label htmlFor="stock">M.I.</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="mi_4"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.mi_4 ?? '' }
    />
</div>
<div className="p-2 col-md-4">
  <label htmlFor="stock">Taxpayer ID</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="tin_4"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.tin_4 ?? '' }
    />
</div>

</div>

<div className="d-flex justify-content-evenly">

<div className="p-2 col-md-6">
  <label htmlFor="stock">DBA</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="dba_4"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.dba_4 ?? '' }
    />
</div>


<div className="p-2 col-md-6">
  <label htmlFor="stock">EIN</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="ein_4"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.ein_4 ?? '' }
    />
</div>

</div>
<div className="d-flex justify-content-evenly">

<div className="p-2 col-md-6">
  <label htmlFor="stock">Address</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="address_4"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.address_4 ?? '' }
    />
</div>

<div className="p-2 col-md-6">
  <label htmlFor="stock">Occupation, profession, business</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="occupation_4"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.occupation_4 ?? '' }
    />
</div>

</div>

<div className="d-flex justify-content-evenly">

<div className="p-2 col-md-4">
  <label htmlFor="stock">City</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="city_4"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.city_4 ?? '' }
    />
</div>
<div className="p-2 col-md-4">
  <label htmlFor="stock">State</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="state_4"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.state_4 ?? '' }
    />
</div>
<div className="p-2 col-md-4">
  <label htmlFor="stock">ZIP code</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="zip_4"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.zip_4 ?? '' }
    />
</div>

</div>


<div className="d-flex justify-content-evenly">

<div className="p-2 col-md-12">
  <label htmlFor="stock">Country (If not U.S.)</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="country_4"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.country_4 ?? '' }
    />
</div>

</div>

<div className="d-flex justify-content-evenly">

<div className="p-2 col-md-4">
  <label htmlFor="stock">Describe ID</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="described_id_4"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.described_id_4 ?? '' }
    />
</div>
<div className="p-2 col-md-4">
  <label htmlFor="stock">Number</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="id_number_4"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.id_number_4 ?? '' }
    />
</div>
<div className="p-2 col-md-4">
  <label htmlFor="stock">Issued by</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="issued_by_4"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.issued_by_4 ?? '' }
    />
</div>

</div>

<h4>Additional Seller</h4>

<div className="d-flex justify-content-evenly">

  <div className="p-2 col-md-3">
    <label htmlFor="stock">Last Name</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="last_name_5"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.last_name_5 ?? '' }
      />
  </div>
  <div className="p-2 col-md-3">
    <label htmlFor="stock">First Name</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="first_name_5"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.first_name_5 ?? '' }
      />
  </div>
  <div className="p-2 col-md-2">
    <label htmlFor="stock">M.I.</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="mi_5"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.mi_5 ?? '' }
      />
  </div>
  <div className="p-2 col-md-4">
    <label htmlFor="stock">Taxpayer ID</label>
    <input
      type="text"
      className="form-control rounded-pill mt-1"
      name="tin_5"
      onChange={handleDocumentValuesChange}
      onBlur={(e) => updatePreview(e)}
      value={ documentValues.tin_5 ?? '' }
      />
  </div>

</div>

<div className="d-flex justify-content-evenly">

<div className="p-2 col-md-6">
  <label htmlFor="stock">DBA</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="dba_5"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.dba_5 ?? '' }
    />
</div>

<div className="p-2 col-md-6">
  <label htmlFor="stock">EIN</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="ein_5"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.ein_5 ?? '' }
    />
</div>

</div>

<div className="d-flex justify-content-evenly">

<div className="p-2 col-md-6">
  <label htmlFor="stock">Address</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="address_5"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.address_5 ?? '' }
    />
</div>

<div className="p-2 col-md-6">
  <label htmlFor="stock">Occupation, profession, business</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="occupation_5"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.occupation_5 ?? '' }
    />
</div>

</div>

<div className="d-flex justify-content-evenly">

<div className="p-2 col-md-4">
  <label htmlFor="stock">City</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="city_5"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.city_5 ?? '' }
    />
</div>
<div className="p-2 col-md-4">
  <label htmlFor="stock">State</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="state_5"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.state_5 ?? '' }
    />
</div>
<div className="p-2 col-md-4">
  <label htmlFor="stock">ZIP code</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="zip_5"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.zip_5 ?? '' }
    />
</div>

</div>


<div className="d-flex justify-content-evenly">

<div className="p-2 col-md-12">
  <label htmlFor="stock">Country (If not U.S.)</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="country_5"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.country_5 ?? '' }
    />
</div>

</div>

<div className="d-flex justify-content-evenly">

<div className="p-2 col-md-4">
  <label htmlFor="stock">Describe ID</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="describe_id_5"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.describe_id_5 ?? '' }
    />
</div>
<div className="p-2 col-md-4">
  <label htmlFor="stock">Number</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="id_number_5"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.id_number_5 ?? '' }
    />
</div>
<div className="p-2 col-md-4">
  <label htmlFor="stock">Issued by</label>
  <input
    type="text"
    className="form-control rounded-pill mt-1"
    name="issued_by_5"
    onChange={handleDocumentValuesChange}
    onBlur={(e) => updatePreview(e)}
    value={ documentValues.issued_by_5 ?? '' }
    />
</div>

</div>

                  <h4>Comments</h4>

                  <div className="d-flex justify-content-evenly">

                    <div className="p-2 col-md-12">
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="comments_1"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.comments_1 ?? '' }
                        />
                    </div>

                  </div>

                  <div className="d-flex justify-content-evenly">

                    <div className="p-2 col-md-12">
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="comments_2"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.comments_2 ?? '' }
                        />
                    </div>

                  </div>

                  <div className="d-flex justify-content-evenly">

                    <div className="p-2 col-md-12">
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="comments_3"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.comments_3 ?? '' }
                        />
                    </div>

                  </div>

                  <div className="d-flex justify-content-evenly">

                    <div className="p-2 col-md-12">
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="comments_4"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.comments_4 ?? '' }
                        />
                    </div>

                  </div>

                  <div className="d-flex justify-content-evenly">

                    <div className="p-2 col-md-12">
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="comments_5"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.comments_5 ?? '' }
                        />
                    </div>

                  </div>

                  <div className="d-flex justify-content-evenly">

                    <div className="p-2 col-md-12">
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="comments_6"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.comments_6 ?? '' }
                        />
                    </div>

                  </div>

                  <hr></hr>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default IRS8300

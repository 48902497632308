import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";
import { autoCityStateZip } from './docUtils.js';

export const TECInsuranceRequestGlobalAutofill = (documentValues, order, property=null, value=null, location) => {

    var newValues = documentValues;

    if(property ==='customer.name'){
      newValues.customer_name = value;
      if(order.customer.dba){
        newValues.customer_name += " dba "+order.customer.dba;
      }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          newValues.customer_name += " AND "+cosigner.name;
        }
      }
  }
    if(property ==='customer.dba'){
      newValues.customer_name = order.customer.name;
      if(value){
        newValues.customer_name += " dba "+value;
      }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          newValues.customer_name += " AND "+cosigner.name;
        }
      }
  }
    if(property ==='customer.cosigner'){
      newValues.customer_name = order.customer.name;
      if(order.customer.dba){
        newValues.customer_name += " dba "+order.customer.dba;
      }else if(value && value.length > 0){
        let cosigner = value[0];
        if(cosigner && cosigner.name){
          newValues.customer_name += " AND "+cosigner.name;
        }
      }
  }
    if(property ==='documentationDate'){
      newValues.date = moment.utc(value).format("YYYY-MM-DD");
  }
    if(property ==='location'){
        newValues.location_address = value.address+" "+value.city+" "+value.state+" "+value.zip;
        newValues.location_phone = value.phone;
        newValues.location_email = value.email;
        if(value.state === "CA"){
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
        }else{
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
        }
  }

    if(property !== null && value !== null){
      return newValues;
  }

    if(order && order.customer && order.customer.name){
      newValues.customer_name = order.customer.name;
      if(order.customer.dba){
        newValues.customer_name += " dba "+order.customer.dba;
      }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          newValues.customer_name += " AND "+cosigner.name;
        }
      }
    }

    if(location !== undefined){
      newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
    }

    if(location && location.phone){
      newValues.location_phone = location.phone;
    }

    if(location && location.email){
      newValues.location_email = location.email;
    }

    if(location){
      if(location.state === "CA"){
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
      }else{
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
      }
    }

    if(order && order.documentationDate && documentValues.date === undefined){
      newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }
    return newValues
}

export const TECInsuranceRequestAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
    var newValues = documentValues;

    if(property === 'fundingDetail.finalizedLendingOption.lossPayee' && value !== null){
      newValues.requirements_line_1 = (value).toString().trim()
    }
    if(property === 'fundingDetail.finalizedLendingOption.lossPayeeAddress' && value !== null){
      newValues.requirements_line_2 = (value).toString().trim()
    }
    if(property === 'fundingDetail.finalizedLendingOption.lossPayeeCity' && value !== null){
      newValues.requirements_line_3 = autoCityStateZip((value).toString().trim(), selectedProduct.fundingDetail.finalizedLendingOption.lossPayeeState, selectedProduct.fundingDetail.finalizedLendingOption.lossPayeeZip)
    }
    if(property === 'fundingDetail.finalizedLendingOption.lossPayeeState' && value !== null){
      newValues.requirements_line_3 = autoCityStateZip(selectedProduct.fundingDetail.finalizedLendingOption.lossPayeeCity, (value).toString().trim(), selectedProduct.fundingDetail.finalizedLendingOption.lossPayeeZip)
    }
    if(property === 'fundingDetail.finalizedLendingOption.lossPayeeZip' && value !== null){
      newValues.requirements_line_3 = autoCityStateZip(selectedProduct.fundingDetail.finalizedLendingOption.lossPayeeCity, selectedProduct.fundingDetail.finalizedLendingOption.lossPayeeState, (value).toString().trim())
    }
    if(property === 'fundingDetail.cashLienHolder.bankName' && value !== null){
      newValues.requirements_line_1 = (value).toString().trim();
    }
    if(property === 'fundingDetail.cashLienHolder.addressLine1' && value !== null){
      newValues.requirements_line_2 = (value).toString().trim()
      
    }
    if(property === 'fundingDetail.cashLienHolder.addressLine2' && value !== null){
      newValues.requirements_line_2 = (value).toString().trim();
      if(selectedProduct.shipTo.addressLine2){
        newValues.lienholder_line_2 = ((value).toString().trim()+" "+selectedProduct.shipTo.addressLine2)
      }
    }
    if(property === 'fundingDetail.cashLienHolder.city' && value !== null){
      newValues.requirements_line_3 = autoCityStateZip((value).toString().trim(), selectedProduct.fundingDetail.cashLienHolder.state, selectedProduct.fundingDetail.cashLienHolder.zip)
    }
    if(property === 'fundingDetail.cashLienHolder.state' && value !== null){
      newValues.requirements_line_3 = autoCityStateZip(selectedProduct.fundingDetail.cashLienHolder.city, (value).toString().trim(), selectedProduct.fundingDetail.cashLienHolder.zip)
    }
    if(property === 'fundingDetail.cashLienHolder.zip' && value !== null){
      newValues.requirements_line_3 = autoCityStateZip(selectedProduct.fundingDetail.cashLienHolder.city, selectedProduct.fundingDetail.cashLienHolder.state, (value).toString().trim())
    }

  if(property !== null && value !== null){
        return newValues;
    }

    if(selectedProduct.fundingDetail && selectedProduct.fundingDetail.fundingMethod && (selectedProduct.fundingDetail.fundingMethod === "tec-financing" || selectedProduct.fundingDetail.fundingMethod === "finance-only" || selectedProduct.fundingDetail.fundingMethod === "direct-deal") && selectedProduct.fundingDetail.finalizedLendingOption){
      newValues.requirements_line_1 = selectedProduct.fundingDetail.finalizedLendingOption.lossPayee ? selectedProduct.fundingDetail.finalizedLendingOption.lossPayee : "";
      newValues.requirements_line_2 = selectedProduct.fundingDetail.finalizedLendingOption.lossPayeeAddress ? selectedProduct.fundingDetail.finalizedLendingOption.lossPayeeAddress : "";
      newValues.requirements_line_3 = (selectedProduct.fundingDetail.finalizedLendingOption.lossPayeeCity ?? "")+", "+(selectedProduct.fundingDetail.finalizedLendingOption.lossPayeeState ?? "")+" "+(selectedProduct.fundingDetail.finalizedLendingOption.lossPayeeZip ?? "");
    }
  
    if(selectedProduct.fundingDetail && selectedProduct.fundingDetail.fundingMethod && selectedProduct.fundingDetail.fundingMethod === "cash-with-lien" && selectedProduct.fundingDetail.cashLienHolder){
      newValues.requirements_line_1 = (selectedProduct.fundingDetail.cashLienHolder.bankName ?? "");
      newValues.requirements_line_2 = (selectedProduct.fundingDetail.cashLienHolder.addressLine1 ?? "")+" "+(selectedProduct.fundingDetail.cashLienHolder.addressLine2 ?? "");
      newValues.requirements_line_3 = (selectedProduct.fundingDetail.cashLienHolder.city ?? "")+", "+(selectedProduct.fundingDetail.cashLienHolder.state ?? "")+" "+(selectedProduct.fundingDetail.cashLienHolder.zip ?? "");
    }
    
    return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {
  // X1.2- Field Type Specific Preview Formatting
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  // Data point formatting
  if(payload.date){
    payload.date = moment.utc(payload.date).format("MM/DD/YY");
  }

  return payload;
}

import _ from "lodash"

const removeChars = val => {
  // find out if has decimal, if has more than one take out additional decimals, then split string from decimal, remove extra characters/letters
  let counter = 0;
  let valArr = [];
  let trimValStr;

  if(val !== undefined && val !== null) {
    trimValStr = val.toString();
    trimValStr = trimValStr.charAt(0) === '$' ? trimValStr.substring(1): trimValStr;
    if(trimValStr.includes('.')){
      valArr = trimValStr.toString().split('');
      valArr.forEach((char, index) => {
        if(char === '.') {
          if(counter > 0) {
            valArr.splice(index, 1);
          } else {
            counter = counter + 1;
          }
        }
      })
    } else {
      return trimValStr.replace(/\D/g,'');
    }
  }
  let newStr = valArr.join('');
  let newArr = newStr.split('.');
  newArr.forEach((el, index) => {
    newArr[index] = el.replace(/\D/g,'');
  });
  newStr = newArr.join('.');
  return newStr;
}

var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

const formatPercentValue=(value)=>{
    let val = removeChars(value);
    let splitVal = val.split('.');
    if(splitVal > 100) {
      return ('100.000%');
    } else {
      let newVal = Number(val).toFixed(3).toString();
      return newVal+'%';
    }
}

function formatPhoneNumber(value) {
  if (_.isEmpty( value )) return "";
  value = removeChars(value);

  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
}

const formatPhone = phone => {
  if ( _.isEmpty(phone) ) {
    return ''
  }

  const formattedPhone = phone.replace(/\D/g, '')

  if ( formattedPhone.length > 10 ) {
    return formattedPhone.slice(0, -1);
  }

  return formattedPhone
}

const formatCosignerData = (cosigner) => {
  return {
    ...cosigner,
    phone: formatPhone(cosigner.phone),
  }
}

const formatContactData = (contactObj) => {
  return {
    ...contactObj,
    phone: formatPhone(contactObj.phone),
    fax: formatPhone(contactObj.fax),
    notes: 'Note'
  }
}

const formatMoneyValue=value=>{
  return formatter.format(removeChars(value));
}

const formatZipCode = (value) => {

  if ( _.isEmpty( value ) ) {
    return ''
  }

  let zipCode = value.replace(/[^\d]/g, "");

  zipCode =
    zipCode
    .trim()
    .replace(/(\d{5})/gi,"$1-").replace(/-$/gi,"");

  if ( zipCode.length > 10 ) {
    zipCode = zipCode.slice(0, 10);
  }

  return zipCode;
}

const formatDecimal = num => {
  if(num !== undefined) {
    num = removeChars(num);
    num = Number(num);
    return num.toFixed(2);
  }
}

const formatNumber = num => {

  if ( _.isNumber( num ) ) {
    num = removeChars(num);
    num = Number(num);
    num = num.toFixed(2);
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}

function getAreaCode(phoneNumber){
  return phoneNumber.slice(0, 3);
}

function getPhoneNumber(phoneNumber){
  let number = phoneNumber.slice(3);
  return number.slice(0,3)+"-"+number.slice(3);
}

function getPhoneNumberAlt(phoneNumber){
  let number = phoneNumber.slice(4);
  return number;
}

function getLastName(name){
  let wordArray = name.split(" ");
  if(wordArray.length === 2){
    return wordArray[1];
  }else if(wordArray.length === 3){
    return wordArray[2];
  }
}

function getFirstName(name){
  let wordArray = name?.split(" ");
  return wordArray?.length>0?wordArray[0]:name;
}

function getMiddleName(name){
  let wordArray = name.split(" ");
  if(wordArray.length === 2){
    // return wordArray[1]+", "+wordArray[0];
  }else if(wordArray.length === 3){
    return wordArray[1];
  }
}

function formatUpperCase(value){
  return value.toString().toUpperCase()
}

function formatNameLastMiddleFirst(name){
  let wordArray = name.split(" ");
  if(wordArray.length === 2){
    return wordArray[1]+", "+wordArray[0];
  }else if(wordArray.length === 3){
    return wordArray[2]+", "+wordArray[0]+", "+wordArray[1];
  }
  return name;
}


function formatNameLastFirstMiddle(name){
  let wordArray = name.split(" ");
  if(wordArray.length === 2){
    return wordArray[1]+", "+wordArray[0];
  }else if(wordArray.length === 3){
    return wordArray[2]+", "+wordArray[0]+", "+wordArray[1];
  }
  return name;
}

function capitalizeFirstLetter(string) {
  if(string && string !== ""){
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
}
const splitString =(str, cutOff)=>{
const substrings = [];
  let currentSubstring = '';



  for (let i = 0; i < str?.length; i++) {
    currentSubstring += str[i];

    if ((i + 1) % cutOff === 0) {
      const lastChar = currentSubstring[currentSubstring?.length - 1];
      const endsWithSpace = lastChar === ' ';

      let splitIndex = cutOff-1;
      if (!endsWithSpace) {
        splitIndex = currentSubstring.lastIndexOf(' ', cutOff-1);
      }

      substrings.push(currentSubstring.substring(0, splitIndex).trim());

      currentSubstring = currentSubstring.substring(splitIndex).trim();
    }
  }

  if (currentSubstring.length > 0) {
    substrings.push(currentSubstring);
  }

  return substrings;
}

const wordSplit = (name, length, lineBreak=false) =>{
    
  if(name?.length>length) {
    if(lineBreak){
      for (let position in name){
        if(Number(position)%length===length-1){
         name = name.substring(0, position) + ' ' + name.substring(position);
        }
      }
    } else {
      name = name.slice(0,length)+'...'
    }
  }
  return name
}

export { 
  formatPhone,
  formatCosignerData,
  formatContactData,
  formatPercentValue,
  formatMoneyValue,
  removeChars,
  formatter,
  formatDecimal,
  formatNumber,
  formatPhoneNumber,
  getAreaCode,
  getPhoneNumber,
  getPhoneNumberAlt,
  formatZipCode,
  getLastName,
  getFirstName,
  getMiddleName,
  formatUpperCase,
  formatNameLastMiddleFirst,
  formatNameLastFirstMiddle,
  capitalizeFirstLetter,
  splitString,
  wordSplit
}
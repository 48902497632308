import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";


export const NEPoAGlobalAutofill = (documentValues, order, property=null, value=null, location) => {

    var newValues = documentValues;
    
    let cosigner = null;
  if(property ==='customer.name'){
    newValues.name_please_print = order.customer.name
  }
  if(property ==='customer.cosigner[0].name'){
    newValues.name_please_print = order.customer.name
    if(value!==''){
        newValues.name_please_print_2 += value;
        newValues.date_2 = moment.utc(order.documentationDate).format("YYYY-MM-DD")
      }
  }
  if(property ==='documentationDate'){
    newValues.date = moment.utc(value).format("YYYY-MM-DD")
    if(order.customer.cosigners&& order.customer.cosigners.length>0){
      newValues.date_2 = moment.utc(value).format("YYYY-MM-DD")
    }
  }
  if(property ==='location'){
    if(value.state === "CA"){
      newValues.appoint_name = "TEC OF CALIFORNIA, INC.";
    }else{
      newValues.appoint_name = "TEC EQUIPMENT, INC.";
    }
  }
  if(property !== null && value !== null){
    return newValues;
}

    if(order && order.customer){
  
      if(order && order.customer && order.customer.name){
          newValues.name_please_print = order.customer.name;
      }
  
      if(order.customer.cosigners && order.customer.cosigners.length > 0){
          cosigner = order.customer.cosigners[0];
          if(cosigner){
            newValues.name_please_print_2 = cosigner.name;
          }
      }
    
    }
      
    if(order && order.documentationDate){
      newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
      if(cosigner){
        newValues.date_2 = moment.utc(order.documentationDate).format("YYYY-MM-DD");
      }
    }
  
    if(location){
      if(location.state === "CA"){
        newValues.appoint_name = "TEC OF CALIFORNIA, INC.";
      }else{
        newValues.appoint_name = "TEC EQUIPMENT, INC.";
      }
    }
  
    return newValues
}


export const NEPoAAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
  var newValues = documentValues;

  if(property === 'year' && value !== null){
    newValues.year = (value).toString().trim().replace(/['"]+/g, '');
}

if(property === 'make' && value !== null){
    newValues.make = (value).toString().trim();
}
if(property !== null && value !== null){
    return newValues;
}

  newValues.year = (selectedProduct.year);
  newValues.make = (selectedProduct.make).toString().toUpperCase();
  newValues.vinhin = (selectedProduct.vin).toString().toUpperCase();
  
  return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  if(payload.date){
    payload.date = moment.utc(payload.date).format("MM/DD/YY");
  }

  if(payload.date_2){
    payload.date_2 = moment.utc(payload.date_2).format("MM/DD/YY");
  }

  return payload;
}
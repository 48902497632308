import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector} from "react-redux";
import { editProduct, editWarranty,editGap, editProductAddition, editProductUpgrade } from "../../../../../../../../redux/products";
import { useFetch, recordFrontEndError} from "../../../../../../../../utlis/api"
import * as api from "../../../../../../../../utlis/api";
import {
  useParams
} from "react-router-dom";
import Additions from './Additions';
import Upgrades from './Upgrades';
import Deposits from './Deposits';
import { Autocomplete, TextField, CircularProgress, Tooltip, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import NumberFormat from "react-number-format"
// import States from '../States';
import { formatter, removeChars, formatNumber } from "../../../../../../../../utlis/formatters";
import { useOrder } from "../../../../../../../../redux/orders/order"
import { updateCopySection, updateCopySubSection, updateObjectId, updateObjectGroupId, updateChangesArray, updateGroupChangesArray, updateStatus, updateSaveFunction, resetAll } from "../../../../../../../../redux/saveAndCopy"
import { newProductDetails, updateProductDetails, saveProductSection, getOtherProductsInGroup, filterDocumentsForProduct, filterProductDocumentsForOrder } from "../../../../../../../../utlis/dataSaveAndCopyLibrary";
import SyncIcon from '@mui/icons-material/Sync';
import Button from '@mui/material/Button';
import { setActiveModal, setActiveModalTab } from "../../../../../../../../redux/app/appSaver";
import { setProduct } from "../../../../../../../../redux/products";
import { addTestingTag } from "../../../../../../../../utlis/testingHelpers";

const ModalProductDetail = ({  location, product, passedDepartments, resetFunc, resetAddFunc, resetUpgradeFunc, resetDepositFunc, hasUnsavedChanges, setHasUnsavedChanges, hasClickedOut, setHasClickedOut, preCloseClose, productLoaded, setProductLoaded, documents, setValue }) => {
  const dispatch  = useDispatch();
  // const history   = useHistory();
  const axios     = useFetch()
  let { id }      = useParams();

  const [dataChangeArray, setDataChangeArray]   = useState([]);

  const saveStatus = useSelector((state) => state?.copyChangesToDocumentsRedux.status);
  const changesArray = useSelector((state) => state?.copyChangesToDocumentsRedux.changesArray);

  const order = useOrder();

  // const stateAbbrev = States.map(state => {
  //   return state.abbreviation;
  // });

    const styles = {
    suggestions: {
      zIndex: 10,
      // display:displayStockSuggestions,
      // width:'inherit',
      position: 'absolute',
      border: '1px solid #999',
      backgroundColor:'white',
      borderRadius:'5px',
      borderTopWidth: 0,
      listStyle: 'none',
      marginTop: 0,
      maxHeight: 143,
      overflowY: 'auto',
      paddingLeft: 0,
      width: 'calc(425px + 1rem)',
      "& li": {
        padding: '0.5rem'
      },
      "& li:hover": {
        backgroundColor: '#0d6efd',
        color: '#fff',
        cursor: 'pointer',
        fontWeight: 700
      }
    },
    suggestionActive: {
      backgroundColor: '#0d6efd',
      color: '#fff',
      cursor: 'pointer',
      fontWeight: 700
    },
    zIndex:3
  };

  // const preCloseSave = e => {
  //   handleSubmit(e)
  //   setHasClickedOut(false);
  //   setHasUnsavedChanges(false);
  // }
  
  // const [product, setProduct] = useState(undefined);
  const [departments, setDepartments] = useState([]);
  // const [departmentValue, setDepartmentValue] = useState(undefined);
  // const [hasDocuments, setHasDocuments] = useState(false);
  const [reloadingDepartments, setReloadingDepartments] = useState(false);
  
  // const [saveSuccess, setSaveSuccess] = useState(false);
  // const [isLoading, setIsLoading]=useState(false)

  // const [dataChangeArray, setDataChangeArray]=useState([])


  const getDepartmentByCode = useCallback((code) => {
    let department = departments.find(department => department.salesDeptCode === code);
    return department;
  },[ departments ]);

  const reloadDepartments = useCallback(() => {
    if(order && order.tecLocationCode){
      setReloadingDepartments(true);
      setDepartments([]);
      axios
      .get(`/tec-location/${order.tecLocationCode}/sales-departments`)
      .then((res) => {
        let returnedDepartments = res?.data;
        let departmentsSorted = returnedDepartments.sort((a, b) => (a.description < b.description) ? 1 : -1);
        
        setDepartments(departmentsSorted);
        setTimeout( () => {
            setReloadingDepartments(false);
        }, 5000);
      }).catch((err) => {
      });
    }
  },[axios, order])

  const appData = useSelector((state) => state?.appData);

  useEffect(() => {

    if(appData?.activeModal === 'product' && appData?.activeModalTab === 'details'){
      dispatch(updateChangesArray(dataChangeArray));
    }

  }, [ appData, dataChangeArray, dispatch ])


  const handleSubmit = async event => {
    event.preventDefault();

    dispatch(updateStatus('loading'));

    if(!validateForm() ){
      if((product !== undefined) && (product !== null)&& product?.id!==undefined&& product?.id!==null)  { 
        if(product?.additions?.length > 0){
          product.additions.filter((addition,i)=> {
            if(addition?.serialNumber==='' || addition?.type===''){
              dispatch(editProductAddition({index:i, prop: 'invalid', value: true}))
              return true;
            }else{
              return false;
            }
          })
        }

        if(product?.upgrades?.length > 0){
          product.upgrades.filter((upgrade,i)=> {
            if(upgrade?.serialNumber!=='' && upgrade?.type!=='' && upgrade?.price!==undefined && upgrade?.stockNumber!==''){
              dispatch(editProductUpgrade({index:i, prop: 'invalid', value: true}))
              return true;
            }else{
              return false;
            }
          })
        }

        if(product?.deposits?.length > 0){
          product.deposits.filter((deposit,i)=> {
            if(deposit?.amount!==undefined && deposit?.status!=='' && deposit.paymentMethodId !== null && deposit.documentNumber!== ''){
              dispatch(editProductAddition({index:i, prop: 'invalid', value: true}))
              return true;
            }else{
              return false;
            }
          })
        }
      }else{
        product.additions.filter((addition,i)=> {
          if(addition?.serialNumber==='' || addition?.type===''){
            dispatch(editProductAddition({index:i, prop:'invalid', value:true}))
            return true
          }else return false
        })
        product.upgrades.filter((upgrade,i)=> {
          if(upgrade?.serialNumber!=='' && upgrade?.type!=='' && upgrade?.price!==undefined && upgrade?.stockNumber!==''){
            dispatch(editProductUpgrade({index:i, prop:'invalid', value:true}))
            return true
          }else return false
        })
        product.deposits.filter((deposit,i)=> {
          if(deposit?.amount!==undefined && deposit?.status!=='' && deposit.paymentMethodId !== null && deposit.documentNumber!== ''){
            dispatch(editProductAddition({index:i, prop:'invalid', value:true}))
            return true
          }else return false
        })
      }
      return;
    }
    
    
    // Edit Product
    if((product !== undefined) && (product !== null)&& product?.id!==undefined&& product?.id!==null)  { 
        
        saveProductSection(event, order, product, 'details', changesArray, updateProductDetails, () => {
          dispatch(updateStatus('saved'));
          setHasUnsavedChanges(false);
        });

         dispatch(updateCopySection('product'));
         dispatch(updateCopySubSection('details'));
         dispatch(updateObjectId(product?.id));
         dispatch(updateObjectGroupId(product?.productGroupId));
         dispatch(updateSaveFunction(updateProductDetails));

    } else {
      // New Product
      newProductDetails(id, product, (newProductId) => {
        
        if(newProductId > 0){

          api.getProduct(order?.id, newProductId).then( res => {
            dispatch(updateStatus('saved'));
            dispatch(updateObjectId(newProductId));
            setHasUnsavedChanges(false);
            if(res?.data && res?.data?.id){
            dispatch(setProduct(res?.data));
            dispatch(setActiveModal('product')); 
            dispatch(setActiveModalTab('fob'));
            setValue(1); // Redirect to FOB Tab
          }
          
        });
      }else{
        // Display Error
      }
        
      });

    }
    
  }


  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsObjs, setSuggestionsObjs] = useState([]);
  const [priceVal, setPriceVal] = useState(undefined);
  const [extWarrantyVal, setExtWarrantyVal] = useState(undefined);
  const [extWarrantyPrice, setextWarrantyPrice] = useState(undefined);
  const [gapInsVal, setGapInsVal] = useState(undefined);
  const [gapInsPrice, setGapInsPrice] = useState(undefined);
  const [highlightListItem, setHighlightListItem] = useState(0);
  const resetValsOnClose = useCallback(() => {
    let boolFalseVals = [
       setExtWarrantyVal
    ]
    let emptyArrs = [
      setSuggestions, setSuggestionsObjs
    ];
    setHighlightListItem(0);
    boolFalseVals.forEach(val => {
      val(false);
    });
    emptyArrs.forEach(val => {
      val([]);
    });

    dispatch(updateStatus('init'));
    dispatch(resetAll());

    // history.push(`/order/${id}`);
  },[ dispatch])

  // set function to reset newAdditions/newUpgrades/newDeposits values in child component
  // const resetAdditionsFunc = React.useRef(null);
  const resetUpgradesFunc = React.useRef(null);
  const resetDepositsFunc = React.useRef(null);

  function enableExtendedWarrantyHelper(){
    if(dataChangeArray["extendedWarranty.enabled"]?.oldValue){
      setDataChangeArray((prev)=>({
        ...prev,
        "extendedWarranty.enabled": {
          "newValue": "yes",
          "oldValue": dataChangeArray["extendedWarranty.enabled"].oldValue
        }
      }));
    }else{
      setDataChangeArray((prev)=>({
        ...prev,
        "extendedWarranty.enabled": {
          "newValue": "yes",
          "oldValue":  "yes"
        }
      }));
    }
  }

  const onIsExtWarrantyChange = e => {
    let initExtWarranty={
      description: product?.extendedWarranty?.description,
      price: product?.extendedWarranty?.price,
      provider: product?.extendedWarranty?.provider,
    }
    
    if (e.target.checked){
      dispatch(editWarranty({obj:initExtWarranty}));

      setDataChangeArray((prev)=>({
        ...prev,
        "extendedWarranty.enabled": {
          "newValue": "",
          "oldValue": product?.extendedWarranty ? true : false
        },
      }));

    }else{
      dispatch(editWarranty({obj:null}))
      if(product?.extendedWarranty?.id) api.deleteExtendedWarranty(product.extendedWarranty.id)
      delete changesArray["extendedWarranty.price"];
      delete changesArray["extendedWarranty.provider"];
      delete changesArray["extendedWarranty.description"];
    }

    if(changesArray["extendedWarranty.enabled"]?.oldValue){
      setDataChangeArray((prev)=>({
        ...prev,
        "extendedWarranty.enabled": {
          "newValue": e.target.checked === true ? "yes" : "no",
          "oldValue": changesArray["extendedWarranty.enabled"].oldValue
        }
      }));
    }else{
      setDataChangeArray((prev)=>({
        ...prev,
        "extendedWarranty.enabled": {
          "newValue": e.target.checked === true ? "yes" : "no",
          "oldValue": product?.extendedWarranty?.id ? "yes" : "no"
        }
      }));
    }

    dispatch(updateStatus('unsaved'));
  }

  const onExtWarrantyPriceChange = (options, sourceInfo) => {
    if ( sourceInfo?.source === 'prop' ) {
      return; //Ignore changes that are due to prop changes...
    }

    enableExtendedWarrantyHelper();

    let { formattedValue: formatted} = options

    formatted = removeChars(formatted);

    // if(!product?.extendedWarranty?.id && dataChangeArray["extendedWarranty.price"] === false){
    //   delete dataChangeArray[]
    // }

    if(changesArray["extendedWarranty.price"]?.oldValue){
      setDataChangeArray((prev)=>({
        ...prev,
        "extendedWarranty.price": {
          "newValue": formatted,
          "oldValue": changesArray["extendedWarranty.price"].oldValue
        }
      }));
    }else{
      setDataChangeArray((prev)=>({
        ...prev,
        "extendedWarranty.price": {
          "newValue": formatted,
          "oldValue": product?.extendedWarranty?.price ? product.extendedWarranty.price : "--"
        }
      }));
    }

    dispatch(editWarranty({prop:'price', value:formatted}))
    dispatch(updateStatus('unsaved'))
  }

  const onExtWarrantyProviderChange = e => {
    let val = e.target.value;
    let formatted = val;

    enableExtendedWarrantyHelper();

    if(changesArray["extendedWarranty.provider"]?.oldValue){
      setDataChangeArray((prev)=>({
        ...prev,
        "extendedWarranty.provider": {
          "newValue": formatted,
          "oldValue": changesArray["extendedWarranty.provider"].oldValue
        }
      }));
    }else{
      setDataChangeArray((prev)=>({
        ...prev,
        "extendedWarranty.provider": {
          "newValue": formatted,
          "oldValue": product?.extendedWarranty?.provider ? product.extendedWarranty.provider : "--"
        }
      }));
    }

    dispatch(editWarranty({prop:'provider', value:val}))

    dispatch(updateStatus('unsaved'))

  }

  const onExtWarrantyDescChange = e => {
    let val =e.target.value
    let formatted = val;

    enableExtendedWarrantyHelper();

    if(changesArray["extendedWarranty.description"]?.oldValue){
      setDataChangeArray((prev)=>({
        ...prev,
        "extendedWarranty.description": {
          "newValue": formatted,
          "oldValue": changesArray["extendedWarranty.description"].oldValue
        }
      }));
    }else{
      setDataChangeArray((prev)=>({
        ...prev,
        "extendedWarranty.description": {
          "newValue": formatted,
          "oldValue": product?.extendedWarranty?.description ? product.extendedWarranty.description : "--"
        }
      }));
    }

    dispatch(editWarranty({prop:'description', value:val}))

    dispatch(updateStatus('unsaved'))

  }
  


  function enableGapInsuranceHelper(){
    if(changesArray["gapInsurance.enabled"]?.oldValue){
      setDataChangeArray((prev)=>({
        ...prev,
        "gapInsurance.enabled": {
          "newValue": "yes",
          "oldValue": changesArray["gapInsurance.enabled"].oldValue
        }
      }));
    }else{
      setDataChangeArray((prev)=>({
        ...prev,
        "gapInsurance.enabled": {
          "newValue": "yes",
          "oldValue":  "yes"
        }
      }));
    }
  
  }

  const onIsGapInsChange = e => {
    let initGapInsurance={
      amount: product?.gapInsurance?.amount,
      productId: product?.id,
      provider: product?.gapInsurance?.provider,
    }
    
    if (e.target.checked){
      dispatch(editGap({obj:initGapInsurance}));

      setDataChangeArray((prev)=>({
        ...prev,
        "gapInsurance.enabled": {
          "newValue": "",
          "oldValue": product?.gapInsurance ? true : false
        },
      }));

    }else{
      dispatch(editGap({obj:null}));
      if(product?.gapInsurance?.id) api.deleteGapInsurance(product.gapInsurance.id);
      delete changesArray["gapInsurance.amount"];
      delete changesArray["gapInsurance.provider"];
    }

    if(changesArray["gapInsurance.enabled"]?.oldValue){
      setDataChangeArray((prev)=>({
        ...prev,
        "gapInsurance.enabled": {
          "newValue": e.target.checked === true ? "yes" : "no",
          "oldValue": changesArray["gapInsurance.enabled"].oldValue
        }
      }));
    }else{
      setDataChangeArray((prev)=>({
        ...prev,
        "gapInsurance.enabled": {
          "newValue": e.target.checked === true ? "yes" : "no",
          "oldValue": product?.gapInsurance?.id ? "yes" : "no"
        }
      }));
    }

    dispatch(updateStatus('unsaved'))

  }
  const onGapInsPriceChange =(options, sourceInfo) => {
    if ( sourceInfo?.source === 'prop' ) {
        return; //Ignore changes that are due to prop changes...
      }
      let { formattedValue: formatted } = options
      
      formatted = removeChars(formatted);

    if(changesArray){
      enableGapInsuranceHelper();
      
      if(changesArray["gapInsurance.amount"]?.oldValue){
        setDataChangeArray((prev)=>({
          ...prev,
          "gapInsurance.amount": {
            "newValue": formatted,
            "oldValue": changesArray["gapInsurance.amount"].oldValue
          }
        }));
      }else{
        setDataChangeArray((prev)=>({
          ...prev,
          "gapInsurance.amount": {
            "newValue": formatted,
            "oldValue": product?.gapInsurance?.amount ? product.gapInsurance.amount : "--"
          }
        }));
      }
    }


    dispatch(editGap({prop:'amount', value:formatted}))
    dispatch(updateStatus('unsaved'))

  }

  const onGapInsProviderChange = e => {
    let val = e.target.value;

    enableGapInsuranceHelper();

    if(changesArray["gapInsurance.provider"]?.oldValue){
      setDataChangeArray((prev)=>({
        ...prev,
        "gapInsurance.provider": {
          "newValue": val,
          "oldValue": changesArray["gapInsurance.provider"].oldValue
        }
      }));
    }else{
      setDataChangeArray((prev)=>({
        ...prev,
        "gapInsurance.provider": {
          "newValue": val,
          "oldValue": product?.gapInsurance?.provider ? product.gapInsurance.provider : "--"
        }
      }));
    }


    dispatch(editGap({prop:'provider', value:val}))

    dispatch(updateStatus('unsaved'))

  }
  
const handleDetailsEdit = (prop, e, passedValue=null)=>{
    let value = e?.target?.value

    if(passedValue !== null){
      value = passedValue;
    }

    if (prop === 'year'){
      let newStr = value.replace(/[^0-9]/g,'');
      value = newStr.length > 4 ? product.year.replace(/[^0-9]/g,'') : newStr;
    }
    if (prop === 'vin'){
      value.replace(/[^a-zA-Z0-9]/g, '');
    }
    if (prop === 'make'){
      value.toUpperCase();
    }
    if (prop === 'price'){
      value = removeChars(value);
    }

    let doNotCopyProperties = [ 'stockNumber', 'vin' ];

    if(!doNotCopyProperties.includes(prop)){

      if( changesArray && Array.isArray(changesArray) && changesArray.includes(prop) && changesArray[prop]?.oldValue){
        setDataChangeArray((prev)=>({
          ...prev,
          [prop]: {
            "newValue": value,
            "oldValue": changesArray[prop].oldValue
          }
        }));
      }else{
        setDataChangeArray((prev)=>({
          ...prev,
          [prop]: {
            "newValue": value,
            "oldValue": product[prop]
          }
        }));
      }

    }

    dispatch(editProduct({prop:prop, value:value}))
    dispatch(updateStatus('unsaved'))
  }



const getUpgradesPriceSubjectToFet = useCallback((upgradesArr) => {
    let totalPrice = 0;
    if(upgradesArr !== undefined) {
      if(upgradesArr.length) {
        upgradesArr.forEach(upgrade => {
          if(upgrade?.subjectToFet === true){
            let upgradePrice = removeChars(upgrade?.price);
            totalPrice = totalPrice + Number(upgradePrice);
          }
        })
      }
    }
    
    totalPrice = formatNumber(totalPrice);
    return '$' + totalPrice;
  },[])

const totalSubjectToFet = useCallback(() => {
    let totalUpgrades = getUpgradesPriceSubjectToFet(product.upgrades);
    totalUpgrades = removeChars(totalUpgrades);
    // let productPrice = priceVal !== undefined ? priceVal : (product ? (product.price) : (''));
    let productPrice = (product ? (product.price) : 0);
    let formatProductPrice = removeChars(productPrice);
    let total = Number(totalUpgrades) + Number(formatProductPrice);
    return total;
  },[getUpgradesPriceSubjectToFet, product])

  const updateTotals = useCallback((val) => {
    totalSubjectToFet();
  },[totalSubjectToFet])

  
  
  const lookupByStockno = async event => {
    setHasUnsavedChanges(true);
    // setSaveSuccess(false);
    // setStockNo(event.target.value);
    handleDetailsEdit('stockNumber', event)
    const inputVal = event.target.value;
    // dispatch(editProduct({prop:'stockNumber', value:inputVal}))
    if(inputVal.length >= 2){
      // event.preventDefault();
      try {
        let res = await  api.getInventorySelection(inputVal)
        if (res){
          const {data} = res.data; 
          const results = data.map(el => {
            return el.stockno;
          });
          setSuggestions(results);
          setSuggestionsObjs(data);
        }
      } catch (err){
        recordFrontEndError('index.js', 'Error getting stock numbers: ' + err)
      }
      
    } else {
      setSuggestions([]);
    }
    // show validation error if empty
    // event.target.value === '' ? setstockNoErrorShow('block') : setstockNoErrorShow('none');
  };

  const handleListToggle = (index) => {
    setHighlightListItem(index);
  }

  const setSuggestionSelectionIndex = (index) => {

    setSuggestions([]);
    const {stockno, year, vin, makename, model, stocktype} = suggestionsObjs[index];

    dispatch(editProduct({prop:'stockNumber', value: ( stockno ?? "") }))
    dispatch(editProduct({prop:'year', value: ( year ?? "") }))
    dispatch(editProduct({prop:'vin', value: ( vin ?? "") }))
    dispatch(editProduct({prop:'type', value: ( stocktype ==='NEW'||stocktype ==='USED'? stocktype :"") }))
    dispatch(editProduct({prop:'make', value: ( makename ?? "") }))
    dispatch(editProduct({prop:'model', value: ( model ?? "") }))
    // dispatch(editProduct({prop:'stateRegistered', value: ( licensestate ?? "") }))

    dispatch(updateStatus('unsaved'))
    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  }

  const  addSuggestionList = (suggestions) => {
    if(suggestions.length) {
      return(
        <ul style={styles.suggestions } className='col-12' data-testid="product.control.productStockSuggestionList" >
          {suggestions.map((suggestion, index) => {
            let foundProd= suggestionsObjs[index]

            return (
              // <li
              //   onMouseOver={() => handleListToggle(index)}
              //   style={highlightListItem === index ? styles.suggestionActive : null}
              //   onClick={e => setSuggestionSelectionIndex(index)}
              // >
              //   {suggestion}
              // </li>
<>
                  <li
                    onMouseOver={() => handleListToggle(index)}
                    style={highlightListItem === index ? styles.suggestionActive : null}
                    onClick={e => setSuggestionSelectionIndex(index)}
                    >
                    <Tooltip 
                      arrow
                      placement='left'
                      title={
                        <>
                        <div style={{ display:'flex', flexDirection:'column',  height:'fit-content', width:'fit-content', padding:'0.5rem',opacity:'100%'}}> 
                          <div style={{height:'min-content', display:'flex', flexDirection:'column', width:'fit-content', gap:'1rem'}}>
                            <div style={{display:'flex', flexDirection:'row', width:'100%',maxHeight:'min-content',alignItems :'center', minWidth: '300px' }}>
                              {/* <img className='info-svg' style={{height:'1rem',position: 'fixed' }} src={infoSVG} alt='info svg'/> */}
                                <p className="text-uppercase" style={{fontSize:'1.5rem', padding:'0', margin:'0 1.5rem', height:'100%', flexGrow:1, textAlign:'center', textDecoration:'underline', textDecorationThickness:'1px', textUnderlineOffset:'.25rem', color:'white'}}>
                                  {suggestion}
                                </p>
                            </div>
                            <div>
                              <ul className='tooltip-list pa-1'>
                                <li><span style={{ color: "#000", fontWeight: 'bold' }}>Type: </span>{foundProd?.stocktype}</li>
                                <li><span style={{ color: "#000", fontWeight: 'bold' }}>Year: </span>{foundProd?.year}</li>
                                <li><span style={{ color: "#000", fontWeight: 'bold' }}>Make: </span>{foundProd?.makename}</li>
                                <li><span style={{ color: "#000", fontWeight: 'bold' }}>Model: </span>{foundProd?.model}</li>
                                <li><span style={{ color: "#000", fontWeight: 'bold' }}>VIN: </span>{foundProd?.vin}</li>
                                <li><span style={{ color: "#000", fontWeight: 'bold' }}>State Registered: </span>{foundProd?.licensestate}</li>
                              </ul>
  
                            </div>
                          </div>
                        </div>
                        { suggestion?.deliveryAddress?.addressLine1 && suggestion?.deliveryAddress?.addressLine1 !== "" ? (
                          <div className="text-center mx-auto" style={{ marginTop: '-15px', fontSize: '14px', marginLeft: '-150px'}}>
                            <span style={{ color: "#000", fontWeight: 'bold', fontSize: '15px' }}>Customer Address</span><br />
                              {suggestion?.deliveryAddress?.addressLine1}<br />
                              { suggestion?.deliveryAddress?.addressLine2 && suggestion?.deliveryAddress?.addressLine2 !== "" ? (
                                <>
                                  {suggestion?.deliveryAddress?.addressLine2}<br />
                                </>
                              ):null}
                              {suggestion?.deliveryAddress?.city}, {suggestion?.deliveryAddress?.state} {suggestion?.deliveryAddress?.zip}
                          </div>
                        ):null}
                        </>
                      }
                    >
                      <p style={{textAlign:'center', margin:'0 1.5rem'}}>
                        {suggestion}
                      </p>
                    </Tooltip>
                  </li>
                </>


            );
          })}
        </ul>
      );
    }
  }

  const getUpgradesPrice = (upgradesArr) => {
    let totalPrice = 0;
    if(upgradesArr !== undefined) {
      if(upgradesArr?.length) {
        upgradesArr?.forEach(upgrade => {
          let upgradePrice = removeChars(upgrade?.price);
          totalPrice = totalPrice + Number(upgradePrice);
        })
      }
    }
    totalPrice = formatNumber(totalPrice);
    return '$' + totalPrice;
  }



  const getUpgradesPriceSubjectToSalesTax = (upgradesArr) => {
    let totalPrice = 0;
    if(upgradesArr !== undefined) {
      if(upgradesArr.length) {
        upgradesArr.forEach(upgrade => {
          if(upgrade.subjectToSalesTax === true){
            let upgradePrice = removeChars(upgrade.price);
            totalPrice = totalPrice + Number(upgradePrice);
          }
        })
      }
    }
    totalPrice = formatNumber(totalPrice);
    return '$' + totalPrice;
  }

  const salesPriceTotal = () => {
    let totalUpgrades = getUpgradesPrice(product?.upgrades);
    totalUpgrades = removeChars(totalUpgrades);
    // let productPrice = priceVal !== undefined ? priceVal : (product ? (product?.price) : 0);
    let productPrice = product ? (product?.price) : 0;
    let formatProductPrice = removeChars(productPrice);
    let total = Number(totalUpgrades) + Number(formatProductPrice);

    return total;
  }

 

  const totalSubjectToSalesTax = () => {
    let totalUpgrades = getUpgradesPriceSubjectToSalesTax(product.upgrades);
    totalUpgrades = removeChars(totalUpgrades);
    let productPrice = (product ? (product.price) : 0);
    let formatProductPrice = removeChars(productPrice);
    let total = Number(totalUpgrades) + Number(formatProductPrice);
    return total;
  }
 
  const validateForm = () => {

    let validationFailures = [];

    if(!Boolean(product?.stockNumber) || product?.stockNumber === ''){
      validationFailures.push('stockNumber')
    }

    if(product?.type !== 'NEW' && product?.type !== 'USED'){
      validationFailures.push('type')
    }

    if((!Boolean(product?.year)|| product?.year === '')){
      validationFailures.push('year')
    }

    if(product?.year && product?.year.length > 0 && product?.year.length < 4 ){
      validationFailures.push('year')
    }

    if((!Boolean(product?.vin) || product?.vin === '') || product?.vin?.length !== 17){
      validationFailures.push('vin')
    }

    if(!Boolean(product?.make) || product?.make === ''){
      validationFailures.push('make')
    }

    if(!Boolean(product?.model) || product?.model === ''){
      validationFailures.push('model')
    }

    // if(States.every(state=>state.abbreviation !== product.registrationAddress?.state)){
    //   validationFailures.push('stateRegistered')
    // }

    if(!Boolean(product?.price) || product?.price === ''){
      validationFailures.push('price')
    }

    if(!Boolean(product?.vehicleType) || product?.vehicleType === ''){
      validationFailures.push('vehicleType')
    }

    if(validationFailures.length > 0){
      dispatch(editProduct({prop:'checkFailed', value: true}))
      dispatch(updateStatus('failed'));
      return false;
    }
    return true;
  }



  useEffect(() => {

    if(appData?.activeModal === 'product' && appData?.activeModalTab === 'details'){

      try{
    
      if(product && product.gapInsurance && gapInsPrice===undefined){
        setGapInsPrice(formatter.format(product.gapInsurance.amount));
      }
      if(product && product.gapInsurance && gapInsVal===undefined) {
        setGapInsVal(true);
      }
      if(product && product?.price && priceVal===undefined ){
        setPriceVal(formatter.format(product.price))
      }
      if (product &&  product?.extendedWarranty && extWarrantyVal===undefined) {
        setExtWarrantyVal(true);
      }
      if(product && product?.extendedWarranty&& extWarrantyPrice === undefined){
        setextWarrantyPrice(formatter.format(product.extendedWarranty.price));
      }
      // reset vals on x-out with useRef hook from parent component
      resetFunc.current = resetValsOnClose;

      updateTotals();
    
    }catch(err){
      // console.error(err)
    }

  }
 
  }, [ appData, order, hasUnsavedChanges, setProductLoaded, productLoaded, passedDepartments,axios, departments.length, extWarrantyVal, gapInsVal, product, resetFunc, resetValsOnClose, updateTotals, getDepartmentByCode, priceVal, extWarrantyPrice, gapInsPrice]);
  

  useEffect(() => {

    if(appData?.activeModal === 'product' && appData?.activeModalTab === 'details'){

      if(passedDepartments !== undefined){
        setDepartments(passedDepartments);
        // if(product && product?.tecSalesDepartmentCode){
        //   var foundDepartment = getDepartmentByCode(product?.tecSalesDepartmentCode)
        //   if(foundDepartment){
        //     setDepartmentValue(foundDepartment)
        //   }
        // }
      }else{
        if(departments.length === 0 && order && order.tecLocationCode && reloadingDepartments === false){

          reloadDepartments();
        }
      }

    }

  },[appData, departments, order, passedDepartments, reloadDepartments, reloadingDepartments]);

  return (
    <>
      { appData?.activeModal === 'product' && appData?.activeModalTab === 'details' ? (
      <div>
        <form style={{overflowY: "scroll", overflowX: "hidden", maxHeight: "550px", width: "102%"}}>
          <div className="container">
            {/* ------------------------------ Row 1 ------------------------------------ */}
            <div className="row border-bottom">
              <div className="d-flex text-capitalize justify-content-between m-2">
              {/* <h5>Product Detail {product.id ?? ''} </h5> */}
              <h5>Product Detail</h5>
                  { saveStatus === 'unsaved' ? (
                    <div className="d-flex">
                      <p className="m-1">Save Changes?</p>
                      <SaveChangesYesButton onClick={handleSubmit} product={product} />
                      {/* <button className="m-1 btn btn-sm btn-success" aria-label='save' onClick={preCloseSave}>Yes</button> */}
                      <button className="m-1 btn btn-sm btn-danger" aria-label='do not save' data-testid="product.control.saveNo" onClick={preCloseClose}>No</button>
                    </div>
                  ): saveStatus === 'failed' ? (
                    <div className="d-flex">
                      <p className="m-1" style={{ color: "red" }} >Validation error<br />
                      <SaveProductButton onClick={handleSubmit} product={product} text={"RETRY"} dataTestIdValue="product.control.saveTop" /></p>
                    </div>
                  ):(
                    <div>
                      { saveStatus === 'loading' ? (
                        <CircularProgress style={{ placeSelf: "center" }} />
                      ):( saveStatus === 'saved' ? (
                        <SavedProductButton product={product} documents={documents} dataTestIdValue="product.control.saveTop" />
                      ):(
                        <SaveProductButton onClick={handleSubmit} product={product} dataTestIdValue="product.control.saveTop" />
                        // <button onClick={handleSubmit} aria-label='save' className="btn btn-primary text-uppercase">Save</button>
                      ))}
                    </div>
                  )}
              </div>
            </div>
            {/* ------------------------------ Row 2 ------------------------------------ */}
            <div className="row">
              <div className="d-flex mb-3 ">
                <div className="col-5 mx-3">
                  <label htmlFor="stock" style={{width:'auto', display:'flex', justifyContent:'space-between'}}><span>Stock # <span style={{ color: 'red' }}>*</span></span>{( !product.id && Boolean(order?.products?.find(p=>p?.stockNumber===product?.stockNumber)))&&(<span className="text-uppercase text-danger" style={{ textAlign:'center'}}>Product found on order</span>)}</label>
                  <input
                    autoComplete="off"
                    type="text"
                    aria-label='product stock number'
                    className="form-control rounded-pill mt-1 "
                    name="productDetailStock"
                    onChange={(e) => lookupByStockno(e)}
                    value={product?.stockNumber??''}
                    data-testid="product.stockNumber"
                    // onBlur={()=>setDisplayStockSuggestions('none')}

                    onFocus={()=> {
                      setSuggestions([]);
                      // setDisplayStockSuggestions('block');
                    }}
                  />
                  <span 
                    style={{display: (product.checkFailed&&(!Boolean(product?.stockNumber) || product?.stockNumber===''))?'block':'none'}} 
                    className="text-danger small"
                  >
                    Stock number is required.
                  </span>
                  {product?.stockNumber?.length >= 2? addSuggestionList(suggestions): null}
                </div>
                <div className=" mx-3">
                  <label htmlFor="productDetailType">New or Used <span style={{ color: 'red' }}>*</span></label>
                  <select
                    type="text"
                    aria-label='product type'
                    className="form-control rounded-pill mt-1 text-uppercase"
                    name="productDetailType"
                    value={product?.type ?? ''}
                    data-testid="product.type"
                    onChange={(e) => handleDetailsEdit('type',e)}
                  >
                    <option value = '' disabled hidden/>
                    <option value='NEW'>NEW</option>
                    <option value='USED'>USED</option>
                  </select>
                  <span style={{display: ( product.checkFailed && product?.type !== 'NEW' && product?.type !== 'USED' ) ? 'block' : 'none' }} className="text-danger small">Type is required.</span>
                </div>
                <ProductYear value={product?.year} onChange={handleDetailsEdit} />
                {/* <div className=" mx-3">
                  <label htmlFor="year">Year</label>
                  <input
                    type="text"
                    className="form-control rounded-pill mt-1 "
                    name="productDetailYear"
                    aria-label="product year"
                    value={ (product?.year) ?? ''}
                    onChange={(e) => handleDetailsEdit('year',e)}
                  />
                  <span style={{display: (product.checkFailed&&(!Boolean(product?.year) || product?.year===''))?'block':'none'}} className="text-danger small">Year is required.</span>
                </div> */}
              </div>

              <div className="d-flex mb-3 ">
                <div className="col-5 mx-3">
                  <label htmlFor="stock">Vin <span style={{ color: 'red' }}>*</span></label>
                  <input
                    type="text"
                    aria-label='product vin'
                    className="form-control rounded-pill mt-1 "
                    name="productDetailVin"
                    value={product?.vin ?? ''}
                    data-testid="product.vin"
                    onChange={(e) => handleDetailsEdit('vin',e)}
                  />
                  <span style={{display: (product.checkFailed && ((!Boolean(product?.vin)|| product?.vin ==='') || product?.vin?.length !== 17))?'block':'none'}} className="text-danger small">Vin must be 17 digits long.</span>
                </div>
                <div className=" mx-3">
                  <label htmlFor="type">Make <span style={{ color: 'red' }}>*</span></label>
                  <input
                    type="text"
                    aria-label='product make'
                    className="form-control rounded-pill mt-1 "
                    name="productDetailMake"
                    value={product?.make ?? ''}
                    data-testid="product.make"
                    onChange={(e) => handleDetailsEdit('make',e)}
                  />
                  <span style={{display: (product.checkFailed&&(!Boolean(product?.make) || product?.make===''))?'block':'none'}} className="text-danger small">Make is required.</span>
                </div>
                <div className=" mx-3">
                  <label htmlFor="year">Model <span style={{ color: 'red' }}>*</span></label>
                  <input
                    type="text"
                    aria-label="product model"
                    className="form-control rounded-pill mt-1 "
                    name="productDetailModel"
                    value={product?.model ?? ''}
                    data-testid="product.model"
                    onChange={(e) => handleDetailsEdit('model',e)}
                  />
                  <span style={{display: (product.checkFailed&&(!Boolean(product?.model) || product?.model ===''))?'block':'none'}} className="text-danger small">Model is required.</span>
                </div>
              </div>

              <div className="d-flex mb-3 ">
                <div className="col-4 mx-3 pt-4">
                  <label htmlFor="type">Sale Price <span style={{ color: 'red' }}>*</span></label>
                  <NumberFormat
                            className="rounded-pill form-control"
                            value={ Number(product?.price).toFixed(2)??''}
                            name="price"
                            aria-label='product sale price'
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={'$'}
                            // format={format}
                            autoComplete='off'
                            data-testid="product.price"
                            // onBlur={()=>dispatch(editProduct({prop:'price', value:Number(product?.price).toFixed(2)}))}
                            // onValueChange={ onPriceChange }
                            onChange={(e) => handleDetailsEdit('price', e)}

                          />
                  <span style={{display: (product.checkFailed&&(!Boolean(product?.price) || product?.price ===''))?'block':'none'}} className="text-danger small">Price is required.</span>
                </div>
                {/* <div className="col-3 mx-3 p-3">
                  <br />
                  <Autocomplete
                    value={product?.stateRegistration ?? ''}
                    aria-label='state registered'
                    onChange={(e, newValue) => {
                      handleDetailsEdit('stateRegistered', e, newValue)
                    }}
                    options={stateAbbrev}
                    sx={{ width: 230}}
                    renderInput={(params) => <TextField {...addTestingTag(params, 'product.stateRegistration')} label={ <span>STATE REGISTERED <span style={{ color: 'red' }}>*</span></span> } />}
                    />
                  <span style={{display: (product.checkFailed && States.every(state=>state.abbreviation !== product?.stateRegistration))?'block':'none'}} className="text-danger small">State is required.</span>
                </div> */}
                <div className="col-3 mx-3 p-3 pt-0" style={{ width: 'fit-content'}}>
                  <p style={{ fontSize: '14px' }} className="">Location: { location?.description ?? '...Loading' }</p>
                  <Autocomplete
                    disabled={reloadingDepartments === true ? true : false}
                    // value={product?.tecSalesDepartmentCode ? departmentValue : null}
                    value={getDepartmentByCode(product?.tecSalesDepartmentCode) ?? null}
                    aria-label='product tec department'
                    getOptionLabel={ department => {
                      return department.description }}
                    onChange={(e, newValue) => {
                      if(newValue?.salesDeptCode){
                        handleDetailsEdit('tecSalesDepartmentCode', e, newValue.salesDeptCode);
                      }
                    }}
                    options={departments??[]}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...addTestingTag(params, 'product.tecSalesDepartmentCode')} label={ <span>DEPARTMENT <span style={{ color: 'red' }}>*</span></span> } />}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.salesDeptCode}>
                          {option.description}
                        </li>
                      );
                    }}
                  />
                  <div className="d-flex justify-space-between">
                    <Button disabled={reloadingDepartments} data-testid="product.control.reloadDepartments" style={{ float: 'right'}} variant="text" onClick={reloadDepartments}><SyncIcon></SyncIcon> Reload departments</Button>
                  </div>
                </div>
              </div>

              <div className="d-flex mb-3 ">
                <div className="col-5 mx-3">
                  <label htmlFor="type">Vehicle Type <span style={{ color: 'red' }}>*</span></label>
                  <input
                    type="text"
                    className="form-control rounded-pill mt-1 "
                    name="vehicleType"
                    autoComplete='off'
                    aria-label="product vehicle type"
                    value={product.vehicleType?? ''}
                    data-testid="product.vehicleType"
                    // onChange={(e) => dispatch(editProduct({prop:'vehicleType', value: e.target.value}))}
                    onChange={(e) => handleDetailsEdit('vehicleType', e)}

                  />
                  <span style={{display: (product.checkFailed && (!Boolean(product?.vehicleType) || product?.vehicleType ===''))?'block':'none'}} className="text-danger small">Vehicle Type is required.</span>
                </div>
                </div>
            </div>
            <div className="row">
              <div className="mb-3 ">
                <div className="col-12">
                  <label htmlFor="stock">Product Notes (Internal Use Only)</label>
                  <textarea
                    maxLength="250"
                    className="form-control col-10 rounded-lg mt-1 "
                    rows="2"
                    aria-label="product notes"
                    name="productDetailProductNote"
                    data-testid="product.productNotes"
                    value={product?.productNotes ?? ''}
                    // onChange={(e) => dispatch(editProduct({prop: 'productNotes', value: e.target.value}))}
                    onChange={(e) => handleDetailsEdit('productNotes', e)}

                  ></textarea>
                </div>
              </div>
            </div>
            <hr className="my-3" />
            {/* ------------------------------ Row 3 ------------------------------------ */}
            <div className="row">
              <div className="d-flex mb-3">
                <div className="col-3 mt-4 text-capitalize">
                  <div className="custom-control custom-checkbox checkbox-lg">
                      <input
                        type="checkbox"
                        style={{padding:'10px'}}
                        aria-label='product extended warranty'
                        className="custom-control-input-group-lg"
                        checked={product?.extendedWarranty?? ''}
                        name="extendedWarranty.enabled"
                        data-testid="product.extendedWarranty"
                        onChange={(e) => onIsExtWarrantyChange(e)}
                        />
                      <label htmlFor="salesTax" style={{paddingLeft:'10px'}}>
                        <p> Extended Warranty </p>
                      </label>                    
                    </div>
                </div>
                {product?.extendedWarranty ? (
                <div className="col-3 mx-3 ">
                  <label htmlFor="type">Provider </label>
                  <input
                    type="text"
                    aria-label='product extended warranty provider'
                    className="form-control rounded-pill mt-1 "
                    name="extendedWarranty.provider"
                    data-testid="product.extendedWarranty.provider"
                    value={product?.extendedWarranty?.provider ?? ''}
                    onChange={(e) => {onExtWarrantyProviderChange(e);}
                    }
                  />
                </div>
                ) : ''}
                {product?.extendedWarranty ? (
                  <>
                    <div className="col-3 mx-3 ">
                      <label htmlFor="type">Warranty Price</label>
                      <NumberFormat
                        className="rounded-pill form-control"
                        value={ Number(product?.extendedWarranty?.price).toFixed(2)??''}
                        name="extendedWarranty.price"
                        aria-label='product extended warranty price'
                        data-testid="product.extendedWarranty.price"
                        thousandSeparator={true}
                        decimalScale={2}
                        prefix={'$'}
                        // format={format}
                        onBlur={()=>dispatch(editWarranty({prop:'price', value:Number(product?.extendedWarranty?.price).toFixed(2)}))}
                        onValueChange={ onExtWarrantyPriceChange }
                        />
                    </div>
                    { order?.customer?.deliveryAddress?.state !== "WA" ? (
                    <div className="col-3 mx-2 ">
                      <label htmlFor="type">Apply Sales Tax?</label>
                      <FormGroup className="m-2">
                        <FormControlLabel 
                          control={<Checkbox color="default" 
                          name="type_7"
                          checked={(product?.extendedWarranty?.isTaxable ? product?.extendedWarranty?.isTaxable : false)}
                          onClick={(e)=> dispatch(editWarranty({prop:'isTaxable', value: e.target.checked }))} />} 
                          label="Is Taxable" 
                        />
                      </FormGroup>
                    </div>
                    ):null}
                  </>
                ) : ''}
              </div>
              {product?.extendedWarranty ? (
              <div className="d-flex mb-3 ">
                <div className="col-12 mx-3">
                  <label htmlFor="stock">Warranty description (<span style={{ color: "red",}}>Shows on Invoice Notes</span>)</label>
                  <textarea
                    maxLength="250"
                    className="form-control col-10 rounded-lg mt-1 "
                    rows="5"
                    name="extendedWarranty.description"
                    aria-label="product extended warranty description"
                    data-testid="product.extendedWarranty.description"
                    value={product?.extendedWarranty?.description ?? ''}
                    onChange={e => onExtWarrantyDescChange(e)}
                  ></textarea>
                </div>
              </div>
              ) : ''}
            </div>

            <hr className="my-3" />
            {/* ------------------------------ Row 4 ------------------------------------ */}
            <div className="row">
              <div className=" mb-3">
                <div className="col-5 mt-4 text-capitalize">
                  <div className="custom-control custom-checkbox checkbox-lg">
                      <input
                        type="checkbox"
                        style={{padding:'10px'}}
                        className="custom-control-input-group-lg"
                        checked={product.gapInsurance?? ''}
                        aria-label='product gap insurance'
                        data-testid="product.gapInsurance"
                        name="gapInsurance.enabled"
                        onChange={(e) => {
                          onIsGapInsChange(e);
                          }}
                        />
                      <label htmlFor="salesTax" style={{paddingLeft:'10px'}}>
                        <p> GAP Waiver </p>
                      </label>                    
                    </div>
                </div>
                {product?.gapInsurance ? (
                <>
                <div className="col-5 mt-2 ">
                  <label htmlFor="stock">Provided By</label>
                  <input
                    type="text"
                    className="form-control rounded-pill mt-1 "
                    maxLength="50"
                    name="gapInsurance.provider"
                    data-testid="product.gapInsurance.provider"
                    aria-label='product gap insurance provider'
                    value={(product && product.gapInsurance) ? product.gapInsurance.provider : ''}
                    onChange={e => onGapInsProviderChange(e)}
                  />
                </div>
                <div className="col-5 mt-2">
                  <label htmlFor="stock">Amount</label>
                  <NumberFormat
                    className="rounded-pill form-control"
                    value={ Number(product?.gapInsurance?.amount).toFixed(2) ?? ''}
                    name="gapInsurance.amount"
                    aria-label='product gap insurance amount'
                    data-testid="product.gapInsurance.amount"
                    thousandSeparator={true}
                    decimalScale={2}
                    prefix={'$'}
                    // format={format}    
                    onBlur={()=>{dispatch(editGap({prop:'amount', value:Number(product?.gapInsurance?.amount).toFixed(2)}))}}
                    onValueChange={ onGapInsPriceChange }
                  />
                </div>
                </>
                ) : ''}
              </div>
            </div>
            {/* <hr className="my-3" /> */}
            {/* ------------------------------ Row 5 ------------------------------------ */}

          </div>

          <Additions 
            product={product} 
          />
          <Upgrades 
            product={product}
            removeChars={removeChars} 
            // updateIsValidUpgradesAddForm={updateIsValidUpgradesAddForm}
            resetUpgradeFunc={resetUpgradeFunc}
            updateTotals={updateTotals}
            resetUpgradesFunc={resetUpgradesFunc}
            // setHasUnsavedChanges ={setHasUnsavedChanges}
            // setSaveSuccess = {setSaveSuccess}
          />
          <Deposits 
            product={product} 
            resetDepositFunc={resetDepositFunc}
            resetDepositsFunc={resetDepositsFunc}
            // setHasUnsavedChanges ={setHasUnsavedChanges}
            // setSaveSuccess = {setSaveSuccess}
          />
              
          {/* ------------------------------ Row 10 ------------------------------------ */}
          <div className="row">
            <div className="my-3 mx-2">
              <h5>Price Summary</h5>
              <div className="d-flex justify-content-between my-2 text-capitalize">
                <div className="mx-5">
                  <h6 className="text-secondary">Sale Price</h6>
                </div>
                <div className="mx-5">
                <h6 data-testid="product.calculated.salePrice">{(product && product?.price) ? '$' + formatNumber(Number(product?.price)) : ('$0.00')}</h6>
                </div>
              </div>
              <div className="d-flex justify-content-between my-2 text-capitalize">
                <div className="mx-5">
                  <h6 className="text-secondary">Upgrades</h6>
                </div>
                <div className="mx-5">
                  <h6 data-testid="product.calculated.upgradesPrice">{getUpgradesPrice(product.upgrades)}</h6>
                </div>
              </div>
              <hr style={{width: "98%"}} />
              <div className="d-flex justify-content-between my-2 text-capitalize">
                <div className="mx-5">
                  <h6 className="text-secondary">Sale Price total</h6>
                </div>
                <div className="mx-5">
                  <h6 data-testid="product.calculated.salesPriceTotal">{ formatter.format(salesPriceTotal()) }</h6>
                </div>
              </div>
                  {
                    product.extendedWarranty?(
                      <div className="d-flex justify-content-between my-2 text-capitalize">
                      <div className="mx-5">
                    <h6 className="text-secondary">Extended Warranty</h6>
                    </div>
                    <div className="mx-5">
                  <h6 data-testid="product.calculated.extendedWarrantyTotal">{product?.extendedWarranty?.price ? formatter.format(product.extendedWarranty.price) : '$0.00'}</h6>
                </div>
              </div>
              ):null
            }

              {product.gapInsurance?(

              <div className="d-flex justify-content-between my-2">
                <div className="mx-5">
                  <h6 className="text-secondary">GAP Waiver</h6>
                </div>
                <div className="mx-5">
                {/* <h6>{gapInsPrice !== undefined ? (Number(removeChars(gapInsPrice)) > 0 ? formatter.format(gapInsPrice) : "$0.00") : (product && product.gapInsurance && product.gapInsurance.amount && product.gapInsurance.amount > 0 ? formatter.format(product.gapInsurance.amount) : "$0.00")}</h6> */}
                <h6 data-testid="product.calculated.gapInsuranceTotal">{product?.gapInsurance?.amount ? formatter.format(product.gapInsurance.amount) : '$0.00'}</h6>
                </div>
              </div>
              ):null}
              <hr style={{width: "98%"}} />
              <div className="d-flex justify-content-between my-2 text-capitalize">
                <div className="mx-5">
                  <h6 className="text-secondary">Total Subject to FET</h6>
                </div>
                <div className="mx-5">
                  <h6 data-testid="product.calculated.totalSubjectToFET">{ formatter.format(totalSubjectToFet()) }</h6>
                </div>
              </div>
              { order?.customer?.isExemptSalesTax === false ? (
                <div className="d-flex justify-content-between my-2 text-capitalize">
                  <div className="mx-5">
                    <h6 className="text-secondary">Total Subject to Sales Tax</h6>
                  </div>
                  <div className="mx-5">
                    <h6 data-testid="product.calculated.totalSubjectToSalesTax">{ formatter.format(totalSubjectToSalesTax()) }</h6>
                  </div>
                </div>
              ):null}
            </div>
          </div>
        </form>
        <div className="modal-footer justify-content-center mt-2">
        { saveStatus === 'loading' ? 
              <CircularProgress/>
            : ( saveStatus === 'saved' ? (
                <SavedProductButton product={product} documents={documents} dataTestIdValue="product.control.saveBottom" />
              ): saveStatus === 'failed' ? (
                <SaveProductButton onClick={handleSubmit} product={product} text={"RETRY"} dataTestIdValue="product.control.saveBottom" />
              ):(
                <SaveProductButton onClick={handleSubmit} product={product} dataTestIdValue="product.control.saveBottom" />
              ))}
          </div>
      </div>
      ):null}
    </>
  );
}

const SaveProductButton = ({ onClick, product , text="Save", dataTestIdValue}) => {
  return (
    <>
      { text === "Save" ? (
        <button aria-label='Save' data-testid={dataTestIdValue} onClick={onClick} className="btn btn-primary text-uppercase">{ text }</button>
      ):(
        <button aria-label='Save' data-testid={dataTestIdValue} onClick={onClick} className="btn btn-warning text-uppercase">{ text }</button>
      )}
    </>
  )
}

const SaveChangesYesButton = ({ onClick, product, text="Yes" }) => {
  return (
    <>
      { text === "Yes" ? (
        <button aria-label='Yes' data-testid="product.control.saveYes" onClick={onClick} className="btn btn-success text-uppercase">{ text }</button>
      ):(
        <button aria-label='Yes' data-testid="product.control.saveYes" onClick={onClick} className="btn btn-warning text-uppercase">{ text }</button>
      )}
    </>
  )
}

const SavedProductButton = ({ product, documents}) => {

  const order   = useOrder();
  const dispatch  = useDispatch();

  const changesArray = useSelector((state) => state?.copyChangesToDocumentsRedux.changesArray);

  function filterDocuments(){
    let productDocuments = filterDocumentsForProduct(product.id, documents);
    let orderDocuments = filterProductDocumentsForOrder(product.id, documents);

    // console.log(orderDocuments);

    let allFilteredDocuments = orderDocuments.concat(productDocuments);
    return allFilteredDocuments;
  }

  function prepRedux(){
    dispatch(updateCopySection('product'));
    dispatch(updateCopySubSection('details'));
    dispatch(setActiveModalTab(null));

  }

  function prepGroupCopy(){
    dispatch(updateGroupChangesArray([changesArray]));
    dispatch(updateStatus('copy-to-group'));
    dispatch(setActiveModal('copy-to-product-group'));
  }

  function prepDocumentCopy(){
    let copyChangesArray = [];
    
    copyChangesArray.id = product.id;
    copyChangesArray.stockNumber = product.stockNumber;
    copyChangesArray.propertySaved = false;
    copyChangesArray.docsSavedCount = 0;

    Object.entries(changesArray).forEach( value => {
      copyChangesArray[value[0]] = value[1];
    });


    dispatch(updateGroupChangesArray([copyChangesArray]));
    dispatch(updateObjectId(product?.id));
    dispatch(updateObjectGroupId(product?.productGroupId));
    dispatch(updateStatus('copy'));
    dispatch(setActiveModal('copy-to-documents'));
  }

  return (
    <>
      <span className="btn btn-sm btn-success btn-text text-uppercase mx-2 btn-block my-2" disabled="true">Product Saved</span><br />
      { getOtherProductsInGroup(order, product.id, product.productGroupId)?.length > 0 ? (
        <>
          <button aria-label='Copy To Group' 
           data-testid="product.control.copyToGroup"
            onClick={ (e)=>{
              e.preventDefault();
              prepRedux();
              prepGroupCopy();
            }} data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#copychangestogroupmodal" className="my-2 btn  btn-block btn-sm btn-primary text-uppercase mx-2">Copy To Group</button><br />
        </>
      ): filterDocuments()?.length > 0 ? (
        <>
          <button aria-label='Copy to Documents' 
            data-testid="product.control.copyToDocuments"
            onClick={ (e)=>{
              e.preventDefault();
              prepRedux();
              prepDocumentCopy();
            }} data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#copychangestodocuments" className="my-2 btn  btn-block btn-sm btn-primary text-uppercase mx-2">Copy to Documents</button><br />
        </>
      ):null}
    </>
  )
}

const ProductYear = ({ onChange, value }) => {

  const [hasChanged, setHasChanged] = useState(false);
  const [hasBlured, setHasBlured] = useState(false);

  return (
    <>
      <div className=" mx-3">
        <label htmlFor="year">Year <span style={{ color: 'red' }}>*</span></label>
        <input
          type="text"
          className="form-control rounded-pill mt-1 "
          name="product.year"
          aria-label="product year"
          data-testid="product.year"
          value={ value }
          onChange={(e) => {
            setHasChanged(true);
            setHasBlured(false);
            onChange('year',e);
          }}
          onBlur={() => setHasBlured(true)}
        />
        <span style={{ display: (hasChanged && (!Boolean(value) || value==='') ) ? 'block' : 'none' }} className="text-danger small">Year is required.</span>
        <span style={{ display: (hasBlured && value && ( value?.length > 0 && value?.length < 4 ) ) ? 'block' : 'none' }} className="text-danger small">Invalid Year Format</span>
      </div>    </>
  )
}


export default ModalProductDetail

import React, { useState, useRef } from "react";
// import { useDispatch } from "react-redux";
import { Accordion, Card, Dropdown } from "react-bootstrap";
import { useFetch } from "../../../../../../utlis/api";
import { Divider } from '@mui/material';
import { useOrder } from "../../../../../../redux/orders/order"
import Slide from "@mui/material/Slide"
import Box from "@mui/material/Box"
// import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import moment from 'moment'
import DocumentInfoTemplate from "./DocumentInfoTemplate";
import { useBlankCard, useMarginTop, useNewCardHeight } from "./documentHelpers";
import "./documentStyles.css"
import { useDispatch } from "react-redux";
import { setActiveModal } from '../../../../../../redux/app/appSaver';

const TradeinDocumentsSection = ({tradein, documents, deleteDocument, setDocumentFocus, isOpen, setIsOpen, readOnly, isDeletingDoc, isDocActionDisabled, isAddingDocument}) => {  
  const axios = useFetch()
  const dispatch = useDispatch();

  let order = useOrder();
  const [location, setLocation] = useState(undefined);
  const [selectedDocument, setselectedDocument] = useState(undefined);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const cardRef = useRef(null);
  const [showBlankCard, setShowBlankCard] = useState(false)
  const [newCardHeight, setNewCardHeight] = useState('130px')
  const [marginTopValue, setMarginTopValue] = useState('130px')
  useBlankCard(isAddingDocument, setShowBlankCard)
  useMarginTop(cardRef, selectedDocument, setMarginTopValue)
  useNewCardHeight(cardRef, isAddingDocument, setNewCardHeight)

  function handleResize () {
    if(cardRef?.current?.offsetHeight){
    setMarginTopValue(`-${cardRef?.current?.offsetHeight}px`)
    setNewCardHeight(`${cardRef?.current?.offsetHeight}px`)
    }
  }

  window.addEventListener('resize', handleResize)

  async function getLocation(location_id){
      return await axios.get(`/location/${location_id}`);
  }  

  const [fetchingLocation, setFetchingLocation] = useState(false);
  if(location === undefined && order && order.location && order.location.id && !fetchingLocation){
    setFetchingLocation(true);
    getLocation(order.location.id).then(res => {
      setLocation(res.data);
    });
  }

  function normalizeSlug(slug){
    if(slug.includes("tec-sales-order")){
      return "tec-sales-order";
    }

    if(slug.includes("tec-poa")){
      return "tec-poa";
    }

    if(slug.includes("tec-statement-of-error")){
      return "tec-statement-of-error";
    }

    return slug;
  }

  function getAssignedTradeVin(document){
    if(document && document.assignedObjectType && document.assignedObjectType === 'tradein' && document.assignedObjectId && document.assignedObjectId > 0){
      var foundTrade = order.tradeins.find((trade) => trade.id === Number(document.assignedObjectId));
      if(foundTrade){
        return foundTrade.vin.toUpperCase();
      }
    }
    return ""; 
  }

  const setFormModal = (documentSlug) => {
    return ('#'+documentSlug);
  }

  function normalizeDocumentName(documentTemplate){
    if(documentTemplate){
      let slug = documentTemplate.slug;
      let newName = documentTemplate.name;
      if(slug === "tec-sales-order-multi" || slug === "tec-sales-order-multi-trade-multi"){
        newName = "TEC Sales Order";
      }

      if(slug === "tec-poa-no-notary"){
        newName = "TEC Power of Attorney";
      }

      if(slug === "tec-statement-of-error-notary"){
        newName = "TEC Statement of Error";
      }
      
      return newName;
    }
    return "";
  }
  
  return (
    <div className="mx-2">
      <div>
        <Accordion defaultActiveKey="0" className="document-accordion"  data-testid="tradeinDocuments.tradein.accordian" data-testcontext={"tradeinVinNumber: " + tradein?.vin + ", tradeinId: " + tradein?.id}>
    {documents !== undefined && documents.length > 0 ? documents.sort((a, b) => {
        if (a.documentTemplate.name.includes('TEC') && !b.documentTemplate.name.includes('TEC')) {
          // If a contains 'TEC' but b does not, push a to the start of the array
          return -1;
        } else if (!a.documentTemplate.name.includes('TEC') && b.documentTemplate.name.includes('TEC')) {
          // If b contains 'TEC' but a does not, push b to the start of the array
          return 1;
        } else {
          // Otherwise, sort alphabetically
          return a.documentTemplate.name.localeCompare(b.documentTemplate.name);
        }
      }).map((document, index) => (
        <div key={document?.id} data-testid="tradeinDocuments.tradein.document.topDiv" data-testcontext={"documentSlug: " + document?.documentTemplate?.slug + ", documentId: " + document?.id}>
        { (document?.assignedObjectId === tradein.id && document?.assignedObjectType === 'tradein') || (!document.assignedObjectId && !document.assignedObjectType) ? (
          <>
          {document?.isTemp === true ? (
           <>
           <Card 
             className={`p-1 mx-3 my-1 initial-blank-card disabled-cursor ${showBlankCard ? 'blank-card' : ''}`}
             style={{ height: showBlankCard ? newCardHeight : '0px' }}
           >
             <DocumentInfoTemplate isNewCard={true} createdAt={moment.utc(document.createdAt).format("MM/DD/YY")} type={document !== undefined && document.documentTemplate !== undefined ? normalizeDocumentName(document.documentTemplate) : '' } status={'DRAFT'}>
             </DocumentInfoTemplate>
           </Card>
         </>
         ) 
         : (
          <Card
          ref={cardRef}
          style={{ marginTop: isDeletingDoc === document.id ? marginTopValue : '10px' }}
          className={`card-initial-styles ${
            isDeletingDoc === document.id ? 'slide-out' : 'p-1 mx-3'}`}>
            <DocumentInfoTemplate type={document !== undefined && document.documentTemplate !== undefined ? normalizeDocumentName(document.documentTemplate) : '' } displayNumber={getAssignedTradeVin(document)} status={document.documentStatus} createdAt={moment.utc(document.createdAt).format("MM/DD/YY")} document={document} isDeletingDoc={isDeletingDoc} isDocActionDisabled={isDocActionDisabled} isNewCard={false} >
              { document && document.documentStatus === 'Draft' ? (
              <>
                <Dropdown.Item
                data-bs-toggle="modal"
                data-bs-target={setFormModal(normalizeSlug(document.documentTemplate.slug))}
                onClick={(e) => {
                   setDocumentFocus(document);
                   dispatch(setActiveModal("document-edit"));

                  }
                }
                >
                <b>Prepare</b>
                </Dropdown.Item>
                <Divider />
                <Dropdown.Item
                  onClick={e => {
                    setselectedDocument(document);
                    setIsDeleteModalOpen(true);
                    }
                  }
                >
                  <b>Delete Document</b>
              </Dropdown.Item>
              </>
              ):null}
              { document && (document.documentStatus === 'Prepared' || document.documentStatus === 'Generated') ? (
              <Dropdown.Item
                data-bs-toggle="modal"
                data-bs-target="#viewDocumentModal"
                onClick={(e) => {
                  setDocumentFocus(document);
                  dispatch(setActiveModal("document-view"));
                }}>
                <b>View</b>
              </Dropdown.Item>
              ):null}
              { !readOnly && document && (document.documentStatus === 'Prepared' || document.documentStatus === 'Generated') ? (
              <>
              <Dropdown.Item
                data-bs-toggle="modal"
                data-bs-target={setFormModal(normalizeSlug(document.documentTemplate.slug))}
                onClick={(e) => {
                   setDocumentFocus(document);
                   dispatch(setActiveModal("document-edit"));
                  }
                }

              >
                <b>Edit</b>
              </Dropdown.Item>
              <Dropdown.Item
                  onClick={e => {
                    setselectedDocument(document);
                    setIsDeleteModalOpen(true);
                    }
                  }
                >
                  <b>Delete Document</b>
              </Dropdown.Item>
              </>
              ):null}
            </DocumentInfoTemplate>
             <Accordion.Collapse eventKey={document.id} className="p-3" in={isOpen?.selectedProduct===document.id}>
              <div>
              </div>
            </Accordion.Collapse>
          </Card>)}
          </>
        ):null}
        </div>
    )):('')}
      </Accordion>
    </div>
    <Modal 
        id={`productdocumentmodaldeleteconfirm`}
        style={{width: '65vw', marginTop: '25vh', marginLeft: 'auto', marginRight: 'auto'}}
        open={isDeleteModalOpen}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide direction="down" in={isDeleteModalOpen} mountOnEnter unmountOnExit>
          <Box 
            // id={`${id}-open-wrapper`}
          >
            <div className="modal-content">
              <div className="modal-header bg-primary text-white">
                <h5
                  className="modal-title text-capitalize"
                >
                  Delete Document
                </h5>
                <button
                  type="button"
                  className="btn-close bg-white"
                  // data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() =>{
                    setIsDeleteModalOpen(false);
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <div className="px-3 py-3">
                  <h4 className="container text-secondary text-center my-3">
                    Are you sure you want to delete this document?
                  </h4>
                </div>
              </div>
              <div className="modal-footer text-center">
                <div className="mx-auto">
                  <button
                    type="button"
                    className="btn btn-primary mx-3"
                    onClick={(e) => {
                      deleteDocument(e, selectedDocument.id);
                      setIsDeleteModalOpen(false);
                    }}
                    data-bs-dismiss="modal"
                  >
                    Delete
                  </button>
                    <button
                    type="button"
                    className="btn btn-secondary mx-3"
                    // data-bs-dismiss="modal"
                    onClick={() =>{
                      setIsDeleteModalOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Box>
        </Slide>
    </Modal>
  </div>
  );
}

export default TradeinDocumentsSection
import _ from "lodash"
import { useSelector } from "react-redux"

/* DEFAULT STATE UPDATE TYPES */
const REFRESH_VALUES = 'REFRESH_VALUES'

//Simple values
const SET_VALUE   = 'SET_VALUE'
const EDIT_ENTITY = 'EDIT_ENTITY'

//Entities
const EDIT_ENTITY_FIELD       = 'EDIT_ENTITY_FIELD'
const SHOW_ENTITY_FIELD_ERROR = 'SHOW_ENTITY_FIELD_ERROR'
const HIDE_ENTITY_FIELD_ERROR = 'HIDE_ENTITY_FIELD_ERROR'

//Arrays
const REMOVE_ARRAY_ENTITY           = 'REMOVE_ARRAY_ENTITY'
const SET_ARRAY                     = 'SET_ARRAY'
const SET_ARRAY_ENTITY              = 'SET_ARRAY_ENTITY'
const SET_ARRAY_ENTITY_FIELD        = 'SET_ARRAY_ENTITY_FIELD'
const SHOW_ARRAY_ENTITY_FIELD_ERROR = 'SHOW_ARRAY_ENTITY_FIELD_ERROR'
const HIDE_ARRAY_ENTITY_FIELD_ERROR = 'HIDE_ARRAY_ENTITY_FIELD_ERROR'
const INSERT_EMPTY_INTO_ARRAY       = 'INSERT_EMPTY_INTO_ARRAY'

// const FIELD_ACTION_PREFIXES = [ 'SET', 'SHOW_ERROR', 'HIDE_ERROR' ]

export const buildReduxActionsAndSelectors = (initialState, options) => {

  const {
    entities=[],
    fields: ENTITY_FIELDS,
    lists=[],
    reduxPath,
    reduxPrefix,
    statePath,
    defaults=true
  } = options

  //Used by generators to append the redux prefix
  //to each action...
  const newAction = action => _.merge(action, {
    __prefix: reduxPrefix,
    __statePath: statePath
  })

  //Handles creating all simple set* action creators...
  function generateSetActionCreator( target, options ) {

    //i.e. SET_IS_LOADING
    const actionType        = _.snakeCase(' set ' + target).toUpperCase()
    const actionCreatorName = _.camelCase(actionType)       //setIsLoading
    const selectorName      = _.camelCase(' use ' + target) //useIsLoading

    /* Action creator */
    const actionCreator = value => {
      return newAction({
        type: actionType,
        payload: value,
        __target: target,
        __updateType: SET_VALUE
      })
    }

    /* Selector */
    const useSelectorHook = () => {
      return useSelector(state => {
        return _.get(state, reduxPath)[ target ]
      })
    }

    return {
      [ actionCreatorName ]: actionCreator, //i.e. setIsSelected = () => { }
      [ selectorName ]: useSelectorHook     //i.e. useIsSelected = () => { }
    }
  }

  //Action creators for editing an entity's fields
  //and showing/hiding errors relating to those fields...
  function generateEditEntityFieldsActionCreators(target, options) {
    //target i.e. editedCustomerInfo

    /* Holds all action creators relating to target */
    const actionCreators = {}

    //i.e. SET_EDITED_CUSTOMER_INFO, setEditedCustomerInfo()
    const editEntityActionType        = _.snakeCase(' SET ' + target).toUpperCase()
    const editEntityActionCreatorName = _.camelCase(editEntityActionType)

    /* Action creator to edit the entire object. */
    actionCreators[ editEntityActionCreatorName ] = ( arg1, arg2 ) => {

      const objLiteralArg = _.isNil( arg2 )

      if ( objLiteralArg ) {
        return newAction({
          type: editEntityActionType,
          payload: arg1,
          __updateType: EDIT_ENTITY,
          __target: target,
        })
      } else {
        const field = arg1
        const editFieldActionType =
          _.snakeCase(' SET ' + target + ' ' + field).toUpperCase()

        return newAction({
          type: editFieldActionType,
          payload: { field: arg1, value: arg2 },
          __updateType: EDIT_ENTITY_FIELD,
          __target: target,
        })
      }
    }

    /* Selector for the entity */
    const selectorName = _.camelCase(' use ' + target) //useEditedCustomerInfo
    const useEntity = () => {
      //i.e. state.customer.modal[ target ]
      return useSelector(state => _.get(state, reduxPath)[ target ])
    }

    actionCreators[ selectorName ] = useEntity

    /* Selector for the entity errors */
    const selectorNameErrors = _.camelCase(' use ' + target + ' Errors') //useEditedCustomerInfoErrors

    const useEntityErrors = () => {
      return useSelector(state => _.get(state,reduxPath).errors[ target ])
    }

    actionCreators[ selectorNameErrors ] = useEntityErrors

    /* Show errors and hide errors on the entity */
    /* i.e. SHOW_EDITED_CUSTOMER_INFO_ERROR, showEditedCustomerInfoPhoneError */
    const showEntityErrorActionType =
      _.snakeCase(' SHOW ' + target + ' ERROR ').toUpperCase()

    const showEntityErrorActionCreatorName = _.camelCase(showEntityErrorActionType)

    /* i.e. HIDE_EDITED_CUSTOMER_INFO_PHONE_ERROR, etc. */
    const hideEntityErrorActionType    = _.snakeCase(' HIDE ' + target + ' ERROR ').toUpperCase()
    const hideEntityErrorActionCreatorName = _.camelCase(hideEntityErrorActionType) //showEditCustomerPhoneError

    /* Show error action */
    actionCreators[ showEntityErrorActionCreatorName ] = field => {
      return newAction({
        type: showEntityErrorActionType,
        payload: field,
        __target: target,
        __updateType: SHOW_ENTITY_FIELD_ERROR
      })
    }

      /* Hide error action */
      actionCreators[ hideEntityErrorActionCreatorName ] = value => {
        return newAction({
          type: hideEntityErrorActionType,
          payload: value,
          __target: target,
          __updateType: HIDE_ENTITY_FIELD_ERROR
        })
      }

    //If we don't know the entities fields, we're done...
    if ( ! ENTITY_FIELDS[ target ] ) {
      return actionCreators
    }

    //Create action creators for each field...
    ENTITY_FIELDS[ target ].forEach( field => {

      /* i.e. SET_EDITED_CUSTOMER_INFO_PHONE, setEditedCustomerInfoPhone */
      const editFieldActionType         = _.snakeCase(' SET ' + target + ' ' + field).toUpperCase()
      const editFieldActionCreatorName  = _.camelCase(editFieldActionType)

      /* i.e. SHOW_EDITED_CUSTOMER_INFO_PHONE_ERROR, showEditedCustomerInfoPhoneError */
      const showFieldErrorActionType =
        _.snakeCase(' SHOW ' + target + ' ' + field + ' ERROR ').toUpperCase()
      const showFieldErrorActionCreatorName = _.camelCase(showFieldErrorActionType)

      /* i.e. HIDE_EDITED_CUSTOMER_INFO_PHONE_ERROR, etc. */
      const hideFieldErrorActionType    = _.snakeCase(' HIDE ' + target + ' ' + field + ' ERROR ').toUpperCase()
      const hideFieldErrorActionCreatorName = _.camelCase(hideFieldErrorActionType) //showEditCustomerPhoneError

      /* Edit action */
      actionCreators[ editFieldActionCreatorName ] = value => {
        return newAction({
          type: editFieldActionType,
          payload: value,
          __updateType: EDIT_ENTITY_FIELD
        })
      }

      /* Show error action */
      actionCreators[ showFieldErrorActionCreatorName ] = value => {
        return newAction({
          type: showFieldErrorActionType,
          payload: value,
          __target: target,
          __updateType: SHOW_ENTITY_FIELD_ERROR
        })
      }

      /* Hide error action */
      actionCreators[ hideFieldErrorActionCreatorName ] = value => {
        return newAction({
          type: hideFieldErrorActionType,
          payload: value,
          __updateType: HIDE_ENTITY_FIELD_ERROR
        })
      }

    })

    return actionCreators
  }

  function generateEditArrayEntityActionCreators( target, options ) {
    //Generates these action creators based on the target
    //i.e. SET_ADDED_CONTACT              | setAddedContact()
    //i.e. SET_ADDED_CONTACT_PHONE        | setAddedContactPhone()
    //i.e. SHOW_ADDED_CONTACT_PHONE_ERROR | showErrorAddedContactPhone()
    //i.e. HIDE_ADDED_CONTACT_PHONE_ERROR | hideErrorAddedContactPhone()
    const actions = {}

    //target i.e. addedContacts => addedContact
    const targetKey = target.substring(0, target.length-1)

    /* i.e. useAddedContacts */
    const arraySelectorName = _.camelCase(' use ' + target)
    const useArraySelector = () => {
      return useSelector(state => _.get(state,reduxPath)[ target ])
    }

    actions[ arraySelectorName ] = useArraySelector

    /* i.e. useAddedContactsErrors */
    const useErrorSelector = () => {
      return useSelector(state => _.get(state,reduxPath).errors[ target ])
    }

    const setArrayActionCreatorType = _.snakeCase(' set ' + target).toUpperCase()
    const setArrayActionCreatorName = _.camelCase(setArrayActionCreatorType)

    const setArrayActionCreator = list => {
      return newAction({
        type: setArrayActionCreatorType,
        payload: list,
        __target: target,
        __updateType: SET_ARRAY
      })
    }
    actions[ setArrayActionCreatorName ] = setArrayActionCreator

    const errorSelectorName       = _.camelCase(' use ' + target + ' errors ')
    actions[ errorSelectorName ]  = useErrorSelector

    let actionCreatorName =
      _.camelCase(' set ' + targetKey + ' at index ')

    /* i.e. SET_ADDED_CONTACT_AT_INDEX */
    //setAddedContactAtIndex(3,'phone','23232')
    const actionCreator = ( arg1, arg2, arg3 ) => {
      const index = arg1
      //i.e. SET_ADDED_CONTACT_AT_INDEX
      let actionType =
        _.snakeCase(' set ' + targetKey + ' at index ').toUpperCase()

      if ( _.isObject(arg2) ) {
        return newAction({
          type: actionType,
          payload: { index, values: arg2 },
          __target: target,
          __updateType: SET_ARRAY_ENTITY
        })
      }

      const field = arg2

      //i.e. SET_ADDED_CONTACT_AT_INDEX_PHONE
      actionType =
        _.snakeCase(' set ' + targetKey + ' at index ' + field).toUpperCase()

      return newAction({
        type: actionType,
        payload: { index, field: arg2, value: arg3 },
        __target: target,
        __updateType: SET_ARRAY_ENTITY_FIELD
      })
    }

    actions[ actionCreatorName ] = actionCreator

    const showErrorActionType =
      _.snakeCase(' show ' + targetKey + ' field error at index ').toUpperCase()
    const showErrorActionCreatorName = _.camelCase(showErrorActionType)

    const showErrorActionCreator = ( index, field ) => {
      return newAction({
        type: showErrorActionType,
        payload: { index, field },
        __target: target,
        __updateType: SHOW_ARRAY_ENTITY_FIELD_ERROR
      })
    }

    actions[ showErrorActionCreatorName ] = showErrorActionCreator

    const hideErrorActionType =
      _.snakeCase(' hide ' + targetKey + ' field error at index ').toUpperCase()
    const hideErrorActionCreatorName = _.camelCase(hideErrorActionType)

    const hideErrorActionCreator = ( index, field ) => {
      return newAction({
        type: hideErrorActionType,
        payload: { index, field },
        __target: target,
        __updateType: HIDE_ARRAY_ENTITY_FIELD_ERROR
      })
    }

    actions[ hideErrorActionCreatorName ] = hideErrorActionCreator

    const insertEmptyActionType =
      _.snakeCase(' insert empty ' + targetKey).toUpperCase()
    const insertEmptyActionCreatorName = _.camelCase(insertEmptyActionType)

    const insertEmptyActionCreator = ( index, field ) => {
      return newAction({
        type: insertEmptyActionType,
        payload: { index, field },
        __target: target,
        __updateType: INSERT_EMPTY_INTO_ARRAY
      })
    }

    actions[ insertEmptyActionCreatorName ] = insertEmptyActionCreator

    //REMOVE_ADDED_CONTACT_AT_INDEX
    const removeArrayEntityActionType =
      _.snakeCase(' remove ' + targetKey + ' at index ').toUpperCase()
    const removeArrayEntityActionCreatorName = _.camelCase(removeArrayEntityActionType)

    const removeArrayEntityActionCreator = index => {
      return newAction({
        type: removeArrayEntityActionType,
        payload: index,
        __target: target, //i.e. addedContacts
        __updateType: REMOVE_ARRAY_ENTITY
      })
    }

    actions[ removeArrayEntityActionCreatorName ] = removeArrayEntityActionCreator

    const FIELD_ACTION_PREFIX_MAP = {
      'SET':  SET_ARRAY_ENTITY_FIELD,       //i.e. Generate SET_ADDED_CONTACT_PHONE
      'SHOW': SHOW_ARRAY_ENTITY_FIELD_ERROR,//i.e. SHOW_ADDED_CONTACT_PHONE_ERROR
      'HIDE': HIDE_ARRAY_ENTITY_FIELD_ERROR,//i.e. HIDE_ADDED_CONTACT_PHONE_ERROR
    }

    if ( _.isEmpty( ENTITY_FIELDS[ target ] ) ) {
      return actions //Can't create field actions without the fields...
    }

    ENTITY_FIELDS[ target ].forEach( field => { //i.e. phone

      /* For each field generate set, show and hide action creators */
      _.keys(FIELD_ACTION_PREFIX_MAP).forEach( prefix => {

        const errorPostfixed =
          ( prefix.startsWith("SHOW") || prefix.startsWith("HIDE") ) ? " ERROR " : ""

        //i.e. SET_ADDED_CONTACT_PHONE, SHOW_ERROR_ADDED_CONTACT_PHONE
        const actionType =
          _.snakeCase(prefix + ' ' + targetKey + ' ' + field + ' ' + errorPostfixed).toUpperCase()
        const actionCreatorName = _.camelCase(actionType)

        const __updateType = FIELD_ACTION_PREFIX_MAP[ prefix ]

        /* i.e. SET_ADDED_CONTACT_PHONE, SHOW_ADDED_CONTACT_PHONE_ERROR etc.*/
        const actionCreator = value => {
          return newAction({
            type: actionType,
            payload: value,
            __target: target,
            __updateType
          })
        }

        //Include the action creator...
        actions[ actionCreatorName ] = actionCreator

      })

    })

    return actions
  }

  const actions = {}

  /* Generate custom hook for returning state values */
  const useRdxContext = ( additionalContext={} ) => {
    return useSelector(state => _.merge({ ...additionalContext }, _.get(state, reduxPath)))
  }

  const refreshValuesActionCreator = (values=[]) => {
    return newAction({
      payload: values,
      type: REFRESH_VALUES,
      __updateType: REFRESH_VALUES,
    })
  }

  actions.refreshValues = refreshValuesActionCreator

  actions.useRdxContext = useRdxContext

  const addActions = newActions => {
    _.merge(actions, newActions)
  }

  //Generate actions for all the keys
  //inside a portion of state i.e. 'state.modal'
  _.keys( initialState[ statePath ]).forEach( target => {

    if ( entities.includes(target) ) {
        //i.e. setEditedCustomerInfo
        //i.e. setEditedCustomerInfoPhone:
        //i.e. showEditedCustomerInfoPhoneError:
      addActions(generateEditEntityFieldsActionCreators(target))

    } else if ( lists.includes(target) ) {
        //i.e. addedContacts
        //i.e. addedCosigners
        addActions(generateEditArrayEntityActionCreators(target))
    } else if ( defaults ) { //generate regular 'set' actions for the other keys...
        //i.e.
        //setAction
        //setEditedSalesTaxExemption
        //setIsMailingAddressChecked
        addActions(generateSetActionCreator(target))
    }
  })

  return actions
}

/* REDUCER HELPERS */
// const handleRefreshValues = (state, action) => {
//   const target    = action.__target //i.e. isLoading, shouldShowX etc.
//   const statePath = action.__statePath
//   const values    = action.payload

// }

const handleSetValue = ( state, action ) => {
  const target    = action.__target //i.e. isLoading, shouldShowX etc.
  const statePath = action.__statePath
  const value     = action.payload

  return {
    ...state,
    [ statePath ]: {
      ...state[ statePath ],
      [ target ]: value
    }
  }
}

const handleEditEntity = (state, action) => {

  const entity      = action.__target       //i.e. editedCustomerInfo
  const isFieldEdit = _.has(action.payload, 'field')

  if ( isFieldEdit ) {
    //Only edit the field...
    return handleEditEntityField(state,action)
  }

  //Edit the entire object...
  const edit = _.defaults(action.payload)

  return {
    ...state,
    modal: {
      ...state.modal,
      [ entity ]: {
        ...state.modal[ entity ],
        ...edit
      }
    }
  }
}

// const handleClearEntity = (state, action) => {

//   const entity      = action.__target       //i.e. editedCustomerInfo
//   const isFieldEdit = _.has(action.payload, 'field')

//   // if ( isFieldEdit ) {
//   //   //Only edit the field...
//   //   return handleEditEntityField(state,action)
//   // }

//   //Edit the entire object...
//   const edit = _.defaults(action.payload)

//   return {
//     ...state,
//     modal: {
//       ...state.modal,
//       [ entity ]: {
//         ...state.modal[ entity ],
//         ...edit
//       }
//     }
//   }
// }


/* HANDLE WHEN ENTITY FIELD IS UPDATED */
const handleEditEntityField = ( state, action ) => {
  const entity = action.__target       //i.e. editedCustomerInfo
  const field  = action.payload.field
  const value  = action.payload.value

  return {
    ...state,
    modal: {
      ...state.modal,
      [ entity ]: {
        ...state.modal[ entity ],
        [ field ]: value
      }
    }
  }
}

/* HANDLE ENTITY FIELD ERROR */
const handleShowEntityFieldError = ( state, action ) => {
  const entity    = action.__target  //i.e. editedCustomerInfo
  const statePath = action.__statePath

  const field = action.payload   //i.e. phone

  //Will be an empty object if there are no errors...
  const errorsForEntityCurrent = state[ statePath ].errors[ entity ]

  //Place the error
  const errorsForEntityWithFieldError = {
    ...errorsForEntityCurrent,
    [ field ]: true
  }

  return {
    ...state,
    modal: {
      ...state.modal,
      errors: {
        ...state[ statePath ].errors,
        [ entity ]: errorsForEntityWithFieldError
      }
    }
  }
}

/* HANDLE HIDE ENTITY FIELD ERROR */
const handleHideEntityFieldError = ( state, action ) => {
  const entity = action.__target //i.e. editedCustomerInfo
  const field  = action.payload

  const errorsForEntityCurrent = state.modal.errors[ entity ]

  //Remove the error from state...
  const errorsForEntityWithoutFieldError = _.omit({
    ...errorsForEntityCurrent,
  }, field)

  //Return the updated state without the error
  return {
    ...state,
    modal: {
      ...state.modal,
      errors: {
        ...state.modal.errors,
        [ entity ]: errorsForEntityWithoutFieldError
      }
    }
  }
}

/* HANDLE ARRAY ENTITY FIELD EDITED */
const handleSetArray = ( state, action ) => {
  const entity    = action.__target //i.e. addedCosigners
  const statePath = action.__statePath

  const newState ={
    ...state,
    [ statePath ]: {
      ...state[ statePath ],
      [ entity ]: action.payload
    }
  }
  return newState
}

const handleEditArrayEntity = ( state, action ) => {
  const { index, values } = action.payload
  const entity    = action.__target //i.e. addedCosigners
  const statePath = action.__statePath

  const entityEditsForIndexCurrent =
    _.defaults(state[ statePath ][ entity ][ index ])

  const entityEditsForIndex = {
    ...entityEditsForIndexCurrent,
    ...values
  }

  const editedEntityArray =
    state[ statePath ][ entity ].map( ( entity, idx ) => {
      if ( idx === index ) {
        return entityEditsForIndex
      }
      return entity
    })

  return {
    ...state,
    [ statePath ]: {
      ...state[ statePath ],
      //i.e. [ 'addedContacts' ]
      [ entity ]: editedEntityArray
    }
  }
}

const handleEditArrayEntityField = ( state, action ) => {
  const entity    = action.__target //i.e. addedCosigners
  const statePath = action.__statePath

  const { index, field, value } = action.payload

  const entityEditsForIndexCurrent = _.defaults(state[ statePath ][ entity ][ index ])

  const entityEditsForIndex = { //{ name: "Bob" }
    ...entityEditsForIndexCurrent,
    [ field ]: value
  }

  const editedEntityArray =
    state[ statePath ][ entity ].map( ( entity, idx ) => {
      if ( idx === index ) {
        return entityEditsForIndex
      }
      return entity
    })

  return {
    ...state,
    [ statePath ]: {
      ...state[ statePath ],
      //i.e. [ 'addedContacts' ]
      [ entity ]: editedEntityArray
    }
  }
}

/* HANDLE ARRAY ENTITY FIELD ERROR */
const handleShowArrayEntityFieldError = (state, action) => {
  const entity    = action.__target   //i.e. addedcosigners
  const statePath = action.__statePath

  const { index, field } = action.payload

  //i.e. { } or { phone: true }
  const entityErrorsForIndexCurrent = _.defaults(state[ statePath ].errors[ entity ][ index ])

  const entityErrorsForIndex = { //{ name: true }
    ...entityErrorsForIndexCurrent,
    [ field ]: true
  }

  return {
    ...state,
    [ statePath ]: {
      ...state[ statePath ],
      errors: {
        ...state[ statePath ].errors,
        [ entity ]: {
          [ index ]: entityErrorsForIndex
        }
      }
    }
  }
}

/* HANDLE ARRAY ENTITY FIELD ERROR HIDE */
const handleHideArrayEntityFieldError = (state, action) => {
  const entity    = action.__target //i.e. addedcosigners
  const statePath = action.__statePath

  const { index, field } = action.payload

  //i.e. { } or { phone: true }
  const entityErrorsForIndexCurrent = _.defaults(state[ statePath ].errors[ entity ][ index ])

  const entityErrorsForIndex = _.omit({ //{ name: true }
    entityErrorsForIndexCurrent,
  }, field)

  //Remove the index from the entity errors if no
  //more errors exist at that index...
  const entityErrors = _.omitBy({
    ...state[ statePath ].errors[ entity ],
    [ index ]: entityErrorsForIndex
  }, _.isEmpty)

  return {
    ...state,
    [ statePath ]: {
      ...state[ statePath ],
      errors: {
        ...state[ statePath ].errors,
        [ entity ]: entityErrors
      }
    }
  }
}

const handleInsertEmptyIntoArray = ( state, action ) => {
  const entity    = action.__target //i.e. addedcosigners
  const statePath = action.__statePath

  return {
    ...state,
    [ statePath ]: {
      ...state[ statePath ],
      [ entity ]: [
        ...state[ statePath ][ entity ], {}
      ]
    }
  }
}

//Function to remove the error
function removeErrorForEntityAtIndex(entityErrors, deletedIndex) {

  //Handle shifting errros over...
  const hasEntityErrors = _.isEmpty(entityErrors) === false
  if ( ! hasEntityErrors ) {
    return entityErrors; //No errors, return the same object...
  }

  //Shift any errors over and remove the error at the index...
  const modifiedEntityErrors =
  _.keys( entityErrors ).reduce(( errors, errorIndex ) => {

    if ( errorIndex < deletedIndex ) { //Error index stay at the same key...
      return { ...errors, [ errorIndex ]: entityErrors[ errorIndex ] }

    } else if ( errorIndex === deletedIndex ) {
      return errors

    } else { //If it's greater than the deleted, we shift the error (right) by one...
      return { ...errors, [ errorIndex - 1 ]: entityErrors[ errorIndex ] }
    }
  }, {})

  return modifiedEntityErrors
}

const handleRemoveArrayEntity = ( state, action ) => {
  const entity        = action.__target //i.e. addedcosigners
  const statePath     = action.__statePath
  const deletedIndex  = action.payload

  const modifiedEntityErrors = //i.e. state.modal.addedCosigners.errors, 3
    removeErrorForEntityAtIndex(state[ statePath ].errors[ entity ], deletedIndex )

  //Handle removing the entity from the array...
  const modifiedEntityArr =
    state[ statePath ][ entity ].filter((_,idx) => idx !== deletedIndex )

  return {
    ...state,
    [ statePath ]: {
      ...state[ statePath ],
      [ entity ]: modifiedEntityArr,
      errors: {
        ...state[ statePath ].errors,
        [ entity ]: modifiedEntityErrors
      }
    }
  }
}

export const handleStateDefault = ( state, action ) => {

  const { __updateType } = action

  switch ( __updateType ) {

    /* Sets for general fields on state */
    case SET_VALUE:
      return handleSetValue(state,action);

    case EDIT_ENTITY:
      return handleEditEntity(state,action)

    // case CLEAR_ENTITY:
    //   return handleClearEntity(state,action)

    /* Enities not inside of an array */
    case EDIT_ENTITY_FIELD:
      return handleEditEntityField(state,action);

    case SHOW_ENTITY_FIELD_ERROR:
      return handleShowEntityFieldError(state,action);

    case HIDE_ENTITY_FIELD_ERROR:
      return handleHideEntityFieldError(state,action);

    /* Enities inside of an array */
    case SET_ARRAY:
      return handleSetArray(state,action)
    case SET_ARRAY_ENTITY:
      return handleEditArrayEntity(state,action)
    case SET_ARRAY_ENTITY_FIELD:
      return handleEditArrayEntityField(state,action)
    case SHOW_ARRAY_ENTITY_FIELD_ERROR:
      return handleShowArrayEntityFieldError(state,action)
    case HIDE_ARRAY_ENTITY_FIELD_ERROR:
      return handleHideArrayEntityFieldError(state,action)
    case INSERT_EMPTY_INTO_ARRAY:
      return handleInsertEmptyIntoArray(state,action)
    case REMOVE_ARRAY_ENTITY:
      return handleRemoveArrayEntity(state,action)
    default:
      return state
  }

}

import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";
import { salesPriceWithUpgradeCalc } from '../../../../../../../utlis/productCalculations.js';


export const NEForm6GlobalAutofill = (documentValues, order, property=null, value=null, location) => {

    var newValues = documentValues;
    
    if(property ==='customer.name'){
      newValues.name = value;
        if(newValues.dba){
          newValues.name += " dba "+newValues.dba;
        } else if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner){
            newValues.name += " and "+cosigner.name;
          }
      }
  }
    if(property ==='customer.dba'){
      newValues.name = order.customer.name;
      if (value!==''){
        newValues.name+= " dba " + value
      }else if (value==='' && order.customer.cosigners && order.customer.cosigners.length>0) {
        newValues.name+=' and ' +order.customer.cosigners[0].name
      }
    }
    if(property ==='customer.cosigner[0].name'){
      newValues.name = order.customer.name;
      if(value !==''){
          newValues.name += " and "+value;
      } else if(value==='' && order.customer.dba!==''){
        newValues.name+= " dba " + order.customer.dba
      }
    }
    if(property ==='customer.deliveryAddress.addressLine1'){
      newValues.street_or_other_address = value;
      if(value !== null && order.customer.deliveryAddress.addressLine2 !== null && order.customer.deliveryAddress.addressLine2 !== ''){
        newValues.street_or_other_address +=" "+order.customer.deliveryAddress.addressLine2;
      }
    }
    if(property ==='customer.deliveryAddress.city'){
        newValues.city = value;
      }
    
    if(property ==='customer.deliveryAddress.state'){
        newValues.state = value;
      }
    
    if(property ==='customer.deliveryAddress.zip'){
        newValues.zip = value;
      }
    
    if(property ==='documentationDate'){
      newValues.date_of_purchase = moment.utc(value).format("YYYY-MM-DD");
      newValues.date = moment.utc(value).format("YYYY-MM-DD");
      newValues.date_paid = moment.utc(value).format("YYYY-MM-DD");
      }
    
    if(property ==='location'){
      if(value.state === "CA"){
        newValues.name_2 = "TEC OF CALIFORNIA, INC.";
      }else{
        newValues.name_2 = "TEC EQUIPMENT, INC.";
      };
      newValues.street_or_other_address_2 = value.address;
      newValues.city_2 = value.city;
      newValues.state_2 = value.state;
      newValues.zip_2 = value.zip;
      }
    

    if(property !== null && value !== null){
      return newValues;
  }

    if(order && order.customer){
  
      if(order && order.customer && order.customer.name){
  
        // newValues.phone_number = order.customer.phone;
  
        // if(order.customer.type === "entity"){
          newValues.name = order.customer.name;
          if(order.customer.dba){
            newValues.name = order.customer.name+" dba "+order.customer.dba;
          }
        // }
    
        if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner){
            newValues.name += " and "+cosigner.name;
          }
      }
    }
      
      if(order && order.customer && order.customer.deliveryAddress){
        newValues.street_or_other_address = order.customer.deliveryAddress.addressLine1;
        if(order.customer.deliveryAddress.addressLine1 !== null && order.customer.deliveryAddress.addressLine2 !== null && order.customer.deliveryAddress.addressLine2 !== ''){
          newValues.street_or_other_address = newValues.street_or_other_address+" "+order.customer.deliveryAddress.addressLine2;
        }
        newValues.city = order.customer.deliveryAddress.city;
        newValues.state = order.customer.deliveryAddress.state;
        newValues.zip = order.customer.deliveryAddress.zip;
      }
  
    }
    
    if(order && order.documentationDate){
      newValues.date_of_purchase = moment.utc(order.documentationDate).format("YYYY-MM-DD");
      newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
      newValues.date_paid = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }
  
    if(location){
      if(location.state === "CA"){
        newValues.name_2 = "TEC OF CALIFORNIA, INC.";
      }else{
        newValues.name_2 = "TEC EQUIPMENT, INC.";
      }
      newValues.street_or_other_address_2 = location.address;
      newValues.city_2 = location.city;
      newValues.state_2 = location.state;
      newValues.zip_2 = location.zip;
    }
  
    return newValues
}

export const NEForm6AutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
  var newValues = documentValues;

  if(property === 'year' && value !== null){
    newValues.year = (value);
  }

  if(property === 'make' && value !== null){
    newValues.make = (value).toString().toUpperCase();
  } 

  if(property === 'vin' && value !== null){
    newValues.vin = (value).toString().toUpperCase();
  }

  if(property === 'model' && value !== null){
      newValues.model = (value).toString().toUpperCase();
  }

  if(property === 'price' && value !== null){
    newValues.total_sales_price = formatMoneyValue(salesPriceWithUpgradeCalc(selectedProduct));
  }

  if(property !== null && value !== null){
      return newValues;
  }

  newValues.year = (selectedProduct.year);
  newValues.make = (selectedProduct.make).toString().toUpperCase();
  newValues.model = (selectedProduct.model).toString().toUpperCase();
  newValues.vin = (selectedProduct.vin).toString().toUpperCase();
  newValues.total_sales_price = formatMoneyValue(salesPriceWithUpgradeCalc(selectedProduct));


  return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  if(payload.trade_leased_toggle){
    // Reset all
    payload.trade_in_lease_yes = "no";
    payload.trade_in_lease_no = "no";

    switch(payload.trade_leased_toggle){
      case "yes":
        payload.trade_in_lease_yes = "yes";
        break;
      case "no":
        payload.trade_in_lease_no = "yes";
        break;
      default:
    }
  }

  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  if(payload.date){
    payload.date = moment.utc(payload.date).format("MM/DD/YY");
  }

  if(payload.date_of_purchase){
    payload.date_of_purchase = moment.utc(payload.date_of_purchase).format("MM/DD/YY");
  }

  if(payload.date_paid){
    payload.date_paid = moment.utc(payload.date_paid).format("MM/DD/YY");
  }

  return payload;
}
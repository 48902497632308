import appSaverReducer from './app/appSaver';
import { combineReducers } from 'redux';
import { orderReducer } from './orders/order';
import { ordersReducer } from './orders/orders';
import {  financeDashboardReducer } from './finance_dashboard/financeDashboardReducer';
import { productFocusReducer } from './products/productFocusReducer';
import { tradeinsReducer } from './tradeins';
import { downpaymentsReducer } from './downpayments'
import { fundingPaymentsReducer } from './fundingPayments'
import { detailsReducer } from './orders/detailsReducer'
import { orderStatusesReducer } from './orders/orderStatusesReducer';
import { documentFocusReducer } from './documents/documentFocusReducer';
import { lenderFocusReducer } from './products/lenderFocusReducer';
import { customerReducer } from "./customers"
import { roleReducer } from "./role/roleReducer"
import spinnersReducer from './spinners/spinners'
import copyChangesToDocumentsReducer from './saveAndCopy'
import productsReducer from './products'
import meReducer from './me'
import allLocationsReducer from './allLocations'

export const rootReducer = combineReducers({
  appData: appSaverReducer,
  me: meReducer,
  allLocations: allLocationsReducer,
  customer: customerReducer,
  order: orderReducer,
  product: productsReducer,
  downpayments: downpaymentsReducer,
  fundingPayments: fundingPaymentsReducer,
  financeDashboardState: financeDashboardReducer,
  orderState: orderReducer, //TODO
  ordersState: ordersReducer,
  productFocus: productFocusReducer,
  tradeins: tradeinsReducer,
  detailsReducer: detailsReducer,
  orderStatuses: orderStatusesReducer,
  documentFocus: documentFocusReducer,
  lenderFocus: lenderFocusReducer,
  role: roleReducer,
  spinners: spinnersReducer,
  copyChangesToDocumentsRedux: copyChangesToDocumentsReducer,
})

import {createSlice} from '@reduxjs/toolkit'

export const copyChangesToDocumentsSlice = createSlice({
  name: "copyChangesToDocuments",
  initialState: {
    object: {
      id: null,
      groupId: null,
    },
    status: "init",
    saveFunction: null,
    copySection: "customer",
    copySubSection: "details",
    changesArray: [],
    groupChangesArray: [],
    copyChangesToDocumentsModal: {
      isOpen: false,
    },
  },
  reducers: {
    updateObjectId: (state, action) => {
      state.object.id = action.payload;
    },
    updateObjectGroupId: (state, action) => {
      state.object.groupId = action.payload;
    },
    updateStatus: (state, action) => {
      state.status = action.payload;
    },
    updateSaveFunction: (state, action) => {
      // Currently Used / Passed Functions
      // updateProductFob
      // newProductFob
      // updateProductShipSold
      // updateTaxAndFeesLineItem
      // updateFinanceFundingDetails
      // updateProductDetails

      state.saveFunction = action.payload;
    },
    updateCopySection: (state, action) => {
      state.copySection = action.payload;
    },
    updateCopySubSection: (state, action) => {
      state.copySubSection = action.payload;
    },
    updateChangesArray: (state, action) => {
      state.changesArray = action.payload;
    },
    updateGroupChangesArray: (state, action) => {
      state.groupChangesArray = action.payload;
    },
    updateCopyChangesToDocumentsModalIsOpen: (state, action) => {
      state.copyChangesToDocumentsModal.isOpen = action.payload;
    },
    resetAll: (state, action) => {
      state.object.id = null;
      state.object.groupId = null;
      state.status = "init";
      state.saveFunction = null;
      state.copySection = "customer";
      state.copySubSection = "details";
      state.changesArray = [];
      state.groupChangesArray = [];
    },
  },
});

export const { 

    updateObjectId,
    updateObjectGroupId,
    updateStatus,
    updateSaveFunction,
    updateCopySection,
    updateCopySubSection,
    updateChangesArray,
    updateGroupChangesArray,
    updateCopyChangesToDocumentsModalIsOpen,
    resetAll

} = copyChangesToDocumentsSlice.actions

export default copyChangesToDocumentsSlice.reducer
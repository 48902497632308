import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import { removeChars, formatter, formatPhoneNumber, formatUpperCase } from "../../../../../../../utlis/formatters";
import { getOrderLevelAddresses, updateAddressOptions } from "../../../../../../../utlis/documentHelpers";
import { useOrder } from "../../../../../../../redux/orders/order"
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { CABillOfSaleGlobalAutofill, CABillOfSaleAutofillProductDetails, CABillOfSaleAutofillTradeDetails, preProcessPayload } from "../DocumentAutofill/CABillOfSale"

const CABillOfSale = ({document_id, location, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "ca-bill-of-sale";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  // App Logic & Data //
  let { id } = useParams();
  const removeFormatting = e =>{
    const {name,value}=e.target;
    setDocumentValues({...documentValues,[name]:removeChars(value)});
  }

  const order = useOrder();
  const axios = useFetch()

  const [documentUrl, setdocumentUrl] = useState("");
  const [document, setDocument] = useState(undefined);
  const [previousDocumentId, setPreviousDocumentId] = useState(0);

  // Data Structure & Init //
  
  var initialDocumentStructure = {
    identification_number: undefined,
    model: undefined,
    make: undefined,
    license_plate: undefined,
    mc_engine_number: undefined,
    month: undefined,
    day: undefined,
    year: undefined,
    selling_price: undefined,
    gift_value: undefined,
    relationship_type: undefined,
    seller_name: undefined,
    seller_name_signature: undefined,
    seller_name_signature_2: undefined,
    seller_id: undefined,
    seller_id_2: undefined,
    sell_date: undefined,
    sell_date_2: undefined,
    seller_address: undefined,
    seller_city: undefined,
    seller_state: undefined,
    seller_zip: undefined,
    seller_phone: undefined,
    buyer_name: undefined,
    buyer_print_name: undefined,
    buyer_print_name_2: undefined,
    buyer_address: undefined,
    buyer_city: undefined,
    buyer_state: undefined,
    buyer_zip: undefined,
    copy_identification_number: undefined,
    copy_license_plate: undefined,
    copy_mc_engine_number: undefined,
    copy_buyer_name: undefined,
    copy_month: undefined,
    copy_day: undefined,
    copy_year_1: undefined,
    copy_year_2: undefined,
    copy_year_3: undefined,
    copy_year_4: undefined,
    copy_selling_price: undefined,
    copy_gift_value: undefined,
    copy_relationship_type: undefined,
    copy_seller_name: undefined,
    copy_seller_name_signature: undefined,
    copy_seller_name_signature_2: undefined,
    copy_sell_date: undefined,
    copy_sell_date_2: undefined,
    copy_seller_id: undefined,
    copy_seller_id_2: undefined,
    copy_seller_phone: undefined,
    copy_seller_zip: undefined,
    copy_seller_state: undefined,
    copy_seller_city: undefined,
    copy_seller_address: undefined,
    copy_buyer_print_name: undefined,
    copy_buyer_print_name_2: undefined,
    copy_buyer_address: undefined,
    copy_buyer_city: undefined,
    copy_buyer_state: undefined,
    copy_buyer_zip: undefined,
    copy_model: undefined
  }
  
  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  
  const moneyFields = useMemo(()=>{return["selling_price", "gift_value"]},[]);
  const phoneFields = useMemo(()=>{return["seller_phone"]},[]);
  const upperCaseFields = useMemo(()=>{return["identification_number"]},[]);
  const checkboxes = useMemo(()=>{return[]},[]);

const handleDocumentValuesChange = e =>{
  const {name,value}=e.target;

  if(moneyFields.indexOf(name) >= 0){
    // To eliminate text being added
    setDocumentValues({...documentValues,[name]:removeChars(value)});
  }else if(phoneFields.indexOf(name) >= 0){
    setDocumentValues({...documentValues,[name]:removeChars(value)});
  }else if(upperCaseFields.indexOf(name) >= 0){
    const savedPos = e.target.selectionStart;
    setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
    setTimeout(() => {
      e.target.setSelectionRange(savedPos, savedPos);
    }, 0)
  }else{
    setDocumentValues({...documentValues,[name]:value});
  }
  setHasUnsavedChanges(true);
  setSaveSuccess(false);
}
  
  // const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('CABillOfSale.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])

  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      // setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, loadDocument ])
  const [assignedProduct, setAssignedProduct] = useState(undefined);
  const [assignedTrade, setAssignedTrade] = useState(undefined);

  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const [addressOptions, setAddressOptions] = useState(undefined);
  const [inputAddressVal, setinputAddressVal] = useState(undefined);

  const [locationVal, setLocationVal] = useState(undefined);
  const [selectedLocation, setselectedLocation] = useState(undefined);
  const [inputLocationVal, setinputLocationVal] = useState(undefined);
  const [locationOptions, setLocationOptions] = useState(undefined);
  const [locationToggleAutoFill, setLocationToggleAutoFill] = useState(false);

// Dropdowns //

if(locations && locations.length > 0 && locationOptions === undefined){
  let getLocationOptions = [];
  locations.map(location => {
    let str = `${location.description}`;
    let obj = {locationCode: location.locationCode, description: str}
    getLocationOptions.push(obj);
    return null;
  })
  setLocationOptions(getLocationOptions);
}


 // Autofill //

const GlobalAutofill = useCallback(async () => {

  let newValues = CABillOfSaleGlobalAutofill(documentValues, order);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

},[ documentValues, order ])

const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });

    if(document){
      var docCopy = JSON.parse(JSON.stringify(document));
      docCopy.payload =  JSON.parse(JSON.stringify(payload));
      docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
      docCopy.payload =  JSON.stringify(docCopy.payload);
      delete docCopy.documentStatus;

      try {
        let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
          const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
          setdocumentUrl(fileURL)
      } catch (err) {
        recordFrontEndError('CABillOfSale.jsx', 'Error previewing document payload: ' + err)
      }
    }
},[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


const AutofillProductDetails = useCallback(async (selectedProduct) => {
  let newValues = CABillOfSaleAutofillProductDetails(selectedProduct, documentValues);

  if(newValues){
    setDocumentValues(newValues);
    previewPayloadDocument(documentValues);
    setHasUnsavedChanges(true);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setSaveSuccess(false);
  }

  // var newValues = documentValues;
  // // newValues.year = (selectedProduct.year).trim();
  // newValues.make = (selectedProduct.make).trim();
  // newValues.model = (selectedProduct.year);
  // newValues.identification_number = (selectedProduct.vin.toUpperCase());
  // newValues.selling_price = selectedProduct.price;

  // newValues.copy_make = (selectedProduct.make).trim();
  // newValues.copy_model = (selectedProduct.year);
  // newValues.copy_identification_number = (selectedProduct.vin.toUpperCase());
  // newValues.copy_selling_price = selectedProduct.price;
  

  // setDocumentValues(newValues);
  // // setProductToggleAutoFill(false);
  // if(document){
  //   previewPayloadDocument(documentValues);
  // }
  // // setAddressOptions(updateAddressOptions(selectedProduct, order));
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ document, documentValues, previewPayloadDocument ]);

const AutofillTradeDetails = useCallback(async (selectedTradein) => {
  let newValues = CABillOfSaleAutofillTradeDetails(selectedTradein, documentValues);

  if(newValues){
    setDocumentValues(newValues);
    previewPayloadDocument(documentValues);
    setHasUnsavedChanges(true);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setSaveSuccess(false);
  }

  // var newValues = documentValues;
  // // newValues.year = (selectedProduct.year).trim();
  // newValues.make = (selectedTradein.make).trim();
  // newValues.model = (selectedTradein.year)
  // newValues.identification_number = (selectedTradein.vin.toUpperCase());

  // newValues.copy_make = (selectedTradein.make).trim();
  // newValues.copy_model = (selectedTradein.year)
  // newValues.copy_identification_number = (selectedTradein.vin.toUpperCase());
  

  // setDocumentValues(newValues);
  // // setProductToggleAutoFill(false);
  // if(document){
  //   previewPayloadDocument(documentValues);
  // }
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ document, documentValues, previewPayloadDocument ]);

  // Document BREAD + Preview // TODO: Move to Redux
  useEffect(() => {
    if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){
  
      existingPayload = true;
  
      if(documentPayload.buy_date){
        documentPayload.buy_date = moment.utc(documentPayload.buy_date).format("YYYY-MM-DD");
      }

      if(documentPayload.sell_date){
        documentPayload.sell_date = moment.utc(documentPayload.sell_date).format("YYYY-MM-DD");
      }

      if(documentPayload.sell_date_2){
        documentPayload.sell_date_2 = moment.utc(documentPayload.sell_date_2).format("YYYY-MM-DD");
      }

      if(documentPayload.copy_sell_date){
        documentPayload.copy_sell_date = moment.utc(documentPayload.copy_sell_date).format("YYYY-MM-DD");
      }

      if(documentPayload.copy_sell_date_2){
        documentPayload.copy_sell_date_2 = moment.utc(documentPayload.copy_sell_date_2).format("YYYY-MM-DD");
      }
    
      setDocumentValues(documentPayload);

      // Loading Use Effect
      if(document.additionalData){
        const methodMap = { 
          selectedAddress: setSelectedAddress, 
          selectedLocation: setselectedLocation, 
        };

        let parsedAdditionalData = JSON.parse(document.additionalData);
        Object.keys(parsedAdditionalData).forEach(function(key) {
          if(methodMap[key]){
            if(key === "selectedLocation"){
              setLocationVal(parsedAdditionalData[key]);
              if(parsedAdditionalData[key].locationCode){
                setselectedLocation(locations.find((location) => location.locationCode === parsedAdditionalData[key].locationCode));
              }
            }else{
              methodMap[key](parsedAdditionalData[key]);
            }
          }
        });
      }
    }

    var foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }
    
    setAddressOptions(getOrderLevelAddresses(order));
    if(foundAssignedProduct){
        setAssignedProduct(foundAssignedProduct);
        setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
    }
    
    var foundAssignedTradein = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'tradein'){
      foundAssignedTradein = order.tradeins.find((trade) => trade.id === document.assignedObjectId);
    }
    
    if(foundAssignedTradein){
      setAssignedTrade(foundAssignedTradein);
    }
    
    
    if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
        if(foundAssignedTradein){
          AutofillTradeDetails(foundAssignedTradein)
        }
      }else{
        // if(document && document.itemIds){
        //   var itemIds = document.itemIds.split(',');
        //   var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
        //   let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
        //   let obj = {id: foundProduct.id, name: str}
        //   setproductVal(obj);
        //   if(foundProduct){
        //     setAddressOptions(updateAddressOptions(foundProduct, order));
        //   }
        //   streamDocument();
        // }
        
      }
      
      setDataLoaded(true);
    }
  },[ locations, documentValues, document, dataLoaded, order, AutofillProductDetails, AutofillTradeDetails, GlobalAutofill, previewPayloadDocument ])


  
  
    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();

      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
  
  
      // const payload = JSON.parse(JSON.stringify(documentValues));
      // var docCopy = document;
      // docCopy.payload = payload;
      // docCopy.payload = preProcessPayload(docCopy.payload);
      // docCopy.payload =  JSON.stringify(docCopy.payload);
      // delete docCopy.documentStatus;
      
      // if(selectedProduct && selectedProduct.id){
      //   docCopy.itemIds = `${selectedProduct.id}`;
      // }
      //Save Dropdown Selections
      let dropdownData = {};
      if(selectedAddress){
        dropdownData.selectedAddress = {
          id: selectedAddress.id,
          name: selectedAddress.name
        };
      }
      if(selectedLocation){
        dropdownData.selectedLocation = {
          locationCode: selectedLocation.locationCode,
          description: selectedLocation.description
        };
      }
      let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, additionalData, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });

  
      // //TODO: Add TradeId's to save.
      // try {
      //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
      //   if (res) {
      //     await axios.post(`/document/${document.id}/generate`)
      //     getDocuments();
      //     setSaveSuccess(true);
      //     setHasUnsavedChanges(false);
      //   }
      // } catch (err) {
      //   recordFrontEndError('CABillOfSale.jsx', 'Error saving document: ' + err)
      // }
    }

// Component Helpers //
// useEffect(() => {
  //Autofill Product Details After Selection

// },[ selectedProduct, productToggleAutoFill ])

// useEffect(() => {
//   //Autofill Tradein Details After Selection
//   if(selectedTradein !== undefined && tradeinToggleAutoFill){
//     var newValues = documentValues;
//     newValues.trade_year = (selectedTradein.year);
//     newValues.trade_make = (selectedTradein.make);
//     newValues.trade_model = (selectedTradein.model);
//     newValues.trade_body_type = (selectedTradein.seriesBodyType);
//     newValues.trade_serial_number = (selectedTradein.serialNumber);
//     newValues.balance_owner = (order.customer.name); //Need specific field for TRADE IN OWNER?
//     newValues.balance_owner_address = (order.customer.deliveryAddress.addressLine1);
//     newValues.amount = (selectedTradein.balanceOwed);
//     newValues.trade_allowance = (selectedTradein.tradeAllowanceAtTerms);
//     newValues.balance_owed = (selectedTradein.balanceOwed);
//     newValues.cash_refund = (selectedTradein.cashRefundToCustomer);
//     newValues.net_trade_allowance = (selectedTradein.finalTradeAllowance);
//     // newValues.cash_down_payment = (selectedTradein);

//     setDocumentValues(newValues);
//     setTradeinToggleAutoFill(false);
//     if(document){
//       previewPayloadDocument(documentValues);
//     }
//   }
// },[ selectedTradein, tradeinToggleAutoFill ])

useEffect(() => {
  //Autofill Tradein Details After Selection
  if(selectedLocation !== undefined && locationToggleAutoFill){
    var newValues = documentValues;
    newValues.seller_address = selectedLocation.address;
    newValues.seller_city = selectedLocation.city;
    newValues.seller_state = selectedLocation.state;
    newValues.seller_zip = selectedLocation.zip;
    newValues.seller_phone = formatPhoneNumber(selectedLocation.phone);

    newValues.seller_id = "31422";

    if(selectedLocation.state === "CA"){
      newValues.seller_name_signature = "TEC OF CALIFORNIA, INC";
      newValues.copy_seller_name_signature = "TEC OF CALIFORNIA, INC";
    }else{
      newValues.seller_name_signature = "TEC EQUIPMENT, INC.";
      newValues.copy_seller_name_signature = "TEC EQUIPMENT, INC.";
    }

    setDocumentValues(newValues);
    setLocationToggleAutoFill(false);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
},[ selectedLocation, locationToggleAutoFill, document, documentValues, previewPayloadDocument ])

//Autofill Address Details After Selection
function AutoFillAddress(addressSelected, selectionInstance){
  var newValues = documentValues;
  if(selectionInstance){
    if(selectionInstance === "buyer-address"){
      newValues.buyer_address = addressSelected.address;
      newValues.buyer_city = addressSelected.city;
      newValues.buyer_state = addressSelected.state;
      newValues.buyer_zip = addressSelected.zip;
      if(order && order.customer){
        newValues.buyer_print_name = order.customer.name;
      }
    }
  }
  setDocumentValues(newValues);
  if(document){
    previewPayloadDocument(documentValues);
  }
  setHasUnsavedChanges(true);
  setSaveSuccess(false);
}

const onCloseClick = e => {
  e.preventDefault()
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setdocumentUrl("");
  setDocument(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
  // setDocumentLoaded(false);
  setDataLoaded(false);
  setAssignedProduct(undefined);
  setAssignedTrade(undefined);
  setSelectedAddress(undefined);
  setAddressOptions(undefined);
  setinputAddressVal(undefined);
  setLocationVal(undefined);
  setselectedLocation(undefined);
  setinputLocationVal(undefined);
  setLocationOptions(undefined);
  setLocationToggleAutoFill(false);
  setDocumentFocus(undefined);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

  return (
    <>
      <div
        className="modal fade"
        // id="ca-bill-of-sale"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                CA - Bill of Sale
              </h5>
              <br />
              { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1 pt-1 text-white">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                  </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}
                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                  
                <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                    GlobalAutofill();
                    if(assignedProduct){
                      AutofillProductDetails(assignedProduct);
                    }

                    if(assignedTrade){
                      AutofillTradeDetails(assignedTrade)
                    }

                    previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                </div>

                <br />

                { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Product Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                        </div>
                    </div>
                ):null}

                { assignedTrade ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Trade In Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                          <h5>VIN: { assignedTrade.vin.toUpperCase() }</h5>
                          <h5>YMM: { assignedTrade.year+" "+assignedTrade.make+" "+assignedTrade.model.toUpperCase() }</h5>
                        </div>
                    </div>
                ):null}

                  <div className="row">
                    <h5>Equipment</h5>
                  </div>

                  <div className="row">
                    <label htmlFor="stock"><b>VEHICLE/VESSEL DESCRIPTION</b></label>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Identification Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="identification_number"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.identification_number ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Model</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="model"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.model ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">License Plate</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="license_plate"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.license_plate ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">MC Engine Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mc_engine_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mc_engine_number ?? '' }
                          />
                      </div>
                    </div>


                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Seller Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Buyer Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_name ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Buy Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="buy_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buy_date ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Selling Price</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="selling_price"
                          onFocus={removeFormatting}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.selling_price ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly mb-3">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Relationship Type</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="relationship_type"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.relationship_type ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Gift Value</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="gift_value"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.gift_value ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    <label htmlFor="stock"><b>SELLER</b></label>
                    { locationOptions && locationOptions.length > 0 ? (
                    <div className="d-flex justify-content-evenly">
                      <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                          style={{ width: '100%'}}
                          value={ (locationVal && locationVal.description) ?? null}
                          onChange={(event, newValue) => {
                              setLocationVal(newValue);
                              if(newValue && newValue.locationCode){
                                setselectedLocation(locations.find((location) => location.locationCode === newValue.locationCode));
                                setLocationToggleAutoFill(true);
                              }
                              // resetVariables();
                          }}
                          inputValue={inputLocationVal}
                          onInputChange={(event, newInputValue) => {
                              setinputLocationVal(newInputValue);
                          }}
                          //id="controllable-states-demo"
                          options={locationOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="SELLING LOCATION" />}
                          renderOption={(props, option) => {
                              return (
                              <li {...props} key={option.locationCode}>
                                  {option.description}
                              </li>
                              );
                          }}
                          />
                      </div>
                    </div>):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Locations Found</h5>
                      </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Seller - Print Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_name_signature"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_name_signature ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="sell_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.sell_date ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">DL, ID OR DEALER #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_id"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_id ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Seller - Print Name (2)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_name_signature_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_name_signature_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="sell_date_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.sell_date_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">DL, ID OR DEALER #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_id_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_id_2 ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Mailing Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_address ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-2">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_zip ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Phone</label>
                        <input
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          onFocus={removeFormatting}
                          className="form-control rounded-pill mt-1"
                          name="seller_phone"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_phone ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    <label htmlFor="stock"><b>BUYER</b></label>
                    {addressOptions && addressOptions.length > 0 ? (  
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress && selectedAddress.name) ?? null}
                          name="buyer-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress(newValue);
                            AutoFillAddress(newValue, "buyer-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="ADDRESS" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>                    ):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Buyer - Print Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_print_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_print_name ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Buyer - Print Name (2)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_print_name_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_print_name_2 ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Mailing Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_address ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_zip ?? '' }
                          />
                      </div>
                    </div>


                </div>
                <hr></hr>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default CABillOfSale

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
// import { Autocomplete, TextField } from '@mui/material';
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
import { removeChars, formatter, formatPhoneNumber, formatUpperCase } from "../../../../../../../utlis/formatters";
import { getOrderLevelAddresses, updateAddressOptions } from "../../../../../../../utlis/documentHelpers";
import { useOrder } from "../../../../../../../redux/orders/order";
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { AZBillOfSaleGlobalAutofill, AZBillOfSaleAutofillProductDetails, preProcessPayload } from "../DocumentAutofill/AZBillOfSale"
import { TextField, Grid, FormLabel, FormControl } from "@mui/material";
import moment from "moment";

const AZBillOfSale = ({document_id, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "az-bill-of-sale";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  // const dispatch = useDispatch();

  const order = useOrder();
  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);

  const [documentValues, setDocumentValues] = useState({});
  const [addressOptions, setAddressOptions] = useState(undefined);


  // const removeFormatting = e =>{
  //   const {name,value}=e.target;
  //   setDocumentValues({...documentValues,[name]:removeChars(value)});
  // }

  


  const moneyFields = useMemo(()=>{return["list_price", "lien_amount"]},[]);
  const phoneFields = useMemo(()=>{return[""]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "model"]},[]);
  const checkboxes = useMemo(()=>{return[
    "rented_wo_driver_cb",
    "station_wagon_cb",
    "semi_cb",
    "add_lien_holder_cb"
  ]},[]);


  const handleDocumentValuesChange = e =>{
    // e.preventDefault();
    const {name,value,type}=e.target;

    

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatPhoneNumber(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else if(type==='date'){
      const formattedDate = moment(value).format("MM-DD-YYYY");
      setDocumentValues({...documentValues,[name]:formattedDate});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  
  const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('AZTitleApp.jsx', 'Error loading document: ' + err)
      }      
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, documentLoaded, loadDocument ])
  const [assignedProduct, setAssignedProduct] = useState(undefined);

  const [selectedAddress, setSelectedAddress] = useState(undefined);

 // Autofill //

 const GlobalAutofill = useCallback(async () => {
  let newValues = AZBillOfSaleGlobalAutofill(documentValues, order);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

},[ documentValues, order ])

const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });
    var docCopy = JSON.parse(JSON.stringify(document));
    docCopy.payload =  JSON.parse(JSON.stringify(payload));
    docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
    docCopy.payload =  JSON.stringify(docCopy.payload);
    delete docCopy.documentStatus;
    try{
      let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
      const file = new Blob(
        [response.data], 
        {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      setdocumentUrl(fileURL)
    } catch (err) {
      recordFrontEndError('AZBillOfSale.jsx', 'Error previewing document payload: ' + err)
    }
},[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


  // Document BREAD + Preview // TODO: Move to Redux

  //Autofill Product Details After Selection
  const AutofillProductDetails = useCallback(async (selectedProduct) => {

    let newValues = AZBillOfSaleAutofillProductDetails(selectedProduct, documentValues);

    if(newValues){
      setDocumentValues(newValues);
      previewPayloadDocument(documentValues);
      setHasUnsavedChanges(true);
      if(document){
        previewPayloadDocument(documentValues);
      }
      setSaveSuccess(false);
    }

  },[ document, documentValues, previewPayloadDocument ]);

  useEffect(() => {
    if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){
  
      existingPayload = true;
  
      // if(documentPayload.changes_date){
      //   documentPayload.changes_date = moment.utc(documentPayload.changes_date).format("YYYY-MM-DD");
      // }
      // Loading Use Effect
    if(document.additionalData){
      const methodMap = { 
        selectedAddress: setSelectedAddress,
      };

      let parsedAdditionalData = JSON.parse(document.additionalData);
      Object.keys(parsedAdditionalData).forEach(function(key) {
        if(methodMap[key]){
          methodMap[key](parsedAdditionalData[key]);
        }
      });
    }
  
      setDocumentValues(documentPayload);
    }
  
    var foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }
    
    setAddressOptions(getOrderLevelAddresses(order));
    if(foundAssignedProduct){
        setAssignedProduct(foundAssignedProduct);
        setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
    }

      if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
      }
      
      setDataLoaded(true);
    }
  },[ documentValues, document, dataLoaded, AutofillProductDetails, GlobalAutofill, order, previewPayloadDocument ])



  
    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();

      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
      
      let dropdownData = {};
      if(selectedAddress){
        dropdownData.selectedAddress1 = {
          id: selectedAddress.id,
          name: selectedAddress.name
        };
      }

      let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, additionalData, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });
    }

//Autofill Address Details After Selection
// function AutoFillAddress(addressSelected, selectionInstance){
//   var newValues = documentValues;
//   if(selectionInstance){
//     if(selectionInstance === "mailing-address"){
//       newValues.mailing_address = addressSelected.address;
//       newValues.mailing_city = addressSelected.city;
//       newValues.mailing_state = addressSelected.state;
//       newValues.mailing_zip = addressSelected.zip;
//     }

//   }
//   setDocumentValues(newValues);
//   if(document){
//     previewPayloadDocument(documentValues);
//   }
//   setHasUnsavedChanges(true);
//   setSaveSuccess(false);
// }

const onCloseClick = e => {
  e.preventDefault()
  setDataLoaded(false);
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setAssignedProduct(undefined);
  setdocumentUrl("");
  setDocument(undefined);
  setDocumentFocus(undefined);
  setSelectedAddress(undefined);
  setAddressOptions(undefined);
  setPreviousDocumentId(0);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

const setSellerLocation = (e) =>{
  const loc = locations.find((i)=> i.description===e.target.value)
  const updatedValues = {
    ...documentValues,
    mailing_address_2: loc.address,
    mailing_address_state_2: loc.state,
    mailing_address_city_2: loc.city,
    mailing_address_zip_2: loc.zip,
  };

  setDocumentValues(updatedValues);
}

const setBuyerLocation = (e) => {
  const add = addressOptions.find((i)=> i.name===e.target.value)

  const updatedValues = {
    ...documentValues,
    mailing_address: add.address,
    mailing_address_state: add.state,
    mailing_address_city: add.city,
    mailing_address_zip: add.zip,
  };

  setDocumentValues(updatedValues);

}

const formatPotentialDate = (value, type) => {
  if (type === 'date' && value) {
    return moment(value, "MM-DD-YYYY").format("YYYY-MM-DD");
  }
  return value ?? '';
};

const sellerInfo = [
  { label: 'Seller Name', name: 'seller_name', type: 'text', size: 12 },
  { label: "Driver's License Number", name: 'drivers_license_number_2', type: 'text', size: 4 },
  { label: 'Date of Birth', name: 'dob_2', type: 'date', size: 4 },
  // { label: 'Mailing Address', name: 'mailing_address_2', type: 'text', size: 12 },
  // { label: 'City', name: 'mailing_address_city_2', type: 'text', size: 4 },
  // { label: 'State', name: 'mailing_address_state_2', type: 'text', size: 4 },
  // { label: 'Zip', name: 'mailing_address_zip_2', type: 'text', size: 4 },
];

const buyerInfo = [
  { label: 'Buyer Name', name: 'buyer_name', type: 'text', size: 12 },
  { label: "Driver's License Number", name: 'drivers_license_number', type: 'text', size: 4 },
  { label: 'Date of Birth', name: 'dob', type: 'date', size: 4 },
  { label: 'Mailing Address', name: 'mailing_address', type: 'text', size: 12 },
  { label: 'City', name: 'mailing_address_city', type: 'text', size: 4 },
  { label: 'State', name: 'mailing_address_state', type: 'text', size: 4 },
  { label: 'Zip', name: 'mailing_address_zip', type: 'text', size: 4 },
];

const saleInfo = [
  { label: 'Sale Date', name: 'sale_date', type: 'date', size: 4 },
  { label: 'Sale Payment Amount', name: 'sale_payment_amount', type: 'text', size: 4 },
  { label: 'County', name: 'county', type: 'text', size: 4 },
  { label: 'State', name: 'state', type: 'text', size: 4 },
  { label: 'Commission Expires', name: 'commission_expires', type: 'date', size: 4 },
];

const vehicleInfo = [
  { label: 'VIN', name: 'vin', type: 'text', size: 12 },
  { label: 'Year', name: 'year', type: 'text', size: 4 },
  { label: 'Make', name: 'make', type: 'text', size: 4 },
  { label: 'Body Style', name: 'body_style', type: 'text', size: 4 },
];


  return (
    <>
      <div
        className="modal fade"
        // id="wa-title-app"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                AZ - Bill of Sale
              </h5>
              <br />
              { hasClickedClose ? (
              <div className="d-flex">
                  <p className="m-1 pt-1 text-white">Save Changes?</p>
                  <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                  <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
              </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ fontSize: '12px', overflowY: 'scroll', height: 'inherit'}}>
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill();
                      AutofillProductDetails(assignedProduct);
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>
                  <br />

                  { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Product Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                        </div>
                    </div>
                  ):(
                    <div className="m-2 p-2 border">
                        <span>No Assigned Product</span>
                    </div>
                  )}

                    <div className="row" style={{ padding: '1rem 0rem 1rem 0rem', borderBottom: '0.5px solid black' }}>
                    <h5>Vehicle Information</h5>
                    {vehicleInfo?.map((i, index)=>{
                        return <Grid item xs={i.size} key={index}> 
                            <FormControl fullWidth={true}>
                              <FormLabel>{i.label}</FormLabel>
                              <TextField 
                              name={i.name}
                              value={formatPotentialDate(documentValues[i.name], i.type)}
                              type={i.type} 
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              size="small"
                              ></TextField>
                            </FormControl>
                        </Grid>
                      })}
                  </div>
                  <div className="row" style={{ padding: '1rem 0rem 1rem 0rem', borderBottom: '0.5px solid black' }}>
                      <h5>Sale Information</h5>
                      {saleInfo?.map((i, index)=>{
                        return <Grid item xs={i.size} key={index}> 
                        <FormControl fullWidth={true}>
                          <FormLabel>{i.label}</FormLabel>
                          <TextField 
                          name={i.name}
                          value={formatPotentialDate(documentValues[i.name], i.type)}
                          type={i.type} 
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          size="small"
                          ></TextField>
                        </FormControl>
                    </Grid>
                      })}
                  </div>
                  <div className="row" style={{ padding: '1rem 0rem 1rem 0rem', borderBottom: '0.5px solid black' }}>
                    <h5>Buyer Information</h5>
                    <Grid item > 
                        <FormControl fullWidth={true}>
                            <FormLabel>Buyer Address</FormLabel>
                            {addressOptions ? 
                            <div className="dropdown">
                              <select
                              onChange={setBuyerLocation}
                              className="dropdown-select"
                              onBlur={(e) => updatePreview(e)}
                              >
                                <option disabled selected value="">Select</option>
                                {addressOptions.map((a, index) => (
                                  <option key={index} value={a.name}>
                                    {a.name}
                                  </option>
                                ))}
                              </select>
                              <span className="dropdown-arrow">&#9662;</span>
                            </div> : null}
                        </FormControl>
                          </Grid>
                      {buyerInfo?.map((i, index)=>{
                        return <Grid item xs={i.size} key={index}> 
                        <FormControl fullWidth={true}>
                          <FormLabel>{i.label}</FormLabel>
                          <TextField 
                          name={i.name}
                          value={formatPotentialDate(documentValues[i.name], i.type)}
                          type={i.type} 
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          size="small"
                          ></TextField>
                        </FormControl>
                    </Grid>
                      })}
                  </div>
                  <div className="row" style={{ padding: '1rem 0rem 1rem 0rem', borderBottom: '0.5px solid black', marginBottom: '4rem' }}>
                      <h5>Seller Information</h5>
                      <Grid item > 
                        <FormControl fullWidth={true}>
                            <FormLabel>Selling Location</FormLabel>
                            <div className="dropdown">
                              <select
                              onChange={setSellerLocation}
                              className="dropdown-select"
                              onBlur={(e) => updatePreview(e)}
                              >
                                <option disabled selected value="">Select</option>
                                {locations?.map((location) => (
                                  <option key={location.id} value={location.description}>
                                    {location.description}
                                  </option>
                                ))}
                              </select>
                              <span className="dropdown-arrow">&#9662;</span>
                            </div>
                        </FormControl>
                          </Grid>
                      {sellerInfo?.map((i, index)=>{
                        return <Grid item xs={i.size} key={index}> 
                        <FormControl fullWidth={true}>
                          <FormLabel>{i.label}</FormLabel>
                          <TextField 
                          name={i.name}
                          value={formatPotentialDate(documentValues[i.name], i.type)}
                          type={i.type} 
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          size="small"
                          ></TextField>
                        </FormControl>
                    </Grid>
                      })}
                  </div>
                <hr></hr>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default AZBillOfSale
import React, { useState, useEffect,useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import States from './States';
import { setShowFinanceSpinner } from "../../../../../../../redux/spinners/spinners";
import { useParams } from "react-router-dom";
import { setLenderFocus } from "../../../../../../../redux/products/lenderFocusReducer";
import { recordFrontEndError } from "../../../../../../../utlis/api"
import * as api from "../../../../../../../utlis/api"
import { CircularProgress } from "@material-ui/core";
import  {Grid, Button, Autocomplete,TextField }  from '@mui/material';
import { useOrder } from "../../../../../../../redux/orders/order";
import { editFundingDetail,editCashLienHolder } from "../../../../../../../redux/products";
import { formatNumber, removeChars } from "../../../../../../../utlis/formatters";
import  NumberFormat  from 'react-number-format';
import { filterDocumentsForProduct, filterProductDocumentsForOrder, updateFinanceFundingDetails, finalizeFinanceFundingDetails, saveProductSection, unfinalizeFinanceFundingDetails, getOtherProductsInGroup } from "../../../../../../../utlis/dataSaveAndCopyLibrary";
import { updateCopySubSection, updateStatus, updateObjectId, updateObjectGroupId, updateChangesArray, updateSaveFunction, updateCopySection, updateGroupChangesArray } from "../../../../../../../redux/saveAndCopy"
import { setActiveModal, setActiveModalTab } from "../../../../../../../redux/app/appSaver";
import { addTestingTag } from "../../../../../../../utlis/testingHelpers";
import { setProduct } from "../../../../../../../redux/products";



function ProductFinance({product, updateLenderInfo, updateLenderInfoFromChild, updateLenderInfoFunction, resetTab, documents, preCloseClose}) {

  let { id } = useParams();
  const dispatch = useDispatch();
  const {showFinanceSpinner}=useSelector(state=>state.spinners)
  const order = useOrder()

  // const [dataChangeArray, setDataChangeArray]=useState([]);
  const changesArray = useSelector((state) => state?.copyChangesToDocumentsRedux.changesArray);
  const appData = useSelector((state) => state?.appData);

  const [dataChangeArray, setDataChangeArray]   = useState([]);
  function updateReduxChangeArray(prop=null, value=null){
    // e.preventDefault();

    // const { name: prop, value} = e.target;
    if(prop === null || value === null){
      return;
    }


    let doNotCopyProperties = [];

    if(dataChangeArray && !doNotCopyProperties.includes(prop)){

      if(dataChangeArray[prop]?.oldValue){
        setDataChangeArray((prev)=>({
          ...prev,
          [prop]: {
            "newValue": value,
            "oldValue": dataChangeArray[prop].oldValue
          }
        }))
        
      }else{
        const oldValue = product?.fundingDetail[prop];

        setDataChangeArray((prev)=>({
          ...prev,
          [prop]: {
            "newValue": value,
            "oldValue": oldValue ? oldValue : '--'
          }
        }))

      }
    }
    // clearFundingMethodProperties();
    // _callback();
}

  // function clearFundingMethodProperties(){
  const clearFundingMethodProperties = useCallback(async () => {

    let value = dataChangeArray['fundingMethod'];

    if(value){
      let cleanedDataChangeArray = JSON.parse(JSON.stringify(dataChangeArray));

      if(value !== 'cash-with-lien'){
        delete cleanedDataChangeArray["cashLienHolder.bankName"];
        dispatch(editCashLienHolder({prop: "bankName", value: ""}));

        delete cleanedDataChangeArray["cashLienHolder.addressLine1"];
        dispatch(editCashLienHolder({prop: "addressLine1", value: ""}));

        delete cleanedDataChangeArray["cashLienHolder.addressLine2"];
        dispatch(editCashLienHolder({prop: "addressLine2", value: ""}));

        delete cleanedDataChangeArray["cashLienHolder.city"];
        dispatch(editCashLienHolder({prop: "city", value: ""}));

        delete cleanedDataChangeArray["cashLienHolder.state"];
        dispatch(editCashLienHolder({prop: "state", value: ""}));

        delete cleanedDataChangeArray["cashLienHolder.zip"];
        dispatch(editCashLienHolder({prop: "zip", value: ""}));

      }

      if(value !== 'cash-no-lien'){
        
      }

      if(value !== 'direct-deal'){
        delete cleanedDataChangeArray["directDealAdminFee"];
        dispatch(editFundingDetail({prop: "directDealAdminFee", value: "0"}));

        delete cleanedDataChangeArray["directDealReserve"];
        dispatch(editFundingDetail({prop: "directDealReserve", value: "0"}));

        delete cleanedDataChangeArray["directDealInsuranceIncome"];
        dispatch(editFundingDetail({prop: "directDealInsuranceIncome", value: "0"}));

      }

      if(value !== 'tec-financing'){
      }

      if(value !== 'finance-only'){
      }

      setDataChangeArray(cleanedDataChangeArray);
    }

  },[dataChangeArray, dispatch])


  useEffect(() => {

    if(appData?.activeModal === 'product' && appData?.activeModalTab === 'financing'){
      dispatch(updateChangesArray(dataChangeArray));
    }

  }, [ appData, dataChangeArray, dispatch ])


  const copyProductFinancing = async ()=>{
    let foundCopyProduct = order.products.find(product=>product.id=== selectedProductFunding)
    if(foundCopyProduct?.fundingDetail===null){
      setFinanceCopyError(true)
      return
    }
    try{
     await api.copyProductFinancing(order.id, product.id, selectedProductFunding).then(res => {
       resetTab(0);
       resetTab(4);
      //  dispatch(refreshOrder(order.id, true))

       api.getProduct(order?.id, product.id).then( res => {
        if(res?.data && res?.data?.id){
          dispatch(setProduct(res?.data));
        }

      });

     })
        // updateLenderInfoFromChild(true);
    } catch(err){
      recordFrontEndError('ProductFinance.js', 'Error copying finance details: ' + err)
      setFinanceCopyError(true)

    }
  }
  
  const stateAbbrev = States.map(state => {
    return state.abbreviation;
  });
  const statesList = stateAbbrev.map((state, index) => {
    return (
      <option key={index} value={state}>{state}</option>
    )
  })

  
  const initFinanceDetail = {
    directDealAdminFee: 0,
    directDealReserve: 0,
    directDealInsuranceIncome: 0,
    paymentMethod:"",
    documentNumber: null,
    fundingMethod:"cash-with-lien"
  }

  const [productLenders, setproductLenders] = useState(undefined);
  const [inputCopyProduct, setInputCopyProduct]=useState('')
  const [fundingFinalized, setfundingFinalized] = useState(undefined);
  const [lenderFinalized, setlenderFinalized] = useState(false);
  const [pendingLender, setPendingLender] = useState(false);
  const [fundingDetailLoaded, setFundingDetailLoaded] = useState(false);
  const [selectedProductFunding, setSelectedProductFunding] = useState('')
  const [financeCopyError, setFinanceCopyError]=useState(false)
  const [fundingDetailObject, setfundingDetailObject] = useState(initFinanceDetail);
  const [fetchingLenders, setFetchingLenders]=useState(false)
  const [initRefresh, setInitRefresh]=useState(false)

  const getProductLenders = useCallback(async () => {
    setFetchingLenders(true);

    if(id && product.id){
      try {
        let response = await api.getProductLenderOptions(id, product.id)        
        setproductLenders(response.data);
        setTimeout(() => {
          setFetchingLenders(false);
        }, 1500);
        
      } catch (err) {
        recordFrontEndError('ProductFinance.js', 'Error getting product lenders')
      }
    }
    return (err)=>{
    }
  },[ id, product , setproductLenders ])

  const getProductFundingDetails = useCallback(async () => {
      let response = await api.getProductFundingDetails(id,product.id);
      if(response){
        setFundingDetailLoaded(true);
        var fundingDetail = response?.data?.fundingDetail;
        var data = {
          fundingMethod: fundingDetail.fundingMethod,
          directDealAdminFee: '$' + formatNumber(fundingDetail.directDealAdminFee),
          directDealReserve: '$' + formatNumber(fundingDetail.directDealReserve),
          directDealInsuranceIncome: '$' + formatNumber(fundingDetail.directDealInsuranceIncome)
        }
        setfundingDetailObject(data);
        if(response.data.fundingDetail.finalizedAt !== '0001-01-01T00:00:00' && response.data.fundingDetail.finalizedAt){
          setfundingFinalized(true);
        }else{
          setfundingFinalized(false);
          if(fundingDetail.fundingMethod === "tec-financing" || fundingDetail.fundingMethod === "finance-only" || fundingDetail.fundingMethod === "direct-deal"){
            if((!fundingDetail?.finalizedLendingOption && fundingDetail?.finalizedLendingOption?.id)){
              setPendingLender(true);
            }else{
              setPendingLender(false);
            }
          }
        }
      } 
    return(err)=>{
    }
  },[ id, product.id])
  
  const putProductFundingDetails = async (e) => {
    e.preventDefault();

    function initDataRefresh(fundingMethod){
      if(initRefresh === false){
        setInitRefresh(true);
        setTimeout(() => { 
          if(fundingMethod !== 'cash-with-lien' && fundingMethod !== 'cash-no-lien'){
            getProductLenders();
          }
          getProductFundingDetails();
          setInitRefresh(false);
        }, 5000);
      }
    }

    let productPayload = JSON.parse(JSON.stringify(product));
    productPayload.fundingDetailObject = fundingDetailObject;

    saveProductSection(e, order, productPayload, 'finance', changesArray, updateFinanceFundingDetails, () => {
      // if(productPayload?.fundingDetailObject?.fundingMethod !== 'cash-with-lien' && productPayload?.fundingDetailObject?.fundingMethod !== 'direct-deal'){
      //   getProductLenders();
      //   getProductFundingDetails();
      // }
      initDataRefresh(productPayload?.fundingDetailObject?.fundingMethod);
      dispatch(setShowFinanceSpinner(false));
    });
    
    dispatch(updateCopySubSection('product'));
    dispatch(updateCopySubSection('finance'));
    dispatch(updateObjectId(product.id));
    dispatch(updateObjectGroupId(product.productGroupId));
    dispatch(updateChangesArray(changesArray));
    dispatch(updateSaveFunction(updateFinanceFundingDetails));
  }

  const putProductFundingDetailsAndFinalize = async (e) => {

    let productPayload = JSON.parse(JSON.stringify(product));
    productPayload.fundingDetailObject = fundingDetailObject;

    saveProductSection(e, order, productPayload, 'finance', changesArray, updateFinanceFundingDetails, () => {
      finalizeFinanceFundingDetails(order, product, () => {
        getProductLenders();
        getProductFundingDetails();
        dispatch(setShowFinanceSpinner(false))
      });
    });

    dispatch(updateCopySubSection('product'));
    dispatch(updateCopySubSection('finance'));
    dispatch(updateObjectId(product.id));
    dispatch(updateObjectGroupId(product.productGroupId));
    dispatch(updateChangesArray(changesArray));
    dispatch(updateSaveFunction(updateFinanceFundingDetails));

  }



  const showLenders = () => {
    if(fundingFinalized || lenderFinalized){
      if(productLenders !== undefined && productLenders.length > 0) {
        return productLenders.map(lender => {
            if(lender.finalizedAt != null && lender.finalizedAt !== undefined){
              // setlenderFinalized(true)
              return <span><p className="my-3 px-3"
                        data-bs-toggle="modal"
                        data-bs-target="#productFinanceLenderTermsModal"
                        data-bs-dismiss="modal"
                        style={{cursor: "pointer", color: 'green', fontWeight: 'bolder'}}
                        onClick={(e) => {
                          if(fundingDetailObject.fundingMethod === "tec-financing" || fundingDetailObject.fundingMethod === "finance-only" || fundingDetailObject.fundingMethod === "direct-deal"){
                            // resetProductLenders();
                            dispatch(setLenderFocus(lender));
                          }
                        }}
                      >{lender.bankName}</p>
                      </span>
            }return null
        })
      }
    }else{
      if(productLenders !== undefined && productLenders.length > 0) {
        return productLenders.map(lender => {
            if(lender.finalizedAt != null && lender.finalizedAt !== undefined){
              return <span><li className="my-3 px-3"
                        data-bs-toggle="modal"
                        data-bs-target="#productFinanceLenderTermsModal"
                        data-bs-dismiss="modal"
                        aria-label="lenders"
                        style={{cursor: "pointer", color: 'green', fontWeight: 'bolder'}}
                        onClick={(e) => {
                          if(fundingDetailObject.fundingMethod === "tec-financing" || fundingDetailObject.fundingMethod === "finance-only" || fundingDetailObject.fundingMethod === "direct-deal"){
                            dispatch(setLenderFocus(lender));
                          }
                        }}
                      >{lender.bankName}</li> 
                      </span>
            }
  
            return <span><li className="my-3 py-3">
                      <button className="btn btn-default btn-xs pl-1"
                              style={{ textAlign: 'left', display: 'contents'}}
                              onClick={(e) => {
                                if(fundingDetailObject.fundingMethod === "tec-financing" || fundingDetailObject.fundingMethod === "finance-only" || fundingDetailObject.fundingMethod === "direct-deal"){
                                  dispatch(setLenderFocus(lender));
                                }
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#productFinanceLenderTermsModal"
                              data-bs-dismiss="modal"
                              aria-label="lenders"
                      >
                        {lender.bankName} 
                      </button>
                  </li>
                  </span>
        })
      }
    }
  }

  useEffect(() => {

    if(updateLenderInfo === true) {
      getProductFundingDetails();
      getProductLenders();
      updateLenderInfoFromChild(false);
    }

  },[getProductFundingDetails, getProductLenders, updateLenderInfo, updateLenderInfoFromChild])


  useEffect(() => {

    if(appData?.activeModal === 'product' && appData?.activeModalTab === 'financing'){

      if( productLenders && productLenders.length > 0 ){
        setlenderFinalized(false);
        productLenders.forEach(lender => {
          if(lender.finalizedAt && lender.finalizedAt !== '0001-01-01T00:00:00'){
            setlenderFinalized(true);
          }
        });
      }

    }

  },[ appData, productLenders ])

  const updateLenderInfoFunc = useCallback(() => {
    getProductFundingDetails();
    getProductLenders();
  },[getProductFundingDetails,getProductLenders])

  const handleFundingDetailChange = async e =>{
    const {name,value}=e.target;
    if (name === 'fundingMethod'){
      setfundingDetailObject({...fundingDetailObject,[name]:value});
      dispatch(editFundingDetail({prop:name, value:value}))
      updateReduxChangeArray(name, value);
    }
  }

  const handleEditFundingDetails = async (e=null, prop=null, options=null) =>{

    if (options){
      let { value } = options
      dispatch(editFundingDetail({prop:prop, value:Number(value)}))
      if(dataChangeArray[prop]?.oldValue){

        setDataChangeArray((prev)=>({
          ...prev,
          [prop]: {
            "newValue": value,
            "oldValue": dataChangeArray[prop].oldValue
          }
        }))
        
      }else{
        const oldValue = product?.fundingDetail[prop];

        setDataChangeArray((prev)=>({
          ...prev,
          [prop]: {
            "newValue": value,
            "oldValue": oldValue ? oldValue : '--'
          }
        }))
      }
    }else if(e?.target) {
      let {value, name} = e?.target
      if(name.includes('cashLienHolder')){
        dispatch(editCashLienHolder({prop: prop, value:value}));
        if(dataChangeArray[prop]?.oldValue){

          setDataChangeArray((prev)=>({
            ...prev,
            [name]: {
              "newValue": value,
              "oldValue": dataChangeArray[name].oldValue
            }
          }))
          
        }else{

          let oldValue = '';
          if(product?.fundingDetail?.cashLienHolder !== undefined && product?.fundingDetail?.cashLienHolder !== null && product?.fundingDetail?.cashLienHolder.length > 0){
            if(product?.fundingDetail?.cashLienHolder.hasOwnProperty(prop)){
              oldValue = product?.fundingDetail?.cashLienHolder[prop];
            }
          }
  
          setDataChangeArray((prev)=>({
            ...prev,
            [name]: {
              "newValue": value,
              "oldValue": oldValue ? oldValue : '--'
            }
          }))   
        }

      }
    } 

  }
      
  const saveFinanceDetails = async e =>{
    await putProductFundingDetails(e);
  }

  const handleBlurFormat = async (e, prop)=>{
    const val =Number(removeChars(e.target.value)).toFixed(2)
    dispatch(editFundingDetail({prop:prop, value:val}))
    saveFinanceDetails(e);
  }

  const finalizedFinancingDetails = async e => {
    e.preventDefault();
    dispatch(setShowFinanceSpinner(true));
    updateReduxChangeArray("fundingDetails.finalized", "yes");
    await putProductFundingDetailsAndFinalize(e);
  }

  useEffect(() => {

    if(appData?.activeModal === 'product' && appData?.activeModalTab === 'financing'){

      if(productLenders === undefined) {
        getProductLenders();
        getProductFundingDetails();
      }
      updateLenderInfoFunction.current = updateLenderInfoFunc;

    }

},[ appData, productLenders, updateLenderInfoFunction,getProductFundingDetails, getProductLenders, updateLenderInfoFunc ])


// useEffect(() => {
//   clearFundingMethodProperties();
// },[clearFundingMethodProperties, dataChangeArray]);


  const unfinalizeFunding = async (e) => {
    dispatch(setShowFinanceSpinner(true));
    unfinalizeFinanceFundingDetails(order, product, () => {
      getProductLenders();
      getProductFundingDetails();
      updateReduxChangeArray("fundingDetails.finalized", "no");
      dispatch(setShowFinanceSpinner(false));
    })

  }

  return (
    <>
    { appData?.activeModal === 'product' && appData?.activeModalTab === 'financing' ? (
      <div className="container-fluid">
        { fundingDetailLoaded ? (
          <div className="row col-12">
            {/* ------------------------------ Row 1: Sidebar ------------------------------------ */}
            {product?.id !== undefined && (product?.fundingDetail?.fundingMethod === "tec-financing" || product?.fundingDetail?.fundingMethod === "finance-only" || product?.fundingDetail?.fundingMethod === "direct-deal") ? (
              <div className="col-3 border-end">
                { !fundingFinalized && !lenderFinalized ? (
                  <div>
                    <ul>
                      <li 
                        className="list-group-item text-success fs-5 text-center"
                        data-bs-toggle="modal"
                        data-bs-target="#productFinanceModal"
                        data-bs-dismiss="modal"
                        data-testid="product.financing.control.addLender"
                        style={{cursor: "pointer"}}
                        aria-label='add lender'
                        >
                        + ADD LENDER
                      </li>
                    </ul>
                    <hr />
                  </div>
                ):(
                  <h5>Selected Lender</h5>
                )}
                { fetchingLenders === false ? (
                  <ol>{showLenders()}</ol>
                ):(
                  <ol  style={{ paddingLeft: '0', paddingTop: '3rem', paddingBottom: '3rem' }} className="text-center"><CircularProgress/></ol>
                )}
                <div className="mx-auto text-center">
                  <button data-testid="product.financing.control.reloadLenders" className="btn btn-primary btn-sm mx-auto text-center" style={{ bottom: 0, marginTop: '3rem' }} onClick={getProductLenders} disabled={fetchingLenders}>Reload Lenders</button>
                </div>
              </div>
            ) : null}
            {/* ------------------------------ Row 1: Section ------------------------------------ */}
            <div className={(product?.id !== undefined && (product?.fundingDetail?.fundingMethod === "tec-financing" || product?.fundingDetail?.fundingMethod === "finance-only" || product?.fundingDetail?.fundingMethod === "direct-deal")) ? 'col-9' : 'col-12'}>
              <div className="row">
                <Grid container style={{width:'100%'}} justifyContent={order?.products?.length>1?'space-between': 'start' }className="">
                  <Grid item> 
                  <h6>Financing Details / Reserve Report</h6>
                  </Grid>
                  {order?.products?.length>1 && (<>
                  <Grid container item alignItems='center' style={{width:'auto'}}> 
                  <Grid item>
                  <Autocomplete
                   disablePortal
                   id="productOptions"
                   aria-label='select product to copy finance'
                   options={ order.products.filter(prod=>
                    prod?.id&& prod?.id!== product.id && prod?.fundingDetail)??[]}
                   getOptionLabel={(option) => {
                    return option.stockNumber??''
                  }}
                  onChange={(e,v)=>{
                    setFinanceCopyError(false)
                    setSelectedProductFunding(v?.id)}
                  }
                  value={order?.products.find(product=>product.stockNumber===inputCopyProduct)??''}
                  inputValue={inputCopyProduct}
                  onInputChange={(event, newInputValue) => {
                            setInputCopyProduct(newInputValue);
                          }}
                  sx={{ width: 300, margin:'0 10px' }}
                  renderInput={(params) => <TextField {...addTestingTag(params, 'product.financing.control.selectProductToCopy')} label="Select product financing to copy" />}/>
                  </Grid>
                  <Grid item> 
                  <Button variant="contained" onClick={()=>copyProductFinancing()} data-testid="product.financing.control.copyToFinancing" aria-label='copy selected product financing' >Copy Financing</Button>
                  </Grid>
                  </Grid>
                  {
                  financeCopyError&& (
                    <Grid sx={{width:'100%'}}container item direction='row-reverse'>
                    <Grid item> 
                    <p style={{color:'red'}}>No Financing details available</p>
                    </Grid>
                  </Grid>
                  )
                  }
                  
                  </>
                  )}
                </Grid>
                <div className="d-flex mx-4 my-3 ">
                  <div className="col-11 mx-3 ">
                    <label htmlFor="stock">Funding Method</label>
                    <select
                      className="rounded-pill form-control form-select"
                      onChange={handleFundingDetailChange}
                      onBlur={(e) => {
                        clearFundingMethodProperties();
                        saveFinanceDetails(e);
                      }}
                      data-testid="product.financing.fundingMethod"
                      aria-label='funding method options'
                      name="fundingMethod"
                      id="fundingMethod"
                      disabled={fundingFinalized}
                      value={ product?.fundingDetail?.fundingMethod ?? ''}
                    >
                      <option value="" selected disabled hidden>Funding Methods</option>
                      <option value="cash-with-lien">CASH DEAL WITH LIEN</option>
                      <option value="cash-no-lien">CASH DEAL NO LIEN</option>
                      <option value="tec-financing">TEC FINANCING</option>
                      <option value="direct-deal">DIRECT DEAL</option>
                      <option value="finance-only">FINANCE ONLY</option>
                    </select> 
                  </div>
                  <div className="col-1 mx-3">
                  </div>
                </div>
              </div>
              { product?.fundingDetail?.fundingMethod === 'direct-deal' ? (
                <div className="row">
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2">
                      <label htmlFor="type">Admin Fee</label>
                        <NumberFormat
                          className="rounded-pill form-control"
                          value={ Number(product?.fundingDetail?.directDealAdminFee).toFixed(2)??Number().toFixed(2)}
                          name="directDeal.adminFee"
                          aria-label='direct deal admin fee'
                          thousandSeparator={true}
                          data-testid="product.financing.directDealAdminFee"
                          decimalScale={2}
                          prefix={'$'}
                          disabled={fundingFinalized}
                          onValueChange={ options =>handleEditFundingDetails(null,'directDealAdminFee', options) }
                          onBlur={e => {
                            handleBlurFormat(e, 'directDealAdminFee');
                          }}
                        />
                    </div>
                    <div className="p-2">
                      <label htmlFor="type">Reserve</label>
                        <NumberFormat
                          className="rounded-pill form-control"
                          value={ Number(product?.fundingDetail?.directDealReserve).toFixed(2)??Number().toFixed(2)}
                          name="directDeal.reserve"
                          aria-label='direct deal reserve'
                          thousandSeparator={true}
                          data-testid="product.financing.directDealReserve"
                          decimalScale={2}
                          prefix={'$'}
                          disabled={fundingFinalized}
                          onValueChange={ options =>handleEditFundingDetails(null,'directDealReserve', options) }
                          onBlur={e => {
                            handleBlurFormat(e, 'directDealReserve');
                          }}
                        />
                    </div>
                    <div className="p-2">
                      <label htmlFor="type">Insurance Income</label>
                        <NumberFormat
                          className="rounded-pill form-control"
                          value={ Number(product?.fundingDetail?.directDealInsuranceIncome).toFixed(2)??Number().toFixed(2)}
                          name="directDeal.insuranceIncome"
                          aria-label='direct deal insurance income'
                          thousandSeparator={true}
                          data-testid="product.financing.directDealInsuranceIncome"
                          decimalScale={2}
                          prefix={'$'}
                          disabled={fundingFinalized}
                          onValueChange={ options =>handleEditFundingDetails(null,'directDealInsuranceIncome', options) }
                          onBlur={e => {
                            handleBlurFormat(e, 'directDealInsuranceIncome');
                          }}
                        />
                    </div>
                  </div>
                </div>
              ):null}
              { product?.fundingDetail?.fundingMethod === 'cash-with-lien' ? (
              <div className="row">
                <hr className="my-3 mx-3" />
                <h6>Lien Details </h6>
                <div className="d-flex mx-4 my-3 ">
                  <div className="col-11 mx-3">
                    <label htmlFor="type">Lien holder</label>
                    <input
                      onChange={e =>handleEditFundingDetails(e, 'bankName')}
                      disabled={fundingFinalized}
                      name="cashLienHolder.bankName"
                      data-testid="product.financing.cashLienHolder.bankName"
                      aria-label='cash lien bank name'
                      type="text"
                      className="form-control rounded-pill mt-1 "
                      value={ product?.fundingDetail?.cashLienHolder?.bankName ?? ''}
                      onBlur={e => {
                        saveFinanceDetails(e);
                      }}
                      />
                  </div>
                </div>
                <div className="d-flex mx-4 my-3 ">
                  <div className="col-11 mx-3">
                    <label htmlFor="type">Address</label>
                    <input
                      type="text"
                      onChange={e =>handleEditFundingDetails(e, 'addressLine1')}
                      disabled={fundingFinalized}
                      data-testid="product.financing.cashLienHolder.addressLine1"
                      aria-label='cash lien holder address line 1'
                      name='cashLienHolder.addressLine1'
                      className="form-control rounded-pill mt-1 "
                      value={ product?.fundingDetail?.cashLienHolder?.addressLine1 ?? ''}
                      onBlur={e => {
                        saveFinanceDetails(e);
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex mx-4 my-3 ">

                  <div className="col-11 mx-3">
                    <label htmlFor="type">Address Line 2</label>
                    <input
                      type="text"
                      onChange={e =>handleEditFundingDetails(e, 'addressLine2')}
                      disabled={fundingFinalized}
                      name='cashLienHolder.addressLine2'
                      data-testid="product.financing.cashLienHolder.addressLine2"
                      aria-label="cash lien holder address line 2"
                      className="form-control rounded-pill mt-1 "                   
                      value={ product?.fundingDetail?.cashLienHolder?.addressLine2 ?? ''}
                      onBlur={e => {
                        saveFinanceDetails(e);
                      }}

                    />
                  </div>
                </div>
                <div className="d-flex mx-4 my-3 ">
                  <div className="col-4 mx-3">
                    <label htmlFor="city">City</label>
                    <input
                      type="text"
                      onChange={e =>handleEditFundingDetails(e, 'city')}
                      disabled={fundingFinalized}
                      name="cashLienHolder.city"
                      data-testid="product.financing.cashLienHolder.city"
                      aria-label='cash lien holder city'
                      className="form-control rounded-pill mt-1 "
                      value={ product?.fundingDetail?.cashLienHolder?.city ?? ''}
                      onBlur={e => {
                        saveFinanceDetails(e);
                      }}
                    />
                  </div>
                  <div className="col-3 mx-3">
                    <label htmlFor="state">
                      State
                    </label>
                    <select
                      className="rounded-pill form-control form-select mt-1"
                      name="cashLienHolder.state"
                      onChange={e =>handleEditFundingDetails(e, 'state')}
                      disabled={fundingFinalized}
                      data-testid="product.financing.cashLienHolder.state"
                      id="state"
                      aria-label="cash lien holder state"
                      value={ product?.fundingDetail?.cashLienHolder?.state ?? ''}
                      onBlur={e => {
                        saveFinanceDetails(e);
                      }}
                    >
                      <option value="" selected disabled hidden>Select State</option>
                      {statesList}
                    </select>
                  </div>
                  <div className="col-3 mx-3">
                    <label htmlFor="type">Zip</label>
                    <input
                      type="text"
                      onChange={e =>handleEditFundingDetails(e, 'zip')}
                      disabled={fundingFinalized}
                      data-testid="product.financing.cashLienHolder.zip"
                      name='cashLienHolder.zip'
                      aria-label="cash lien holder zip"
                      className="form-control rounded-pill mt-1"
                      value={ product?.fundingDetail?.cashLienHolder?.zip ?? ''}
                      onBlur={e => {
                        saveFinanceDetails(e);
                      }}
                    />
                  </div>
                </div>
              </div>):null}
          
            <div className="row">
              <hr className="my-3 mx-3" />
              <div className="d-flex text-center justify-content-evenly">
                <div className="text-center">
                  <button 
                    data-bs-dismiss="modal" 
                    data-bs-toggle="modal"
                    onClick={(e) => resetTab()}
                    aria-label='close'
                    className="btn btn-secondary "
                    data-testid="product.financing.control.close">Close</button>
                </div>
                {showFinanceSpinner?<CircularProgress/>:( !fundingFinalized && !pendingLender ? (
                  <div className="text-center">
                  <button onClick={ finalizedFinancingDetails } data-testid="product.financing.control.finalize"  aria-label='finalize' className="btn btn-warning ">Finalize</button>
                </div>): fundingFinalized ? (
                  <> 
                  { (fundingDetailObject.fundingMethod === "tec-financing" || fundingDetailObject.fundingMethod === "finance-only") ? (
                        <button 
                        data-bs-toggle="modal"
                        data-bs-target="#productFinanceLenderTermsModal"
                        data-bs-dismiss="modal"
                        data-testid="product.financing.control.updateLenderTerms"
                        onClick={(e) => {
                          var finalizedLender = productLenders.find(l => l.finalizedAt != null && l.finalizedAt !== undefined);
                          if(finalizedLender){
                            dispatch(setLenderFocus(finalizedLender));
                          }
                        }} className="btn btn-success "
                        aria-label="update lender terms" >Update Lender Terms</button>
                     ):null}
                    <button onClick={unfinalizeFunding} className="btn btn-warning " data-testid="product.financing.control.unfinalize" aria-label='un-finalize'>Unfinalize Funding</button>
                  </>
                ):null)}
                <SavedProductButton product={product} documents={documents} disabled={(dataChangeArray?.length > 0)} />
              </div>
            </div>
          </div>
          </div>
        ):(
          <div class="flex mx-auto text-center">
            <label>Loading Financing Details</label>
            <br />
            <br />
            <CircularProgress style={{ placeSelf: "center" }} />
          </div>
        )}
      </div>
    ):null}
    {/* { dataChangeArray?.length > 0 ? ( */}
    {/* ):null} */}
   </>
  );
}

const SavedProductButton = ({ product, documents }) => {

  const order   = useOrder();
  const dispatch  = useDispatch();
  const changesArray = useSelector((state) => state?.copyChangesToDocumentsRedux.changesArray);

  function filterDocuments(){
    let productDocuments = filterDocumentsForProduct(product.id, documents);
    let orderDocuments = filterProductDocumentsForOrder(product.id, documents);

    let allFilteredDocuments = orderDocuments.concat(productDocuments);
    return allFilteredDocuments;
  }

  function prepRedux(){
    dispatch(updateCopySection('product'));
    dispatch(updateCopySubSection('financing'));
    dispatch(setActiveModalTab(null));
  }

  function prepGroupCopy(){
    dispatch(updateGroupChangesArray([changesArray]));
    dispatch(updateStatus('copy-to-group'));
    dispatch(setActiveModal('copy-to-product-group'));
  }

  function prepDocumentCopy(){
    let copyChangesArray = [];
    copyChangesArray.id = product.id;
    copyChangesArray.stockNumber = product.stockNumber;
    copyChangesArray.propertySaved = false;
    copyChangesArray.docsSavedCount = 0;
    Object.entries(changesArray).forEach( value => {
      copyChangesArray[value[0]] = value[1];
    });
    dispatch(updateGroupChangesArray([copyChangesArray]));
    dispatch(updateStatus('copy'));
    dispatch(setActiveModal('copy-to-documents'));
  }

  return (
    <>
      { getOtherProductsInGroup(order, product.id, product.productGroupId)?.length > 0 ? (
        <>
          <button aria-label='Copy To Group' 
            onClick={ (e)=>{
              e.preventDefault()
              prepRedux();
              prepGroupCopy();
            }} data-bs-dismiss="modal" data-bs-toggle="modal" data-testid="product.financing.control.copyToGroup" data-bs-target="#copychangestogroupmodal" className="btn btn-primary text-uppercase mx-2">Copy To Group</button>
        </>       
        ): filterDocuments()?.length > 0 ? (
        <>
          <button aria-label='Copy to Documents' 
            onClick={ (e)=>{
              e.preventDefault();
              prepRedux();
              prepDocumentCopy();
            }} data-bs-dismiss="modal" data-bs-toggle="modal" data-testid="product.financing.control.copyToDocument" data-bs-target="#copychangestodocuments" className="btn btn-sm btn-primary text-uppercase mx-2">Copy to Documents</button>
        </>
      ):null}
    </>
  )
}

export default ProductFinance;
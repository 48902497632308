import React from "react";
import { useOrder, rdx } from "../../../../../../redux/orders/order"
import moment from 'moment'

// import { useFetch } from "../../../../../../utlis/api";

//Redux refactor: Yes
const DetailsSection = () => {
  const order = useOrder();
  const salesReps = useSalesReps();
  const salesManagers = useSalesManagers();


  function getRepByEmployeeId(employeeId){
    if(salesReps && salesReps.length > 0){
      let foundRep = salesReps.find(rep => rep.employee_ID === employeeId);
      if(foundRep){
        return foundRep.preferredName ? foundRep.preferredName+" "+foundRep.lastName : foundRep.firstName+" "+foundRep.lastName;
      }
    }
    return "";
  }

  function getManagerByEmployeeId(employeeId){
    if(salesManagers && salesManagers.length > 0){
      let foundManager = salesManagers.find(manager => manager.employee_ID === employeeId);
      if(foundManager){
        return foundManager.preferredName ? foundManager.preferredName+" "+foundManager.lastName : foundManager.firstName+" "+foundManager.lastName;
      }
    }
    return "";
  }

  return (
    <>
      <div className="p-4">
        <h5>DELIVERY DETAILS</h5>
        <br />
        <div className="d-flex justify-content-between">

          <div className="p-1">
            {!order || !order.orderStatus || !order.orderStatus.name ? (
            <h5 className="text-success">--</h5>
            ) : (
              <h5 className="text-success">{ order.orderStatus.name }</h5>
            )}
            <p className="mx-0 text-secondary pt-3">Status</p>
          </div>

          <div className="p-1">
            {!order || !order.tecLocationCode ? (
            <h5 className="">--</h5>
            ) : (
              <h5 className="">{ order.tecLocationCode }</h5>
            )}                
            <p className="mx-0 text-secondary pt-3">Location Code</p>
          </div>

          <div className="p-1">
            {order && order.salesRepId && order.salesRepId > 0 ? (
                <h5 className="">{ getRepByEmployeeId(order.salesRepId) }</h5>
              ):(
                  <h5 className="">--</h5>
              )}
            <p className="mx-0 text-secondary pt-3">Sales Rep</p>
          </div>

          <div className="p-1">
            {order && order.salesManagerId && order.salesManagerId > 0 ? (
                <h5 className="">{ getManagerByEmployeeId(order.salesManagerId) }</h5>
              ):(
                  <h5 className="">--</h5>
              )}
            <p className="mx-0 text-secondary pt-3">Sales Manager</p>
          </div>

          {/* <div className="p-1">
              {!order || !order.salesManagerName ? (
              <h5 className="">--</h5>
              ) : (
                <h5 className="">{ order.salesManagerName }</h5>
              )}
            <p className="mx-0 text-secondary pt-3">Manager</p>
          </div> */}

          <div className="p-1">
            {!order || !order.orderDate ? (
            <h5 className="">--</h5>
            ) : (
              <h5 className="">{ moment.utc(order.orderDate).format("MM/DD/YY") }</h5>
            )}
            <p className="mx-0 text-secondary pt-3">Order Date</p>
          </div>

        </div>
      </div>

      <div className="p-4">
        <div className="d-flex justify-content-between">

          <div className="p-1">
            {!order || !order.documentationDate ? (
            <h5 className="">--</h5>
            ) : (
              <h5 className="">{ moment.utc(order.documentationDate).format("MM/DD/YY") }</h5>
            )}
            <p className="mx-0 text-secondary pt-3">Documentation Date</p>
          </div>

          <div className="p-1">
            {!order || !order.dateFinalized ? (
            <h5 className="">--</h5>
            ) : (
              <h5 className="">{ moment.utc(order.dateFinalized).format("MM/DD/YY") }</h5>
            )}
            <p className="mx-0 text-secondary pt-3">Date Finalized</p>
          </div>

          <div className="p-1">
              <h5>Special Instructions</h5>
              <div className="d-flex justify-content-start">
                {!order || !order.specialInstructions ? (
                  <span>--</span>
                ) : (
                  <span>{ order.specialInstructions }</span>
                )}
              </div>
            </div>
        </div>
      </div>
    </>
  );
};

const {
  useSalesReps,
  useSalesManagers
} = rdx

export default DetailsSection;

import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";
import { autoSecurityAddress } from './docUtils.js';

export const NVAffPurchaserGlobalAutofill = (documentValues, order, property=null, value=null, location) => {
    var newValues = documentValues;
    
    if(property ==='customer.name'){
      newValues.name = value;
      newValues.name_of_purchaser = value;
      newValues.notary_purchasers = value;
      if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner){
          newValues.name+=" and "+cosigner.name;
          newValues.name_of_purchaser+=" and "+cosigner.name;
          newValues.notary_purchasers+=" and "+cosigner.name;
        }
      }
  }
    if(property ==='customer.cosigner[0].name'){
      newValues.name = order.customer.name
      newValues.name_of_purchaser = order.customer.name
      newValues.notary_purchasers = order.customer.name
      if(value!==''){
        newValues.name+= " and "+value;
        newValues.name_of_purchaser+= " and "+value;
        newValues.notary_purchasers+= " and "+value;
      }
  }
  if(property ==='customer.deliveryAddress.addressLine1'){
    let address=value
      if(value != null && order.customer.deliveryAddress.addressLine2 !==undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        address+=order.customer.deliveryAddress.addressLine2
      }
      newValues.address_of_purchaser = autoSecurityAddress(address,order.customer.deliveryAddress.city, order.customer.deliveryAddress.state, order.customer.deliveryAddress.zip)
  }
  if(property ==='customer.deliveryAddress.addressLine2'){
    let address= order.customer.deliveryAddress.addressLine1 + ' ' + value
      newValues.address_of_purchaser = autoSecurityAddress(address,order.customer.deliveryAddress.city, order.customer.deliveryAddress.state, order.customer.deliveryAddress.zip)
  }
  if(property ==='customer.deliveryAddress.city'){
    let address= order.customer.deliveryAddress.addressLine1 + ' ' + order.customer.deliveryAddress.addressLine2
      newValues.address_of_purchaser = autoSecurityAddress(address,value, order.customer.deliveryAddress.state, order.customer.deliveryAddress.zip)
  }
  if(property ==='customer.deliveryAddress.state'){
    let address= order.customer.deliveryAddress.addressLine1 + ' ' + order.customer.deliveryAddress.addressLine2
      newValues.address_of_purchaser = autoSecurityAddress(address,order.customer.deliveryAddress.city, value, order.customer.deliveryAddress.zip)
  }
  if(property ==='customer.deliveryAddress.zip'){
    let address= order.customer.deliveryAddress.addressLine1 + ' ' + order.customer.deliveryAddress.addressLine2
      newValues.address_of_purchaser = autoSecurityAddress(address,order.customer.deliveryAddress.city, order.customer.deliveryAddress.state, value)
  }
  if(property ==='location'){
    if(value.state === "CA"){
      newValues.purchased_from = "TEC OF CALIFORNIA, INC.";
    }else{
      newValues.purchased_from = "TEC EQUIPMENT, INC.";
    }
  }
  if(property ==='documentationDate'){
    newValues.date = moment.utc(value).format("YYYY-MM-DD");
  }
    if(property !== null && value !== null){
      return newValues;
  }

    if(order && order.customer){
  
      if(order && order.customer && order.customer.name){
  
        // newValues.phone_number = order.customer.phone;
        
        newValues.name = order.customer.name;
        newValues.name_of_purchaser = order.customer.name;
        newValues.notary_purchasers = order.customer.name;
    
        if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner){
            newValues.name = newValues.name+" and "+cosigner.name;
            newValues.name_of_purchaser = newValues.name_of_purchaser+" and "+cosigner.name;
            newValues.notary_purchasers = newValues.notary_purchasers+" and "+cosigner.name;
          }
        }
      }
      
      if(order && order.customer && order.customer.deliveryAddress){
        newValues.address_of_purchaser = order.customer.deliveryAddress.addressLine1;
        if(order.customer.deliveryAddress.addressLine1 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
          newValues.address_of_purchaser = newValues.address_of_purchaser+" "+order.customer.deliveryAddress.addressLine2;
        }
        newValues.address_of_purchaser += order.customer.deliveryAddress.city+" "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
      }
    }
  
    if(location){
      if(location.state === "CA"){
        newValues.purchased_from = "TEC OF CALIFORNIA, INC.";
      }else{
        newValues.purchased_from = "TEC EQUIPMENT, INC.";
      }
    }
    
    if(order && order.documentationDate){
      newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }
    return newValues
}

export const NVAffPurchaserAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
  var newValues = documentValues;
  
  if(property === 'year' && value !== null){
    newValues.year = (value).toString().trim().replace(/['"]+/g, '');
}

if(property === 'make' && value !== null){
    newValues.make = (value).toString().trim();
}

if(property === 'model' && value !== null){
    newValues.model = (value).toString().trim();
}
if(property !== null && value !== null){
    return newValues;
}

  newValues.year = (selectedProduct.year);
  newValues.make = (selectedProduct.make).toString().toUpperCase();
  newValues.model = (selectedProduct.model).toString().toUpperCase();
  newValues.vin_hull = (selectedProduct.vin).toString().toUpperCase();
  
  return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  if(payload.type_toggle){
    // Reset all
    payload.vehicle_check = "no";
    payload.vessel_check = "no";

    switch(payload.type_toggle){
      case "vehicle":
        payload.vehicle_check = "yes";
        break;
      case "vessel":
        payload.vessel_check = "yes";
        break;
      default:
    }
  }

  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  // if(payload.date_of_sale){
  //   payload.date_of_sale = moment.utc(payload.date_of_sale).format("MM/DD/YY");
  // }

  if(payload.date){
    payload.date = moment.utc(payload.date).format("MM/DD/YY");
  }

  return payload;
}
import React, {useState, useRef} from "react";
import { useDispatch } from "react-redux";
import { Accordion, Card, Dropdown } from "react-bootstrap";
import { useFetch } from "../../../../../../utlis/api";
import { Divider } from '@mui/material';
import { useOrder } from "../../../../../../redux/orders/order"
import DocumentInfoTemplate from "./DocumentInfoTemplate";
import { setActiveModal } from '../../../../../../redux/app/appSaver';
import moment from 'moment'
import "./documentStyles.css"
import { useBlankCard, useMarginTop, useNewCardHeight } from "./documentHelpers";

const OrderDocumentsSection = ({documents, deleteDocument, documentFocus, setDocumentFocus, isOpen, setIsOpen, readOnly, isDeletingDoc, isDocActionDisabled, isAddingDocument}) => {
  const axios = useFetch()
  const dispatch        = useDispatch()
  let order = useOrder();
  const [selectedDocument, setselectedDocument] = useState(undefined);
  const [location, setLocation] = useState(undefined);
  const cardRef = useRef(null);
  const [showBlankCard, setShowBlankCard] = useState(false)
  const [newCardHeight, setNewCardHeight] = useState('130px')
  const [marginTopValue, setMarginTopValue] = useState('130px')
  useBlankCard(isAddingDocument, setShowBlankCard)
  useMarginTop(cardRef, selectedDocument, setMarginTopValue)
  useNewCardHeight(cardRef, isAddingDocument, setNewCardHeight)

  function handleResize () {
    if(cardRef?.current?.offsetHeight){
    setMarginTopValue(`-${cardRef?.current?.offsetHeight}px`)
    setNewCardHeight(`${cardRef?.current?.offsetHeight}px`)
    }
  }

  window.addEventListener('resize', handleResize)

  //refactor for try catch
  async function getLocation(location_id){
      return await axios.get(`/location/${location_id}`);
  }  

  const [fetchingLocation, setFetchingLocation] = useState(false);
  if(location === undefined && order && order.location && order.location.id && !fetchingLocation){
    setFetchingLocation(true);
    getLocation(order.location.id).then(res => {
      setLocation(res.data);
    });
  }
  
  function normalizeSlug(slug){
    if(!slug) return
    if(slug.includes("tec-sales-order")){
      return "tec-sales-order";
    }

    if(slug.includes("tec-poa")){
      return "tec-poa";
    }

    if(slug.includes("tec-statement-of-error")){
      return "tec-statement-of-error";
    }
    return slug;
  }

  const setFormModal = (documentSlug) => {
    return ('#'+documentSlug);
  }

  function normalizeDocumentName(documentTemplate){
    if(documentTemplate){
      let slug = documentTemplate?.slug;
      let newName = documentTemplate.name;
      if(
        slug === "tec-sales-order-single" ||
        slug === "tec-sales-order-multi" || 
        slug === "tec-sales-order-multi-trade-multi" ||
        slug === "tec-sales-order-single-trade-multi" ||
        slug === "tec-sales-order-single-extended" ||
        slug === "tec-sales-order-multi-extended" || 
        slug === "tec-sales-order-multi-trade-multi-extended" ||
        slug === "tec-sales-order-single-trade-multi-extended"
        ){
        newName = "TEC Sales Order";
      }

      if(slug === "tec-poa-no-notary"){
        newName = "TEC Power of Attorney";
      }

      if(slug === "tec-statement-of-error-notary"){
        newName = "TEC Statement of Error";
      }
      
      return newName;
    }
    return "";
  }

  return (
    <div>
    <div>
      <Accordion defaultActiveKey="0" className="document-accordion" data-testid="orderDocuments.accordian">
      {documents !== undefined && documents.length > 0 ? documents.sort((a, b) => {
        if (a.documentTemplate.name.includes('TEC') && !b.documentTemplate.name.includes('TEC')) {
          // If a contains 'TEC' but b does not, push a to the start of the array
          return -1;
        } else if (!a.documentTemplate.name.includes('TEC') && b.documentTemplate.name.includes('TEC')) {
          // If b contains 'TEC' but a does not, push b to the start of the array
          return 1;
        } else {
          // Otherwise, sort alphabetically
          return a.documentTemplate.name.localeCompare(b.documentTemplate.name);
        }
      }).map((document, index) => (
        <div key={document.id} data-testid="orderDocuments.document.topDiv" data-testcontext={"documentSlug: " + document?.documentTemplate?.slug + ", documentId: " + document?.id}
        >
        { document && !document.assignedObjectId && (document.assignedObjectType === 'order' || !document.assignedObjectType) ? (
          <>
           {document.isTemp === true ? (
            <>
            <Card 
              className={`p-1 mx-3 my-1 initial-blank-card disabled-cursor ${showBlankCard ? 'blank-card' : ''}`}
              style={{ height: showBlankCard ? newCardHeight : '0px' }}
            >
              <DocumentInfoTemplate isNewCard={true} createdAt={moment.utc(document.createdAt).format("MM/DD/YY")} type={document !== undefined && document.documentTemplate !== undefined ? normalizeDocumentName(document.documentTemplate) : '' } status={'DRAFT'}>
              </DocumentInfoTemplate>
            </Card>
          </>
          ) 
          : (
            <Card
            ref={cardRef}
            style={{ marginTop: isDeletingDoc === document.id ? marginTopValue : '10px' }}
            className={`card-initial-styles ${
              isDeletingDoc === document.id ? 'slide-out' : 'p-1 mx-3'}`}
          >
            <DocumentInfoTemplate type={document !== undefined && document.documentTemplate !== undefined ? normalizeDocumentName(document.documentTemplate) : '' } displayNumber={""} status={document.documentStatus} createdAt={moment.utc(document.createdAt).format("MM/DD/YY")} document={document} isDeletingDoc={isDeletingDoc} isDocActionDisabled={isDocActionDisabled} isNewCard={false} >
              { document && document.documentStatus === 'Draft'? (
              <>
                <Dropdown.Item
                data-bs-toggle="modal"
                data-bs-target={setFormModal(normalizeSlug(document?.documentTemplate?.slug))}
                data-testid="orderDocuments.document.context.prepare"
                onClick={(e) => {
                   setDocumentFocus(document);
                   dispatch(setActiveModal('document-edit'));
                  }
                }
                >
                <b>Prepare</b>
                </Dropdown.Item>
                <Divider />
              <Dropdown.Item
                data-bs-toggle="modal"
                data-bs-target="#orderdocumentmodaldeleteconfirm"
                data-testid="orderDocuments.document.context.delete"
                onClick={e => {
                   setselectedDocument(document);
                   // getDocuments();
                  }
                }
              >
                <b>Delete Document</b>
              </Dropdown.Item>
              </>
              ):null}
              { document && (document.documentStatus === 'Prepared' || document.documentStatus === 'Generated')  ? (
              <Dropdown.Item
                data-bs-toggle="modal"
                data-bs-target="#viewDocumentModal"
                data-testid="orderDocuments.document.context.view"
                onClick={(e) => {
                  setDocumentFocus(document);
                  dispatch(setActiveModal("document-view"));
                }}>
                <b>View</b>
              </Dropdown.Item>
              ):null}
              { !readOnly && document && (document.documentStatus === 'Prepared' || document.documentStatus === 'Generated') && document.documentTemplate.slug !== 'tec-invoice-template' && document.documentTemplate.slug !== 'tec-invoice-template-v2' ? (
              <>
              <Dropdown.Item
                data-bs-toggle="modal"
                data-bs-target={setFormModal(normalizeSlug(document.documentTemplate?.slug))}
                onClick={() => {
                   setDocumentFocus(document);
                   dispatch(setActiveModal('document-edit'));
                  }
                }
                data-testid="orderDocuments.document.context.edit"
              >
                <b>Edit</b>
              </Dropdown.Item>
              <Dropdown.Item
                data-bs-toggle="modal"
                data-bs-target="#orderdocumentmodaldeleteconfirm"
                data-testid="orderDocuments.document.context.delete"
                onClick={e => {
                   setselectedDocument(document);
                  }
                }
              >
                <b>Delete Document</b>
              </Dropdown.Item>
              </>
              ):null}
              { !readOnly && document && (document.documentStatus === 'Prepared' || document.documentStatus === 'Generated') && ( document.documentTemplate?.slug === 'tec-invoice-template' || document.documentTemplate?.slug === 'tec-invoice-template-v2') ? (
              <>
              <Dropdown.Item
                data-bs-toggle="modal"
                data-bs-target="#documentsInvoiceModal"
                data-testid="orderDocuments.document.context.edit"
                onClick={(e) => {
                  setDocumentFocus(document);
                  dispatch(setActiveModal('invoice-edit'));
                }}
              >
                <b>Edit Invoice</b>
              </Dropdown.Item>
              <Dropdown.Item
                data-bs-toggle="modal"
                data-bs-target="#orderdocumentmodaldeleteconfirm"
                data-testid="orderDocuments.document.context.delete"
                onClick={e => {
                   setselectedDocument(document);
                  }
                }
              >
                <b>Delete Document</b>
              </Dropdown.Item>
              </>
              ):null}
            </DocumentInfoTemplate>
             <Accordion.Collapse eventKey={document.id} className="p-3" in={isOpen?.selectedProduct===document.id}>
              <div>
            </div>
            </Accordion.Collapse>
          </Card>)}
          </>
        ):null}
        </div>
      )):('')}
      </Accordion>
        </div>
    <div
    className="modal fade"
    id="orderdocumentmodaldeleteconfirm"
    tabIndex="-1"
    aria-labelledby="exampledownpaymentmodel"
    aria-hidden="true"
    data-bs-backdrop="static" 
    data-bs-keyboard="false"
  >
    <div className="modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header bg-primary text-white">
          <h5
            className="modal-title text-capitalize"
            id="exampledownpaymentmodel"
          >
            Delete Document
          </h5>
          <button
            type="button"
            className="btn-close bg-white"
            data-bs-dismiss="modal"
            aria-label="Close"
            data-testid="modal.deleteDocument.control.close"
          ></button>
        </div>
        <div className="modal-body">
          <div className="px-3 py-3">
            <h4 className="container text-secondary text-center my-3">
              Are you sure you want to delete this document?
            </h4>
          </div>
        </div>
        <div className="modal-footer text-center">
          <div className="mx-auto">
            <button
              type="button"
              className="btn btn-primary mx-3"
              onClick={(e) => deleteDocument(e, selectedDocument.id)}
              data-bs-dismiss="modal"
              data-testid="modal.deleteDocument.button.delete"
            >
              Delete
            </button>
              <button
              type="button"
              className="btn btn-secondary mx-3"
              data-bs-dismiss="modal"
              data-testid="modal.deleteDocument.button.cancel"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  );
}

export default OrderDocumentsSection
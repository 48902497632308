
import React from 'react'
import { Card, Dropdown } from "react-bootstrap";
import { Menu } from "@material-ui/icons";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { ArrowLeft } from '@material-ui/icons';
import { ArrowDropDown } from '@material-ui/icons';

const  SectionTemplate = ( { title, recCount, eventKey, children, mode, customerName, isOpen, setIsOpen, tray, readOnly=false, productGroupId, isOpenValues, accordianColor="#2d7ecf", borderRadius='inherit'} ) => {

const handleToggle = useAccordionButton(eventKey, ()=>{
  if(tray === 'productGroups'){
    if(isOpenValues.selectedProductGroup === productGroupId){
      setIsOpen((prev)=>({
        ...prev,
        selectedProductGroup: undefined
      }))
    }else{
      setIsOpen((prev)=>({
        ...prev,
        selectedProductGroup: productGroupId
      }))
    }
  }else{
    setIsOpen((prev)=>({
      ...prev,
      [tray]: !isOpen
    }))
  }
})

  return (
    <Card.Header style={{ backgroundColor: accordianColor, color: 'white', borderRadius: borderRadius }} >
      { !readOnly ? (
        <>
          { tray && tray !== 'productGroups' ? (
            <Dropdown style={{ display: 'inline-table' }} >
              <Dropdown.Toggle  data-testid={title.toLowerCase().replace(" ", "")+"section.contextMenu"} style={{ backgroundColor: accordianColor, color: 'white', border: 'none', paddingTop: '1px' }}>
                <Menu />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                  { children }
              </Dropdown.Menu>
            </Dropdown>
          ):null}
        </>
      ):null}
        <span style={{ paddingLeft: '15px', fontWeight: 'bold' }}>{title} 
          { recCount && recCount > 0 ? (
            <span style={{ borderRadius: '3px', background: 'rgba(255, 255, 255, 0.5)', color: '#fff', paddingLeft: '0.33rem', paddingRight: '0.33rem', paddingTop: '0.1rem', paddingBottom: '0.1rem' }} className="m-2">
              <span style={{ opacity: '1' }}>{ recCount }</span>
            </span>
          ):null }
          { customerName ? (
            <span style={{ borderRadius: '3px', background: 'rgba(255, 255, 255, 0.5)', color: '#fff' }} className="m-2 px-2 pb-1">
              <span style={{ opacity: '1', fontSize: '0.8rem' }} >{ customerName }</span>
            </span>
          ):null}
        </span>
        { ((recCount && recCount > 0) || mode === 'static'|| title==='DOCUMENTS') ? (
          <button
          type="button"
          style={{ backgroundColor: accordianColor, color: 'white', border: 'none', maxWidth: '25px', float: 'right' }}
          key={eventKey}
          data-testid={title.toLowerCase().replace(" ", "")+"Section.control.accordianArrow"}
          onClick={(e) => handleToggle(eventKey)}
          >
            { isOpen ? ( <ArrowDropDown /> ) : ( <ArrowLeft /> ) }
          </button>
        ):(
          title!=='DOCUMENTS'&& <div className="section-btn" style={{ width: 'fit-content', float: 'right', color: 'white' }}>
            { children?.length > 0 ? children[0] : children }
          </div>
        ) }
    </Card.Header>
  );
};


export default SectionTemplate;

import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  useParams,
} from "react-router-dom";
import { TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {recordFrontEndError } from "../../../../../../utlis/api";
import * as api from "../../../../../../utlis/api";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import States from './States';
import { useOrder } from "../../../../../../redux/orders/order"
// import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import {matchSorter} from 'match-sorter';
import { addTestingTag } from "../../../../../../utlis/testingHelpers";

const AddDocumentModal = ({updateOrderDocsInfo, documents, setIsOpen, isOpen, setIsAddingDocument, setOrderdocuments}) => {
  // const history = useHistory();
  // const axios = useFetch()

  let { id } = useParams();
  // const dispatch = useDispatch();
  const order = useOrder();
  


  // const [dataCalcdate, setDataCalcdate] = useState('cal'); // 'cal','fis'

  // const onDataCalcDateChange = (event) => {
  //   setDataCalcdate(event.target.value);
  // };
  const closeButtonRef = useRef(null);
  const [availableDocuments, setAvailableDocuments] = useState(undefined);
  const [showSelectedTypeDropdown, setShowSelectedTypeDropdown] = useState(false);
  const [documentTemplateOptions, setdocumentTemplateOptions] = useState([]);

  const getDocuments= useCallback(async ()=>{
    try{
      let res = await api.getDocumentTemplates();
      setAvailableDocuments(res?.data);
    } catch{
      setAvailableDocuments([]);
    }
  }, [])

  useEffect(() => {
    if(availableDocuments === undefined){
      getDocuments()
    }else{
      if(documentTemplateOptions.length === 0){
        // let documentTemplateOptions = [];
        if(availableDocuments && availableDocuments.length > 0) {
          
          var filtered = availableDocuments.filter(el =>{
            if(
              // el.slug !== "tec-sales-order-single" &&
              el.slug !== "tec-invoice-schedule-a" &&
              el.slug !== "tec-sales-order-schedule-a" &&
              el.slug !== "tec-invoice-template" &&
              el.slug !== "tec-invoice-template-v2" &&
              el.slug !== "tec-invoice-trade-schedule" &&
              el.slug !== "tec-sales-order-multi" &&
              el.slug !== "tec-sales-order-multi-trade-multi" &&
              el.slug !== "tec-sales-order-single-trade-multi" &&
              el.slug !== "tec-sales-order-single-extended" &&
              el.slug !== "tec-sales-order-multi-extended" && 
              el.slug !== "tec-sales-order-single-trade-multi-extended" &&
              el.slug !== "tec-sales-order-multi-trade-multi-extended" &&
              el.slug !== "tec-poa-no-notary" &&
              el.slug !== "ca-title" &&
              el.slug !== "tec-statement-of-error-notary"
            ){
              return el;
            }
            return null;
          })
          
          let documentTemplateOptionstemp = filtered.sort((a, b) => {
            if (a.name.includes('TEC') && !b.name.includes('TEC')) {
              // If a contains 'TEC' but b does not, push a to the start of the array
              return -1;
            } else if (!a.name.includes('TEC') && b.name.includes('TEC')) {
              // If b contains 'TEC' but a does not, push b to the start of the array
              return 1;
            } else {
              // Otherwise, sort alphabetically
              return a.name.localeCompare(b.name);
            }
          }).map(el => {
            return {id: el.id, label: el.name};
          })

          setdocumentTemplateOptions(documentTemplateOptionstemp);
        }
      }
    }
  }, [availableDocuments, documentTemplateOptions.length, getDocuments]);


  const _filterOptions = createFilterOptions();

  const filterOptions = React.useCallback((options, state) => {  
      const results = _filterOptions(options, state);
      return results;
  }, [_filterOptions]);

    
    const [optionCount, setOptionCount] = React.useState(0);
    const filterDocumentOptions = React.useCallback((options, state) => {  

      if(options?.length > 0){
      
      let allItems = options.map((s) => {
        if(s?.label){
          let searchableText = s.label;
          searchableText = searchableText.replace("-", "");
          searchableText = searchableText.replace(" - ", "");        
          const nameParts = searchableText.split(" ");
          return {
            label: s.label,
            part1: nameParts[0],
            part2: nameParts[1],
            part3: nameParts[2],
            part4: nameParts[3],
            part5: nameParts[4],
            part6: nameParts[5],
            part7: nameParts[6],
            part8: nameParts[7],
            part9: nameParts[8],
            part10: nameParts[9],
            part11: nameParts[10],
            part12: nameParts[11],
            part13: nameParts[12],
            part14: nameParts[13],
            part15: nameParts[14],
            part16: nameParts[15],
            part17: nameParts[16],
            part18: nameParts[17],
            part19: nameParts[18],
            part20: nameParts[19],
            id: s.id
          };
        }else{
          return s;
        }
      });
    
      let words = state.inputValue.split(" ");
      let results = words.reduceRight(
        (allItems, word) =>
          matchSorter(allItems, word, {
            keys: [
              "part1",
              "part2",
              "part3",
              "part4",
              "part5",
              "part6",
              "part7",
              "part8",
              "part9",
              "part10",
              "part11",
              "part12",
              "part13",
              "part14",
              "part15",
              "part16",
              "part17",
              "part18",
              "part19",
              "part20",
            ]
          }), allItems );
        

      // const results = _filterOptions(options, state);

      if (optionCount !== results.length) {
        setOptionCount(results.length);
      }

      return results;
    }
    }, [ optionCount ]);

  // let documentTemplateOptions = [];
  // if(availableDocuments && availableDocuments.length > 0) {
    
  //   var filtered = availableDocuments.filter(el =>{
  //     if(
  //       // el.slug !== "tec-sales-order-single" &&
  //       el.slug !== "tec-invoice-schedule-a" &&
  //       el.slug !== "tec-sales-order-schedule-a" &&
  //       el.slug !== "tec-invoice-template" &&
  //       el.slug !== "tec-invoice-template-v2" &&
  //       el.slug !== "tec-invoice-trade-schedule" &&
  //       el.slug !== "tec-sales-order-multi" &&
  //       el.slug !== "tec-sales-order-multi-trade-multi" &&
  //       el.slug !== "tec-sales-order-single-trade-multi" &&
  //       el.slug !== "tec-sales-order-single-extended" &&
  //       el.slug !== "tec-sales-order-multi-extended" && 
  //       el.slug !== "tec-sales-order-single-trade-multi-extended" &&
  //       el.slug !== "tec-sales-order-multi-trade-multi-extended" &&
  //       el.slug !== "tec-poa-no-notary" &&
  //       el.slug !== "tec-statement-of-error-notary"
  //     ){
  //       return el;
  //     }
  //     return null;
  //   })
    
  //   documentTemplateOptions = filtered.sort((a, b) => {
  //     if (a.name.includes('TEC') && !b.name.includes('TEC')) {
  //       // If a contains 'TEC' but b does not, push a to the start of the array
  //       return -1;
  //     } else if (!a.name.includes('TEC') && b.name.includes('TEC')) {
  //       // If b contains 'TEC' but a does not, push b to the start of the array
  //       return 1;
  //     } else {
  //       // Otherwise, sort alphabetically
  //       return a.name.localeCompare(b.name);
  //     }
  //   }).map(el => {
  //     return {id: el.id, label: el.name};
  //   })
  // }

  const [selectedProduct, setselectedProduct] = useState(undefined);
  const [productOptions, setproductOptions] = useState(undefined);

  const [selectedTradein, setselectedTradein] = useState(undefined);
  const [tradeinOptions, setTradeinOptions] = useState(undefined);

  useEffect(() => {
    
    if(order && order.products && order.products.length > 0){
      let getProductOptions = [];
      order.products.map(product => {
        let str = `${product.stockNumber.toString().toUpperCase()}`;
        let obj = {id: product.id, name: str}
        getProductOptions.push(obj);
        return null;
      })
      getProductOptions.sort((a, b) => {
        return a.name?.localeCompare(b.name, undefined, { numeric: true })}).unshift({name:'Select All', id: 'Select All'});
      setproductOptions(getProductOptions);
    }
    
    if(order && order.tradeins && order.tradeins.length > 0){
      let getTradeinOptions = [];
      order.tradeins.map(tradein => {
        let str = `${tradein.vin.toString().toUpperCase()}`;
        let obj = {id: tradein.id, name: str}
        getTradeinOptions.push(obj);
        return null;
      })
      getTradeinOptions.sort((a, b) => {
        return a.name?.localeCompare(b.name, undefined, { numeric: true })}).unshift({name:'Select All', id: 'Select All'});
      setTradeinOptions(getTradeinOptions);
    }

  },[order]);
  
  const [documentTemplate, setdocumentTemplate] = useState(undefined);
  const [saveReady, setSaveReady] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [documentFound, setDocumentFound] = useState(false);
  const [selectedDocumentTemplate, setSelectedDocumentTemplate] = useState(undefined);
  const [selectedDocumentTemplateType, setSelectedDocumentTemplateType] = useState({ slug: undefined, name: undefined});
  const [availableTypeOptions, setAvailableTypeOptions] = useState([
    { slug: 'order', name: "ORDER"},
    { slug: 'product', name: "PRODUCT"},
    { slug: 'tradein', name: "TRADEIN"},
  ]);

  function isOrderDocType(type){
    if(
      type === "order" || 
      type === "both-order" || 
      type === "product-order" || 
      type === "product-selected-order" || 
      type === "order-selected" || 
      type === "special-sales" || 
      type === "special"
    ){
      return true;
    }
    return false;
  }

  function checkForDocument(selectedDocumentTemplate, assignedObjectId=null){


    let documentFound = false;
    if(documents && documents.length > 0){
      documents.forEach(document => {

        if(document && document.documentTemplate && document.documentTemplate.type === selectedDocumentTemplate.type){

          if(isOrderDocType(document.documentTemplate.type) && document.assignedObjectType && document.assignedObjectType === 'order' && 
          ((selectedDocumentTemplate.type === 'both-order' || selectedDocumentTemplate.type === 'product-order' || selectedDocumentTemplate.type === 'product-selected-order') && selectedDocumentTemplateType === 'order')){
           
            if(document.documentTemplate.slug === selectedDocumentTemplate.slug){
              documentFound = true;
            }

          } else {

            // Check if product or trade in is assigned.
            if(assignedObjectId && document.assignedObjectId === assignedObjectId && document.assignedObjectType === selectedDocumentTemplateType.slug){
              if(document.documentTemplate.slug === selectedDocumentTemplate.slug){
                documentFound = true;
              }
            }

          }
        }
      });
    }
    setDocumentFound(documentFound);
    return documentFound;
  }

  const onDocumentTemplateChange = (value) => {
    setSaveSuccess(false);
    setSaveReady(false);
    setDocumentFound(false);
    setselectedProduct(undefined)
    setselectedTradein(undefined)
    setSelectedDocumentTemplateType({ slug: undefined, name: undefined})
    setAvailableTypeOptions([
      { slug: 'order', name: "ORDER"},
      { slug: 'product', name: "PRODUCT"},
      { slug: 'tradein', name: "TRADEIN"},
    ])
    // availableDocuments        
    let selectedDocument = availableDocuments.find((document) => document.id === Number(value.id));
    if(selectedDocument){
      setSelectedDocumentTemplate(selectedDocument);
      setShowSelectedTypeDropdown(false);
      setSelectedDocumentTemplateType({ slug: undefined, name: undefined})
      if(isOrderDocType(selectedDocument.type)){
        setSelectedDocumentTemplateType({ slug: 'order', name: 'Order'});
        if(selectedDocument.type === 'both-order' || selectedDocument.type === 'product-order' || selectedDocument.type === 'product-selected-order'){
          setShowSelectedTypeDropdown(true);
        }
        if(!checkForDocument(selectedDocument)){
          setSaveReady(true);
        }
      } else if(selectedDocument.type === "product" || selectedDocument.type ===  "product-selected"){
        setSelectedDocumentTemplateType({ slug: 'product', name: 'Product'});
      } else if(selectedDocument.type === "tradein"){
        setSelectedDocumentTemplateType({ slug: 'tradein', name: 'Tradein'});
      } else if(selectedDocument.type === "both"){
        setAvailableTypeOptions([
          { slug: 'product', name: "PRODUCT"},
          { slug: 'tradein', name: "TRADEIN"},
        ])
        setSelectedDocumentTemplateType({ slug: 'product', name: 'Product'});
        setShowSelectedTypeDropdown(true);
      }
    } 
    setdocumentTemplate(value)
  }

  const onSelectedProductChange = (value) => {
    setSaveReady(false);
    // if ''select all'' filter products with documents with ```checkForDocument(selectedDocumentTemplate, product.id)``` before setting state
    if(value.name === 'Select All'){
      let filteredArray = order.products.filter((product)=>!checkForDocument(selectedDocumentTemplate, product.id))
      setselectedProduct({stockNumber:'Select All', name:'Select All', id:'selectAll', productArray:filteredArray });
      setSaveReady(true);
    } else {
      let selectedProduct = order.products.find((product) => product.id === Number(value.id));
      if(selectedProduct){
        setselectedProduct(selectedProduct);
      }
      if(selectedDocumentTemplateType?.slug === 'product'){
        if(!checkForDocument(selectedDocumentTemplate, selectedProduct.id)){
          setSaveReady(true);
        }
      }

    }

  }

  const onSelectedTradeinChange = (value) => {
    setSaveReady(false);
    if(value.name === 'Select All'){
      // if ''select all'' filter products with documents with ```checkForDocument(selectedDocumentTemplate, tradein.id)``` before setting state
      let filteredArray = order.tradeins.filter((tradein)=>!checkForDocument(selectedDocumentTemplate, tradein.id))
      setselectedTradein({vin:'Select All', name:'Select All', id:'selectAll', tradeinArray:filteredArray });
      setSaveReady(true);
    } else {
      let selectedTrade = order.tradeins.find((tradein) => tradein.id === Number(value?.id));
      if(selectedTrade){
        setselectedTradein(selectedTrade);
      }
      if(selectedDocumentTemplateType?.slug === 'tradein'){
        if(!checkForDocument(selectedDocumentTemplate, selectedTrade.id)){
          setSaveReady(true);
        }
      }
    }

  }

  const resetVals = () => {
    setSelectedDocumentTemplateType({ slug: undefined, name: undefined});
    setselectedProduct(undefined);
    setselectedTradein(undefined);
    setShowSelectedTypeDropdown(false);
    setdocumentTemplate(undefined);
    setSaveSuccess(false);
    setSaveReady(false);
    setDocumentFound(false);
    setAvailableTypeOptions([
      { slug: 'order', name: "ORDER"},
      { slug: 'product', name: "PRODUCT"},
      { slug: 'tradein', name: "TRADEIN"},
    ])
  }

//come back to handle select all option (if state === array...)

const createDraft = async event => {
  event.preventDefault();
  if(documentTemplate && documentTemplate.id){
    let documentDraftData = {
      DocumentStatusId: 5, //Draft
      DocumentTemplateId: documentTemplate.id,
      OrderId: id,
      Payload: JSON.stringify({}),
    }
    // Add the temporary card to the documents array
    const tempDocument = { ...documentDraftData };
    const tempTemplate = { name: documentTemplate.label };
    tempDocument.isTemp = true;
    tempDocument.documentTemplate = tempTemplate;
    setOrderdocuments((prevDocuments) => [
      ...prevDocuments,
      tempDocument,
    ]);
    documentDraftData.AssignedObjectType = selectedDocumentTemplateType?.slug;
      if(selectedProduct?.productArray || selectedTradein?.tradeinArray){
        if(selectedProduct?.productArray.length>0){
          selectedProduct?.productArray.forEach(async product => {
          documentDraftData.AssignedObjectId = product.id
          try {
           await api.addDocument(documentDraftData)
            } catch (err) {
              recordFrontEndError('AddDocumentModal.jsx', 'Error adding draft: ' +err)
            }
          })
          setSaveSuccess(true);
          updateOrderDocsInfo(true, true);
          resetVals();
        } else if (selectedTradein?.tradeinArray.length>0){
          selectedTradein?.tradeinArray?.forEach(async tradein => {
          documentDraftData.AssignedObjectId = tradein.id
          try {
            await api.addDocument(documentDraftData)
            } catch (err) {
              recordFrontEndError('AddDocumentModal.jsx', 'Error adding draft: ' +err)
            }
          })
          setSaveSuccess(true);
          updateOrderDocsInfo(true, true);
          resetVals();
        }
      } else {
        if(selectedProduct && selectedProduct.id > 0 && selectedDocumentTemplateType?.slug === 'product'){
          documentDraftData.AssignedObjectId = selectedProduct.id;
        }else if(selectedTradein && selectedTradein.id > 0 && selectedDocumentTemplateType?.slug === 'tradein'){
          documentDraftData.AssignedObjectId = selectedTradein.id;
        }
        
        try {
          await api.addDocument(documentDraftData)
              setSaveSuccess(true);
              updateOrderDocsInfo(true, true);
              resetVals();
              // }
        } catch (err) {
          recordFrontEndError('AddDocumentModal.jsx', 'Error adding draft: ' +err)
        }
      }
    }
  }

  // const eventKey = "7"
  // const sectionEventKeys = {
  //   "order": "1x",
  //   "product": "2d",
  //   "tradein": "3d"
  // }

  // const handleToggle = useAccordionButton(eventKey, ()=>{
  //   setIsOpen((prevState) => ({
  //     ...prevState,
  //     documents: true,
  //   }));
  // })

  // const closeModal = () => {
  //   if(closeButtonRef.current){
  //   closeButtonRef.current.click();
  //   }
  // };

  // const openRelevantSection = () => {
  //   setIsOpen((prevState) => ({
  //     ...prevState,
  //     documentSection: sectionEventKeys[selectedDocumentTemplateType.slug],
  //     documentSubSection: selectedDocumentTemplateType.slug==="product" ? selectedProduct?.id+"p" : selectedDocumentTemplateType.slug==="tradein" ? selectedTradein?.id+"t" : null
  //   }));
  // }

  const initiateCloseSequence = (e) => {
    // setIsAddingDocument(true)
    // closeModal()
    // if(!isOpen.documents){
    // handleToggle()
    // }
    // openRelevantSection()
    createDraft(e);
  }

  return (
    <>
      <div
        className="modal fade"
        id="documentsmodal"
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                Add Document
              </h5>
              <br />
              <button
                ref={closeButtonRef}
                type="button"
                className="btn-close bg-white"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={e => resetVals(e)}
                data-testid="modal.addDocument.control.close"
            ></button>
            </div>
            <Box sx={{ flexGrow: 1 }} className="mb-2">
              <Grid container className="mx-3 mt-3">
                <Grid item md={6} className="px-4 mt-3">
                  <label>Select Document</label>
                </Grid>
                <Grid item md={6} className="px-4 mt-3">
                  { documentTemplateOptions && documentTemplateOptions.length > 0 ? (
                    <Autocomplete
                    className=" m-2"
                      value={documentTemplate !== undefined && documentTemplate.label ? documentTemplate.label : null}
                      onChange={(event, newValue) => {
                        if(newValue !==  null) {
                          setselectedProduct(undefined)
                          setselectedTradein(undefined)
                          setSelectedDocumentTemplateType({ slug: undefined, name: undefined});
                          onDocumentTemplateChange(newValue);
                        }
                      }}
                      filterOptions={filterDocumentOptions}
                      options={documentTemplateOptions}
                      sx={{ width: 230}}
                      renderInput={(params) => <TextField {...addTestingTag(params, 'modal.addDocument.autocomplete.document')} label="DOCUMENT" />}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.label}
                          </li>
                        );
                      }}
                    />
                  ):(
                    <h5>Loading Document Templates</h5>
                  )}

                  {  showSelectedTypeDropdown ? (

//The `getOptionLabel` method of Autocomplete returned object ([object Object]) instead of a string for {"slug":"tradein","name":"TRADEIN"}. 
                  <Autocomplete
                    className=" m-2"
                    value={ ( selectedDocumentTemplateType?.name ? selectedDocumentTemplateType.name : null )}
                    onChange={(event, newValue) => {
                      if(newValue !== null) {
                        setselectedProduct(undefined)
                        setselectedTradein(undefined)
                        setSelectedDocumentTemplateType(newValue);
                      }
                    }}
                    getOptionLabel={(option)=> option?.name?option.name:option}
                    // inputValue={inputDocument}
                    // onInputChange={(event, newInputValue) => {
                    //   setinputDocument(newInputValue);
                    // }}
                    //id="controllable-states-demo"
                    filterOptions={filterOptions}
                    // options={[
                    //   { slug: 'order', name: "ORDER"},
                    //   { slug: 'product', name: "PRODUCT"},
                    //   { slug: 'tradein', name: "TRADEIN"},
                    // ]}
                    options={availableTypeOptions}
                    sx={{ width: 230}}
                    renderInput={(params) => <TextField {...addTestingTag(params, 'modal.addDocument.autocomplete.selectType')} label="Select Type" />}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.slug}>
                          {option.name}
                        </li>
                      );
                    }}
                  />
                  ):null}

                  {  selectedDocumentTemplateType?.slug === 'product' && productOptions && productOptions.length > 0 ? (
                  <Autocomplete
                    className=" m-2"
                    value={ selectedProduct?.productArray ? selectedProduct.name :(selectedProduct?.stockNumber ? selectedProduct.stockNumber : null)}
                    onChange={(event, newValue) => {
                      if(newValue !==  null) {
                        onSelectedProductChange(newValue);
                      }
                    }}
                    getOptionLabel={(option)=> option?.name?option.name:option}
                    // inputValue={inputDocument}
                    // onInputChange={(event, newInputValue) => {
                    //   setinputDocument(newInputValue);
                    // }}
                    //id="controllable-states-demo"
                    filterOptions={filterOptions}
                    options={productOptions}
                    sx={{ width: 230}}
                    renderInput={(params) => <TextField {...addTestingTag(params, 'modal.addDocument.autocomplete.product')} label="PRODUCT" />}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.name}
                        </li>
                      );
                    }}
                  />
                  ):null}

                  {  selectedDocumentTemplateType?.slug === 'tradein' && tradeinOptions && tradeinOptions.length > 0 ? (
                  <Autocomplete
                    className=" m-2"
                    value={ selectedTradein?.tradeinArray?selectedTradein.name:( selectedTradein && selectedTradein.vin ? selectedTradein.vin.toUpperCase() : null)}
                    onChange={(event, newValue) => {
                      if(newValue !==  null) {
                        onSelectedTradeinChange(newValue);
                      }
                    }}
                    getOptionLabel={(option)=> option?.name?option.name:option}

                    // getOptionLabel={(option)=>(option.name?option.name:'')}
                    // inputValue={inputDocument}
                    // onInputChange={(event, newInputValue) => {
                    //   setinputDocument(newInputValue);
                    // }}
                    //id="controllable-states-demo"
                    filterOptions={filterOptions}
                    options={tradeinOptions}
                    sx={{ width: 230}}
                    renderInput={(params) => <TextField {...addTestingTag(params, 'modal.addDocument.autocomplete.tradein')} label="TRADE IN" />}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.name}
                        </li>
                      );
                    }}
                  />
                  ):null}
                </Grid>

              </Grid>
            </Box>
 
            {documentFound ? (
              <span data-testid="modal.addDocument.message.documentFound" className="mx-auto bolder m-2 text-danger">Document already found on { selectedDocumentTemplateType?.name }.</span>
            ): !saveReady ? (
              <div className="text-center m-3 mb-4">
              { saveSuccess ? (
              <button className="btn btn-success" 
              >
                Created Draft
              </button>
              ) : (
                <button className="btn btn-primary" 
                type="button"
                disabled={true}
              >
                Create Draft
              </button>
              ) }
            </div>
            ):(
              <div className="text-center m-3 mb-4">
                { saveSuccess ? (
                <button className="btn btn-success" 
                >
                  Created Draft
                </button>
                ) : (
                  <button className="btn btn-primary" data-testid="modal.addDocument.button.createDraft"
                  type="button"
                  onClick={(e) => initiateCloseSequence(e)}
                  // data-bs-dismiss="modal"
                >
                  Create Draft
                </button>
                ) }
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddDocumentModal

import moment from 'moment'
import { formatNameLastMiddleFirst, formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";
import { salesPriceWithUpgradeCalc } from '../../../../../../../utlis/productCalculations.js';

export const SDDealerReportGlobalAutofill = (documentValues, order, property=null, value=null, location=null) => {

  var newValues = documentValues;

  if(property ==='customer.name'){
    if(order.customer.type === "entity"){
      newValues.name = value;
      if(order.customer.dba){
        newValues.name +=" dba "+order.customer.dba;
      }
    }else{
      newValues.name = formatNameLastMiddleFirst(value);
      if(order.customer.dba){
        newValues.name += " dba "+order.customer.dba;
      }
    }
  }

  if(property ==='customer.dba'){
    if(order.customer.type === "entity"){
        newValues.name = order.customer.name;
    }else{
        newValues.name = formatNameLastMiddleFirst(order.customer.name);
    }
    if (value!==''){
      newValues.name+= " dba "+value
    }
  }

  if(property ==='customer.deliveryAddress.addressLine1'){
    newValues.address = value;
    if(value != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
      newValues.address +=" "+order.customer.deliveryAddress.addressLine2;
    }
}
  if(property ==='customer.deliveryAddress.addressLine2'){
    newValues.address = order.customer.deliveryAddress.addressLine1+value;
}
  if(property ==='customer.deliveryAddress.city'){
    newValues.city = value;
}
  if(property ==='customer.deliveryAddress.state'){
    newValues.state = value;
}
  if(property ==='customer.deliveryAddress.zip'){
    newValues.zip_code = value;
}
  if(property ==='documentationDate'){
  newValues.date_of_sale = moment.utc(value).format("YYYY-MM-DD");
  newValues.date = moment.utc(value).format("YYYY-MM-DD");
}
  if(property==='location'){
    newValues.dealerships_name = "TEC EQUIPMENT, INC.";
    newValues.dealership_address = value.address;
    newValues.dealer_license_number = value.dealerLicenseNumber;
    newValues.city_2 = value.city;
    newValues.state_2 = value.state;
    newValues.zip_2 = value.zip;
}

  if(property !== null && value !== null){
    return newValues;
}
  
  if(order && order.customer){

    if(order && order.customer && order.customer.name){

      // newValues.phone_number = order.customer.phone;

      if(order.customer.type === "entity"){
        newValues.name = order.customer.name;
        if(order.customer.dba){
          newValues.name = order.customer.name+" dba "+order.customer.dba;
        }
      }else{
        newValues.name = formatNameLastMiddleFirst(order.customer.name);
        if(order.customer.dba){
          newValues.name = order.customer.name+" dba "+order.customer.dba;
        }
      }

    }
    
    if(order && order.customer && order.customer.deliveryAddress){
      newValues.address = order.customer.deliveryAddress.addressLine1;
      if(order.customer.deliveryAddress.addressLine1 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        newValues.address = newValues.address+" "+order.customer.deliveryAddress.addressLine2;
      }
      newValues.city = order.customer.deliveryAddress.city;
      newValues.state = order.customer.deliveryAddress.state;
      newValues.zip_code = order.customer.deliveryAddress.zip;
    }
  }
  
  if(order && order.documentationDate){
    newValues.date_of_sale = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  }

  if(location){
    newValues.dealerships_name = "TEC EQUIPMENT, INC.";
    newValues.dealership_address = location.address;
    newValues.dealer_license_number = location.dealerLicenseNumber;
    newValues.city_2 = location.city;
    newValues.state_2 = location.state;
    newValues.zip_2 = location.zip;
  }
  return newValues
}

export const SDDealerReportAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
    var newValues = documentValues;

    if(property === 'year' && value !== null){
      newValues.year = (value).toString().trim().replace(/['"]+/g, '');
    }

    if(property === 'make' && value !== null){
        newValues.make = (value).toString().trim();
    }

    if(property === 'price' && value !== null){
        newValues.selling_price = formatMoneyValue(salesPriceWithUpgradeCalc(selectedProduct));
    }

    if(property !== null && value !== null){
        return newValues;
    }

    newValues.year = (selectedProduct.year);
    newValues.vin = (selectedProduct.vin).toString().toUpperCase();
    newValues.make = (selectedProduct.make).toString().toUpperCase();
    newValues.selling_price = formatMoneyValue(salesPriceWithUpgradeCalc(selectedProduct));
    
    return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  if(payload.date_of_sale){
    payload.date_of_sale = moment.utc(payload.date_of_sale).format("MM/DD/YY");
  }

  if(payload.date){
    payload.date = moment.utc(payload.date).format("MM/DD/YY");
  }

  return payload;
}
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {  Checkbox, FormGroup } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

const FASTFormElement = ({type, property, value, label, options, changeValueFunction, onBlurFunction}) => {

    return (
        <>
            { type === "Checkbox" ? (
                <FormGroup className="m-2">
                    <FormControlLabel 
                    control={<Checkbox color="default" 
                    name={property}
                    checked={(value ? value : false)}
                    onChange={(e) => { changeValueFunction(e) }} />} 
                    onBlur={(e) => onBlurFunction(e)}
                    label={label}
                    />
                </FormGroup>
            ):null}

            { type === "Radio" ? (
                <>
                    { label && label !== "" ? (
                        <label>
                            {label}
                        </label>
                    ):null}
                    <FormControl className="px-3 pt-2">
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            value={value ?? ""}
                            name={property}
                            onBlur={(e) => onBlurFunction(e)}
                            onChange={e => changeValueFunction(e) }>
                            { options && options.length > 0 ? ( options.map(option => {
                                return <FormControlLabel className="px-3" value={option.value} control={<Radio />} label={option.label} />
                            })):null}
                        </RadioGroup>
                    </FormControl>
                </>
            ):null}


            { type === "Text" ? (
                <>
                    { label && label !== "" ? (
                        <label>
                            {label}
                        </label>
                    ):null}                            
                    <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name={property}
                        onChange={changeValueFunction}
                        onBlur={(e) => onBlurFunction(e)}
                        value={ value ?? '' }
                        />
                </>
            ):null}
        </>
    );
}

export default FASTFormElement
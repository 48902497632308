
import React from 'react'
import { Card, Dropdown } from "react-bootstrap";
import { Menu } from "@material-ui/icons";
// import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import moment from "moment";
import { formatter } from "../../../../../../utlis/formatters";

const FundingPaymentInfoTemplate = props => {
  const {
    amount,
    paymentMethod,
    status,
    documentNumber,
    dateReceived,
    adminFee,
    gap,
    fundingReserve,
    nettedPayoff,
    children
  } = props

  // var formatter = new Intl.NumberFormat('en-US', {
  //   style: 'currency',
  //   currency: 'USD',
  // });

  return (
    <Card.Header style={{ backgroundColor: '#fff', border: 'none', borderRadius: '0px'}}>
      <div className='row'>
        <div className="d-flex align-items-center justify-content-between p-2">
          <Dropdown style={{ display: 'inline-table' }}>
            <Dropdown.Toggle style={{ backgroundColor: '#fff', color: 'black', border: 'none', paddingTop: '1px' }}>
              <Menu />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              { children }
            </Dropdown.Menu>
          </Dropdown>
          <div>
            <h5 className="text-uppercase">{ formatter.format(amount) }</h5>
            <p className="mx-0 text-secondary pt-3">Amount</p>
          </div>
          <div>
            <h5 className="text-uppercase">{ formatter.format(adminFee) }</h5>
            <p className="mx-0 text-secondary pt-3">Admin Fee</p>
          </div>
          <div>
            <h5 className="text-uppercase">{ formatter.format(gap) }</h5>
            <p className="mx-0 text-secondary pt-3">GAP</p>
          </div>
          <div>
            <h5 className="text-uppercase">{ formatter.format(fundingReserve) }</h5>
            <p className="mx-0 text-secondary pt-3">Funding Reserve</p>
          </div>
          <div>
            <h5 className="text-uppercase">{ formatter.format(nettedPayoff) }</h5>
            <p className="mx-0 text-secondary pt-3">Netted Payoff</p>
          </div>
          <div>
            <h5 className="text-uppercase">{ paymentMethod }</h5>
            <p className="mx-0 text-secondary pt-3">Payment Method</p>
          </div>
          <div>
            <h5 className="text-uppercase">{ documentNumber }</h5>
            <p className="mx-0 text-secondary pt-3">Document #</p>
          </div>
          <div>
            <h5 className="text-uppercase">{ status }</h5>
            <p className="mx-0 text-secondary pt-3">Status</p>
          </div>
          <div>
            <h5 className="text-uppercase">{ dateReceived ? moment.utc(dateReceived).format("MM/DD/YYYY") : "" }</h5>
            <p className="mx-0 text-secondary pt-3">Date Received</p>
          </div>
        </div>
      </div>
    </Card.Header>
  );
};

export default FundingPaymentInfoTemplate;

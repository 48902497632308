import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  useParams
} from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { useOrder } from "../../../../../../../redux/orders/order"
import { removeChars, formatter, formatUpperCase } from "../../../../../../../utlis/formatters";
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { TECTaxWaiverGlobalAutofill, TECTaxWaiverAutofillProductDetails, preProcessPayload } from "../DocumentAutofill/TECTaxWaiver"

const TECTaxWaiver = ({document_id, location, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "tec-tax-waiver";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  // App Logic & Data //
  let { id } = useParams();
  const order = useOrder();
  const axios = useFetch();

  const [documentUrl, setdocumentUrl] = useState("");
  const [document, setDocument] = useState(undefined);
  const [previousDocumentId, setPreviousDocumentId] = useState(0);
  
  var initialDocumentStructure = {
    location_address: undefined, // Header Location Details / System Autofill
    location_phone: undefined, // Header Location Details / System Autofill
    location_email: undefined, // Header Location Details / System Autofill
    tec_footer_label: undefined, // Footer / System Autofill
    pg_no: undefined, // Footer / System Autofill
    
    vin: undefined, //Autofill
    vehicle: undefined, //Autofill
    // date: undefined,
    purchaser_name: undefined, //Autofill
    purchaser_address: undefined, //Autofill
    purchaser_city_state_zip: undefined, //Autofill
    purchaser_phone: undefined, //Autofill
  }
  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
   const removeFormatting = e =>{
    const {name,value}=e.target;
    setDocumentValues({...documentValues,[name]:removeChars(value)});
  }

  const moneyFields = useMemo(()=>{return[]},[]);
  const phoneFields = useMemo(()=>{return["purchaser_phone"]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "model"]},[]);
  const checkboxes = useMemo(()=>{return[
  ]},[]);
  // const radios = [
  // ];
  
  const handleDocumentValuesChange = e =>{
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }

    if(name.includes('serial_number')) {
        let newStr = value.replace(/[^0-9]/g,'');
        setDocumentValues({...documentValues,[name]:newStr});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  
  const [documentLoaded, setDocumentLoaded] = useState(false);
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('TECTaxWaiver.jsx', 'Error loading document: ' + err)
      }
    }
  },[axios, id])
  
  const [previewLoaded, setPreviewLoaded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
  if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
    setdocumentUrl("");
    setPreviousDocumentId(document_id);
    setDocumentLoaded(true);
    loadDocument(document_id);
  }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, documentLoaded, loadDocument ])

  const [assignedProduct, setAssignedProduct] = useState(undefined);
  // const [productVal, setproductVal] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  // const [tradeinVal, setTradeinVal] = useState(undefined);
  // const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  // const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);

// Dropdowns //

  // if(order && order.products && order.products.length > 0 && productOptions === undefined){
  //   let getProductOptions = [];
  //   order.products.map(product => {
  //     // let str = `${product.year.replace('"', '').replace('"', '')} ${product.make} ${product.model.toString().toUpperCase()} - ${product.stockNumber.toString().toUpperCase()}`;
  //     let str = `${product.stockNumber.toString().toUpperCase()}`;
  //     let obj = {id: product.id, name: str}
  //     getProductOptions.push(obj);
  //   })
  //   setproductOptions(getProductOptions);
  // }

  // if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
  //   let getTradeinOptions = [];
  //   order.tradeins.map(tradein => {
  //     let str = `${tradein.year} ${tradein.make} ${tradein.model}`;
  //     let obj = {id: tradein.id, name: str}
  //     getTradeinOptions.push(obj);
  //   })
  //   setTradeinOptions(getTradeinOptions);
  // }

  // const preProcessPayload = useCallback((payload) => {

  //   // X1.2- Field Type Specific Preview Formatting
  //   Object.keys(payload).forEach(key => {
  //     if(moneyFields.indexOf(key) >= 0){
  //         payload[key] = formatMoneyValue(payload[key]);
  //     }else if(phoneFields.indexOf(key) >= 0){
  //         payload[key] = formatPhoneNumber(payload[key]);
  //     }else if(upperCaseFields.indexOf(key) >= 0){
  //         payload[key] = formatUpperCase(payload[key]);
  //     }
  //   });
  //   setDocumentValues(JSON.parse(JSON.stringify(payload)));
  //   Object.keys(payload).forEach(key => {
  //     if(checkboxes.indexOf(key) >= 0){
  //       payload[key] = payload[key] ? "yes": "no";
  //     }
  //   });

  //   if(payload.date){
  //     payload.date = moment.utc(payload.date).format("MM/DD/YY");
  //   }
  
  //   return payload;
  // },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])

  // Autofill //

 const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
  Object.keys(payload).forEach(key => {
    if (payload[key] === null || payload[key] === undefined) {
      delete payload[key];
    }
  });
  
  if(document){
    var docCopy = JSON.parse(JSON.stringify(document));
    docCopy.payload =  JSON.parse(JSON.stringify(payload));
    docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
    docCopy.payload =  JSON.stringify(docCopy.payload);
    delete docCopy.documentStatus;
    try {
      let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
      const file = new Blob(
        [response.data], 
        {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
      setdocumentUrl(fileURL)
    } catch (err) {
      recordFrontEndError('TECTaxWaiver.jsx', 'Error previewing document payload: ' + err)
    };  
  }
},[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])

const previewDocument = useCallback(async () => {
  try {
    let response = await axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
    const file = new Blob(
      [response.data], 
      {type: 'application/pdf'});
    const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
    setdocumentUrl(fileURL)
  } catch (err) {
      recordFrontEndError('TECTaxWaiver.jsx', 'Error previewing document: ' + err)
    }
  },[ axios, document ])

 const GlobalAutofill = useCallback(async () => {
  
  let newValues = TECTaxWaiverGlobalAutofill(documentValues, order, null, null, location);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  // var newValues = documentValues;
  //   if(order && order.customer && order.customer.name){
  //     newValues.purchaser_name = order.customer.name;
  //     if(order.customer.dba){
  //       newValues.purchaser_name += " dba "+order.customer.dba;
  //     }

  //     if(order.customer.cosigners && order.customer.cosigners.length > 0){
  //       let cosigner = order.customer.cosigners[0];
  //       if(cosigner && cosigner.name){
  //         newValues.purchaser_name += " AND " +cosigner.name;
  //       }
  //     }
  //   }
    
  //   if(order && order.customer && order.customer.deliveryAddress){
  //     newValues.purchaser_address = (order.customer.deliveryAddress.addressLine1+" "+(order.customer.deliveryAddress.addressLine2 ?? ''));
  //   }
    
  //   if(order && order.customer && order.customer.deliveryAddress){
  //     newValues.purchaser_city_state_zip = (order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
  //   }
    
  //   if(order && order.customer && order.customer.phone){
  //     newValues.purchaser_phone = formatPhoneNumber(order.customer.phone);
  //   }
    
    // Get Location Address & Autofill Address
    // if(location !== undefined){
    //   newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
    // }
  
    // if(location && location.phone){
    //   newValues.location_phone = location.phone;
    // }
    
    // if(location && location.email){
    //   newValues.location_email = location.email;
    // }
  
    // if(location){
    //   if(location.state === "CA"){
    //     newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
    //   }else{
    //     newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
    //   }
    // }

    // if(order && order.documentationDate){
    //   newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    // }

    // newValues.pg_no = "Pg #1";

    // setDocumentValues(newValues);
    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  },[ documentValues, order, location ])

  const AutofillProductDetails = useCallback(async (selectedProduct, autofillProductDetails=true, productCount) => {
    let newValues = TECTaxWaiverAutofillProductDetails(selectedProduct, documentValues, null, null, null, null, autofillProductDetails, productCount);

    if(newValues){
      setDocumentValues(newValues);
      setHasUnsavedChanges(true);
      setSaveSuccess(false);
    }
    // var newValues = documentValues;
    // if(autofillProductDetails){
    //   newValues.vin = (selectedProduct.vin).trim();
    // }
    // // newValues.purchaser_phone = (selectedProduct.purchaser_phone).trim();
    // if(autofillProductDetails){
    //   newValues.vehicle = (selectedProduct.year.trim().replace('"', '').replace('"', '')+" "+selectedProduct.make.trim()+" "+selectedProduct.model.trim().toString().toUpperCase());
    // }else{
    //   newValues.vehicle = "("+productCount+") "+(selectedProduct.year.trim().replace('"', '').replace('"', '')+" "+selectedProduct.make.trim()+" "+selectedProduct.model.trim().toString().toUpperCase());
    // }
    // setDocumentValues(newValues);
  
    // // setProductToggleAutoFill(false);
    // // previewPayloadDocument(documentValues);
    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  },[ documentValues ]);

  useEffect(() => {
    if(document && document.payload && !dataLoaded){
      var existingPayload = false;
      var documentPayload = JSON.parse(document.payload);
      if(documentPayload && Object.keys(documentPayload).length > 0){
        existingPayload = true;

        if(documentPayload.date){
          documentPayload.date = moment.utc(documentPayload.date).format("YYYY-MM-DD");
        }

        setDocumentValues(documentPayload);
      }

        var foundAssignedOrder = null;
        if(document.assignedObjectType && document.assignedObjectType === 'order'){
          foundAssignedOrder = true;
        }

        var foundAssignedProduct = null;
        if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
          foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
        }

        if(foundAssignedProduct){
          setAssignedProduct(foundAssignedProduct);
        }

        // setAddressOptions(getOrderLevelAddresses(order));
        if(!existingPayload){
          GlobalAutofill();
          // previewPayloadDocument(documentValues);
          if(foundAssignedProduct){
            AutofillProductDetails(foundAssignedProduct)
          }
          if(foundAssignedOrder){
            // Autofill "See Schedule A"
            var copyDocumentValues = documentValues;
            copyDocumentValues.vin = "See Schedule A";
            setDocumentValues(copyDocumentValues);
            setHasUnsavedChanges(true);
            setSaveSuccess(false);

            //Get First Product
            if(order && order.products && order.products.length > 0){
              let firstProduct = order.products[0];
              AutofillProductDetails(firstProduct, false, order.products.length);
            }
          }
          // if(document){
          //   previewPayloadDocument(documentValues);
          // }
        }else{
          // if(document && document.itemIds){
          //   var itemIds = document.itemIds.split(',');
          //     var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
          //     let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
          //     let obj = {id: foundProduct.id, name: str}
          //     setproductVal(obj);
          //     // if(foundProduct){
          //     //   setAddressOptions(updateAddressOptions(foundProduct, order));
          //     // }
          // }

          previewDocument();
        }
        
        setDataLoaded(true);
    }else if(document && document.payload && dataLoaded && !previewLoaded){
      setPreviewLoaded(true);
      previewPayloadDocument(documentValues);
    }
  },[ documentValues, document, dataLoaded, AutofillProductDetails, GlobalAutofill, order, previewDocument, previewPayloadDocument, previewLoaded ])



  
  // Document BREAD + Preview // TODO: Move to Redux
  


  // function previewDocument(){



  // Component Events

  const updatePreview = e => {
    e.preventDefault();
    previewPayloadDocument(documentValues);
  }


  const handleSubmit = async event => {
    event.preventDefault();

    let payload = JSON.parse(JSON.stringify(documentValues));

    payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
  
    DocumentHandleSubmit(document, payload, null, () => {
      getDocuments();
      setSaveSuccess(true);
      setHasUnsavedChanges(false);
    });

    // const payload = JSON.parse(JSON.stringify(documentValues));
    // var docCopy = document;
    // docCopy.payload = payload;
    // docCopy.payload = preProcessPayload(docCopy.payload);
    // docCopy.payload =  JSON.stringify(docCopy.payload);
    // delete docCopy.documentStatus;
    
    // if(selectedProduct && selectedProduct.id){
    //   docCopy.itemIds = `${selectedProduct.id}`;
    // }

    //TODO: Add TradeId's to save.
    // try {
    //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
    //   if (res) {
    //    await axios.post(`/document/${document.id}/generate`)
    //     getDocuments();
    //     setSaveSuccess(true);
    //     setHasUnsavedChanges(false);  
    //   };
    // } catch (err) {
    //   recordFrontEndError('TECTaxWaiver.jsx', 'Error saving document: ' + err)
    // };
    
  }

// Component Helpers //

const onCloseClick = e => {
  e.preventDefault()
  setPreviewLoaded(false);
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setdocumentUrl("");
  setDocument(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
  setDocumentLoaded(false);
  setDataLoaded(false);
  setAssignedProduct(undefined);
  setDocumentFocus(undefined);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

return (
    <>
      <div
        className="modal fade"
        // id="document-edit-modal"
        id="tec-tax-waiver"
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                TEC Tax Waiver
              </h5>
              <br />
              { hasClickedClose ? (
                <div className="d-flex">
                    <p className="m-1 pt-1 text-white">Save Changes?</p>
                    <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                    <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                </div>
              ): hasUnsavedChanges ? (
              <button
                type="button"
                className="btn-close bg-white"
                aria-label="Close"
                // onClick={() => setdocumentUrl("")}
                onClick={closeClicked}
              ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill();
                      AutofillProductDetails(assignedProduct);
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>
                  <br />
                  { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                      Product Snapshot
                      <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                      </div>
                    </div>
                  ):(
                    <div className="m-2 p-2 border">
                      <span>No Assigned Product</span>
                    </div>
                  )}

                    <h5>Vehicle Information</h5>
                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Vehicle</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vehicle"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vehicle ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>
                      {/* <div className="p-2 col-md-4">
                        <label htmlFor="stock">Date</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date ?? '' }
                          />
                      </div> */}
                    </div>
                  </div>
                  <div className="row">
                    <h5>Purchaser</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_address ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">City, State, Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_city_state_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_city_state_zip ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Phone</label>
                        <input
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          onFocus={removeFormatting}
                          className="form-control rounded-pill mt-1"
                          name="purchaser_phone"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_phone ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={document  && document !== undefined && documentUrl !== undefined ? documentUrl : ""} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
           { saveSuccess ? (
              <span
                className="btn btn-success mx-auto"
              >
                Saved Document
              </span>
            ):(
              <button
              type="button"
              className="btn btn-primary mx-auto"
              // data-bs-dismiss="modal"
              onClick={handleSubmit}
              >
              Save
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default TECTaxWaiver

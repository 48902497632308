export const GET_DOCUMENT_FOCUS = "GET_DOCUMENT_FOCUS";
export const SET_DOCUMENT_FOCUS = "SET_DOCUMENT_FOCUS";
export const SAVE_DOCUMENT = "SAVE_DOCUMENT";

export const getDocumentFocus = () => {
    return {
        type: GET_DOCUMENT_FOCUS
    }
}

export const setDocumentFocus = (data) => {
    return {
        type: SET_DOCUMENT_FOCUS, 
        payload: data 
    }
}

const defaultOrdersState = {
    document_focus: null,
    // document_focus_id: null,
};

export const documentFocusReducer = (store = defaultOrdersState , action) => {
    switch (action.type) {
        case SET_DOCUMENT_FOCUS: 
            return {
                ...store,
                document_focus: action.payload
                // document_focus_id: action.payload.id
            }
        case GET_DOCUMENT_FOCUS: 
        return store.document_focus;
        // return store.document_focus_id;
        default:
            return store
     }
}

import _ from "lodash"
/*
 * VALIDATION UTILS for performing various
 * validations for differnt components.
 */

const validateEmail = email => {
  if ( _.isEmpty( email ) ) {
    return false
  }

  const re = /\S+@\S+\.\S+/;
  const isValidEmail = re.test(email);

  return isValidEmail
}

const validatePhone = phone => {
  if ( _.isEmpty( phone ) ) {
    return false
  }

  // const editphoneVal      = phone.replace(/\D/g, '');
  const isFullPhoneNumber = phone.length >= 10

  return isFullPhoneNumber
}

const validateContact = contact => {
  const REQUIRED_CONTACT_FIELDS = [ 'name','phone','company','fax','email' ]
  const isFullPhoneNumber = validatePhone(contact.phone)
  const isValidEmail      = validateEmail(contact.email)

  const allOtherFieldsValid =
    _.isNil(_.find(REQUIRED_CONTACT_FIELDS, field => _.isEmpty(contact[ field ])))

  return isFullPhoneNumber && isValidEmail && allOtherFieldsValid
}

const validateCosigner = cosigner => {
  const isValidPhone        = validatePhone(cosigner.phone)
  const isValidCosignerName = _.isEmpty( cosigner.name ) === false

  return isValidPhone && isValidCosignerName
}

const validateZip = zip => {
  let editzipVal  = zip.replace(/\D/g, '');
  editzipVal      = editzipVal.length > 9 ? editzipVal.slice(0, -1) : editzipVal;

  const isValidZip = editzipVal.length === 5 || editzipVal.length === 9 
  return isValidZip
}

const validateTaxExempt = (deliveryAddress, customer) => {

  //Based on the order, if the delivery address
  //on the order or editedDelivery address...
  if ( validateExemptFromVehicleSalesTax(deliveryAddress) ) {
    const isValidTaxInfo = validateSalesTaxFields(customer)

    return isValidTaxInfo
  }

  return false
}

// determine if sales tax info should be required based on status
const validateExemptFromVehicleSalesTax = (deliveryAddress) => {

  // const { name: orderStatus } = order.orderStatus;

  const INVALID_SALES_TAX_EXEMPTION_STATES  = []
  // const VALID_ORDER_STATUS_FOR_EXEXMPTION   = [
  //   'Docs Requested',
  //   'Docs Generated',
  //   'Docs Signed',
  //   'Funding Request',
  //   'Funded',
  //   'Booking Request',
  //   'Booked'
  // ]

  //Determine state from edited delivery address or order...
  const state = deliveryAddress.state

  return INVALID_SALES_TAX_EXEMPTION_STATES.includes(state) === false
}

// determine if sales tax info should be required based on status
const validateExemptFromNonVehicleSalesTax = (deliveryAddress) => {

  // const { name: orderStatus } = order.orderStatus;

  const INVALID_NON_VEHICLE_SALES_TAX_EXEMPTION_STATES  = []
  // const VALID_ORDER_STATUS_FOR_EXEXMPTION   = [
  //   'Docs Requested',
  //   'Docs Generated',
  //   'Docs Signed',
  //   'Funding Request',
  //   'Funded',
  //   'Booking Request',
  //   'Booked'
  // ]

  //Determine state from edited delivery address or order...
  const state = deliveryAddress.state

  return INVALID_NON_VEHICLE_SALES_TAX_EXEMPTION_STATES.includes(state) === false
}

const validateSalesTaxFields = customer => {
  if ( customer.isExemptSalesTax ) {
    return _.isEmpty(customer.iccNumber) === false
  } else {
    if(customer.deliveryAddress && customer.deliveryAddress.state && customer.deliveryAddress.state === "WA"){
      return _.isEmpty(customer.vehicleSalesTaxPercent) === false &&
      _.isEmpty(customer.vehicleTaxCounty) &&
      _.isEmpty(customer.nonVehicleSalesTaxPercent) === false &&
      _.isEmpty(customer.nonVehicleTaxCounty)
    }else{
      return _.isEmpty(customer.vehicleSalesTaxPercent) === false &&
      _.isEmpty(customer.vehicleTaxCounty)
    }
  }
}

const methods = 
{
  validatePhone,
  validateEmail,
  validateZip,
  validateContact,
  validateCosigner,
  validateTaxExempt,
  validateExemptFromVehicleSalesTax,
  validateExemptFromNonVehicleSalesTax
}

export default methods;

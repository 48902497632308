import { SET_ROLE, GET_ROLE, SET_AD_ROLE, GET_AD_ROLE, SET_DEVELOPER_ACCESS, GET_DEVELOPER_ACCESS } from "./roleTypes"


const intialState = {
    role: 'default',
    developerAccess: null,
    activeDirectoryRole: 'default'
}


export const roleReducer = (store = intialState , action) => {
     switch (action.type) {
        case SET_ROLE:
            return {
                ...store,
                role: action.payload
            }
        case GET_ROLE:
            return store.role;

        case SET_DEVELOPER_ACCESS:
            return {
                ...store,
                developerAccess: action.payload
            }
        case GET_DEVELOPER_ACCESS:
            return store.developerAccess;

        case SET_AD_ROLE:
            return {
                ...store,
                activeDirectoryRole: action.payload
            }
        case GET_AD_ROLE:
            return store.activeDirectoryRole;

        default:
           return store
     }
}
import React from "react";
import Box from "@mui/material/Box";

//Maintenance page
const Maintenance = () => {

  return (
    <Box 
    id={`maintenance-page`}
    sx={classes.OuterWrapper}
    >
      <Box 
        id={``}
        sx={classes.ContentWrapper}
      >
        Application is in Maintenance Mode
      </Box>
    </Box>
  );
};

const classes = {
  OuterWrapper: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
  ContentWrapper: {
    display: "flex",
    flexDirection: "column", 
    alignItems: "center",
    gap: 5,
  },
}

export default Maintenance;

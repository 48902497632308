import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase, getAreaCode, getPhoneNumber, formatNameLastFirstMiddle } from "../../../../../../../utlis/formatters";
 
 
export const CATitleApp343GlobalAutofill  = (documentValues, order, property=null, value=null) => {

  var newValues = documentValues;
  
  if(property?.includes('customer') || property?.includes('cosigner') || property === null){

    if(order && order.customer){

      if(order && order.customer && order.customer.name){

        newValues.area_code = getAreaCode(order.customer.phone);
        newValues.phone = getPhoneNumber(order.customer.phone);
        if(order.customer.type === "entity"){
          newValues.owner_name = order.customer.name;
          if(order.customer.dba){
            newValues.owner_name = order.customer.name+" dba "+order.customer.dba;
          }
        }else{
          newValues.owner_name = formatNameLastFirstMiddle(order.customer.name);
          if(order.customer.dba){
            newValues.owner_name = formatNameLastFirstMiddle(order.customer.name)+" dba "+order.customer.dba;
          }
        }
    
        if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner){
            newValues.owner_name_2 = cosigner.name;
            newValues.true_name_toggle = "and";
            if(cosigner.phone){
              newValues.area_code_2 = getAreaCode(cosigner.phone);
              newValues.phone_2 = getPhoneNumber(cosigner.phone);
            }
          }
        }
      }
    
      if(order && order.customer && order.customer.deliveryAddress){
        if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
          newValues.address_line_2 = order.customer.deliveryAddress.addressLine2;
        }
        newValues.address_line_1 = order.customer.deliveryAddress.addressLine1;
        newValues.city = order.customer.deliveryAddress.city;
        newValues.state = order.customer.deliveryAddress.state;
        newValues.zip = order.customer.deliveryAddress.zip;
      }
    }

  }

  if(property?.includes('documentationDate') || property === null){

    if(order && order.documentationDate){
      newValues.signature_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }

  }

  return newValues
}


export const CATitleApp343AutofillProductDetails  = (selectedProduct, documentValues, property=null, value=null) => {
      var newValues = documentValues;

      newValues.vin = (selectedProduct.vin);
      newValues.copy_vin = (selectedProduct.vin);
      newValues.make = (selectedProduct.make);
      newValues.copy_make = (selectedProduct.make);
      newValues.year_model = (selectedProduct.year);
      newValues.copy_year_model = (selectedProduct.year);
      newValues.model = (selectedProduct.model).toString().toUpperCase();
      newValues.body_type = (selectedProduct.vehicleType);

      if(selectedProduct.fundingDetail && (selectedProduct.fundingDetail.fundingMethod === "tec-financing" || selectedProduct.fundingDetail.fundingMethod === "finance-only" || selectedProduct.fundingDetail.fundingMethod === "direct-deal")){
        newValues.lien_owner_name = selectedProduct.fundingDetail.finalizedLendingOption.bankName;
        newValues.lien_address_line_1 = selectedProduct.fundingDetail.finalizedLendingOption.lienAddress;
        newValues.lien_city = selectedProduct.fundingDetail.finalizedLendingOption.lienCity;
        newValues.lien_state = selectedProduct.fundingDetail.finalizedLendingOption.lienState;
        newValues.lien_zip = selectedProduct.fundingDetail.finalizedLendingOption.lienZip;   
      }else if(selectedProduct.fundingDetail && selectedProduct.fundingDetail.fundingMethod === "cash-with-lien"){
        newValues.lien_owner_name = selectedProduct.fundingDetail.cashLienHolder.bankName;
        newValues.lien_address_line_1 = selectedProduct.fundingDetail.cashLienHolder.addressLine1;
        newValues.lien_address_line_2 = selectedProduct.fundingDetail.cashLienHolder.addressLine2;
        newValues.lien_city = selectedProduct.fundingDetail.cashLienHolder.city;
        newValues.lien_state = selectedProduct.fundingDetail.cashLienHolder.state;
        newValues.lien_zip = selectedProduct.fundingDetail.cashLienHolder.zip;
      }else if(selectedProduct.shipTo){
        newValues.lien_owner_name = selectedProduct.shipTo.name;
        newValues.lien_address_line_1 = selectedProduct.shipTo.addressLine1;
        newValues.lien_address_line_2 = selectedProduct.shipTo.addressLine2;
        newValues.lien_city = selectedProduct.shipTo.city;
        newValues.lien_state = selectedProduct.shipTo.state;
        newValues.lien_zip = selectedProduct.shipTo.zip;
      }

      return newValues
}


export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields, documentValues) => {



  if(payload.vehicle_type){
    payload.type_auto = "no";
    payload.type_commercial = "no";
    payload.type_motorcycle = "no";
    payload.type_off_highway = "no";
    payload.type_trailer_coach = "no";

    switch(payload.vehicle_type){
      case "type_auto":
        payload.type_auto = "yes";
        break;
      case "type_commercial":
        payload.type_commercial = "yes";
        break;
      case "type_motorcycle":
        payload.type_motorcycle = "yes";
        break;
      case "type_off_highway":
        payload.type_off_highway = "yes";
        break;
      case "type_trailer_coach":
        payload.type_trailer_coach = "yes";
          break;
      default:
    }
  }

  if(payload.commercial_use){
    payload.commercial_use_yes = "no";
    payload.commercial_use_no = "no";

    switch(payload.commercial_use){
      case "yes":
        payload.commercial_use_yes = "yes";
        break;
      case "no":
        payload.commercial_use_no = "yes";
        break;
      default:
    }
  }

  if(payload.overweight){
    payload.overweight_yes = "no";
    payload.overweight_no = "no";

    switch(payload.overweight){
      case "yes":
        payload.overweight_yes = "yes";
        break;
      case "no":
        payload.overweight_no = "yes";
        break;
      default:
    }
  }

  if(payload.true_name_toggle){
    payload.owner_and = "no";
    payload.owner_or = "no";

    switch(payload.true_name_toggle){
      case "and":
        payload.owner_and = "yes";
        break;
      case "or":
        payload.owner_or = "yes";
        break;
      case "x":
        payload.owner_and = undefined;
        payload.owner_or = undefined;
        break;
      default:
    }
  }

  if(payload.true_name_toggle_2){
    payload.owner_and_2 = "no";
    payload.owner_or_2 = "no";

    switch(payload.true_name_toggle_2){
      case "and":
        payload.owner_and_2 = "yes";
        break;
      case "or":
        payload.owner_or_2 = "yes";
        break;
      case "x":
        payload.owner_and_2 = undefined;
        payload.owner_or_2 = undefined;
        break;
      default:
    }
  }

  if(payload.aquired_new_used){
    payload.pruchased_new = "no";
    payload.pruchased_used = "no";

    switch(payload.aquired_new_used){
      case "new":
        payload.pruchased_new = "yes";
        break;
      case "used":
        payload.pruchased_used = "yes";
        break;
      default:
    }
  }

  if(payload.aquired_in_ca){
    payload.purchased_ca_inside = "no";
    payload.purchased_ca_outside = "no";

    switch(payload.aquired_in_ca){
      case "inside":
        payload.purchased_ca_inside = "yes";
        break;
      case "outside":
        payload.purchased_ca_outside = "yes";
        break;
      default:
    }
  }

  if(payload.cost_detail){
    payload.purchase = "no";
    payload.gift = "no";
    payload.trade = "no";

    switch(payload.cost_detail){
      case "purchase":
        payload.purchase = "yes";
        break;
      case "gift":
        payload.gift = "yes";
        break;        
      case "trade":
        payload.trade = "yes";
        break;
      default:
    }
  }

  if(payload.purchased_from){
    payload.from_dealer_cb = "no";
    payload.from_private_party = "no";
    payload.from_dismantler = "no";
    payload.from_family_member = "no";

    switch(payload.purchased_from){
      case "from_dealer":
        payload.from_dealer_cb = "yes";
        break;
      case "from_private_party":
        payload.from_private_party = "yes";
        break;        
      case "from_dismantler":
        payload.from_dismantler = "yes";
        break;
      case "from_family_member":
        payload.from_family_member = "yes";
        break;
      default:
    }
  }

  if(payload.body_mod_toggle){
    payload.body_modifications_yes = "no";
    payload.body_modifications_no = "no";

    switch(payload.body_mod_toggle){
      case "yes":
        payload.body_modifications_yes = "yes";
        break;
      case "no":
        payload.body_modifications_no = "yes";
        break;        
      default:
    }
  }

  if(payload.sales_tax_other_state_toggle){
    payload.sales_tax_unknown = "no";
    payload.sales_tax_yes = "no";
    payload.sales_tax_no = "no";

    switch(payload.sales_tax_other_state_toggle){
      case "unknown":
        payload.sales_tax_unknown = "yes";
        break;
      case "yes":
        payload.sales_tax_yes = "yes";
        break;
      case "no":
        payload.sales_tax_no = "yes";
        break;        
      default:
    }
  }

  if(payload.commercial_toggle){
    payload.commercial_vehicle = "no";
    payload.noncommercial_vehicle = "no";

    switch(payload.commercial_toggle){
      case "commercial":
        payload.commercial_vehicle = "yes";
        break;
      case "noncommercial":
        payload.noncommercial_vehicle = "yes";
        break;        
      default:
    }
  }

  if(payload.plates_toggle){
    payload.plates_expired = "no";
    payload.plates_surrendered = "no";
    payload.plates_destroyed = "no";
    payload.plates_retained = "no";
    payload.plates_returned_to_state = "no";

    switch(payload.plates_toggle){
      case "plates_expired":
        payload.plates_expired = "yes";
        break;
      case "plates_surrendered":
        payload.plates_surrendered = "yes";
        break; 
      case "plates_destroyed":
        payload.plates_destroyed = "yes";
        break
      case "plates_retained":
        payload.plates_retained = "yes";
        break
      case "plates_returned_to_state":
        payload.plates_returned_to_state = "yes";
        break       
      default:
    }
  }

  if(payload.on_duty_toggle){
    payload.on_duty_yes = "no";
    payload.on_duty_no = "no";

    switch(payload.on_duty_toggle){
      case "yes":
        payload.on_duty_yes = "yes";
        break;
      case "no":
        payload.on_duty_no = "yes";
        break;        
      default:
    }
  }

  if(payload.previous_activeduty_toggle){
    payload.previous_active_duty_yes = "no";
    payload.previous_active_duty_no = "no";

    switch(payload.previous_activeduty_toggle){
      case "yes":
        payload.previous_active_duty_yes = "yes";
        break;
      case "no":
        payload.previous_active_duty_no = "yes";
        break;        
      default:
    }
  }

  // X1.2- Field Type Specific Preview Formatting
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  payload.copy_vin = payload.vin;

  // Padd the left side of the value with blanks so it formats as desired on the document.
  if(payload?.odometer && payload.odometer.length > 0){

    if(payload.odometer.length < 6){
      let dif = 6 - payload.odometer.length;
      let padding = "";
      
      
      for(var i = 0; i < dif; i++){
        padding += "x";
      }
      payload.odometer = padding+payload.odometer;
    }

    payload.odo_1 = (payload.odometer.charAt(0) === "x") ? "" : payload.odometer.charAt(0);
    payload.odo_2 = (payload.odometer.charAt(1) === "x") ? "" : payload.odometer.charAt(1);
    payload.odo_3 = (payload.odometer.charAt(2) === "x") ? "" : payload.odometer.charAt(2);;
    payload.odo_4 = (payload.odometer.charAt(3) === "x") ? "" : payload.odometer.charAt(3);;
    payload.odo_5 = (payload.odometer.charAt(4) === "x") ? "" : payload.odometer.charAt(4);;
    payload.odo_6 = (payload.odometer.charAt(5) === "x") ? "" : payload.odometer.charAt(5);;
  }

  if(payload.signature_date){
    payload.signature_date = moment.utc(payload.signature_date).format("MM/DD/YY");
  }

  if(payload.signature_date_2){
    payload.signature_date_2 = moment.utc(payload.signature_date_2).format("MM/DD/YY");
  }

  if(payload.signature_date_3){
    payload.signature_date_3 = moment.utc(payload.signature_date_3).format("MM/DD/YY");
  }

  return payload;
}
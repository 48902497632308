import React, { useState } from "react";
import { useSelector } from "react-redux";

// React Router components:
import { Link as RouterLink } from "react-router-dom";
// MSAL components:
import { useMsal } from "@azure/msal-react";
// MaterialUI components:
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// MaterialUI icons:
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LogoutIcon from '@mui/icons-material/Logout';
import AirportShuttle from '@mui/icons-material/AirportShuttle';
import Announcement from '@mui/icons-material/Announcement';
import AttachMoney from '@mui/icons-material/AttachMoney';
import Dashboard from '@mui/icons-material/Dashboard';
import MenuIcon from '@mui/icons-material/Menu';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ReportIcon from '@mui/icons-material/Report';

// Logo img component:
import Logo from "../../../assets/loginPage-logoTransparent.png";

const Sidebar = () => {
  const { instance } = useMsal();
  const role = useSelector((state) => state?.role.role);

  const msalLogout = event => {
    instance.logoutRedirect({
      account: instance.getActiveAccount(),
      postLogoutRedirectUri: (process?.env?.REACT_APP_LOGOUT_MSAL_REDIRECT_URI ?? null),
      // postLogoutRedirectUri: "http://localhost:3000/logout",
    });
  }

  const [open, setOpen] = useState(false);
  const handleOpen = () => (event) => {
    if (event &&event.type === "keydown" &&(event.key === "Tab" || event.key === "Shift")) {
      return
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false)
  };
  const id = open ? "navbar-sidebar-open" : undefined;

  return (
    <Box id="navbar-sidebar-wrapper">
      <IconButton
        id="navbar-sidebar-icon-button"
        edge="start"
        aria-label="menu"
        onClick={handleOpen()}
      >
        <MenuIcon id="navbar-sidebar-icon" />
      </IconButton>
      <Drawer
        id={`${id}-drawer`}
        anchor="left"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        onKeyDown={handleClose}
      >
        <Box
          id={`${id}-drawer-style-wrapper`}
          sx={{
            width: 250,
          }}
        >
          <Box
            component="img" 
            sx={{
              maxWidth: 200,
              m:3,
            }}
            id={`sidebar-page-logo`}
            src={Logo}
            alt="TEC Logo"
          />
          <List id={`${id}-list`}>

            <ListItem
              id={`${id}-list-item-0`}
              dense
              disableGutters
            >
              <ListItemButton
                id={`${id}-list-item-0-button`}
                component={RouterLink}
                to="/"
                dense
              >
                <ListItemIcon id={`${id}-list-item-0-icon`}>
                  <Dashboard id={`${id}-list-item-0-dashboard-icon`} />
                </ListItemIcon>
                <ListItemText
                  id={`${id}-list-item-0-text`}
                  primary="Dashboard"
                  primaryTypographyProps={{ variant: "subtitle1" }}
                />
              </ListItemButton>
            </ListItem>

            <ListItem
              id={`${id}-list-item-1`}
              dense
              disableGutters
            >
              <ListItemButton
                id={`${id}-list-item-1-button`}
                component={RouterLink}
                to="/order"
                dense
              >
                <ListItemIcon id={`${id}-list-item-1-icon`}>
                  <AirportShuttle id={`${id}-list-item-1-airport-shuttle-icon`} />
                </ListItemIcon>
                <ListItemText
                  id={`${id}-list-item-1-text`}
                  primary="Sales Orders"
                  primaryTypographyProps={{ variant: "subtitle1" }}
                />
              </ListItemButton>
            </ListItem>

            <ListItem
              id={`${id}-list-item-2`}
              dense
              disableGutters
            >
              <ListItemButton
                id={`${id}-list-item-2-button`}
                component={RouterLink}
                to="/sale"
                dense
              >
                <ListItemIcon id={`${id}-list-item-2-icon`}>
                  <AttachMoney id={`${id}-list-item-2-attach-money-icon`} />
                </ListItemIcon>
                <ListItemText
                  id={`${id}-list-item-2-text`}
                  primary="Sales"
                  primaryTypographyProps={{ variant: "subtitle1" }}
                />
              </ListItemButton>
            </ListItem>

            <ListItem
              id={`${id}-list-item-3`}
              dense
              disableGutters
            >
              <ListItemButton
                id={`${id}-list-item-3-button`}
                component={RouterLink}
                to="/finance-requests"
                dense
              >
                <ListItemIcon id={`${id}-list-item-3-icon`}>
                  <Announcement id={`${id}-list-item-3-announcement-icon`} />
                </ListItemIcon>
                <ListItemText
                  id={`${id}-list-item-3-text`}
                  primary="Finance Requests"
                  primaryTypographyProps={{ variant: "subtitle1" }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              id={`${id}-list-item-3`}
              dense
              disableGutters
            >
              <ListItemButton
                id={`${id}-list-item-3-button`}
                component={RouterLink}
                to="/my-checked-out"
                dense
              >
                <ListItemIcon id={`${id}-list-item-3-icon`}>
                  <ContentCopyIcon id={`${id}-list-item-3-announcement-icon`} />
                </ListItemIcon>
                <ListItemText
                  id={`${id}-list-item-3-text`}
                  primary="My Checked Out Orders"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </ListItemButton>
            </ListItem>

            { role && role === 'master' ? (

<>
            <ListItem
              id={`${id}-list-item-3`}
              dense
              disableGutters
            >
              <ListItemButton
                id={`${id}-list-item-3-button`}
                component={RouterLink}
                to="/all-checked-out"
                dense
              >
                <ListItemIcon id={`${id}-list-item-3-icon`}>
                  <AppRegistrationIcon id={`${id}-list-item-3-announcement-icon`} />
                </ListItemIcon>
                <ListItemText
                  id={`${id}-list-item-3-text`}
                  primary="All Checked Out Orders"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </ListItemButton>
            </ListItem>
           
           <ListItem
              id={`${id}-list-item-3`}
              dense
              disableGutters
            >
              <ListItemButton
                id={`${id}-list-item-3-button`}
                component={RouterLink}
                to="/user-access"
                dense
              >
                <ListItemIcon id={`${id}-list-item-3-icon`}>
                  <LocationOnIcon id={`${id}-list-item-3-announcement-icon`} />
                </ListItemIcon>
                <ListItemText
                  id={`${id}-list-item-3-text`}
                  primary="User Location Access"
                  primaryTypographyProps={{ variant: "subtitle1" }}
                />
              </ListItemButton>
            </ListItem>
            </>
            ):null}

            {/* <ListItem
              id={`${id}-list-item-4`}
              dense
              disableGutters
            >
              <ListItemButton
                id={`${id}-list-item-4-button`}
                component={RouterLink}
                to="/config"
                dense
              >
                <ListItemIcon id={`${id}-list-item-4-icon`}>
                  <BorderOuter id={`${id}-list-item-4-border-outer-icon`} />
                </ListItemIcon>
                <ListItemText
                  id={`${id}-list-item-4-text`}
                  primary="Config"
                  primaryTypographyProps={{ variant: "subtitle1" }}
                />
              </ListItemButton>
            </ListItem> */}

            <ListItem
              id={`${id}-list-item-7`}
              dense
              disableGutters
            >
              <ListItemButton
                id={`${id}-list-item-7-button`}
                component={RouterLink}
                to="/bug-report"
                dense
              >
                <ListItemIcon id={`${id}-list-item-7-icon`}>
                  <ReportIcon id={`${id}-list-item-7-announcement-icon`} />
                </ListItemIcon>
                <ListItemText
                  id={`${id}-list-item-7-text`}
                  primary="Report Bug"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </ListItemButton>
            </ListItem>

            <Divider />

            <ListItem
              id={`${id}-list-item-5`}
              dense
              disableGutters
            >
              <ListItemButton
                id={`${id}-list-item-5-button`}
                onClick={() => { msalLogout() }}
                dense
              >
                <ListItemIcon id={`${id}-list-item-5-icon`}>
                  <LogoutIcon id={`${id}-list-item-5-logout-icon`} />
                </ListItemIcon>
                <ListItemText
                  id={`${id}-list-item-5-text`}
                  primary="Logout"
                  primaryTypographyProps={{ variant: "subtitle1" }}
                />
              </ListItemButton>
            </ListItem>



            <Divider />


            
            <ListItem
              id={`${id}-list-item-6`}
              dense
              disableGutters
              >
              { role && role === 'master' ? (
              <ListItemButton
                id={`${id}-list-item-6-button`}
                component={RouterLink}
                to="/version-history"
                dense
                text-center
              >
                <ListItemText
                  id={`${id}-list-item-6-text`}
                  primary={"Version "+(process?.env?.REACT_APP_CURRENT_APP_VERSION ?? null)}
                  style={{ textAlign: 'center' }}
                />
              </ListItemButton>
              ):(
                <ListItemText
                  id={`${id}-list-item-6-text`}
                  primary={"Version "+(process?.env?.REACT_APP_CURRENT_APP_VERSION ?? null)}
                  style={{ textAlign: 'center' }}
                />
              )}
            </ListItem>



            </List>
        </Box>
      </Drawer>
    </Box>
  )
};

export default Sidebar;

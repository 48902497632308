import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";
import { autoYMM } from './docUtils.js';

export const AZ5010GlobalAutofill = (documentValues, order, property=null, value=null) => {

  var newValues = documentValues;
  
  if(property === 'customer.name'){
    newValues.purchaser_name = value;
     if(order.customer.cosigners && order.customer.cosigners.length > 0){
      let cosigner = order.customer.cosigners[0];
      if(cosigner && cosigner.name){
          newValues.purchaser_name += " AND "+cosigner.name;
      }
    }
  }

  if(property === 'customer.cosigner[0].name'){
    newValues.purchaser_name = order.customer.name
    if(value!==''){
      newValues.purchaser_name+=" AND "+value;
      }
  }

  if(property === 'documentationDate'){
    newValues.delivery_date = moment.utc(value).format("YYYY-MM-DD");
  }

  if(property !== null && value !== null){
    return newValues;
  }

  ///////////////////////////

  if(order && order.customer && order.customer.name){
      newValues.purchaser_name = order.customer.name;
  }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
    let cosigner = order.customer.cosigners[0];
    if(cosigner && cosigner.name){
        newValues.purchaser_name += " AND "+cosigner.name;
    }
  }

  if(order && order.documentationDate && documentValues.delivery_date === undefined){
    newValues.delivery_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  }

  return newValues
}

export const AZ5010AutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
  var newValues = documentValues;
  if(property === 'year' && value !== null){
    newValues.vehicle_ymm = autoYMM((value).toString().trim().replace(/['"]+/g, ''),selectedProduct.make.trim(),selectedProduct.model.trim());
  }

  if(property === 'make' && value !== null){
      newValues.vehicle_ymm = autoYMM(selectedProduct.year.trim(), (value).toString().trim(),selectedProduct.model.trim());
  }

  if(property === 'model' && value !== null){
      newValues.vehicle_ymm = autoYMM(selectedProduct.year.trim(), selectedProduct.make.trim(), (value).toString().trim());
    }

  if(property !== null && value !== null){
    return newValues;
  }

///

  newValues.vin = (selectedProduct.vin).toString().toUpperCase();
  newValues.vehicle_ymm = (selectedProduct.year).trim()+" "+(selectedProduct.make).trim()+" "+(selectedProduct.model).toString().toUpperCase();

  return newValues
}

export const preProcessPayload = (payload,  checkboxes, moneyFields, phoneFields, upperCaseFields ) => {

  // Data point formatting
  if(payload.delivery_date){
    payload.delivery_date = moment.utc(payload.delivery_date).format("MM/DD/YY");
  }

  if(payload.safety_label_toggle){
    // Reset all
    payload.fcs_label_yes_cb = "no";
    payload.fcs_label_no_cb = "no";

    switch(payload.safety_label_toggle){
      case "yes":
        payload.fcs_label_yes_cb = "yes";
        break;
      case "no":
        payload.fcs_label_no_cb = "yes";
        break;
      default:
    }
  }

  if(payload.odometer_toggle){
    // Reset all
    payload.mileage_exceeded_cb = "no";
    payload.odo_reading_incorrect_cb = "no";
    payload.model_year_exemption_cb = "no";

    switch(payload.odometer_toggle){
      case "1":
        payload.mileage_exceeded_cb = "yes";
        break;
      case "2":
        payload.odo_reading_incorrect_cb = "yes";
        break;
      case "3":
        payload.model_year_exemption_cb = "yes";
        break;
      default:
    }
  }

  if(payload.living_quarters_toggle){
    // Reset all
    payload.trailer_living_quarters_yes_cb = "no";
    payload.trailer_living_quarters_no_cb = "no";

    switch(payload.living_quarters_toggle){
      case "yes":
        payload.trailer_living_quarters_yes_cb = "yes";
        break;
      case "no":
        payload.trailer_living_quarters_no_cb = "yes";
        break;
      default:
    }
  }

  if(payload.fuel_toggle){
    // Reset all
    payload.fuel_gas_cb = "no";
    payload.fuel_diesel_cb = "no";
    payload.fuel_propane_cb = "no";
    payload.fuel_electric_cb = "no";
    payload.fuel_other_cb = "no";

    switch(payload.fuel_toggle){
      case "gas":
        payload.fuel_gas_cb = "yes";
        break;
      case "diesel":
        payload.fuel_diesel_cb = "yes";
        break;
      case "propane":
        payload.fuel_propane_cb = "yes";
        break;
      case "electric":
        payload.fuel_electric_cb = "yes";
        break;
      case "other":
        payload.fuel_other_cb = "yes";
        break;
      default:
    }
  }
  


// X1.2- Field Type Specific Preview Formatting
Object.keys(payload).forEach(key => {
  if(moneyFields.indexOf(key) >= 0){
      payload[key] = formatMoneyValue(payload[key]);
  }else if(phoneFields.indexOf(key) >= 0){
      payload[key] = formatPhoneNumber(payload[key]);
  }else if(upperCaseFields.indexOf(key) >= 0){
      payload[key] = formatUpperCase(payload[key]);
  }
});
Object.keys(payload).forEach(key => {
  if(checkboxes.indexOf(key) >= 0){
    payload[key] = payload[key] ? "yes": "no";
  }
});

  return payload;
}
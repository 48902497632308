import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  useParams
} from "react-router-dom";
import { useFetch,recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {  Checkbox, FormGroup } from '@mui/material';
import { removeChars, formatter, formatPhoneNumber, formatUpperCase } from "../../../../../../../utlis/formatters";
import { useOrder } from "../../../../../../../redux/orders/order"
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { NETitleAppGlobalAutofill, NETitleAppAutofillProductDetails, preProcessPayload } from "../DocumentAutofill/NETitleApp"

const NETitleApp = ({document_id, getDocuments, passedDocumentSlug, locations, setDocumentFocus, location}) => {
  const documentSlug = "ne-title-app";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  // const dispatch = useDispatch();

  // const stateAbbrev = States.map(state => {
  //   return state.abbreviation;
  // });

  const order = useOrder();

  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");


  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);
  // const [ locations, setLocations ] = useState([]);//Redux
  // const [ nameOptions, setNameOptions ] = useState([]);//Redux

  // Data Structure & Init //
  
  var initialDocumentStructure = {
  }

  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);

  const moneyFields = useMemo(()=>{return[]},[]);
  const phoneFields = useMemo(()=>{return[]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "model"]},[]);
  const checkboxes = useMemo(()=>{return[
    "spouse_of_1_selected",
    "spouse_of_1_or_2_selected"
  ]},[]);
  // const radios = [
  //   "type_toggle",
  //   "group_1_toggle",
  //   "group_2_toggle",
  // ];

  // X1- Current General On Change
  const handleDocumentValuesChange = e =>{
    // e.preventDefault();
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatPhoneNumber(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }

    // if(name === 'odometer'){
    //   setDocumentValues({...documentValues,[name]:removeChars(value)});
    // }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('NETitleApp.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);
  

  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");


      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
      // setNameOptions(getAllNames(order, null));
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, order, documentLoaded, loadDocument ])

  const [assignedProduct, setAssignedProduct] = useState(undefined);
  // const [selectedAddress1, setSelectedAddress1] = useState(undefined);
  // const [selectedAddress2, setSelectedAddress2] = useState(undefined);
  // const [selectedAddress3, setSelectedAddress3] = useState(undefined);
  // const [selectedAddress4, setSelectedAddress4] = useState(undefined);
  // const [selectedAddress5, setSelectedAddress5] = useState(undefined);
  // const [selectedAddress6, setSelectedAddress6] = useState(undefined);
  // const [addressOptions, setAddressOptions] = useState(undefined);
  // const [inputAddressVal, setinputAddressVal] = useState(undefined);

 // Autofill //

 const GlobalAutofill = useCallback(async () => {
  let newValues = NETitleAppGlobalAutofill(documentValues, order);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  // var newValues = documentValues;
  
  // if(order && order.customer){

  //   if(order && order.customer && order.customer.name){



  //     // newValues.phone_number = order.customer.phone;

  //     if(order.customer.type === "entity"){
  //       newValues.owner_last_name_or_business_name = order.customer.name;
  //     }else{
  //       newValues.owner_last_name_or_business_name = getLastName(order.customer.name);
  //       newValues.owner_middle_name = getMiddleName(order.customer.name);
  //       newValues.owner_first_name = getFirstName(order.customer.name);
  //     }
  
  //     if(order.customer.cosigners && order.customer.cosigners.length > 0){
  //       let cosigner = order.customer.cosigners[0];
  //       if(cosigner){
  //         newValues.owner_last_name_or_business_name_2 = getLastName(order.customer.name);
  //         newValues.owner_middle_name_2 = getMiddleName(order.customer.name);
  //         newValues.owner_first_name_2 = getFirstName(order.customer.name);
  //       }
  //     }
  //   }
    
  //   if(order && order.customer && order.customer.deliveryAddress){
  //     newValues.owners_residential_address_city_state_zip = order.customer.deliveryAddress.addressLine1;
  //     if(order.customer.deliveryAddress.addressLine1 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
  //       newValues.owners_residential_address_city_state_zip = newValues.owners_residential_address_city_state_zip+" "+order.customer.deliveryAddress.addressLine2;
  //     }

  //     newValues.owners_residential_address_city_state_zip += " "+order.customer.deliveryAddress.city+" "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
  //   }

  //   if(order && order.customer && order.customer.mailingAddress){
  //     newValues.owners_residential_address_city_state_zip = order.customer.mailingAddress.addressLine1;
  //     if(order.customer.mailingAddress.addressLine1 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
  //       newValues.owners_residential_address_city_state_zip = newValues.owners_residential_address_city_state_zip+" "+order.customer.mailingAddress.addressLine2;
  //     }

  //     newValues.owners_residential_address_city_state_zip += " "+order.customer.mailingAddress.city+" "+order.customer.mailingAddress.state+" "+order.customer.mailingAddress.zip;
  //   }
  // }
  
  // if(order && order.documentationDate){
  //   newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  // }
  // setDocumentValues(newValues);
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ documentValues, order ])

// const preProcessPayload = useCallback((payload) => {

//   if(payload.title_type_toggle){
//     // Reset all
//     payload.salvage_selected = "no";
//     payload.prev_salvage_selected = "no";
//     payload.flood_selected = "no";
//     payload.non_transferable_selected = "no";
//     payload.manufacture_buyback = "no";

//     switch(payload.title_type_toggle){
//       case "salvage":
//         payload.salvage_selected = "yes";
//         break;
//       case "prev-salvage":
//         payload.prev_salvage_selected = "yes";
//         break;
//       case "flood":
//         payload.flood_selected = "yes";
//         break;
//       case "non-trans":
//         payload.non_transferable_selected = "yes";
//         break;
//       case "manu-buy-back":
//         payload.manufacture_buyback = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.taxi_toggle){
//     // Reset all
//     payload.taxi_use_yes_selected = "no";
//     payload.taxi_use_no_selected = "no";

//     switch(payload.taxi_toggle){
//       case "yes":
//         payload.taxi_use_yes_selected = "yes";
//         break;
//       case "no":
//         payload.taxi_use_no_selected = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.clear_rights_toggle){
//     // Reset all
//     payload.no_check_owner_applicant_information_selected = "no";
//     payload.yes_check_owner_applicant_information = "no";

//     switch(payload.clear_rights_toggle){
//       case "no":
//         payload.no_check_owner_applicant_information_selected = "yes";
//         break;
//       case "yes":
//         payload.yes_check_owner_applicant_information = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.lien_toggle){
//     // Reset all
//     payload.yes_lien_on_vehicle = "no";
//     payload.no_lien_on_vehicle = "no";

//     switch(payload.lien_toggle){
//       case "yes":
//         payload.yes_lien_on_vehicle = "yes";
//         break;
//       case "no":
//         payload.no_lien_on_vehicle = "yes";
//         break;
//       default:
//     }
//   }

  
//   if(payload.lien_non_res_toggle){
//     // Reset all
//     payload.req_print_yes_selected = "no";
//     payload.req_print_no_selected = "no";

//     switch(payload.lien_non_res_toggle){
//       case "yes":
//         payload.req_print_yes_selected = "yes";
//         break;
//       case "no":
//         payload.req_print_no_selected = "yes";
//         break;
//       default:
//     }
//   }

//   Object.keys(payload).forEach(key => {
//     if(moneyFields.indexOf(key) >= 0){
//         payload[key] = formatMoneyValue(payload[key]);
//     }else if(phoneFields.indexOf(key) >= 0){
//         payload[key] = formatPhoneNumber(payload[key]);
//     }else if(upperCaseFields.indexOf(key) >= 0){
//         payload[key] = formatUpperCase(payload[key]);
//     }
//   });
//   setDocumentValues(JSON.parse(JSON.stringify(payload)));
//   Object.keys(payload).forEach(key => {
//     if(checkboxes.indexOf(key) >= 0){
//       payload[key] = payload[key] ? "yes": "no";
//     }
//   });

//   if(payload.date){
//     payload.date = moment.utc(payload.date).format("MM/DD/YY");
//   }
  
//   if(payload.date_of_birth){
//     payload.date_of_birth = moment.utc(payload.date_of_birth).format("MM/DD/YY");
//   }

//   if(payload.date_of_birth_2){
//     payload.date_of_birth_2 = moment.utc(payload.date_of_birth_2).format("MM/DD/YY");
//   }

//   if(payload.date_of_birth_3){
//     payload.date_of_birth_3 = moment.utc(payload.date_of_birth_3).format("MM/DD/YY");
//   }

//   return payload;
// },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])


const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });
    
    if(!document){
      return null;
    }

      var docCopy = JSON.parse(JSON.stringify(document));
      docCopy.payload =  JSON.parse(JSON.stringify(payload));
      docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
      docCopy.payload =  JSON.stringify(docCopy.payload);
        delete docCopy.documentStatus;
      try{
        let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
        setdocumentUrl(fileURL)
        } catch (err) {
          recordFrontEndError('NETitleApp.jsx', 'Error previewing document payload: ' + err)
        };  
    
},[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


    //Autofill Product Details After Selection
    const AutofillProductDetails = useCallback(async (selectedProduct) => {
      let newValues = NETitleAppAutofillProductDetails(selectedProduct, documentValues);

      if(newValues){
        setDocumentValues(newValues);
        previewPayloadDocument(documentValues);
        setHasUnsavedChanges(true);
        if(document){
          previewPayloadDocument(documentValues);
        }
        setSaveSuccess(false);
      }
      // var newValues = documentValues;
      // newValues.year = (selectedProduct.year);
      // newValues.make = (selectedProduct.make).toString().toUpperCase();
      // newValues.model = (selectedProduct.model).toString().toUpperCase();
      // newValues.vin = (selectedProduct.vin).toString().toUpperCase();
      // newValues.body_style = (selectedProduct.vehicleType);
      // // newValues.selling_price = selectedProduct.price;

      // if(selectedProduct.fundingDetail && (selectedProduct.fundingDetail.fundingMethod === "tec-financing" || selectedProduct.fundingDetail.fundingMethod === "finance-only" || selectedProduct.fundingDetail.fundingMethod === "direct-deal")){
      //   newValues.lien_holder_name_1 = selectedProduct.fundingDetail.finalizedLendingOption.bankName;
      //   newValues.lien_holder_street_address = selectedProduct.fundingDetail.finalizedLendingOption.lienAddress;
      //   newValues.lien_holder_city = selectedProduct.fundingDetail.finalizedLendingOption.lienCity;
      //   newValues.lien_holder_state = selectedProduct.fundingDetail.finalizedLendingOption.lienState;
      //   newValues.lien_holder_zip = selectedProduct.fundingDetail.finalizedLendingOption.lienZip;   
      // }else if(selectedProduct.fundingDetail && selectedProduct.fundingDetail.fundingMethod === "cash-with-lien"){
      //   newValues.lien_holder_name_1 = selectedProduct.fundingDetail.cashLienHolder.bankName;
      //   newValues.lien_holder_street_address = selectedProduct.fundingDetail.cashLienHolder.addressLine1+" "+selectedProduct.fundingDetail.cashLienHolder.addressLine2;
      //   newValues.lien_holder_city = selectedProduct.fundingDetail.cashLienHolder.city;
      //   newValues.lien_holder_state = selectedProduct.fundingDetail.cashLienHolder.state;
      //   newValues.lien_holder_zip = selectedProduct.fundingDetail.cashLienHolder.zip;
      // }else if(selectedProduct.shipTo){
      //   newValues.lien_holder_name_1 = selectedProduct.shipTo.name;
      //   newValues.lien_holder_street_address = selectedProduct.shipTo.addressLine1+" "+selectedProduct.shipTo.addressLine2;
      //   newValues.lien_holder_city = selectedProduct.shipTo.city;
      //   newValues.lien_holder_state = selectedProduct.shipTo.state;
      //   newValues.lien_holder_zip = selectedProduct.shipTo.zip;
      // }

      // setDocumentValues(newValues);
      // // setProductToggleAutoFill(false);
      // if(document){
      //   previewPayloadDocument(documentValues);
      // }
      // setHasUnsavedChanges(true);
      // setSaveSuccess(false);
    },[ document, documentValues, previewPayloadDocument ]);

  // Document BREAD + Preview // TODO: Move to Redux

  useEffect(() => {
    if(document && document.payload && !dataLoaded){
      var existingPayload = false;
      var documentPayload = JSON.parse(document.payload);
      if(documentPayload && Object.keys(documentPayload).length > 0){
    
        existingPayload = true;
    
        // if(documentPayload.date_of_sale){
        //   documentPayload.date_of_sale = moment.utc(documentPayload.date_of_sale).format("YYYY-MM-DD");
        // }

        if(documentPayload.date){
          documentPayload.date = moment.utc(documentPayload.date).format("YYYY-MM-DD");
        }

        if(documentPayload.date_of_birth){
          documentPayload.date_of_birth = moment.utc(documentPayload.date_of_birth).format("YYYY-MM-DD");
        }
    
        if(documentPayload.date_of_birth_2){
          documentPayload.date_of_birth_2 = moment.utc(documentPayload.date_of_birth_2).format("YYYY-MM-DD");
        }
        
        if(documentPayload.date_of_birth_3){
          documentPayload.date_of_birth_3 = moment.utc(documentPayload.date_of_birth_3).format("YYYY-MM-DD");
        }
        
        setDocumentValues(documentPayload);

        // Loading Use Effect
        // if(document.additionalData){
        //   const methodMap = { 
        //     selectedAddress1: setSelectedAddress1, 
        //     selectedAddress2: setSelectedAddress2, 
        //     selectedAddress3: setSelectedAddress3, 
        //     selectedAddress4: setSelectedAddress4, 
        //     selectedAddress5: setSelectedAddress5, 
        //     selectedAddress6: setSelectedAddress6, 
        //   };

        //   let parsedAdditionalData = JSON.parse(document.additionalData);
        //   Object.keys(parsedAdditionalData).forEach(function(key) {
        //     if(methodMap[key]){
        //       methodMap[key](parsedAdditionalData[key]);
        //     }
        //   });
        // }

      }
      var foundAssignedProduct = null;
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
        foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
      }
      
      // setAddressOptions(getOrderLevelAddresses(order));
      if(foundAssignedProduct){
          setAssignedProduct(foundAssignedProduct);
          // setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
          // setNameOptions(getAllNames(order, foundAssignedProduct));
      }
      
        
        if(!existingPayload){
          GlobalAutofill();
          previewPayloadDocument(documentValues);
          if(foundAssignedProduct){
            AutofillProductDetails(foundAssignedProduct)
          }
        }else{
          // if(document && document.itemIds){
          //   var itemIds = document.itemIds.split(',');
          //     var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
          //     let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
          //     let obj = {id: foundProduct.id, name: str}
          //     setproductVal(obj);
          //     if(foundProduct){
          //       setAddressOptions(updateAddressOptions(foundProduct, order));
          //     }
          //     streamDocument();
          // }
    
        }
        
      setDataLoaded(true);
    }
  },[ documentValues, document, dataLoaded, order, AutofillProductDetails, GlobalAutofill, previewPayloadDocument ])


  function ReloadAutoFillValues(){  
    var foundAssignedProduct = null;
    if(document && document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }

    GlobalAutofill();
    if(foundAssignedProduct){
      AutofillProductDetails(foundAssignedProduct)
    }
  }

  
   
  
    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();

      
      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);

  
      // const payload = JSON.parse(JSON.stringify(documentValues));
      // var docCopy = document;
      // docCopy.payload = payload;
      // docCopy.payload = preProcessPayload(docCopy.payload);
      // docCopy.payload =  JSON.stringify(docCopy.payload);
      // delete docCopy.documentStatus;
      
      // if(selectedProduct && selectedProduct.id){
      //   docCopy.itemIds = `${selectedProduct.id}`;
      // }
  
      //Save Dropdown Selections
      // let dropdownData = {};
      // if(selectedAddress1){
      //   dropdownData.selectedAddress1 = {
      //     id: selectedAddress1.id,
      //     name: selectedAddress1.name
      //   };
      // }
      // if(selectedAddress2){
      //   dropdownData.selectedAddress2 = {
      //     id: selectedAddress2.id,
      //     name: selectedAddress2.name
      //   };
      // }
      // if(selectedAddress3){
      //   dropdownData.selectedAddress3 = {
      //     id: selectedAddress3.id,
      //     name: selectedAddress3.name
      //   };
      // }
      // if(selectedAddress4){
      //   dropdownData.selectedAddress4 = {
      //     id: selectedAddress4.id,
      //     name: selectedAddress4.name
      //   };
      // }
      // if(selectedAddress5){
      //   dropdownData.selectedAddress5 = {
      //     id: selectedAddress5.id,
      //     name: selectedAddress5.name
      //   };
      // }
      // if(selectedAddress6){
      //   dropdownData.selectedAddress6 = {
      //     id: selectedAddress6.id,
      //     name: selectedAddress6.name
      //   };
      // }

      // docCopy.additionalData = JSON.stringify(dropdownData);
      // let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, null, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });

      
    }

// Component Helpers //

  // useEffect(() => {

  // },[ selectedProduct, productToggleAutoFill ])

  // useEffect(() => {
  //   //Autofill Tradein Details After Selection
  //   if(selectedLocation !== undefined && locationToggleAutoFill){
  //     var newValues = documentValues;
  //     newValues.seller_address = selectedLocation.address;
  //     newValues.seller_city = selectedLocation.city;
  //     newValues.seller_state = selectedLocation.state;
  //     newValues.seller_zip = selectedLocation.zip;
  //     newValues.seller_phone = formatPhoneNumber(selectedLocation.phone);
  
  //     if(selectedLocation.state === "CA"){
  //       newValues.seller_name_signature = "TEC OF CALIFORNIA, INC";
  //       newValues.copy_seller_name_signature = "TEC OF CALIFORNIA, INC";
  //     }else{
  //       newValues.seller_name_signature = "TEC EQUIPMENT, INC.";
  //       newValues.copy_seller_name_signature = "TEC EQUIPMENT, INC.";
  //     }
  
  //     setDocumentValues(newValues);
  //     setLocationToggleAutoFill(false);
  //     if(document){
  //       previewPayloadDocument(documentValues);
  //     }
  //     setHasUnsavedChanges(true);
  //     setSaveSuccess(false);
  //   }
  // },[ selectedLocation, locationToggleAutoFill ])
  
  //Autofill Address Details After Selection
  // function AutoFillAddress(addressSelected, selectionInstance){
  //   var newValues = documentValues;
  //   if(selectionInstance){
  //     if(selectionInstance === "physical-address"){
  //       newValues.address_line_1 = addressSelected.address;
  //       // newValues.address_line_2 = addressSelected.addressLine2;
  //       newValues.city = addressSelected.city;
  //       newValues.state = addressSelected.state;
  //       newValues.zip = addressSelected.zip;
  //     }

  //     if(selectionInstance === "mailing-address"){
  //       newValues.mailing_address_line_1 = addressSelected.address;
  //       // newValues.mailing_address_line_2 = addressSelected.addressLine2;
  //       newValues.mailing_city = addressSelected.city;
  //       newValues.mailing_state = addressSelected.state;
  //       newValues.mailing_zip = addressSelected.zip;
  //     }

  //     if(selectionInstance === "lessee-address"){
  //       newValues.lessee_address_line_1 = addressSelected.address;
  //       // newValues.lessee_address_line_2 = addressSelected.addressLine2;
  //       newValues.lessee_city = addressSelected.city;
  //       newValues.lessee_state = addressSelected.state;
  //       newValues.lessee_zip = addressSelected.zip;
  //     }

  //     if(selectionInstance === "located-address"){
  //       newValues.trailer_coach_address = addressSelected.address;
  //       newValues.trailer_coach_city = addressSelected.city;
  //       newValues.trailer_coach_state = addressSelected.state;
  //       newValues.trailer_coach_zip = addressSelected.zip;
  //     }

  //     if(selectionInstance === "physical2-address"){
  //       newValues.lien_address_line_1 = addressSelected.address;
  //       // newValues.lien_address_line_2 = addressSelected.addressLine2;
  //       newValues.lien_city = addressSelected.city;
  //       newValues.lien_state = addressSelected.state;
  //       newValues.lien_zip = addressSelected.zip;
  //     }

  //     if(selectionInstance === "mailing2-address"){
  //       newValues.lien_mailing_address_line_1 = addressSelected.address;
  //       // newValues.lien_mailing__address_line_2 = addressSelected.addressLine2;
  //       newValues.lien_mailing_city = addressSelected.city;
  //       newValues.lien_mailing_state = addressSelected.state;
  //       newValues.lien_mailing_zip = addressSelected.zip;
  //     }
  //   }
  //   setDocumentValues(newValues);
  //   if(document){
  //     previewPayloadDocument(documentValues);
  //   }
  //   setHasUnsavedChanges(true);
  //   setSaveSuccess(false);
  // }

  const onCloseClick = e => {
    e.preventDefault()
    setHasUnsavedChanges(false);
    setSaveSuccess(false);
    setdocumentUrl("");
    setDocument(undefined);
    // setNameOptions([]);
    setPreviousDocumentId(0);
    setDocumentValues(initialDocumentStructure);
    setDocumentLoaded(false);
    setDataLoaded(false);
    setAssignedProduct(undefined);
    // setSelectedAddress1(undefined);
    // setSelectedAddress2(undefined);
    // setSelectedAddress3(undefined);
    // setSelectedAddress4(undefined);
    // setSelectedAddress5(undefined);
    // setSelectedAddress6(undefined);
    // setAddressOptions(undefined);
    // setinputAddressVal(undefined);
    setDocumentFocus(undefined);
  }
  
  const [hasClickedClose, setHasClickedClose] = useState(false);
  
  const closeClicked = e => {
    if( hasUnsavedChanges ){
        setHasClickedClose(true);
    } else {
        onCloseClick(e);
    }
  }
  
  const preCloseSave = e => {
    handleSubmit(e)
    setHasClickedClose(false);
  }
  
  const preCloseClose = e => {
    setHasClickedClose(false);
    onCloseClick(e)
  }

  return (
    <>
      <div
        className="modal fade"
        // id="ca-statement-of-facts"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                Nebraska Application for Certificate of Title
              </h5>
              <br />
              { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1 pt-1 text-white">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                  </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}


                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}


                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ fontSize: '12px', overflowY: 'scroll', height: 'inherit'}}>
               
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      ReloadAutoFillValues();
                      // GlobalAutofill();
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>
                  <br />
                  { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Product Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                        </div>
                    </div>
                  ):(
                    <div className="m-2 p-2 border">
                        <span>No Assigned Product</span>
                    </div>
                  )}

                  <div className="row">
                    <h5>Dealer's Report of Sale</h5>
                  </div>

                  {/* <Autocomplete
                      style={{ width: '100%'}}
                      value={ (documentValues.name) ?? null}
                      name="owner_name"
                      onBlur={(e) => updatePreview(e)}
                      onChange={(event, newValue) => {
                        let updatedObject = documentValues;
                        updatedObject.name = newValue.name;
                        setDocumentValues(updatedObject);
                        // setDocumentValues({...documentValues,["owner_name"]:newValue.name});
                      }}
                      // inputValue={inputAddressVal}
                      // onInputChange={(event, newInputValue) => {
                      //   setinputAddressVal(newInputValue);
                      // }}
                      options={nameOptions}
                      sx={{ width: 400 }}
                      renderInput={(params) => <TextField {...params} label="Purchaser Name" />}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                    /> */}


                  <div className="row">
                    <h5>1 - Vehicle or Motorboat Description</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Title Type</label>
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="title_type_toggle"
                            value={(documentValues.title_type_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="salvage" control={<Radio />} label="Salvage" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="prev-salvage" control={<Radio />} label="Previously Salvage" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="flood" control={<Radio />} label="Flood" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="non-trans" control={<Radio />} label="Non-Transferable" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="manu-buy-back" control={<Radio />} label="Manufacturer Buyback" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Model</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="model"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.model ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Body Style</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="body_style"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.body_style ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Color</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="color"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.color ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">GVWR</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="gvwr"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.gvwr ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Capacity/Propulsion</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="capacity_propulsion"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.capacity_propulsion ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Taxi Use</label>
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="taxi_toggle"
                            value={(documentValues.taxi_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="no" control={<Radio />} label="No" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <h5>2 - Owner/Applicant Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Multiple Owner / Clear Rights</label>
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="clear_rights_toggle"
                            value={(documentValues.clear_rights_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="no" control={<Radio />} label="No" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Owner name / Last or business name*</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_last_name_or_business_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_last_name_or_business_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">First Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_first_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_first_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Middle Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_middle_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_middle_name ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Nebraska Driver's License Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="nebraska_drivers_license_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.nebraska_drivers_license_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Soc. Sec. Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="soc_sec_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.soc_sec_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                      <label htmlFor="stock">Employer Identification Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="employer_identification_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.employer_identification_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Date of Birth</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date_of_birth"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date_of_birth ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">2nd Owner name / Last or business name*</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_last_name_or_business_name_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_last_name_or_business_name_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">First Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_first_name_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_first_name_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Middle Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_middle_name_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_middle_name_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Spouse is Owner 1</label>
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="spouse_of_1_selected"
                            checked={(documentValues && documentValues.spouse_of_1_selected ? documentValues.spouse_of_1_selected : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="Spouse is Owner 1" 
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Nebraska Driver's License Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="nebraska_drivers_license_ number_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.nebraska_drivers_license_number_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Soc. Sec. Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="soc_sec_number_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.soc_sec_number_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Employer Identification Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="employer_identification_number_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.employer_identification_number_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Date of Birth</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date_of_birth_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date_of_birth_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  
                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">3rd Owner name / Last or business name*</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_last_name_or_business_name_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_last_name_or_business_name_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">First Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_first_name_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_first_name_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Middle Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_middle_name_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_middle_name_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="spouse_of_1_or_2_selected"
                            checked={(documentValues && documentValues.spouse_of_1_or_2_selected ? documentValues.spouse_of_1_or_2_selected : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="Spouse is Owner 1 or 2" 
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Nebraska Driver's License Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="nebraska_drivers_license_ number_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.nebraska_drivers_license_number_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Soc. Sec. Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="soc_sec_number_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.soc_sec_number_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Employer Identification Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="employer_identification_number_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.employer_identification_number_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Date of Birth</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date_of_birth_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date_of_birth_3 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">1st TOD</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="tod_name_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.tod_name_1 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">2nd TOD</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="tod_name_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.tod_name_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Owner's Residential Address, City, State, Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owners_residential_address_city_state_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owners_residential_address_city_state_zip ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Owner's Mailing Address, City, State, Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owners_mailing_address_city_state_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owners_mailing_address_city_state_zip ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <h5>3 - Lien Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Lien on Vehicle?</label>
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="lien_toggle"
                            value={(documentValues.lien_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="no" control={<Radio />} label="No" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Lien Holder Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lien_holder_name_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lien_holder_name_1 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">PLID</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="plid"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.plid ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Street</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lien_holder_street_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lien_holder_street_address ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lien_holder_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lien_holder_city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lien_holder_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lien_holder_zip ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">If there is a lien, are you a non-resident requesting a printed title for issuance of a title in your state of residence?</label>
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="lien_non_res_toggle"
                            value={(documentValues.lien_non_res_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="no" control={<Radio />} label="No" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default NETitleApp

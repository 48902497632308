import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  useParams
} from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useOrder } from "../../../../../../../redux/orders/order"
import { removeChars, formatter, formatPhoneNumber, formatUpperCase, formatNameLastMiddleFirst } from "../../../../../../../utlis/formatters";
import { getOrderLevelAddresses, updateAddressOptions } from "../../../../../../../utlis/documentHelpers";
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { WAVehicleTitleApplicationAdditionalGlobalAutofill, WAVehicleTitleApplicationAdditionalAutofillProductDetails, preProcessPayload } from "../DocumentAutofill/WAVehicleTitleApplicationAdditional"

const WAVehicleTitleApplicationAdditional = ({document_id, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "wa-title-app-additional";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  // const dispatch = useDispatch();

  // const stateAbbrev = States.map(state => {
  //   return state.abbreviation;
  // });

  const order = useOrder();
  // const customer = useSelector((state) => state?.orderState.customer);
  // const products = useSelector((state) => state?.orderState.products);
  // const tradeins = useSelector((state) => state?.orderState.tradeins);
  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);

  // Data Structure & Init //
  
  var initialDocumentStructure = {
    vin: undefined, 
    plate_number: undefined, 
    permit_number: undefined, 
    transfer_plate_cb: undefined, 
    transfer_lease_cb: undefined, 
    condition_new_cb: undefined, 
    condition_used_cb: undefined, 
    vehicle_type: undefined, 
    use_type: undefined, 
    year_model: undefined, 
    make: undefined, 
    model: undefined, 
    trim: undefined, 
    body_style: undefined, 
    fuel_type: undefined, 
    moto_style: undefined, 
    gv_weight_rating: undefined, 
    scale_wt: undefined, 
    gross_weight: undefined, 
    moto_gwt: undefined, 
    seats: undefined, 
    trl_axels: undefined, 
    color_1: undefined, 
    color_2: undefined, 
    equipment_number: undefined, 
    purchase_price: undefined, 
    wheels: undefined, 
    rental_number: undefined, 
    fleet_number: undefined, 
    engine_mc: undefined, 
    moto_etc_serial_number: undefined, 
    length: undefined, 
    width: undefined, 
    quick_title_yes_cb: undefined, 
    quick_title_no_cb: undefined, 
    park_donation_yes_cb: undefined, 
    park_donation_no_cb: undefined, 
    drivers_license_etc_no: undefined, 
    exp_date: undefined, 
    phone_type: undefined, 
    phone_num_s1: undefined, 
    owner_name_biz_address: undefined, 
    wa_primary_res_address: undefined, 
    mailing_address_s1: undefined, 
    one_time_mailing_s1: undefined, 
    email_address: undefined, 
    notify_by_email_cb: undefined, 
    drivers_license_etc_no_s2: undefined, 
    exp_date_s2: undefined, 
    phone_num_s2: undefined, 
    id_type_s2: undefined, 
    joint_tenants_yes_cb: undefined, 
    joint_tenants_no_cb: undefined, 
    owner_name_biz_name_s2: undefined, 
    legal_owner_name: undefined, 
    legal_exp_date: undefined, 
    legal_owner_type: undefined, 
    legal_id_type: undefined, 
    legal_drivers_etc_number: undefined, 
    elt_yes_cb: undefined, 
    elt_no_cb: undefined, 
    legal_mailing_address: undefined, 
    dealer_name: undefined, 
    sale_date: undefined, 
    delivery_date: undefined, 
    vehicle_status_new_cb: undefined, 
    dealer_number: undefined, 
    dealer_type: undefined, 
    id_type: undefined, 
    owner_type: undefined, 
    owner_type_s2: undefined, 
    transfer_title_cb: undefined, 
    discover_pass_yes_cb: undefined, 
    discover_pass_no_cb: undefined, 
    vehicle_status_prev_title_cb: undefined, 
    vehicle_status_used_cb: undefined, 

    elt_part_leg2_toggle: undefined,
    elt_part_leg3_toggle: undefined
  }

  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  // X1
  // const removeFormatting = e =>{
  //   const {name,value}=e.target;
  //   setDocumentValues({...documentValues,[name]:removeChars(value)});
  // }

  // X1 Reference Arrays - TODO: Possibly move these to back end as part of the template stucture
  const moneyFields = useMemo(()=>{return[""]},[]);
  const phoneFields = useMemo(()=>{return["phone3", "phone4", "phone5", "phone6","phone7"]},[]);
  const upperCaseFields = useMemo(()=>{return["vin",]},[]);
  const checkboxes = useMemo(()=>{return[
    ""
  ]},[]);
  // const radios = [
  //   "elt_part_leg2_toggle", 
  //   "elt_part_leg3_toggle", 
  // ];

  // X1- Current General On Change
  const handleDocumentValuesChange = e =>{
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  
  const [documentLoaded, setDocumentLoaded] = useState(false);  

  const loadDocument=useCallback( async (documentId)=>{
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('WAVehicleTitleApplicationAdditional.jsx', 'Error loading document: ' + err)
      }
    }
  },[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug,documentLoaded, loadDocument ])

  const [assignedProduct, setAssignedProduct] = useState(undefined);
  // const [productVal, setproductVal] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  // const [tradeinVal, setTradeinVal] = useState(undefined);
  // const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  // const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);

  const [selectedAddress1, setSelectedAddress1] = useState(undefined);
  const [selectedAddress2, setSelectedAddress2] = useState(undefined);
  const [selectedAddress3, setSelectedAddress3] = useState(undefined);
  const [selectedAddress4, setSelectedAddress4] = useState(undefined);
  const [selectedAddress5, setSelectedAddress5] = useState(undefined);
  const [selectedAddress6, setSelectedAddress6] = useState(undefined);
  const [selectedAddress7, setSelectedAddress7] = useState(undefined);
  const [addressOptions, setAddressOptions] = useState(undefined);
  const [inputAddressVal, setinputAddressVal] = useState(undefined);

  const [ownerTypeOptions, setOwnerTypeOptions] = useState(undefined);
  const [idTypeOptions, setIdTypeOptions] = useState(undefined);

// Dropdowns //colorOptions

// if(order && order.products && order.products.length > 0 && productOptions === undefined){
//   let getProductOptions = [];
//   order.products.map(product => {
//     let str = `${product.stockNumber.toString().toUpperCase()}`;
//     let obj = {id: product.id, name: str}
//     getProductOptions.push(obj);
//   })
//   setproductOptions(getProductOptions);
// }

// if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
//   let getTradeinOptions = [];
//   order.tradeins.map(tradein => {
//     let str = `${tradein.year} ${tradein.make} ${tradein.model.toString().toUpperCase()}`;
//     let obj = {id: tradein.id, name: str}
//     getTradeinOptions.push(obj);
//   })
//   setTradeinOptions(getTradeinOptions);
// }

const getOwnerTypes=useCallback(()=>{
  let ownerTypes = [];
  ownerTypes.push({name: "1 - Individual"});
  ownerTypes.push({name: "2 - Business"});

  return ownerTypes;
},[])

const getIdTypes=useCallback(()=>{
  let idTypes = [];
  idTypes.push({name: "1 - Driver licence/ID"});
  idTypes.push({name: "2 - Tax ID no"});
  idTypes.push({name: "3 - Employer ID no"});
  idTypes.push({name: "4 - Unified business no"});

  return idTypes;
},[])



 // Autofill //

const LoadDropdowns=useCallback(()=>{
  setOwnerTypeOptions(getOwnerTypes());
  setIdTypeOptions(getIdTypes());
},[getOwnerTypes,getIdTypes])

const GlobalAutofill=useCallback(()=>{
  let newValues = WAVehicleTitleApplicationAdditionalGlobalAutofill(documentValues, order);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }


  // var newValues = documentValues;
  
  // if(order && order.customer && order.customer.email){
  //     newValues.email_address = order.customer.email;
  // }

  // if(order && order.customer){
  //   if(order.customer.type === "entity"){
  //     newValues.owner_type = "Business";
  //   }else{
  //     newValues.owner_type = "Individual";
  //   }
  //   newValues.owner_name_biz_address = order.customer.name;
  //   newValues.phone_num_s1 = formatPhoneNumber(order.customer.phone);

  //   if(order.customer.dba){
  //     newValues.owner_name_biz_address = order.customer.name+" dba "+order.customer.dba;
  //   }
  //   if(order.customer.cosigners && order.customer.cosigners.length > 0){
  //     let cosigner = order.customer.cosigners[0];
  //     if(cosigner){
  //       newValues.owner_type_s2 = "Individual"
  //       newValues.phone_num_s2 = formatPhoneNumber(cosigner.phone);
  //       newValues.owner_name_biz_name_s2 = cosigner.name;
  //     }
  //   }
  // }
  
  // if(order && order.customer && order.customer.deliveryAddress){
  //   var addressLine = '';
  //   if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
  //     addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
  //   }else{
  //     addressLine = order.customer.deliveryAddress.addressLine1;
  //   }
  //   newValues.wa_primary_res_address = addressLine+" "+(order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
  // }

  // // if(order && order.customer && order.customer.mailingAddress){
  // //   var addressLine = '';
  // //   if(order.customer.mailingAddress.addressLine2 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
  // //     addressLine = (order.customer.mailingAddress.addressLine1+" "+order.customer.mailingAddress.addressLine2);
  // //   }else{
  // //     addressLine = order.customer.mailingAddress.addressLine1;
  // //   }
  // //   newValues.mailing_address = addressLine;
  // //   newValues.mailing_city_state_zip = (order.customer.mailingAddress.city+", "+order.customer.mailingAddress.state+" "+order.customer.mailingAddress.zip);
  // // }

  // if(order && order.documentationDate && documentValues.delivery_date === undefined){
  //   newValues.delivery_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  // }


  // setDocumentValues(newValues);
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[documentValues, order])

  // Document BREAD + Preview // TODO: Move to Redux

  // const preProcessPayload=useCallback((payload)=>{

  //   if(payload.elt_part_leg2_toggle){
  //     // Reset all
  //     payload.elt2_yes_cb = "no";
  //     payload.elt2_no_cb = "no";

  //     switch(payload.elt_part_leg2_toggle){
  //       case "yes":
  //         payload.elt2_yes_cb = "yes";
  //         break;
  //       case "no":
  //         payload.elt2_no_cb = "yes";
  //         break;
  //       default:
  //     }
  //   }

  //   if(payload.elt_part_leg3_toggle){
  //     // Reset all
  //     payload.elt3_yes_cb = "no";
  //     payload.elt3_no_cb = "no";

  //     switch(payload.elt_part_leg3_toggle){
  //       case "yes":
  //         payload.elt3_yes_cb = "yes";
  //         break;
  //       case "no":
  //         payload.elt3_no_cb = "yes";
  //         break;
  //       default:
  //     }
  //   }


  //   // X1.2- Field Type Specific Preview Formatting
  //   Object.keys(payload).forEach(key => {
  //     if(moneyFields.indexOf(key) >= 0){
  //         payload[key] = formatMoneyValue(payload[key]);
  //     }else if(phoneFields.indexOf(key) >= 0){
  //         payload[key] = formatPhoneNumber(payload[key]);
  //     }else if(upperCaseFields.indexOf(key) >= 0){
  //         payload[key] = formatUpperCase(payload[key]);
  //     }
  //   });
  //   setDocumentValues(JSON.parse(JSON.stringify(payload)));
  //   Object.keys(payload).forEach(key => {
  //     if(checkboxes.indexOf(key) >= 0){
  //       payload[key] = payload[key] ? "yes": "no";
  //     }
  //   });

  //   // Data point formatting

  //   if(payload.exp3){
  //     payload.exp3 = moment.utc(payload.exp3).format("MM/DD/YY");
  //   }

  //   if(payload.exp4){
  //     payload.exp4 = moment.utc(payload.exp4).format("MM/DD/YY");
  //   }

  //   if(payload.exp5){
  //     payload.exp5 = moment.utc(payload.exp5).format("MM/DD/YY");
  //   }

  //   if(payload.exp6){
  //     payload.exp6 = moment.utc(payload.exp6).format("MM/DD/YY");
  //   }

  //   if(payload.exp7){
  //     payload.exp7 = moment.utc(payload.exp7).format("MM/DD/YY");
  //   }

  //   if(payload.legExp2){
  //     payload.legExp2 = moment.utc(payload.legExp2).format("MM/DD/YY");
  //   }

  //   if(payload.legExp3){
  //     payload.legExp3 = moment.utc(payload.legExp3).format("MM/DD/YY");
  //   }

  //   if(payload.delivery_date){
  //     payload.delivery_date = moment.utc(payload.delivery_date).format("MM/DD/YY");
  //   }

  //   return payload;
  // },[checkboxes,moneyFields,phoneFields,upperCaseFields])

const previewPayloadDocument=useCallback(async ()=>{
      var payload = documentValues;
      Object.keys(payload).forEach(key => {
        if (payload[key] === null || payload[key] === undefined) {
          delete payload[key];
        }
      });
      
      var docCopy = JSON.parse(JSON.stringify(document));
      docCopy.payload =  JSON.parse(JSON.stringify(payload));
      docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
      docCopy.payload =  JSON.stringify(docCopy.payload);
      delete docCopy.documentStatus;
  
      try {
        let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
        setdocumentUrl(fileURL)
      } catch (err) {
        recordFrontEndError('WAVehicleTitleApplicationAdditional.jsx', 'Error previewing document payload: ' + err)
      };  
    },[axios, checkboxes, document, documentValues, moneyFields, phoneFields, upperCaseFields])

const AutofillProductDetails=useCallback((selectedProduct)=>{
  let newValues = WAVehicleTitleApplicationAdditionalAutofillProductDetails(selectedProduct, documentValues);

  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setSaveSuccess(false);
  }

  // var newValues = documentValues;
  // newValues.vin = (selectedProduct.vin).toString().toUpperCase();
  // newValues.year_model = (selectedProduct.year).trim();
  // newValues.make = (selectedProduct.make).trim();
  // newValues.model = (selectedProduct.model).trim().toString().toUpperCase();

  // if(selectedProduct.type.toUpperCase() === "NEW"){
  //   newValues.new_used_toggle = "new";
  // }else{
  //   newValues.new_used_toggle = "used";
  // }

  // newValues.vehicle_type = selectedProduct.vehicle_type;

  // setDocumentValues(newValues);
  // // setProductToggleAutoFill(false);
  // if(document){
  //   previewPayloadDocument(documentValues);
  // }
  // // setAddressOptions(updateAddressOptions(selectedProduct, order));
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[document,documentValues,previewPayloadDocument])

  useEffect(() => {
    if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){
  
      existingPayload = true;

      if(documentPayload.exp3){
        documentPayload.exp3 = moment.utc(documentPayload.exp3).format("YYYY-MM-DD");
      }
  
      if(documentPayload.exp4){
        documentPayload.exp4 = moment.utc(documentPayload.exp4).format("YYYY-MM-DD");
      }
  
      if(documentPayload.exp5){
        documentPayload.exp5 = moment.utc(documentPayload.exp5).format("YYYY-MM-DD");
      }
  
      if(documentPayload.exp6){
        documentPayload.exp6 = moment.utc(documentPayload.exp6).format("YYYY-MM-DD");
      }
  
      if(documentPayload.exp7){
        documentPayload.exp7 = moment.utc(documentPayload.exp7).format("YYYY-MM-DD");
      }
  
      if(documentPayload.legExp2){
        documentPayload.legExp2 = moment.utc(documentPayload.legExp2).format("YYYY-MM-DD");
      }
  
      if(documentPayload.legExp3){
        documentPayload.legExp3 = moment.utc(documentPayload.legExp3).format("YYYY-MM-DD");
      }
      
      if(documentPayload.delivery_date){
        documentPayload.delivery_date = moment.utc(documentPayload.delivery_date).format("YYYY-MM-DD");
      }
      
      setDocumentValues(documentPayload);

      // Loading Use Effect
      if(document.additionalData){
        const methodMap = { 
          selectedAddress1: setSelectedAddress1, 
          selectedAddress2: setSelectedAddress2, 
          selectedAddress3: setSelectedAddress3, 
          selectedAddress4: setSelectedAddress4, 
          selectedAddress5: setSelectedAddress5, 
          selectedAddress6: setSelectedAddress6, 
          selectedAddress7: setSelectedAddress7, 
        };

        let parsedAdditionalData = JSON.parse(document.additionalData);
        Object.keys(parsedAdditionalData).forEach(function(key) {
          if(methodMap[key]){
            methodMap[key](parsedAdditionalData[key]);
          }
        });
      }
    }

      var foundAssignedProduct = null;
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
        foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
      }

      setAddressOptions(getOrderLevelAddresses(order));
      if(foundAssignedProduct){
          setAssignedProduct(foundAssignedProduct);
          setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
      }

  
      LoadDropdowns();
      if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
      }else{
        // if(document && document.itemIds){
        //   var itemIds = document.itemIds.split(',');
        //     var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
        //     let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
        //     let obj = {id: foundProduct.id, name: str}
        //     setproductVal(obj);
        //     if(foundProduct){
        //       setAddressOptions(updateAddressOptions(foundProduct, order));
        //     }
        //     // streamDocument();
        // }
  
      }
      
      setDataLoaded(true);
    }
  },[ document, dataLoaded, AutofillProductDetails, GlobalAutofill, LoadDropdowns, order, previewPayloadDocument, documentValues ])



  


    
  
    // function streamDocument(){
    //   axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
    //     .then(response => {
    //       const file = new Blob(
    //         [response.data], 
    //         {type: 'application/pdf'});
    //         const fileURL = URL.createObjectURL(file);
    //         // window.open(fileURL);
    //       setdocumentUrl(fileURL)
    //     }).catch(err => {
    //     })
    // }
  
    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();

      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
    
    
      // const payload = JSON.parse(JSON.stringify(documentValues));
      // var docCopy = document;
      // docCopy.payload = payload;
      // docCopy.payload = preProcessPayload(docCopy.payload);
      // docCopy.payload =  JSON.stringify(docCopy.payload);
      // delete docCopy.documentStatus;
      
      // if(selectedProduct && selectedProduct.id){
      //   docCopy.itemIds = `${selectedProduct.id}`;
      // }
  
      
      //Save Dropdown Selections
      let dropdownData = {};
      if(selectedAddress1){
        dropdownData.selectedAddress1 = {
          id: selectedAddress1.id,
          name: selectedAddress1.name
        };
      }
      if(selectedAddress2){
        dropdownData.selectedAddress2 = {
          id: selectedAddress2.id,
          name: selectedAddress2.name
        };
      }
      if(selectedAddress3){
        dropdownData.selectedAddress3 = {
          id: selectedAddress3.id,
          name: selectedAddress3.name
        };
      }
      if(selectedAddress4){
        dropdownData.selectedAddress4 = {
          id: selectedAddress4.id,
          name: selectedAddress4.name
        };
      }
      if(selectedAddress5){
        dropdownData.selectedAddress5 = {
          id: selectedAddress5.id,
          name: selectedAddress5.name
        };
      }
      if(selectedAddress6){
        dropdownData.selectedAddress6 = {
          id: selectedAddress6.id,
          name: selectedAddress6.name
        };
      }
      if(selectedAddress7){
        dropdownData.selectedAddress7 = {
          id: selectedAddress7.id,
          name: selectedAddress7.name
        };
      }

      let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, additionalData, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });

      // docCopy.additionalData = JSON.stringify(dropdownData);

      // try {
      //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
      //   if (res) {
      //     await axios.post(`/document/${document.id}/generate`) 
      //     getDocuments();
      //     setSaveSuccess(true);
      //     setHasUnsavedChanges(false);
      //   };
      // } catch (err) {
      //   recordFrontEndError('WAVehicleTitleApplicationAdditional.jsx', 'Error saving document: ' + err)
      // };
      
    }

// Component Helpers //
// useEffect(() => {
  //Autofill Product Details After Selection

// ,[ selectedProduct, productToggleAutoFill ])

//Autofill Address Details After Selection
function AutoFillAddress(addressSelected, selectionInstance){
  var newValues = documentValues;
    if(selectionInstance){
      if(selectionInstance === "owner-3"){
        if(addressSelected.type === "entity"){
          newValues.ownerType3 = "2 - Business";
          if(addressSelected.label){
            newValues.name3 = addressSelected.label;
          }
        }else{
          newValues.ownerType3 = "1 - Individual";
          if(addressSelected.label){
            newValues.name3 = formatNameLastMiddleFirst(addressSelected.label);
          }
        }
        if(addressSelected.phone){
          newValues.phone3 = formatPhoneNumber(addressSelected.phone);
        }
      }

      if(selectionInstance === "owner-4"){
        if(addressSelected.type === "entity"){
          newValues.ownerType4 = "2 - Business";
          if(addressSelected.label){
            newValues.name4 = addressSelected.label;
          }
        }else{
          newValues.ownerType4 = "1 - Individual";
          if(addressSelected.label){
            newValues.name4 = formatNameLastMiddleFirst(addressSelected.label);
          }
        }
        if(addressSelected.phone){
          newValues.phone4 = formatPhoneNumber(addressSelected.phone);
        }
      }

      if(selectionInstance === "owner-5"){
        if(addressSelected.type === "entity"){
          newValues.ownerType5 = "2 - Business";
          if(addressSelected.label){
            newValues.name5 = addressSelected.label;
          }
        }else{
          newValues.ownerType5 = "1 - Individual";
          if(addressSelected.label){
            newValues.name5 = formatNameLastMiddleFirst(addressSelected.label);
          }
        }
        if(addressSelected.phone){
          newValues.phone5 = formatPhoneNumber(addressSelected.phone);
        }
      }

      if(selectionInstance === "owner-6"){
        if(addressSelected.type === "entity"){
          newValues.ownerType6 = "2 - Business";
          if(addressSelected.label){
            newValues.name6 = addressSelected.label;
          }
        }else{
          newValues.ownerType6 = "1 - Individual";
          if(addressSelected.label){
            newValues.name6 = formatNameLastMiddleFirst(addressSelected.label);
          }
        }
        if(addressSelected.phone){
          newValues.phone6 = formatPhoneNumber(addressSelected.phone);
        }
      }

      if(selectionInstance === "owner-7"){
        if(addressSelected.type === "entity"){
          newValues.ownerType7 = "2 - Business";
          if(addressSelected.label){
            newValues.name7 = addressSelected.label;
          }
        }else{
          newValues.ownerType7 = "1 - Individual";
          if(addressSelected.label){
            newValues.name7 = formatNameLastMiddleFirst(addressSelected.label);
          }
        }
        if(addressSelected.phone){
          newValues.phone7 = formatPhoneNumber(addressSelected.phone);
        }
      }

      if(selectionInstance === "legal-2"){
        if(addressSelected.type === "entity"){
          newValues.ownerLienholderType2 = "2 - Business";
          if(addressSelected.label){
            newValues.legName2 = addressSelected.label;
          }
        }else{
          newValues.ownerLienholderType2 = "1 - Individual";
          if(addressSelected.label){
            newValues.legName2 = formatNameLastMiddleFirst(addressSelected.label);
          }
        }

        if(addressSelected.address){
          newValues.legAdd2 = addressSelected.address+" "+addressSelected.city+" "+addressSelected.state+", "+addressSelected.zip;
        }
      }

      if(selectionInstance === "legal-3"){
        if(addressSelected.type === "entity"){
          newValues.ownerLienholderType3 = "2 - Business";
          if(addressSelected.label){
            newValues.legName3 = addressSelected.label;
          }
        }else{
          newValues.ownerLienholderType3 = "1 - Individual";
          if(addressSelected.label){
            newValues.legName3 = formatNameLastMiddleFirst(addressSelected.label);
          }
        }
        if(addressSelected.address){
          newValues.legAdd3 = addressSelected.address+" "+addressSelected.city+" "+addressSelected.state+", "+addressSelected.zip;
        }
      }

      
    }
  setDocumentValues(newValues);
  if(document){
    previewPayloadDocument(documentValues);
  }
}

const onCloseClick = e => {
  e.preventDefault()
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setdocumentUrl("");
  setDocument(undefined);
  setPreviousDocumentId(0);
  setAssignedProduct(undefined);
  setDocumentValues(initialDocumentStructure);
  setDocumentLoaded(false);
  setDataLoaded(false);
  setSelectedAddress1(undefined);
  setSelectedAddress2(undefined);
  setSelectedAddress3(undefined);
  setSelectedAddress4(undefined);
  setSelectedAddress5(undefined);
  setSelectedAddress6(undefined);
  setSelectedAddress7(undefined);
  setAddressOptions(undefined);
  setinputAddressVal(undefined);
  setAssignedProduct(undefined);
  setHasClickedClose(false);
  setDocumentFocus(undefined);
}


const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

  return (
    <>
      <div
        className="modal fade"
        // id="wa-title-app"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                WA - Vehicle Title Application Additional
              </h5>
              <br />
              { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1 pt-1 text-white">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                  </div>
              ): hasUnsavedChanges ? (
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}
                  onClick={closeClicked}
                ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ fontSize: '12px', overflowY: 'scroll', height: 'inherit'}}>
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill();
                      AutofillProductDetails(assignedProduct);
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>

                  { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                      Product Snapshot
                      <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                      </div>
                    </div>
                  ):(
                    <div className="m-2 p-2 border">
                      <span>No Assigned Product</span>
                    </div>
                  )}

                  <div className="row">
                    <h5>Vehicle Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>
                    </div>

                    <h5>Owner 3</h5>

                    <div className="row">
                      {addressOptions && addressOptions.length > 0 ? (
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress1 && selectedAddress1.name) ?? null}
                            name="owner-3"
                            onChange={(event, newValue) => {
                              setSelectedAddress1(newValue);
                              AutoFillAddress(newValue, "owner-3");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Owner 3" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>):(
                        <div  className="text-center mx-auto">
                          <h5 style={{ color: "red" }}> No Addresses Found</h5>
                        </div>
                      )}
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                      { ownerTypeOptions && ownerTypeOptions.length > 0 ? (
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.ownerType3) ?? null}
                          name="ownerType3"
                          onChange={(event, newValue) => {
                            let updatedObject = documentValues;
                            updatedObject.ownerType3 = newValue.name
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,["ownerType3"]:newValue.name});
                          }}
                          // inputValue={inputVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputVal(newInputValue);
                          // }}
                          onBlur={(e) => updatePreview(e)}
                          options={ownerTypeOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Owner Type" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}> No Owner Types Found</h5>
                          </div>
                        )}
                      </div>
                      <div className="p-2 col-md-6">
                      { idTypeOptions && idTypeOptions.length > 0 ? (
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.idType3) ?? null}
                          name="idType3"
                          onChange={(event, newValue) => {
                            let updatedObject = documentValues;
                            updatedObject.idType3 = newValue.name
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,["idType3"]:newValue.name});
                          }}
                          // inputValue={inputVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputVal(newInputValue);
                          // }}
                          onBlur={(e) => updatePreview(e)}
                          options={idTypeOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="ID Type" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}> No ID Types Found</h5>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Driver License/ID/TIN/EIN/UBI Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="dl3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dl3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Expiration date </label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="exp3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.exp3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Phone Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="phone3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.phone3 ?? '' }
                          />
                      </div>
                    </div>


                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Registered owner full name (Last, First, Middle, Suffix) or Business name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="name3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.name3 ?? '' }
                          />
                      </div>
                    </div>

                    {/*  */}

                    <h5>Owner 4</h5>

                    <div className="row">
                      {addressOptions && addressOptions.length > 0 ? (
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress2 && selectedAddress2.name) ?? null}
                            name="owner-4"
                            onChange={(event, newValue) => {
                              setSelectedAddress2(newValue);
                              AutoFillAddress(newValue, "owner-4");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Owner 4" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>):(
                        <div  className="text-center mx-auto">
                          <h5 style={{ color: "red" }}> No Addresses Found</h5>
                        </div>
                      )}
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                      { ownerTypeOptions && ownerTypeOptions.length > 0 ? (
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.ownerType4) ?? null}
                          name="ownerType4"
                          onChange={(event, newValue) => {
                            let updatedObject = documentValues;
                            updatedObject.ownerType4 = newValue.name
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,["ownerType4"]:newValue.name});
                          }}
                          // inputValue={inputVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputVal(newInputValue);
                          // }}
                          onBlur={(e) => updatePreview(e)}
                          options={ownerTypeOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Owner Type" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}> No Owner Types Found</h5>
                          </div>
                        )}
                      </div>
                      <div className="p-2 col-md-6">
                      { idTypeOptions && idTypeOptions.length > 0 ? (
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.idType4) ?? null}
                          name="idType4"
                          onChange={(event, newValue) => {
                            let updatedObject = documentValues;
                            updatedObject.idType4 = newValue.name
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,["idType4"]:newValue.name});
                          }}
                          // inputValue={inputVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputVal(newInputValue);
                          // }}
                          onBlur={(e) => updatePreview(e)}
                          options={idTypeOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="ID Type" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}> No ID Types Found</h5>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Driver License/ID/TIN/EIN/UBI Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="dl4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dl4 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Expiration date </label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="exp4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.exp4 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Phone Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="phone4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.phone4 ?? '' }
                          />
                      </div>
                    </div>


                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Registered owner full name (Last, First, Middle, Suffix) or Business name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="name4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.name4 ?? '' }
                          />
                      </div>
                    </div>

                    {/*  */}

                    <h5>Owner 5</h5>

                    <div className="row">
                      {addressOptions && addressOptions.length > 0 ? (
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress3 && selectedAddress3.name) ?? null}
                            name="owner-5"
                            onChange={(event, newValue) => {
                              setSelectedAddress3(newValue);
                              AutoFillAddress(newValue, "owner-5");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Owner 5" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>):(
                        <div  className="text-center mx-auto">
                          <h5 style={{ color: "red" }}> No Addresses Found</h5>
                        </div>
                      )}
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                       { ownerTypeOptions && ownerTypeOptions.length > 0 ? (
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.ownerType5) ?? null}
                          name="ownerType5"
                          onChange={(event, newValue) => {
                            let updatedObject = documentValues;
                            updatedObject.ownerType5 = newValue.name
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,["ownerType5"]:newValue.name});
                          }}
                          // inputValue={inputVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputVal(newInputValue);
                          // }}
                          onBlur={(e) => updatePreview(e)}
                          options={ownerTypeOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Owner Type" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}> No Owner Types Found</h5>
                          </div>
                        )}
                      </div>
                      <div className="p-2 col-md-6">
                      { idTypeOptions && idTypeOptions.length > 0 ? (
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.idType5) ?? null}
                          name="idType5"
                          onChange={(event, newValue) => {
                            let updatedObject = documentValues;
                            updatedObject.idType5 = newValue.name
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,["idType5"]:newValue.name});
                          }}
                          // inputValue={inputVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputVal(newInputValue);
                          // }}
                          onBlur={(e) => updatePreview(e)}
                          options={idTypeOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="ID Type" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}> No ID Types Found</h5>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Driver License/ID/TIN/EIN/UBI Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="dl5"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dl5 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Expiration date </label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="exp5"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.exp5 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Phone Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="phone5"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.phone5 ?? '' }
                          />
                      </div>
                    </div>


                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Registered owner full name (Last, First, Middle, Suffix) or Business name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="name5"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.name5 ?? '' }
                          />
                      </div>
                    </div>

                    {/*  */}

                    <h5>Owner 6</h5>

                    <div className="row">
                      {addressOptions && addressOptions.length > 0 ? (
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress4 && selectedAddress4.name) ?? null}
                            name="owner-6"
                            onChange={(event, newValue) => {
                              setSelectedAddress4(newValue);
                              AutoFillAddress(newValue, "owner-6");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Owner 6" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>):(
                        <div  className="text-center mx-auto">
                          <h5 style={{ color: "red" }}> No Addresses Found</h5>
                        </div>
                      )}
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        { ownerTypeOptions && ownerTypeOptions.length > 0 ? (
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.ownerType6) ?? null}
                          name="ownerType6"
                          onChange={(event, newValue) => {
                            let updatedObject = documentValues;
                            updatedObject.ownerType6 = newValue.name
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,["ownerType6"]:newValue.name});
                          }}
                          // inputValue={inputVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputVal(newInputValue);
                          // }}
                          onBlur={(e) => updatePreview(e)}
                          options={ownerTypeOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Owner Type" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}> No Owner Types Found</h5>
                          </div>
                        )}
                      </div>
                      <div className="p-2 col-md-6">
                      { idTypeOptions && idTypeOptions.length > 0 ? (
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.idType6) ?? null}
                          name="idType6"
                          onChange={(event, newValue) => {
                            let updatedObject = documentValues;
                            updatedObject.idType6 = newValue.name
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,["idType6"]:newValue.name});
                          }}
                          // inputValue={inputVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputVal(newInputValue);
                          // }}
                          onBlur={(e) => updatePreview(e)}
                          options={idTypeOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="ID Type" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}> No ID Types Found</h5>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Driver License/ID/TIN/EIN/UBI Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="dl6"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dl6 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Expiration date </label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="exp6"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.exp6 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Phone Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="phone6"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.phone6 ?? '' }
                          />
                      </div>
                    </div>


                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Registered owner full name (Last, First, Middle, Suffix) or Business name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="name6"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.name6 ?? '' }
                          />
                      </div>
                    </div>

                    {/*  */}

                    <h5>Owner 7</h5>

                    <div className="row">
                      {addressOptions && addressOptions.length > 0 ? (
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress5 && selectedAddress5.name) ?? null}
                            name="owner-7"
                            onChange={(event, newValue) => {
                              setSelectedAddress5(newValue);
                              AutoFillAddress(newValue, "owner-7");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Owner 7" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>):(
                        <div  className="text-center mx-auto">
                          <h5 style={{ color: "red" }}> No Addresses Found</h5>
                        </div>
                      )}
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                      { ownerTypeOptions && ownerTypeOptions.length > 0 ? (
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.ownerType7) ?? null}
                          name="ownerType7"
                          onChange={(event, newValue) => {
                            let updatedObject = documentValues;
                            updatedObject.ownerType7 = newValue.name
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,["ownerType7"]:newValue.name});
                          }}
                          // inputValue={inputVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputVal(newInputValue);
                          // }}
                          onBlur={(e) => updatePreview(e)}
                          options={ownerTypeOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Owner Type" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}> No Owner Types Found</h5>
                          </div>
                        )}
                      </div>
                      <div className="p-2 col-md-6">
                      { idTypeOptions && idTypeOptions.length > 0 ? (
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.idType7) ?? null}
                          name="idType7"
                          onChange={(event, newValue) => {
                            let updatedObject = documentValues;
                            updatedObject.idType7 = newValue.name
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,["idType7"]:newValue.name});
                          }}
                          // inputValue={inputVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputVal(newInputValue);
                          // }}
                          onBlur={(e) => updatePreview(e)}
                          options={idTypeOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="ID Type" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}> No ID Types Found</h5>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Driver License/ID/TIN/EIN/UBI Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="dl7"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dl7 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Expiration date </label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="exp7"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.exp7 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Phone Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="phone7"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.phone7 ?? '' }
                          />
                      </div>
                    </div>


                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Registered owner full name (Last, First, Middle, Suffix) or Business name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="name7"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.name7 ?? '' }
                          />
                      </div>
                    </div>

                    {/*  */}

                    <h5>Legal owners/Lienholders 2</h5>

                    <div className="row">
                      {addressOptions && addressOptions.length > 0 ? (
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress6 && selectedAddress6.name) ?? null}
                            name="legal-2"
                            onChange={(event, newValue) => {
                              setSelectedAddress6(newValue);
                              AutoFillAddress(newValue, "legal-2");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Legal owners/Lienholders 2" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>):(
                        <div  className="text-center mx-auto">
                          <h5 style={{ color: "red" }}> No Addresses Found</h5>
                        </div>
                      )}
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Name of legal owner/lienholder (Last, First, Middle initial or Business name)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="legName2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.legName2 ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                      { ownerTypeOptions && ownerTypeOptions.length > 0 ? (
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.ownerLienholderType2) ?? null}
                          name="ownerLienholderType2"
                          onChange={(event, newValue) => {
                            let updatedObject = documentValues;
                            updatedObject.ownerLienholderType2 = newValue.name
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,["ownerLienholderType2"]:newValue.name});
                          }}
                          // inputValue={inputVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputVal(newInputValue);
                          // }}
                          onBlur={(e) => updatePreview(e)}
                          options={ownerTypeOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Legal Owner/Lienholder Type" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}> No Legal Owner/Lienholder Types Found</h5>
                          </div>
                        )}
                      </div>
                      <div className="p-2 col-md-6">
                        { idTypeOptions && idTypeOptions.length > 0 ? (
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.idType2) ?? null}
                          name="idType2"
                          onChange={(event, newValue) => {
                            let updatedObject = documentValues;
                            updatedObject.idType2 = newValue.name
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,["idType2"]:newValue.name});
                          }}
                          // inputValue={inputVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputVal(newInputValue);
                          // }}
                          onBlur={(e) => updatePreview(e)}
                          options={idTypeOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="ID Type" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}> No ID Types Found</h5>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Driver License/ID/TIN/EIN/UBI Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="legDL2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.legDL2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Expiration Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="legExp2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.legExp2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">ELT participant</label>
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                        <RadioGroup   
                          row                         
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="elt_part_leg2_toggle"
                          value={(documentValues.elt_part_leg2_toggle ?? "")}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                        >
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="yes" control={<Radio />} label="Yes" />
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="no" control={<Radio />} label="No" />
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                        </RadioGroup>
                      </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Mailing address (Street address or PO Box, City, State, ZIP code)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="legAdd2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.legAdd2 ?? '' }
                          />
                      </div>
                    </div>

                    {/*  */}

                    <h5>Legal owners/Lienholders 3</h5>

                    <div className="row">
                      {addressOptions && addressOptions.length > 0 ? (
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress7 && selectedAddress7.name) ?? null}
                            name="legal-3"
                            onChange={(event, newValue) => {
                              setSelectedAddress7(newValue);
                              AutoFillAddress(newValue, "legal-3");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Legal owners/Lienholders 3" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>):(
                        <div  className="text-center mx-auto">
                          <h5 style={{ color: "red" }}> No Addresses Found</h5>
                        </div>
                      )}
                    </div>


                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                      <label htmlFor="stock">Name of legal owner/lienholder (Last, First, Middle initial or Business name)</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="legName3"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.legName3 ?? '' }
                        />
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                      { ownerTypeOptions && ownerTypeOptions.length > 0 ? (
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.ownerLienholderType3) ?? null}
                          name="ownerLienholderType3"
                          onChange={(event, newValue) => {
                            let updatedObject = documentValues;
                            updatedObject.ownerLienholderType3 = newValue.name
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,["ownerLienholderType3"]:newValue.name});
                          }}
                          // inputValue={inputVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputVal(newInputValue);
                          // }}
                          onBlur={(e) => updatePreview(e)}
                          options={ownerTypeOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Legal Owner/Lienholder Type" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}> No Legal Owner/Lienholder Types Found</h5>
                          </div>
                        )}
                      </div>
                      <div className="p-2 col-md-6">
                        { idTypeOptions && idTypeOptions.length > 0 ? (
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.idType3) ?? null}
                          name="idType3"
                          onChange={(event, newValue) => {
                            let updatedObject = documentValues;
                            updatedObject.idType3 = newValue.name
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,["idType3"]:newValue.name});
                          }}
                          // inputValue={inputVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputVal(newInputValue);
                          // }}
                          onBlur={(e) => updatePreview(e)}
                          options={idTypeOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="ID Type" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}> No ID Types Found</h5>
                          </div>
                        )}
                      </div>
                    </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">Driver License/ID/TIN/EIN/UBI Number</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="legDL3"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.legDL3 ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">Expiration Date</label>
                      <input
                        type="date"
                        className="form-control rounded-pill mt-1"
                        name="legExp3"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.legExp3 ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">ELT participant</label>
                      <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                      <RadioGroup   
                        row                         
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="elt_part_leg3_toggle"
                        value={(documentValues.elt_part_leg3_toggle ?? "")}
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                      >
                        <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="no" control={<Radio />} label="No" />
                        <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                      </RadioGroup>
                    </FormControl>
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                      <label htmlFor="stock">Mailing address (Street address or PO Box, City, State, ZIP code)</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="legAdd3"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.legAdd3 ?? '' }
                        />
                    </div>
                  </div>
<hr/>
<div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Date of Signature</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="delivery_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.delivery_date ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6"></div>
                    </div>
              

                  </div>


                <hr></hr>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default WAVehicleTitleApplicationAdditional

import { removeChars, formatUpperCase } from "../../../../../../utlis/formatters";
import { useEffect } from "react";

const processValuesChange = (target, moneyFields, phoneFields, upperCaseFields, checkboxes) => {
    const {name, value, checked}=target;
    if(moneyFields.indexOf(name) >= 0){
      return removeChars(value);
    }else if(phoneFields.indexOf(name) >= 0){
      return removeChars(value);
    }else if(upperCaseFields.indexOf(name) >= 0){
      return formatUpperCase(value);
    }else if(checkboxes.indexOf(name) >= 0){
      return (checked);
    }else{
      return value;
    }
}
  
export { 
    processValuesChange
}

export const useBlankCard = (isAddingDocument, setShowBlankCard) => {
  useEffect(() => {
    if (!isAddingDocument) {
      setShowBlankCard(false);
      return
    }
    //Delay so modal opening doesnt interfere with animation render
    const timer = setTimeout(() => {
      setShowBlankCard(true);
    }, 100);
    return () => clearTimeout(timer);
  }, [isAddingDocument, setShowBlankCard]);
}

export const useMarginTop = (cardRef, selectedDocument, setMarginTopValue ) => {
  useEffect(()=>{
    if(cardRef?.current?.offsetHeight){
    setMarginTopValue(`-${cardRef?.current?.offsetHeight}px`)
    }
  },[cardRef, selectedDocument, setMarginTopValue])
}

export const useNewCardHeight = (cardRef, isAddingDocument, setNewCardHeight) => {
  useEffect(()=>{
    if(cardRef?.current?.offsetHeight){
    setNewCardHeight(`${cardRef?.current?.offsetHeight}px`)
    }
  },[cardRef, isAddingDocument, setNewCardHeight])
}

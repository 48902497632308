import React, { useState } from "react";
import * as MaterialComponents from '@mui/material';

const SettingsModal = () => {

  const [dataCalcdate, setDataCalcdate] = useState('cal'); // 'cal','fis'

  const onDataCalcDateChange = (event) => {
    setDataCalcdate(event.target.value);
  };

  return (
    <>
      <div
        className="modal fade"
        id="financeDashboardSettingsModal"
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header bg-primary my-0 py-1">
              <h5
                className="modal-title text-white col-12 mt-2"
                id="exampleModalLabel"
              >
                Dashboard Settings
              </h5>
              <br />
            </div>
            <div className="p-4">
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Data Calculations Start Date</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={dataCalcdate}
                  onChange={onDataCalcDateChange}
                >
                  <FormControlLabel value="cal" control={<Radio />} label="Calendar (January 1st)" />
                  <FormControlLabel value="fis" control={<Radio />} label="Fiscal (June 1st)" />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="text-center m-3">
              <button className="btn btn-primary" 
                data-bs-dismiss="modal"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const {
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  FormControlLabel
} = MaterialComponents

export default SettingsModal;

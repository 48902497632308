// import _ from "lodash"
import { useSelector } from "react-redux"

import * as api from "../../utlis/api"
import { buildReduxActionsAndSelectors } from "../"
import { handleStateDefault } from "../"
import { setShowDownPaymentSpinner } from "../spinners/spinners"

const DOWNPAYMENT_MODAL_REDUX_PREFIX = 'DP'

//Define all valid entity fields
const DOWNPAYMENT_VALID_ENTITY_FIELDS = {
  editedDownpayment: [
    'status',
    'amount',
    'dateReceived',
    'paymentMethodId',
    'documentNumber'
  ]
}

const downpaymentsInitialState = {
  selectedDownpaymentId: null,
  modal: {
    downpayment: {},
    downpaymentFocusId: '',
    editedDownpayment: {},
    formattedAmount: '',
    isEditMode: false,
    isOpen: false,
    isValidForSubmit: false,
    lastClosedTimestamp: null,
    lastSaveTimestamp: null,
    showSuccessFeedback: false,
    statusInputValue: "",
    statusInputValueError: null,
    hasUnsavedChanges: false,
    errors: {
      editedDownpayment: {},
    }
  },
  section: {
  }
}

//Generate actions and selectors based on initial state and build options
const buildOptions = {
  fields: DOWNPAYMENT_VALID_ENTITY_FIELDS,
  entities: [ 'editedDownpayment' ],
  reduxPath: "downpayments.modal",
  reduxPrefix: DOWNPAYMENT_MODAL_REDUX_PREFIX,
  statePath: "modal"
}

export const rdx =
  buildReduxActionsAndSelectors(downpaymentsInitialState, buildOptions)

const useSelectedDownpaymentId = () => {
  return useSelector(state => state.order.selectedDownpaymentId)
}

rdx.useSelectedDownpaymentId = useSelectedDownpaymentId

/* CONSTANTS */
const RESET_DOWNPAYMENT_MODAL = 'RESET_DOWNPAYMENT_MODAL'

export const downpaymentsReducer = ( state=downpaymentsInitialState, action ) => {

  const __reduxPrefix = action.__prefix

  switch ( action.type ) {

    case RESET_DOWNPAYMENT_MODAL:

      return {
        ...state,
        modal: {
          ...downpaymentsInitialState.modal,
          //Keep the same action as before it was closed...
          lastClosedTimestamp: state.modal.lastClosedTimestamp
        }
      }
    default:
      //Handle all generated state updates...
      if ( __reduxPrefix === DOWNPAYMENT_MODAL_REDUX_PREFIX) {
        return handleStateDefault(state, action)
      }
  }

  return state
}

rdx.deleteDownpayment = (orderId, downpaymentId) => {

  return dispatch => {
    api.deleteDownpayment(orderId, downpaymentId)
    .then(() => {
      dispatch(rdx.setLastSaveTimestamp(new Date().getTime()))
    })
  }
}

rdx.resetDownpaymentModal = () => {
  return {
    type: RESET_DOWNPAYMENT_MODAL
  }
}

rdx.saveNewDownpayment = ( orderId, downpaymentObj ) => {

  return dispatch => {
    api.saveNewDownpayment(orderId,downpaymentObj)
    .then(res => {
      if(res){
        dispatch(rdx.setDownpaymentFocusId(res.data?.id))
        dispatch(setShowDownPaymentSpinner(false))
        dispatch(rdx.showSuccessFeedback())
      }
    }).catch((error)=>{
      dispatch(setShowDownPaymentSpinner(false))
    })
  }
}

rdx.updateDownpayment = ( orderId, downpaymentId, downpaymentObj ) => {
  return dispatch => {
    dispatch(setShowDownPaymentSpinner(true))
    api.updateDownpayment(orderId, downpaymentId, downpaymentObj)
    .then( res => {
    if(res){
      dispatch(setShowDownPaymentSpinner(false))
      dispatch(rdx.showSuccessFeedback())
    }
    }).catch((error)=>{
    dispatch(setShowDownPaymentSpinner(false))
    })
  }
}

rdx.getPaymentMethods = ( ) => {
  // return dispatch => {

    // var thing = api.getPaymentMethods()
    // return thing;
  // }
}

rdx.showSuccessFeedback = () => {
  return dispatch => {
    dispatch(rdx.setShowSuccessFeedback(true))
    dispatch(rdx.setHasUnsavedChanges(false))
    // setTimeout(() => {
    //   dispatch(rdx.setShowSuccessFeedback(false))
    // }, 2000)
  }
}

export default rdx

import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  useParams
} from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {  useOrder } from "../../../../../../../redux/orders/order"
import { removeChars, formatNameLastFirstMiddle, formatter, formatPhoneNumber, formatUpperCase } from "../../../../../../../utlis/formatters";
import { getOrderLevelAddresses, updateAddressOptions } from "../../../../../../../utlis/documentHelpers";
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { WAVehicleTitleApplicationGlobalAutofill, WAVehicleTitleApplicationAutofillProductDetails, preProcessPayload } from "../DocumentAutofill/WAVehicleTitleApplication"

const WAVehicleTitleApplication = ({document_id, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "wa-title-app";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  // const dispatch = useDispatch();

  // const stateAbbrev = States.map(state => {
  //   return state.abbreviation;
  // });

  const order = useOrder();
  // const customer = useSelector((state) => state?.orderState.customer);
  // const products = useSelector((state) => state?.orderState.products);
  // const tradeins = useSelector((state) => state?.orderState.tradeins);
  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");

  const [document, setDocument] = useState(undefined);
  const [previousDocumentId, setPreviousDocumentId] = useState(0);

  // Data Structure & Init //
  
  var initialDocumentStructure = {
    vin: undefined, 
    plate_number: undefined, 
    permit_number: undefined, 
    transfer_plate_cb: undefined, 
    transfer_lease_cb: undefined, 
    condition_new_cb: undefined, 
    condition_used_cb: undefined, 
    vehicle_type: undefined, 
    use_type: undefined, 
    year_model: undefined, 
    make: undefined, 
    model: undefined, 
    trim: undefined, 
    body_style: undefined, 
    fuel_type: undefined, 
    moto_style: undefined, 
    gv_weight_rating: undefined, 
    scale_wt: undefined, 
    gross_weight: undefined, 
    moto_gwt: undefined, 
    seats: undefined, 
    trl_axels: undefined, 
    color_1: undefined, 
    color_2: undefined, 
    equipment_number: undefined, 
    purchase_price: undefined, 
    wheels: undefined, 
    rental_number: undefined, 
    fleet_number: undefined, 
    engine_mc: undefined, 
    moto_etc_serial_number: undefined, 
    length: undefined, 
    width: undefined, 
    quick_title_yes_cb: undefined, 
    quick_title_no_cb: undefined, 
    park_donation_yes_cb: undefined, 
    park_donation_no_cb: undefined, 
    drivers_license_etc_no: undefined, 
    exp_date: undefined, 
    phone_type: undefined, 
    phone_num_s1: undefined, 
    owner_name_biz_address: undefined, 
    wa_primary_res_address: undefined, 
    mailing_address_s1: undefined, 
    one_time_mailing_s1: undefined, 
    email_address: undefined, 
    notify_by_email_cb: undefined, 
    drivers_license_etc_no_s2: undefined, 
    exp_date_s2: undefined, 
    phone_num_s2: undefined, 
    id_type_s2: undefined, 
    joint_tenants_yes_cb: undefined, 
    joint_tenants_no_cb: undefined, 
    owner_name_biz_name_s2: undefined, 
    legal_owner_name: undefined, 
    legal_exp_date: undefined, 
    legal_owner_type: undefined, 
    legal_id_type: undefined, 
    legal_drivers_etc_number: undefined, 
    elt_yes_cb: undefined, 
    elt_no_cb: undefined, 
    legal_mailing_address: undefined, 
    dealer_name: undefined, 
    sale_date: undefined, 
    delivery_date: undefined, 
    vehicle_status_new_cb: undefined, 
    dealer_number: undefined, 
    dealer_type: undefined, 
    id_type: undefined, 
    owner_type: undefined, 
    owner_type_s2: undefined, 
    transfer_title_cb: undefined, 
    discover_pass_yes_cb: undefined, 
    discover_pass_no_cb: undefined, 
    vehicle_status_prev_title_cb: undefined, 
    vehicle_status_used_cb: undefined, 

    form_purpose_toggle: undefined,
    new_used_toggle: undefined,
    quick_title_toggle: undefined,
    park_donation_toggle: undefined,
    discover_pass_toggle: undefined,
    joint_tenants_toggle: undefined,
    elt_toggle: undefined,
    vehicle_status_toggle: undefined
  }

  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);

  const removeFormatting = e =>{
    const {name,value}=e.target;
    setDocumentValues({...documentValues,[name]:removeChars(value)});
  }

  // const moneyFields = useMemo(()=>{return["purchase_price"];
  const moneyFields = useMemo(()=>{return["purchase_price"]},[]);
  // const phoneFields = useMemo(()=>{return["phone_num_s1", "phone_num_s2"];
  const phoneFields = useMemo(()=>{return["phone_num_s1", "phone_num_s2"]},[]);
  // const upperCaseFields = useMemo(()=>{return["vin", "model"];
  const upperCaseFields = useMemo(()=>{return["vin", "model"]},[]);

  // const checkboxes = useMemo(()=>{return[
  //   "notify_by_email_cb"
  // ];
  const checkboxes = useMemo(()=>{return["notify_by_email_cb"]},[]);

  // const radios = [
  //   "form_purpose_toggle", 
  //   "new_used_toggle", 
  //   "quick_title_toggle", 
  //   "park_donation_toggle", 
  //   "discover_pass_toggle", 
  //   "joint_tenants_toggle", 
  //   "elt_toggle", 
  //   "vehicle_status_toggle", 
  // ];

  // X1- Current General On Change
  const handleDocumentValuesChange = e =>{
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  
  const [documentLoaded, setDocumentLoaded] = useState(false);  

  const loadDocument = useCallback(async (documentId) => {
    // const getProductDeposits = useCallback(async (orderId, productId) => {
      if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('WAVehicleTitleApplication.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug,documentLoaded,loadDocument ])

  const [assignedProduct, setAssignedProduct] = useState(undefined);
  // const [productVal, setproductVal] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  // const [tradeinVal, setTradeinVal] = useState(undefined);
  // const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  // const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);

  // const [selectedAddress1, setSelectedAddress1] = useState(undefined);
  const [selectedAddress1a, setSelectedAddress1a] = useState(undefined);
  const [selectedAddress1b, setSelectedAddress1b] = useState(undefined);
  const [selectedAddress1c, setSelectedAddress1c] = useState(undefined);
  const [selectedAddress2, setSelectedAddress2] = useState(undefined);
  const [selectedAddress3, setSelectedAddress3] = useState(undefined);
  // const [selectedAddress4, setSelectedAddress4] = useState(undefined);
  const [addressOptions, setAddressOptions] = useState(undefined);
  const [inputAddressVal, setinputAddressVal] = useState(undefined);

  const [vehicleTypeOptions, setVehicleTypeOptions] = useState(undefined);
  const [colorOptions, setColorOptions] = useState(undefined);
  const [ownerTypeOptions, setOwnerTypeOptions] = useState(undefined);
  const [idTypeOptions, setIdTypeOptions] = useState(undefined);
  const [phoneTypeOptions, setPhoneTypeOptions] = useState(undefined);
  const [dealerTypeOptions, setDealerTypeOptions] = useState(undefined);
  const [weightRatingOptions, setWeightRatingOptions] = useState(undefined);
  const [fuelTypeOptions, setFuelTypeOptions] = useState(undefined);
  const [motoStyleOptions, setMotoStyleOptions] = useState(undefined);
  const [useTypeOptions, setUseTypeOptions] = useState(undefined);

// Dropdowns //colorOptions

// if(order && order.products && order.products.length > 0 && productOptions === undefined){
//   let getProductOptions = [];
//   order.products.map(product => {
//     let str = `${product.stockNumber.toString().toUpperCase()}`;
//     let obj = {id: product.id, name: str}
//     getProductOptions.push(obj);
//   })
//   setproductOptions(getProductOptions);
// }

// if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
//   let getTradeinOptions = [];
//   order.tradeins.map(tradein => {
//     let str = `${tradein.year} ${tradein.make} ${tradein.model.toString().toUpperCase()}`;
//     let obj = {id: tradein.id, name: str}
//     getTradeinOptions.push(obj);
//   })
//   setTradeinOptions(getTradeinOptions);
// }

// function getOrderLevelAddresses(order){
//   let getAddressOptions = [];
//   if(order.customer && order.customer.deliveryAddress){
//     var addressLine = '';
//     if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
//       addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
//     }else{
//       addressLine = order.customer.deliveryAddress.addressLine1;
//     }
//     let city = order.customer.deliveryAddress.city;
//     let state = order.customer.deliveryAddress.state;
//     let zip = order.customer.deliveryAddress.zip;

//     let obj = {slug: "customer-delivery", name: "Customer Delivery", address: addressLine, city: city, state: state, zip: zip};
//     getAddressOptions.push(obj);
//   }

//   if(order.customer && order.customer.mailingAddress){

//     if(order.customer.mailingAddress.addressLine2 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
//       addressLine = (order.customer.mailingAddress.addressLine1+" "+order.customer.mailingAddress.addressLine2);
//     }else{
//       addressLine = order.customer.mailingAddress.addressLine1;
//     }
//     let city = order.customer.mailingAddress.city;
//     let state = order.customer.mailingAddress.state;
//     let zip = order.customer.mailingAddress.zip;

//     let obj = {slug: "customer-mailing", name: "Customer Mailing", address: addressLine, city: city, state: state, zip: zip};
//     getAddressOptions.push(obj);
//   }
//   return getAddressOptions;
// }

function getVehicleTypes(){
  let vehicleTypes = [];
  vehicleTypes.push({name: "Automobile"});
  vehicleTypes.push({name: "Truck"});
  vehicleTypes.push({name: "Motorcycle"});
  vehicleTypes.push({name: "Mobile/Mfg home"});
  vehicleTypes.push({name: "Trailer"});
  vehicleTypes.push({name: "Other"});

  return vehicleTypes;
}

function getColors(){
  let colors = [];
  colors.push({name: "Beige"});
  colors.push({name: "Black"});
  colors.push({name: "Blue"});
  colors.push({name: "Blue, dark"});
  colors.push({name: "Blue, light"});
  colors.push({name: "Brown"});
  colors.push({name: "Bronze"});
  colors.push({name: "Chrome/steel"});
  colors.push({name: "Copper"});
  colors.push({name: "Cream/ivory"});
  colors.push({name: "Gold"});
  colors.push({name: "Gray"});
  colors.push({name: "Green"});
  colors.push({name: "Green, dark"});
  colors.push({name: "Green, light"});
  colors.push({name: "Ivory"});
  colors.push({name: "Lavender"});
  colors.push({name: "Maroon/burgundy"});
  colors.push({name: "Multicolor"});
  colors.push({name: "Orange"});
  colors.push({name: "Pink"});
  colors.push({name: "Purple"});
  colors.push({name: "Red"});
  colors.push({name: "Silver/aluminum"});
  colors.push({name: "Tan"});
  colors.push({name: "Turquoise"});
  colors.push({name: "White"});
  colors.push({name: "Yellow"});

  return colors;
}

function getOwnerTypes(){
  let ownerTypes = [];
  ownerTypes.push({name: "Individual"});
  ownerTypes.push({name: "Business"});

  return ownerTypes;
}

function getIdTypes(){
  let idTypes = [];
  idTypes.push({name: "Drivers License ID"});
  idTypes.push({name: "Tax ID no"});
  idTypes.push({name: "Employer ID no"});
  idTypes.push({name: "Unified business no"});

  return idTypes;
}

function getPhoneTypes(){
  let phoneTypes = [];
  phoneTypes.push({name: "Business"});
  phoneTypes.push({name: "Cell"});
  phoneTypes.push({name: "Home"});

  return phoneTypes;
}

function getDealerTypes(){
  let dealerTypes = [];
  dealerTypes.push({name: "In State"});
  dealerTypes.push({name: "Out of State"});

  return dealerTypes;
}

function getWeightRatings(){
  let weightRatings = [];
  weightRatings.push({name: "0-6,000"});
  weightRatings.push({name: "6,001-10,000"});
  weightRatings.push({name: "10,001-14,000"});
  weightRatings.push({name: "14,001-16,000"});
  weightRatings.push({name: "16,001-19,000"});
  weightRatings.push({name: "19,001-26,000"});
  weightRatings.push({name: "26,001-33,000"});
  weightRatings.push({name: "33,001-99,999"});


  return weightRatings;
}

function getFuelTypes(){
  let fuelTypes = [];
  fuelTypes.push({name: "Gasoline"});
  fuelTypes.push({name: "Diesel"});
  fuelTypes.push({name: "Electric"});
  fuelTypes.push({name: "Hybrid"});
  fuelTypes.push({name: "Comp natural gas"});
  fuelTypes.push({name: "Liq natural gas"});
  fuelTypes.push({name: "liq petroleum gas"});
  fuelTypes.push({name: "Butane"});
  fuelTypes.push({name: "Propane"});
  fuelTypes.push({name: "Steam"});
  fuelTypes.push({name: "Unpowered"});
  fuelTypes.push({name: "Other"});


  return fuelTypes;
}

function getMotoStyles(){
  let motoStyles = [];
  motoStyles.push({name: "3-Wheel"});
  motoStyles.push({name: "Adventure bike"});
  motoStyles.push({name: "Cruiser bike"});
  motoStyles.push({name: "Dirt bike"});
  motoStyles.push({name: "Dual sport bike"});
  motoStyles.push({name: "Scooter"});
  motoStyles.push({name: "Sport bike"});
  motoStyles.push({name: "Standard"});
  motoStyles.push({name: "Touring bike"});

  return motoStyles;
}

function getUseTypes(){
  let useTypes = [];

  useTypes.push({name: "Passenger vehicle"});
  useTypes.push({name: "Antique vehicle"});
  useTypes.push({name: "Commerical"});
  useTypes.push({name: "Exempt (st/co/loc/tribal)"});
  useTypes.push({name: "Farm exempt"});
  useTypes.push({name: "For hire"});
  useTypes.push({name: "Gov't(federal)"});
  useTypes.push({name: "Med-speed electric"});
  useTypes.push({name: "Off-road vehicle"});
  useTypes.push({name: "School (private)"});
  useTypes.push({name: "Stage"});
  useTypes.push({name: "Taxi cab"});
  useTypes.push({name: "Truck"});
  useTypes.push({name: "Combo (farm use)"});
  useTypes.push({name: "Combo (non-farm use)"});
  useTypes.push({name: "Farm use"});
  useTypes.push({name: "Fixed load vehicle"});
  useTypes.push({name: "Logging"});
  useTypes.push({name: "Motorhome"});
  useTypes.push({name: "Tow truck"});
  useTypes.push({name: "Motorcycle"});
  useTypes.push({name: "Mobile home"});
  useTypes.push({name: "Com'l motorcycle trailer"});
  useTypes.push({name: "House dolly"});
  useTypes.push({name: "Trailer (personal use)"});
  useTypes.push({name: "Travel trailer"});
  useTypes.push({name: "Travel trailer (antique)"});
  useTypes.push({name: "All terraine veh (WATV"});
  useTypes.push({name: "Camper"});
  useTypes.push({name: "Converter gear"});
  useTypes.push({name: "Mid-elect truck"});
  useTypes.push({name: "Moped"});
  useTypes.push({name: "Snowmobile"});
  useTypes.push({name: "Snowmobile (exempt)"});
  useTypes.push({name: "Snowmobile (vintage)"});

  return useTypes;
}


const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
  Object.keys(payload).forEach(key => {
    if (payload[key] === null || payload[key] === undefined) {
      delete payload[key];
    }
  });
  
  var docCopy = JSON.parse(JSON.stringify(document));
  docCopy.payload =  JSON.parse(JSON.stringify(payload));

  docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);

  docCopy.payload =  JSON.stringify(docCopy.payload);
  delete docCopy.documentStatus;
  
  try {
    let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
    const file = new Blob(
      [response.data], 
      {type: 'application/pdf'});
    const fileURL = URL.createObjectURL(file);
      // window.open(fileURL);
    setdocumentUrl(fileURL)
  } catch (err) {
    recordFrontEndError('WAVehicleTitleApplication.jsx', 'Error previewing document payload: ' + err)
  };  
},[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


//Autofill Address Details After Selection
const AutoFillAddress = useCallback(async (addressSelected, selectionInstance) => {

  var newValues = documentValues;
    if(selectionInstance){
      if(selectionInstance === "wa-registered-owner"){
        if(addressSelected.type === "entity"){
          newValues.owner_type = "Business";
          newValues.owner_name_biz_address = addressSelected.label;
        }else{
          newValues.owner_type = "Individual";
          newValues.owner_name_biz_address = formatNameLastFirstMiddle(addressSelected.label);
        }

        if(addressSelected.phone){
          newValues.phone_num_s1 = formatPhoneNumber(addressSelected.phone);
        }
        // newValues.owner_name_biz_address = addressSelected.label;
        if(addressSelected.address){
          newValues.wa_primary_res_address = addressSelected.address+" "+addressSelected.city+" "+addressSelected.state+", "+addressSelected.zip;
        }

        // Autofill Cosigner to 2nd owner section - name, phone, type.
        if(addressSelected.cosigner){
          newValues.owner_type_s2 = "Individual"
          newValues.phone_num_s2 = formatPhoneNumber(addressSelected.cosigner.phone);
          newValues.owner_name_biz_name_s2 = formatNameLastFirstMiddle(addressSelected.cosigner.name);
        }
        
      }
    
      if(selectionInstance === "wa-second-owner"){
        if(addressSelected.type === "entity"){
          newValues.owner_type_s2 = "Business"
          newValues.owner_name_biz_name_s2 = addressSelected.label;
        }else{
          newValues.owner_type_s2 = "Individual"
          newValues.owner_name_biz_name_s2 = formatNameLastFirstMiddle(addressSelected.label);
        }
        if(addressSelected.phone){
          newValues.phone_num_s2 = formatPhoneNumber(addressSelected.phone);
        }
      }

      if(selectionInstance === "wa-legal-lienholder"){
        newValues.legal_owner_name = addressSelected.label;
        if(addressSelected.type === "entity"){
          newValues.legal_owner_type = "Business"
        }else{
          newValues.legal_owner_type = "Individual"
        }

        if(addressSelected.address){
          newValues.legal_mailing_address =  addressSelected.address+" "+addressSelected.city+" "+addressSelected.state+", "+addressSelected.zip;
        }
        // if(addressSelected.phone){
        //   newValues.phone_num_s2 = formatPhoneNumber(addressSelected.phone);
        // }
      }

      if(selectionInstance === "wa-primary-address"){
        if(addressSelected.address){
          newValues.wa_primary_res_address = addressSelected.address+" "+addressSelected.city+" "+addressSelected.state+", "+addressSelected.zip;
        }
      }
      if(selectionInstance === "mailing-address"){
        if(addressSelected.address){
          newValues.mailing_address_s1 = addressSelected.address+" "+addressSelected.city+" "+addressSelected.state+", "+addressSelected.zip;
        }
      }
      if(selectionInstance === "one-time-address"){
        if(addressSelected.address){
          newValues.one_time_mailing_s1 = addressSelected.address+" "+addressSelected.city+" "+addressSelected.state+", "+addressSelected.zip;
        }
      }
      if(selectionInstance === "legal-mailing-address"){
        if(addressSelected.address){
          newValues.legal_mailing_address = addressSelected.address+" "+addressSelected.city+" "+addressSelected.state+", "+addressSelected.zip;
        }
      }
    }
  setDocumentValues(newValues);
  if(document){
    previewPayloadDocument(documentValues);
  }
  setHasUnsavedChanges(true);
  setSaveSuccess(false);
},[document, documentValues, previewPayloadDocument])



 // Autofill //

//  function LoadDropdowns(){
const LoadDropdowns = useCallback(async () => {
  setVehicleTypeOptions(getVehicleTypes());
  setColorOptions(getColors());
  setOwnerTypeOptions(getOwnerTypes());
  setIdTypeOptions(getIdTypes());
  setPhoneTypeOptions(getPhoneTypes());
  setDealerTypeOptions(getDealerTypes());
  setWeightRatingOptions(getWeightRatings());
  setFuelTypeOptions(getFuelTypes());
  setMotoStyleOptions(getMotoStyles());
  setUseTypeOptions(getUseTypes());
},[ ])

// const preProcessPayload = useCallback((payload) => {

//   if(payload.form_purpose_toggle){
//     // Reset all
//     payload.transfer_title_cb = "no";
//     payload.transfer_plate_cb = "no";
//     payload.transfer_lease_cb = "no";

//     switch(payload.form_purpose_toggle){
//       case "lease":
//         payload.transfer_lease_cb = "yes";
//         break;
//       case "plate":
//         payload.transfer_plate_cb = "yes";
//         break;
//       case "title":
//         payload.transfer_title_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.new_used_toggle){
//     // Reset all
//     payload.condition_new_cb = "no";
//     payload.condition_used_cb = "no";

//     switch(payload.new_used_toggle){
//       case "new":
//         payload.condition_new_cb = "yes";
//         break;
//       case "used":
//         payload.condition_used_cb = "yes";
//         break;

//       default:
//     }
//   }

//   if(payload.quick_title_toggle){
//     // Reset all
//     payload.quick_title_yes_cb = "no";
//     payload.quick_title_no_cb = "no";

//     switch(payload.quick_title_toggle){
//       case "yes":
//         payload.quick_title_yes_cb = "yes";
//         break;
//       case "no":
//         payload.quick_title_no_cb = "yes";
//         break;

//       default:
//     }
//   }

//   if(payload.park_donation_toggle){
//     // Reset all
//     payload.park_donation_yes_cb = "no";
//     payload.park_donation_no_cb = "no";

//     switch(payload.park_donation_toggle){
//       case "yes":
//         payload.park_donation_yes_cb = "yes";
//         break;
//       case "no":
//         payload.park_donation_no_cb = "yes";
//         break;

//       default:
//     }
//   }

//   if(payload.discover_pass_toggle){
//     // Reset all
//     payload.discover_pass_yes_cb = "no";
//     payload.discover_pass_no_cb = "no";

//     switch(payload.discover_pass_toggle){
//       case "yes":
//         payload.discover_pass_yes_cb = "yes";
//         break;
//       case "no":
//         payload.discover_pass_no_cb = "yes";
//         break;

//       default:
//     }
//   }

//   if(payload.joint_tenants_toggle){
//     // Reset all
//     payload.joint_tenants_yes_cb = "no";
//     payload.joint_tenants_no_cb = "no";

//     switch(payload.joint_tenants_toggle){
//       case "yes":
//         payload.joint_tenants_yes_cb = "yes";
//         break;
//       case "no":
//         payload.joint_tenants_no_cb = "yes";
//         break;

//       default:
//     }
//   }

//   if(payload.elt_toggle){
//     // Reset all
//     payload.elt_yes_cb = "no";
//     payload.elt_no_cb = "no";

//     switch(payload.elt_toggle){
//       case "yes":
//         payload.elt_yes_cb = "yes";
//         break;
//       case "no":
//         payload.elt_no_cb = "yes";
//         break;

//       default:
//     }
//   }

//   if(payload.vehicle_status_toggle){
//     // Reset all
//     payload.vehicle_status_new_cb = "no";
//     payload.vehicle_status_used_cb = "no";
//     payload.vehicle_status_prev_title_cb = "no";

//     switch(payload.vehicle_status_toggle){
//       case "new":
//         payload.vehicle_status_new_cb = "yes";
//         break;
//       case "used":
//         payload.vehicle_status_used_cb = "yes";
//         break;
//       case "prev_title":
//         payload.vehicle_status_prev_title_cb = "yes";
//         break;
//       default:
//     }
//   }

//   // X1.2- Field Type Specific Preview Formatting
//   Object.keys(payload).forEach(key => {
//     if(moneyFields.indexOf(key) >= 0){
//         payload[key] = formatMoneyValue(payload[key]);
//     }else if(phoneFields.indexOf(key) >= 0){
//         payload[key] = formatPhoneNumber(payload[key]);
//     }else if(upperCaseFields.indexOf(key) >= 0){
//         payload[key] = formatUpperCase(payload[key]);
//     }
//   });
//   setDocumentValues(JSON.parse(JSON.stringify(payload)));
//   Object.keys(payload).forEach(key => {
//     if(checkboxes.indexOf(key) >= 0){
//       payload[key] = payload[key] ? "yes": "no";
//     }
//   });

//   // Data point formatting
//   if(payload.exp_date){
//     payload.exp_date = moment.utc(payload.exp_date).format("MM/DD/YY");
//   }

//   if(payload.exp_date_s2){
//     payload.exp_date_s2 = moment.utc(payload.exp_date_s2).format("MM/DD/YY");
//   }

//   if(payload.legal_exp_date){
//     payload.legal_exp_date = moment.utc(payload.legal_exp_date).format("MM/DD/YY");
//   }

//   if(payload.sale_date){
//     payload.sale_date = moment.utc(payload.sale_date).format("MM/DD/YY");
//   }

//   if(payload.delivery_date){
//     payload.delivery_date = moment.utc(payload.delivery_date).format("MM/DD/YY");
//   }

//   if(payload.delivery_date_2){
//     payload.delivery_date_2 = moment.utc(payload.delivery_date_2).format("MM/DD/YY");
//   }

//   if(payload.delivery_date_3){
//     payload.delivery_date_3 = moment.utc(payload.delivery_date_3).format("MM/DD/YY");
//   }

//   return payload;
// },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])

// function previewPayloadDocument(){

// function GlobalAutofill(){
const GlobalAutofill = useCallback(async () => {

  let newValues = WAVehicleTitleApplicationGlobalAutofill(documentValues, order);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  // var newValues = documentValues;
  
  // if(order && order.customer && order.customer.email){
  //     newValues.email_address = order.customer.email;
  // }

  // if(order && order.customer){
  //   if(order.customer.type === "entity"){
  //     newValues.owner_type = "Business";
  //     newValues.owner_name_biz_address = order.customer.name;
  //   }else{
  //     newValues.owner_type = "Individual";
  //     newValues.owner_name_biz_address = formatNameLastFirstMiddle(order.customer.name);
  //   }
  //   newValues.phone_num_s1 = formatPhoneNumber(order.customer.phone);

  //   if(order.customer.dba){
  //     newValues.owner_name_biz_address = order.customer.name+" dba "+order.customer.dba;
  //   }
  //   if(order.customer.cosigners && order.customer.cosigners.length > 0){
  //     let cosigner = order.customer.cosigners[0];
  //     if(cosigner){
  //       newValues.owner_type_s2 = "Individual"
  //       newValues.phone_num_s2 = formatPhoneNumber(cosigner.phone);
  //       newValues.owner_name_biz_name_s2 = formatNameLastFirstMiddle(cosigner.name);
  //     }
  //   }
  // }
  
  // if(order && order.customer && order.customer.deliveryAddress){
  //   var addressLine = '';
  //   if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
  //     addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
  //   }else{
  //     addressLine = order.customer.deliveryAddress.addressLine1;
  //   }
  //   newValues.wa_primary_res_address = addressLine+" "+(order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
  // }

  // // if(order && order.customer && order.customer.mailingAddress){
  // //   var addressLine = '';
  // //   if(order.customer.mailingAddress.addressLine2 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
  // //     addressLine = (order.customer.mailingAddress.addressLine1+" "+order.customer.mailingAddress.addressLine2);
  // //   }else{
  // //     addressLine = order.customer.mailingAddress.addressLine1;
  // //   }
  // //   newValues.mailing_address = addressLine;
  // //   newValues.mailing_city_state_zip = (order.customer.mailingAddress.city+", "+order.customer.mailingAddress.state+" "+order.customer.mailingAddress.zip);
  // // }


  // if(order && order.documentationDate && documentValues.sale_date === undefined){
  //   newValues.sale_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  // }

  // if(order && order.documentationDate && documentValues.delivery_date === undefined){
  //   newValues.delivery_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  // }

  // if(order && order.documentationDate && documentValues.delivery_date_2 === undefined){
  //   newValues.delivery_date_2 = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  // }

  // if(order && order.documentationDate && documentValues.delivery_date_3 === undefined){
  //   newValues.delivery_date_3 = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  // }


  // setDocumentValues(newValues);
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ documentValues, order ])

const AutofillProductDetails = useCallback(async (selectedProduct) => {
  let newValues = WAVehicleTitleApplicationAutofillProductDetails(selectedProduct, documentValues);

  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setSaveSuccess(false);
  }

//   var newValues = documentValues;
//   newValues.vin = (selectedProduct.vin).toString().toUpperCase();
//   newValues.year_model = (selectedProduct.year).trim();
//   newValues.make = (selectedProduct.make).trim();
//   newValues.model = (selectedProduct.model).trim().toString().toUpperCase();

//   if(selectedProduct.type.toUpperCase() === "NEW"){
//     newValues.new_used_toggle = "new";
//   }else{
//     newValues.new_used_toggle = "used";
//   }

//   newValues.vehicle_type = selectedProduct.vehicle_type;

//   setDocumentValues(newValues);
//   // setProductToggleAutoFill(false);
//   if(document){
//     previewPayloadDocument(documentValues);
//   }
//   // setAddressOptions(updateAddressOptions(selectedProduct, order));
//   setHasUnsavedChanges(true);
// setSaveSuccess(false);
// }
},[ document, documentValues, previewPayloadDocument ])

  // Document BREAD + Preview // TODO: Move to Redux

  useEffect(() => {
    if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){
  
      existingPayload = true;
  
      if(documentPayload.exp_date){
        documentPayload.exp_date = moment.utc(documentPayload.exp_date).format("YYYY-MM-DD");
      }

      if(documentPayload.exp_date_s2){
        documentPayload.exp_date_s2 = moment.utc(documentPayload.exp_date_s2).format("YYYY-MM-DD");
      }

      if(documentPayload.legal_exp_date){
        documentPayload.legal_exp_date = moment.utc(documentPayload.legal_exp_date).format("YYYY-MM-DD");
      }

      if(documentPayload.sale_date){
        documentPayload.sale_date = moment.utc(documentPayload.sale_date).format("YYYY-MM-DD");
      }

      if(documentPayload.delivery_date){
        documentPayload.delivery_date = moment.utc(documentPayload.delivery_date).format("YYYY-MM-DD");
      }

      if(documentPayload.delivery_date_2){
        documentPayload.delivery_date_2 = moment.utc(documentPayload.delivery_date_2).format("YYYY-MM-DD");
      }

      if(documentPayload.delivery_date_3){
        documentPayload.delivery_date_3 = moment.utc(documentPayload.delivery_date_3).format("YYYY-MM-DD");
      }
      
      // Loading Use Effect
      if(document.additionalData){
        const methodMap = { 
          selectedAddress1a: setSelectedAddress1a, 
          selectedAddress1b: setSelectedAddress1b, 
          selectedAddress1c: setSelectedAddress1c, 
          selectedAddress2: setSelectedAddress2, 
          selectedAddress3: setSelectedAddress3
        };

        let parsedAdditionalData = JSON.parse(document.additionalData);
        Object.keys(parsedAdditionalData).forEach(function(key) {
          if(methodMap[key]){
            methodMap[key](parsedAdditionalData[key]);
          }
        });
      }
  
      setDocumentValues(documentPayload);
    }

    var foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }

    setAddressOptions(getOrderLevelAddresses(order));
    if(foundAssignedProduct){
        setAssignedProduct(foundAssignedProduct);

        let addressOptionsArray = updateAddressOptions(foundAssignedProduct, order)

        setAddressOptions(addressOptionsArray);

        /////
        let assignedLienHolderAddressOptionSlug = null;

        if(foundAssignedProduct.fundingDetail && foundAssignedProduct.fundingDetail.finalizedAt){
          if(foundAssignedProduct.fundingDetail.fundingMethod === "tec-financing" || foundAssignedProduct.fundingDetail.fundingMethod === "finance-only" || foundAssignedProduct.fundingDetail.fundingMethod === "direct-deal"){
            if(foundAssignedProduct.fundingDetail.finalizedLendingOption){
              assignedLienHolderAddressOptionSlug = "product-lendor-lien";
            }
          }
          
          if(foundAssignedProduct.fundingDetail.fundingMethod === "cash-with-lien" && foundAssignedProduct.fundingDetail.cashLienHolder){
            assignedLienHolderAddressOptionSlug = "product-lendor-cash";
          }

          if(assignedLienHolderAddressOptionSlug !== null){
            let foundLenderAddress = addressOptionsArray.find( x => x.slug === assignedLienHolderAddressOptionSlug);
            if(foundLenderAddress){
              setSelectedAddress1c(foundLenderAddress);
              AutoFillAddress(foundLenderAddress, "wa-legal-lienholder");
            }
          }

        }
        /////
    }
  
      LoadDropdowns();
      if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
      }else{
        // if(document && document.itemIds){
        //   var itemIds = document.itemIds.split(',');
        //     var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
        //     let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
        //     let obj = {id: foundProduct.id, name: str}
        //     setproductVal(obj);
        //     if(foundProduct){
        //       setAddressOptions(updateAddressOptions(foundProduct, order));
        //     }
        //     // streamDocument();
        // }
  
      }
      
      setDataLoaded(true);
    }
  },[document, dataLoaded, AutofillProductDetails, GlobalAutofill, LoadDropdowns, order, previewPayloadDocument, documentValues, AutoFillAddress])





  
    // function streamDocument(){
    //   axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
    //     .then(response => {
    //       const file = new Blob(
    //         [response.data], 
    //         {type: 'application/pdf'});
    //         const fileURL = URL.createObjectURL(file);
    //         // window.open(fileURL);
    //       setdocumentUrl(fileURL)
    //     }).catch(err => {
    //     })
    // }
  
    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();
  
      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
    
      // const payload = JSON.parse(JSON.stringify(documentValues));
      // var docCopy = document;
      // docCopy.payload = payload;
      // docCopy.payload = preProcessPayload(docCopy.payload);
      // docCopy.payload =  JSON.stringify(docCopy.payload);
      // delete docCopy.documentStatus;
      
      // if(selectedProduct && selectedProduct.id){
      //   docCopy.itemIds = `${selectedProduct.id}`;
      // }
  
      //Save Dropdown Selections
      let dropdownData = {};
      if(selectedAddress1a){
        dropdownData.selectedAddress1a = {
          id: selectedAddress1a.id,
          name: selectedAddress1a.name
        };
      }
      if(selectedAddress1b){
        dropdownData.selectedAddress1b = {
          id: selectedAddress1b.id,
          name: selectedAddress1b.name
        };
      }
      if(selectedAddress1c){
        dropdownData.selectedAddress1c = {
          id: selectedAddress1c.id,
          name: selectedAddress1c.name
        };
      }
      if(selectedAddress2){
        dropdownData.selectedAddress2 = {
          id: selectedAddress2.id,
          name: selectedAddress2.name
        };
      }
      if(selectedAddress3){
        dropdownData.selectedAddress3 = {
          id: selectedAddress3.id,
          name: selectedAddress3.name
        };
      }

      let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, additionalData, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });
      // docCopy.additionalData = JSON.stringify(dropdownData);

      // try {
      //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
      //   if (res) {
      //     await axios.post(`/document/${document.id}/generate`) 
      //     getDocuments();
      //     setSaveSuccess(true);
      //     setHasUnsavedChanges(false);
      //   };
      // } catch (err) {
      //   recordFrontEndError('WAVehicleTitleApplication.jsx', 'Error saving document: ' + err)
      // };
      
    }

// Component Helpers //
// useEffect(() => {
  //Autofill Product Details After Selection
  // const AutofillProductDetails = useCallback(async (selectedProduct) => {




const onCloseClick = e => {
  e.preventDefault()
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setdocumentUrl("");
  setDataLoaded(false);
  setAssignedProduct(undefined);
  setDocumentValues(initialDocumentStructure);
  setDocument(undefined);
  setDocumentLoaded(false);
  setAssignedProduct(undefined);
  setSelectedAddress1a(undefined);
  setSelectedAddress1b(undefined);
  setSelectedAddress1c(undefined);
  setSelectedAddress2(undefined);
  setSelectedAddress3(undefined);
  setinputAddressVal(undefined);
  setAddressOptions(undefined);
  setPreviousDocumentId(0);
  setHasClickedClose(false);
  setDocumentFocus(undefined);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}



  return (
    <>
      <div
        className="modal fade"
        // id="wa-title-app"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                WA - Vehicle Title Application
              </h5>
              <br />
              { hasClickedClose ? (
                <div className="d-flex">
                    <p className="m-1 pt-1 text-white">Save Changes?</p>
                    <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                    <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                </div>
              ): hasUnsavedChanges ? (
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}
                  onClick={closeClicked}
                ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ fontSize: '12px', overflowY: 'scroll', height: 'inherit'}}>
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill();
                      AutofillProductDetails(assignedProduct);
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>
                  <br />

                  { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                      Product Snapshot
                      <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                      </div>
                    </div>
                  ):(
                    <div className="m-2 p-2 border">
                      <span>No Assigned Product</span>
                    </div>
                  )}

                  <div className="row">
                    <h5>Vehicle Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                      <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                        <RadioGroup   
                          row                         
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="form_purpose_toggle"
                          value={(documentValues.form_purpose_toggle ?? "")}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                        >
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="lease" control={<Radio />} label="Lease" />
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="plate" control={<Radio />} label="Plate Transfer" />
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="title" control={<Radio />} label="Title purposes only" />
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                        </RadioGroup>
                      </FormControl>

                      { documentValues.form_purpose_toggle === "plate" ? (
                        <div className="col-md-6">
                          <label htmlFor="stock">Plate Number</label>
                          <input
                            type="text"
                            className="form-control rounded-pill mt-1"
                            name="plate_number"
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                            value={ documentValues.plate_number ?? '' }
                            />
                        </div>
                      ):null}
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Permit Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="permit_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.permit_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6"></div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-8">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Condition</label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup     
                            row                       
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="new_used_toggle"
                            value={(documentValues.new_used_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="new" control={<Radio />} label="New" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="used" control={<Radio />} label="Used" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        { vehicleTypeOptions && vehicleTypeOptions.length > 0 ? (
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (documentValues.vehicle_type) ?? null}
                            name="vehicle_type"
                            onChange={(event, newValue) => {
                              let updatedObject = documentValues;
                              updatedObject.vehicle_type = newValue.name
                              setDocumentValues(updatedObject);
                            }}
                            // inputValue={inputVal}
                            // onInputChange={(event, newInputValue) => {
                            //   setinputVal(newInputValue);
                            // }}
                            onBlur={(e) => updatePreview(e)}
                            options={vehicleTypeOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Vehicle Type" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}>No Vehicle Types Found</h5>
                          </div>
                        )}
                      </div>
                      <div className="p-2 col-md-4">
                        { useTypeOptions && useTypeOptions.length > 0 ? (
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.use_type) ?? null}
                          name="use_type"
                          onChange={(event, newValue) => {
                            let updatedObject = documentValues;
                            updatedObject.use_type = newValue.name
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,["use_type"]:newValue.name});
                          }}
                          // inputValue={inputVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputVal(newInputValue);
                          // }}
                          onBlur={(e) => updatePreview(e)}
                          options={useTypeOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Primary Use Type" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}> No Primary Use Types Found</h5>
                          </div>
                        )}
                      </div>
                      <div className="p-2 col-md-4">
                      { fuelTypeOptions && fuelTypeOptions.length > 0 ? (
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.fuel_type) ?? null}
                          name="fuel_type"
                          onChange={(event, newValue) => {
                            let updatedObject = documentValues;
                            updatedObject.fuel_type = newValue.name
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,["fuel_type"]:newValue.name});
                          }}
                          // inputValue={inputVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputVal(newInputValue);
                          // }}
                          onBlur={(e) => updatePreview(e)}
                          options={fuelTypeOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Fuel Type" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}> No Fuel Types Found</h5>
                          </div>
                        )}
                      </div>
                    </div>


                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Model Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year_model"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year_model ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Model</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="model"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.model ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Trim</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="trim"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.trim ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Body Style</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="body_style"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.body_style ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                      { motoStyleOptions && motoStyleOptions.length > 0 ? (
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.moto_style) ?? null}
                          name="moto_style"
                          onChange={(event, newValue) => {
                            let updatedObject = documentValues;
                            updatedObject.moto_style = newValue.name
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,["moto_style"]:newValue.name});
                          }}
                          // inputValue={inputVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputVal(newInputValue);
                          // }}
                          onBlur={(e) => updatePreview(e)}
                          options={motoStyleOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Motorcycle Style" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}> No Motorcycle Styles Found</h5>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        { weightRatingOptions && weightRatingOptions.length > 0 ? (
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.gv_weight_rating) ?? null}
                          name="gv_weight_rating"
                          onChange={(event, newValue) => {
                            let updatedObject = documentValues;
                            updatedObject.gv_weight_rating = newValue.name
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,["gv_weight_rating"]:newValue.name});
                          }}
                          // inputValue={inputVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputVal(newInputValue);
                          // }}
                          onBlur={(e) => updatePreview(e)}
                          options={weightRatingOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="GV weight rating" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}> No GV weight rating Found</h5>
                          </div>
                        )}
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Scale wt</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="scale_wt"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.scale_wt ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Gross weight</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="gross_weight"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.gross_weight ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Mo GWT</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="moto_gwt"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.moto_gwt ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Seats</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seats"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seats ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Trl axles</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="trl_axels"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.trl_axels ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        { colorOptions && colorOptions.length > 0 ? (
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (documentValues.color_1) ?? null}
                            name="color_1"
                            //double check this bit... not sure if i converted value update correctly
                            onChange={(event, newValue) => {
                              let updatedObject = documentValues;
                              updatedObject.color_1 = newValue.name;
                              setDocumentValues(updatedObject);
                              // setDocumentValues({...documentValues,["color_1"]:newValue.name});
                            }}
                            // inputValue={inputVal}
                            // onInputChange={(event, newInputValue) => {
                            //   setinputVal(newInputValue);
                            // }}
                            onBlur={(e) => updatePreview(e)}
                            options={colorOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Color #1" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                          ):(
                            <div  className="text-center mx-auto">
                              <h5 style={{ color: "red" }}> No Color Options Found</h5>
                            </div>
                          )}
                      </div>
                      <div className="p-2 col-md-6">
                         { colorOptions && colorOptions.length > 0 ? (
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (documentValues.color_2) ?? null}
                            name="color_2"
                            onChange={(event, newValue) => {
                              let updatedObject = documentValues;
                              updatedObject.color_2 = newValue.name
                              setDocumentValues(updatedObject);
                              // setDocumentValues({...documentValues,["color_2"]:newValue.name});
                            }}
                            // inputValue={inputVal}
                            // onInputChange={(event, newInputValue) => {
                            //   setinputVal(newInputValue);
                            // }}
                            onBlur={(e) => updatePreview(e)}
                            options={colorOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Color #2" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                          ):(
                            <div  className="text-center mx-auto">
                              <h5 style={{ color: "red" }}> No Color Options Found</h5>
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Equipment Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="equipment_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.equipment_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Purchase Price</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchase_price"
                          onFocus={removeFormatting}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchase_price ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Wheels</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="wheels"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.wheels ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Rental Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="rental_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.rental_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Fleet</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="fleet_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.fleet_number ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Engine (MC)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="engine_mc"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.engine_mc ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Motor home/Cycle/WATV eng serial no</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="moto_etc_serial_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.moto_etc_serial_number ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Length</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="length"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.length ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Width</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="width"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.width ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Quick Title</label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup    
                            row                        
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="quick_title_toggle"
                            value={(documentValues.quick_title_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="no" control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Discover Pass</label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup     
                            row                       
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="discover_pass_toggle"
                            value={(documentValues.discover_pass_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="no" control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Park Donation</label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup 
                            row                           
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="park_donation_toggle"
                            value={(documentValues.park_donation_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="no" control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                    <h5>Section 1 - Registered Owner</h5>
                  
                    <div className="row">
                      {addressOptions && addressOptions.length > 0 ? (
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress1a && selectedAddress1a.name) ?? null}
                            name="wa-registered-owner"
                            onChange={(event, newValue) => {
                              setSelectedAddress1a(newValue);
                              AutoFillAddress(newValue, "wa-registered-owner");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Registered Owner" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>):(
                        <div  className="text-center mx-auto">
                          <h5 style={{ color: "red" }}> No Addresses Found</h5>
                        </div>
                      )}
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                          { ownerTypeOptions && ownerTypeOptions.length > 0 ? (
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (documentValues.owner_type) ?? null}
                            name="owner_type"
                            onChange={(event, newValue) => {
                              let updatedObject = documentValues;
                              updatedObject.owner_type = newValue.name
                              setDocumentValues(updatedObject);
                              // setDocumentValues({...documentValues,["owner_type"]:newValue.name});
                            }}
                            // inputValue={inputVal}
                            // onInputChange={(event, newInputValue) => {
                            //   setinputVal(newInputValue);
                            // }}
                            onBlur={(e) => updatePreview(e)}
                            options={ownerTypeOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Owner Type" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                          ):(
                            <div  className="text-center mx-auto">
                              <h5 style={{ color: "red" }}> No Owner Types Found</h5>
                            </div>
                          )}
                      </div>
                      <div className="p-2 col-md-6">
                       { idTypeOptions && idTypeOptions.length > 0 ? (
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.id_type) ?? null}
                          name="id_type"
                          onChange={(event, newValue) => {
                            let updatedObject = documentValues;
                            updatedObject.id_type = newValue.name
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,["id_type"]:newValue.name});
                          }}
                          // inputValue={inputVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputVal(newInputValue);
                          // }}
                          onBlur={(e) => updatePreview(e)}
                          options={idTypeOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="ID Type" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}> No ID Types Found</h5>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Drivers License* no</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="drivers_license_etc_no"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.drivers_license_etc_no ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Expiration Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="exp_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.exp_date ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                      { phoneTypeOptions && phoneTypeOptions.length > 0 ? (
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.phone_type) ?? null}
                          name="phone_type"
                          onChange={(event, newValue) => {
                            let updatedObject = documentValues;
                            updatedObject.phone_type = newValue.name
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,["phone_type"]:newValue.name});
                          }}
                          // inputValue={inputVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputVal(newInputValue);
                          // }}
                          onBlur={(e) => updatePreview(e)}
                          options={phoneTypeOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Phone Type" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}> No Phone Types Found</h5>
                          </div>
                        )}
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Phone Number</label>
                        <input
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          onFocus={removeFormatting}
                          className="form-control rounded-pill mt-1"
                          name="phone_num_s1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.phone_num_s1 ?? '' }
                          />
                      </div>
                    </div>
                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Registered owner full name (Last, First, Middle, Suffix) or Business name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_name_biz_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_name_biz_address ?? '' }
                          />
                      </div>
                    </div>

                    {/* <div className="row">
                      {addressOptions && addressOptions.length > 0 ? (
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress1 && selectedAddress1.name) ?? null}
                            name="wa-primary-address"
                            onChange={(event, newValue) => {
                              setSelectedAddress1(newValue);
                              AutoFillAddress(newValue, "wa-primary-address");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Washington primary residence address" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>):(
                        <div  className="text-center mx-auto">
                          <h5 style={{ color: "red" }}> No Addresses Found</h5>
                        </div>
                      )}
                    </div> */}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Washington primary residence address (if an individual) or Washington principal place of business address (if a business)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="wa_primary_res_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.wa_primary_res_address ?? '' }
                          />
                      </div>
                    </div>

                    <div className="row">
                    {addressOptions && addressOptions.length > 0 ? (
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress2 && selectedAddress2.name) ?? null}
                            name="mailing-address"
                            onChange={(event, newValue) => {
                              setSelectedAddress2(newValue);
                              AutoFillAddress(newValue, "mailing-address");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Mailing address, if different than residence address..." />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>):(
                    <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Addresses Found</h5>
                    </div>
                    )}
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Mailing address, if different than residence address (Street address or PO Box, City, State, ZIP code) or exception address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_address_s1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_address_s1 ?? '' }
                          />
                      </div>
                    </div>

                    <div className="row">
                    {addressOptions && addressOptions.length > 0 ? (
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress3 && selectedAddress3.name) ?? null}
                            name="one-time-address"
                            onChange={(event, newValue) => {
                              setSelectedAddress3(newValue);
                              AutoFillAddress(newValue, "one-time-address");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="One-time mailing address, if applicable." />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>):(
                    <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Addresses Found</h5>
                    </div>
                    )}
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">One-time mailing address, if applicable</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="one_time_mailing_s1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.one_time_mailing_s1 ?? '' }
                          />
                      </div>
                    </div>
                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Email Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="email_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.email_address ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Paperless renewal option</label>
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.notify_by_email_cb }
                              name="notify_by_email_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="Notify me by email when it’s time to renew my vehicle" />
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <hr></hr>


                  <div className="row">
                    <h5>Section 2 - Second Owner</h5>
                    <div className="row">
                      {addressOptions && addressOptions.length > 0 ? (
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress1b && selectedAddress1b.name) ?? null}
                            name="wa-second-owner"
                            onChange={(event, newValue) => {
                              setSelectedAddress1b(newValue);
                              AutoFillAddress(newValue, "wa-second-owner");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Second Owner" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>):(
                        <div  className="text-center mx-auto">
                          <h5 style={{ color: "red" }}> No Addresses Found</h5>
                        </div>
                      )}
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                         { ownerTypeOptions && ownerTypeOptions.length > 0 ? (
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (documentValues.owner_type_s2) ?? null}
                            name="owner_type_s2"
                            onChange={(event, newValue) => {
                              let updatedObject = documentValues;
                              updatedObject.owner_type_s2 = newValue.name
                              setDocumentValues(updatedObject);
                              // setDocumentValues({...documentValues,["owner_type_s2"]:newValue.name});
                            }}
                            // inputValue={inputVal}
                            // onInputChange={(event, newInputValue) => {
                            //   setinputVal(newInputValue);
                            // }}
                            onBlur={(e) => updatePreview(e)}
                            options={ownerTypeOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Owner Type" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                          ):(
                            <div  className="text-center mx-auto">
                              <h5 style={{ color: "red" }}> No Owner Types Found</h5>
                            </div>
                          )}
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Ownership-Joint tenants w/right...</label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="joint_tenants_toggle"
                            value={(documentValues.joint_tenants_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="no" control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div className="p-2 col-md-4">
                        { idTypeOptions && idTypeOptions.length > 0 ? (
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.id_type_s2) ?? null}
                          name="id_type_s2"
                          onChange={(event, newValue) => {
                            let updatedObject = documentValues;
                            updatedObject.id_type_s2 = newValue.name;
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,["id_type_s2"]:newValue.name});
                          }}
                          // inputValue={inputVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputVal(newInputValue);
                          // }}
                          onBlur={(e) => updatePreview(e)}
                          options={idTypeOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="ID Type" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}> No ID Types Found</h5>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Drivers License* No</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="drivers_license_etc_no_s2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.drivers_license_etc_no_s2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Expiration Date</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="exp_date_s2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.exp_date_s2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Phone Number</label>
                        <input
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          onFocus={removeFormatting}
                          className="form-control rounded-pill mt-1"
                          name="phone_num_s2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.phone_num_s2 ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Registered owner full name (Last, First, Middle, Suffix) or Business name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_name_biz_name_s2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_name_biz_name_s2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                    <h5>Section - Legal owner/Lienholder*</h5>

                    <div className="row">
                      {addressOptions && addressOptions.length > 0 ? (
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress1c && selectedAddress1c.name) ?? null}
                            name="wa-legal-lienholder"
                            onChange={(event, newValue) => {
                              setSelectedAddress1c(newValue);
                              AutoFillAddress(newValue, "wa-legal-lienholder");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Name of legal owner/lienholder" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>):(
                        <div  className="text-center mx-auto">
                          <h5 style={{ color: "red" }}> No Addresses Found</h5>
                        </div>
                      )}
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Name of legal owner/lienholder (Last, First, Middle initial or Business name)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="legal_owner_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.legal_owner_name ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                      { ownerTypeOptions && ownerTypeOptions.length > 0 ? (
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.legal_owner_type) ?? null}
                          name="legal_owner_type"
                          onChange={(event, newValue) => {
                            let updatedObject = documentValues;
                            updatedObject.legal_owner_type = newValue.name
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,["legal_owner_type"]:newValue.name});
                          }}
                          // inputValue={inputVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputVal(newInputValue);
                          // }}
                          onBlur={(e) => updatePreview(e)}
                          options={ownerTypeOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Legal Owner/Lienholder Type" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}> No Legal Owner/Lienholder Types Found</h5>
                          </div>
                        )}
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">ELT Participant</label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup           
                            row                 
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="elt_toggle"
                            value={(documentValues.elt_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="no" control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        { idTypeOptions && idTypeOptions.length > 0 ? (
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.legal_id_type) ?? null}
                          name="legal_id_type"
                          onChange={(event, newValue) => {
                            let updatedObject = documentValues;
                            updatedObject.legal_id_type = newValue.name;
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,["legal_id_type"]:newValue.name});
                          }}
                          // inputValue={inputVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputVal(newInputValue);
                          // }}
                          onBlur={(e) => updatePreview(e)}
                          options={idTypeOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="ID Type" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}> No ID Types Found</h5>
                          </div>
                        )}
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Drivers License* No</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="legal_drivers_etc_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.legal_drivers_etc_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Expiration Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="legal_exp_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.legal_exp_date ?? '' }
                          />
                      </div>
                    </div>

                    {/* <div className="row">
                    {addressOptions && addressOptions.length > 0 ? (
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress4 && selectedAddress4.name) ?? null}
                            name="legal-mailing-address"
                            onChange={(event, newValue) => {
                              setSelectedAddress4(newValue);
                              AutoFillAddress(newValue, "legal-mailing-address");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Mailing address (Street address or PO Box, City, State, ZIP code)" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>):(
                    <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Addresses Found</h5>
                    </div>
                    )}
                    </div> */}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Mailing address (Street address or PO Box, City, State, ZIP code)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="legal_mailing_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.legal_mailing_address ?? '' }
                          />
                      </div>
                    </div>

                  </div>
                  <div className="row">
                    <h5>Section - Dealer</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        { dealerTypeOptions && dealerTypeOptions.length > 0 ? (
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.dealer_type) ?? null}
                          name="dealer_type"
                          onChange={(event, newValue) => {
                            let updatedObject = documentValues;
                            updatedObject.dealer_type = newValue.name
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,["dealer_type"]:newValue.name});
                          }}
                          // inputValue={inputVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputVal(newInputValue);
                          // }}
                          onBlur={(e) => updatePreview(e)}
                          options={dealerTypeOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Dealer Type" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                        ):(
                          <div  className="text-center mx-auto">
                            <h5 style={{ color: "red" }}> No Dealer Types Found</h5>
                          </div>
                        )}
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Dealer No</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="dealer_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dealer_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Dealer Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="dealer_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dealer_name ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Sale Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="sale_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.sale_date ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Delivery Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="delivery_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.delivery_date ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Vehicle Status</label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup     
                            row                       
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="vehicle_status_toggle"
                            value={(documentValues.vehicle_status_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="new" control={<Radio />} label="New" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="used" control={<Radio />} label="Used" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="prev_title" control={<Radio />} label=" Prev Titled " />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>





                  </div>


                <hr></hr>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
            { saveSuccess ? (
              <span
                className="btn btn-success mx-auto"
              >
                Saved Document
              </span>
            ):(
              <button
              type="button"
              className="btn btn-primary mx-auto"
              // data-bs-dismiss="modal"
              onClick={handleSubmit}
              >
              Save
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default WAVehicleTitleApplication

import React from "react";

import Navbar from "../../../Components/Navbar/Navbar";
// import ConfigHeader from "./components/ConfigHeader";


const Config = () => {
  return (
    <div>
      <Navbar title="FAST Configuration" />
      <div>
        {/* <ConfigHeader /> */}
      </div>
    </div>
  );
};

export default Config;

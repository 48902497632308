import { useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import NumberFormat from 'react-number-format'

import moment from "moment";
import { Autocomplete, TextField, Modal, CircularProgress } from '@mui/material';

import { setActiveModal } from "../../../../../../redux/app/appSaver";

import FormError from "../../../../../../Components/commonElements/FormError";

import { updateCopySection, updateCopySubSection, updateStatus } from "../../../../../../redux/saveAndCopy"
import { addTestingTag } from "../../../../../../utlis/testingHelpers";

const DownpaymentModal = props => {

  const dispatch        = useDispatch();

  const{showDownPaymentSpinner}=useSelector(state=>state.spinners);

  const {
    editedOrDefaultDownpayment,
    editedDownpaymentErrors,
    formattedAmount,
    isEditMode,
    isOpen,
    onDocumentNumberChange,
    onDateReceivedChange,
    onAmountChange,
    onFormSubmit,
    onPaymentMethodChange,
    onStatusChange,
    onStatusInputChange,
    onCloseModal,
    isValidForSubmit,
    showSuccessFeedback,
    statusInputValue,
    hasUnsavedChanges,
    paymentMethods
  } = props;

  const appData = useSelector((state) => state?.appData);

  const [hasClickedClose, setHasClickedClose] = useState(false);

  function closeClicked(){
    if(hasUnsavedChanges){
      setHasClickedClose(true);
    }else{
      onCloseModal();
    }
  }

  const preCloseSave = e => {
    onFormSubmit(e)
    setHasClickedClose(false);
  }

  const preCloseClose = e => {
    setHasClickedClose(false);
    onCloseModal()
  }

  function getPaymentMethodObject(id){
    if(paymentMethods && paymentMethods.length > 0){
      return paymentMethods.find(item => item.id === id);
    }
    return null;
  }

  function prepRedux(){
    dispatch(updateCopySection('downpayment'));
    dispatch(updateCopySubSection('details'));
  }

  function prepDocumentCopy(){
    dispatch(updateStatus('copy'));
    dispatch(setActiveModal('copy-to-documents'));
  }

  return (
    <>
      
    { appData?.activeModal === 'downpayment' ? (
      <Modal
        style={{ overflow: 'scroll' }}
        open={ isOpen }
        onClose={ onCloseModal }
        aria-labelledby=""
          aria-describedby="">
          <div
            id="downpaymentsmodal"
            tabIndex="-1">
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header bg-primary text-white">
                  <h5
                    className="modal-title text-capitalize"
                    id="exampledownpaymentmodel"
                  >
                    { isEditMode ? 'Edit Down Payment' : 'Add Down Payment' }
                  </h5>
                  { hasClickedClose ? (
                    <div className="d-flex">
                      <p className="m-1">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" aria-label="save" data-testid="downpayment.control.saveYes" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" aria-label='cancel' data-testid="downpayment.control.saveNo" onClick={preCloseClose}>No</button>
                    </div>
                  ):(
                    <button
                      type="button"
                      className="btn-close bg-white"
                      // data-bs-dismiss="modal"
                      aria-label="Close"
                      data-testid="downpayment.control.close"
                      onClick={ closeClicked }
                    ></button>
                  )}
                </div>

                {/* MODLE BODY START HERE */}
                <div className="modal-body">
                  {/* <div className="d-flex">
                    {isValidForSubmit ? (
                    <button
                      type="button"
                      className={ "btn mx-auto "+(showSuccessFeedback ? "btn-success" : "btn-primary")}
                      onClick={ onFormSubmit } 
                      data-bs-dismiss="modal"
                    >
                      { showSuccessFeedback ? "Downpayment Saved" : "Save" }
                    </button>
                    ) : (
                      <button
                        type="button"
                        className="btn mx-auto btn-primary"
                        onClick={ onFormSubmit } 
                        data-bs-dismiss="">
                      Save
                    </button>
                    )}
                  </div> */}
                  <div className="px-3">
                    <div className="container fw-bold text-secondary my-3">
                      <label htmlFor="stock" className="mb-1 text-uppercase">STATUS</label>
                      <Autocomplete
                          value={ editedOrDefaultDownpayment.status }
                          onChange={ onStatusChange }
                          aria-label='down payment status'
                          inputValue={ statusInputValue }
                          onInputChange={ onStatusInputChange }
                          options={['Due At Delivery', 'Received']}
                          renderInput={(params) => <TextField {...addTestingTag(params, 'downpayment.status')} label="STATUS" />}
                      />
                      <FormError validate={ editedDownpaymentErrors.status }>
                        Status is required.
                      </FormError>
                    </div>
                    <div className="container fw-bold text-secondary my-3 ">
                      <label htmlFor="stock" className="mb-1 text-uppercase">AMOUNT</label>
                      <NumberFormat
                        className="form-control rounded-pill mt-1"
                        value={ formattedAmount }
                        name="amount"
                        aria-label='down payment amount'
                        thousandSeparator={true}
                        prefix={'$'}
                        data-testid="downpayment.amount"
                        onValueChange={(values) => {
                          const { formattedValue, value } = values;
                          onAmountChange(formattedValue, value)
                        }}
                      />
                      <FormError validate={ editedDownpaymentErrors.amount }>
                        Amount is required.
                      </FormError>
                    </div>
                    <div className="container fw-bold text-secondary my-3 ">
                      {/* <label htmlFor="stock" className="mb-1">PAYMENT METHOD</label> */}
                      {/* { (editedOrDefaultDownpayment && editedOrDefaultDownpayment.downPaymentMethod && editedOrDefaultDownpayment.downPaymentMethod !== "") ? (
                          <input
                          type="text"
                          className="form-control rounded-pill mt-1 text-uppercase"
                          name="paymentMethod"
                          // onChange={(e) => onPaymentMethodChange(e)}
                          disabled={1}
                          maxLength={50}
                          value={ editedOrDefaultDownpayment.downPaymentMethod }
                          />
                      ):null} */}
                      {/* <br /> */}
                      { paymentMethods && paymentMethods.length > 0 ? (
                        <Autocomplete
                          value={ getPaymentMethodObject(editedOrDefaultDownpayment.paymentMethodId)??null }
                          onChange={ onPaymentMethodChange }
                          options={paymentMethods??null}
                          aria-label='payment method'
                          getOptionLabel={ option => option.name }
                          renderInput={(params) => <TextField {...addTestingTag(params, 'downpayment.paymentMethodId')} label="PAYMENT METHOD" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                          />
                        ):null}
                      {/* <FormError validate={ editedDownpaymentErrors.paymentMethodId }>
                        Payment method is required.
                      </FormError> */}
                    </div>
                    <div className="container fw-bold text-secondary my-3 ">
                      <label htmlFor="type" className="mb-1">Document Number</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="documentNumber"
                        aria-label="document number"
                        maxLength={50}
                        onChange={(e) => onDocumentNumberChange(e)}
                        value={ editedOrDefaultDownpayment.documentNumber }
                        data-testid="downpayment.documentNumber"
                      />
                      <FormError validate={ editedDownpaymentErrors.documentNumber }>
                        Document number is required.
                      </FormError>
                    </div>
                    <div className="container fw-bold text-secondary my-3 ">
                      <label htmlFor="stock">Date Received</label>
                      <input
                        type="date"
                        className="form-control rounded-pill mt-1"
                        name="dateReceived"
                        aria-label='date received'
                        onChange={(e) => onDateReceivedChange(e)}
                        data-testid="downpayment.dateReceived"
                        value={ editedOrDefaultDownpayment.dateReceived ? moment.utc(editedOrDefaultDownpayment.dateReceived).format("YYYY-MM-DD") : "" }
                      />
                    </div>
                  </div>
                </div>

                {/* MODAL BODY END HERE */}

                <div className="modal-footer text-center">
                  <div className="mx-auto">
                  {showDownPaymentSpinner?<CircularProgress/>:(isValidForSubmit ? (
                    <>
                      <button
                        type="button"
                        className={ "btn mx-auto "+(showSuccessFeedback ? "btn-success" : "btn-primary")}
                        onClick={ onFormSubmit }
                        aria-label='submit' 
                        data-bs-dismiss="modal"
                        data-testid="downpayment.control.saveBottom"
                        >
                        { showSuccessFeedback ? "Downpayment Saved" : "Save" }
                      </button>

                      { showSuccessFeedback ? (
                        <>
                          <button aria-label='Copy to Documents' 
                            data-testid="downpayment.control.copyToDocuments"
                            onClick={ (e)=>{
                              e.preventDefault();
                              // ModalHelper.initalizeCopyToDocuments(ModalHelper.dataChangeArray);
                              // ModalHelper.handleClose();
                              prepRedux();
                              prepDocumentCopy();
                            }} data-bs-toggle="modal" data-bs-target="#copychangestodocuments" className="my-2 btn  btn-block btn-sm btn-primary text-uppercase mx-2">Copy to Documents</button><br />
                        </>
                      ):null}
    


                    </>
                    ) : (
                      <button
                        type="button"
                        className="btn mx-auto btn-primary"
                        onClick={ onFormSubmit } 
                        aria-label='save'
                        data-testid="downpayment.control.saveBottom"
                        data-bs-dismiss="">
                      Save
                    </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ):null}
    </>
  )
}

export default DownpaymentModal

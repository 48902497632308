import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";

export const SDPoAGlobalAutofill = (documentValues, order, property=null, value=null) => {

    var newValues = documentValues;
    
      newValues.type_toggle = "vehicle";
  
      if(property ==='customer.name'){
        newValues.name = value;
        newValues.appointed_name = value;
    }
      if(property ==='customer.cosigner[0].name'){
        if(value){
            newValues.name_2 = value;
            newValues.appointed_name_2 = value;
        }
    }
      if(property ==='customer.deliveryAddress.addressLine1'){
        newValues.appointed_address = value;
        if(value != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
          newValues.appointed_address +=" "+order.customer.deliveryAddress.addressLine2;
        }
    }
      if(property ==='customer.deliveryAddress.addressLine2'){
          newValues.appointed_address = order.customer.deliveryAddress.addressLine1+" "+value;
    }
      if(property ==='customer.deliveryAddress.city'){
        newValues.city = value;
        newValues.appointed_city = value;
    }
      if(property ==='customer.deliveryAddress.state'){
        newValues.appointed_state = value;
    }
      if(property ==='customer.deliveryAddress.zip'){
        newValues.appointed_zip_code = value;
    }
      if(property !== null && value !== null){
        return newValues;
    }

      if(order && order.customer && order.customer.name){
  
        newValues.name = order.customer.name;
        newValues.appointed_name = order.customer.name;
    
        if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner){
            newValues.name_2 = cosigner.name;
            newValues.appointed_name_2 = cosigner.name;
          }
        }
      }
      
      if(order && order.customer && order.customer.deliveryAddress){
        newValues.appointed_address = order.customer.deliveryAddress.addressLine1;
        if(order.customer.deliveryAddress.addressLine1 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
          newValues.appointed_address = newValues.appointed_address+" "+order.customer.deliveryAddress.addressLine2;
        }
        newValues.city = order.customer.deliveryAddress.city;
        newValues.appointed_city = order.customer.deliveryAddress.city;
        newValues.appointed_state = order.customer.deliveryAddress.state;
        newValues.appointed_zip_code = order.customer.deliveryAddress.zip;
      }
  
    return newValues
}

export const SDPoAAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
  var newValues = documentValues;

  if(property === 'year' && value !== null){
    newValues.year = (value).toString().trim().replace(/['"]+/g, '');
}

if(property === 'make' && value !== null){
    newValues.make = (value).toString().trim();
}

if(property === 'model' && value !== null){
    newValues.model = (value).toString().trim();
}

if(property !== null && value !== null){
    return newValues;
}

  newValues.year = (selectedProduct.year);
  newValues.make = (selectedProduct.make).toString().toUpperCase();
  newValues.model = (selectedProduct.model).toString().toUpperCase();
  newValues.vin_hin = (selectedProduct.vin).toString().toUpperCase();
  

  return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  if(payload.type_toggle){
    // Reset all
    payload.vehicle_selected = "no";
    payload.boat_selected = "no";

    switch(payload.type_toggle){
      case "vehicle":
        payload.vehicle_selected = "yes";
        break;
      case "boat":
        payload.boat_selected = "yes";
        break;
      default:
    }
  }

  if(payload.group_1_toggle){
    // Reset all
    payload.jointly_selected = "no";
    payload.serverlly_selected = "no";

    switch(payload.group_1_toggle){
      case "jointly":
        payload.jointly_selected = "yes";
        break;
      case "severally":
        payload.serverlly_selected = "yes";
        break;
      default:
    }
  }

  if(payload.group_2_toggle){
    // Reset all
    payload.group_2a_selected = "no";
    payload.group_2b_selected = "no";

    switch(payload.group_2_toggle){
      case "apply":
        payload.group_2a_selected = "yes";
        break;
      case "assign":
        payload.group_2b_selected = "yes";
        break;
      case "both":
        payload.group_2a_selected = "yes";
        payload.group_2b_selected = "yes";
        break
      default:
    }
  }

  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  // if(payload.date_of_sale){
  //   payload.date_of_sale = moment.utc(payload.date_of_sale).format("MM/DD/YY");
  // }

  // if(payload.date){
  //   payload.date = moment.utc(payload.date).format("MM/DD/YY");
  // }

  return payload;
}
import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";
import { salesPriceWithUpgradeCalc } from '../../../../../../../utlis/productCalculations.js';


export const CASection837SalesTaxAffidavitGlobalAutofill  = (documentValues, order, property=null, value=null, location) => {
  var newValues = documentValues;
  
  if(property?.includes('location') || property === null){

    if(location){
      if(location.state === "CA"){
        newValues.from_dealer = "TEC OF CALIFORNIA, INC";
        // newValues.delivery_dealer = "TEC OF CALIFORNIA, INC";
      }else{
        newValues.from_dealer = "TEC EQUIPMENT, INC";
        // newValues.delivery_dealer = "TEC EQUIPMENT, INC";
      }
  
      newValues.from_dealer_address = location.address+" - "+location.city+", "+location.state+", "+location.zip;
      // newValues.delivery_dealer_address = location.address+" - "+location.city+", "+location.state+", "+location.zip;
    }

  }

  if(property?.includes('documentationDate') || property === null){

    if(order.documentationDate){
      newValues.delivery_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }
  
  }

  return newValues;
}

const calcCASalesTax =(product,order) => {
  // Base Price
  let basePrice = salesPriceWithUpgradeCalc(product);

  let adminFee = order?.adminFee ? order?.adminFee : 0;

  let docFee = 0;
  if(product.lineItemFee){
    docFee = product.lineItemFee.docFee ? product.lineItemFee.docFee : 0;
  }
  return Number(basePrice)+Number(adminFee)+Number(docFee);
}

export const CASection837SalesTaxAffidavitAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {

  var newValues = documentValues;

  if(property?.includes('year') || property === null){
    newValues.year = (selectedProduct.year).trim();
  }
  if(property?.includes('make') || property?.includes('model') || property === null){
    newValues.make_model = (selectedProduct.make).trim()+" "+(selectedProduct.model).trim().toString().toUpperCase();
  }

  if(property?.includes('vin') || property === null){
    newValues.vin = (selectedProduct.vin);
  }

  if(property?.includes('stateRegistered') || property === null){
    newValues.vehicle_registered_state = selectedProduct.registrationAddress?.state;
  }

  if(property?.includes('price') || property === null){
    newValues.purchase_price = calcCASalesTax(selectedProduct);
  }


  if(property?.includes('shipTo') || property === null){

    if(selectedProduct.shipTo){

      var addressLine = '';
      if(selectedProduct.shipTo.addressLine2 != null && selectedProduct.shipTo.addressLine2 !== undefined && selectedProduct.shipTo.addressLine2 !== ''){
        addressLine = selectedProduct.shipTo.name+" "+(selectedProduct.shipTo.addressLine1+" "+selectedProduct.shipTo.addressLine2);
      }else{
        addressLine = selectedProduct.shipTo.name+" "+selectedProduct.shipTo.addressLine1;
      }

      addressLine = addressLine+" "+(selectedProduct.shipTo.city+", "+selectedProduct.shipTo.state+" "+selectedProduct.shipTo.zip);

      newValues.lessee_name_address = addressLine;
    }

  }

  return newValues;
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  if(payload.cbset_1 !== undefined || payload.cbset_1 !== null){
    payload.sales_tax_section_1_cb = "no";
    payload.sales_tax_section_2_cb = "no";
    switch(payload.cbset_1){
      case "6388":
        payload.sales_tax_section_1_cb = "yes";
        break;
      case "6388.5":
        payload.sales_tax_section_2_cb = "yes";
        break;
      default:
    }
  }else{
    delete payload.cbset_1;
  }

  if(payload.cbset_2 !== undefined || payload.cbset_2 !== null){
    payload.vehicle_truck_cb = "no";
    payload.vehicle_truck_tractor_cb = "no";
    payload.vehicle_trailer_cb = "no";
    payload.vehicle_semitrailer_cb = "no";
    payload.vehicle_trailer_coach_cb = "no";
    payload.vehicle_auxiliary_dolly_cb = "no";

    switch(payload.cbset_2){
      case "truck":
        payload.vehicle_truck_cb = "yes";
        break;
      case "truck_tractor":
        payload.vehicle_truck_tractor_cb = "yes";
        break;
      case "trailer":
        payload.vehicle_trailer_cb = "yes";
        break;
      case "semitrailer":
        payload.vehicle_semitrailer_cb = "yes";
        break;
      case "trailer_coach":
        payload.vehicle_trailer_coach_cb = "yes";
        break;
      case "auxiliary_dolly":
        payload.vehicle_auxiliary_dolly_cb = "yes";
        break;
      default:
    }
  }else{
    delete payload.cbset_2;
  }

  if(payload.cbset_3 !== undefined || payload.cbset_3 !== null){
    payload.moved_30_days_cb = "no";
    payload.moved_75_days_cb = "no";
    switch(payload.cbset_3){
      case "moved_30_days":
        payload.moved_30_days_cb = "yes";
        break;
      case "moved_75_days":
        payload.moved_75_days_cb = "yes";
        break;
      default:
    }
  }else{
    delete payload.cbset_3;
  }

  if(payload.cbset_4 !== undefined || payload.cbset_4 !== null){
    payload.lease_cb = "no";
    payload.no_lease_cb = "no";
    switch(payload.cbset_4){
      case "is":
        payload.lease_cb = "yes";
        break;
      case "is_not":
        payload.no_lease_cb = "yes";
        break;
      default:
    }
  }else{
    delete payload.cbset_4;
  }


  if(payload.cbset_5 !== undefined || payload.cbset_5 !== null){
    payload.purchaser_corportation_cb = "no";
    payload.purchaser_llc_cb = "no";
    payload.purchaser_partnership_cb = "no";
    payload.purchaser_sole_proprietor_cb = "no";

    switch(payload.cbset_5){
      case "corporation":
        payload.purchaser_corportation_cb = "yes";
        break;
      case "llc":
        payload.purchaser_llc_cb = "yes";
        break;
      case "partnership":
        payload.purchaser_partnership_cb = "yes";
        break;
      case "sole_proprietor":
        payload.purchaser_sole_proprietor_cb = "yes";
        break;
      default:
    }
  }else{
    delete payload.cbset_5;
  }

  if(payload.cbset_6 !== undefined || payload.cbset_6 !== null){
    payload.ca_resident_cb = "no";
    payload.non_ca_resident_cb = "no";

    switch(payload.cbset_6){
      case "ca_resident":
        payload.ca_resident_cb = "yes";
        break;
      case "non_ca_resident":
        payload.non_ca_resident_cb = "yes";
        break;
      default:
    }
  }else{
    delete payload.cbset_6;
  }

  if(payload.cbset_7 !== undefined || payload.cbset_7 !== null){
    payload.usage_outside_ca_cb = "no";
    payload.usage_outside_ca_with_icc_cb = "no";

    switch(payload.cbset_7){
      case "usage_outside_ca":
        payload.usage_outside_ca_cb = "yes";
        break;
      case "usage_outside_ca_with_icc":
        payload.usage_outside_ca_with_icc_cb = "yes";
        break;
      default:
    }
  }else{
    delete payload.cbset_7;
  }




  // X1.2- Field Type Specific Preview Formatting
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  // Data point formatting
  if(payload.delivery_date){
    payload.delivery_date = moment.utc(payload.delivery_date).format("MM/DD/YY");
  }

  if(payload.date_moved){
    payload.date_moved = moment.utc(payload.date_moved).format("MM/DD/YY");
  }

  if(payload.date){
    payload.date = moment.utc(payload.date).format("MM/DD/YY");
  }

  return payload;
}
import React, { useState, useEffect, useCallback } from "react";
import {
  useParams
} from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { formatter } from "../../../../../../../utlis/formatters";
import { useOrder } from "../../../../../../../redux/orders/order"
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { TECSoldAsIsGlobalAutofill, TECSoldAsIsAutofillProductDetails } from "../DocumentAutofill/TECSoldAsIs"


const TECSoldAsIs = ({document_id, location, getDocuments, passedDocumentSlug, setDocumentFocus }) => {
  const documentSlug = "tec-sold-as-is";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  // const dispatch = useDispatch();

  const order = useOrder();

  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  // const [globalAutofill, setGlobalAutofill] = useState(false);
  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);
  
  var initialDocumentStructure = {
    location_address: undefined, // Header Location Details / System Autofill
    location_phone: undefined, // Header Location Details / System Autofill
    location_email: undefined, // Header Location Details / System Autofill
    tec_footer_label: undefined, // Footer / System Autofill
    pg_no: undefined, // Footer / System Autofill

    vin: undefined,
    year: undefined,
    make: undefined,
    model: undefined,
    stock_number: undefined,
    city_state_zip: undefined,
    address: undefined,
    purchaser_name: undefined,
    zip: undefined,
    state: undefined,
    input_state: undefined,
    city: undefined,
    salesperson_date: undefined,
    purchaser_date: undefined,
    sale_odo_reading: undefined,
  }
  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  const handleDocumentValuesChange = e =>{
    e.preventDefault();
    const {name,value}=e.target;
    if(name.includes('vin')) {
      let newStr = value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
      newStr = newStr.length > 17 ? newStr.slice(0, -1) : newStr;
      setDocumentValues({...documentValues,[name]:newStr});
    } else if(name.includes('year')) {
        let newStr = value.replace(/[^0-9]/g,'');
        newStr = newStr.length > 4 ? documentValues[name].replace(/[^0-9]/g,'') : newStr;
        setDocumentValues({...documentValues,[name]:newStr});
    } else if(name.includes('serial_number') || name.includes('stock_number')) {
        let newStr = value.replace(/[^0-9]/g,'');
        setDocumentValues({...documentValues,[name]:newStr});
      } else if(name === 'model') {
        setDocumentValues({...documentValues,[name]:value.toString().toUpperCase()});
      } else if(name === 'sale_odo_reading') {
        // let val = value.replace(/[^a-zA-Z0-9]/g, '');
        setDocumentValues({...documentValues,[name]:value});
    } else if(name === 'zip') {
      let editzipVal = value.replace(/\D/g, '');
      editzipVal = editzipVal.length > 9 ? editzipVal.slice(0, -1) : editzipVal;
      setDocumentValues({...documentValues,[name]:editzipVal});
    } else {
        setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  
  const [documentLoaded, setDocumentLoaded] = useState(false);
    
const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('TECSoldAsIs.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);
  
  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, documentLoaded, loadDocument ])

  const [assignedProduct, setAssignedProduct] = useState(undefined);
  // const [productVal, setproductVal] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  // const [tradeinVal, setTradeinVal] = useState(undefined);
  // const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  // const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);

// Dropdowns //

  // if(order && order.products && order.products.length > 0 && productOptions === undefined){
  //   let getProductOptions = [];
  //   order.products.map(product => {
  //     // let str = `${product.year.replace('"', '').replace('"', '')} ${product.make} ${product.model.toString().toUpperCase()} - ${product.stockNumber.toString().toUpperCase()}`;
  //     let str = `${product.stockNumber.toString().toUpperCase()}`;
  //     let obj = {id: product.id, name: str}
  //     getProductOptions.push(obj);
  //   })
  //   setproductOptions(getProductOptions);
  // }

  // if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
  //   let getTradeinOptions = [];
  //   order.tradeins.map(tradein => {
  //     let str = `${tradein.year} ${tradein.make} ${tradein.model}`;
  //     let obj = {id: tradein.id, name: str}
  //     getTradeinOptions.push(obj);
  //   })
  //   setTradeinOptions(getTradeinOptions);
  // }

 // Autofill //

 const GlobalAutofill = useCallback(async () => {
  let newValues = TECSoldAsIsGlobalAutofill(documentValues, order, null, null, location);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }


  // var newValues = documentValues;
  //   if(order && order.customer && order.customer.name){
  //     newValues.purchaser_name = order.customer.name;

      
  //     if(order.customer.dba){
  //       newValues.purchaser_name += " dba "+order.customer.dba;
  //     }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
  //       let cosigner = order.customer.cosigners[0];
  //       if(cosigner && cosigner.name){
  //         newValues.purchaser_name += " AND "+cosigner.name;
  //       }
  //     }
  //     // setDocumentValues({...documentValues,['purchaser_name']:order.customer.name});
  //   }

  //   if(order && order.customer && order.customer.deliveryAddress){
  //     newValues.address = (order.customer.deliveryAddress.addressLine1+" "+(order.customer.deliveryAddress.addressLine2 ?? ''));
  //     //   setDocumentValues({...documentValues,['address']:(order.customer.deliveryAddress.addressLine1+" "+(order.customer.deliveryAddress.addressLine2 ?? ''))});
  //   }

  //   if(order && order.customer && order.customer.deliveryAddress){
  //     newValues.city_state_zip = (order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
  //     // setDocumentValues({...documentValues,['city_state_zip']:(order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip)});
  //   }

  //   if(order && order.customer && order.customer.deliveryAddress){
  //     newValues.city = (order.customer.deliveryAddress.city);
  //     // setDocumentValues({...documentValues,['city']:(order.customer.deliveryAddress.city)});
  //   }

  //   if(order && order.customer && order.customer.deliveryAddress){
  //     newValues.state = (order.customer.deliveryAddress.state);
  //     // setDocumentValues({...documentValues,['state']:(order.customer.deliveryAddress.state)});
  //   }

  //   if(order && order.customer && order.customer.deliveryAddress){
  //     newValues.zip = (order.customer.deliveryAddress.zip);
  //     // setDocumentValues({...documentValues,['zip']:(order.customer.deliveryAddress.zip)});
  //   }

    // Get Location Address & Autofill Address
    if(location !== undefined){
      newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
      // setDocumentValues({...documentValues,['location_address']:location.address+" "+location.city+" "+location.state+" "+location.zip});
    }
  
    if(location && location.phone){
      newValues.location_phone = location.phone;
      // setDocumentValues({...documentValues,['location_phone']:location.phone});
    }
    
    if(location && location.email){
      newValues.location_email = location.email;
      // setDocumentValues({...documentValues,['location_email']:location.email});
    }
  
    if(location){
      if(location.state === "CA"){
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
        // setDocumentValues({...documentValues,['tec_footer_label']:"TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT"});
      }else{
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
        // setDocumentValues({...documentValues,['tec_footer_label']:"TECEQUIPMENT.COM | TEC EQUIPMENT, INC"});
      }
    }

    // if(order && order.documentationDate && documentValues.delivery_date === undefined){
    //   newValues.delivery_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    // }

    // if(order && order.documentationDate && documentValues.delivery_date_2 === undefined){
    //   newValues.delivery_date_2 = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    // }

    // newValues.pg_no = "PG #1";
    
    setDocumentValues(newValues);
    // setHasUnsavedChanges(true);
    // setGlobalAutofill(false);
  },[ documentValues, order, location ])

  const previewPayloadDocument = useCallback(async (documentValues) => {
    var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });

    if(payload.delivery_date){
      payload.delivery_date = moment.utc(payload.delivery_date).format("MM/DD/YY");
    }

    if(payload.delivery_date_2){
      payload.delivery_date_2 = moment.utc(payload.delivery_date_2).format("MM/DD/YY");
    }

    if(document){

      var docCopy = JSON.parse(JSON.stringify(document));
      docCopy.payload =  JSON.stringify(payload);
      delete docCopy.documentStatus;
      
      // return axios.put(`/document/${document.id}/prepare`, docCopy);  
      // return axios.post(`/document/${document.id}/preview-payload`, docCopy);  
      try {
        let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
        setdocumentUrl(fileURL)
      } catch (err) {
        recordFrontEndError('TECSoldAsIs.jsx', 'Error previewing document payload: ' + err)
      };  
    }
  },[ axios, document ])
  
  const AutofillProductDetails = useCallback(async (selectedProduct) => {
    let newValues = TECSoldAsIsAutofillProductDetails(selectedProduct, documentValues);

    if(newValues){
      setDocumentValues(newValues);
      setHasUnsavedChanges(true);
      previewPayloadDocument(documentValues);
      setSaveSuccess(false);
    }
  

    // var newValues = documentValues;
    // newValues.year = (selectedProduct.year).trim().replace('"', '').replace('"', '');
    // newValues.make = (selectedProduct.make).trim().toString().toUpperCase();
    // newValues.model = (selectedProduct.model).trim().toString().toUpperCase();
    // newValues.stock_number = (selectedProduct.stockNumber);
    // newValues.mileage = (selectedProduct.mileage);
    // newValues.serial_number = (selectedProduct.serialNumber);
    // newValues.vin = (selectedProduct.vin); setDocumentValues(newValues);

    // previewPayloadDocument(documentValues);
    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  },[ documentValues, previewPayloadDocument ]);

  const previewDocument = useCallback(async () => {

    if(document && document.id){
      try {
        let response = await axios.get(`/document/${document.id}/stream`,   {responseType: 'blob'})
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
        setdocumentUrl(fileURL)
      } catch (err) {
        recordFrontEndError('TECSoldAsIs.jsx', 'Error previewing document: ' + err)
      }
    }
  },[ axios, document ]);

useEffect(() => {
if(document && document.payload && !dataLoaded){
  var existingPayload = false;
  var documentPayload = JSON.parse(document.payload);
  if(documentPayload && Object.keys(documentPayload).length > 0){
    existingPayload = true;

    if(documentPayload.delivery_date){
      documentPayload.delivery_date = moment.utc(documentPayload.delivery_date).format("YYYY-MM-DD");
    }

    if(documentPayload.delivery_date_2){
      documentPayload.delivery_date_2 = moment.utc(documentPayload.delivery_date_2).format("YYYY-MM-DD");
    }

    setDocumentValues(documentPayload);
  }

    var foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }

    if(foundAssignedProduct){
      setAssignedProduct(foundAssignedProduct);
    }

    // setAddressOptions(getOrderLevelAddresses(order));
    if(!existingPayload){
      GlobalAutofill();
      previewPayloadDocument(documentValues);
      if(foundAssignedProduct){
        AutofillProductDetails(foundAssignedProduct)
      }
    }else{
      // if(document && document.itemIds){
      //   var itemIds = document.itemIds.split(',');
      //     var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
      //     let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
      //     let obj = {id: foundProduct.id, name: str}
      //     setproductVal(obj);
      //     // if(foundProduct){
      //     //   setAddressOptions(updateAddressOptions(foundProduct, order));
      //     // }
      // }

      previewDocument();
    }
    
    setDataLoaded(true);
  }
},[ documentValues, document, dataLoaded, order, AutofillProductDetails, GlobalAutofill, previewDocument, previewPayloadDocument ])
  
  // Document BREAD + Preview // TODO: Move to Redux
  



  // function previewDocument(){


  // Component Events

  const updatePreview = e => {
    e.preventDefault();
    previewPayloadDocument(documentValues);
    // saveDocument().then(r => { 
    //   regenerateDocument().then(res => {
    //     streamDocument()
    //   })
    // })
  }


  const handleSubmit = async event => {
    event.preventDefault();


    let payload = JSON.parse(JSON.stringify(documentValues));

    DocumentHandleSubmit(document, payload, null, () => {
      getDocuments();
      setSaveSuccess(true);
      setHasUnsavedChanges(false);
    });

    // var docCopy = document;
    // docCopy.payload = payloadString;
    // delete docCopy.documentStatus;
    
    // if(selectedProduct && selectedProduct.id){
    //   docCopy.itemIds = `${selectedProduct.id}`;
    // }
    // try {
    //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
    //   if (res){
    //     await axios.post(`/document/${document.id}/generate`) 
    //     getDocuments();
    //     setSaveSuccess(true);
    //     setHasUnsavedChanges(false);
    //   };
    // } catch (err) {
    //   recordFrontEndError('TECSoldAsIs.jsx', 'Error saving document: ' + err)
    // };
    
  };


///////////////////////////

const onCloseClick = e => {
  e.preventDefault()
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setdocumentUrl("");
  setDocument(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
  setDocumentLoaded(false);
  setDataLoaded(false);
  setAssignedProduct(undefined);
  // setproductVal(undefined);
  setDocumentFocus(undefined);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

return (
    <>
      <div
        className="modal fade"
        // id="document-edit-modal"
        id="tec-sold-as-is"
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                TEC Sold As Is
              </h5>
              <br />
              { hasClickedClose ? (
                <div className="d-flex">
                    <p className="m-1 pt-1 text-white">Save Changes?</p>
                    <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                    <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                </div>
              ): hasUnsavedChanges ? (
              <button
                type="button"
                className="btn-close bg-white"
                aria-label="Close"
                // onClick={() => setdocumentUrl("")}
                onClick={closeClicked}
              ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill();
                      AutofillProductDetails(assignedProduct);
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>

                  { assignedProduct ? (
                  <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                    Product Snapshot
                    <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                      <h5>SN: { assignedProduct.stockNumber }</h5>
                      <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                      <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                    </div>
                  </div>
                  ):(
                    <div className="m-2 p-2 border">
                      <span>No Assigned Product</span>
                    </div>
                  )}

                  <h5>Vehicle Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Model</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="model"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.model ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Stock Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="stock_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.stock_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Purchaser Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_name ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Purchaser Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.address ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Purchaser City, State, Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="city_state_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.city_state_zip ?? '' }
                          />
                      </div>
                    </div>
                     <div className="d-flex justify-content-evenly">
                       <div className="col-md-12">
                         <label htmlFor="stock">ODO Reading at Time of Sale</label>
                         <input
                           type="text"
                           className="form-control rounded-pill mt-1"
                           name="sale_odo_reading"
                           onChange={handleDocumentValuesChange}
                           onBlur={(e) => {
                             let val = documentValues['sale_odo_reading'].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                             let updatedObject = documentValues;
                             updatedObject.sale_odo_reading = val;
                             setDocumentValues(updatedObject);
                            //  setDocumentValues({...documentValues,['sale_odo_reading']:val});
                             updatePreview(e);
                             }
                           }
                           value={ documentValues.sale_odo_reading ?? '' }
                           />
                       </div>
                     </div>
                  <hr></hr>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
            { saveSuccess ? (
              <span
                className="btn btn-success mx-auto"
              >
                Saved Document
              </span>
            ):(
              <button
              type="button"
              className="btn btn-primary mx-auto"
              // data-bs-dismiss="modal"
              onClick={handleSubmit}
              >
              Save
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default TECSoldAsIs

import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const TECDepositReceiptGlobalAutofill = (documentValues, order, property=null, value=null, location, locations=null, salesReps) => {

    function getRepByEmployeeId(employeeId){
      if(salesReps && salesReps.length > 0){
        let foundRep = salesReps.find(rep => rep.employee_ID === employeeId);
        if(foundRep){
          return foundRep.preferredName ? foundRep.preferredName+" "+foundRep.lastName : foundRep.firstName+" "+foundRep.lastName;
        }
      }
      return "";
    }

    var newValues = documentValues;
    if(order && order.customer && order.customer.name){
      newValues.purchaser_name = order.customer.name;
      if(order.customer.dba){
        newValues.purchaser_name += " dba "+order.customer.dba;
      }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          newValues.purchaser_name += " AND "+cosigner.name;
        }
      }
    }

    if(order && order.customer && order.customer.phone){
      newValues.purchaser_phone = order.customer.phone;
    }

    if(order && order.customer && order.customer.email){
      newValues.purchaser_email = order.customer.email;
    }


    if(order && order.customer && order.customer.deliveryAddress){
      var newAddr = '';
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== ''){
        newAddr = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
      }else{
        newAddr = order.customer.deliveryAddress.addressLine1;
      }
      newValues.purchaser_address = newAddr;
    }

    if(order && order.customer && order.customer.deliveryAddress){
      newValues.purchaser_city_state_zip = (order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
    }

    if(order && order.salesRepId){
      newValues.sales_person = getRepByEmployeeId(order.salesRepId);
    }


    if(order && order.documentationDate ){
      newValues.estimated_delivery_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }
    
    // Get Location Address & Autofill Address
    if(location !== undefined){
      newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
    }
  
    if(location && location.phone){
      newValues.location_phone = location.phone;
    }
    
    if(location && location.email){
      newValues.location_email = location.email;
    }
  
    if(location){
      if(location.state === "CA"){
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
      }else{
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
      }
    }

    return newValues
}


export const TECDepositReceiptAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
  var newValues = documentValues;

  // if(property === 'year' && value !== null){
  //   newValues.vehicle_year = (value).toString().trim().replace(/['"]+/g, '');
  // }

  // if(property === 'make' && value !== null){
  //     newValues.vehicle_make = (value).toString().trim();
  // }

  // if(property === 'model' && value !== null){
  //     newValues.vehicle_model = (value).toString().trim();
  // }

  // if(property === 'vehicleType' && value !== null){
  //     newValues.vehicle_type = (value);
  // }

  // if(property === 'vin' && value !== null){
  //   newValues.serial_number = (value);
  // }

  // if(property === 'stockNumber' && value !== null){
  //   newValues.stock_number = (value);
  // }

  // if(property !== null && value !== null){
  //     return newValues;
  // }

  if(property === 'year' || property === null){
    newValues.vehicle_year = (selectedProduct.year).trim().replace('"','').replace('"','');
  }

  if(property === 'make' || property === null){
    newValues.vehicle_make = (selectedProduct.make).trim();
  }

  if(property === 'model' || property === null){
    newValues.vehicle_model = (selectedProduct.model).trim().toUpperCase();
  }
  if(property === 'stockNumber' || property === null){
    newValues.stock_number = (selectedProduct.stockNumber).toUpperCase();
  }
  if(property === 'vin' || property === null){
    newValues.serial_number = (selectedProduct.vin).toUpperCase();
  }

  if(property === 'vehicleType' || property === null){
    newValues.vehicle_type = (selectedProduct.vehicleType);
  }

  if(property === 'qty' || property === null){
    newValues.vehicle_quantity = 1;
  }

  return newValues
}

export const TECDepositReceiptAutofillDeposit =( selectedDeposit, documentValues, property=null, value=null)=>{
  var newValues = documentValues;
  
  if(property ==='deposit.amount'){
    newValues.total = value;
  }

  if(property ==='deposit.paymentMethodName'){
    newValues.payment_type = value;
  }

  if(property ==='deposit.documentNumber'){
    newValues.document_number = value;
  }

  if(property !== null && value !== null){
    return newValues;
  }
  
  newValues.total = formatter.format(selectedDeposit.amount);
  newValues.payment_type = (selectedDeposit.paymentMethodObj.name).trim();
  newValues.document_number = (selectedDeposit.documentNumber).trim();


  return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

    
  // if(payload.q1_toggle){
    //   // Reset all
    //   payload.q_1_true_cb = "no";
    //   payload.q_1_false_cb = "no";
    
    //   switch(payload.q1_toggle){
      //     case "true":
      //       payload.q_1_true_cb = "yes";
      //       break;
      //     case "false":
      //       payload.q_1_false_cb = "yes";
      //       break;
      //     default:
      //   }
      // }
    
  // X1.2- Field Type Specific Preview Formatting
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  // Data point formatting
  if(payload.estimated_delivery_date){
    payload.estimated_delivery_date = moment.utc(payload.estimated_delivery_date).format("MM/DD/YY");
  }
    
  return payload;
}
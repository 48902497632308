import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
// import {salesPriceWithUpgradeCalc} from "../../../../../../../utlis/productCalculations";
import { removeChars, formatter, formatPhoneNumber, getAreaCode, getPhoneNumberAlt, formatUpperCase } from "../../../../../../../utlis/formatters";
import { getOrderLevelAddresses, updateAddressOptions } from "../../../../../../../utlis/documentHelpers";
import { useOrder } from "../../../../../../../redux/orders/order"
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { CASalesTaxStatement6247GlobalAutofill, CASalesTaxStatement6247AutofillProductDetails, preProcessPayload } from "../DocumentAutofill/CASalesTaxStatement6247"

const CASalesTaxStatement6247 = ({document_id, location, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "ca-sales-tax-statement";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  // const dispatch = useDispatch();

  // const stateAbbrev = States.map(state => {
  //   return state.abbreviation;
  // });

  const order = useOrder();

  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  const [document, setDocument] = useState(undefined);
  const [previousDocumentId, setPreviousDocumentId] = useState(0);


  // Data Structure & Init //
  
  var initialDocumentStructure = {
    ca_address: undefined,
    ca_address_city: undefined,
    ca_state: undefined,
    ca_zip: undefined,
    mailing_address: undefined,
    mailing_city: undefined,
    mailing_state: undefined,
    mailing_zip: undefined,
    usage_address: undefined,
    usage_city: undefined,
    usage_state: undefined,
    usage_zip: undefined,
    delivery_address: undefined,
    delivery_city: undefined,
    deliery_state: undefined, // Miss spelled
    delivery_zip: undefined,
    delivery_id_number: undefined,
    purchaser_name: undefined,
    state: undefined,
    area_code: undefined,
    phone: undefined,
    date: undefined,
    year: undefined,
    make: undefined,
    model: undefined,
    purchase_price: undefined,
    purchase_date: undefined,
    vin: undefined,
    seller_name: undefined,
    seller_address: undefined,
    seller_cdtfa_number: undefined,
    seller_area_code: undefined,
    seller_phone: undefined,
    seller_state: undefined,
    seller_zip: undefined,
    seller_signature_date: undefined,
    seller_agent_name: undefined,
    seller_city: undefined
  }
  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  // X1
  const removeFormatting = e =>{
    const {name,value}=e.target;
    setDocumentValues({...documentValues,[name]:removeChars(value)});
  }

  // X1 Reference Arrays - TODO: Possibly move these to back end as part of the template stucture
  const moneyFields = useMemo(()=>{return["purchase_price"]},[]);
  const phoneFields = useMemo(()=>{return[]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "model"]},[]);
  const checkboxes = useMemo(()=>{return[]},[]);

  // X1- Current General On Change
  const handleDocumentValuesChange = e =>{
    e.preventDefault();
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatPhoneNumber(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  
  const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try{
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('CASalesTaxStatement6247.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, documentLoaded, loadDocument ])
  const [assignedProduct, setAssignedProduct] = useState(undefined);
  // const [productVal, setproductVal] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  // const [tradeinVal, setTradeinVal] = useState(undefined);
  // const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  // const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);

  const [selectedAddress1, setSelectedAddress1] = useState(undefined);
  const [selectedAddress2, setSelectedAddress2] = useState(undefined);
  const [selectedAddress3, setSelectedAddress3] = useState(undefined);
  const [selectedAddress4, setSelectedAddress4] = useState(undefined);

  const [addressOptions, setAddressOptions] = useState(undefined);
  const [inputAddressVal, setinputAddressVal] = useState(undefined);

  const [locationVal, setLocationVal] = useState(undefined);
  const [selectedLocation, setselectedLocation] = useState(undefined);
  const [inputLocationVal, setinputLocationVal] = useState(undefined);
  const [locationOptions, setLocationOptions] = useState(undefined);
  const [locationToggleAutoFill, setLocationToggleAutoFill] = useState(false);


// Dropdowns //

  if(locations && locations.length > 0 && locationOptions === undefined){
    let getLocationOptions = [];
    locations.map(location => {
      let str = `${location.description}`;
      let obj = {locationCode: location.locationCode, description: str}
      getLocationOptions.push(obj);
      return null;
    })
    setLocationOptions(getLocationOptions);
  }

  // Autofill //
  // const preProcessPayload = useCallback((payload) => {

  //   // Field Type Specific Preview Formatting
  //   Object.keys(payload).forEach(key => {
  //     if(moneyFields.indexOf(key) >= 0){
  //         payload[key] = formatMoneyValue(payload[key]);
  //     }else if(phoneFields.indexOf(key) >= 0){
  //         payload[key] = formatPhoneNumber(payload[key]);
  //     }else if(upperCaseFields.indexOf(key) >= 0){
  //         payload[key] = formatUpperCase(payload[key]);
  //     }
  //   });
  //   setDocumentValues(JSON.parse(JSON.stringify(payload)));
  //   Object.keys(payload).forEach(key => {
  //     if(checkboxes.indexOf(key) >= 0){
  //       payload[key] = payload[key] ? "yes": "no";
  //     }
  //   });

  //   // Data point formatting
  //   if(payload.purchase_date){
  //     payload.purchase_date = moment.utc(payload.purchase_date).format("MM/DD/YY");
  //   }
  
  //   if(payload.seller_signature_date){
  //     payload.seller_signature_date = moment.utc(payload.seller_signature_date).format("MM/DD/YY");
  //   }

  //   if(payload.date){
  //     payload.date = moment.utc(payload.date).format("MM/DD/YY");
  //   }

  //   return payload;
  // },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])


  const previewPayloadDocument = useCallback(async (documentValues) => {
    var payload = documentValues;
      Object.keys(payload).forEach(key => {
        if (payload[key] === null || payload[key] === undefined) {
          delete payload[key];
        }
      });

      if(document){
        var docCopy = JSON.parse(JSON.stringify(document));
        docCopy.payload =  JSON.parse(JSON.stringify(payload));
        docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
        docCopy.payload =  JSON.stringify(docCopy.payload);
        delete docCopy.documentStatus;

        try {
          let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
          const file = new Blob(
            [response.data], 
            {type: 'application/pdf'});
          const fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
          setdocumentUrl(fileURL)
        } catch (err) {
          recordFrontEndError('CASalesTaxStatement6247.jsx', ' Error previewing document payload: ' + err)
        };  
      }
    },[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])

  const GlobalAutofill = useCallback(async () => {
    const foundAssignedProduct = order?.products.find((product) => product?.id === document?.assignedObjectId);
    //if FOB address exists, set it as default for autofill, otherwise keep default as delivery address
    const defaultAddress = foundAssignedProduct?.fobAddress?.addressLine1 ? foundAssignedProduct?.fobAddress : null;
    let newValues = CASalesTaxStatement6247GlobalAutofill(documentValues, order, null, null, location);

    
    if(defaultAddress !== null){
      let streetAddress;

      if(defaultAddress?.addressLine2){
        streetAddress = (defaultAddress?.addressLine1+" "+defaultAddress.addressLine2);
      }else{
        streetAddress = defaultAddress?.addressLine1;
      }

      setSelectedAddress1({slug: "fob", name: "FOB Address", type: order.customer.type, label: "FOB Address", address: streetAddress, city: defaultAddress?.city, state: defaultAddress?.state, zip: defaultAddress?.zip});

      newValues.ca_address = streetAddress;
      newValues.ca_address_city = defaultAddress?.city;
      newValues.ca_state = defaultAddress?.state;
      newValues.ca_zip = defaultAddress?.zip;
    }

    if(newValues){
      setDocumentValues(newValues);
      setHasUnsavedChanges(true);
      setSaveSuccess(false);
    }



    // var newValues = documentValues;

    // const TEC_TAX_ID_NUMBER = "91-1757479";

    // if(order && order.customer){
    //   newValues.purchaser_name = order.customer.name;
    //   newValues.area_code = getAreaCode(order.customer.phone);
    //   newValues.phone = getPhoneNumber(order.customer.phone);
    // }
    
    // if(order.customer.dba){
    //   newValues.purchaser_name += " dba "+order.customer.dba;
    // }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
    //   let cosigner = order.customer.cosigners[0];
    //   if(cosigner && cosigner.name){
    //       newValues.purchaser_name += " AND "+cosigner.name;
    //   }
    // }

    // if(order && order.customer && order.customer.deliveryAddress){
    //   var addressLineDelivery = '';
    //   if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
    //     addressLineDelivery = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
    //   }else{
    //     addressLineDelivery = order.customer.deliveryAddress.addressLine1;
    //   }
    //   newValues.ca_address = addressLineDelivery;
    //   newValues.ca_address_city = order.customer.deliveryAddress.city;
    //   newValues.ca_state = order.customer.deliveryAddress.state;
    //   newValues.ca_zip = order.customer.deliveryAddress.zip;
    // }

    // if(order && order.customer && order.customer.mailingAddress){
    //   var addressLineMailing = '';
    //   if(order.customer.mailingAddress.addressLine2 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
    //     addressLineMailing = (order.customer.mailingAddress.addressLine1+" "+order.customer.mailingAddress.addressLine2);
    //   }else{
    //     addressLineMailing = order.customer.mailingAddress.addressLine1;
    //   }
    //   newValues.usage_address = addressLineMailing;
    //   newValues.mailing_city = order.customer.mailingAddress.city;
    //   newValues.mailing_state = order.customer.mailingAddress.state;
    //   newValues.mailing_zip = order.customer.mailingAddress.zip;
    // }

    // if(location && location.state){
    //   // newValues.agency_dealership = "TEC EQUIPMENT, INC.";
    //   newValues.delivery_address = location.address;
    //   newValues.delivery_city = location.city;
    //   newValues.deliery_state = location.state;
    //   newValues.delivery_zip = location.zip;

    //   if(location.state === "CA"){
    //     newValues.seller_name = "TEC OF CALIFORNIA, INC";
    //   }else{
    //     newValues.seller_name = "TEC EQUIPMENT, INC.";
    //   }

    //   if(location.state && location.state === "CA"){
    //     newValues.seller_cdtfa_number = TEC_TAX_ID_NUMBER;
    //   }

    //   newValues.seller_address = location.address;
    //   newValues.seller_city = location.city;
    //   newValues.seller_state = location.state;
    //   newValues.seller_zip = location.zip;
    //   newValues.seller_area_code = getAreaCode(location.phone);
    //   newValues.seller_phone = getPhoneNumberAlt(location.phone);
    // }

    // if(order && order.documentationDate){
    //   newValues.seller_signature_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    //   newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    //   newValues.purchase_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    // }

    // setDocumentValues(newValues);
    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  },[ documentValues, order, location, document ])

  const AutofillProductDetails = useCallback(async (selectedProduct) => {
    let newValues = CASalesTaxStatement6247AutofillProductDetails(selectedProduct, documentValues, null, null, location, order);

    if(newValues){
      setDocumentValues(newValues);
      previewPayloadDocument(documentValues);
      setHasUnsavedChanges(true);
      if(document){
        previewPayloadDocument(documentValues);
      }
      setSaveSuccess(false);
    }

    // var newValues = documentValues;
    // newValues.year = (selectedProduct.year).trim();
    // newValues.make = (selectedProduct.make).trim();
    // newValues.model = (selectedProduct.model).trim().toString().toUpperCase();
    // newValues.vin = (selectedProduct.vin);

    // if(location.state && location.state === "AZ"){
    //   newValues.purchase_price = salesPriceWithUpgradeCalc(selectedProduct);
    //   if(order.adminFee){
    //     newValues.purchase_price += Number(order.adminFee);
    //   }
    //   if(selectedProduct.lineItemFee && selectedProduct.lineItemFee.docFee){
    //     newValues.purchase_price += Number(selectedProduct.lineItemFee.docFee);
    //   }
    // }


    // setDocumentValues(newValues);
    // // setProductToggleAutoFill(false);
    // if(document){
    //   previewPayloadDocument(documentValues);
    // }
    // // setAddressOptions(updateAddressOptions(selectedProduct, order));
    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  },[document, documentValues, location, order, previewPayloadDocument]);

  // Document BREAD + Preview // TODO: Move to Redux
  useEffect(() => {
    if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){
  
      existingPayload = true;
  
      if(documentPayload.seller_signature_date){
        documentPayload.seller_signature_date = moment.utc(documentPayload.seller_signature_date).format("YYYY-MM-DD");
      }
      
      if(documentPayload.date){
        documentPayload.date = moment.utc(documentPayload.date).format("YYYY-MM-DD");
      }

      if(documentPayload.purchase_date){
        documentPayload.purchase_date = moment.utc(documentPayload.purchase_date).format("YYYY-MM-DD");
      }
  
      setDocumentValues(documentPayload);




      // Loading Use Effect
      if(document.additionalData){
        const methodMap = { 
          selectedAddress1: setSelectedAddress1, 
          selectedAddress2: setSelectedAddress2, 
          selectedAddress3: setSelectedAddress3, 
          selectedAddress4: setSelectedAddress4, 
          selectedLocation: setselectedLocation, 
          locationVal: setLocationVal, 
        };

        let parsedAdditionalData = JSON.parse(document.additionalData);
        Object.keys(parsedAdditionalData).forEach(function(key) {
          if(methodMap[key]){
            methodMap[key](parsedAdditionalData[key]);
          }
        });
      }



    }
    let foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }
    setAddressOptions(getOrderLevelAddresses(order));

    if(foundAssignedProduct){
        setAssignedProduct(foundAssignedProduct);
        setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
    }
      
      if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
      }else{
        // if(document && document.itemIds){
        //   var itemIds = document.itemIds.split(',');
        //     var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
        //     let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
        //     let obj = {id: foundProduct.id, name: str}
        //     setproductVal(obj);
        //     if(foundProduct){
        //       setAddressOptions(updateAddressOptions(foundProduct, order));
        //     }
        //     streamDocument();
        // }
  
      }
      
      setDataLoaded(true);
    }
  },[ documentValues, document, dataLoaded, order, AutofillProductDetails, GlobalAutofill, previewPayloadDocument ])


  
    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();

      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
  
      // const payload = JSON.parse(JSON.stringify(documentValues));
      // var docCopy = document;
      // docCopy.payload = payload;
      // docCopy.payload = preProcessPayload(docCopy.payload);
      // docCopy.payload =  JSON.stringify(docCopy.payload);
      // delete docCopy.documentStatus;
      
      // if(selectedProduct && selectedProduct.id){
      //   docCopy.itemIds = `${selectedProduct.id}`;
      // }
  
      //Save Dropdown Selections
      let dropdownData = {};
      if(selectedAddress1){
        dropdownData.selectedAddress1 = {
          id: selectedAddress1.id,
          name: selectedAddress1.name
        };
      }
      if(selectedAddress2){
        dropdownData.selectedAddress2 = {
          id: selectedAddress2.id,
          name: selectedAddress2.name
        };
      }
      if(selectedAddress3){
        dropdownData.selectedAddress3 = {
          id: selectedAddress3.id,
          name: selectedAddress3.name
        };
      }
      if(selectedAddress4){
        dropdownData.selectedAddress4 = {
          id: selectedAddress4.id,
          name: selectedAddress4.name
        };
      }
      if(selectedLocation){
        dropdownData.selectedLocation = {
          locationCode: selectedLocation.locationCode,
          description: selectedLocation.description
        };
      }
      if(locationVal){
        dropdownData.locationVal = {
          locationCode: locationVal.locationCode,
          description: locationVal.description
        };
      }

      let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, additionalData, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });
  

      // docCopy.additionalData = JSON.stringify(dropdownData);

      // try {
      //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
      //   if (res){
      //     await axios.post(`/document/${document.id}/generate`)
      //     getDocuments();
      //     setSaveSuccess(true);
      //     setHasUnsavedChanges(false);
      //   }
      // } catch (err) {
      //   recordFrontEndError('CASalesTaxStatement6247.jsx', 'Error saving document: ' + err)
      // }
    }

// Component Helpers //
// useEffect(() => {
  //Autofill Product Details After Selection

// },[ selectedProduct, productToggleAutoFill ])

// useEffect(() => {
//   //Autofill Tradein Details After Selection
//   if(selectedTradein !== undefined && tradeinToggleAutoFill){
//     var newValues = documentValues;
//     newValues.trade_year = (selectedTradein.year);
//     newValues.trade_make = (selectedTradein.make);
//     newValues.trade_model = (selectedTradein.model).toString().toUpperCase();
//     newValues.trade_body_type = (selectedTradein.seriesBodyType);
//     newValues.trade_serial_number = (selectedTradein.serialNumber);
//     newValues.balance_owner = (order.customer.name); //Need specific field for TRADE IN OWNER?
//     newValues.balance_owner_address = (order.customer.deliveryAddress.addressLine1);
//     newValues.amount = (selectedTradein.balanceOwed);
//     newValues.trade_allowance = (selectedTradein.tradeAllowanceAtTerms);
//     newValues.balance_owed = (selectedTradein.balanceOwed);
//     newValues.cash_refund = (selectedTradein.cashRefundToCustomer);
//     newValues.net_trade_allowance = (selectedTradein.finalTradeAllowance);
//     // newValues.cash_down_payment = (selectedTradein);

//     setDocumentValues(newValues);
//     setTradeinToggleAutoFill(false);
//     previewPayloadDocument(documentValues);
//   }
// },[ selectedTradein, tradeinToggleAutoFill ])

useEffect(() => {
  //Autofill Tradein Details After Selection
  if(selectedLocation !== undefined && locationToggleAutoFill){
    var newValues = documentValues;
    newValues.seller_address = selectedLocation.address;
    newValues.seller_city = selectedLocation.city;
    newValues.seller_state = selectedLocation.state;
    newValues.seller_zip = selectedLocation.zip;
    newValues.seller_area_code = getAreaCode(selectedLocation.phone);
    newValues.seller_phone = getPhoneNumberAlt(selectedLocation.phone);

    if(selectedLocation.state === "CA"){
      newValues.seller_name = "TEC OF CALIFORNIA, INC";
    }else{
      newValues.seller_name = "TEC EQUIPMENT, INC.";
    }

    setDocumentValues(newValues);
    setLocationToggleAutoFill(false);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
},[ selectedLocation, locationToggleAutoFill, document, documentValues, previewPayloadDocument ])

function AutoFillAddress(addressSelected, selectionInstance){
  var newValues = documentValues;
  if(selectionInstance){
    if(selectionInstance === "ca-address"){
      newValues.ca_address = addressSelected.address;
      newValues.ca_address_city = addressSelected.city;
      newValues.ca_state = addressSelected.state;
      newValues.ca_zip = addressSelected.zip;
    }
    
    if(selectionInstance === "mailing-address"){
      newValues.mailing_address = addressSelected.address;
      newValues.mailing_city = addressSelected.city;
      newValues.mailing_state = addressSelected.state;
      newValues.mailing_zip = addressSelected.zip;
    }

    if(selectionInstance === "use-address"){
      newValues.usage_address = addressSelected.address;
      newValues.usage_city = addressSelected.city;
      newValues.usage_state = addressSelected.state;
      newValues.usage_zip = addressSelected.zip;
    }

    if(selectionInstance === "delivery-address"){
      newValues.delivery_address = addressSelected.address;
      newValues.delivery_city = addressSelected.city;
      newValues.deliery_state = addressSelected.state;
      newValues.delivery_zip = addressSelected.zip;
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  setDocumentValues(newValues);
  if(document){
    previewPayloadDocument(documentValues);
  }
}

const onCloseClick = e => {
  e.preventDefault()
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setdocumentUrl("");
  setDocument(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
  setDocumentLoaded(false);
  setDataLoaded(false);
  setAssignedProduct(undefined);
  setSelectedAddress1(undefined);
  setSelectedAddress2(undefined);
  setSelectedAddress3(undefined);
  setSelectedAddress4(undefined);
  setAddressOptions(undefined);
  setinputAddressVal(undefined);
  setLocationVal(undefined);
  setselectedLocation(undefined);
  setinputLocationVal(undefined);
  setLocationOptions(undefined);
  setLocationToggleAutoFill(undefined);
  setDocumentFocus(undefined);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}



  return (
    <>
      <div
        className="modal fade"
        // id="ca-section-6247"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                CA - CDTFA-447 Sales Tax Statement
              </h5>
              <br />
              { hasClickedClose ? (
                <div className="d-flex">
                    <p className="m-1 pt-1 text-white">Save Changes?</p>
                    <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                    <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                </div>
            ): hasUnsavedChanges ? (
                <button
                type="button"
                className="btn-close bg-white"
                aria-label="Close"
                // onClick={() => setdocumentUrl("")}
                onClick={closeClicked}
                ></button>
            ):(
              <button
                type="button"
                className="btn-close bg-white"
                aria-label="Close"
                data-bs-dismiss="modal"
                // onClick={() => setdocumentUrl("")}
                onClick={preCloseClose}
              ></button>
            )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                  
                <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                    GlobalAutofill();
                    AutofillProductDetails(assignedProduct);
                    previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                </div>

                <br />

                { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Product Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                        </div>
                    </div>
                ):(
                    <div className="m-2 p-2 border">
                        <span>No Assigned Product</span>
                    </div>
                )}
                
                  <label htmlFor="stock"><b>To be completed by the purchaser. (please type or print)</b></label>

                  <div className="row">
                  {addressOptions && addressOptions.length > 0 ? (  

                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress1 && selectedAddress1.name) ?? null}
                          name="ca-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress1(newValue);
                            AutoFillAddress(newValue, "ca-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="My California Address is:" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>                    ):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">CA Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="ca_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.ca_address ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">CA Address City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="ca_address_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.ca_address_city ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">CA State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="ca_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.ca_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">CA Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="ca_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.ca_zip ?? '' }
                          />
                      </div>
                    </div>

                    {addressOptions && addressOptions.length > 0 ? (  
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress2 && selectedAddress2.name) ?? null}
                          name="mailing-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress2(newValue);
                            AutoFillAddress(newValue, "mailing-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Mailing Address:" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>               ):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}
                    

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Mailing Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_address ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Mailing City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_city ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Mailing State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Mailing Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_zip ?? '' }
                          />
                      </div>
                    </div>

                    {addressOptions && addressOptions.length > 0 ? (  
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress3 && selectedAddress3.name) ?? null}
                          name="use-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress3(newValue);
                            AutoFillAddress(newValue, "use-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Usage Address:" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>               ):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Usage Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="usage_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.usage_address ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Usage City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="usage_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.usage_city ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Usage State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="usage_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.usage_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Usage Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="usage_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.usage_zip ?? '' }
                          />
                      </div>
                    </div>

                    {addressOptions && addressOptions.length > 0 ? (  
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress4 && selectedAddress4.name) ?? null}
                          name="delivery-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress4(newValue);
                            AutoFillAddress(newValue, "delivery-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Delivery Address:" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>  ):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Delivery Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="delivery_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.delivery_address ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Delivery City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="delivery_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.delivery_city ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly mb-3">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Delivery State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="deliery_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.deliery_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Delivery Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="delivery_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.delivery_zip ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>


                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Drivers License Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="delivery_id_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.delivery_id_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Area Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="area_code"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.area_code ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Phone</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="phone"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.phone ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly mb-3">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Purchaser Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    <label htmlFor="stock"><b>To be completed by the seller. (please type or print) </b></label>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Model</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="model"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.model ?? '' }
                          />
                      </div>
                    </div>
                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly mb-3">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Purchase Price</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchase_price"
                          onFocus={removeFormatting}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchase_price ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Date of Purchase</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="purchase_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchase_date ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    <label htmlFor="stock"><b>Name and Address of California seller: </b></label>

                    {locationOptions && locationOptions.length > 0 ? (  
                    <div className="d-flex justify-content-evenly">
                      <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                          style={{ width: '100%'}}
                          value={ (locationVal && locationVal.description) ?? null}
                          onChange={(event, newValue) => {
                              setLocationVal(newValue);
                              if(newValue && newValue.locationCode){
                                setselectedLocation(locations.find((location) => location.locationCode === newValue.locationCode));
                                setLocationToggleAutoFill(true);
                              }
                              // resetVariables();
                          }}
                          inputValue={inputLocationVal}
                          onInputChange={(event, newInputValue) => {
                              setinputLocationVal(newInputValue);
                          }}
                          //id="controllable-states-demo"
                          options={locationOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="SELLING LOCATION" />}
                          renderOption={(props, option) => {
                              return (
                              <li {...props} key={option.locationCode}>
                                  {option.description}
                              </li>
                              );
                          }}
                          />
                      </div>
                    </div>                 ):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Locations Found</h5>
                      </div>
                    )}


                    <div className="d-flex justify-content-evenly" style={{ fontSize: '12px' }}>
                      <div className="p-2 col-md-5">
                        <label htmlFor="stock">Seller Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-2">
                        <label htmlFor="stock">Seller CDTFA Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_cdtfa_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_cdtfa_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-2">
                        <label htmlFor="stock">Seller Area Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_area_code"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_area_code ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Seller Phone</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_phone"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_phone ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Seller Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_address ?? '' }
                          />
                      </div>
                    </div>


                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Seller City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Seller State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Seller Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_zip ?? '' }
                          />
                      </div>
                    </div>
                    

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Name of Agent</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_agent_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_agent_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="seller_signature_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_signature_date ?? '' }
                          />
                      </div>
                    </div>
                </div>
                <hr></hr>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default CASalesTaxStatement6247

import React, { useState, useEffect,useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash"
import { useParams } from "react-router-dom";
import moment from 'moment'
import * as MaterialComponents from '@mui/material';
import { formatter, removeChars } from "../../../../../../utlis/formatters";
import { CircularProgress } from "@material-ui/core";
import { useFetch, recordFrontEndError } from "../../../../../../utlis/api";
import { useOrder } from "../../../../../../redux/orders/order"
import * as ProductCalculators from "../../../../../../utlis/productCalculations";
import { setActiveModal } from "../../../../../../redux/app/appSaver";
import * as api from '../../../../../../utlis/api.js'

const DocumentScheduleAModal = ({ initializeDocumentModal, setInitializeDocumentModal, document_id, location, getDocuments, passedDocumentSlug, locations, setDocumentFocus }) => {

  const documentSlug = "tec-schedule-a";

  let { id }        = useParams();
  const order       = useOrder();
  const axios       = useFetch();
  const dispatch    = useDispatch();
  const activeModal = useSelector((state) => state?.appData.activeModal);

  const previewEmbeded = React.useRef(null);

  var initialDocumentValues = useMemo(()=>{return initialDocumentValues={
    customer_name: undefined,
    
    location_address: undefined, // Header Location Details / System Autofill
    location_phone: undefined, // Header Location Details / System Autofill
    location_email: undefined, // Header Location Details / System Autofill
    tec_footer_label: undefined, // Footer / System Autofill
    pg_no: undefined, // Footer / System Autofill
  }},[]);

  // const appData = useSelector((state) => state?.appData);

  const [ document, setDocument ]                               = useState(undefined);
  const [ documentValues, setDocumentValues ]                   = useState(initialDocumentValues);
  const [ documentValuesPaginated, setDocumentValuesPaginated ] = useState([]);
  const [ documentUrl, setdocumentUrl ]                         = useState("");
  const [ selectedProducts, setSelectedProducts ]               = useState([]);
  const [ selectedTradeins, setSelectedTradeins ]               = useState([]);
  const [ previewChanges, setPreviewChanges ]                   = useState(false);
  const [ unsavedChanges, setUnsavedChanges ]                   = useState(false);
  const [ invoiceProductOptions, setInvoiceProductOptions ]     = useState([])
  const [ invoiceTradeinOptions, setInvoiceTradeinOptions ]     = useState([])
  const [saveSuccess, setSaveSuccess]                           = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [autofillLoaded, setAutofillLoaded] = useState(false);
  const [updatingPreview, setUpdatingPreview] = useState(false);
  const [savingDocument, setSavingDocument] = useState(false);
  const [updateForm, setUpdateForm] = useState(0);
  const [fetchingDocument, setFetchingDocument] = useState(false);

  const [ productAdminFeeTracker, setProductAdminFeeTracker ] = useState([]);

  async function UpdateProductAdminFeeAndTotalPriceBreakout(passedProduct, passedAdminFee, passedTotalPrice){
    console.log('UpdateProductAdminFee', passedProduct, passedAdminFee)

    if(passedProduct){
      let editedLineItemFee = passedProduct?.lineItemFee;
      editedLineItemFee.adminFee = passedAdminFee;
      editedLineItemFee.totalPrice = passedTotalPrice;

      if(passedProduct?.lineItemFee?.id){
        await api.editLineItemFee(passedProduct?.lineItemFee?.id, editedLineItemFee);
      }else{
        let newLineItem = {
          id: passedProduct?.lineItemFee?.id,
          LineItemId : passedProduct?.lineItemId,
          adminFee: passedAdminFee,
          totalPrice: passedTotalPrice
        }

        await api.addLineItemFee(newLineItem);
      }
    }
  }

  const Autofill=useCallback(()=>{

    let newValues = documentValues;

    if(order){

      if(order.customer){

        newValues['customer_name'] = order.customer.name;

        if(order.customer.dba){
          newValues['customer_name'] = order.customer.name+' dba '+order.customer.dba;
        }

        if(order && order.documentationDate && documentValues.date === undefined){
          documentValues['date'] = moment.utc(order.documentationDate).format("YYYY-MM-DD");
        }
      
        if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          newValues['co_signer'] = cosigner.name;
          newValues['date_2'] =  moment.utc().format("YYYY-MM-DD");
        }
      }
    }

    setDocumentValues(newValues);
    setAutofillLoaded(true);
    setSelectedProducts(invoiceProductOptions);

  },[documentValues, invoiceProductOptions, order])

  function reloadCustomerValues(){
    let newValues = documentValues;
    if(order){
      if(order.customer){
          newValues['customer_name'] = order.customer.name;
          if(order.customer.dba){
            newValues['customer_name'] = order.customer.name+' dba '+order.customer.dba;
          }
          
          if(order && order.documentationDate){
            newValues['date'] = moment.utc(order.documentationDate).format("YYYY-MM-DD");
          }
                
        if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          newValues['co_signer'] = cosigner.name;
          newValues['date_2'] =  moment.utc().format("YYYY-MM-DD");
        }
      }
    }
    setDocumentValues(newValues);
    setPreviewChanges(true);
    setUpdateForm((Number(updateForm) + 1));
  }


  const loadDocument=useCallback( async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        setFetchingDocument(true);
        await axios.get(`/order/${id}/document/${documentId}`).then(res => {
          if(res?.data.documentTemplate.slug === documentSlug){
            setDocument(res?.data)
            setDataLoaded(false);
            setFetchingDocument(false);
            setInvoiceProductOptions([]);
            setInvoiceTradeinOptions([]);
          }
        }).any( () => {
          setFetchingDocument(false);
        });
      } catch (err) {
        recordFrontEndError('DocumentScheduleAModal.jsx', 'Error loading document: ', )
      }
      
    }
  },[axios,id])

  function calculateWeight(productsJSON, selectedProductJSON){
    // if(selectedTradeins){

    //   let tradeins = [];
    //   selectedTradeins.forEach((item) => {
    //     let tradein = order.tradeins.find((trade) => trade.id === Number(item.id));
    //     if(tradein){
    //       tradeins.push(tradein)
    //     }
    //   })

    // }
    
      let totalTaxableAmount = 0;
      let selectedTaxableAmount = 0;
      productsJSON.forEach((item) => {
        let product = order.products.find((product) => product.id === Number(item.id));
        if(product){
          let productTaxableAmount = 0;
          productTaxableAmount = ProductCalculators.vehicleTaxableAmountUpgradeNoAdmin(product, order);
          if(productTaxableAmount && product.id === selectedProductJSON.id){
            selectedTaxableAmount = productTaxableAmount;
          }
          totalTaxableAmount += productTaxableAmount;
        }
      });

      let weight = Number(selectedTaxableAmount) / Number(totalTaxableAmount);

      return weight;
  }

  function calculateWeightedTradeCredit(productsJSON, selectedProductJSON){
    let productWeight = calculateWeight(productsJSON, selectedProductJSON);
    let totalTradeCredit = calculateTotalTaxCredit(selectedTradeins);

    return (totalTradeCredit * productWeight);
  }

  // Used on sales to NV only
  function calculateWeightedTradeAllowance(productsJSON, selectedProductJSON){
    let productWeight = calculateWeight(productsJSON, selectedProductJSON);
    let totalTradeAllowance = calculateTotalTaxAllowance(selectedTradeins);

    return (totalTradeAllowance * productWeight);
  }

  function calculateWeightedAdminFee(productsJSON){
    if(productsJSON.length && productsJSON.length > 0){
      let productWeight = Number( 1 / productsJSON.length);
      return ((order.adminFee ?? 0) * productWeight);
    }
    return 0;
  }

  const streamDocument=useCallback(async ()=>{
    try {
      let response = await axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
      const file = new Blob(
        [response.data], 
        {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
      setdocumentUrl(fileURL)
    } catch (err) {
      recordFrontEndError('DocumentScheduleAModal.jsx', 'Error streaming document: ' + err)
    }
  },[axios,document])

  const loadData=useCallback(()=>{
    if(document){
      if(document.payload){
        var documentPayload = JSON.parse(document.payload);
        if(documentPayload && Object.keys(documentPayload).length > 0){
          setDocumentValues(documentPayload);
        }
      }
      streamDocument();
    }

    setDataLoaded(true);
  },[ document, streamDocument ])




  //Each time the documentId we receive from our parent changes,
  //load the document...
  useEffect(() => {
    
    // if(appData?.activeModal === 'document-schedule-a'){
      // console.log('edit sch a', activeModal, fetchingDocument, passedDocumentSlug, document)
      if(activeModal === "document-edit" && document_id && passedDocumentSlug && (passedDocumentSlug === "tec-schedule-a" || passedDocumentSlug === "tec-wa-schedule-a") && ( !document || document === undefined ) && fetchingDocument === false){
        // console.log('edit sch a2', activeModal, fetchingDocument, document)

        setdocumentUrl("");
        setInvoiceProductOptions([]);
        setInvoiceTradeinOptions([]);
        setDocumentValues(initialDocumentValues);
        loadDocument(document_id);
      }else if(passedDocumentSlug && passedDocumentSlug === documentSlug && initializeDocumentModal){
        console.log('edit sch a3', activeModal, fetchingDocument, document)

        setdocumentUrl("");
        setInvoiceProductOptions([]);
        setInvoiceTradeinOptions([]);
        setInitializeDocumentModal(false);
        Autofill();
        setFetchingDocument(false);
      }

      if(!(passedDocumentSlug === "tec-schedule-a" || passedDocumentSlug === "tec-wa-schedule-a")){
        setFetchingDocument(false);
      }

    // }

  }, [order, document_id, loadDocument, document, Autofill, autofillLoaded, documentSlug, initialDocumentValues, initializeDocumentModal, setInitializeDocumentModal, passedDocumentSlug, activeModal, fetchingDocument])

  //Effect to load the data from the document each time the document changes.
  useEffect(() => {

    
    // if(appData?.activeModal === 'document-schedule-a'){

      if ( document && !dataLoaded ){
        loadData();

        if(document && document.itemIds){
          var itemIds = document.itemIds.split(',');
          var sproducts = [];
          itemIds.forEach(itemId => {
            var foundProduct = order.products.find((product) => Number(product.id) === Number(itemId));
            if(foundProduct && foundProduct.stockNumber){
              let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
              let obj = {id: foundProduct.id, name: str}
              sproducts.push(obj);
            }
          })
          setSelectedProducts(sproducts);
        }

        if(document && document.tradeinIds){
          var tradeinIds = document.tradeinIds.split(',');
          var selected_tradeins = [];
          tradeinIds.forEach(tradeId => {
            var foundTrade = order.tradeins.find((trade) => Number(trade.id) === Number(tradeId));
            let str = `${foundTrade.vin.toString().toUpperCase()}`;
            let obj = {id: foundTrade.id, name: str}
            selected_tradeins.push(obj);
          })
          setSelectedTradeins(selected_tradeins);
        }
      }

    // }

  }, [document, loadData, order, dataLoaded])

  //Effect to calculate the invoiceProductOptions
  //based on products in the order.
  useEffect(() => {
    
    // if(appData?.activeModal === 'document-schedule-a'){

      if ( _.isEmpty( order.products ) === false && invoiceProductOptions && invoiceProductOptions.length === 0 ) {

        const newinvoiceProductOptions =
          order.products.map(el => {
            if ( !el.invoiceId ) {
              return { id: el.id, name: (el.stockNumber.toString().toUpperCase())};
            }
            return el;
          })

        setInvoiceProductOptions(newinvoiceProductOptions)
      }


      if( _.isEmpty( order.tradeins ) === false && invoiceTradeinOptions && invoiceTradeinOptions.length === 0) {


        const newinvoiceTradeinOptions = order.tradeins.map(el => {
          if(! el.invoiceId ) {
            return { id: el.id, name: (el.vin.toString().toUpperCase()) };
          }
          return el;
        })

        setInvoiceTradeinOptions(newinvoiceTradeinOptions)
      }

    // }
      
  }, [order, invoiceProductOptions, invoiceTradeinOptions])

  // TODO: Move to lib
  function calculateTotalTaxCredit(tradeinsJSON){
    let totalTaxCredit = 0;
    if(tradeinsJSON && tradeinsJSON.length > 0){
      tradeinsJSON.forEach(item => {
        let tradein = order.tradeins.find((trade) => trade.id === Number(item.id));
        if(tradein){
          let tradeCredit = (tradein.tradeAllowanceAtTerms - tradein.balanceOwed - tradein.cashRefundToCustomer);
          // if(tradeCredit > 0){
            totalTaxCredit += tradeCredit;
          // }
        }
      })
    }
    return totalTaxCredit;
  }

  function calculateTotalTaxAllowance(tradeinsJSON){
    let totalTaxCredit = 0;
    if(tradeinsJSON && tradeinsJSON.length > 0){
      tradeinsJSON.forEach(item => {
        let tradein = order.tradeins.find((trade) => trade.id === Number(item.id));
        if(tradein){
          let tradeCredit = tradein.tradeAllowanceAtTerms;
          totalTaxCredit = Number(totalTaxCredit) + Number(tradeCredit);
        }
      })
    }
    return totalTaxCredit;
  }

  // TODO: Move to lib
  function totalNonVehicleTaxableAmount(selectedProduct){
    let product = (order.products.filter(item => {
      return item.id === selectedProduct.id
    }));
    if(product && product.length === 1) {
      product = product[0];
    };

    let total = 0;

    if(product.extendedWarranty && product.extendedWarranty.price){
      total = Number(total) + Number(product.extendedWarranty.price);
    }

    if(product.gapInsurance && product.gapInsurance.amount){
      total = Number(total) + Number(product.gapInsurance.amount);
    }
    
    return total;
  }

  const onSaveClick = event => {
    event.preventDefault();
    setSavingDocument(true);
    updateDocument(document_id);
  }

  const onCustomerNameChange = (newValue) => {
    let updatedObject = JSON.parse(JSON.stringify(documentValues));
    updatedObject.customer_name = newValue;
    setDocumentValues(updatedObject);
    setSaveSuccess(false);
  }

  const onCoSignerChange = (newValue) => {
    let updatedObject = JSON.parse(JSON.stringify(documentValues));
    updatedObject.co_signer = newValue;
    setDocumentValues(updatedObject);
    setSaveSuccess(false);
    // setDocumentValues({...documentValues,['co_signer']:newValue});
  }

  const onDateChange = (newValue) => {
    let updatedObject = JSON.parse(JSON.stringify(documentValues));
    updatedObject.date = newValue;
    setDocumentValues(updatedObject);
    setSaveSuccess(false);
    // setDocumentValues({...documentValues,['date']:newValue});
  }

  const onDate2Change = (newValue) => {
    let updatedObject = JSON.parse(JSON.stringify(documentValues));
    updatedObject.date_2 = newValue;
    setDocumentValues(updatedObject);
    setSaveSuccess(false);
    // setDocumentValues({...documentValues,['date_2']:newValue});
  }
  
  async function getDocumentTemplateBySlug(slug){
    return await axios.get(`/document-template/${slug}/by-slug`);
  }
  
  // come back to refactor nested axios calls
  async function updateDocument(documentId){
    if ( documentId == null || documentId === undefined ){
      
      getDocumentTemplateBySlug(documentSlug).then(response => {
        let invoiceTemplate = response?.data;
        if(invoiceTemplate && invoiceTemplate.id){
          let documentDraftData = {
            DocumentStatusId: 5, //Draft
            DocumentTemplateId: invoiceTemplate.id, //TODO Improve: Change out with found template tec-sales-order-schedule-a                                                                                
            OrderId: id,
            Payload: JSON.stringify({}),
          }
          
       
          axios.post(`/document`, documentDraftData).then((res) => {
            setDocument(res?.data);
            //Rotate Document?

            documentValuesPaginated.forEach(page => {
              if(page.date){
                page['date'] =  moment.utc(page.date).format("MM/DD/YYYY");
              }
            
              if(page.co_signer && page.co_signer !== ""){
                if(page.date_2){
                  page['date_2'] =  moment.utc(page.date_2).format("MM/DD/YYYY");
                }
              }else{
                page['date_2'] =  "";
                page['co_signer'] =  "";
              }
            })
            
            clearLines();
            const payloadString = JSON.stringify(documentValues);
            var docCopy = JSON.parse(JSON.stringify(res?.data));
            docCopy.payload = payloadString;
            delete docCopy.documentStatus;
            
            if(selectedProducts && selectedProducts.length > 0){
              var itemIds = [];
              selectedProducts.forEach(product => {
                itemIds.push(product.id);
              })
              // var itemIds = selectedProducts.filter(({ id }) => selectedProducts.includes(id));
              docCopy.itemIds = `${itemIds}`;
            }

            if(selectedTradeins && selectedTradeins.length > 0){
              var tradeIds = [];
              selectedTradeins.forEach(trade => {
                tradeIds.push(trade.id);
              })
              docCopy.tradeinIds = `${tradeIds}`;
            }

             // Loop tracked products and update admin fee per saved results.
             if(productAdminFeeTracker.length > 0){
              productAdminFeeTracker.forEach(trackedProduct => {
                var foundTrackedProduct = order.products.find(p => p.id === trackedProduct.id);
                if(foundTrackedProduct){
                  UpdateProductAdminFeeAndTotalPriceBreakout(foundTrackedProduct, trackedProduct.adminFee, trackedProduct.totalPrice);
                }
              })
              setProductAdminFeeTracker([]);
            }

  
            axios.put(`/document/${res?.data.id}/prepare`, docCopy).then(res => {
              axios.post(`/document/${docCopy.id}/generate-schedule`, documentValuesPaginated).then(resp => {
                setUnsavedChanges(false);
                setSaveSuccess(true);
                setSavingDocument(false);

                streamDocument();
              
                if(documentValues){
                  if(documentValues.date === undefined){
                    documentValues['date'] =  moment.utc().format("YYYY-MM-DD");
                  }else{
                    documentValues['date'] =  moment.utc(documentValues.date).format("YYYY-MM-DD");
                  }
                  
                  if(documentValues.co_signer && documentValues.co_signer !== ""){
                    if(documentValues.date_2 === undefined){
                      // documentValues['date_2'] =  moment.utc().format("YYYY-MM-DD");
                    }else{
                      documentValues['date_2'] =  moment.utc(documentValues.date_2).format("YYYY-MM-DD");
                    }
                  }
                }
            });
            }).catch(err => {
            });
          });
          
        }else{
          // Throw Error (Mark)
        }
      });



    }else{
      // Update Document Prepare & Generate PDF

      documentValuesPaginated.forEach(page => {
        if(page.date){
          page['date'] =  moment.utc(page.date).format("MM/DD/YYYY");
        }
        
        if(page.co_signer && page.co_signer !== ""){
          if(page.date_2){
            page['date_2'] =  moment.utc(page.date_2).format("MM/DD/YYYY");
          }
        }
      })

      // Update Document
      if(document){

        clearLines();
        const payloadString = JSON.stringify(documentValues);
        var docCopy = JSON.parse(JSON.stringify(document));
        docCopy.payload = payloadString;
        delete docCopy.documentStatus;
       
        if(selectedProducts && selectedProducts.length > 0){
          var itemIds = [];
          selectedProducts.forEach(product => {
            itemIds.push(product.id);
          })
          // var itemIds = selectedProducts.filter(({ id }) => selectedProducts.includes(id));
          docCopy.itemIds = `${itemIds}`;
        }

        if(selectedTradeins && selectedTradeins.length > 0){
          var tradeIds = [];
          selectedTradeins.forEach(trade => {
            tradeIds.push(trade.id);
          })
          docCopy.tradeinIds = `${tradeIds}`;
        }

         // Loop tracked products and update admin fee per saved results.
         if(productAdminFeeTracker.length > 0){
          productAdminFeeTracker.forEach(trackedProduct => {
            var foundTrackedProduct = order.products.find(p => p.id === trackedProduct.id);
            if(foundTrackedProduct){
              UpdateProductAdminFeeAndTotalPriceBreakout(foundTrackedProduct, trackedProduct.adminFee, trackedProduct.totalPrice);
            }
          })
          setProductAdminFeeTracker([]);
        }

        await axios.put(`/document/${document.id}/prepare`, docCopy).then(res => {
          axios.post(`/document/${document.id}/generate-schedule`, documentValuesPaginated).then(resp => {
            setSavingDocument(false);
            setUnsavedChanges(false);
            setUpdatingPreview(false);
            setSaveSuccess(true);

            streamDocument();

            if(documentValues){
              if(documentValues.date === undefined){
                documentValues['date'] =  moment.utc().format("YYYY-MM-DD");
              }else{
                documentValues['date'] =  moment.utc(documentValues.date).format("YYYY-MM-DD");
              }
              
              if(documentValues.co_signer && documentValues.co_signer !== ""){
                if(documentValues.date_2 === undefined){
                  // documentValues['date_2'] =  moment.utc().format("YYYY-MM-DD");
                }else{
                  documentValues['date_2'] =  moment.utc(documentValues.date_2).format("YYYY-MM-DD");
                }
              }
            }
          });
        }).catch(err => {
        });
      }
    }
  }

  const generateInvoice = async event => {
    event.preventDefault();
    setdocumentUrl('');

    setUpdatingPreview(true);

    function clearLinesLocal(){
      for (let i=1; i<=18; i++){
        documentValues[`stock_${i}`] = "";
        documentValues[`status_${i}`] = "";
        documentValues[`year_${i}`] = "";
        documentValues[`make_${i}`] = "";
        documentValues[`model_${i}`] = "";
        documentValues[`vin_${i}`] = "";
        documentValues[`unit_price_${i}`] = "";
        documentValues[`fet_${i}`] = "";
        documentValues[`warranty_${i}`] = "";
        documentValues[`taxable_${i}`] = "";
        documentValues[`gap_${i}`] = "";
        documentValues[`admin_fee_${i}`] = "";
        documentValues[`add_tax_${i}`] = "";
        documentValues[`total_price_${i}`] = "";

        documentValues[`sales_tax_${i}`] = "";
        documentValues[`non_sales_tax_${i}`] = "";
      }
    }


     // Get Location Address & Autofill Address
    if(location && location.address){
      documentValues['location_address'] = location.address+" "+location.city+" "+location.state+" "+location.zip;
    }
      
    if(location && location.phone){
      documentValues['location_phone'] = location.phone;
    }

    if(location && location.email){
      documentValues['location_email'] = location.email;
    }

    if(location && location.state){
      if(location.state === "CA"){
          documentValues['tec_footer_label'] = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
      }else{
          documentValues['tec_footer_label'] = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC";
      }
    }

    // let salesTaxRemainderCents = 0;
    let adminFeeRemainderCents = 0;

    let currentWriteLine = 0;
    let lastProductLine = 0;

    let customer = (order && order.customer) ? order.customer : null;
    let customerState = null;
    if(customer && customer.deliveryAddress){
      customerState = customer.deliveryAddress.state;
    }

    // let lastProduct = null;
    let documentPagesArray = []
    let sortedSelectedProducts = [];
// if(selectedProducts.some(x=>x.id==='selectAll')){
//   selectedProducts=invoiceProductOptions
// } else{
//   selectedProducts=selectedProducts
// }
    


    selectedProducts.forEach((item, i) => {
      let selectedProduct = item;
      let product = (order.products.filter(item => {
        return item.id === selectedProduct.id
      }));
      if(product && product.length === 1) {
        product = product[0];
      };
      
      if(product){
        sortedSelectedProducts.push(product);
      }
    });
    
    // sortedSelectedProducts = sortedSelectedProducts.sort(function(a, b) {
    //    if(a.stockNumber < b.stockNumber) return -1;
    //   if(a.stockNumber > b.stockNumber) return 1;
    //   return 0;
    // });

    var trackingArray = [];

    sortedSelectedProducts.forEach((product, i) => {
      currentWriteLine++;
      // lastProduct = product;
      
      // let selectedProduct = item;
      // let product = (order.products.filter(item => {
      //   return item.id === selectedProduct.id
      // }));
      // if(product && product.length === 1) {
      //   product = product[0];
      // };

      // if(product) {
      // }

      documentValues[`stock_${currentWriteLine}`] = product.stockNumber.toUpperCase();
      documentValues[`status_${currentWriteLine}`] = product.type;
      documentValues[`year_${currentWriteLine}`] = product.year;
      documentValues[`make_${currentWriteLine}`] = product.make;
      documentValues[`model_${currentWriteLine}`] = product.model.toUpperCase();
      documentValues[`vin_${currentWriteLine}`] = product.vin.toUpperCase();
      let productPrice = product.price;
      // totalUnitAmount += productPrice;
      documentValues[`unit_price_${currentWriteLine}`] = formatter.format(productPrice);

      let productGapTotal = ProductCalculators.calculateProductGapTotal(product);
      // totalGapAmount += productGapTotal;
      documentValues[`gap_${currentWriteLine}`] = formatter.format(productGapTotal);

      let productWarrantyTotal = ProductCalculators.calculateProductWarrantyTotal(product);
      // totalWarrantyAmount += productWarrantyTotal;
      documentValues[`warranty_${currentWriteLine}`] = formatter.format(productWarrantyTotal);

      let productTaxableAmount = 0;
      let productSalesTax = 0;
      let nonVehicleSalesTax = 0;

      let adminFeeWeighted = calculateWeightedAdminFee(selectedProducts);
      let roundedAdminFee = Math.round(adminFeeWeighted);
      let newAdminFeeCents =  0;
      newAdminFeeCents = Number(roundedAdminFee) - Number(adminFeeWeighted);
      adminFeeRemainderCents += Number(newAdminFeeCents);
      // totalAdminFeeAmount += roundedAdminFee;
      documentValues[`admin_fee_${currentWriteLine}`] = formatter.format(roundedAdminFee);

      // // Update Tracker so we can save the values to the line item fee per product.
      // trackingArray.push({id: product.id, adminFee: ProductCalculators.roundTwoDecimals(roundedAdminFee).toFixed(2)})

      if(order?.customer?.isExemptSalesTax === false && customerState && customerState !== "WA"){
        productTaxableAmount = ProductCalculators.vehicleTaxableAmountNoUpgradeAdminWeighted(product, order.customer, adminFeeWeighted);
        documentValues[`taxable_${currentWriteLine}`] = formatter.format(productTaxableAmount);

        // let tradeCredit = calculateWeightedTradeCredit(selectedProducts, product);
        let tradeCredit = 0;
        let subTrade = false;
        if(product.registrationAddress?.state === "NV" || product.registrationAddress?.state === "AZ" || product.registrationAddress?.state === "WA"){
          tradeCredit = calculateWeightedTradeAllowance(selectedProducts, product);
          subTrade = true;
        }else{
          tradeCredit = calculateWeightedTradeCredit(selectedProducts, product);
        }

        productSalesTax = ProductCalculators.getSalesTaxTotal(order, (productTaxableAmount ?? 0), (tradeCredit ?? 0), subTrade);

        documentValues[`sales_tax_${currentWriteLine}`] = formatter.format(productSalesTax);
      }
      if(order?.customer?.isExemptSalesTax === false && customerState && customerState === "WA"){
        nonVehicleSalesTax = ((totalNonVehicleTaxableAmount(product) * order.customer.nonVehicleSalesTaxPercent) / 100);
        documentValues[`non_sales_tax_${currentWriteLine}`] = formatter.format(nonVehicleSalesTax);
      }
      
      let productFetTotal = ProductCalculators.getFetTotal(product);
      documentValues[`fet_${currentWriteLine}`] = formatter.format(productFetTotal);

      let taxFeeSubtotal = ProductCalculators.calcTaxFeeSubtotal(order, product); // TODO FIX: Currently includes upgrade values
      documentValues[`add_tax_${currentWriteLine}`] = formatter.format(taxFeeSubtotal);


        let productTotalPrice = 0;
        productTotalPrice += Number(productPrice) + Number(productGapTotal) + Number(productWarrantyTotal) + Number(roundedAdminFee) + Number(productFetTotal) + Number(taxFeeSubtotal);
        if(order?.customer?.isExemptSalesTax === false && customerState && customerState !== "WA"){
          productTotalPrice += Number(productSalesTax);
        }

        if(order?.customer?.isExemptSalesTax === false && customerState && customerState === "WA"){
          productTotalPrice += Number(nonVehicleSalesTax);
        }

        documentValues[`total_price_${currentWriteLine}`] = formatter.format(productTotalPrice);
      // }

      // Update Tracker so we can save the values to the line item fee per product.
      trackingArray.push({
        id: product.id,
        adminFee:
          ProductCalculators.roundTwoDecimals(roundedAdminFee).toFixed(2),
        totalPrice: Number(productTotalPrice).toFixed(2)
      });

      lastProductLine = currentWriteLine;

      if(currentWriteLine === 18){
        documentPagesArray.push({...documentValues});
        clearLinesLocal();
        currentWriteLine = 0;
      }

      product.upgrades.forEach((upgrade, u) => {
        currentWriteLine++;
  
        documentValues[`status_${currentWriteLine}`] = "";
        documentValues[`model_${currentWriteLine}`] = upgrade.type;
        documentValues[`vin_${currentWriteLine}`] = "SN: "+upgrade.serialNumber.toUpperCase();
        let upgradePrice = upgrade.price;
        documentValues[`unit_price_${currentWriteLine}`] = formatter.format(upgradePrice);
  
        let productSalesTax = 0;
        if(upgrade.subjectToSalesTax && order?.customer?.isExemptSalesTax === false){
          let productTaxableAmount = upgradePrice;
          // totalTaxableAmount += Number(productTaxableAmount);
          documentValues[`taxable_${currentWriteLine}`] = formatter.format(productTaxableAmount);
          
          productSalesTax =  (productTaxableAmount * order.customer.vehicleSalesTaxPercent) / 100;

          documentValues[`sales_tax_${currentWriteLine}`] = formatter.format(productSalesTax);
        }
        
        if(product.lineItemFee){
          let upgradeTotalPrice = upgradePrice + productSalesTax;
          // let upgradeTotalPrice = upgradePrice + upgradeFetTotal + upgradeSalesTax; // TODO FIX: THIS.
          // totalTotalAmount += upgradeTotalPrice;
          documentValues[`total_price_${currentWriteLine}`] = formatter.format(upgradeTotalPrice);

        }
        if(currentWriteLine === 18){
          documentPagesArray.push({...documentValues});
          clearLinesLocal();
          currentWriteLine = 0;
        }
      });
      
      
      product.additions.forEach((addition, u) => {
        currentWriteLine++;
        documentValues[`model_${currentWriteLine}`] = addition.type;
        documentValues[`vin_${currentWriteLine}`] = "SN: "+addition.serialNumber.toUpperCase();

        if(currentWriteLine === 18){
          documentPagesArray.push({...documentValues});
          clearLinesLocal();
          currentWriteLine = 0;
        }     
      });

      if(i === (selectedProducts.length - 1) && currentWriteLine > 0 ){
        documentPagesArray.push({...documentValues});
        clearLinesLocal();
        currentWriteLine = 0;
      }  
    });
/////////////////////////// 



      let lastPageIndex = (documentPagesArray.length - 1) >= 0 ? (documentPagesArray.length - 1) : 0;

      let adminFeeWeighted = calculateWeightedAdminFee(selectedProducts);
      let roundedAdminFee = Math.round(adminFeeWeighted);
      let newAdminFeeCents =  0;
      newAdminFeeCents = Number(roundedAdminFee) - Number(adminFeeWeighted);
      adminFeeRemainderCents -= Number(newAdminFeeCents);
      
      adminFeeWeighted = (Number(adminFeeWeighted) - Number(adminFeeRemainderCents))
      documentPagesArray[lastPageIndex][`admin_fee_${lastProductLine}`] = formatter.format(adminFeeWeighted);

      // // Update last line on local tracker variable and then save to the state variable.
      // if(sortedSelectedProducts?.length > 0){
      //   var lastProduct = sortedSelectedProducts[(sortedSelectedProducts.length - 1)];
      //   var productFoundIndex = trackingArray.findIndex(p => p.id === lastProduct.id);
      //   console.log('productFoundIndex', productFoundIndex, trackingArray)
      //   if(productFoundIndex >= 0){
      //     trackingArray[productFoundIndex].adminFee = ProductCalculators.roundTwoDecimals(adminFeeWeighted).toFixed(2);
      //   }else{
      //     trackingArray.push({id: lastProduct.id, adminFee: ProductCalculators.roundTwoDecimals(adminFeeWeighted).toFixed(2)})
      //   }

      //   setProductAdminFeeTracker(trackingArray)
      // }

      // Update Last Line Total
      let lastLineTotal = 
        Number(removeChars(documentPagesArray[lastPageIndex][`unit_price_${lastProductLine}`])) + 
        Number(removeChars(documentPagesArray[lastPageIndex][`fet_${lastProductLine}`])) + 
        Number(removeChars(documentPagesArray[lastPageIndex][`warranty_${lastProductLine}`])) + 
        Number(removeChars(documentPagesArray[lastPageIndex][`admin_fee_${lastProductLine}`])) + 
        Number(removeChars(documentPagesArray[lastPageIndex][`sales_tax_${lastProductLine}`])) +
        Number(removeChars(documentPagesArray[lastPageIndex][`add_tax_${lastProductLine}`]));

        
        if(customerState && customerState === "WA"){
          lastLineTotal += Number(removeChars(documentPagesArray[lastPageIndex][`non_sales_tax_${lastProductLine}`]));
        }else{
          lastLineTotal += Number(removeChars(documentPagesArray[lastPageIndex][`gap_${lastProductLine}`]));
        }

      documentPagesArray[lastPageIndex][`total_price_${lastProductLine}`] = formatter.format(Number(lastLineTotal));

    // Update last line on local tracker variable and then save to the state variable.
    if (sortedSelectedProducts?.length > 0) {
      var lastProduct =
        sortedSelectedProducts[sortedSelectedProducts.length - 1];
      var productFoundIndex = trackingArray.findIndex(
        (p) => p.id === lastProduct.id,
      );

      if (productFoundIndex >= 0) {
        trackingArray[productFoundIndex].adminFee = ProductCalculators.roundTwoDecimals(adminFeeWeighted).toFixed(2);
        trackingArray[productFoundIndex].totalPrice = Number(lastLineTotal).toFixed(2);
      } else {
        trackingArray.push({
          id: lastProduct.id,
          adminFee:
            ProductCalculators.roundTwoDecimals(adminFeeWeighted).toFixed(2),
            totalPrice: Number(lastLineTotal).toFixed(2)
        });
      }

      setProductAdminFeeTracker(trackingArray);
    }

    let unitPriceSum = 0;
    let fetSum = 0;
    let gapSum = 0;
    let warrantySum = 0;
    let totalRows = 18;

    documentPagesArray.forEach(page => {
      for(let x = 1; x <= totalRows; x++){
        if(page[`unit_price_${x}`]){
          unitPriceSum += Number(removeChars(page[`unit_price_${x}`]));
        }

        if(page[`fet_${x}`]){
          fetSum += Number(removeChars(page[`fet_${x}`]));
        }

        if(page[`gap_${x}`]){
          gapSum += Number(removeChars(page[`gap_${x}`]));
        }

        if(page[`warranty_${x}`]){
          warrantySum += Number(removeChars(page[`warranty_${x}`]));
        }
      }
    })

    // documentPagesArray.forEach(page => {
      documentPagesArray[lastPageIndex].unit_price_sum = formatter.format(unitPriceSum);
      documentPagesArray[lastPageIndex].fet_sum = formatter.format(fetSum);
      documentPagesArray[lastPageIndex].gap_sum = formatter.format(gapSum);
      documentPagesArray[lastPageIndex].warranty_sum = formatter.format(warrantySum);
    // });

    if(order?.customer?.isExemptSalesTax === false){
      let taxableSum = 0;
      let salesTaxSum = 0;

      documentPagesArray.forEach(page => {
        for(let x = 1; x <= totalRows; x++){
          if(page[`taxable_${x}`]){
            taxableSum += Number(removeChars(page[`taxable_${x}`]));
          }

          if(page[`sales_tax_${x}`]){
            salesTaxSum += Number(removeChars(page[`sales_tax_${x}`]));
          }
        }
      })

      // documentPagesArray.forEach(page => {
        documentPagesArray[lastPageIndex].taxable_sum = formatter.format(taxableSum);
        documentPagesArray[lastPageIndex].sales_tax_sum = formatter.format(Number(salesTaxSum));
      // })

      // Reconciles last line to adjust sales tax & total. - Deprecating.
      // if(salesTaxSum !== order.orderSalesTaxTotal && selectedProducts.length === order.products.length){
      //   let difference = Number(order.orderSalesTaxTotal) - Number(salesTaxSum);
        
      //   let currentLastLineSalesTax = removeChars(documentPagesArray[lastPageIndex][`sales_tax_${lastProductLine}`]);
      //   let currentLastLineTotalPrice = removeChars(documentPagesArray[lastPageIndex][`total_price_${lastProductLine}`]);
        
      //   let adjSalesTax = Number(currentLastLineSalesTax) + (difference);
      //   let adjTotalPrice = Number(currentLastLineTotalPrice) + (difference);
        
      //   documentPagesArray[lastPageIndex][`sales_tax_${lastProductLine}`] = formatter.format(adjSalesTax);
      //   documentPagesArray[lastPageIndex][`total_price_${lastProductLine}`] = formatter.format(adjTotalPrice);

      //   salesTaxSum = 0;

      //   documentPagesArray.forEach(page => {
      //     for(let x = 1; x <= totalRows; x++){
      //       if(page[`sales_tax_${x}`]){
      //         salesTaxSum += Number(removeChars(page[`sales_tax_${x}`]));
      //       }
      //     }
      //   })
  
      //   // documentPagesArray.forEach(page => {
      //     documentPagesArray[lastPageIndex].sales_tax_sum = formatter.format(Number(salesTaxSum));
      //   // })
  
      // }

    }
    
    if(customerState && customerState === "WA"){

      let nonVehicleSalesTaxSum = 0;
      documentPagesArray.forEach(page => {
        for(let x = 1; x <= totalRows; x++){
          if(page[`non_sales_tax_${x}`]){
            nonVehicleSalesTaxSum += Number(removeChars(page[`non_sales_tax_${x}`]));
          }
        }
      })

      // documentPagesArray.forEach(page => {
        documentPagesArray[lastPageIndex].non_sales_tax_sum = formatter.format(nonVehicleSalesTaxSum);
      // })
    }

    let adminFeeSum = 0;
    let addTaxSum = 0;
    let totalPriceSum = 0;
    documentPagesArray.forEach(page => {
      for(let x = 1; x <= totalRows; x++){
        if(page[`admin_fee_${x}`]){
          adminFeeSum += Number(removeChars(page[`admin_fee_${x}`]));
        }

        if(page[`add_tax_${x}`]){
          addTaxSum += Number(removeChars(page[`add_tax_${x}`]));
        }

        if(page[`total_price_${x}`]){
          totalPriceSum += Number(removeChars(page[`total_price_${x}`]));
        }
      }
    });

    // documentPagesArray.forEach(page => {
      documentPagesArray[lastPageIndex].admin_fee_sum = formatter.format(adminFeeSum);
      documentPagesArray[lastPageIndex].add_tax_fee_sum = formatter.format(addTaxSum);
      documentPagesArray[lastPageIndex].total_price_sum = formatter.format(totalPriceSum);
    // });

    let docslug = documentSlug;
    if(order){
      if(order.customer && order.customer.deliveryAddress && order.customer.deliveryAddress.state && order.customer.deliveryAddress.state === "WA"){
        // if(docslug === "tec-schedule-a"){
          docslug = "tec-wa-schedule-a";
        // }
      }else{
        // if(docslug === "tec-wa-schedule-a"){
          docslug = "tec-schedule-a";
        // }
      }
    }

    documentPagesArray.forEach(page => {
      if(page.date){
        page['date'] =  moment.utc(page.date).format("MM/DD/YYYY");
      }else{
        page['date'] =  "";
      }
      
      if(page.co_signer && page.co_signer !== ""){
        if(page.date_2){
          page['date_2'] =  moment.utc(page.date_2).format("MM/DD/YYYY");
        }
      }else{
        page['date_2'] =  "";
        page['co_signer'] =  "";
      }
    });
    
      //TODO: Move API calls to utils/api
      setDocumentValuesPaginated(documentPagesArray);
          //pass documentPages array as  option to axios call

          try {
            let response = await axios.post(`/order/${id}/preview-schedule-paginated/${docslug}`,
            documentPagesArray,
              { responseType: 'blob' }
            )
            setUpdatingPreview(false);
            // setRotatedSuccess(false);
  
            const file = new Blob(
              [response.data], 
              {type: 'application/pdf'},
            );
            const fileURL = URL.createObjectURL(file);
            setdocumentUrl(fileURL);
            setPreviewChanges(false);
            setUnsavedChanges(true);
            setSaveSuccess(false);
  
            if(documentValues){
              if(documentValues.date === undefined){
                documentValues['date'] =  moment.utc().format("YYYY-MM-DD");
              }else{
                documentValues['date'] =  moment.utc(documentValues.date).format("YYYY-MM-DD");
              }
              
              if(documentValues.co_signer && documentValues.co_signer !== ""){
                if(documentValues.date_2 === undefined){
                  // documentValues['date_2'] =  moment.utc().format("YYYY-MM-DD");
                }else{
                  documentValues['date_2'] =  moment.utc(documentValues.date_2).format("YYYY-MM-DD");
                }
              }
            }
          } catch (err) {
            recordFrontEndError('DocumentScheduleAModal.jsx', ' Error generating invoice: ' + err)
          }
      
          
  }

  function clearLines(){
    let newValues = {...documentValues};
    for (let i=1; i<=18; i++){
      newValues[`stock_${i}`] = "";
      newValues[`status_${i}`] = "";
      newValues[`year_${i}`] = "";
      newValues[`make_${i}`] = "";
      newValues[`model_${i}`] = "";
      newValues[`vin_${i}`] = "";
      newValues[`unit_price_${i}`] = "";
      newValues[`fet_${i}`] = "";
      newValues[`warranty_${i}`] = "";
      newValues[`taxable_${i}`] = "";
      newValues[`gap_${i}`] = "";
      newValues[`admin_fee_${i}`] = "";
      newValues[`add_tax_${i}`] = "";
      newValues[`total_price_${i}`] = "";
      newValues[`sales_tax_${i}`] = "";
      newValues[`non_sales_tax_${i}`] = "";
    }
    setDocumentValues(newValues);
    return newValues;
  }

  const onSelectedProductChange = (e, newValue) => {
    clearLines();
    if(newValue.some((x=>x.id === 'selectAll'))){
      setSelectedProducts(invoiceProductOptions);
    } else{
      setSelectedProducts(newValue);
    }
    setPreviewChanges(true);
    setSaveSuccess(false);
    // generateInvoice(e);
  }

  function formattedOptions(){
    return [{id:'selectAll', name:'Select All', options: invoiceProductOptions}, ...invoiceProductOptions]
  }

  const resetVals = (e) => {
    // e.preventDefault();
    setDocumentValuesPaginated([]);
    setInvoiceProductOptions([]);
    setInvoiceTradeinOptions([]);
    setDocument(undefined);
    setdocumentUrl("");
    setAutofillLoaded(false);
    setDataLoaded(false);
    setSelectedProducts([]);
    setSelectedTradeins([]);
    setPreviewChanges(false);
    setUnsavedChanges(false);
    setDocumentValues(initialDocumentValues);
    getDocuments(true);
    setUpdatingPreview(false);
    setDocumentFocus(undefined);
    setSaveSuccess(false);
    setSavingDocument(false);
    dispatch(setActiveModal(null));
    setFetchingDocument(false);
  }

  
  return (
    <>
      <div
        className="modal fade"
        id={documentSlug}
        tabIndex="-1"
        // aria-labelledby="Finance-Dashboard-Settings-Modal"
        // aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        {/* <div className="modal-dialog modal-dialog-centered modal-lg"> */}
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
              <div className="modal-content">
                <div className="modal-header bg-primary">
                  { document ? (
                    <h5
                    className="modal-title text-white mt-2"
                    id="exampleModalLabel"
                    >
                      Edit Schedule A
                    </h5>
                  ):(
                    <h5
                    className="modal-title text-white mt-2"
                    id="exampleModalLabel"
                    >
                      Generate Schedule A
                    </h5>
                  )}
                  <br />
                  <button
                    type="button"
                    className="btn-close bg-white"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => resetVals(e)}
                    disabled={savingDocument}
                  ></button>
                </div>
                <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
                  <div className="container-fluid mt-0">
                    <div className="row" style={{ height: '70vh'}}>
                      <div className="col-md-3 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}} key={updateForm}>
                        <div>
                          <button 
                          type="button"
                          className="btn btn-primary mx-auto"
                          disabled={savingDocument}
                          onClick={() => {
                            reloadCustomerValues();
                          }}>Reapply Customer Values</button>
                        </div>
                        <br />
                      { order && order.adminFee && order.adminFee > 0 ? (
                        <span>Total Admin Fee: { formatter.format(order.adminFee) }</span>
                      ):null}
                        <br />
                        <Autocomplete
                          disabled={savingDocument}
                          value={ selectedProducts ?? null}
                          multiple
                          id="tags-outlined"
                          onChange={(event, newValue) => {
                            setSaveSuccess(false);
                            onSelectedProductChange(event, newValue);
                          }}
                          options={formattedOptions()}
                          getOptionLabel={(option) => option.name}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Products"
                            />
                          )}
                        />

                        {/* <ul>
                        { selectedProducts && selectedProducts.length > 0 ? (selectedProducts.map((item, index) => (
                          <li key={index}>{ item.name }: 
                            <br />Taxable: { formatter.format(ProductCalculators.vehicleTaxableAmountUpgradeNoAdmin(order.products.find((product) => product.id === Number(item.id)), order.customer)) }
                            <br />Weighted Trade Percent: { Math.round(calculateWeight(selectedProducts, item) * 100) }%
                            <br />Trade Credit Applied: { formatter.format(calculateWeightedTradeCredit(selectedProducts, item))}
                            <br />Weighted Sales Tax: { formatter.format(calculateWeightedSalesTax2(selectedProducts, item, Math.round(calculateWeightedAdminFee(selectedProducts, item))))}
                            <br />Weighted Admin Fee: { formatter.format(calculateWeightedAdminFee(selectedProducts, item))}
                            </li>
                        ))):null}
                        </ul> */}


                        <br />
                        <Autocomplete
                          disabled={savingDocument}
                          value={ selectedTradeins ?? null}
                          multiple
                          id="tags-outlined"
                          // onChange={updateSelectedTradeins}
                          onChange={(event, newValue) => {
                            setSaveSuccess(false);
                            setSelectedTradeins(newValue);
                          }}
                          options={(invoiceTradeinOptions ?? [])}
                          getOptionLabel={(option) => option.name}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Tradeins"
                            />
                          )}
                        />
                        <br />

                        { selectedTradeins && selectedTradeins.length > 0 ?(
                          <div>
                            <ul>
                            <li>Total Tax Credit: {formatter.format(calculateTotalTaxCredit(selectedTradeins))}</li>
                            </ul>
                          </div>
                        ):null}

                        <div className="row">
                          <div className="col-md-12">
                            <label htmlFor="stock">Customer Name</label>
                            <input
                              disabled={savingDocument}
                              value={ ((documentValues && documentValues.customer_name) ?? '') }
                              type="text"
                              className="form-control rounded-pill mt-1"
                              name="customer_name"
                              onChange={(e) => {
                                onCustomerNameChange(e.target.value);
                              }}
                              onBlur={(e) => {
                                setPreviewChanges(true);}}
                              />
                          </div>
                          </div>
                          <div className="row">
                          <div className="col-md-12">
                            <label htmlFor="stock">Date</label>
                            <input
                              disabled={savingDocument}
                              value={ ((documentValues && documentValues.date) ?? '') }
                              type="date"
                              className="form-control rounded-pill mt-1"
                              name="date"
                              onChange={(e, newValue) => {
                                onDateChange(e.target.value);
                              }}                        
                              onBlur={(e) => {
                                setPreviewChanges(true);}}
                              />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <label htmlFor="stock">Co-Signer Name</label>
                            <input
                              disabled={savingDocument}
                              value={ ((documentValues && documentValues.co_signer) ?? '') }
                              type="text"
                              className="form-control rounded-pill mt-1"
                              name="co_signer"
                              onChange={(e, newValue) => {
                                onCoSignerChange(e.target.value);
                              }}
                              onBlur={(e) => {
                                setPreviewChanges(true);}}
                              />
                          </div>
                          </div>
                          <div className="row">
                          <div className="col-md-12">
                            <label htmlFor="stock">Date</label>
                            <input
                              disabled={savingDocument}
                              value={ ((documentValues && documentValues.date_2) ?? '') }
                              type="date"
                              className="form-control rounded-pill mt-1"
                              name="date_2"
                              onChange={(e, newValue) => {
                                onDate2Change(e.target.value);
                              }}                        
                              onBlur={(e) => {
                                setPreviewChanges(true);}}
                              />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-9" style={{ overflowY: 'scroll'}}>
                        <h5>Schedule Preview               
                          { previewChanges && selectedProducts.length > 0 ? (
                          <button className="btn btn-small btn-primary mx-3" 
                              type="button"
                              onClick={generateInvoice}
                              disabled={updatingPreview}
                              // data-bs-dismiss="modal"
                            >
                              Update Preview
                            </button>
                          ):null}
                        </h5>
                        { documentUrl !== "" && documentUrl !== undefined && documentUrl && !savingDocument ? ( 
                          <embed ref={previewEmbeded} id="previewEmbeded" width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                        ): updatingPreview ? (
                          <div class="mx-auto mt-5 text-center" style={{ width: '100%' }}>
                            <br />
                            <br />
                            <br />
                            <label>Loading Preview</label>
                            <br />
                            <br />
                            <div class="mx-auto text-center">
                              <CircularProgress color="#0d6efd" style={{ placeSelf: "center" }} />
                            </div>
                          </div>
                        ): savingDocument ? (
                          <div class="mx-auto mt-5 text-center" style={{ width: '100%' }}>
                            <br />
                            <br />
                            <br />
                            <label>Saving Document</label>
                            <br />
                            <br />
                            <div class="mx-auto text-center">
                              <CircularProgress color="#0d6efd" style={{ placeSelf: "center" }} />
                            </div>
                          </div>
                        ):null}
                      </div>
                    </div>
                  </div>
                </div>
                { unsavedChanges ? (
                <div className="text-center m-3 mb-4">
                  <button className="btn btn-danger mx-3" 
                    type="button"
                    // onClick={generateInvoice}
                    data-bs-dismiss="modal"
                    disabled={savingDocument}
                    onClick={e => {
                      resetVals();
                    }}
                  >
                    Cancel
                  </button>
                  {saveSuccess ? (
                  <button className="btn btn-success mx-3" >
                    Saved Invoice
                  </button>
                  ) : (
                  <button className="btn btn-primary mx-3" 
                    type="button"
                    onClick={e => onSaveClick(e)}
                    disabled={savingDocument}
                    // data-bs-dismiss="modal"
                  >
                    Save
                  </button>
                  ) }
                </div>
                ): (
                  <div className="text-center m-3 mb-4">
                  <button className="btn btn-primary mx-3" 
                    type="button"
                    // onClick={generateInvoice}
                    data-bs-dismiss="modal"
                    onClick={e => {
                      resetVals();
                    }}
                  >
                    Close
                  </button>
                </div>
                )}
              </div>
        </div>
      </div>
    </>
  );
}

const {
  Autocomplete,
  TextField,
} = MaterialComponents

export default DocumentScheduleAModal

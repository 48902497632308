import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import {TextField} from '@mui/material';

export default function DocSearchBar({searchVal=null, handleChange, label=null, datatestid}) {
  return (
        
        <TextField 
            className="form-control text-uppercase" 
            style={{borderRadius:'10px', width:'100%', padding:'1rem'}} 
            value={searchVal??null}
            onChange={handleChange??null}
            label={label??''}
            size='small'
            data-testid={datatestid}
            InputProps={{
                startAdornment: (
                    <SearchIcon />
                ),
              }}
              variant="outlined"
            />
  )
}

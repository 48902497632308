import _ from 'lodash'
import React,{ useEffect, useState, useCallback } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { AppContext } from './contexts'
import { useDispatch, useSelector } from "react-redux";
import { useFetch } from "./utlis/api"
import { loginRequest } from "./authConfig";
import { createAxiosInstance } from "./utlis/api"
import Login from "./pages/guest/login";
import Maintenance from "./pages/guest/maintenance";
import FinanceDashboard from "./pages/logged_in/finance_dashboard";
import FinanceRequests from "./pages/logged_in/finance_requests";
import UserAccess from "./pages/logged_in/user_access";
import Config from "./pages/logged_in/config";
import Notifications from "./pages/logged_in/me/notifications"
import Order from "./pages/logged_in/order";
import Orders from "./pages/logged_in/orders";
import Sale from "./pages/logged_in/sales";
import VersionHistory from "./pages/logged_in/version_history";
import MyCheckedOut from './pages/logged_in/checked_out_orders/MyCheckedOut';
import AllCheckedOut from './pages/logged_in/checked_out_orders/AllCheckedOut';
import { callMsGraph } from "./graphUser";
import { getMyGroups } from "./graphUserGroups";
import { getAllGroups } from "./graphGroups";
import { useOrder, refreshOrder } from './redux/orders/order';
import moment from 'moment';
// import withClearCache from "./ClearCache";
import { setAdRole, setDeveloperAccess, setRole } from './redux/role/roleAction';
import { capitalizeFirstLetter } from "./utlis/formatters";
import BugReport from './pages/logged_in/bugreport';
import { updateMe } from "./redux/me";
import { getMe } from "./utlis/api";
import DataMigration146 from './pages/logged_in/data_migrations/DataMigration146';

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
 export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
  return buildDate;
};

// const ClearCacheComponent = withClearCache(MainApp);
/**
 * If a user is authenticated the ProfileContent component above is rendered.
 * Otherwise a message indicating a user is not authenticated is rendered.
 */
    
function App() {
  return <MainApp />;
}

function MainApp(props) {
  const dispatch  = useDispatch();

  const { instance, accounts }    = useMsal();
  const isAuthenticated           = useIsAuthenticated();
  const role            = useSelector((state) => state?.role.role);
  const developerAccess = useSelector((state) => state?.role.developerAccess);
  const me               = useSelector((state) => state?.me);

  //Single instance of axios with headers set...
  const [ axiosInstance, setAxiosInstance ] = useState(null)
  const [graphData, setGraphData] = useState(null);
  const [groups, setGroups] = useState(null);
  const [myGroups, setMyGroups] = useState(null);
  const [userDefaultRole, setUserDefaultRole] = useState(null);
  const [updatingTokens, setUpdatingTokens] = useState(false);
  const [serverMode, setServerMode] = useState(null);
  const [appStatus, setAppStatus] = useState('initalize');
  const [updatingMe, setUpdatingMe] = useState(false);

  // function resetComponent(){

  // }

  // Load "me" to Redux
  const findMe = useCallback(async () => {
    let newMe = await getMe();
    if(newMe?.data){
      dispatch(updateMe(newMe.data));
      setUpdatingMe(false);
    }
  },[dispatch]);

  useEffect(() => {
    if(me?.id === null && !updatingMe && axiosInstance !== null){
      setUpdatingMe(true);
      findMe();
    }
  },[axiosInstance, dispatch, findMe, me, updatingMe]);

  //Effect to update the axios instance
  //based on whether the user is authenticated.
  useEffect(() => {

    function updateGraphData(){
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
      }).then((response) => {
        callMsGraph(response.accessToken).then(response => setGraphData(response));
        getMyGroups(response.accessToken).then(response => {
          setMyGroups(response.value);
        });

        getAllGroups(response.accessToken).then(response => {
          setGroups(response.value);
        });

      });
    }

    if(instance && instance.axios && graphData == null){
      updateGraphData();
    }

    function updateTokens(){
      if ( isAuthenticated ) {
        instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
          response_type: 'id_token',
          nonce: Math.random(1,10000),
          forceRefresh: true
        }).then((response) => {
          const instance = createAxiosInstance(response.idToken, response.accessToken)
  
          setAxiosInstance({ axios: instance })

          if(graphData == null){
            updateGraphData();
          }

          // return () => {
          //   setAxiosInstance(null)
          // }

          
  
        })
        setTimeout(() => {
          updateTokens();
      }, "600000");
    }
    }

    updateTokens();

  }, [ isAuthenticated, accounts, instance, graphData, setUpdatingTokens, updatingTokens, serverMode ])

  useEffect(() => {
    
    if(axiosInstance?.axios){

      if(serverMode === null && appStatus === 'initalize' && role && role !== 'default' && developerAccess !== null){
        setAppStatus('loading');
        axiosInstance.axios
        .get(`/server`).then(res => {
          if(res?.data){

            let serverObject = res.data;

            if(serverObject.serverMode){
              setServerMode(serverObject.serverMode);
              if(serverObject.serverMode === 'maintenance'){
                setAppStatus('maintenance');
                if(window.location.pathname !== '/maintenance' && developerAccess === false){
                    window.location = '/maintenance';
                }
              }

              if(serverObject.serverMode === 'running'){
                setAppStatus('running');
                if(window.location.pathname === '/maintenance'){
                  window.location = '/';
                }
              }
            }

          }
        });
      }

    }

  }, [ axiosInstance, serverMode, appStatus, role, developerAccess ])


  useEffect(() => {

    let developerAccessLocal = null;

    const msalLogout = event => {
      instance.logoutRedirect({
        account: instance.getActiveAccount(),
        postLogoutRedirectUri: ( process?.env?.REACT_APP_LOGOUT_MSAL_REDIRECT_URI ?? null),
      })
    }

    function determineUserDefaultRole(userGroups){

      let masterRoleId = process?.env?.REACT_APP_ROLE_MASTER_ID;
      let financeManagerRoleId = process?.env?.REACT_APP_ROLE_FINANCE_MANAGER_ID;
      let financeAdminRoleId = process?.env?.REACT_APP_ROLE_FINANCE_ADMIN_ID;
      let developerRoleId = process?.env?.REACT_APP_ROLE_DEVELOPER_ID;

      let defaultRole = undefined;
      userGroups.forEach(id => {

        if(developerRoleId){
          if(id === developerRoleId){
            dispatch(setDeveloperAccess(true));
            developerAccessLocal = true;
          }
        }

          if(masterRoleId){
            if(id === masterRoleId){
              defaultRole = 'master';
            }
          }

        if(defaultRole !== 'master'){
          if(financeManagerRoleId){
            if(id === financeManagerRoleId){
              defaultRole = 'finance-manager';
            }
          }
        }

        if(defaultRole !== 'master' && defaultRole !== 'finance-manager'){
          if(financeAdminRoleId){
            if(id === financeAdminRoleId){
              defaultRole = 'finance-admin';
            }
          }
        }
      });
      
      if(defaultRole !== undefined){
        setUserDefaultRole(defaultRole);
      }
      return defaultRole;
    }

    // New Way
    if(myGroups !== null && (userDefaultRole === null || userDefaultRole === 'default')){

      let udfTemp = null;
      udfTemp = determineUserDefaultRole(myGroups)

      var saveToRedux = new Promise((resolve) => {
        // Save to Redux
        if(userDefaultRole){
          dispatch(setAdRole(userDefaultRole));
          dispatch(setRole(userDefaultRole));
        }else if(udfTemp){
          dispatch(setAdRole(udfTemp));
          dispatch(setRole(udfTemp));
        }else{
          if(isAuthenticated){
            msalLogout();
          }
        }
        resolve();
      });

      saveToRedux.then( () => {
        if(developerAccessLocal === null){
          dispatch(setDeveloperAccess(false));
        }
      });
    }

  }, [ instance, myGroups, groups, userDefaultRole, dispatch, isAuthenticated, developerAccess ])

  const ServerBar = ({appStatus}) => {

    const developerAccess = useSelector((state) => state?.role.developerAccess);
    let showServerBar = false;

    let serverBarColor = '#ff7c00';
    if(process?.env?.REACT_APP_SERVER_TAG === "local" || process?.env?.REACT_APP_SERVER_TAG === "development" || process?.env?.REACT_APP_SERVER_TAG === "staging" || process?.env?.REACT_APP_SERVER_TAG === "training"){
      showServerBar = true;
  
      if(process?.env?.REACT_APP_SERVER_TAG === "staging"){
        serverBarColor = '#ff0000b0';
      }
  
      if(process?.env?.REACT_APP_SERVER_TAG === "training"){
        serverBarColor = '#0097ff';
      }
    }

    // let isLoggedIn = instance.getAllAccounts().length > 0;

    return (
      <>
        {/* <div>Is Logged In: { isLoggedIn === true ? "Yes" : "No" }</div> */}
        { showServerBar === true && (appStatus === 'running' || developerAccess === true ) ? (
          <>
            <div data-testid="testSuiteFeedback" style={{ zIndex: '100' ,backgroundColor: serverBarColor, color: 'white', width: '100%', fontSize: '16px', fontWeight: 'bold'}} className="py-2 text-center">Testing Message Here</div>
            <div style={{ backgroundColor: serverBarColor, color: 'white', width: '100%', fontSize: '26px', fontWeight: 'bold'}} className="py-2 text-center">{capitalizeFirstLetter((process?.env?.REACT_APP_SERVER_TAG ?? ""))} Server - v{process?.env?.REACT_APP_CURRENT_APP_VERSION}</div>
          </>
          ):null}
      </>
    )
  }

  return (
      <div className="App">
        <ServerBar appStatus={appStatus} />
        <AppContext.Provider value={ axiosInstance }>

          <AuthenticatedTemplate>
            { _.isNil( axiosInstance ) ? null :
              <AuthenticatedRoutes appStatus={appStatus} setAxiosInstance={setAxiosInstance} />
            }
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <UnauthenticatedRoutes />
          </UnauthenticatedTemplate>

        </AppContext.Provider>
      </div>
  );
}

const AuthenticatedRoutes = ({appStatus}) => {

  const developerAccess = useSelector((state) => state?.role.developerAccess);

  return (
    <Router>

      <RequestInterceptors/>

      <Switch>
        { (appStatus === 'running' || developerAccess === true ) ? (
          <>
            <Route exact path="/">
              <FinanceDashboard />
            </Route>

            <Route exact path="/order">
              <Orders />
            </Route>
            <Route exact path="/config">
              <Config />
            </Route>

            <Route path="/order/:id">
              <Order />
            </Route>

            <Route exact path="/sale">
              <Sale />
            </Route>
            <Route exact path="/me/notifications">
              <Notifications />
            </Route>
            <Route exact path="/finance-requests">
              <FinanceRequests />
            </Route>
            <Route exact path="/my-checked-out">
              <MyCheckedOut />
            </Route>
            <Route exact path="/all-checked-out">
              <AllCheckedOut />
            </Route>
            <Route exact path="/user-access">
              <UserAccess />
            </Route>
            <Route exact path="/bug-report">
              <BugReport />
            </Route>
            <Route exact path="/version-history">
              <VersionHistory />
            </Route>
            <Route exact path="/data-migration-1-4-6">
              <DataMigration146 />
            </Route>
          </>
        ):null}

        <Route exact path="/maintenance">
          <Maintenance />
        </Route>

      </Switch>
    </Router>
  )
}

const UnauthenticatedRoutes = () => {
  return (
    <Router>
      <Switch>

        <Route exact path="*">
          <Login />
        </Route>

      </Switch>
    </Router>
  )
}

const RequestInterceptors = () => {
  
  const { instance }    = useMsal();
  const axios     = useFetch()
  const dispatch  = useDispatch()
  const order     = useOrder()

  //On mount, add an interceptor for 401s.
  useEffect(() => {

    const msalLogout = event => {
      instance.logoutRedirect({
        account: instance.getActiveAccount(),
        postLogoutRedirectUri: ( process?.env?.REACT_APP_LOGOUT_MSAL_REDIRECT_URI ?? null),
        // postLogoutRedirectUri: "https://devfast.softwareharmonics.site/",
      })
    }

    const redirectIf401 = err => {
      if ( err.response.status === 401 ) {
        msalLogout() // Sends to logout page. Not Ideal.
      }
    }

    const refreshOrderIfOrderUpdate = response => {


      const updateMethods = [ 'post', 'put', 'delete' ]

      if(response && response.config){

        const isPostPutOrDelete = updateMethods.includes(response.config.method)
        
        if ( isPostPutOrDelete === false ) {
          return response //No need to update the order for GETs
        }
        
        const apiIndex  = response.request.responseURL.indexOf('api/')
        const baseURL   =
        response.request.responseURL.substring(apiIndex+ 'api'.length)
        
        //Whitelisted...
        // const baseURLInWhitelistedRefreshAPIs =
        //   _.isEmpty(
          //     WHITELISTED_ORDER_REFRESH_API_TEMPLATES.filter(api => baseURL.match(api))
          //   ) === false
          
          //Blacklisted...
          const baseURLNotInBlacklistedNoRefreshAPIs =
          _.isEmpty(
          BLACKLISTED_ORDER_NO_REFRESH_API_TEMPLATES.filter(api => baseURL.match(api)))

        if ( baseURLNotInBlacklistedNoRefreshAPIs ){  //Not in blacklisted
          //   if ( baseURLNotInBlacklistedNoRefreshAPIs &&  //Not in blacklisted
          //   ( baseURL.startsWith('/order') ||           //Order resource or
          // ( baseURLInWhitelistedRefreshAPIs ))) {     //we want to override
          if(order?.id && order?.id > 0){
            dispatch(refreshOrder(order.id, true))
          }

        }
        
        return response
      }
      return null
    }
    
    const redirectInterceptor =
      axios.interceptors.response.use(response => response, redirectIf401)
      
      const refreshInterceptor =
      axios.interceptors.response.use(refreshOrderIfOrderUpdate)
      
      return () => {
        axios.interceptors.response.eject(redirectInterceptor)
        axios.interceptors.response.eject(refreshInterceptor)
      }
      
    }, [ order, axios.interceptors.response, dispatch, instance])
    
  return null
}

//API templates not under /order for refresh...
// const WHITELISTED_ORDER_REFRESH_API_TEMPLATES = [ 
//   '/document', 
//   '/lineItemFee', 
//   '/extendedWarranty', 
//   '/gapInsurance' 
// ]

//API templates under order we don't want for refresh...
const BLACKLISTED_ORDER_NO_REFRESH_API_TEMPLATES = [
  '/cosigner',
  '/update-final-values',
  '/rotate-for-printing',
  '/prepare',
  '/generate',
  '/document',
  '/preview-tec-document',
  '/front-end-error',
  '/tec-schedule-a',
  '/lending-option-by-source',
  '/terms',
  '/clear-product-groups',
  '/create-product-group',
  '/extendedWarranty',
  '/token',
  '/lien-holder',
  '/funding-details',
  '/preview-schedule-paginated',
  '/preview-invoice',
  '/ship-to',
  '/sold-to',
  '/clear-product-groups',
  '/create-product-group',
  '/clear-extra-product-groups',
  '/addition',
  '/upgrade',
  '/deposit',
  '/duplicate'
  // 'nir=true'
]

export default App;
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./Style/productmodal.css";
import { CircularProgress } from "@mui/material";
import { useOrder } from "../../../../../../../redux/orders/order";
import { DocumentHandleSubmit } from "../../Documents/DocumentAutofill/DocumentHelpers";
import { AZ5000GlobalAutofill, AZ5000AutofillProductDetails } from "../../Documents/DocumentAutofill/AZ5000AF";
import { AZ5010GlobalAutofill, AZ5010AutofillProductDetails } from "../../Documents/DocumentAutofill/AZ5010AF";
import { AZ5011GlobalAutofill, AZ5011AutofillProductDetails } from "../../Documents/DocumentAutofill/AZ5011AF";
import { AZ5012GlobalAutofill, AZ5012AutofillProductDetails } from "../../Documents/DocumentAutofill/AZ5012AF";
import { AZTitleAppGlobalAutofill, AZTitleAppAutofillProductDetails } from "../../Documents/DocumentAutofill/AZTitleApp";
import { CABillOfSaleGlobalAutofill, CABillOfSaleAutofillProductDetails, CABillOfSaleAutofillTradeDetails } from "../../Documents/DocumentAutofill/CABillOfSale";
import { CACertificateOfNonOperationGlobalAutofill, CACertificateOfNonOperationAutofillProductDetails } from "../../Documents/DocumentAutofill/CACertificateOfNonOperation";
import { CADeliveryStatementGlobalAutofill, CADeliveryStatementAutofillProductDetails } from "../../Documents/DocumentAutofill/CADeliveryStatement";
import { CADMVDeclarationOfGVWGlobalAutofill, CADMVDeclarationOfGVWAutofillProductDetails } from "../../Documents/DocumentAutofill/CADMVDeclarationOfGVW";
import { CAPowerOfAttorneyGlobalAutofill, CAPowerOfAttorneyAutofillProductDetails, CAPowerOfAttorneyAutofillTradeDetails } from "../../Documents/DocumentAutofill/CAPowerOfAttorney";
import { CAPTICertGlobalAutofill, CAPTICertAutofillProductDetails } from "../../Documents/DocumentAutofill/CAPTICert";
import { CAResaleCertGlobalAutofill, CaResaleCertAutofillProductDetails } from "../../Documents/DocumentAutofill/CAResaleCert";
import { CASalesTaxStatement6247GlobalAutofill, CASalesTaxStatement6247AutofillProductDetails } from "../../Documents/DocumentAutofill/CASalesTaxStatement6247";
import { CASection837SalesTaxAffidavitGlobalAutofill, CASection837SalesTaxAffidavitAutofillProductDetails } from "../../Documents/DocumentAutofill/CASection837SalesTaxAffidavit";
import { CAStatementOfFactsGlobalAutofill, CAStatementOfFactsAutofillProductDetails } from "../../Documents/DocumentAutofill/CAStatementOfFacts";
import { CATitleApp343GlobalAutofill, CATitleApp343AutofillProductDetails } from "../../Documents/DocumentAutofill/CATitleApp343";
import { IATitleAppGlobalAutofill, IATitleAppAutofillProductDetails } from "../../Documents/DocumentAutofill/IATitleApp";
import { IRS8300GlobalAutofill } from "../../Documents/DocumentAutofill/IRS8300";
import { NEForm6GlobalAutofill, NEForm6AutofillProductDetails } from "../../Documents/DocumentAutofill/NEForm6";
import { NEForm13GlobalAutofill, NEForm13AutofillProductDetails } from "../../Documents/DocumentAutofill/NEForm13";
import { NEPoAGlobalAutofill, NEPoAAutofillProductDetails } from "../../Documents/DocumentAutofill/NEPoA";
import { NETitleAppGlobalAutofill, NETitleAppAutofillProductDetails } from "../../Documents/DocumentAutofill/NETitleApp";
import { NVAffPurchaserGlobalAutofill, NVAffPurchaserAutofillProductDetails } from "../../Documents/DocumentAutofill/NVAffPurchaser";
import { NevadaBillOfSaleGlobalAutofill, NevadaBillOfSaleAutofillProductDetails } from "../../Documents/DocumentAutofill/NVBillOfSale";
import { NVErasureAffidavitGlobalAutofill, NVErasureAffidavitAutofillProductDetails } from "../../Documents/DocumentAutofill/NVErasureAffidavit";
import { NVPowerOfAttorneyGlobalAutofill, NVPowerOfAttorneyAutofillProductDetails } from "../../Documents/DocumentAutofill/NVPowerOfAttorney";
import { NVVehicleInspectionCertificateGlobalAutofill, NVVehicleInspectionCertificateAutofillProductDetails } from "../../Documents/DocumentAutofill/NVVehicleInspectionCertificate";
import { OregonBillOfSaleGlobalAutofill, OregonBillOfSaleAutofillProductDetails } from "../../Documents/DocumentAutofill/OregonBillOfSale";
import { OregonTitleGlobalAutofill, OregonTitleAutofillProductDetails } from "../../Documents/DocumentAutofill/OregonTitle";
import { OregonVINGlobalAutofill, OregonVINAutofillProductDetails } from "../../Documents/DocumentAutofill/OregonVIN";
import { SD45DayExtGlobalAutofill, SD45DayExtAutofillProductDetails } from "../../Documents/DocumentAutofill/SD45DayExt";
import { SDDealerReportGlobalAutofill, SDDealerReportAutofillProductDetails } from "../../Documents/DocumentAutofill/SDDealerReport";
import { SDDuplicateTitleGlobalAutofill, SDDuplicateTitleAutofillProductDetails } from "../../Documents/DocumentAutofill/SDDuplicateTitle";
import { SDPoAGlobalAutofill, SDPoAAutofillProductDetails } from "../../Documents/DocumentAutofill/SDPoA";
import { SDTitleAppGlobalAutofill, SDTitleAppAutofillProductDetails } from "../../Documents/DocumentAutofill/SDTitleApp";
import { TECAffidavitGlobalAutofill, TECAffidavitAutofillProductDetails } from "../../Documents/DocumentAutofill/TECAffidavit";
import { TECBillOfSaleGlobalAutofill, TECBillOfSaleAutofillProductDetails } from "../../Documents/DocumentAutofill/TECBillofSale";
import { TECCAAffExmptSalesTaxGlobalAutofill, TECCAAffExmptSalesTaxAutofillProductDetails, TECCAAffExmptSalesTaxAutofillTradeDetails } from "../../Documents/DocumentAutofill/TECCAAffExmptSalesTax";
import { TECCaliforniaSalesTaxExemptionCertificateGlobalAutofill, TECCaliforniaSalesTaxExemptionCertificateAutofillProductDetails } from "../../Documents/DocumentAutofill/TECCaliforniaSalesTaxExemptionCertificate";
import { TECCertOOSMultiGlobalAutofill, TECCertOOSMultiAutofillProductDetails } from "../../Documents/DocumentAutofill/TECCertOOSMulti";
import { TECCertOOSSingleGlobalAutofill, TECCertOOSSingleAutofillProductDetails } from "../../Documents/DocumentAutofill/TECCertOOSSingle";
import { TECDeliveryReceiptGlobalAutofill, TECDeliveryReceiptAutofillProductDetails } from "../../Documents/DocumentAutofill/TECDeliveryReceipt";
import { TECDepositReceiptGlobalAutofill, TECDepositReceiptAutofillProductDetails, TECDepositReceiptAutofillDeposit } from "../../Documents/DocumentAutofill/TECDepositReceipt";
import { TECDoAGlobalAutofill, TECDoAAutofillProductDetails, TECDoAAutofillTradeDetails } from "../../Documents/DocumentAutofill/TECDoA";
import { TECInsuranceRequestGlobalAutofill, TECInsuranceRequestAutofillProductDetails } from "../../Documents/DocumentAutofill/TECInsuranceRequest";
import { TECPayOffAuthorizationGlobalAutofill, TECPayOffAuthorizationAutofillProductDetails, TECPayOffAuthorizationAutofillTradeDetails } from "../../Documents/DocumentAutofill/TECPayOffAuthorization";
import { TECPowerOfAttorneyGlobalAutofill, TECPowerOfAttorneyAutofillProductDetails, TECPowerOfAttorneyAutofillTradeDetails } from "../../Documents/DocumentAutofill/TECPowerOfAttorney";
import { TECSalesOrderGlobalAutofill, calculateTotalsFunction } from "../../Documents/DocumentAutofill/TECSalesOrder";
import { TECSoldAsIsGlobalAutofill, TECSoldAsIsAutofillProductDetails } from "../../Documents/DocumentAutofill/TECSoldAsIs";
import { TECStatementOfErrorGlobalAutofill, TECStatementOfErrorAutofillProductDetails } from "../../Documents/DocumentAutofill/TECStatementOfError";
import { TECTaxWaiverGlobalAutofill, TECTaxWaiverAutofillProductDetails } from "../../Documents/DocumentAutofill/TECTaxWaiver";
import { TECVehicleSalesDocumentGlobalAutofill, TECVehicleSalesDocumentAutofillProductDetails } from "../../Documents/DocumentAutofill/TECVehicleSalesDocument";
import { TECWAWeightSlipTrailersGlobalAutofill, TECWAWeightSlipTrailersAutofillProductDetails, TECWAWeightSlipTrailersAutofillTradeDetails } from "../../Documents/DocumentAutofill/TECWAWeightSlipTrailers";
import { WABillOfSaleGlobalAutofill, WABillOfSaleAutofillProductDetails, WABillOfSaleAutofillTradeDetails } from "../../Documents/DocumentAutofill/WABillOfSale";
import { WAReleaseOfInterestPowerOfAttorneyGlobalAutofill, WAReleaseOfInterestPowerOfAttorneyAutofillProductDetails, WAReleaseOfInterestPowerOfAttorneyAutofillTradeDetails } from "../../Documents/DocumentAutofill/WAReleaseOfInterestPowerOfAttorney";
import { WASalesTaxGlobalAutofill, WASalesTaxAutofillProductDetails } from "../../Documents/DocumentAutofill/WASalesTax";
import { WAVehicleTitleApplicationGlobalAutofill, WAVehicleTitleApplicationAutofillProductDetails } from "../../Documents/DocumentAutofill/WAVehicleTitleApplication";
import { WAVehicleTitleApplicationAdditionalGlobalAutofill, WAVehicleTitleApplicationAdditionalAutofillProductDetails } from "../../Documents/DocumentAutofill/WAVehicleTitleApplicationAdditional";
import { WAWholeSaleGlobalAutofill, WAWholeSaleAutofillProductDetails, WAWholeSaleAutofillTradeDetails } from "../../Documents/DocumentAutofill/WAWholesale";
import MobileStepper from '@mui/material/MobileStepper';
import { capitalizeFirstLetter, formatter } from "../../../../../../../utlis/formatters";
import { updateStatus, updateChangesArray, updateGroupChangesArray, resetAll } from "../../../../../../../redux/saveAndCopy"
import { setActiveModal } from "../../../../../../../redux/app/appSaver";
import { rdx } from "../../../../../../../redux/orders/order"
import { filterDocumentsForProduct, filterProductDocumentsForOrder, filterDocumentsGeneral } from "../../../../../../../utlis/dataSaveAndCopyLibrary";
import moment from 'moment'
import {
  generateInvoice,
} from "../../../../../../../utlis/invoiceCalculations";
import * as api from '../../../../../../../utlis/api.js'

function CopyChangeToDocumentsModal( {documents, getDocuments} ) {  
  const dispatch  = useDispatch();
  // const order   = useOrder();
  const appData = useSelector((state) => state?.appData);
  const copySection = useSelector((state) => state?.copyChangesToDocumentsRedux.copySection);  


  const [documentsSavedCount, setDocumentsSavedCount] = useState(0);
  const [totalDocuments, setTotalDocuments] = useState(0);


  function resetReduxSaver(){
    dispatch(resetAll());
  }

  function resetModal(resetReduxSaverVar=false){
    getDocuments();
    dispatch(setActiveModal(null));
    setDocumentsSavedCount(0);
    setTotalDocuments(0);
    if(resetReduxSaverVar === true){
      resetReduxSaver();
    }
  }

  function correctSlug(newSlug){


    if(newSlug.includes('tec-sales-order')){
      newSlug = "tec-sales-order";
    }

    if(newSlug.includes('tec-invoice-template')){
      newSlug = "tec-invoice-template";
    }

    if(newSlug.includes('tec-poa')){
      newSlug = "tec-poa";
    }

    if(newSlug.includes('tec-statement-of-error')){
      newSlug = "tec-statement-of-error";
    }

    return newSlug;
  }

  let documentAutofillMap = [
    {slug: 'az-5000', globalAutofillFunction: AZ5000GlobalAutofill, productAutofillFunction: AZ5000AutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'az-5010', globalAutofillFunction: AZ5010GlobalAutofill, productAutofillFunction: AZ5010AutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'az-5011', globalAutofillFunction: AZ5011GlobalAutofill, productAutofillFunction: AZ5011AutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'az-5012', globalAutofillFunction: AZ5012GlobalAutofill, productAutofillFunction: AZ5012AutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'az-title-app', globalAutofillFunction: AZTitleAppGlobalAutofill, productAutofillFunction: AZTitleAppAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'ca-bill-of-sale', globalAutofillFunction: CABillOfSaleGlobalAutofill, productAutofillFunction: CABillOfSaleAutofillProductDetails,  tradeinAutofillFunction: CABillOfSaleAutofillTradeDetails},
    {slug: 'ca-non-opp', globalAutofillFunction: CACertificateOfNonOperationGlobalAutofill, productAutofillFunction: CACertificateOfNonOperationAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'ca-delivery-statement', globalAutofillFunction: CADeliveryStatementGlobalAutofill, productAutofillFunction: CADeliveryStatementAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'ca-dec-gvw', globalAutofillFunction: CADMVDeclarationOfGVWGlobalAutofill, productAutofillFunction: CADMVDeclarationOfGVWAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'ca-poa', globalAutofillFunction: CAPowerOfAttorneyGlobalAutofill, productAutofillFunction: CAPowerOfAttorneyAutofillProductDetails,  tradeinAutofillFunction: CAPowerOfAttorneyAutofillTradeDetails},
    {slug: 'ca-pti-cert', globalAutofillFunction: CAPTICertGlobalAutofill, productAutofillFunction: CAPTICertAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'ca-resale-cert', globalAutofillFunction: CAResaleCertGlobalAutofill, productAutofillFunction: CaResaleCertAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'ca-sales-tax-statement', globalAutofillFunction: CASalesTaxStatement6247GlobalAutofill, productAutofillFunction: CASalesTaxStatement6247AutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'ca-sales-tax-affidavit', globalAutofillFunction: CASection837SalesTaxAffidavitGlobalAutofill, productAutofillFunction: CASection837SalesTaxAffidavitAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'ca-statement-of-facts', globalAutofillFunction: CAStatementOfFactsGlobalAutofill, productAutofillFunction: CAStatementOfFactsAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'ca-title', globalAutofillFunction: CATitleApp343GlobalAutofill, productAutofillFunction: CATitleApp343AutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'ia-title-app', globalAutofillFunction: IATitleAppGlobalAutofill, productAutofillFunction: IATitleAppAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'irs-8300', globalAutofillFunction: IRS8300GlobalAutofill, productAutofillFunction: null,  tradeinAutofillFunction: null},
    {slug: 'ne-form-6', globalAutofillFunction: NEForm6GlobalAutofill, productAutofillFunction: NEForm6AutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'ne-form-13', globalAutofillFunction: NEForm13GlobalAutofill, productAutofillFunction: NEForm13AutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'ne-poa', globalAutofillFunction: NEPoAGlobalAutofill, productAutofillFunction: NEPoAAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'ne-title-app', globalAutofillFunction: NETitleAppGlobalAutofill, productAutofillFunction: NETitleAppAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'nv-aff-pur', globalAutofillFunction: NVAffPurchaserGlobalAutofill, productAutofillFunction: NVAffPurchaserAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'nv-bill-of-sale', globalAutofillFunction: NevadaBillOfSaleGlobalAutofill, productAutofillFunction: NevadaBillOfSaleAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'nv-erasure-aff', globalAutofillFunction: NVErasureAffidavitGlobalAutofill, productAutofillFunction: NVErasureAffidavitAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'nv-poa', globalAutofillFunction: NVPowerOfAttorneyGlobalAutofill, productAutofillFunction: NVPowerOfAttorneyAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'nv-vehicle-inspection-cert', globalAutofillFunction: NVVehicleInspectionCertificateGlobalAutofill, productAutofillFunction: NVVehicleInspectionCertificateAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'or-bill-of-sale', globalAutofillFunction: OregonBillOfSaleGlobalAutofill, productAutofillFunction: OregonBillOfSaleAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'or-title-app', globalAutofillFunction: OregonTitleGlobalAutofill, productAutofillFunction: OregonTitleAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'or-vin-inspect', globalAutofillFunction: OregonVINGlobalAutofill, productAutofillFunction: OregonVINAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'sd-title-ext', globalAutofillFunction: SD45DayExtGlobalAutofill, productAutofillFunction: SD45DayExtAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'sd-dealer-report', globalAutofillFunction: SDDealerReportGlobalAutofill, productAutofillFunction: SDDealerReportAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'sd-dup-title', globalAutofillFunction: SDDuplicateTitleGlobalAutofill, productAutofillFunction: SDDuplicateTitleAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'sd-poa', globalAutofillFunction: SDPoAGlobalAutofill, productAutofillFunction: SDPoAAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'sd-title-app', globalAutofillFunction: SDTitleAppGlobalAutofill, productAutofillFunction: SDTitleAppAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'tec-affidavit', globalAutofillFunction: TECAffidavitGlobalAutofill, productAutofillFunction: TECAffidavitAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'tec-bill-of-sale', globalAutofillFunction: TECBillOfSaleGlobalAutofill, productAutofillFunction: TECBillOfSaleAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'tec-ca-affidavit-exempt-sales-tax', globalAutofillFunction: TECCAAffExmptSalesTaxGlobalAutofill, productAutofillFunction: TECCAAffExmptSalesTaxAutofillProductDetails,  tradeinAutofillFunction: TECCAAffExmptSalesTaxAutofillTradeDetails},
    {slug: 'tec-ca-sales-tax-exempt-cert', globalAutofillFunction: TECCaliforniaSalesTaxExemptionCertificateGlobalAutofill, productAutofillFunction: TECCaliforniaSalesTaxExemptionCertificateAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'tec-oos-cert-multi', globalAutofillFunction: TECCertOOSMultiGlobalAutofill, productAutofillFunction: TECCertOOSMultiAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'tec-oos-cert', globalAutofillFunction: TECCertOOSSingleGlobalAutofill, productAutofillFunction: TECCertOOSSingleAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'tec-delivery-receipt', globalAutofillFunction: TECDeliveryReceiptGlobalAutofill, productAutofillFunction: TECDeliveryReceiptAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'tec-deposit-receipt', globalAutofillFunction: TECDepositReceiptGlobalAutofill, productAutofillFunction: TECDepositReceiptAutofillProductDetails,  tradeinAutofillFunction: null, depositAutofill: TECDepositReceiptAutofillDeposit}, 
    {slug: 'tec-disclosure-applicability', globalAutofillFunction: TECDoAGlobalAutofill, productAutofillFunction: TECDoAAutofillProductDetails,  tradeinAutofillFunction: TECDoAAutofillTradeDetails},
    {slug: 'tec-insurance-request', globalAutofillFunction: TECInsuranceRequestGlobalAutofill, productAutofillFunction: TECInsuranceRequestAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'tec-auth-payoff', globalAutofillFunction: TECPayOffAuthorizationGlobalAutofill, productAutofillFunction: TECPayOffAuthorizationAutofillProductDetails,  tradeinAutofillFunction: TECPayOffAuthorizationAutofillTradeDetails},
    {slug: 'tec-poa', globalAutofillFunction: TECPowerOfAttorneyGlobalAutofill, productAutofillFunction: TECPowerOfAttorneyAutofillProductDetails,  tradeinAutofillFunction: TECPowerOfAttorneyAutofillTradeDetails},
    {slug: 'tec-sales-order', globalAutofillFunction: TECSalesOrderGlobalAutofill, productAutofillFunction: null,  tradeinAutofillFunction: null},
    {slug: 'tec-sold-as-is', globalAutofillFunction: TECSoldAsIsGlobalAutofill, productAutofillFunction: TECSoldAsIsAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'tec-statement-of-error', globalAutofillFunction: TECStatementOfErrorGlobalAutofill, productAutofillFunction: TECStatementOfErrorAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'tec-tax-waiver', globalAutofillFunction: TECTaxWaiverGlobalAutofill, productAutofillFunction: TECTaxWaiverAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'tec-vehicle-sales-doc', globalAutofillFunction: TECVehicleSalesDocumentGlobalAutofill, productAutofillFunction: TECVehicleSalesDocumentAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'tec-wa-trailer-weight-slip', globalAutofillFunction: TECWAWeightSlipTrailersGlobalAutofill, productAutofillFunction: TECWAWeightSlipTrailersAutofillProductDetails,  tradeinAutofillFunction: TECWAWeightSlipTrailersAutofillTradeDetails},
    {slug: 'wa-release-interest-poa', globalAutofillFunction: WAReleaseOfInterestPowerOfAttorneyGlobalAutofill, productAutofillFunction: WAReleaseOfInterestPowerOfAttorneyAutofillProductDetails,  tradeinAutofillFunction: WAReleaseOfInterestPowerOfAttorneyAutofillTradeDetails},
    {slug: 'wa-sales-tax', globalAutofillFunction: WASalesTaxGlobalAutofill, productAutofillFunction: WASalesTaxAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'wa-title-app', globalAutofillFunction: WAVehicleTitleApplicationGlobalAutofill, productAutofillFunction: WAVehicleTitleApplicationAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'wa-title-app-additional', globalAutofillFunction: WAVehicleTitleApplicationAdditionalGlobalAutofill, productAutofillFunction: WAVehicleTitleApplicationAdditionalAutofillProductDetails,  tradeinAutofillFunction: null},
    {slug: 'wa-wholesale', globalAutofillFunction: WAWholeSaleGlobalAutofill, productAutofillFunction: WAWholeSaleAutofillProductDetails,  tradeinAutofillFunction: WAWholeSaleAutofillTradeDetails},
    {slug: 'wa-bill-of-sale', globalAutofillFunction: WABillOfSaleGlobalAutofill, productAutofillFunction: WABillOfSaleAutofillProductDetails,  tradeinAutofillFunction: WABillOfSaleAutofillTradeDetails},
    {slug: 'tec-invoice-template', globalAutofillFunction: generateInvoice, productAutofillFunction: null,  tradeinAutofillFunction: null},
   ]

   function getProductArray(order, doc){
     if(doc && doc.itemIds){
      let foundProductOptions = [];
      var itemIds = doc.itemIds.split(',');
      if(itemIds.length > 0){
        itemIds.forEach((product_id, i) => {
          var foundProduct = order?.products?.find((product) => product.id === Number(product_id));
          if(foundProduct){
            foundProductOptions.push(foundProduct);
          }
        });
        return foundProductOptions;
      }
    }
    return [];
   }

   function getTradeinArray(order, doc){
    if(doc && doc.tradeinIds){
      let foundTradeinOptions = [];
      var tradeinIds = doc.tradeinIds.split(',');
      if(tradeinIds.length > 0){
        tradeinIds.forEach((tradein_id, i) => {
          var foundTradein = order?.tradeins?.find((tradein) => tradein.id === Number(tradein_id));
          if(foundTradein){
            foundTradeinOptions.push(foundTradein);
          }
        });
        return foundTradeinOptions;
      }
    }
    return [];
  }
  

  return (
    <>
          <div
            className="modal fade"
            id="copychangestodocuments"
            tabIndex="-1"
            aria-labelledby="copychangestodocuments"
            aria-hidden="true"
            data-bs-backdrop="static" 
            data-bs-keyboard="false"
          >
            <div className="modal-dialog modal-xl modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header bg-primary text-white">
                      <h5
                        className="modal-title"
                        id="exampledownpaymentmodel"
                      >
                        Copy Changes To documents
                      </h5>
                      <button
                        type="button"
                        className="btn-close bg-white"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        disabled={Number(totalDocuments) !== Number(documentsSavedCount)}
                        onClick={() => {
                          resetModal();
                        }}
                      ></button>
                    </div>
                    { appData?.activeModal === 'copy-to-documents' ? (
                    <>
                      {copySection === 'product'? (
                        <ProductSubComponent correctSlug={correctSlug} getProductArray={getProductArray} getTradeinArray={getTradeinArray} documentAutofillMap={documentAutofillMap} documents={documents} getDocuments={getDocuments} setTotalDocuments={setTotalDocuments} totalDocuments={totalDocuments} documentsSavedCount={documentsSavedCount} setDocumentsSavedCount={setDocumentsSavedCount} resetModal={resetModal} />
                      ): copySection === 'customer' || copySection === 'tradein' || copySection === 'details' || copySection === 'downpayment' ? (
                        <CustomerSubComponent correctSlug={correctSlug} getProductArray={getProductArray} getTradeinArray={getTradeinArray} documentAutofillMap={documentAutofillMap} documents={documents} getDocuments={getDocuments} setTotalDocuments={setTotalDocuments} totalDocuments={totalDocuments} documentsSavedCount={documentsSavedCount} setDocumentsSavedCount={setDocumentsSavedCount} resetModal={resetModal} />
                      ):null}
                    </>
                  ):null}
                </div>
            </div>
          </div>
    </>
  );
}

const ProductSubComponent = ({documents, getDocuments, documentAutofillMap, setTotalDocuments, totalDocuments, documentsSavedCount, setDocumentsSavedCount, resetModal, getProductArray, getTradeinArray, correctSlug}) => {
  const dispatch  = useDispatch();
  const order   = useOrder();

  const copySection = useSelector((state) => state?.copyChangesToDocumentsRedux.copySection);  
  // const copySubSection = useSelector((state) => state?.copyChangesToDocumentsRedux.copySubSection);  
  const groupChangesArray = useSelector((state) => state?.copyChangesToDocumentsRedux.groupChangesArray);
  const allLocations = JSON.parse(localStorage.getItem("alllocations"));
  // const location = JSON.parse(localStorage.getItem("location"));
  const salesReps = useSalesReps();
  const saveStatus = useSelector((state) => state?.copyChangesToDocumentsRedux.status);  

  const [productIndex, setProductIndex] = useState(0);
  const [propertyIndex, setPropertyIndex] = useState(0);
  const [previousPropertySaved, setPreviousPropertySaved] = useState(false);
  const [copyAllProcessing, setCopyAllProcessing] = useState(false);
  const [copyAll, setCopyAll] = useState(false);

  // const taxExemptReasons = JSON.parse(localStorage.getItem("taxExemptReasons"));

  const [location, setLocation] = useState(null)
  const [loadingLocation, setLoadingLocation] = useState(false)

  useEffect(() => {
    async function updateLocation(locationCode){

        setLoadingLocation(true);

        let foundLocation = allLocations.find(l => l.locationCode === locationCode);
        if(foundLocation){
          try{
            let res = await api.getTecRegion(foundLocation?.locationCode)
            let region = res?.data;
            foundLocation.email = region?.email;
            setLocation(foundLocation);
            setLoadingLocation(false);
        }catch(err) {}
      }

    }

    if(allLocations?.length > 0 && (location === null || location?.locationCode !== order?.tecLocationCode) && loadingLocation === false && order?.tecLocationCode){
      updateLocation(order.tecLocationCode);
    }

  },[loadingLocation, location, allLocations, order.tecLocationCode]);

  // function resetModal(resetReduxSaverVar=false){
  //   setProductIndex(0);
  //   setPropertyIndex(0);
  //   // getDocuments();
  //   setPreviousPropertySaved(false);
  //   // dispatch(setActiveModal(null));
  //   // if(resetReduxSaverVar === true){
  //   //   resetReduxSaver();
  //   // }
  // }

  function findProductProperties(includeStats=false){
    let newArray = [];

    if(groupChangesArray && Object.entries(groupChangesArray).length > 0 && productIndex >= 0){
      Object.entries(Object.entries(groupChangesArray)[productIndex][1]).forEach( prop => {
        if(prop[0] !== 'id' && prop[0] !== 'stockNumber' && prop[0] !== 'propertySaved' && prop[0] !== 'docsSavedCount'){
          newArray.push(prop);
        }

        if(includeStats === true && (prop[0] === 'propertySaved' || prop[0] === 'docsSavedCount')){
          newArray.push(prop);
        }
      });
    }
    return newArray;
  }

  function hasPreviousProduct(){
    let prevProductIndex = productIndex - 1;

    if(prevProductIndex >= 0){
      return true;
    }
    return false;
  }

  function previousProduct(){

    let prevProductIndex = productIndex - 1;

    if(prevProductIndex >= 0){
      setProductIndex(prevProductIndex);
      setPropertyIndex(0);
      setPreviousPropertySaved(false);
    }
  }

  function hasNextProduct(){

    let nextProductIndex = productIndex + 1;

    let totalProducts = Object.entries(groupChangesArray).length;
    if(nextProductIndex < totalProducts){
      return true;
    }
    return false;
  }

  function nextProduct(){


    let nextProductIndex = productIndex + 1;

    let totalProducts = Object.entries(groupChangesArray).length;
    if(nextProductIndex < totalProducts){
      setProductIndex(nextProductIndex);
      // loadProperties(nextProductIndex);
      setPropertyIndex(0);
      setPreviousPropertySaved(false);
    }
  }

  function hasPreviousProperty(){
    let prevPropertyIndex = propertyIndex - 1;

    if(prevPropertyIndex >= 0){
      return true;
    }
    return false;
  }

  function previousProperty(){

    let prevPropertyIndex = propertyIndex - 1;

    if(prevPropertyIndex >= 0){
      // setCurrentProperty(prevPropertyIndex);
      setPropertyIndex(prevPropertyIndex);

      setPreviousPropertySaved(false);
    }
  }

  function hasNextProperty(){

    let nextPropertyIndex = propertyIndex + 1;
    let productProperties = findProductProperties();
    if(productProperties && Object.entries(productProperties) && Object.entries(productProperties).length > 0){
      let totalProperties = Object.entries(productProperties).length;
      if(nextPropertyIndex < totalProperties){
        return true;
      }
    }

    return false;
  }

  function nextProperty(){

    let nextPropertyIndex = propertyIndex + 1;
    let productProperties = findProductProperties();
    if(productProperties && Object.entries(productProperties) && Object.entries(productProperties).length > 0){
      let totalProperties = Object.entries(productProperties).length;
      if(nextPropertyIndex < totalProperties){
        setPropertyIndex(nextPropertyIndex);
        setPreviousPropertySaved(false);
      }
    }
  }

  //
  function currentProductPropertySaved(){
    let returnValue = false;
    let productProperties = findProductProperties(true);

    if(productProperties.length > 0 && productIndex >= 0 && propertyIndex >= 0){
      if(groupChangesArray[productIndex]){
        returnValue = groupChangesArray[productIndex].propertySaved;
      }
    }



    return returnValue;
  }


  function currentDocumentSaved(){
    if(groupChangesArray[productIndex] && groupChangesArray[productIndex].length > 0 && productIndex >= 0 && propertyIndex >= 0){
      if(groupChangesArray[productIndex][propertyIndex].docsSavedCount && groupChangesArray[productIndex][propertyIndex].docsSavedCount >= 0){
        return groupChangesArray[productIndex][propertyIndex].docsSavedCount;
      }
    }
    return 0;
  }

  function previousDocumentSaved(){
    if(groupChangesArray[productIndex] && groupChangesArray[productIndex].length > 0 && productIndex >= 0 && propertyIndex >= 1){
      let previousPropertyIndex = propertyIndex - 1;
      if(groupChangesArray[productIndex][previousPropertyIndex].docsSavedCount && groupChangesArray[productIndex][previousPropertyIndex].docsSavedCount >= 0){
        return groupChangesArray[productIndex][previousPropertyIndex].docsSavedCount;
      }
    }
    return 0;
  }

  function filterDocuments(productId=null){
    if(productId === null && groupChangesArray){
      productId = Object.entries(groupChangesArray)[productIndex][1].id; 
    }

    let orderDocuments = filterProductDocumentsForOrder(productId, documents);
    let productDocuments = filterDocumentsForProduct(productId, documents);


    let allFilteredDocuments = [];

    if(orderDocuments.length > 0){
      allFilteredDocuments = allFilteredDocuments.concat(orderDocuments);
    }

    if(productDocuments.length > 0){
      allFilteredDocuments = allFilteredDocuments.concat(productDocuments);
    }



    return allFilteredDocuments;

    // return documents.filter(document => {
    //   if(document && document.assignedObjectId && document.assignedObjectId === productId && document.assignedObjectType && document.assignedObjectType === 'product' && (document?.documentStatus?.toLowerCase() === 'generated' || document?.documentStatus?.toLowerCase() === 'prepared')){
    //     return document;
    //   }
    //   return null;
    // })

  }

  function totalDocumentCount(){

    let totalDocuments = 0;

    Object.entries(groupChangesArray).forEach( (key, copyProductIndex, array) => {
      let currentProduct = key[1];

      let filteredDocuments = filterDocuments(currentProduct.id);

      totalDocuments += filteredDocuments.length;

    });

    return totalDocuments;
  }

  function getCurrentProduct(){
    return groupChangesArray[productIndex];
  }

  


  function applyPropertyToDocument(property, value, document, _callback){
    let documentValues = JSON.parse(document.payload);
    let newValues = null;

    if(groupChangesArray && groupChangesArray.length > 0 && groupChangesArray[productIndex] && groupChangesArray[productIndex].id ){
      
      let findBySlug = document?.documentTemplate?.slug;

      findBySlug = correctSlug(findBySlug);

      let documentFunctions = documentAutofillMap.find( d => d.slug === findBySlug);
      if(documentFunctions){
        if(document?.documentTemplate?.slug?.includes('tec-sales-order')){
          newValues = documentFunctions.globalAutofillFunction(document, documentValues, order, property, value, location, allLocations, salesReps, null, order?.products?.length, document.assignedObjectType);
          newValues = calculateTotalsFunction(document, getProductArray(order, document), getTradeinArray(order, document), order, documentValues, location);
        }else if(document?.documentTemplate?.slug?.includes('tec-invoice-template')){
          newValues = documentFunctions.globalAutofillFunction(order, document, documentValues, null, null, salesReps, location);
        }else{
          newValues = documentFunctions.globalAutofillFunction(documentValues, order, property, value, location, allLocations, salesReps, null, order?.products?.length, document.assignedObjectType);
        }


        if(copySection === 'product'){
          let foundProduct = order.products.find(p => p.id === (groupChangesArray[productIndex]).id);
          if(foundProduct){
            if(documentFunctions.productAutofillFunction !== null){
              newValues = documentFunctions.productAutofillFunction(foundProduct, newValues, property, value, location, order, null, order?.products?.length);

            }
          }
        }

        if(copySection === 'tradein'){

          let foundTradein = order.tradeins.find(p => p.id === (groupChangesArray[productIndex]).id);
          if(foundTradein){
            if(documentFunctions.tradeinAutofillFunction !== null){
              newValues = documentFunctions.tradeinAutofillFunction(foundTradein, newValues, property, value, location);

            }
          }
          
        }

        if(documentFunctions?.depositAutofill && documentFunctions?.depositAutofill !== null){
          let foundProduct = order.products.find(p => p.id === (groupChangesArray[productIndex]).id);
          if(foundProduct){
            if(foundProduct?.deposits && foundProduct?.deposits.length > 0){
              let foundDeposit = foundProduct.deposits[0];
              if(foundDeposit){
                newValues = documentFunctions.depositAutofill(foundDeposit, newValues, property, value);
              }
            }
          }
        }

        DocumentHandleSubmit(document, newValues, null, () => {});
      
      }
    }

    _callback();
  }

  function savePropertyToAllDocuments(productId, passedProductIndex=0, passedPropertyIndex=0, propertyName=null, propertyValue=null, _callback){ 
    
    if(passedProductIndex === null || passedPropertyIndex === null){
      passedProductIndex = productIndex;
      passedPropertyIndex = propertyIndex;
    }

    if(propertyName === null || propertyValue === null){
      // let propertyFound = Object.entries(Object.entries(groupChangesArray)[passedProductIndex][1])[passedPropertyIndex];
      propertyName = getCurrentPropertyName();
      propertyValue = getCurrentPropertyValue();
    }

    var savePropertyToAllDocumentsPromise = new Promise(async (resolve) => {
      let filteredDocuments = filterDocuments(productId);

      if(filteredDocuments?.length > 0){
        filteredDocuments.forEach(async (document, index, array) => {
          applyPropertyToDocument(propertyName, propertyValue, document, () => {


            // if(groupChangesArray[passedProductIndex][passedPropertyIndex][2]){
              let newValues = groupChangesArray;


              Object.entries(Object.entries(newValues)[passedProductIndex][1])[passedPropertyIndex].propertySaved = true;
              Object.entries(Object.entries(newValues)[passedProductIndex][1])[passedPropertyIndex].docsSavedCount++;

              dispatch(updateGroupChangesArray(newValues));

            // }else{
            //   groupChangesArray[passedProductIndex][passedPropertyIndex][] = true;
            // }

            // if(groupChangesArray[passedProductIndex][passedPropertyIndex][3]){
            //   groupChangesArray[passedProductIndex][passedPropertyIndex][3]++;
            // }else{
            //   groupChangesArray[passedProductIndex][passedPropertyIndex][] = 1;
            // }

            // groupChangesArray[passedProductIndex][passedPropertyIndex][3]++;
            if (index === array.length -1) resolve();
          });
        });
      }
    });

    savePropertyToAllDocumentsPromise.then( () => {
      _callback();
    });
  }


  const confirmChanges = async (e) => {
    e.preventDefault();


    savePropertyToAllDocuments(Object.entries(groupChangesArray)[productIndex].id, null, null, null, null, () => {
      getDocuments();
      dispatch(updateStatus('copied'))
    })
  }

  // function findProperties(includeStats=false){
  //   let newArray = [];

  //   if(copyData && Object.entries(copyData).length > 0){
  //     Object.entries(copyData).forEach( prop => {

  //       if(prop[0] !== 'id' && prop[0] !== 'stockNumber' && prop[0] !== 'propertySaved' && prop[0] !== 'docsSavedCount'){
  //         newArray.push(prop);
  //       }

  //       if(includeStats && (prop[0] === 'propertySaved' || prop[0] === 'docsSavedCount')){
  //         newArray.push(prop);
  //       }
  //     });
  //   }
  //   return newArray;
  // }
  // const copyPropertiesToDocuments = async (productId, productIndex, productProperties, productDocuments) => {

  function copyPropertiesToDocuments(productId, productIndex, productProperties, productDocuments, _callback){
    // let filteredProperties = findProperties();

    let foundProduct = order.products.find(p => p.id === productId);

    if(!foundProduct){
      return null;
    }
    let documentSavedCountLocal = 0;

    productDocuments.forEach( (doc, index, array) => {

      let documentValues = JSON.parse(doc.payload);
      let newValues = null;

      
      Object.entries(productProperties).forEach( (xey, propertyIndex) => {

        let propertyName = xey[0];

        if(propertyName === 'id' || propertyName === 'stockNumber' || propertyName === 'propertySaved' || propertyName === 'docsSavedCount'){
          return;
        }
        
        let propertyValue = "";
        if(propertyName !== 'deposits'){
          if(!xey[1]?.newValue){
            return;
          }
          propertyValue = xey[1].newValue;
        } 

        let findBySlug = doc?.documentTemplate?.slug;

        findBySlug = correctSlug(findBySlug);

        let documentFunctions = documentAutofillMap.find( d => d.slug === findBySlug);
        if(documentFunctions){
          if(doc?.documentTemplate?.slug?.includes('tec-sales-order')){
            newValues = documentFunctions.globalAutofillFunction(doc, documentValues, order, propertyName, propertyValue, location, allLocations, salesReps, null, order?.products?.length, doc.assignedObjectType);
            newValues = calculateTotalsFunction(doc, getProductArray(order, doc), getTradeinArray(order, doc), order, newValues, location);
          }else if(doc?.documentTemplate?.slug?.includes('tec-invoice-template')){
            var invoiceResetParameters = {};
            invoiceResetParameters.showSalesTaxPerUnitPrice = documentValues.showSalesTaxPerUnitPrice;
            newValues = documentFunctions.globalAutofillFunction(order, doc, invoiceResetParameters, null, null, salesReps, location);
          }else{
            newValues = documentFunctions.globalAutofillFunction(documentValues, order, propertyName, propertyValue, location, allLocations, salesReps, null, order?.products?.length, doc.assignedObjectType);
          }
         
          if(documentFunctions.productAutofillFunction !== null && doc.assignedObjectType === 'product'){
            let foundProduct = order.products.find(p => p.id === doc.assignedObjectId);
            if(foundProduct){
              newValues = documentFunctions.productAutofillFunction(foundProduct, newValues, propertyName, propertyValue, location, order, null, order?.products?.length);
              if(foundProduct?.deposits && foundProduct?.deposits.length > 0){
                let foundDeposit = foundProduct.deposits[0];
                if(foundDeposit){
                  newValues = documentFunctions.depositAutofill(foundDeposit, newValues, propertyName, propertyValue);
                }
              }
            }
          }
    
          if(documentFunctions.tradeinAutofillFunction !== null && doc.assignedObjectType === 'tradein'){
            let foundTradein = order.tradeins.find(t => t.id === doc.assignedObjectId);
            if(foundTradein){ 
              newValues = documentFunctions.tradeinAutofillFunction(foundTradein, newValues, propertyName, propertyValue, location);
            }
          }

        }

      });

      DocumentHandleSubmit(doc, newValues, null, () => {
        documentSavedCountLocal++;
        setDocumentsSavedCount((documentsSavedCount + documentSavedCountLocal));
        if(index === (array.length - 1)){
          _callback(documentSavedCountLocal);
        }
      });

    })
  }

  const copyAllFunction = async (e) => {
    e.preventDefault();

    // Reset feedback variables
    setTotalDocuments(0);
    setDocumentsSavedCount(0);

    // Set state variables
    setCopyAllProcessing(true);
    setCopyAll(true);

    // Local function counter
    let totalDocuments = 0;

    let localSavedDocumentCount = 0;

    // Loop Each Product
    Object.entries(groupChangesArray).forEach( (key, copyProductIndex, array) => {
      let currentProduct = key[1];

      let filteredDocuments = filterDocuments(currentProduct.id);

      totalDocuments += filteredDocuments.length;

      copyPropertiesToDocuments(currentProduct.id, copyProductIndex, currentProduct, filteredDocuments, (passedCount) => {
        localSavedDocumentCount += passedCount;
        if(copyProductIndex === (array.length - 1)){
          setCopyAllProcessing(false);
        }
      });
      
    });

    setDocumentsSavedCount((documentsSavedCount + localSavedDocumentCount));

    setTotalDocuments(totalDocuments);

    getDocuments();
  }

  function getCurrentPropertyName(){
    let productProperties = findProductProperties()
    let propertyObj =  productProperties[propertyIndex];
    let propertyName = propertyObj[0];
    let fullString = "";
    if(propertyName.includes(".")){
      let propertyNameArray = propertyName.split(".");
      fullString = capitalizeFirstLetter(propertyNameArray[0])+": "+capitalizeFirstLetter(propertyNameArray[1]);
      
      if(propertyNameArray[2]){
        fullString += ": "+capitalizeFirstLetter(propertyNameArray[2]);
      }
      
    }else{
      fullString = capitalizeFirstLetter(propertyName);
    }

    return fullString;
  }

  function getCurrentPropertyValue(formatted=false){
    let productProperties = findProductProperties()
    let propertyObj =  productProperties[propertyIndex];

    let propertyName = propertyObj[0];

    let value = propertyObj[1].newValue;

    if(formatted === true){
      
      let moneyArray = ["price"];

      if(moneyArray.indexOf(propertyName) >= 0){
        value = formatter.format(value);
      }

      if(propertyName === "fundingMethod"){
        value = value.name;
        value = value.replace('-', ' ');
        value = value.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
      }

      if(propertyName === "fundingDetails.finalized"){
        value = value.name;
        value = capitalizeFirstLetter(value);
      }
    }
    
    return value;
  }

  return (
    <>

      <div className="modal-body">
        <div className="container">
          <div className="text-center mx-auto">
            { !copyAll && groupChangesArray && Object.entries(groupChangesArray).length === 1 ? (
              <>
                <p  className="my-0 py-0" style={{ fontSize: '18px' }}>Product "{getCurrentProduct()?.stockNumber}"</p><br />
              </>
            ): !copyAll && groupChangesArray && Object.entries(groupChangesArray).length > 1 ? (
              <>
                <div className="px-3 my-0 py-0 section_main mx-auto text-center">
                    <MobileStepper
                      className="mx-auto"
                      variant="dots"
                      steps={Object.entries(groupChangesArray).length}
                      position="static"
                      activeStep={productIndex}
                      sx={{ maxWidth: 'fit-content', flexGrow: 1 }}
                    />
                </div>
                <p  className="my-0 py-0" style={{ fontSize: '18px' }}>Product "{getCurrentProduct()?.stockNumber}" { (productIndex ?? 0) + 1} of {Object.entries(groupChangesArray)?.length ?? "--"}</p><br />
              </>
            ):null}
            
            <div className="px-3 my-0 py-0 section_main mx-auto text-center" style={{ width: '100%' }}>
            { !copyAll && findProductProperties().length === 1 ? (
              <>
                <p className="my-0 py-0" style={{ fontSize: '18px' }}>Field "<span style={{ fontWeight: 'bold' }}>{getCurrentPropertyName()}</span>"</p><br />
                <p className="my-0 py-0"style={{ fontWeight: 'bold', fontSize: '16px' }}>{getCurrentPropertyValue(true) ?? ''}</p><br />
              </>
            ): !copyAll && findProductProperties().length > 1 ? (
              <>
                <div className="mx-auto text-center my-0 py-0">
                  <MobileStepper
                    className="mx-auto my-1 py-0 "
                    variant="dots"
                    steps={findProductProperties().length}
                    position="static"
                    activeStep={propertyIndex}
                    sx={{ maxWidth: 'fit-content', flexGrow: 1 }}
                    />
                </div>
                <p className="my-0 py-0" style={{ fontSize: '18px' }}>Field "<span style={{ fontWeight: 'bold' }}>{getCurrentPropertyName()}</span>" { (propertyIndex ?? 0) + 1} of {findProductProperties().length ?? "--"}</p><br />
                <p className="my-0 py-0"style={{ fontWeight: 'bold', fontSize: '16px' }}>{getCurrentPropertyValue(true) ?? ''}</p><br />
              </>
            ):null}
              
            </div>
            { !copyAll && currentProductPropertySaved() ? (
              <>
                <p>{currentDocumentSaved()} Documents Updated.</p><br />
              </>
            ): !copyAll && saveStatus === "copy" ? (
              <>
                <p>Copy to {totalDocumentCount()} documents?</p><br />
              </>
            ): null}
          </div>
          { !copyAll && previousPropertySaved ? (
          <div className="mx-auto text-center" style={{ width: '100%' }}>
            <hr></hr>
            <p style={{ color: 'green', fontSize: '11px' }}>Previous - {previousDocumentSaved()} Documents Updated.</p>
          </div>
          ):null}

          { copyAll ? (
            <div className="mx-auto text-center" style={{ width: '100%' }}>
              <p style={{ color: 'green', fontSize: '18px' }}>{documentsSavedCount} / {totalDocumentCount()} Documents Updated Successfully.</p>
              { copyAllProcessing === true || Number(totalDocuments) !== Number(documentsSavedCount) ? (
                <CircularProgress />
              ):null}
            </div>
          ):null}
        </div>
      </div>
      <div className="modal-footer text-center">


        <div className="mx-auto d-flex justify-content-evenly" style={{ width: '100%' }}>
          {!copyAll ? (

            <div>
              <button
                  type="button"
                  className="btn btn-primary mx-1 btn-sm"
                  disabled={ (!hasPreviousProduct()) }
                  // disable={saveStatus.includes('-group')}
                  // data-bs-dismiss="modal"
                  onClick={() => {
                    previousProduct();
                  }}
                  >
                  Previous {capitalizeFirstLetter(copySection)}
              </button>

              <button
                type="button"
                className="btn btn-primary mx-1 btn-sm"
                disabled={ (!hasPreviousProperty()) }
                
                // disable={saveStatus.includes('-group')}
                // data-bs-dismiss="modal"
                onClick={() => {
                  previousProperty();
                }}
                >
                Previous Field
              </button>
            </div>
          ):null}

          { !copyAll && saveStatus === "copy" && !currentProductPropertySaved() ? (
            <>
              <div>
                <button
                  type="button"
                  className="btn btn-primary mx-1 btn-sm"
                  onClick={(e) => confirmChanges(e)}
                  // disable={saveStatus.includes('-group')}
                  disable="true"
                  
                  // data-bs-dismiss="modal"
                  // data-bs-target="#productModal"
                  >
                  Copy
                </button>
                              
                <button
                  type="button"
                  className="btn btn-primary mx-1"
                  disable="true"
                  onClick={(e) => {
                    setPreviousPropertySaved(false);
                    confirmChanges(e);
                    if(hasNextProperty()){
                      nextProperty();
                      setPreviousPropertySaved(true);
                    }else if(hasNextProduct()){
                      nextProduct();
                      setPreviousPropertySaved(true);
                    }
                  }}
                  // disable={saveStatus.includes('-group') || (!hasNextProperty() && !hasNextProduct())}
                  // data-bs-dismiss="modal"
                  // data-bs-target="#productModal"
                  >
                  Copy & Next
                </button>

                <button
                  type="button"
                  className="btn btn-primary mx-1 btn-sm"
                  onClick={(e) => copyAllFunction(e)}
                  disable={saveStatus.includes('-group')}
                  
                  // data-bs-dismiss="modal"
                  // data-bs-target="#productModal"
                  >
                  Copy All
                </button>
              </div>
            </>
          ): !copyAll && currentProductPropertySaved() ? (
            <button
            type="button"
            className="btn btn-success"
            // onClick={(e) => confirmChanges(e)}
            disable={true}
            // data-bs-dismiss="modal"
            // data-bs-target="#productModal"
            >
              Documents Updated
            </button>
          ): copyAll === true && copyAllProcessing === false && Number(totalDocuments) === Number(documentsSavedCount) ? (
            <button
            type="button"
            className="btn btn-primary"
            onClick={(e) => resetModal(true)}
            data-bs-dismiss="modal"
            >
              Close
            </button>
          ):null}

          {!copyAll ? (
            <div>
              <button
                type="button"
                className="btn btn-primary mx-1 btn-sm"
                // disable={saveStatus.includes('-group')}
                // data-bs-dismiss="modal"
                disabled={ (!hasNextProperty()) }
                
                onClick={() => {
                  nextProperty();
                }}
              >
                Next Field
              </button>
              
              <button
                  type="button"
                  className="btn btn-primary mx-1 btn-sm"
                  disabled={ (!hasNextProduct()) }
                  
                  // disable={saveStatus.includes('-group')}
                  // data-bs-dismiss="modal"
                  onClick={() => {
                    nextProduct();
                  }}
                  >
                  Next {capitalizeFirstLetter(copySection)}
              </button>
            </div>
          ):null}
        </div>
      </div>
    </>

)}

const CustomerSubComponent = ({documents, getDocuments, documentAutofillMap, setTotalDocuments, totalDocuments, documentsSavedCount, setDocumentsSavedCount, resetModal, getProductArray, getTradeinArray, correctSlug}) => {
  const dispatch            = useDispatch();
  const order               = useOrder();
  const salesReps           = useSalesReps();
  const salesManagers       = useSalesManagers()
  const orderStatuses       = useOrderStatuses()


  // const copySection = useSelector((state) => state?.copyChangesToDocumentsRedux.copySection);  
  const copyData = useSelector((state) => state?.copyChangesToDocumentsRedux.changesArray);
  const saveStatus = useSelector((state) => state?.copyChangesToDocumentsRedux.status);  
  const allLocations = JSON.parse(localStorage.getItem("alllocations"));
  // const location = JSON.parse(localStorage.getItem("location"));

  const [copyAll, setCopyAll] = useState(false);
  const [copyAllProcessing, setCopyAllProcessing] = useState(false);
  const [propertyIndex, setPropertyIndex] = useState(0);
  const [previousPropertySaved, setPreviousPropertySaved] = useState(false);

  const [location, setLocation] = useState(null)
  const [loadingLocation, setLoadingLocation] = useState(false)

  useEffect(() => {
    async function updateLocation(locationCode){

        setLoadingLocation(true);

        let foundLocation = allLocations.find(l => l.locationCode === locationCode);
        if(foundLocation){
          try{
            let res = await api.getTecRegion(foundLocation?.locationCode)
            let region = res?.data;
            foundLocation.email = region?.email;
            setLocation(foundLocation);
            setLoadingLocation(false);
        }catch(err) {}
      }

    }

    if(allLocations?.length > 0 && (location === null || location?.locationCode !== order?.tecLocationCode) && loadingLocation === false && order?.tecLocationCode){
      updateLocation(order.tecLocationCode);
    }

  },[loadingLocation, location, allLocations, order.tecLocationCode]);


  function findProperties(includeStats=false){
    let newArray = [];



    if(copyData && Object.entries(copyData).length > 0){
      Object.entries(copyData).forEach( prop => {

        if(prop[0] !== 'id' && prop[0] !== 'stockNumber' && prop[0] !== 'propertySaved' && prop[0] !== 'docsSavedCount'){
          newArray.push(prop);
        }

        if(includeStats && (prop[0] === 'propertySaved' || prop[0] === 'docsSavedCount')){
          newArray.push(prop);
        }
      });
    }
    return newArray;
  }

  function hasPreviousProperty(){
    let prevPropertyIndex = propertyIndex - 1;

    if(prevPropertyIndex >= 0){
      return true;
    }
    return false;
  }

  function previousProperty(){

    let prevPropertyIndex = propertyIndex - 1;

    if(prevPropertyIndex >= 0){
      // setCurrentProperty(prevPropertyIndex);
      setPropertyIndex(prevPropertyIndex);

      setPreviousPropertySaved(false);
    }
  }

  function hasNextProperty(){

    let nextPropertyIndex = propertyIndex + 1;
    if(copyData && Object.entries(copyData) && Object.entries(copyData).length > 0){
      let totalProperties = Object.entries(copyData).length;
      if(nextPropertyIndex < totalProperties){
        return true;
      }
    }

    return false;
  }

  function nextProperty(){

    let nextPropertyIndex = propertyIndex + 1;
    if(copyData && Object.entries(copyData) && Object.entries(copyData).length > 0){
      let totalProperties = Object.entries(copyData).length;
      if(nextPropertyIndex < totalProperties){
        setPropertyIndex(nextPropertyIndex);
        setPreviousPropertySaved(false);
      }
    }
  }

  // function filterDocuments(){
  //     return documents.filter(document => {
  //       if(document && (document?.documentStatus?.toLowerCase() === 'generated' || document?.documentStatus?.toLowerCase() === 'prepared')){
  //         return document;
  //       }
  //       return null;
  //     })
  // }

  function filterDocuments(){
    let filtered = filterDocumentsGeneral(documents);


    return filtered;
  }

  // function getCurrentPropertyName(){
  //   let foundProperties = findProperties()
  //   let propertyObj =  foundProperties[propertyIndex];
  //   return propertyObj[0];
  // }

  function getCurrentPropertyName(){
    let productProperties = findProperties()
    let propertyObj =  productProperties[propertyIndex];
    let propertyName = propertyObj[0];

    let fullString = "";
    if(propertyName.includes(".")){
      let propertyNameArray = propertyName.split(".");
      fullString = capitalizeFirstLetter(propertyNameArray[0])+": "+capitalizeFirstLetter(propertyNameArray[1]);
        
      if(propertyNameArray[2]){
        fullString += ": "+capitalizeFirstLetter(propertyNameArray[2]);
      }
          
    }else{
      fullString = capitalizeFirstLetter(propertyName);
    }

    if(fullString === "OrderStatusId"){
      fullString = "Order Status";
    }

    if(fullString === "TecLocationCode"){
      fullString = "Order Location";
    }

    if(fullString === "SalesRepId"){
      fullString = "Order Sales Rep";
    }

    if(fullString === "SalesManagerId"){
      fullString = "Order Sales Manager";
    }

    if(fullString === "OrderDate"){
      fullString = "Order Date";
    }

    if(fullString === "DocumentationDate"){
      fullString = "Documentation Date";
    }

    if(fullString === "DateFinalized"){
      fullString = "Date Finalized";
    }

    return fullString;
  }

  function getCurrentPropertyValue(formatted=false){
    let productProperties = findProperties()
    let propertyObj =  productProperties[propertyIndex];
    
    let propertyName = propertyObj[0];
    
    let value = propertyObj[1].newValue;
    
    if(formatted === true){
      
      if(propertyName === "customer.type"){
        value = capitalizeFirstLetter(value);
      }

      if(propertyName === "orderStatusId"){
        let orderStatus = orderStatuses.find(sr => sr.id === value);
        value = orderStatus?.name;
      }

      if(propertyName === "location"){
        value = value?.description;
      }

      if(propertyName === "salesRepId"){
        let salesRep = salesReps.find(sr => sr.employee_ID === value);
        if(salesRep){
          value = salesRep.employee_ID +": " + (salesRep.preferredName !== null ? salesRep.firstName+' ('+salesRep.preferredName+') '+salesRep.lastName : salesRep.firstName+' '+salesRep.lastName);
        }
      }

      if(propertyName === "salesManagerId"){
        let salesManager = salesManagers.find(sr => sr.employee_ID === value);
        if(salesManagers){
          value = salesManager.employee_ID +": " + (salesManager.preferredName !== null ? salesManager.firstName+' ('+salesManager.preferredName+') '+salesManager.lastName : salesManager.firstName+' '+salesManager.lastName);
        }
      }

      if(propertyName === "downpayment.paymentMethod"){
          value = value.name;
      }

      let moneyArray = ["tradein.balanceOwed", "tradein.tradeAllowanceAtTerms", "tradein.cashRefundToCustomer", "price", "product.price", "downpayment.amount"];

      if(moneyArray.indexOf(propertyName) >= 0){
        value = formatter.format(value);
      }

      let dateArray = ["orderDate", "documentationDate", "dateFinalized"];

      if(dateArray.indexOf(propertyName) >= 0){
        value = moment.utc(value).format("MM/DD/YY");
      }

    }
    
    return value;
  }

  function applyPropertyToDocument(property, value, document, _callback){
    let documentValues = JSON.parse(document.payload);
    let newValues = null;

    let findBySlug = document?.documentTemplate?.slug;

    findBySlug = correctSlug(findBySlug);

    let documentFunctions = documentAutofillMap.find( d => d.slug === findBySlug);
    if(documentFunctions){

      if(document?.documentTemplate?.slug?.includes('tec-sales-order')){
        newValues = documentFunctions.globalAutofillFunction(document, documentValues, order, property, value, location, allLocations, salesReps, null, order?.products?.length, document.assignedObjectType);
        newValues = calculateTotalsFunction(document, getProductArray(order, document), getTradeinArray(order, document), order, documentValues, location);
      }else if(document?.documentTemplate?.slug?.includes('tec-invoice-template')){
        newValues = documentFunctions.globalAutofillFunction(order, document, documentValues, null, null, salesReps, location);
      }else{
        newValues = documentFunctions.globalAutofillFunction(documentValues, order, property, value, location, allLocations, salesReps, null, order?.products?.length, document.assignedObjectType);
      }

      if(documentFunctions.productAutofillFunction !== null){
        newValues = documentFunctions.productAutofillFunction(null, newValues, property, value, location, order, null, order?.products?.length);
      }

      if(documentFunctions.tradeinAutofillFunction !== null){
        newValues = documentFunctions.tradeinAutofillFunction(null, newValues, property, value, location);

      }

      if(documentFunctions?.depositAutofill && documentFunctions?.depositAutofill !== null){
        newValues = documentFunctions.depositAutofill(null, newValues, property, value);
      }

      DocumentHandleSubmit(document, newValues, null, () => {});

    }

    _callback();
  }

  function savePropertyToAllDocuments(passedPropertyIndex=0, propertyName=null, propertyValue=null, _callback){    
    if(propertyName === null || propertyValue === null){
      propertyName = getCurrentPropertyName();
      propertyValue = getCurrentPropertyValue();
    }

    var savePropertyToAllDocumentsPromise = new Promise(async (resolve) => {
      let filteredDocuments = filterDocuments();

      if(filteredDocuments?.length > 0){
        filteredDocuments.forEach(async (document, index, array) => {
          applyPropertyToDocument(propertyName, propertyValue, document, () => {

              let newValues = copyData;

              Object.entries(Object.entries(newValues))[passedPropertyIndex].propertySaved = true;
              Object.entries(Object.entries(newValues))[passedPropertyIndex].docsSavedCount++;

              dispatch(updateChangesArray(newValues));
            if (index === array.length -1) resolve();
          });
        });
      }
    });
    savePropertyToAllDocumentsPromise.then( () => {
      _callback();
    });
  }

  function copyPropertiesToDocuments(passedDocuments, _callback){
    let filteredProperties = findProperties();


    let documentSavedCoutLocal = 0;

    passedDocuments.forEach( (doc, index, array) => {

      let documentValues = JSON.parse(doc.payload);
      let newValues = null;
      let findBySlug = doc?.documentTemplate?.slug;
      
      findBySlug = correctSlug(findBySlug);

      let documentFunctions = documentAutofillMap.find( d => d.slug === findBySlug);
      if(documentFunctions){
        Object.entries(filteredProperties).forEach( (xey, propertyIndex) => {
          let propertyName = xey[1][0];
          let propertyValue = propertyName !== 'deposits' ? xey[1][1].newValue : "";

  
          
          if(propertyName!== 'id' && propertyName !== 'stockNumber'){

            if(doc?.documentTemplate?.slug?.includes('tec-sales-order')){
              newValues = documentFunctions.globalAutofillFunction(doc, documentValues, order, propertyName, propertyValue, location, allLocations, salesReps, null, order?.products?.length, doc.assignedObjectType);
              newValues = calculateTotalsFunction(doc, getProductArray(order, doc), getTradeinArray(order, doc), order, documentValues, location);
            }else if(doc?.documentTemplate?.slug?.includes('tec-invoice-template')){
              var invoiceResetParameters = {};
              invoiceResetParameters.showSalesTaxPerUnitPrice = documentValues.showSalesTaxPerUnitPrice;
              newValues = documentFunctions.globalAutofillFunction(order, doc, invoiceResetParameters, null, null, salesReps, location);
            }else{
              newValues = documentFunctions.globalAutofillFunction(documentValues, order, propertyName, propertyValue, location, allLocations, salesReps, null, order?.products?.length, doc.assignedObjectType);
            }            

            if(documentFunctions.productAutofillFunction !== null && doc.assignedObjectType === 'product'){
              let foundProduct = order.products.find(p => p.id === doc.assignedObjectId);
              if(foundProduct){
                newValues = documentFunctions.productAutofillFunction(foundProduct, newValues, propertyName, propertyValue, location, order, null, order?.products?.length);
                if(foundProduct?.deposits && foundProduct?.deposits.length > 0){
                  let foundDeposit = foundProduct.deposits[0];
                  if(foundDeposit){
                    newValues = documentFunctions.depositAutofill(foundDeposit, newValues, propertyName, propertyValue);
                  }
                }
              }
            }
      
            if(documentFunctions.tradeinAutofillFunction !== null && doc.assignedObjectType === 'tradein'){
              let foundTradein = order.tradeins.find(t => t.id === doc.assignedObjectId);
              if(foundTradein){
                newValues = documentFunctions.tradeinAutofillFunction(foundTradein, newValues, propertyName, propertyValue, location);
              }
            }

          }
      
        });
      }


      DocumentHandleSubmit(doc, newValues, null, () => {
        documentSavedCoutLocal++;
        setDocumentsSavedCount(documentSavedCoutLocal);
        if (index === array.length -1) setCopyAllProcessing(false);
      });
    })
    setDocumentsSavedCount(documentSavedCoutLocal);
    _callback();
  }

  const copyAllFunction = async (e) => {
    e.preventDefault();
    
    // Reset feedback variables
    setTotalDocuments(0);
    setDocumentsSavedCount(0);
    
    // Set state variables
    setCopyAllProcessing(true);
    setCopyAll(true);

    // Local function counter
    let totalDocuments = 0;

    // Retrieve documents list
    let filteredDocuments = filterDocuments();

    // Update local counter
    totalDocuments = filteredDocuments.length;

    // Update state feedback counter
    setTotalDocuments(totalDocuments);

    // Initiate copy all properties to all documents passed in. Need callback!
    copyPropertiesToDocuments(filteredDocuments, () => {
      getDocuments();
    });

  }

  const confirmChanges = async (e) => {
    e.preventDefault();


    savePropertyToAllDocuments(null, null, null, () => {
      getDocuments();
      dispatch(updateStatus('copied'))
    })
  }

  function currentPropertySaved(){
    let returnValue = false;

    let foundProperties = findProperties(true);

    if(foundProperties.length > 0 && propertyIndex >= 0){
      if(foundProperties[propertyIndex].propertySaved){

        returnValue = foundProperties[propertyIndex].propertySaved;
      }
    }
    return returnValue;
  }

  function currentDocumentSaved(){
    if(copyData[propertyIndex].docsSavedCount && copyData[propertyIndex].docsSavedCount >= 0){
      return copyData[propertyIndex].docsSavedCount;
    }
    return 0;
  }

  function previousDocumentSaved(){
      let previousPropertyIndex = propertyIndex - 1;
      if(copyData[previousPropertyIndex].docsSavedCount && copyData[previousPropertyIndex].docsSavedCount >= 0){
        return copyData[previousPropertyIndex].docsSavedCount;
      }
    return 0;
  }



  return (
    <>
      <div className="modal-body">
        <div className="container">
          <div className="text-center mx-auto">          
            <div className="px-3 my-0 py-0 section_main mx-auto text-center" style={{ width: '100%' }}>
            { !copyAll && findProperties().length === 1 ? (
              <>
                <p className="my-0 py-0" style={{ fontSize: '18px' }}>Field "<span style={{ fontWeight: 'bold' }}>{getCurrentPropertyName()}</span>"</p><br />
                <p className="my-0 py-0"style={{ fontWeight: 'bold', fontSize: '16px' }}>{getCurrentPropertyValue(true) ?? ''}</p><br />
              </>
            ): !copyAll && findProperties().length > 1 ? (
              <>
                <div className="mx-auto text-center my-0 py-0">
                  <MobileStepper
                    className="mx-auto my-1 py-0 "
                    variant="dots"
                    steps={findProperties().length}
                    position="static"
                    activeStep={propertyIndex}
                    sx={{ maxWidth: 'fit-content', flexGrow: 1 }}
                    />
                </div>
                <p className="my-0 py-0" style={{ fontSize: '18px' }}>Field "<span style={{ fontWeight: 'bold' }}>{getCurrentPropertyName()}</span>" { (propertyIndex ?? 0) + 1} of {findProperties().length ?? "--"}</p><br />
                <p className="my-0 py-0"style={{ fontWeight: 'bold', fontSize: '16px' }}>{getCurrentPropertyValue(true) ?? ''}</p><br />
              </>
            ):null}
              
            </div>
            { !copyAll && currentPropertySaved() ? (
              <>
                <p>{currentDocumentSaved()} Documents Updated.</p><br />
              </>
            ): !copyAll && saveStatus === "copy" ? (
              <>
                <p>Copy to {(filterDocuments()).length} documents?</p><br />
              </>
            ): null}
          </div>
          { !copyAll && previousPropertySaved ? (
          <div className="mx-auto text-center" style={{ width: '100%' }}>
            <hr></hr>
            <p style={{ color: 'green', fontSize: '11px' }}>Previous - {previousDocumentSaved()} Documents Updated.</p>
          </div>
          ):null}

          { copyAll ? (
          <div className="mx-auto text-center" style={{ width: '100%' }}>
            <p style={{ color: 'green', fontSize: '18px' }}>{documentsSavedCount} / {totalDocuments} Documents Updated Successfully.</p>
            { copyAllProcessing === true || Number(totalDocuments) !== Number(documentsSavedCount) ? (
              <CircularProgress />
            ):null}
          </div>
          ):null}
        </div>
      </div>
      <div className="modal-footer text-center">

        { !copyAll && !copyAllProcessing ? (
          <>
            <div className="mx-auto d-flex justify-content-evenly" style={{ width: '100%' }}>
              <div>
                <button
                  type="button"
                  className="btn btn-primary mx-1 btn-sm"
                  disabled={ (!hasPreviousProperty()) }
                  
                  // disable={saveStatus.includes('-group')}
                  // data-bs-dismiss="modal"
                  onClick={() => {
                    previousProperty();
                  }}
                  >
                  Previous Field
                </button>
              </div>

              { saveStatus === "copy" && !currentPropertySaved() ? (
                <>
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary mx-1 btn-sm"
                      onClick={(e) => confirmChanges(e)}
                      // disable={saveStatus.includes('-group')}
                      disable={true}

                      // data-bs-dismiss="modal"
                      // data-bs-target="#productModal"
                      >
                      Copy
                    </button>
                                  
                    <button
                      type="button"
                      className="btn btn-primary mx-1"
                      onClick={(e) => {
                        setPreviousPropertySaved(false);
                        confirmChanges(e);
                        if(hasNextProperty()){
                          nextProperty();
                          setPreviousPropertySaved(true);
                        }
                      }}
                      // disable={saveStatus.includes('-group') || (!hasNextProperty())}
                      disable={true}

                      // data-bs-dismiss="modal"
                      // data-bs-target="#productModal"
                      >
                      Copy & Next
                    </button>

                    <button
                      type="button"
                      className="btn btn-primary mx-1 btn-sm"
                      onClick={(e) => copyAllFunction(e)}
                      disable={saveStatus.includes('-group')}
                      
                      // data-bs-dismiss="modal"
                      // data-bs-target="#productModal"
                      >
                      Copy All
                    </button>
                  </div>
                </>
              ): !copyAll && currentPropertySaved() ? (
                <button
                type="button"
                className="btn btn-success"
                // onClick={(e) => confirmChanges(e)}
                disable={true}
                // data-bs-dismiss="modal"
                // data-bs-target="#productModal"
                >
                  Documents Updated
                </button>
              ): copyAll === true && copyAllProcessing === false && Number(totalDocuments) === Number(documentsSavedCount) ? (
                <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => resetModal(true)}
                data-bs-dismiss="modal"
                >
                  Close
                </button>
              ):null}

              <div>
                <button
                  type="button"
                  className="btn btn-primary mx-1 btn-sm"
                  // disable={saveStatus.includes('-group')}
                  // data-bs-dismiss="modal"
                  disabled={ (!hasNextProperty() || !copyData || copyData?.length <= 1) }
                  
                  onClick={() => {
                    nextProperty();
                  }}
                >
                  Next Field
                </button>
              
              </div>
            </div>
          </>
        ):null}

      </div>
    </>

)}

const {
  useSalesReps,
  useSalesManagers,
  useOrderStatuses
} = rdx

export default CopyChangeToDocumentsModal;
import _ from 'lodash'
import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import DocumentSection from "./DocumentSectionTemplate";
import DocumentSubSection from "./DocumentSubSectionTemplate";
import OrderDocumentsSection from "./sections/Documents/OrderDocumentsSection";
import ProductDocumentsSection from "./sections/Documents/ProductDocumentsSection";
import TradeinDocumentsSection from "./sections/Documents/TradeinDocumentsSection";
import { Accordion, Card, Dropdown } from "react-bootstrap";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import CustomerSection from "./sections/Customer/CustomerSection";
import ProductSection from "./sections/Products/ProductSection";
import TradeInSection from "./sections/Tradeins/TradeInSection";
import DetailsSection from "./sections/Details/DetailsSection";
import DownPaymentSection from "./sections/DownPayments/DownPaymentSection";
import AddDocumentModal from "./sections/Documents/AddDocumentModal";
import DocumentSetModal from "./sections/Documents/DocumentSetModal";
import DocumentViewModal from "./sections/Documents/DocumentViewModal";
import TECSalesOrder from "./sections/Documents/DocumentForms/TECSalesOrder";
import DocumentInvoiceModal from "./sections/Documents/DocumentInvoiceModal";
import DocumentScheduleAModal from "./sections/Documents/DocumentScheduleAModal";
import DocumentTradeScheduleModal from "./sections/Documents/DocumentTradeScheduleModal";
import DocumentFundsReceivedScheduleModal from "./sections/Documents/DocumentFundsReceivedScheduleModal";
import TECDoA from "./sections/Documents/DocumentForms/TECDoA";
import OregonTitleModal from './sections/Documents/DocumentForms/OregonTitleModal';
import OregonVINModal from './sections/Documents/DocumentForms/OregonVINModal';
import OregonBillOfSaleModal from './sections/Documents/DocumentForms/OregonBillOfSaleModal';
import TECPowerOfAttorney from './sections/Documents/DocumentForms/TECPowerOfAttorney';
import TECTaxWaiver from "./sections/Documents/DocumentForms/TECTaxWaiver";
import TECPayOffAuthorization from "./sections/Documents/DocumentForms/TECPayOffAuthorization";
import TECSoldAsIs from "./sections/Documents/DocumentForms/TECSoldAsIs";
import TECBillofSale from "./sections/Documents/DocumentForms/TECBillofSale";
import WAReleaseOfInterestPowerOfAttorney from "./sections/Documents/DocumentForms/WAReleaseOfInterestPowerOfAttorney";
import WAVehicleTitleApplication from "./sections/Documents/DocumentForms/WAVehicleTitleApplication";
import WAVehicleTitleApplicationAdditional from "./sections/Documents/DocumentForms/WAVehicleTitleApplicationAdditional";
import NVVehicleInspectionCertificate from "./sections/Documents/DocumentForms/NVVehicleInspectionCertificate";
import NVErasureAffidavit from "./sections/Documents/DocumentForms/NVErasureAffidavit";
import NVBillOfSale from "./sections/Documents/DocumentForms/NVBillOfSale";
import NVPowerOfAttorney from "./sections/Documents/DocumentForms/NVPowerOfAttorney";
import CAPowerOfAttorney from "./sections/Documents/DocumentForms/CAPowerOfAttorney";
import CADMVDeclarationOfGVW from "./sections/Documents/DocumentForms/CADMVDeclarationOfGVW";
import CAPTICert from "./sections/Documents/DocumentForms/CAPTICert";
import CASection837SalesTaxAffidavit from "./sections/Documents/DocumentForms/CASection837SalesTaxAffidavit";
import CABillOfSale from "./sections/Documents/DocumentForms/CABillOfSale";
import CAStatementOfFacts from "./sections/Documents/DocumentForms/CAStatementOfFacts";
import CATitleApp343 from "./sections/Documents/DocumentForms/CATitleApp343";
import CASalesTaxStatement6247 from "./sections/Documents/DocumentForms/CASalesTaxStatement6247";
import CADeliveryStatement from "./sections/Documents/DocumentForms/CADeliveryStatement";
import CACertificateOfNonOperation from "./sections/Documents/DocumentForms/CACertificateOfNonOperation";
import TECDepositReceipt from "./sections/Documents/DocumentForms/TECDepositReceipt";
import TECDeliveryReceipt from "./sections/Documents/DocumentForms/TECDeliveryReceipt"
import TECInsuranceRequest from "./sections/Documents/DocumentForms/TECInsuranceRequest";
import TECCaliforniaSalesTaxExemptionCertificate from "./sections/Documents/DocumentForms/TECCaliforniaSalesTaxExemptionCertificate";
import IRS8300 from "./sections/Documents/DocumentForms/IRS8300";
import WASalesTax from "./sections/Documents/DocumentForms/WASalesTax";
import WAWholesale from "./sections/Documents/DocumentForms/WAWholesale";
import AZ5000 from "./sections/Documents/DocumentForms/AZ5000";
import AZ5010 from "./sections/Documents/DocumentForms/AZ5010";
import AZ5011 from "./sections/Documents/DocumentForms/AZ5011";
import AZ5012 from "./sections/Documents/DocumentForms/AZ5012";
import AZBillOfSale from "./sections/Documents/DocumentForms/AZBillOfSale";
import AZTitleApp from "./sections/Documents/DocumentForms/AZTitleApp";
import CustomerModal from "./sections/Customer/CustomerModal";
import ProductModal from "./sections/Products/modals/ProductModal";
import DeleteProductModal from "./sections/Products/modals/DeleteProductModal";
import DuplicateProductModal from "./sections/Products/modals/DuplicateProductModal";
import CopyChangesToProductsInGroupModal from "./sections/Products/modals/CopyChangesToProductsInGroupModal";
import CopyChangeToDocumentsModal from "./sections/Products/modals/CopyChangeToDocumentsModal";
import DownpaymentModal from "./sections/DownPayments/DownpaymentModalContainer"
import DetailsModal from "./sections/Details/DetailsModal";
import TradeinModal from "./sections/Tradeins/TradeinModalContainer";
import PrintAllDocModal from './sections/Documents/PrintAllDocModal';
import ProductSummary from "./sections/ProductSummary";
import TradeinSummary from "./sections/TradeinSummary";
import OrderSummary from "./sections/OrderSummary";
import { setOrder } from "../../../../redux/orders/order";
import OrderSection from "./SectionTemplate";
import { useOrder } from "../../../../redux/orders/order"
import { rdx as tradeinRdx } from "../../../../redux/tradeins"
import { rdx as customerRdx } from "../../../../redux/customers"
import { rdx as orderRdx } from "../../../../redux/orders/order"
import { rdx as downpaymentRdx } from "../../../../redux/downpayments"
import { rdx as fundingPaymentRdx } from "../../../../redux/fundingPayments"
import { setProduct} from "../../../../redux/products/index"
import { setProductFocus } from "../../../../redux/products/productFocusReducer";
import FundingPaymentSection from "./sections/FundingPayments/FundingPaymentSection";
import FundingPaymentModal from "./sections/FundingPayments/FundingPaymentModalContainer"
import SD45DayExt from "./sections/Documents/DocumentForms/SD45DayExt";
import SDDealerReport from "./sections/Documents/DocumentForms/SDDealerReport";
import SDDuplicateTitle from "./sections/Documents/DocumentForms/SDDuplicateTitle";
import SDPoA from "./sections/Documents/DocumentForms/SDPoA";
import SDTitleApp from "./sections/Documents/DocumentForms/SDTitleApp";
import NEForm6 from "./sections/Documents/DocumentForms/NEForm6";
import NEForm13 from "./sections/Documents/DocumentForms/NEForm13";
import NEPoA from "./sections/Documents/DocumentForms/NEPoA";
import NETitleApp from "./sections/Documents/DocumentForms/NETitleApp";
import NVAffPurchaser from "./sections/Documents/DocumentForms/NVAffPurchaser";
import IATitleApp from "./sections/Documents/DocumentForms/IATitleApp";
import CAResaleCert from "./sections/Documents/DocumentForms/CAResaleCert";
import TECAffidavit from "./sections/Documents/DocumentForms/TECAffidavit";
import TECCAAffExmptSalesTax from "./sections/Documents/DocumentForms/TECCAAffExmptSalesTax";
import TECCertOOSSingle from "./sections/Documents/DocumentForms/TECCertOOSSingle";
import TECCertOOSMulti from "./sections/Documents/DocumentForms/TECCertOOSMulti";
import TECStatementOfError from "./sections/Documents/DocumentForms/TECStatementOfError";
import TECVehicleSalesDocument from "./sections/Documents/DocumentForms/TECVehicleSalesDocument";
import TECWAWeightSlipTrailers from "./sections/Documents/DocumentForms/TECWAWeightSlipTrailers";
import WABillOfSale from "./sections/Documents/DocumentForms/WABillOfSale";
import CACarb from "./sections/Documents/DocumentForms/CACarb";
import * as api from '../../../../utlis/api.js'
import {useFetch} from '../../../../utlis/api.js'

import "./Style/orderquote.css";
import DeleteDocsModal from './sections/Documents/DeleteDocsModal';
import DocSearchBar from './sections/Documents/DocComponents/DocSearchBar';
import { setActiveModal, setActiveModalTab } from '../../../../redux/app/appSaver';
// import { createProductGroup, matchAndGroup } from '../../../../utlis/productCalculations';
import { matchAndGroupProducts } from '../../../../utlis/dataSaveAndCopyLibrary.js';
import { refreshOrder } from '../../../../redux/orders/order';

//Order Page
const Page = ({eventKey, callback}) => {

  const access_token  = useSelector((state) => state?.appData.access_token);
  const role = useSelector((state) => state?.role.role);
  const { id }        = useParams();
  const dispatch      = useDispatch();
  const order         = useOrder();
  const axios         = useFetch()
  const {isLoading}=useSelector(state=>state.spinners)

  const [ documentFocus, setDocumentFocus ] = useState(undefined) //empty list by default.
  const [ lastFinalizedAt, setLastFinalizedAt ] = useState(undefined) //empty list by default.
  const [ isFinalized, setIsFinalized ] = useState(false) //empty list by default.
  const [isAddingDocument, setIsAddingDocument] = useState(false)

  // const location = JSON.parse(localStorage.getItem("location"));
  const locations = JSON.parse(localStorage.getItem("alllocations"));
  const taxExemptReasons = JSON.parse(localStorage.getItem("taxExemptReasons"));

  const [location, setLocation] = useState(null)
  const [loadingLocation, setLoadingLocation] = useState(false)

  useEffect(() => {
    async function updateLocation(locationCode){

        setLoadingLocation(true);

        let foundLocation = locations.find(l => l.locationCode === locationCode);
        if(foundLocation){
          try{
            let res = await api.getTecRegion(foundLocation?.locationCode)
            let region = res?.data;
            foundLocation.email = region?.email;
            setLocation(foundLocation);
            setLoadingLocation(false);
        }catch(err) {}
      }

    }

    if(locations?.length > 0 && (location === null || location?.locationCode !== order?.tecLocationCode) && loadingLocation === false && order?.tecLocationCode){
      updateLocation(order.tecLocationCode);
    }

  },[loadingLocation, location, locations, order.tecLocationCode]);

  const [ initializeDocumentModal, setInitializeDocumentModal ] = useState(false); //Redux
  const [ passedDocumentSlug, setPassedDocumentSlug ] = useState(undefined); //Redux
  const customerModalOpen = useCustomerModalIsOpen()
  const tradeinModalOpen  = useTradeinModalIsOpen()
  const downPaymentModalOpen  = useDownPaymentModalIsOpen()
  const fundingPaymentModalOpen  = useOpenFundingPaymentModal()
  const [ orderdocuments, setOrderdocuments ] = useState(undefined) //empty list by default.
  const [ updateDocSetModal, setUpdateDocSetModal ] = useState(0) //empty list by default.
  const [ departments, setDepartments ]       = useState(undefined);
  const [docSearch, setDocSearch] = useState('');
  const [itemSearch, setItemSearch] = useState('');
  const [isOpen, setIsOpen] = useState({
    customer:false,
    order:false,
    product:false,
    tradeIn:false,
    downPayment:false,
    fundingPayment:false,
    documents:false,
    selectedProduct:undefined,
    documentSubSection:undefined,
    documentSection:undefined,
    selectedProductGroup: undefined
  })
  const [readOnly, setReadOnly] = useState(false);
  const [loadingRegion, setLoadingRegion] = useState(false);
  const [isDeletingDoc, setIsDeletingDoc] = useState(null)
  const [isDocActionDisabled, setIsDocActionDisabled] = useState(false)
  
  const orderDoneLoading = useCallback(async () => {

    if(isLoading === false && order && order.id){
      return true;
    }
    return false;

  }, [isLoading, order])

  useEffect(() => {
    if(orderDoneLoading()){
      if(documentFocus && documentFocus.documentTemplate && documentFocus.documentTemplate.slug){
        setPassedDocumentSlug(documentFocus.documentTemplate.slug);
      }
    }
  }, [documentFocus, orderDoneLoading]);

  //Function to update the order doc info...
  const updateOrderDocsInfo = useCallback(async (FORCE=false, shouldDelay) => {

    if(orderdocuments === undefined || FORCE){
      try {
        let response = await api.getDocuments(id)
        if (response?.data) {
          //delay to ensure add doc animation happens smoothly
          if (shouldDelay) {
            setTimeout(() => {
              setOrderdocuments(response?.data);
              setLoadingDocuments(false);
              setIsAddingDocument(false)
            }, 1600);  
          }
          else{
            setOrderdocuments(response?.data);
            setLoadingDocuments(false);
            setIsAddingDocument(false)
          }   
        }
      } catch (err) {
        api.recordFrontEndError('Page.jsx' , 'Error updating order docs info: ' + err)
      }
    }
    return ()=>null
  },[ id, orderdocuments ]);

  function checkForDocument(selectedDocumentTemplate, assignedObjectId=null, hardType=null){
    let documentFound = false;
    if(orderdocuments && orderdocuments.length > 0){
      orderdocuments.forEach(document => {
        if(document && document.documentTemplate && document.documentTemplate.type === selectedDocumentTemplate.type){
          if(document.documentTemplate.type === "order" || document.documentTemplate.type === "order-selected" || document.documentTemplate.type === "special-sales" || document.documentTemplate.type === "special"){
            if(document.documentTemplate.slug === selectedDocumentTemplate.slug){
              documentFound = true;
            }
          } else {
            // Check if product or trade in is assigned.
            if(assignedObjectId && document.assignedObjectId === assignedObjectId && hardType && document.assignedObjectType === hardType){
              if(document.documentTemplate.slug === selectedDocumentTemplate.slug){
                documentFound = true;
              }
            }
          }
        }
      });
    }
    return documentFound;
  }

  function getTotalProductCount(order){
    let count = 0;

    if(order && order.groupedProducts){
       if(order.groupedProducts.length > 0){
        order?.groupedProducts?.forEach(group => {
          count = Number(count) + Number(group.products.length);
        })
      }
    }

    if(order && order.products){
      if(order.products.length > 0){
        order?.products?.forEach(product => {
          if(product && product.productGroupId === null){
            count = Number(count) + Number(1);
          }
        });
      }
    }

    return count;
  }

  function filterSingleProducts(products){
    return products.filter(product => {
      if(product.productGroupId === null){
        return product;
      }else{
        return null;
      }
    });
  }

  // function getDocuments(){
  const getDocuments = useCallback(async () => {
    updateOrderDocsInfo(true, false);
  },[ updateOrderDocsInfo ]);

  useEffect(() => {
    if(orderDoneLoading()){

      if((order.finalizedAt && lastFinalizedAt && order.finalizedAt > lastFinalizedAt) || (!lastFinalizedAt) || (!order.finalizedAt)){
        setLastFinalizedAt(order.finalizedAt);
        setIsFinalized(order.finalizedValues);
      }

    }

  }, [order, setLastFinalizedAt, setIsFinalized.apply, lastFinalizedAt, orderDoneLoading])

  const [loadingDocuments, setLoadingDocuments] = useState(false);

  // Effect to fetch the order documents on mount...
  useEffect(() => {
    if(orderDoneLoading()){

      if(orderdocuments === undefined && loadingDocuments === false){
        setLoadingDocuments(true);
        getDocuments();
      }

    }
  }, [orderdocuments, getDocuments, orderDoneLoading, loadingDocuments])

  const updateOrderStatus = async newStatusSlug => {
    try {
      await api.updateOrderStatus(id,newStatusSlug)
    } catch (err) {
      api.recordFrontEndError('Page.jsx', "Error updating order status" + err)
    }
    reloadOrder()
   }

  async function reloadOrder(){
    try {
      let res = await api.loadOrder(id, access_token)
      if ( _.isEmpty( res?.data ) ) { //Added check...
        dispatch(setOrder(res.data));
      }
    } catch (err) {
      api.recordFrontEndError('Page.jsx', 'Error reloading order: ' + err)
    }
  }

  async function updateLocation(locationCode){
    let foundLocation = locations.find(l => l.locationCode === locationCode);
    if(foundLocation){
      try{
        let res = await api.getTecRegion(foundLocation?.locationCode)
        let region = res?.data;
        foundLocation.email = region?.email;
        localStorage.setItem("location", JSON.stringify(foundLocation));
    }catch(err) {
      api.recordFrontEndError('Page.jsx', 'Error updating location: ' + err);
      }
    }
  }


  useEffect(() => {

    async function loadRegionForEmail(location){
      
      try{
        let res = await api.getTecRegion(location.locationCode)
        let region = res?.data;
        location.email = region.email;
        localStorage.setItem("location", JSON.stringify(location));

        setLoadingRegion(false);
      } catch(err) {
        api.recordFrontEndError('Page.jsx', 'Error getting TEC location: ' + err);

      }
    }

    async function loadLocations(){
      try{
        let res = await api.getTecLocation()
          var itemsArray = res?.data.data;
          localStorage.setItem("alllocations", JSON.stringify(itemsArray));

      }catch(err) {
        api.recordFrontEndError('Page.jsx', 'Error getting TEC location: ' + err);
      }
    }

    if(orderDoneLoading()){


      if(locations === null || (locations && locations.length === 0)){
        loadLocations();
      }else if(locations && locations.length > 0 && location === null){
        let foundLocation = locations.find(l => l.locationCode === order.tecLocationCode);
        if(foundLocation && loadingRegion === false){
          setLoadingRegion(true);
          loadRegionForEmail(foundLocation)
        }
      }

    }
  }, [locations, location, order, dispatch, orderDoneLoading, loadingRegion]);

  const deleteDocument = async (e, documentId) => {
    e.preventDefault();
    setIsDeletingDoc(documentId);
    setIsDocActionDisabled(true);

    //Delay to make animation smooth
    await new Promise(resolve => setTimeout(resolve, 2000));
      try {
        await api.deleteDocument(documentId);
        setIsDocActionDisabled(false)
        updateOrderDocsInfo(true, false);
      } catch (err) {
        api.recordFrontEndError('Page.jsx', 'Error deleting document: ' + err);
      }
  }

  useEffect(()=>{
    if(orderDoneLoading()){

      if(taxExemptReasons === null || taxExemptReasons.length === 0){
        
        axios.get('sales-tax-exemption-reasons').then((res)=>{
          localStorage.setItem("taxExemptReasons", JSON.stringify(res.data));
        })
      }

    }
    
  },[taxExemptReasons, axios, order, orderDoneLoading]);

  useEffect(()=>{
    if(orderDoneLoading()){

      if(order?.orderStatus?.name.toString().toLowerCase() === 'booked'){
        setReadOnly(true)
      }else{
        setReadOnly(false)
      }

    }
  },[order, orderDoneLoading])

  return (
    <>
      { orderDoneLoading() ? (
        <>
          <div className="container-fluid" id="order-page-tst">
            <div className="row">
              <div className="col-3">
                <div className="p-3 section_main">
                  <Timeline>
                    <TimelineItem>
                      <TimelineSeparator>
                        { order && order.customer && order.customer.name ? (
                          <TimelineDot color="primary" />
                        ):(
                          <TimelineDot />
                        )}
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent >Customer</TimelineContent>
                    </TimelineItem>

                    <TimelineItem>
                      <TimelineSeparator>
                        { order && order.documentationDate ? (
                          <TimelineDot color="primary" />
                        ):(
                          <TimelineDot />
                        )}
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>Order Details</TimelineContent>
                    </TimelineItem>

                    <TimelineItem>
                      <TimelineSeparator>
                        { order && order.products && order.products.length > 0 ? (
                          <TimelineDot color="primary" />
                        ):(
                          <TimelineDot />
                        )}
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>Product
                      { order && order.products && order.products.length > 0 ? (
                        <div style={{ fontSize: '12px' }}>
                          <span>Qty: { order.products.length }</span>
                        </div>
                      ):null}
                      </TimelineContent>
                    </TimelineItem>

                    <TimelineItem>
                      <TimelineSeparator>
                        { order && order.tradeins && order.tradeins.length > 0 ? (
                          <TimelineDot color="primary" />
                        ):(
                          <TimelineDot />
                        )}
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>Trade Ins
                      { order && order.tradeins && order.tradeins.length > 0 ? (
                        <div style={{ fontSize: '12px' }}>
                          <span>Qty: { order.tradeins.length }</span>
                        </div>
                      ):null}
                      </TimelineContent>
                    </TimelineItem>

                    <TimelineItem>
                      <TimelineSeparator>
                        { order && order.downPayments && order.downPayments.length > 0 ? (
                          <TimelineDot color="primary" />
                        ):(
                          <TimelineDot />
                        )}
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>Down Payment
                      { order && order.downPayments && order.downPayments.length > 0 ? (
                        <div style={{ fontSize: '12px' }}>
                          <span>Qty: { order.downPayments.length }</span>
                        </div>
                      ):null}
                      </TimelineContent>
                    </TimelineItem>

                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot />
                      </TimelineSeparator>
                      <TimelineContent>Summary</TimelineContent>
                    </TimelineItem>
                  </Timeline>
                </div>

                <div className="d-grid gap-2 mx-4">
                  { order && order.orderStatus && order.orderStatus.orderStatusSlug === 'quote' ? (
                    <button className="btn btn-primary" type="button" onClick={() => updateOrderStatus('manager-review')}>
                      SUBMIT FOR MANAGER APPROVAL
                    </button>
                  ):('')}

                  { order && order.orderStatus && order.orderStatus.orderStatusSlug === 'manager-review' ? (
                    <button className="btn btn-success" type="button" onClick={() => updateOrderStatus('finance-review')}>
                      SUBMIT FOR FINANCE REVIEW
                    </button>
                  ):('')}

                  { order && order.orderStatus && order.orderStatus.orderStatusSlug === 'finance-review' ? (
                    <button className="btn btn-success" type="button" onClick={() => updateOrderStatus('credit-approval')}>
                      SUBMIT FOR CREDIT APPROVAL
                    </button>
                  ):('')}

                  { order && order.orderStatus && order.orderStatus.orderStatusSlug === 'credit-approval' ? (
                    <button className="btn btn-success" type="button" onClick={() => updateOrderStatus('docs-requested')}>
                      SUBMIT FOR DOCUMENTS
                    </button>
                  ):('')}

                  { order && order.orderStatus && order.orderStatus.orderStatusSlug === 'docs-requested' ? (
                    <button className="btn btn-success" type="button" onClick={() => updateOrderStatus('docs-generated')}>
                      DOCUMENTS GENERATED
                    </button>
                  ):('')}

                  { order && order.orderStatus && order.orderStatus.orderStatusSlug === 'docs-generated' ? (
                    <button className="btn btn-primary" type="button" onClick={() => updateOrderStatus('docs-signed')}>
                      DOCUMENTS SIGNED
                    </button>
                  ):('')}

                  { order && order.orderStatus && order.orderStatus.orderStatusSlug === 'docs-signed' ? (
                    <button className="btn btn-primary" type="button" onClick={() => updateOrderStatus('funding-request')}>
                      SUBMIT FUNDING REQUEST
                    </button>
                  ):('')}

                  { order && order.orderStatus && order.orderStatus.orderStatusSlug === 'funding-request' ? (
                    <button className="btn btn-success" type="button" onClick={() => updateOrderStatus('funded')}>
                      FUNDED
                    </button>
                  ):('')}

                  { order && order.orderStatus && order.orderStatus.orderStatusSlug === 'funded' ? (
                    <button className="btn btn-primary" type="button" onClick={() => updateOrderStatus('booking-request')}>
                      SUBMIT BOOKING REQUEST
                    </button>
                  ):('')}

                  { order && order.orderStatus && order.orderStatus.orderStatusSlug === 'booking-request' ? (
                    <button className="btn btn-success" type="button" onClick={() => updateOrderStatus('booked')}>
                      BOOKED
                    </button>
                  ):('')}
                  { order && order.orderStatus && role==='master' && order.orderStatus.orderStatusSlug === 'booked'? (
                    <button className="btn btn-outline-primary" type="button" onClick={() => updateOrderStatus('booking-request')}>
                      UN-BOOK
                    </button>
                  ):('')}
                </div>
              </div>

              {
                <div className="col-8  border-5">
                  <div className="p-1">
                  <Accordion>

                    <div className="d-flex align-items-center">
                      
                      <div>
                      { customerModalOpen ?  <CustomerModal taxExemptReasons={taxExemptReasons} readOnly={readOnly} /> : null }
                      </div>

                      <div className="flex-grow-1">
                        <Card>
                          <OrderSection title="CUSTOMER" eventKey="2" mode="static" tray='customer' setIsOpen={setIsOpen} isOpen={isOpen?.customer} customerName={order && order.customer ? order.customer.name : null}>
                            <Dropdown.Item
                              data-bs-toggle="modal"
                              onClick={ () => {
                              dispatch(customerRdx.setIsOpen(true))
                              dispatch(setActiveModal('customer'))
                              }}
                              data-bs-target="#customermodal">
                              <b>{readOnly?'View Customer':'Edit Customer'}</b>
                            </Dropdown.Item>
                          </OrderSection>
                          <Accordion.Collapse eventKey="2">
                            <CustomerSection taxExemptReasons={taxExemptReasons}/>
                          </Accordion.Collapse>
                        </Card>
                      </div>
                    </div>

                    {/* Details Model Start Here  */}
                    <div className="d-flex align-items-center">
                      
                      <div>
                        <DetailsModal
                          readOnly={readOnly}
                          locations={locations}
                          departments={departments}
                          setDepartments={setDepartments}
                          updateOrderDocsInfo={updateOrderDocsInfo}
                          updateLocation={updateLocation}
                          /> 
                      </div>
      
                      <div className="flex-grow-1">
                        <Card>
                          <OrderSection title="ORDER DETAILS" eventKey="1" mode="static" tray='order' setIsOpen={setIsOpen} isOpen={isOpen?.order}>
                            <Dropdown.Item
                              name="expand-details"
                              onClick={ () => {
                              dispatch(orderRdx.setIsOpen(true));
                              dispatch(setActiveModal('details'))
                            }}>
                              <b>{readOnly?'View Details':'Edit Details'}</b>
                            </Dropdown.Item>
                          </OrderSection>
                          <Accordion.Collapse
                            title="Expand Order Details"
                            name="expand-details"
                            data-testid="expOrderDetails"
                            eventKey="1">
                            <DetailsSection />
                          </Accordion.Collapse>
                        </Card>
                      </div>
                    </div>

                    {/* product Model Start Here  */}
                    <div className="d-flex align-items-center">

                      <div>
                      <ProductModal departments={departments} location={location} readOnly={readOnly} documents={orderdocuments}/>
                      </div>
                      <div>
                        <DuplicateProductModal/>
                        <DeleteProductModal/>
                        <CopyChangesToProductsInGroupModal />
                        <CopyChangeToDocumentsModal documents={orderdocuments} getDocuments={getDocuments} />
                      </div>

                      <div className="flex-grow-1">
                        <Card>
                        <OrderSection title="PRODUCTS" eventKey="3" tray='product' readOnly={readOnly} setIsOpen={setIsOpen} isOpen={isOpen?.product} recCount={ getTotalProductCount(order) } style={{color: '#000'}}>
                            <Dropdown.Item
                              data-testid="accordian.product.contextMenu.addProduct"
                              data-bs-toggle="modal"
                              onClick={(e) =>{
                                dispatch(setProduct({name:'new'}));
                                dispatch(setProductFocus({}));
                                dispatch(setActiveModal('product'))
                                dispatch(setActiveModalTab('details'))
                              }}
                              data-bs-target="#productModal"
                              className="">
                              <b>Add Product</b> 
                            </Dropdown.Item>
                            <Dropdown.Item
                              data-testid="accordian.product.contextMenu.matchAndGroupProducts"
                              onClick={(e) => { matchAndGroupProducts(order, () => {
                                dispatch(refreshOrder(order.id, true))
                              })}}
                              //   let groups = matchAndGroup(order.products);

                              //   // Clear all order product group connections with products.
                              //   api.clearProductGroups(order.id).then(() => {

                              //     var createProductGroups = new Promise((resolve, reject) => {
                              //       groups.forEach((group, index, array) => {
                              //         if(group?.products.length > 1){
                              //           createProductGroup(order, group);
                              //         }
                              //         if (index === array.length -1) resolve();
                              //       })
                              //     });

                              //     createProductGroups.then( () => {
                              //       // Clear all order empty order product groups.
                              //       api.clearExtraProductGroups(order.id);  
                              //     });
                              //   });



                              // }}
                              // data-bs-target="#productModal"
                              >
                              <b>Match & Group Products</b> 
                            </Dropdown.Item>
                          </OrderSection>
                          <Accordion.Collapse eventKey="3" in={isOpen?.product}>
                            <>
                            <div style={{ margin: '0.5rem'}}>
                              { order?.groupedProducts?.length > 0 ? order.groupedProducts.map(productGroup => (
                                <>
                                { productGroup && productGroup.products && productGroup.products.length > 0 ? (
                                  <>
                                    <div style={{ marginBottom: '0.25rem'}}>
                                      <OrderSection accordianColor="#1c5e9f" title={(productGroup.products[0].year+" "+productGroup.products[0].make.toUpperCase()+" "+productGroup.products[0].model.toUpperCase())} eventKey={productGroup.id} tray='productGroups' isOpenValues={isOpen} productGroupId={productGroup.id} setIsOpen={setIsOpen} isOpen={(isOpen?.selectedProductGroup === productGroup.id)} recCount={ (productGroup?.products ? productGroup.products.length : 0) } style={{color: '#000', marginLeft: '25px'}}>
                                      </OrderSection>
                                      <Accordion.Collapse eventKey={productGroup.id} in={isOpen.selectedProductGroup === productGroup.id}>
                                        <ProductSection location={location} isOpen={isOpen} setIsOpen={setIsOpen} products={productGroup.products} readOnly={readOnly} />
                                      </Accordion.Collapse>
                                    </div>
                                  </>
                                ):null}
                                </>
                              )):null}
                              { order?.products && filterSingleProducts(order.products).length > 0 ? (
                                <>
                                  <div className='text-center mx-auto pa-3 mt-3'>
                                    <h6 style={{ backgroundColor: "#1c5e9f", color: "#fff", padding: '1rem'}} className='pa-3 mx-auto'>Ungrouped Products</h6>
                                  </div>
                                  <ProductSection location={location} isOpen={isOpen} setIsOpen={setIsOpen} products={filterSingleProducts(order.products)} readOnly={readOnly} />
                                </>
                              ):null}
                            </div>
                            </>
                          </Accordion.Collapse>
                        </Card>
                      </div>
                    </div>
                    {/* Trade Model Start Here  */}
                    <div className="d-flex align-items-center">
                      <div>
                      { tradeinModalOpen ?  <TradeinModal readOnly={readOnly}/> : null }
                      </div>
                      <div className="flex-grow-1">
                        <Card>
                        <OrderSection title="TRADE INS" eventKey="4" tray='tradeIn' readOnly={readOnly} setIsOpen={setIsOpen} isOpen={isOpen?.tradeIn} recCount={ (order && order.tradeins ? order.tradeins.length : 0) }>
                            <Dropdown.Item
                            data-bs-toggle="modal"
                            data-bs-target="#tradeinModal"
                            onClick={(e) => {
                              dispatch(tradeinRdx.setIsOpen(true));
                              dispatch(tradeinRdx.setTradeinFocusId(''));
                              dispatch(setActiveModal('tradein'));
                            }}
                            >
                              <b>Add Trade In</b> 
                            </Dropdown.Item>
                          </OrderSection>
                          <Accordion.Collapse eventKey="4">
                          <TradeInSection setIsOpen={setIsOpen} isOpen={isOpen} readOnly={readOnly} />
                          </Accordion.Collapse>
                        </Card>
                      </div>
                    </div>

                    {/* Downpayment Model Start Here  */}
                    <div className="d-flex align-items-center">
                      <div>
                        { downPaymentModalOpen ?  <DownpaymentModal readOnly={readOnly}/> : null }

                      </div>
                      <div className="flex-grow-1">
                        <Card>
                        <OrderSection title="DOWN PAYMENTS" eventKey="5" tray='downPayment' readOnly={readOnly} setIsOpen={setIsOpen} isOpen={isOpen?.downPayment} recCount={ (order && order.downPayments ? order.downPayments.length : 0) }>
                            <Dropdown.Item
                              onClick={(e) => {
                                dispatch(downpaymentRdx.setIsOpen(true));
                                dispatch(downpaymentRdx.setHasUnsavedChanges(false));
                                dispatch(setActiveModal('downpayment'));
                              }}>
                              <b>Add Down Payment</b>
                            </Dropdown.Item>
                          </OrderSection>
                          <Accordion.Collapse eventKey="5">
                          <DownPaymentSection readOnly={readOnly}/>
                          </Accordion.Collapse>
                        </Card>
                      </div>
                    </div>
                    {/* Funding Payment Model Start Here  */}
                    <div className="d-flex align-items-center">
                      <div>
                        { fundingPaymentModalOpen ?  <FundingPaymentModal /> : null }

                      </div>
                      <div className="flex-grow-1">
                        <Card>
                        <OrderSection title="FUNDING PAYMENTS" tray='fundingPayment' setIsOpen={setIsOpen} readOnly={readOnly} isOpen={isOpen?.fundingPayment} eventKey="6" recCount={ (order && order.fundingPayments ? order.fundingPayments.length : 0) }>
                            <Dropdown.Item
                              onClick={(e) => {
                                dispatch(fundingPaymentRdx.setOpenFundingPaymentModal(true));
                                dispatch(fundingPaymentRdx.setHasUnsavedChanges(false));
                                dispatch(setActiveModal('funding-payment'));
                              }}>
                              <b>Add Funding Payment</b>
                            </Dropdown.Item>
                          </OrderSection>
                          <Accordion.Collapse eventKey="6">
                          <FundingPaymentSection readOnly={readOnly} />
                          </Accordion.Collapse>
                        </Card>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <ProductSummary />
                    </div>

                    <div className="d-flex align-items-center">
                      <TradeinSummary />
                    </div>
   
                    <div className="d-flex align-items-center">
                    <OrderSummary readOnly={readOnly} />
                    </div>
                    <br />
                    { !isFinalized ? (
                    <div className="d-flex align-items-center" style={{ width: '100%' }}>
                    </div>
                    ):(
                    <div className="d-flex align-items-center">
                      <div>
                        <AddDocumentModal updateOrderDocsInfo={updateOrderDocsInfo} documents={orderdocuments} setIsOpen={setIsOpen} isOpen={isOpen} setIsAddingDocument={setIsAddingDocument} setOrderdocuments={setOrderdocuments} />
                        <DocumentSetModal documents={orderdocuments} updateOrderDocsInfo={updateOrderDocsInfo} updateDocSet={updateDocSetModal} />
                        <PrintAllDocModal order={order}orderDocs={orderdocuments}/>
                      </div>
                      <div className="flex-grow-1">
                        <Card>
                          <OrderSection title="DOCUMENTS" eventKey="7" tray='documents' setIsOpen={setIsOpen} isOpen={isOpen?.documents} recCount={ (orderdocuments ? orderdocuments.length : 0) }>
                            { !checkForDocument({type: 'special', slug: 'tec-invoice-template'}) ? (
                              <Dropdown.Item
                                data-bs-toggle="modal"
                                data-bs-target="#documentsInvoiceModal"
                                disabled={readOnly}
                                onClick={() => {
                                  setInitializeDocumentModal(true);
                                  setPassedDocumentSlug('tec-invoice-template-v2');
                                  dispatch(setActiveModal('invoice'));
                                }}
                                data-testid="orderpage.documentContext.generateInvoice"
                              >

                                <b>Generate Invoice</b>
                              </Dropdown.Item>
                            ):null}
                            
                          
                            { !checkForDocument({type: 'order-selected', slug: 'tec-schedule-a'}) && !checkForDocument({type: 'order-selected', slug: 'tec-wa-schedule-a'}) ? (
                              <Dropdown.Item
                                data-bs-toggle="modal"
                                data-bs-target="#tec-schedule-a"
                                disabled={readOnly}
                                onClick={() => {
                                  setInitializeDocumentModal(true);
                                  setPassedDocumentSlug('tec-schedule-a');
                                  dispatch(setActiveModal('document-schedule-a'));
                                }}
                                data-testid="orderpage.documentContext.generateScheduleA"
                              >
                                <b>Generate Schedule A</b>
                              </Dropdown.Item>
                            ):null}

                            { !checkForDocument({type: 'order-selected', slug: 'tec-invoice-trade-schedule'}) ? (
                              <Dropdown.Item
                                data-bs-toggle="modal"
                                data-bs-target="#tec-invoice-trade-schedule"
                                disabled={readOnly}
                                data-testid="orderpage.documentContext.generateTradeSchedule"
                                onClick={() => {
                                  setInitializeDocumentModal(true);
                                  setPassedDocumentSlug('tec-invoice-trade-schedule');
                                  dispatch(setActiveModal('trade-schedule'));
                                }}                      >
                                <b>Generate Invoice Trade Schedule</b>
                              </Dropdown.Item>
                            ):null}
                            
                            { !checkForDocument({type: 'order-selected', slug: 'tec-funds-received-schedule'}) ? (
                              <Dropdown.Item
                                data-bs-toggle="modal"
                                data-bs-target="#tec-funds-received-schedule"
                                disabled={readOnly}
                                onClick={() => {
                                  setInitializeDocumentModal(true);
                                  setPassedDocumentSlug('tec-funds-received-schedule');
                                  dispatch(setActiveModal('funds-received-schedule'));
                                }}
                                data-testid="orderpage.documentContext.generateFundsReceivedSchedule"
                              >
                                <b>Generate Funds Received Schedule</b>
                              </Dropdown.Item>
                            ):null}
                        

                            <Dropdown.Item
                              data-bs-toggle="modal"
                              data-bs-target="#documentsetmodal"
                              disabled={readOnly}
                              onClick={e => {
                                setUpdateDocSetModal((updateDocSetModal + 1));
                                dispatch(setActiveModal('document-set-modal'))
                              }}
                              data-testid="orderpage.documentContext.generateDocumentSet"
                              // onClick={setUpdateDocSet(Math.random())}
                            >
                              <b>Generate Document Set</b>
                            </Dropdown.Item>
                            <Dropdown.Item
                              data-bs-toggle="modal"
                              data-bs-target="#documentsmodal"
                              disabled={readOnly}
                              data-testid="orderpage.documentContext.addDocument"
                            >
                              <b>Add Document</b>
                            </Dropdown.Item>

                            {orderdocuments?.length > 0 ? (
                              <Dropdown.Item
                                data-bs-toggle="modal"
                                data-bs-target="#deleteAllDocModal"
                                disabled={readOnly}
                                data-testid="orderpage.documentContext.deleteAllDocuments"
                              >
                                <b>Delete All Documents</b>
                              </Dropdown.Item>
                            ):null}

                            <hr></hr>
                          
                            <Dropdown.Item
                              data-bs-toggle="modal"
                              data-bs-target="#printAllDocModal"
                              data-testid="orderpage.documentContext.printAll"
                            >
                              <b>Print All</b>
                            </Dropdown.Item>

                          </OrderSection>
                          <Accordion.Collapse eventKey="7">
                            <Card>
                              <Accordion >
                                <DocumentSection title="ORDER DOCUMENTS" eventKey="1x" setItemSearch={setItemSearch} setDocSearch={setDocSearch} isOpen={isOpen} setIsOpen={setIsOpen}recCount={ ( orderdocuments && orderdocuments.length ? orderdocuments.length : 0 ) }>
                                </DocumentSection>
                                { orderdocuments && orderdocuments.length > 0 ? (
                                  <Accordion.Collapse eventKey="1x" in={isOpen.documentSection==='1x'}>
                                    <>
                                      <DocSearchBar searchVal={docSearch} handleChange={(e)=> setDocSearch(e.target.value.toLowerCase())} label="Document" datatestid="documentSection.order.documentSearch"/>
                                      <OrderDocumentsSection
                                        readOnly={readOnly}
                                        documents={docSearch===''?orderdocuments:orderdocuments?.filter((doc)=>doc.documentTemplate.name.toLowerCase().includes(docSearch) || ( readOnly && doc?.documentStatus==='Generated'))}
                                        deleteDocument={deleteDocument}
                                        documentFocus={documentFocus}
                                        setDocumentFocus={setDocumentFocus}
                                        isDeletingDoc={isDeletingDoc}
                                        isDocActionDisabled={isDocActionDisabled}
                                        isAddingDocument={isAddingDocument}
                                        />
                                      </>
                                  </Accordion.Collapse>
                                ):null}
                                <DocumentSection title="PRODUCT DOCUMENTS" eventKey="2d" isOpen={isOpen} setItemSearch={setItemSearch} setDocSearch={setDocSearch} setIsOpen={setIsOpen} recCount={ ( order && order.products && order.products.length ? order.products.length : 0 ) }>
                                </DocumentSection>
                                <Accordion.Collapse eventKey="2d"in={isOpen?.documentSection==="2d"} >
                                  <>
                                <DocSearchBar searchVal={itemSearch} handleChange={(e)=> setItemSearch(e.target.value.toLowerCase())} label="Product Stock Number" datatestid="documentSection.productSearch"/>
                                  <Accordion data-testid="productDocuments.accordian">
                                    { order && order.products && order.products.length > 0 ? ( order.products.sort((a, b) => a.stockNumber?.localeCompare(b.stockNumber, undefined, { numeric: true })).filter(prod=>prod.stockNumber.toLowerCase().includes(itemSearch)).map(product => (
                                      <div key={'pro'+product.id} className='px-2'>
                                        <DocumentSubSection title={ ( product.stockNumber ?? "[Product Not Found]") } readOnly={readOnly} isOpen={isOpen} setDocSearch={setDocSearch} setItemSearch={setItemSearch} setIsOpen={setIsOpen} eventKey={product.id+"p"} >
                                        </DocumentSubSection>
                                        <Accordion.Collapse eventKey={product.id+"p"} in={isOpen?.documentSubSection===product.id+"p"}><>
                                        <DocSearchBar searchVal={docSearch} handleChange={ (e) => setDocSearch(e.target.value.toLowerCase())} label="Document" datatestid="documentSection.products.documentSearch"/>
                                          <ProductDocumentsSection 
                                            readOnly={readOnly}
                                            product={product}
                                            documents={docSearch===''?orderdocuments:orderdocuments?.filter((doc) => doc.documentTemplate.name.toLowerCase().includes(docSearch) || (readOnly && doc?.documentStatus==='Generated'))} 
                                            deleteDocument={deleteDocument}
                                            documentFocus={documentFocus}
                                            setDocumentFocus={setDocumentFocus}
                                            isDeletingDoc={isDeletingDoc}
                                            isDocActionDisabled={isDocActionDisabled}
                                            isAddingDocument={isAddingDocument}
                                            /></>
                                        </Accordion.Collapse>
                                      </div>
                                    ))):null}
                                  </Accordion>
                                  </>
                                </Accordion.Collapse>
                                <DocumentSection title="TRADE IN DOCUMENTS" eventKey="3d" isOpen={isOpen} setIsOpen={setIsOpen} setDocSearch={setDocSearch} setItemSearch={setItemSearch} recCount={ ( order && order.tradeins && order.tradeins.length ? order.tradeins.length : 0 ) }>
                                </DocumentSection>
                                <Accordion.Collapse eventKey="3d" in={isOpen?.documentSection==="3d"}>
                                  <>
                                  <DocSearchBar searchVal={itemSearch} handleChange={(e)=> setItemSearch(e.target.value.toLowerCase())} label="Trade-in Vin" datatestid="documentSection.tradeinSearch"/>
                                  <Accordion data-testid="tradeinDocuments.accordian"> 
                                    { order && order.tradeins && order.tradeins.length > 0 ? ( order.tradeins.sort((a, b) => a.vin?.localeCompare(b.vin, undefined, { numeric: true })).filter(tradeIn=>tradeIn.vin.toLowerCase().includes(itemSearch)).map(trade => (
                                      <div key={'tra'+trade.id} className='px-2'>
                                        <DocumentSubSection title={ ( trade.vin ?? "[ Trade Not Found]") } setDocSearch={setDocSearch} setItemSearch={setItemSearch} isOpen={isOpen} setIsOpen={setIsOpen} eventKey={trade.id+"t"} recCount={ 10 }>
                                        </DocumentSubSection>
                                        <Accordion.Collapse eventKey={trade.id+"t"} in={isOpen?.documentSubSection===trade.id+"t"}>
                                          <>
                                            <DocSearchBar searchVal={docSearch} handleChange={(e)=> setDocSearch(e.target.value.toLowerCase())} label="Document" datatestid="documentSection.tradeins.documentSearch"/>
                                            <TradeinDocumentsSection 
                                            readOnly={readOnly}
                                              tradein={trade}
                                              documents={docSearch===''?orderdocuments:orderdocuments?.filter((doc) => doc.documentTemplate.name.toLowerCase().includes(docSearch) || ( readOnly && doc?.documentStatus==='Generated'))} 
                                              deleteDocument={deleteDocument}
                                              documentFocus={documentFocus}
                                              setDocumentFocus={setDocumentFocus}
                                              isDeletingDoc={isDeletingDoc}
                                              isDocActionDisabled={isDocActionDisabled}
                                              isAddingDocument={isAddingDocument}
                                              />
                                            </>
                                        </Accordion.Collapse>
                                      </div>
                                    ))):null}
                                  </Accordion>
                                            </>
                                </Accordion.Collapse>
                              </Accordion>
                            </Card>
                
                            </Accordion.Collapse>
                        </Card>
                      </div>
                    </div>
                    )}
                  </Accordion>
                      <DocumentViewModal document={documentFocus ? documentFocus : null} />
                      
                      {/* Delete all documents modal */}
                      <DeleteDocsModal order={order} orderdocuments={orderdocuments} updateOrderDocsInfo={updateOrderDocsInfo}/>

                      {/* //Order Documents */}
                      <DocumentInvoiceModal initializeDocumentModal={initializeDocumentModal} setInitializeDocumentModal={setInitializeDocumentModal} setDocumentFocus={setDocumentFocus} passedDocumentSlug={passedDocumentSlug} document_id={documentFocus && documentFocus.id ? documentFocus.id : null} location={location}  getDocuments={getDocuments}  taxExemptReasons={taxExemptReasons} />
                      <DocumentScheduleAModal initializeDocumentModal={initializeDocumentModal} setInitializeDocumentModal={setInitializeDocumentModal} setDocumentFocus={setDocumentFocus} passedDocumentSlug={passedDocumentSlug} document_id={documentFocus && documentFocus.id ? documentFocus.id : null} location={location}  getDocuments={getDocuments} />
                      <DocumentTradeScheduleModal  initializeDocumentModal={initializeDocumentModal} setInitializeDocumentModal={setInitializeDocumentModal} setDocumentFocus={setDocumentFocus} passedDocumentSlug={passedDocumentSlug} document_id={documentFocus && documentFocus.id ? documentFocus.id : null} location={location}  getDocuments={getDocuments} />
                      <DocumentFundsReceivedScheduleModal initializeDocumentModal={initializeDocumentModal} setInitializeDocumentModal={setInitializeDocumentModal} setDocumentFocus={setDocumentFocus} passedDocumentSlug={passedDocumentSlug} document_id={documentFocus && documentFocus.id ? documentFocus.id : null} location={location}  getDocuments={getDocuments} />
                      <TECSalesOrder setDocumentFocus={setDocumentFocus} passedDocumentSlug={passedDocumentSlug} document_id={documentFocus && documentFocus.id ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      
                      {/* //Product & Trade Documents */}
                      <TECInsuranceRequest setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"}  document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <CABillOfSale setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <CAPowerOfAttorney  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <TECPowerOfAttorney  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <TECDoA  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />   

                      {/* //Product Documents */}
                      <OregonVINModal  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <OregonTitleModal  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} getDocuments={getDocuments} />
                      <OregonBillOfSaleModal  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      
                      <CATitleApp343  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <CAPTICert  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"}  document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <CADMVDeclarationOfGVW  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <CASection837SalesTaxAffidavit  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <CAStatementOfFacts  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <CASalesTaxStatement6247  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <CACertificateOfNonOperation  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <CADeliveryStatement  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                    
                      <WAVehicleTitleApplication  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"}  document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <WAVehicleTitleApplicationAdditional  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"}  document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      
                      <NVVehicleInspectionCertificate  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <NVErasureAffidavit  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <NVBillOfSale  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <NVPowerOfAttorney  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      
                      <AZTitleApp  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <AZBillOfSale  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <AZ5000  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <AZ5010  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <AZ5011  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <AZ5012  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      
                      <TECSoldAsIs  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <TECTaxWaiver  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"}  document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <TECDepositReceipt  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"}  document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <TECDeliveryReceipt  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"}  document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <TECBillofSale  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"}  document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <TECCaliforniaSalesTaxExemptionCertificate  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <IRS8300  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <WAWholesale  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <WASalesTax  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                                      
                      {/* //Trade in Documents */}
                      <TECPayOffAuthorization  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"}  document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <WAReleaseOfInterestPowerOfAttorney  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"}  document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />

                      <SD45DayExt  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <SDDealerReport  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <SDDuplicateTitle  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <SDPoA  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <SDTitleApp  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <NEForm6  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <NEForm13  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <NEPoA  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <NETitleApp  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <NVAffPurchaser  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <IATitleApp  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <CAResaleCert  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />

                      <TECAffidavit  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"}  document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <TECCAAffExmptSalesTax  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"}  document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <TECCertOOSSingle  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"}  document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <TECCertOOSMulti  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"}  document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <TECStatementOfError  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"}  document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <TECVehicleSalesDocument  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"}  document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <TECWAWeightSlipTrailers  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"}  document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <WABillOfSale  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                      <CACarb  setDocumentFocus={setDocumentFocus} locations={locations} passedDocumentSlug={documentFocus ? documentFocus.documentTemplate.slug : "NULL"} document_id={documentFocus ? documentFocus.id : null} location={location} getDocuments={getDocuments} />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>}
            </div>
          </div>
        </>
      ):null}
    </>
  );
};

const { useIsOpen: useTradeinModalIsOpen }  = tradeinRdx
const { useIsOpen: useCustomerModalIsOpen } = customerRdx
const { useIsOpen: useDownPaymentModalIsOpen }  = downpaymentRdx
const { useOpenFundingPaymentModal } = fundingPaymentRdx

export default Page;
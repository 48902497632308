import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  useParams
} from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
// import States from '../States';
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
// import FormLabel from '@mui/material/FormLabel';
import { formatter, removeChars, formatUpperCase } from "../../../../../../../utlis/formatters";
import { getOrderLevelAddresses, updateAddressOptions } from "../../../../../../../utlis/documentHelpers";
import { useOrder } from "../../../../../../../redux/orders/order"
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { CAStatementOfFactsGlobalAutofill, CAStatementOfFactsAutofillProductDetails, preProcessPayload } from "../DocumentAutofill/CAStatementOfFacts"

const CAStatementOfFacts = ({document_id, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "ca-statement-of-facts";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  // const dispatch = useDispatch();

  // const stateAbbrev = States.map(state => {
  //   return state.abbreviation;
  // });

  const order = useOrder();

  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);
  
  // Data Structure & Init //
  
  var initialDocumentStructure = {
    undersigned_statement: undefined,
    name_misspelled: undefined,
    same_person: undefined,
    same_person_name_1: undefined,
    same_person_name_2: undefined,
    name_misspelled_name: undefined,
    changing_name_to: undefined,
    changing_name_from: undefined,
    changing_name: undefined,
    market_value: undefined, //?
    changes_date: undefined,
    changes_cost: undefined, //
    unladen_weight_reason: undefined,
    unladen_weight_changed: undefined,
    motive_power_changed: undefined,
    motive_power_from: undefined,
    motive_power_to: undefined,
    body_type_from: undefined,
    body_type_to: undefined,
    body_type_changed: undefined,
    number_axles_to: undefined,
    number_axles_from: undefined,
    axles_changed: undefined,
    license_plate: undefined,
    copy_license_plate: undefined,
    copy_year_make: undefined,
    year_make: undefined,
    vin: undefined, //
    copy_vin: undefined,
    family_transfer: undefined,
    addition_deletion_family_member: undefined,
    gift: undefined,
    court_order: undefined,
    inheritance: undefined,
    current_market_value: undefined,
    power_electric: undefined,
    power_diesel: undefined,
    power_other: undefined,
    power_other_text: undefined,
    powered: undefined,
    last_smog_recent: undefined,
    outside_ca_nv_mx: undefined,
    transfer: undefined,
    close_member_transfer: undefined,
    sole_propietorship_transfer: undefined,
    lessor_transfer: undefined,
    lessor_operator_transfer: undefined,
    lease_company_transfer: undefined,
    registered_owner_add_transfer: undefined,
    transfer_only: undefined,
    title_only: undefined,
    disabled_person_plate: undefined,
    disabled_veteran_plate: undefined,
    disabled_person_placard: undefined,
    window_vin: undefined, //
    window_make: undefined,
    window_license_plate: undefined,
    mailing_name: undefined,
    mailing_address: undefined,
    mailing_city: undefined,
    mailing_state: undefined,
    mailing_zip: undefined,
    printed_name: undefined,
    first_name: undefined,
    middle_name: undefined,
    area_code: undefined,
    phone: undefined,
    signature_date: undefined,
    exempt_reason: undefined,  // Radio
    smog_exempt: undefined,  // Radio
    smog_exempt_powered: undefined, // Radio
    smog_exempt_transfer: undefined, // Radio
    trans_title: undefined  // Radio
  }
  
  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  // X1
  const removeFormatting = e =>{
    const {name,value}=e.target;
    setDocumentValues({...documentValues,[name]:removeChars(value)});
  }

  // X1 Reference Arrays - TODO: Possibly move these to back end as part of the template stucture
  const moneyFields = useMemo(()=>{return["market_value", "changes_cost", "current_market_value"]},[]);
  const phoneFields = useMemo(()=>{return[]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "window_vin"]},[]);
  const checkboxes = useMemo(()=>{return[
    "unladen_weight_changed", 
    "motive_power_changed", 
    "body_type_changed", 
    "axels_changed",
    "same_person",
    "name_misspelled",
    "changing_name"
  ]},[]);
  // const radios = [
  //   "exempt_reason", 
  //   "smog_exempt", 
  //   "smog_exempt_powered",  
  //   "smog_exempt_transfer", 
  //   "trans_title"
  // ];

  // X1.1- Current General On Change
  const handleDocumentValuesChange = e =>{
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  
  const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
       recordFrontEndError('CaStatementOfFacts.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, documentLoaded, loadDocument ])
  const [assignedProduct, setAssignedProduct] = useState(undefined);
  // const [productVal, setproductVal] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  // const [tradeinVal, setTradeinVal] = useState(undefined);
  // const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  // const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);

  const [sofVal, setSofVal] = useState(undefined);
  const [selectedSof, setselectedSof] = useState(undefined);
  const [inputSofVal, setinputSofVal] = useState(undefined);
  const [sofOptions, setSofOptions] = useState(undefined);
  const [sofToggleAutoFill, setSofToggleAutoFill] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const [addressOptions, setAddressOptions] = useState(undefined);
  const [inputAddressVal, setinputAddressVal] = useState(undefined);

  const [undersignedStatementError, setundersignedStatementError] = useState(false);
  // const [modalDismiss, setmodalDismiss] = useState(true);

// Dropdowns //

// if(order && order.products && order.products.length > 0 && productOptions === undefined){
//   let getProductOptions = [];
//   order.products.map(product => {
//     let str = `${product.stockNumber.toString().toUpperCase()}`;
//     let obj = {id: product.id, name: str}
//     getProductOptions.push(obj);
//   })
//   setproductOptions(getProductOptions);
// }

// if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
//   let getTradeinOptions = [];
//   order.tradeins.map(tradein => {
//     let str = `${tradein.year} ${tradein.make} ${tradein.model.toString().toUpperCase()}`;
//     let obj = {id: tradein.id, name: str}
//     getTradeinOptions.push(obj);
//   })
//   setTradeinOptions(getTradeinOptions);
// }

// function getOrderLevelAddresses(order){
//   let getAddressOptions = [];
//   if(order.customer && order.customer.deliveryAddress){
//     var addressLine = '';
//     if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
//       addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
//     }else{
//       addressLine = order.customer.deliveryAddress.addressLine1;
//     }
//     let city = order.customer.deliveryAddress.city;
//     let state = order.customer.deliveryAddress.state;
//     let zip = order.customer.deliveryAddress.zip;

//     let obj = {slug: "customer-delivery", name: "Customer Delivery", address: addressLine, city: city, state: state, zip: zip};
//     getAddressOptions.push(obj);
//   }

//   if(order.customer && order.customer.mailingAddress){

//     if(order.customer.mailingAddress.addressLine2 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
//       addressLine = (order.customer.mailingAddress.addressLine1+" "+order.customer.mailingAddress.addressLine2);
//     }else{
//       addressLine = order.customer.mailingAddress.addressLine1;
//     }
//     let city = order.customer.mailingAddress.city;
//     let state = order.customer.mailingAddress.state;
//     let zip = order.customer.mailingAddress.zip;

//     let obj = {slug: "customer-mailing", name: "Customer Mailing", address: addressLine, city: city, state: state, zip: zip};
//     getAddressOptions.push(obj);
//   }
//   return getAddressOptions;
// }

if(sofOptions === undefined){
  let getSofOptions = [
    {name: "Out-of state delivery by a common carrier. PTI registered."},
    {name: "Paid CA Sales tax. PTI registered."},
    {name: "Customer demands title. Customer has received title. Customer is responsible for securing the lien within 10 days."},
    {name: "Customer demands MSO. Customer has received MSO. Customer is responsible for securing the lien within 10 days."},
    {name: "This unit weighs more than 15,001lbs. Sales tax has been paid in the state of CA. Body type is XXXX. Fuel type is XXXX."},
    {name: "This unit weighs more than 15,001lbs. Vehicle has been delivered out-of-state by a common carrier. Body type is XXXX. Fuel type is XXXX. Vehicle is registered under IRP. Process as title only."},
  ];
  setSofOptions(getSofOptions);
}

 // Autofill //

 const GlobalAutofill = useCallback(async () => {
  let newValues = CAStatementOfFactsGlobalAutofill(documentValues, order);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  // var newValues = documentValues;
  
  // if(order && order.customer && order.customer.name){
  //   if(order.customer.type === "entity"){
  //     newValues.printed_name = order.customer.name;
  //   }else{
  //     newValues.printed_name = getLastName(order.customer.name);
  //     newValues.first_name = getFirstName(order.customer.name);
  //     newValues.middle_name = getMiddleName(order.customer.name);
  //   }
  //   newValues.area_code = getAreaCode(order.customer.phone);
  //   newValues.phone = getPhoneNumber(order.customer.phone);    
  // }

  // if(order && order.customer && order.documentationDate){
  //   newValues.signature_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  // }

  // setDocumentValues(newValues);
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
 },[ documentValues, order ])

  // Document BREAD + Preview // TODO: Move to Redux

  // const preProcessPayload = useCallback((payload) => {



  //   if(payload.vin){
  //     payload.copy_vin = payload.vin;
  //   }

  //   if(payload.year_make){
  //     payload.copy_year_make = payload.year_make;
  //   }

  //   if(payload.license_plate){
  //     payload.copy_license_plate = payload.license_plate;
  //   }

  //   if(payload.exempt_reason){
  //     // Reset all
  //     payload.family_transfer = "no";
  //     payload.addition_deletion_family_member = "no";
  //     payload.gift = "no";
  //     payload.court_order = "no";
  //     payload.inheritance = "no";

  //     switch(payload.exempt_reason){
  //       case "family_transfer":
  //         payload.family_transfer = "yes";
  //         break;
  //       case "addition_deletion_family_member":
  //         payload.addition_deletion_family_member = "yes";
  //         break;
  //       case "gift":
  //         payload.gift = "yes";
  //         break;
  //       case "court_order":
  //         payload.court_order = "yes";
  //         break;        
  //       case "inheritance":
  //         payload.inheritance = "yes";
  //         break;
  //       default:
  //     }
  //   }

  //   if(payload.smog_exempt){
  //     // Reset all
  //     payload.last_smog_recent = "no";

  //     payload.powered = "no";
  //       payload.power_electric = "no";
  //       payload.power_diesel = "no";
  //       payload.power_other = "no";

  //     payload.outside_ca_nv_mx = "no";

  //     payload.transfer = "no";
  //       payload.close_member_transfer = "no";
  //       payload.sole_propietorship_transfer = "no";
  //       payload.lease_company_transfer = "no";
  //       payload.lessor_transfer = "no";
  //       payload.lessor_operator_transfer = "no";
  //       payload.registered_owner_add_transfer = "no";


  //     switch(payload.smog_exempt){
  //       case "last_smog_recent":
  //         payload.last_smog_recent = "yes";
  //         break;
  //       case "powered":
  //         payload.powered = "yes";

  //         if(payload.smog_exempt_powered){
  //           switch(payload.smog_exempt_powered){
  //             case "power_electric":
  //               payload.power_electric = "yes";
  //               break;
  //             case "power_diesel":
  //               payload.power_diesel = "yes";
  //               break;
  //             case "power_other":
  //               payload.power_other = "yes";
  //               break;
  //             default:
  //           }

  //           if(payload.smog_exempt_powered !== "power_other"){
  //             payload.power_other_text = "";
  //           }
  //         }

  //         break;
  //       case "outside_ca_nv_mx":
  //         payload.outside_ca_nv_mx = "yes";
  //         break;
  //       case "transfer":
  //         payload.transfer = "yes";

  //         if(payload.smog_exempt_powered){
  //           switch(payload.smog_exempt_powered){
  //             case "close_member_transfer":
  //               payload.close_member_transfer = "yes";
  //               break;
  //             case "sole_propietorship_transfer":
  //               payload.sole_propietorship_transfer = "yes";
  //               break;
  //             case "lease_company_transfer":
  //               payload.lease_company_transfer = "yes";
  //               break;
  //             case "lessor_transfer":
  //               payload.lessor_transfer = "yes";
  //               break;
  //             case "lessor_operator_transfer":
  //               payload.lessor_operator_transfer = "yes";
  //               break;
  //             case "registered_owner_add_transfer":
  //               payload.registered_owner_add_transfer = "yes";
  //               break;
  //           default:
  //           }
  //         }

  //         break;        
  //       case "inheritance":
  //         payload.inheritance = "yes";
  //         break;
  //       default:
  //     }
  //   }

  //   if(payload.trans_title){
  //     // Reset all
  //     payload.transfer_only = "no";
  //     payload.title_only = "no";


  //     switch(payload.trans_title){
  //       case "transfer_only":
  //         payload.transfer_only = "yes";
  //         break;
  //       case "title_only":
  //         payload.title_only = "yes";
  //         break;
  //       default:
  //     }
  //   }

  //   // X1.2- Field Type Specific Preview Formatting
  //   Object.keys(payload).forEach(key => {
  //     if(moneyFields.indexOf(key) >= 0){
  //         payload[key] = formatMoneyValue(payload[key]);
  //     }else if(phoneFields.indexOf(key) >= 0){
  //         payload[key] = formatPhoneNumber(payload[key]);
  //     }else if(upperCaseFields.indexOf(key) >= 0){
  //         payload[key] = formatUpperCase(payload[key]);
  //     }
  //   });
  //   setDocumentValues(JSON.parse(JSON.stringify(payload)));
  //   Object.keys(payload).forEach(key => {
  //     if(checkboxes.indexOf(key) >= 0){
  //       payload[key] = payload[key] ? "yes": "no";
  //     }
  //   });
  
  //   // Data point formatting
  //   if(payload.changes_date){
  //     payload.changes_date = moment.utc(payload.changes_date).format("MM/DD/YY");
  //   }

  //   if(payload.signature_date){
  //     payload.signature_date = moment.utc(payload.signature_date).format("MM/DD/YY");
  //   }

  //   return payload;
  // },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])



  const previewPayloadDocument = useCallback(async (documentValues) => {
    var payload = documentValues;
      Object.keys(payload).forEach(key => {
        if (payload[key] === null || payload[key] === undefined) {
          delete payload[key];
        }
      });
      
      var docCopy = JSON.parse(JSON.stringify(document));
      docCopy.payload =  JSON.parse(JSON.stringify(payload));
      docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
      docCopy.payload =  JSON.stringify(docCopy.payload);
      delete docCopy.documentStatus;
  
      try {
        let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
        setdocumentUrl(fileURL)
      } catch (err) {
        recordFrontEndError('CAStatementOfFacts.jsx', 'Error previewing document payload: ' + err)
      };  
  },[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])
  

  //Autofill Product Details After Selection
  const AutofillProductDetails = useCallback(async (selectedProduct) => {
    let newValues = CAStatementOfFactsAutofillProductDetails(selectedProduct, documentValues);

    if(newValues){
      setDocumentValues(newValues);
      previewPayloadDocument(documentValues);
      setHasUnsavedChanges(true);
      if(document){
        previewPayloadDocument(documentValues);
      }
      setSaveSuccess(false);
    }

  },[ document, documentValues, previewPayloadDocument ]);

  useEffect(() => {
    if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){
  
      existingPayload = true;
  
      if(documentPayload.changes_date){
        documentPayload.changes_date = moment.utc(documentPayload.changes_date).format("YYYY-MM-DD");
      }

      if(documentPayload.signature_date){
        documentPayload.signature_date = moment.utc(documentPayload.signature_date).format("YYYY-MM-DD");
      }
    
      setDocumentValues(documentPayload);

      // Loading Use Effect
      if(document.additionalData){
        const methodMap = { 
          selectedAddress: setSelectedAddress, 
          selectedSof: setselectedSof, 
          sofVal: setSofVal, 
        };

        let parsedAdditionalData = JSON.parse(document.additionalData);
        Object.keys(parsedAdditionalData).forEach(function(key) {
          if(methodMap[key]){
            methodMap[key](parsedAdditionalData[key]);
          }
        });
      }

    }

      var foundAssignedProduct = null;
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
        foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
      }

      setAddressOptions(getOrderLevelAddresses(order));
      if(foundAssignedProduct){
          setAssignedProduct(foundAssignedProduct);
          setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
      }
      
      if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
      }
      
      setDataLoaded(true);
    }
  },[ documentValues, document, dataLoaded, AutofillProductDetails, GlobalAutofill, order, previewPayloadDocument ])
  
    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();

      
      let payload = JSON.parse(JSON.stringify(documentValues));
      
      
      if(payload && payload.undersigned_statement && payload.undersigned_statement.includes('XXXX')){
        setundersignedStatementError(true);
        return;
      }

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);

  
      //Save Dropdown Selections
      let dropdownData = {};
      if(selectedAddress){
        dropdownData.selectedAddress = {
          id: selectedAddress.id,
          name: selectedAddress.name
        };
      }
      if(selectedSof){
        dropdownData.selectedSof = {
          id: selectedSof.id,
          name: selectedSof.name
        };
      }
      if(sofVal){
        dropdownData.sofVal = {
          id: sofVal.id,
          name: sofVal.name
        };
      }

      let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, additionalData, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });

      // docCopy.additionalData = JSON.stringify(dropdownData);

      // try {
      //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
      //   if (res) {
      //     await axios.post(`/document/${document.id}/generate`)
      //     getDocuments();
      //     setSaveSuccess(true);
      //     setHasUnsavedChanges(false);
      //   }
      // } catch (err) {
      //   recordFrontEndError('CAStatementofFacts.jsx', 'Error saving document: ' + err)
      // }
      
    }

// Component Helpers //
// useEffect(() => {

// },[ selectedProduct, productToggleAutoFill ])

// useEffect(() => {
//   //Autofill Tradein Details After Selection
//   if(selectedTradein !== undefined && tradeinToggleAutoFill){
//     var newValues = documentValues;
//     newValues.trade_year = (selectedTradein.year);
//     newValues.trade_make = (selectedTradein.make);
//     newValues.trade_model = (selectedTradein.model);
//     newValues.trade_body_type = (selectedTradein.seriesBodyType);
//     newValues.trade_serial_number = (selectedTradein.serialNumber);
//     newValues.balance_owner = (order.customer.name); //Need specific field for TRADE IN OWNER?
//     newValues.balance_owner_address = (order.customer.deliveryAddress.addressLine1);
//     newValues.amount = (selectedTradein.balanceOwed);
//     newValues.trade_allowance = (selectedTradein.tradeAllowanceAtTerms);
//     newValues.balance_owed = (selectedTradein.balanceOwed);
//     newValues.cash_refund = (selectedTradein.cashRefundToCustomer);
//     newValues.net_trade_allowance = (selectedTradein.finalTradeAllowance);
//     // newValues.cash_down_payment = (selectedTradein);

//     setDocumentValues(newValues);
//     setTradeinToggleAutoFill(false);
//     if(document){
//       previewPayloadDocument(documentValues);
//     }
//   }
// },[ selectedTradein, tradeinToggleAutoFill ])

useEffect(() => {
  //Autofill Sof Details After Selection
  if(selectedSof !== undefined && sofToggleAutoFill){
    var newValues = documentValues;
    newValues.undersigned_statement = (selectedSof.name);

    setDocumentValues(newValues);
    setSofToggleAutoFill(false);
    if(document){
      previewPayloadDocument(documentValues);
    }
  }
},[ selectedSof, sofToggleAutoFill, document, documentValues, previewPayloadDocument ])

//Autofill Address Details After Selection
function AutoFillAddress(addressSelected, selectionInstance){
  var newValues = documentValues;
  if(selectionInstance){
    if(selectionInstance === "mailing-address"){
      newValues.mailing_address = addressSelected.address;
      newValues.mailing_city = addressSelected.city;
      newValues.mailing_state = addressSelected.state;
      newValues.mailing_zip = addressSelected.zip;
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  setDocumentValues(newValues);
  if(document){
    previewPayloadDocument(documentValues);
  }
}

const onCloseClick = e => {
  e.preventDefault()
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setdocumentUrl("");
  setDocument(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
  setDocumentLoaded(false);
  setDataLoaded(false);
  setundersignedStatementError(false);
  setAssignedProduct(undefined);
  setSofVal(undefined);
  setselectedSof(undefined);
  setinputSofVal(undefined);
  setSofOptions(undefined);
  setSofToggleAutoFill(false);
  setSelectedAddress(undefined);
  setAddressOptions(undefined);
  setinputAddressVal(undefined);
  // setmodalDismiss(false);
  setDocumentFocus(undefined);

}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

  return (
    <>
      <div
        className="modal fade"
        // id="ca-statement-of-facts"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                CA - Statement of Facts
              </h5>
              <br />
              { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1 pt-1 text-white">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                  </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}
                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill();
                      AutofillProductDetails(assignedProduct);
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>

                  <br />

                  { assignedProduct ? (
                      <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                          Product Snapshot
                          <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                          <h5>SN: { assignedProduct.stockNumber }</h5>
                          <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                          <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                          </div>
                      </div>
                  ):(
                      <div className="m-2 p-2 border">
                          <span>No Assigned Product</span>
                      </div>
                  )}

                  <div className="row">
                    <h5>Equipment</h5>
                  </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">License Plate/CF Number</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="license_plate"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.license_plate ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">VIN</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="vin"
                        maxLength="17"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.vin ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">Year/Make</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="year_make"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.year_make ?? '' }
                        />
                    </div>
                  </div>

                  <label htmlFor="stock"><b>A. STATEMENT FOR USE TAX EXEMPTION</b></label>
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                      <label htmlFor="stock">This transfer is exempt from use tax because it is a:</label>
                      <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="exempt_reason"
                            value={(documentValues.exempt_reason ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="family_transfer" control={<Radio />} label="Family transfer sold between a parent, child, grandparent, grandchild, spouse, domestic partner, or siblings (if both are
minors related by blood or adoption)." />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="addition_deletion_family_member" control={<Radio />} label="Addition or deletion of family member (spouse, domestic partner, parent[s], son/daughter, grandparents, grandchildren)." />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="gift" control={<Radio />} label="Gift (does not include vehicles traded between individuals, transfer of contracts or other valuable consideration).
" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="court_order" control={<Radio />} label="Court Order" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="inheritance" control={<Radio />} label="Inheritance" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                    </div>
                  </div>
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                      <label htmlFor="stock">Current Market Value</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="current_market_value"
                        onFocus={removeFormatting}
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.current_market_value ?? '' }
                        />
                    </div>
                  </div>


                  <label htmlFor="stock"><b>B. STATEMENT FOR SMOG EXEMPTION</b></label>
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                      <label htmlFor="stock">The vehicle does not require a smog certification for transfer of ownership because:</label>
                      <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="smog_exempt"
                            value={(documentValues.smog_exempt ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="last_smog_recent" control={<Radio />} label="The last smog certification was obtained within the last 90 days." />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="powered" control={<Radio />} label="It is powered by:" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="outside_ca_nv_mx" control={<Radio />} label="It is located outside the State of California. (Exception: Nevada and Mexico)" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="transfer" control={<Radio />} label="It is being transferred from/between:" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                    </div>
                  </div>

                  { documentValues.smog_exempt ===  "powered" ? (
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-1"></div>
                    <div className="p-2 col-md-12">
                      <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="smog_exempt_powered"
                            value={(documentValues.smog_exempt_powered ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="power_electric" control={<Radio />} label="Electricity" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="power_diesel" control={<Radio />} label="Diesel" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="power_other" control={<Radio />} label="Other" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                    </div>
                  </div>
                  ):null}

                  { documentValues.smog_exempt ===  "powered" &&  documentValues.smog_exempt_powered ===  "power_other" ? (   
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                      <label htmlFor="stock">Powered Other</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="power_other_text"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.power_other_text ?? '' }
                        />
                    </div>
                  </div>
                  ):null}

                  { documentValues.smog_exempt ===  "transfer" ? (
                    <div className="d-flex justify-content-evenly">
                    <div className="col-md-1"></div>
                    <div className="col-md-11">                      
                      <FormControl className="px-2 pt-1">
                          <RadioGroup
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="smog_exempt_transfer"
                            value={(documentValues.smog_exempt_transfer ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="close_member_transfer" control={<Radio />} label="The parent, grandparent, child, grandchild, brother, sister, spouse, or domestic partner (as defined in Family Code §297) of the transferee.*" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="sole_propietorship_transfer" control={<Radio />} label="A sole proprietorship to the proprietor as owner.*" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="lease_company_transfer" control={<Radio />} label="Companies whose principal business is leasing vehicles. There is no change in lessee or operator.*" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="lessor_transfer" control={<Radio />} label="Lessor and lessee of vehicle, and no change in the lessee or operator of the vehicle.*" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="lessor_operator_transfer" control={<Radio />} label="Lessor and person who has been lessee’s operator of the vehicle for at least one year.*" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="registered_owner_add_transfer" control={<Radio />} label="Individual(s) being added as registered owner(s).*" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                    </div>
                  </div>
                  ):null}

                  
                  <label htmlFor="stock"><b>C. STATEMENT FOR TRANSFER ONLY OR TITLE ONLY</b></label>
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                      <label htmlFor="stock">This vehicle has not been used or parked on a street or highway or off-highway. I am applying for a:</label>
                      <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="trans_title"
                            value={(documentValues.trans_title ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="transfer_only" control={<Radio />} label="Transfer Only" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="title_only" control={<Radio />} label="Title Only" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                    </div>
                  </div>
                  
                  <label htmlFor="stock"><b>D. WINDOW DECAL FOR WHEELCHAIR LIFT OR WHEELCHAIR CARRIER</b></label>
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">Disabled Person Plate</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="disabled_person_plate"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.disabled_person_plate ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">Disabled Veteran Plate</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="disabled_veteran_plate"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.disabled_veteran_plate ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">Disabled Person Placard</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="disabled_person_placard"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.disabled_person_placard ?? '' }
                        />
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">Window License Plate</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="window_license_plate"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.window_license_plate ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">Window Make</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="window_make"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.window_make ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">Window VIN</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="window_vin"
                        maxLength="17"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.window_vin ?? '' }
                        />
                    </div>
                  </div>
                  
                  {addressOptions && addressOptions.length > 0 ? (  
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress && selectedAddress.name) ?? null}
                          name="mailing-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress(newValue);
                            AutoFillAddress(newValue, "mailing-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="ADDRESS" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>        
                    ):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Mailing Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Mailing Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_address ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Mailing City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Mailing State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_state ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Mailing Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_zip ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6"></div>
                    </div>
                  
                  <label htmlFor="stock"><b>E. STATEMENT FOR VEHICLE BODY CHANGE (OWNERSHIP CERTIFICATE REQUIRED)</b></label>
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">Market Value</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="market_value"
                        onFocus={removeFormatting}
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.market_value ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">Changes Cost</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="changes_cost"
                        onFocus={removeFormatting}
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.changes_cost ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">Changes Date</label>
                      <input
                        type="date"
                        className="form-control rounded-pill mt-1"
                        name="changes_date"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.changes_date ?? '' }
                        />
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                    <label htmlFor="stock">This is what I changed: Check all that apply:</label>
                    <FormControl className="px-3 pt-2">
                            <FormControlLabel 
                              className="px-3" 
                              control={<input type="checkbox"
                                checked={ documentValues.unladen_weight_changed }
                                name="unladen_weight_changed"
                                onChange={handleDocumentValuesChange}
                                onBlur={(e) => updatePreview(e)}
                                className="m-2"
                                />} 
                              label="Unladen Weight changed because:" />
                              { documentValues.unladen_weight_changed ? (
                                <div className="d-flex justify-content-evenly">
                                  <div className="p-2 col-md-12">
                                    <label htmlFor="stock">Unladen Weight Changed Reason</label>
                                    <input
                                      type="text"
                                      className="form-control rounded-pill mt-1"
                                      onChange={handleDocumentValuesChange}
                                      onBlur={(e) => updatePreview(e)}
                                      name="unladen_weight_reason"
                                      value={ documentValues.unladen_weight_reason ?? '' }
                                      />
                                  </div>
                                </div>
                              ):null}

                            <FormControlLabel 
                              className="px-3" 
                              control={<input type="checkbox"
                                checked={ documentValues.motive_power_changed }
                                name="motive_power_changed"
                                onChange={handleDocumentValuesChange}
                                onBlur={(e) => updatePreview(e)}
                                className="m-2"
                                />} 
                              label="Motive Power changed from:" />

                            { documentValues.motive_power_changed ? (
                              <div className="d-flex justify-content-evenly">
                                <div className="p-2 col-md-6">
                                  <label htmlFor="stock">Changed From:</label>
                                  <input
                                    type="text"
                                    className="form-control rounded-pill mt-1"
                                    onChange={handleDocumentValuesChange}
                                    onBlur={(e) => updatePreview(e)}
                                    name="motive_power_from"
                                    value={ documentValues.motive_power_from ?? '' }
                                    />
                                </div>
                                <div className="p-2 col-md-6">
                                  <label htmlFor="stock">Changed To:</label>
                                  <input
                                    type="text"
                                    className="form-control rounded-pill mt-1"
                                    onChange={handleDocumentValuesChange}
                                    onBlur={(e) => updatePreview(e)}
                                    name="motive_power_to"
                                    value={ documentValues.motive_power_to ?? '' }
                                    />
                                </div>
                              </div>
                            ):null}

                            <FormControlLabel 
                              className="px-3" 
                              control={<input type="checkbox"
                                checked={ documentValues.body_type_changed }
                                name="body_type_changed"
                                onChange={handleDocumentValuesChange}
                                onBlur={(e) => updatePreview(e)}
                                className="m-2"
                                />} 
                              label="Body Type changed from:" />

                            { documentValues.body_type_changed ? (
                              <div className="d-flex justify-content-evenly">
                                <div className="p-2 col-md-6">
                                  <label htmlFor="stock">Changed From:</label>
                                  <input
                                    type="text"
                                    className="form-control rounded-pill mt-1"
                                    onChange={handleDocumentValuesChange}
                                    onBlur={(e) => updatePreview(e)}
                                    name="body_type_from"
                                    value={ documentValues.body_type_from ?? '' }
                                    />
                                </div>
                                <div className="p-2 col-md-6">
                                  <label htmlFor="stock">Changed To:</label>
                                  <input
                                    type="text"
                                    className="form-control rounded-pill mt-1"
                                    onChange={handleDocumentValuesChange}
                                    onBlur={(e) => updatePreview(e)}
                                    name="body_type_to"
                                    value={ documentValues.body_type_to ?? '' }
                                    />
                                </div>
                              </div>
                            ):null}

                            <FormControlLabel 
                              className="px-3" 
                              control={<input type="checkbox"
                                checked={ documentValues.axels_changed }
                                name="axels_changed"
                                onChange={handleDocumentValuesChange}
                                onBlur={(e) => updatePreview(e)}
                                className="m-2"
                                />} 
                              label="Number of Axles changed from:" />

                            { documentValues.axels_changed ? (
                              <div className="d-flex justify-content-evenly">
                                <div className="p-2 col-md-6">
                                  <label htmlFor="stock">Changed From:</label>
                                  <input
                                    type="text"
                                    className="form-control rounded-pill mt-1"
                                    onChange={handleDocumentValuesChange}
                                    onBlur={(e) => updatePreview(e)}
                                    name="number_axels_from"
                                    value={ documentValues.number_axels_from ?? '' }
                                    />
                                </div>
                                <div className="p-2 col-md-6">
                                  <label htmlFor="stock">Changed To:</label>
                                  <input
                                    type="text"
                                    className="form-control rounded-pill mt-1"
                                    onChange={handleDocumentValuesChange}
                                    onBlur={(e) => updatePreview(e)}
                                    name="number_axles_to"
                                    value={ documentValues.number_axles_to ?? '' }
                                    />
                                </div>
                              </div>
                            ):null}

                        </FormControl>
                    </div>
                  </div>

                  <label htmlFor="stock"><b>F. NAME STATEMENT (OWNERSHIP CERTIFICATE REQUIRED)</b></label>
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                    <FormControl className="px-3 pt-2">
                            <FormControlLabel 
                              className="px-3" 
                              control={<input type="checkbox"
                                checked={ documentValues.same_person }
                                name="same_person"
                                onChange={handleDocumentValuesChange}
                                onBlur={(e) => updatePreview(e)}
                                className="m-2"
                                />} 
                              label="I, _______________________________ and _______________________________ are one and the same person." />

                              <div className="d-flex justify-content-evenly">
                                <div className="p-2 col-md-6">
                                  <label htmlFor="stock">Person 1</label>
                                  <input
                                    type="text"
                                    className="form-control rounded-pill mt-1"
                                    onChange={handleDocumentValuesChange}
                                    onBlur={(e) => updatePreview(e)}
                                    name="same_person_name_1"
                                    value={ documentValues.same_person_name_1 ?? '' }
                                    />
                                </div>
                                <div className="p-2 col-md-6">
                                  <label htmlFor="stock">Person 2</label>
                                  <input
                                    type="text"
                                    className="form-control rounded-pill mt-1"
                                    onChange={handleDocumentValuesChange}
                                    onBlur={(e) => updatePreview(e)}
                                    name="same_person_name_2"
                                    value={ documentValues.same_person_name_2 ?? '' }
                                    />
                                </div>
                              </div>
                              <hr></hr>

                            <FormControlLabel 
                              className="px-3" 
                              control={<input type="checkbox"
                                checked={ documentValues.name_misspelled }
                                name="name_misspelled"
                                onChange={handleDocumentValuesChange}
                                onBlur={(e) => updatePreview(e)}
                                className="m-2"
                                />} 
                              label="My name is misspelled. Please correct it to: _________________________________________________________" />

                            <div className="d-flex justify-content-evenly">
                              <div className="p-2 col-md-12">
                                <label htmlFor="stock">Correct Spelling</label>
                                <input
                                  type="text"
                                  className="form-control rounded-pill mt-1"
                                  onChange={handleDocumentValuesChange}
                                  onBlur={(e) => updatePreview(e)}
                                  name="name_misspelled_name"
                                  value={ documentValues.name_misspelled_name ?? '' }
                                  />
                              </div>
                            </div>
                            <hr></hr>

                            <FormControlLabel 
                              className="px-3" 
                              control={<input type="checkbox"
                                checked={ documentValues.changing_name }
                                name="changing_name"
                                onChange={handleDocumentValuesChange}
                                onBlur={(e) => updatePreview(e)}
                                className="m-2"
                                />} 
                              label="I am changing my name from  _____________________________ to  ___________________________________" />

                              <div className="d-flex justify-content-evenly">
                                <div className="p-2 col-md-6">
                                  <label htmlFor="stock">Change From:</label>
                                  <input
                                    type="text"
                                    className="form-control rounded-pill mt-1"
                                    onChange={handleDocumentValuesChange}
                                    onBlur={(e) => updatePreview(e)}
                                    name="changing_name_from"
                                    value={ documentValues.changing_name_from ?? '' }
                                    />
                                </div>
                                <div className="p-2 col-md-6">
                                  <label htmlFor="stock">Change To:</label>
                                  <input
                                    type="text"
                                    className="form-control rounded-pill mt-1"
                                    onChange={handleDocumentValuesChange}
                                    onBlur={(e) => updatePreview(e)}
                                    name="changing_name_to"
                                    value={ documentValues.changing_name_to ?? '' }
                                    />
                                </div>
                              </div>
                              <hr></hr>

                        </FormControl>
                      </div>
                  </div>

                  <div className="row">
                    <label htmlFor="stock"><b>G. Statement of Facts</b></label>

                    {sofOptions && sofOptions.length > 0 ? (  
                    <div className="d-flex justify-content-evenly">
                        <div className="mb-3 col-md-12 pt-4">
                            <Autocomplete
                            style={{ width: '100%'}}
                            value={ (sofVal && sofVal.name) ?? null}
                            onChange={(event, newValue) => {
                                setSofVal(newValue);
                                setselectedSof(newValue);
                                setSofToggleAutoFill(true);
                                // resetVariables();
                            }}
                            inputValue={inputSofVal}
                            onInputChange={(event, newInputValue) => {
                              if(newInputValue.includes('XXXX')) {
                                setundersignedStatementError(true);
                                // setmodalDismiss(false);
                              } else {
                                setundersignedStatementError(false);
                                // setmodalDismiss(true);
                              }
                                setinputSofVal(newInputValue);
                            }}
                            //id="controllable-states-demo"
                            options={sofOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Undersigned Statement Options" />}
                            renderOption={(props, option) => {
                                return (
                                <li {...props} key={option.id}>
                                    {option.name}
                                </li>
                                );
                            }}
                            />
                        </div>
                    </div>
                    ):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> SOF Section G Templates Not Found</h5>
                      </div>
                    )}


                  </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                      <label htmlFor="stock">Undersigned Statement</label>
                      {/* <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="undersigned_statement"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.undersigned_statement ?? '' }
                      /> */}
                        <br />
                        <textarea
                          rows="4"
                          className="form-control mt-1 col-md-12"
                          name="undersigned_statement"
                          onChange={(e) => {
                            if(e.target.value.includes('XXXX')) {
                              // setmodalDismiss(false);
                            } else {
                              // setmodalDismiss(true);
                            }
                            handleDocumentValuesChange(e);
                            }
                          }
                          onBlur={(e) => { 
                            if(e.target.value.includes('XXXX')) {
                              setundersignedStatementError(true);
                            } else {
                              setundersignedStatementError(false);
                            }
                            updatePreview(e);
                            }
                          }
                          value={ documentValues.undersigned_statement ?? '' }
                        >
                          {documentValues.undersigned_statement }
                        </textarea>
                        <FormError validate={ undersignedStatementError }>
                          Please update instances of XXXX in the value above.
                        </FormError>
                    </div>
                  </div>

                  <label htmlFor="stock"><b>H. APPLICANT’S SIGNATURE</b></label>
                  <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Printed Last Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="printed_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.printed_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">First Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="first_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.first_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Middle Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="middle_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.middle_name ?? '' }
                          />
                      </div>
                    </div>


                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Area Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="area_code"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.area_code ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Phone</label>
                        <input
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{4}"
                          className="form-control rounded-pill mt-1"
                          name="phone"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.phone ?? '' }
                          />
                      </div>
                    </div>

                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="signature_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.signature_date ?? '' }
                          />
                      </div>
                    </div>
                <hr></hr>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
            {/* {modalDismiss === true ? (
            <button
              type="button"
              className="btn btn-primary mx-auto"
              data-bs-dismiss="modal"
              onClick={handleSubmit}
            >
              SUBMIT
            </button>
            ) : (
              <button
              type="button"
              className="btn btn-primary mx-auto"
              onClick={(e) => }
            >
              SUBMIT
            </button>
            )} */}

          { saveSuccess ? (
              <span
                className="btn btn-success mx-auto"
              >
                Saved Document
              </span>
            ):(
              <button
              type="button"
              className="btn btn-primary mx-auto"
              // data-bs-dismiss="modal"
              onClick={handleSubmit}
              >
              Save
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

//For displaying errros to the user
const FormError = ({ validate: validationError, children }) => {
  return !validationError ? null :
      <span className="text-danger small">{ children }</span>
}

export default CAStatementOfFacts

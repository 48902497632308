import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";
import { autoYMM, formatter } from './docUtils.js';

export const TECVehicleSalesDocumentGlobalAutofill = (documentValues, order, property=null, value=null, location) => {

    let newValues = documentValues;
    let customerName = "";
  
    if(property ==='customer.name'){
      customerName = order.customer.name;
        if(order.customer.dba){
          customerName +=" dba "+order.customer.dba;
        } 
      if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          customerName += " AND "+cosigner.name;
        }
      }
      newValues.buyer_1 = customerName;
      newValues.buyer_2 = customerName;
      newValues.buyer_3 = customerName;
  }
    if(property ==='customer.dba'){
        customerName = order.customer.name+" dba "+value;
      if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          customerName += " AND "+cosigner.name;
        }
      }
  
      newValues.buyer_1 = customerName;
      newValues.buyer_2 = customerName;
      newValues.buyer_3 = customerName;
  }
    if(property ==='customer.cosigner[0].name'){
        customerName = order.customer.name;
        if(order.customer.dba){
          customerName = order.customer.name+" dba "+order.customer.dba;
      }
      if(value!==''){
          customerName += " AND "+value;
      }
  
      newValues.buyer_1 = customerName;
      newValues.buyer_2 = customerName;
      newValues.buyer_3 = customerName;
  }
    if(property ==='customer.deliveryAddress.addressLine1'){
      newValues.customer_line_2 = value
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        newValues.customer_line_2 += " "+order.customer.deliveryAddress.addressLine2;
      }
  }
    if(property ==='customer.deliveryAddress.addressLine2'){
      newValues.customer_line_2 = order.customer.deliveryAddress.addressLine1+" "+value
  }
    if(property ==='customer.deliveryAddress.city'){
      newValues.customer_line_3 = value+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
  }
    if(property ==='customer.deliveryAddress.state'){
      newValues.customer_line_3 = order.customer.deliveryAddress.city+", "+value+" "+order.customer.deliveryAddress.zip;
  }
    if(property ==='customer.deliveryAddress.zip'){
      newValues.customer_line_3 = order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+value;
  }
    if(property ==='documentationDate'){
      newValues.date = moment.utc(value).format("YYYY-MM-DD");
  }
    if(property ==='location'){
        newValues.location_address = value.address+" "+value.city+" "+value.state+" "+value.zip;
        newValues.location_phone = value.phone;
        newValues.location_email = value.email;
    
      let tecName = "";
  
        if(value.state === "CA"){
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
          tecName = "TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
        }else{
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
          tecName = "TEC OF CALIFORNIA, INC.";
        }
  
      newValues.seller_1 = tecName;
  }
    if(property !== null && value !== null){
      return newValues;
  }
  
    if(order && order.customer && order.customer.name){
  
  
      if(order.customer.type === "entity"){
        customerName = order.customer.name;
      }else{
        if(order.customer.dba){
          customerName = order.customer.name+" dba "+order.customer.dba;
        }else{
          customerName = order.customer.name;
        } 
      }
      
      if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          customerName += " AND "+cosigner.name;
        }
      }
  
      newValues.buyer_1 = customerName;
      newValues.buyer_2 = customerName;
      newValues.buyer_3 = customerName;
  
      if(order.customer.deliveryAddress){
        var addressLine = '';
        if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
          addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
        }else{
          addressLine = order.customer.deliveryAddress.addressLine1;
        }
  
        newValues.customer_line_2 = addressLine
        newValues.customer_line_3 = order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
      }
    }
  
      if(location !== undefined){
        newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
      }
    
      if(location && location.phone){
        newValues.location_phone = location.phone;
      }
      
      if(location && location.email){
        newValues.location_email = location.email;
      }
    
      let tecName = "";
  
      if(location){
        if(location.state === "CA"){
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
          tecName = "TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
        }else{
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
          tecName = "TEC OF CALIFORNIA, INC.";
        }
      }
  
      newValues.seller_1 = tecName;
  
      if(order && order.documentationDate && documentValues.date_2 === undefined){
        newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
      }
  
      newValues.value_received = "Value Received";
  
    return newValues
}

export const TECVehicleSalesDocumentAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null, location=null, order=null, autofillProductDetails=true) => {
    var newValues = documentValues;

    if(property === 'year' && value !== null){
      newValues.year = (value).toString().trim().replace(/['"]+/g, '');
      newValues.ymm = autoYMM((value).toString().trim().replace(/['"]+/g, ''),selectedProduct.make,selectedProduct.model)
  }

  if(property === 'make' && value !== null){
      newValues.make = (value).toString().trim();
      newValues.ymm = autoYMM(selectedProduct.year,(value).toString().trim(),selectedProduct.model)
  }

  if(property === 'model' && value !== null){
      newValues.model = (value).toString().trim();
      newValues.ymm = autoYMM(selectedProduct.year,selectedProduct.make,(value).toString().trim())
  }
  if(property === 'price' && value !== null){
    newValues.dollar_value = formatter.format(selectedProduct.price);
  }
  
  if(property !== null && value !== null){
      return newValues;
  }

    if(autofillProductDetails){
      newValues.ymm = (selectedProduct.year).trim()+" "+(selectedProduct.make).trim()+" "+(selectedProduct.model).toString().toUpperCase();
      newValues.vin = (selectedProduct.vin).toString().toUpperCase();
      newValues.year = (selectedProduct.year).trim();
      newValues.make = (selectedProduct.make).trim();
      newValues.model = (selectedProduct.model).toString().toUpperCase();
      newValues.dollar_value = formatter.format(selectedProduct.price);
    }
    
    return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  // X1.2- Field Type Specific Preview Formatting
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  // Data point formatting
  if(payload.date_2){
    payload.date_2 = moment.utc(payload.date_2).format("MM/DD/YY");
  }

  return payload;
}
import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";

export const TECStatementOfErrorGlobalAutofill = (documentValues, order, property=null, value=null, location) => {
  var newValues = documentValues;

  if(property ==='documentationDate'){
    newValues.date = moment.utc(value).format("YYYY-MM-DD");
}
  if(property ==='location'){
      newValues.location_address = value.address+" "+value.city+" "+value.state+" "+value.zip;
      newValues.location_phone = value.phone;
      newValues.location_email = value.email;
      if(value.state === "CA"){
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
      }else{
      newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
      }
}
  if(property !== null && value !== null){
    return newValues;
}

    if(location !== undefined){
      newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
    }
  
    if(location && location.phone){
      newValues.location_phone = location.phone;
    }
    
    if(location && location.email){
      newValues.location_email = location.email;
    }
  
    if(location){
      if(location.state === "CA"){
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
      }else{
      newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
      }
    }

    if(order && order.documentationDate && documentValues.date === undefined){
      newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }

    newValues.letter_contents = "In regards to the vehicle listed above,";

    if(document && document.documentTemplate && document.documentTemplate.slug){
      if(document.documentTemplate.slug === "tec-statement-of-error-notary"){
        newValues.toggle_notary = true;
      }else{
        newValues.toggle_notary = false;
      }
    }

  return newValues
}

export const TECStatementOfErrorAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null, location=null, order=null, autofillProductDetails=true) => {
    var newValues = documentValues;

    if(property === 'year' && value !== null){
      newValues.ymm = (value).toString().trim().replace(/['"]+/g, '')+" "+(selectedProduct.make).trim()+" "+(selectedProduct.model).toString().toUpperCase();
  }

  if(property === 'make' && value !== null){
      newValues.ymm = (selectedProduct.year).trim()+" "+(value).toString().trim()+" "+(selectedProduct.model).toString().toUpperCase();
  }

  if(property === 'model' && value !== null){
      newValues.ymm = (selectedProduct.year).trim()+" "+(selectedProduct.make).trim()+" "+(value).toString().trim().toUpperCase();
  }

  if(property !== null && value !== null){
      return newValues;
  }

    if(autofillProductDetails){
      newValues.ymm = (selectedProduct.year).trim()+" "+(selectedProduct.make).trim()+" "+(selectedProduct.model).toString().toUpperCase();
      newValues.vin = (selectedProduct.vin).toString().toUpperCase();
    }
    

    return newValues;
  }


  export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

    // X1.2- Field Type Specific Preview Formatting
    Object.keys(payload).forEach(key => {
      if(moneyFields.indexOf(key) >= 0){
          payload[key] = formatMoneyValue(payload[key]);
      }else if(phoneFields.indexOf(key) >= 0){
          payload[key] = formatPhoneNumber(payload[key]);
      }else if(upperCaseFields.indexOf(key) >= 0){
          payload[key] = formatUpperCase(payload[key]);
      }
    });
    Object.keys(payload).forEach(key => {
      if(checkboxes.indexOf(key) >= 0){
        payload[key] = payload[key] ? "yes": "no";
      }
    });
  
    // Data point formatting
    if(payload.date){
      payload.date = moment.utc(payload.date).format("MM/DD/YY");
    }
  
    return payload;
  }
export const GET_PRODUCT_FOCUS    = "GET_PRODUCT_FOCUS";
export const SET_PRODUCT_FOCUS    = "SET_PRODUCT_FOCUS";
export const UPDATE_PRODUCT_FOCUS = "SAVE_PRODUCT";

export const getProductFocus = () => {
    return {
        type: GET_PRODUCT_FOCUS
    }
}

export const setProductFocus = (data) => {
    return {
        type: SET_PRODUCT_FOCUS, 
        payload: data 
    }
}

//Actually being called, refactor later...
export const updateProductFocus = (data) => {
    return {
        type: UPDATE_PRODUCT_FOCUS,
        payload: data
    }
}

const defaultOrdersState = {
    product_focus_id: null,
};

export const productFocusReducer = (store = defaultOrdersState , action) => {
    switch (action.type) {
        case SET_PRODUCT_FOCUS: 
            return {
                ...store,
                product_focus_id: action.payload.id
            }
        case GET_PRODUCT_FOCUS:
            return store.product_focus_id;
        case UPDATE_PRODUCT_FOCUS:
            return {
            ...store,
            product_focus_id: action.payload
            }
        default:
            return store
     }
}

import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  useParams
} from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { formatter, formatPhoneNumber } from "../../../../../../../utlis/formatters";
import { useOrder } from "../../../../../../../redux/orders/order"
import FASTFormElement from "../FASTFormElement"
import  { processValuesChange } from "../documentHelpers"
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { WAWholeSaleGlobalAutofill, WAWholeSaleAutofillProductDetails, WAWholeSaleAutofillTradeDetails, preProcessPayload } from "../DocumentAutofill/WAWholesale"


const WAWholesale = ({document_id, location, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {

  // Document Definition
  const documentTitle = "WA - Wholesale";
  const documentSlug = "wa-wholesale";
  const dateFields = useMemo(()=>{return["date", "date_seller", "date_buyer"]},[]);
  const moneyFields = useMemo(()=>{return["price"]},[]);
  const phoneFields = useMemo(()=>{return["dealer_phone"]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "model"]},[]);
  const checkboxes = useMemo(()=>{return["odo_1_selected", "odo_2_selected"]},[]);
  const radios = useMemo(()=>{return[{
                property: "sold_bought_toggle",
                options: [
                  {label: "SOLD TO", value: "sold_to_selected"}, 
                  {label: "BOUGHT FROM", value: "bought_from_selected"},
                  {label: "None", value: "clear"}
                ]}
              ]},[]);
            
  // Data Structure & Init //
  const initialDocumentStructure = {}

  // App Helpers //
  let { id } = useParams();
  const order = useOrder();
  const axios = useFetch()

  // Use State Objects //
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [documentUrl, setdocumentUrl] = useState("");
  const [document, setDocument] = useState(undefined);
  const [previousDocumentId, setPreviousDocumentId] = useState(0);
  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [assignedProduct, setAssignedProduct] = useState(undefined);
  const [assignedTrade, setAssignedTrade] = useState(undefined);
  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const [selectedLocation, setselectedLocation] = useState(undefined);
  const [locationOptions, setLocationOptions] = useState(undefined);
  const [locationToggleAutoFill, setLocationToggleAutoFill] = useState(false);
  const [hasClickedClose, setHasClickedClose] = useState(false);

  // Component Functions //

  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('WAWholesale.jsx', 'Error loading document: ' + err)
      }
    }
  },[axios, id])

  const handleDocumentValuesChange = e =>{
    // Primary Form Handler
    const {name} = e.target;
    const savedPos = e.target.selectionStart;
    
    setDocumentValues({...documentValues,[name]:processValuesChange(e.target, moneyFields, phoneFields, upperCaseFields, checkboxes)});
      
    if(upperCaseFields.indexOf(name) >= 0){
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }
    
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  const updatePreview = e => {
    e.preventDefault();
    previewPayloadDocument(documentValues);
  }
  
  const handleSubmit = async event => {
    event.preventDefault();

    let payload = JSON.parse(JSON.stringify(documentValues));

    payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
  
    // const payload = JSON.parse(JSON.stringify(documentValues));
    // var docCopy = document;
    // docCopy.payload = payload;
    // docCopy.payload = preProcessPayload(docCopy.payload);
    // docCopy.payload =  JSON.stringify(docCopy.payload);
    // delete docCopy.documentStatus;
    
    // if(selectedProduct && selectedProduct.id){
    //   docCopy.itemIds = `${selectedProduct.id}`;
    // }
    //Save Dropdown Selections
    let dropdownData = {};
    if(selectedAddress){
      dropdownData.selectedAddress = {
        id: selectedAddress.id,
        name: selectedAddress.name
      };
    }
    if(selectedLocation){
      dropdownData.selectedLocation = {
        locationCode: selectedLocation.locationCode,
        description: selectedLocation.description
      };
    }


    let additionalData = JSON.stringify(dropdownData);

    DocumentHandleSubmit(document, payload, additionalData, () => {
      getDocuments();
      setSaveSuccess(true);
      setHasUnsavedChanges(false);
    });

    // docCopy.additionalData = JSON.stringify(dropdownData);


    // //TODO: Add TradeId's to save.
    // try {
    //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
    //   if (res) {
    //     await axios.post(`/document/${document.id}/generate`)
    //     getDocuments();
    //     setSaveSuccess(true);
    //     setHasUnsavedChanges(false);
    //   }
    // } catch (err) {
    //   recordFrontEndError('WAWholesale.jsx', 'Error saving document: ' + err)
    // }
  }

  // Form Processing

  function getOptions(propertyName){
    let foundRadio = radios?.find(radio => radio.property === propertyName);
    return foundRadio?.options;
  }

  // const preProcessPayload = useCallback((payload) => {

  //   // Process radios for document preview
  //   radios.forEach(radio => {
  //     radio?.options.forEach(option => {
  //       if(option.value !== "clear"){ //Don't add "None" as a property.
  //         payload[option.value] = "no";
  //         if(payload[radio.property] === option.value){
  //           payload[option.value] = "yes";
  //         }
  //       }
  //     });
  //   });

  //   // Process money, phone and uppercase fields for document preview
  //   Object.keys(payload).forEach(key => {
  //     if(moneyFields.indexOf(key) >= 0){
  //         payload[key] = formatMoneyValue(payload[key]);
  //     }else if(phoneFields.indexOf(key) >= 0){
  //         payload[key] = formatPhoneNumber(payload[key]);
  //     }else if(upperCaseFields.indexOf(key) >= 0){
  //         payload[key] = formatUpperCase(payload[key]);
  //     }
  //   });
    
  //   setDocumentValues(JSON.parse(JSON.stringify(payload)));

  //   // Process checkboxes for document preview
  //   Object.keys(payload).forEach(key => {
  //     if(checkboxes.indexOf(key) >= 0){
  //       payload[key] = payload[key] ? "yes": "no";
  //     }
  //   });
    
  //   // Format Date Fields for the document
  //   dateFields?.forEach(datefield => {
  //     if(payload[datefield]){
  //       payload[datefield] = moment.utc(payload[datefield]).format("MM/DD/YY");
  //     }
  //   });


  //   return payload;
  // },[ checkboxes, moneyFields, phoneFields, upperCaseFields, radios, dateFields ])

  const previewPayloadDocument = useCallback(async (documentValues) => {
    var payload = documentValues;
      Object.keys(payload).forEach(key => {
        if (payload[key] === null || payload[key] === undefined) {
          delete payload[key];
        }
      });

      if(document){
        var docCopy = JSON.parse(JSON.stringify(document));
        docCopy.payload =  JSON.parse(JSON.stringify(payload));
        docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
        docCopy.payload =  JSON.stringify(docCopy.payload);
        delete docCopy.documentStatus;

        try {
          let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
          const file = new Blob(
            [response.data], 
            {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
            setdocumentUrl(fileURL)
          } catch (err) {
            recordFrontEndError('WAWholesale.jsx', 'Error previewing document payload: ' + err)
          }
        }
  },[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])

  // Unsaved Changes Functions //

  const onCloseClick = e => {
    e.preventDefault()
    setHasUnsavedChanges(false);
    setSaveSuccess(false);
    setdocumentUrl("");
    setDocument(undefined);
    setPreviousDocumentId(0);
    setDocumentValues(initialDocumentStructure);
    // setDocumentLoaded(false);
    setDataLoaded(false);
    setAssignedProduct(undefined);
    setAssignedTrade(undefined);
    setSelectedAddress(undefined);
    // setAddressOptions(undefined);
    // setinputAddressVal(undefined);
    // setLocationVal(undefined);
    setselectedLocation(undefined);
    // setinputLocationVal(undefined);
    setLocationOptions(undefined);
    setLocationToggleAutoFill(false);
    setDocumentFocus(undefined);
  }

  const closeClicked = e => {
    if( hasUnsavedChanges ){
        setHasClickedClose(true);
    } else {
        onCloseClick(e);
    }
  }

  const preCloseSave = e => {
    handleSubmit(e)
    setHasClickedClose(false);
  }

  const preCloseClose = e => {
    setHasClickedClose(false);
    onCloseClick(e)
  }
  
  // Autofill //

  const GlobalAutofill = useCallback(async () => {

    let newValues = WAWholeSaleGlobalAutofill(documentValues, order, null, null, locations);
    if(newValues){
      setDocumentValues(newValues);
      setHasUnsavedChanges(true);
      setSaveSuccess(false);
    }

  //   var newValues = documentValues;

  //   // TEC is Seller
  //   if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){

  //     newValues.dealer_name = "TEC EQUIPMENT, INC.";
  //     newValues.seller_name = "TEC EQUIPMENT, INC.";
  //     if(locations && locations.length > 0 && order.tecLocationCode){
  //         let foundLocation = locations.find(l => l.locationCode === order.tecLocationCode);
  //         if(foundLocation){
  //           newValues.seller_address = foundLocation.address;
  //           newValues.seller_city = formatUpperCase(foundLocation.city);
  //           newValues.seller_state = foundLocation.state;
  //           newValues.seller_zip = foundLocation.zip;

  //           newValues.dealer_address = foundLocation.address;
  //           newValues.dealer_city = formatUpperCase(foundLocation.city);
  //           newValues.dealer_state = foundLocation.state;
  //           newValues.dealer_zip = foundLocation.zip;
  //         }
  //       }

  //       if(order?.customer){
  //         newValues.buyer_name = order.customer.name;
  //         if(order.customer.cosigners && order.customer.cosigners.length > 0){
  //           let cosigner = order.customer.cosigners[0];
  //           if(cosigner && cosigner.name){
  //             newValues.buyer_name += " AND "+cosigner.name;
  //           }
  //         }

  //         if(order?.customer?.dba){
  //           newValues.buyer_company_name = order?.customer?.dba;
  //         }

  //         newValues.buyer_address = order.customer.deliveryAddress.addressLine1;
  //         if(order.customer.deliveryAddress.addressLine2 && order.customer.deliveryAddress.addressLine2 !== ""){
  //           newValues.buyer_address += " "+order.customer.deliveryAddress.addressLine2;
  //         }
  //         newValues.buyer_city = formatUpperCase(order.customer.deliveryAddress.city);
  //         newValues.buyer_state = order.customer.deliveryAddress.state;
  //         newValues.buyer_zip = order.customer.deliveryAddress.zip;
  //       }

  // }

  // // Customer Is Seller
  // if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'tradein'){

  //   if(order?.customer){
  //     newValues.seller_name = order.customer.name;

  //     newValues.seller_address = order.customer.deliveryAddress.addressLine1;
  //     if(order.customer.deliveryAddress.addressLine2 && order.customer.deliveryAddress.addressLine2 !== ""){
  //       newValues.seller_address += " "+order.customer.deliveryAddress.addressLine2;
  //     }
  //     newValues.seller_city = formatUpperCase(order.customer.deliveryAddress.city);
  //     newValues.seller_state = order.customer.deliveryAddress.state;
  //     newValues.seller_zip = order.customer.deliveryAddress.zip;
  //   }

  //   newValues.buyer_name = "TEC EQUIPMENT, INC.";
  //   newValues.buyer_company_name = "TEC EQUIPMENT, INC.";
  //   if(locations && locations.length > 0 && order.tecLocationCode){
  //       let foundLocation = locations.find(l => l.locationCode === order.tecLocationCode);
  //       if(foundLocation){
  //         newValues.buyer_address = foundLocation.address;
  //         newValues.buyer_city = formatUpperCase(foundLocation.city);
  //         newValues.buyer_state = foundLocation.state;
  //         newValues.buyer_zip = foundLocation.zip;
  //       }
  //   }

  // }

  //     setDocumentValues(newValues);
  //     setHasUnsavedChanges(true);
  //     setSaveSuccess(false);
  },[documentValues, locations, order])

  const AutofillProductDetails = useCallback(async (selectedProduct) => {

    let newValues = WAWholeSaleAutofillProductDetails(selectedProduct, documentValues);

    if(newValues){
      setDocumentValues(newValues);
      setHasUnsavedChanges(true);
      if(document){
        previewPayloadDocument(documentValues);
      }
      setSaveSuccess(false);
    }

    // var newValues = documentValues;
    // newValues.year = (selectedProduct.year);
    // newValues.make = (selectedProduct.make).trim();
    // newValues.vin = (selectedProduct.vin.toUpperCase());
    // newValues.body_type = (selectedProduct.vehicleType).trim().toUpperCase();
    // newValues.price = formatter.format(selectedProduct.price);
    // newValues.stock_number = selectedProduct.stockNumber;

    // setDocumentValues(newValues);
    // // setProductToggleAutoFill(false);
    // if(document){
    //   previewPayloadDocument(documentValues);
    // }
    // // setAddressOptions(updateAddressOptions(selectedProduct, order));
    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  },[ document, documentValues, previewPayloadDocument ]);

  const AutofillTradeDetails = useCallback(async (selectedTradein) => {

    let newValues = WAWholeSaleAutofillTradeDetails(documentValues, selectedTradein);

    if(newValues){
      setDocumentValues(newValues);
      setHasUnsavedChanges(true);
      if(document){
        previewPayloadDocument(documentValues);
      }
      setSaveSuccess(false);
    }


    // var newValues = documentValues;
    // newValues.year = (selectedTradein.year);
    // newValues.make = (selectedTradein.make).trim();
    // newValues.vin = (selectedTradein.vin.toUpperCase());
    // newValues.body_type = (selectedTradein.SeriesBodyType).trim().toUpperCase();
    // newValues.price = formatter.format(selectedTradein.FinalTradeAllowance);
    
  
    // setDocumentValues(newValues);
    // // setProductToggleAutoFill(false);
    // if(document){
    //   previewPayloadDocument(documentValues);
    // }
    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  },[ document, documentValues, previewPayloadDocument ]);

  // Use Effects //

  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, loadDocument ])

  useEffect(() => {
    if(document && document.payload && !dataLoaded){
      var existingPayload = false;
      var documentPayload = JSON.parse(document.payload);
      if(documentPayload && Object.keys(documentPayload).length > 0){
        
        existingPayload = true;
        
        // Format dateFields for the form
        dateFields?.forEach(datefield => {
          if(documentPayload[datefield]){
            documentPayload[datefield] = moment.utc(documentPayload[datefield]).format("YYYY-MM-DD");
          }
        });
        
        // if(documentPayload.date_of_sale){
          //   documentPayload.date_of_sale = moment.utc(documentPayload.date_of_sale).format("YYYY-MM-DD");
          // }
          
          setDocumentValues(documentPayload);
          
          // Loading Use Effect
          if(document.additionalData){
            const methodMap = { 
              selectedAddress: setSelectedAddress, 
              selectedLocation: setselectedLocation, 
            };
            
            let parsedAdditionalData = JSON.parse(document.additionalData);
        Object.keys(parsedAdditionalData).forEach(function(key) {
          if(methodMap[key]){
            if(key === "selectedLocation"){
              // setLocationVal(parsedAdditionalData[key]);
              if(parsedAdditionalData[key].locationCode){
                setselectedLocation(locations.find((location) => location.locationCode === parsedAdditionalData[key].locationCode));
              }
            }else{
              methodMap[key](parsedAdditionalData[key]);
            }
          }
        });
      }
    }
    
    var foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }
    
    // setAddressOptions(getOrderLevelAddresses(order));
    if(foundAssignedProduct){
        setAssignedProduct(foundAssignedProduct);
        // setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
    }
    
    var foundAssignedTradein = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'tradein'){
      foundAssignedTradein = order.tradeins.find((trade) => trade.id === document.assignedObjectId);
    }
    
    if(foundAssignedTradein){
      setAssignedTrade(foundAssignedTradein);
    }
    
    
    if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
        if(foundAssignedTradein){
          AutofillTradeDetails(foundAssignedTradein)
        }
      }else{
        // if(document && document.itemIds){
        //   var itemIds = document.itemIds.split(',');
        //   var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
        //   let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
        //   let obj = {id: foundProduct.id, name: str}
        //   setproductVal(obj);
        //   if(foundProduct){
        //     setAddressOptions(updateAddressOptions(foundProduct, order));
        //   }
        //   streamDocument();
        // }
        
      }
      
      setDataLoaded(true);
    }
  },[ dateFields, locations, documentValues, document, dataLoaded, order, AutofillProductDetails, AutofillTradeDetails, GlobalAutofill, previewPayloadDocument ])

  useEffect(() => {
    //Autofill Tradein Details After Selection
    if(selectedLocation !== undefined && locationToggleAutoFill){
      var newValues = documentValues;
      newValues.seller_address = selectedLocation.address;
      newValues.seller_city = selectedLocation.city;
      newValues.seller_state = selectedLocation.state;
      newValues.seller_zip = selectedLocation.zip;
      newValues.seller_phone = formatPhoneNumber(selectedLocation.phone);

      // newValues.seller_id = "31422";

      // if(selectedLocation.state === "CA"){
      //   newValues.seller_name_signature = "TEC OF CALIFORNIA, INC";
      //   newValues.copy_seller_name_signature = "TEC OF CALIFORNIA, INC";
      // }else{
      //   newValues.seller_name_signature = "TEC EQUIPMENT, INC.";
      //   newValues.copy_seller_name_signature = "TEC EQUIPMENT, INC.";
      // }

      setDocumentValues(newValues);
      setLocationToggleAutoFill(false);
      if(document){
        previewPayloadDocument(documentValues);
      }
      setHasUnsavedChanges(true);
      setSaveSuccess(false);
    }
  },[ selectedLocation, locationToggleAutoFill, document, documentValues, previewPayloadDocument ])

  if(locations && locations.length > 0 && locationOptions === undefined){
    let getLocationOptions = [];
    locations.map(location => {
      let str = `${location.description}`;
      let obj = {locationCode: location.locationCode, description: str}
      getLocationOptions.push(obj);
      return null;
    })
    setLocationOptions(getLocationOptions);
  }

  return (
    <>
      <div
        className="modal fade"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                { documentTitle }
              </h5>
              <br />
              { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1 pt-1 text-white">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                  </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}
                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                  
                <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                    GlobalAutofill();
                    AutofillProductDetails(assignedProduct);
                    previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                </div>

                <br />

                { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Product Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                        </div>
                    </div>
                ):null}

                { assignedTrade ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Trade In Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                          <h5>VIN: { assignedTrade.vin.toUpperCase() }</h5>
                          <h5>YMM: { assignedTrade.year+" "+assignedTrade.make+" "+assignedTrade.model.toUpperCase() }</h5>
                        </div>
                    </div>
                ):null}

                    {/* ////// */}


                <div className="d-flex justify-content-evenly">
                  <div className="p-2 col-md-4">
                    <FASTFormElement 
                      type="Radio" 
                      property="sold_bought_toggle" 
                      value={documentValues.sold_bought_toggle} 
                      options={getOptions("sold_bought_toggle") ?? []}
                      label="Document Type"
                      changeValueFunction={handleDocumentValuesChange}
                      onBlurFunction={updatePreview}
                      />
                 
                  </div>
                  <div className="p-2 col-md-4">
                    <label htmlFor="stock">Price</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="price"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.price ?? '' }
                      />
                  </div>
                  <div className="p-2 col-md-4">
                    <label htmlFor="stock">Stock Number</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="stock_number"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.stock_number ?? '' }
                      />
                  </div>
                </div>

                <div className="d-flex justify-content-evenly">
                  <div className="p-2 col-md-6">
                    <label htmlFor="stock">Date</label>
                    <input
                      type="date"
                      className="form-control rounded-pill mt-1"
                      name="date"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.date ?? '' }
                      />
                  </div>

                  <div className="p-2 col-md-6">
                    <label htmlFor="stock">Dealer Phone</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="dealer_phone"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.dealer_phone ?? '' }
                      />
                  </div>
                </div>

                
                <div className="d-flex justify-content-evenly">
                  <div className="p-2 col-md-12">
                    <label htmlFor="stock">Dealer Name</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="dealer_name"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.dealer_name ?? '' }
                      />
                  </div>
                </div>
     
                <div className="d-flex justify-content-evenly">
                  <div className="p-2 col-md-12">
                    <label htmlFor="stock">Dealer Address</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="dealer_address"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.dealer_address ?? '' }
                      />
                  </div>
                </div>

                <div className="d-flex justify-content-evenly">
                  <div className="p-2 col-md-4">
                    <label htmlFor="stock">Dealer City</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="dealer_city"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.dealer_city ?? '' }
                      />
                  </div>
                  <div className="p-2 col-md-4">
                    <label htmlFor="stock">Dealer State</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="dealer_state"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.dealer_state ?? '' }
                      />
                  </div>
                  <div className="p-2 col-md-4">
                    <label htmlFor="stock">Dealer Zip</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="dealer_zip"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.dealer_zip ?? '' }
                      />
                  </div>
                </div>

                <div className="d-flex justify-content-evenly">
                  <div className="p-2 col-md-6">
                    <label htmlFor="stock">Seller Name</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="seller_name"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.seller_name ?? '' }
                      />
                  </div>
                  <div className="p-2 col-md-6">
                    <label htmlFor="stock">Odometer</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="odometer"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.odometer ?? '' }
                      />
                  </div>
                </div>

                <div className="d-flex justify-content-evenly">
                  <div className="p-2 col-md-6">
                    <FASTFormElement 
                      type="Checkbox" 
                      property="odo_1_selected" 
                      value={documentValues.odo_1_selected} 
                      label="1) I hereby certify that to the best of my knowledge..."
                      changeValueFunction={handleDocumentValuesChange}
                      onBlurFunction={updatePreview}
                      />
                  </div>  
                  <div className="p-2 col-md-6">
                    <FASTFormElement 
                      type="Checkbox" 
                      property="odo_2_selected" 
                      value={documentValues.odo_2_selected} 
                      label="2) I hereby certify that the odometer is NOT the actual mileage."
                      changeValueFunction={handleDocumentValuesChange}
                      onBlurFunction={updatePreview}
                      />
                  </div>
                </div>

                <div className="d-flex justify-content-evenly">
                  <div className="p-2 col-md-4">
                    <label htmlFor="stock">Year</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="year"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.year ?? '' }
                      />
                  </div>
                  <div className="p-2 col-md-4">
                    <label htmlFor="stock">Make</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="make"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.make ?? '' }
                      />
                  </div>
                  <div className="p-2 col-md-4">
                    <label htmlFor="stock">Series / Body Type</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="body_type"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.body_type ?? '' }
                      />
                  </div>
                </div>

                <div className="d-flex justify-content-evenly">
                  <div className="p-2 col-md-12">
                    <label htmlFor="stock">VIN</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="vin"
                        maxLength="17"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.vin ?? '' }
                        />
                  </div>
                </div>

                <div className="d-flex justify-content-evenly">
                  <div className="p-2 col-md-6">
                    <label htmlFor="stock">License</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="license"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.license ?? '' }
                      />
                  </div>
                  <div className="p-2 col-md-6">
                    <label htmlFor="stock">State Licensed</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="state_licensed"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.state_licensed ?? '' }
                      />
                  </div>
                </div>

                <div className="d-flex justify-content-evenly">
                  <div className="p-2 col-md-12">
                    <label htmlFor="stock">Transferor's (Seller) Name</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="seller_name"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.seller_name ?? '' }
                      />
                  </div>
                </div>

                <div className="d-flex justify-content-evenly">
                  <div className="p-2 col-md-12">
                    <label htmlFor="stock">Transferor's (Seller) Address</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="seller_address"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.seller_address ?? '' }
                      />
                  </div>
                </div>

                <div className="d-flex justify-content-evenly">
                  <div className="p-2 col-md-4">
                    <label htmlFor="stock">Transferor's (Seller) City</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="seller_city"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.seller_city ?? '' }
                      />
                  </div>
                  <div className="p-2 col-md-4">
                    <label htmlFor="stock">Transferor's (Seller) State</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="seller_state"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.seller_state ?? '' }
                      />
                  </div>
                  <div className="p-2 col-md-4">
                    <label htmlFor="stock">Transferor's (Seller) Zip</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="seller_zip"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.seller_zip ?? '' }
                      />
                  </div>
                </div>

                <div className="d-flex justify-content-evenly">
                  <div className="p-2 col-md-12">
                    <label htmlFor="stock">Transferee (Buyer Name or Company Rep.) </label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="buyer_name"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.buyer_name ?? '' }
                      />
                  </div>
                </div>


                <div className="d-flex justify-content-evenly">
                  <div className="p-2 col-md-12">
                    <label htmlFor="stock">Transferee (Buyer) Company Name</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="buyer_company_name"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.buyer_company_name ?? '' }
                      />
                  </div>
                </div>

                <div className="d-flex justify-content-evenly">
                  <div className="p-2 col-md-12">
                    <label htmlFor="stock">Transferee (Buyer) Street Address</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="buyer_address"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.buyer_address ?? '' }
                      />
                  </div>
                </div>

                <div className="d-flex justify-content-evenly">
                  <div className="p-2 col-md-4">
                    <label htmlFor="stock">Transferee (Buyer) City</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="buyer_city"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.buyer_city ?? '' }
                      />
                  </div>
                  <div className="p-2 col-md-4">
                    <label htmlFor="stock">Transferee (Buyer) State</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="buyer_state"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.buyer_state ?? '' }
                      />
                  </div>
                  <div className="p-2 col-md-4">
                    <label htmlFor="stock">Transferee (Buyer) Zip</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="buyer_zip"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.buyer_zip ?? '' }
                      />
                  </div>
                </div>

                <div className="d-flex justify-content-evenly">
                  <div className="p-2 col-md-6">
                    <label htmlFor="stock">Date of Statement (Seller)</label>
                    <input
                      type="date"
                      className="form-control rounded-pill mt-1"
                      name="date_seller"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.date_seller ?? '' }
                      />
                  </div>
                  <div className="p-2 col-md-6">
                    <label htmlFor="stock">Date of Statement (Buyer)</label>
                    <input
                      type="date"
                      className="form-control rounded-pill mt-1"
                      name="date_buyer"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.date_buyer ?? '' }
                      />
                  </div>
                </div>

                <div className="d-flex justify-content-evenly">
                  <div className="p-2 col-md-12">
                    <label htmlFor="stock">Permit Number</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="permit_no"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.permit_no ?? '' }
                      />
                  </div>
                </div>


                <div className="d-flex justify-content-evenly">
                  <div className="p-2 col-md-6">
                    <label htmlFor="stock">SELLERS ST. OF WASHINGTON #</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="seller_st_of_wa_num"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.seller_st_of_wa_num ?? '' }
                      />
                  </div>
                  <div className="p-2 col-md-6">
                    <label htmlFor="stock">BUYERS ST. OF WASHINGTON #</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="buyer_st_of_wa_num"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.buyer_st_of_wa_num ?? '' }
                      />
                  </div>
                </div>



                {/* </div> */}
                <hr></hr>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default WAWholesale

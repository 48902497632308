import React, { useState } from "react";
import { useFetch } from "../../../../../../utlis/api";
// import { useParams, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useOrder } from "../../../../../../redux/orders/order"
import { formatter, removeChars } from "../../../../../../utlis/formatters";
import {
    calculateTotalProductDeposits, 
    calculateTotalTradeCreditTotal, 
    calculateVehicleSalesTax,
    calculateTotalProductSubtotal, calculateOrderSubtotal, calculateTotalDownpayment, calculateGrandSubtotal, calculateNonVehicleSalesTaxTotal} from "../../../../../../utlis/orderCalculations";


const OrderSummary = () => {
    let { id }      = useParams();
    // const history   = useHistory();
    const axios     = useFetch()
    const order     = useOrder();

    const [adminFeeVal, setAdminFeeVal] = useState(undefined);
    const onAdminFeeChange = e => {
        let adminFeeValue = e.target.value;
        setAdminFeeVal(adminFeeValue);
    }

    const updateAdminFee = async (orderObject) => {
        await
          axios
          .put(`/order/${id}/admin-fee`, orderObject)
    }
    
    const saveAdminFee = () => {
        let orderObj = order;
        if(Number(removeChars(adminFeeVal)) >= 0 && adminFeeVal !== undefined && adminFeeVal !== null && adminFeeVal !== ""){
            orderObj.adminFee = removeChars(formatter.format(Number(removeChars(adminFeeVal))));
        }else{
            orderObj.adminFee = 0.00;
        }
        setAdminFeeVal(formatter.format(orderObj.adminFee));

        updateAdminFee(orderObj).then(res => {
            console.log('saveAdminFee', res);            
            setAdminFeeVal(formatter.format(orderObj.adminFee));
        });
    }

    return (
    <>
        <div className="col-12">
            <div className="border-5 border-start border-primary mt-3">
                <h5 className="p-2 text-uppercase">ORDER SUMMARY</h5>

                <div className="p-3">
                    <div className="d-flex justify-content-between px-1 py-1">
                        <h6 className="text-uppercase">PRODUCT SUBTOTAL</h6>
                        <h6 data-testid="orderSummary.productSubtotal">{ formatter.format(calculateTotalProductSubtotal(order, order.products)) }</h6>
                        {/* <h6>{ formatter.format(order.productSubtotal ?? 0.00) }</h6> */}
                    </div>
                {/* <div className="d-flex justify-content-between px-1 py-1">
                    <h6 className="text-uppercase">(NET TRADE) / OA</h6>
                    { calculateTotalTradeCreditTotal(order.tradeins, order) < 0 ? (
                    <h6>{ formatter.format(Math.abs(calculateTotalTradeCreditTotal(order.tradeins, order))) }</h6>
                    ):(
                    <h6>({ formatter.format(calculateTotalTradeCreditTotal(order.tradeins, order)) })</h6>
                    )}
                    {/* <h6>({ formatter.format(order.tradeInCredit ?? 0.00) })</h6> */}
                {/*</div> */}

                <div className="d-flex justify-content-between px-1 py-1">
                    <h6 className="text-uppercase">ADMINISTRATION FEE</h6>
                    <input 
                        type="text" 
                        onChange={e => onAdminFeeChange(e)} 
                        style={{ textAlign: 'right'}}
                        // onBlur={e => {history.push(`/order/${id}`)}}
                        value={ adminFeeVal !== undefined ? adminFeeVal : (order && order.adminFee ? formatter.format(order.adminFee) : formatter.format(0.00)) }
                        // value={ formatter.format(order.adminFee ?? 0.00) }
                        aria-label='order summary administration fee'
                        data-testid="orderSummary.adminFee"
                        onBlur={e => {
                            saveAdminFee();
                            // setAdminFeeVal(formatter.format(adminFeeVal !== undefined ? removeChars(adminFeeVal) : (order && order.adminFee ? order.adminFee : '')));
                            // // setAdminFeeVal(formatter.format(order.adminFee ?? 0.00));
                            }
                        } 
                    />
                </div>

                { order && order.customer && !order.customer.isExemptSalesTax && order.customer.deliveryAddress && order.customer.deliveryAddress.state !== "WA" ? (
                    <div className="d-flex justify-content-between px-1 py-1">
                        <h6 className="text-uppercase">Sales Tax ({order.customer.vehicleSalesTaxPercent }%)</h6>
                        <h6 data-testid="orderSummary.salesTax">{ formatter.format(calculateVehicleSalesTax(order.products, order.tradeins, order)) }</h6>
                        {/* <h6>{ formatter.format(calculateOrderSubtotal(order, order.products, order.tradeins, order.adminFee)) }</h6> */}
                    </div>
                ):null}

                { order && order.customer && !order.customer.isExemptSalesTax && order.customer.deliveryAddress && order.customer.deliveryAddress.state === "WA" ? (
                    <div>
                        <div className="d-flex justify-content-between px-1 py-1">
                            <h6 className="text-uppercase">Vehicle Sales Tax ({order.customer.vehicleSalesTaxPercent }%)</h6>
                            <h6 data-testid="orderSummary.vehicleSalesTax" >{ formatter.format(calculateVehicleSalesTax(order.products, order.tradeins, order)) }</h6>
                            {/* <h6>{ formatter.format(calculateOrderSubtotal(order, order.products, order.tradeins, order.adminFee)) }</h6> */}
                        </div>
                        <div className="d-flex justify-content-between px-1 py-1">
                            <h6 className="text-uppercase">Non Vehicle Sales Tax ({order.customer.nonVehicleSalesTaxPercent }%)</h6>
                            <h6 data-testid="orderSummary.nonVehicleSalesTax">{ formatter.format(calculateNonVehicleSalesTaxTotal(order.products, order)) }</h6>
                            {/* <h6>{ formatter.format(calculateOrderSubtotal(order, order.products, order.tradeins, order.adminFee)) }</h6> */}
                        </div>
                        <div className="d-flex justify-content-between px-1 py-1">
                            <h6 className="text-uppercase">Sales Tax Total</h6>
                            <h6 data-testid="orderSummary.salesTaxTotal">{ formatter.format(Number(calculateVehicleSalesTax(order.products, order.tradeins, order))+Number(calculateNonVehicleSalesTaxTotal(order.products, order))) }</h6>
                            {/* <h6>{ formatter.format(calculateOrderSubtotal(order, order.products, order.tradeins, order.adminFee)) }</h6> */}
                        </div>
                    </div>
                ):null}

                <div className="d-flex justify-content-between px-1 py-1">
                    <h6 className="text-uppercase">SUBTOTAL</h6>
                    <h6 data-testid="orderSummary.subtotal">{ formatter.format(calculateOrderSubtotal(order, order.products, order.tradeins, order.adminFee)) }</h6>
                    {/* <h6>{ formatter.format(order.subtotal ?? 0.00) }</h6> */}
                </div>
                <hr></hr>
        
                <div className="d-flex justify-content-between px-1 py-1">
                    <h6 className="text-uppercase">DEPOSIT</h6>
                    <h6 data-testid="orderSummary.deposit">({ formatter.format(calculateTotalProductDeposits(order.products)) })</h6>
                </div>
                <hr></hr>
 
                <div className="d-flex justify-content-between px-1 py-1">
                <h6 className="text-uppercase">TRADE VALUE</h6>
                    { calculateTotalTradeCreditTotal(order.tradeins, order) < 0 ? (
                    <h6 data-testid="orderSummary.tradeValue">{ formatter.format(Math.abs(calculateTotalTradeCreditTotal(order.tradeins, order))) }</h6>
                    ):(
                    <h6 data-testid="orderSummary.tradeValue">({ formatter.format(calculateTotalTradeCreditTotal(order.tradeins, order)) })</h6>
                    )}
                </div>

                <div className="d-flex justify-content-between px-1 py-1">
                    <h6 className="text-uppercase">CASH DOWN PAYMENT</h6>
                    <h6 data-testid="orderSummary.cashDownPayment">({ formatter.format(calculateTotalDownpayment(order.downPayments)) })</h6>
                </div>
                <hr></hr>
            
                <div className="d-flex justify-content-between bg-success text-white pt-2 px-1">
                    <h6 className="text-uppercase"><b>TOTAL AMOUNT FINANCED</b></h6>
                    <h6 data-testid="orderSummary.totalAmountFinanced"><b>{ formatter.format(calculateGrandSubtotal(order)) }</b></h6>
                </div>

            </div>
            </div>
        </div>
    </>
    );
};
  
export default OrderSummary;
 

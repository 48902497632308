import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase,  } from "../../../../../../../utlis/formatters";
import { salesPriceWithUpgradeCalc } from '../../../../../../../utlis/productCalculations.js';

export const AZ5011GlobalAutofill = (documentValues, order, property=null, value=null) => {
  var newValues = documentValues;
    
  if(property?.includes('customer') || property === null){

    if(order && order.customer && order.customer.name){
        newValues.printed = order.customer.name;
    }

    if(order && order.customer){
      if(order.customer.type.toLowerCase() === "entity"){
        newValues.non_resident_entity_name = order.customer.name;
        newValues.non_resident_address = order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
        newValues.non_resident_phone = formatPhoneNumber(order.customer.phone);
      }else{
        newValues.purchaser_name = order.customer.name;
        newValues.purchaser_address = order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
        newValues.purchaser_phone = formatPhoneNumber(order.customer.phone);
      }
    }

  }

  if(property?.includes('documentationDate') || property === null){

    if(order && order.documentationDate && documentValues.delivery_date === undefined){
      newValues.delivery_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }

  }

  if(property === null){

    newValues.q4_toggle = "false";
    newValues.q5_toggle = "false";
    newValues.q6_toggle = "false";
    newValues.q7_toggle = "false";
    newValues.q8_toggle = "false";
    newValues.q9_toggle = "false";
    newValues.q10_toggle = "false";
    newValues.q11_toggle = "false";
    newValues.q12_toggle = "false";

  }

  return newValues
}


export const AZ5011AutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {

    var newValues = documentValues;

    if(property?.includes('vin') || property === null){
      newValues.vin = (selectedProduct.vin).toString().toUpperCase();
    }

    if(property?.includes('year') || property === null){
      newValues.year = (selectedProduct.year).trim();
    }

    if(property?.includes('make') || property === null){
      newValues.make = (selectedProduct.make).trim();
    }

    if(property?.includes('model') || property === null){
      newValues.model = (selectedProduct.model).toString().toUpperCase();  
    }

    if(property?.includes('registrationAddress') || property === null){
      newValues.state_issued = selectedProduct.registrationAddress?.state;
    }

    if(property?.includes('price') || property === null){
      newValues.sales_price = salesPriceWithUpgradeCalc(selectedProduct);
    }

    if(property?.includes('fees') || property === null){
      if(selectedProduct.lineItemFee && selectedProduct.lineItemFee.applyFet && selectedProduct.lineItemFee.applyFet === true && selectedProduct.lineItemFee.fetTotal && selectedProduct.lineItemFee.fetTotal > 0){
        newValues.sales_price += Number(selectedProduct.lineItemFee.fetTotal);
      }
    }
    
    return newValues
}


export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields ) => {

  if(payload.q1_toggle){
    // Reset all
    payload.q_1_true_cb = "no";
    payload.q_1_false_cb = "no";

    switch(payload.q1_toggle){
      case "true":
        payload.q_1_true_cb = "yes";
        break;
      case "false":
        payload.q_1_false_cb = "yes";
        break;
      default:
    }
  }

  if(payload.q2_toggle){
    // Reset all
    payload.q_2_true_cb = "no";
    payload.q_2_false_cb = "no";

    switch(payload.q2_toggle){
      case "true":
        payload.q_2_true_cb = "yes";
        break;
      case "false":
        payload.q_2_false_cb = "yes";
        break;
      default:
    }
  }

  if(payload.q3_toggle){
    // Reset all
    payload.q_3_true_cb = "no";
    payload.q_3_false_cb = "no";

    switch(payload.q3_toggle){
      case "true":
        payload.q_3_true_cb = "yes";
        break;
      case "false":
        payload.q_3_false_cb = "yes";
        break;
      default:
    }
  }

  if(payload.q4_toggle){
    // Reset all
    payload.q_4_true_cb = "no";
    payload.q_4_false_cb = "no";

    switch(payload.q4_toggle){
      case "true":
        payload.q_4_true_cb = "yes";
        break;
      case "false":
        payload.q_4_false_cb = "yes";
        break;
      default:
    }
  }

  if(payload.q5_toggle){
    // Reset all
    payload.q_5_true_cb = "no";
    payload.q_5_false_cb = "no";

    switch(payload.q5_toggle){
      case "true":
        payload.q_5_true_cb = "yes";
        break;
      case "false":
        payload.q_5_false_cb = "yes";
        break;
      default:
    }
  }

  if(payload.q6_toggle){
    // Reset all
    payload.q_6_true_cb = "no";
    payload.q_6_false_cb = "no";

    switch(payload.q6_toggle){
      case "true":
        payload.q_6_true_cb = "yes";
        break;
      case "false":
        payload.q_6_false_cb = "yes";
        break;
      default:
    }
  }

  if(payload.q7_toggle){
    // Reset all
    payload.q_7_true_cb = "no";
    payload.q_7_false_cb = "no";

    switch(payload.q7_toggle){
      case "true":
        payload.q_7_true_cb = "yes";
        break;
      case "false":
        payload.q_7_false_cb = "yes";
        break;
      default:
    }
  }

  if(payload.q8_toggle){
    // Reset all
    payload.q_8_true_cb = "no";
    payload.q_8_false_cb = "no";

    switch(payload.q8_toggle){
      case "true":
        payload.q_8_true_cb = "yes";
        break;
      case "false":
        payload.q_8_false_cb = "yes";
        break;
      default:
    }
  }

  if(payload.q9_toggle){
    // Reset all
    payload.q_9_true_cb = "no";
    payload.q_9_false_cb = "no";

    switch(payload.q9_toggle){
      case "true":
        payload.q_9_true_cb = "yes";
        break;
      case "false":
        payload.q_9_false_cb = "yes";
        break;
      default:
    }
  }

  if(payload.q10_toggle){
    // Reset all
    payload.q_10_true_cb = "no";
    payload.q_10_false_cb = "no";

    switch(payload.q10_toggle){
      case "true":
        payload.q_10_true_cb = "yes";
        break;
      case "false":
        payload.q_10_false_cb = "yes";
        break;
      default:
    }
  }

  if(payload.q11_toggle){
    // Reset all
    payload.q_11_true_cb = "no";
    payload.q_11_false_cb = "no";

    switch(payload.q11_toggle){
      case "true":
        payload.q_11_true_cb = "yes";
        break;
      case "false":
        payload.q_11_false_cb = "yes";
        break;
      default:
    }
  }

  if(payload.q12_toggle){
    // Reset all
    payload.q_12_true_cb = "no";
    payload.q_12_false_cb = "no";

    switch(payload.q12_toggle){
      case "true":
        payload.q_12_true_cb = "yes";
        break;
      case "false":
        payload.q_12_false_cb = "yes";
        break;
      default:
    }
  }

  // X1.2- Field Type Specific Preview Formatting
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  // Data point formatting
  if(payload.expires_date){
    payload.expires_date = moment.utc(payload.expires_date).format("MM/DD/YY");
  }

  if(payload.delivery_date){
    payload.delivery_date = moment.utc(payload.delivery_date).format("MM/DD/YY");
  }

  return payload;
}
import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase, getFirstName, getLastName,getMiddleName,getAreaCode, getPhoneNumber, removeChars } from "../../../../../../../utlis/formatters";

const AutoYM=(year, make)=>{
  return (year).toString().replace(/['"]+/g, '').trim()+" "+(make).trim()
}

export const CAStatementOfFactsGlobalAutofill  = (documentValues, order, property=null, value=null) => {

    var newValues = documentValues;
    
    if(property === 'customer.name'){
      if(order.customer.type === "entity"){
        newValues.printed_name = value;
      }else{
        newValues.printed_name = getLastName(value);
        newValues.first_name = getFirstName(value);
        newValues.middle_name = getMiddleName(value);
      }
  }
    if(property === 'customer.phone'){
      let phoneNumber=removeChars(value)
      newValues.area_code = getAreaCode(phoneNumber);
      newValues.phone = getPhoneNumber(phoneNumber);
  }
    if(property === 'documentationDate'){
      newValues.signature_date = moment.utc(value).format("YYYY-MM-DD");
  }
    if(property !== null && value !== null){
      return newValues;
  }

    if(order && order.customer && order.customer.name){
      if(order.customer.type === "entity"){
        newValues.printed_name = order.customer.name;
      }else{
        newValues.printed_name = getLastName(order.customer.name);
        newValues.first_name = getFirstName(order.customer.name);
        newValues.middle_name = getMiddleName(order.customer.name);
      }
      newValues.area_code = getAreaCode(order.customer.phone);
      newValues.phone = getPhoneNumber(order.customer.phone);
    }
  
    if(order && order.customer && order.documentationDate){
      newValues.signature_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }
  
    return newValues
}

export const CAStatementOfFactsAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
  var newValues = documentValues;

  if(property === 'year' && value !== null){
    newValues.year_make = AutoYM((value).toString().trim().replace(/['"]+/g, ''),selectedProduct.make)
    newValues.copy_year_make = AutoYM((value).toString().trim().replace(/['"]+/g, ''),selectedProduct.make)
}

if(property === 'make' && value !== null){
  newValues.year_make = AutoYM(selectedProduct.year.trim(),(value).toString().trim())
  newValues.copy_year_make = AutoYM(selectedProduct.year.trim(),(value).toString().trim())
}
if(property !== null && value !== null){
    return newValues;
}

  newValues.vin = (selectedProduct.vin);
  newValues.year_make = (selectedProduct.year).trim()+" "+(selectedProduct.make).trim();

  newValues.copy_vin = (selectedProduct.vin);
  newValues.copy_year_make = (selectedProduct.year).trim()+" "+(selectedProduct.make).trim();

  return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {



  if(payload.vin){
    payload.copy_vin = payload.vin;
  }

  if(payload.year_make){
    payload.copy_year_make = payload.year_make;
  }

  if(payload.license_plate){
    payload.copy_license_plate = payload.license_plate;
  }

  if(payload.exempt_reason){
    // Reset all
    payload.family_transfer = "no";
    payload.addition_deletion_family_member = "no";
    payload.gift = "no";
    payload.court_order = "no";
    payload.inheritance = "no";

    switch(payload.exempt_reason){
      case "family_transfer":
        payload.family_transfer = "yes";
        break;
      case "addition_deletion_family_member":
        payload.addition_deletion_family_member = "yes";
        break;
      case "gift":
        payload.gift = "yes";
        break;
      case "court_order":
        payload.court_order = "yes";
        break;        
      case "inheritance":
        payload.inheritance = "yes";
        break;
      default:
    }
  }

  if(payload.smog_exempt){
    // Reset all
    payload.last_smog_recent = "no";

    payload.powered = "no";
      payload.power_electric = "no";
      payload.power_diesel = "no";
      payload.power_other = "no";

    payload.outside_ca_nv_mx = "no";

    payload.transfer = "no";
      payload.close_member_transfer = "no";
      payload.sole_propietorship_transfer = "no";
      payload.lease_company_transfer = "no";
      payload.lessor_transfer = "no";
      payload.lessor_operator_transfer = "no";
      payload.registered_owner_add_transfer = "no";


    switch(payload.smog_exempt){
      case "last_smog_recent":
        payload.last_smog_recent = "yes";
        break;
      case "powered":
        payload.powered = "yes";

        if(payload.smog_exempt_powered){
          switch(payload.smog_exempt_powered){
            case "power_electric":
              payload.power_electric = "yes";
              break;
            case "power_diesel":
              payload.power_diesel = "yes";
              break;
            case "power_other":
              payload.power_other = "yes";
              break;
            default:
          }

          if(payload.smog_exempt_powered !== "power_other"){
            payload.power_other_text = "";
          }
        }

        break;
      case "outside_ca_nv_mx":
        payload.outside_ca_nv_mx = "yes";
        break;
      case "transfer":
        payload.transfer = "yes";

        if(payload.smog_exempt_powered){
          switch(payload.smog_exempt_powered){
            case "close_member_transfer":
              payload.close_member_transfer = "yes";
              break;
            case "sole_propietorship_transfer":
              payload.sole_propietorship_transfer = "yes";
              break;
            case "lease_company_transfer":
              payload.lease_company_transfer = "yes";
              break;
            case "lessor_transfer":
              payload.lessor_transfer = "yes";
              break;
            case "lessor_operator_transfer":
              payload.lessor_operator_transfer = "yes";
              break;
            case "registered_owner_add_transfer":
              payload.registered_owner_add_transfer = "yes";
              break;
          default:
          }
        }

        break;        
      case "inheritance":
        payload.inheritance = "yes";
        break;
      default:
    }
  }

  if(payload.trans_title){
    // Reset all
    payload.transfer_only = "no";
    payload.title_only = "no";


    switch(payload.trans_title){
      case "transfer_only":
        payload.transfer_only = "yes";
        break;
      case "title_only":
        payload.title_only = "yes";
        break;
      default:
    }
  }

  // X1.2- Field Type Specific Preview Formatting
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  // Data point formatting
  if(payload.changes_date){
    payload.changes_date = moment.utc(payload.changes_date).format("MM/DD/YY");
  }

  if(payload.signature_date){
    payload.signature_date = moment.utc(payload.signature_date).format("MM/DD/YY");
  }

  return payload;
}
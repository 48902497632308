import React from "react";
import { Accordion, Card} from "react-bootstrap";
import moment from "moment";
import { formatter } from "../../../../../../utlis/formatters";


import "./Style/orderquote.css";

const ProductDeposits = ({ deposits }) => {

  // var formatter = new Intl.NumberFormat('en-US', {
  //   style: 'currency',
  //   currency: 'USD',
  // });

  return (
    <div className="container-fluid">
      { deposits && deposits.length > 0 ? (
        <div className="row">
          <Accordion defaultActiveKey="a1">
            <Card className="p-3">
              <Card.Header style={{ backgroundColor: '#2d7ecf', color: 'white', padding: '1rem' }}>
                <span style={{ paddingLeft: '15px', fontWeight: 'bold' }}>DEPOSITS</span>
              </Card.Header>
              {/* <SectionTemplate title="DEPOSITS" eventKey="a1"> */}
                {/* <Dropdown.Item>
                  <div
                    type="button"
                    className="btn btn-primary px-1 mb-auto mt-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#productModal"
                    >
                    Add Addition
                  </div>
                </Dropdown.Item> */}
              {/* </SectionTemplate> */}
              <Accordion.Collapse eventKey="a1">
                <div className="p-4">
                  <div className="d-flex justify-content-between">
                      <div style={{ width: '100%' }}>
                        <Accordion>
                        {deposits.map((deposit, index) => (
                          <Accordion.Item eventKey={deposit.id} key={deposit.id}>
                            <Accordion.Header>Deposit# {index + 1}</Accordion.Header>
                              <Accordion.Body>
                            <div>
                              <div className="p-4 border-start border-5 border-primary">
                                <div className="d-flex justify-content-between">

                                  <div className="p-4">
                                    <p className="text-secondary">{ deposit.status }</p>
                                    <h5>STATUS</h5>
                                  </div>

                                  <div className="p-4">
                                    <p className="text-secondary">{ formatter.format(deposit.amount) }</p>
                                    <h5>AMOUNT</h5>
                                  </div>

                                  <div className="p-4">
                                    <p className="text-secondary">{(deposit && deposit.paymentMethodObj ? deposit.paymentMethodObj.name: '--')}</p>
                                    <h5>PAYMENT METHOD</h5>
                                  </div>

                                  <div className="p-4">
                                    <p className="text-secondary">{ deposit.documentNumber }</p>
                                    <h5>DOCUMENT NUMBER</h5>
                                  </div>
                        
                                </div>
                              </div>
                              
                              <div className="p-4 bg-light border-start border-5 border-primary">
                                <div className="d-flex justify-content-between">
                                  <div className="p-4">
                                    <p className="text-secondary">{ deposit.note }</p>
                                    <h5>DESCRIPTION/NOTES</h5>
                                  </div>
                                </div>
                              </div>

                            {/* { deposit.applyToPurchase ? ( */}
                              <div className="p-4 border-start border-5 border-primary">
                                <h5>APPLY TO PURCHASE</h5>
                                <div className="d-flex justify-content-between">
                                  <div className="p-4">
                                    <p className="text-secondary">{ formatter.format(deposit.appliedAmount) }</p>
                                    <h5>APPLIED AMOUNT</h5>
                                  </div>

                                  <div className="p-4">
                                    <p className="text-secondary">{ formatter.format(deposit.amount - deposit.appliedAmount) }</p>
                                    <h5>REFUND TO CUSTOMER</h5>
                                  </div>
                                </div>
                              </div>
                            {/* ):('')} */}

                            <div className="p-4 bg-light border-start border-5 border-primary">
                              <div className="d-flex justify-content-between">
                                <div className="p-4">
                                  <p className="text-secondary">{ deposit.dateReceived ? moment.utc(deposit.dateReceived).format("MM/DD/YYYY") : "" }</p>
                                  <h5>DATE RECEIVED</h5>
                                </div>
                              </div>
                            </div>

                            <hr></hr>
                            </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                        </Accordion>
                      </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      ):null}
    </div>
  );
}

export default ProductDeposits;

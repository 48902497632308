import {createSlice} from '@reduxjs/toolkit'

export const appSaverSlice= createSlice({
    name:'appSaver',
    initialState: {
        access_token: [], // Primary Token
        id_token: null, // Deprecated?
        activeModal: null, // Keep reload focus on the active modal
        activeModalTab: null, // Keep reload focus on the active modal tab
    },
    reducers:{
        setAccessToken: (state, action)=>{
            state.access_token = action.payload;
        },
        setIdToken: (state, action)=>{
            state.id_token = action.payload;
        },
        setActiveModal: (state, action)=>{
            state.activeModal = action.payload;
        },
        setActiveModalTab: (state, action)=>{
            state.activeModalTab = action.payload;
        },
        resetActiveModal: (state, action)=>{
            state.activeModal = null;
        }
    }
})

export const { setAccessToken, setIdToken, setActiveModal, resetActiveModal, setActiveModalTab } = appSaverSlice.actions

export default appSaverSlice.reducer
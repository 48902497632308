import moment from 'moment'

export const TECDeliveryReceiptGlobalAutofill = (documentValues, order, property=null, value=null, location) => {
    var newValues = documentValues;

    if(property ==='customer.name'){
      newValues.customer_name = value
  }
    if(property ==='documentationDate'){
      let documentationDate = moment.utc(value).format("YYYY-MM-DD");
      newValues.invoice_date = documentationDate;
      newValues.date = documentationDate;
      newValues.date_2 = documentationDate;
  }
    if(property ==='customer.deliveryAddress.addressLine1'){
      let addressLine = '';
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        addressLine = (value+" "+order.customer.deliveryAddress.addressLine2);
      }else{
        addressLine = value;
      }
      newValues.purchaser_line_3 = addressLine;
  }
    if(property ==='customer.deliveryAddress.addressLine2'){
      let addressLine = '';
      if(value != null && value !== undefined && value !== ''){
        addressLine = (order.customer.deliveryAddress.addressLine1+" "+value);
      }else{
        addressLine = order.customer.deliveryAddress.addressLine1;
      }
      newValues.purchaser_line_3 = addressLine;
  }
    if(property ==='customer.deliveryAddress.city'){
      newValues.purchaser_line_4 = (value+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
  }
    if(property ==='customer.deliveryAddress.state'){
      newValues.purchaser_line_4 = (order.customer.deliveryAddress.city+", "+value+" "+order.customer.deliveryAddress.zip);
  }
    if(property ==='customer.deliveryAddress.zip'){
      newValues.purchaser_line_4 = (order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+value);
  }
    if(property ==='location'){
        if(value.state === "CA"){
          newValues.dynamic_text_1 = "TEC of California, Inc. dba Tec Equipment located at "+value.address+", "+value.city+", "+value.state+", "+value.zip+", being owner of aforementioned motor vehicle, hereby sells and assigns all its rights, title and interest in said certificate of title and motor vehicle to:";  
          newValues.dynamic_text_2 = "TEC OF CALIFORNIA, INC., CALIFORNIA DEALER LICENSE NO. 31422";  
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
        }else{
          newValues.dynamic_text_1 = "TEC Equipment, Inc. located at "+value.address+", "+value.city+", "+value.state+", "+value.zip+", being owner of aforementioned motor vehicle, hereby sells and assigns all its rights, title and interest in said certificate of title and motor vehicle to:";
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
        }
        if(value.state === "OR"){
          newValues.dynamic_text_2 = "TEC EQUIPMENT, INC., OREGON DEALER LICENSE NO. 555";  
        }
        if(value.state === "WA"){
          newValues.dynamic_text_2 = "TEC EQUIPMENT, INC., WASHINGTON DEALER LICENSE NO. 7936";  
        }
        if(value.state === "NV"){
          newValues.dynamic_text_2 = "TEC EQUIPMENT, INC., NEVADA DEALER LICENSE NO. 29622";  
        }
        if(value.state === "AZ"){
          newValues.dynamic_text_2 = "TEC EQUIPMENT, INC., ARIZONA DEALER LICENSE NO. L00014432";  
        }
        newValues.location_address = value.address+" "+value.city+" "+value.state+" "+value.zip;
        newValues.location_phone = value.phone;
        newValues.location_email = value.email;
        
  }
    if(property !== null && value !== null){
      return newValues;
  }
    
    if(order && order.customer && order.customer.name){
      newValues.customer_name = order.customer.name;
    }
    if(order && order.documentationDate ){
      let documentationDate = moment.utc(order.documentationDate).format("YYYY-MM-DD");
      newValues.invoice_date = documentationDate;
      newValues.date = documentationDate;
      newValues.date_2 = documentationDate;
    }
  
  
    if(order && order.customer && order.customer.deliveryAddress){
      var addressLine = '';
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
      }else{
        addressLine = order.customer.deliveryAddress.addressLine1;
      }
      newValues.purchaser_line_3 = addressLine;
    }
  
    if(order && order.customer && order.customer.deliveryAddress){
      newValues.purchaser_line_4 = (order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
    }
  
    if(location !== undefined){
      if(location.state === "CA"){
        newValues.dynamic_text_1 = "TEC of California, Inc. dba Tec Equipment located at "+location.address+", "+location.city+", "+location.state+", "+location.zip+", being owner of aforementioned motor vehicle, hereby sells and assigns all its rights, title and interest in said certificate of title and motor vehicle to:";  
        newValues.dynamic_text_2 = "TEC OF CALIFORNIA, INC., CALIFORNIA DEALER LICENSE NO. 31422";  
      }else{
        newValues.dynamic_text_1 = "TEC Equipment, Inc. located at "+location.address+", "+location.city+", "+location.state+", "+location.zip+", being owner of aforementioned motor vehicle, hereby sells and assigns all its rights, title and interest in said certificate of title and motor vehicle to:";
      }
      if(location.state === "OR"){
        newValues.dynamic_text_2 = "TEC EQUIPMENT, INC., OREGON DEALER LICENSE NO. 555";  
      }
      if(location.state === "WA"){
        newValues.dynamic_text_2 = "TEC EQUIPMENT, INC., WASHINGTON DEALER LICENSE NO. 7936";  
      }
      if(location.state === "NV"){
        newValues.dynamic_text_2 = "TEC EQUIPMENT, INC., NEVADA DEALER LICENSE NO. 29622";  
      }
      if(location.state === "AZ"){
        newValues.dynamic_text_2 = "TEC EQUIPMENT, INC., ARIZONA DEALER LICENSE NO. L00014432";  
      }
    }
  
    if(location !== undefined){
      newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
    }
  
    if(location && location.phone){
      newValues.location_phone = location.phone;
    }
    
    if(location && location.email){
      newValues.location_email = location.email;
    }
  
    if(location){
      if(location.state === "CA"){
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
      }else{
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
      }
    }
  
    return newValues
}

function formatCollateral(year, make, vin){
  return (year).trim()+' '+(make).trim().toString().toUpperCase()+' '+ (vin).trim().toUpperCase();
}

export const TECDeliveryReceiptAutofillProductDetails  = (selectedProduct, documentValues, property=null, value=null) => {
  var newValues = documentValues;

  if(property === 'year' && value !== null){
    newValues.collateral = formatCollateral(value, selectedProduct.make, selectedProduct.vin)
  }

  if(property === 'make' && value !== null){
      newValues.collateral = formatCollateral(selectedProduct.year, value, selectedProduct.vin)
  }

  if(property !== null && value !== null){
      return newValues;
  }

  if(selectedProduct?.year && selectedProduct?.make && selectedProduct?.vin){
    newValues.collateral = formatCollateral(selectedProduct.year, selectedProduct.make, selectedProduct.vin);
  }
  
  return newValues
}

export const preProcessPayload = (payload) => {
    
  if(payload.physical_delivery_toggle){
    // Reset all
    payload.physical_delivery_cb = "no";

    switch(payload.physical_delivery_toggle){
      case "yes":
        payload.physical_delivery_cb = "yes";
        break;
      default:
    }
  }

  if(payload.transfer_ownership_toggle){
    // Reset all
    payload.transfer_ownership_cb = "no";

    switch(payload.transfer_ownership_toggle){
      case "yes":
        payload.transfer_ownership_cb = "yes";
        break;
      default:
    }
  }

    // payload.transfer_ownership_cb = "no";
    // if(payload.transfer_ownership_toggle === "yes"){
    //   payload.transfer_ownership_cb = "yes";
    // }

    if(payload.transfer_ownership_toggle === "yes" && payload.approximately_date){
      payload.approximately_date = moment.utc(payload.approximately_date).format("MM/DD/YY");
    }else if (payload.transfer_ownership_toggle === "no" && payload.approximately_date){
      payload.approximately_date = "";
    }

    if(payload.invoice_date){
      payload.invoice_date = moment.utc(payload.invoice_date).format("MM/DD/YY");
    }
    if(payload.date){
      payload.date = moment.utc(payload.date).format("MM/DD/YY");
    }
    if(payload.date_2){
      payload.date_2 = moment.utc(payload.date_2).format("MM/DD/YY");
    }
    return payload;
}
import React, { useState, useEffect, useRef } from "react";
import _ from "lodash"
import { useDispatch,useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";
import * as MaterialComponents from '@mui/material';
import { CircularProgress } from "@material-ui/core";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import Tooltip from '@mui/material/Tooltip';
import RadioGroup from '@mui/material/RadioGroup';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { AbbreviatedStates } from "../../../../../../utlis/States"
import { useFetch, recordFrontEndError } from "../../../../../../utlis/api"
import { setActiveModal } from "../../../../../../redux/app/appSaver";

import { useModalHelper } from "./CustomerModalHelper"
import { useOrder } from "../../../../../../redux/orders/order"
import * as formatters from "../../../../../../utlis/formatters";
import * as api from "../../../../../../utlis/api";
import { rdx } from "../../../../../../redux/customers"
import { Modal } from '@mui/material'
import { updateCopySection, updateCopySubSection, updateChangesArray, updateStatus, } from "../../../../../../redux/saveAndCopy"
// import InputBase from '@mui/material/InputBase';
import { addTestingTag } from "../../../../../../utlis/testingHelpers";

const CustomerModal = ({taxExemptReasons}) => {

  const { id: orderId } = useParams();
  const dispatch        = useDispatch();
  const history         = useHistory();
  const axios           = useFetch(); 
  const order           = useOrder();
  const customerId      = order.customer?.id;
  const {showSpinner} = useSelector((state)=>state.spinners);
  const appData = useSelector((state) => state?.appData);

  //https://lodash.com/docs/4.17.15#defaults
  const mailingAddressFromOrder   = _.defaults(order.customer?.mailingAddress) //Empty object if undefined

  const reduxContext  = useRdxContext({ orderId, order })
  const ModalHelper   = useModalHelper(dispatch, history, reduxContext)

  /* MODAL GENERAL */
  const modalAction         = useAction() //Edit or Add
  const isOpen              = useIsOpen()
  const saveCustomerSuccess = useSaveCustomerSuccess()
  const lastSaveTimestamp   = useLastSaveTimestamp()
  
  /* CUSTOMER INFO */
  const editedCustomerInfo        = useEditedCustomerInfo()
  const editedCustomerInfoErrors  = useEditedCustomerInfoErrors()

  /* DELIVERY ADDRESS */
  const editedDeliveryAddress       = useEditedDeliveryAddress()
  const editedDeliveryAddressErrors = useEditedDeliveryAddressErrors()

  /* MAILING ADDRESS */
  // const editedMailingAddress          = useEditedMailingAddress()
  const editedMailingAddressErrors    = useEditedMailingAddressErrors()
  const mailingAddressChecked         = useIsMailingAddressChecked()
  const sameAsDeliveryAddressChecked  = useIsSameAsDeliveryAddressChecked()

  /* COSIGNERS */
  const cosigners               = useCosigners()


  /* CONTACTS */
  const contacts                = useContacts()//Existing excluding deleted
  const addedContacts           = useAddedContacts()      //New
  const addedContactsErrors     = useAddedContactsErrors()
  const deletedContactIds       = useDeletedContactIds()
  const displayNewContactFields = useShouldShowNewContactFields()
  const editedContacts          = useEditedContacts()     //Edited
  const editedContactsErrors    = useEditedContactsErrors()

  const hasUnsavedChanges       = useHasUnsavedChanges()
  
  const [fetchingInitData, setFetchingInitData]=useState(false);
  const [deleteContactParams, setdeleteContactParams] = useState([]);

  const [inputdeliveryStateValue, setInputdeliveryStateValue] = useState("");
  const [inputmailingStateValue, setInputmailingStateValue]   = useState("");
  const [inputSalesTaxExemptionReasonId, setInputSalesTaxExemptionReasonId]   = useState("");
  const [hasClickedClose, setHasClickedClose] = useState(false);
  const [copyStatus, setCopyStatus] = useState('');

  const [customerLookup, setCustomerLookup] = useState('');
  const [customerLookupId, setCustomerLookupId] = useState(null);



  const cosignerRef = useRef(cosigners)

  function closeClicked(){
    if(hasUnsavedChanges){
      setHasClickedClose(true);
    }else{
      ModalHelper.handleClose();
    }
  }
  const preCloseSave = e => {
    ModalHelper.onHandleSubmit(e)
    setHasClickedClose(false);
  }

  const preCloseClose = e => {
    setHasClickedClose(false);
    ModalHelper.handleClose()
  }

  const lookupByCustomerName = async event => {
    const inputVal = event.target.value;
    setCustomerLookup(inputVal)
    if(inputVal.length >= 1){
      try {
        let res = await  api.getCustomerLookup(inputVal)
        if (res){

          let sorted = res.data.sort(function(a, b) {
              if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
              if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
              return 0;
          })

          setSuggestions(sorted);
          setSuggestionsObjs(sorted);
        }
      } catch (err){
        recordFrontEndError('index.js', 'Error getting stock numbers: ' + err)
      }
      
    } else {
      setSuggestions([]);
      setSuggestionsObjs([]);
    }
  };

  //Effect to set the modal action based on the customerId.
  useEffect(() => {
    if(appData?.activeModal === 'customer'){
      const modalAction = _.isNil( customerId ) ? 'Add' : 'Edit'
      
      //customerId is still null after saves
      //so revert to lastSaveTimestamp
      if ( lastSaveTimestamp ) {
        dispatch(rdx.setAction('Edit'))
      } else {
        
        dispatch(rdx.setAction(modalAction))
        if ( modalAction === 'Add' ) {
          dispatch(rdx.setEditedCustomerInfo('type', 'individual'))
        }
      }

    }
  }, [customerId, lastSaveTimestamp, order.customer, dispatch, appData?.activeModal])
  
  /* Effect to initialize the edited(cosigners|contacts) array*/
  useEffect(() => {

    if(appData?.activeModal === 'customer'){

      if(fetchingInitData === false){
        setFetchingInitData(true);
        const cosignersFromOrder  = _.defaultTo(order.customer?.cosigners, [])
        const contactsFromOrder   = _.defaultTo(order.customer?.contacts, [])
        
        const emptyContacts   = contactsFromOrder.map( c => ({}))
        
        dispatch(rdx.setEditedContacts(emptyContacts))
        dispatch(rdx.setContacts(contactsFromOrder))
        
        if(cosignersFromOrder !== cosignerRef.current){
          
          dispatch(rdx.setCosigners(cosignersFromOrder))
          cosignerRef.current= cosignersFromOrder
          
        }
      }

    }

  }, [order, dispatch, cosigners, addedContacts, fetchingInitData, setFetchingInitData, appData?.activeModal])

  const editSubDataPoint=(e)=>{
    const {value , name : subDataPointName} = e.target
    
    let foundCode = getSterCode(ModalHelper.getEditedOrDefaultCustomerInfoValue('salesTaxExemptionReasonId'));

    if (foundCode === 'COLBYLESSOR' || foundCode === 'DLRRESALE' || foundCode === 'LEAINTERCOM'){
      ModalHelper.onSTERDealerNumberEdit('')
      ModalHelper.onSTERMcNumberEdit('')
      ModalHelper.onSTERDotNumberEdit('')
      ModalHelper.onSTERResaleNumberEdit(value)
    }

    if (foundCode === 'EXEMPTCERT' || foundCode === 'SOLDATAUCTN'){
      ModalHelper.onSTERMcNumberEdit('')
      ModalHelper.onSTERDotNumberEdit('')
      if(subDataPointName ==='salesTaxExemptionReason.sterResaleNumber'){
        ModalHelper.onSTERResaleNumberEdit(value)
      }
      if(subDataPointName ==='salesTaxExemptionReason.sterDealerNumber'){
        ModalHelper.onSTERDealerNumberEdit(value)
      }
    }

    if (foundCode === 'CDTFA837'){
      ModalHelper.onSTERResaleNumberEdit('')
      ModalHelper.onSTERDealerNumberEdit('')
      if (subDataPointName ==='salesTaxExemptionReason.sterMcNumber'){
        ModalHelper.onSTERMcNumberEdit(value)
      }
      if (subDataPointName === 'salesTaxExemptionReason.sterDotNumber'){ 
        ModalHelper.onSTERDotNumberEdit(value)
      }
    }

    updateDataChangeArray(e);
  }

  
  //Effect to set isMailingAddressChecked based on the mailing address from the order...
  useEffect(() => {

    if(appData?.activeModal === 'customer'){


      if ( _.isEmpty( mailingAddressFromOrder.addressLine1 ) === false ) {
        dispatch(rdx.setIsMailingAddressChecked(true))
      }

    }
  }, [mailingAddressFromOrder, dispatch, appData?.activeModal])

  const isTaxExempt = ModalHelper.onValidateTaxExempt(order, editedDeliveryAddress, editedCustomerInfo)

  //Effect to recalculate isValidTaxInfo...
  useEffect(() => {

    if(appData?.activeModal === 'customer'){

      if ( _.isEmpty( order.orderStatus ) ) {
        return; //Wait for the customer or orderStatus
      }

      dispatch(rdx.setIsValidTaxInfo(isTaxExempt))

    }

  }, [order, dispatch, isTaxExempt, appData?.activeModal])

  const copyCustomer = async( customerId )=>{
    if(customerId && customerId > 0){
      setCopyStatus('copying')
      try{
        let {data} = await axios.get(`customer/${customerId}`)
        ModalHelper.copyCustomer(data)
      setCopyStatus('success')
    } catch (err) {
      setCopyStatus('fail')
      recordFrontEndError('CustomerModal.jsx', 'Error fetching customer to copy' + err)
    }
    setCustomerLookup('')
    setCustomerLookupId(null)
    }else return ()=> null
  }

  const deleteCosigner = async (e) => {
    e.preventDefault();
    let {value:cosignerId, name:index} = e.target
    let cosignerCopy = [...cosigners]
    let filteredArr = cosignerCopy.filter((cosigner, i)=>{ 
      let filtered
      if (cosigner?.id){
        filtered = (cosigner.id!==Number(cosignerId))
        if (!filtered) api.deleteCustomerCosigner(orderId, customerId, cosignerId)
      } else {
        filtered = (i.toString() !== index)
      }
      return filtered
  })

  updateDataChangeArray(e, 'cosigner[0].name', (filteredArr[0]?.name??''))

  if(cosignerId) await api.deleteCustomerCosigner(orderId, customerId, cosignerId)
  dispatch({type:'DELETE_COSIGNER', payload:filteredArr})
    dispatch(rdx.setSaveCustomerSuccess(false))
    dispatch(rdx.setHasUnsavedChanges(true))
  }


  //Function to show new or existing cosigners...
  const showCosigners = ( cosigners ) => {

    return cosigners?.map( ( cosigner, index ) => {

      return (
        <div className="container row" key={index}>
          <div className="mb-2  col-md-5">
            <label htmlFor="customerName" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="rounded-pill form-control "
              value={ cosigner?.name??'' }
              required
              name={'cosigner['+index+'].name'}
              data-testid="cosigner.name"
              onChange={(e) =>{
                updateDataChangeArray(e)
                dispatch(rdx.setHasUnsavedChanges(true))
                dispatch({type:'EDIT_COSIGNER',payload:{i:index, value:e.target.value, field:'name'}}) 
              }}
            />
            <FormError validate={ cosigner?.errors?.name }>
              Name is required.
            </FormError>
          </div>
          <div className="mb-3 col-md-5">
            <label htmlFor="customerPhone" className="form-label">
              Phone
            </label>
            <input
              type="tel"
              className="rounded-pill form-control"
              value={ cosigner?.phone.replace(/^(\d{3})\s*-?\s*(\d{3})\s*-?\s*(\d{4})$/, '($1) $2-$3')??'' }
              required
              data-testid="cosigner.phone"
              // pattern="^[0-9-+\s()]"
              name={'cosigner['+index+'].phone'}
              maxLength='10'
              onChange={(e) => {
                updateDataChangeArray(e)
                dispatch(rdx.setHasUnsavedChanges(true))
                dispatch({type:'EDIT_COSIGNER',payload:{i:index, value:e.target.value.replace(/\D/g, ""), field:'phone'}})
              }}
              onBlur={e=>{
                dispatch({type:'EDIT_COSIGNER',payload:{i:index, value:cosigner.phone?.replace(/^(\d{3})\s*-?\s*(\d{3})\s*-?\s*(\d{4})$/, '($1) $2-$3'), field:'phone'}})
              }}
            />
            <FormError validate={ cosigner?.errors?.phone }>
              Phone is required and must be 10 numbers long.
            </FormError>
          </div>
          <div className="mb-3 col-md-2">
            <button type="submit" value={cosigner?.id!==''?cosigner.id:null} name={index} onClick={deleteCosigner}
              className="btn btn-primary mt-4">
                Delete
            </button>
          </div>
          <Divider />
        </div>
      )
    })
  }
  
  //Show existing or new contacts...
  const showContacts = ( existing, contacts ) => {

    //Example: { "0": { "phone": true }, "3": { name: true } }
    const contactErrorsAll = existing ? editedContactsErrors : addedContactsErrors
    return contacts.map((contact, index) => {

      if ( existing && deletedContactIds[ contact.id ] ) {
        return null //Hide deleted contacts
      }

      /* For existing, default to edits before previous value */
      const contactWithEdits =
        existing ? _.defaults(editedContacts[ index ], contact) : contact

      /* Empty object if undefined */
      const contactErrors =
        _.defaults(contactErrorsAll[ index ])

      const isAdd = existing === false && index === contacts.length -1

      const buttonOnClick = e => {
        if ( isAdd ) {
          ModalHelper.onAddNewContact(e, index, contact)
        } else {
          // ModalHelper.onDeleteContact(existing, e, index, contact)
          let arr = [existing, e, index, contact];
          setdeleteContactParams([...arr]);
        }
      }
      const buttonText = isAdd ? 'Add' : 'Delete'

      return (
        <div className="container row mt-2" key={index}>
          {/* ------------------------Row 1---------------------------- */}
          <div className="row">
            <div className="mb-2 col-md-4">
              <label htmlFor="customerName" className="form-label">
                Name <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="text"
                aria-label='contact name'
                className="rounded-pill form-control"
                value={ _.isNil( contactWithEdits.name ) ? '' : contactWithEdits.name }
                onChange={(e) => ModalHelper.onEditContact(existing, index, 'name', e.target.value, contact.id)}
                data-testid="contact.name"
              />
              <FormError validate={ contactErrors.name }>
                Name is required.
              </FormError>
            </div>
            <div className="mb-3 col-md-4">
              <label htmlFor="customerPhone" className="form-label">
                Phone <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="tel"
                aria-label='contact telephone number'
                className="rounded-pill form-control"
                value={_.isNil(contactWithEdits.phone) ? '' :
                  formatters.formatPhoneNumber(contactWithEdits.phone) }
                onChange={(e) => ModalHelper.onEditContact(existing, index, 'phone', e.target.value, contact.id)}
                data-testid="contact.phone"
              />
              <FormError validate={ contactErrors.name }>
                Phone is required and must be 10 numbers long.
              </FormError>
            </div>
            <div className="mb-3 col-md-4">
              <label htmlFor="customerEmail" className="form-label">
                Email <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="email"
                aria-label='contact email address'
                className="rounded-pill form-control"
                value={_.isNil( contactWithEdits.email ) ? '' : contactWithEdits.email }
                onChange={(e) => ModalHelper.onEditContact(existing, index, 'email', e.target.value)}
                data-testid="contact.email"
              />
              <FormError validate={ contactErrors.name }>
                Email is required and must be in valid format.
              </FormError>
            </div>
          </div>
          {/* ------------------------Row 2---------------------------- */}
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="customerCompany" className="form-label">
                Company
              </label>
              <input
                type="text"
                aria-label='contact company'
                className="rounded-pill form-control"
                value={_.isNil(contactWithEdits.company ) ? '' : contactWithEdits.company }
                onChange={(e) => ModalHelper.onEditContact(existing, index, 'company', e.target.value)}
                data-testid="contact.company"
              />
            </div>
            <div className="mb-2 col-md-4">
              <label htmlFor="customerFax" className="form-label">
                Fax
              </label>
              <input
                type="tel"
                aria-label='contact fax number'
                className="rounded-pill form-control"
                value={formatters.formatPhoneNumber(contactWithEdits.fax) }
                onChange={(e) => ModalHelper.onEditContact(existing, index, 'fax', e.target.value)}
                data-testid="contact.fax"
              />
            </div>
            <div className="mb-3 col-md-2">
            {buttonText === 'Add' ? (
              <button type="submit"
                onClick={ buttonOnClick }
                aria-label={`${buttonText} Customer`}
                className="btn btn-primary mt-4">
                { buttonText }
              </button>
            ) : (
              <button 
                type="submit"
                aria-label='customer delete confirm'
                onClick={ e => {
                  e.preventDefault();
                  buttonOnClick();
                  } 
                }
                className="btn btn-primary mt-4"
                data-bs-toggle="modal"
                data-bs-target="#contactmodaldeleteconfirm"
              >
                { buttonText }
              </button>
            )}
          </div>
          <Divider />
          </div>
        </div>
      )
    })
  }

  /* For determining whether we should show portions of the view */
  const showTaxExemptionCheckbox = true;

  const isExemptCheckboxChecked =
    _.isNil(editedCustomerInfo.isExemptSalesTax ) ?
      (order.customer?.isExemptSalesTax === true ) : editedCustomerInfo.isExemptSalesTax

  const shouldCheckSameAsDeliveryAddress =
    _.isNil(sameAsDeliveryAddressChecked) ?
      false : sameAsDeliveryAddressChecked

  const shouldCheckMailingAddress =
    _.isNil( mailingAddressChecked ) ?
      _.isEmpty( mailingAddressFromOrder.addressLine1 ) === false : mailingAddressChecked

    function getSterCode(sterid){
      if(sterid && taxExemptReasons && taxExemptReasons.length > 0){
        let foundSter = taxExemptReasons.find(r => r.id === sterid);
        if(foundSter){
          return foundSter.code;
        }
      }
      return '';
    }

  //   function isJson(str) {
  //     try {
  //         JSON.parse(str);
  //     } catch (e) {
  //         return false;
  //     }
  //     return true;
  //   }

  const [dataChangeArray, setDataChangeArray]   = useState([]);
  function updateDataChangeArray(e, passedProp=null, newValue=null){
    e.preventDefault();
    let prop, value
    if(e.target&& !newValue){
      value = e.target.value
      prop = e.target.name
    }
    if(passedProp !== null){
      prop = passedProp;
      if (newValue!==null && (prop==='deliveryAddress.state'||prop==='mailingAddress.state'|| prop=== 'cosigner[0].name')){
        value=newValue
      }
    }

    let doNotCopyProperties = [];

    if(dataChangeArray && !doNotCopyProperties.includes(prop) && prop !== undefined){

      if(dataChangeArray["customer."+prop]?.oldValue){

        setDataChangeArray((prev)=>({
          ...prev,
          ["customer."+prop]: {
            "newValue": value,
            "oldValue": dataChangeArray["customer."+prop].oldValue
          }
        }))
        
      }else{
        const oldValue = ModalHelper.getEditedOrDefaultCustomerInfoValue(prop);

        setDataChangeArray((prev)=>({
          ...prev,
          ["customer."+prop]: {
            "newValue": value,
            "oldValue": oldValue ? oldValue : '--'
          }
        }))

      }
    }
  }

  useEffect(() => {

    if(appData?.activeModal === 'customer'){
      dispatch(updateChangesArray(dataChangeArray));
    }

  }, [ appData, dataChangeArray, dispatch ])


    const suggestionStyles = {
      suggestions: {
        zIndex: 10,
        position: 'absolute',
        border: '1px solid #999',
        backgroundColor:'white',
        borderRadius:'5px',
        borderTopWidth: 0,
        listStyle: 'none',
        marginTop: '0',
        maxHeight: 143,
        overflowY: 'auto',
        paddingLeft: 0,
        width: '335px',
        "& li": {
          padding: '0.5rem'
        },
        "& li:hover": {
          backgroundColor: '#0d6efd',
          color: '#fff',
          cursor: 'pointer',
          fontWeight: 700
        }
      },
      suggestionActive: {
        backgroundColor: '#0d6efd',
        color: '#fff',
        cursor: 'pointer',
        fontWeight: 700
      },
      zIndex:3
    };

    const [suggestions, setSuggestions] = useState([]);
    const [suggestionsObjs, setSuggestionsObjs] = useState([]);
    const [highlightListItem, setHighlightListItem] = useState(0);

    const setSuggestionSelectionIndex = (index) => {

      setSuggestions([]);
      const {name, id} = suggestionsObjs[index];

      setCustomerLookup(name);
      setCustomerLookupId(id);
    }


    const  addSuggestionList = (suggestions) => {
      if(suggestions.length) {
        return(
          <>
          <ul style={suggestionStyles.suggestions } className='col-12'>
            {suggestions.map((suggestion, index) => {

              let taxExempt= taxExemptReasons?.find(reason=> reason.id === suggestion.salesTaxExemptionReasonId)

              return (
                <>
                  <li
                    onMouseOver={() => handleListToggle(index)}
                    style={highlightListItem === index ? suggestionStyles.suggestionActive : null}
                    onClick={e => setSuggestionSelectionIndex(index)}
                    >
                    <Tooltip 
                      arrow
                      placement='left'
                      title={
                        <>
                        <div style={{ display:'flex', flexDirection:'column',  height:'fit-content', width:'fit-content', padding:'0.5rem',opacity:'100%'}}> 
                          <div style={{height:'min-content', display:'flex', flexDirection:'column', width:'fit-content', gap:'1rem'}}>
                            <div style={{display:'flex', flexDirection:'row', width:'100%',maxHeight:'min-content',alignItems :'center', minWidth: '300px' }}>
                              {/* <img className='info-svg' style={{height:'1rem',position: 'fixed' }} src={infoSVG} alt='info svg'/> */}
                                <p className="text-uppercase" style={{fontSize:'1.5rem', padding:'0', margin:'0 1.5rem', height:'100%', flexGrow:1, textAlign:'center', textDecoration:'underline', textDecorationThickness:'1px', textUnderlineOffset:'.25rem', color:'white'}}>
                                  {suggestion?.name}
                                </p>
                            </div>
                            <div>
                              <ul className='tooltip-list pa-1'>
                                { (suggestion?.dba && suggestion?.dba !== '') ? (
                                  <li><span style={{ color: "#000", fontWeight: 'bold' }}>DBA: </span>{suggestion.dba}</li>
                                  ):null}
                                <li><span style={{ color: "#000", fontWeight: 'bold' }}>Phone: </span>{formatters.formatPhoneNumber(suggestion.phone)}</li>
                                <li><span style={{ color: "#000", fontWeight: 'bold' }}>Email: </span>{suggestion.email}</li>

                                {suggestion?.isExemptSalesTax === false && suggestion?.vehicleSalesTaxPercent > 0 ? <li><span style={{ color: "#000", fontWeight: 'bold' }}>Tax Rate: </span>{suggestion?.vehicleSalesTaxPercent} %</li>:null}
                                {suggestion?.isExemptSalesTax === false && suggestion?.vehicleSalesTaxCounty !== '' && suggestion?.vehicleSalesTaxCounty !== null && suggestion?.vehicleSalesTaxCounty !== undefined ? <li><span style={{ color: "#000", fontWeight: 'bold' }}>Tax County: </span>{suggestion?.vehicleTaxCounty}</li>:null}

                                {suggestion?.isExemptSalesTax === false && suggestion?.deliveryAddress?.state === 'WA' && suggestion?.nonVehicleSalesTaxPercent > 0 ?<li><span style={{ color: "#000", fontWeight: 'bold' }}>Tax (Non-Vehicle) Rate: </span>{suggestion?.nonVehicleSalesTaxPercent} %</li>:null}
                                {suggestion?.isExemptSalesTax === false && suggestion?.deliveryAddress?.state === 'WA' && suggestion?.nonVehicleTaxCounty !== '' &&  suggestion?.nonVehicleTaxCounty !== null && suggestion?.nonVehicleTaxCounty !== undefined?<li><span style={{ color: "#000", fontWeight: 'bold' }}>Tax (Non-Vehicle)County: </span>{suggestion?.nonVehicleTaxCounty}</li>:null}

                                {suggestion?.isExemptSalesTax === true && taxExempt?<li><span style={{ color: "#000", fontWeight: 'bold' }}>Tax Exempt: </span>{taxExempt?.description}</li>:null}
                                {suggestion?.isExemptSalesTax === true && suggestion?.sterDealerNumber !=='' && suggestion?.sterDealerNumber !== null?<li><span style={{ color: "#000", fontWeight: 'bold' }}>Dealer Number: </span>{suggestion?.sterDealerNumber}</li>:null}
                                {suggestion?.isExemptSalesTax === true && suggestion?.sterDotNumber !==''&& suggestion?.sterDotNumber !==null?<li><span style={{ color: "#000", fontWeight: 'bold' }}>DOT Number: </span>{suggestion?.sterDotNumber}</li>:null}
                                {suggestion?.isExemptSalesTax === true && suggestion?.sterMcNumber !==''&& suggestion?.sterMcNumber !==null?<li><span style={{ color: "#000", fontWeight: 'bold' }}>MC Number: </span>{suggestion?.sterMcNumber}</li>:null}
                                {suggestion?.isExemptSalesTax === true && suggestion?.sterResaleNumber !==''&& suggestion?.sterResaleNumber !==null?<li><span style={{ color: "#000", fontWeight: 'bold' }}>Resale Number: </span>{suggestion?.sterResaleNumber}</li>:null}
                                {/* {suggestion?.vehicleTaxCounty&& suggestion?.vehicleTaxCounty!==''?<li><span style={{ color: "#000", fontWeight: 'bold' }}>Tax County: </span>{suggestion?.isTaxExempt} -- {suggestion?.vehicleTaxCounty}</li>:null} */}
                                {suggestion?.type?<li><span style={{ color: "#000", fontWeight: 'bold' }}>Customer Type: </span>{formatters.capitalizeFirstLetter(suggestion?.type)}</li>:null}

                              </ul>
  
                            </div>
                          </div>
                        </div>
                        { suggestion?.deliveryAddress?.addressLine1 && suggestion?.deliveryAddress?.addressLine1 !== "" ? (
                          <div className="text-center mx-auto" style={{ marginTop: '-15px', fontSize: '14px', marginLeft: '-150px'}}>
                            <span style={{ color: "#000", fontWeight: 'bold', fontSize: '15px' }}>Customer Address</span>
                            <br />
                              {suggestion?.deliveryAddress?.addressLine1}
                              <br />
                              { suggestion?.deliveryAddress?.addressLine2 && suggestion?.deliveryAddress?.addressLine2 !== "" ? (
                                <>
                                  {suggestion?.deliveryAddress?.addressLine2}
                                  <br />
                                </>
                              ):null}
                              {formatters.capitalizeFirstLetter(suggestion?.deliveryAddress?.city)}, {suggestion?.deliveryAddress?.state?.toUpperCase()} {suggestion?.deliveryAddress?.zip}
                          </div>
                        ):null}
                        <br/>
                        { suggestion?.mailingAddress && suggestion?.mailingAddress?.id ? (
                          <><div className="text-center mx-auto" style={{ marginTop: '-15px', fontSize: '14px', marginLeft: '-150px'}}>
                            <span style={{ color: "#000", fontWeight: 'bold', fontSize: '15px' }}>Mailing Address</span>
                            <br />
                            {suggestion?.mailingAddress?.addressLine1}
                            <br />
                              { suggestion?.mailingAddress?.addressLine2 && suggestion?.mailingAddress?.addressLine2 !== "" ? (
                                <>
                                  {suggestion?.mailingAddress?.addressLine2}
                                  <br />
                                </>
                              ):null}
                              {formatters.capitalizeFirstLetter(suggestion?.mailingAddress?.city)}, {suggestion?.mailingAddress?.state?.toUpperCase()} {suggestion?.mailingAddress?.zip}
                              {/* <br /> */}
                          </div>
                              <br/>
                              </>
                        ):null}
                        </>
                      }
                    >
                      <p style={{textAlign:'center', margin:'0 1.5rem'}}>
                        {suggestion?.name}
                      </p>
                    </Tooltip>
                  </li>
                </>
              )
            })}
          </ul>
          </>
        )
      }
    }
  
    const handleListToggle = (index) => {
      setHighlightListItem(index);
    }

    function prepRedux(){
      dispatch(updateCopySection('customer'));
      dispatch(updateCopySubSection('details'));
    }
  
    function prepDocumentCopy(){
      dispatch(updateStatus('copy'));
      dispatch(setActiveModal('copy-to-documents'));
    }
    
  return (
    <>
      { appData?.activeModal === 'customer' ? (
        <>
          <Modal
            style={{ overflow: 'scroll' }}
            open={ isOpen }
            onClose={ () => {
              ModalHelper.handleClose(); 
              setFetchingInitData(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
          <div>
          <div>
          { order ? (
            <div
              tabIndex="-1"
              aria-labelledby="exampleModalCustomer"
              data-bs-backdrop="static" 
              data-bs-keyboard="false"
            >
              <div className="modal-dialog modal-dialog-scrollable modal-xl">
                <div className="modal-content">
                  <div className="modal-header bg-primary text-white">
                    <h5 className="modal-title text-capitalize" id="exampleModalCustomer">
                    {modalAction}  Customer
                    </h5>
                    { hasClickedClose ? (
                      <div className="d-flex">
                          <p className="m-1">Save Changes?</p>
                          <button className="m-1 btn btn-sm btn-success" data-testid="customer.control.saveYes" aria-label='save changes' onClick={preCloseSave}>Yes</button>
                          <button className="m-1 btn btn-sm btn-danger" data-testid="customer.control.saveNo" aria-label='do not save changes' onClick={preCloseClose}>No</button>
                        </div>
                      ):(
                        <button
                        type="button"
                        className="btn-close bg-white"
                        // data-bs-dismiss="modal"
                        aria-label="close"
                        data-testid="customer.control.closeModal"
                        onClick={ closeClicked }
                        ></button>
                      )}

                  </div>

                  <div className="modal-body">
                    <form className="container-fluid mt-0">
                      <div className="d-flex flex-column justify-content-center">
                        <div className="d-flex mx-auto">
                        {showSpinner ?
                          <CircularProgress/>
                        : ( saveCustomerSuccess ? (
                          <>
                            <button className="ma-2 btn  btn-block btn-sm btn-success text-uppercase mx-3" aria-label="customer info saved successfully">Customer Saved</button>
                            <button aria-label='Copy to Documents' 
                              data-testid="customer.control.copyToDocuments"
                              onClick={ (e)=>{
                                e.preventDefault();
                                ModalHelper.initalizeCopyToDocuments(ModalHelper.changesArray);
                                ModalHelper.handleClose();
                                prepRedux();
                                prepDocumentCopy();
                              }} data-bs-toggle="modal" data-bs-target="#copychangestodocuments" className="ma-2 btn  btn-block btn-sm btn-primary text-uppercase">Copy to Documents</button><br />
                          </>
                        ):(
                          <button onClick={ e => ModalHelper.onHandleSubmit(e)}
                            className="mx-auto btn btn-primary text-uppercase"
                            aria-label="save"
                            data-testid="customer.control.saveTop"
                            >
                            Save
                          </button>
                        ))}
                        </div>
                        
                        <div className="d-flex mx-auto py-3 align-items-center">
                          <div style={{ maxWidth: '400px', paddingRight: '1rem', width: '350px'}}>
                            <input
                              autoComplete="off"
                              type="text"
                              aria-label='product stock number'
                              className="form-control rounded-pill mt-1 "
                              name="productDetailStock"
                              data-testid="customer.control.lookupText"
                              onChange={(e) => {
                                lookupByCustomerName(e);
                              }}
                              value={customerLookup??''}
                              // onBlur={()=>setDisplayStockSuggestions('none')}
                              
                              onFocus={()=> {
                                setSuggestions([]);
                                // setDisplayStockSuggestions('block');
                              }}
                              />
                            {customerLookup.length >= 1? addSuggestionList(suggestions): null}
                          </div>
                          <button className="mx-auto btn btn-primary text-uppercase" 
                            aria-label='Copy Customer'
                            disabled={!customerLookupId || copyStatus==='copying'}
                            data-testid="customer.control.copyCustomerBtn"
                            onClick={(e)=>{
                            e.preventDefault()
                            copyCustomer(customerLookupId)
                          }}>Copy Customer</button>
                        </div>
                      
                        <div style={{display:'flex', justifyContent:'center'}}>
                          { copyStatus==='copying' ? <CircularProgress/>:(copyStatus==='success'? <p className="text-primary">Customer Successfully Copied!</p>:(copyStatus==='fail'?<p className="text-danger">Server Failed to Copy Customer</p>:null))}
                        </div>
                      </div>
                      <div className="container-fluid row">
                        <div className="mb-3 col-md-4 ">
                          <label htmlFor="customerName" className="form-label">
                            Name <span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            type="text"
                            aria-label='customer name'
                            className="form-control rounded-pill"
                            value={ ModalHelper.getEditedOrDefaultCustomerInfoValue('name') }
                            name="name"
                            onChange={(e) => {
                              ModalHelper.onCustomerNameChange(e);
                              updateDataChangeArray(e);
                            }}
                            data-testid="customer.name"
                          />
                          <FormError validate={ editedCustomerInfoErrors.name }>
                            Name is required.
                          </FormError>
                        </div>
                        <div className="mb-3  col-md-4">
                          <label htmlFor="customerPhone" className="form-label">
                            Phone <span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            type="tel"
                            aria-label='customer telephone number'
                            className="rounded-pill form-control"
                            name="phone"
                            value={ formatters.formatPhoneNumber(ModalHelper.getEditedOrDefaultCustomerInfoValue('phone')) }
                            onChange={(e) => {
                              ModalHelper.onPhoneChange(e);
                              updateDataChangeArray(e);
                            }}
                            data-testid="customer.phone"
                          />
                          <FormError validate={ editedCustomerInfoErrors.phone }>
                            Phone is required and must be 10 numbers long.
                          </FormError>
                        </div>
                        <div className="mb-3  col-md-4">
                          <label htmlFor="customerEmail" className="form-label">
                            Email <span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            type="email"
                            name="email"
                            aria-label='customer email address'
                            className="rounded-pill form-control"
                            value={ ModalHelper.getEditedOrDefaultCustomerInfoValue('email') }
                            onChange={(e) => {
                              ModalHelper.onEmailChange(e);
                              updateDataChangeArray(e);
                            }}
                            data-testid="customer.email"
                          />
                          <FormError validate={ editedCustomerInfoErrors.email }>
                            Email is required and must be in valid format.
                          </FormError>
                        </div>
                      </div>
                      <div className="container-fluid row">
                        <div className="mb-3 col-md-4 ">
                          <label htmlFor="customerName" className="form-label">
                            Customer Type
                          </label>
                          <br />
                          <FormControl className="px-3 pt-2">
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              value={ ModalHelper.getEditedOrDefaultCustomerInfoValue('type') }
                              name="type"
                              data-testid="customer.type"
                              onChange={e => {
                                ModalHelper.onCustomerTypeChange(e);
                                updateDataChangeArray(e);
                               }}>
                              <FormControlLabel className="px-3" value="individual" control={<Radio data-testid="customer.type.individual" />} label="Individual" />
                              <FormControlLabel className="px-3" value="entity" control={<Radio data-testid="customer.type.entity" />} label="Entity" />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        <div className="mb-3 col-md-4 ">
                          <label htmlFor="customerName" className="form-label">
                            DBA
                          </label>
                          <input
                            type="text"
                            aria-label='customer dba'
                            data-testid="customer.dba"
                            className="form-control rounded-pill"
                            value={ ModalHelper.getEditedOrDefaultCustomerInfoValue('dba') }
                            name="dba"
                            onChange={(e) => {
                              ModalHelper.onDbaChange(e);
                              updateDataChangeArray(e);
                            }}
                          />
                        </div>
                        <div className="mb-3 col-md-4 ">
                          <div className="d-flex justify-content-start" style={{ paddingTop: '2rem'}}>
                              <FormGroup className="m-2">
                                <FormControlLabel 
                                  control={<Checkbox color="default"  data-testid="customer.acceptMarketingEmailsCheckbox"
                                  checked={ ModalHelper.getEditedOrDefaultCustomerInfoValue('acceptMarketingEmails') }
                                  aria-label='check customer accept marketing emails'
                                  onClick={(e) => { 
                                    ModalHelper.onAcceptMarketingEmailsToggle(ModalHelper.getEditedOrDefaultCustomerInfoValue('acceptMarketingEmails'));
                                    updateDataChangeArray(e);
                                  }} />} 
                                  label="Receive Marketing Emails?" 
                                  name="customer.acceptMarketingEmails"
                                />
                              </FormGroup>
                            </div>
                        </div>
                      </div>

                      <hr />
                  
                      {/* -------------------------row2 Address------------------- */}
                      <div className="container-fluid row">
                        {/*---------------------------------- Column 1 Address fields ----------------------------------------- */}
                        <div className="mb-3 col-md-12">
                          <div className="d-flex justify-content-start">
                            <h5 className="m-2 mt-3">Customer Address <span style={{ color: 'red' }}>*</span></h5>
                            <FormGroup className="m-2">
                              <FormControlLabel 
                                control={<Checkbox color="default"  data-testid="customer.mailingAddress.checkbox"
                                checked={ shouldCheckMailingAddress }
                                aria-label='check customer mailing address'
                                onChange={(e) => { 
                                  ModalHelper.onMailingAddressToggle(mailingAddressChecked);
                                  updateDataChangeArray(e);
                                }} />} 
                                label="Mailing Address" 
                                name="deliveryAddress.enabled"
                              />
                            </FormGroup>
                          </div>
                            <div>
                              <label htmlFor="addressLine1" className="form-label">
                                Address1 <span style={{ color: 'red' }}>*</span>
                              </label>
                              <input
                                type="text"
                                aria-label='customer delivery address line 1'
                                className="rounded-pill form-control "
                                value={ ModalHelper.getEditedOrDefaultDeliveryAddressValue('addressLine1') }
                                onChange={(e) => {
                                  ModalHelper.onDeliveryAddressEdited('addressLine1', e);
                                  updateDataChangeArray(e);
                                }}
                                name="deliveryAddress.addressLine1"
                                data-testid="customer.deliveryAddress.line1"
                              />
                              <FormError validate={ editedDeliveryAddressErrors.addressLine1 }>
                                Address is required.
                              </FormError>
                            </div>
                            <div>
                              <label htmlFor="address2" className="form-label">
                                Address2
                              </label>
                              <input
                                type="text"
                            aria-label='customer deliver address line 2'
                            className="rounded-pill form-control "
                                value={ ModalHelper.getEditedOrDefaultDeliveryAddressValue('addressLine2') }
                                onChange={(e) => {
                                  ModalHelper.onDeliveryAddressEdited('addressLine2', e);
                                  updateDataChangeArray(e);
                                }}
                                name="deliveryAddress.addressLine2"
                                data-testid="customer.deliveryAddress.line2"
                              />
                            </div>
                            <div className="row">
                              <div className="mb-3 col-md-6">
                                <label htmlFor="city" className="rounded-pill form-label">
                                  City <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  aria-label='customer delivery city'
                                  className="rounded-pill form-control "
                                  value={ ModalHelper.getEditedOrDefaultDeliveryAddressValue('city') }
                                  onChange={(e) => {
                                    ModalHelper.onDeliveryAddressEdited('city', e);
                                    updateDataChangeArray(e);                                    
                                  }} 
                                  name="deliveryAddress.city"
                                  data-testid="customer.deliveryAddress.city"
                                />
                                <FormError validate={ editedDeliveryAddressErrors.city }>
                                  City is required.
                                </FormError>
                              </div>
                              <div className="mb-3 col-md-6 mt-3">
                                <Autocomplete
                                  value={ ModalHelper.getEditedOrDefaultDeliveryAddressValue('state') }
                                  onChange={(event, newValue) => {
                                    ModalHelper.onDeliveryAddressEdited('state', newValue);

                                    updateDataChangeArray(event, "deliveryAddress.state",newValue );   
                                    // updateDataChangeArray(event);

                                  }}
                                  inputValue={inputdeliveryStateValue}
                                  onInputChange={(event, newInputValue) => {
                                    setInputdeliveryStateValue(newInputValue);
                                  }}
                                  name="deliveryAddress.state"
                                  aria-label='customer delivery state'
                                  options={ AbbreviatedStates }
                                  sx={{ width: 150 }}
                                  renderInput={(params) => <TextField {...addTestingTag(params, 'customer.deliveryAddress.state')} label={ <span>STATE <span style={{ color: 'red' }}>*</span></span> } />}
                                />
                                <FormError validate={ editedDeliveryAddressErrors.state }>
                                  State is required.
                                </FormError>
                              </div>
                            </div>
                            <div className="row">
                              <div className="mb-3 col-md-6">
                                <label htmlFor="zip" className="form-label">
                                  Zip <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  aria-label='customer delivery zip'
                                  className="rounded-pill form-control"
                                  value={ formatters.formatZipCode(ModalHelper.getEditedOrDefaultDeliveryAddressValue('zip')) }
                                  onChange={(e) => {
                                    ModalHelper.onDeliveryAddressEdited('zip', e);
                                    updateDataChangeArray(e);  
                                  }} 
                                  name="deliveryAddress.zip"
                                  data-testid="customer.deliveryAddress.zip"
                                />
                                <FormError validate={ editedDeliveryAddressErrors.zip }>
                                  Zip is required and must be 5 or 9 digits long.
                                </FormError>
                              </div>
                            </div>
                        </div>
                      </div>
                      { shouldCheckMailingAddress ? (
                      <div className="container-fluid row">
                        <div className="mb-3 col-md-12">
                          <div className="d-flex justify-content-start">
                            <h5 className="m-2 mt-3">Mailing Address</h5>
                            <FormGroup className="m-2">
                                <FormControlLabel 
                                  label="Same as Customer Address"
                                  control={
                                    <Checkbox
                                      color="default" 
                                      checked={ shouldCheckSameAsDeliveryAddress } 
                                      aria-label='check customer mailing address same as customer address'
                                      name="mailingAddress.enabled"
                                      data-testid="customer.sameAsCustomerAddress.checkbox"
                                      onChange={(e) => {
                                        ModalHelper.onSameAsDeliveryAddressToggle(shouldCheckSameAsDeliveryAddress);
                                        updateDataChangeArray(e);   
                                      }} />
                                  }/>
                              </FormGroup>
                              </div>
                            <div>
                              <label htmlFor="addressLine1" className="form-label">
                                Address1 <span style={{ color: 'red' }}>*</span>
                              </label>
                              <input
                                type="text"
                                name="mailingAddress.addressLine1"
                                aria-label='customer mailing address line 1'
                                data-testid="customer.mailingAddress.line1"
                                className="rounded-pill form-control "
                                value={ ModalHelper.getEditedOrDefaultMailingAddressValue('addressLine1') }
                                onChange={(e) => {
                                  ModalHelper.onMailingAddressEdited('addressLine1', e);
                                  updateDataChangeArray(e);   
                                }}
                              />
                              <FormError validate={ editedMailingAddressErrors.addressLine1 }>
                                Mailing address is required.
                              </FormError>
                            </div>
                            <div>
                              <label htmlFor="address2" className="form-label">
                                Address2
                              </label>
                              <input
                                type="text"
                                name="mailingAddress.addressLine2"
                                aria-label='customer mailing address line 2'
                                data-testid="customer.mailingAddress.line2"
                                className="rounded-pill form-control "
                                value={ ModalHelper.getEditedOrDefaultMailingAddressValue('addressLine2') }
                                onChange={(e) => {
                                  ModalHelper.onMailingAddressEdited('addressLine2', e);
                                  updateDataChangeArray(e);   
                                }}
                              />
                            </div>
                            <div className="row">
                              <div className="mb-3 col-md-6">
                                <label htmlFor="city" className="rounded-pill form-label">
                                  City <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  name="mailingAddress.city"
                                  data-testid="customer.mailingAddress.city"
                                  aria-label='customer mailing address city'
                                  className="rounded-pill form-control "
                                  value={ ModalHelper.getEditedOrDefaultMailingAddressValue('city') }
                                  onChange={(e) => {
                                    ModalHelper.onMailingAddressEdited('city', e);
                                    updateDataChangeArray(e);   
                                  }}
                                />
                                <FormError validate={ editedMailingAddressErrors.city }>
                                  Mailing city is required.
                                </FormError>
                              </div>
                              </div>
                            <div className="row">

                              <div className="mb-3 col-md-6 mt-3">
                                <Autocomplete
                                  value={ ModalHelper.getEditedOrDefaultMailingAddressValue('state') }
                                  onChange={(event, newValue) => {
                                    ModalHelper.onMailingAddressEdited('state', newValue);
                                    updateDataChangeArray(event, 'mailingAddress.state', newValue);
                                  }}
                                  name="mailingAddress.state"
                                  inputValue={inputmailingStateValue}
                                  onInputChange={(event, newInputValue) => {
                                    setInputmailingStateValue(newInputValue);
                                  }}
                                  aria-label='customer mailing address state'
                                  options={ AbbreviatedStates }
                                  sx={{ width: 100 }}
                                  renderInput={(params) => <TextField {...addTestingTag(params, 'customer.mailingAddress.state')} label={ <span>STATE <span style={{ color: 'red' }}>*</span></span> } />}
                                />
                                <FormError validate={ editedMailingAddressErrors.state }>
                                  Mailing state is required.
                                </FormError>
                              </div>
                            </div>
                            <div className="row">
                              <div className="mb-3 col-md-6">
                                <label htmlFor="zip" className="form-label">
                                  Zip <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  name="mailingAddress.zip"
                                  aria-label='customer mailing address zip'
                                  data-testid="customer.mailingAddress.zip"
                                  className="rounded-pill form-control"
                                  value={ formatters.formatZipCode(ModalHelper.getEditedOrDefaultMailingAddressValue('zip')) }
                                  onChange={(e) => {
                                    ModalHelper.onMailingAddressEdited('zip', e);
                                    updateDataChangeArray(e);   
                                  }} 
                                />
                                <FormError validate={ editedMailingAddressErrors.zip }>
                                  Mailing zip is required and must be 5 or 9 digits long.
                                </FormError>
                              </div>
                            </div>
                        </div>
                      </div>
                      
                      ): null}
                      <hr />
                      { showTaxExemptionCheckbox && (
                      <>
                      <div className="container-fluid row">
                        <div className="mb-3 col-md-6 ">
                          <div className="custom-control custom-checkbox checkbox-lg">
                            <FormGroup className="m-2">
                              <FormControlLabel
                                label="EXEMPT FROM SALES TAX"
                                control={
                                  <Checkbox color="default"
                                    aria-label='customer exempt from sales tax'
                                    name="isSalesTaxExempt.enabled"
                                    checked={ isExemptCheckboxChecked }
                                    data-testid="customer.isSalesTaxExempt.checkbox"
                                    onChange={(e) => {
                                      ModalHelper.onIsExemptSalesTaxChange(isExemptCheckboxChecked);
                                      updateDataChangeArray(e);   
                                     }} />
                                  }
                                />
                            </FormGroup>
                          </div>
                        </div>
                        { ( _.isNil( editedCustomerInfo.isExemptSalesTax ) ?
                          order.customer?.isExemptSalesTax : editedCustomerInfo.isExemptSalesTax ) ? (

                          <div className="container-fluid d-flex justify-content-around ">
                              <div className="mb-3 col-md-4">
                              {/* V htmlFor what? V */}

                              <label htmlFor="taxPercentage" className="form-label">
                                Exemption Validation <span style={{ color: 'red' }}>*</span>
                              </label>
                                
                                <Autocomplete 
                                id="SalesTaxExemptReasons"
                                options={taxExemptReasons??[]}
                                getOptionLabel={(option) => {
                                  return option.description??''
                                }}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...addTestingTag(params, 'customer.salesTaxExemptionReasonId')} />}
                                value={ taxExemptReasons?.find(reason=> reason.id === ModalHelper.getEditedOrDefaultCustomerInfoValue('salesTaxExemptionReasonId'))??''}
                                inputValue={inputSalesTaxExemptionReasonId}
                                onInputChange={(event, newInputValue) => {
                                  setInputSalesTaxExemptionReasonId(newInputValue);
                                }}
                                aria-label='customer tax exempt reason'
                                name="salesTaxExemptionReasonId"
                                // data-testid="customer.salesTaxExemptionReasonId"
                                onChange={(e,v) =>{
                                  ModalHelper.onSalesTaxExemptionReasonIdEdit(v);
                                  updateDataChangeArray(e);   
                                }
                                } 
                                />
                                <FormError validate={ editedCustomerInfoErrors.SalesTaxExemptionReason }>
                                  Exemption Validation is required.
                                </FormError>

                              </div>
                              <div className="mb-3 col-md-8 d-flex justify-content-around">
                              { (getSterCode(ModalHelper.getEditedOrDefaultCustomerInfoValue('salesTaxExemptionReasonId')) === 'EXEMPTCERT' || 
                                getSterCode(ModalHelper.getEditedOrDefaultCustomerInfoValue('salesTaxExemptionReasonId')) === 'COLBYLESSOR' ||
                                getSterCode(ModalHelper.getEditedOrDefaultCustomerInfoValue('salesTaxExemptionReasonId')) === 'DLRRESALE' ||
                                getSterCode(ModalHelper.getEditedOrDefaultCustomerInfoValue('salesTaxExemptionReasonId')) === 'SOLDATAUCTN' ||
                                getSterCode(ModalHelper.getEditedOrDefaultCustomerInfoValue('salesTaxExemptionReasonId')) === 'LEAINTERCOM') ? (
                                <div className="col-md-4 mx-5">
                                  <label className="form-label p-2">Resale Number:</label>
                                      <input
                                        // id="resaleNumber"
                                        aria-label="customer resale number"
                                        className="rounded-pill form-control"
                                        // name={getSterCode(ModalHelper.getEditedOrDefaultCustomerInfoValue('salesTaxExemptionReasonId'))}
                                        placeholder="Resale Number"
                                        name="salesTaxExemptionReason.sterResaleNumber"
                                        value={ModalHelper.getEditedOrDefaultCustomerInfoValue('sterResaleNumber')??''} 
                                        onChange={(e) => {
                                          editSubDataPoint(e);
                                        }}
                                        data-testid="customer.sterResaleNumber"
                                      />
                                      </div>
                              ):null}
                              
                              { getSterCode(ModalHelper.getEditedOrDefaultCustomerInfoValue('salesTaxExemptionReasonId')) === 'EXEMPTCERT' ||
                              getSterCode(ModalHelper.getEditedOrDefaultCustomerInfoValue('salesTaxExemptionReasonId')) === 'SOLDATAUCTN' ? (

                                  <div className="col-md-4 mx-5">
                                      <label className="form-label p-2">Dealer Number:</label>
                                      <input
                                      // id='dealerNumber'
                                      className="rounded-pill form-control"
                                      aria-label="customer dealer number"
                                      // name={getSterCode(ModalHelper.getEditedOrDefaultCustomerInfoValue('salesTaxExemptionReasonId'))}
                                      name="salesTaxExemptionReason.sterDealerNumber"
                                      placeholder="Dealer Number"
                                      value={ModalHelper.getEditedOrDefaultCustomerInfoValue('sterDealerNumber')??''}
                                      onChange={(e) => editSubDataPoint(e)}
                                      data-testid="customer.sterDealerNumber"
                                      />

                                  </div>
                                
                              ):null}
                              { getSterCode(ModalHelper.getEditedOrDefaultCustomerInfoValue('salesTaxExemptionReasonId')) === 'CDTFA837' ? (<>
                                    <div className="col-md-4 mx-5">
                                      <label className="form-label p-2">MC Number:</label>
                                      <input 
                                        placeholder='MC number'
                                        // id='mcNumber'
                                        aria-label="customer mc number"
                                        className="rounded-pill form-control"
                                        name="salesTaxExemptionReason.sterMcNumber"
                                        // name={getSterCode(ModalHelper.getEditedOrDefaultCustomerInfoValue('salesTaxExemptionReasonId'))}
                                        value={ModalHelper.getEditedOrDefaultCustomerInfoValue('sterMcNumber')??''}
                                        onChange={(e) => editSubDataPoint(e)}
                                        data-testid="customer.sterMcNumber"
                                      />
                                    </div>
                                    <div className="col-md-4 mx-5">
                                      <label className="form-label p-2">DOT Number:</label>
                                      <input 
                                      placeholder='DOT number'
                                      // id='dotNumber'
                                      aria-label="customer dot number"
                                      name="salesTaxExemptionReason.sterDotNumber"
                                      className="rounded-pill form-control"
                                      // name={getSterCode(ModalHelper.getEditedOrDefaultCustomerInfoValue('salesTaxExemptionReasonId'))}
                                      value={ModalHelper.getEditedOrDefaultCustomerInfoValue('sterDotNumber')??''}
                                      onChange={(e) => editSubDataPoint(e)}
                                      data-testid="customer.sterDotNumber"
                                      />

                                      </div>
                                      </>
                                ):null}
                              </div>

                          </div>
                        ): ModalHelper.getEditedOrDefaultDeliveryAddressValue('state') === "WA" ? (
                          <div>
                            <div className="container-fluid row">
                              <div className="mb-3 col-md-6">
                                <label htmlFor="taxPercentage" className="form-label">
                                  VEHICLE SALES TAX % <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="rounded-pill form-control"
                                  aria-label="customer vehicle sales tax %"
                                  value={ ModalHelper.getEditedOrDefaultCustomerInfoValue('vehicleSalesTaxPercent') }
                                  onChange={(e) => {
                                    ModalHelper.onVehicleSalesTaxPercentChange(e);
                                    updateDataChangeArray(e);   
                                  }}
                                  onBlur={(e) => ModalHelper.onVehicleSalesTaxPercentBlur(e)}
                                  name="vehicleSalesTaxPercent"
                                  data-testid="customer.vehicleSalesTaxPercent"
                                />
                                <FormError validate={ editedCustomerInfoErrors.vehicleSalesTaxPercent }>
                                  Vehicle Sales tax percentage is required.
                                </FormError>
                              </div>
                              <div className="mb-3 col-md-6">
                                <label htmlFor="county" className="form-label">
                                  COUNTY <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="rounded-pill form-control"
                                  aria-label="customer vehicle sales tax county"
                                  maxLength="50"
                                  value={ ModalHelper.getEditedOrDefaultCustomerInfoValue('vehicleTaxCounty') }
                                  onChange={(e) => {
                                    ModalHelper.onVehicleTaxCountyChange(e);
                                    updateDataChangeArray(e);   
                                  }} 
                                  name="vehicleTaxCounty"
                                  data-testid="customer.vehicleTaxCounty"
                                />
                                <FormError validate={ editedCustomerInfoErrors.vehicleTaxCounty }>
                                  Vehicle Sales tax county is required.
                                </FormError>
                              </div>
                            </div>

                            <div className="container-fluid row">
                              <div className="mb-3 col-md-6">
                                <label htmlFor="taxPercentage" className="form-label">
                                  NON-VEHICLE SALES TAX % <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="rounded-pill form-control"
                                  aria-label="customer non=vehicle sales tax %"
                                  value={ ModalHelper.getEditedOrDefaultCustomerInfoValue('nonVehicleSalesTaxPercent') }
                                  onChange={(e) => {
                                    ModalHelper.onNonVehicleSalesTaxPercentChange(e);
                                    updateDataChangeArray(e);   
                                  }}
                                  onBlur={(e) => ModalHelper.onNonVehicleSalesTaxPercentBlur(e)}
                                  name="nonVehicleSalesTaxPercent"
                                  data-testid="customer.nonVehicleSalesTaxPercent"
                                />
                                <FormError validate={ editedCustomerInfoErrors.nonVehicleSalesTaxPercent }>
                                  Non-Vehicle Sales tax percentage is required.
                                </FormError>
                              </div>
                              <div className="mb-3 col-md-6">
                                <label htmlFor="county" className="form-label">
                                  COUNTY <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="rounded-pill form-control"
                                  aria-label="customer non-vehicle sales tax county"
                                  maxLength="50"
                                  value={ ModalHelper.getEditedOrDefaultCustomerInfoValue('nonVehicleTaxCounty') }
                                  onChange={(e) => {
                                    ModalHelper.onNonVehicleTaxCountyChange(e);
                                    updateDataChangeArray(e);   
                                  }} 
                                  name="nonVehicleTaxCounty"
                                  data-testid="customer.nonVehicleTaxCounty"
                                />
                                <FormError validate={ editedCustomerInfoErrors.nonVehicleTaxCounty }>
                                  Non-Vehicle Sales tax county is required.
                                </FormError>
                              </div>
                            </div>
                          </div>
                        ):(
                          <div className="container-fluid row">
                            <div className="mb-3 col-md-6">
                              <label htmlFor="taxPercentage" className="form-label">
                                SALES TAX % <span style={{ color: 'red' }}>*</span>
                              </label >
                              <input
                                type="text"
                                className="rounded-pill form-control"
                                aria-label='customer vehicle sales tax %'
                                name="vehicleSalesTaxPercent"
                                value={ ModalHelper.getEditedOrDefaultCustomerInfoValue('vehicleSalesTaxPercent') }
                                onChange={(e) => {
                                  ModalHelper.onVehicleSalesTaxPercentChange(e);
                                  updateDataChangeArray(e);   
                                }}
                                onBlur={(e) => ModalHelper.onVehicleSalesTaxPercentBlur(e)}
                                data-testid="customer.vehicleSalesTaxPercent"
                              />
                              <FormError validate={ editedCustomerInfoErrors.vehicleSalesTaxPercent }>
                                Sales tax percentage is required.
                              </FormError>
                            </div>
                            <div className="mb-3 col-md-6">
                              <label htmlFor="county" className="form-label">
                                COUNTY <span style={{ color: 'red' }}>*</span>
                              </label>
                              <input
                                type="text"
                                name="vehicleTaxCounty"
                                className="rounded-pill form-control"
                                aria-label='customer vehicle sales tax county'
                                maxLength="50"
                                value={ ModalHelper.getEditedOrDefaultCustomerInfoValue('vehicleTaxCounty') }
                                onChange={(e) => {
                                  ModalHelper.onVehicleTaxCountyChange(e);
                                  updateDataChangeArray(e);   
                                }} 
                                data-testid="customer.vehicleTaxCounty"
                              />
                              <FormError validate={ editedCustomerInfoErrors.salesTaxCounty }>
                                Sales tax county is required.
                              </FormError>
                            </div>
                          </div>
                        )}
                      </div>
                      <hr />
                      </>
                      )}
                      <div className="container-fluid row">
                        <div className="mb-3 col-md-12">
                          <label htmlFor="notes" className="form-label">
                            Notes (Internal Notes Only)
                          </label>
                          <textarea
                            className="form-control  rounded-lg"
                            aria-label="customer notes"
                            id="notes"
                            rows="2"
                            cols="50"
                            maxLength="250"
                            name="notes"
                            onChange={(e) => {
                              ModalHelper.onNotesChange(e);
                              updateDataChangeArray(e);   
                            }}
                            data-testid="customer.notes"
                            value={ ModalHelper.getEditedOrDefaultCustomerInfoValue('notes') }
                          ></textarea>
                        </div>
                      </div>
                      {/*---------------------------- CoSigner input details------------------------------- */}
                      
                      <>
                      <div className="row">
                        <div className="col-12 bg-primary py-3 text-white d-flex">
                          <h6 style={{marginRight: "10px"}}>Co-Signer</h6>
                          {/* { !displayNewCosignerFields && */}
                            <AddCircleOutlineIcon 
                              style={{cursor: "pointer" }} 
                              aria-label='show customer cosigner fields'
                              data-testid="customer.cosigner.addButton"
                              onClick={e => {
                                e.preventDefault()
                                dispatch(rdx.setSaveCustomerSuccess(false))
                                dispatch(rdx.setHasUnsavedChanges(true))
                                dispatch({type:'ADD_NEW_COSIGNER'})
                              }
                            }
                            />
                        </div>
                      </div>
                          {showCosigners(cosigners)}
                          <br/>
                      </>
                      <hr className="my-3" />
                      {/* ------------------------Addition contact details ---------------------------------- */}
                      <div className="row">
                        <div className="col-12 bg-primary py-3 text-white d-flex">
                          <h6 style={{marginRight: "10px"}}>Additional Contact Information</h6>
                          { !displayNewContactFields &&
                            <AddCircleOutlineIcon 
                              style={{cursor: "pointer" }} 
                              aria-label='show new customer cosigner fields'
                              data-testid="customer.contacts.addButton"
                              onClick={e => ModalHelper.onShowNewContactFields(e)}
                            />
                          }
                          { displayNewContactFields &&
                            <RemoveCircleOutlineIcon 
                              style={{cursor: "pointer" }} 
                              aria-label='hide new customer contact fields'
                              data-testid="customer.contacts.removeButton"
                              onClick={e => ModalHelper.onHideNewContactFields(e)}
                            />
                          }
                        </div>
                      </div>
                      { //Show existing contacts...
                        ( _.isEmpty(contacts) === false ? showContacts(true, contacts) : null )
                      }

                      { //Show new contacts...
                        (( _.isEmpty(addedContacts) === false && displayNewContactFields ) ? showContacts(false, addedContacts) : null )
                      }

                    </form>
                  </div>
                  <div className="modal-footer justify-content-center mt-2">
                    {showSpinner?<div
                style={{
                  display: "flex",
                  width: "100vw",
                  height:'13vh',
                  justifyContent: "center",
                }}
              >
                <CircularProgress style={{ placeSelf: "center" }} />
              </div>:( saveCustomerSuccess ? (
                    <>
                      <button className="btn btn-success text-uppercase" aria-label="customer info save success">Customer Saved</button>
                      <button aria-label='Copy to Documents' 
                        data-testid="customer.control.copyToDocuments"
                        onClick={ (e)=>{
                          e.preventDefault();
                          ModalHelper.initalizeCopyToDocuments(ModalHelper.changesArray);
                          ModalHelper.handleClose();
                          prepRedux();
                          prepDocumentCopy();
                        }} data-bs-toggle="modal" data-bs-target="#copychangestodocuments" className="ma-2 btn  btn-block btn-sm btn-primary text-uppercase">Copy to Documents</button><br />
                  </>
                    ):(
                      <button onClick={ (e) => {
                        e.preventDefault();
                        ModalHelper.onHandleSubmit(e)
                      }
                      }
                        data-testid="customer.control.saveBottom"
                        aria-label='save customer'
                        className="btn btn-primary text-uppercase">
                        Save
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            ) : (<span></span>)}
          </div>
          <div
            className="modal fade"
            id="signermodaldeleteconfirm"
            tabIndex="-1"
            aria-labelledby="exampledownpaymentmodel"
            aria-hidden="true"
            data-bs-backdrop="false" 
            data-bs-keyboard="false"
          >
        </div>
        <div
            className="modal fade"
            id="contactmodaldeleteconfirm"
            tabIndex="-1"
            aria-labelledby="exampledownpaymentmodel"
            aria-hidden="true"
            data-bs-backdrop="false" 
            data-bs-keyboard="false"
          >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header bg-primary text-white">
                <h5
                  className="modal-title text-capitalize"
                  id="exampledownpaymentmodel"
                  aria-label="delete customer additional contact"
                >
                  Delete Additional Contact
                </h5>
                <button
                  type="button"
                  className="btn-close bg-white"
                  data-bs-dismiss="modal"
                  aria-label="close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="px-3 py-3">
                  <h4 className="container text-secondary text-center my-3">
                    Are you sure you want to delete this additional contact?
                  </h4>
                </div>
              </div>
              <div className="modal-footer text-center">
                <div className="mx-auto">
                  <button
                    type="button"
                    className="btn btn-primary mx-3"
                    onClick={event => {
                      event.preventDefault();
                      ModalHelper.onDeleteContact(deleteContactParams[0], deleteContactParams[1], deleteContactParams[2], deleteContactParams[3])
                    }}
                    data-bs-dismiss="modal"
                    aria-label="delete additional customer contact"
                    data-testid="contact.confirm.control.delete"
                  >
                    Delete
                  </button>
                    <button
                    type="button"
                    className="btn btn-secondary mx-3"
                    data-bs-dismiss="modal"
                    aria-label="Cancel"
                    data-testid="contact.confirm.control.cancel"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
          </Modal>
        </>
      ):null}
    </>
  )
}

//For displaying errros to the user
const FormError = ({ validate: validationError, children }) => {
  return !validationError ? null :
      <span className="text-danger small">{ children }</span>
}

const {
  Autocomplete,
  TextField,
  Divider,
  Checkbox,
  FormGroup,
  FormControlLabel
} = MaterialComponents

const {
  useAction,
  useAddedContacts,
  useAddedContactsErrors,
  useDeletedContactIds,
  useContacts,
  useCosigners,
  useEditedContacts,
  useEditedContactsErrors,
  useEditedCustomerInfo,
  useEditedCustomerInfoErrors,
  useEditedDeliveryAddress,
  useEditedDeliveryAddressErrors,
  useEditedMailingAddressErrors,
  useIsOpen,
  useIsMailingAddressChecked,
  useIsSameAsDeliveryAddressChecked,
  useLastSaveTimestamp,
  useRdxContext,
  useSaveCustomerSuccess,
  useShouldShowNewContactFields,
  // useShouldShowNewCosignerFields,
  useHasUnsavedChanges
} = rdx

export default CustomerModal

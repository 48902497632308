import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";

export const WABillOfSaleGlobalAutofill = (documentValues, order, property=null, value=null, locations, allLocations=null, salesReps=null, unsuedparam=null, productCount=null, assignedObjectType=null) => {
    var newValues = documentValues;
  
      newValues.seller_names = "TEC EQUIPMENT, INC.";
  
      if(property ==='customer.name'){
        newValues.buyer_names = value;
        if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner && cosigner.name){
            newValues.buyer_names += " AND "+cosigner.name;
          }
        }
      }
      if(property ==='customer.cosigners[0].name'){
        newValues.buyer_names = order.customer.names;
        if(value !==''){
            newValues.buyer_names += " AND "+value;
          }
        }
      
      if(property ==='customer.deliveryAddress.addressLine1'){
        newValues.buyer_address = value;
        if(order.customer.deliveryAddress.addressLine2 && order.customer.deliveryAddress.addressLine2 !== ""){
          newValues.buyer_address += " "+order.customer.deliveryAddress.addressLine2;
        }
      }
      if(property ==='customer.deliveryAddress.addressLine2'){
          newValues.buyer_address = order.customer.deliveryAddress.addressLine1+ " "+value;
      }
      if(property ==='customer.deliveryAddress.city'){
        newValues.buyer_city = formatUpperCase(value);
      }
      if(property ==='customer.deliveryAddress.state'){
        newValues.buyer_state = value;
      }
      if(property ==='customer.deliveryAddress.zip'){
        newValues.buyer_zip = value;
      }
      if(property ==='documentationDate'){
        newValues.date_of_sale = moment.utc(value).format("YYYY-MM-DD");
      }
      if(property === 'location'){
        let foundLocation = value;
        if(foundLocation){
          newValues.seller_address = foundLocation.address;
          newValues.seller_city = formatUpperCase(foundLocation.city);
          newValues.seller_state = foundLocation.state;
          newValues.seller_zip = foundLocation.zip;
        }
      }

      if(property !== null && value !== null){
        return newValues;
    }

      
      if(locations && locations.length > 0 && order.tecLocationCode){
        
        let foundLocation = locations.find(l => l.locationCode === order.tecLocationCode);
        if(foundLocation){
          newValues.seller_address = foundLocation.address;
          newValues.seller_city = formatUpperCase(foundLocation.city);
          newValues.seller_state = foundLocation.state;
          newValues.seller_zip = foundLocation.zip;
        }
      }
  
      if(order && order.customer && order.customer.name){
        newValues.buyer_names = order.customer.name;
        newValues.buyer_address = order.customer.deliveryAddress.addressLine1;
        if(order.customer.deliveryAddress.addressLine2 && order.customer.deliveryAddress.addressLine2 !== ""){
          newValues.buyer_address += " "+order.customer.deliveryAddress.addressLine2;
        }
        newValues.buyer_city = formatUpperCase(order.customer.deliveryAddress.city);
        newValues.buyer_state = order.customer.deliveryAddress.state;
        newValues.buyer_zip = order.customer.deliveryAddress.zip;
  
        if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner && cosigner.name){
            newValues.buyer_names += " AND "+cosigner.name;
          }
        }
      }
  
      if(order && order.documentationDate){
        newValues.date_of_sale = moment.utc(order.documentationDate).format("YYYY-MM-DD");
      }
  
      return newValues
}

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const WABillOfSaleAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null, location=null, order=null, unsuedparam=null, productCount=null) => {
    var newValues = documentValues;

    if(property === 'year' && value !== null){
      newValues.year = (value).toString().trim().replace(/['"]+/g, '');
  }

  if(property === 'make' && value !== null){
      newValues.make = (value).toString().trim();
  }

  if(property === 'model' && value !== null){
      newValues.model = (value).toString().trim();
  }

  if(property === 'vin' && value !== null){
    newValues.vin = (value).toString().trim();
  }

  if(property === 'price' && value !== null){
      newValues.sale_price = formatter.format(selectedProduct.price);
  }

  if(property !== null && value !== null){
      return newValues;
  }

    // newValues.year = (selectedProduct.year).trim();
    newValues.model = (selectedProduct.model).trim().toUpperCase();
    newValues.make = (selectedProduct.make).trim();
    newValues.year = (selectedProduct.year);
    newValues.vin = (selectedProduct.vin.toUpperCase());
    newValues.sale_price = formatter.format(selectedProduct.price);
  
    return newValues
}
  
export const WABillOfSaleAutofillTradeDetails = (selectedTradein, documentValues, property=null, value= null, location=null)=>{
    var newValues = documentValues;

    if(property === 'tradein.year' && value !== null){
      newValues.year = (value).trim();
      newValues.copy_year = (value).trim();
    }

    if(property === 'tradein.make' && value !== null){
      newValues.make = (value).trim();
      newValues.copy_make = (value).trim();
    }

    if(property === 'tradein.model' && value !== null){
      newValues.model = (value)
      newValues.copy_model = (value)
    }

    if(property === 'tradein.vin' && value !== null){
      newValues.identification_number = (value.toUpperCase())
      newValues.copy_identification_number = (value.toUpperCase())
    }

    if(property !== null && value !== null){
      return newValues;
    }

    newValues.year = (selectedTradein).trim();
    newValues.make = (selectedTradein.make).trim();
    newValues.model = (selectedTradein.year)
    newValues.identification_number = (selectedTradein.vin.toUpperCase());

    newValues.copy_year = (selectedTradein).trim();
    newValues.copy_make = (selectedTradein.make).trim();
    newValues.copy_model = (selectedTradein.year)
    newValues.copy_identification_number = (selectedTradein.vin.toUpperCase());
    
  
    return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  if(payload.sale_of_toggle){
    // Reset all
    payload.sale_vehicle_selected = "no";
    payload.sale_vessel_selected = "no";

    switch(payload.sale_of_toggle){
      case "vehicle":
        payload.sale_vehicle_selected = "yes";
        break;
      case "vessel":
        payload.sale_vessel_selected = "yes";
        break;
      default:
    }
  }

  if(payload.gift_toggle){
    // Reset all
    payload.gift_yes_selected = "no";
    payload.gift_no_selected = "no";

    switch(payload.gift_toggle){
      case "yes":
        payload.gift_yes_selected = "yes";
        break;
      case "no":
        payload.gift_no_selected = "yes";
        break;
      default:
    }
  }

  // X1.2- Field Type Specific Preview Formatting
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });

  // if(payload.selling_price){
  //   payload.selling_price = payload['selling_price'].replace("$","");
  // }

  // if(payload.gift_value){
  //   payload.gift_value = payload['gift_value'].replace("$","");
  // }
  
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });
  

  if(payload.date_of_sale){
    payload.date_of_sale = moment.utc(payload.date_of_sale).format("MM/DD/YY");
  }

  return payload;
}
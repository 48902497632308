import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";

export const TECAffidavitGlobalAutofill = (documentValues, order, property=null, value=null, location) => {

    // setAddressOptions(getOrderLevelAddresses(order));
  
    var newValues = documentValues;
    
    if(property ==='customer.name'){
      if(order.customer.type === "entity"){
        newValues.customer_name = value;
      }else{
        if(order.customer.dba){
          newValues.customer_name = value+" dba "+order.customer.dba;
        }else{
          newValues.customer_name = value;
        } 
      }
      if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          newValues.customer_name += " AND "+cosigner.name;
        }
      }
  }
    if(property ==='customer.dba'){
      if(order.customer.type === "entity"){
          newValues.customer_name = order.customer.name+" dba "+value;
        } 
        if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner && cosigner.name){
            newValues.customer_name += " AND "+cosigner.name;
          }
        }
    }
    if(property ==='customer.cosigner[0].name'){
      if(order.customer.type === "entity"){
        newValues.customer_name = order.customer.name;
      }else{
        if(order.customer.dba){
          newValues.customer_name = order.customer.name+" dba "+order.customer.dba;
        }else{
          newValues.customer_name = order.customer.name;
        } 
      }
        if(value!==''){
            newValues.customer_name += " AND "+value;
        }
    }
    if(property ==='customer.deliveryAddress.addressLine1'){
      newValues.customer_address_1= value
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        newValues.customer_address_1 +=" "+order.customer.deliveryAddress.addressLine2;
      }
    }
    if(property ==='customer.deliveryAddress.addressLine2'){
        newValues.customer_address_1 = order.customer.deliveryAddress.addressLine1+" "+value;
    }
    if(property ==='customer.deliveryAddress.city'){
      newValues.customer_address_2 = value+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
    }
    if(property ==='customer.deliveryAddress.state'){
      newValues.customer_address_2 = order.customer.deliveryAddress.city+", "+value+" "+order.customer.deliveryAddress.zip;
    }
    if(property ==='customer.deliveryAddress.zip'){
      newValues.customer_address_2 = order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+value;
    }
    if(property ==='documentationDate'){
      newValues.date = moment.utc(value).format("YYYY-MM-DD");
    }
    if(property ==='location'){
      
        newValues.location_address = value.address+" "+value.city+" "+value.state+" "+value.zip;
        newValues.state_of = value.state;
        newValues.permit_state = value.state;
        newValues.permit_state_2 = value.state;
        newValues.location_phone = value.phone;
        newValues.location_email = value.email;
        // setDocumentValues({...documentValues,['location_address']:location.address+" "+location.city+" "+location.state+" "+location.zip});
        if(value.state === "CA"){
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
          // setDocumentValues({...documentValues,['tec_footer_label']:"TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT"});
        }else{
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
          // setDocumentValues({...documentValues,['tec_footer_label']:"TECEQUIPMENT.COM | TEC EQUIPMENT, INC"});
        }
      }
  
    if(property !== null && value !== null){
      return newValues;
  }

    if(order && order.customer && order.customer.name){
      if(order.customer.type === "entity"){
        newValues.customer_name = order.customer.name;
      }else{
        if(order.customer.dba){
          newValues.customer_name = order.customer.name+" dba "+order.customer.dba;
        }else{
          newValues.customer_name = order.customer.name;
        } 
      }
      
      if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          newValues.customer_name += " AND "+cosigner.name;
        }
      }
  
      if(order.customer.deliveryAddress){
        var addressLine = '';
        if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
          addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
        }else{
          addressLine = order.customer.deliveryAddress.addressLine1;
        }
  
        newValues.customer_address_1 = addressLine
        newValues.customer_address_2 = order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
      }
    }
  
      // Get Location Address & Autofill Address
      if(location !== undefined){
        newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
        newValues.state_of = location.state;
        newValues.permit_state = location.state;
        newValues.permit_state_2 = location.state;
        // setDocumentValues({...documentValues,['location_address']:location.address+" "+location.city+" "+location.state+" "+location.zip});
      }
    
      if(location && location.phone){
        newValues.location_phone = location.phone;
        // setDocumentValues({...documentValues,['location_phone']:location.phone});
      }
      
      if(location && location.email){
        newValues.location_email = location.email;
        // setDocumentValues({...documentValues,['location_email']:location.email});
      }
    
      if(location){
        if(location.state === "CA"){
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
          // setDocumentValues({...documentValues,['tec_footer_label']:"TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT"});
        }else{
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
          // setDocumentValues({...documentValues,['tec_footer_label']:"TECEQUIPMENT.COM | TEC EQUIPMENT, INC"});
        }
      }
  
      if(order && order.documentationDate && documentValues.date === undefined){
        newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
      }
  
    return newValues
}

export const TECAffidavitAutofillProductDetails  = (selectedProduct, documentValues, property=null, value=null, location=null, order=null, autofillProductDetails=true) => {
  var newValues = documentValues;

  if(property === 'year' && value !== null){
    newValues.year = (value).toString().trim().replace(/['"]+/g, '');
  }

  if(property === 'make' && value !== null){
      newValues.make = (value).toString().trim();
  }

  if(property === 'model' && value !== null){
      newValues.model = (value).toString().trim();
  }

  if(property !== null && value !== null){
      return newValues;
  }

  newValues.year = (selectedProduct?.year ?? "").trim();
  newValues.make = (selectedProduct?.make ?? "").trim();
  newValues.model = (selectedProduct?.model ?? "").toString().toUpperCase();
  newValues.vin = (selectedProduct?.vin ?? "").toString().toUpperCase();

  return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  if(payload.reason_toggle){
    // Reset all
    payload.drivers_selected = "no";
    payload.business_selected = "no";
    payload.income_selected = "no";
    payload.other_selected = "no";

    switch(payload.reason_toggle){
      case "drivers-license":
        payload.drivers_selected = "yes";
        break;
      case "business-license":
        payload.business_selected = "yes";
        break;
      case "income-tax-return":
        payload.income_selected = "yes";
        break;
      case "other":
        payload.other_selected = "yes";
        break;
      default:
    }
  }



  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  // Data point formatting
  if(payload.date){
    payload.date = moment.utc(payload.date).format("MM/DD/YY");
  }

  return payload;
}
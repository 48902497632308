import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";



var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});


export const CADMVDeclarationOfGVWGlobalAutofill = (documentValues, order, property=null, value=null) => {

    var newValues = documentValues;

  if(property==='customer.name'){
    newValues.name = value;
      if(order.customer.dba && order.customer.dba !== ""){
        newValues.name += " dba " + order.customer.dba;
      } else if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner){
          newValues.name += " AND " +cosigner.name;
        }
      }
  }
  if(property==='customer.dba'){
    newValues.name = order.customer.name
    if(value!==''){
      newValues.name += " dba " + value;
    } else if(order.customer.cosigners && order.customer.cosigners.length > 0){
      let cosigner = order.customer.cosigners[0];
      if(cosigner){
        newValues.name += " AND " +cosigner.name;
      }
    }
  }
  if(property==='customer.cosigner[0].name'){
    newValues.name = order.customer.name
    if(value!==''&& order.customer.dba===''){
      newValues.name += " AND " +value;
    } else if( order.customer.dba){
      newValues.name+= " dba " + order.customer.dba;
    }
  }

  if(property==='customer.deliveryAddress.addressLine1'){
    if(order.customer.deliveryAddress.addressLine2){
      newValues.address = (value+" "+order.customer.deliveryAddress.addressLine2);
    } else{
      newValues.address = value;
    }
}
  if(property==='customer.deliveryAddress.addressLine2'){
    newValues.address = (order.customer.deliveryAddress.addressLine1+" "+value);
  }
  if(property==='customer.deliveryAddress.city'){
        newValues.city = value;
  }
  if(property==='customer.deliveryAddress.state'){
    newValues.state = value;
  }
  if(property==='customer.deliveryAddress.zip'){
    newValues.zip = value;
  }
  if(property==='customer.vehicleTaxCounty'){
    if(!order.customer.isExemptSalesTax && order.customer.vehicleTaxCounty){
      newValues.county = value;
    }
  }
  if(property==='documentationDate'){
    newValues.signature_date = moment.utc(value).format("YYYY-MM-DD");
  }
  
  if(property !== null && value !== null){
    return newValues;
}
    if(order && order.customer){
      newValues.name = order.customer.name;
      if(order.customer.dba && order.customer.dba !== ""){
        newValues.name += " dba " + order.customer.dba;
      }  else if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner){
          newValues.name += " AND " +cosigner.name;
        }
      }
    }

    if(order && order.customer){
        if(order.customer.deliveryAddress){
        var addressLine = '';
        if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
          addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
        }else{
          addressLine = order.customer.deliveryAddress.addressLine1;
        }
        newValues.address = addressLine;
        newValues.city = order.customer.deliveryAddress.city;
        newValues.state = order.customer.deliveryAddress.state;
        newValues.zip = order.customer.deliveryAddress.zip;
      }

      if(!order.customer.isExemptSalesTax && order.customer.vehicleTaxCounty){
        newValues.county = order.customer.vehicleTaxCounty;
      }
    }

    if(order && order.documentationDate){
      newValues.signature_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }

    return newValues
}

export const CADMVDeclarationOfGVWAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
    var newValues = documentValues;

    if(property === 'year' && value !== null){
      newValues.year = (value).toString().trim().replace(/['"]+/g, '');
  }

  if(property === 'make' && value !== null){
      newValues.make = (value).toString().trim();
  }

  if(property === 'model' && value !== null){
      newValues.model = (value).toString().trim();
  }

  if(property === 'price' && value !== null){
    newValues.sales_price =  formatter.format((value).toString().trim());
}

if(property !== null && value !== null){
    return newValues;
}

    newValues.year = (selectedProduct.year).trim();
    newValues.make = (selectedProduct.make).trim();
    newValues.model = (selectedProduct.model).trim();
    newValues.stock_number = (selectedProduct.stockNumber);
    newValues.mileage = (selectedProduct.mileage);
    newValues.serial_number = (selectedProduct.serialNumber);
    newValues.vin = (selectedProduct.vin);
    
    newValues.sale_price = formatter.format(selectedProduct.price);

    return newValues

}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  // X1.2- Field Type Specific Preview Formatting
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  // Data point formatting
  if(payload.date_operated){
    payload.date_operated = moment.utc(payload.date_operated).format("MM/DD/YY");
  }

  if(payload.date_operated_2){
    payload.date_operated_2 = moment.utc(payload.date_operated_2).format("MM/DD/YY");
  }

  if(payload.signature_date){
    payload.signature_date = moment.utc(payload.signature_date).format("MM/DD/YY");
  }

  return payload;
}
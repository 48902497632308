import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";
import { salesPriceWithUpgradeCalc } from '../../../../../../../utlis/productCalculations.js';

export const AZTitleAppGlobalAutofill = (documentValues, order, property=null, value=null) => {
  var newValues = documentValues;

  if(property?.includes('customer') || property === null){

    if(order && order.customer && order.customer.name){
        newValues.printed = order.customer.name;
    }

  }

  return newValues;

}

export const AZTitleAppAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {

  var newValues = documentValues;

  if(property?.includes('vin') || property === null){
    newValues.vin = (selectedProduct.vin).toString().toUpperCase();
  }

  if(property?.includes('year') || property === null){
    newValues.year = (selectedProduct.year).trim();
  }

  if(property?.includes('make') || property === null){
    newValues.make = (selectedProduct.make).trim();
  }

  if(property?.includes('model') || property === null){
    newValues.model = (selectedProduct.model).toString().toUpperCase();
  }

  if(property?.includes('vehicleType') || property === null){
    newValues.body_style = selectedProduct.vehicleType;
  }

  if(property?.includes('fundingDetail') || property?.includes('fundingMethod') || property === null){
    if(selectedProduct.fundingDetail && selectedProduct.fundingDetail.finalizedAt){
      if(selectedProduct.fundingDetail.fundingMethod === "tec-financing" || selectedProduct.fundingDetail.fundingMethod === "finance-only" || selectedProduct.fundingDetail.fundingMethod === "direct-deal"){
        if(selectedProduct.fundingDetail.finalizedLendingOption){
          newValues.lien_holder_name = selectedProduct.fundingDetail.finalizedLendingOption.bankName;
    
          let addressLine = "";
          addressLine = selectedProduct.fundingDetail.finalizedLendingOption.lienAddress;
    
        //  addressLine += " "+(selectedProduct.fundingDetail.finalizedLendingOption.lienCity+", "+selectedProduct.fundingDetail.finalizedLendingOption.lienState+" "+selectedProduct.fundingDetail.finalizedLendingOption.lienZip)
        newValues.mailing_address = addressLine;
        newValues.mailing_city = selectedProduct.fundingDetail.finalizedLendingOption.lienCity;
        newValues.mailing_state = selectedProduct.fundingDetail.finalizedLendingOption.lienState;
        newValues.mailing_zip = selectedProduct.fundingDetail.finalizedLendingOption.lienZip;
      }
      }
    
      if(selectedProduct.fundingDetail.fundingMethod === "cash-with-lien"){
        if(selectedProduct.fundingDetail.cashLienHolder){
          newValues.lien_holder_name = selectedProduct.fundingDetail.cashLienHolder.bankName;
    
          let addressLine = "";
          if(selectedProduct.fundingDetail.cashLienHolder.addressLine2 != null && selectedProduct.fundingDetail.cashLienHolder.addressLine2 !== undefined && selectedProduct.fundingDetail.cashLienHolder.addressLine2 !== ''){
            addressLine = (selectedProduct.fundingDetail.cashLienHolder.addressLine1+" "+selectedProduct.fundingDetail.cashLienHolder.addressLine2);
          }else{
            addressLine = selectedProduct.fundingDetail.cashLienHolder.addressLine1;
          }
    
        //  addressLine += (selectedProduct.fundingDetail.cashLienHolder.city+", "+selectedProduct.fundingDetail.cashLienHolder.state+" "+selectedProduct.fundingDetail.cashLienHolder.zip)
          newValues.mailing_address = addressLine;
          newValues.mailing_city = selectedProduct.fundingDetail.cashLienHolder.city;
          newValues.mailing_state = selectedProduct.fundingDetail.cashLienHolder.state;
          newValues.mailing_zip = selectedProduct.fundingDetail.cashLienHolder.zip;
        }
      }
    }
  }

  if(property?.includes('soldTo') || property === null){

    if(selectedProduct.soldTo){
      newValues.owner_name = selectedProduct.soldTo.name;

      var addressLineSoldTo = '';
      if(selectedProduct.soldTo.addressLine2 != null && selectedProduct.soldTo.addressLine2 !== undefined && selectedProduct.soldTo.addressLine2 !== ''){
        addressLineSoldTo = (selectedProduct.soldTo.addressLine1+" "+selectedProduct.soldTo.addressLine2);
      }else{
        addressLineSoldTo = selectedProduct.soldTo.addressLine1;
      }
      newValues.street_address = addressLineSoldTo;
      newValues.street_city = selectedProduct.soldTo.city;
      newValues.street_state = selectedProduct.soldTo.state;
      newValues.street_zip = selectedProduct.soldTo.zip;
    }

  }

  if(property?.includes('shipTo') || property === null){

    if(selectedProduct.shipTo){
      newValues.owner_name_2 = selectedProduct.shipTo.name;

      var addressLineShipTo = '';
      if(selectedProduct.shipTo.addressLine2 != null && selectedProduct.shipTo.addressLine2 !== undefined && selectedProduct.shipTo.addressLine2 !== ''){
        addressLineShipTo = (selectedProduct.shipTo.addressLine1+" "+selectedProduct.shipTo.addressLine2);
      }else{
        addressLineShipTo = selectedProduct.shipTo.addressLine1;
      }
      newValues.street_address_2 = addressLineShipTo;
      newValues.street_city_2 = selectedProduct.shipTo.city;
      newValues.street_state_2 = selectedProduct.shipTo.state;
      newValues.street_zip_2 = selectedProduct.shipTo.zip;
    }

  }

  if(property?.includes('price') || property?.includes('fees') || property === null){

    newValues.list_price = salesPriceWithUpgradeCalc(selectedProduct);
    if(selectedProduct.lineItemFee && selectedProduct.lineItemFee.fetTotal){
      newValues.list_price += Number(selectedProduct.lineItemFee.fetTotal);
    }

  }

  return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {
  
  if(payload.form_type_toggle){
    // Reset all
    payload.title_reg_cb = "no";
    payload.title_cb = "no";
    payload.duplicate_cb = "no";
    payload.reg_cb = "no";
    payload.dismantle_cb = "no";
    payload.salvage_cb = "no";
    payload.stolen_cb = "no";

    switch(payload.form_type_toggle){
      case "title_reg":
        payload.title_reg_cb = "yes";
        break;
      case "title":
        payload.title_cb = "yes";
        break;
      case "dupe":
        payload.duplicate_cb = "yes";
        break;
      case "reg":
        payload.reg_cb = "yes";
        break;
      case "dismantle":
        payload.dismantle_cb = "yes";
        break;
      case "salvage":
        payload.salvage_cb = "yes";
        break;
      case "stolen":
        payload.stolen_cb = "yes";
        break;

      default:
    }
  }

  
  if(payload.odometer_toggle){
    // Reset all
    payload.odo_code_a_cb = "no";
    payload.odo_code_b_cb = "no";
    payload.odo_code_c_cb = "no";

    switch(payload.odometer_toggle){
      case "a":
        payload.odo_code_a_cb = "yes";
        break;
      case "b":
        payload.odo_code_b_cb = "yes";
        break;
      case "c":
        payload.odo_code_c_cb = "yes";
        break;
      default:
    }
  }

  if(payload.vehicle_const_toggle){
    // Reset all
    payload.vehicle_const_special_cb = "no";
    payload.vehicle_const_reconstruct_cb = "no";

    switch(payload.vehicle_const_toggle){
      case "special":
        payload.vehicle_const_special_cb = "yes";
        break;
      case "reconstruct":
        payload.vehicle_const_reconstruct_cb = "yes";
        break;
      default:
    }
  }

  if(payload.trailer_plate_toggle){
    // Reset all
    payload.trailer_full_plate_cb = "no";
    payload.trailer_small_cb = "no";

    switch(payload.trailer_plate_toggle){
      case "full":
        payload.trailer_full_plate_cb = "yes";
        break;
      case "small":
        payload.trailer_small_cb = "yes";
        break;
      default:
    }
  }

  if(payload.legal_status_toggle){
    // Reset all
    payload.legal_status_or_cb = "no";
    payload.legal_status_and_cb = "no";
    payload.legal_status_and_or_cb = "no";

    switch(payload.legal_status_toggle){
      case "or":
        payload.legal_status_or_cb = "yes";
        break;
      case "and":
        payload.legal_status_and_cb = "yes";
        break;
      case "and_or":
        payload.legal_status_and_or_cb = "yes";
        break;
      default:
    }
  }

  if(payload.legal_status_2_toggle){
    // Reset all
    payload.ower_legal_status_or_cb = "no";
    payload.ower_legal_status_and_cb = "no";
    payload.ower_legal_status_and_or_cb = "no";

    switch(payload.legal_status_2_toggle){
      case "or":
        payload.ower_legal_status_or_cb = "yes";
        break;
      case "and":
        payload.ower_legal_status_and_cb = "yes";
        break;
      case "and_or":
        payload.ower_legal_status_and_or_cb = "yes";
        break;
      default:
    }
  }

  if(payload.legal_status_3_toggle){
    // Reset all
    payload.owner_legal_status_or_2_cb = "no";
    payload.owner_legal_status_and_2_cb = "no";
    payload.owner_legal_status_and_or_2_cb = "no";

    switch(payload.legal_status_3_toggle){
      case "or":
        payload.owner_legal_status_or_2_cb = "yes";
        break;
      case "and":
        payload.owner_legal_status_and_2_cb = "yes";
        break;
      case "and_or":
        payload.owner_legal_status_and_or_2_cb = "yes";
        break;
      default:
    }
  }

  if(payload.legal_status_4_toggle){
    // Reset all
    payload.owner_legal_status_or_3_cb = "no";
    payload.owner_legal_status_and_3_cb = "no";
    payload.owner_legal_status_and_or_3_cb = "no";

    switch(payload.legal_status_4_toggle){
      case "or":
        payload.owner_legal_status_or_3_cb = "yes";
        break;
      case "and":
        payload.owner_legal_status_and_3_cb = "yes";
        break;
      case "and_or":
        payload.owner_legal_status_and_or_3_cb = "yes";
        break;
      default:
    }
  }


  // X1.2- Field Type Specific Preview Formatting
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  // Data point formatting
  if(payload.credit_date){
    payload.credit_date = moment.utc(payload.credit_date).format("MM/DD/YY");
  }

  if(payload.first_reg_date){
    payload.first_reg_date = moment.utc(payload.first_reg_date).format("MM/DD/YY");
  }

  if(payload.reg_eff_date){
    payload.reg_eff_date = moment.utc(payload.reg_eff_date).format("MM/DD/YY");
  }

  if(payload.reg_exp_date){
    payload.reg_exp_date = moment.utc(payload.reg_exp_date).format("MM/DD/YY");
  }

  if(payload.owner_dob){
    payload.owner_dob = moment.utc(payload.owner_dob).format("MM/DD/YY");
  }

  if(payload.owner_dob_2){
    payload.owner_dob_2 = moment.utc(payload.owner_dob_2).format("MM/DD/YY");
  }

  if(payload.owner_dob_3){
    payload.owner_dob_3 = moment.utc(payload.owner_dob_3).format("MM/DD/YY");
  }

  if(payload.date_acquired){
    payload.date_acquired = moment.utc(payload.date_acquired).format("MM/DD/YY");
  }

  if(payload.lien_date){
    payload.lien_date = moment.utc(payload.lien_date).format("MM/DD/YY");
  }

  return payload;
}
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./Style/productmodal.css"
import { capitalizeFirstLetter } from "../../../../../../../utlis/formatters";
import { getOtherProductsInGroup } from "../../../../../../../utlis/dataSaveAndCopyLibrary";
import { useOrder, refreshOrder } from "../../../../../../../redux/orders/order"
import { saveProductSection, extractProperty } from "../../../../../../../utlis/dataSaveAndCopyLibrary";
import { updateGroupChangesArray, updateStatus, resetAll } from "../../../../../../../redux/saveAndCopy"

import States from './States';
import { useFetch} from "../../../../../../../utlis/api"
import { Autocomplete, TextField } from '@mui/material';
import { setActiveModal } from "../../../../../../../redux/app/appSaver";
import { matchAndGroupProducts } from "../../../../../../../utlis/dataSaveAndCopyLibrary";

function CopyChangesToProductsInGroupModal() {  
  const dispatch  = useDispatch();
  const order   = useOrder();
  const product = useSelector((state) => state?.copyChangesToDocumentsRedux.object);
  const axios     = useFetch()
  const appData = useSelector((state) => state?.appData);
  const saveStatus = useSelector((state) => state?.copyChangesToDocumentsRedux.status);

  const stateAbbrev = States.map(state => {
    return state.abbreviation;
  });

  const [copyStatus, setCopyStatus] = useState('pending');
  const [departments, setDepartments] = useState([]);
  const [departmentFetchStatus, setDepartmentFetchStatus] = useState('init');
  const [inputAddressVal, setinputAddressVal] = useState(undefined);
  const [groupProductsObject, setGroupProductsObject] = useState([]);


  function resetReduxSaver(){
    dispatch(resetAll());
  }

  function resetModal(resetReduxSaverVar=false){
    setCopyStatus('pending');
    setDepartments([]);
    setDepartmentFetchStatus('init');
    setinputAddressVal(undefined);
    setGroupProductsObject([]);
    dispatch(setActiveModal(null));
    if(resetReduxSaverVar){
      resetReduxSaver();
    }
  }

  const getDepartmentByCode = useCallback((code) => {
    let department = departments.find(department => department.salesDeptCode === code);
    return department;
  },[ departments ]);

  // Load Departments
  useEffect(() => {
    if(appData?.activeModal === 'copy-to-product-group'){


        if(departments.length === 0 && departmentFetchStatus === 'init' && order.tecLoctionCode){

          setDepartmentFetchStatus('fetching');
          axios
          .get(`/tec-location/${order.tecLoctionCode}/sales-departments`)
          .then((res) => {
            let returnedDepartments = res?.data.departments;
            let departmentsSorted = returnedDepartments.sort((a, b) => (a.description < b.description) ? 1 : -1);
            setDepartments(departmentsSorted);
            setDepartmentFetchStatus('fetched');
          }).catch((err) => {
          });
        }

      }

  },[ appData, axios, departmentFetchStatus, departments, order ]);

  const changesArray = useSelector((state) => state?.copyChangesToDocumentsRedux.changesArray);
  const saveFunction = useSelector((state) => state?.copyChangesToDocumentsRedux.saveFunction);
  // const copySection = useSelector((state) => state?.copyChangesToDocumentsRedux.copySection);
  const productSection = useSelector((state) => state?.copyChangesToDocumentsRedux.copySubSection);
  let otherProductsInGroup = getOtherProductsInGroup(order, product.id, product.groupId);


  // Loop changes array and build the groupProductsObject
  useEffect(() => {
    if(appData?.activeModal === 'copy-to-product-group'){

      if(groupProductsObject?.length === 0 && otherProductsInGroup?.length > 0 && Object.entries(changesArray)?.length > 0){
        otherProductsInGroup.forEach( oproduct => {
          Object.entries(changesArray).forEach(key => {

            // setGroupProductsObject( (prev) => {
            //   return {...prev, ...prev[oproduct.id]: { 
            //     ...prev,
            //     [key[0]]: { "newValue": key[1]?.newValue, "oldValue": oproduct[key[0]] },
            //     id: oproduct.id,
            //     stockNumber: oproduct.stockNumber
            //   }}
            // });

            // General Fields
            if(!key[0].includes('extendedWarranty') || !key[0].includes('gapInsurance')){

              setGroupProductsObject( (prev) => ({
                ...prev,
                [oproduct.id]: { 
                  ...prev[oproduct.id],
                  [key[0]]: {},
                  id: oproduct.id,
                  stockNumber: oproduct.stockNumber,
                  // propertySaved: false,
                  // docsSavedCount: 0,
                }
              }));

              setGroupProductsObject( (prev) => ({
                ...prev,
                [oproduct.id]: { 
                  ...prev[oproduct.id],
                  [key[0]]: { ...prev[oproduct.id][key[0]], "newValue": key[1]?.newValue, "oldValue": oproduct[key[0]] },
                  id: oproduct.id,
                  stockNumber: oproduct.stockNumber,
                  // propertySaved: false,
                  // docsSavedCount: 0,
                }
              }));

            }

            if(key[0].includes('extendedWarranty.enabled')){
              setGroupProductsObject( (prev) => ({
                ...prev,
                [oproduct.id]: { 
                  ...prev[oproduct.id],
                  [key[0]]: {},
                  id: oproduct.id,
                  stockNumber: oproduct.stockNumber
                }
              }));

              setGroupProductsObject( (prev) => ({
                ...prev,
                [oproduct.id]: { 
                  ...prev[oproduct.id],
                  [key[0]]: { ...prev[oproduct.id][key[0]], "newValue": key[1]?.newValue, "oldValue": oproduct?.extendedWarranty?.id ? "yes" : "no" },
                  id: oproduct.id,
                  stockNumber: oproduct.stockNumber
                }
              }));
            }

            if(key[0].includes('extendedWarranty') && !key[0].includes('extendedWarranty.enabled')){
              setGroupProductsObject( (prev) => ({
                ...prev,
                [oproduct.id]: { 
                  ...prev[oproduct.id],
                  [key[0]]: {},
                  id: oproduct.id,
                  stockNumber: oproduct.stockNumber
                }
              }));

              setGroupProductsObject( (prev) => ({
                ...prev,
                [oproduct.id]: { 
                  ...prev[oproduct.id],
                  [key[0]]: { ...prev[oproduct.id][key[0]], "newValue": key[1]?.newValue, "oldValue": oproduct?.extendedWarranty?.id ? oproduct.extendedWarranty[extractProperty('extendedWarranty', key[0])] : null },
                  id: oproduct.id,
                  stockNumber: oproduct.stockNumber
                }
              }));
            }

            // if(!key[0].includes('gapInsurance')){

            //   setGroupProductsObject( (prev) => ({
            //     ...prev,
            //     [oproduct.id]: { 
            //       ...prev[oproduct.id],
            //       [key[0]]: {},
            //       id: oproduct.id,
            //       stockNumber: oproduct.stockNumber
            //     }
            //   }));

            //   setGroupProductsObject( (prev) => ({
            //     ...prev,
            //     [oproduct.id]: { 
            //       ...prev[oproduct.id],
            //       [key[0]]: { ...prev[oproduct.id][key[0]], "newValue": key[1]?.newValue, "oldValue": oproduct[key[0]] },
            //       id: oproduct.id,
            //       stockNumber: oproduct.stockNumber
            //     }
            //   }));

            // }

            if(key[0].includes('gapInsurance.enabled')){
              setGroupProductsObject( (prev) => ({
                ...prev,
                [oproduct.id]: { 
                  ...prev[oproduct.id],
                  [key[0]]: {},
                  id: oproduct.id,
                  stockNumber: oproduct.stockNumber
                }
              }));

              setGroupProductsObject( (prev) => ({
                ...prev,
                [oproduct.id]: { 
                  ...prev[oproduct.id],
                  [key[0]]: { ...prev[oproduct.id][key[0]], "newValue": key[1]?.newValue, "oldValue": oproduct?.gapInsurance?.id ? "yes" : "no" },
                  id: oproduct.id,
                  stockNumber: oproduct.stockNumber
                }
              }));
            }

            if(key[0].includes('gapInsurance') && !key[0].includes('gapInsurance.enabled')){
              setGroupProductsObject( (prev) => ({
                ...prev,
                [oproduct.id]: { 
                  ...prev[oproduct.id],
                  [key[0]]: {},
                  id: oproduct.id,
                  stockNumber: oproduct.stockNumber
                }
              }));

              setGroupProductsObject( (prev) => ({
                ...prev,
                [oproduct.id]: { 
                  ...prev[oproduct.id],
                  [key[0]]: { ...prev[oproduct.id][key[0]], "newValue": key[1]?.newValue, "oldValue": oproduct?.gapInsurance?.id ? oproduct.gapInsurance[extractProperty('gapInsurance', key[0])] : null },
                  id: oproduct.id,
                  stockNumber: oproduct.stockNumber
                }
              }));
            }

          });
        });


      }

    }

  }, [product, groupProductsObject, otherProductsInGroup, changesArray, appData?.activeModal]);


  const preSaveDataPoint = async (e, productId, propName) => {
    e.preventDefault();

    let { value } = e?.target;

    // setSaveDetails( (prev) => ({
    //   ...prev,
    //   [productId]:{propName: value}
    // }));


    setGroupProductsObject( (prev) => ({
      ...prev,
      [productId]: {
        ...prev[productId], 
        [propName]: { ...prev[productId][propName], "newValue": value },
        id: prev[productId].id,
        stockNumber: prev[productId].stockNumber,
        // propertySaved: false,
        // docsSavedCount: 0,
      }
    }));

  }

  // const groupChangesArray = useSelector((state) => state?.copyChangesToDocumentsRedux.groupChangesArray);


  const confirmChanges = async (e) => {
    e.preventDefault();

    console.log('confirmChanges', groupProductsObject, productSection, saveFunction)

    dispatch(updateStatus('saving-group'));

    var otherProductsInGroup = getOtherProductsInGroup(order, product.id, product.groupId);
    if(otherProductsInGroup?.length > 0){

      //Loop through and apply updates to other products in the group.
      var saveProductGroupPromise = new Promise(async (resolve) => {
        otherProductsInGroup.forEach( (oproduct, index) => {
          console.log('confirmChanges update group products promise loop', oproduct, index, order, oproduct, productSection, groupProductsObject[oproduct.id], saveFunction)
          // Advanced save function to handle each product section correctly.
          saveProductSection(e, order, oproduct, productSection, groupProductsObject[oproduct.id], saveFunction, () => {
          });
          if (index === otherProductsInGroup.length -1) resolve();
        });
      });

      saveProductGroupPromise.then( () => {
        console.log('confirmChanges saveProductGroupPromise then..', changesArray)

        // Copy the persist changes array and add id, stocknumber to it.
        let copyOriginalProductChangeArray = JSON.parse(JSON.stringify(changesArray));
        copyOriginalProductChangeArray.id = product.id;

        if(!product.stockNumber){
          let foundProduct = order.products.find(p => p.id === product.id);
          if(foundProduct){
            copyOriginalProductChangeArray.stockNumber = product.stockNumber;
          }
        }else{
          copyOriginalProductChangeArray.stockNumber = product.stockNumber;
        }
        copyOriginalProductChangeArray.propertySaved = false;
        copyOriginalProductChangeArray.docsSavedCount = 0;

        // Copy the groupProductArray
        let copyGroupProductsObject = JSON.parse(JSON.stringify(groupProductsObject));

        Object.entries(copyGroupProductsObject).forEach( (oproduct, index) => {
          oproduct["propertySaved"] = false;
          oproduct["docsSavedCount"] = 0;
        });


        // Add the updated original product array to the group array.
        copyGroupProductsObject[copyOriginalProductChangeArray.id] = [];
        copyGroupProductsObject[copyOriginalProductChangeArray.id] = copyOriginalProductChangeArray;
        dispatch(updateGroupChangesArray(copyGroupProductsObject));
        // matchAndGroupProducts(order, () => {
        //   dispatch(refreshOrder(order.id, true))
        // });
      });

    }

    dispatch(updateStatus('saved-group'));
    setCopyStatus('copied');
  }

  return (
    <>
      <div
        className="modal fade"
        id="copychangestogroupmodal"
        tabIndex="-1"
        aria-labelledby="copychangestogroupmodal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
        { appData?.activeModal === 'copy-to-product-group' ? (
          <>
            <div className="modal-content">
              <div className="modal-header bg-primary text-white">
                <h5
                  className="modal-title"
                  id="exampledownpaymentmodel"
                >
                  Confirm -  Changes to product group
                </h5>
                <button
                  type="button"
                  className="btn-close bg-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    resetModal(true);
                    matchAndGroupProducts(order, () => {
                      dispatch(refreshOrder(order.id, true))
                    });
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <div className="container">
                  <table style={{ width: '100%' }} key={groupProductsObject?.length}>
                    <tr className="text-center mx-auto">
                      <th style={{ borderRight: '1px solid #000' }}>Stock #</th>
                      { changesArray && Object.entries(changesArray).length > 0 ? Object.entries(changesArray).map(key => {
                        return (
                          <>
                            {  !key[0].includes('fobAddress') && !key[0].includes('extendedWarranty') && !key[0].includes('gapInsurance') && !key[0].includes('addition') && !key[0].includes('upgrade')  && !key[0].includes('deposit') ? (
                              <th>{ capitalizeFirstLetter( key[0] )}</th>
                            ):null}

                            { key[0].includes('extendedWarranty.enabled') ? (
                              <th>Extended Warranty</th>
                            ):null}
                            
                            { key[0].includes('extendedWarranty') && !key[0].includes('extendedWarranty.enabled') ? (
                              <th>Extended Warranty { capitalizeFirstLetter(extractProperty("extendedWarranty", key[0]))  }</th>
                            ):null}

                            { key[0].includes('gapInsurance.enabled') ? (
                              <th>GAP Waiver</th>
                            ):null}
                            
                            { key[0].includes('gapInsurance') && !key[0].includes('gapInsurance.enabled') ? (
                              <th>GAP Waiver { capitalizeFirstLetter(extractProperty("gapInsurance", key[0]))  }</th>
                            ):null}

                            { key[0].includes('fobAddress') ? (
                              <th>FOB { capitalizeFirstLetter(extractProperty("fobAddress", key[0]))  }</th>
                            ):null}
                          </>
                        )
                      }):null}
                    </tr>
                    <tr style={{ color: 'green', borderBottom: '2px solid #000'}} className="text-center mx-auto">
                      <th style={{ borderRight: '1px solid #000' }}>{ product?.stockNumber }</th>
                      { changesArray && Object.entries(changesArray).length > 0 ? Object.entries(changesArray).map(key => {
                        return (
                          <>
                            {!key[0].includes('fobAddress') && !key[0].includes('extendedWarranty') && !key[0].includes('gapInsurance') && !key[0].includes('addition') && !key[0].includes('upgrade')  && !key[0].includes('deposit') ? (
                              <td>{ key[1].newValue }</td>
                            ):null}

                            { key[0].includes('extendedWarranty.enabled') ? (
                              <td>{ capitalizeFirstLetter( key[1].newValue ) }</td>
                            ):null}
                            
                            { key[0].includes('extendedWarranty') && !key[0].includes('extendedWarranty.enabled') ? (
                              <td>{ key[1].newValue }</td>
                            ):null}

                            { key[0].includes('gapInsurance.enabled') ? (
                              <td>{ capitalizeFirstLetter( key[1].newValue ) }</td>
                            ):null}
                            
                            { key[0].includes('gapInsurance') && !key[0].includes('gapInsurance.enabled') ? (
                              <td>{ key[1].newValue }</td>
                            ):null}

                            { key[0].includes('fobAddress') ? (
                              <td>{ key[1].newValue }</td>
                            ):null}
                          </>
                        )
                      }):null}
                    </tr>

                  { groupProductsObject && Object.entries(groupProductsObject).length > 0 ? Object.entries(groupProductsObject).map(key => {
                    return (
                      <>
                        <tr className="text-center mx-auto" style={{ borderBottom: '1px solid #000' }}>
                          <td style={{ borderRight: '1px solid #000' }}>{ key[1].stockNumber }</td>
                            { key[1] && Object.entries(key[1]).length > 0 ? Object.entries(key[1]).map(xey => {
                              return (
                                <>
                                  { xey[0] !== 'id' && xey[0] !== 'stockNumber' ? (
                                    <>
                                      {!xey[0].includes('fobAddress.state') && !xey[0].includes('shipTo.state') && !xey[0].includes('soldTo.state') && !xey[0].includes('stateRegistered') && !xey[0].includes('tecSalesDepartmentCode') && !xey[0].includes('extendedWarranty') && !xey[0].includes('gapInsurance') && !xey[0].includes('addition') && !xey[0].includes('upgrade')  && !xey[0].includes('deposit') ? (
                                        <>
                                          <td className="text-center mx-auto" style={{ paddingTop: '16px' }}><input disabled={saveStatus.includes('-group')} onChange={(e) => preSaveDataPoint(e,  key[0], xey[0])} className="form-control" style={{ maxWidth: '150px', minWidth: '75px', width: 'content', display: 'inline-table' }} value={xey[1].newValue} />
                                          <br />
                                          <p style={{ fontSize: '14px', fontStyle: 'italic' }}>{xey[1].oldValue}</p></td>
                                        </>
                                        ) : null}

                                      { xey[0].includes('extendedWarranty.enabled') ? (
                                        <>
                                          <div className="custom-control custom-checkbox checkbox-lg">
                                          <br />
                                            <input
                                              type="checkbox"
                                              style={{padding:'10px'}}
                                              aria-label='product extended warranty checkbox'
                                              className="custom-control-input-group-lg"
                                              checked={xey[1].newValue === "yes" ? true : false}
                                              onChange={(e) => {
                                                let { checked } = e.target;
                                                key[1].newValue = checked ;
                                              }}
                                              />                
                                          </div>
                                        </>
                                      ) : null}

                                      { xey[0].includes('extendedWarranty') && !xey[0].includes('extendedWarranty.enabled') ? (
                                        <>
                                          <td className="text-center mx-auto" style={{ paddingTop: '16px' }}><input disabled={key[1]["extendedWarranty.enabled"] === "no"} onChange={(e) => preSaveDataPoint(e,  key[0], xey[0])} className="form-control" style={{ maxWidth: '150px', minWidth: '75px', width: 'content', display: 'inline-table' }} value={xey[1].newValue} />
                                          <br />
                                          <p style={{ fontSize: '14px', fontStyle: 'italic' }}>{xey[1].oldValue}</p></td>
                                        </>
                                      ):null}

                                      { xey[0].includes('gapInsurance.enabled') ? (
                                        <>
                                          <div className="custom-control custom-checkbox checkbox-lg">
                                          <br />
                                            <input
                                              type="checkbox"
                                              style={{padding:'10px'}}
                                              aria-label='product extended warranty checkbox'
                                              className="custom-control-input-group-lg"
                                              checked={xey[1].newValue === "yes" ? true : false}
                                              onChange={(e) => {
                                                let { checked } = e.target;
                                                key[1].newValue = checked ;
                                              }}
                                              />                
                                          </div>
                                        </>
                                      ) : null}

                                      { xey[0].includes('gapInsurance') && !xey[0].includes('gapInsurance.enabled') ? (
                                        <>
                                          <td className="text-center mx-auto" style={{ paddingTop: '16px' }}><input disabled={key[1]["gapInsurance.enabled"] === "no"} onChange={(e) => preSaveDataPoint(e,  key[0], xey[0])} className="form-control" style={{ maxWidth: '150px', minWidth: '75px', width: 'content', display: 'inline-table' }} value={xey[1].newValue} />
                                          <br />
                                          <p style={{ fontSize: '14px', fontStyle: 'italic' }}>{xey[1].oldValue}</p></td>
                                        </>
                                      ):null}

                                      
                                      { xey[0].includes('state') ? (
                                        <>
                                          <td className="text-center mx-auto" style={{ paddingTop: '16px' }}>
                                            <br />
                                            <Autocomplete
                                              value={xey[1].newValue ?? ''}
                                              aria-label='state registered'
                                              onChange={(e, newValue) => {
                                                xey[1].newValue = newValue ;
                                              }}
                                              options={stateAbbrev}
                                              sx={{ width: 230}}
                                              renderInput={(params) => <TextField {...params} label="STATE REGISTERED" />}
                                              />
                                              <br />
                                            <p style={{ fontSize: '14px', fontStyle: 'italic' }}>{xey[1].oldValue}</p>    
                                          </td>
                                        </>
                                      ) : null}

                                      { xey[0].includes('fobAddress.state') ? (
                                        <>
                                          <td className="text-center mx-auto" style={{ paddingTop: '16px' }}>
                                            <br />
                                            <Autocomplete
                                              value={xey[1].newValue ?? ''}
                                              aria-label='fob state'
                                              onChange={(e, newValue) => {
                                                xey[1].newValue = newValue ;
                                              }}
                                              options={stateAbbrev}
                                              sx={{ width: 230}}
                                              renderInput={(params) => <TextField {...params} label="FOB STATE" />}
                                              />
                                              <br />
                                            <p style={{ fontSize: '14px', fontStyle: 'italic' }}>{xey[1].oldValue}</p>    
                                          </td>
                                        </>
                                      ) : null}

                                      { xey[0].includes('shipTo.state') ? (
                                        <>
                                          <td className="text-center mx-auto" style={{ paddingTop: '16px' }}>
                                            <br />
                                            <Autocomplete
                                              value={xey[1].newValue ?? ''}
                                              aria-label='ship to state'
                                              onChange={(e, newValue) => {
                                                xey[1].newValue = newValue ;
                                              }}
                                              options={stateAbbrev}
                                              sx={{ width: 230}}
                                              renderInput={(params) => <TextField {...params} label="SHIP TO STATE" />}
                                              />
                                              <br />
                                            <p style={{ fontSize: '14px', fontStyle: 'italic' }}>{xey[1].oldValue}</p>    
                                          </td>
                                        </>
                                      ) : null}

                                      { xey[0].includes('soldTo.state') ? (
                                        <>
                                          <td className="text-center mx-auto" style={{ paddingTop: '16px' }}>
                                            <br />
                                            <Autocomplete
                                              value={xey[1].newValue ?? ''}
                                              aria-label='sold to state'
                                              onChange={(e, newValue) => {
                                                xey[1].newValue = newValue ;
                                              }}
                                              options={stateAbbrev}
                                              sx={{ width: 230}}
                                              renderInput={(params) => <TextField {...params} label="SOLD TO STATE" />}
                                              />
                                              <br />
                                            <p style={{ fontSize: '14px', fontStyle: 'italic' }}>{xey[1].oldValue}</p>    
                                          </td>
                                        </>
                                      ) : null}

                                      { xey[0].includes('tecSalesDepartmentCode') && departments?.length > 0 ? (
                                        <>
                                          <td className="text-center mx-auto" style={{ paddingTop: '16px' }}>
                                            <br />
                                            <Autocomplete
                                              value={xey[1].newValue}
                                              aria-label='tec department'
                                              getOptionLabel={ department => {
                                                return department.description }}
                                              onChange={(e, newValue) => {
                                                xey[1].newValue = newValue ;
                                              }}
                                              inputValue={inputAddressVal}
                                              onInputChange={(event, newInputValue) => {
                                                setinputAddressVal(newInputValue);
                                              }}
                                              options={departments??[]}
                                              sx={{ width: 230}}
                                              renderInput={(params) => <TextField {...params} label="DEPARTMENT" />}
                                              renderOption={(props, option) => {
                                                return (
                                                  <li {...props} key={option.salesDeptCode}>
                                                    {option.description}
                                                  </li>
                                                );
                                              }}
                                              />              
                                            <p style={{ fontSize: '14px', fontStyle: 'italic' }}>{getDepartmentByCode(xey[1].newValue)?.description}</p>    
                                          </td>
                                        </>
                                      ) : null}

                                    </>
                                  ):null}

                                </>
                              )
                            }):null}
                        </tr>
                      </>
                    )
                  }):null}

                  </table>
                </div>
              </div>
              <div className="modal-footer text-center">
                <div className="mx-auto">

                  { copyStatus === 'pending' ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-primary mx-3"
                        onClick={(e) => confirmChanges(e)}
                        disable={saveStatus.includes('-group')}
                        // data-bs-dismiss="modal"
                        data-bs-target="#productModal"
                      >
                        Confirm
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary mx-3"
                        disable={saveStatus.includes('-group')}
                        data-bs-dismiss="modal"
                        onClick={() => {
                          resetModal(true);
                          matchAndGroupProducts(order, () => {
                            dispatch(refreshOrder(order.id, true))
                          });
                        }}
                      >
                        Cancel
                      </button>
                    </>
                  ): copyStatus === 'copied' ? (
                    <>
                      <button aria-label='Copy to Documents' 
                      onClick={ (e)=>{
                        e.preventDefault();
                        dispatch(updateStatus('copy'));
                        dispatch(setActiveModal('copy-to-documents'));
                        matchAndGroupProducts(order, () => {
                          dispatch(refreshOrder(order.id, true))
                        });
                        // resetModal(false);
                      }} data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#copychangestodocuments" className="my-2 btn  btn-block btn-sm btn-primary text-uppercase mx-2">Copy to Documents</button><br />
                    </>
                  ):null}

                  {/* { saveStatus === 'saved-group' ? (
                  <button aria-label=' To Documents' 
                    onClick={ (e)=>{
                      e.preventDefault();
                      dispatch(updateSaveStatus("init"));
                      dispatch(updateDocumentModalIsOpen(true));
                    }} data-bs-dismiss="modal" className="my-2 btn btn-sm btn-block btn-primary text-uppercase mx-2"> To Documents >></button>
                  ):null} */}
                </div>
              </div>
            </div>
          </>
        ):null}
        </div>
      </div>
    </>
  );
}

export default CopyChangesToProductsInGroupModal;

import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Navbar/Navbar";
import FinanceBookedDetails from "./components/FinanceBookedDetails";
import FinanceBookedHeader from "./components/FinanceBookedHeader";
import FinanceCurrentOrders from "./components/FinanceCurrentOrders";
import FinanceRequest from "./components/FinanceRequest";
import { useDispatch} from "react-redux";
import { setShowDashboardSpinner } from "../../../redux/spinners/spinners";
import "./components/Styles/financeDashboard.css";
import SettingsModal from "./SettingsModal";
import { LinearProgress,Box } from "@mui/material";

const FinanceDashboard = () => {

  const [ statsLoaded, setStatsLoaded ]                 = useState(false);
  const [ initFinanceRequests, setInitFinanceRequests ] = useState(true);
  const [ trainingOverride, setTrainingOverride ]       = useState(false);
  const [ isLoading, setIsLoading ]                     = useState(false)

  const dispatch              = useDispatch();

  //Effect to load stats when the component mounts...
  useEffect(() => {
      dispatch(setShowDashboardSpinner(true));

      setStatsLoaded(true);
      setIsLoading(true)
      setTimeout(setIsLoading(false),3000)

  }, [dispatch, statsLoaded])

  return (
    <>
      <Navbar title="Finance Dashboard" setTrainingOverride={setTrainingOverride} />

      <div>
        <SettingsModal />
      </div>

      
      <div className=" bg-color ms-4 rounded-start py-2">

        { isLoading ? ( 
          <div
            style={{
              display: "flex",
              width: "100vw",
              height:'50vh',
              justifyContent: "center",
            }}
          >
            <Box width='50%' style={{placeSelf:'center'}}>
              <LinearProgress />
            </Box>
          </div>
        ):(
          <div className="p-2">
            <FinanceBookedHeader  setInitFinanceRequests={setInitFinanceRequests} trainingOverride={trainingOverride} setTrainingOverride={setTrainingOverride} />
            <FinanceBookedDetails />
            <FinanceCurrentOrders />
          </div>
        )
        }
      </div>
      <div className="ms-4 ">
        <FinanceRequest initFinanceRequests={initFinanceRequests} setInitFinanceRequests={setInitFinanceRequests} loadFinanceRequests={true} />
      </div>
      {/* )} */}
    </>
  );
};

export default FinanceDashboard;

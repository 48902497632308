import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
// import {salesPriceWithUpgradeCalc} from "../../../../../../../utlis/productCalculations";
import { removeChars, formatter, formatPhoneNumber, formatUpperCase, getAreaCode, getPhoneNumberAlt } from "../../../../../../../utlis/formatters";
import { getOrderLevelAddresses, updateAddressOptions } from "../../../../../../../utlis/documentHelpers";
import { useOrder } from "../../../../../../../redux/orders/order"
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { CADeliveryStatementGlobalAutofill, CADeliveryStatementAutofillProductDetails, preProcessPayload } from "../DocumentAutofill/CADeliveryStatement"


const CADeliveryStatement = ({document_id, location, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "ca-delivery-statement";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  // const dispatch = useDispatch();

  // const stateAbbrev = States.map(state => {
  //   return state.abbreviation;
  // });

  const order = useOrder();

  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);

  // Data Structure & Init //
  var initialDocumentStructure = {
    county: undefined,
    purchaser: undefined,
    year: undefined,
    make: undefined,
    model: undefined,
    vin: undefined,
    purchase_price: undefined,
    purchase_date: undefined,
    delivery_address: undefined,
    delivery_city: undefined,
    delivery_state: undefined,
    delivery_zip: undefined,
    phone: undefined,
    cdtfa_permit_number: undefined,
    dealer_name: undefined,
    dealer_address: undefined,
    dealer_city: undefined,
    dealer_state: undefined,
    dealer_zip: undefined,
    dealer_seller_name: undefined,
    salesperson_cb: undefined,
    employee_cb: undefined,
    partner_cb: undefined,
    other_cb: undefined,
    delivery_date: undefined,
    purchaser_name: undefined,
    purchase_id_number: undefined,
    purchaser_state: undefined,
    date: undefined,
    area_code: undefined,
    purchaser_area_code: undefined,
    purchaser_phone: undefined,
    notary_date: undefined,
    notary_name: undefined,
    state: undefined,
    seller: undefined,
    seller_type: undefined, // Radio Set + Explainer
    other_explain: undefined
  }
  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);

  // X1
  const removeFormatting = e =>{
    const {name,value}=e.target;
    setDocumentValues({...documentValues,[name]:removeChars(value)});
  }

  const moneyFields = useMemo(()=>{return["purchase_price"]},[]);
  const phoneFields = useMemo(()=>{return[]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "model"]},[]);
  const checkboxes = useMemo(()=>{return[]},[]);
  // const radios = ["seller_type"];


  // X1- Current General On Change
  const handleDocumentValuesChange = e =>{
    e.preventDefault();
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatPhoneNumber(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    // }else if(radios.indexOf(name) >= 0){
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  
  const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('CADeliveryStatement.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, documentLoaded, loadDocument ])
  const [assignedProduct, setAssignedProduct] = useState(undefined);
  // const [productVal, setproductVal] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  // const [tradeinVal, setTradeinVal] = useState(undefined);
  // const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  // const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const [addressOptions, setAddressOptions] = useState(undefined);
  const [inputAddressVal, setinputAddressVal] = useState(undefined);

  const [locationVal, setLocationVal] = useState(undefined);
  const [selectedLocation, setselectedLocation] = useState(undefined);
  const [inputLocationVal, setinputLocationVal] = useState(undefined);
  const [locationOptions, setLocationOptions] = useState(undefined);
  const [locationToggleAutoFill, setLocationToggleAutoFill] = useState(false);

// Dropdowns //

  // if(order && order.products && order.products.length > 0 && productOptions === undefined){
  //   let getProductOptions = [];
  //   order.products.map(product => {
  //     let str = `${product.stockNumber.toString().toUpperCase()}`;
  //     let obj = {id: product.id, name: str}
  //     getProductOptions.push(obj);
  //   })
  //   setproductOptions(getProductOptions);
  // }

  // if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
  //   let getTradeinOptions = [];
  //   order.tradeins.map(tradein => {
  //     let str = `${tradein.year} ${tradein.make} ${tradein.model.toString().toUpperCase()}`;
  //     let obj = {id: tradein.id, name: str}
  //     getTradeinOptions.push(obj);
  //   })
  //   setTradeinOptions(getTradeinOptions);
  // }

  if(locations && locations.length > 0 && locationOptions === undefined){
    let getLocationOptions = [];
    locations.map(location => {
      let obj = {locationCode: location.locationCode, description: location.description}
      getLocationOptions.push(obj);
      return null;
    })
    setLocationOptions(getLocationOptions);
  }

  // Autofill //

  const GlobalAutofill = useCallback(async () => {

    const foundAssignedProduct = order?.products.find((product) => product?.id === document?.assignedObjectId);
    //if FOB address exists, set it as default for autofill, otherwise keep default as delivery address
    const defaultAddress = foundAssignedProduct?.fobAddress?.addressLine1 ? foundAssignedProduct?.fobAddress : null;

    let newValues = CADeliveryStatementGlobalAutofill(documentValues, order, null, null, location);
    
    if(defaultAddress !== null){
      let streetAddress;

      if(defaultAddress?.addressLine2){
        streetAddress = (defaultAddress?.addressLine1+" "+defaultAddress.addressLine2);
      }else{
        streetAddress = defaultAddress?.addressLine1;
      }

      setSelectedAddress({slug: "fob", name: "FOB Address", type: order.customer.type, label: "FOB Address", address: streetAddress, city: defaultAddress?.city, state: defaultAddress?.state, zip: defaultAddress?.zip});

      newValues.delivery_address = streetAddress;
      newValues.delivery_city = defaultAddress?.city;
      newValues.delivery_state = defaultAddress?.state;
      newValues.delivery_zip = defaultAddress?.zip;

      newValues.owner_city_2 = defaultAddress.city;
      newValues.owner_state_2 = defaultAddress.state;
      newValues.owner_zip_2 = defaultAddress.zip;

      
    }

    if(newValues){
      setDocumentValues(newValues);
      setHasUnsavedChanges(true);
      setSaveSuccess(false);
    }
    
    // var newValues = documentValues;
    // // if(order && order.customer && order.customer.name){
    // //   newValues.purchaser_name = order.customer.name;
    // // }

    // if(location){
    //   if(location.state && location.state === "CA"){
    //     newValues.dealer_name = "TEC OF CALIFORNIA, INC.";
    //   }else{
    //     newValues.dealer_name = "TEC EQUIPMENT, INC.";
    //   }
    //   newValues.dealer_address = location.address;
    //   newValues.dealer_city = location.city;
    //   newValues.dealer_state = location.state;
    //   newValues.dealer_zip = location.zip;
    //   newValues.area_code = getAreaCode(location.phone);
    //   newValues.phone = getPhoneNumberAlt(location.phone);

    //   if(location.state && location.state === "CA"){
    //     newValues.cdtfa_permit_number = "91-1757479";
    //   }
    // }

    



    // if(order && order.customer){
    //   newValues.purchaser_name = order.customer.name;
    //   newValues.purchaser_area_code = getAreaCode(order.customer.phone)
    //   newValues.purchaser_phone = getPhoneNumber(order.customer.phone)
    //   if(order.customer.dba && order.customer.dba !== ""){
    //     newValues.purchaser_name += " / " + order.customer.dba;
    //   }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
    //     let cosigner = order.customer.cosigners[0];
    //     if(cosigner && cosigner.name){
    //         newValues.purchaser_name += " AND "+cosigner.name;
    //     }
    //   }
    // }

    // // if(order && order.documentationDate && documentValues.delivery_date === undefined){
    // //   newValues.delivery_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    // // }

    // setDocumentValues(newValues);
    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  },[document?.assignedObjectId, documentValues, location, order])


  // const preProcessPayload = useCallback((payload) => {

  //   if(payload.seller_type){

  //     // Reset all
  //     payload.salesperson_cb = "no";
  //     payload.employee_cb = "no";
  //     payload.partner_cb = "no";
  //     payload.other_cb = "no";

  //     switch(payload.seller_type){
  //       case "salesperson_cb":
  //         payload.salesperson_cb = "yes";
  //         break;
  //       case "employee_cb":
  //         payload.employee_cb = "yes";
  //         break;
  //       case "partner_cb":
  //         payload.partner_cb = "yes";
  //         break;
  //       case "other_cb":
  //         payload.other_cb = "yes";
  //         break;
  //       default:
  //     }

  //     if(payload.seller_type !== "other_cb"){
  //       payload.other_explain = "";
  //     }

  //     payload.date = moment.utc(payload.date).format("MM/DD/YY");
  //   }

  //   // X1.2- Field Type Specific Preview Formatting
  //   Object.keys(payload).forEach(key => {
  //     if(moneyFields.indexOf(key) >= 0){
  //         payload[key] = formatMoneyValue(payload[key]);
  //     }else if(phoneFields.indexOf(key) >= 0){
  //         payload[key] = formatPhoneNumber(payload[key]);
  //     }else if(upperCaseFields.indexOf(key) >= 0){
  //         payload[key] = formatUpperCase(payload[key]);
  //     }
  //   });
  //   setDocumentValues(JSON.parse(JSON.stringify(payload)));
  //   Object.keys(payload).forEach(key => {
  //     if(checkboxes.indexOf(key) >= 0){
  //       payload[key] = payload[key] ? "yes": "no";
  //     }
  //   });

  //   // Data point formatting
  //   if(payload.purchase_date){
  //     payload.purchase_date = moment.utc(payload.purchase_date).format("MM/DD/YY");
  //   }
  
  //   if(payload.delivery_date){
  //     payload.delivery_date = moment.utc(payload.delivery_date).format("MM/DD/YY");
  //   }

  //   if(payload.date){
  //     payload.date = moment.utc(payload.date).format("MM/DD/YY");
  //   }
    
  //   return payload;
  // },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])


  const previewPayloadDocument = useCallback(async (documentValues) => {
    var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });
    

    if(document){
      var docCopy = JSON.parse(JSON.stringify(document));
      docCopy.payload =  JSON.parse(JSON.stringify(payload));
      docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
      docCopy.payload =  JSON.stringify(docCopy.payload);
      delete docCopy.documentStatus;
      
      try {
        let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
          const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
          setdocumentUrl(fileURL)
      } catch (err) {
        recordFrontEndError('CADeliveryStatement.jsx', 'Error previewing document payload: ' + err)
      }
    }
  },[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])

  // const calcAZandCASalesTax = useCallback(async (product) => {

  //   // Base Price
  //   let basePrice = salesPriceWithUpgradeCalc(product);
  //   let adminFee = order.adminFee ? order.adminFee : 0;
  //   let docFee = 0;
  //   if(product.lineItemFee){
  //     docFee = product.lineItemFee.docFee ? product.lineItemFee.docFee : 0;
  //   }
  //   return Number(basePrice)+Number(adminFee)+Number(docFee);

  // },[ order ]);

  const AutofillProductDetails = useCallback(async (selectedProduct) => {
    // var newValues = documentValues;

    // if((location.state && (location.state === "CA" || location.state === "AZ")) || (order && order.customer && order.customer.deliveryAddress && order.customer.deliveryAddress.state === "CA")){
    //   newValues.purchase_price = formatter.format(calcAZandCASalesTax(selectedProduct));
    // }

    let newValues = CADeliveryStatementAutofillProductDetails(selectedProduct, documentValues, null, null, location, order);

    if(newValues){
      setDocumentValues(newValues);
      previewPayloadDocument(documentValues);
      setHasUnsavedChanges(true);
      if(document){
        previewPayloadDocument(documentValues);
      }
      setSaveSuccess(false);
    }

    // var newValues = documentValues;
    // newValues.year = (selectedProduct.year).trim();
    // newValues.make = (selectedProduct.make).trim();
    // newValues.model = (selectedProduct.model).trim().toString().toUpperCase();
    // newValues.stock_number = (selectedProduct.stockNumber);
    // newValues.mileage = (selectedProduct.mileage);
    
    // newValues.serial_number = (selectedProduct.serialNumber);
    // newValues.vin = (selectedProduct.vin);

    
    // newValues.sale_price = (selectedProduct.price);

    // if((location.state && (location.state === "CA" || location.state === "AZ")) || (order && order.customer && order.customer.deliveryAddress && order.customer.deliveryAddress.state === "CA")){
    //   newValues.purchase_price = formatter.format(calcAZandCASalesTax(selectedProduct));
    // }


    // setDocumentValues(newValues);
    // // setProductToggleAutoFill(false);
    // if(document){
    //   previewPayloadDocument(documentValues);
    // }
    // // setAddressOptions(updateAddressOptions(selectedProduct, order));
    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  },[document, documentValues, location, order, previewPayloadDocument]);

  // Document BREAD + Preview // TODO: Move to Redux
  useEffect(() => {
    if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){
  
      existingPayload = true;
  
      if(documentPayload.purchase_date){
        documentPayload.purchase_date = moment.utc(documentPayload.purchase_date).format("YYYY-MM-DD");
      }

      if(documentPayload.delivery_date){
        documentPayload.delivery_date = moment.utc(documentPayload.delivery_date).format("YYYY-MM-DD");
      }

      if(documentPayload.date){
        documentPayload.date = moment.utc(documentPayload.date).format("YYYY-MM-DD");
      }
  
      setDocumentValues(documentPayload);

      // Loading Use Effect
      if(document.additionalData){
        const methodMap = { 
          selectedAddress: setSelectedAddress, 
          selectedLocation: setselectedLocation, 
          locationVal: setLocationVal, 
        };

        let parsedAdditionalData = JSON.parse(document.additionalData);
        Object.keys(parsedAdditionalData).forEach(function(key) {
          if(methodMap[key]){
            methodMap[key](parsedAdditionalData[key]);
          }
        });
      }

    }

    var foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }

    setAddressOptions(getOrderLevelAddresses(order));
    if(foundAssignedProduct){
        setAssignedProduct(foundAssignedProduct);
        setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
    }
    
  
      if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
      }
      
      setDataLoaded(true);
    }
  },[ documentValues, document, dataLoaded, order, AutofillProductDetails, GlobalAutofill, previewPayloadDocument ])
  
  const updatePreview = e => {
    e.preventDefault();
    previewPayloadDocument(documentValues);
  }
  
  
  const handleSubmit = async event => {
    event.preventDefault();

    let payload = JSON.parse(JSON.stringify(documentValues));

    payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);

    //Save Dropdown Selections
    let dropdownData = {};
    if(selectedAddress){
      dropdownData.selectedAddress = {
        id: selectedAddress.id,
        name: selectedAddress.name
      };
    }
    if(selectedLocation){
      dropdownData.selectedLocation = {
        locationCode: selectedLocation.locationCode,
        description: selectedLocation.description
      };
    }
    if(locationVal){
      dropdownData.locationVal = {
        locationCode: locationVal.locationCode,
        description: locationVal.description
      };
    }

    let additionalData = JSON.stringify(dropdownData);

    DocumentHandleSubmit(document, payload, additionalData, () => {
      getDocuments();
      setSaveSuccess(true);
      setHasUnsavedChanges(false);
    });

    // docCopy.additionalData = JSON.stringify(dropdownData);
    // try {
    //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
    //   if (res) {
    //     axios.post(`/document/${document.id}/generate`)
    //     getDocuments();
    //     setSaveSuccess(true);
    //     setHasUnsavedChanges(false);
    //   }
    // } catch (err) {
    //   recordFrontEndError('CADeliveryStatement.jsx', 'Error saving document: ' + err)
    // }
  }

  // function calcAZandCASalesTax(product){


// Component Helpers //
// useEffect(() => {
  //Autofill Product Details After Selection

// },[ selectedProduct, productToggleAutoFill ])

// useEffect(() => {
//   //Autofill Tradein Details After Selection
//   if(selectedTradein !== undefined && tradeinToggleAutoFill){
//     var newValues = documentValues;
//     newValues.trade_year = (selectedTradein.year);
//     newValues.trade_make = (selectedTradein.make);
//     newValues.trade_model = (selectedTradein.model);
//     newValues.trade_body_type = (selectedTradein.seriesBodyType);
//     newValues.trade_serial_number = (selectedTradein.serialNumber);
//     newValues.balance_owner = (order.customer.name); //Need specific field for TRADE IN OWNER?
//     newValues.balance_owner_address = (order.customer.deliveryAddress.addressLine1);
//     newValues.amount = (selectedTradein.balanceOwed);
//     newValues.trade_allowance = (selectedTradein.tradeAllowanceAtTerms);
//     newValues.balance_owed = (selectedTradein.balanceOwed);
//     newValues.cash_refund = (selectedTradein.cashRefundToCustomer);
//     newValues.net_trade_allowance = (selectedTradein.finalTradeAllowance);
//     // newValues.cash_down_payment = (selectedTradein);

//     setDocumentValues(newValues);
//     setTradeinToggleAutoFill(false);
//     if(document){
//       previewPayloadDocument(documentValues);
//     }
//   }
// },[ selectedTradein, tradeinToggleAutoFill ])

useEffect(() => {
  //Autofill Tradein Details After Selection
  if(selectedLocation !== undefined && locationToggleAutoFill){
    var newValues = documentValues;
    newValues.dealer_address = selectedLocation.address;
    newValues.dealer_city = selectedLocation.city;
    newValues.dealer_state = selectedLocation.state;
    newValues.dealer_zip = selectedLocation.zip;
    newValues.area_code = getAreaCode(selectedLocation.phone);
    newValues.phone = getPhoneNumberAlt(selectedLocation.phone);
    // newValues.seller_phone = formatPhoneNumber(selectedLocation.phone);

    if(selectedLocation.state === "CA"){
      newValues.dealer_name = "TEC OF CALIFORNIA, INC";
    }else{
      newValues.dealer_name = "TEC EQUIPMENT, INC.";
    }

    if(selectedLocation.state && selectedLocation.state === "CA"){
      newValues.cdtfa_permit_number = "91-1757479";
    }

    setHasUnsavedChanges(true);
    setSaveSuccess(false);
    setDocumentValues(newValues);
    setLocationToggleAutoFill(false);
    if(document){
      previewPayloadDocument(documentValues);
    }
  }
},[ selectedLocation, locationToggleAutoFill, document, documentValues, previewPayloadDocument ])

  //Autofill Address Details After Selection
  function AutoFillAddress(addressSelected, selectionInstance){
    var newValues = documentValues;
    if(selectionInstance){
      if(selectionInstance === "oos-delivery"){
        newValues.delivery_address = addressSelected.address;
        newValues.delivery_city = addressSelected.city;
        newValues.delivery_state = addressSelected.state;
        newValues.delivery_zip = addressSelected.zip;
      }

      if(selectionInstance === "owner-2"){
        newValues.owner_city_2 = addressSelected.city;
        newValues.owner_state_2 = addressSelected.state;
        newValues.owner_zip_2 = addressSelected.zip;
      }
    }
    setDocumentValues(newValues);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  const onCloseClick = e => {
    e.preventDefault()
    setHasUnsavedChanges(false);
    setSaveSuccess(false);
    setdocumentUrl("");
    setDocument(undefined);
    setPreviousDocumentId(0);
    setDocumentValues(initialDocumentStructure);
    setDocumentLoaded(false);
    setDataLoaded(false);
    setAssignedProduct(undefined);
    setSelectedAddress(undefined);
    setAddressOptions(undefined);
    setinputAddressVal(undefined);
    setLocationVal(undefined);
    setselectedLocation(undefined);
    setinputLocationVal(undefined);
    setLocationOptions(undefined);
    setLocationToggleAutoFill(false);
    setDocumentFocus(undefined);
  }
  
  const [hasClickedClose, setHasClickedClose] = useState(false);
  
  const closeClicked = e => {
    if( hasUnsavedChanges ){
        setHasClickedClose(true);
    } else {
        onCloseClick(e);
    }
  }
  
  const preCloseSave = e => {
    handleSubmit(e)
    setHasClickedClose(false);
  }
  
  const preCloseClose = e => {
    setHasClickedClose(false);
    onCloseClick(e)
  }

  return (
    <>
      <div
        className="modal fade"
        // id="ca-notice-to-seller"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                CA - CDTFA-448 Delivery Statement / Notice To Seller
              </h5>
              <br />
              { hasClickedClose ? (
                <div className="d-flex">
                    <p className="m-1 pt-1 text-white">Save Changes?</p>
                    <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                    <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}
                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                  
                <div className="text-center">
                  <button 
                  type="button"
                  className="btn btn-primary mx-auto"
                  onClick={() => {
                    GlobalAutofill();
                    AutofillProductDetails(assignedProduct);
                    previewPayloadDocument(documentValues);
                  }}>Reload Autofill Values</button>
                </div>
                <br />

                { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Product Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                        </div>
                    </div>
                ):(
                    <div className="m-2 p-2 border">
                        <span>No Assigned Product</span>
                    </div>
                )}
                  <div className="row">
                    <h5>Equipment</h5>
                  </div>
                  
                  <div className="row">
                    <label htmlFor="stock"><b>To be completed by seller...</b></label>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Model</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="model"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.model ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly mb-3">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Purchase Price</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchase_price"
                          onFocus={removeFormatting}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchase_price ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Purchase Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="purchase_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchase_date ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    {addressOptions && addressOptions.length > 0 ? (  
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress && selectedAddress.name) ?? null}
                          name="oos"
                          onChange={(event, newValue) => {
                            setSelectedAddress(newValue);
                            AutoFillAddress(newValue, "oos-delivery");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Out-of-State Delivery Street Address" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Out-of-State Delivery Street Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="delivery_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.delivery_address ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly mb-3">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Delivery City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="delivery_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.delivery_city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Delivery State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="delivery_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.delivery_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Delivery Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="delivery_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.delivery_zip ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    {locationOptions && locationOptions.length > 0 ? (  
                    <div className="d-flex justify-content-evenly">
                      <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                          style={{ width: '100%'}}
                          value={ (locationVal && locationVal.description) ?? null}
                          onChange={(event, newValue) => {
                              setLocationVal(newValue);
                              if(newValue && newValue.locationCode){
                                setselectedLocation(locations.find((location) => location.locationCode === newValue.locationCode));
                                setLocationToggleAutoFill(true);
                              }
                              // resetVariables();
                          }}
                          inputValue={inputLocationVal}
                          onInputChange={(event, newInputValue) => {
                              setinputLocationVal(newInputValue);
                          }}
                          //id="controllable-states-demo"
                          options={locationOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="DEALER LOCATION" />}
                          renderOption={(props, option) => {
                              return (
                              <li {...props} key={option.locationCode}>
                                  {option.description}
                              </li>
                              );
                          }}
                          />
                      </div>
                    </div>):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Locations Found</h5>
                      </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Name of Selling Dealer</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="dealer_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dealer_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock" style={{ fontSize: '12px'}} >CDTFA Seller's Permit Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="cdtfa_permit_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.cdtfa_permit_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-2">
                        <label htmlFor="stock">Area Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="area_code"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.area_code ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Daytime Phone #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="phone"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.phone ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Dealer Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="dealer_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dealer_address ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly mb-3">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Dealer City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="dealer_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dealer_city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Dealer State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="dealer_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dealer_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Dealer Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="dealer_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dealer_zip ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Name of Seller</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="dealer_seller_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dealer_seller_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Date of Delivery</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="delivery_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.delivery_date ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12" style={{ display: 'inline-flex' }}>
                        <label htmlFor="customerName" className="form-label">Seller Type</label>
                        <FormControl className="px-2 pt-1">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="seller_type"
                            value={(documentValues.seller_type ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel className="p-2" value="salesperson_cb" control={<Radio />} label="Salesperson" />
                            <FormControlLabel className="p-2" value="employee_cb" control={<Radio />} label="Employee" />
                            <FormControlLabel className="p-2" value="partner_cb" control={<Radio />} label="Partner" />
                            <FormControlLabel className="p-2" value="other_cb" control={<Radio />} label="Other" />
                          </RadioGroup>
                        </FormControl>
                        { documentValues.seller_type === "other_cb" ? (
                          <div className="">
                            <label htmlFor="stock">Explain</label>
                            <input
                              type="text"
                              className="form-control rounded-pill mt-1"
                              name="other_explain"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              value={ documentValues.other_explain ?? '' }
                            />
                          </div>
                        ):null}
                      </div>
                    </div>
                    <hr></hr>

                    <label htmlFor="stock"><b>To be completed by the purchaser.</b></label>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Purchaser Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Purchase ID Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchase_id_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchase_id_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Purchaser State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_state ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Purchaser Area Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_area_code"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_area_code ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Purchaser Phone</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_phone"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_phone ?? '' }
                          />
                      </div>
                    </div>
                </div>
                <hr></hr>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default CADeliveryStatement

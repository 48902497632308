// import * as api from '../../../../../../../utlis/api.js'
import moment from 'moment'
import { formatNameLastMiddleFirst, formatPhoneNumber, formatMoneyValue, formatUpperCase, getAreaCode, getPhoneNumber } from "../../../../../../../utlis/formatters";


export const OregonVINGlobalAutofill = (documentValues, order, property=null, value=null, location, locations=null, salesReps) => {
    function getRepByEmployeeId(employeeId){
      if(salesReps && salesReps.length > 0){
        let foundRep = salesReps.find(rep => rep.employee_ID === employeeId);
        if(foundRep){
          return foundRep.preferredName ? foundRep.preferredName+" "+foundRep.lastName : foundRep.firstName+" "+foundRep.lastName;
        }
      }
      return "";
    }

    var newValues = documentValues;
    if(property ==='customer.name'){
      if(order.customer.type === "entity"){
        newValues.name = value.toString().toUpperCase();
      }else{
        newValues.name = formatNameLastMiddleFirst(value).toString().toUpperCase();
      }
      if(order.customer.dba!==''){
        newValues.name += " dba "+order.customer.dba;
      }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
            newValues.name += " AND "+cosigner.name;
        }
      }
  }
    if(property ==='customer.dba'){
      if(order.customer.type === "entity"){
        newValues.name = order.customer.name.toString().toUpperCase();
      }else{
        newValues.name = formatNameLastMiddleFirst(order.customer.name).toString().toUpperCase();
      }
      if(value!==''){
        newValues.name += " dba "+value;
      }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
            newValues.name += " AND "+cosigner.name;
        }
      }
  }
    if(property ==='customer.cosigners[0]'){
      if(order.customer.type === "entity"){
        newValues.name = order.customer.name.toString().toUpperCase();
      }else{
        newValues.name = formatNameLastMiddleFirst(order.customer.name).toString().toUpperCase();
      }
      if(value!==''&& order.customer.dba!==''){
        newValues.name += " dba "+order.customer.name;
      }else if(value && value.length > 0){
        let cosigner = value[0];
        if(cosigner && cosigner.name){
            newValues.name += " AND "+cosigner.name;
        }
      }
  }
    if(property ==='customer.phone'){
      newValues.phone_area = getAreaCode(value);
      newValues.phone_number = getPhoneNumber(value);
  }
    if(property ==='salesRepId'){
      newValues.inspector_name = getRepByEmployeeId(value);
  }
    if(property ==='location'){
      newValues.state_of_issue = value.state;
      if(value.state === "OR"){
        newValues.agency_dealership = "TEC EQUIPMENT, INC.";
        newValues.inspector_address = value.address+" "+value.city+" "+value.state+" "+value.zip;
        newValues.dealer_number = "555";
      }
      newValues.inspector_number = formatPhoneNumber(value.phone);
  }
    if(property ==='customer.deliveryAddress.addressLine1'){
      let addressLine = '';
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        addressLine = (value+" "+order.customer.deliveryAddress.addressLine2);
      }else{
        addressLine = value;
      }

      addressLine = addressLine.toString().toUpperCase()+", "+order.customer.deliveryAddress.city.toString().toUpperCase()+", "+order.customer.deliveryAddress.state.toString().toUpperCase()+" "+order.customer.deliveryAddress.zip.toString().toUpperCase();

      newValues.address = addressLine;
  }
    if(property ==='customer.deliveryAddress.addressLine2'){
      let addressLine = '';
        addressLine = (order.customer.deliveryAddress.addressLine1+" "+value);

      addressLine = addressLine.toString().toUpperCase()+", "+order.customer.deliveryAddress.city.toString().toUpperCase()+", "+order.customer.deliveryAddress.state.toString().toUpperCase()+" "+order.customer.deliveryAddress.zip.toString().toUpperCase();

      newValues.address = addressLine;
  }
    if(property ==='customer.deliveryAddress.city'){
      let addressLine = '';
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
      }else{
        addressLine = order.customer.deliveryAddress.addressLine1;
      }

      addressLine = addressLine.toString().toUpperCase()+", "+value.toString().toUpperCase()+", "+order.customer.deliveryAddress.state.toString().toUpperCase()+" "+order.customer.deliveryAddress.zip.toString().toUpperCase();

      newValues.address = addressLine;
  }
    if(property ==='customer.deliveryAddress.state'){
      let addressLine = '';
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
      }else{
        addressLine = order.customer.deliveryAddress.addressLine1;
      }

      addressLine = addressLine.toString().toUpperCase()+", "+order.customer.deliveryAddress.city.toString().toUpperCase()+", "+value.toString().toUpperCase()+" "+order.customer.deliveryAddress.zip.toString().toUpperCase();

      newValues.address = addressLine;
  }
    if(property ==='customer.deliveryAddress.zip'){
      let addressLine = '';
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
      }else{
        addressLine = order.customer.deliveryAddress.addressLine1;
      }

      addressLine = addressLine.toString().toUpperCase()+", "+order.customer.deliveryAddress.city.toString().toUpperCase()+", "+order.customer.deliveryAddress.state.toString().toUpperCase()+" "+value.toString().toUpperCase();

      newValues.address = addressLine;
  }
    if(property !== null && value !== null){
      return newValues;
  }

    if(order && order.customer && order.customer.name){
      
      if(order.customer.type === "entity"){
        newValues.name = order.customer.name.toString().toUpperCase();
      }else{
        newValues.name = formatNameLastMiddleFirst(order.customer.name).toString().toUpperCase();
      }

      if(order.customer.dba){
        newValues.name += " dba "+order.customer.dba;
      }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
            newValues.name += " AND "+cosigner.name;
        }
      }
    }

    if(order && order.customer && order.customer.phone){
      newValues.phone_area = getAreaCode(order.customer.phone);
      newValues.phone_number = getPhoneNumber(order.customer.phone);
    }

    if(order && order.salesRepId){
      newValues.inspector_name = getRepByEmployeeId(order.salesRepId);
    }

    if(location && location.state){
      newValues.state_of_issue = location.state;
    }

    if(location && location.state){
      if(location.state === "OR"){
        newValues.agency_dealership = "TEC EQUIPMENT, INC.";
        newValues.inspector_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
       
        newValues.inspector_number = formatPhoneNumber(location.phone);
        newValues.dealer_number = "555";
      }
    }

    if(order && order.customer && order.customer.deliveryAddress){
      var addressLine = '';
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
      }else{
        addressLine = order.customer.deliveryAddress.addressLine1;
      }

      addressLine = addressLine.toString().toUpperCase()+", "+order.customer.deliveryAddress.city.toString().toUpperCase()+", "+order.customer.deliveryAddress.state.toString().toUpperCase()+" "+order.customer.deliveryAddress.zip.toString().toUpperCase();

      newValues.address = addressLine;
    }

return newValues
  }

export const OregonVINAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {

  var newValues = documentValues;

  if(property === 'year' && value !== null){
    newValues.year = (value).toString().trim().replace(/['"]+/g, '');
}

if(property === 'make' && value !== null){
    newValues.make = (value).toString().trim();
}

if(property === 'model' && value !== null){
    newValues.model = (value).toString().trim();
}

if(property === 'vehicleType' && value !== null){
    newValues.body_style = (value);
}

if(property !== null && value !== null){
    return newValues;
}

  newValues.year = (selectedProduct.year).trim().replace(/['"]+/g, '');
  newValues.make = (selectedProduct.make).trim();
  newValues.model = (selectedProduct.model).trim().toString().toUpperCase();
  newValues.body_style = (selectedProduct.vehicleType);

  return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

    if(payload.weight_exceeded === "yes"){
      payload.weight_exceeded_yes = "yes";
      payload.weight_exceeded_no = "no";
    }else if(payload.weight_exceeded === "no"){
      payload.weight_exceeded_yes = "no";
      payload.weight_exceeded_no = "yes";
    }else{
      payload.weight_exceeded_yes = "";
      payload.weight_exceeded_no = "";
    }

    if(payload.loaded_weight === "yes"){
      payload.loaded_weight_yes = "yes";
      payload.loaded_weight_no = "no";
    }else if(payload.loaded_weight === "no"){
      payload.loaded_weight_yes = "no";
      payload.loaded_weight_no = "yes";
    }else{
      payload.loaded_weight_yes = "";
      payload.loaded_weight_no = "";
    }

    // X1.2- Field Type Specific Preview Formatting
    Object.keys(payload).forEach(key => {
      if(moneyFields.indexOf(key) >= 0){
          payload[key] = formatMoneyValue(payload[key]);
      }else if(phoneFields.indexOf(key) >= 0){
          payload[key] = formatPhoneNumber(payload[key]);
      }else if(upperCaseFields.indexOf(key) >= 0){
          payload[key] = formatUpperCase(payload[key]);
      }
    });
    Object.keys(payload).forEach(key => {
      if(checkboxes.indexOf(key) >= 0){
        payload[key] = payload[key] ? "yes": "no";
      }
    });

    if(payload.inspection_date){
      payload.inspection_date = moment.utc(payload.inspection_date).format("MM/DD/YY");
    }

    return payload;
}
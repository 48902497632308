import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  useParams
} from "react-router-dom";
import { useFetch,recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {  Checkbox, FormGroup } from '@mui/material';
import { removeChars, formatter, formatPhoneNumber, formatUpperCase } from "../../../../../../../utlis/formatters";
import { useOrder } from "../../../../../../../redux/orders/order"
// import {salesPriceWithUpgradeCalc} from "../../../../../../../utlis/productCalculations";
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { NEForm6GlobalAutofill, NEForm6AutofillProductDetails, preProcessPayload } from "../DocumentAutofill/NEForm6"

const NEForm6 = ({document_id, getDocuments, passedDocumentSlug, locations, setDocumentFocus, location}) => {
  const documentSlug = "ne-form-6";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  // const dispatch = useDispatch();

  // const stateAbbrev = States.map(state => {
  //   return state.abbreviation;
  // });

  const order = useOrder();

  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");


  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);
  // const [ locations, setLocations ] = useState([]);//Redux
  // const [ nameOptions, setNameOptions ] = useState([]);//Redux

  // Data Structure & Init //
  
  var initialDocumentStructure = {
  }

  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);

  const moneyFields = useMemo(()=>{return[
    "total_sales_price",
    "trade_in_allowance",
    "manufacturer_rebate",
    "tax_base",
    "toal_nebraska_and_local_sales_tax",
    "tire_fee",
    "penalty_late_payment",
    "interest_for_late_payment",
    "balance_due",
    "finance_and_support_enter_amount_of_contribution"
  ]},[]);
  const phoneFields = useMemo(()=>{return[]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "vin_2", "model"]},[]);
  const checkboxes = useMemo(()=>{return[
    "type_1",
    "type_2",
    "type_3",
    "type_4",
    "type_5",
    "type_6",
    "type_7",
    "type_8",
    "type_9",
    "type_10",
    "type_11",
    "type_12"
  ]},[]);
  // const radios = [
  // ];

  // X1- Current General On Change
  const handleDocumentValuesChange = e =>{
    // e.preventDefault();
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatPhoneNumber(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }

    // if(name === 'odometer'){
    //   setDocumentValues({...documentValues,[name]:removeChars(value)});
    // }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('NEForm6.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);
  

  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");


      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
      // setNameOptions(getAllNames(order, null));
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, order, documentLoaded, loadDocument ])

  const [assignedProduct, setAssignedProduct] = useState(undefined);
  // const [selectedAddress1, setSelectedAddress1] = useState(undefined);
  // const [selectedAddress2, setSelectedAddress2] = useState(undefined);
  // const [selectedAddress3, setSelectedAddress3] = useState(undefined);
  // const [selectedAddress4, setSelectedAddress4] = useState(undefined);
  // const [selectedAddress5, setSelectedAddress5] = useState(undefined);
  // const [selectedAddress6, setSelectedAddress6] = useState(undefined);
  // const [addressOptions, setAddressOptions] = useState(undefined);
  // const [inputAddressVal, setinputAddressVal] = useState(undefined);

  // const [tradeinVal, setTradeinVal] = useState(undefined);
  const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);


  const _filterOptions = createFilterOptions();

  const [optionCount, setOptionCount] = React.useState(0);
  const filterOptions = React.useCallback((options, state) => {
    const results = _filterOptions(options, state);

    if (optionCount !== results.length) {
      setOptionCount(results.length);
    }

    return results;
  }, [ _filterOptions, optionCount ]);

  if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
    let getTradeinOptions = [];
    order.tradeins.forEach(tradein => {
      let str = `${tradein.vin.toString().toUpperCase()}`;
      let obj = {id: tradein.id, name: str}
      getTradeinOptions.push(obj);
    })
    setTradeinOptions(getTradeinOptions);
  }


 // Autofill //

 const GlobalAutofill = useCallback(async () => {
  let newValues = NEForm6GlobalAutofill(documentValues, order, null, null, location);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  // var newValues = documentValues;
  
  // if(order && order.customer){

  //   if(order && order.customer && order.customer.name){

  //     // newValues.phone_number = order.customer.phone;

  //     // if(order.customer.type === "entity"){
  //       newValues.name = order.customer.name;
  //       if(order.customer.dba){
  //         newValues.name = order.customer.name+" dba "+order.customer.dba;
  //       }
  //     // }
  
  //     if(order.customer.cosigners && order.customer.cosigners.length > 0){
  //       let cosigner = order.customer.cosigners[0];
  //       if(cosigner){
  //         newValues.name += " and "+cosigner.name;
  //       }
  //   }
  // }
    
  //   if(order && order.customer && order.customer.deliveryAddress){
  //     newValues.street_or_other_address = order.customer.deliveryAddress.addressLine1;
  //     if(order.customer.deliveryAddress.addressLine1 !== null && order.customer.deliveryAddress.addressLine2 !== null && order.customer.deliveryAddress.addressLine2 !== ''){
  //       newValues.street_or_other_address = newValues.street_or_other_address+" "+order.customer.deliveryAddress.addressLine2;
  //     }
  //     newValues.city = order.customer.deliveryAddress.city;
  //     newValues.state = order.customer.deliveryAddress.state;
  //     newValues.zip = order.customer.deliveryAddress.zip;
  //   }

  //   // if(order && order.customer && order.customer.mailingAddress){
  //   //   if(order.customer.mailingAddress.addressLine1 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
  //   //     newValues.address = newValues.address+" "+order.customer.mailingAddress.addressLine2;
  //   //   }
  //   //   newValues.city = order.customer.mailingAddress.city;
  //   //   newValues.state = order.customer.mailingAddress.state;
  //   //   newValues.zip_code = order.customer.mailingAddress.zip;
  //   // }
  // }
  
  // if(order && order.documentationDate){
  //   newValues.date_of_purchase = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  //   newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  //   newValues.date_paid = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  // }

  // if(location){
  //   if(location.state === "CA"){
  //     newValues.name_2 = "TEC OF CALIFORNIA, INC.";
  //   }else{
  //     newValues.name_2 = "TEC EQUIPMENT, INC.";
  //   }
  //   newValues.street_or_other_address_2 = location.address;
  //   newValues.city_2 = location.city;
  //   newValues.state_2 = location.state;
  //   newValues.zip_2 = location.zip;
  // }

  // setDocumentValues(newValues);
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[documentValues, location, order])

// const preProcessPayload = useCallback((payload) => {

//   if(payload.trade_leased_toggle){
//     // Reset all
//     payload.trade_in_lease_yes = "no";
//     payload.trade_in_lease_no = "no";

//     switch(payload.trade_leased_toggle){
//       case "yes":
//         payload.trade_in_lease_yes = "yes";
//         break;
//       case "no":
//         payload.trade_in_lease_no = "yes";
//         break;
//       default:
//     }
//   }

//   Object.keys(payload).forEach(key => {
//     if(moneyFields.indexOf(key) >= 0){
//         payload[key] = formatMoneyValue(payload[key]);
//     }else if(phoneFields.indexOf(key) >= 0){
//         payload[key] = formatPhoneNumber(payload[key]);
//     }else if(upperCaseFields.indexOf(key) >= 0){
//         payload[key] = formatUpperCase(payload[key]);
//     }
//   });
//   setDocumentValues(JSON.parse(JSON.stringify(payload)));
//   Object.keys(payload).forEach(key => {
//     if(checkboxes.indexOf(key) >= 0){
//       payload[key] = payload[key] ? "yes": "no";
//     }
//   });

//   if(payload.date){
//     payload.date = moment.utc(payload.date).format("MM/DD/YY");
//   }

//   if(payload.date_of_purchase){
//     payload.date_of_purchase = moment.utc(payload.date_of_purchase).format("MM/DD/YY");
//   }

//   if(payload.date_paid){
//     payload.date_paid = moment.utc(payload.date_paid).format("MM/DD/YY");
//   }

//   return payload;
// },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])


const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });
    
    if(!document){
      return null;
    }

      var docCopy = JSON.parse(JSON.stringify(document));
      docCopy.payload =  JSON.parse(JSON.stringify(payload));
      docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
      docCopy.payload =  JSON.stringify(docCopy.payload);
        delete docCopy.documentStatus;
      try{
        let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
        setdocumentUrl(fileURL)
        } catch (err) {
          recordFrontEndError('NEForm6.jsx', 'Error previewing document payload: ' + err)
        };  
    
},[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


    //Autofill Product Details After Selection
    const AutofillProductDetails = useCallback(async (selectedProduct) => {
      let newValues = NEForm6AutofillProductDetails(selectedProduct, documentValues);

      if(newValues){
        setDocumentValues(newValues);
        previewPayloadDocument(documentValues);
        setHasUnsavedChanges(true);
        if(document){
          previewPayloadDocument(documentValues);
        }
        setSaveSuccess(false);
      }
    
    

      // var newValues = documentValues;
      // newValues.year = (selectedProduct.year);
      // newValues.make = (selectedProduct.make).toString().toUpperCase();
      // newValues.model = (selectedProduct.model).toString().toUpperCase();
      // newValues.vin = (selectedProduct.vin).toString().toUpperCase();
      // newValues.total_sales_price = salesPriceWithUpgradeCalc(selectedProduct);


      // setDocumentValues(newValues);
      // if(document){
      //   previewPayloadDocument(documentValues);
      // }
      // setHasUnsavedChanges(true);
      // setSaveSuccess(false);
    },[ document, documentValues, previewPayloadDocument ]);

  // Document BREAD + Preview // TODO: Move to Redux

  useEffect(() => {
    if(document && document.payload && !dataLoaded){
      var existingPayload = false;
      var documentPayload = JSON.parse(document.payload);
      if(documentPayload && Object.keys(documentPayload).length > 0){
    
        existingPayload = true;
    
        // if(documentPayload.date_of_sale){
        //   documentPayload.date_of_sale = moment.utc(documentPayload.date_of_sale).format("YYYY-MM-DD");
        // }

        if(documentPayload.date){
          documentPayload.date = moment.utc(documentPayload.date).format("YYYY-MM-DD");
        }

        if(documentPayload.date_of_purchase){
          documentPayload.date_of_purchase = moment.utc(documentPayload.date_of_purchase).format("YYYY-MM-DD");
        }

        if(documentPayload.date_paid){
          documentPayload.date_paid = moment.utc(documentPayload.date_paid).format("YYYY-MM-DD");
        }

    
        
        setDocumentValues(documentPayload);

        // Loading Use Effect
        // if(document.additionalData){
        //   const methodMap = { 
        //     selectedAddress1: setSelectedAddress1, 
        //     selectedAddress2: setSelectedAddress2, 
        //     selectedAddress3: setSelectedAddress3, 
        //     selectedAddress4: setSelectedAddress4, 
        //     selectedAddress5: setSelectedAddress5, 
        //     selectedAddress6: setSelectedAddress6, 
        //   };

        //   let parsedAdditionalData = JSON.parse(document.additionalData);
        //   Object.keys(parsedAdditionalData).forEach(function(key) {
        //     if(methodMap[key]){
        //       methodMap[key](parsedAdditionalData[key]);
        //     }
        //   });
        // }

      }
      var foundAssignedProduct = null;
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
        foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
      }
      
      // setAddressOptions(getOrderLevelAddresses(order));
      if(foundAssignedProduct){
          setAssignedProduct(foundAssignedProduct);
          // setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
          // setNameOptions(getAllNames(order, foundAssignedProduct));
      }
      
        
        if(!existingPayload){
          GlobalAutofill();
          previewPayloadDocument(documentValues);
          if(foundAssignedProduct){
            AutofillProductDetails(foundAssignedProduct)
          }
          // Load First Tradein
          if(order && order.tradeins && order.tradeins.length > 0){
            let tradein = order.tradeins[0];
            if(tradein){
              setselectedTradein(tradein);
            }
          }
        }else{
          // if(document && document.itemIds){
          //   var itemIds = document.itemIds.split(',');
          //     var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
          //     let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
          //     let obj = {id: foundProduct.id, name: str}
          //     setproductVal(obj);
          //     if(foundProduct){
          //       setAddressOptions(updateAddressOptions(foundProduct, order));
          //     }
          //     streamDocument();
          // }
    
        }
        
      setDataLoaded(true);
    }
  },[ documentValues, document, dataLoaded, order, AutofillProductDetails, GlobalAutofill, previewPayloadDocument ])


  function ReloadAutoFillValues(){  
    var foundAssignedProduct = null;
    if(document && document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }

    GlobalAutofill();
    if(foundAssignedProduct){
      AutofillProductDetails(foundAssignedProduct)
    }
  }



    const onTradeinChange = (tradeinObj) => {
      if(order && order.tradeins && order.tradeins.length > 0){
        let tradein = order.tradeins.find(t => t.id = tradeinObj.id);
        if(tradein){
          setselectedTradein(tradein);
          settradeinProcessed(false);
        }
      }

      // calculateTotals(selectedProducts, tradeinArray);
      // updateDocumentTemplateId(selectedProducts, tradeinArray);
    }
  
    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();

      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
    
  
      // const payload = JSON.parse(JSON.stringify(documentValues));
      // var docCopy = document;
      // docCopy.payload = payload;
      // docCopy.payload = preProcessPayload(docCopy.payload);
      // docCopy.payload =  JSON.stringify(docCopy.payload);
      // delete docCopy.documentStatus;
      
      // if(selectedProduct && selectedProduct.id){
      //   docCopy.itemIds = `${selectedProduct.id}`;
      // }
  
      //Save Dropdown Selections
      // let dropdownData = {};
      // if(selectedAddress1){
      //   dropdownData.selectedAddress1 = {
      //     id: selectedAddress1.id,
      //     name: selectedAddress1.name
      //   };
      // }
      // if(selectedAddress2){
      //   dropdownData.selectedAddress2 = {
      //     id: selectedAddress2.id,
      //     name: selectedAddress2.name
      //   };
      // }
      // if(selectedAddress3){
      //   dropdownData.selectedAddress3 = {
      //     id: selectedAddress3.id,
      //     name: selectedAddress3.name
      //   };
      // }
      // if(selectedAddress4){
      //   dropdownData.selectedAddress4 = {
      //     id: selectedAddress4.id,
      //     name: selectedAddress4.name
      //   };
      // }
      // if(selectedAddress5){
      //   dropdownData.selectedAddress5 = {
      //     id: selectedAddress5.id,
      //     name: selectedAddress5.name
      //   };
      // }
      // if(selectedAddress6){
      //   dropdownData.selectedAddress6 = {
      //     id: selectedAddress6.id,
      //     name: selectedAddress6.name
      //   };
      // }

      // docCopy.additionalData = JSON.stringify(dropdownData);

      // let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, null, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });

  
      
    }

// Component Helpers //
const [tradeinProcessed, settradeinProcessed] = useState(false);

  useEffect(() => {
    if(selectedTradein && !tradeinProcessed){
      settradeinProcessed(true);
      let newValues = documentValues;
      if(newValues){
        newValues.make_2 = (selectedTradein.make).toString().toUpperCase();
        newValues.vin_2 = (selectedTradein.vin).toString().toUpperCase();
        newValues.year_2 = selectedTradein.year;
        newValues.body_type_2 = selectedTradein.seriesBodyType;
        newValues.license_plate_number = selectedTradein.plateNumber;
        newValues.trade_leased_toggle = selectedTradein.balanceOwed &&  Number(selectedTradein.balanceOwed) > 0 ? "yes" : "no";
        newValues.trade_in_allowance = selectedTradein.finalTradeAllowance;
        setDocumentValues(newValues);
        if(document){
          previewPayloadDocument(documentValues);
        }
        setHasUnsavedChanges(true);
        setSaveSuccess(false);
      }
    }
  },[ selectedTradein, document, documentValues, previewPayloadDocument, tradeinProcessed, settradeinProcessed ])

  // useEffect(() => {
  //   //Autofill Tradein Details After Selection
  //   if(selectedLocation !== undefined && locationToggleAutoFill){
  //     var newValues = documentValues;
  //     newValues.seller_address = selectedLocation.address;
  //     newValues.seller_city = selectedLocation.city;
  //     newValues.seller_state = selectedLocation.state;
  //     newValues.seller_zip = selectedLocation.zip;
  //     newValues.seller_phone = formatPhoneNumber(selectedLocation.phone);
  
  //     if(selectedLocation.state === "CA"){
  //       newValues.seller_name_signature = "TEC OF CALIFORNIA, INC";
  //       newValues.copy_seller_name_signature = "TEC OF CALIFORNIA, INC";
  //     }else{
  //       newValues.seller_name_signature = "TEC EQUIPMENT, INC.";
  //       newValues.copy_seller_name_signature = "TEC EQUIPMENT, INC.";
  //     }
  
  //     setDocumentValues(newValues);
  //     setLocationToggleAutoFill(false);
  //     if(document){
  //       previewPayloadDocument(documentValues);
  //     }
  //     setHasUnsavedChanges(true);
  //     setSaveSuccess(false);
  //   }
  // },[ selectedLocation, locationToggleAutoFill ])
  
  //Autofill Address Details After Selection
  // function AutoFillAddress(addressSelected, selectionInstance){
  //   var newValues = documentValues;
  //   if(selectionInstance){
  //     if(selectionInstance === "physical-address"){
  //       newValues.address_line_1 = addressSelected.address;
  //       // newValues.address_line_2 = addressSelected.addressLine2;
  //       newValues.city = addressSelected.city;
  //       newValues.state = addressSelected.state;
  //       newValues.zip = addressSelected.zip;
  //     }

  //     if(selectionInstance === "mailing-address"){
  //       newValues.mailing_address_line_1 = addressSelected.address;
  //       // newValues.mailing_address_line_2 = addressSelected.addressLine2;
  //       newValues.mailing_city = addressSelected.city;
  //       newValues.mailing_state = addressSelected.state;
  //       newValues.mailing_zip = addressSelected.zip;
  //     }

  //     if(selectionInstance === "lessee-address"){
  //       newValues.lessee_address_line_1 = addressSelected.address;
  //       // newValues.lessee_address_line_2 = addressSelected.addressLine2;
  //       newValues.lessee_city = addressSelected.city;
  //       newValues.lessee_state = addressSelected.state;
  //       newValues.lessee_zip = addressSelected.zip;
  //     }

  //     if(selectionInstance === "located-address"){
  //       newValues.trailer_coach_address = addressSelected.address;
  //       newValues.trailer_coach_city = addressSelected.city;
  //       newValues.trailer_coach_state = addressSelected.state;
  //       newValues.trailer_coach_zip = addressSelected.zip;
  //     }

  //     if(selectionInstance === "physical2-address"){
  //       newValues.lien_address_line_1 = addressSelected.address;
  //       // newValues.lien_address_line_2 = addressSelected.addressLine2;
  //       newValues.lien_city = addressSelected.city;
  //       newValues.lien_state = addressSelected.state;
  //       newValues.lien_zip = addressSelected.zip;
  //     }

  //     if(selectionInstance === "mailing2-address"){
  //       newValues.lien_mailing_address_line_1 = addressSelected.address;
  //       // newValues.lien_mailing__address_line_2 = addressSelected.addressLine2;
  //       newValues.lien_mailing_city = addressSelected.city;
  //       newValues.lien_mailing_state = addressSelected.state;
  //       newValues.lien_mailing_zip = addressSelected.zip;
  //     }
  //   }
  //   setDocumentValues(newValues);
  //   if(document){
  //     previewPayloadDocument(documentValues);
  //   }
  //   setHasUnsavedChanges(true);
  //   setSaveSuccess(false);
  // }

  const onCloseClick = e => {
    e.preventDefault()
    setHasUnsavedChanges(false);
    setSaveSuccess(false);
    setdocumentUrl("");
    setDocument(undefined);
    // setNameOptions([]);
    setPreviousDocumentId(0);
    setDocumentValues(initialDocumentStructure);
    setDocumentLoaded(false);
    setDataLoaded(false);
    setAssignedProduct(undefined);
    // setSelectedAddress1(undefined);
    // setSelectedAddress2(undefined);
    // setSelectedAddress3(undefined);
    // setSelectedAddress4(undefined);
    // setSelectedAddress5(undefined);
    // setSelectedAddress6(undefined);
    // setAddressOptions(undefined);
    // setinputAddressVal(undefined);
    setDocumentFocus(undefined);
  }
  
  const [hasClickedClose, setHasClickedClose] = useState(false);
  
  const closeClicked = e => {
    if( hasUnsavedChanges ){
        setHasClickedClose(true);
    } else {
        onCloseClick(e);
    }
  }
  
  const preCloseSave = e => {
    handleSubmit(e)
    setHasClickedClose(false);
  }
  
  const preCloseClose = e => {
    setHasClickedClose(false);
    onCloseClick(e)
  }

  return (
    <>
      <div
        className="modal fade"
        // id="ca-statement-of-facts"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                Nebraska Sales/Use Tax and Tire Fee Statement - Form 6
              </h5>
              <br />
              { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1 pt-1 text-white">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                  </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}


                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}


                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ fontSize: '12px', overflowY: 'scroll', height: 'inherit'}}>
               
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      ReloadAutoFillValues();
                      // GlobalAutofill();
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>
                  <br />
                  { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Product Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                        </div>
                    </div>
                  ):(
                    <div className="m-2 p-2 border">
                        <span>No Assigned Product</span>
                    </div>
                  )}

                  <div className="row">
                    <h5>Dealer's Report of Sale</h5>
                  </div>

                  {/* <Autocomplete
                      style={{ width: '100%'}}
                      value={ (documentValues.name) ?? null}
                      name="owner_name"
                      onBlur={(e) => updatePreview(e)}
                      onChange={(event, newValue) => {
                        let updatedObject = documentValues;
                        updatedObject.name = newValue.name;
                        setDocumentValues(updatedObject);
                        // setDocumentValues({...documentValues,["owner_name"]:newValue.name});
                      }}
                      // inputValue={inputAddressVal}
                      // onInputChange={(event, newInputValue) => {
                      //   setinputAddressVal(newInputValue);
                      // }}
                      options={nameOptions}
                      sx={{ width: 400 }}
                      renderInput={(params) => <TextField {...params} label="Purchaser Name" />}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                    /> */}

                  <div className="row">
                    <br />
                    <h5>Purchaser's Name and Address</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.name ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Street or Other Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="street_or_other_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.street_or_other_address ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.state ?? '' }
                          />
                          </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.zip ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <br />
                    <h5>Seller's Name and Address</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="name_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.name_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Street or Other Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="street_or_other_address_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.street_or_other_address_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="city_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.city_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="zip_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.zip_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>


                  <div className="row">
                    <h5>Purchased Vehicle/Traller Description</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Body Type</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="body_type"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.body_type ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-9">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Date of Purchase</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date_of_purchase"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date_of_purchase ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  { tradeinOptions && tradeinOptions.length > 0 ? (
                    <div className="row my-3">

                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ ( selectedTradein && selectedTradein.vin ? selectedTradein.vin.toUpperCase() : null)}
                          onChange={(event, newValue) => {
                            if(newValue !==  null) {
                              onTradeinChange(newValue);
                            }
                          }}
                          getOptionLabel={(option)=>(option.name ? option.name : '')}
                          // inputValue={inputTradeinVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputDocument(newInputValue);
                          // }}
                          //id="controllable-states-demo"
                          filterOptions={filterOptions}
                          options={tradeinOptions}
                          sx={{ width: 230}}
                          renderInput={(params) => <TextField {...params} label="SELECT TRADE IN" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />

                  </div>

                  ):(
                    <p>No Trade Ins Found On Order</p>
                  )}

                  <div className="row">
                    <br />
                    <h5>Trade-In Vehicle/Traller Description</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Body Type</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="body_type_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.body_type_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-9">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin_2"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">License Plate Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="license_plate_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.license_plate_number ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-9">
                        <label htmlFor="stock">Was the trade in vehicle being leased?</label>
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="trade_leased_toggle"
                            value={(documentValues.trade_leased_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="no" control={<Radio />} label="No" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  
                  <div className="row">
                    <br />
                    <h5>Tax Computation</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">1. Total Sales Price</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="total_sales_price"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.total_sales_price ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">2. Less trade-in allowance</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="trade_in_allowance"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.trade_in_allowance ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">3. Less manufacturer's rebate assigned to dealer at time of sale</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="manufacturer_rebate"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.manufacturer_rebate ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">4. Tax Base</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="tax_base"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.tax_base ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">5. Nebraska and local sales or use tax due</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="nebraska_sales_or_use_tax"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.nebraska_sales_or_use_tax ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">6. Local sales or use tax</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="local_sales_or_use_tax"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.local_sales_or_use_tax ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">7. Total Nebraska and local sales or use tax due</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="toal_nebraska_and_local_sales_tax"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.toal_nebraska_and_local_sales_tax ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">8a. Tire Total</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="tire_count"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.tire_count ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">8b. Tire Fee</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="tire_fee"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.tire_fee ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">9. Penalty for late payment</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="penalty_late_payment"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.penalty_late_payment ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">10. Interest for late payment</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="interest_for_late_payment"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.interest_for_late_payment ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">11. Balance Due</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="balance_due"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.balance_due ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <br />
                    <h5>Nebraska Resale or Exempt Sale Certificate for Motor Vehicle and Trailer Sales</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="type_1"
                            checked={(documentValues && documentValues.type_1 ? documentValues.type_1 : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="1. Purchase by an exempt organization..." 
                          />
                        </FormGroup>
                        { documentValues && documentValues.type_1 && documentValues.type_1 === true ? (
                          <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="number_05"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.number_05 ?? '' }
                          />
                        ):null}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="type_2"
                            checked={(documentValues && documentValues.type_2 ? documentValues.type_2 : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="2. Vehicle will be used in a common or contract carrier capacity..." 
                          />
                        </FormGroup>
                        { documentValues && documentValues.type_2 && documentValues.type_2 === true ? (
                          <input
                            type="text"
                            className="form-control rounded-pill mt-1"
                            name="the_nebraska_department_of_revenue_number_05"
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                            value={ documentValues.the_nebraska_department_of_revenue_number_05 ?? '' }
                            />
                        ):null}

                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="type_3"
                            checked={(documentValues && documentValues.type_3 ? documentValues.type_3 : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="3. Purchased by a lessor......" 
                          />
                        </FormGroup>
                        { documentValues && documentValues.type_3 && documentValues.type_3 === true ? (
                          <input
                            type="text"
                            className="form-control rounded-pill mt-1"
                            name="lessors_sales_tax_number_01"
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                            value={ documentValues.lessors_sales_tax_number_01 ?? '' }
                            />
                        ):null}

                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="type_4"
                            checked={(documentValues && documentValues.type_4 ? documentValues.type_4 : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="4. Purchase under the Lesso's Option..." 
                          />
                        </FormGroup>
                        { documentValues && documentValues.type_4 && documentValues.type_4 === true ? (
                          <input
                            type="text"
                            className="form-control rounded-pill mt-1"
                            name="number_05_2"
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                            value={ documentValues.number_05_2 ?? '' }
                            />
                        ):null}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="type_5"
                            checked={(documentValues && documentValues.type_5 ? documentValues.type_5 : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="5. Purchase by a Native American..." 
                          />
                        </FormGroup>
                        { documentValues && documentValues.type_5 && documentValues.type_5 === true ? (
                          <input
                            type="text"
                            className="form-control rounded-pill mt-1"
                            name="tax_exemption_id_card_number"
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                            value={ documentValues.tax_exemption_id_card_number ?? '' }
                            />
                        ):null}

                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="type_6"
                            checked={(documentValues && documentValues.type_6 ? documentValues.type_6 : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="6. Vehicle was purchased..." 
                          />
                        </FormGroup>
                        { documentValues && documentValues.type_6 && documentValues.type_6 === true ? (
                          <input
                            type="text"
                            className="form-control rounded-pill mt-1"
                            name="identify_state"
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                            value={ documentValues.identify_state ?? '' }
                            />
                        ):null}

                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="type_7"
                            checked={(documentValues && documentValues.type_7 ? documentValues.type_7 : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="7. Purchase by a person with a disability..." 
                          />
                        </FormGroup>
                        { documentValues && documentValues.type_7 && documentValues.type_7 === true ? (
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="finance_and_support_enter_amount_of_contribution"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.finance_and_support_enter_amount_of_contribution ?? '' }
                          />
                        ):null}

                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="type_8"
                            checked={(documentValues && documentValues.type_8 ? documentValues.type_8 : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="8. Purchase by a governmental entity..." 
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="type_9"
                            checked={(documentValues && documentValues.type_9 ? documentValues.type_9 : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="9. Purchase of a header trailer, head hauler..." 
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="type_10"
                            checked={(documentValues && documentValues.type_10 ? documentValues.type_10 : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="10. Vehicle is a gift or received by inheritance..." 
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="type_11"
                            checked={(documentValues && documentValues.type_11 ? documentValues.type_11 : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="11. A transfer of a motor vehicle..." 
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="type_12"
                            checked={(documentValues && documentValues.type_12 ? documentValues.type_12 : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="12. Purchase of a 30-day plate by a nonresident..." 
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                    
                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default NEForm6

import React from "react";
import { useOrder } from "../../../../../../redux/orders/order"
import { formatPhoneNumber, capitalizeFirstLetter } from "../../../../../../utlis/formatters";

//Redux Refactor: No
const CustomerSection = ({taxExemptReasons}) => {

  const order = useOrder();
  const displayExemption=(exemptionId)=>{
  let foundReason = taxExemptReasons?.find(reason=>reason.id===exemptionId)
  
 return (
<div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
<h5>{foundReason?.description}</h5>
{ (foundReason?.code === 'EXEMPTCERT' || 
                           foundReason?.code === 'COLBYLESSOR' ||
                           foundReason?.code === 'DLRRESALE' ||
                           foundReason?.code === 'SOLDATAUCTN' ||
                           foundReason?.code === 'LEAINTERCOM') ? (
                          <div className="">
                            <label className="form-label p-2" style={{fontWeight:'bold'}}>Resale Number: <span style={{fontWeight:'initial'}}>{order?.customer?.sterResaleNumber}</span></label>
                                </div>
                        ):null}
                        { foundReason?.code === 'CDTFA837' ? (<>
                              <div className="">
                                <label className="form-label p-2" style={{fontWeight:'bold'}}>MC Number: <span style={{fontWeight:'initial'}}>{order?.customer?.sterMcNumber}</span></label>
                               
                              </div>
                              <div className="">
                                <label className="form-label p-2" style={{fontWeight:'bold'}}>DOT Number: <span style={{fontWeight:'initial'}}>{order?.customer?.sterDotNumber}</span></label>

                                </div>
                                </>
                          ):null}
                                { foundReason?.code  === 'EXEMPTCERT' ||
                        foundReason?.code  === 'SOLDATAUCTN' ? (

                            <div className="">
                                <label className="form-label p-2">Dealer Number: <span style={{fontWeight:'initial'}}>{order?.customer?.sterDealerNumber}</span></label>
                            </div>
                          
                        ):null}
</div>
  )
  }


  return (
    <>
    <div>
      <div className="p-4">
        <h5>CUSTOMER DETAILS</h5>
        <br />
        <div className="d-flex justify-content-around">
          <div className="p-1">
            {!order || !order.customer || !order.customer.type ? (
            <h5 className="">--</h5>
            ) : (
              <h5 className="">{ capitalizeFirstLetter(order.customer.type) }</h5>
            )}
            <p className="mx-0 text-secondary pt-3">Customer Type</p>
          </div>

          <div className="p-1">
            {!order || !order.customer || !order.customer.name ? (
            <h5 className="">--</h5>
            ) : (
              <h5 className="">{ order.customer.name }{ (order.customer.dba) ? " / "+order.customer.dba : null}</h5>
            )}
            <p className="mx-0 text-secondary pt-3">{ order && order.customer && order.customer.dba && order.customer.dba !== "" ? ("Name & DBA"):("Name")}</p>
          </div>

          <div className="p-1">
            {!order || !order.customer || !order.customer.phone ? (
            <h5 className="">--</h5>
            ) : (
              <h5 className="">{ formatPhoneNumber(order.customer.phone) }</h5>
            )}
            <p className="mx-0 text-secondary pt-3">Phone</p>
          </div>

          <div className="p-1">
            {!order || !order.customer || !order.customer.email ? (
            <h5 className="">--</h5>
            ) : (
              <h5 className="">{ order.customer.email }</h5>
            )}
            <p className="mx-0 text-secondary pt-3">Email</p>
          </div>
          
          <div className="p-1">
              {order?.customer?.acceptMarketingEmails ? (
                <h5>Yes</h5>
              ):(
                <h5>No</h5>
              )}
              <p className="mx-0 text-secondary pt-3">Receiving Marketing Emails?</p>
            </div>
        </div>
      </div>

      <div className="p-4">
        <div className="d-flex justify-content-around">
          <div className="p-1 pt-1">
              {order && order.customer && order.customer.deliveryAddress && order.customer.deliveryAddress.addressLine1 ? (
                <h6 className="">{ order.customer.deliveryAddress.addressLine1 }</h6>
                ) :null}
              { order && order.customer && order.customer.deliveryAddress && order.customer.deliveryAddress.addressLine2 ? (
                <h6 className="">{ order.customer.deliveryAddress.addressLine2 }</h6>
                ):('')}
              {order && order.customer && order.customer.deliveryAddress ? (
                <h6 className="">{ order.customer.deliveryAddress.city}, { order.customer.deliveryAddress.state} { order.customer.deliveryAddress.zip}</h6>
                ):('')}
              <p className="mx-0 text-secondary pt-3">Customer Address</p>
            </div>
            <div className="p-1 pt-1">
              {order && order.customer && order.customer.mailingAddress !== null ? (
              <div>
              {order && order.customer && order.customer.mailingAddress && order.customer.mailingAddress.addressLine1 ? (
                <h6 className="">{ order.customer.mailingAddress.addressLine1 }</h6>
              ) :null}
              { order && order.customer && order.customer.mailingAddress && order.customer.mailingAddress.addressLine2 ? (
                <h6 className="">{ order.customer.addressLine2 }</h6>
              ):('')}
              {order && order.customer && order.customer.mailingAddress ? (
              <h6 className="">{ order.customer.mailingAddress.city}, { order.customer.mailingAddress.state} { order.customer.mailingAddress.zip}</h6>
              ):('')}
              <p className="mx-0 text-secondary pt-3">Mailing Address</p>
            </div>
              ) : (
                <div>
                  <h6 className="">None</h6>
                  <br />
                  <p className="mx-0 text-secondary pt-3">Mailing Address</p>
                </div>              ) }
            </div>
          </div>
      </div>

      { order && order.customer ? (
      <div className="p-4">
          <h5 className="mb-2">Notes</h5>
          <div className="p-1">
            <h6>{ order.customer.notes }</h6>
          </div>
      </div>
      ):null}

      { order && order.customer ? (
        <div className="p-4">
          <h5>TAX STATUS</h5>
          <br />
          <div className="d-flex justify-content-around">

            <div className="p-1 mr-2">
              {order && order.customer && order.customer.isExemptSalesTax ? (
              <h5 className="">Yes</h5>
              ):(
              <h5 className="text-danger">No</h5>
              )}
              <p className="mx-0 text-secondary pt-3">Tax Exempt?</p>
            </div>

            <div className="p-1 mr-2" style={{width:'75%'}}>
              {order.customer.isExemptSalesTax === true ? (
                <>
                  { displayExemption(order.customer.salesTaxExemptionReasonId) }
                  <p className="mx-0 text-secondary pt-3" style={{textAlign:'center'}}>Exemption Validation</p>
                </>
              ):null}
              {/* {order && order.customer && order.customer.isExemptSalesTax && order.customer.iccNumber ? (
              <h5>{ order.customer.iccNumber ?? "--" }</h5>
              ):(
              <h5>--</h5>
              )} */}
            </div>
            {/* {order && order.customer && order.customer.isExemptSalesTax ? (
              <div className="p-1">
                <h5>{ order.customer.iccNumber ?? "--" }</h5>
                <p className="mx-0 text-secondary pt-3">Exemption Validation</p>
              </div>
            ):('')} */}

            {order && order.customer && order.customer.isExemptSalesTax === false && order.customer.deliveryAddress && order.customer.deliveryAddress.state !== "WA" ? (
              <div className="d-flex">
                {order && order.customer && order.customer.vehicleSalesTaxPercent ? (
                  <div className="p-1 ml-4 mr-2">
                    <h5>{ order.customer.vehicleSalesTaxPercent }</h5>
                    <p className="mx-0 text-secondary pt-3">Sales Tax</p>
                  </div>
                ):('')}
                
                {order && order.customer && order.customer.vehicleTaxCounty ? (
                  <div className="p-1 mr-2">
                    <h5>{ order.customer.vehicleTaxCounty }</h5>
                    <p className="mx-0 text-secondary pt-3">County</p>
                  </div>
                ):('')}
              </div>
            ):null}

            {order && order.customer  && !order.customer.isExemptSalesTax && order.customer.deliveryAddress && order.customer.deliveryAddress.state === "WA" ? (
              <div>
                {order && order.customer && order.customer.vehicleSalesTaxPercent ? (
                  <div className="p-1 ml-4 mr-2">
                    <h5>{ order.customer.vehicleSalesTaxPercent }</h5>
                    <p className="mx-0 text-secondary pt-3">Vehicle Sales Tax</p>
                  </div>
                ):('')}
                
                {order && order.customer && order.customer.vehicleTaxCounty ? (
                  <div className="p-1 mr-2">
                    <h5>{ order.customer.vehicleTaxCounty }</h5>
                    <p className="mx-0 text-secondary pt-3">County</p>
                  </div>
                ):('')}

                {order && order.customer && order.customer.nonVehicleSalesTaxPercent ? (
                  <div className="p-1 ml-4 mr-2">
                    <h5>{ order.customer.nonVehicleSalesTaxPercent }</h5>
                    <p className="mx-0 text-secondary pt-3">Non Vehicle Sales Tax</p>
                  </div>
                ):('')}
                
                {order && order.customer && order.customer.nonVehicleTaxCounty ? (
                  <div className="p-1 mr-2">
                    <h5>{ order.customer.nonVehicleTaxCounty }</h5>
                    <p className="mx-0 text-secondary pt-3">County</p>
                  </div>
                ):('')}
              </div>
            ):null}

          </div>
        </div>
      ):null}

      <div className="p-4">
        {order && order.customer && order.customer.cosigners ? (
          <h5 className="mb-2">CO-SIGNERS</h5>
        ):null}

        {order && order.customer && order.customer.cosigners ? ( order.customer.cosigners.map(cosigner => (
            <div className="d-flex justify-content-around" key={cosigner.id}>
              <div className="p-1">
                <h5>{ cosigner.name }</h5>
                <p className="mx-0 text-secondary pt-3">Name</p>
              </div>
              <div className="p-1">
                <h5>{ formatPhoneNumber(cosigner.phone) }</h5>
                <p className="mx-0 text-secondary pt-3">Phone</p>
              </div>
            </div>
          ))):('')}
      </div>

      <div className="p-4 bg-light ">
          {order && order.customer && order.customer.contacts ? (
            <h5 className="mb-2">ADDITIONAL CONTACTS</h5>
          ):null}
          
          {order && order.customer && order.customer.contacts ? ( order.customer.contacts.map(contact => (
              <div className="d-flex p-3  justify-content-around" key={contact.id}>
                <div className="p-1">
                  <h5>{ contact.name }</h5>
                  <p className="mx-0 text-secondary pt-3">Name</p>
                </div>

                <div className="p-1">
                  <h5>{ formatPhoneNumber(contact.phone) }</h5>
                  <p className="mx-0 text-secondary pt-3">Phone</p>
                </div>

                <div className="p-1">
                  <h5>{ contact.email }</h5>
                  <p className="mx-0 text-secondary pt-3">Email</p>
                </div>

                <div className="p-1">
                  <h5>{ contact.company }</h5>
                  <p className="mx-0 text-secondary pt-3">Company</p>
                </div>

                <div className="p-1">
                  <h5>{ formatPhoneNumber(contact.fax) }</h5>
                  <p className="mx-0 text-secondary pt-3">Fax</p>
                </div>
              </div>
          ))):('')}
      </div>
    </div>
    </>
  );
};

export default CustomerSection;

export const GET_FOCUS  = "GET_FOCUS";
export const SET_FOCUS  = "SET_FOCUS";
export const SAVE       = "SAVE";

export const getLenderFocus = () => {
    return {
        type: GET_FOCUS
    }
}

export const setLenderFocus = (data) => {
    return {
        type: SET_FOCUS, 
        payload: data 
    }
}

export const saveData = (data) => {
    return {
        type: SAVE, 
        payload: data 
    }
}

const defaultOrdersState = {
    lender_option_focus_id: null,
};

export const lenderFocusReducer = (store = defaultOrdersState , action) => {
    switch (action.type) {
        case SET_FOCUS: 
            return {
                ...store,
                lender_option_focus_id: action.payload.id
            }
        case GET_FOCUS: 
            return store.lender_option_focus_id;
        default:
            return store
     }
}

import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import { useOrder } from "../../../../../../../redux/orders/order"
import { removeChars, formatter } from "../../../../../../../utlis/formatters";
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { TECBillOfSaleGlobalAutofill, TECBillOfSaleAutofillProductDetails, preProcessPayload } from "../DocumentAutofill/TECBillofSale"


const TECBillofSale = ({document_id, location, getDocuments, passedDocumentSlug, setDocumentFocus}) => {
  const documentSlug = "tec-bill-of-sale";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  const order = useOrder();

  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  const [document, setDocument] = useState(undefined);
  const [previousDocumentId, setPreviousDocumentId] = useState(0);

  var initialDocumentStructure = {
    title_number: undefined,
    year: undefined,
    make: undefined,
    state_of: undefined,
    serial_number: undefined,

    purchaser_line_1: undefined,
    purchaser_line_2: undefined,
    purchaser_line_3: undefined,

    sold_to_line_1: undefined,
    sold_to_line_2: undefined,
    sold_to_line_3: undefined,

    lienholder_line_1: undefined,
    lienholder_line_2: undefined,
    lienholder_line_3: undefined,

    date_day: undefined,
    day_of: undefined,
    date_month: undefined,

    authorized_agent: undefined,

    dynamic_text_1: undefined,
    dynamic_text_2: undefined,
    
    tec_footer_label: undefined,
    pg_no: undefined,
    location_phone: undefined,
    location_address: undefined,
    location_email: undefined
  }

  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  const handleDocumentValuesChange = e =>{
    e.preventDefault();
    const {name,value}=e.target;
    if(name.includes('year')) {
        let newStr = value.replace(/[^0-9]/g,'');
        newStr = newStr.length > 4 ? documentValues[name].replace(/[^0-9]/g,'') : newStr;
        setDocumentValues({...documentValues,[name]:newStr});
    } else if(name.includes('license_number') || name.includes('serial_motor_number')) {
        let newStr = value.replace(/[^0-9]/g,'');
        setDocumentValues({...documentValues,[name]:newStr});
    } else if(name === 'odo_reading') {
        let val = removeChars(value);
        setDocumentValues({...documentValues,[name]:val});
    } else if(name === 'amount') {
      let val = '$' + (removeChars(value));
      setDocumentValues({...documentValues,[name]:val});
    }else {
        setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  
  // const [documentLoaded, setDocumentLoaded] = useState(false);
    
const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('TECBillofSale.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])
  
const [previewLoaded, setPreviewLoaded] = useState(false);
const [dataLoaded, setDataLoaded] = useState(false);
useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      // setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, loadDocument ])

  // const [productVal, setproductVal] = useState(undefined);
  const [assignedProduct, setAssignedProduct] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  // const [usedProductVal, setusedProductVal] = useState(undefined);
  // const [selectedUsedProduct, setselectedUsedProduct] = useState(undefined);
  // const [inputUsedProductVal, setinputUsedProductVal] = useState(undefined);
  // const [usedProductOptions, setUsedProductOptions] = useState(undefined);
  // const [usedProductToggleAutoFill, setUsedProductToggleAutoFill] = useState(false);


  // const [tradeinVal, setTradeinVal] = useState(undefined);
  // const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  // const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);

  // const [previousOrderId, setPreviousOrderId] = useState(undefined);

  // Dropdowns //

  // if(order && order.products && order.products.length > 0 && productOptions === undefined){
  //   let getProductOptions = [];
  //   order.products.map(product => {
  //     let str = `${product.stockNumber.toString().toUpperCase()}`;
  //     let obj = {id: product.id, name: str}
  //     getProductOptions.push(obj);
  //   })
  //   setproductOptions(getProductOptions);
  // }

  // if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
  //   let getTradeinOptions = [];
  //   order.tradeins.map(tradein => {
  //     let str = `${tradein.year} ${tradein.make} ${tradein.model}`;
  //     let obj = {id: tradein.id, name: str}
  //     getTradeinOptions.push(obj);
  //   })
  //   setTradeinOptions(getTradeinOptions);
  // }

  const previewPayloadDocument = useCallback(async (documentValues) => {

    var payload = documentValues;
  
    payload = preProcessPayload(payload, null, null, null, null);
  
  
    // if(payload.serial_number){
    //   payload.serial_number = payload.serial_number.toString().toUpperCase();
    // }
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });
    if(document){
      if(document.documentTemplate.slug === documentSlug){
  
        var docCopy = JSON.parse(JSON.stringify(document));
        docCopy.payload =  JSON.stringify(payload);
        delete docCopy.documentStatus;
        try {
          let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
          const file = new Blob(
            [response.data], 
            {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
            setdocumentUrl(fileURL)
        } catch (err) {
          recordFrontEndError('TECBillofSake.jsx', 'Error previewing document payload: ' + err)
        }
      }
    }
  },[ axios, document ])
  

 // Autofill //

 const AutofillProductDetails = useCallback(async (selectedProduct, autofillProductDetails=true) => {
  let newValues = TECBillOfSaleAutofillProductDetails(selectedProduct, documentValues, null, null, autofillProductDetails);

  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setSaveSuccess(false);
  }

  
  // var newValues = documentValues;
  // if(selectedProduct.year){
  //   newValues.year = (selectedProduct.year).trim();
  // }
  // if(selectedProduct.make){
  //   newValues.make = (selectedProduct.make).trim().toString().toUpperCase();
  // }
  // if(autofillProductDetails){
  //   if(selectedProduct.vin){
  //     newValues.serial_number = (selectedProduct.vin).trim().toUpperCase();
  //   }
  // }
  // newValues.state_of = (selectedProduct.registrationAddress?.state).trim();

  // // Wrong
  // // if(selectedProduct.shipTo){
  // //   newValues.purchaser_line_1 = selectedProduct.shipTo.name;
  // //   newValues.purchaser_line_3 = selectedProduct.shipTo.addressLine1;
  // //   if(selectedProduct.shipTo.addressLine2){
  // //     newValues.purchaser_line_3 = newValues.address+" "+selectedProduct.shipTo.addressLine2;
  // //   }
  // //   newValues.purchaser_line_4 = selectedProduct.shipTo.city+", "+selectedProduct.shipTo.state+" "+selectedProduct.shipTo.zip;
  // // }

  // if(selectedProduct.shipTo){
  //   newValues.sold_to_line_1 = selectedProduct.shipTo.name;
  //   newValues.sold_to_line_3 = selectedProduct.shipTo.addressLine1;
  //   if(selectedProduct.shipTo.addressLine2){
  //     newValues.sold_to_line_3 = selectedProduct.shipTo.addressLine1+" "+selectedProduct.shipTo.addressLine2;
  //   }
  //   newValues.sold_to_line_4 = selectedProduct.shipTo.city+", "+selectedProduct.shipTo.state+" "+selectedProduct.shipTo.zip;
  // }

  // // Wrong
  // // if(selectedProduct.soldTo){
  // //   newValues.sold_to_line_1 = selectedProduct.soldTo.name;
  // //   newValues.sold_to_line_3 = selectedProduct.soldTo.addressLine1;
  // //   if(selectedProduct.soldTo.addressLine2){
  // //     newValues.sold_to_line_3 = newValues.address+" "+selectedProduct.soldTo.addressLine2;
  // //   }
  // //   newValues.sold_to_line_4 = selectedProduct.soldTo.city+", "+selectedProduct.soldTo.state+" "+selectedProduct.shipTo.zip;
  // // }

  // if(selectedProduct.soldTo){
  //   newValues.purchaser_line_1 = selectedProduct.soldTo.name;
  //   newValues.purchaser_line_3 = selectedProduct.soldTo.addressLine1;
  //   if(selectedProduct.soldTo.addressLine2){
  //     newValues.purchaser_line_3 = selectedProduct.soldTo.addressLine1+" "+selectedProduct.soldTo.addressLine2;
  //   }
  //   newValues.purchaser_line_4 = selectedProduct.soldTo.city+", "+selectedProduct.soldTo.state+" "+selectedProduct.soldTo.zip;
  // }

  // if(selectedProduct.fundingDetail){
  //   if(selectedProduct.fundingDetail.fundingMethod === "tec-financing" || selectedProduct.fundingDetail.fundingMethod === "finance-only" || selectedProduct.fundingDetail.fundingMethod === "direct-deal"){
  //     if(selectedProduct.fundingDetail.finalizedLendingOption){
  //       newValues.lienholder_line_1 = selectedProduct.fundingDetail.finalizedLendingOption.bankName;
  //       newValues.lienholder_line_2 = selectedProduct.fundingDetail.finalizedLendingOption.lienAddress;
  //       newValues.lienholder_line_3 = selectedProduct.fundingDetail.finalizedLendingOption.lienCity+", "+selectedProduct.fundingDetail.finalizedLendingOption.lienState+" "+selectedProduct.fundingDetail.finalizedLendingOption.lienZip;
  //     }
  //   }

  //   if(selectedProduct.fundingDetail.fundingMethod === "cash-with-lien"){
  //     if(selectedProduct.fundingDetail.cashLienHolder){
  //       newValues.lienholder_line_1 = selectedProduct.fundingDetail.cashLienHolder.bankName;
  //       newValues.lienholder_line_2 = selectedProduct.fundingDetail.cashLienHolder.addressLine1+" "+(selectedProduct.fundingDetail.cashLienHolder.addressLine2 ? selectedProduct.fundingDetail.cashLienHolder.addressLine2 : "");
  //       newValues.lienholder_line_3 = selectedProduct.fundingDetail.cashLienHolder.city+", "+selectedProduct.fundingDetail.cashLienHolder.state+" "+selectedProduct.fundingDetail.cashLienHolder.zip;
  //     }
  //   }

  // }


  // setDocumentValues(newValues);

  // // setProductToggleAutoFill(false);
  // // previewPayloadDocument(documentValues);
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[document, documentValues, previewPayloadDocument]);


 const GlobalAutofill = useCallback(async (autofillFirstProduct=false) => {
  let newValues = TECBillOfSaleGlobalAutofill(documentValues, order, null, null, location, null, null, autofillFirstProduct);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  // var newValues = documentValues;
  // if(order && order.customer && order.customer.name){
  //   newValues.purchaser_line_1 = order.customer.name;

  //   if(order.customer.dba){
  //     newValues.purchaser_line_1 += " dba "+order.customer.dba;
  //   }

  //   if(order.customer.cosigners && order.customer.cosigners.length > 0){
  //     let cosigner = order.customer.cosigners[0];
  //     if(cosigner && cosigner.name){
  //       newValues.purchaser_line_2 = cosigner.name;
  //     }
  //   }
  // }

  // if(order && order.customer && order.customer.deliveryAddress){
  //   var addressLine = '';
  //   if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
  //     addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
  //   }else{
  //     addressLine = order.customer.deliveryAddress.addressLine1;
  //   }
  //   newValues.purchaser_line_3 = addressLine;
  // }

  // if(order && order.customer && order.customer.deliveryAddress){
  //   newValues.purchaser_line_4 = (order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
  // }

  // if(location !== undefined){
  //   if(location.state === "CA"){
  //     newValues.dynamic_text_1 = "TEC of California, Inc. dba Tec Equipment located at "+location.address+", "+location.city+", "+location.state+", "+location.zip+", being owner of aforementioned motor vehicle, hereby sells and assigns all its rights, title and interest in said certificate of title and motor vehicle to:";  
  //     newValues.dynamic_text_2 = "TEC OF CALIFORNIA, INC., CALIFORNIA DEALER LICENSE NO. 31422";  
  //   }else{
  //     newValues.dynamic_text_1 = "TEC Equipment, Inc. located at "+location.address+", "+location.city+", "+location.state+", "+location.zip+", being owner of aforementioned motor vehicle, hereby sells and assigns all its rights, title and interest in said certificate of title and motor vehicle to:";
  //   }
  //   if(location.state === "OR"){
  //     newValues.dynamic_text_2 = "TEC EQUIPMENT, INC., OREGON DEALER LICENSE NO. 555";  
  //   }
  //   if(location.state === "WA"){
  //     newValues.dynamic_text_2 = "TEC EQUIPMENT, INC., WASHINGTON DEALER LICENSE NO. 7936";  
  //   }
  //   if(location.state === "NV"){
  //     newValues.dynamic_text_2 = "TEC EQUIPMENT, INC., NEVADA DEALER LICENSE NO. 29622";  
  //   }
  //   if(location.state === "AZ"){
  //     newValues.dynamic_text_2 = "TEC EQUIPMENT, INC., ARIZONA DEALER LICENSE NO. L00014432";  
  //   }
  // }

  // // Get Location Address & Autofill Address
  // if(location !== undefined){
  //   newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
  // }

  if(location && location.phone){
    newValues.location_phone = location.phone;
  }
  
  if(location && location.email){
    newValues.location_email = location.email;
  }

  if(location){
    if(location.state === "CA"){
      newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
    }else{
      newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
    }
  }

  

  // if(order && order.documentationDate && documentValues.delivery_date === undefined){
  //   newValues.date_day = moment.utc(order.documentationDate).format("DD");
  //   newValues.day_of = moment.utc(order.documentationDate).format("YYYY");
  //   newValues.date_month = moment.utc(order.documentationDate).format("MM");
  // }

  // newValues.pg_no = "Pg #1";
  
  setDocumentValues(newValues);
  setHasUnsavedChanges(true);
  // setSaveSuccess(false);

  if(autofillFirstProduct){

    var foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }else if(document.assignedObjectType && document.assignedObjectType === 'order'){
      foundAssignedProduct = order.products[0];
    }

    AutofillProductDetails(foundAssignedProduct, false);
  }

},[ documentValues, order, location, document, AutofillProductDetails ])


const previewDocument = useCallback(async () => {
  try {
    let response = await axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
    const file = new Blob(
      [response.data], 
      {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      // window.open(fileURL);
    setdocumentUrl(fileURL)
  } catch (err) {
    recordFrontEndError('TECBillofSale.jsx', 'Error previewing document: '+ err)
  }
},[axios, document]); 

useEffect(() => {
  if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){
      existingPayload = true;
      setDocumentValues(documentPayload);
    }

    var foundAssignedOrder = null;
    if(document.assignedObjectType && document.assignedObjectType === 'order'){
      foundAssignedOrder = true;
    }

    var foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }

    if(foundAssignedProduct){
      setAssignedProduct(foundAssignedProduct);
    }
  
    // setAddressOptions(getOrderLevelAddresses(order));
    if(!existingPayload){
      GlobalAutofill();
      // previewPayloadDocument(documentValues);
      if(foundAssignedProduct){
        AutofillProductDetails(foundAssignedProduct)
      }
      if(foundAssignedOrder){
        // Autofill "See Schedule A"
        var copyDocumentValues = documentValues;
        copyDocumentValues.serial_number = "See Schedule A";
        setDocumentValues(copyDocumentValues);
        setHasUnsavedChanges(true);
        setSaveSuccess(false);

        //Get First Product
        if(order && order.products && order.products.length > 0){
          let firstProduct = order.products[0];
          AutofillProductDetails(firstProduct, false);
        }
      }
      // if(document){
      //   previewPayloadDocument(documentValues);
      // }
    }else{
      // if(document && document.itemIds){
      //   var itemIds = document.itemIds.split(',');
      //     var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
      //     let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
      //     let obj = {id: foundProduct.id, name: str}
      //     setproductVal(obj);
      //     // if(foundProduct){
      //     //   setAddressOptions(updateAddressOptions(foundProduct, order));
      //     // }
      // }
  
      previewDocument();
    }
    
    setDataLoaded(true);
  }else if(document && document.payload && dataLoaded && !previewLoaded){
    setPreviewLoaded(true);
    previewPayloadDocument(documentValues);
  }
},[documentValues, document, dataLoaded, AutofillProductDetails, GlobalAutofill, order, previewDocument, previewLoaded, previewPayloadDocument]);



  // Perform an .GET for the initial render
  // useEffect(()=>{
  //   if (documentUrl === "" && document) {
  //     previewDocument();
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[documentUrl, document]);

//   setTimeout(function(){
//     this.printIframeRef.contentWindow.document.getElementById('secondaryPrint').click()
// }.bind(this), 3000)

  // Component Events

  const updatePreview = e => {
    e.preventDefault();
    previewPayloadDocument(documentValues);
  }



  const handleSubmit = async event => {
    event.preventDefault();

    let payload = JSON.parse(JSON.stringify(documentValues));

    payload = preProcessPayload(payload, null, null, null, null);
  

    // const payloadString = JSON.stringify(documentValues);
    // var docCopy = document;
    // docCopy.payload = payloadString;
    // delete docCopy.documentStatus;
    
    // if(selectedProduct && selectedProduct.id){
    //   docCopy.itemIds = `${selectedProduct.id}`;
    // }


    // let additionalData = JSON.stringify(dropdownData);

    DocumentHandleSubmit(document, payload, null, () => {
      getDocuments();
      setSaveSuccess(true);
      setHasUnsavedChanges(false);
    });

    // try {
    //   await axios.put(`/document/${document.id}/prepare`, docCopy)
    //   await axios.post(`/document/${document.id}/generate`) 
    //   getDocuments();
    //   setSaveSuccess(true);
    //   setHasUnsavedChanges(false);
    // } catch (err) {
    //   recordFrontEndError('TECBillofSale.jsx', 'Error saving document: ' + err)
    // }
    
    
  };

const onCloseClick = e => {
  e.preventDefault()
  setPreviewLoaded(false);
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setdocumentUrl("");
  setDocument(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
  // setDocumentLoaded(false);
  setDataLoaded(false);
  setAssignedProduct(undefined);
  setDocumentFocus(undefined);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

  return (
    <>
      <div
        className="modal fade"
        // id="tec-vehicle-sale"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                TEC Bill of Sale
              </h5>
              <br />
              { hasClickedClose ? (
                <div className="d-flex">
                    <p className="m-1 pt-1 text-white">Save Changes?</p>
                    <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                    <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                </div>
              ): hasUnsavedChanges ? (
              <button
                type="button"
                className="btn-close bg-white"
                aria-label="Close"
                // onClick={() => setdocumentUrl("")}
                onClick={closeClicked}
              ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill(true);
                      AutofillProductDetails(assignedProduct);
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>
                  
                  <div className="row">

                  <h5>Select Vehicle</h5>


                  { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                      Product Snapshot
                      <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                      </div>
                    </div>
                  ):(
                    <div className="m-2 p-2 border">
                      <span>No Assigned Product</span>
                    </div>
                  )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Title No.</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="title_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.title_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">State Of</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="state_of"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => {
                            updatePreview(e)
                            } 
                          }
                          value={ documentValues.state_of ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Serial No.</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="serial_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => {
                            let updatedObject = documentValues;
                            updatedObject.serial_number = documentValues.serial_number.toUpperCase();
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,['serial_number']:documentValues.serial_number.toUpperCase()});
                            updatePreview(e);
                          }}
                          value={ documentValues.serial_number ?? '' }
                          />
                      </div>
                    </div>
                    <br />
                    
                    <br />
                    <hr></hr>

                    <h5>Purchaser Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Name (Line 1)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_line_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_line_1 ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Co Signer (Line 2)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_line_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_line_2 ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Address (Line 3)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_line_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_line_3 ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">City, State Zip (Line 4)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_line_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_line_4 ?? '' }
                          />
                      </div>
                    </div>

                    <h5>Purchaser Information (Sold To)</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Name (Line 1)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="sold_to_line_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.sold_to_line_1 ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Co Signer (Line 2)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="sold_to_line_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.sold_to_line_2 ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Address (Line 3)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="sold_to_line_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.sold_to_line_3 ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">City, State Zip (Line 4)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="sold_to_line_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.sold_to_line_4 ?? '' }
                          />
                      </div>
                    </div>

                    <br />
                    <hr></hr>

                    <h5>Lienholder Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lienholder_line_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lienholder_line_1 ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lienholder_line_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lienholder_line_2 ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">City, State Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lienholder_line_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lienholder_line_3 ?? '' }
                          />
                      </div>
                    </div>

                    <br />
                    <hr></hr>

                    <h5>Details</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Authorized Agent</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="authorized_agent"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.authorized_agent ?? '' }
                          />
                      </div>
                    </div>

                    <br />
                    <hr></hr>
           
                </div>
      
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
           { saveSuccess ? (
              <span
                className="btn btn-success mx-auto"
              >
                Saved Document
              </span>
            ):(
              <button
              type="button"
              className="btn btn-primary mx-auto"
              // data-bs-dismiss="modal"
              onClick={handleSubmit}
              >
              Save
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default TECBillofSale

// import * as api from '../../.././../../../utlis/api.js'
import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase, formatNameLastFirstMiddle } from "../../../../../../../utlis/formatters";

export const WAVehicleTitleApplicationGlobalAutofill = (documentValues, order, property=null, value=null) => {

    var newValues = documentValues;
    
    if(property ==='customer.email'){
      newValues.email_address = value
    }
    if(property ==='customer.name'){
      if(order.customer.type === "entity"){
        newValues.owner_type = "Business";
        newValues.owner_name_biz_address = value;
      }else{
        newValues.owner_type = "Individual";
        newValues.owner_name_biz_address = formatNameLastFirstMiddle(value);
      }
      if(order.customer.dba){
        newValues.owner_name_biz_address = order.customer.name+" dba "+order.customer.dba;
      }
    }
    if(property ==='customer.dba'){
      if(order.customer.type === "entity"){
        newValues.owner_name_biz_address = order.customer.name;
      }else{
        newValues.owner_name_biz_address = formatNameLastFirstMiddle(order.customer.name);
      }
      if(value!==''){
        newValues.owner_name_biz_address += " dba "+value;
      } else if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
            newValues.name += " AND "+cosigner.name;
        }
      }
    }
    if(property ==='customer.phone'){
      newValues.phone_num_s1 = formatPhoneNumber(value);
    }
    if(property ==='customer.cosigners[0].name'){
      if(value && value.length > 0){
        let cosigner = value[0];
        if(cosigner){
          newValues.owner_type_s2 = "Individual"
          newValues.phone_num_s2 = formatPhoneNumber(cosigner.phone);
          newValues.owner_name_biz_name_s2 = formatNameLastFirstMiddle(cosigner.name);
        }
      }
    }
    if(property ==='customer.deliveryAddress.addressLine1'){
      let addressLine = value
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        addressLine +=" "+order.customer.deliveryAddress.addressLine2;
    }
    newValues.wa_primary_res_address = addressLine+" "+(order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
  }
    if(property ==='customer.deliveryAddress.addressLine2'){
      let addressLine = order.customer.deliveryAddress.addressLine1
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        addressLine +=" "+value;
    }
    newValues.wa_primary_res_address = addressLine+" "+(order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
  }
    if(property ==='customer.deliveryAddress.city'){
      let addressLine = order.customer.deliveryAddress.addressLine1
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        addressLine +=" "+order.customer.deliveryAddress.addressLine2;
    }
    newValues.wa_primary_res_address = addressLine+" "+(value+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
  }
    if(property ==='customer.deliveryAddress.state'){
      let addressLine = order.customer.deliveryAddress.addressLine1
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        addressLine +=" "+order.customer.deliveryAddress.addressLine2;
    }
    newValues.wa_primary_res_address = addressLine+" "+(order.customer.deliveryAddress.city+", "+value+" "+order.customer.deliveryAddress.zip);
  }
    if(property ==='customer.deliveryAddress.zip'){
      let addressLine = order.customer.deliveryAddress.addressLine1
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        addressLine +=" "+order.customer.deliveryAddress.addressLine2;
    }
    newValues.wa_primary_res_address = addressLine+" "+(order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+value);
  }
    if(property ==='documentationDate'){
      newValues.sale_date = moment.utc(value).format("YYYY-MM-DD");
      newValues.delivery_date = moment.utc(value).format("YYYY-MM-DD");
      newValues.delivery_date_2 = moment.utc(value).format("YYYY-MM-DD");
      newValues.delivery_date_3 = moment.utc(value).format("YYYY-MM-DD");
  }
    if(property !== null && value !== null){
      return newValues;
    }

    if(order && order.customer && order.customer.email){
        newValues.email_address = order.customer.email;
    }
  
    if(order && order.customer){
      if(order.customer.type === "entity"){
        newValues.owner_type = "Business";
        newValues.owner_name_biz_address = order.customer.name;
      }else{
        newValues.owner_type = "Individual";
        newValues.owner_name_biz_address = formatNameLastFirstMiddle(order.customer.name);
      }
      newValues.phone_num_s1 = formatPhoneNumber(order.customer.phone);
  
      if(order.customer.dba){
        newValues.owner_name_biz_address = order.customer.name+" dba "+order.customer.dba;
      }
      if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner){
          newValues.owner_type_s2 = "Individual"
          newValues.phone_num_s2 = formatPhoneNumber(cosigner.phone);
          newValues.owner_name_biz_name_s2 = formatNameLastFirstMiddle(cosigner.name);
        }
      }
    }
    
    if(order && order.customer && order.customer.deliveryAddress){
      var addressLine = '';
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
      }else{
        addressLine = order.customer.deliveryAddress.addressLine1;
      }
      newValues.wa_primary_res_address = addressLine+" "+(order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
    }
  
    if(order && order.documentationDate && documentValues.sale_date === undefined){
      newValues.sale_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }
  
    if(order && order.documentationDate && documentValues.delivery_date === undefined){
      newValues.delivery_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }
  
    if(order && order.documentationDate && documentValues.delivery_date_2 === undefined){
      newValues.delivery_date_2 = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }
  
    if(order && order.documentationDate && documentValues.delivery_date_3 === undefined){
      newValues.delivery_date_3 = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }
  
    return newValues
}
  
export const WAVehicleTitleApplicationAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
  
    var newValues = documentValues;

    if(property === 'year' && value !== null){
      newValues.year = (value).toString().trim().replace(/['"]+/g, '');
  }

  if(property === 'make' && value !== null){
      newValues.make = (value).toString().trim();
  }

  if(property === 'model' && value !== null){
      newValues.model = (value).toString().trim();
  }


  if(property === 'vehicleType' && value !== null){
      newValues.vehicle_type = (value);
  }

  if(property !== null && value !== null){
      return newValues;
  }

    newValues.vin = (selectedProduct.vin).toString().toUpperCase();
    newValues.year_model = (selectedProduct.year).trim();
    newValues.make = (selectedProduct.make).trim();
    newValues.model = (selectedProduct.model).trim().toString().toUpperCase();
  
    if(selectedProduct.type.toUpperCase() === "NEW"){
      newValues.new_used_toggle = "new";
    }else{
      newValues.new_used_toggle = "used";
    }
  
    newValues.vehicle_type = selectedProduct.vehicle_type;
  
    return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  if(payload.form_purpose_toggle){
    // Reset all
    payload.transfer_title_cb = "no";
    payload.transfer_plate_cb = "no";
    payload.transfer_lease_cb = "no";

    switch(payload.form_purpose_toggle){
      case "lease":
        payload.transfer_lease_cb = "yes";
        break;
      case "plate":
        payload.transfer_plate_cb = "yes";
        break;
      case "title":
        payload.transfer_title_cb = "yes";
        break;
      default:
    }
  }

  if(payload.new_used_toggle){
    // Reset all
    payload.condition_new_cb = "no";
    payload.condition_used_cb = "no";

    switch(payload.new_used_toggle){
      case "new":
        payload.condition_new_cb = "yes";
        break;
      case "used":
        payload.condition_used_cb = "yes";
        break;

      default:
    }
  }

  if(payload.quick_title_toggle){
    // Reset all
    payload.quick_title_yes_cb = "no";
    payload.quick_title_no_cb = "no";

    switch(payload.quick_title_toggle){
      case "yes":
        payload.quick_title_yes_cb = "yes";
        break;
      case "no":
        payload.quick_title_no_cb = "yes";
        break;

      default:
    }
  }

  if(payload.park_donation_toggle){
    // Reset all
    payload.park_donation_yes_cb = "no";
    payload.park_donation_no_cb = "no";

    switch(payload.park_donation_toggle){
      case "yes":
        payload.park_donation_yes_cb = "yes";
        break;
      case "no":
        payload.park_donation_no_cb = "yes";
        break;

      default:
    }
  }

  if(payload.discover_pass_toggle){
    // Reset all
    payload.discover_pass_yes_cb = "no";
    payload.discover_pass_no_cb = "no";

    switch(payload.discover_pass_toggle){
      case "yes":
        payload.discover_pass_yes_cb = "yes";
        break;
      case "no":
        payload.discover_pass_no_cb = "yes";
        break;

      default:
    }
  }

  if(payload.joint_tenants_toggle){
    // Reset all
    payload.joint_tenants_yes_cb = "no";
    payload.joint_tenants_no_cb = "no";

    switch(payload.joint_tenants_toggle){
      case "yes":
        payload.joint_tenants_yes_cb = "yes";
        break;
      case "no":
        payload.joint_tenants_no_cb = "yes";
        break;

      default:
    }
  }

  if(payload.elt_toggle){
    // Reset all
    payload.elt_yes_cb = "no";
    payload.elt_no_cb = "no";

    switch(payload.elt_toggle){
      case "yes":
        payload.elt_yes_cb = "yes";
        break;
      case "no":
        payload.elt_no_cb = "yes";
        break;

      default:
    }
  }

  if(payload.vehicle_status_toggle){
    // Reset all
    payload.vehicle_status_new_cb = "no";
    payload.vehicle_status_used_cb = "no";
    payload.vehicle_status_prev_title_cb = "no";

    switch(payload.vehicle_status_toggle){
      case "new":
        payload.vehicle_status_new_cb = "yes";
        break;
      case "used":
        payload.vehicle_status_used_cb = "yes";
        break;
      case "prev_title":
        payload.vehicle_status_prev_title_cb = "yes";
        break;
      default:
    }
  }

  // X1.2- Field Type Specific Preview Formatting
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  // Data point formatting
  if(payload.exp_date){
    payload.exp_date = moment.utc(payload.exp_date).format("MM/DD/YY");
  }

  if(payload.exp_date_s2){
    payload.exp_date_s2 = moment.utc(payload.exp_date_s2).format("MM/DD/YY");
  }

  if(payload.legal_exp_date){
    payload.legal_exp_date = moment.utc(payload.legal_exp_date).format("MM/DD/YY");
  }

  if(payload.sale_date){
    payload.sale_date = moment.utc(payload.sale_date).format("MM/DD/YY");
  }

  if(payload.delivery_date){
    payload.delivery_date = moment.utc(payload.delivery_date).format("MM/DD/YY");
  }

  if(payload.delivery_date_2){
    payload.delivery_date_2 = moment.utc(payload.delivery_date_2).format("MM/DD/YY");
  }

  if(payload.delivery_date_3){
    payload.delivery_date_3 = moment.utc(payload.delivery_date_3).format("MM/DD/YY");
  }

  return payload;
}
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { removeChars, formatter, formatPhoneNumber, formatUpperCase } from "../../../../../../../utlis/formatters";
import { getOrderLevelAddresses, updateAddressOptions } from "../../../../../../../utlis/documentHelpers";
import { useOrder } from "../../../../../../../redux/orders/order"
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { CADMVDeclarationOfGVWGlobalAutofill, CADMVDeclarationOfGVWAutofillProductDetails, preProcessPayload } from "../DocumentAutofill/CADMVDeclarationOfGVW"

const CADMVDeclarationOfGVW = ({document_id, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "ca-dec-gvw";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  // const dispatch = useDispatch();

  // const stateAbbrev = States.map(state => {
  //   return state.abbreviation;
  // });

  const order = useOrder();

  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);
  
  // Data Structure & Init //
  
  var initialDocumentStructure = {
    name: undefined,
    fleet_number: undefined,
    no_ca_county: undefined,
    county: undefined,
    address: undefined,
    city: undefined,
    state: undefined,
    zip: undefined,
    mailing_address: undefined,
    mailing_city: undefined,
    mailing_state: undefined,
    mailing_zip: undefined,
    date_operated: undefined,
    cgw_code: undefined,
    gvw_code: undefined,
    vehicle_operated_underweight: undefined,
    make: undefined,
    vin: undefined,
    vln: undefined,
    vln_2: undefined,
    vin_2: undefined,
    make_2: undefined,
    vehicle_operated_underweight_2: undefined,
    gvw_code_2: undefined,
    cgw_code_2: undefined,
    date_operated_2: undefined,
    signature_date: undefined
  }
  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  // X1
  // const removeFormatting = e =>{
  //   const {name,value}=e.target;
  //   setDocumentValues({...documentValues,[name]:removeChars(value)});
  // }

  // X1 Reference Arrays - TODO: Possibly move these to back end as part of the template stucture
  const moneyFields = useMemo(()=>{return[""]},[]);
  const phoneFields = useMemo(()=>{return[""]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "vin_2"]},[]);
  const checkboxes = useMemo(()=>{return["no_ca_county"]},[]);

  // X1- Current General On Change
  const handleDocumentValuesChange = e =>{
    // e.preventDefault();
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatPhoneNumber(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  
  const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      }catch(err){
        recordFrontEndError('CADMVDeclarationOfGVW.jsx', 'Error loading documnet: ' + err)
      }
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, documentLoaded, loadDocument ])

  const [assignedProduct, setAssignedProduct] = useState(undefined);
  // const [productVal, setproductVal] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  // const [tradeinVal, setTradeinVal] = useState(undefined);
  // const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  // const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);

  const [selectedAddress1, setSelectedAddress1] = useState(undefined);
  const [selectedAddress2, setSelectedAddress2] = useState(undefined);
  const [addressOptions, setAddressOptions] = useState(undefined);
  const [inputAddressVal, setinputAddressVal] = useState(undefined);

// Dropdowns //

// if(order && order.products && order.products.length > 0 && productOptions === undefined){
//   let getProductOptions = [];
//   order.products.map(product => {
//     let str = `${product.stockNumber.toString().toUpperCase()}`;
//     let obj = {id: product.id, name: str}
//     getProductOptions.push(obj);
//   })
//   setproductOptions(getProductOptions);
// }

// if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
//   let getTradeinOptions = [];
//   order.tradeins.map(tradein => {
//     let str = `${tradein.year} ${tradein.make} ${tradein.model.toString().toUpperCase()}`;
//     let obj = {id: tradein.id, name: str}
//     getTradeinOptions.push(obj);
//   })
//   setTradeinOptions(getTradeinOptions);
// }

// function getOrderLevelAddresses(order){
//   let getAddressOptions = [];
//   if(order.customer && order.customer.deliveryAddress){
//     var addressLine = '';
//     if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
//       addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
//     }else{
//       addressLine = order.customer.deliveryAddress.addressLine1;
//     }
//     let city = order.customer.deliveryAddress.city;
//     let state = order.customer.deliveryAddress.state;
//     let zip = order.customer.deliveryAddress.zip;

//     let obj = {slug: "customer-delivery", name: "Customer Delivery", address: addressLine, city: city, state: state, zip: zip};
//     getAddressOptions.push(obj);
//   }

//   if(order.customer && order.customer.mailingAddress){

//     if(order.customer.mailingAddress.addressLine2 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
//       addressLine = (order.customer.mailingAddress.addressLine1+" "+order.customer.mailingAddress.addressLine2);
//     }else{
//       addressLine = order.customer.mailingAddress.addressLine1;
//     }
//     let city = order.customer.mailingAddress.city;
//     let state = order.customer.mailingAddress.state;
//     let zip = order.customer.mailingAddress.zip;

//     let obj = {slug: "customer-mailing", name: "Customer Mailing", address: addressLine, city: city, state: state, zip: zip};
//     getAddressOptions.push(obj);
//   }
//   return getAddressOptions;
// }

  // Autofill //

  const GlobalAutofill = useCallback(async () => {
    let newValues = CADMVDeclarationOfGVWGlobalAutofill(documentValues, order);
    if(newValues){
      setDocumentValues(newValues);
      setHasUnsavedChanges(true);
      setSaveSuccess(false);
    }
    // var newValues = documentValues;

    // if(order && order.customer){
    //   newValues.name = order.customer.name;
    //   if(order.customer.dba && order.customer.dba !== ""){
    //     newValues.name += " dba " + order.customer.dba;
    //   } 
      
    //   if(order.customer.cosigners && order.customer.cosigners.length > 0){
    //     let cosigner = order.customer.cosigners[0];
    //     if(cosigner){
    //       newValues.name += " AND " +cosigner.name;
    //     }
    //   }
    // }

    // if(order && order.customer){
    //     if(order.customer.deliveryAddress){
    //     var addressLine = '';
    //     if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
    //       addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
    //     }else{
    //       addressLine = order.customer.deliveryAddress.addressLine1;
    //     }
    //     newValues.address = addressLine;
    //     newValues.city = order.customer.deliveryAddress.city;
    //     newValues.state = order.customer.deliveryAddress.state;
    //     newValues.zip = order.customer.deliveryAddress.zip;
    //   }

    //   if(!order.customer.isExemptSalesTax && order.customer.vehicleTaxCounty){
    //     newValues.county = order.customer.vehicleTaxCounty;
    //   }
    // }

    // if(order && order.documentationDate){
    //   newValues.signature_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    // }

    // setDocumentValues(newValues);
    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  },[ documentValues, order ])

  // const preProcessPayload = useCallback((payload) => {

  //   // X1.2- Field Type Specific Preview Formatting
  //   Object.keys(payload).forEach(key => {
  //     if(moneyFields.indexOf(key) >= 0){
  //         payload[key] = formatMoneyValue(payload[key]);
  //     }else if(phoneFields.indexOf(key) >= 0){
  //         payload[key] = formatPhoneNumber(payload[key]);
  //     }else if(upperCaseFields.indexOf(key) >= 0){
  //         payload[key] = formatUpperCase(payload[key]);
  //     }
  //   });
  //   setDocumentValues(JSON.parse(JSON.stringify(payload)));
  //   Object.keys(payload).forEach(key => {
  //     if(checkboxes.indexOf(key) >= 0){
  //       payload[key] = payload[key] ? "yes": "no";
  //     }
  //   });

  //   // Data point formatting
  //   if(payload.date_operated){
  //     payload.date_operated = moment.utc(payload.date_operated).format("MM/DD/YY");
  //   }

  //   if(payload.date_operated_2){
  //     payload.date_operated_2 = moment.utc(payload.date_operated_2).format("MM/DD/YY");
  //   }
  
  //   if(payload.signature_date){
  //     payload.signature_date = moment.utc(payload.signature_date).format("MM/DD/YY");
  //   }

  //   return payload;
  // },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])


  const previewPayloadDocument = useCallback(async (documentValues) => {
    var payload = documentValues;
      Object.keys(payload).forEach(key => {
        if (payload[key] === null || payload[key] === undefined) {
          delete payload[key];
        }
      });
      
      if(document){
        var docCopy = JSON.parse(JSON.stringify(document));
        docCopy.payload =  JSON.parse(JSON.stringify(payload));
        docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
        docCopy.payload =  JSON.stringify(docCopy.payload);
        delete docCopy.documentStatus;
        
        try {
          let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
          const file = new Blob(
            [response.data], 
          {type: 'application/pdf'});
          const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
          setdocumentUrl(fileURL)
        } catch (err) {
          recordFrontEndError('CADMVDeclarationOfGVW.jsx', 'Error previewing document payload: ' + err)
        }
    }
  },[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


  const AutofillProductDetails = useCallback(async (selectedProduct) => {
    let newValues = CADMVDeclarationOfGVWAutofillProductDetails(selectedProduct, documentValues);

    if(newValues){
      setDocumentValues(newValues);
      previewPayloadDocument(documentValues);
      setHasUnsavedChanges(true);
      if(document){
        previewPayloadDocument(documentValues);
      }
      setSaveSuccess(false);
    }

    // var newValues = documentValues;
    // newValues.year = (selectedProduct.year).trim();
    // newValues.make = (selectedProduct.make).trim();
    // newValues.model = (selectedProduct.model).trim();
    // newValues.stock_number = (selectedProduct.stockNumber);
    // newValues.mileage = (selectedProduct.mileage);
    // newValues.serial_number = (selectedProduct.serialNumber);
    // newValues.vin = (selectedProduct.vin);
    
    // newValues.sale_price = (selectedProduct.price);

    // // newValues.documentation_fee = (selectedProduct); 
    // // newValues.elt_fee = (selectedProduct);
    // // newValues.subtotal = (selectedProduct);
    // // newValues.tire_tax = (selectedProduct);
    // // newValues.sales_tax = (selectedProduct);
    // // newValues.extended_warranty = (selectedProduct);
    // // newValues.license_fee = (selectedProduct);
    // // newValues.cash_delivery_price = (selectedProduct);
    // // newValues.down_payment = (selectedProduct); // Calculate
    // // newValues.unpaid_balance_due = (selectedProduct);


    // setDocumentValues(newValues);
    // // setProductToggleAutoFill(false);
    // if(document){
    //   previewPayloadDocument(documentValues);
    // }
    //   // setAddressOptions(updateAddressOptions(selectedProduct, order));
    //   setHasUnsavedChanges(true);
    //   setSaveSuccess(false);

    },[ document, documentValues, previewPayloadDocument ]);



  
   


  // Document BREAD + Preview // TODO: Move to Redux
  useEffect(() => {
    if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){
  
      existingPayload = true;
  
      if(documentPayload.date_operated){
        documentPayload.date_operated = moment.utc(documentPayload.date_operated).format("YYYY-MM-DD");
      }

      if(documentPayload.date_operated_2){
        documentPayload.date_operated_2 = moment.utc(documentPayload.date_operated_2).format("YYYY-MM-DD");
      }

      if(documentPayload.signature_date){
        documentPayload.signature_date = moment.utc(documentPayload.signature_date).format("YYYY-MM-DD");
      }
  
      setDocumentValues(documentPayload);

      // Loading Use Effect
      if(document.additionalData){
        const methodMap = { 
          selectedAddress1: setSelectedAddress1, 
          selectedAddress2: setSelectedAddress2, 
        };

        let parsedAdditionalData = JSON.parse(document.additionalData);
        Object.keys(parsedAdditionalData).forEach(function(key) {
          if(methodMap[key]){
            methodMap[key](parsedAdditionalData[key]);
          }
        });
      }

    }

    var foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }

    setAddressOptions(getOrderLevelAddresses(order));
    if(foundAssignedProduct){
        setAssignedProduct(foundAssignedProduct);
        setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
    }
    
  
      if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
      }
      
      setDataLoaded(true);
    }
  },[ documentValues, document, dataLoaded, AutofillProductDetails, GlobalAutofill, order, previewPayloadDocument ])

  

    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }


  const handleSubmit = async event => {
    event.preventDefault();

    let payload = JSON.parse(JSON.stringify(documentValues));

    payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);


  

    //Save Dropdown Selections
    let dropdownData = {};
    if(selectedAddress1){
      dropdownData.selectedAddress1 = {
        id: selectedAddress1.id,
        name: selectedAddress1.name
      };
    }
    if(selectedAddress2){
      dropdownData.selectedAddress2 = {
        id: selectedAddress2.id,
        name: selectedAddress2.name
      };
    }

    let additionalData = JSON.stringify(dropdownData);

    DocumentHandleSubmit(document, payload, additionalData, () => {
      getDocuments();
      setSaveSuccess(true);
      setHasUnsavedChanges(false);
    });

    // docCopy.additionalData = JSON.stringify(dropdownData);

    // try {
    //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
    //   if (res) {
    //     axios.post(`/document/${document.id}/generate`) 
    //     getDocuments();
    //     setSaveSuccess(true);
    //     setHasUnsavedChanges(false);
    //   }
    // } catch (err) {
    //   recordFrontEndError('CADMVDeclarationofGVW.jsx', 'Error saving document: ' + err)
    // }
  }

// Component Helpers //

// useEffect(() => {
  //Autofill Product Details After Selection

// },[ selectedProduct, productToggleAutoFill ])

// useEffect(() => {
//   //Autofill Tradein Details After Selection
//   if(selectedTradein !== undefined && tradeinToggleAutoFill){
//     var newValues = documentValues;
//     newValues.trade_year = (selectedTradein.year);
//     newValues.trade_make = (selectedTradein.make);
//     newValues.trade_model = (selectedTradein.model);
//     newValues.trade_body_type = (selectedTradein.seriesBodyType);
//     newValues.trade_serial_number = (selectedTradein.serialNumber);
//     newValues.balance_owner = (order.customer.name); //Need specific field for TRADE IN OWNER?
//     newValues.balance_owner_address = (order.customer.deliveryAddress.addressLine1);
//     newValues.amount = (selectedTradein.balanceOwed);
//     newValues.trade_allowance = (selectedTradein.tradeAllowanceAtTerms);
//     newValues.balance_owed = (selectedTradein.balanceOwed);
//     newValues.cash_refund = (selectedTradein.cashRefundToCustomer);
//     newValues.net_trade_allowance = (selectedTradein.finalTradeAllowance);
//     // newValues.cash_down_payment = (selectedTradein);

//     setDocumentValues(newValues);
//     setTradeinToggleAutoFill(false);
//     if(document){
//       previewPayloadDocument(documentValues);
//     }
//   }
// },[ selectedTradein, tradeinToggleAutoFill ])

//Autofill Address Details After Selection
function AutoFillAddress(addressSelected, selectionInstance){
  var newValues = documentValues;
  if(selectionInstance){
    if(selectionInstance === "buyer-address"){
      newValues.address = addressSelected.address;
      newValues.city = addressSelected.city;
      newValues.state = addressSelected.state;
      newValues.zip = addressSelected.zip;
    }

    if(selectionInstance === "mailing-address"){
      newValues.mailing_address = addressSelected.address;
      newValues.mailing_city = addressSelected.city;
      newValues.mailing_state = addressSelected.state;
      newValues.mailing_zip = addressSelected.zip;
    }
  }
  setDocumentValues(newValues);
  if(document){
    previewPayloadDocument(documentValues);
  }
  setHasUnsavedChanges(true);
  setSaveSuccess(false);
}

const onCloseClick = e => {
  e.preventDefault()
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setdocumentUrl("");
  setDocument(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
  setDocumentLoaded(false);
  setDataLoaded(false);
  setAssignedProduct(undefined);
  setSelectedAddress1(undefined);
  setSelectedAddress2(undefined);
  setAddressOptions(undefined);
  setinputAddressVal(undefined);
  setDocumentFocus(undefined);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}
  return (
    <>
      <div
        className="modal fade"
        // id="ca-dec-gvw"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                CA - DMV Declaration of GVW
              </h5>
              <br />
              { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1 pt-1 text-white">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                  </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}
                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                  
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill();
                      AutofillProductDetails(assignedProduct);
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>
                  <br />

                  { assignedProduct ? (
                      <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                          Product Snapshot
                          <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                          <h5>SN: { assignedProduct.stockNumber }</h5>
                          <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                          <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                          </div>
                      </div>
                  ):(
                      <div className="m-2 p-2 border">
                          <span>No Assigned Product</span>
                      </div>
                  )}
                  <div className="row">
                    {/* <h5>Equipment</h5> */}
                    {/* <div className="d-flex justify-content-evenly">
                      <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                          style={{ width: '100%'}}
                          value={ (productVal && productVal.name) ?? null}
                          onChange={(event, newValue) => {
                              setproductVal(newValue);
                              setselectedProduct(order.products.find((product) => product.id === newValue.id));
                              setProductToggleAutoFill(true);
                              // resetVariables();
                          }}
                          inputValue={inputProductVal}
                          onInputChange={(event, newInputValue) => {
                              setinputProductVal(newInputValue);
                          }}
                          //id="controllable-states-demo"
                          options={productOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="PRODUCT" />}
                          renderOption={(props, option) => {
                              return (
                              <li {...props} key={option.id}>
                                  {option.name}
                              </li>
                              );
                          }}
                          />
                      </div>
                    </div>
                  </div> */}
                  <label htmlFor="stock" style={{ fontSize: '12px' }}><b>A. REGISTERED OWNER OR LESSEE INFORMATION</b></label>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-9">
                        <label htmlFor="stock">Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Fleet Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="fleet_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.fleet_number ?? '' }
                          />
                      </div>
                    </div>

                    {addressOptions && addressOptions.length > 0 ? (  
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress1 && selectedAddress1.name) ?? null}
                          name="buyer-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress1(newValue);
                            AutoFillAddress(newValue, "buyer-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="ADDRESS" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>      ):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}
                    

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.address ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.zip ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly mb-3">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">County</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="county"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.county ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <FormControl className="px-3 pt-2">
                            <FormControlLabel 
                              className="p-4" 
                              control={<input type="checkbox"
                                checked={ documentValues.no_ca_county }
                                name="no_ca_county"
                                onChange={handleDocumentValuesChange}
                                onBlur={(e) => updatePreview(e)}
                                className="m-2"
                                />} 
                              label="No CA County & Used Out-of-State" />
                        </FormControl>
                      </div>
                    </div>
                    <hr></hr>

                    {addressOptions && addressOptions.length > 0 ? (  
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress2 && selectedAddress2.name) ?? null}
                          name="mailing-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress2(newValue);
                            AutoFillAddress(newValue, "mailing-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="MALING ADDRESS" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>      ):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Mailing Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_address ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Mailing City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_city ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Mailing State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Mailing Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_zip ?? '' }
                          />
                      </div>
                    </div>
                    
                    <div className="d-flex justify-content-evenly">
                    </div>

                    <label htmlFor="stock" style={{ fontSize: '12px' }}><b>C. DECLARATION</b></label>
                    
                  </div>

                    <div className="d-flex justify-content-evenly" style={{ fontSize: '12px' }}>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Vehicle License Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vln"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vln ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-9">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly" style={{ fontSize: '12px' }}>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Vehicle Operated Underweight</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vehicle_operated_underweight"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vehicle_operated_underweight ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly mb-3" style={{ fontSize: '12px' }}>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">CGW Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="cgw_code"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.cgw_code ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">GVW Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="gvw_code"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.gvw_code ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">First Operated Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date_operated"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date_operated ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly" style={{ fontSize: '12px' }}>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Vehicle License Number (2)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vln_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vln_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-9">
                        <label htmlFor="stock">VIN (2)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin_2"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin_2 ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly" style={{ fontSize: '12px' }}>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Make (2)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Vehicle Operated Underweight (2)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vehicle_operated_underweight_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vehicle_operated_underweight_2 ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly mb-3" style={{ fontSize: '12px' }}>
                      <div className="p-2 col-4">
                        <label htmlFor="stock">CGW Code (2)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="cgw_code_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.cgw_code_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-4">
                        <label htmlFor="stock">GVW Code (2)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="gvw_code_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.gvw_code_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-4">
                        <label htmlFor="stock">First Operated Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date_operated_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date_operated_2 ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Signature Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="signature_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.signature_date ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6"></div>
                    </div>
                </div>
                <hr></hr>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default CADMVDeclarationOfGVW

import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector} from "react-redux";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { addProductAddition, editProductAddition, deleteProductAddition } from './../../../../../../../../redux/products';
import * as api from '../../../../../../../../utlis/api'
import { updateChangesArray, updateStatus } from "../../../../../../../../redux/saveAndCopy"

const Additions = ({ product }) => {
  let { id } = useParams();
  const dispatch = useDispatch()
  const changesArray = useSelector((state) => state?.copyChangesToDocumentsRedux.changesArray);
  // const [dataChangeArray, setDataChangeArray]   = useState([]);


// Update when addition data when properties change
const onEditAddition=(e, id=null , prop)=>{
  let { value, id:index } = e.target;

  if(changesArray && Array.isArray(changesArray)){

    let newArray = JSON.parse(JSON.stringify(changesArray));
    // If no additions array, create it.
    if(!newArray.additions){
      newArray.additions = [];
      dispatch(updateChangesArray(newArray));
    }

    if(!newArray.additions[index]){
      newArray.additions.push([]);
      dispatch(updateChangesArray(newArray));
    }

    if(newArray.additions[index] && !newArray.additions[index][prop]){
      newArray.additions[index][prop] = {};
      dispatch(updateChangesArray(newArray));
    }


    if((newArray?.additions?.length - 1) >= index){
      // if(newArray["additions"][index]["addition.enabled"]?.oldValue){
      //   dispatch(updateChangesArray( (prev) => ({
      //     ...prev,
      //     "additions": prev["additions"][index]["addition.enabled"] =   
      //     {
      //       "newValue": "yes",
      //       "oldValue": newArray["additions"][index]["addition.enabled"].oldValue
      //     }
      //   })));
      // }else{
      //   dispatch(updateChangesArray( (prev) => ({
      //     ...prev,
      //     "additions": prev["additions"][index]["addition.enabled"] =
      //     {
      //       "newValue": "yes",
      //       "oldValue":  product?.additions && product?.additions.length > 0 && product.additions[index] ? "yes" : "no"
      //     }
      //   })));
      // }

      if(newArray["additions"][index][prop]?.oldValue){
        newArray["additions"][index][prop] = {
          "newValue": value,
          "oldValue":  newArray["additions"][index][prop].oldValue
        }
        dispatch(updateChangesArray(...newArray));
      }else{
        if(newArray["additions"][index][prop]){

          newArray["additions"][index][prop] = {
            "newValue": value,
            "oldValue":  product?.additions && product?.additions.length > 0 && product.additions[index] ? product.additions[index][prop] : '--'
          }
          dispatch(updateChangesArray(...newArray));
        }
      }
    }
  }

    dispatch(editProductAddition({index, prop, value}))
    dispatch(updateStatus('unsaved'));
  }

  // Save a new addition
  const addNewAddition = (e) => {
    e.preventDefault();
    let initAddition= {
      description:'',
      includedInSalesPrice: false,
      itemId: product?.id,
      price: undefined,
      serialNumber: '',
      subjectToFet: false,
      subjectToSalesTax: false,
      type: '',
      invalid:false
    }

    if(changesArray && Array.isArray(changesArray)){

      let newArray = JSON.parse(JSON.stringify(changesArray));

      if(!newArray.additions){
        newArray.additions = [initAddition];
        dispatch(updateChangesArray(newArray));
      }else{
        newArray.additions.push(initAddition);
        dispatch(updateChangesArray(newArray));
      }

    }

    dispatch(addProductAddition({additionObject:initAddition, orderId:id, productId:product?.id}))
    dispatch(updateStatus('unsaved'));
  }
  

  // Delet an addition
  const deleteAddition = (e, additionIndex) => {
    e.preventDefault();
    let {id:additionId} = e.target
    let additionsCopy = [...product.additions]
    let filteredArr = additionsCopy.filter((addition, i)=>{ 
      let filtered
      if (addition?.id){
        filtered = (addition.id.toString()!==additionId)
        if (!filtered) api.deleteProductAddition(id, product.id, additionId)
      } else {
        filtered =  (i !== additionIndex)
      } 
      return filtered
    })
  
    additionId && api.deleteProductAddition(id,product.id, additionId)
    dispatch(deleteProductAddition(filteredArr))
    dispatch(updateStatus('unsaved'));
  }
     
  return (
      <div>
        {/* ------------------------------ Row 6 ------------------------------------ */}
        <div className="row">
          <div className="col-12 bg-primary py-3 text-white d-flex" style={{marginLeft: "-20px"}}>
            <h6 style={{marginRight: "10px", marginLeft: "50px"}}>Additions (Included in Sales Price)</h6>
            <AddCircleOutlineIcon 
              disabled="disabled"
              aria-label='add new addition'
              style={{cursor: "pointer"}} 
              onClick={e => addNewAddition(e) }
              data-testid="product.addition.control.addUpgradeButton"
            />
          </div>
      {product?.additions?.length > 0 && product?.additions.map((addition, index) => {
        return (
          <div key={index}>
            <div className="row mt-3">
                <div className="d-flex mb-3 ">
                  <div className="col-5 mx-3">
                    <label htmlFor="stock">SERIAL # <span style={{ color: 'red' }}>*</span></label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1 "
                      value={addition.serialNumber ?? ''}
                      id={index}
                      data-testid="product.addition.serialNumber"
                      aria-label='addition serial number'
                      onChange={(e) => onEditAddition(e, addition.id, 'serialNumber')}
                    />
                    <span style={{display: (addition?.invalid && addition?.serialNumber=== '')?'block':'none'}} className="text-danger small">Serial number is required.</span>
                  </div>
                  <div className="col-5 mx-3">
                    <label htmlFor="stock">TYPE <span style={{ color: 'red' }}>*</span></label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1 "
                      value={addition.type?? ''}
                      id={index}
                      data-testid="product.addition.type"
                      aria-label='addition type'
                      onChange={(e) => onEditAddition(e, addition.id, 'type')}
                    />
                    <span style={{display: (addition?.invalid && addition?.type=== '')?'block':'none'}} className="text-danger small">Type is required.</span>
                  </div>
                  </div>
                  <div className="row">

                  <div className="col-5 mx-3"></div>

                  <div className="row">
                    <div className="col-12 mx-3">
                    <label htmlFor="stock">DESCRIPTION/NOTES</label>
                      <textarea
                        maxLength="250"
                        className="form-control rounded-lg mt-1 "
                        rows="5"
                        name="additionNotes"
                        aria-label="addition description"
                        data-testid="product.addition.description"
                        id={index}
                        value={ addition.description??''}
                        onChange={(e) => onEditAddition(e, addition.id, 'description')}
                      ></textarea>
                    </div>
                  </div>
                  <div className="text-center mt-3">
                    <button className="btn btn-primary" 
                    id={addition.id}
                    aria-label='delete addition'
                    onClick={e => deleteAddition(e, index)}
                    data-testid="product.addition.control.deleteUpgradeButton"
                    >
                      DELETE ADDITION
                    </button>
                  </div>
                </div>
              </div>
          </div>
        );
      })}
    </div>
    <hr className="my-3" style={{width: "99%"}} />
  </div>
  );
}

export default Additions;

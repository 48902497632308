import React, { useState, useEffect, useMemo, useCallback } from "react";
// import { useDispatch, useSelector } from "react-redux";
import {

  useParams
} from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
// import States from '../States';
// import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
// import FormLabel from '@mui/material/FormLabel';
// import {salesPriceWithUpgradeCalc} from "../../../../../../../utlis/productCalculations";
import { removeChars, formatter, formatPhoneNumber, formatUpperCase } from "../../../../../../../utlis/formatters";
import { getOrderLevelAddresses, updateAddressOptions } from "../../../../../../../utlis/documentHelpers";
import { useOrder } from "../../../../../../../redux/orders/order";
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { AZTitleAppGlobalAutofill, AZTitleAppAutofillProductDetails, preProcessPayload } from "../DocumentAutofill/AZTitleApp"

const AZTitleApp = ({document_id, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "az-title-app";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  // const dispatch = useDispatch();

  // const stateAbbrev = States.map(state => {
  //   return state.abbreviation;
  // });

  const order = useOrder();
  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);

  // Data Structure & Init //
  
  var initialDocumentStructure = {
    plate_number: undefined, 
    plate_credit_no: undefined, 
    credit_date: undefined, 
    first_reg_date: undefined, 
    reg_eff_date: undefined, 
    reg_exp_date: undefined, 
    unit_number: undefined, 
    mobile_home_wl: undefined, 
    vin: undefined, 
    make: undefined, 
    body_style: undefined, 
    year: undefined, 
    model: undefined, 
    list_price: undefined, 
    gvw: undefined, 
    fuel: undefined, 
    odometer: undefined, 
    odo_code_a_cb: undefined, 
    odo_code_b_cb: undefined, 
    odo_code_c_cb: undefined, 
    vehicle_const_special_cb: undefined, 
    vehicle_const_reconstruct_cb: undefined, 
    trailer_full_plate_cb: undefined, 
    trailer_small_cb: undefined, 
    lien_amount: undefined, 
    lien_date: undefined, 
    add_lien_holder: undefined, 
    add_lien_holder_cb: undefined, 
    lien_holder_dl_ein: undefined, 
    lien_holder_name: undefined, 
    legal_status_or_cb: undefined, 
    legal_status_and_cb: undefined, 
    legal_status_and_or_cb: undefined, 
    mailing_address: undefined, 
    mailing_city: undefined, 
    mailing_state: undefined, 
    mailing_zip: undefined, 
    owner_dl_ein: undefined, 
    owner_name: undefined, 
    owner_dob: undefined, 
    owner_mvd_tax_number: undefined, 
    ower_legal_status_or_cb: undefined, 
    ower_legal_status_and_cb: undefined, 
    ower_legal_status_and_or_cb: undefined, 
    street_address: undefined, 
    street_city: undefined, 
    street_state: undefined, 
    street_zip: undefined, 
    street_county: undefined, 
    mailing_address_2: undefined, 
    mailing_city_2: undefined, 
    mailing_state_2: undefined, 
    mailing_zip_2: undefined, 
    mailing_county_2: undefined, 
    owner_dl_ein_2: undefined, 
    owner_name_2: undefined, 
    owner_dob_2: undefined, 
    owner_mvd_tax_number_2: undefined, 
    owner_legal_status_and_or_2: undefined, 
    owner_legal_status_and_2: undefined, 
    owner_legal_status_or_2: undefined, 
    street_address_2: undefined, 
    street_city_2: undefined, 
    street_county_2: undefined, 
    mailing_address_3: undefined, 
    mailing_city_3: undefined, 
    mailing_state_3: undefined, 
    street_zip_2: undefined, 
    street_state_2: undefined, 
    mailing_county_3: undefined, 
    mailing_zip_3: undefined, 
    owner_dl_ein_3: undefined, 
    owner_name_3: undefined, 
    owner_dob_3: undefined, 
    owner_mvd_tax_number_3: undefined, 
    owner_legal_status_or_3: undefined, 
    owner_legal_status_and_3: undefined, 
    owner_legal_status_and_or_3: undefined, 
    street_address_3: undefined, 
    street_city_3: undefined, 
    street_state_3: undefined, 
    street_zip_3: undefined, 
    street_county_3: undefined, 
    mailing_address_4: undefined, 
    mailing_city_4: undefined, 
    mailing_state_4: undefined, 
    mailing_zip_4: undefined, 
    mailing_county_4: undefined, 
    vehicle_mobile_home_address: undefined, 
    vehicle_mobile_home_city: undefined, 
    vehicle_mobile_home_state: undefined, 
    vehicle_mobile_home_zip: undefined, 
    vehicle_mobile_home_county: undefined, 
    service_options: undefined, 
    date_acquired: undefined, 
    // release_consent_cb: undefined, 
    rented_wo_driver_cb: undefined, 
    station_wagon_cb: undefined, 
    semi_cb: undefined, 
    cat: undefined, 
    canceled_plate_num_2_cb: undefined, 
    canceled_plate_num_1_cb: undefined, 
    canceled_plate_number: undefined, 
    current_title_number: undefined, 
    mvd_state: undefined, 
    date_title_issued: undefined, 
    reg: undefined, 
    eif: undefined, 
    aqf: undefined, 
    pen: undefined, 
    pst: undefined, 
    trn: undefined, 
    lop: undefined, 
    sno: undefined, 
    dor: undefined, 
    ttl: undefined, 
    utx: undefined, 
    spl: undefined, 
    vlt: undefined, 
    crf: undefined, 
    wgt: undefined, 
    mcf: undefined, 
    prc: undefined, 
    snoprc: undefined, 
    total_fees: undefined, 
    title_reg_cb: undefined, 
    title_cb: undefined, 
    duplicate_cb: undefined, 
    reg_cb: undefined, 
    dismantle_cb: undefined, 
    salvage_cb: undefined, 
    stolen_cb: undefined,

    form_type_toggle: undefined
  }

  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  // X1
  const removeFormatting = e =>{
    const {name,value}=e.target;
    setDocumentValues({...documentValues,[name]:removeChars(value)});
  }

  // X1 Reference Arrays - TODO: Possibly move these to back end as part of the template stucture
  const moneyFields = useMemo(()=>{return["list_price", "lien_amount"]},[]);
  const phoneFields = useMemo(()=>{return[""]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "model"]},[]);
  const checkboxes = useMemo(()=>{return[
    "rented_wo_driver_cb",
    "station_wagon_cb",
    "semi_cb",
    "add_lien_holder_cb"
  ]},[]);
  // const radios = [
  //   "odometer_toggle", 
  //   "vehicle_const_toggle", 
  //   "trailer_plate_toggle",
  //   "legal_status_toggle",
  //   "legal_status_2_toggle",
  //   "legal_status_3_toggle", 
  //   "legal_status_4_toggle" 
  // ];

  // X1- Current General On Change
  const handleDocumentValuesChange = e =>{
    // e.preventDefault();
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatPhoneNumber(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  
  const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('AZTitleApp.jsx', 'Error loading document: ' + err)
      }      
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, documentLoaded, loadDocument ])
  const [assignedProduct, setAssignedProduct] = useState(undefined);
  // const [productVal, setproductVal] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  // const [tradeinVal, setTradeinVal] = useState(undefined);
  // const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  // const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);

  const [selectedAddress1, setSelectedAddress1] = useState(undefined);
  const [selectedAddress2, setSelectedAddress2] = useState(undefined);
  const [selectedAddress3, setSelectedAddress3] = useState(undefined);
  const [selectedAddress4, setSelectedAddress4] = useState(undefined);
  const [selectedAddress5, setSelectedAddress5] = useState(undefined);
  const [selectedAddress6, setSelectedAddress6] = useState(undefined);
  const [selectedAddress7, setSelectedAddress7] = useState(undefined);
  const [addressOptions, setAddressOptions] = useState(undefined);
  const [inputAddressVal, setinputAddressVal] = useState(undefined);

  // const [vehicleTypeOptions, setVehicleTypeOptions] = useState(undefined);
  // const [colorOptions, setColorOptions] = useState(undefined);
  // const [ownerTypeOptions, setOwnerTypeOptions] = useState(undefined);
  // const [idTypeOptions, setIdTypeOptions] = useState(undefined);
  // const [phoneTypeOptions, setPhoneTypeOptions] = useState(undefined);
  // const [dealerTypeOptions, setDealerTypeOptions] = useState(undefined);
  // const [weightRatingOptions, setWeightRatingOptions] = useState(undefined);
  // const [fuelTypeOptions, setFuelTypeOptions] = useState(undefined);
  // const [motoStyleOptions, setMotoStyleOptions] = useState(undefined);
  // const [useTypeOptions, setUseTypeOptions] = useState(undefined);

// Dropdowns //colorOptions

// if(order && order.products && order.products.length > 0 && productOptions === undefined){
//   let getProductOptions = [];
//   order.products.map(product => {
//     let str = `${product.stockNumber.toString().toUpperCase()}`;
//     let obj = {id: product.id, name: str}
//     getProductOptions.push(obj);
//   })
//   setproductOptions(getProductOptions);
// }

// if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
//   let getTradeinOptions = [];
//   order.tradeins.map(tradein => {
//     let str = `${tradein.year} ${tradein.make} ${tradein.model.toString().toUpperCase()}`;
//     let obj = {id: tradein.id, name: str}
//     getTradeinOptions.push(obj);
//   })
//   setTradeinOptions(getTradeinOptions);
// }


 // Autofill //

 const GlobalAutofill = useCallback(async () => {
  let newValues = AZTitleAppGlobalAutofill(documentValues, order);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  // var newValues = documentValues;
  
  // if(order && order.customer && order.customer.name){
  //     newValues.printed = order.customer.email;
  // }




  // setDocumentValues(newValues);
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ documentValues, order ])


// const preProcessPayload = useCallback((payload) => {
    
//   if(payload.form_type_toggle){
//     // Reset all
//     payload.title_reg_cb = "no";
//     payload.title_cb = "no";
//     payload.duplicate_cb = "no";
//     payload.reg_cb = "no";
//     payload.dismantle_cb = "no";
//     payload.salvage_cb = "no";
//     payload.stolen_cb = "no";

//     switch(payload.form_type_toggle){
//       case "title_reg":
//         payload.title_reg_cb = "yes";
//         break;
//       case "title":
//         payload.title_cb = "yes";
//         break;
//       case "dupe":
//         payload.duplicate_cb = "yes";
//         break;
//       case "reg":
//         payload.reg_cb = "yes";
//         break;
//       case "dismantle":
//         payload.dismantle_cb = "yes";
//         break;
//       case "salvage":
//         payload.salvage_cb = "yes";
//         break;
//       case "stolen":
//         payload.stolen_cb = "yes";
//         break;

//       default:
//     }
//   }

  
//   if(payload.odometer_toggle){
//     // Reset all
//     payload.odo_code_a_cb = "no";
//     payload.odo_code_b_cb = "no";
//     payload.odo_code_c_cb = "no";

//     switch(payload.odometer_toggle){
//       case "a":
//         payload.odo_code_a_cb = "yes";
//         break;
//       case "b":
//         payload.odo_code_b_cb = "yes";
//         break;
//       case "c":
//         payload.odo_code_c_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.vehicle_const_toggle){
//     // Reset all
//     payload.vehicle_const_special_cb = "no";
//     payload.vehicle_const_reconstruct_cb = "no";

//     switch(payload.vehicle_const_toggle){
//       case "special":
//         payload.vehicle_const_special_cb = "yes";
//         break;
//       case "reconstruct":
//         payload.vehicle_const_reconstruct_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.trailer_plate_toggle){
//     // Reset all
//     payload.trailer_full_plate_cb = "no";
//     payload.trailer_small_cb = "no";

//     switch(payload.trailer_plate_toggle){
//       case "full":
//         payload.trailer_full_plate_cb = "yes";
//         break;
//       case "small":
//         payload.trailer_small_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.legal_status_toggle){
//     // Reset all
//     payload.legal_status_or_cb = "no";
//     payload.legal_status_and_cb = "no";
//     payload.legal_status_and_or_cb = "no";

//     switch(payload.legal_status_toggle){
//       case "or":
//         payload.legal_status_or_cb = "yes";
//         break;
//       case "and":
//         payload.legal_status_and_cb = "yes";
//         break;
//       case "and_or":
//         payload.legal_status_and_or_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.legal_status_2_toggle){
//     // Reset all
//     payload.ower_legal_status_or_cb = "no";
//     payload.ower_legal_status_and_cb = "no";
//     payload.ower_legal_status_and_or_cb = "no";

//     switch(payload.legal_status_2_toggle){
//       case "or":
//         payload.ower_legal_status_or_cb = "yes";
//         break;
//       case "and":
//         payload.ower_legal_status_and_cb = "yes";
//         break;
//       case "and_or":
//         payload.ower_legal_status_and_or_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.legal_status_3_toggle){
//     // Reset all
//     payload.owner_legal_status_or_2_cb = "no";
//     payload.owner_legal_status_and_2_cb = "no";
//     payload.owner_legal_status_and_or_2_cb = "no";

//     switch(payload.legal_status_3_toggle){
//       case "or":
//         payload.owner_legal_status_or_2_cb = "yes";
//         break;
//       case "and":
//         payload.owner_legal_status_and_2_cb = "yes";
//         break;
//       case "and_or":
//         payload.owner_legal_status_and_or_2_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.legal_status_4_toggle){
//     // Reset all
//     payload.owner_legal_status_or_3_cb = "no";
//     payload.owner_legal_status_and_3_cb = "no";
//     payload.owner_legal_status_and_or_3_cb = "no";

//     switch(payload.legal_status_4_toggle){
//       case "or":
//         payload.owner_legal_status_or_3_cb = "yes";
//         break;
//       case "and":
//         payload.owner_legal_status_and_3_cb = "yes";
//         break;
//       case "and_or":
//         payload.owner_legal_status_and_or_3_cb = "yes";
//         break;
//       default:
//     }
//   }


//   // X1.2- Field Type Specific Preview Formatting
//   Object.keys(payload).forEach(key => {
//     if(moneyFields.indexOf(key) >= 0){
//         payload[key] = formatMoneyValue(payload[key]);
//     }else if(phoneFields.indexOf(key) >= 0){
//         payload[key] = formatPhoneNumber(payload[key]);
//     }else if(upperCaseFields.indexOf(key) >= 0){
//         payload[key] = formatUpperCase(payload[key]);
//     }
//   });
//   setDocumentValues(JSON.parse(JSON.stringify(payload)));
//   Object.keys(payload).forEach(key => {
//     if(checkboxes.indexOf(key) >= 0){
//       payload[key] = payload[key] ? "yes": "no";
//     }
//   });

//   // Data point formatting
//   if(payload.credit_date){
//     payload.credit_date = moment.utc(payload.credit_date).format("MM/DD/YY");
//   }

//   if(payload.first_reg_date){
//     payload.first_reg_date = moment.utc(payload.first_reg_date).format("MM/DD/YY");
//   }

//   if(payload.reg_eff_date){
//     payload.reg_eff_date = moment.utc(payload.reg_eff_date).format("MM/DD/YY");
//   }

//   if(payload.reg_exp_date){
//     payload.reg_exp_date = moment.utc(payload.reg_exp_date).format("MM/DD/YY");
//   }

//   if(payload.owner_dob){
//     payload.owner_dob = moment.utc(payload.owner_dob).format("MM/DD/YY");
//   }

//   if(payload.owner_dob_2){
//     payload.owner_dob_2 = moment.utc(payload.owner_dob_2).format("MM/DD/YY");
//   }

//   if(payload.owner_dob_3){
//     payload.owner_dob_3 = moment.utc(payload.owner_dob_3).format("MM/DD/YY");
//   }

//   if(payload.date_acquired){
//     payload.date_acquired = moment.utc(payload.date_acquired).format("MM/DD/YY");
//   }

//   if(payload.lien_date){
//     payload.lien_date = moment.utc(payload.lien_date).format("MM/DD/YY");
//   }

//   return payload;
// },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])


const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });
    
    var docCopy = JSON.parse(JSON.stringify(document));
    docCopy.payload =  JSON.parse(JSON.stringify(payload));
    docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
    docCopy.payload =  JSON.stringify(docCopy.payload);
    delete docCopy.documentStatus;
    try{
      let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
      const file = new Blob(
        [response.data], 
        {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      setdocumentUrl(fileURL)
    } catch (err) {
      recordFrontEndError('AZTitleApp.jsx', 'Error previewing document payload: ' + err)
    }
},[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


  // Document BREAD + Preview // TODO: Move to Redux

  //Autofill Product Details After Selection
  const AutofillProductDetails = useCallback(async (selectedProduct) => {

    let newValues = AZTitleAppAutofillProductDetails(selectedProduct, documentValues);

    if(newValues){
      setDocumentValues(newValues);
      previewPayloadDocument(documentValues);
      setHasUnsavedChanges(true);
      if(document){
        previewPayloadDocument(documentValues);
      }
      setSaveSuccess(false);
    }

    // var newValues = documentValues;
    // newValues.vin = (selectedProduct.vin).toString().toUpperCase();
    // newValues.year = (selectedProduct.year).trim();
    // newValues.make = (selectedProduct.make).trim();
    // newValues.model = (selectedProduct.model).toString().toUpperCase();

    // newValues.body_style = selectedProduct.vehicleType;

    // if(selectedProduct.fundingDetail && selectedProduct.fundingDetail.finalizedAt){
    //   if(selectedProduct.fundingDetail.fundingMethod === "tec-financing" || selectedProduct.fundingDetail.fundingMethod === "finance-only" || selectedProduct.fundingDetail.fundingMethod === "direct-deal"){
    //     if(selectedProduct.fundingDetail.finalizedLendingOption){
    //      newValues.lien_holder_name = selectedProduct.fundingDetail.finalizedLendingOption.bankName;
   
    //      let addressLine = "";
    //      addressLine = selectedProduct.fundingDetail.finalizedLendingOption.lienAddress;
   
    //     //  addressLine += " "+(selectedProduct.fundingDetail.finalizedLendingOption.lienCity+", "+selectedProduct.fundingDetail.finalizedLendingOption.lienState+" "+selectedProduct.fundingDetail.finalizedLendingOption.lienZip)
    //     newValues.mailing_address = addressLine;
    //     newValues.mailing_city = selectedProduct.fundingDetail.finalizedLendingOption.lienCity;
    //     newValues.mailing_state = selectedProduct.fundingDetail.finalizedLendingOption.lienState;
    //     newValues.mailing_zip = selectedProduct.fundingDetail.finalizedLendingOption.lienZip;
    //   }
    //   }
   
    //   if(selectedProduct.fundingDetail.fundingMethod === "cash-with-lien"){
    //    if(selectedProduct.fundingDetail.cashLienHolder){
    //      newValues.lien_holder_name = selectedProduct.fundingDetail.cashLienHolder.bankName;
   
    //      let addressLine = "";
    //      if(selectedProduct.fundingDetail.cashLienHolder.addressLine2 != null && selectedProduct.fundingDetail.cashLienHolder.addressLine2 !== undefined && selectedProduct.fundingDetail.cashLienHolder.addressLine2 !== ''){
    //        addressLine = (selectedProduct.fundingDetail.cashLienHolder.addressLine1+" "+selectedProduct.fundingDetail.cashLienHolder.addressLine2);
    //      }else{
    //        addressLine = selectedProduct.fundingDetail.cashLienHolder.addressLine1;
    //      }
   
    //     //  addressLine += (selectedProduct.fundingDetail.cashLienHolder.city+", "+selectedProduct.fundingDetail.cashLienHolder.state+" "+selectedProduct.fundingDetail.cashLienHolder.zip)
    //      newValues.mailing_address = addressLine;
    //      newValues.mailing_city = selectedProduct.fundingDetail.cashLienHolder.city;
    //      newValues.mailing_state = selectedProduct.fundingDetail.cashLienHolder.state;
    //      newValues.mailing_zip = selectedProduct.fundingDetail.cashLienHolder.zip;
    //    }
    //   }
    // }

    // if(selectedProduct.soldTo){
    //   newValues.owner_name = selectedProduct.soldTo.name;

    //   var addressLineSoldTo = '';
    //   if(selectedProduct.soldTo.addressLine2 != null && selectedProduct.soldTo.addressLine2 !== undefined && selectedProduct.soldTo.addressLine2 !== ''){
    //     addressLineSoldTo = (selectedProduct.soldTo.addressLine1+" "+selectedProduct.soldTo.addressLine2);
    //   }else{
    //     addressLineSoldTo = selectedProduct.soldTo.addressLine1;
    //   }
    //   newValues.street_address = addressLineSoldTo;
    //   newValues.street_city = selectedProduct.soldTo.city;
    //   newValues.street_state = selectedProduct.soldTo.state;
    //   newValues.street_zip = selectedProduct.soldTo.zip;
    // }

    // if(selectedProduct.shipTo){
    //   newValues.owner_name_2 = selectedProduct.shipTo.name;

    //   var addressLineShipTo = '';
    //   if(selectedProduct.shipTo.addressLine2 != null && selectedProduct.shipTo.addressLine2 !== undefined && selectedProduct.shipTo.addressLine2 !== ''){
    //     addressLineShipTo = (selectedProduct.shipTo.addressLine1+" "+selectedProduct.shipTo.addressLine2);
    //   }else{
    //     addressLineShipTo = selectedProduct.shipTo.addressLine1;
    //   }
    //   newValues.street_address_2 = addressLineShipTo;
    //   newValues.street_city_2 = selectedProduct.shipTo.city;
    //   newValues.street_state_2 = selectedProduct.shipTo.state;
    //   newValues.street_zip_2 = selectedProduct.shipTo.zip;
    // }

    // newValues.list_price = salesPriceWithUpgradeCalc(selectedProduct);
    // if(selectedProduct.lineItemFee && selectedProduct.lineItemFee.fetTotal){
    //   newValues.list_price += Number(selectedProduct.lineItemFee.fetTotal);
    // }

    // setDocumentValues(newValues);
    // // setProductToggleAutoFill(false);
    // if(document){
    //   previewPayloadDocument(documentValues);
    // }
    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  },[ document, documentValues, previewPayloadDocument ]);

  useEffect(() => {
    if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){
  
      existingPayload = true;
  
      // if(documentPayload.changes_date){
      //   documentPayload.changes_date = moment.utc(documentPayload.changes_date).format("YYYY-MM-DD");
      // }
      // Loading Use Effect
    if(document.additionalData){
      const methodMap = { 
        selectedAddress1: setSelectedAddress1, 
        selectedAddress2: setSelectedAddress2, 
        selectedAddress3: setSelectedAddress3, 
        selectedAddress4: setSelectedAddress4, 
        selectedAddress5: setSelectedAddress5, 
        selectedAddress6: setSelectedAddress6, 
        selectedAddress7: setSelectedAddress7, 
      };

      let parsedAdditionalData = JSON.parse(document.additionalData);
      Object.keys(parsedAdditionalData).forEach(function(key) {
        if(methodMap[key]){
          methodMap[key](parsedAdditionalData[key]);
        }
      });
    }
  
      setDocumentValues(documentPayload);
    }
  
    var foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }
    
    setAddressOptions(getOrderLevelAddresses(order));
    if(foundAssignedProduct){
        setAssignedProduct(foundAssignedProduct);
        setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
    }

      if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
      }
      
      setDataLoaded(true);
    }
  },[ documentValues, document, dataLoaded, AutofillProductDetails, GlobalAutofill, order, previewPayloadDocument ])



  
    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();

      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
 
      
      let dropdownData = {};
      if(selectedAddress1){
        dropdownData.selectedAddress1 = {
          id: selectedAddress1.id,
          name: selectedAddress1.name
        };
      }
      if(selectedAddress2){
        dropdownData.selectedAddress2 = {
          id: selectedAddress2.id,
          name: selectedAddress2.name
        };
      }
      if(selectedAddress3){
        dropdownData.selectedAddress3 = {
          id: selectedAddress3.id,
          name: selectedAddress3.name
        };
      }
      if(selectedAddress4){
        dropdownData.selectedAddress4 = {
          id: selectedAddress4.id,
          name: selectedAddress4.name
        };
      }
      if(selectedAddress5){
        dropdownData.selectedAddress5 = {
          id: selectedAddress5.id,
          name: selectedAddress5.name
        };
      }
      if(selectedAddress6){
        dropdownData.selectedAddress6 = {
          id: selectedAddress6.id,
          name: selectedAddress6.name
        };
      }
      if(selectedAddress7){
        dropdownData.selectedAddress7 = {
          id: selectedAddress7.id,
          name: selectedAddress7.name
        };
      }
      let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, additionalData, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });
      // try {
      //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
      //   if(res){
      //     await axios.post(`/document/${document.id}/generate`) 
      //     getDocuments();
      //     setSaveSuccess(true);
      //     setHasUnsavedChanges(false);
      //   }
      // } catch (err) {
      //   recordFrontEndError('AZTitleApp.jsx', 'Error saving document: ' + err)
      // }
    }

// Component Helpers //
// useEffect(() => {

// },[ selectedProduct, productToggleAutoFill ])

// useEffect(() => {
//   //Autofill Tradein Details After Selection
//   if(selectedTradein !== undefined && tradeinToggleAutoFill){
//     var newValues = documentValues;
//     newValues.trade_year = (selectedTradein.year);
//     newValues.trade_make = (selectedTradein.make);
//     newValues.trade_model = (selectedTradein.model);
//     newValues.trade_body_type = (selectedTradein.seriesBodyType);
//     newValues.trade_serial_number = (selectedTradein.serialNumber);
//     newValues.balance_owner = (order.customer.name); //Need specific field for TRADE IN OWNER?
//     newValues.balance_owner_address = (order.customer.deliveryAddress.addressLine1);
//     newValues.amount = (selectedTradein.balanceOwed);
//     newValues.trade_allowance = (selectedTradein.tradeAllowanceAtTerms);
//     newValues.balance_owed = (selectedTradein.balanceOwed);
//     newValues.cash_refund = (selectedTradein.cashRefundToCustomer);
//     newValues.net_trade_allowance = (selectedTradein.finalTradeAllowance);
//     // newValues.cash_down_payment = (selectedTradein);

//     setDocumentValues(newValues);
//     setTradeinToggleAutoFill(false);
//     if(document){
//       previewPayloadDocument(documentValues);
//     }
//   }
// },[ selectedTradein, tradeinToggleAutoFill ])

//Autofill Address Details After Selection
function AutoFillAddress(addressSelected, selectionInstance){
  var newValues = documentValues;
  if(selectionInstance){
    if(selectionInstance === "mailing-address"){
      newValues.mailing_address = addressSelected.address;
      newValues.mailing_city = addressSelected.city;
      newValues.mailing_state = addressSelected.state;
      newValues.mailing_zip = addressSelected.zip;
    }
    
    if(selectionInstance === "street-address"){
      newValues.owner_name = addressSelected.label;
      newValues.street_address = addressSelected.address;
      newValues.street_city = addressSelected.city;
      newValues.street_state = addressSelected.state;
      newValues.street_zip = addressSelected.zip;
      // newValues.owner_name = addressSelected.label;
    }

    if(selectionInstance === "mailing-address-2"){
      newValues.mailing_address_2 = addressSelected.address;
      newValues.mailing_city_2 = addressSelected.city;
      newValues.mailing_state_2 = addressSelected.state;
      newValues.mailing_zip_2 = addressSelected.zip;
      // newValues.owner_name = addressSelected.label;
    }

    if(selectionInstance === "street-address-2"){
      newValues.owner_name_2 = addressSelected.label
      newValues.street_address_2 = addressSelected.address;
      newValues.street_city_2 = addressSelected.city;
      newValues.street_state_2 = addressSelected.state;
      newValues.street_zip_2 = addressSelected.zip;
    }

    if(selectionInstance === "mailing-address-3"){
      newValues.mailing_address_3 = addressSelected.address;
      newValues.mailing_city_3 = addressSelected.city;
      newValues.mailing_state_3 = addressSelected.state;
      newValues.mailing_zip_3 = addressSelected.zip;
    }
    
    if(selectionInstance === "street-address-3"){
      newValues.owner_name_3 = addressSelected.label;
      newValues.street_address_3 = addressSelected.address;
      newValues.street_city_3 = addressSelected.city;
      newValues.street_state_3 = addressSelected.state;
      newValues.street_zip_3 = addressSelected.zip;
      // newValues.owner_name = addressSelected.label;
    }

    if(selectionInstance === "mailing-address-4"){
      newValues.mailing_address_4 = addressSelected.address;
      newValues.mailing_city_4 = addressSelected.city;
      newValues.mailing_state_4 = addressSelected.state;
      newValues.mailing_zip_4 = addressSelected.zip;
      // newValues.owner_name = addressSelected.label;
    }

    if(selectionInstance === "home-location"){
      newValues.vehicle_mobile_home_address = addressSelected.address;
      newValues.vehicle_mobile_home_city = addressSelected.city;
      newValues.vehicle_mobile_home_state = addressSelected.state;
      newValues.vehicle_mobile_home_zip = addressSelected.zip;
      // newValues.owner_name = addressSelected.label;
    }


  }
  setDocumentValues(newValues);
  if(document){
    previewPayloadDocument(documentValues);
  }
  setHasUnsavedChanges(true);
  setSaveSuccess(false);
}

const onCloseClick = e => {
  e.preventDefault()
  setDataLoaded(false);
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setAssignedProduct(undefined);
  setdocumentUrl("");
  setDocument(undefined);
  setDocumentFocus(undefined);
  setSelectedAddress1(undefined);
  setSelectedAddress2(undefined);
  setSelectedAddress3(undefined);
  setSelectedAddress4(undefined);
  setSelectedAddress5(undefined);
  setSelectedAddress6(undefined);
  setSelectedAddress7(undefined);
  setAddressOptions(undefined);
  setinputAddressVal(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}


  return (
    <>
      <div
        className="modal fade"
        // id="wa-title-app"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                AZ - Title & Registration Application
              </h5>
              <br />
              { hasClickedClose ? (
              <div className="d-flex">
                  <p className="m-1 pt-1 text-white">Save Changes?</p>
                  <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                  <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
              </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}
                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ fontSize: '12px', overflowY: 'scroll', height: 'inherit'}}>
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill();
                      AutofillProductDetails(assignedProduct);
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>
                  <br />

                  { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Product Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                        </div>
                    </div>
                  ):(
                    <div className="m-2 p-2 border">
                        <span>No Assigned Product</span>
                    </div>
                  )}

                  <div className="row">

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="form_type_toggle"
                            value={(documentValues.form_type_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="title_reg" control={<Radio />} label="Title and Registration" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="title" control={<Radio />} label="Title Only  " />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="dupe" control={<Radio />} label="Duplicate" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="reg" control={<Radio />} label="Registration Only  " />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="dismantle" control={<Radio />} label="Dismantle" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="salvage" control={<Radio />} label="Salvage" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="stolen" control={<Radio />} label="Stolen" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Plate Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="plate_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.plate_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Plate Credit No. </label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="plate_credit_no"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.plate_credit_no ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Credit Eff. Date </label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="credit_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.credit_date ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">First Registered</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="first_reg_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.first_reg_date ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Reg. Eff. Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="reg_eff_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.reg_eff_date ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Reg. Expiration Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="reg_exp_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.reg_exp_date ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Unit Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="unit_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.unit_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Mobile Home W/L</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mobile_home_wl"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mobile_home_wl ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Body Style</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="body_style"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.body_style ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Model</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="model"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.model ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">List Price</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          onFocus={removeFormatting}
                          name="list_price"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.list_price ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">GVW</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="gvw"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.gvw ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Fuel</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="fuel"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.fuel ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Odometer Reading</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="odometer"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.odometer ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Odometer Codes* </label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="odometer_toggle"
                            value={(documentValues.odometer_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="a" control={<Radio />} label="A" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="b" control={<Radio />} label="B" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="c" control={<Radio />} label="C" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Vehicle Construction</label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="vehicle_const_toggle"
                            value={(documentValues.vehicle_const_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="special" control={<Radio />} label="Specially Constructed" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="reconstruct" control={<Radio />} label="Reconstructed" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Trailer Plate Size </label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="trailer_plate_toggle"
                            value={(documentValues.trailer_plate_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="full" control={<Radio />} label="Full Size" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="small" control={<Radio />} label="Small" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    <hr></hr>

                    { addressOptions && addressOptions.length > 0 ? (
                    <div className="row">
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress1 && selectedAddress1.name) ?? null}
                            name="mailing-address"
                            onChange={(event, newValue) => {
                              setSelectedAddress1(newValue);
                              AutoFillAddress(newValue, "mailing-address");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Lien Holder" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    ):null}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Lien Amount</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lien_amount"
                          onFocus={removeFormatting}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lien_amount ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Lien Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="lien_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lien_date ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.add_lien_holder_cb }
                              name="add_lien_holder_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="Additional lien or lienholders (attach another application)" />
                        </FormControl>
                      </div>
                    </div>

                    { documentValues.add_lien_holder_cb ? (
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Additional lien or lienholders</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="add_lien_holder"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.add_lien_holder ?? '' }
                          />
                      </div>
                    </div>
                    ):null}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Lienholder Driver License or EIN* </label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lien_holder_dl_ein"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lien_holder_dl_ein ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Lienholder Name (if no lien, write NONE)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lien_holder_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lien_holder_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Legal Status</label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="legal_status_toggle"
                            value={(documentValues.legal_status_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="or" control={<Radio />} label="OR" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="and" control={<Radio />} label="AND" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="and_or" control={<Radio />} label="AND/OR" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Mailing Address </label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_address ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_zip ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    { addressOptions && addressOptions.length > 0 ? (
                    <div className="row">
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress2 && selectedAddress2.name) ?? null}
                            name="street-address"
                            onChange={(event, newValue) => {
                              setSelectedAddress2(newValue);
                              AutoFillAddress(newValue, "street-address");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Main Owner" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    ):(
                      <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Addresses Loaded</h5>
                    </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Owner Driver License or EIN* </label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_dl_ein"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_dl_ein ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Owner Name (first, middle, last, suffix)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_name ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Date of Birth</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="owner_dob"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_dob ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">MVD Tax Account Number (if applicable) </label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_mvd_tax_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_mvd_tax_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-5">
                      <label htmlFor="stock">Legal Status* 1</label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="legal_status_2_toggle"
                            value={(documentValues.legal_status_2_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="or" control={<Radio />} label="OR" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="and" control={<Radio />} label="AND" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="and_or" control={<Radio />} label="AND/OR" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Street Address </label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="street_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.street_address ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="street_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.street_city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="street_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.street_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="street_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.street_zip ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">County</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="street_county"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.street_county ?? '' }
                          />
                      </div>
                    </div>

                    { addressOptions && addressOptions.length > 0 ? (
                    <div className="row">
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress3 && selectedAddress3.name) ?? null}
                            name="mailing-address-2"
                            onChange={(event, newValue) => {
                              setSelectedAddress3(newValue);
                              AutoFillAddress(newValue, "mailing-address-2");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Main Owner Mailing Address" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    ):(
                      <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Addresses Loaded</h5>
                    </div>
                    )}
                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Mailing Address </label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_address_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_address_2 ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_city_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_city_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_state_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_state_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_zip_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_zip_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">County</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_county_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_county_2 ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    { addressOptions && addressOptions.length > 0 ? (
                    <div className="row">
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress4 && selectedAddress4.name) ?? null}
                            name="street-address-2"
                            onChange={(event, newValue) => {
                              setSelectedAddress4(newValue);
                              AutoFillAddress(newValue, "street-address-2");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Second Owner" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    ):null}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Owner Driver License or EIN* </label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_dl_ein_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_dl_ein_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Owner Name (first, middle, last, suffix)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_name_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_name_2 ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Date of Birth</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="owner_dob_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_dob_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">MVD Tax Account Number (if applicable) </label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_mvd_tax_number_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_mvd_tax_number_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-5">
                      <label htmlFor="stock">Legal Status* </label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="legal_status_3_toggle"
                            value={(documentValues.legal_status_3_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="or" control={<Radio />} label="OR" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="and" control={<Radio />} label="AND" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="and_or" control={<Radio />} label="AND/OR" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Street Address </label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="street_address_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.street_address_2 ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="street_city_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.street_city_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="street_state_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.street_state_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="street_zip_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.street_zip_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">County</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="street_county_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.street_county_2 ?? '' }
                          />
                      </div>
                    </div>

                    { addressOptions && addressOptions.length > 0 ? (
                    <div className="row">
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress5 && selectedAddress5.name) ?? null}
                            name="mailing-address-3"
                            onChange={(event, newValue) => {
                              setSelectedAddress5(newValue);
                              AutoFillAddress(newValue, "mailing-address-3");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Second Owner Mailing Address" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    ):(
                      <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Addresses Loaded</h5>
                    </div>
                    )}
                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Mailing Address </label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_address_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_address_3 ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_city_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_city_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_state_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_state_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_zip_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_zip_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">County</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_county_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_county_3 ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    { addressOptions && addressOptions.length > 0 ? (
                    <div className="row">
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress5 && selectedAddress5.name) ?? null}
                            name="street-address-2"
                            onChange={(event, newValue) => {
                              setSelectedAddress5(newValue);
                              AutoFillAddress(newValue, "street-address-3");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Third Owner" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                   ):(
                    <div  className="text-center mx-auto">
                    <h5 style={{ color: "red" }}> No Addresses Loaded</h5>
                  </div>
                  )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Owner Driver License or EIN* </label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_dl_ein_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_dl_ein_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Owner Name (first, middle, last, suffix)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_name_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_name_3 ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Date of Birth</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="owner_dob_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_dob_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">MVD Tax Account Number (if applicable) </label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_mvd_tax_number_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_mvd_tax_number_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-5">
                      <label htmlFor="stock">Legal Status* </label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="legal_status_4_toggle"
                            value={(documentValues.legal_status_4_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="or" control={<Radio />} label="OR" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="and" control={<Radio />} label="AND" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="and_or" control={<Radio />} label="AND/OR" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Street Address </label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="street_address_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.street_address_3 ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="street_city_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.street_city_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="street_state_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.street_state_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="street_zip_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.street_zip_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">County</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="street_county_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.street_county_3 ?? '' }
                          />
                      </div>
                    </div>

                    { addressOptions && addressOptions.length > 0 ? (
                    <div className="row">
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress6 && selectedAddress6.name) ?? null}
                            name="mailing-address-4"
                            onChange={(event, newValue) => {
                              setSelectedAddress6(newValue);
                              AutoFillAddress(newValue, "mailing-address-4");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Third Owner Mailing Address" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    ):(
                      <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Addresses Loaded</h5>
                    </div>
                    )}
                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Mailing Address </label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_address_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_address_4 ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_city_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_city_4 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_state_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_state_4 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_zip_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_zip_4 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">County</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_county_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_county_4 ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    { addressOptions && addressOptions.length > 0 ? (
                    <div className="row">
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress7 && selectedAddress7.name) ?? null}
                            name="home-location"
                            onChange={(event, newValue) => {
                              setSelectedAddress7(newValue);
                              AutoFillAddress(newValue, "home-location");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Vehicle/Mobile Home Location" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    ):(
                      <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Addresses Loaded</h5>
                    </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Vehicle/Mobile Home Location</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vehicle_mobile_home_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vehicle_mobile_home_address ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vehicle_mobile_home_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vehicle_mobile_home_city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vehicle_mobile_home_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vehicle_mobile_home_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vehicle_mobile_home_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vehicle_mobile_home_zip ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">County</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vehicle_mobile_home_county"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vehicle_mobile_home_county ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>
                      
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Service Options*</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="service_options"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.service_options ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Date Vehicle Acquired </label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date_acquired"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date_acquired ?? '' }
                          />
                      </div>
                    </div>

                    {/* <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.release_consent_cb }
                              name="release_consent_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="I  consent to the release of personal information contained in my driver license and vehicle record. I understand that this is not a one-time consent that applies only to a specific individual or organization, but is instead a general consent that applies to all requests from any and all individuals or organizations for any purpose, until revoked by me in writing. Consent for a vehicle record applies to all owners." />
                        </FormControl>
                      </div>
                    </div> */}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.rented_wo_driver_cb }
                              name="rented_wo_driver_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="Vehicle will be rented without a driver (such as a rental car)." />
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.station_wagon_cb }
                              name="station_wagon_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="I certify that this vehicle, commonly referred to as a station wagon or referred to by the manufacturer’s rating as a 3/4 ton or less pickup truck or 3/4 ton or less van, is not maintained and operated more than 1,000 hours in a vehicle registration year for the transportation of passengers or property in the furtherance of a commercial enterprise." />
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.semi_cb }
                              name="semi_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="I certify that this trailer or semitrailer with a declared gross weight of 10,000 lbs or less is not maintained and operated in the furtherance of a commercial enterprise." />
                        </FormControl>
                      </div>
                    </div>
              

                  </div>
                <hr></hr>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default AZTitleApp
import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";

export const AZ5000GlobalAutofill = (documentValues, order, property=null, value=null) => {

  var newValues = documentValues;

  
  if(order){
    if(order.customer){
      if(property?.includes('customer') || property === null){
        if(order.customer.name){
          newValues.name = order.customer.name;
          newValues.printed = order.customer.name;
        }
      }

      if(property?.includes('cosigner') || property === null){

        if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner && cosigner.name){
              newValues.name += " AND "+cosigner.name;
          }
        }

      }
    }

    if(property?.includes('documentationDate') || property === null){
      if(order && order.documentationDate && documentValues.date === undefined){
        newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
      }
    }
  }

  if(property === null){
    newValues.reason_for_exemption_14_cb = "yes";
    newValues.reason_for_exemption_14_deduction = "SECTION 28-58-54 & 28-5860";
    newValues.reason_for_exemption_14_desc = "Leasing or renting a motor vehicle subject to and upon which the motor carrier fees have been paid at registration.";
    newValues.no_license_reason = "Transportation";
    newValues.percise_nature_business = "See #14 Motor Carrier Tax Paid at Registration";

    newValues.single_trans_cert_cb = "yes";
    newValues.trans_with_business_cb = "yes";
  }

  return newValues
}

export const AZ5000AutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {

  var newValues = documentValues;

  if(property?.includes('vin') || property === null){
    newValues.vin = (selectedProduct.vin).toString().toUpperCase();
  }

  if(property?.includes('year') || property === null){
    newValues.year = (selectedProduct.year).trim();
  }

  if(property?.includes('make') || property === null){
    newValues.make = (selectedProduct.make).trim();
  }

  if(property?.includes('model') || property === null){
    newValues.model = (selectedProduct.model).toString().toUpperCase();
  }

  if(property?.includes('year') || property?.includes('make') || property?.includes('model') || property?.includes('vin') || property === null){
    newValues.section_e_desc = selectedProduct.year+" "+selectedProduct.make+" "+(selectedProduct.model).toString().toUpperCase()+" VIN "+(selectedProduct.vin).toString().toUpperCase();
  }

  if(property?.includes('shipTo') || property === null){

    if(selectedProduct.shipTo){
      newValues.name = selectedProduct.shipTo.name;
      newValues.address = selectedProduct.shipTo.addressLine1;
      if(selectedProduct.shipTo.addressLine2){
        newValues.address = newValues.address+" "+selectedProduct.shipTo.addressLine2;
      }
      newValues.city = selectedProduct.shipTo.city;
      newValues.state = selectedProduct.shipTo.state;
      newValues.zip = selectedProduct.shipTo.zip;
    }


    if(selectedProduct.soldTo){
      newValues.vendors_name = selectedProduct.soldTo.name;
    }
    
  }

  return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  if(payload.date){
    payload.date = moment.utc(payload.date).format("MM/DD/YY");
  }

  if(payload.period_from_cb === "yes"){
    if(payload.period_from && payload.period_from !== ""){
      payload.period_from = moment.utc(payload.period_from).format("MM/DD/YY");
    }

    if(payload.period_through && payload.period_through !== ""){
      payload.period_through = moment.utc(payload.period_through).format("MM/DD/YY");
    }
  }else{
    payload.period_from = null;
    payload.period_through = null;
  }

  return payload;
}
import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";
import { autoCountYMM } from './docUtils.js';

export const TECTaxWaiverGlobalAutofill = (documentValues, order, property=null, value=null, location) => {
    var newValues = documentValues;

    if(property ==='customer.name'){
      newValues.purchaser_name = order.customer.name;
        if(order.customer.dba){
          newValues.purchaser_name += " dba "+order.customer.dba;
        }
        if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner && cosigner.name){
            newValues.purchaser_name += " AND " +cosigner.name;
          }
        }
  }
    if(property ==='customer.dba'){
          newValues.purchaser_name = order.customer.name+ " dba "+value;
        if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner && cosigner.name){
            newValues.purchaser_name += " AND " +cosigner.name;
          }
        }
  }
    if(property ==='customer.cosigner[0].name'){
      newValues.purchaser_name = order.customer.name;
        if(order.customer.dba){
          newValues.purchaser_name += " dba "+order.customer.dba;
        }
        if(value && value.length > 0){
          let cosigner = value[0];
          if(cosigner && cosigner.name){
            newValues.purchaser_name += " AND " +cosigner.name;
          }
        }
  }
        if(property ==='customer.deliveryAddress.addressLine1'){
          newValues.address = value
          if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
            newValues.address +=" "+order.customer.deliveryAddress.addressLine2;
          }
      }
        if(property ==='customer.deliveryAddress.addressLine2'){
          newValues.address = order.customer.deliveryAddress.addressLine1+" "+value;
      }
      if(property ==='customer.deliveryAddress.city'){
        newValues.purchaser_city_state_zip = (value+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
    }
      if(property ==='customer.deliveryAddress.state'){
        newValues.purchaser_city_state_zip = (order.customer.deliveryAddress.city+", "+value+" "+order.customer.deliveryAddress.zip);
    }
      if(property ==='customer.deliveryAddress.zip'){
        newValues.purchaser_city_state_zip = (order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+value);
    }
    if(property ==='location'){
        newValues.location_address = value.address+" "+value.city+" "+value.state+" "+value.zip;
      if(value && value.phone){
        newValues.location_phone = value.phone;
      }
      if(value && value.email){
        newValues.location_email = value.email;
      }
      if(value){
        if(value.state === "CA"){
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
        }else{
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
        }
      }
  }
    if(property ==='location'){
        newValues.location_address = value.address+" "+value.city+" "+value.state+" "+value.zip;
      if(value && value.phone){
        newValues.location_phone = value.phone;
      }
      if(value && value.email){
        newValues.location_email = value.email;
      }
      if(value){
        if(value.state === "CA"){
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
        }else{
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
        }
      }
  }
    if(property !== null && value !== null){
      return newValues;
  }

      if(order && order.customer && order.customer.name){
        newValues.purchaser_name = order.customer.name;
        if(order.customer.dba){
          newValues.purchaser_name += " dba "+order.customer.dba;
        }
  
        if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner && cosigner.name){
            newValues.purchaser_name += " AND " +cosigner.name;
          }
        }
      }
      
      if(order && order.customer && order.customer.deliveryAddress){
        newValues.purchaser_address = (order.customer.deliveryAddress.addressLine1+" "+(order.customer.deliveryAddress.addressLine2 ?? ''));
      }
      
      if(order && order.customer && order.customer.deliveryAddress){
        newValues.purchaser_city_state_zip = (order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
      }
      
      if(order && order.customer && order.customer.phone){
        newValues.purchaser_phone = formatPhoneNumber(order.customer.phone);
      }
      
      // Get Location Address & Autofill Address
      if(location !== undefined){
        newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
      }
    
      if(location && location.phone){
        newValues.location_phone = location.phone;
      }
      
      if(location && location.email){
        newValues.location_email = location.email;
      }
    
      if(location){
        if(location.state === "CA"){
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
        }else{
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
        }
      }
  
      if(order && order.documentationDate){
        newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
      }
  
      return newValues
}
  
export const TECTaxWaiverAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null, location=null, order=null, autofillProductDetails=true, productCount=0) => {
  
      var newValues = documentValues;

      if(property === 'year' && value !== null){
        if(autofillProductDetails){
          newValues.vehicle = autoCountYMM((value).toString().trim(), (selectedProduct.make).trim(), (selectedProduct.model).toString().toUpperCase());
        }else{
          newValues.vehicle = autoCountYMM((value).toString().trim(), (selectedProduct.make).trim(), (selectedProduct.model).toString().toUpperCase(), productCount);
        }
    }
  
    if(property === 'make' && value !== null){
        if(autofillProductDetails){
          newValues.vehicle = autoCountYMM((selectedProduct.year).trim(), (value).toString().trim(),  (selectedProduct.model).toString().toUpperCase());
        }else{
          newValues.vehicle = autoCountYMM((selectedProduct.year).trim(), (value).toString().trim(), (selectedProduct.model).toString().toUpperCase(), productCount);
        }
    }
  
    if(property === 'model' && value !== null){
        if(autofillProductDetails){
          newValues.vehicle = autoCountYMM((selectedProduct.year).trim(),  (selectedProduct.make, (value).toString().trim()).toString().toUpperCase());
        }else{
          newValues.vehicle = autoCountYMM((selectedProduct.year).trim(), (selectedProduct.make).toString().toUpperCase(), (value).toString().trim(), productCount);
        }
    }
  
    if(property !== null && value !== null){
        return newValues;
    }

      if(autofillProductDetails){
        newValues.vin = (selectedProduct.vin).trim();
      }
      // newValues.purchaser_phone = (selectedProduct.purchaser_phone).trim();
      if(autofillProductDetails){
        newValues.vehicle = (selectedProduct.year.trim().replace('"', '').replace('"', '')+" "+selectedProduct.make.trim()+" "+selectedProduct.model.trim().toString().toUpperCase());
      }else{
        newValues.vehicle = "("+productCount+") "+(selectedProduct.year.trim().replace('"', '').replace('"', '')+" "+selectedProduct.make.trim()+" "+selectedProduct.model.trim().toString().toUpperCase());
      }
      
      return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  // X1.2- Field Type Specific Preview Formatting
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  if(payload.date){
    payload.date = moment.utc(payload.date).format("MM/DD/YY");
  }

  return payload;
}
import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";
import { autoYearMake } from './docUtils.js';

export const CAPTICertGlobalAutofill = (documentValues, order, property=null, value=null) => {

  var newValues = documentValues;
  // if(order && order.customer && order.customer.name){
  //   newValues.purchaser_name = order.customer.name;
  // }
  return newValues
}

export const CAPTICertAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
  var newValues = documentValues;

  if(property === 'year' && value !== null){
    newValues.year_make = autoYearMake((value).toString().trim().replace(/['"]+/g, ''), (selectedProduct.make).trim())
    newValues.app_year_make = autoYearMake((value).toString().trim().replace(/['"]+/g, ''), (selectedProduct.make).trim())
  }

  if(property === 'make' && value !== null){
      newValues.year_make = autoYearMake(selectedProduct.year, (value).toString().trim())
      newValues.app_year_make = autoYearMake(selectedProduct.year, (value).toString().trim())
  }

  if(property !== null && value !== null){
    return newValues;
  }

  newValues.vin = (selectedProduct.vin);
  newValues.year_make = (selectedProduct.year).trim()+" "+(selectedProduct.make).trim();
  newValues.app_vin = (selectedProduct.vin);
  newValues.app_year_make = (selectedProduct.year).trim()+" "+(selectedProduct.make).trim();

  return newValues;
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

    // Data point formatting
    if(payload.date){
      payload.date = moment.utc(payload.date).format("MM/DD/YY");
    }      
    
    // Data point formatting
    if(payload.owner_signature_date){
      payload.owner_signature_date = moment.utc(payload.owner_signature_date).format("MM/DD/YY");
    }
  
    if(payload.owner_signature_date_2){
      payload.owner_signature_date_2 = moment.utc(payload.owner_signature_date_2).format("MM/DD/YY");
    }

    if(payload.date){
      payload.date = moment.utc(payload.date).format("MM/DD/YY");
    }

  return payload;
}
import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const WASalesTaxGlobalAutofill = (documentValues, order, property=null, value=null) => {
    var newValues = documentValues;

    if(property ==='customer.name'){
      newValues.name = value;
      if(order?.customer?.cosigners && order?.customer?.cosigners?.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          newValues.name += " AND "+cosigner.name;
        }
      }
  }
    if(property ==='customer.cosigners[0].name'){
      newValues.name = order.customer.name;
      if(value !== ''){
          newValues.name += " AND "+value;
      }
  }
    if(property ==='customer.dba'){
      newValues.type_business = value
  }
    if(property ==='customer.mailingAddress.addressLine1'){
      newValues.mailing_address = order.customer.mailingAddress.addressLine1;
          if(order.customer.mailingAddress.addressLine2 && order.customer.mailingAddress.addressLine2 !== ""){
            newValues.mailing_address += " "+order.customer.mailingAddress.addressLine2;
          }
  }
    if(property ==='customer.mailingAddress.addressLine2'){
            newValues.mailing_address = order.customer.mailingAddress.addressLine1+ " "+order.customer.mailingAddress.addressLine2;
  }
    if(property ==='customer.mailingAddress.city'){
          newValues.mailing_city = formatUpperCase(order.customer.mailingAddress.city);
  }
    if(property ==='customer.mailingAddress.state'){
          newValues.mailing_state = order.customer.mailingAddress.state;
  }
    if(property ==='customer.mailingAddress.zip'){
          newValues.mailing_zip = order.customer.mailingAddress.zip;
  }
    if(property ==='customer.deliveryAddress.addressLine1'){
      newValues.buyer_address = value;
      if(order.customer.deliveryAddress.addressLine2 && order.customer.deliveryAddress.addressLine2 !== ""){
        newValues.buyer_address += " "+order.customer.deliveryAddress.addressLine2;
      }
  }
    if(property ==='customer.deliveryAddress.addressLine2'){
        newValues.buyer_address = order.customer.deliveryAddress.addressLine1+ " "+value;
  }
    if(property ==='customer.deliveryAddress.city'){
      newValues.buyer_city_state_zip = value+", "+order.customer.deliveryAddress.state+", "+order.customer.deliveryAddress.zip;
  }
    if(property ==='customer.deliveryAddress.state'){
      newValues.buyer_city_state_zip = order.customer.deliveryAddress.city+", "+value+", "+order.customer.deliveryAddress.zip;
  }
    if(property ==='customer.deliveryAddress.zip'){
      newValues.buyer_city_state_zip = order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+", "+value;
  }

    if(property !== null && value !== null){
      return newValues;
  }

      if(order?.customer){
        newValues.name = order.customer.name;
  
        if(order?.customer?.cosigners && order?.customer?.cosigners?.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner && cosigner.name){
            newValues.name += " AND "+cosigner.name;
          }
        }
  
        if(order?.customer?.dba){
          newValues.type_business = order?.customer?.dba;
        }
  
        if(order?.customer?.mailingAddress?.addressLine1){
          newValues.mailing_address = order.customer.mailingAddress.addressLine1;
          if(order.customer.mailingAddress.addressLine2 && order.customer.mailingAddress.addressLine2 !== ""){
            newValues.mailing_address += " "+order.customer.mailingAddress.addressLine2;
          }
          newValues.mailing_city = formatUpperCase(order.customer.mailingAddress.city);
          newValues.mailing_state = order.customer.mailingAddress.state;
          newValues.mailing_zip = order.customer.mailingAddress.zip;
        }else{
          newValues.mailing_address = order.customer.deliveryAddress.addressLine1;
          if(order.customer.deliveryAddress.addressLine2 && order.customer.deliveryAddress.addressLine2 !== ""){
            newValues.mailing_address += " "+order.customer.deliveryAddress.addressLine2;
          }
          newValues.mailing_city = formatUpperCase(order.customer.deliveryAddress.city);
          newValues.mailing_state = order.customer.deliveryAddress.state;
          newValues.mailing_zip = order.customer.deliveryAddress.zip;
        }
  
        newValues.buyer_address = order.customer.deliveryAddress.addressLine1;
        if(order.customer.deliveryAddress.addressLine2 && order.customer.deliveryAddress.addressLine2 !== ""){
          newValues.buyer_address += " "+order.customer.deliveryAddress.addressLine2;
        }
        newValues.buyer_city_state_zip = order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+", "+order.customer.deliveryAddress.zip;
     
      }
  
      return newValues
}

export const WASalesTaxAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
    var newValues = documentValues;

    if(property === 'year' && value !== null){
      newValues.year = (value).toString().trim().replace(/['"]+/g, '');
  }

  if(property === 'make' && value !== null){
      newValues.make = (value).toString().trim();
  }

  if(property === 'price' && value !== null){
    newValues.sales_price = formatter.format(selectedProduct.price);
}

  if(property !== null && value !== null){
      return newValues;
  }
    // newValues.year = (selectedProduct.year).trim();
    newValues.model = (selectedProduct.model).trim().toUpperCase();
    newValues.make = (selectedProduct.make).trim();
    newValues.year = (selectedProduct.year);
    newValues.vin = (selectedProduct.vin.toUpperCase());
    newValues.sale_price = formatter.format(selectedProduct.price);
  
    return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields, dateFields, radios) => {

  // Process radios for document preview
  radios?.forEach(radio => {
    radio?.options.forEach(option => {
      if(option.value !== "clear"){ //Don't add "None" as a property.
        payload[option.value] = "no";
        if(payload[radio.property] === option.value){
          payload[option.value] = "yes";
        }
      }
    });
  });

  // Process money, phone and uppercase fields for document preview
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  
  // Process checkboxes for document preview
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });
  
  // Format Date Fields for the document
  dateFields?.forEach(datefield => {
    if(payload[datefield]){
      payload[datefield] = moment.utc(payload[datefield]).format("MM/DD/YY");
    }
  });

  return payload;
}
import React, { useState, useEffect, useCallback } from "react";
import {
  useParams
} from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import { useOrder } from "../../../../../../../redux/orders/order"
import { removeChars, formatter } from "../../../../../../../utlis/formatters";
import moment from 'moment';
import {RadioGroup,Radio, FormControl, FormControlLabel} from '@mui/material';
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { TECDeliveryReceiptGlobalAutofill, TECDeliveryReceiptAutofillProductDetails, preProcessPayload } from "../DocumentAutofill/TECDeliveryReceipt"

const TECDeliveryReceipt = ({document_id, location, getDocuments, passedDocumentSlug, setDocumentFocus}) => {
  const documentSlug = "tec-delivery-receipt";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  const order = useOrder();

  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  const [document, setDocument] = useState(undefined);
  const [previousDocumentId, setPreviousDocumentId] = useState(0);

  var initialDocumentStructure = {
    location_address:undefined,
    location_phone:undefined,
    location_email:undefined,
    customer_name:undefined,
    collateral:undefined,
    invoice_date:undefined,
    physical_delivery_cb:undefined,
    transfer_ownership_cb:undefined,
    approximately_date:undefined,
    add_details_line_1:undefined,
    add_details_line_2:undefined,
    add_details_line_3:undefined,
    add_details_line_4:undefined,
    tec_footer_label:undefined,
    pg_no:undefined,
    date:undefined,
    date_2:undefined,
    physical_delivery_toggle: undefined,
    transfer_ownership_toggle: undefined,
  }

  // const checkboxes = useMemo(()=>{return["physical_delivery_toggle", "transfer_ownership_toggle"]},[]);


  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  const handleDocumentValuesChange = e =>{
    e.preventDefault();
    const {name,value}=e.target;
    if(name.includes('year')) {
        let newStr = value.replace(/[^0-9]/g,'');
        newStr = newStr.length > 4 ? documentValues[name].replace(/[^0-9]/g,'') : newStr;
        setDocumentValues({...documentValues,[name]:newStr});
    } else if(name.includes('license_number') || name.includes('serial_motor_number')) {
        let newStr = value.replace(/[^0-9]/g,'');
        setDocumentValues({...documentValues,[name]:newStr});
    } else if(name === 'odo_reading') {
        let val = removeChars(value);
        setDocumentValues({...documentValues,[name]:val});
    } else if(name === 'amount') {
      let val = '$' + (removeChars(value));
      setDocumentValues({...documentValues,[name]:val});
    }else{
        setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  
  // const [documentLoaded, setDocumentLoaded] = useState(false);
    
  const updatePreview = e => {
    e.preventDefault();
    previewPayloadDocument(documentValues);
  }

const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('TECDeliveryReceipt.jsx', 'Error loading document')
      }
    }
},[axios, id])
  
const [previewLoaded, setPreviewLoaded] = useState(false);
const [dataLoaded, setDataLoaded] = useState(false);
useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      // setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, loadDocument ])

  // const [productVal, setproductVal] = useState(undefined);
  const [assignedProduct, setAssignedProduct] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  // const [usedProductVal, setusedProductVal] = useState(undefined);
  // const [selectedUsedProduct, setselectedUsedProduct] = useState(undefined);
  // const [inputUsedProductVal, setinputUsedProductVal] = useState(undefined);
  // const [usedProductOptions, setUsedProductOptions] = useState(undefined);
  // const [usedProductToggleAutoFill, setUsedProductToggleAutoFill] = useState(false);


  // const [tradeinVal, setTradeinVal] = useState(undefined);
  // const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  // const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);

  // const [previousOrderId, setPreviousOrderId] = useState(undefined);

  // Dropdowns //

  // if(order && order.products && order.products.length > 0 && productOptions === undefined){
  //   let getProductOptions = [];
  //   order.products.map(product => {
  //     let str = `${product.stockNumber.toString().toUpperCase()}`;
  //     let obj = {id: product.id, name: str}
  //     getProductOptions.push(obj);
  //   })
  //   setproductOptions(getProductOptions);
  // }

  // if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
  //   let getTradeinOptions = [];
  //   order.tradeins.map(tradein => {
  //     let str = `${tradein.year} ${tradein.make} ${tradein.model}`;
  //     let obj = {id: tradein.id, name: str}
  //     getTradeinOptions.push(obj);
  //   })
  //   setTradeinOptions(getTradeinOptions);
  // }

 // Autofill //

  const GlobalAutofill = useCallback(async () => {
    let newValues = TECDeliveryReceiptGlobalAutofill(documentValues, order, null, null, location);
    if(newValues){
      setDocumentValues(newValues);
      setHasUnsavedChanges(true);
      setSaveSuccess(false);
    }

  // var newValues = documentValues;
  // if(order && order.customer && order.customer.name){
  //   newValues.customer_name = order.customer.name;
  // }
  // if(order && order.documentationDate ){
  //   let documentationDate = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  //   newValues.invoice_date = documentationDate;
  //   newValues.date = documentationDate;
  //   newValues.date_2 = documentationDate;
  // }


  // if(order && order.customer && order.customer.deliveryAddress){
  //   var addressLine = '';
  //   if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
  //     addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
  //   }else{
  //     addressLine = order.customer.deliveryAddress.addressLine1;
  //   }
  //   newValues.purchaser_line_3 = addressLine;
  // }

  // if(order && order.customer && order.customer.deliveryAddress){
  //   newValues.purchaser_line_4 = (order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
  // }

  if(location !== undefined){
    if(location.state === "CA"){
      newValues.dynamic_text_1 = "TEC of California, Inc. dba Tec Equipment located at "+location.address+", "+location.city+", "+location.state+", "+location.zip+", being owner of aforementioned motor vehicle, hereby sells and assigns all its rights, title and interest in said certificate of title and motor vehicle to:";  
      newValues.dynamic_text_2 = "TEC OF CALIFORNIA, INC., CALIFORNIA DEALER LICENSE NO. 31422";  
    }else{
      newValues.dynamic_text_1 = "TEC Equipment, Inc. located at "+location.address+", "+location.city+", "+location.state+", "+location.zip+", being owner of aforementioned motor vehicle, hereby sells and assigns all its rights, title and interest in said certificate of title and motor vehicle to:";
    }
    if(location.state === "OR"){
      newValues.dynamic_text_2 = "TEC EQUIPMENT, INC., OREGON DEALER LICENSE NO. 555";  
    }
    if(location.state === "WA"){
      newValues.dynamic_text_2 = "TEC EQUIPMENT, INC., WASHINGTON DEALER LICENSE NO. 7936";  
    }
    if(location.state === "NV"){
      newValues.dynamic_text_2 = "TEC EQUIPMENT, INC., NEVADA DEALER LICENSE NO. 29622";  
    }
    if(location.state === "AZ"){
      newValues.dynamic_text_2 = "TEC EQUIPMENT, INC., ARIZONA DEALER LICENSE NO. L00014432";  
    }
  }

  // Get Location Address & Autofill Address
  if(location !== undefined){
    newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
  }

  if(location && location.phone){
    newValues.location_phone = location.phone;
  }
  
  if(location && location.email){
    newValues.location_email = location.email;
  }

  if(location){
    if(location.state === "CA"){
      newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
    }else{
      newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
    }
  }

  // if(order && order.documentationDate && documentValues.delivery_date === undefined){
  //   newValues.date_day = moment.utc(order.documentationDate).format("DD");
  //   newValues.day_of = moment.utc(order.documentationDate).format("YYYY");
  //   newValues.date_month = moment.utc(order.documentationDate).format("MM");
  // }

  // newValues.pg_no = "Pg #1";
  
  setDocumentValues(newValues);
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ documentValues, order, location ])

    
//   if(payload.physical_delivery_toggle){
//     // Reset all
//     payload.physical_delivery_cb = "no";

//     switch(payload.physical_delivery_toggle){
//       case "yes":
//         payload.physical_delivery_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.transfer_ownership_toggle){
//     // Reset all
//     payload.transfer_ownership_cb = "no";

//     switch(payload.transfer_ownership_toggle){
//       case "yes":
//         payload.transfer_ownership_cb = "yes";
//         break;
//       default:
//     }
//   }

//     // payload.transfer_ownership_cb = "no";
//     // if(payload.transfer_ownership_toggle === "yes"){
//     //   payload.transfer_ownership_cb = "yes";
//     // }

//     if(payload.transfer_ownership_toggle === "yes" && payload.approximately_date){
//       payload.approximately_date = moment.utc(payload.approximately_date).format("MM/DD/YY");
//     }else if (payload.transfer_ownership_toggle === "no" && payload.approximately_date){
//       payload.approximately_date = "";
//     }

//     if(payload.invoice_date){
//       payload.invoice_date = moment.utc(payload.invoice_date).format("MM/DD/YY");
//     }
//     if(payload.date){
//       payload.date = moment.utc(payload.date).format("MM/DD/YY");
//     }
//     if(payload.date_2){
//       payload.date_2 = moment.utc(payload.date_2).format("MM/DD/YY");
//     }
//     return payload;
// },[])
const previewPayloadDocument = useCallback(async (documentValues) => {

  var payload = documentValues;
  if(payload.serial_number){
    payload.serial_number = payload.serial_number.toString().toUpperCase();
  }
  Object.keys(payload).forEach(key => {
    if (payload[key] === null || payload[key] === undefined) {
      delete payload[key];
    }
  });
  if(document){
    if(document.documentTemplate.slug === documentSlug){

      var docCopy = JSON.parse(JSON.stringify(document));
      docCopy.payload =  JSON.parse(JSON.stringify(payload));
      docCopy.payload = preProcessPayload(docCopy.payload, null, null, null, null);
      docCopy.payload =  JSON.stringify(docCopy.payload);
      delete docCopy.documentStatus;
      
      // return axios.put(`/document/${document.id}/prepare`, docCopy);  
      // return axios.post(`/document/${document.id}/preview-payload`, docCopy);  
      try {
        let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
        setdocumentUrl(fileURL)
        } catch (err) {
          recordFrontEndError('TECDeliveryReceipt.jsx', 'Error previewing document payload: ' + err)
        };  
    }
  }
},[axios, document])

// function formatCollateral(year, make, vin){
//   return (year).trim()+' '+(make).trim().toString().toUpperCase()+' '+ (vin).trim().toUpperCase();
// }

const AutofillProductDetails = useCallback(async (selectedProduct) => {

  let newValues = TECDeliveryReceiptAutofillProductDetails(selectedProduct, documentValues);

  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  // var newValues = documentValues;
  // // if(selectedProduct.year){
  // //   newValues.year = (selectedProduct.year).trim();
  // // }
  // // if(selectedProduct.make){
  // //   newValues.make = (selectedProduct.make).trim().toString().toUpperCase();
  // // }
  // // if(selectedProduct.vin){
  // //   newValues.serial_number = (selectedProduct.vin).trim().toUpperCase();
  // // }
  // // newValues.state_of = (selectedProduct.registrationAddress?.state).trim();
  // if(selectedProduct.year && selectedProduct.make && selectedProduct.vin){
  //   newValues.collateral = formatCollateral(selectedProduct.year, selectedProduct.make, selectedProduct.vin);
  // }
  // //   if(selectedProduct.shipTo){
  // //   newValues.purchaser_line_1 = selectedProduct.shipTo.name;
  // //   newValues.purchaser_line_3 = selectedProduct.shipTo.addressLine1;
  // //   if(selectedProduct.shipTo.addressLine2){
  // //     newValues.purchaser_line_3 = newValues.address+" "+selectedProduct.shipTo.addressLine2;
  // //   }
  // //   newValues.purchaser_line_4 = selectedProduct.shipTo.city+", "+selectedProduct.shipTo.state+" "+selectedProduct.shipTo.zip;
  // // }

  // // if(selectedProduct.soldTo){
  // //   newValues.sold_to_line_1 = selectedProduct.soldTo.name;
  // //   newValues.sold_to_line_3 = selectedProduct.soldTo.addressLine1;
  // //   if(selectedProduct.soldTo.addressLine2){
  // //     newValues.sold_to_line_3 = newValues.address+" "+selectedProduct.soldTo.addressLine2;
  // //   }
  // //   newValues.sold_to_line_4 = selectedProduct.soldTo.city+", "+selectedProduct.soldTo.state+" "+selectedProduct.shipTo.zip;
  // // }

  // // if(selectedProduct.fundingDetail){
  // //   if(selectedProduct.fundingDetail.fundingMethod === "tec-financing" || selectedProduct.fundingDetail.fundingMethod === "finance-only" || selectedProduct.fundingDetail.fundingMethod === "direct-deal"){
  // //     if(selectedProduct.fundingDetail.finalizedLendingOption){
  // //       newValues.lienholder_line_1 = selectedProduct.fundingDetail.finalizedLendingOption.bankName;
  // //       newValues.lienholder_line_2 = selectedProduct.fundingDetail.finalizedLendingOption.lienAddress;
  // //       newValues.lienholder_line_3 = selectedProduct.fundingDetail.finalizedLendingOption.lienCity+", "+selectedProduct.fundingDetail.finalizedLendingOption.lienState+" "+selectedProduct.fundingDetail.finalizedLendingOption.lienZip;
  // //     }
  // //   }

  // //   if(selectedProduct.fundingDetail.fundingMethod === "cash-with-lien"){
  // //     if(selectedProduct.fundingDetail.cashLienHolder){
  // //       newValues.lienholder_line_1 = selectedProduct.fundingDetail.cashLienHolder.bankName;
  // //       newValues.lienholder_line_2 = selectedProduct.fundingDetail.cashLienHolder.addressLine1+" "+(selectedProduct.fundingDetail.cashLienHolder.addressLine2 ? selectedProduct.fundingDetail.cashLienHolder.addressLine2 : "");
  // //       newValues.lienholder_line_3 = selectedProduct.fundingDetail.cashLienHolder.city+", "+selectedProduct.fundingDetail.cashLienHolder.state+" "+selectedProduct.fundingDetail.cashLienHolder.zip;
  // //     }
  // //   }

  // // }


  // setDocumentValues(newValues);

  // // setProductToggleAutoFill(false);
  // // previewPayloadDocument(documentValues);
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ documentValues ]);



const previewDocument = useCallback(async () => {
  try {
    let response = await axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
      const file = new Blob(
        [response.data], 
        {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
      setdocumentUrl(fileURL)
    } catch (err) {
      recordFrontEndError('TECDeliveryReceipt.jsx', 'Error previewing document: ' + err)
    }
},[axios, document]); 

useEffect(() => {
  if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){
      existingPayload = true;

      if(documentPayload.invoice_date){
        documentPayload.invoice_date = moment.utc(documentPayload.invoice_date).format("YYYY-MM-DD")
      }

      if(documentPayload.transfer_ownership_cb === "yes" && documentPayload.approximately_date){
        documentPayload.approximately_date = moment.utc(documentPayload.approximately_date).format("YYYY-MM-DD")
      }else if(documentPayload.approximately_date){
        documentPayload.approximately_date = "";
      }

      if(documentPayload.date){
        documentPayload.date = moment.utc(documentPayload.date).format("YYYY-MM-DD")
      }

      if(documentPayload.date_2){
        documentPayload.date_2 = moment.utc(documentPayload.date_2).format("YYYY-MM-DD")
      }

      setDocumentValues(documentPayload);
    }

    var foundAssignedOrder = null;
    if(document.assignedObjectType && document.assignedObjectType === 'order'){
      foundAssignedOrder = true;
    }

    var foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }

    if(foundAssignedProduct){
      setAssignedProduct(foundAssignedProduct);
    }
  
    // setAddressOptions(getOrderLevelAddresses(order));
    if(!existingPayload){
      GlobalAutofill();
      // previewPayloadDocument(documentValues);
      if(foundAssignedProduct){
        AutofillProductDetails(foundAssignedProduct)
      }
      if(foundAssignedOrder){
        // Autofill "See Schedule A"
        var copyDocumentValues = documentValues;
        copyDocumentValues.collateral = "See Schedule A";
        setDocumentValues(copyDocumentValues);
        setHasUnsavedChanges(true);
        setSaveSuccess(false);
      }
      // if(document){
      //   previewPayloadDocument(documentValues);
      // }
    }else{
      // if(document && document.itemIds){
      //   var itemIds = document.itemIds.split(',');
      //     var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
      //     let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
      //     let obj = {id: foundProduct.id, name: str}
      //     setproductVal(obj);
      //     // if(foundProduct){
      //     //   setAddressOptions(updateAddressOptions(foundProduct, order));
      //     // }
      // }
  
      previewDocument();
    }
    
    setDataLoaded(true);
  }else if(document && document.payload && dataLoaded && !previewLoaded){
    setPreviewLoaded(true);
    previewPayloadDocument(documentValues);
  }
},[ documentValues, document, dataLoaded, AutofillProductDetails, GlobalAutofill, order, previewPayloadDocument, previewDocument, previewLoaded ]);



  // Perform an .GET for the initial render
  // useEffect(()=>{
  //   if (documentUrl === "" && document) {
  //     previewDocument();
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[documentUrl, document]);

//   setTimeout(function(){
//     this.printIframeRef.contentWindow.document.getElementById('secondaryPrint').click()
// }.bind(this), 3000)

  // Component Events





  const handleSubmit = async event => {
    event.preventDefault();

    let payload = JSON.parse(JSON.stringify(documentValues));

    payload = preProcessPayload(payload, null, null, null, null);
  
    // const payload = JSON.parse(JSON.stringify(documentValues));
    // var docCopy = document;
    // docCopy.payload = preProcessPayload(payload);
    // docCopy.payload =  JSON.stringify(docCopy.payload);
    // delete docCopy.documentStatus;
    
    // if(selectedProduct && selectedProduct.id){
    //   docCopy.itemIds = `${selectedProduct.id}`;
    // }


    // let additionalData = JSON.stringify(dropdownData);

    DocumentHandleSubmit(document, payload, null, () => {
      getDocuments();
      setSaveSuccess(true);
      setHasUnsavedChanges(false);
    });

    // try {
    //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
    //   if (res) {
    //     await axios.post(`/document/${document.id}/generate`) 
    //     getDocuments();
    //     setSaveSuccess(true);
    //     setHasUnsavedChanges(false);
    //   };
    // } catch (err) {
    //   recordFrontEndError('TECDeliveryReceipt.jsx', 'Error saving document: ' + err)
    // };

    // resetVals();
    
  };

const onCloseClick = e => {
  e.preventDefault()
  setPreviewLoaded(false);
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setdocumentUrl("");
  setDocument(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
  // setDocumentLoaded(false);
  setDataLoaded(false);
  setAssignedProduct(undefined);
  setDocumentFocus(undefined);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

  return (
    <>
      <div
        className="modal fade"
        // id="tec-vehicle-sale"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                TEC Delivery Receipt
              </h5>
              <br />
              { hasClickedClose ? (
                <div className="d-flex">
                    <p className="m-1 pt-1 text-white">Save Changes?</p>
                    <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                    <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                </div>
              ): hasUnsavedChanges ? (
              <button
                type="button"
                className="btn-close bg-white"
                aria-label="Close"
                // onClick={() => setdocumentUrl("")}
                onClick={closeClicked}
              ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill();
                      AutofillProductDetails(assignedProduct);
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>
                  
                  <div className="row">

                  <h5>Select Vehicle</h5>


                  { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                      Product Snapshot
                      <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                      </div>
                    </div>
                  ):(
                    <div className="m-2 p-2 border">
                      <span>No Assigned Product</span>
                    </div>
                  )}
                  
                  <h5>Customer Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="customer_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.customer_name ?? '' }
                          />
                      </div>
                    </div>
                    
                    <br />
                    <hr></hr>
                  <h5>Collateral Information</h5>
                  
                    {/* <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                      
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Serial No.</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="serial_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => {
                            let updatedObject = documentValues;
                            updatedObject.serial_number = documentValues.serial_number.toUpperCase();
                            setDocumentValues(updatedObject);
                            // setDocumentValues({...documentValues,['serial_number']:documentValues.serial_number.toUpperCase()});
                            updatePreview(e);
                          }}
                          value={ documentValues.serial_number ?? '' }
                          />
                      </div>
                    </div>
                    <br /> */}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Collateral</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="collateral"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.collateral ?? '' }
                          />
                      </div>
                    </div>
                    
                    <br />
                    <hr></hr>


                    <h5>Invoice Date</h5>


                    <div className="d-flex justify-content-start">
                    <div className="p-2 col-3">
              <input
                type="date"
                className="form-control rounded-pill mt-1"
                name="invoice_date"
                onChange={handleDocumentValuesChange}
                onBlur={(e) => updatePreview(e)}
                value={ documentValues.invoice_date ?? '' }
                />
            </div>
                      </div> 
                    <br />
                    <hr></hr>

                    <h5>Check Boxes</h5>
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="p-2 col-12">
                        <label>Physical Delivery</label>
                        <br />
                        <FormControl className="px-3 pt-2">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="physical_delivery_toggle"
                            value={(documentValues.physical_delivery_toggle ?? "no")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel className="p-3" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel className="p-3" value="no" control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="p-2 col-12">
                        <label>Transfer Ownership</label>
                        <br />
                        <FormControl className="px-3 pt-2">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="transfer_ownership_toggle"
                            value={(documentValues.transfer_ownership_toggle ?? "no")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel className="p-3" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel className="p-3" value="no" control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>

                    { documentValues && documentValues.transfer_ownership_toggle === "yes" ? (
                      <div className="p-2 col-12">
                        <label htmlFor="stock">Approximate Delivery Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="approximately_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.approximately_date ?? '' }
                          />
                      </div>
                    ):null}

                    <br />
                    <hr></hr>
                    <h5>Details</h5>
                    <div style={{flexDirection:'column'}}className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Line 1</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="add_details_line_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.add_details_line_1 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Line 2</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="add_details_line_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.add_details_line_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Line 3</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="add_details_line_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.add_details_line_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Line 4</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="add_details_line_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.add_details_line_4 ?? '' }
                          />
                      </div>
                    </div>
                    <br/>
                    <hr/>
                    <h5>Signature Dates</h5>
                    <div className="d-flex justify-content-start">
                      <div className="p-2 col-3">
                        <label htmlFor="stock">Customer Signature Date</label>
                        <input
                        type="date"
                        className="form-control rounded-pill mt-1"
                        name="date"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.date ?? '' }
                        />
                      </div>
                      <div className="p-2 col-3">
                        <label htmlFor="stock">Dealer Signature Date</label>
                        <input
                        type="date"
                        className="form-control rounded-pill mt-1"
                        name="date_2"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.date_2 ?? '' }
                        />
                      </div>
                    </div> 

                <hr></hr>
                </div>
      
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
              <span
                className="btn btn-success mx-auto"
              >
                Saved Document
              </span>
            ):(
              <button
              type="button"
              className="btn btn-primary mx-auto"
              // data-bs-dismiss="modal"
              onClick={handleSubmit}
              >
              Save
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default TECDeliveryReceipt

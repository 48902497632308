import axios from "axios"
import * as api from '../../../utlis/api'

export function checkAllOrdersOut(orders){
    for(const order of orders){
        axios.get(`order/${order.id.toString()}/uncheckout`)
    }
}

export function checkSelectedOrdersOut(orders){
    for(const order of orders){
        if(order?.selected) {
            api.useFetch(`order/${order.id.toString()}/uncheckout`)
        }
    }
}

export function selectItem(id, orders){
    let ordersCopy= [...orders]
    let index= orders.findIndex(order=>Number(order.id)===Number(id))
    let updatedOrder= {...ordersCopy[index], selected:!ordersCopy[index]?.selected}
    ordersCopy[index]=updatedOrder
    return ordersCopy
}

export const fetchCheckedOutOrders= async(type)=>{
    let orderArr =[]
    switch (type) {
        case 'ALL':
            try{
                let res = await api.getAllCheckedOut()
                for (const obj of res?.data){
                    orderArr.push({...obj, selected:false})
                }
            } catch (err){
                api.recordFrontEndError('CheckedOutHelpers.js', 'Error getting all checked out orders: ' + err )
            }
            break;
            case 'MINE':
                try{
                    let res = await api.getMyCheckedOut()
                    for (const obj of res?.data){
                        orderArr.push({...obj, selected:false})
                    }
                } catch (err){
                    api.recordFrontEndError('CheckedOutHelpers.js', 'Error getting all checked out orders: ' + err )
                }
                break
                ;
                
                default:
                    break;
                }
                return orderArr
                
}

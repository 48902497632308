import React, {useState, useEffect,useCallback} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import {
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { setLenderFocus } from "../../../../../../../redux/products/lenderFocusReducer";
import { recordFrontEndError } from "../../../../../../../utlis/api"
import * as api from "../../../../../../../utlis/api"
import NumberFormat from "react-number-format"

import {formatPercentValue, removeChars, formatter} from "../../../../../../../utlis/formatters";
  
function QuickSearchToolbar(props) {
return (
    <Box
    sx={{
        p: 0.5,
        pb: 0,
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
    }}
    >
    <div className="w-100">
        <GridToolbarFilterButton />
    </div>
    <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        InputProps={{
        startAdornment: <SearchIcon fontSize="small" />,
        endAdornment: (
            <IconButton
            title="Clear"
            aria-label="Clear"
            size="small"
            style={{ visibility: props.value ? 'visible' : 'hidden' }}
            onClick={props.clearSearch}
            >
            <ClearIcon fontSize="small" />
            </IconButton>
        ),
        }}
        sx={{
        width: {
            xs: 1,
            sm: 'auto',
        },
        m: (theme) => theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
            mr: 0.5,
        },
        '& .MuiInput-underline:before': {
            borderBottom: 1,
            borderColor: 'divider',
        },
        }}
    />
    </Box>
);
}

QuickSearchToolbar.propTypes = {
clearSearch: PropTypes.func.isRequired,
onChange: PropTypes.func.isRequired,
value: PropTypes.string.isRequired,
};

function ProductFinanceLenderTermsModal({product, updateLenderInfoFromChild}) {
  let { id } = useParams();
  const dispatch = useDispatch();

  // const product_id = useSelector((state) => state?.productFocus.product_focus_id);
    let lender_option_id = useSelector((state) => state?.lenderFocus.lender_option_focus_id);
  const lenderTerms = {
    term: undefined,
    adminFee: undefined,
    customerRate: undefined,
    reserve: undefined,
    dealerRate: undefined,
    insuranceIncome: undefined,
  }

  // const axios = useFetch()
  const [lenderObject, setlenderObject] = useState(undefined);
  // const [productLenders, setproductLenders] = useState(undefined);
  const [finalizeBtnDisplay, setfinalizeBtnDisplay] = useState('block');


  const [lenderTermsObject, setlenderTermsObject] = useState(lenderTerms);


  const handleLenderTermsChange = (e = null, prop = null, options = null) =>{
    if(!options && e?.target){
    const {name,value}=e.target;
        setlenderTermsObject({...lenderTermsObject,[name]:value});
    } 
    if (options){
      let value = options.value
      let  name  =e?.event?.target?.name
      setlenderTermsObject({...lenderTermsObject,[name]:value});
    }
  }

  const getLenderTerms = useCallback(async () => {
    if(lender_option_id > 0){
      setlenderObject(undefined);
      try {
        let response = await api.getLenderTerms(id, product.id,lender_option_id)
        var customerRate = formatPercentValue(response.data.customerRate);
          var dealerRate = formatPercentValue(response.data.dealerRate);
          var adminFee = formatter.format(response.data.adminFee);
          var reserve = formatter.format(response.data.reserve);
          var insuranceIncome = formatter.format(response.data.insuranceIncome);

          var data = {
            term: response.data.term,
            adminFee: adminFee,
            customerRate: customerRate,
            reserve: reserve,
            dealerRate: dealerRate,
            insuranceIncome: insuranceIncome,
          }

          if(!response.data.finalizedAt){
            setfinalizeBtnDisplay('block');
          }

          setlenderTermsObject(data);
          setlenderObject(response.data);
      } catch (err) {
        recordFrontEndError('ProductFinanceLenderTermsModal.js', 'Error getting lender terms: ', + err )
      }   
    }
  },[id,lender_option_id,product.id])

  const finalizeLender = async (e) => {
    putLenderTerms();
    if(id && product.id && lender_option_id){
      try {
      await api.finalizeLenderTerms(id, product.id,lender_option_id)
      } catch (err) {
        recordFrontEndError('ProductFinanceLenderTermsModal.js', 'Error finalizing lender: ' + err)
      }
      updateLenderInfoFromChild(true)
      clearComponenet();
      getLenderTerms();
      // getProductLenders();
    }
  }


  const unfinalizeLender = async (e) => {

    if(id && product.id && lender_option_id){
      try {
        await api.unFinalizeLenderTerms(id, product.id,lender_option_id)
      } catch (err) {
        recordFrontEndError('ProductFinanceLenderTermsModal.js', 'Error un-finalizing lender: ' + err)
      }
      updateLenderInfoFromChild(true)
      clearComponenet();
      getLenderTerms();
      // getProductLenders();
    }
  }

  const handlePercentBlur = e =>{
    let {name, value} = e?.target
    value = formatPercentValue(value.replace(/[^0-9.]/g, ""))
    setlenderTermsObject({...lenderTermsObject,[name]:value});

  }

  const [previousLenderOptionId, setPreviousLenderOptionId] = useState(0);


  useEffect(() => {
    if(lender_option_id > 0 && lender_option_id !== previousLenderOptionId) {
      setPreviousLenderOptionId(lender_option_id);
      getLenderTerms();
    }
  },[ lender_option_id, getLenderTerms, previousLenderOptionId ])

  const putLenderTerms = async () =>{

    let payload = lenderTermsObject;
    payload.term =  payload.term ? payload.term : payload.term;
    payload.customerRate = removeChars(payload.customerRate);
    payload.dealerRate = removeChars(payload.dealerRate);
    payload.insuranceIncome = removeChars(payload.insuranceIncome);
    payload.adminFee = removeChars(payload.adminFee);
    payload.reserve = removeChars(payload.reserve);

    try {
      api.updateLenderTerms(id,product.id,lender_option_id, lenderTermsObject)
    } catch (err) {
      recordFrontEndError('ProductFinanceLenderTermsModal.js', 'Error updating lender terms: ' + err)
    }
  }

  const handleSubmit=e=>{
    e.preventDefault();
    putLenderTerms();
    clearComponenet();
    // getProductLenders();
  }

  const clearComponenet = e => {
    setfinalizeBtnDisplay('block');
    setlenderObject(undefined);
    setlenderTermsObject(lenderTerms);
    setPreviousLenderOptionId(0);
    dispatch(setLenderFocus({}));
    // getProductLenders();
  }

  const formatMoneyValue=e=>{
    const {name,value}=e.target;
    setlenderTermsObject({...lenderTermsObject,[name]:formatter.format(removeChars(value))});
  }
  
  return (
    
    <>
    {(product.id !== undefined) ? (
      <div
        className="modal fade"
        id="productFinanceLenderTermsModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header bg-primary my-0 py-1">
              <div className="col-12 d-flex">
                <h5
                  className="modal-title text-white col-8 my-2"
                  id="exampleModalLabel"
                >
                  Lender Terms - { lenderObject && lenderObject.bankName ? lenderObject.bankName : '' }
                </h5>
                <button
                  type="button"
                  className="btn-close bg-white mt-2"
                  data-bs-dismiss="modal"
                  aria-label="close"
                  data-bs-toggle="modal"
                  data-bs-target="#productModal"
                  data-testid="product.financing.lenderTerms.control.close"
                  onClick={e => {
                      clearComponenet();
                      // getProductLenders();
                    }
                  }
                ></button>
              </div>
            </div>
            <div className="modal-body"  style={{height: "650px"}}>
              <div className="container">
                <div className="row border-bottom">
                    <div className="d-flex text-capitalize">
                        <div className="my-2">
                            <h5>Financing Details / Reserve Report</h5>
                        </div>
                    </div>
                </div>
                <div className="container-fluid row mt-4">
                  <div className="d-flex mx-4 my-3 ">
                    <div className="col-5 mx-3">
                      <label htmlFor="stock">Term</label>
                      <input
                        onChange={handleLenderTermsChange}
                        name='term'
                        type="text"
                        data-testid="product.financing.lenderTerms.term"
                        aria-label="lender term"
                        className="form-control rounded-pill mt-1 "
                        value={ lenderTermsObject && lenderTermsObject.term !== undefined ? lenderTermsObject.term : ''}
                      />
                    </div>
                    <div className="col-5 mx-3 ">
                      <label htmlFor="type">Admin Fee</label>
                      <NumberFormat
                        className="rounded-pill form-control"
                        value={ lenderTermsObject?.adminFee ?? Number().toFixed(2) }
                        name="adminFee"
                        aria-label='lender admin fee'
                        data-testid="product.financing.lenderTerms.adminFee"
                        thousandSeparator={true}
                        decimalScale={2}
                        prefix={'$'}
                        onValueChange={ (options, e) =>handleLenderTermsChange(e,'adminFee',options) }
                        onBlur={e => formatMoneyValue(e)}
                      />
                    </div>
                  </div>
                  <div className="d-flex mx-4 my-3 ">
                    <div className="col-5 mx-3">
                      <label htmlFor="stock">Customer Rate</label>
                      <NumberFormat
                        className="rounded-pill form-control"
                        value={ lenderTermsObject?.customerRate?? Number().toFixed(3)}
                        name="customerRate"
                        aria-label='lender customer rate'
                        thousandSeparator={true}
                        data-testid="product.financing.lenderTerms.customerRate"
                        decimalScale={3}
                        suffix={'%'}
                        onValueChange={ (options,e) =>handleLenderTermsChange(e,'customerRate',options)}
                        onBlur={e => handlePercentBlur(e)}
                      />
                    </div>
                    <div className="col-5 mx-3 ">
                      <label htmlFor="type">Reserve</label>
    
                      <NumberFormat
                        className="rounded-pill form-control"
                        value={ lenderTermsObject?.reserve ?? Number().toFixed(2) }
                        name="reserve"
                        aria-label='lender reserve'
                        thousandSeparator={true}
                        data-testid="product.financing.lenderTerms.reserve"
                        decimalScale={2}
                        prefix={'$'}
                        onValueChange={ (options, e) =>handleLenderTermsChange(e,'reserve',options) }
                        onBlur={e => formatMoneyValue(e)}
                      />
                    </div>
                  </div>
                  <div className="d-flex mx-4 my-3 ">
                    <div className="col-5 mx-3">
                      <label htmlFor="stock">Dealer Rate</label>
                      <NumberFormat
                        className="rounded-pill form-control"
                        value={ lenderTermsObject?.dealerRate?? Number().toFixed(3)}
                        name="dealerRate"
                        aria-label='lender dealer rate'
                        data-testid="product.financing.lenderTerms.dealerRate"
                        thousandSeparator={true}
                        decimalScale={3}
                        suffix={'%'}
                        onValueChange={ (options,e) =>handleLenderTermsChange(e,'dealerRate',options)}
                        onBlur={e => handlePercentBlur(e)}
                      />
                    </div>
                    <div className="col-5 mx-3 ">
                      <label htmlFor="type">Insurance Income</label>
                      <NumberFormat
                        className="rounded-pill form-control"
                        value={ lenderTermsObject?.insuranceIncome ?? Number().toFixed(2) }
                        name="insuranceIncome"
                        aria-label='lender insurance income'
                        thousandSeparator={true}
                        data-testid="product.financing.lenderTerms.insuranceIncome"
                        decimalScale={2}
                        prefix={'$'}
                        onValueChange={ (options, e) =>handleLenderTermsChange(e,'insuranceIncome',options) }
                        onBlur={e => formatMoneyValue(e)}
                      />
                      <br />
                      <br />
                      <h4 data-testid="product.financing.lenderTerms.calc.totalFndI" >{ formatter.format(parseFloat(removeChars(lenderTermsObject.insuranceIncome ?? 0.00)) + parseFloat(removeChars(lenderTermsObject.adminFee ?? 0.00)) + parseFloat(removeChars(lenderTermsObject.reserve ?? 0.00))) }</h4>
                      <h6 className="text-secondary">Total F & I</h6>
                    </div>
                    <div className="col-4 mx-3 text-capitalize">
                    </div>
                  </div>
                  <hr className="my-5 mx-3" />
                  <div className="d-flex mb-3">



                  <div className="col-12 mx-3">
                  { lenderObject && !lenderObject?.finalizedAt ? (
                    <div className="text-center" style={{ display: finalizeBtnDisplay }}>
                      <button 
                        type="submit" 
                        // onClick={e => addLenders(e, selections)} 
                        data-bs-dismiss="modal" 
                        className="btn btn-success"
                        aria-label="close"
                        data-bs-toggle="modal"
                        data-bs-target="#productModal"
                        data-testid="product.financing.lenderTerms.control.saveTerms"
                        onClick={handleSubmit}
                      >
                          Save Terms
                      </button>
                      <button 
                        data-bs-dismiss="modal" 
                        aria-label="save and finalize"
                        data-bs-toggle="modal"
                        data-bs-target="#productModal"
                        data-testid="product.financing.lenderTerms.control.finalizeLender"
                        onClick={() => {
                          finalizeLender();
                          
                          // setfinalizeBtnDisplay('none');
                          }
                        } 
                        className="mx-3 btn btn-warning "
                      >
                        Save & Finalize Lender
                      </button>
                    </div>
                  ): lenderObject && lenderObject?.finalizedAt ? (
                    <div className="text-center" >
                      <button 
                        type="submit" 
                        // onClick={e => addLenders(e, selections)} 
                        data-bs-dismiss="modal" 
                        className="btn btn-success"
                        aria-label="save"
                        data-bs-toggle="modal"
                        data-bs-target="#productModal"
                        data-testid="product.financing.lenderTerms.control.saveTerms"
                        onClick={handleSubmit}
                      >
                          Save Terms
                      </button>
                      <button 
                        data-bs-dismiss="modal" 
                        aria-label="unfinalize"
                        data-bs-toggle="modal"
                        data-bs-target="#productModal"
                        ata-testid="product.financing.lenderTerms.control.unfinalizeLender"
                        onClick={() => {
                          unfinalizeLender();
                          // setfinalizeBtnDisplay('block');
                          }
                        } 
                        className="mx-3 btn btn-primary "
                      >
                        Unfinalize Lender
                      </button>
                    </div>
                  ):null}
                  </div>

                
                   
                  </div>
                </div>
              </div>
            </div>  
          </div>
        </div>
      </div>
      ) : null}
    </>
    
  );
}

export default ProductFinanceLenderTermsModal

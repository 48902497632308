import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase, getFirstName, getMiddleName, getLastName } from "../../../../../../../utlis/formatters";

export const NETitleAppGlobalAutofill = (documentValues, order, property=null, value=null) => {

    var newValues = documentValues;

    if(property?.includes('customer') || property === null){

      if(order && order.customer){
    
        if(order && order.customer && order.customer.name){

          if(order.customer.type === "entity"){
            newValues.owner_last_name_or_business_name = order.customer.name;
          }else{
            newValues.owner_last_name_or_business_name = getLastName(order.customer.name);
            newValues.owner_middle_name = getMiddleName(order.customer.name);
            newValues.owner_first_name = getFirstName(order.customer.name);
          }
      
          if(order.customer.cosigners && order.customer.cosigners.length > 0){
            let cosigner = order.customer.cosigners[0];
            if(cosigner){
              newValues.owner_last_name_or_business_name_2 = getLastName(order.customer.name);
              newValues.owner_middle_name_2 = getMiddleName(order.customer.name);
              newValues.owner_first_name_2 = getFirstName(order.customer.name);
            }
          }
        }
        
        if(order && order.customer && order.customer.deliveryAddress){
          newValues.owners_residential_address_city_state_zip = order.customer.deliveryAddress.addressLine1;
          if(order.customer.deliveryAddress.addressLine1 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
            newValues.owners_residential_address_city_state_zip = newValues.owners_residential_address_city_state_zip+" "+order.customer.deliveryAddress.addressLine2;
          }
    
          newValues.owners_residential_address_city_state_zip += " "+order.customer.deliveryAddress.city+" "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
        }
    
        if(order && order.customer && order.customer.mailingAddress){
          newValues.owners_mailing_address_city_state_zip = order.customer.mailingAddress.addressLine1;
          if(order.customer.mailingAddress.addressLine1 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
            newValues.owners_mailing_address_city_state_zip = newValues.owners_mailing_address_city_state_zip+" "+order.customer.mailingAddress.addressLine2;
          }
    
          newValues.owners_mailing_address_city_state_zip += " "+order.customer.mailingAddress.city+" "+order.customer.mailingAddress.state+" "+order.customer.mailingAddress.zip;
        }
      }

    }
    
    if(property?.includes('documentationDate') || property === null){

      if(order && order.documentationDate){
        newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
      }

    }
    return newValues
}

export const NETitleAppAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
  var newValues = documentValues;

  if(property?.includes('year') || property === null){
    newValues.year = (selectedProduct.year);
  }

  if(property?.includes('make') || property === null){
    newValues.make = (selectedProduct.make).toString().toUpperCase();
  }

  if(property?.includes('model') || property === null){
    newValues.model = (selectedProduct.model).toString().toUpperCase();
  }

  if(property?.includes('vin') || property === null){
    newValues.vin = (selectedProduct.vin).toString().toUpperCase();
  }

  if(property?.includes('vehicleType') || property === null){
    newValues.body_style = (selectedProduct.vehicleType);
  }

  if(property?.includes('fundingDetail') || property?.includes('fundingMethod') || property?.includes('shipTo') || property === null){

    if(selectedProduct.fundingDetail && (selectedProduct.fundingDetail.fundingMethod === "tec-financing" || selectedProduct.fundingDetail.fundingMethod === "finance-only" || selectedProduct.fundingDetail.fundingMethod === "direct-deal")){
      newValues.lien_holder_name_1 = selectedProduct.fundingDetail.finalizedLendingOption.bankName;
      newValues.lien_holder_street_address = selectedProduct.fundingDetail.finalizedLendingOption.lienAddress;
      newValues.lien_holder_city = selectedProduct.fundingDetail.finalizedLendingOption.lienCity;
      newValues.lien_holder_state = selectedProduct.fundingDetail.finalizedLendingOption.lienState;
      newValues.lien_holder_zip = selectedProduct.fundingDetail.finalizedLendingOption.lienZip;   
    }else if(selectedProduct.fundingDetail && selectedProduct.fundingDetail.fundingMethod === "cash-with-lien"){
      newValues.lien_holder_name_1 = selectedProduct.fundingDetail.cashLienHolder.bankName;
      newValues.lien_holder_street_address = selectedProduct.fundingDetail.cashLienHolder.addressLine1+" "+selectedProduct.fundingDetail.cashLienHolder.addressLine2;
      newValues.lien_holder_city = selectedProduct.fundingDetail.cashLienHolder.city;
      newValues.lien_holder_state = selectedProduct.fundingDetail.cashLienHolder.state;
      newValues.lien_holder_zip = selectedProduct.fundingDetail.cashLienHolder.zip;
    }else if(selectedProduct.shipTo){
      newValues.lien_holder_name_1 = selectedProduct.shipTo.name;
      newValues.lien_holder_street_address = selectedProduct.shipTo.addressLine1+" "+selectedProduct.shipTo.addressLine2;
      newValues.lien_holder_city = selectedProduct.shipTo.city;
      newValues.lien_holder_state = selectedProduct.shipTo.state;
      newValues.lien_holder_zip = selectedProduct.shipTo.zip;
    }

  }

  return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  if(payload.title_type_toggle){
    // Reset all
    payload.salvage_selected = "no";
    payload.prev_salvage_selected = "no";
    payload.flood_selected = "no";
    payload.non_transferable_selected = "no";
    payload.manufacture_buyback = "no";

    switch(payload.title_type_toggle){
      case "salvage":
        payload.salvage_selected = "yes";
        break;
      case "prev-salvage":
        payload.prev_salvage_selected = "yes";
        break;
      case "flood":
        payload.flood_selected = "yes";
        break;
      case "non-trans":
        payload.non_transferable_selected = "yes";
        break;
      case "manu-buy-back":
        payload.manufacture_buyback = "yes";
        break;
      default:
    }
  }

  if(payload.taxi_toggle){
    // Reset all
    payload.taxi_use_yes_selected = "no";
    payload.taxi_use_no_selected = "no";

    switch(payload.taxi_toggle){
      case "yes":
        payload.taxi_use_yes_selected = "yes";
        break;
      case "no":
        payload.taxi_use_no_selected = "yes";
        break;
      default:
    }
  }

  if(payload.clear_rights_toggle){
    // Reset all
    payload.no_check_owner_applicant_information_selected = "no";
    payload.yes_check_owner_applicant_information = "no";

    switch(payload.clear_rights_toggle){
      case "no":
        payload.no_check_owner_applicant_information_selected = "yes";
        break;
      case "yes":
        payload.yes_check_owner_applicant_information = "yes";
        break;
      default:
    }
  }

  if(payload.lien_toggle){
    // Reset all
    payload.yes_lien_on_vehicle = "no";
    payload.no_lien_on_vehicle = "no";

    switch(payload.lien_toggle){
      case "yes":
        payload.yes_lien_on_vehicle = "yes";
        break;
      case "no":
        payload.no_lien_on_vehicle = "yes";
        break;
      default:
    }
  }

  
  if(payload.lien_non_res_toggle){
    // Reset all
    payload.req_print_yes_selected = "no";
    payload.req_print_no_selected = "no";

    switch(payload.lien_non_res_toggle){
      case "yes":
        payload.req_print_yes_selected = "yes";
        break;
      case "no":
        payload.req_print_no_selected = "yes";
        break;
      default:
    }
  }

  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  if(payload.date){
    payload.date = moment.utc(payload.date).format("MM/DD/YY");
  }
  
  if(payload.date_of_birth){
    payload.date_of_birth = moment.utc(payload.date_of_birth).format("MM/DD/YY");
  }

  if(payload.date_of_birth_2){
    payload.date_of_birth_2 = moment.utc(payload.date_of_birth_2).format("MM/DD/YY");
  }

  if(payload.date_of_birth_3){
    payload.date_of_birth_3 = moment.utc(payload.date_of_birth_3).format("MM/DD/YY");
  }

  return payload;
}
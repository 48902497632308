import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  useParams
} from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import { Autocomplete, TextField } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { formatter, removeChars, getAreaCode, getPhoneNumberAlt, formatUpperCase } from "../../../../../../../utlis/formatters";
import { getOrderLevelAddresses, updateAddressOptions } from "../../../../../../../utlis/documentHelpers";
import { useOrder } from "../../../../../../../redux/orders/order";
import moment from 'moment';
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { AZ5010GlobalAutofill, AZ5010AutofillProductDetails, preProcessPayload } from "../DocumentAutofill/AZ5010AF"

const AZ5010 = ({document_id, getDocuments, passedDocumentSlug, locations, location, setDocumentFocus}) => {
  const documentSlug = "az-5010";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  // App Logic & Data //
  let { id } = useParams();
  const order = useOrder();
  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);

  // Data Structure & Init //
  
  var initialDocumentStructure = {
    seller_name: undefined, 
    tpt_license_seller: undefined, 
    seller_address: undefined, 
    seller_area_code: undefined, 
    seller_phone: undefined, 
    seller_city_state_zip: undefined, 
    vehicle_ymm: undefined, 
    vin: undefined, 
    adot_90_permit_no: undefined, 
    sales_price: undefined, 
    delivery_address: undefined, 
    delivery_city_state_zip: undefined, 
    delivery_name: undefined, 
    delivery_title: undefined, 
    delivery_area_code: undefined, 
    delivery_phone_number: undefined, 
    seller_cert_1_cb: undefined, 
    seller_cert_2_cb: undefined, 
    purchaser_name: undefined, 
    purchaser_address: undefined, 
    purchaser_city_state_zip: undefined, 
    purchaser_drivers_license: undefined, 
    purchaser_state: undefined
  }

  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  // X1
  // const removeFormatting = e =>{
  //   const {name,value}=e.target;
  //   setDocumentValues({...documentValues,[name]:removeChars(value)});
  // }

  // X1 Reference Arrays - TODO: Possibly move these to back end as part of the template stucture
  const moneyFields = useMemo(()=>{return["sales_price"]},[]);
  const phoneFields = useMemo(()=>{return[]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "model"]},[]);
  const checkboxes = useMemo(()=>{return[
    "seller_cert_1_cb",
    "seller_cert_2_cb",
  ]},[]);
  // const radios = [
  //   "", 
  // ];

  // X1- Current General On Change
  const handleDocumentValuesChange = e =>{
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  
  const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try{
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      }catch(err){
        recordFrontEndError('AZ5010.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, documentLoaded, loadDocument ])
  const [assignedProduct, setAssignedProduct] = useState(undefined);
  // const [productVal, setproductVal] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  // const [tradeinVal, setTradeinVal] = useState(undefined);
  // const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  // const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);

  // const [selectedAddress1, setSelectedAddress1] = useState(undefined);
  const [selectedAddress2, setSelectedAddress2] = useState(undefined);
  // const [selectedAddress3, setSelectedAddress3] = useState(undefined);
  // const [selectedAddress4, setSelectedAddress4] = useState(undefined);
  const [addressOptions, setAddressOptions] = useState(undefined);
  const [inputAddressVal, setinputAddressVal] = useState(undefined);

  const [locationVal, setLocationVal] = useState(undefined);
  const [selectedLocation, setselectedLocation] = useState(undefined);
  const [inputLocationVal, setinputLocationVal] = useState(undefined);
  const [locationOptions, setLocationOptions] = useState(undefined);
  const [locationToggleAutoFill, setLocationToggleAutoFill] = useState(false);

// Dropdowns //colorOptions

// if(order && order.products && order.products.length > 0 && productOptions === undefined){
//   let getProductOptions = [];
//   order.products.map(product => {
//     let str = `${product.stockNumber.toString().toUpperCase()}`;
//     let obj = {id: product.id, name: str}
//     getProductOptions.push(obj);
//     return product;
//   })
//   setproductOptions(getProductOptions);
// }

// if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
//   let getTradeinOptions = [];
//   order.tradeins.map(tradein => {
//     let str = `${tradein.year} ${tradein.make} ${tradein.model.toString().toUpperCase()}`;
//     let obj = {id: tradein.id, name: str}
//     getTradeinOptions.push(obj);
//     return tradein;
//   })
//   setTradeinOptions(getTradeinOptions);
// }

if(locations && locations.length > 0 && locationOptions === undefined){
  let getLocationOptions = [];
  locations.map(location => {
    let str = `${location.description}`;
    let obj = {locationCode: location.locationCode, description: str}
    getLocationOptions.push(obj);
    return location;
  })
  setLocationOptions(getLocationOptions);
}

 // Autofill //

const GlobalAutofill = useCallback(async () => {
  let newValues = AZ5010GlobalAutofill(documentValues, order);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  // var newValues = documentValues;
  
  // if(order && order.customer && order.customer.name){
  //     newValues.printed = order.customer.email;
  // }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
  //   let cosigner = order.customer.cosigners[0];
  //   if(cosigner && cosigner.name){
  //       newValues.printed += " AND "+cosigner.name;
  //   }
  // }

  // if(order && order.documentationDate && documentValues.delivery_date === undefined){
  //   newValues.delivery_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  // }
  
  
  
  // setDocumentValues(newValues);
  if(location && locations && locations.length > 0 && locationOptions !== undefined){
    setLocationVal(locations.find((loc) => loc.locationCode === location.locationCode));
    setselectedLocation(locations.find((loc) => loc.locationCode === location.locationCode));
    setLocationToggleAutoFill(true);
  }
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ documentValues, order, location, locationOptions, locations ])

// const preProcessPayload = useCallback((payload) => {

//   // Data point formatting
//   if(payload.delivery_date){
//     payload.delivery_date = moment.utc(payload.delivery_date).format("MM/DD/YY");
//   }

//   if(payload.safety_label_toggle){
//     // Reset all
//     payload.fcs_label_yes_cb = "no";
//     payload.fcs_label_no_cb = "no";

//     switch(payload.safety_label_toggle){
//       case "yes":
//         payload.fcs_label_yes_cb = "yes";
//         break;
//       case "no":
//         payload.fcs_label_no_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.odometer_toggle){
//     // Reset all
//     payload.mileage_exceeded_cb = "no";
//     payload.odo_reading_incorrect_cb = "no";
//     payload.model_year_exemption_cb = "no";

//     switch(payload.odometer_toggle){
//       case "1":
//         payload.mileage_exceeded_cb = "yes";
//         break;
//       case "2":
//         payload.odo_reading_incorrect_cb = "yes";
//         break;
//       case "3":
//         payload.model_year_exemption_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.living_quarters_toggle){
//     // Reset all
//     payload.trailer_living_quarters_yes_cb = "no";
//     payload.trailer_living_quarters_no_cb = "no";

//     switch(payload.living_quarters_toggle){
//       case "yes":
//         payload.trailer_living_quarters_yes_cb = "yes";
//         break;
//       case "no":
//         payload.trailer_living_quarters_no_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.fuel_toggle){
//     // Reset all
//     payload.fuel_gas_cb = "no";
//     payload.fuel_diesel_cb = "no";
//     payload.fuel_propane_cb = "no";
//     payload.fuel_electric_cb = "no";
//     payload.fuel_other_cb = "no";

//     switch(payload.fuel_toggle){
//       case "gas":
//         payload.fuel_gas_cb = "yes";
//         break;
//       case "diesel":
//         payload.fuel_diesel_cb = "yes";
//         break;
//       case "propane":
//         payload.fuel_propane_cb = "yes";
//         break;
//       case "electric":
//         payload.fuel_electric_cb = "yes";
//         break;
//       case "other":
//         payload.fuel_other_cb = "yes";
//         break;
//       default:
//     }
//   }
  


// // X1.2- Field Type Specific Preview Formatting
// Object.keys(payload).forEach(key => {
//   if(moneyFields.indexOf(key) >= 0){
//       payload[key] = formatMoneyValue(payload[key]);
//   }else if(phoneFields.indexOf(key) >= 0){
//       payload[key] = formatPhoneNumber(payload[key]);
//   }else if(upperCaseFields.indexOf(key) >= 0){
//       payload[key] = formatUpperCase(payload[key]);
//   }
// });
// setDocumentValues(JSON.parse(JSON.stringify(payload)));
// Object.keys(payload).forEach(key => {
//   if(checkboxes.indexOf(key) >= 0){
//     payload[key] = payload[key] ? "yes": "no";
//   }
// });

//   return payload;
// },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])

const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });
    
    var docCopy = JSON.parse(JSON.stringify(document));
    docCopy.payload =  JSON.parse(JSON.stringify(payload));
    docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
    docCopy.payload =  JSON.stringify(docCopy.payload);
    delete docCopy.documentStatus;

    try{
    let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
      const file = new Blob(
        [response.data], 
        {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
      setdocumentUrl(fileURL)
    } catch(err) {
      recordFrontEndError('AZ5010.jsx', 'Error previewing document payload: ' + err)
    };  
},[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


const AutofillProductDetails = useCallback(async (selectedProduct) => {

  let newValues = AZ5010AutofillProductDetails(selectedProduct, documentValues);

  if(newValues){
    setDocumentValues(newValues);
    previewPayloadDocument(documentValues);
    setHasUnsavedChanges(true);
    if(document){
      previewPayloadDocument(documentValues);
    }
  }

  // setDataLoaded(true);

//   var newValues = documentValues;
//   newValues.vin = (selectedProduct.vin).toString().toUpperCase();
//   newValues.vehicle_ymm = (selectedProduct.year).trim()+" "+(selectedProduct.make).trim()+" "+(selectedProduct.model).toString().toUpperCase();

//   setDocumentValues(newValues);
//   // setProductToggleAutoFill(false);
//   if(document){
//     previewPayloadDocument(documentValues);
//   }
//   // setAddressOptions(updateAddressOptions(selectedProduct, order));
//   setHasUnsavedChanges(true);
//   setSaveSuccess(false);
},[ document, documentValues, previewPayloadDocument ]);

  useEffect(() => {
    if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){
  
      existingPayload = true;
  
      if(documentPayload.delivery_date){
        documentPayload.delivery_date = moment.utc(documentPayload.delivery_date).format("YYYY-MM-DD");
      }
      

      // Loading Use Effect
      if(document.additionalData){
        const methodMap = { 
          selectedAddress2: setSelectedAddress2, 
          selectedLocation: setselectedLocation, 
        };

        let parsedAdditionalData = JSON.parse(document.additionalData);
        Object.keys(parsedAdditionalData).forEach(function(key) {
          if(methodMap[key]){
            if(key === "selectedLocation"){
              setLocationVal(parsedAdditionalData[key]);
              if(parsedAdditionalData[key].id){
                setselectedLocation(locations.find((location) => location.id === parsedAdditionalData[key].id));
              }
            }else{
              methodMap[key](parsedAdditionalData[key]);
            }
          }
        });
      }
  
      setDocumentValues(documentPayload);
    }

      var foundAssignedProduct = null;
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
        foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
      }

      setAddressOptions(getOrderLevelAddresses(order));
      if(foundAssignedProduct){
        setAssignedProduct(foundAssignedProduct);
        setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
      }
  
      // setAddressOptions(getOrderLevelAddresses(order));
      if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
      }else{
        // if(document && document.itemIds){
        //   var itemIds = document.itemIds.split(',');
        //     var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
        //     let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
        //     let obj = {id: foundProduct.id, name: str}
        //     setproductVal(obj);
        //     if(foundProduct){
        //       setAddressOptions(updateAddressOptions(foundProduct, order));
        //     }
        //     streamDocument();
        // }
  
      }
      
      setDataLoaded(true);
    }
  },[documentValues, document, previewPayloadDocument, dataLoaded, order, locations, GlobalAutofill, AutofillProductDetails])
  
    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();
  
      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
  
      //Save Dropdown Selections
      let dropdownData = {};
      if(selectedAddress2){
        dropdownData.selectedAddress2 = {
          id: selectedAddress2.id,
          name: selectedAddress2.name
        };
      }
      if(selectedLocation){
        dropdownData.selectedLocation = {
          locationCode: selectedLocation.locationCode,
          description: selectedLocation.description
        };
      }
      let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, additionalData, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });
      
    }

useEffect(() => {
  //Autofill Tradein Details After Selection
  if(selectedLocation !== undefined && locationToggleAutoFill){
    var newValues = documentValues;
    newValues.seller_address = selectedLocation.address;
    newValues.seller_city_state_zip = selectedLocation.city+", "+selectedLocation.state+", "+selectedLocation.zip;
    newValues.seller_area_code = getAreaCode(selectedLocation.phone);
    newValues.seller_phone = getPhoneNumberAlt(selectedLocation.phone);

    if(selectedLocation.state === "CA"){
      newValues.seller_name = "TEC OF CALIFORNIA, INC";
    }else{
      newValues.seller_name = "TEC EQUIPMENT, INC.";
    }

    setDocumentValues(newValues);
    setLocationToggleAutoFill(false);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
},[ selectedLocation, locationToggleAutoFill, document, documentValues, previewPayloadDocument ])

// useEffect(() => {
//   //Autofill Tradein Details After Selection
//   if(selectedTradein !== undefined && tradeinToggleAutoFill){
//     var newValues = documentValues;
//     newValues.trade_year = (selectedTradein.year);
//     newValues.trade_make = (selectedTradein.make);
//     newValues.trade_model = (selectedTradein.model);
//     newValues.trade_body_type = (selectedTradein.seriesBodyType);
//     newValues.trade_serial_number = (selectedTradein.serialNumber);
//     newValues.balance_owner = (order.customer.name); //Need specific field for TRADE IN OWNER?
//     newValues.balance_owner_address = (order.customer.deliveryAddress.addressLine1);
//     newValues.amount = (selectedTradein.balanceOwed);
//     newValues.trade_allowance = (selectedTradein.tradeAllowanceAtTerms);
//     newValues.balance_owed = (selectedTradein.balanceOwed);
//     newValues.cash_refund = (selectedTradein.cashRefundToCustomer);
//     newValues.net_trade_allowance = (selectedTradein.finalTradeAllowance);
//     // newValues.cash_down_payment = (selectedTradein);

//     setDocumentValues(newValues);
//     setTradeinToggleAutoFill(false);
//     if(document){
//       previewPayloadDocument(documentValues);
//     }
//   }
// },[ selectedTradein, tradeinToggleAutoFill ])

//Autofill Address Details After Selection
function AutoFillAddress(addressSelected, selectionInstance){
  var newValues = documentValues;
  if(selectionInstance){
    if(selectionInstance === "delivery-address"){
      newValues.delivery_address = addressSelected.address;
      newValues.delivery_city_state_zip = addressSelected.city+", "+addressSelected.state+", "+addressSelected.zip;
    }

    if(selectionInstance === "purchaser-address"){
      newValues.purchaser_name = addressSelected.label;
      newValues.purchaser_address = addressSelected.address;
      newValues.purchaser_city_state_zip = addressSelected.city+", "+addressSelected.state+", "+addressSelected.zip;
    }
  }
  setDocumentValues(newValues);
  if(document){
    previewPayloadDocument(documentValues);
  }
  setHasUnsavedChanges(true);
  setSaveSuccess(false);
}

const onCloseClick = e => {
  e.preventDefault()
  // setDataLoaded(false);
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setAssignedProduct(undefined);
  setdocumentUrl("");
  setDocument(undefined);
  setDocumentFocus(undefined);
  setSelectedAddress2(undefined);
  setAddressOptions(undefined);
  setinputAddressVal(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
  setLocationVal(undefined);
  setselectedLocation(undefined);
  setinputLocationVal(undefined);
  setLocationOptions(undefined);
  setLocationToggleAutoFill(false);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

  return (
    <>
      <div
        className="modal fade"
        // id="wa-title-app"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                AZ - 5010 - CERTIFICATE TO ESTABLISH OUT-OF-STATE DELIVERY OF MOTOR VEHICLE TO NONRESIDENT
              </h5>
              <br />
              { hasClickedClose ? (
                <div className="d-flex">
                    <p className="m-1 pt-1 text-white">Save Changes?</p>
                    <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                    <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                </div>
                ): hasUnsavedChanges ? (
                    <button
                    type="button"
                    className="btn-close bg-white"
                    aria-label="Close"
                    // onClick={() => setdocumentUrl("")}
                    onClick={closeClicked}
                    ></button>
                ):(
                  <button
                    type="button"
                    className="btn-close bg-white"
                    aria-label="Close"
                    data-bs-dismiss="modal"
                    // onClick={() => setdocumentUrl("")}
                    onClick={preCloseClose}
                  ></button>
                )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ fontSize: '12px', overflowY: 'scroll', height: 'inherit'}}>
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill();
                      AutofillProductDetails(assignedProduct);
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>
                  <br />
                  { assignedProduct ? (
                      <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                          Product Snapshot
                          <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                          <h5>SN: { assignedProduct.stockNumber }</h5>
                          <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                          <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                          </div>
                      </div>
                  ):(
                      <div className="m-2 p-2 border">
                          <span>No Assigned Product</span>
                      </div>
                  )}

                  <div className="row">

                    { locationOptions && locationOptions.length > 0 ? (
                    <div className="d-flex justify-content-evenly">
                      <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                          style={{ width: '100%'}}
                          value={ (locationVal && locationVal.description) ?? null}
                          onChange={(event, newValue) => {
                              setLocationVal(newValue);
                              if(newValue && newValue.locationCode){
                                setselectedLocation(locations.find((loc) => loc.locationCode === newValue.locationCode));
                                setLocationToggleAutoFill(true);
                              }
                              // resetVariables();
                          }}
                          inputValue={inputLocationVal}
                          onInputChange={(event, newInputValue) => {
                              setinputLocationVal(newInputValue);
                          }}
                          //id="controllable-states-demo"
                          options={locationOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="SELLING LOCATION" />}
                          renderOption={(props, option) => {
                              return (
                              <li {...props} key={option.locationCode}>
                                  {option.description}
                              </li>
                              );
                          }}
                          />
                      </div>
                    </div>
                    ):null}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Seller's Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">TPT LICENSE NO</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="tpt_license_seller"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.tpt_license_seller ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-5">
                        <label htmlFor="stock">Seller's Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_address ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Seller Area Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_area_code"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_area_code ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Seller Phone</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_phone"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_phone ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Seller's City / State / Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_city_state_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_city_state_zip ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Year Make Model</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vehicle_ymm"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vehicle_ymm ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-8">
                        <label htmlFor="stock">VIN No.</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">NET Sales Price</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="sales_price"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.sales_price ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">ADOT 90 Day Permit No.</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="adot_90_permit_no"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.adot_90_permit_no ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    {/* { addressOptions && addressOptions.length > 0 ? (
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress1 && selectedAddress1.name) ?? null}
                          name="delivery-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress1(newValue);
                            AutoFillAddress(newValue, "delivery-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Delivery Address" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>
                    ):null} */}

                    {/* { locationOptions && locationOptions.length > 0 ? (
                    <div className="d-flex justify-content-evenly">
                      <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                          style={{ width: '100%'}}
                          value={ (locationVal && locationVal.name) ?? null}
                          onChange={(event, newValue) => {
                              setLocationVal(newValue);
                              if(newValue && newValue.id){
                                setselectedLocation(locations.find((location) => location.id === newValue.id));
                                setLocationToggleAutoFill(true);
                              }
                              // resetVariables();
                          }}
                          inputValue={inputLocationVal}
                          onInputChange={(event, newInputValue) => {
                              setinputLocationVal(newInputValue);
                          }}
                          //id="controllable-states-demo"
                          options={locationOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="SELLING LOCATION" />}
                          renderOption={(props, option) => {
                              return (
                              <li {...props} key={option.id}>
                                  {option.name}
                              </li>
                              );
                          }}
                          />
                      </div>
                    </div>
                    ):null} */}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Delivery Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="delivery_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.delivery_address ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Delivery City / State / Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="delivery_city_state_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.delivery_city_state_zip ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Name of person delivering vehicle</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="delivery_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.delivery_name ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-5">
                        <label htmlFor="stock">Job Title</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="delivery_title"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.delivery_title ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Area Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="delivery_area_code"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.delivery_area_code ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Phone Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="delivery_phone_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.delivery_phone_number ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.seller_cert_1_cb }
                              name="seller_cert_1_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="I certify, under penalty of perjury under the laws of the State of Arizona, that I personally delivered the above vehicle to the delivery address indicated above." />
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.seller_cert_2_cb }
                              name="seller_cert_2_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="I certify, under penalty of perjury under the laws of the State of Arizona, that I have delivered the above vehicle to a licensed auto transporter or common carrier who will deliver the vehicle to the above address and from whom I have received a bill of lading.   (See Notice above.)" />
                        </FormControl>
                      </div>
                    </div>
                    <hr></hr>

                    {addressOptions && addressOptions.length > 0 ? (  
                    <div className="row">
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress2 && selectedAddress2.name) ?? null}
                            name="delivery-address"
                            onChange={(event, newValue) => {
                              setSelectedAddress2(newValue);
                              AutoFillAddress(newValue, "purchaser-address");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Purchaser" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    ):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Purchaser Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Drivers License No.</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_drivers_license"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_drivers_license ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_state ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_address ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">City / State / Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_city_state_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_city_state_zip ?? '' }
                          />
                      </div>
                    </div>









                  </div>
                <hr></hr>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default AZ5010
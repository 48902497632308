import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";
import { autoYMM } from './docUtils.js';

export const NEForm13GlobalAutofill = (documentValues, order, property=null, value=null, location) => {

    var newValues = documentValues;
    
  if(property ==='customer.name'){
    newValues.name_1 = value;
    newValues.legal_name = value;
    if(order.customer.dba!==''){
      newValues.name_1 += " dba "+order.customer.dba;
      newValues.legal_name += " dba "+order.customer.dba;

    } else if(order.customer.dba===''&&order.customer.cosigners && order.customer.cosigners.length > 0){

      let cosigner = order.customer.cosigners[0];
      if(cosigner){
        newValues.name_1 += " and "+cosigner.name;
        newValues.legal_name += " and "+cosigner.name;
      }
    }
  }
  if(property ==='customer.dba'){
      newValues.name_1 = order.customer.name;
      newValues.legal_name = order.customer.name;
      if (value !==''){
        newValues.name_1+= " dba "+value
        newValues.legal_name+= " dba "+value
      } else if( value==='' && order.customer.cosigners && order.customer.cosigners.length > 0){
      let cosigner = order.customer.cosigners[0];
      if(cosigner){
        newValues.name_1 += " and "+cosigner.name;
        newValues.legal_name += " and "+cosigner.name;
      }
    }
  }
  if(property ==='customer.cosigner[0].name'){
    newValues.name_1 = order.customer.name;
    newValues.legal_name = order.customer.name;

    if (order.customer.dba !==''){
      newValues.name_1+= " dba "+order.customer.dba
      newValues.legal_name+= " dba "+order.customer.dba
    } else if( order.customer.dba===''){
      newValues.name_1 += " and "+value;
      newValues.legal_name += " and "+value;
  }

  }
  
  if(property ==='customer.deliveryAddress.addressLine1'){
    newValues.address_1 = value;
    if(value !== null && order.customer.deliveryAddress.addressLine2 !== null && order.customer.deliveryAddress.addressLine2 !== ''){
      newValues.address_1 += " "+order.customer.deliveryAddress.addressLine2;
    }
  }
  if(property ==='customer.deliveryAddress.addressLine2'){
    newValues.address_1 = order.customer.deliveryAddress.addressLine1+" "+value;
  }
  if(property ==='documentationDate'){
    newValues.date_of_sellers_original_purchase = moment.utc(value).format("YYYY-MM-DD");
    newValues.date = moment.utc(value).format("YYYY-MM-DD");
  }
  if(property ==='customer.deliveryAddress.city'){
    newValues.city_1 = order.customer.deliveryAddress.city;
  }
  if(property ==='customer.deliveryAddress.state'){
    newValues.state_1 = order.customer.deliveryAddress.state;
  }
  if(property ==='customer.deliveryAddress.zip'){
    newValues.zip_code_1 = order.customer.deliveryAddress.zip;
  }
  if(property ==='location'){
    if(value.state === "CA"){
      newValues.name_2 = "TEC OF CALIFORNIA, INC.";
    }else{
      newValues.name_2 = "TEC EQUIPMENT, INC.";
    }
    newValues.address_2 = value.address;
    newValues.city_2 = value.city;
    newValues.state_2 = value.state;
    newValues.zip_code_2 = value.zip;
  }
    if(property !== null && value !== null){
      return newValues;
  }

    if(order && order.customer){
  
      if(order && order.customer && order.customer.name){

        newValues.name_1 = order.customer.name;
          newValues.legal_name = order.customer.name;
          if(order.customer.dba){
            newValues.name_1 = order.customer.name+" dba "+order.customer.dba;
            newValues.legal_name = order.customer.name+" dba "+order.customer.dba;
          } else if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner){
            newValues.name_1 += " and "+cosigner.name;
            newValues.legal_name += " and "+cosigner.name;
          }
        }
      }
      
      if(order && order.customer && order.customer.deliveryAddress){
        newValues.address_1 = order.customer.deliveryAddress.addressLine1;
        if(order.customer.deliveryAddress.addressLine1 !== null && order.customer.deliveryAddress.addressLine2 !== null && order.customer.deliveryAddress.addressLine2 !== ''){
          newValues.address_1 = newValues.address_1+" "+order.customer.deliveryAddress.addressLine2;
        }
        newValues.city_1 = order.customer.deliveryAddress.city;
        newValues.state_1 = order.customer.deliveryAddress.state;
        newValues.zip_code_1 = order.customer.deliveryAddress.zip;
      }
    }
  
    if(order && order.documentationDate){
      newValues.date_of_sellers_original_purchase = moment.utc(order.documentationDate).format("YYYY-MM-DD");
      newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }
  
    if(location){
      if(location.state === "CA"){
        newValues.name_2 = "TEC OF CALIFORNIA, INC.";
      }else{
        newValues.name_2 = "TEC EQUIPMENT, INC.";
      }
      newValues.address_2 = location.address;
      newValues.city_2 = location.city;
      newValues.state_2 = location.state;
      newValues.zip_code_2 = location.zip;
    }
  
    return newValues
}

export const NEForm13AutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
  var newValues = documentValues;

  if(property === 'year' && value !== null){
    newValues.description_of_property_or_service_purchased = autoYMM((value).toString().trim().replace(/['"]+/g, ''),selectedProduct.make.trim(),selectedProduct.model.trim());
    newValues.description_of_product_sold = autoYMM((value).toString().trim().replace(/['"]+/g, ''),selectedProduct.make.trim(),selectedProduct.model.trim());
    newValues.descripton_of_items_purchased_2 = autoYMM((value).toString().trim().replace(/['"]+/g, ''),selectedProduct.make.trim(),selectedProduct.model.trim());
    newValues.description_of_items_sold = autoYMM((value).toString().trim().replace(/['"]+/g, ''),selectedProduct.make.trim(),selectedProduct.model.trim());
}

if(property === 'make' && value !== null){
      newValues.description_of_property_or_service_purchased = autoYMM(selectedProduct.year.trim(), (value).toString().trim(),selectedProduct.model.trim());
    newValues.description_of_product_sold = autoYMM(selectedProduct.year.trim(), (value).toString().trim(),selectedProduct.model.trim());;
    newValues.descripton_of_items_purchased_2 = autoYMM(selectedProduct.year.trim(), (value).toString().trim(),selectedProduct.model.trim());;
    newValues.description_of_items_sold = autoYMM(selectedProduct.year.trim(), (value).toString().trim(),selectedProduct.model.trim());;
}

if(property === 'model' && value !== null){
      newValues.description_of_property_or_service_purchased = autoYMM(selectedProduct.year.trim(), selectedProduct.make.trim(),(value).toString().trim());
    newValues.description_of_product_sold = autoYMM(selectedProduct.year.trim(), selectedProduct.make.trim(),(value).toString().trim());
    newValues.descripton_of_items_purchased_2 = autoYMM(selectedProduct.year.trim(), selectedProduct.make.trim(),(value).toString().trim());
    newValues.description_of_items_sold = autoYMM(selectedProduct.year.trim(), selectedProduct.make.trim(),(value).toString().trim());
}
if(property !== null && value !== null){
    return newValues;
}

  let ymm = (selectedProduct.year)+" "+(selectedProduct.make).toString().toUpperCase()+" "+(selectedProduct.model).toString().toUpperCase();

  newValues.description_of_property_or_service_purchased = ymm;
  newValues.description_of_product_sold = ymm;
  newValues.descripton_of_items_purchased_2 = ymm;
  newValues.description_of_items_sold = ymm;

  return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  if(payload.cert_type_toggle){
    // Reset all
    payload.single_selected = "no";
    payload.blanket_selected = "no";

    switch(payload.cert_type_toggle){
      case "single":
        payload.single_selected = "yes";
        break;
      case "blanket":
        payload.blanket_selected = "yes";
        break;
      default:
    }
  }

  if(payload.check_toggle){
    // Reset all
    payload.purchase_check_tax_exempt_selected = "no";
    payload.exempt_check_tax_exempt_selected = "no";
    payload.contractor_check_tax_exempt_selected = "no";

    switch(payload.check_toggle){
      case "resale":
        payload.purchase_check_tax_exempt_selected = "yes";
        break;
      case "exempt":
        payload.exempt_check_tax_exempt_selected = "yes";
        break;
      case "contractor":
        payload.contractor_check_tax_exempt_selected = "yes";
        break;
      default:
    }
  }

  if(payload.business_toggle){
    // Reset all
    payload.wholesaler_selected = "no";
    payload.retailer_selected = "no";
    payload.manufacturer_selected = "no";
    payload.lessor_selected = "no";

    switch(payload.business_toggle){
      case "wholesaler":
        payload.wholesaler_selected = "yes";
        break;
      case "retail":
        payload.retailer_selected = "yes";
        break;
      case "manu":
        payload.manufacturer_selected = "yes";
        break;
      case "lessor":
        payload.lessor_selected = "yes";
        break;
      default:
    }
  }

  if(payload.tax_paid_by_seller_toggle){
    // Reset all
    payload.yes_tax_paid_selected = "no";
    payload.no_tax_not_paid_selected = "no";

    switch(payload.tax_paid_by_seller_toggle){
      case "yes":
        payload.yes_tax_paid_selected = "yes";
        break;
      case "no":
        payload.no_tax_not_paid_selected = "yes";
        break;
      default:
    }
  }

  if(payload.item_dep_toggle){
    // Reset all
    payload.yes_depreciable_selected = "no";
    payload.no_not_depreciable_selected = "no";

    switch(payload.item_dep_toggle){
      case "yes":
        payload.yes_depreciable_selected = "yes";
        break;
      case "no":
        payload.no_not_depreciable_selected = "yes";
        break;
      default:
    }
  }

  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  if(payload.date_of_sellers_original_purchase){
    payload.date_of_sellers_original_purchase = moment.utc(payload.date_of_sellers_original_purchase).format("MM/DD/YY");
  }

  if(payload.date){
    payload.date = moment.utc(payload.date).format("MM/DD/YY");
  }
  
  return payload;
}
import React from 'react'
import { Card, Dropdown } from "react-bootstrap";
import { Menu } from "@material-ui/icons";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

import { ArrowLeft } from '@material-ui/icons';
import { ArrowDropDown } from '@material-ui/icons'
import { formatNumber, formatter} from "../../../../../../utlis/formatters";


const TradeinInfoTemplate = ({ eventKey, children, tradein,isOpen,setIsOpen, selectedProduct}) => {
  // constructor(props, context) {
    // super(props, context);

    const calcFinalTradeAllowance = (tradein) => {
      let val = tradein.tradeAllowanceAtTerms - tradein.balanceOwed - tradein.cashRefundToCustomer;
      val = val >= 0 ? '$' + formatNumber(val) : '($' + formatNumber(val) + ')';
      return val;
    }


    const handleToggle = useAccordionButton(eventKey, ()=>{
      if(isOpen.selectedProduct === eventKey){
        setIsOpen((prev)=>({
          ...prev,
          selectedProduct: undefined
        }))
      }else{
        setIsOpen((prev)=>({
          ...prev,
          selectedProduct: eventKey
        }))
      }
    })


  return (
    <Card.Header style={{ backgroundColor: '#fff', border: 'none', borderRadius: '0px'}}>
      <div className='row'>
        <div className="d-flex align-items-center justify-content-between p-2">
      <Dropdown style={{ display: 'inline-table' }}>
        <Dropdown.Toggle style={{ backgroundColor: '#fff', color: 'black', border: 'none', paddingTop: '1px' }}>
          <Menu />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          { children }
        </Dropdown.Menu>
      </Dropdown>
      <div>
        <h5 className="text-uppercase">{ tradein.year+" "+tradein.make+" "+tradein.model }</h5>
        <p className="mx-0 text-secondary pt-3">YMM</p>
      </div>
      { tradein.applyMileage === true ? (
        <div>
          <h5 className="text-uppercase">{ tradein.mileage }</h5>
          <p className="mx-0 text-secondary pt-3">Mileage</p>
        </div>
      ):(
        <div>
          <h5 className="text-uppercase">N/A</h5>
          <p className="mx-0 text-secondary pt-3">Mileage</p>
      </div>
      )}
      <div>
        <h5 className="text-uppercase">{ formatter.format(tradein.balanceOwed) }</h5>
        <p className="mx-0 text-secondary pt-3">Balance Owed</p>
      </div>
      <div>
        {/* calcFinalTradeAllowance() >= 0 ? '$' + formatNumber(calcFinalTradeAllowance()) : '($' + formatNumber(calcFinalTradeAllowance()) + ')' */}
        <h5 className="text-uppercase">{ calcFinalTradeAllowance(tradein) }</h5>
        <p className="mx-0 text-secondary pt-3">Final Trade Allowance</p>
      </div>
        {/* <CustomToggle eventKey={eventKey} tabOpen={sectionState.open}>
          { sectionState.open ? ( <ArrowDropDown /> ) :( <ArrowLeft /> ) }
        </CustomToggle> */}
        <button
      type="button"
      style={{ backgroundColor: '#fff', color: 'grey', border: 'none', maxWidth: '25px', float: 'right' }}
      onClick={()=>handleToggle(eventKey)}
    >
      { (eventKey===selectedProduct && isOpen.tradeIn) ? ( <ArrowDropDown /> ) :( <ArrowLeft /> ) }
    </button>
        </div>
      </div>
    </Card.Header>
  );
};

// function CustomToggle({ children, eventKey, tabOpen, sectionState }) {

//   // var toggleArrow = false;
  
//   var decoratedOnClickx = useAccordionButton(eventKey, () => {
//     tabOpen = !tabOpen;
//     // sectionState.open = !sectionState.open;
//   });

//   return (
//     <button
//       type="button"
//       style={{ backgroundColor: '#fff', color: 'grey', border: 'none', maxWidth: '25px', float: 'right' }}
//       onClick={()=>handleToggle(eventKey)}
//     >
//       { tabOpen.open ? ( <ArrowDropDown /> ) :( <ArrowLeft /> ) }
//     </button>
//   );
// }

export default TradeinInfoTemplate;

import _ from "lodash"
import { useSelector } from "react-redux"
import { setIsLoading,setShowSpinner } from "../spinners/spinners"
import * as api from "../../utlis/api"
import { buildReduxActionsAndSelectors } from "../"

import { handleStateDefault } from "../"

const ORDER_REDUX_PREFIX = 'OR'

//Define all valid entity fields
const ORDER_VALID_ENTITY_FIELDS = {
  editedOrderDetails: [
    'fob',
    'documentationDate',
    'dateFinalized',
    'locationId',
    'orderDate',
    'orderStatusId',
    'salesRepEmployeeId',
    'salesRepName',
    'salesManagerEmployeeId',
    'salesManagerName',
    'specialInstructions',
    'status',
  ]
}

const orderInitialState = {
  orderList: [],
  order: {},
  selectedDownpaymentId: null,
  modal: {
    editedOrderDetails: {},
    locations: [],
    grantedLocations: [],
    inputLocationValue: null,
    isOpen: false,
    inputStatusValue: null,
    orderStatuses: [],
    salesManagers: [],
    salesReps: [],
    saveSuccess: false,
    hasUnsavedChanges: false,
    errors: {
      editedOrderDetails: {},
    }
  }
}

const buildOptions = {
  fields: ORDER_VALID_ENTITY_FIELDS,
  entities: [ 'editedOrderDetails' ],
  reduxPath: "order.modal",
  reduxPrefix: ORDER_REDUX_PREFIX,
  statePath: "modal"
}

export const rdx =
  buildReduxActionsAndSelectors(orderInitialState, buildOptions)

rdx.getOrderStatus = () => {

  return dispatch => {
    api.getOrderStatus()
    .then(orderStatuses => {
      dispatch(rdx.setOrderStatuses(orderStatuses))
    })
  }
}

rdx.getLocations = () => {

  return dispatch => {
    api.getMyLocations()
    .then( locations => {
      dispatch(rdx.setLocations(locations))
    })
  }
}

rdx.getGrantedLocations = () => {

  return dispatch => {
    api.getMyLocations()
    .then( locations => {
      dispatch(rdx.setLocations(locations))
    })
  }
}

rdx.updateOrderDetails = ( orderId, orderDetails ) => {
  return dispatch => {
    api.updateOrderDetails(orderId, orderDetails)
    .then(() => {
      dispatch(rdx.setSaveSuccess(true))
      // dispatch(setShowSpinner(false))
      // window.location.reload(true)
    })
  }
}

rdx.getSalesManagers = () => {

  return dispatch => {

    api.getSalesManagers()
    .then(salesManagers => {
      dispatch(rdx.setSalesManagers(salesManagers.data))
    })
  }
}

rdx.getSalesReps = () => {
  return dispatch => {
    api.getSalesReps()
    .then( reps => {
      dispatch(rdx.setSalesReps(reps))
    })
  }
}

export const CLEAR_ORDER  = "CLEAR_ORDER";
export const SET_ORDER    = "SET_ORDER";
export const RESET_VALUES = "RESET_ORDER_DETAILS_VALUES"

rdx.resetValues = () => {
  return {
    type: RESET_VALUES
  }
}


export const clearOrder = () => {
  return {
    type: CLEAR_ORDER, 
  }
}

export const setOrder = (data) => {
  return {
    type: SET_ORDER, 
    payload: data 
  }
}

export const refreshOrder = (orderId, updateValues=false, showSpinner=false) => {
  return dispatch => {
    dispatch(setShowSpinner(true));
    
    api.getOrder(orderId)
    .then( order => {

      if(updateValues){
        api.updateOrderValues(orderId, order).then( () => {
          api.getOrder(orderId).then( order => {
            dispatch(setIsLoading(false))
            dispatch(setShowSpinner(false))
            dispatch({
              type: SET_ORDER,
              payload: { id: orderId, ...order }
            })
          });
        });
      }else{
        dispatch(setIsLoading(false))
        dispatch(setShowSpinner(false))
        dispatch({
          type: SET_ORDER,
          payload: { id: orderId, ...order }
        })
      }
    })
  }
}

export const orderReducer = (state=orderInitialState, action) => {

  const __reduxPrefix = action.__prefix

  switch (action.type) {
    case CLEAR_ORDER:
      return {
        ...state,
        order: orderInitialState.order
      }
    case SET_ORDER:
      const newState = {
        ...state,
        order: action.payload
        // modal: {
        //   ...orderInitialState.modal,
        //   salesReps: state.modal.salesReps,
        //   salesManagers: state.modal.salesManagers,
        //   orderStatuses: state.modal.orderStatuses,
        //   locations: state.modal.locations
        // }
      }
      return newState
    case RESET_VALUES:
      return {
        ...state,
        modal: {
          ...orderInitialState.modal,
          salesReps: state.modal.salesReps,
          salesManagers: state.modal.salesManagers,
          orderStatuses: state.modal.orderStatuses,
          locations: state.modal.locations
        }
      }
    default:
      //Handle all generated state updates...
      if ( __reduxPrefix === ORDER_REDUX_PREFIX ) {
        return handleStateDefault(state, action)
      }
  }
  return state
}

export const useOrder = () => {
  return useSelector(state => state.order?.order)
}

export const useCosigners = () => {
  return useSelector(state => _.defaultTo(state.order?.customer?.cosigners, []));
}

export const useContacts = () => {
  return useSelector(state => _.defaultTo(state.order?.customer?.contacts, []));
}

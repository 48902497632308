import React from 'react'
import { Card, Dropdown } from "react-bootstrap";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

import { ArrowLeft } from '@material-ui/icons';
import { ArrowDropDown } from '@material-ui/icons';

const initDocSection=  {
  customer:false,
  order:false,
  product:false,
  tradeIn:false,
  downPayment:false,
  fundingPayment:false,
  documents:true,
  selectedProduct:undefined,
  documentSubSection:undefined,
  documentSection:undefined
  }

const DocumentSectionTemplate = ({ title, recCount, eventKey, children, mode, customerName,isOpen,setIsOpen, setDocSearch, setItemSearch }) => {
    const handleToggle = useAccordionButton(eventKey, ()=>{
      setIsOpen((prev)=>({
            ...initDocSection,
            documentSection: (isOpen?.documentSection===eventKey ? undefined : eventKey )
      }))
      setDocSearch('')
      setItemSearch('')
    })
  return (
    <Card.Header className='m-2' style={{ backgroundColor: '#2d7ecf', color: 'white' }}>
      <Dropdown style={{ display: 'inline-table' }}>

        <Dropdown.Menu>
            { children }
        </Dropdown.Menu>
      </Dropdown>
        <span style={{ paddingLeft: '15px', fontWeight: 'bold' }}>{title}</span>
         <button
      type="button"
      style={{ backgroundColor: '#2d7ecf', color: 'white', border: 'none', maxWidth: '25px', float: 'right' }}
      onClick={(e) => handleToggle(eventKey)}
      data-testid={title.toLowerCase().replace(" ", "")+"Section.control.accordianArrow"}
    >
      { isOpen?.documents&&isOpen?.documentSection===eventKey ? ( <ArrowDropDown /> ) : ( <ArrowLeft /> ) }
    </button>
    </Card.Header>
  );
};

export default DocumentSectionTemplate;
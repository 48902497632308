import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  useParams
} from "react-router-dom";
import { useFetch,recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { removeChars, formatter, formatPhoneNumber, formatUpperCase } from "../../../../../../../utlis/formatters";
import { getOrderLevelAddresses, updateAddressOptions } from "../../../../../../../utlis/documentHelpers";
import { useOrder } from "../../../../../../../redux/orders/order"
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { NVAffPurchaserGlobalAutofill, NVAffPurchaserAutofillProductDetails, preProcessPayload } from "../DocumentAutofill/NVAffPurchaser"

const NVAffPurchaser = ({document_id, getDocuments, passedDocumentSlug, locations, setDocumentFocus, location}) => {
  const documentSlug = "nv-aff-pur";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  // const dispatch = useDispatch();

  // const stateAbbrev = States.map(state => {
  //   return state.abbreviation;
  // });

  const order = useOrder();

  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");


  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);
  // const [ locations, setLocations ] = useState([]);//Redux
  // const [ nameOptions, setNameOptions ] = useState([]);//Redux

  // Data Structure & Init //
  
  var initialDocumentStructure = {
  }

  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);

  const moneyFields = useMemo(()=>{return[]},[]);
  const phoneFields = useMemo(()=>{return[]},[]);
  const upperCaseFields = useMemo(()=>{return["vin_hin", "secondary_vin", "model", "f_vinhin_1", "f_vinhin_2"]},[]);
  const checkboxes = useMemo(()=>{return[]},[]);
  // const radios = [
  //   "type_toggle",
  //   "group_1_toggle",
  //   "group_2_toggle",
  // ];

  // X1- Current General On Change
  const handleDocumentValuesChange = e =>{
    // e.preventDefault();
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatPhoneNumber(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }

    // if(name === 'odometer'){
    //   setDocumentValues({...documentValues,[name]:removeChars(value)});
    // }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('NVAffPurchaser.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);
  

  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");


      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
      // setNameOptions(getAllNames(order, null));
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, order, documentLoaded, loadDocument ])

  const [assignedProduct, setAssignedProduct] = useState(undefined);
  const [selectedAddress1, setSelectedAddress1] = useState(undefined);
  const [selectedAddress2, setSelectedAddress2] = useState(undefined);
  const [addressOptions, setAddressOptions] = useState(undefined);
  const [inputAddressVal, setinputAddressVal] = useState(undefined);

 // Autofill //

 const GlobalAutofill = useCallback(async () => {
  let newValues = NVAffPurchaserGlobalAutofill(documentValues, order, null, null, location);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  // var newValues = documentValues;
  
  // if(order && order.customer){

  //   if(order && order.customer && order.customer.name){

  //     // newValues.phone_number = order.customer.phone;
      
  //     newValues.name = order.customer.name;
  //     newValues.name_of_purchaser = order.customer.name;
  //     newValues.notary_purchasers = order.customer.name;
  
  //     if(order.customer.cosigners && order.customer.cosigners.length > 0){
  //       let cosigner = order.customer.cosigners[0];
  //       if(cosigner){
  //         newValues.name = newValues.name+" and "+cosigner.name;
  //         newValues.name_of_purchaser = newValues.name_of_purchaser+" and "+cosigner.name;
  //         newValues.notary_purchasers = newValues.notary_purchasers+" and "+cosigner.name;
  //       }
  //     }
  //   }
    
  //   if(order && order.customer && order.customer.deliveryAddress){
  //     newValues.address_of_purchaser = order.customer.deliveryAddress.addressLine1;
  //     if(order.customer.deliveryAddress.addressLine1 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
  //       newValues.address_of_purchaser = newValues.address_of_purchaser+" "+order.customer.deliveryAddress.addressLine2;
  //     }
  //     newValues.address_of_purchaser = order.customer.deliveryAddress.city+" "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
  //   }
  // }

  // if(location){
  //   if(location.state === "CA"){
  //     newValues.purchased_from = "TEC OF CALIFORNIA, INC.";
  //   }else{
  //     newValues.purchased_from = "TEC EQUIPMENT, INC.";
  //   }
  // }
  
  // if(order && order.documentationDate){
  //   newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  // }
  // setDocumentValues(newValues);
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[documentValues, location, order])

// const preProcessPayload = useCallback((payload) => {

//   if(payload.type_toggle){
//     // Reset all
//     payload.vehicle_check = "no";
//     payload.vessel_check = "no";

//     switch(payload.type_toggle){
//       case "vehicle":
//         payload.vehicle_check = "yes";
//         break;
//       case "vessel":
//         payload.vessel_check = "yes";
//         break;
//       default:
//     }
//   }

//   Object.keys(payload).forEach(key => {
//     if(moneyFields.indexOf(key) >= 0){
//         payload[key] = formatMoneyValue(payload[key]);
//     }else if(phoneFields.indexOf(key) >= 0){
//         payload[key] = formatPhoneNumber(payload[key]);
//     }else if(upperCaseFields.indexOf(key) >= 0){
//         payload[key] = formatUpperCase(payload[key]);
//     }
//   });
//   setDocumentValues(JSON.parse(JSON.stringify(payload)));
//   Object.keys(payload).forEach(key => {
//     if(checkboxes.indexOf(key) >= 0){
//       payload[key] = payload[key] ? "yes": "no";
//     }
//   });

//   // if(payload.date_of_sale){
//   //   payload.date_of_sale = moment.utc(payload.date_of_sale).format("MM/DD/YY");
//   // }

//   if(payload.date){
//     payload.date = moment.utc(payload.date).format("MM/DD/YY");
//   }

//   return payload;
// },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])


const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });
    
    if(!document){
      return null;
    }

      var docCopy = JSON.parse(JSON.stringify(document));
      docCopy.payload =  JSON.parse(JSON.stringify(payload));
      docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
      docCopy.payload =  JSON.stringify(docCopy.payload);
        delete docCopy.documentStatus;
      try{
        let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
        setdocumentUrl(fileURL)
        } catch (err) {
          recordFrontEndError('NVAffPurchaser.jsx', 'Error previewing document payload: ' + err)
        };  
    
},[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


    //Autofill Product Details After Selection
    const AutofillProductDetails = useCallback(async (selectedProduct) => {
        
      let newValues = NVAffPurchaserAutofillProductDetails(selectedProduct, documentValues);

      if(newValues){
        setDocumentValues(newValues);
        previewPayloadDocument(documentValues);
        setHasUnsavedChanges(true);
        if(document){
          previewPayloadDocument(documentValues);
        }
        setSaveSuccess(false);
      }
      // var newValues = documentValues;
      // newValues.year = (selectedProduct.year);
      // newValues.make = (selectedProduct.make).toString().toUpperCase();
      // newValues.model = (selectedProduct.model).toString().toUpperCase();
      // newValues.vin_hull = (selectedProduct.vin).toString().toUpperCase();
      // // newValues.selling_price = selectedProduct.price;
      // // newValues.body_type = (selectedProduct.vehicleType);

      // // if(selectedProduct.fundingDetail && (selectedProduct.fundingDetail.fundingMethod === "tec-financing" || selectedProduct.fundingDetail.fundingMethod === "finance-only")){
      // //   newValues.name = selectedProduct.fundingDetail.finalizedLendingOption.bankName;
      // //   newValues.address_3 = selectedProduct.fundingDetail.finalizedLendingOption.lienAddress;
      // //   newValues.city_3 = selectedProduct.fundingDetail.finalizedLendingOption.lienCity;
      // //   newValues.state_3 = selectedProduct.fundingDetail.finalizedLendingOption.lienState;
      // //   newValues.zip_code_3 = selectedProduct.fundingDetail.finalizedLendingOption.lienZip;   
      // // }else if(selectedProduct.fundingDetail && selectedProduct.fundingDetail.fundingMethod === "cash-with-lien"){
      // //   newValues.name = selectedProduct.fundingDetail.cashLienHolder.bankName;
      // //   newValues.address_3 = selectedProduct.fundingDetail.cashLienHolder.addressLine1+" "+selectedProduct.fundingDetail.cashLienHolder.addressLine2;
      // //   newValues.city_3 = selectedProduct.fundingDetail.cashLienHolder.city;
      // //   newValues.state_3 = selectedProduct.fundingDetail.cashLienHolder.state;
      // //   newValues.zip_code_3 = selectedProduct.fundingDetail.cashLienHolder.zip;
      // // }else if(selectedProduct.shipTo){
      // //   newValues.name = selectedProduct.shipTo.name;
      // //   newValues.address_3 = selectedProduct.shipTo.addressLine1+" "+selectedProduct.shipTo.addressLine2;
      // //   newValues.city_3 = selectedProduct.shipTo.city;
      // //   newValues.state_3 = selectedProduct.shipTo.state;
      // //   newValues.zip_code_3 = selectedProduct.shipTo.zip;
      // // }

      // setDocumentValues(newValues);
      // // setProductToggleAutoFill(false);
      // if(document){
      //   previewPayloadDocument(documentValues);
      // }
      // setHasUnsavedChanges(true);
      // setSaveSuccess(false);
    },[ document, documentValues, previewPayloadDocument ]);

  // Document BREAD + Preview // TODO: Move to Redux

  useEffect(() => {
    if(document && document.payload && !dataLoaded){
      var existingPayload = false;
      var documentPayload = JSON.parse(document.payload);
      if(documentPayload && Object.keys(documentPayload).length > 0){
    
        existingPayload = true;
    
        // if(documentPayload.date_of_sale){
        //   documentPayload.date_of_sale = moment.utc(documentPayload.date_of_sale).format("YYYY-MM-DD");
        // }

        if(documentPayload.date){
          documentPayload.date = moment.utc(documentPayload.date).format("YYYY-MM-DD");
        }

    
        
        setDocumentValues(documentPayload);

        // Loading Use Effect
        // if(document.additionalData){
        //   const methodMap = { 
        //     selectedAddress1: setSelectedAddress1, 
        //     selectedAddress2: setSelectedAddress2, 
        //     selectedAddress3: setSelectedAddress3, 
        //     selectedAddress4: setSelectedAddress4, 
        //     selectedAddress5: setSelectedAddress5, 
        //     selectedAddress6: setSelectedAddress6, 
        //   };

        //   let parsedAdditionalData = JSON.parse(document.additionalData);
        //   Object.keys(parsedAdditionalData).forEach(function(key) {
        //     if(methodMap[key]){
        //       methodMap[key](parsedAdditionalData[key]);
        //     }
        //   });
        // }

      }
      var foundAssignedProduct = null;
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
        foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
      }
      
      setAddressOptions(getOrderLevelAddresses(order));
      if(foundAssignedProduct){
          setAssignedProduct(foundAssignedProduct);
          setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
          // setNameOptions(getAllNames(order, foundAssignedProduct));
      }
      
        
        if(!existingPayload){
          GlobalAutofill();
          previewPayloadDocument(documentValues);
          if(foundAssignedProduct){
            AutofillProductDetails(foundAssignedProduct)
          }
        }else{
          // if(document && document.itemIds){
          //   var itemIds = document.itemIds.split(',');
          //     var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
          //     let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
          //     let obj = {id: foundProduct.id, name: str}
          //     setproductVal(obj);
          //     if(foundProduct){
          //       setAddressOptions(updateAddressOptions(foundProduct, order));
          //     }
          //     streamDocument();
          // }
    
        }
        
      setDataLoaded(true);
    }
  },[ documentValues, document, dataLoaded, order, AutofillProductDetails, GlobalAutofill, previewPayloadDocument ])


  function ReloadAutoFillValues(){  
    var foundAssignedProduct = null;
    if(document && document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }

    GlobalAutofill();
    if(foundAssignedProduct){
      AutofillProductDetails(foundAssignedProduct)
    }
  }

  
    // function streamDocument(){
    //   axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
    //     .then(response => {
    //       const file = new Blob(
    //         [response.data], 
    //         {type: 'application/pdf'});
    //         const fileURL = URL.createObjectURL(file);
    //         // window.open(fileURL);
    //       setdocumentUrl(fileURL)
    //     }).catch(err => {
    //     })
    // }
  
    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();

      
      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);

  
      // const payload = JSON.parse(JSON.stringify(documentValues));
      // var docCopy = document;
      // docCopy.payload = payload;
      // docCopy.payload = preProcessPayload(docCopy.payload);
      // docCopy.payload =  JSON.stringify(docCopy.payload);
      // delete docCopy.documentStatus;
      
      // if(selectedProduct && selectedProduct.id){
      //   docCopy.itemIds = `${selectedProduct.id}`;
      // }
  
      //Save Dropdown Selections
      // let dropdownData = {};
      // if(selectedAddress1){
      //   dropdownData.selectedAddress1 = {
      //     id: selectedAddress1.id,
      //     name: selectedAddress1.name
      //   };
      // }
      // if(selectedAddress2){
      //   dropdownData.selectedAddress2 = {
      //     id: selectedAddress2.id,
      //     name: selectedAddress2.name
      //   };
      // }
      // if(selectedAddress3){
      //   dropdownData.selectedAddress3 = {
      //     id: selectedAddress3.id,
      //     name: selectedAddress3.name
      //   };
      // }
      // if(selectedAddress4){
      //   dropdownData.selectedAddress4 = {
      //     id: selectedAddress4.id,
      //     name: selectedAddress4.name
      //   };
      // }
      // if(selectedAddress5){
      //   dropdownData.selectedAddress5 = {
      //     id: selectedAddress5.id,
      //     name: selectedAddress5.name
      //   };
      // }
      // if(selectedAddress6){
      //   dropdownData.selectedAddress6 = {
      //     id: selectedAddress6.id,
      //     name: selectedAddress6.name
      //   };
      // }

      // docCopy.additionalData = JSON.stringify(dropdownData);
      // let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, null, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });
      // try {
      //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
      //   if (res){
      //     await axios.post(`/document/${document.id}/generate`) 
      //     getDocuments();
      //     setSaveSuccess(true);
      //     setHasUnsavedChanges(false);
      //   };
      // } catch (err) {
      //   recordFrontEndError('NVAffPurchaser.jsx', 'Error saving document: ' + err)
      // };
      
    }

// Component Helpers //

  // useEffect(() => {

  // },[ selectedProduct, productToggleAutoFill ])

  // useEffect(() => {
  //   //Autofill Tradein Details After Selection
  //   if(selectedLocation !== undefined && locationToggleAutoFill){
  //     var newValues = documentValues;
  //     newValues.seller_address = selectedLocation.address;
  //     newValues.seller_city = selectedLocation.city;
  //     newValues.seller_state = selectedLocation.state;
  //     newValues.seller_zip = selectedLocation.zip;
  //     newValues.seller_phone = formatPhoneNumber(selectedLocation.phone);
  
  //     if(selectedLocation.state === "CA"){
  //       newValues.seller_name_signature = "TEC OF CALIFORNIA, INC";
  //       newValues.copy_seller_name_signature = "TEC OF CALIFORNIA, INC";
  //     }else{
  //       newValues.seller_name_signature = "TEC EQUIPMENT, INC.";
  //       newValues.copy_seller_name_signature = "TEC EQUIPMENT, INC.";
  //     }
  
  //     setDocumentValues(newValues);
  //     setLocationToggleAutoFill(false);
  //     if(document){
  //       previewPayloadDocument(documentValues);
  //     }
  //     setHasUnsavedChanges(true);
  //     setSaveSuccess(false);
  //   }
  // },[ selectedLocation, locationToggleAutoFill ])
  
  //Autofill Address Details After Selection
  function AutoFillAddress(addressSelected, selectionInstance){
    var newValues = documentValues;
    if(selectionInstance){
      if(selectionInstance === "customer-address"){
        newValues.address_of_purchaser = addressSelected.address+" "+addressSelected.city+" "+addressSelected.state+" "+addressSelected.zip;
      }
      if(selectionInstance === "customer-address-2"){
        newValues.address_of_purchaser_2 = addressSelected.address+" "+addressSelected.city+" "+addressSelected.state+" "+addressSelected.zip;
      }
    }
    setDocumentValues(newValues);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  const onCloseClick = e => {
    e.preventDefault()
    setHasUnsavedChanges(false);
    setSaveSuccess(false);
    setdocumentUrl("");
    setDocument(undefined);
    // setNameOptions([]);
    setPreviousDocumentId(0);
    setDocumentValues(initialDocumentStructure);
    setDocumentLoaded(false);
    setDataLoaded(false);
    setAssignedProduct(undefined);
    setSelectedAddress1(undefined);
    setSelectedAddress2(undefined);
    setAddressOptions(undefined);
    setinputAddressVal(undefined);
    setDocumentFocus(undefined);
  }
  
  const [hasClickedClose, setHasClickedClose] = useState(false);
  
  const closeClicked = e => {
    if( hasUnsavedChanges ){
        setHasClickedClose(true);
    } else {
        onCloseClick(e);
    }
  }
  
  const preCloseSave = e => {
    handleSubmit(e)
    setHasClickedClose(false);
  }
  
  const preCloseClose = e => {
    setHasClickedClose(false);
    onCloseClick(e)
  }

  return (
    <>
      <div
        className="modal fade"
        // id="ca-statement-of-facts"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                Nevada - Affidavit of Purchaser
              </h5>
              <br />
              { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1 pt-1 text-white">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                  </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}


                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}


                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ fontSize: '12px', overflowY: 'scroll', height: 'inherit'}}>
               
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      ReloadAutoFillValues();
                      // GlobalAutofill();
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>
                  <br />
                  { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Product Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                        </div>
                    </div>
                  ):(
                    <div className="m-2 p-2 border">
                        <span>No Assigned Product</span>
                    </div>
                  )}

                  <div className="row">
                    <h5>Dealer's Report of Sale</h5>
                  </div>

                  {/* <Autocomplete
                      style={{ width: '100%'}}
                      value={ (documentValues.name) ?? null}
                      name="owner_name"
                      onBlur={(e) => updatePreview(e)}
                      onChange={(event, newValue) => {
                        let updatedObject = documentValues;
                        updatedObject.name = newValue.name;
                        setDocumentValues(updatedObject);
                        // setDocumentValues({...documentValues,["owner_name"]:newValue.name});
                      }}
                      // inputValue={inputAddressVal}
                      // onInputChange={(event, newInputValue) => {
                      //   setinputAddressVal(newInputValue);
                      // }}
                      options={nameOptions}
                      sx={{ width: 400 }}
                      renderInput={(params) => <TextField {...params} label="Purchaser Name" />}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                    /> */}

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Purchaser Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Purchaser Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Type</label>
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="type_toggle"
                            value={(documentValues.type_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="vehicle" control={<Radio />} label="Vehicle" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="vessel" control={<Radio />} label="Vessel" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Purchaser From</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchased_from"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchased_from ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">VIN/HULL #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin_hull"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin_hull ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <hr></hr>

                  
                  { addressOptions && addressOptions.length > 0 ? (
                      <div className="row">
                        <div className="d-flex justify-content-evenly pb-2">
                          <div className="mb-3 col-md-12 pt-4">
                            <Autocomplete
                              style={{ width: '100%'}}
                              value={ (selectedAddress1 && selectedAddress1.name) ?? null}
                              name="customer-address"
                              onChange={(event, newValue) => {
                                setSelectedAddress1(newValue);
                                AutoFillAddress(newValue, "customer-address");
                              }}
                              inputValue={inputAddressVal}
                              onInputChange={(event, newInputValue) => {
                                setinputAddressVal(newInputValue);
                              }}
                              options={addressOptions}
                              sx={{ width: 400 }}
                              renderInput={(params) => <TextField {...params} label="Addresses" />}
                              renderOption={(props, option) => {
                                return (
                                  <li {...props} key={option.id}>
                                    {option.name}
                                  </li>
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Name of Purchaser</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="name_of_purchaser"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.name_of_purchaser ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Drivers License Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="drivers_license_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.drivers_license_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-2">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.state ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="address_of_purchaser"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.address_of_purchaser ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Social Security Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="social_security_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.social_security_number ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <hr></hr>

                  { addressOptions && addressOptions.length > 0 ? (
                      <div className="row">
                        <div className="d-flex justify-content-evenly pb-2">
                          <div className="mb-3 col-md-12 pt-4">
                            <Autocomplete
                              style={{ width: '100%'}}
                              value={ (selectedAddress2 && selectedAddress2.name) ?? null}
                              name="customer-address"
                              onChange={(event, newValue) => {
                                setSelectedAddress2(newValue);
                                AutoFillAddress(newValue, "customer-address-2");
                              }}
                              inputValue={inputAddressVal}
                              onInputChange={(event, newInputValue) => {
                                setinputAddressVal(newInputValue);
                              }}
                              options={addressOptions}
                              sx={{ width: 400 }}
                              renderInput={(params) => <TextField {...params} label="Addresses" />}
                              renderOption={(props, option) => {
                                return (
                                  <li {...props} key={option.id}>
                                    {option.name}
                                  </li>
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}


                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Name of Purchaser 2</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="name_of_purchaser_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.name_of_purchaser_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Drivers License Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="drivers_license_number_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.drivers_license_number_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-2">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="state_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.state_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="address_of_purchaser_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.address_of_purchaser_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Social Security Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="social_security_number_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.social_security_number_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Notary Purchasers</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="notary_purchasers"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.notary_purchasers ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default NVAffPurchaser

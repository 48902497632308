import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { removeChars, formatUpperCase, formatter } from "../../../../../../../utlis/formatters";
import { updateAddressOptions } from "../../../../../../../utlis/documentHelpers";
import { useOrder } from "../../../../../../../redux/orders/order"
import { OregonTitleGlobalAutofill, OregonTitleAutofillProductDetails, preProcessPayload } from "../DocumentAutofill/OregonTitle"
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"


const OregonTitleModal = ({document_id, getDocuments, passedDocumentSlug, setDocumentFocus}) => {
  const documentSlug = "or-title-app";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  const order = useOrder();
  const axios = useFetch()

  const [documentUrl, setdocumentUrl] = useState("");
  const [document, setDocument] = useState(undefined);
  const [previousDocumentId, setPreviousDocumentId] = useState(0);
  
  // Data Structure & Init //
  var initialDocumentStructure = {
    // "Clear Form": undefined,  // Top Right

    vin: undefined, // Vehicle Information 
    oregon_title_number: undefined,  // Vehicle Information
    gvwr: undefined,  // Vehicle Information
    present_oregon_license_plate: undefined,  // Vehicle Information
    year: undefined,  // Vehicle Information
    make: undefined,  // Vehicle Information
    style: undefined,  // Vehicle Information
    weight_length: undefined,  // Vehicle Information

    width_exceeded_yes: undefined, // Radio Toggle - // Vehicle Information
    width_exceeded_no: undefined, // Radio Toggle - // Vehicle Information
    weight_exceeded: undefined,  // Radio Toggle - // Vehicle Information

    farm_id_number: undefined,  // Vehicle Information
    fleet_account_number: undefined,  // Vehicle Information
    equipment_number: undefined,  // Vehicle Information

    gas: undefined,  // Checkbox Set - Vehicle Information
    electric: undefined,  // Checkbox Set - Vehicle Information
    diesel: undefined,  // Checkbox Set - Vehicle Information
    propane: undefined,  // Checkbox Set - Vehicle Information
    hybrid: undefined,  // Checkbox Set - Vehicle Information
    natural_gas: undefined,  // Checkbox Set - Vehicle Information
    plug_in_hybrid: undefined,  // Checkbox Set - Vehicle Information
    other: undefined,  // Checkbox Set - Vehicle Information
    other_text: undefined,  // Checkbox Set - Vehicle Information
    flex_fuel: undefined,  // Checkbox Set - Vehicle Information


    odo_reading: undefined, // Vehicle Information
    reading_date: undefined, // Vehicle Information

    mileage_excess: undefined, // Checkbox - Vehicle Information
    mileage_incorrect: undefined, // Checkbox - Vehicle Information

    name: undefined, // Owner or Lessee/Address
    owner_checked: undefined,  // Owner or Lessee/Address
    lessee_checked: undefined,  // Owner or Lessee/Address
    odl_customer_number: undefined,  // Owner or Lessee/Address
    dob: undefined,  // Owner or Lessee/Address

    address: undefined,  // Owner or Lessee/Address
    mailing_address: undefined,  // Owner or Lessee/Address

    city_state_zip: undefined,  // Owner or Lessee/Address
    county: undefined,  // Owner or Lessee/Address
    mailing_city_state_zip: undefined,  // Owner or Lessee/Address
    mailing_county: undefined,  // Owner or Lessee/Address
    
    joint_name: undefined, // Owner or Lessee/Address
    joint_odl_customer_number: undefined,  // Owner or Lessee/Address
    joint_dob: undefined,  // Owner or Lessee/Address

    joint_name_2: undefined, // Owner or Lessee/Address
    joint_odl_customer_number_2: undefined,  // Owner or Lessee/Address
    joint_dob_2: undefined,  // Owner or Lessee/Address

    one_time_mailing_address: undefined,  // Owner or Lessee/Address

    reg_title_both: undefined,
    registration_only: undefined,  // Radio - Owner or Lessee/Address
    title_only: undefined,  // Radio - Owner or Lessee/Address
    both: undefined,  // Radio - Owner or Lessee/Address

    vehicle_address: undefined,  // Owner or Lessee/Address
    vehicle_city_state_zip: undefined,  // Owner or Lessee/Address
    vehicle_county: undefined,  // Owner or Lessee/Address
    
    one_time_mailing_city_state_zip: undefined,  // Owner or Lessee/Address
    
    joint_owners_agree_ros: undefined, // Survivorship
    security_agree_ros: undefined, // Survivorship

    security_name: undefined, // Security Interest Holder and/or Lessor
    security_odl_number: undefined,  // Security Interest Holder and/or Lessor
    security_dob: undefined,  // Security Interest Holder and/or Lessor

    security_address_city_state_zip: undefined,  // Security Interest Holder and/or Lessor
    security_area_code: undefined,  // Security Interest Holder and/or Lessor
    security_phone: undefined,  // Security Interest Holder and/or Lessor

    secondary_name: undefined,  // Security Interest Holder and/or Lessor
    secondary_odl_number: undefined,  // Security Interest Holder and/or Lessor
    secondary_dob: undefined,  // Security Interest Holder and/or Lessor

    secondary_address_city_state_zip: undefined,  // Security Interest Holder and/or Lessor
    secondary_area_code: undefined,  // Security Interest Holder and/or Lessor
    secondary_phone: undefined,  // Security Interest Holder and/or Lessor

    lessor_name: undefined,  // Security Interest Holder and/or Lessor
    lessor_odl_number: undefined,  // Security Interest Holder and/or Lessor
    lessor_dob: undefined,  // Security Interest Holder and/or Lessor

    lessor_address_city_state_zip: undefined,  // Security Interest Holder and/or Lessor
    lessor_area_code: undefined,  // Security Interest Holder and/or Lessor
    lessor_phone_number: undefined,  // Security Interest Holder and/or Lessor

    insurance_company: undefined, // Certificates
    insurance_policy_number: undefined,  // Certificates

    owner_area_code_signature: undefined, //Signatures
    owner_phone_signature: undefined,  //Signatures

    lessor_area_code_signature: undefined, //Signatures
    lessor_phone_signature: undefined  //Signatures
  }
  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  // X1 Reference Arrays - TODO: Possibly move these to back end as part of the template stucture
  const moneyFields = useMemo(()=>{return[]},[]);
  const phoneFields = useMemo(()=>{return[]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "model"]},[]);
  const checkboxes = useMemo(()=>{return[]},[]);

  // X1.1- Current General On Change
  const handleDocumentValuesChange = e =>{
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  // const [documentLoaded, setDocumentLoaded] = useState(false);
    
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('OregonTitleModal.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])

  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
  if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
    setdocumentUrl("");
    setPreviousDocumentId(document_id);
    // setDocumentLoaded(true);
    loadDocument(document_id);
  }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, loadDocument ])
  const [assignedProduct, setAssignedProduct] = useState(undefined);
  // const [productVal, setproductVal] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);


  // const [selectedAddress, setSelectedAddress] = useState(undefined);
  const [selectedBusinessAddress, setSelectedBusinessAddress] = useState(undefined);
  const [selectedMailingAddress, setSelectedMailingAddress] = useState(undefined);
  const [selectedOneTimeMailingAddress, setSelectedOneTimeMailingAddress] = useState(undefined);
  const [selectedVehicleAddress, setSelectedVehicleAddress] = useState(undefined);
  const [selectedSecurityAddress, setSelectedSecurityAddress] = useState(undefined);
  const [selectedSecondaryAddress, setSelectedSecondaryAddress] = useState(undefined);
  const [selectedLessorAddress, setSelectedLessorAddress] = useState(undefined);
  // const [addressVal, setAddressVal] = useState(undefined);
  const [inputAddressVal, setinputAddressVal] = useState(undefined);
  const [addressOptions, setAddressOptions] = useState(undefined);
  // const [addressToggleAutoFill, setAddressToggleAutoFill] = useState(false);
  // const [addressInstance, setAddressInstance] = useState(undefined);

 // Dropdowns //

  // if(order && order.products && order.products.length > 0 && productOptions === undefined){
  //   let getProductOptions = [];
  //   order.products.map(product => {
  //     let str = `${product.stockNumber.toString().toUpperCase()}`;
  //     let obj = {id: product.id, name: str}
  //     getProductOptions.push(obj);
  //   })
  //   setproductOptions(getProductOptions);
  // }

  // if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
  //   let getTradeinOptions = [];
  //   order.tradeins.map(tradein => {
  //     let str = `${tradein.year} ${tradein.make} ${tradein.model.toString().toUpperCase()}`;
  //     let obj = {id: tradein.id, name: str}
  //     getTradeinOptions.push(obj);
  //   })
  //   setTradeinOptions(getTradeinOptions);
  // }

  function getOrderLevelAddresses(order){
    let getAddressOptions = [];
    if(order.customer && order.customer.deliveryAddress){
      var addressLine = '';
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
      }else{
        addressLine = order.customer.deliveryAddress.addressLine1;
      }
      let city = order.customer.deliveryAddress.city;
      let state = order.customer.deliveryAddress.state;
      let zip = order.customer.deliveryAddress.zip;

      let obj = {slug: "customer-delivery", name: "Customer Delivery", address: addressLine, city: city, state: state, zip: zip};
      getAddressOptions.push(obj);
    }

    if(order.customer && order.customer.mailingAddress){

      if(order.customer.mailingAddress.addressLine2 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
        addressLine = (order.customer.mailingAddress.addressLine1+" "+order.customer.mailingAddress.addressLine2);
      }else{
        addressLine = order.customer.mailingAddress.addressLine1;
      }
      let city = order.customer.mailingAddress.city;
      let state = order.customer.mailingAddress.state;
      let zip = order.customer.mailingAddress.zip;

      let obj = {slug: "customer-mailing", name: "Customer Mailing", address: addressLine, city: city, state: state, zip: zip};
      getAddressOptions.push(obj);
    }
    return getAddressOptions;
  }
  
  // if(addressOptions === "undefined"){
  //   // setAddressToggleAutoFill(false);
  // }
  
  // function formatNameLastMiddleFirst(name){
  //   let wordArray = name.split(" ");
  //   if(wordArray.length === 2){
  //     return wordArray[1]+", "+wordArray[0];
  //   }else if(wordArray.length === 3){
  //     return wordArray[2]+", "+wordArray[0]+", "+wordArray[1];
  //   }
  //   return name;
  // }
  
  // Autofill //

  // const preProcessPayload = useCallback((payload) => {

      
  //   if(payload.width_exceeded === "yes"){
  //     payload.width_exceeded_yes = "yes";
  //     payload.width_exceeded_no = "no";
  //   }else if(payload.width_exceeded === "no"){
  //     payload.width_exceeded_yes = "no";
  //     payload.width_exceeded_no = "yes";
  //   }else{
  //     payload.width_exceeded_yes = "";
  //     payload.width_exceeded_no = "";
  //   }
    
  //   if(payload.weight_exceeded === "yes"){
  //     payload.weight_exceeded_yes = "yes";
  //     payload.weight_exceeded_no = "no";
  //   }else if(payload.weight_exceeded === "no"){
  //     payload.weight_exceeded_yes = "no";
  //     payload.weight_exceeded_no = "yes";
  //   }else{
  //     payload.weight_exceeded_yes = "";
  //     payload.weight_exceeded_no = "";
  //   }
    
  //   if(payload.mileage_excess === "yes"){
  //     payload.mileage_excess = "yes";
  //     payload.mileage_incorrect = "no";
  //   }else if(payload.mileage_excess === "no"){
  //     payload.mileage_excess = "no";
  //     payload.mileage_incorrect = "yes";
  //   }else{
  //     payload.mileage_excess = "";
  //     payload.mileage_incorrect = "";
  //   }
    
    
  //   if(payload.owner_checked === "yes"){
  //     payload.owner_checked = "yes";
  //     payload.lessee_checked = "no";
  //   }else if(payload.owner_checked === "no"){
  //     payload.owner_checked = "no";
  //     payload.lessee_checked = "yes";
  //   }else{
  //     payload.owner_checked = "";
  //     payload.lessee_checked = "";
  //   }
    
  //   if(payload.reg_title_both === "reg"){
  //     payload.registration_only = "yes";
  //     payload.title_only = "no";
  //     payload.both = "no";
  //   }else if(payload.reg_title_both === "title"){
  //     payload.registration_only = "no";
  //     payload.title_only = "yes";
  //     payload.both = "no";
  //   }else if(payload.reg_title_both === "both"){
  //     payload.registration_only = "no";
  //     payload.title_only = "no";
  //     payload.both = "yes";
  //   }
    
  //   if(payload.joint_owners_agree_ros === "yes"){
  //     payload.joint_owners_agree_ros_yes = "yes";
  //     payload.joint_owners_agree_ros_no = "no";
  //   }else if(payload.joint_owners_agree_ros === "no"){
  //     payload.joint_owners_agree_ros_yes = "no";
  //     payload.joint_owners_agree_ros_no = "yes";
  //   }else{
  //     payload.joint_owners_agree_ros_yes = "";
  //     payload.joint_owners_agree_ros_no = "";
  //   }
    
  //   if(payload.security_agree_ros === "yes"){
  //     payload.security_agree_ros_yes = "yes";
  //     payload.security_agree_ros_no = "no";
  //   }else if(payload.security_agree_ros === "no"){
  //     payload.security_agree_ros_yes = "no";
  //     payload.security_agree_ros_no = "yes";
  //   }else{
  //     payload.security_agree_ros_yes = "";
  //     payload.security_agree_ros_no = "";
  //   }
    
  //   if(payload.fuel_toggle){
  //     // Reset all
  //     payload.gas_cb = "no";
  //     payload.diesel_cb = "no";
  //     payload.hybrid_cb = "no";
  //     payload.plug_in_hybrid_cb = "no";
  //     payload.electric_cb = "no";
  //     payload.flex_fuel_cb = "no";
  //     payload.propane_cb = "no";
  //     payload.natural_gas_cb = "no";
  //     payload.other_cb = "no";
      
  //     switch(payload.fuel_toggle){
  //       case "gas":
  //         payload.gas_cb = "yes";
  //         break;
  //         case "diesel":
  //           payload.diesel_cb = "yes";
  //           break;
  //           case "hybrid":
  //             payload.hybrid_cb = "yes";
  //             break;
  //       case "plug_in_hybrid":
  //         payload.plug_in_hybrid_cb = "yes";
  //         break;
  //         case "electric":
  //           payload.electric_cb = "yes";
  //           break;
  //           case "flex_fuel":
  //         payload.flex_fuel_cb = "yes";
  //         break;
  //         case "propane":
  //           payload.propane_cb = "yes";
  //           break;
  //           case "natural_gas":
  //         payload.natural_gas_cb = "yes";
  //         break;
  //         case "other":
  //           payload.other_cb = "yes";
  //           break;
  //           default:
  //           }
  //   }
    
  //     // X1.2- Field Type Specific Preview Formatting
  //     Object.keys(payload).forEach(key => {
  //       if(moneyFields.indexOf(key) >= 0){
  //           payload[key] = formatMoneyValue(payload[key]);
  //       }else if(phoneFields.indexOf(key) >= 0){
  //           payload[key] = formatPhoneNumber(payload[key]);
  //       }else if(upperCaseFields.indexOf(key) >= 0){
  //           payload[key] = formatUpperCase(payload[key]);
  //       }
  //     });
  //     setDocumentValues(JSON.parse(JSON.stringify(payload)));
  //     Object.keys(payload).forEach(key => {
  //       if(checkboxes.indexOf(key) >= 0){
  //         payload[key] = payload[key] ? "yes": "no";
  //       }
  //     });
      
  //     if(payload.reading_date){
  //       payload.reading_date = moment.utc(payload.reading_date).format("MM/DD/YY");
  //     }

  //     if(payload.dob){
  //       payload.dob = moment.utc(payload.dob).format("MM/DD/YY");
  //     }

  //     if(payload.joint_dob){
  //       payload.joint_dob = moment.utc(payload.joint_dob).format("MM/DD/YY");
  //     }

  //     if(payload.joint_dob_2){
  //       payload.joint_dob_2 = moment.utc(payload.joint_dob_2).format("MM/DD/YY");
  //     }

  //     if(payload.security_dob){
  //       payload.security_dob = moment.utc(payload.security_dob).format("MM/DD/YY");
  //     }

  //     if(payload.secondary_dob){
  //       payload.secondary_dob = moment.utc(payload.secondary_dob).format("MM/DD/YY");
  //     }

  //     if(payload.lessor_dob){
  //       payload.lessor_dob = moment.utc(payload.lessor_dob).format("MM/DD/YY");
  //     }

  //     if(payload.delivery_date){
  //       payload.delivery_date = moment.utc(payload.delivery_date).format("MM/DD/YY");
  //     }

  //     return payload;
  // },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])
    

const previewPayloadDocument = useCallback(async (documentValues) => {
    var payload = documentValues;
  Object.keys(payload).forEach(key => {
    if (payload[key] === null || payload[key] === undefined) {
      delete payload[key];
    }
  });
  
  var docCopy = JSON.parse(JSON.stringify(document));
  docCopy.payload =  JSON.parse(JSON.stringify(payload));
  docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
  docCopy.payload =  JSON.stringify(docCopy.payload);
  delete docCopy.documentStatus;
  
  try {
    let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
    const file = new Blob(
      [response.data], 
      {type: 'application/pdf'});
    const fileURL = URL.createObjectURL(file);
      // window.open(fileURL);
    setdocumentUrl(fileURL)
    } catch (err) {
      recordFrontEndError('OregonTitleModal.jsx', 'Error previewing document payload: ' + err)
  };  
},[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


  const AutofillProductDetails = useCallback(async (selectedProduct) => {
    let newValues = OregonTitleAutofillProductDetails(selectedProduct, documentValues);

    if(newValues){
      setDocumentValues(newValues);
      previewPayloadDocument(documentValues);
      setHasUnsavedChanges(true);
      previewPayloadDocument(documentValues);
      setSaveSuccess(false);
    }
  

    ////////////

  
  //   var newValues = documentValues;
  //   newValues.year = (selectedProduct.year).trim().replace(/['"]+/g, '');
  //   newValues.make = (selectedProduct.make).trim();
  //   newValues.model = (selectedProduct.model).trim();
  //   newValues.stock_number = (selectedProduct.stockNumber);
  //   newValues.mileage = (selectedProduct.mileage);
  //   newValues.serial_number = (selectedProduct.serialNumber);
  //   newValues.vin = (selectedProduct.vin);
  //   newValues.style = (selectedProduct.vehicleType);
  
  //  if(selectedProduct.fundingDetail && selectedProduct.fundingDetail.finalizedAt){
  //    if(selectedProduct.fundingDetail.fundingMethod === "tec-financing" || selectedProduct.fundingDetail.fundingMethod === "finance-only" || selectedProduct.fundingDetail.fundingMethod === "direct-deal"){
  //      if(selectedProduct.fundingDetail.finalizedLendingOption){
  //       newValues.security_name = selectedProduct.fundingDetail.finalizedLendingOption.bankName;
  
  //       let addressLine = "";
  //       addressLine = selectedProduct.fundingDetail.finalizedLendingOption.lienAddress;
  
  //       addressLine += " "+(selectedProduct.fundingDetail.finalizedLendingOption.lienCity+", "+selectedProduct.fundingDetail.finalizedLendingOption.lienState+" "+selectedProduct.fundingDetail.finalizedLendingOption.lienZip)
  //       newValues.security_address_city_state_zip = addressLine;
  //      }
  //    }
  
  //    if(selectedProduct.fundingDetail.fundingMethod === "cash-with-lien"){
  //     if(selectedProduct.fundingDetail.cashLienHolder){
  //       newValues.security_name = selectedProduct.fundingDetail.cashLienHolder.bankName;
  
  //       let addressLine = "";
  //       if(selectedProduct.fundingDetail.cashLienHolder.addressLine2 != null && selectedProduct.fundingDetail.cashLienHolder.addressLine2 !== undefined && selectedProduct.fundingDetail.cashLienHolder.addressLine2 !== ''){
  //         addressLine = (selectedProduct.fundingDetail.cashLienHolder.addressLine1+" "+selectedProduct.fundingDetail.cashLienHolder.addressLine2);
  //       }else{
  //         addressLine = selectedProduct.fundingDetail.cashLienHolder.addressLine1;
  //       }
  
  //       addressLine += (selectedProduct.fundingDetail.cashLienHolder.city+", "+selectedProduct.fundingDetail.cashLienHolder.state+" "+selectedProduct.fundingDetail.cashLienHolder.zip)
  //       newValues.security_address_city_state_zip = addressLine;
  //     }
  //    }
  //  }
  
  
  //   setDocumentValues(newValues);
  //   // setProductToggleAutoFill(false);
  //   previewPayloadDocument(documentValues);
  //   // setAddressOptions(updateAddressOptions(selectedProduct, order));
  //   setHasUnsavedChanges(true);
  },[ documentValues, previewPayloadDocument ]);
  
  const GlobalAutofill = useCallback(async () => {
    let newValues = OregonTitleGlobalAutofill(documentValues, order);
    if(newValues){
      setDocumentValues(newValues);
      setHasUnsavedChanges(true);
      setSaveSuccess(false);
    }

  //   // setAddressOptions(getOrderLevelAddresses(order));
  //   var newValues = documentValues;
  //   if(order && order.customer && order.customer.name){
  //     if(order.customer.type === "entity"){
  //       newValues.name = order.customer.name.toString().toUpperCase();
  //     }else{
  //       newValues.name = formatNameLastMiddleFirst(order.customer.name).toString().toUpperCase();
  //     }

  //     if(order.customer.dba){
  //       newValues.name += " dba "+order.customer.dba;
  //     }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
  //         let cosigner = order.customer.cosigners[0];
  //         if(cosigner && cosigner.name){
  //             newValues.name += " AND "+cosigner.name;
  //         }
  //     }
  //   }

  //   if(order && order.customer && order.customer.deliveryAddress){
  //     var addressLineDelivery = '';
  //     if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
  //       addressLineDelivery = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
  //     }else{
  //       addressLineDelivery = order.customer.deliveryAddress.addressLine1;
  //     }
  //     newValues.address = addressLineDelivery;
  //     newValues.city_state_zip = (order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
  //   }

  //   if(order && order.customer && order.customer.mailingAddress){
  //     var addressLineMailing = '';
  //     if(order.customer.mailingAddress.addressLine2 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
  //       addressLineMailing = (order.customer.mailingAddress.addressLine1+" "+order.customer.mailingAddress.addressLine2);
  //     }else{
  //       addressLineMailing = order.customer.mailingAddress.addressLine1;
  //     }
  //     newValues.mailing_address = addressLineMailing;
  //     newValues.mailing_city_state_zip = (order.customer.mailingAddress.city+", "+order.customer.mailingAddress.state+" "+order.customer.mailingAddress.zip);
  //   }

  //   if(order && order.documentationDate && documentValues.delivery_date === undefined){
  //     newValues.delivery_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  //   }

  //   setDocumentValues(newValues);
  //   setHasUnsavedChanges(true);
  },[ documentValues, order ])


  useEffect(() => {
  if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){
  
      existingPayload = true;
  
      if(documentPayload.reading_date){
        documentPayload.reading_date = moment.utc(documentPayload.reading_date).format("YYYY-MM-DD");
      }

      if(documentPayload.delivery_date){
        documentPayload.delivery_date = moment.utc(documentPayload.delivery_date).format("YYYY-MM-DD");
      }

      if(documentPayload.dob){
        documentPayload.dob = moment.utc(documentPayload.dob).format("YYYY-MM-DD");
      }

      if(documentPayload.joint_dob){
        documentPayload.joint_dob = moment.utc(documentPayload.joint_dob).format("YYYY-MM-DD");
      }

      if(documentPayload.joint_dob_2){
        documentPayload.joint_dob_2 = moment.utc(documentPayload.joint_dob_2).format("YYYY-MM-DD");
      }

      if(documentPayload.security_dob){
        documentPayload.security_dob = moment.utc(documentPayload.security_dob).format("YYYY-MM-DD");
      }

      if(documentPayload.secondary_dob){
        documentPayload.secondary_dob = moment.utc(documentPayload.secondary_dob).format("YYYY-MM-DD");
      }

      if(documentPayload.lessor_dob){
        documentPayload.lessor_dob = moment.utc(documentPayload.lessor_dob).format("YYYY-MM-DD");
      }


      // Loading Use Effect
      if(document.additionalData){
        const methodMap = { 
          businessAddress: setSelectedBusinessAddress, 
          mailingAddress: setSelectedMailingAddress, 
          oneTimeMailingAddress: setSelectedOneTimeMailingAddress, 
          vehicleAddress: setSelectedVehicleAddress, 
          securityAddress: setSelectedSecurityAddress, 
          secondaryAddress: setSelectedSecondaryAddress, 
          lessorAddress: setSelectedLessorAddress
        };

        let parsedAdditionalData = JSON.parse(document.additionalData);
        Object.keys(parsedAdditionalData).forEach(function(key) {
          if(methodMap[key]){
            methodMap[key](parsedAdditionalData[key]);
          }
        });
      }

      
      setDocumentValues(documentPayload);
    }

    var foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }

    setAddressOptions(getOrderLevelAddresses(order));
    if(foundAssignedProduct){
        setAssignedProduct(foundAssignedProduct);
        setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
    }

  
      if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
      }
      
      setDataLoaded(true);
    }
  },[ documentValues, document, dataLoaded, AutofillProductDetails, GlobalAutofill, order, previewPayloadDocument ])



    
  //   function streamDocument(){
  //     axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
  //     .then(response => {
  //       const file = new Blob(
  //         [response.data], 
  //         {type: 'application/pdf'});
  //         const fileURL = URL.createObjectURL(file);
  //         // window.open(fileURL);
  //       setdocumentUrl(fileURL)
  //     }).catch(err => {
  //     })
  // }

  const updatePreview = e => {
    e.preventDefault();
    // updateFormatting(e);
    previewPayloadDocument(documentValues);
  }


  const handleSubmit = async event => {
    event.preventDefault();



    let payload = JSON.parse(JSON.stringify(documentValues));

    payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);


  //   var docCopy = document;
  //   docCopy.payload = payload;
  //   docCopy.payload = preProcessPayload(docCopy.payload);
  //   docCopy.payload =  JSON.stringify(docCopy.payload);
  //   delete docCopy.documentStatus;
    
  //   // if(selectedProduct && selectedProduct.id){
  //   //   docCopy.itemIds = `${selectedProduct.id}`;
  //   // }

    //Save Dropdown Selections
    let dropdownData = {};
    if(selectedBusinessAddress){
      dropdownData.businessAddress = {
        id: selectedBusinessAddress.id,
        name: selectedBusinessAddress.name
      };
    }
    if(selectedMailingAddress){
      dropdownData.mailingAddress = {
        id: selectedMailingAddress.id,
        name: selectedMailingAddress.name
      };
    }
    if(selectedOneTimeMailingAddress){
      dropdownData.oneTimeMailingAddress = {
        id: selectedOneTimeMailingAddress.id,
        name: selectedOneTimeMailingAddress.name
      };
    }
    if(selectedVehicleAddress){
      dropdownData.vehicleAddress = {
        id: selectedVehicleAddress.id,
        name: selectedVehicleAddress.name
      };
    }
    if(selectedSecurityAddress){
      dropdownData.securityAddress = {
        id: selectedSecurityAddress.id,
        name: selectedSecurityAddress.name
      };
    }
    if(selectedSecondaryAddress){
      dropdownData.secondaryAddress = {
        id: selectedSecondaryAddress.id,
        name: selectedSecondaryAddress.name
      };
    }
    if(selectedLessorAddress){
      dropdownData.lessorAddress = {
        id: selectedLessorAddress.id,
        name: selectedLessorAddress.name
      };
    }
    let additionalData = JSON.stringify(dropdownData);


    DocumentHandleSubmit(document, payload, additionalData, () => {
      getDocuments();
      setSaveSuccess(true);
      setHasUnsavedChanges(false);
    });

  //   try {
  //     let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
  //     if (res) {
  //       await axios.post(`/document/${document.id}/generate`) 
  //       getDocuments();
  //       setSaveSuccess(true);
  //       setHasUnsavedChanges(false);
  //     }
  //   }catch (err) {
  //     recordFrontEndError('OregonTitleModal.jsx', 'Error saving document: ' + err)
  //   };
    
  // };
  }

  //Autofill Product Details After Selection
// if(selectedProduct !== undefined && productToggleAutoFill){



  //Autofill Tradein Details After Selection
// if(selectedTradein !== undefined && tradeinToggleAutoFill){
//   var newValues = documentValues;
//   newValues.trade_year = (selectedTradein.year);
//   newValues.trade_make = (selectedTradein.make);
//   newValues.trade_model = (selectedTradein.model);
//   newValues.trade_body_type = (selectedTradein.seriesBodyType);
//   newValues.trade_serial_number = (selectedTradein.serialNumber);
//   newValues.balance_owner = (order.customer.name); //Need specific field for TRADE IN OWNER?
//   newValues.balance_owner_address = (order.customer.deliveryAddress.addressLine1);
//   newValues.amount = (selectedTradein.balanceOwed);
//   newValues.trade_allowance = (selectedTradein.tradeAllowanceAtTerms);
//   newValues.balance_owed = (selectedTradein.balanceOwed);
//   newValues.cash_refund = (selectedTradein.cashRefundToCustomer);
//   newValues.net_trade_allowance = (selectedTradein.finalTradeAllowance);
//   // newValues.cash_down_payment = (selectedTradein);

//   setDocumentValues(newValues);
//   setTradeinToggleAutoFill(false);
//   previewPayloadDocument(documentValues);
// }

//Autofill Address Details After Selection
function AutoFillAddress(addressSelected, selectionInstance){
  var newValues = documentValues;
  if(selectionInstance){
    if(selectionInstance === "business-address"){
      newValues.address = addressSelected.address;
      newValues.city_state_zip = (addressSelected.city+", "+addressSelected.state+" "+addressSelected.zip);
    }

    if(selectionInstance === "mailing-address"){
      newValues.mailing_address = addressSelected.address;
      newValues.mailing_city_state_zip = (addressSelected.city+", "+addressSelected.state+" "+addressSelected.zip);
    }

    if(selectionInstance === "one-time-address"){
      newValues.one_time_mailing_address = addressSelected.address;
      newValues.one_time_mailing_city_state_zip = (addressSelected.city+", "+addressSelected.state+" "+addressSelected.zip);
    }

    if(selectionInstance === "vehicle-address"){
      newValues.vehicle_address = addressSelected.address;
      newValues.vehicle_city_state_zip = (addressSelected.city+", "+addressSelected.state+" "+addressSelected.zip);
    }

    if(selectionInstance === "security-address"){
      newValues.security_address_city_state_zip = addressSelected.address+" "+(addressSelected.city+", "+addressSelected.state+" "+addressSelected.zip);
    }

    if(selectionInstance === "secondary-address"){
      newValues.secondary_address_city_state_zip = addressSelected.address+" "+(addressSelected.city+", "+addressSelected.state+" "+addressSelected.zip);
    }

    if(selectionInstance === "lessor-address"){
      newValues.lessor_address_city_state_zip = addressSelected.address+" "+(addressSelected.city+", "+addressSelected.state+" "+addressSelected.zip);
    }

    // if(addressInstance === "owner-lessee-address"){
      
    // }
  }



  setDocumentValues(newValues);
  previewPayloadDocument(documentValues);
  // setAddressInstance(undefined);
}

const onCloseClick = e => {
  e.preventDefault()
  setDataLoaded(false);
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setAssignedProduct(undefined);
  setdocumentUrl("");
  setDocumentFocus(undefined);
  setDocument(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
  setinputAddressVal(undefined);
  setAddressOptions(undefined);
  setHasClickedClose(false);
  setSelectedBusinessAddress(undefined);
  setSelectedMailingAddress(undefined);
  setSelectedOneTimeMailingAddress(undefined);
  setSelectedVehicleAddress(undefined);
  setSelectedSecurityAddress(undefined);
  setSelectedSecondaryAddress(undefined);
  setSelectedLessorAddress(undefined);
  setinputAddressVal(undefined);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

  return (
    <>
      <div
        className="modal fade"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                Oregon Title Application
              </h5>
              <br />
              { hasClickedClose ? (
                <div className="d-flex">
                    <p className="m-1 pt-1 text-white">Save Changes?</p>
                    <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                    <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                </div>
              ): hasUnsavedChanges ? (
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  onClick={closeClicked}
                ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>

            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill();
                      AutofillProductDetails(assignedProduct);
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>
                  <br />

                  { assignedProduct ? (
                  <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                    Product Snapshot
                    <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                      <h5>SN: { assignedProduct.stockNumber }</h5>
                      <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                      <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                    </div>
                  </div>
                ):(
                  <div className="m-2 p-2 border">
                    <span>No Assigned Product</span>
                  </div>
                )}

                {/* <div className="row">
                  <h5>Addresses</h5>
                  <div className="d-flex justify-content-evenly">
                    <div className="mb-3 col-md-12 pt-4">
                      <Autocomplete
                        style={{ width: '100%'}}
                        value={ (selectedAddress && selectedAddress.name) ?? null}
                        onChange={(event, newValue) => {
                          setSelectedAddress(newValue);
                          // setselectedProduct(order.products.find((product) => product.id === newValue.id));
                          setAddressToggleAutoFill(true);
                          // resetVariables();
                        }}
                        inputValue={inputAddressVal}
                        onInputChange={(event, newInputValue) => {
                          setinputAddressVal(newInputValue);
                        }}
                        //id="controllable-states-demo"
                        options={addressOptions}
                        sx={{ width: 400 }}
                        renderInput={(params) => <TextField {...params} label="ADDRESS" />}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.name}
                            </li>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div> */}

                  <div className="row">
                    <h5>Vehicle Information</h5>
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="p-2 col-6">
                        <label htmlFor="stock"><b>1</b> - VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>
                      <div className="p-2 col-3">
                        <label htmlFor="stock">Oregon Title #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="oregon_title_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.oregon_title_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-3">
                        <label htmlFor="stock">GVWR</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="gvwr"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.gvwr ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-4">
                        <label htmlFor="stock"><b>2</b> - Present Oregon Plate #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="present_oregon_license_plate"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.present_oregon_license_plate ?? '' }
                          />
                      </div>
                      <div className="p-2 col-4">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year ?? '' }
                          />
                      </div>
                      <div className="p-2 col-4">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="p-2 col-3">
                        <label htmlFor="stock">Style</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="style"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.style ?? '' }
                          />
                      </div>
                      <div className="p-2 col-3">
                        <label htmlFor="stock">Reg Weight / Length</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="weight_length"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.weight_length ?? '' }
                          />
                      </div>
                      <div className="p-2 col-6">
                        <label htmlFor="customerName" className="form-label">
                        Trailer Over 8 1/2 Feet Wide
                        </label>
                        <br />
                        <FormControl className="px-3 pt-2">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="width_exceeded"
                            value={ (documentValues.width_exceeded ?? "" )}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel className="px-3" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel className="px-3" value="no" control={<Radio />} label="No" />
                            <FormControlLabel className="px-3" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-6">
                        <label htmlFor="stock"><b>3</b> - Farm ID #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="farm_id_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.farm_id_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-6">
                        <label htmlFor="stock">Fleet Account #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="fleet_account_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.fleet_account_number ?? '' }
                          />
                      </div>
                    </div>
                    
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="p-2 col-6">
                        <label htmlFor="stock">Equipment #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="equipment_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.equipment_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-6">
                        {/* <label htmlFor="stock">weight_exceeded [Radio Yes/No] - Trailer Over 8,000 LBS.</label> */}
                      
                        <label htmlFor="customerName" className="form-label">Trailer Over 8,000 LBS.</label>
                        <br />
                        <FormControl className="px-3 pt-2">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="weight_exceeded"
                            value={(documentValues.weight_exceeded ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel className="px-3" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel className="px-3" value="no" control={<Radio />} label="No" />
                            <FormControlLabel className="px-3" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="p-2 col-12">
                        <label htmlFor="customerName" className="form-label">
                        Fuel Type Selection                      </label>
                        <br />
                        <FormControl className="px-3 pt-2">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="fuel_toggle"
                            value={(documentValues.fuel_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel className="p-3" value="gas" control={<Radio />} label="Gas" />
                            <FormControlLabel className="p-3" value="diesel" control={<Radio />} label="Diesel" />
                            <FormControlLabel className="p-3" value="hybrid" control={<Radio />} label="Hybrid" />
                            <FormControlLabel className="p-3" value="electric" control={<Radio />} label="Electric" />
                            <FormControlLabel className="p-3" value="propane" control={<Radio />} label="Propane" />
                            <FormControlLabel className="p-3" value="natural_gas" control={<Radio />} label="Natural Gas" />
                            <FormControlLabel className="p-3" value="plug_in_hybrid" control={<Radio />} label="Plug-in Hybrid" />
                            <FormControlLabel className="p-3" value="flex_fuel" control={<Radio />} label="Flex Fuel" />
                            <FormControlLabel className="p-3" value="other" control={<Radio />} label="Other" />
                            <FormControlLabel className="p-3" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>

                    { documentValues.fuel_toggle === "other" ?(
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="p-2 col-12">
                        <label htmlFor="stock">Other</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="other_text"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.other_text ?? '' }
                          />
                      </div>
                    </div>
                    ):null}
                    
                    <hr></hr>



                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="p-2 col-4">
                        <label htmlFor="stock"><b>4</b> - Odometer Reading (No Tenths)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="odo_reading"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.odo_reading ?? '' }
                          />
                      </div>
                      <div className="p-2 col-3">
                        <label htmlFor="stock">Date of Reading</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="reading_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.reading_date ?? '' }
                          />
                      </div>
                      <div className="p-2 col-5">
                        {/* <label htmlFor="stock">mileage_excess [Checkbox] - "The mileage stated..."</label>
                        <label htmlFor="stock">mileage_incorrect [Checkbox] - "The Odometer Reading..."</label> */}
                      
                        <label htmlFor="customerName" className="form-label">
                        Verify Odometer Reading                       </label>
                        <br />
                        <FormControl className="px-3 pt-2">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="mileage_excess"
                            value={documentValues.mileage_excess === "yes" ? "yes" : "no"}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel className="px-3" value="yes" control={<Radio />} label="The mileage stated..." />
                            <FormControlLabel className="px-3" value="no" control={<Radio />} label="The odometer reading..." />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>

                  </div>
                  <hr></hr>
                  
                  <h4>Owner or Lessee / Address: Sections 5 - 11</h4>
                  <br />

                  <div className="row">
                  <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-6">
                        <label htmlFor="stock"><b>5</b> - PRINT FULL LEGAL NAME: LAST, FIRST, MIDDLE</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-6">
                        {/* <label htmlFor="stock">owner_checked/lessee_checked [Raido - Owner/Lessee]</label> */}

                        <label htmlFor="customerName" className="form-label">
                        Owner / Lessee                        </label>
                        <br />
                        <FormControl className="px-3 pt-2">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="owner_checked"
                            value={documentValues.owner_checked === "yes" ? "yes" : "no"}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel className="px-3" value="yes" control={<Radio />} label="Owner" />
                            <FormControlLabel className="px-3" value="no" control={<Radio />} label="Lessee" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="p-2 col-6">
                        <label htmlFor="stock">ODL / ID / Customer #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="odl_customer_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.odl_customer_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-6">
                        <label htmlFor="stock">DATE OF BIRTH (MM/DD/YY)</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="dob"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dob ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>


                  <div className="row ml-3">
                  <h5>Residence / Business Address Selection</h5>
                  { addressOptions && addressOptions.length > 0 ? (
                  <div className="d-flex justify-content-evenly pb-2">
                    <div className="mb-3 col-md-12 pt-4">
                      <Autocomplete
                        style={{ width: '100%'}}
                        value={ (selectedBusinessAddress && selectedBusinessAddress.name) ?? null}
                        name="business-address"
                        onChange={(event, newValue) => {
                          setSelectedBusinessAddress(newValue);
                          AutoFillAddress(newValue, "business-address");
                          // setAddressToggleAutoFill(true);
                        }}
                        inputValue={inputAddressVal}
                        onInputChange={(event, newInputValue) => {
                          setinputAddressVal(newInputValue);
                        }}
                        //id="controllable-states-demo"
                        options={addressOptions}
                        sx={{ width: 400 }}
                        renderInput={(params) => <TextField {...params} label="ADDRESS" />}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.name}
                            </li>
                          );
                        }}
                      />
                    </div>
                  </div>):(
                    <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Addresses Found</h5>
                    </div>
                  )}

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-12">
                      <label htmlFor="stock"><b>6</b> - Residence / Business Address</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="address"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.address ?? '' }
                        />
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-6">
                      <label htmlFor="stock"><b>7</b> - CITY, STATE, ZIP</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="city_state_zip"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.city_state_zip ?? '' }
                        />
                    </div>
                    <div className="p-2 col-6">
                        <label htmlFor="stock">COUNTY OF RESIDENCE</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="county"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.county ?? '' }
                          />
                      </div>
                  </div>
                </div>
                <hr></hr>

                <div className="row">
                  <h5>Mailing Address Selection</h5>
                  { addressOptions && addressOptions.length > 0 ? (

                  <div className="d-flex justify-content-evenly">
                    <div className="mb-3 col-md-12 pt-4">
                      <Autocomplete
                        style={{ width: '100%'}}
                        value={ (selectedMailingAddress && selectedMailingAddress.name) ?? null}
                        name="mailing-address"
                        onChange={(event, newValue) => {
                          setSelectedMailingAddress(newValue);
                          AutoFillAddress(newValue, "mailing-address");
                          // setAddressToggleAutoFill(true);
                        }}
                        inputValue={inputAddressVal}
                        onInputChange={(event, newInputValue) => {
                          setinputAddressVal(newInputValue);
                        }}
                        //id="controllable-states-demo"
                        options={addressOptions}
                        sx={{ width: 400 }}
                        renderInput={(params) => <TextField {...params} label="ADDRESS" />}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.name}
                            </li>
                          );
                        }}
                      />
                    </div>
                  </div>):(
                    <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Addresses Found</h5>
                    </div>
                  )}

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-12">
                      <label htmlFor="stock"><b>6</b> - Mailing Address</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="mailing_address"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.mailing_address ?? '' }
                        />
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-6">
                      <label htmlFor="stock"><b>7</b> - CITY, STATE, ZIP</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="mailing_city_state_zip"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.mailing_city_state_zip ?? '' }
                        />
                    </div>
                    <div className="p-2 col-6">
                        <label htmlFor="stock">COUNTY OF MAILING</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_county"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_county ?? '' }
                          />
                      </div>
                  </div>
                </div>
                <hr></hr>

                <div className="row">

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                      <label htmlFor="stock"><b>8</b> - JOINT OWNER OR LESSEE - Print Full Name: LAST, FIRST, MIDDLE</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="joint_name"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.joint_name ?? '' }
                        />
                    </div>
                  </div>
                  <div className="d-flex justify-content-evenly pb-2">
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">ODL / ID / CUSTOMER #</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="joint_odl_customer_number"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.joint_odl_customer_number ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">DATE OF BIRTH (MM/DD/YYYY)</label>
                      <input
                        type="date"
                        className="form-control rounded-pill mt-1"
                        name="joint_dob"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.joint_dob ?? '' }
                        />
                    </div>
                  </div>
                </div>
                <hr></hr>

                <div className="row">                   
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                      <label htmlFor="stock"><b>9</b> - JOINT OWNER OR LESSEE - Print Full Name: LAST, FIRST, MIDDLE</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="joint_name_2"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.joint_name_2 ?? '' }
                        />
                    </div>
                  </div>
                  <div className="d-flex justify-content-evenly pb-2">
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">ODL / ID / CUSTOMER #</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="joint_odl_customer_number_2"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.joint_odl_customer_number_2 ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">DATE OF BIRTH (MM/DD/YYYY)</label>
                      <input
                        type="date"
                        className="form-control rounded-pill mt-1"
                        name="joint_dob_2"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.joint_dob_2 ?? '' }
                        />
                    </div>
                  </div>
                </div>
                <hr></hr>
                
                <div className="row">
                  <h5>One Time Mailing Address Selection</h5>
                  { addressOptions && addressOptions.length > 0 ? (
                  <div className="d-flex justify-content-evenly pb-2">
                    <div className="mb-3 col-md-12 pt-4">
                      <Autocomplete
                        style={{ width: '100%'}}
                        value={ (selectedOneTimeMailingAddress && selectedOneTimeMailingAddress.name) ?? null}
                        name="mailing-address"
                        onChange={(event, newValue) => {
                          setSelectedOneTimeMailingAddress(newValue);
                          AutoFillAddress(newValue, "one-time-address");
                          // setAddressToggleAutoFill(true);
                        }}
                        inputValue={inputAddressVal}
                        onInputChange={(event, newInputValue) => {
                          setinputAddressVal(newInputValue);
                        }}
                        //id="controllable-states-demo"
                        options={addressOptions}
                        sx={{ width: 400 }}
                        renderInput={(params) => <TextField {...params} label="ADDRESS" />}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.name}
                            </li>
                          );
                        }}
                      />
                    </div>
                  </div>
                  ):(
                    <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Addresses Found</h5>
                    </div>
                  )}

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-6">
                      <label htmlFor="stock"><b>10</b> - ONE-TIME Mailing Address</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="one_time_mailing_address"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.one_time_mailing_address ?? '' }
                        />
                    </div>
                    <div className="p-2 col-6">
                      <label htmlFor="customerName" className="form-label">Reg. Only / Title Only / Both</label>
                        <br />
                        <FormControl className="px-3 pt-2">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="reg_title_both"
                            value={(documentValues.reg_title_both ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel className="px-2" value="reg" control={<Radio />} label="Reg. Only" />
                            <FormControlLabel className="px-2" value="title" control={<Radio />} label="Title Only" />
                            <FormControlLabel className="px-2" value="both" control={<Radio />} label="Both" />
                            <FormControlLabel className="px-2" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-6">
                      <label htmlFor="stock"><b>11</b> - CITY, STATE, ZIP</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="one_time_mailing_city_state_zip"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.one_time_mailing_city_state_zip ?? '' }
                        />
                    </div>
                    <div className="p-2 col-6"></div>
                    {/* <div className="p-2 col-6">
                        <label htmlFor="stock">COUNTY OF RESIDENCE</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="county"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_county ?? '' }
                          />
                      </div> */}
                  </div>
                </div>
                <hr></hr>

                <div className="row">
                  <h5>Vehicle Address Selection</h5>
                  { addressOptions && addressOptions.length > 0 ? (
                  <div className="d-flex justify-content-evenly pb-2">
                    <div className="mb-3 col-md-12 pt-4">
                      <Autocomplete
                        style={{ width: '100%'}}
                        value={ (selectedVehicleAddress && selectedVehicleAddress.name) ?? null}
                        name="vehicle-address"
                        onChange={(event, newValue) => {
                          setSelectedVehicleAddress(newValue);
                          AutoFillAddress(newValue, "vehicle-address");
                          // setAddressToggleAutoFill(true);
                        }}
                        inputValue={inputAddressVal}
                        onInputChange={(event, newInputValue) => {
                          setinputAddressVal(newInputValue);
                        }}
                        //id="controllable-states-demo"
                        options={addressOptions}
                        sx={{ width: 400 }}
                        renderInput={(params) => <TextField {...params} label="ADDRESS" />}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.name}
                            </li>
                          );
                        }}
                      />
                    </div>
                  </div>):(
                    <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Addresses Found</h5>
                    </div>
                  )}

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-12">
                      <label htmlFor="stock"><b>10</b> - Vehicle Address</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="vehicle_address"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.vehicle_address ?? '' }
                        />
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-6">
                      <label htmlFor="stock"><b>11</b> - CITY, STATE, ZIP</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="vehicle_city_state_zip"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.vehicle_city_state_zip ?? '' }
                        />
                    </div>
                    <div className="p-2 col-6">
                        <label htmlFor="stock">COUNTY (of vehicle address or use)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vehicle_county"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vehicle_county ?? '' }
                          />
                    </div>
                  </div>
                  <hr></hr>


                  <div className="row">
                    <label htmlFor="stock"><b>12</b> - Survivorship</label>

                    <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                      <label htmlFor="stock">Joint Owners or Lessees agree that title will show joint ownership with right of survivorship.</label>
                      <br />
                      <FormControl className="px-3 pt-2">
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="joint_owners_agree_ros"
                          value={ (documentValues.joint_owners_agree_ros ?? "" )}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                        >
                          <FormControlLabel className="px-3" value="yes" control={<Radio />} label="Yes" />
                          <FormControlLabel className="px-3" value="no" control={<Radio />} label="No" />
                          <FormControlLabel className="px-3" value="" control={<Radio />} label="None" />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                  <div className="d-flex justify-content-evenly pb-2">
                    <div className="p-2 col-md-12">
                    <label htmlFor="stock">Joint Security Interest Holders agree that title will show join security interest with right of survivorship.</label>
                      <br />
                      <FormControl className="px-3 pt-2">
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="security_agree_ros"
                          value={ (documentValues.security_agree_ros ?? "" )}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                        >
                          <FormControlLabel className="px-3" value="yes" control={<Radio />} label="Yes" />
                          <FormControlLabel className="px-3" value="no" control={<Radio />} label="No" />
                          <FormControlLabel className="px-3" value="" control={<Radio />} label="None" />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">                   
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                      <label htmlFor="stock"><b>13</b> - Security Interest Holder</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="security_name"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.security_name ?? '' }
                        />
                    </div>
                  </div>
                  <div className="d-flex justify-content-evenly pb-2">
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">ODL / ID / CUSTOMER #</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="security_odl_number"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.security_odl_number ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">DATE OF BIRTH (MM/DD/YYYY)</label>
                      <input
                        type="date"
                        className="form-control rounded-pill mt-1"
                        name="security_dob"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.security_dob ?? '' }
                        />
                    </div>
                  </div>
                </div>
                <hr></hr>

                <div className="row">
                { addressOptions && addressOptions.length > 0 ? (
                  <div className="d-flex justify-content-evenly pb-2">
                    <div className="mb-3 col-md-12 pt-4">
                      <Autocomplete
                        style={{ width: '100%'}}
                        value={ (selectedSecurityAddress && selectedSecurityAddress.name) ?? null}
                        name="security-address"
                        onChange={(event, newValue) => {
                          setSelectedSecurityAddress(newValue);
                          AutoFillAddress(newValue, "security-address");
                          // setAddressToggleAutoFill(true);
                        }}
                        inputValue={inputAddressVal}
                        onInputChange={(event, newInputValue) => {
                          setinputAddressVal(newInputValue);
                        }}
                        //id="controllable-states-demo"
                        options={addressOptions}
                        sx={{ width: 400 }}
                        renderInput={(params) => <TextField {...params} label="Security Interest Holder Address Selection" />}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.name}
                            </li>
                          );
                        }}
                      />
                    </div>
                  </div>):(
                    <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Addresses Found</h5>
                    </div>
                  )}

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-12">
                      <label htmlFor="stock"><b>14</b> - Security Interest Holder Address - Include Street / City / State / Zip Code</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="security_address_city_state_zip"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.security_address_city_state_zip ?? '' }
                        />
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-6">
                      <label htmlFor="stock">Area Code</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="security_area_code"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.security_area_code ?? '' }
                        />
                    </div>
                    <div className="p-2 col-6">
                      <label htmlFor="stock">Telephone Number</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="security_phone"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.security_phone ?? '' }
                        />
                    </div>
                  </div>
                </div>
                <hr></hr>

                <div className="row">                   
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                      <label htmlFor="stock"><b>15</b> - Secondary Interest Holder</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="secondary_name"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.secondary_name ?? '' }
                        />
                    </div>
                  </div>
                  <div className="d-flex justify-content-evenly pb-2">
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">ODL / ID / CUSTOMER #</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="secondary_odl_number"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.secondary_odl_number ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">DATE OF BIRTH (MM/DD/YYYY)</label>
                      <input
                        type="date"
                        className="form-control rounded-pill mt-1"
                        name="secondary_dob"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.secondary_dob ?? '' }
                        />
                    </div>
                  </div>
                </div>
                <hr></hr>

                <div className="row">
                { addressOptions && addressOptions.length > 0 ? (
                  <div className="d-flex justify-content-evenly pb-2">
                    <div className="mb-3 col-md-12 pt-4">
                      <Autocomplete
                        style={{ width: '100%'}}
                        value={ (selectedSecondaryAddress && selectedSecondaryAddress.name) ?? null}
                        name="secondary-address"
                        onChange={(event, newValue) => {
                          setSelectedSecondaryAddress(newValue);
                          AutoFillAddress(newValue, "secondary-address");
                          // setAddressToggleAutoFill(true);
                        }}
                        inputValue={inputAddressVal}
                        onInputChange={(event, newInputValue) => {
                          setinputAddressVal(newInputValue);
                        }}
                        //id="controllable-states-demo"
                        options={addressOptions}
                        sx={{ width: 400 }}
                        renderInput={(params) => <TextField {...params} label="Secondary Interest Holder Address Selection" />}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.name}
                            </li>
                          );
                        }}
                      />
                    </div>
                  </div>):(
                    <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Addresses Found</h5>
                    </div>
                  )}

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-12">
                      <label htmlFor="stock"><b>16</b> - Secondary Interest Holder Address - Include Street / City / State / Zip Code</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="secondary_address_city_state_zip"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.secondary_address_city_state_zip ?? '' }
                        />
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-6">
                      <label htmlFor="stock">Area Code</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="secondary_area_code"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.secondary_area_code ?? '' }
                        />
                    </div>
                    <div className="p-2 col-6">
                      <label htmlFor="stock">Telephone Number</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="secondary_phone"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.secondary_phone ?? '' }
                        />
                    </div>
                  </div>
                </div>
                <hr></hr>

                <div className="row">                   
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                      <label htmlFor="stock"><b>17</b> - Lessor</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="lessor_name"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.lessor_name ?? '' }
                        />
                    </div>
                  </div>
                  <div className="d-flex justify-content-evenly pb-2">
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">ODL / ID / CUSTOMER #</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="lessor_odl_number"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.lessor_odl_number ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">DATE OF BIRTH (MM/DD/YYYY)</label>
                      <input
                        type="date"
                        className="form-control rounded-pill mt-1"
                        name="lessor_dob"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.lessor_dob ?? '' }
                        />
                    </div>
                  </div>
                </div>
                <hr></hr>

                <div className="row">
                { addressOptions && addressOptions.length > 0 ? (
                  <div className="d-flex justify-content-evenly pb-2">
                    <div className="mb-3 col-md-12 pt-4">
                      <Autocomplete
                        style={{ width: '100%'}}
                        value={ (selectedLessorAddress && selectedLessorAddress.name) ?? null}
                        name="lessor-address"
                        onChange={(event, newValue) => {
                          setSelectedLessorAddress(newValue);
                          AutoFillAddress(newValue, "lessor-address");
                          // setAddressToggleAutoFill(true);
                        }}
                        inputValue={inputAddressVal}
                        onInputChange={(event, newInputValue) => {
                          setinputAddressVal(newInputValue);
                        }}
                        //id="controllable-states-demo"
                        options={addressOptions}
                        sx={{ width: 400 }}
                        renderInput={(params) => <TextField {...params} label="Lessor Address Selection" />}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.name}
                            </li>
                          );
                        }}
                      />
                    </div>
                  </div>):(
                    <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Addresses Found</h5>
                    </div>
                  )}

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-12">
                      <label htmlFor="stock"><b>18</b> - Lessor Address - Include Street / City / State / Zip Code</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="lessor_address_city_state_zip"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.lessor_address_city_state_zip ?? '' }
                        />
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-6">
                      <label htmlFor="stock">Area Code</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="lessor_area_code"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.lessor_area_code ?? '' }
                        />
                    </div>
                    <div className="p-2 col-6">
                      <label htmlFor="stock">Telephone Number</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="lessor_phone_number"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.lessor_phone_number ?? '' }
                        />
                    </div>
                  </div>
                </div>
                <hr></hr>

                <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock"><b>20</b> - Date of Owner or Lessee Signature</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="delivery_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.delivery_date ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                      </div>                    
                      </div>
<hr/>
                </div>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
            { saveSuccess ? (
              <span
                className="btn btn-success mx-auto"
              >
                Saved Document
              </span>
            ):(
              <button
              type="button"
              className="btn btn-primary mx-auto"
              // data-bs-dismiss="modal"
              onClick={handleSubmit}
              >
              Save
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default OregonTitleModal

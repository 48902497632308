import React from 'react';
import { useSelector } from "react-redux";


const FinanceCurrentOrders = () => {

  const financeDashboardState =
    useSelector((state) => state?.financeDashboardState.stats);

    return (
    <div className="row mx-2 mt-2">
      <div className="col-12 ">
          <p className="m-0">Current Orders</p>
        <div className="p-2 shadow rounded mt-1" style={{backgroundColor:'#08954E', color: 'white'}}>
          <div className="text-uppercase d-flex justify-content-between mx-3 text-center">
            <div className="p-1">
              <p className="m-0">Quotes</p>
              {financeDashboardState && <h1>{financeDashboardState.currentOrders.quotes}</h1> }
            </div>
            <div className="p-1">
              <p className="m-0">Manager Review</p>
              {financeDashboardState && <h1>{financeDashboardState.currentOrders.managerReview}</h1> }
            </div>
            <div className="p-1">
              <p className="m-0">Financing Review</p>
              {financeDashboardState && <h1>{financeDashboardState.currentOrders.financingReview}</h1> }
            </div>
            <div className="p-1">
              <p className="m-0">Credit Approval</p>
              {financeDashboardState && <h1>{financeDashboardState.currentOrders.creditApproval}</h1> }
            </div>
            <div className="p-1">
              <p className="m-0">Document Requests</p>
              {financeDashboardState && <h1>{financeDashboardState.currentOrders.documentRequests}</h1> }
            </div>
            <div className="p-1">
              <p className="m-0">Funded</p>
              {financeDashboardState && <h1>{financeDashboardState.currentOrders.funded}</h1> }
            </div>
            <div className="p-1">
              <p className="m-0">Booked</p>
              {financeDashboardState && <h1>{financeDashboardState.currentOrders.booked}</h1> }
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}

export default FinanceCurrentOrders



import React, { useState, useEffect, useMemo, useCallback } from "react";
// import { useDispatch, useSelector } from "react-redux";
import {
  useParams
} from "react-router-dom";
import { useFetch,recordFrontEndError } from "../../../../../../../utlis/api";
// import States from '../States';
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
// import FormLabel from '@mui/material/FormLabel';
import { removeChars, formatter, formatPhoneNumber, formatUpperCase } from "../../../../../../../utlis/formatters";
import { getAllNames, getOrderLevelAddresses, updateAddressOptions } from "../../../../../../../utlis/documentHelpers";
import { useOrder } from "../../../../../../../redux/orders/order"
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { CATitleApp343GlobalAutofill, CATitleApp343AutofillProductDetails, preProcessPayload } from "../DocumentAutofill/CATitleApp343"

const CATitleApp343 = ({document_id, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "ca-title-v2";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  // const dispatch = useDispatch();

  // const stateAbbrev = States.map(state => {
  //   return state.abbreviation;
  // });

  const order = useOrder();

  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");


  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);
  // const [ locations, setLocations ] = useState([]);//Redux
  const [ nameOptions, setNameOptions ] = useState([]);//Redux

  // Data Structure & Init //
  
  var initialDocumentStructure = {
    trailer_length: undefined, 
    trailer_width: undefined, 
    ca_license_number: undefined, 
    model: undefined, 
    body_type: undefined, 
    make: undefined, 
    fuel_type: undefined, 
    mc_engine_number: undefined, 
    commercial_use: undefined, 
    overweight: undefined, 
    axels: undefined, 
    unladen_weight: undefined, 
    weight_actual: undefined, 
    weight_estimated: undefined, 
    type_auto: undefined, 
    type_commercial: undefined, 
    type_motorcycle: undefined, 
    type_off_highway: undefined, 
    type_trailer_coach: undefined, 
    owner_and: undefined, 
    owner_and_2: undefined, 
    equipment_number: undefined, 
    owner_state: undefined, 
    owner_state_2: undefined, 
    owner_state_3: undefined, 
    owner_id: undefined, 
    owner_id_2: undefined, 
    owner_id_3: undefined, 
    address_line_1: undefined, 
    address_line_2: undefined, 
    city: undefined, 
    state: undefined, 
    zip: undefined, 
    county: undefined, 
    mailing_address_line_1: undefined, 
    mailing_address_line_2: undefined, 
    mailing_city: undefined, 
    mailing_state: undefined, 
    mailing_zip: undefined, 
    lessee_zip: undefined, 
    lessee_state: undefined, 
    lessee_city: undefined, 
    lessee_address_line_2: undefined, 
    lessee_address_line_1: undefined, 
    trailer_coach_address: undefined, 
    trailer_coach_city: undefined, 
    trailer_coach_state: undefined, 
    trailer_coach_zip: undefined, 
    elt: undefined, 
    lien_owner_name: undefined, 
    lien_address_line_1: undefined, 
    lien_mailing_address_line_1: undefined, 
    lien_address_line_2: undefined, 
    lien_mailing_address_line_2: undefined, 
    lien_city: undefined, 
    lien_state: undefined, 
    lien_mailing_state: undefined, 
    lien_mailing_city: undefined, 
    lien_zip: undefined, 
    lien_mailing_zip: undefined, 
    odo_upon_purchase: undefined, 
    odo_as_of_date: undefined, 
    odo_mi: undefined, 
    odo_km: undefined, 
    odo_1: undefined, 
    odo_2: undefined, 
    odo_3: undefined, 
    odo_4: undefined, 
    odo_5: undefined, 
    odo_6: undefined, 
    mileage_exceeded: undefined, 
    odo_discrepancy_reason: undefined, 
    odo_reading_invalid: undefined, 
    vin: undefined,  //
    copy_vin: undefined, 
    copy_make: undefined, 
    copy_year_model: undefined, 
    year_model: undefined, 
    ca_entry_month: undefined, 
    ca_entry_day: undefined, 
    ca_entry_year: undefined, 
    ca_operation_month: undefined, 
    ca_operation_day: undefined, 
    ca_operation_year: undefined, 
    ca_residency_month: undefined, 
    ca_residency_day: undefined, 
    ca_residency_year: undefined, 
    purchase_month: undefined, 
    purchase_day: undefined, 
    purchase_year: undefined, 
    purchased_new: undefined, 
    purchased_used: undefined, 
    purchased_ca_inside: undefined, 
    purchased_ca_outside: undefined, 
    no_ca_residency: undefined, 
    entry_unowned_vehicle: undefined, 
    purchase: undefined, 
    gift: undefined, 
    trade: undefined, 
    purchase_price: undefined, 
    trade_value: undefined, 
    gift_value: undefined, 
    from_dealer: undefined, 
    from_private_party: undefined, 
    from_dismantler: undefined, 
    from_family_member: undefined, 
    family_member_relationship: undefined, 
    body_modifications: undefined, 
    total_cost: undefined, 
    sales_tax_unknown: undefined, 
    sales_tax_yes: undefined, 
    sales_tax_no: undefined, 
    sales_tax_paid: undefined, 
    commercial_vehicle: undefined, 
    noncommercial_vehicle: undefined, 
    plates_expired: undefined, 
    plates_surrendered: undefined, 
    plates_destroyed: undefined, 
    plates_retained: undefined, 
    plates_returned_to_state: undefined, 
    on_duty: undefined, 
    previous_active_duty: undefined, 
    active_duty_station_region: undefined, 
    signature_date: undefined, 
    signature_date_2: undefined, 
    signature_date_3: undefined, 
    area_code: undefined, 
    area_code_2: undefined, 
    area_code_3: undefined, 
    phone: undefined, //
    phone_2: undefined, //
    phone_3: undefined, //
    Text1: undefined, 
    Text25: undefined, 
    Text51: undefined, 
    Text56: undefined, 
    owner_name: undefined, 
    owner_name_2: undefined, 
    owner_name_3: undefined,
    smog_exempt: undefined, //
    odometer: undefined,
    cost_detail: undefined
  }
  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);

  const moneyFields = useMemo(()=>{return[
    "purchase_price",
    "gift_value",
    "trade_value",
    "total_cost",
    "sales_tax_paid"
  ]},[]);
  const phoneFields = useMemo(()=>{return[]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "model"]},[]);
  const checkboxes = useMemo(()=>{return[
    "weight_actual", 
    "weight_estimated",
    "odo_upon_purchase",
    "odo_as_of_date",
    "odo_mi",
    "odo_km",
    "odo_reading_invalid",
    "mileage_exceeded",
    "entry_unowned_vehicle",
    "no_ca_residency"
  ]},[]);
  // const radios = [
  //   "vehicle_type", 
  //   "commercial_use", 
  //   "overweight",
  //   "true_name_toggle",
  //   "true_name_toggle_2",
  //   "aquired_new_used",
  //   "aquired_in_ca",
  //   "cost_detail",
  //   "purchased_from",
  //   "body_mod_toggle",
  //   "sales_tax_other_state_toggle",
  //   "commercial_toggle",
  //   "plates_toggle",
  //   "on_duty_toggle",
  //   "previous_activeduty_toggle"
  // ];

  // X1- Current General On Change
  const handleDocumentValuesChange = e =>{
    // e.preventDefault();
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatPhoneNumber(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }

    // if(name === 'odometer'){
      //   setDocumentValues({...documentValues,[name]:removeChars(value)});    
    // }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug || res?.data.documentTemplate.slug === "ca-title"){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('CATitleApp343.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);
  

  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && (passedDocumentSlug === documentSlug || passedDocumentSlug === "ca-title")){
      setdocumentUrl("");


      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
      setNameOptions(getAllNames(order, null));
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, order, documentLoaded, loadDocument ])

  const [assignedProduct, setAssignedProduct] = useState(undefined);
  const [selectedAddress1, setSelectedAddress1] = useState(undefined);
  const [selectedAddress2, setSelectedAddress2] = useState(undefined);
  const [selectedAddress3, setSelectedAddress3] = useState(undefined);
  const [selectedAddress4, setSelectedAddress4] = useState(undefined);
  const [selectedAddress5, setSelectedAddress5] = useState(undefined);
  const [selectedAddress6, setSelectedAddress6] = useState(undefined);
  const [addressOptions, setAddressOptions] = useState(undefined);
  const [inputAddressVal, setinputAddressVal] = useState(undefined);

 // Autofill //

 const GlobalAutofill = useCallback(async () => {
  let newValues = CATitleApp343GlobalAutofill(documentValues, order);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  // var newValues = documentValues;
  
  // if(order && order.customer){

  //   if(order && order.customer && order.customer.name){

  //     newValues.area_code = getAreaCode(order.customer.phone);
  //     newValues.phone = getPhoneNumber(order.customer.phone);
  //     if(order.customer.type === "entity"){
  //       newValues.owner_name = order.customer.name;
  //       if(order.customer.dba){
  //         newValues.owner_name = order.customer.name+" dba "+order.customer.dba;
  //       }
  //     }else{
  //       newValues.owner_name = order.customer.name;
  //       if(order.customer.dba){
  //         newValues.owner_name = order.customer.name+" dba "+order.customer.dba;
  //       }
  //     }
  
  //     if(order.customer.cosigners && order.customer.cosigners.length > 0){
  //       let cosigner = order.customer.cosigners[0];
  //       if(cosigner){
  //         newValues.owner_name_2 = cosigner.name;
  //         newValues.true_name_toggle = "and";
  //         if(cosigner.phone){
  //           newValues.area_code_2 = getAreaCode(cosigner.phone);
  //           newValues.phone_2 = getPhoneNumber(cosigner.phone);
  //         }
  //       }
  //     }
  //   }
    
  //   if(order && order.customer && order.customer.deliveryAddress){
  //     if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
  //       newValues.address_line_2 = order.customer.deliveryAddress.addressLine2;
  //     }
  //     newValues.address_line_1 = order.customer.deliveryAddress.addressLine1;
  //     newValues.city = order.customer.deliveryAddress.city;
  //     newValues.state = order.customer.deliveryAddress.state;
  //     newValues.zip = order.customer.deliveryAddress.zip;
  //   }

    
  // }
  
  // if(order && order.documentationDate){
  //   newValues.signature_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  // }
  // setDocumentValues(newValues);
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ documentValues, order ])

// const preProcessPayload = useCallback((payload) => {



//   if(payload.vehicle_type){
//     payload.type_auto = "no";
//     payload.type_commercial = "no";
//     payload.type_motorcycle = "no";
//     payload.type_off_highway = "no";
//     payload.type_trailer_coach = "no";

//     switch(payload.vehicle_type){
//       case "type_auto":
//         payload.type_auto = "yes";
//         break;
//       case "type_commercial":
//         payload.type_commercial = "yes";
//         break;
//       case "type_motorcycle":
//         payload.type_motorcycle = "yes";
//         break;
//       case "type_off_highway":
//         payload.type_off_highway = "yes";
//         break;
//       case "type_trailer_coach":
//         payload.type_trailer_coach = "yes";
//           break;
//       default:
//     }
//   }

//   if(payload.commercial_use){
//     payload.commercial_use_yes = "no";
//     payload.commercial_use_no = "no";

//     switch(payload.commercial_use){
//       case "yes":
//         payload.commercial_use_yes = "yes";
//         break;
//       case "no":
//         payload.commercial_use_no = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.overweight){
//     payload.overweight_yes = "no";
//     payload.overweight_no = "no";

//     switch(payload.overweight){
//       case "yes":
//         payload.overweight_yes = "yes";
//         break;
//       case "no":
//         payload.overweight_no = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.true_name_toggle){
//     payload.owner_and = "no";
//     payload.owner_or = "no";

//     switch(payload.true_name_toggle){
//       case "and":
//         payload.owner_and = "yes";
//         break;
//       case "or":
//         payload.owner_or = "yes";
//         break;
//       case "x":
//         payload.owner_and = undefined;
//         payload.owner_or = undefined;
//         break;
//       default:
//     }
//   }

//   if(payload.true_name_toggle_2){
//     payload.owner_and_2 = "no";
//     payload.owner_or_2 = "no";

//     switch(payload.true_name_toggle_2){
//       case "and":
//         payload.owner_and_2 = "yes";
//         break;
//       case "or":
//         payload.owner_or_2 = "yes";
//         break;
//       case "x":
//         payload.owner_and_2 = undefined;
//         payload.owner_or_2 = undefined;
//         break;
//       default:
//     }
//   }

//   if(payload.aquired_new_used){
//     payload.pruchased_new = "no";
//     payload.pruchased_used = "no";

//     switch(payload.aquired_new_used){
//       case "new":
//         payload.pruchased_new = "yes";
//         break;
//       case "used":
//         payload.pruchased_used = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.aquired_in_ca){
//     payload.purchased_ca_inside = "no";
//     payload.purchased_ca_outside = "no";

//     switch(payload.aquired_in_ca){
//       case "inside":
//         payload.purchased_ca_inside = "yes";
//         break;
//       case "outside":
//         payload.purchased_ca_outside = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.cost_detail){
//     payload.purchase = "no";
//     payload.gift = "no";
//     payload.trade = "no";

//     switch(payload.cost_detail){
//       case "purchase":
//         payload.purchase = "yes";
//         break;
//       case "gift":
//         payload.gift = "yes";
//         break;        
//       case "trade":
//         payload.trade = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.purchased_from){
//     payload.from_dealer_cb = "no";
//     payload.from_private_party = "no";
//     payload.from_dismantler = "no";
//     payload.from_family_member = "no";

//     switch(payload.purchased_from){
//       case "from_dealer":
//         payload.from_dealer_cb = "yes";
//         break;
//       case "from_private_party":
//         payload.from_private_party = "yes";
//         break;        
//       case "from_dismantler":
//         payload.from_dismantler = "yes";
//         break;
//       case "from_family_member":
//         payload.from_family_member = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.body_mod_toggle){
//     payload.body_modifications_yes = "no";
//     payload.body_modifications_no = "no";

//     switch(payload.body_mod_toggle){
//       case "yes":
//         payload.body_modifications_yes = "yes";
//         break;
//       case "no":
//         payload.body_modifications_no = "yes";
//         break;        
//       default:
//     }
//   }

//   if(payload.sales_tax_other_state_toggle){
//     payload.sales_tax_unknown = "no";
//     payload.sales_tax_yes = "no";
//     payload.sales_tax_no = "no";

//     switch(payload.sales_tax_other_state_toggle){
//       case "unknown":
//         payload.sales_tax_unknown = "yes";
//         break;
//       case "yes":
//         payload.sales_tax_yes = "yes";
//         break;
//       case "no":
//         payload.sales_tax_no = "yes";
//         break;        
//       default:
//     }
//   }

//   if(payload.commercial_toggle){
//     payload.commercial_vehicle = "no";
//     payload.noncommercial_vehicle = "no";

//     switch(payload.commercial_toggle){
//       case "commercial":
//         payload.commercial_vehicle = "yes";
//         break;
//       case "noncommercial":
//         payload.noncommercial_vehicle = "yes";
//         break;        
//       default:
//     }
//   }

//   if(payload.plates_toggle){
//     payload.plates_expired = "no";
//     payload.plates_surrendered = "no";
//     payload.plates_destroyed = "no";
//     payload.plates_retained = "no";
//     payload.plates_returned_to_state = "no";

//     switch(payload.plates_toggle){
//       case "plates_expired":
//         payload.plates_expired = "yes";
//         break;
//       case "plates_surrendered":
//         payload.plates_surrendered = "yes";
//         break; 
//       case "plates_destroyed":
//         payload.plates_destroyed = "yes";
//         break
//       case "plates_retained":
//         payload.plates_retained = "yes";
//         break
//       case "plates_returned_to_state":
//         payload.plates_returned_to_state = "yes";
//         break       
//       default:
//     }
//   }

//   if(payload.on_duty_toggle){
//     payload.on_duty_yes = "no";
//     payload.on_duty_no = "no";

//     switch(payload.on_duty_toggle){
//       case "yes":
//         payload.on_duty_yes = "yes";
//         break;
//       case "no":
//         payload.on_duty_no = "yes";
//         break;        
//       default:
//     }
//   }

//   if(payload.previous_activeduty_toggle){
//     payload.previous_active_duty_yes = "no";
//     payload.previous_active_duty_no = "no";

//     switch(payload.previous_activeduty_toggle){
//       case "yes":
//         payload.previous_active_duty_yes = "yes";
//         break;
//       case "no":
//         payload.previous_active_duty_no = "yes";
//         break;        
//       default:
//     }
//   }

//   // X1.2- Field Type Specific Preview Formatting
//   Object.keys(payload).forEach(key => {
//     if(moneyFields.indexOf(key) >= 0){
//         payload[key] = formatMoneyValue(payload[key]);
//     }else if(phoneFields.indexOf(key) >= 0){
//         payload[key] = formatPhoneNumber(payload[key]);
//     }else if(upperCaseFields.indexOf(key) >= 0){
//         payload[key] = formatUpperCase(payload[key]);
//     }
//   });
//   setDocumentValues(JSON.parse(JSON.stringify(payload)));
//   Object.keys(payload).forEach(key => {
//     if(checkboxes.indexOf(key) >= 0){
//       payload[key] = payload[key] ? "yes": "no";
//     }
//   });

//   payload.copy_vin = payload.vin;

//   if(documentValues.odometer && documentValues.odometer.length > 0){
//     if(documentValues.odometer.length < 6){
//       let dif = 6 - documentValues.odometer.length;
//       let padding = "";


//       for(var i = 0; i < dif; i++){
//         padding += "x";
//       }
//       documentValues.odometer = padding+documentValues.odometer;
//     }

//     payload.odo_1 = (documentValues.odometer.charAt(0) === "x") ? "" :
    
//     documentValues.odometer.charAt(0);
//     payload.odo_2 = (documentValues.odometer.charAt(1) === "x") ? "" :
    
//     documentValues.odometer.charAt(1);
//     payload.odo_3 = (documentValues.odometer.charAt(2) === "x") ? "" :
    
//     documentValues.odometer.charAt(2);;
//     payload.odo_4 = (documentValues.odometer.charAt(3) === "x") ? "" :
    
//     documentValues.odometer.charAt(3);;
//     payload.odo_5 = (documentValues.odometer.charAt(4) === "x") ? "" :
    
//     documentValues.odometer.charAt(4);;
//     payload.odo_6 = (documentValues.odometer.charAt(5) === "x") ? "" :
    
//     documentValues.odometer.charAt(5);;
//   }

//   if(payload.signature_date){
//     payload.signature_date = moment.utc(payload.signature_date).format("MM/DD/YY");
//   }

//   if(payload.signature_date_2){
//     payload.signature_date_2 = moment.utc(payload.signature_date_2).format("MM/DD/YY");
//   }

//   if(payload.signature_date_3){
//     payload.signature_date_3 = moment.utc(payload.signature_date_3).format("MM/DD/YY");
//   }

//   return payload;
// },[ checkboxes, moneyFields, phoneFields, upperCaseFields, documentValues ])


const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });
    
    if(!document){
      return null;
    }

      var docCopy = JSON.parse(JSON.stringify(document));
      docCopy.payload =  JSON.parse(JSON.stringify(payload));
      docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
      docCopy.payload =  JSON.stringify(docCopy.payload);
        delete docCopy.documentStatus;
      try{
        let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
        setdocumentUrl(fileURL)
        } catch (err) {
          recordFrontEndError('CATitleApp343.jsx', 'Error previewing document payload: ' + err)
        };  
    
},[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


    //Autofill Product Details After Selection
    const AutofillProductDetails = useCallback(async (selectedProduct) => {
      let newValues = CATitleApp343AutofillProductDetails(selectedProduct, documentValues);

      if(newValues){
        setDocumentValues(newValues);
        previewPayloadDocument(documentValues);
        setHasUnsavedChanges(true);
        if(document){
          previewPayloadDocument(documentValues);
        }
        setSaveSuccess(false);
      }


      // var newValues = documentValues;
      // newValues.vin = (selectedProduct.vin);
      // newValues.copy_vin = (selectedProduct.vin);
      // newValues.make = (selectedProduct.make);
      // newValues.copy_make = (selectedProduct.make);
      // newValues.year_model = (selectedProduct.year);
      // newValues.copy_year_model = (selectedProduct.year);
      // newValues.model = (selectedProduct.model).toString().toUpperCase();
      // newValues.body_type = (selectedProduct.vehicleType);

      // if(selectedProduct.fundingDetail && (selectedProduct.fundingDetail.fundingMethod === "tec-financing" || selectedProduct.fundingDetail.fundingMethod === "finance-only" || selectedProduct.fundingDetail.fundingMethod === "direct-deal")){
      //   newValues.lien_owner_name = selectedProduct.fundingDetail.finalizedLendingOption.bankName;
      //   newValues.lien_address_line_1 = selectedProduct.fundingDetail.finalizedLendingOption.lienAddress;
      //   newValues.lien_city = selectedProduct.fundingDetail.finalizedLendingOption.lienCity;
      //   newValues.lien_state = selectedProduct.fundingDetail.finalizedLendingOption.lienState;
      //   newValues.lien_zip = selectedProduct.fundingDetail.finalizedLendingOption.lienZip;   
      // }else if(selectedProduct.fundingDetail && selectedProduct.fundingDetail.fundingMethod === "cash-with-lien"){
      //   newValues.lien_owner_name = selectedProduct.fundingDetail.cashLienHolder.bankName;
      //   newValues.lien_address_line_1 = selectedProduct.fundingDetail.cashLienHolder.addressLine1;
      //   newValues.lien_address_line_2 = selectedProduct.fundingDetail.cashLienHolder.addressLine2;
      //   newValues.lien_city = selectedProduct.fundingDetail.cashLienHolder.city;
      //   newValues.lien_state = selectedProduct.fundingDetail.cashLienHolder.state;
      //   newValues.lien_zip = selectedProduct.fundingDetail.cashLienHolder.zip;
      // }else if(selectedProduct.shipTo){
      //   newValues.lien_owner_name = selectedProduct.shipTo.name;
      //   newValues.lien_address_line_1 = selectedProduct.shipTo.addressLine1;
      //   newValues.lien_address_line_2 = selectedProduct.shipTo.addressLine2;
      //   newValues.lien_city = selectedProduct.shipTo.city;
      //   newValues.lien_state = selectedProduct.shipTo.state;
      //   newValues.lien_zip = selectedProduct.shipTo.zip;
      // }

      // setDocumentValues(newValues);
      // // setProductToggleAutoFill(false);
      // if(document){
      //   previewPayloadDocument(documentValues);
      // }
      // setHasUnsavedChanges(true);
      // setSaveSuccess(false);
    },[ document, documentValues, previewPayloadDocument ]);

  // Document BREAD + Preview // TODO: Move to Redux

  useEffect(() => {
    if(document && document.payload && !dataLoaded){
      var existingPayload = false;
      var documentPayload = JSON.parse(document.payload);
      if(documentPayload && Object.keys(documentPayload).length > 0){
    
        existingPayload = true;
    
        if(documentPayload.signature_date){
          documentPayload.signature_date = moment.utc(documentPayload.signature_date).format("YYYY-MM-DD");
        }

        if(documentPayload.signature_date_2){
          documentPayload.signature_date_2 = moment.utc(documentPayload.signature_date_2).format("YYYY-MM-DD");
        }

        if(documentPayload.signature_date_3){
          documentPayload.signature_date_3 = moment.utc(documentPayload.signature_date_3).format("YYYY-MM-DD");
        }
        
        setDocumentValues(documentPayload);

        // Loading Use Effect
        if(document.additionalData){
          const methodMap = { 
            selectedAddress1: setSelectedAddress1, 
            selectedAddress2: setSelectedAddress2, 
            selectedAddress3: setSelectedAddress3, 
            selectedAddress4: setSelectedAddress4, 
            selectedAddress5: setSelectedAddress5, 
            selectedAddress6: setSelectedAddress6, 
          };

          let parsedAdditionalData = JSON.parse(document.additionalData);
          Object.keys(parsedAdditionalData).forEach(function(key) {
            if(methodMap[key]){
              methodMap[key](parsedAdditionalData[key]);
            }
          });
        }

      }
      var foundAssignedProduct = null;
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
        foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
      }
      
      setAddressOptions(getOrderLevelAddresses(order));
      if(foundAssignedProduct){
          setAssignedProduct(foundAssignedProduct);
          setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
          setNameOptions(getAllNames(order, foundAssignedProduct));
      }
      
        
        if(!existingPayload){
          GlobalAutofill();
          previewPayloadDocument(documentValues);
          if(foundAssignedProduct){
            AutofillProductDetails(foundAssignedProduct)
          }
        }else{
          // if(document && document.itemIds){
          //   var itemIds = document.itemIds.split(',');
          //     var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
          //     let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
          //     let obj = {id: foundProduct.id, name: str}
          //     setproductVal(obj);
          //     if(foundProduct){
          //       setAddressOptions(updateAddressOptions(foundProduct, order));
          //     }
          //     streamDocument();
          // }
    
        }
        
      setDataLoaded(true);
    }
  },[ documentValues, document, dataLoaded, order, AutofillProductDetails, GlobalAutofill, previewPayloadDocument ])


  function ReloadAutoFillValues(){  
    var foundAssignedProduct = null;
    if(document && document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }

    GlobalAutofill();
    if(foundAssignedProduct){
      AutofillProductDetails(foundAssignedProduct)
    }
  }

  
  
    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();

      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
    

      //Save Dropdown Selections
      let dropdownData = {};
      if(selectedAddress1){
        dropdownData.selectedAddress1 = {
          id: selectedAddress1.id,
          name: selectedAddress1.name
        };
      }
      if(selectedAddress2){
        dropdownData.selectedAddress2 = {
          id: selectedAddress2.id,
          name: selectedAddress2.name
        };
      }
      if(selectedAddress3){
        dropdownData.selectedAddress3 = {
          id: selectedAddress3.id,
          name: selectedAddress3.name
        };
      }
      if(selectedAddress4){
        dropdownData.selectedAddress4 = {
          id: selectedAddress4.id,
          name: selectedAddress4.name
        };
      }
      if(selectedAddress5){
        dropdownData.selectedAddress5 = {
          id: selectedAddress5.id,
          name: selectedAddress5.name
        };
      }
      if(selectedAddress6){
        dropdownData.selectedAddress6 = {
          id: selectedAddress6.id,
          name: selectedAddress6.name
        };
      }

      let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, additionalData, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });


      
    }

// Component Helpers //

  
  //Autofill Address Details After Selection
  function AutoFillAddress(addressSelected, selectionInstance){
    var newValues = documentValues;
    if(selectionInstance){
      if(selectionInstance === "physical-address"){
        newValues.address_line_1 = addressSelected.address;
        // newValues.address_line_2 = addressSelected.addressLine2;
        newValues.city = addressSelected.city;
        newValues.state = addressSelected.state;
        newValues.zip = addressSelected.zip;
      }

      if(selectionInstance === "mailing-address"){
        newValues.mailing_address_line_1 = addressSelected.address;
        // newValues.mailing_address_line_2 = addressSelected.addressLine2;
        newValues.mailing_city = addressSelected.city;
        newValues.mailing_state = addressSelected.state;
        newValues.mailing_zip = addressSelected.zip;
      }

      if(selectionInstance === "lessee-address"){
        newValues.lessee_address_line_1 = addressSelected.address;
        // newValues.lessee_address_line_2 = addressSelected.addressLine2;
        newValues.lessee_city = addressSelected.city;
        newValues.lessee_state = addressSelected.state;
        newValues.lessee_zip = addressSelected.zip;
      }

      if(selectionInstance === "located-address"){
        newValues.trailer_coach_address = addressSelected.address;
        newValues.trailer_coach_city = addressSelected.city;
        newValues.trailer_coach_state = addressSelected.state;
        newValues.trailer_coach_zip = addressSelected.zip;
      }

      if(selectionInstance === "physical2-address"){
        newValues.lien_address_line_1 = addressSelected.address;
        // newValues.lien_address_line_2 = addressSelected.addressLine2;
        newValues.lien_city = addressSelected.city;
        newValues.lien_state = addressSelected.state;
        newValues.lien_zip = addressSelected.zip;
      }

      if(selectionInstance === "mailing2-address"){
        newValues.lien_mailing_address_line_1 = addressSelected.address;
        // newValues.lien_mailing__address_line_2 = addressSelected.addressLine2;
        newValues.lien_mailing_city = addressSelected.city;
        newValues.lien_mailing_state = addressSelected.state;
        newValues.lien_mailing_zip = addressSelected.zip;
      }
    }
    setDocumentValues(newValues);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  const onCloseClick = e => {
    e.preventDefault()
    setHasUnsavedChanges(false);
    setSaveSuccess(false);
    setdocumentUrl("");
    setDocument(undefined);
    setNameOptions([]);
    setPreviousDocumentId(0);
    setDocumentValues(initialDocumentStructure);
    setDocumentLoaded(false);
    setDataLoaded(false);
    setAssignedProduct(undefined);
    setSelectedAddress1(undefined);
    setSelectedAddress2(undefined);
    setSelectedAddress3(undefined);
    setSelectedAddress4(undefined);
    setSelectedAddress5(undefined);
    setSelectedAddress6(undefined);
    setAddressOptions(undefined);
    setinputAddressVal(undefined);
    setDocumentFocus(undefined);
  }
  
  const [hasClickedClose, setHasClickedClose] = useState(false);
  
  const closeClicked = e => {
    if( hasUnsavedChanges ){
        setHasClickedClose(true);
    } else {
        onCloseClick(e);
    }
  }
  
  const preCloseSave = e => {
    handleSubmit(e)
    setHasClickedClose(false);
  }
  
  const preCloseClose = e => {
    setHasClickedClose(false);
    onCloseClick(e)
  }

  return (
    <>
      <div
        className="modal fade"
        // id="ca-statement-of-facts"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                CA Title App
              </h5>
              <br />
              { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1 pt-1 text-white">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                  </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}


                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}


                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ fontSize: '12px', overflowY: 'scroll', height: 'inherit'}}>
               
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      ReloadAutoFillValues();
                      // GlobalAutofill();
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>
                  <br />
                  { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Product Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                        </div>
                    </div>
                  ):(
                    <div className="m-2 p-2 border">
                        <span>No Assigned Product</span>
                    </div>
                  )}

                  <div className="row">
                    <h5>Equipment</h5>
                  </div>

                  <div className="row">
                    <h5>Section 1 - Vehicle Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Year Model</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year_model"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year_model ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Fuel Type</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="fuel_type"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.fuel_type ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">CA License Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="ca_license_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.ca_license_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Model or Series</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="model"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.model ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Body Type Model</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="body_type"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.body_type ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Motorcycle Engine Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mc_engine_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mc_engine_number ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <label htmlFor="stock">TYPE OF VEHICLE (CHECK ONE BOX)</label>
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                      <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="vehicle_type"
                            value={(documentValues.vehicle_type ?? "")}


                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="type_auto" control={<Radio />} label="Auto" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="type_commercial" control={<Radio />} label="Commercial" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="type_motorcycle" control={<Radio />} label="Motorcycle" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="type_off_highway" control={<Radio />} label="Off Highway" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="type_trailer_coach" control={<Radio />} label="Trailer Coach" />
                          </RadioGroup>
                        </FormControl>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                    <label htmlFor="stock">FOR TRAILER COACHES ONLY (Length & Width)</label>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">LENGTH (IN)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="trailer_length"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.trailer_length ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">WIDTH (IN)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="trailer_width"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.trailer_width ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <label htmlFor="stock" style={{ fontSize: '10px' }}>Will this vehicle be used for the transportation of persons for hire, compensation, or profit (e.g. limousine, taxi, bus, etc.)?</label>
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                      <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="commercial_use"
                            value={(documentValues.commercial_use ?? "")}


                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="no" control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                    </div>
                  </div>
                  <hr></hr>

                  <label htmlFor="stock" style={{ fontSize: '10px' }}>Is this a commercial vehicle that operates at 10,001 lbs. or more (or is a pickup exceeding 8,001 lbs. unladen and/or 1,499 lbs. Gross Vehicle Weight Rating (GVWR)?</label>
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                      <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="overweight"
                            value={(documentValues.overweight ?? "")}


                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="no" control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                    <label htmlFor="stock">FOR COMMERCIAL VEHICLES ONLY</label>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Number of Axles</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="axels"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.axels ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Unladen Weight</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="unladen_weight"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.unladen_weight ?? '' }
                          />
                      </div>
                    </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                    <label htmlFor="stock">This is what I changed: Check all that apply:</label>
                      <FormControl className="px-3 pt-2">
                              <FormControlLabel 
                                className="px-3" 
                                control={<input type="checkbox"
                                  checked={ documentValues.weight_actual }
                                  name="weight_actual"
                                  onChange={handleDocumentValuesChange}
                                  onBlur={(e) => updatePreview(e)}
                                  className="m-2"
                                  />}
                                label="Actual" />
                              <FormControlLabel 
                                className="px-3" 
                                control={<input type="checkbox"
                                  checked={ documentValues.weight_estimated }
                                  name="weight_estimated"
                                  onChange={handleDocumentValuesChange}
                                  onBlur={(e) => updatePreview(e)}
                                  className="m-2"
                                  />}
                                label="Estimated (Vehicles over 10,001 lbs. only)" />
                      </FormControl>
                    </div>
                  </div>

                  </div>
                  <hr></hr>

                  <div className="row">
                    <h5>Section 2 - Owner Information</h5>

                    <Autocomplete
                      style={{ width: '100%'}}
                      value={ (documentValues.owner_name) ?? null}
                      name="owner_name"
                      onBlur={(e) => updatePreview(e)}
                      onChange={(event, newValue) => {
                        let updatedObject = documentValues;
                        updatedObject.owner_name = newValue.name;
                        setDocumentValues(updatedObject);
                        // setDocumentValues({...documentValues,["owner_name"]:newValue.name});
                      }}
                      // inputValue={inputAddressVal}
                      // onInputChange={(event, newInputValue) => {
                      //   setinputAddressVal(newInputValue);
                      // }}
                      options={nameOptions}
                      sx={{ width: 400 }}
                      renderInput={(params) => <TextField {...params} label="True Full Name of Owner or Lessor" />}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                    />

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">True Full Name of Owner or Lessor</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Drivers License Card Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_id"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_id ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-2">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_state ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">

                    <Autocomplete
                      style={{ width: '100%'}}
                      value={ (documentValues.owner_name_2) ?? null}
                      name="owner_name_2"
                      onBlur={(e) => updatePreview(e)}
                      onChange={(event, newValue) => {
                        let updatedObject = documentValues;
                        updatedObject.owner_name_2 = newValue.name;
                        setDocumentValues(updatedObject);
                        // setDocumentValues({...documentValues,["owner_name_2"]:newValue.name});
                      }}
                      // inputValue={inputAddressVal}
                      // onInputChange={(event, newInputValue) => {
                      //   setinputAddressVal(newInputValue);
                      // }}
                      options={nameOptions}
                      sx={{ width: 400 }}
                      renderInput={(params) => <TextField {...params} label="True Full Name of Owner or Lessor" />}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                    />

                    <div className="d-flex justify-content-evenly" style={{ fontSize: '12px' }}>
                      <div className="p-2 col-md-2">
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                            <RadioGroup
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="true_name_toggle"
                              value={(documentValues.true_name_toggle ?? "")}


                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                            >
                              <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="and" control={<Radio />} label="AND" />
                              <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="or" control={<Radio />} label="OR" />
                              <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="x" control={<Radio />} label="NONE" />
                            </RadioGroup>
                          </FormControl>
                      </div>
                      <div className="p-2 col-md-5">
                        <label htmlFor="stock">True Full Name of Co-owner or Lessee</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_name_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_name_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Drivers License Card Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_id_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_id_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-2">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_state_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_state_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">

                    <Autocomplete
                      style={{ width: '100%'}}
                      value={ (documentValues.owner_name_3) ?? null}
                      name="owner_name_3"
                      onBlur={(e) => updatePreview(e)}
                      onChange={(event, newValue) => {
                        let updatedObject = documentValues;
                        updatedObject.owner_name_3 = newValue.name;
                        setDocumentValues(updatedObject);
                        // setDocumentValues({...documentValues,["owner_name_3"]:newValue.name});
                      }}
                      // inputValue={ (documentValues.owner_name_3) ?? null}
                      // onInputChange={(event, newInputValue) => {
                      //   setinputAddressVal(newInputValue);
                      // }}
                      options={nameOptions}
                      sx={{ width: 400 }}
                      renderInput={(params) => <TextField {...params} label="True Full Name of Owner or Lessor" />}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                      />

                    <div className="d-flex justify-content-evenly" style={{ fontSize: '12px' }}>
                      <div className="p-2 col-md-2">
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                            <RadioGroup
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="true_name_toggle_2"
                              value={(documentValues.true_name_toggle_2 ?? "")}


                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                            >
                              <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="and" control={<Radio />} label="AND" />
                              <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="or" control={<Radio />} label="OR" />
                              <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="x" control={<Radio />} label="NONE" />
                            </RadioGroup>
                          </FormControl>
                      </div>
                      <div className="p-2 col-md-5">
                        <label htmlFor="stock">True Full Name of Co-owner or Lessee #2</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_name_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_name_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Drivers License Card Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_id_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_id_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-2">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_state_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_state_3 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {addressOptions && addressOptions.length > 0 ? (  
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress1 && selectedAddress1.name) ?? null}
                          name="physical-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress1(newValue);
                            AutoFillAddress(newValue, "physical-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Physical Residence or Business Address" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-8">
                        <label htmlFor="stock">Physical Residence or Business Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="address_line_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.address_line_1 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">APT./SPACE/STE.</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="address_line_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.address_line_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.zip ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-8">
                      <label htmlFor="stock">County of Residence</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="county"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.county ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Equipment Number (Optionial)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="equipment_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.equipment_number ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                  {addressOptions && addressOptions.length > 0 ? (  
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress2 && selectedAddress2.name) ?? null}
                          name="mailing-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress2(newValue);
                            AutoFillAddress(newValue, "mailing-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Mailing Address" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>         ):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}


                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-8">
                        <label htmlFor="stock">Mailing Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_address_line_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_address_line_1 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">APT./SPACE/STE.</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_address_line_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_address_line_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_zip ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                    {addressOptions && addressOptions.length > 0 ? (  
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress3 && selectedAddress3.name) ?? null}
                          name="lessee-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress3(newValue);
                            AutoFillAddress(newValue, "lessee-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Lessee Address" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>         ):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}


                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-8">
                        <label htmlFor="stock">Lessee Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lessee_address_line_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lessee_address_line_1 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">APT./SPACE/STE.</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lessee_address_line_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lessee_address_line_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lessee_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lessee_city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lessee_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lessee_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lessee_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lessee_zip ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                  {addressOptions && addressOptions.length > 0 ? (  
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress4 && selectedAddress4.name) ?? null}
                          name="located-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress4(newValue);
                            AutoFillAddress(newValue, "located-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="TRAILER COACH ONLY - ADDRESS WHERE LOCATED" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>        ):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}


                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">TRAILER COACH ONLY - ADDRESS WHERE LOCATED (IF DIFFERENT FROM PHYSICAL ABOVE)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="trailer_coach_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.trailer_coach_address ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="trailer_coach_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.trailer_coach_city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="trailer_coach_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.trailer_coach_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="trailer_coach_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.trailer_coach_zip ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                    <h5>Section 3 - Legal Owner</h5>

                    <Autocomplete
                      style={{ width: '100%'}}
                      value={ (documentValues.lien_owner_name) ?? null}
                      name="lien_owner_name"
                      onBlur={(e) => updatePreview(e)}
                      onChange={(event, newValue) => {
                        let updatedObject = documentValues;
                        updatedObject.lien_owner_name = newValue.name;
                        setDocumentValues(updatedObject);
                        // setDocumentValues({...documentValues,["lien_owner_name"]:newValue.name});
                      }}
                      // inputValue={inputAddressVal}
                      // onInputChange={(event, newInputValue) => {
                      //   setinputAddressVal(newInputValue);
                      // }}
                      options={nameOptions}
                      sx={{ width: 400 }}
                      renderInput={(params) => <TextField {...params} label="True Full Name of Owner or Lessor" />}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                      />

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-8">
                        <label htmlFor="stock">TRUE FULL NAME OF BANK/FINANCE COMPANY OR INDIVIDUAL</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lien_owner_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lien_owner_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">ELECTRONIC LIENHOLDER ID NO.</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="elt"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.elt ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                  {addressOptions && addressOptions.length > 0 ? (  
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress5 && selectedAddress5.name) ?? null}
                          name="physical2-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress5(newValue);
                            AutoFillAddress(newValue, "physical2-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="PHYSICAL RESIDENCE OR BUSINESS ADDRESS" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>        ):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}


                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-8">
                        <label htmlFor="stock">PHYSICAL RESIDENCE OR BUSINESS ADDRESS</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lien_address_line_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lien_address_line_1 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">APT./SPACE/STE.</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lien_address_line_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lien_address_line_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lien_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lien_city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lien_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lien_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lien_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lien_zip ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                  {addressOptions && addressOptions.length > 0 ? (  
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress6 && selectedAddress6.name) ?? null}
                          name="mailing2-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress6(newValue);
                            AutoFillAddress(newValue, "mailing2-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="MAILING ADDRESS" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>                    ):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-8">
                        <label htmlFor="stock">MAILING ADDRESS (IF DIFFERENT FROM PHYSICAL ADDRESS ABOVE)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lien_mailing_address_line_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lien_mailing_address_line_1 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">APT./SPACE/STE.</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lien_mailing_address_line_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lien_mailing_address_line_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lien_mailing_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lien_mailing_city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lien_mailing_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lien_mailing_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lien_mailing_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lien_mailing_zip ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                    <h5>Section 4 - Odometer Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.odo_upon_purchase }
                              name="odo_upon_purchase"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />} 
                            label="upon date of purchase in California was" />

                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.odo_as_of_date }
                              name="odo_as_of_date"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />} 
                            label="as of this date is (if no change in ownership)" />

                        </FormControl>
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">The Odometer Reading</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="odometer"
                          maxLength="6"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.odometer ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-2">
                        { passedDocumentSlug.includes('v2') ? (
                          <FormControl className="px-3 pt-2">
                            <FormControlLabel 
                              className="px-3" 
                              control={<input type="checkbox"
                              checked={ documentValues.odo_mi }
                              name="odo_mi"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />} 
                              label="Miles" />
                          </FormControl>
                        ):null}
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.odo_km }
                              name="odo_km"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />} 
                            label="Kilometers" />
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.odo_reading_invalid }
                              name="odo_reading_invalid"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />} 
                            label="Odometer reading is NOT the actual mileage" />
                        </FormControl>
                      </div>
                      <div className="p-2 col-md-6">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.mileage_exceeded }
                              name="mileage_exceeded"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />} 
                            label="Mileage EXCEEDS the odometer mechanical limits" />
                        </FormControl>
                      </div>
                    </div>

                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Explain odometer discrepancy</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="odo_discrepancy_reason"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.odo_discrepancy_reason ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">VIN (Page 2)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="copy_vin"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.copy_vin ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Make (Page 2)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="copy_make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.copy_make ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Year Model (Page 2)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="copy_year_model"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.copy_year_model ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                    <h5>Section 5 - Date Information</h5>
                    <span>DATE VEHICLE ENTERED OR WILL ENTER CALIFORNIA (CA):</span>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Month</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="ca_entry_month"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.ca_entry_month ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Day</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="ca_entry_day"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.ca_entry_day ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="ca_entry_year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.ca_entry_year ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.entry_unowned_vehicle }
                              name="entry_unowned_vehicle"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />} 
                            style={{ fontSize: '8px'}}
                            label="If vehicle was previously registered in CA, then registered or located out-of-state and has now returned to CA, enter most recent date vehicle entered CA. If you did not own vehicle at time of entry, check this box: " />
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                    <span>DATE VEHICLE FIRST OPERATED IN CALIFORNIA:</span>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Month</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="ca_operation_month"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.ca_operation_month ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Day</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="ca_operation_day"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.ca_operation_day ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="ca_operation_year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.ca_operation_year ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                    <span>DATE YOU WENT TO WORK IN CALIFORNIA, OBTAINED A CA DRIVER LICENSE, OR BECAME A RESIDENT:</span>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Month</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="ca_residency_month"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.ca_residency_month ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Day</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="ca_residency_day"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.ca_residency_day ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="ca_residency_year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.ca_residency_year ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.no_ca_residency }
                              name="no_ca_residency"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />} 
                            style={{ fontSize: '8px'}}
                            label="Enter the date whichever occurred first. If you have been a resident since birth, enter date of birth. If you are not a CA resident, check this box: " />
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                    <span>DATE VEHICLE WAS PURCHASED OR ACQUIRED:</span>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Month</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchase_month"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchase_month ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Day</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchase_day"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchase_day ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchase_year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchase_year ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">AND WAS (CHECK BOX):</label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="aquired_new_used"
                            value={(documentValues.aquired_new_used ?? "")}


                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="new" control={<Radio />} label="New" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="used" control={<Radio />} label="Used" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">AND WAS PURCHASED (CHECK BOX):</label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="aquired_in_ca"
                            value={(documentValues.aquired_in_ca ?? "")}


                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="inside" control={<Radio />} label="Inside CA" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="outside" control={<Radio />} label="Outside CA" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                    <h5>Section 6 - Cost Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">MUST CHECK ONE BOX ONLY, AND ENTER REQUIRED INFORMATION FOR THAT ONE BOX:</label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="cost_detail"
                            value={(documentValues.cost_detail ?? "")}


                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="purchase" control={<Radio />} label=" PURCHASE  – I purchased the vehicle for the price of " />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="gift" control={<Radio />} label="GIFT – I acquired the vehicle as a gift. Its current market value is" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="trade" control={<Radio />} label="TRADE – I acquired the vehicle as a trade. Its value when I acquired it was" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>

                    { documentValues.cost_detail === "purchase" ? (
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Purchase Price</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchase_price"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchase_price ?? '' }
                          />
                      </div>
                    </div>
                    ):null}

                    { documentValues.cost_detail === "gift" ? (
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Gift Value</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="gift_value"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.gift_value ?? '' }
                          />
                      </div>
                    </div>
                    ):null}

                    { documentValues.cost_detail === "trade" ? (
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Trade Value</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="trade_value"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.trade_value ?? '' }
                          />
                      </div>
                    </div>
                    ):null}

                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">VEHICLE WAS PURCHASED OR ACQUIRED FROM:</label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="purchased_from"
                            value={(documentValues.purchased_from ?? "")}


                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="from_dealer" control={<Radio />} label="Dealer" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="from_private_party" control={<Radio />} label="Private Party" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="from_dismantler" control={<Radio />} label="Dismantler" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="from_family_member" control={<Radio />} label="Immediate Family Member – State Relationship" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>

                    { documentValues.purchased_from === "from_family_member" ? (
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Immediate Family Member – State Relationship</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="family_member_relationship"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.family_member_relationship ?? '' }
                          />
                      </div>
                    </div>
                    ):null}
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Since purchasing or acquiring this vehicle, were any body type modifications, additions and/or alterations (e.g., changing from pickup to utility, etc.) made to this vehicle?</label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="body_mod_toggle"
                            value={(documentValues.body_mod_toggle ?? "")}


                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="no" control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Total Cost</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="total_cost"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.total_cost ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6"></div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                    <h5>Section 7 - For Out-of-state or Out-of-country vehicles</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">For vehicles which enter the state within 1 year of purchase, was Sales Tax paid to another state?</label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="sales_tax_other_state_toggle"
                            value={(documentValues.sales_tax_other_state_toggle ?? "")}


                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="unknown" control={<Radio />} label="N/A" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="no" control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Sales Tax Paid</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="sales_tax_paid"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.sales_tax_paid ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">For commercial vehicles (including pickups), this vehicle was last registered as a:</label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="commercial_toggle"
                            value={(documentValues.commercial_toggle ?? "")}


                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="commercial" control={<Radio />} label="Commercial Vehicle" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="noncommercial" control={<Radio />} label="Non-commercial Automobile in the last state of registration." />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">The plates will not be affixed to any vehicle at any time, unless the vehicle is “Dual Registered” in both states. The plates are:</label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="plates_toggle"
                            value={(documentValues.plates_toggle ?? "")}


                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="plates_expired" control={<Radio />} label="Expired, or will be or were:" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="plates_surrendered" control={<Radio />} label="Surrendered to CA DMV" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="plates_destroyed" control={<Radio />} label="Destroyed" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="plates_retained" control={<Radio />} label="Retained" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="plates_returned_to_state" control={<Radio />} label="Returned to the motor vehicle department of the state of issuance." />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    <hr></hr>

                  </div>

                  <div className="row">
                    <h5>Section 8 - Military Service Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Are you or your spouse on active duty as a member of the U.S. Uniformed Services?</label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="on_duty_toggle"
                            value={(documentValues.on_duty_toggle ?? "")}


                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="no" control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">When this vehicle was last licensed, were you or your spouse on active duty as a member of the U.S. Uniformed Services?</label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="previous_activeduty_toggle"
                            value={(documentValues.previous_activeduty_toggle ?? "")}


                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="no" control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>

                    {documentValues.previous_activeduty_toggle === "yes" ? (
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">If yes, in what state or country were you or your spouse stationed?</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="active_duty_station_region"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.active_duty_station_region ?? '' }
                          />
                      </div>
                    </div>
                    ):null}

                    <hr></hr>
                  </div>

                  <div className="row">
                    <h5>Section 9 - Certifications</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Printed Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                          <label htmlFor="stock">Date</label>
                          <input
                            type="date"
                            className="form-control rounded-pill mt-1"
                            name="signature_date"
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                            value={ documentValues.signature_date ?? '' }
                            />
                      </div>
                      <div className="p-2 col-md-3">
                          <label htmlFor="stock">Area code</label>
                          <input
                            type="text"
                            className="form-control rounded-pill mt-1"
                            name="area_code"
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                            value={ documentValues.area_code ?? '' }
                            />
                      </div>
                      <div className="p-2 col-md-3">
                          <label htmlFor="stock">Daytime Telephone Number</label>
                          <input
                            type="text"
                            className="form-control rounded-pill mt-1"
                            name="phone"
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                            value={ documentValues.phone ?? '' }
                            />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Printed Name (2)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_name_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_name_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                          <label htmlFor="stock">Date</label>
                          <input
                            type="date"
                            className="form-control rounded-pill mt-1"
                            name="signature_date_2"
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                            value={ documentValues.signature_date_2 ?? '' }
                            />
                      </div>
                      <div className="p-2 col-md-3">
                          <label htmlFor="stock">Area code</label>
                          <input
                            type="text"
                            className="form-control rounded-pill mt-1"
                            name="area_code_2"
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                            value={ documentValues.area_code_2 ?? '' }
                            />
                      </div>
                      <div className="p-2 col-md-3">
                          <label htmlFor="stock">Daytime Telephone Number</label>
                          <input
                            type="text"
                            className="form-control rounded-pill mt-1"
                            name="phone_2"
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                            value={ documentValues.phone_2 ?? '' }
                            />
                      </div>
                    </div>

                        <div className="d-flex justify-content-evenly">
                          <div className="p-2 col-md-3">
                            <label htmlFor="stock">Printed Name (3)</label>
                            <input
                              type="text"
                              className="form-control rounded-pill mt-1"
                              name="owner_name_3"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              value={ documentValues.owner_name_3 ?? '' }
                              />
                          </div>
                          <div className="p-2 col-md-3">
                              <label htmlFor="stock">Date</label>
                              <input
                                type="date"
                                className="form-control rounded-pill mt-1"
                                name="signature_date_3"
                                onChange={handleDocumentValuesChange}
                                onBlur={(e) => updatePreview(e)}
                                value={ documentValues.signature_date_3 ?? '' }
                                />
                          </div>
                          <div className="p-2 col-md-3">
                              <label htmlFor="stock">Area code</label>
                              <input
                                type="text"
                                className="form-control rounded-pill mt-1"
                                name="area_code_3"
                                onChange={handleDocumentValuesChange}
                                onBlur={(e) => updatePreview(e)}
                                value={ documentValues.area_code_3 ?? '' }
                                />
                          </div>
                          <div className="p-2 col-md-3">
                              <label htmlFor="stock">Daytime Telephone Number</label>
                              <input
                                type="text"
                                className="form-control rounded-pill mt-1"
                                name="phone_3"
                                onChange={handleDocumentValuesChange}
                                onBlur={(e) => updatePreview(e)}
                                value={ documentValues.phone_3 ?? '' }
                                />
                          </div>
                        </div>
                  </div>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default CATitleApp343

import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  useParams
} from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { removeChars, formatter, formatPhoneNumber, formatUpperCase } from "../../../../../../../utlis/formatters";
import { useOrder } from "../../../../../../../redux/orders/order"
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { WABillOfSaleGlobalAutofill, WABillOfSaleAutofillProductDetails, WABillOfSaleAutofillTradeDetails, preProcessPayload } from "../DocumentAutofill/WABillOfSale"

const WABillOfSale = ({document_id, location, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "wa-bill-of-sale";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();

  const order = useOrder();

  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);

  // Data Structure & Init //
  
  var initialDocumentStructure = {
    identification_number: undefined,
    model: undefined,
    make: undefined,
    license_plate: undefined,
    mc_engine_number: undefined,
    month: undefined,
    day: undefined,
    year: undefined,
    selling_price: undefined,
    gift_value: undefined,
    relationship_type: undefined,
    seller_name: undefined,
    seller_name_signature: undefined,
    seller_name_signature_2: undefined,
    seller_id: undefined,
    seller_id_2: undefined,
    sell_date: undefined,
    sell_date_2: undefined,
    seller_address: undefined,
    seller_city: undefined,
    seller_state: undefined,
    seller_zip: undefined,
    seller_phone: undefined,
    buyer_name: undefined,
    buyer_print_name: undefined,
    buyer_print_name_2: undefined,
    buyer_address: undefined,
    buyer_city: undefined,
    buyer_state: undefined,
    buyer_zip: undefined,
    copy_identification_number: undefined,
    copy_license_plate: undefined,
    copy_mc_engine_number: undefined,
    copy_buyer_name: undefined,
    copy_month: undefined,
    copy_day: undefined,
    copy_year_1: undefined,
    copy_year_2: undefined,
    copy_year_3: undefined,
    copy_year_4: undefined,
    copy_selling_price: undefined,
    copy_gift_value: undefined,
    copy_relationship_type: undefined,
    copy_seller_name: undefined,
    copy_seller_name_signature: undefined,
    copy_seller_name_signature_2: undefined,
    copy_sell_date: undefined,
    copy_sell_date_2: undefined,
    copy_seller_id: undefined,
    copy_seller_id_2: undefined,
    copy_seller_phone: undefined,
    copy_seller_zip: undefined,
    copy_seller_state: undefined,
    copy_seller_city: undefined,
    copy_seller_address: undefined,
    copy_buyer_print_name: undefined,
    copy_buyer_print_name_2: undefined,
    copy_buyer_address: undefined,
    copy_buyer_city: undefined,
    copy_buyer_state: undefined,
    copy_buyer_zip: undefined,
    copy_model: undefined
  }
  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  
  const moneyFields = useMemo(()=>{return["sale_price"]},[]);
  const phoneFields = useMemo(()=>{return[]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "model"]},[]);
  const checkboxes = useMemo(()=>{return[]},[]);

const handleDocumentValuesChange = e =>{
  const {name,value}=e.target;

  if(moneyFields.indexOf(name) >= 0){
    // To eliminate text being added
    setDocumentValues({...documentValues,[name]:removeChars(value)});
  }else if(phoneFields.indexOf(name) >= 0){
    setDocumentValues({...documentValues,[name]:removeChars(value)});
  }else if(upperCaseFields.indexOf(name) >= 0){
    const savedPos = e.target.selectionStart;
    setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
    setTimeout(() => {
      e.target.setSelectionRange(savedPos, savedPos);
    }, 0)
  }else{
    setDocumentValues({...documentValues,[name]:value});
  }
  setHasUnsavedChanges(true);
  setSaveSuccess(false);
}
  
  // const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('WABillOfSale.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])

  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      // setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, loadDocument ])
  const [assignedProduct, setAssignedProduct] = useState(undefined);
  const [assignedTrade, setAssignedTrade] = useState(undefined);

  // const [productVal, setproductVal] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  // const [tradeinVal, setTradeinVal] = useState(undefined);
  // const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  // const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState(undefined);
  // const [addressOptions, setAddressOptions] = useState(undefined);
  // const [inputAddressVal, setinputAddressVal] = useState(undefined);

  // const [locationVal, setLocationVal] = useState(undefined);
  const [selectedLocation, setselectedLocation] = useState(undefined);
  // const [inputLocationVal, setinputLocationVal] = useState(undefined);
  const [locationOptions, setLocationOptions] = useState(undefined);
  const [locationToggleAutoFill, setLocationToggleAutoFill] = useState(false);

// Dropdowns //

// if(order && order.products && order.products.length > 0 && productOptions === undefined){
//   let getProductOptions = [];
//   order.products.map(product => {
//     let str = `${product.stockNumber.toString().toUpperCase()}`;
//     let obj = {id: product.id, name: str}
//     getProductOptions.push(obj);
//   })
//   setproductOptions(getProductOptions);
// }

// if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
//   let getTradeinOptions = [];
//   order.tradeins.map(tradein => {
//     let str = `${tradein.year} ${tradein.make} ${tradein.model.toString().toUpperCase()}`;
//     let obj = {id: tradein.id, name: str}
//     getTradeinOptions.push(obj);
//   })
//   setTradeinOptions(getTradeinOptions);
// }

if(locations && locations.length > 0 && locationOptions === undefined){
  let getLocationOptions = [];
  locations.map(location => {
    let str = `${location.description}`;
    let obj = {locationCode: location.locationCode, description: str}
    getLocationOptions.push(obj);
    return null;
  })
  setLocationOptions(getLocationOptions);
}


// function getOrderLevelAddresses(order){
//   let getAddressOptions = [];
//   if(order.customer && order.customer.deliveryAddress){
//     var addressLine = '';
//     if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
//       addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
//     }else{
//       addressLine = order.customer.deliveryAddress.addressLine1;
//     }
//     let city = order.customer.deliveryAddress.city;
//     let state = order.customer.deliveryAddress.state;
//     let zip = order.customer.deliveryAddress.zip;

//     let obj = {slug: "customer-delivery", name: "Customer Delivery", address: addressLine, city: city, state: state, zip: zip};
//     getAddressOptions.push(obj);
//   }

//   if(order.customer && order.customer.mailingAddress){

//     if(order.customer.mailingAddress.addressLine2 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
//       addressLine = (order.customer.mailingAddress.addressLine1+" "+order.customer.mailingAddress.addressLine2);
//     }else{
//       addressLine = order.customer.mailingAddress.addressLine1;
//     }
//     let city = order.customer.mailingAddress.city;
//     let state = order.customer.mailingAddress.state;
//     let zip = order.customer.mailingAddress.zip;

//     let obj = {slug: "customer-mailing", name: "Customer Mailing", address: addressLine, city: city, state: state, zip: zip};
//     getAddressOptions.push(obj);
//   }
//   return getAddressOptions;
// }

 // Autofill //

const GlobalAutofill = useCallback(async () => {
  let newValues = WABillOfSaleGlobalAutofill(documentValues, order, null, null, locations);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }


  // var newValues = documentValues;

  //   newValues.seller_names = "TEC EQUIPMENT, INC.";

    
  //   if(locations && locations.length > 0 && order.tecLocationCode){
      
  //     let foundLocation = locations.find(l => l.locationCode === order.tecLocationCode);
  //     if(foundLocation){
  //       newValues.seller_address = foundLocation.address;
  //       newValues.seller_city = formatUpperCase(foundLocation.city);
  //       newValues.seller_state = foundLocation.state;
  //       newValues.seller_zip = foundLocation.zip;
  //     }
  //   }

  //   if(order && order.customer && order.customer.name){
  //     newValues.buyer_names = order.customer.name;
  //     newValues.buyer_address = order.customer.deliveryAddress.addressLine1;
  //     if(order.customer.deliveryAddress.addressLine2 && order.customer.deliveryAddress.addressLine2 !== ""){
  //       newValues.buyer_address += " "+order.customer.deliveryAddress.addressLine2;
  //     }
  //     newValues.buyer_city = formatUpperCase(order.customer.deliveryAddress.city);
  //     newValues.buyer_state = order.customer.deliveryAddress.state;
  //     newValues.buyer_zip = order.customer.deliveryAddress.zip;

  //     if(order.customer.cosigners && order.customer.cosigners.length > 0){
  //       let cosigner = order.customer.cosigners[0];
  //       if(cosigner && cosigner.name){
  //         newValues.buyer_names += " AND "+cosigner.name;
  //       }
  //     }
  //   }

  //   if(order && order.documentationDate){
  //     newValues.date_of_sale = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  //   }

  //   setDocumentValues(newValues);
  //   setHasUnsavedChanges(true);
  //   setSaveSuccess(false);
},[documentValues, locations, order])

// const preProcessPayload = useCallback((payload) => {

//   if(payload.sale_of_toggle){
//     // Reset all
//     payload.sale_vehicle_selected = "no";
//     payload.sale_vessel_selected = "no";

//     switch(payload.sale_of_toggle){
//       case "vehicle":
//         payload.sale_vehicle_selected = "yes";
//         break;
//       case "vessel":
//         payload.sale_vessel_selected = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.gift_toggle){
//     // Reset all
//     payload.gift_yes_selected = "no";
//     payload.gift_no_selected = "no";

//     switch(payload.gift_toggle){
//       case "yes":
//         payload.gift_yes_selected = "yes";
//         break;
//       case "no":
//         payload.gift_no_selected = "yes";
//         break;
//       default:
//     }
//   }

//   // X1.2- Field Type Specific Preview Formatting
//   Object.keys(payload).forEach(key => {
//     if(moneyFields.indexOf(key) >= 0){
//         payload[key] = formatMoneyValue(payload[key]);
//     }else if(phoneFields.indexOf(key) >= 0){
//         payload[key] = formatPhoneNumber(payload[key]);
//     }else if(upperCaseFields.indexOf(key) >= 0){
//         payload[key] = formatUpperCase(payload[key]);
//     }
//   });

//   // if(payload.selling_price){
//   //   payload.selling_price = payload['selling_price'].replace("$","");
//   // }

//   // if(payload.gift_value){
//   //   payload.gift_value = payload['gift_value'].replace("$","");
//   // }
  
//   setDocumentValues(JSON.parse(JSON.stringify(payload)));
//   Object.keys(payload).forEach(key => {
//     if(checkboxes.indexOf(key) >= 0){
//       payload[key] = payload[key] ? "yes": "no";
//     }
//   });
  

//   if(payload.date_of_sale){
//     payload.date_of_sale = moment.utc(payload.date_of_sale).format("MM/DD/YY");
//   }

//   return payload;
// },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])

const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });

    if(document){
      var docCopy = JSON.parse(JSON.stringify(document));
      docCopy.payload =  JSON.parse(JSON.stringify(payload));
      docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
      docCopy.payload =  JSON.stringify(docCopy.payload);
      delete docCopy.documentStatus;

      try {
        let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
          const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
          setdocumentUrl(fileURL)
      } catch (err) {
        recordFrontEndError('WABillOfSale.jsx', 'Error previewing document payload: ' + err)
      }
    }
},[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


const AutofillProductDetails = useCallback(async (selectedProduct) => {

  let newValues = WABillOfSaleAutofillProductDetails(selectedProduct, documentValues);

  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setSaveSuccess(false);
  }

  // var newValues = documentValues;
  // // newValues.year = (selectedProduct.year).trim();
  // newValues.model = (selectedProduct.model).trim().toUpperCase();
  // newValues.make = (selectedProduct.make).trim();
  // newValues.year = (selectedProduct.year);
  // newValues.vin = (selectedProduct.vin.toUpperCase());
  // newValues.sale_price = formatter.format(selectedProduct.price);

  // setDocumentValues(newValues);
  // // setProductToggleAutoFill(false);
  // if(document){
  //   previewPayloadDocument(documentValues);
  // }
  // // setAddressOptions(updateAddressOptions(selectedProduct, order));
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ document, documentValues, previewPayloadDocument ]);

const AutofillTradeDetails = useCallback(async (selectedTradein) => {

  let newValues = WABillOfSaleAutofillTradeDetails(selectedTradein, documentValues);

  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setSaveSuccess(false);
  }

  // var newValues = documentValues;
  // // newValues.year = (selectedProduct.year).trim();
  // newValues.make = (selectedTradein.make).trim();
  // newValues.model = (selectedTradein.year)
  // newValues.identification_number = (selectedTradein.vin.toUpperCase());

  // newValues.copy_make = (selectedTradein.make).trim();
  // newValues.copy_model = (selectedTradein.year)
  // newValues.copy_identification_number = (selectedTradein.vin.toUpperCase());
  

  // setDocumentValues(newValues);
  // // setProductToggleAutoFill(false);
  // if(document){
  //   previewPayloadDocument(documentValues);
  // }
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ document, documentValues, previewPayloadDocument ]);

  // Document BREAD + Preview // TODO: Move to Redux
  useEffect(() => {
    if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){
  
      existingPayload = true;
  
      if(documentPayload.date_of_sale){
        documentPayload.date_of_sale = moment.utc(documentPayload.date_of_sale).format("YYYY-MM-DD");
      }

      setDocumentValues(documentPayload);

      // Loading Use Effect
      if(document.additionalData){
        const methodMap = { 
          selectedAddress: setSelectedAddress, 
          selectedLocation: setselectedLocation, 
        };

        let parsedAdditionalData = JSON.parse(document.additionalData);
        Object.keys(parsedAdditionalData).forEach(function(key) {
          if(methodMap[key]){
            if(key === "selectedLocation"){
              // setLocationVal(parsedAdditionalData[key]);
              if(parsedAdditionalData[key].locationCode){
                setselectedLocation(locations.find((location) => location.locationCode === parsedAdditionalData[key].locationCode));
              }
            }else{
              methodMap[key](parsedAdditionalData[key]);
            }
          }
        });
      }
    }

    var foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }
    
    // setAddressOptions(getOrderLevelAddresses(order));
    if(foundAssignedProduct){
        setAssignedProduct(foundAssignedProduct);
        // setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
    }
    
    var foundAssignedTradein = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'tradein'){
      foundAssignedTradein = order.tradeins.find((trade) => trade.id === document.assignedObjectId);
    }
    
    if(foundAssignedTradein){
      setAssignedTrade(foundAssignedTradein);
    }
    
    
    if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
        if(foundAssignedTradein){
          AutofillTradeDetails(foundAssignedTradein)
        }
      }else{
        // if(document && document.itemIds){
        //   var itemIds = document.itemIds.split(',');
        //   var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
        //   let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
        //   let obj = {id: foundProduct.id, name: str}
        //   setproductVal(obj);
        //   if(foundProduct){
        //     setAddressOptions(updateAddressOptions(foundProduct, order));
        //   }
        //   streamDocument();
        // }
        
      }
      
      setDataLoaded(true);
    }
  },[ locations, documentValues, document, dataLoaded, order, AutofillProductDetails, AutofillTradeDetails, GlobalAutofill, previewPayloadDocument ])


  
    // function streamDocument(){
    //   axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
    //     .then(response => {
    //       const file = new Blob(
    //         [response.data], 
    //         {type: 'application/pdf'});
    //         const fileURL = URL.createObjectURL(file);
    //         // window.open(fileURL);
    //       setdocumentUrl(fileURL)
    //     }).catch(err => {
    //     })
    // }
  
    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();
  
      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
    
      // const payload = JSON.parse(JSON.stringify(documentValues));
      // var docCopy = document;
      // docCopy.payload = payload;
      // docCopy.payload = preProcessPayload(docCopy.payload);
      // docCopy.payload =  JSON.stringify(docCopy.payload);
      // delete docCopy.documentStatus;
      
      // if(selectedProduct && selectedProduct.id){
      //   docCopy.itemIds = `${selectedProduct.id}`;
      // }
      //Save Dropdown Selections
      let dropdownData = {};
      if(selectedAddress){
        dropdownData.selectedAddress = {
          id: selectedAddress.id,
          name: selectedAddress.name
        };
      }
      if(selectedLocation){
        dropdownData.selectedLocation = {
          locationCode: selectedLocation.locationCode,
          description: selectedLocation.description
        };
      }
      // docCopy.additionalData = JSON.stringify(dropdownData);
      let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, additionalData, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });
  
      //TODO: Add TradeId's to save.
      // try {
      //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
      //   if (res) {
      //     await axios.post(`/document/${document.id}/generate`)
      //     getDocuments();
      //     setSaveSuccess(true);
      //     setHasUnsavedChanges(false);
      //   }
      // } catch (err) {
      //   recordFrontEndError('WABillOfSale.jsx', 'Error saving document: ' + err)
      // }
    }

// Component Helpers //
// useEffect(() => {
  //Autofill Product Details After Selection

// },[ selectedProduct, productToggleAutoFill ])

// useEffect(() => {
//   //Autofill Tradein Details After Selection
//   if(selectedTradein !== undefined && tradeinToggleAutoFill){
//     var newValues = documentValues;
//     newValues.trade_year = (selectedTradein.year);
//     newValues.trade_make = (selectedTradein.make);
//     newValues.trade_model = (selectedTradein.model);
//     newValues.trade_body_type = (selectedTradein.seriesBodyType);
//     newValues.trade_serial_number = (selectedTradein.serialNumber);
//     newValues.balance_owner = (order.customer.name); //Need specific field for TRADE IN OWNER?
//     newValues.balance_owner_address = (order.customer.deliveryAddress.addressLine1);
//     newValues.amount = (selectedTradein.balanceOwed);
//     newValues.trade_allowance = (selectedTradein.tradeAllowanceAtTerms);
//     newValues.balance_owed = (selectedTradein.balanceOwed);
//     newValues.cash_refund = (selectedTradein.cashRefundToCustomer);
//     newValues.net_trade_allowance = (selectedTradein.finalTradeAllowance);
//     // newValues.cash_down_payment = (selectedTradein);

//     setDocumentValues(newValues);
//     setTradeinToggleAutoFill(false);
//     if(document){
//       previewPayloadDocument(documentValues);
//     }
//   }
// },[ selectedTradein, tradeinToggleAutoFill ])

useEffect(() => {
  //Autofill Tradein Details After Selection
  if(selectedLocation !== undefined && locationToggleAutoFill){
    var newValues = documentValues;
    newValues.seller_address = selectedLocation.address;
    newValues.seller_city = selectedLocation.city;
    newValues.seller_state = selectedLocation.state;
    newValues.seller_zip = selectedLocation.zip;
    newValues.seller_phone = formatPhoneNumber(selectedLocation.phone);

    // newValues.seller_id = "31422";

    // if(selectedLocation.state === "CA"){
    //   newValues.seller_name_signature = "TEC OF CALIFORNIA, INC";
    //   newValues.copy_seller_name_signature = "TEC OF CALIFORNIA, INC";
    // }else{
    //   newValues.seller_name_signature = "TEC EQUIPMENT, INC.";
    //   newValues.copy_seller_name_signature = "TEC EQUIPMENT, INC.";
    // }

    setDocumentValues(newValues);
    setLocationToggleAutoFill(false);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
},[ selectedLocation, locationToggleAutoFill, document, documentValues, previewPayloadDocument ])

//Autofill Address Details After Selection
// function AutoFillAddress(addressSelected, selectionInstance){
//   var newValues = documentValues;
//   if(selectionInstance){
//     if(selectionInstance === "buyer-address"){
//       newValues.buyer_address = addressSelected.address;
//       newValues.buyer_city = addressSelected.city;
//       newValues.buyer_state = addressSelected.state;
//       newValues.buyer_zip = addressSelected.zip;
//       if(order && order.customer){
//         newValues.buyer_print_name = order.customer.name;
//       }
//     }
//   }
//   setDocumentValues(newValues);
//   if(document){
//     previewPayloadDocument(documentValues);
//   }
//   setHasUnsavedChanges(true);
//   setSaveSuccess(false);
// }

const onCloseClick = e => {
  e.preventDefault()
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setdocumentUrl("");
  setDocument(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
  // setDocumentLoaded(false);
  setDataLoaded(false);
  setAssignedProduct(undefined);
  setAssignedTrade(undefined);
  setSelectedAddress(undefined);
  // setAddressOptions(undefined);
  // setinputAddressVal(undefined);
  // setLocationVal(undefined);
  setselectedLocation(undefined);
  // setinputLocationVal(undefined);
  setLocationOptions(undefined);
  setLocationToggleAutoFill(false);
  setDocumentFocus(undefined);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

  return (
    <>
      <div
        className="modal fade"
        // id="ca-bill-of-sale"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                WA - Bill of Sale
              </h5>
              <br />
              { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1 pt-1 text-white">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                  </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}
                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                  
                <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                    GlobalAutofill();
                    if(assignedProduct){
                      AutofillProductDetails(assignedProduct);
                    }
                    if(assignedTrade){
                        AutofillTradeDetails(assignedTrade);
                    }  
                    previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                </div>

                <br />

                { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Product Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                        </div>
                    </div>
                ):null}

                { assignedTrade ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Trade In Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                          <h5>VIN: { assignedTrade.vin.toUpperCase() }</h5>
                          <h5>YMM: { assignedTrade.year+" "+assignedTrade.make+" "+assignedTrade.model.toUpperCase() }</h5>
                        </div>
                    </div>
                ):null}

                  <div className="row">
                    <h5>Equipment</h5>
                  </div>

                  <div className="row">
                    <label htmlFor="stock"><b>Sale Of</b></label>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="sale_of_toggle"
                            value={(documentValues.sale_of_toggle ?? "none")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                          <FormControlLabel className="px-3" value="vehicle" control={<Radio />} label="Vehicle" />
                          <FormControlLabel className="px-3" value="vessel" control={<Radio />} label="Vessel" />
                          <FormControlLabel className="px-3" value="" control={<Radio />} label="None" />
                        </RadioGroup>
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">License Plate / Registration Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="license_num"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.license_num ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Identification Number</label>
                          <input
                            type="text"
                            className="form-control rounded-pill mt-1"
                            name="vin"
                            maxLength="17"
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                            value={ documentValues.vin ?? '' }
                            />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Model Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Model</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="model"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.model ?? '' }
                          />
                      </div>
                    </div>


                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Date of Sale</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date_of_sale"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date_of_sale ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Sale Price</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="sale_price"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.sale_price ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Seller Names</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_names"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_names ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Seller Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_address ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Seller City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Seller State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Seller Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_zip ?? '' }
                          />
                      </div>
                    </div>

                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Buyer Names</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_names"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_names ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Buyer Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_address ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Buyer City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Buyer State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Buyer Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_zip ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Are you an immediate family member receiving this vehicle as a gift?</label>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="gift_toggle"
                            value={(documentValues.gift_toggle ?? "none")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                          <FormControlLabel className="px-3" value="yes" control={<Radio />} label="Yes" />
                          <FormControlLabel className="px-3" value="no" control={<Radio />} label="No" />
                          <FormControlLabel className="px-3" value="" control={<Radio />} label="None" />
                        </RadioGroup>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">If Yes, what is your relationship to the seller?</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_relation"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_relation ?? '' }
                          />
                      </div>
                    </div>

                </div>
                <hr></hr>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default WABillOfSale

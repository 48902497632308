
import React from "react";

import Navbar from "../../../Components/Navbar/Navbar";
// import ConfigHeader from "./components/ConfigHeader";


const BugReport = () => {
  return (
    <div>
        <Navbar title="FAST Bug Reporting" />
        <div className="mx-auto text-center">
            {/* <iframe title="Bug Report" width="640px" height="480px" src="https://forms.microsoft.com/Pages/ResponsePage.aspx?id=x0sNgUB4i0yV-1rF3xvAE_56fN2tgQdMgyQzBAaFBLxUQU8xTTlHSkU3S0o5VVNFRFIwRlZNU1RJMC4u&embed=true" frameborder="0" marginwidth="0" marginheight="0" style={{ border: 'none', maxWidth: '100%', maxHeight:'100vh'}} allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe> */}
            <iframe title="Bug Report" width="640px" height="480px" src="https://forms.office.com/r/GVkxKJG8fS?embed=true" frameborder="0" marginwidth="0" marginheight="0" style={{ border: 'none', maxWidth: '100%', maxHeight:'100vh'}} allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe>
        </div>
    </div>
  );
};

export default BugReport;

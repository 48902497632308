import React, {useState} from "react";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Accordion, Card, Dropdown } from "react-bootstrap";
import { Divider } from '@mui/material';
// import { Menu } from "@material-ui/icons";

import DownpaymentInfoTemplate from "./DownpaymentInfoTemplate";
import { rdx } from "../../../../../../redux/downpayments"
import { useOrder } from "../../../../../../redux/orders/order"
import { setActiveModal } from '../../../../../../redux/app/appSaver';

const DownPaymentSection = (props) => {

  const dispatch        = useDispatch()
  const history         = useHistory()
  const { id: orderId } = useParams()
  const order           = useOrder()
  const [selectedPayment, setSelectedPayment] = useState({})

  // const [ deleteDownpaymentId, setDeleteDownpaymentId ] = useState('')

  const deleteDownpayment = async (e, downpaymentId=null) => {
    e.preventDefault();

    dispatch(rdx.deleteDownpayment(orderId, downpaymentId))
    history.push(`/order/${ orderId }`);
  }

  return (
    <div>
    <div>
      <Accordion defaultActiveKey="0">
        {order && order.downPayments ? ( order.downPayments.map(downpayment => (
          <Card className="p-3">
            <DownpaymentInfoTemplate amount={ downpayment.amount } paymentMethod={ (downpayment.paymentMethod ? downpayment.paymentMethod.name : '') } status={ downpayment.status } documentNumber={ downpayment.documentNumber} dateReceived={ downpayment.dateReceived } >
              <Dropdown.Item
                data-bs-toggle="modal"
                data-bs-target="#downpaymentsmodal"
                onClick={(e) => {
                  dispatch(rdx.setIsOpen(true))
                  dispatch(rdx.setDownpaymentFocusId(downpayment.id));
                  dispatch(setActiveModal('downpayment'));
                }}>
                <b>Edit Down Payment</b>
              </Dropdown.Item>
              <Divider />
              <Dropdown.Item
                data-bs-toggle="modal"
                data-bs-target="#downpaymentsmodaldeleteconfirm"
                onClick={e => setSelectedPayment(downpayment)}
                >
                <b>Delete Down Payment</b> 
              </Dropdown.Item>
            </DownpaymentInfoTemplate>
             <Accordion.Collapse eventKey={downpayment.id} className="p-3">
              <div>
              </div>
            </Accordion.Collapse>
          </Card>
        ))):('')}
      </Accordion>
    </div>
    <div
      className="modal fade"
      id="downpaymentsmodaldeleteconfirm"
      tabIndex="-1"
      aria-labelledby="exampledownpaymentmodel"
      aria-hidden="true"
      data-bs-backdrop="static" 
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header bg-primary text-white">
            <h5
              className="modal-title text-capitalize"
              id="exampledownpaymentmodel"
            >
              Delete Down Payment
            </h5>
            <button
              type="button"
              className="btn-close bg-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="px-3 py-3">
              <h4 className="container text-secondary text-center my-3">
                Are you sure you want to delete this down payment?
              </h4>
            </div>
          </div>
          <div className="modal-footer text-center">
            <div className="mx-auto">
              <button
                type="button"
                className="btn btn-primary mx-3"
                onClick={(e) => deleteDownpayment(e,selectedPayment.id)}
                data-bs-dismiss="modal"
              >
                Delete
              </button>
                <button
                type="button"
                className="btn btn-secondary mx-3"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default DownPaymentSection;

import React from "react";
// import { useSelector } from "react-redux";
import * as ProductCalculators from "../../../../../../utlis/productCalculations";
import { useOrder } from "../../../../../../redux/orders/order"
import { formatter } from "../../../../../../utlis/formatters";

const ProductSummary = () => {

  const order = useOrder();

  // var formatter = new Intl.NumberFormat('en-US', {
  //   style: 'currency',
  //   currency: 'USD',
  // });

  return (
    <>
      { order && order.products && order.products.length > 0 ? (
          <div className="col-12">
              <div className="shadow-sm border-start border-5 border-primary p-2" style={{ fontSize: '12px'}}>
                  <h5 className="p-2">PRODUCT SUMMARY</h5>
                  <div className="row px-4">
                      <div className="col-12 d-flex justify-content-between">
                          <p className="text-uppercase col-md-2 px-1 mb-0" style={{ fontWeight: 'bolder' }}>YMM</p>
                          <p className="text-uppercase col-md-2 px-1 mb-0" style={{ textAlign: 'right', fontWeight: 'bolder' }}>PRICE</p>
                          <p className="text-uppercase col-md-2 px-1 mb-0" style={{ textAlign: 'right', fontWeight: 'bolder' }}>FET</p>
                          <p className="text-uppercase col-md-2 px-1 mb-0" style={{ textAlign: 'right', fontWeight: 'bolder' }}>TAX & FEE'S</p>
                          <p className="text-uppercase col-md-2 px-1 mb-0" style={{ textAlign: 'right', fontWeight: 'bolder' }}>WTY</p>
                          {/* <p className="text-uppercase col-md-2 px-1 mb-0" style={{ textAlign: 'right', fontWeight: 'bolder' }}>SUBTOTAL</p> */}
                          { order?.customer?.isExemptSalesTax === false ? (
                            <p className="text-uppercase col-md-2 px-1 mb-0" style={{ textAlign: 'right', fontWeight: 'bolder' }}>TAXABLE AMNT</p>
                          ):(
                            <p className="text-uppercase col-md-2 px-1 mb-0" style={{ textAlign: 'right', fontWeight: 'bolder' }}>SUBTOTAL</p>
                          )}
                      </div>
                  <hr style={{ margin: '5px'}}></hr>
                  </div>


                  { order && order.products ? (order.products.sort((a, b) => a.stockNumber?.localeCompare(b.stockNumber, undefined, { numeric: true })).map(product => (
                  <div className="row px-4" key={'p'+product.id}>
                      <div className="col-12 d-flex justify-content-between">
                          <p className="text-uppercase col-md-2 px-1">{ product.type.toString().toUpperCase() } { product.year.replace('"', '').replace('"', '') } { product.make.toString().toUpperCase() } { product.model.toString().toUpperCase() }</p>
                          <p className="text-uppercase col-md-2 px-1" style={{ textAlign: 'right' }}>{ formatter.format(getTotalSalesPrice(product)) }</p>
                          {(product.type.toString().toUpperCase() === 'NEW' && product.lineItemFee && product.lineItemFee.applyFet === true) ? (
                          <p className="text-uppercase col-md-2 px-1" style={{ textAlign: 'right' }}>{ formatter.format((product.lineItemFee.fetTotal ?? 0)) }</p>
                          ) : (
                          <p className="text-uppercase col-md-2 px-1" style={{ textAlign: 'right' }}>EXEMPT</p>
                          )}
                          <p className="text-uppercase col-md-2 px-1" style={{ textAlign: 'right' }}>{ formatter.format(calcTaxFeeSubtotal(order, product)) }</p>
                          <p className="text-uppercase col-md-2 px-1" style={{ textAlign: 'right' }}>{ formatter.format(calculateProductWarrantyTotal(product)) }</p>
                          { order?.customer?.isExemptSalesTax === false ? (
                            <p className="text-uppercase col-md-2 px-1" style={{ textAlign: 'right' }}>{ formatter.format(vehicleTaxableAmountUpgradeNoAdmin(product, order)) }</p>
                          ):(
                            <p className="text-uppercase col-md-2 px-1" style={{ textAlign: 'right' }}>{ formatter.format(calculateProductSubtotal(order, product)) }</p>
                          )}
                      </div>
                  </div>
                  ))):('')}

              </div>
          </div>
      ) : ('')}
    </>
  );
};
const {
  getTotalSalesPrice,
  vehicleTaxableAmountUpgradeNoAdmin,
  calcTaxFeeSubtotal,
  calculateProductWarrantyTotal,
  calculateProductSubtotal
} = ProductCalculators

export default ProductSummary;
  

import React, { useState, useEffect } from "react";
import { recordFrontEndError } from "../../../../../../../utlis/api";
import { useDispatch, useSelector} from "react-redux";
// import { useParams } from "react-router-dom";
import { Autocomplete, TextField, FormGroup, FormControlLabel, Checkbox, CircularProgress } from '@mui/material';
import States from './States';
import { editProductShipTo, editProductSoldTo } from "../../../../../../../redux/products";
import { updateProductShipSold, saveProductSection, getOtherProductsInGroup, filterDocumentsForProduct, filterProductDocumentsForOrder } from "../../../../../../../utlis/dataSaveAndCopyLibrary";
import { useOrder } from "../../../../../../../redux/orders/order"
import { setActiveModal, setActiveModalTab } from "../../../../../../../redux/app/appSaver";
import { updateCopySection, updateCopySubSection, updateObjectId, updateObjectGroupId, updateChangesArray, updateGroupChangesArray, updateStatus, updateSaveFunction } from "../../../../../../../redux/saveAndCopy"
// import * as api from "../../../../../../../utlis/api";
import { addTestingTag } from "../../../../../../../utlis/testingHelpers";

const ModalProductShipSold = ({ documents, product, preCloseClose }) => {
  const dispatch  = useDispatch();
  // let { id }      = useParams();
  const order = useOrder();

  const saveStatus = useSelector((state) => state?.copyChangesToDocumentsRedux.status);
  const changesArray = useSelector((state) => state?.copyChangesToDocumentsRedux.changesArray);

  let isEmpty = null;
  if((typeof product === 'object') && (product !== undefined) && (product !== null)) {
    isEmpty = Object.keys(product).length === 0;
  }

  let productExists = !isEmpty && (product !== undefined) && (product !== null);
 
  const [initValues, setInitValues] = useState({});
  const [shipToToggle, setShipToToggle] = useState(false);
  const [soldToToggle, setSoldToToggle] = useState(false);
  const [invalidSoldTo, setInvalidSoldTo] = useState(false);
  const [invalidShipTo, setInvalidShipTo] = useState(false);

  // function updateDataChangeArray(e){
  //   e.preventDefault();

  //   const { name: prop, value} = e.target;

  //   let splitProp = prop.split;
  //   let addressType = splitProp[0];
  //   let addressProp = splitProp[1];

  //   let doNotCopyProperties = [];

  //   if(changesArray && !doNotCopyProperties.includes(prop)){

  //     let newValues = JSON.parse(JSON.stringify(changesArray));
  
  //     if(newValues[prop]?.oldValue){

  //       newValues[prop] = {
  //         "newValue": value,
  //         "oldValue": newValues[prop].oldValue
  //       };

  //       dispatch(updateChangesArray(newValues));


  //     }else{


  //       const oldValue = product[addressType][addressProp];

  //       newValues[prop] = {
  //         "newValue": value,
  //         "oldValue": oldValue ? oldValue :"--"
  //       };

  //       dispatch(updateChangesArray(newValues));

  //     }

  //   }
  // }

  const [dataChangeArray, setDataChangeArray]   = useState([]);
  function updateDataChangeArray(e, passedProp=null, passedValue=null){
    e.preventDefault();

    let { name: prop, value} = e.target;
    let addressType = null;
    let addressProp = null;

    if(passedProp !== null){
      prop = passedProp;
    }

    if(passedValue !== null){
      value = passedValue;
    }


    if(prop.toString().includes('.')){
      let splitProp = prop.split('.');
      addressType = splitProp[0];
      addressProp = splitProp[1];
    


      let doNotCopyProperties = [];

      if(dataChangeArray && !doNotCopyProperties.includes(prop)){

        if(dataChangeArray[addressType+"."+addressProp]?.oldValue){

          setDataChangeArray((prev)=>({
            ...prev,
            [addressType+"."+addressProp]: {
              "newValue": value,
              "oldValue": dataChangeArray[addressType+"."+addressProp].oldValue
            }
          }))
          
        }else{
          const oldValue = product[addressType][addressProp];

          setDataChangeArray((prev)=>({
            ...prev,
            [addressType+"."+addressProp]: {
              "newValue": value,
              "oldValue": oldValue ? oldValue : '--'
            }
          }))

        }
      }

    }

  }
  const appData = useSelector((state) => state?.appData);

  useEffect(() => {

    if(appData?.activeModal === 'product' && appData?.activeModalTab === 'ship-sold'){
      dispatch(updateChangesArray(dataChangeArray));
    }

  }, [ appData, dataChangeArray, dispatch ])


  const onShipToToggle = e => {
    let {checked} = e.target


    if(!checked){
      setShipToToggle(false);
      dispatch(editProductShipTo({prop:'clear'}))
      setInitValues({...initValues, shipTo:null})
    }else{
      setShipToToggle(true);
      if (initValues?.shipTo) dispatch(editProductShipTo({prop:'reset', value:initValues.shipTo}))
      else dispatch(editProductShipTo({prop:'init'}))
    }

    if(dataChangeArray["shipTo.enabled"]?.oldValue){
      setDataChangeArray( (prev) => ({
        ...prev,
        "shipTo.enabled": {
          "newValue": checked === true ? "yes" : "no",
          "oldValue": dataChangeArray["shipTo.enabled"].oldValue
        }
      }));
    }else{
      setDataChangeArray( (prev) => ({
        ...prev,
        "shipTo.enabled": {
          "newValue": checked === true ? "yes" : "no",
          "oldValue": (product?.shipTo?.id ? "yes" : "no")
        }
      }));
    }

    dispatch(updateStatus('unsaved'));
  }

  const onSoldToToggle = e => {
    let {checked} = e.target

    if(!checked){
      setSoldToToggle(false);
      dispatch(editProductSoldTo({prop:'clear'}))
      setInitValues({...initValues, soldTo:null})
    }else{
      setSoldToToggle(true);
      if (initValues?.soldTo) dispatch(editProductSoldTo({prop:'reset', value:initValues.soldTo}))
      else dispatch(editProductSoldTo({prop:'init'}))

    }

    if(dataChangeArray["soldTo.enabled"]?.oldValue){
      setDataChangeArray( (prev) => ({
        ...prev,
        "soldTo.enabled": {
          "newValue": checked === true ? "yes" : "no",
          "oldValue": dataChangeArray["soldTo.enabled"].oldValue
        }
      }));
    }else{
      setDataChangeArray( (prev) => ({
        ...prev,
        "soldTo.enabled": {
          "newValue": checked === true ? "yes" : "no",
          "oldValue": (product?.soldTo?.id ? "yes" : "no")
        }
      }));
    }

    dispatch(updateStatus('unsaved'));
  }

  const stateAbbrev = States.map(state => {
    return state.abbreviation;
  });

  const formatZipCode = (value) => {
    if(value !== undefined) {
      let zipCode = value.replace(/[^\d]/g, "");
      zipCode = zipCode.trim().replace(/(\d{5})/gi,"$1-").replace(/-$/gi,"");
      if(zipCode.length > 10) {
        zipCode = zipCode.slice(0, 10);
      }
      return zipCode;
    }
  }

  const onEdit = (e, newValue=null, passedName=null) => {

    let {value, name} = e.target
    let prop = name;

    if(newValue){
      value = newValue;
    }

    if(passedName){
      prop = passedName;
    }
    
    let addressType = '';
    if(prop.includes('.')){
      let propArray = prop.split('.');
      if(propArray.length === 2){
        prop = propArray[1];
        addressType = propArray[0];
      }
      
    }

    if(prop === 'zip') value = formatZipCode(value);
    
    if(addressType === 'shipTo'){
      dispatch(editProductShipTo({value:value, prop:prop}))
    }

    if(addressType === 'soldTo'){
      dispatch(editProductSoldTo({value:value, prop:prop}))
    }

    updateDataChangeArray(e, passedName, newValue);

    dispatch(updateStatus('unsaved'));

  }

  const handleSubmit = async event => {
    event.preventDefault();

    dispatch(updateStatus('saving'));

    let shipToObj = {
      name:  product?.shipTo?.name.toUpperCase() ?? '',
      addressLine1: product?.shipTo?.addressLine1.toUpperCase() ?? '',
      addressLine2: product?.shipTo?.addressLine2.toUpperCase() ?? '',
      city: product?.shipTo?.city.toUpperCase() ?? '',
      state: product?.shipTo?.state ?? '',
      zip: product?.shipTo?.zip ?? '',
    }
    
    let soldToObj = {
      name: product?.soldTo?.name?.toUpperCase() ?? '',
      addressLine1: product?.soldTo?.addressLine1?.toUpperCase() ?? '',
      addressLine2: product?.soldTo?.addressLine2?.toUpperCase() ?? '',
      city: product?.soldTo?.city?.toUpperCase() ?? '',
      state: product?.soldTo?.state ?? '',
      zip: product?.soldTo?.zip  ?? '',
    }

    // Ship To
    if(shipToObj && product?.shipTo){
      if(shipToObj.name === undefined || shipToObj.name === ''  || shipToObj.addressLine1 === undefined || shipToObj.addressLine1 === '' || 
      shipToObj.city === undefined || shipToObj.city === '' || shipToObj.state === '' || shipToObj.state === undefined || shipToObj.state === null || 
      shipToObj.zip === undefined || shipToObj.zip === '' || shipToObj.zip === ''|| (product?.shipTo?.zip?.length !==5 && product?.shipTo?.zip?.length !==9)) {
        setInvalidShipTo(true);
        dispatch(updateStatus('failure'));
      }else {

          try {
            dispatch(updateCopySection('shipsold'));
            dispatch(updateObjectId(product.id));
            dispatch(updateObjectGroupId(product.productGroupId));

            if(product.shipTo && product.shipTo.id){
              dispatch(updateSaveFunction(updateProductShipSold));
              saveProductSection(event, order, product, 'ship-sold', changesArray, updateProductShipSold, () => {
                dispatch(updateStatus('saved'));
              });
            }else{
              dispatch(updateSaveFunction(updateProductShipSold));
              updateProductShipSold(event, order, product, () => {
                dispatch(updateStatus('saved'));
              }); 
            }

          } catch (err) {
            recordFrontEndError('ModalProductShipSold.js', 'Error saving ship to: ' + err )
          }
      }
    }
    // else if (!product?.shipTo){
    //   api.deleteShipTo(id, product.id)
    //   dispatch(updateStatus('saved'));
    // } 

    // Sold To
    if(shipToObj && product?.soldTo){
      if (soldToObj.name === undefined || soldToObj.name === ''  || soldToObj.addressLine1 === undefined || soldToObj.addressLine1 === '' || 
      soldToObj.city === undefined || soldToObj.city === '' || soldToObj.state === '' || soldToObj.state === undefined || soldToObj.state === null ||
      soldToObj.zip === undefined || soldToObj.zip === ''||(product?.soldTo?.zip?.length !==5 && product?.soldTo?.zip?.length !==9)) {
        setInvalidSoldTo(true);
        dispatch(updateStatus('failure'));
      }else {
        console.log('handleSubmit')

          try {
            dispatch(updateCopySection('shipsold'));
            dispatch(updateObjectId(product.id));
            dispatch(updateObjectGroupId(product.productGroupId));
            
            if(product?.soldTo !== null && product?.soldTo?.id > 0){
              dispatch(updateSaveFunction(updateProductShipSold));
              saveProductSection(event, order, product, 'ship-sold', changesArray, updateProductShipSold, () => {
                dispatch(updateStatus('saved'));
              });
            }else{
              dispatch(updateSaveFunction(updateProductShipSold));
              updateProductShipSold(event, order, product, () => {
                dispatch(updateStatus('saved'));
              }); 
            }
          } catch (err) {
            recordFrontEndError('ModalProductShipSold.js', 'Error saving ship to: ' + err )
          }
      }
    }
    // else if (!product?.soldTo){
    //   api.deleteSoldTo(id, product.id)
    //   dispatch(updateStatus('saved'));
    // } 
  
  }



  useEffect(() => {

    if(appData?.activeModal === 'product' && appData?.activeModalTab === 'ship-sold'){

      if(product && productExists && product.shipTo !== null) {
        setShipToToggle(true);
      }
      if(product && productExists && product.soldTo !== null) {
        setSoldToToggle(true);
      }

    }

  },[ appData, product,productExists, initValues ]);

  useEffect(()=>{

    if(appData?.activeModal === 'product' && appData?.activeModalTab === 'ship-sold'){

      if(product?.shipTo && !initValues?.shipTo ) setInitValues({...initValues, shipTo:product.shipTo});
      else if(product?.soldTo && !initValues?.soldTo ) setInitValues({...initValues, soldTo:product.soldTo});

    }

  },[ appData, initValues, product ])
  
  return (
    <>
      { appData?.activeModal === 'product' && appData?.activeModalTab === 'ship-sold' ? (
        <form>
          <div className="container">
            <div className="row border-bottom">
              <div className="d-flex text-capitalize justify-content-between m-2">
                <h5>Product Ship To - Sold To</h5>
                { saveStatus === 'unsaved' ? (
                    <div className="d-flex">
                      <p className="m-1">Save Changes?</p>
                      <SaveChangesYesButton onClick={handleSubmit} product={product} datatestid="product.shipsold.control.saveYes" />
                      <button className="m-1 btn btn-sm btn-danger" data-testid="product.shipsold.control.saveNo" aria-label="cancel" onClick={preCloseClose}>No</button>
                    </div>
                  ):(
                    <div>
                      { saveStatus === 'saving' ?
                        <CircularProgress style={{ placeSelf: "center" }} />
                      :( saveStatus === 'saved' ? (
                        <SavedProductButton product={product} documents={documents} preCloseClose={preCloseClose} />
                      ):(
                        <SaveProductButton onClick={handleSubmit} datatestid="product.shipsold.control.saveTop" product={product} />
                      ))}
                    </div>
                  )}
              </div>
            </div>
            <div className="row">
            <div className="mb-3 col-md-6">
              <div className="d-flex justify-content-start">
                <FormGroup className="m-2">
                <FormControlLabel control={
                  <Checkbox 
                    color="default" 
                    aria-label='ship to toggle'
                    checked={shipToToggle} 
                    onChange={(e) => { onShipToToggle(e) }} />} 
                    label="Ship To Address" 
                    data-testid="product.shipTo.toggleShipTo"
                    name="shipTo.enabled"
                  />
                </FormGroup>
              </div>
              { shipToToggle ? (
              <div>
                <div>
                  <label htmlFor="shipToName" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    aria-label="ship to name"
                    className="rounded-pill form-control "
                    value={product?.shipTo?.name??''}
                    name="shipTo.name"
                    data-testid="product.shipTo.name"
                    onChange={(e) => onEdit(e)}
                    />
                    <span style={{display: (invalidShipTo && (!Boolean(product?.shipTo?.name)||product?.shipTo?.name===''))?'block': 'none'}} className="text-danger small">Name is required.</span>
                </div>
                <div>
                  <label htmlFor="address1" className="form-label">
                    Address1
                  </label>
                  <input
                    type="text"
                    className="rounded-pill form-control "
                    aria-label="ship to address line 1"
                    data-testid="product.shipTo.addressLine1"
                    value={product?.shipTo?.addressLine1??''}
                    name="shipTo.addressLine1"
                    onChange={(e) => onEdit(e)}
                    />
                    <span style={{display: (invalidShipTo && (!Boolean(product?.shipTo?.addressLine1)||product?.shipTo?.addressLine1===''))?'block': 'none'}} className="text-danger small">Address is required.</span>
                </div>
                <div>
                  <label htmlFor="address2" className="form-label">
                    Address2
                  </label>
                  <input
                    type="text"
                    aria-label="ship to address 2"
                    data-testid="product.shipTo.addressLine2"
                    className="rounded-pill form-control "
                    value={product?.shipTo?.addressLine2 ?? ''}
                    name="shipTo.addressLine2"
                    onChange={(e) => onEdit(e)}
                    />
                </div>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="city" className="rounded-pill form-label">
                      City
                    </label>
                    <input
                      type="text"
                      aria-label="ship to city"
                      data-testid="product.shipTo.city"
                      className="rounded-pill form-control "
                      value={product?.shipTo?.city ?? ''}
                      name="shipTo.city"
                      onChange={(e) => onEdit(e)}
                      />
                      <span style={{display: (invalidShipTo && (!Boolean(product?.shipTo?.city)||product?.shipTo?.city===''))?'block': 'none'}} className="text-danger small">City is required.</span>
                  </div>
                  <div className="mb-3 col-md-6 mt-3">
                    <Autocomplete
                      value={product?.shipTo?.state ?? ''}
                      name="shipTo.state"
                      onChange={(e, newValue) => onEdit(e, newValue, 'shipTo.state')}
                      onInputChange={(e, newValue) => onEdit(e, newValue, 'shipTo.state')}
                      inputValue={product?.shipTo?.state ?? ''}
                      // onInputChange={(event, newInputValue) => {
                      //   dispatch(editProductShipTo({value:newInputValue, prop:'state'}))
                      // }}
                      aria-label='ship to state'
                      options={stateAbbrev}
                      sx={{ width: 150 }}
                      renderInput={(params) => <TextField {...addTestingTag(params, 'product.shipTo.state')} label="STATE" />}
                    />
                    <span style={{display: (invalidShipTo && (!Boolean(product?.shipTo?.state)||product?.shipTo?.state===''))?'block': 'none'}} className="text-danger small">State is required.</span>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="zip" className="form-label">
                      Zip
                    </label>
                    <input
                      type="text"
                      aria-label="ship to zip"
                      className="rounded-pill form-control"
                      value={formatZipCode(product?.shipTo?.zip) ?? ''}
                      name="shipTo.zip"
                      data-testid="product.shipTo.zip"
                      onChange={(e) => onEdit(e)}
                    />
                    <span style={{display: (invalidShipTo && (!Boolean(product?.shipTo?.zip)||(product?.shipTo?.zip==='') ||(product?.shipTo?.zip?.length !==5 && product?.shipTo?.zip?.length !==9)))? 'block': 'none'}} className="text-danger small">Zip is required and must be 5 or 9 digits long.</span>
                  </div>
                </div>
              </div>
              ):null}
            </div>

              <div className="mb-3 col-md-6">
                <div className="d-flex justify-content-start">
                <FormGroup className="m-2">
                  <FormControlLabel control={<Checkbox color="default" aria-label='sold to toggle' data-testid="product.soldTo.toggleSoldTo" checked={soldToToggle} name="soldTo.enabled" onChange={(e) => { onSoldToToggle(e) }} />} label="Sold To Address" />
                </FormGroup>
                </div>
                { soldToToggle ? (
                  <div>
                    <div>
                      <label htmlFor="soldToName" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        aria-label="sold to name"
                        className="rounded-pill form-control "
                        value={product?.soldTo?.name ?? ''}
                        data-testid="product.soldTo.name"
                        name="soldTo.name"
                        onChange={(e) => onEdit(e)}
                      />
                      <span style={{display: (invalidSoldTo && (!Boolean(product?.soldTo?.name)||product?.soldTo?.name===''))?'block': 'none'}} className="text-danger small">Name is required.</span>
                    </div>
                    <div>
                      <label htmlFor="address1" className="form-label">
                        Address1
                      </label>
                      <input
                        type="text"
                        aria-label="sold to address like 1"
                        data-testid="product.soldTo.addressLine1"
                        className="rounded-pill form-control "
                        value={product?.soldTo?.addressLine1 ?? ''}
                        name="soldTo.addressLine1"
                        onChange={(e) => onEdit(e)}
                      />
                      <span style={{display: (invalidSoldTo && (!Boolean(product?.soldTo?.addressLine1)||product?.soldTo?.addressLine1===''))?'block': 'none'}} className="text-danger small">Address is required.</span>
                    </div>
                    <div>
                      <label htmlFor="address2" className="form-label">
                        Address2
                      </label>
                      <input
                        type="text"
                        aria-label="sold to address line 2"
                        data-testid="product.soldTo.addressLine2"
                        className="rounded-pill form-control "
                        value={product?.soldTo?.addressLine2 ?? ''}
                        name="soldTo.addressLine2"
                        onChange={(e) => onEdit(e)}
                      />
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label htmlFor="city" className="rounded-pill form-label">
                          City
                        </label>
                        <input
                          type="text"
                          aria-label="sold to city"
                          data-testid="product.soldTo.city"
                          className="rounded-pill form-control "
                          value={product?.soldTo?.city ?? ''}
                          name="soldTo.city"
                        onChange={(e) => onEdit(e)}
                        />
                        <span style={{display:(invalidSoldTo && (!Boolean(product?.soldTo?.city)||product?.soldTo?.city===''))?'block': 'none'}} className="text-danger small">City is required.</span>
                      </div>
                      <div className="mb-3 col-md-6 mt-3">
                        <Autocomplete
                        value={product?.soldTo?.state ?? ''}
                        name="soldTo.state"
                        aria-label='sold to state'
                        onChange={(e, newValue) => onEdit(e, newValue, 'soldTo.state')}
                        onInputChange={(e, newValue) => onEdit(e, newValue, 'soldTo.state')}
                        inputValue={product?.soldTo?.state ?? ''}
                        options={stateAbbrev}
                        sx={{ width: 150 }}
                        renderInput={(params) => <TextField {...addTestingTag(params, 'product.soldTo.state')} label="STATE" />}
                      />
                      <span style={{display: (invalidSoldTo && (!Boolean(product?.soldTo?.state)||product?.soldTo?.state===''))?'block': 'none'}} className="text-danger small">State is required.</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label htmlFor="zip" className="form-label">
                          Zip
                        </label>
                        <input
                          type="text"
                          aria-label="sold to zip"
                          className="rounded-pill form-control"
                          data-testid="product.soldTo.zip"
                          value={product?.soldTo?.zip ?? ''}
                          name="soldTo.zip"
                          onChange={(e) => onEdit(e)}
                        />
                        <span style={{display: (invalidSoldTo && (!Boolean(product?.soldTo?.zip)||(product?.soldTo?.zip==='') ||(product?.soldTo?.zip?.length !==5 && product?.soldTo?.zip?.length !==9)))
                          ? 'block': 'none'}} className="text-danger small">Zip is required and must be 5 or 9 digits long.</span>
                      </div>
                    </div>
                  </div>
                ):null}
              </div>
            </div>
          </div>
          <div className="text-center">
            { saveStatus === 'saving' ?
              <CircularProgress style={{ placeSelf: "center" }} />
            :( saveStatus === 'saved' ? (
              <SavedProductButton product={product} documents={documents} preCloseClose={preCloseClose} />
            ):(
              <SaveProductButton onClick={handleSubmit} product={product} datatestid="product.shipsold.control.saveBottom" />
            ))}
          </div>
        </form>
      ):null}
    </>
  );
}

const SaveProductButton = ({ onClick, datatestid }) => {
  return (
    <>
      <button aria-label='Save' onClick={e => onClick(e)} data-testid={datatestid} className="btn btn-primary text-uppercase">Save</button>
    </>
  )
}

const SaveChangesYesButton = ({ onClick, datatestid }) => {
  return (
    <>
      <button aria-label='Yes' onClick={e => onClick(e)} data-testid={datatestid} className="btn btn-success text-uppercase">Yes</button>
    </>
  )
}

const SavedProductButton = ({ product, documents, preCloseClose }) => {

  const order   = useOrder();
  const dispatch  = useDispatch();
  const changesArray = useSelector((state) => state?.copyChangesToDocumentsRedux.changesArray);

  function filterDocuments(){
    let productDocuments = filterDocumentsForProduct(product.id, documents);
    let orderDocuments = filterProductDocumentsForOrder(product.id, documents);

    let allFilteredDocuments = orderDocuments.concat(productDocuments);
    return allFilteredDocuments;
  }

  function prepRedux(){
    dispatch(updateCopySection('product'));
    dispatch(updateCopySubSection('ship-sold'));
    dispatch(setActiveModalTab(null));
  }

  function prepGroupCopy(){
    dispatch(updateGroupChangesArray([changesArray]));
    dispatch(updateStatus('copy-to-group'));
    dispatch(setActiveModal('copy-to-product-group'));
  }

  function prepDocumentCopy(){
    let copyChangesArray = [];
    copyChangesArray.id = product.id;
    copyChangesArray.stockNumber = product.stockNumber;
    copyChangesArray.propertySaved = false;
    copyChangesArray.docsSavedCount = 0;
    Object.entries(changesArray).forEach( value => {
      copyChangesArray[value[0]] = value[1];
    });
    dispatch(updateGroupChangesArray([copyChangesArray]));
    dispatch(updateStatus('copy'));
    dispatch(setActiveModal('copy-to-documents'));
  }

  return (
    <>
      <span className="btn btn-sm btn-success text-uppercase mx-2">Saved Product Info</span>
      { getOtherProductsInGroup(order, product.id, product.productGroupId)?.length > 0 ? (
        <>
          <button aria-label='Copy to Group' 
            onClick={ (e)=>{
              e.preventDefault();
              prepRedux();
              prepGroupCopy();
              preCloseClose();
            }} data-bs-dismiss="modal" data-bs-toggle="modal" data-testid="product.shipsold.control.copyToGroup" data-bs-target="#copychangestogroupmodal" className="btn btn-primary text-uppercase mx-2">Copy to Group</button>
         </>
         ): filterDocuments()?.length > 0 ? (
        <>
          <button aria-label='Copy to Documents' 
            onClick={ (e)=>{
              e.preventDefault();
              prepRedux();
              prepDocumentCopy();
              preCloseClose();
            }} data-bs-dismiss="modal" data-bs-toggle="modal" data-testid="product.shipsold.control.copyToDocument" data-bs-target="#copychangestodocuments" className="btn btn-primary text-uppercase mx-2">Copy to Documents</button>

        </>
      ):null}
    </>
  )
}

export default ModalProductShipSold;

import React, { useState } from "react";
import {
  useParams,
} from "react-router-dom";
import {Grid, FormControl, Typography, Button, RadioGroup, FormControlLabel,Radio, CircularProgress, MenuItem } from '@material-ui/core';
import { InputLabel,Select } from '@mui/material'
import { useFetch } from "../../../../../../utlis/api";
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import LoopIcon from '@material-ui/icons/Loop';


const bgColor= '#0d6efd'
const PrintAllDocModal = ({ order}) => {
  const axios = useFetch()
  let { id } = useParams();
  const [docSet, setDocSet]=useState('')
  const [productId, setProductId]=useState('')
  const [documentUrl,setDocumentUrl]=useState(undefined)
  const [isLoading, setIsLoading]= useState(false)

  const chooseDocSet= (event) => {
    setProductId('')
    const {value} =event.target
    setDocSet(value)
    
  };




const printDoc = async ()=>{
  setIsLoading(true)
  switch(docSet) {

    case 'allDoc':
      try{
      const response = await axios.get(`/order/${id}/print-all-documents`, {responseType: 'blob'})
      const file = new Blob(
      [response.data],
      {type: 'application/pdf'},
      );
      const fileURL = URL.createObjectURL(file);
      setDocumentUrl(fileURL)
      }catch(err){
      }
      break;

    case 'order':
      try{
        const response = await axios.get(`/order/${id}/print-order-documents`, {responseType: 'blob'})
        const file = new Blob(
        [response.data],
        {type: 'application/pdf'},
        );
        const fileURL = URL.createObjectURL(file);
        setDocumentUrl(fileURL)
  }catch(err){
      console.error('zzz error in dropdown: ',err)
    }
      break;

    case 'product':
      if(productId!==''){
      try{
        const response = await axios.get(`/order/${id}/product/${productId}/print-all-documents`, {responseType: 'blob'})
        const file = new Blob(
        [response.data],
        {type: 'application/pdf'},
        );
        const fileURL = URL.createObjectURL(file);
        setDocumentUrl(fileURL)
      }catch(err){
      } 
      }
      break;

    case 'tradein':
      if(productId!==''){
        try{
          const response = await axios.get(`/order/${id}/tradein/${productId}/print-all-documents`, {responseType: 'blob'})
          const file = new Blob(
          [response.data],
          {type: 'application/pdf'},
          );
          const fileURL = URL.createObjectURL(file);
          setDocumentUrl(fileURL)
        }catch(err){
        } 
        }
      break;

    default:
    break
  }
  setIsLoading(false)
}

  const resetVals = (e) => {
    e.preventDefault()
    setTimeout(()=>{
      setDocSet('')
      setIsLoading(false)
      setProductId('')
      setDocumentUrl(undefined)
    }, 1000)
  }

const selectDoc=(e)=>{
let {value}= e.target
  setProductId(value)
}
  return (
    <>
      <div className="modal fade"
        id="printAllDocModal"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
        
      >
              <div className="modal-dialog modal-dialog-centered modal-lg" style={{maxWidth:'1100px'}} >
                <div className="modal-content">
                  <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                Print All Documents
              </h5>
              <br />
              <button
                type="button"
                className="btn-close bg-white"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={e => resetVals(e)}
            />
            </div>
              <Grid container display='flex' direction="column" alignItems="center" justifyContent='center' spacing={4} style={{margin:'20px 0', width:'auto'}}>

                  { documentUrl !== undefined && documentUrl !== '' && documentUrl ? (
                    
                    <Grid item container spacing={2} xs={12}> 
                      <Grid item xs={12} style={{height:'75vh'}} >
                      <embed height='100%' width='100%' src={documentUrl !== undefined ? documentUrl : null} />
                      </Grid>
                      <Grid item container justifyContent="center" xs={12}>
                        <Button
                        color='primary'
                        style={{backgroundColor:'#f44336'}}
                        variant='contained'
                        data-bs-dismiss="modal"
                        onClick={e=>resetVals(e)}
                        >
                          Close
                        </Button>
                      </Grid>
                    </Grid>
                    ):(
                    <>
                    <p>Only "Generated" documents will be included in print set.</p>
                    <Grid container item gap={2} alignItems='center' justifyContent='space-around'>
                      <Grid item xs={3} >
                        <Typography bold>Select Document Set to Print</Typography>
                      </Grid>
                      <Grid container item xs={3}>
                      <FormControl component="fieldset" fullWidth>
                      <RadioGroup
                      aria-label="documents"
                      name="document-select"
                      value={docSet}
                      onChange={(e)=>{
                        chooseDocSet(e)}}
                      >
                      <FormControlLabel  value='allDoc' label={<Typography><FolderIcon style={{color:bgColor}}/>&nbsp;&nbsp;All Documents</Typography>} control={<Radio color='primary'/>}/>
                      <FormControlLabel value='order' label={<Typography><DescriptionIcon style={{color:bgColor}}/>&nbsp;&nbsp;OrderDocs</Typography>} control={<Radio color='primary' />}/>
                      <FormControlLabel value='product' label={<Typography><DirectionsCarIcon style={{color:bgColor}}/>&nbsp;&nbsp;Product Documents</Typography>} control={<Radio color='primary' />}/>
                      <FormControlLabel value='tradein' label={<Typography><LoopIcon style={{color:bgColor}}/>&nbsp;&nbsp;Trade-In Documents</Typography>} control={<Radio color='primary' />}/>
                    </RadioGroup>
                      </FormControl>
                      </Grid>
                      {(docSet==='allDoc'||docSet===''||docSet==='order') ?(null):(
                        <Grid container item direction='column' xs={3}>
                          <FormControl fullWidth>
                          {docSet==='product' ? (
                            <InputLabel id='selectDocOption'>Select Product</InputLabel>
                          ) : (
                            <InputLabel id='selectDocOption'>Select Trade In</InputLabel>
                          )}
                            <Select
                              labelId='selectDocOption'
                              id='selectDoc'
                              label='Select'
                              value={productId}
                              onChange={e=>selectDoc(e)}
                            >
                              {docSet==='product'?
                                (order.products.map((option, i)=>{
                                  return (<MenuItem key={`product_${i}`} value={option.id}>{option.stockNumber}</MenuItem>)})
                              ):(docSet==='tradein'?(order.tradeins.map((option, i)=>{
                                return (<MenuItem key={`tradeIn_${i}`} value={option.id}>{option.vin}</MenuItem>)})):null)}
                          </Select>
                          </FormControl>  
                      </Grid>
                      )
                      }
                        
                    </Grid>
              <Grid container item justifyContent='center'>
              
            {isLoading?(<CircularProgress/>):(
              <Button 
              variant='contained'
              style={{backgroundColor:bgColor}}
              color='primary'
              onClick={printDoc}>
                Print Selected Document Set
              </Button>)}
                </Grid>
                </>)
                  }
              </Grid>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrintAllDocModal
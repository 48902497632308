import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { formatter, removeChars, formatUpperCase } from "../../../../../../../utlis/formatters";
import { getOrderLevelAddresses, updateAddressOptions } from "../../../../../../../utlis/documentHelpers";
import { useOrder } from "../../../../../../../redux/orders/order"
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { CAPTICertGlobalAutofill, CAPTICertAutofillProductDetails, preProcessPayload } from "../DocumentAutofill/CAPTICert"

const CAPTICert = ({document_id, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "ca-pti-cert";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  // const dispatch = useDispatch();

  // const stateAbbrev = States.map(state => {
  //   return state.abbreviation;
  // });

  const order = useOrder();

  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);

  // Data Structure & Init //
  
  var initialDocumentStructure = {
    pti_plate_number: undefined,
    vin: undefined, //
    year_make: undefined,
    date: undefined,
    phone: undefined, //
    app_title: undefined,
    app_transfer: undefined,
    app_addition: undefined,
    app_pti_plate_number: undefined,
    app_vin: undefined, //
    app_year_make: undefined,
    owner_name: undefined,
    owner_name_2: undefined,
    release_signature_date: undefined,
    release_signature_date_2: undefined,
    addition_signature_date: undefined,
    addition_signature_date_2: undefined,
    owner_line_1: undefined,
    owner_mailing_line_1: undefined,
    owner_line_2: undefined,
    owner_city: undefined,
    owner_mailing_city: undefined,
    owner_mailing_line_2: undefined,
    owner_state: undefined,
    owner_zip: undefined,
    owner_mailing_zip: undefined,
    owner_mailing_state: undefined,
    equipment_number: undefined,
    purchase_date: undefined,
    purchase_price: undefined, //
    gift: undefined,
    trade: undefined,
    owner_id: undefined,
    owner_id_2: undefined,
    owner_full_name: undefined,
    owner_full_name_2: undefined,
    owner_and: undefined,
    owner_or: undefined,
    new_owner_line_1: undefined,
    new_owner_line_2: undefined,
    new_owner_city: undefined,
    new_owner_state: undefined,
    new_owner_zip: undefined,
    new_owner_mailing_line_1: undefined,
    new_owner_mailing_line_2: undefined,
    new_owner_mailing_city: undefined,
    new_owner_mailing_state: undefined,
    new_owner_mailing_zip: undefined,
    owner_signature_date: undefined,
    owner_phone: undefined, //
    owner_phone_2: undefined, //
    owner_signature_date_2: undefined,
    legal_owner_name: undefined,
    elt_number: undefined,
    legal_owner_line_1: undefined,
    legal_owner_line_2: undefined,
    legal_owner_city: undefined,
    legal_owner_state: undefined,
    legal_owner_zip: undefined,
    lessee_address: undefined,
    buyer_name: undefined,
    sell_date: undefined,
    sell_date_2: undefined,
    rs_number: undefined,
    salesperson_number: undefined,
    rs_number_2: undefined,
    salesperson_number_2: undefined,
    agent_name: undefined,
    agent_name_2: undefined,
    dealer_name: undefined,
    dealer_name_2: undefined,
    dealer_number: undefined,
    dealer_number_2: undefined,
    buyer_name_2: undefined
  }
  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  // X1
  const removeFormatting = e =>{
    const {name,value}=e.target;
    setDocumentValues({...documentValues,[name]:removeChars(value)});
  }

  // X1 Reference Arrays - TODO: Possibly move these to back end as part of the template stucture
  const moneyFields = useMemo(()=>{return["purchase_price"]},[]);
  const phoneFields = useMemo(()=>{return["phone", "owner_phone", "owner_phone_2"]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "app_vin"]},[]);
  const checkboxes = useMemo(()=>{return["app_title", "app_transfer", "app_addition"]},[]);


  // X1- Current General On Change
  const handleDocumentValuesChange = e =>{
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try{
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        } 
      }catch (err) {
        recordFrontEndError('CAPTICert.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])

  const [dataLoaded, setDataLoaded] = useState(false);
  
  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, documentLoaded, loadDocument ])

  const [assignedProduct, setAssignedProduct] = useState(undefined);
  const [selectedAddress1, setSelectedAddress1] = useState(undefined);
  const [selectedAddress2, setSelectedAddress2] = useState(undefined);
  const [selectedAddress3, setSelectedAddress3] = useState(undefined);
  const [selectedAddress4, setSelectedAddress4] = useState(undefined);
  const [selectedAddress5, setSelectedAddress5] = useState(undefined);
  const [selectedAddress6, setSelectedAddress6] = useState(undefined);
  const [addressOptions, setAddressOptions] = useState(undefined);
  const [inputAddressVal, setinputAddressVal] = useState(undefined);

 // Autofill //

 const GlobalAutofill = useCallback(async () => {
  let newValues = CAPTICertGlobalAutofill(documentValues, order);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  // var newValues = documentValues;
  // // if(order && order.customer && order.customer.name){
  // //   newValues.purchaser_name = order.customer.name;
  // // }
  
  // setDocumentValues(newValues);
},[documentValues, order])

// const preProcessPayload = useCallback((payload) => {

//   // X1.2- Field Type Specific Preview Formatting
//   Object.keys(payload).forEach(key => {
//     if(moneyFields.indexOf(key) >= 0){
//         payload[key] = formatMoneyValue(payload[key]);
//     }else if(phoneFields.indexOf(key) >= 0){
//         payload[key] = formatPhoneNumber(payload[key]);
//     }else if(upperCaseFields.indexOf(key) >= 0){
//         payload[key] = formatUpperCase(payload[key]);
//     }
//   });
//   setDocumentValues(JSON.parse(JSON.stringify(payload)));
//   Object.keys(payload).forEach(key => {
//     if(checkboxes.indexOf(key) >= 0){
//       payload[key] = payload[key] ? "yes": "no";
//     }
//   });

//     // Data point formatting
//     if(payload.date){
//       payload.date = moment.utc(payload.date).format("MM/DD/YY");
//     }      
    
//     // Data point formatting
//     if(payload.owner_signature_date){
//       payload.owner_signature_date = moment.utc(payload.owner_signature_date).format("MM/DD/YY");
//     }
  
//     if(payload.owner_signature_date_2){
//       payload.owner_signature_date_2 = moment.utc(payload.owner_signature_date_2).format("MM/DD/YY");
//     }

//     if(payload.date){
//       payload.date = moment.utc(payload.date).format("MM/DD/YY");
//     }

//   return payload;
// },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])

const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });
    
    if(document){

      
      var docCopy = JSON.parse(JSON.stringify(document));
      docCopy.payload =  JSON.parse(JSON.stringify(payload));
      docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
      docCopy.payload =  JSON.stringify(docCopy.payload);
      delete docCopy.documentStatus;
      
      try {
        let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
          const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
          setdocumentUrl(fileURL)
        } catch (err) {
          recordFrontEndError('CAPTICert.jsx', 'Error previewing document payload: ' + err)
        };  
    }
},[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])

const AutofillProductDetails = useCallback(async (selectedProduct) => {
  let newValues = CAPTICertAutofillProductDetails(selectedProduct, documentValues);

  if(newValues){
    setDocumentValues(newValues);
    previewPayloadDocument(documentValues);
    setHasUnsavedChanges(true);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setSaveSuccess(false);
  }

  // var newValues = documentValues;
  // // newValues.model = (selectedProduct.model).trim();
  // newValues.vin = (selectedProduct.vin);
  // newValues.year_make = (selectedProduct.year).trim()+" "+(selectedProduct.make).trim();
  
  // // newValues.model = (selectedProduct.model).trim();
  // newValues.app_vin = (selectedProduct.vin);
  // newValues.app_year_make = (selectedProduct.year).trim()+" "+(selectedProduct.make).trim();
  

  // setDocumentValues(newValues);
  // // setProductToggleAutoFill(false);
  // if(document){
  //   previewPayloadDocument(documentValues);
  // }  
  // // setAddressOptions(updateAddressOptions(selectedProduct, order));
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ document, documentValues, previewPayloadDocument ]);


  useEffect(() => {
    // Document BREAD + Preview // TODO: Move to Redux
    if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){
  
      existingPayload = true;

      if(documentPayload.date){
        documentPayload.date = moment.utc(documentPayload.date).format("YYYY-MM-DD");
      }
  
      if(documentPayload.owner_signature_date){
        documentPayload.owner_signature_date = moment.utc(documentPayload.owner_signature_date).format("YYYY-MM-DD");
      }

      if(documentPayload.owner_signature_date_2){
        documentPayload.owner_signature_date_2 = moment.utc(documentPayload.owner_signature_date_2).format("YYYY-MM-DD");
      }

      if(document.additionalData){
        const methodMap = { 
          selectedAddress1: setSelectedAddress1, 
          selectedAddress2: setSelectedAddress2, 
          selectedAddress3: setSelectedAddress3, 
          selectedAddress4: setSelectedAddress4, 
          selectedAddress5: setSelectedAddress5, 
          selectedAddress6: setSelectedAddress6 
        };

        let parsedAdditionalData = JSON.parse(document.additionalData);

        Object.keys(parsedAdditionalData).forEach(function(key) {
          if(methodMap[key]){
            methodMap[key](parsedAdditionalData[key]);
          }
        });
      }
  
      setDocumentValues(documentPayload);
      setHasUnsavedChanges(true);
      setSaveSuccess(false);
    }

    var foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }

      setAddressOptions(getOrderLevelAddresses(order));
      if(foundAssignedProduct){
        setAssignedProduct(foundAssignedProduct);
        setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
      }
  
      if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
      }else{
        // if(document && document.itemIds){
        //   var itemIds = document.itemIds.split(',');
        //     var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
        //     let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
        //     let obj = {id: foundProduct.id, name: str}
        //     setproductVal(obj);
        //     if(foundProduct){
        //       setAddressOptions(updateAddressOptions(foundProduct, order));
        //     }
        //     streamDocument();
        // }
  
      }
      
      setDataLoaded(true);
    }
  },[ documentValues, document, dataLoaded, AutofillProductDetails, GlobalAutofill, order, previewPayloadDocument ])



    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();

      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
  
  
      // const payload = JSON.parse(JSON.stringify(documentValues));
      // var docCopy = document;
      // docCopy.payload = payload;
      // docCopy.payload = preProcessPayload(docCopy.payload);
      // docCopy.payload =  JSON.stringify(docCopy.payload);
      // delete docCopy.documentStatus;
      
      // if(selectedProduct && selectedProduct.id){
      //   docCopy.itemIds = `${selectedProduct.id}`;
      // }

      //Save drop down selections
      let dropdownData = {};
      if(selectedAddress1){
        dropdownData.selectedAddress1 = {
          id: selectedAddress1.id,
          name: selectedAddress1.name
        };
      }
      if(selectedAddress2){
        dropdownData.selectedAddress2 = {
          id: selectedAddress2.id,
          name: selectedAddress2.name
        };
      }
      if(selectedAddress3){
        dropdownData.selectedAddress3 = {
          id: selectedAddress3.id,
          name: selectedAddress3.name
        };
      }
      if(selectedAddress4){
        dropdownData.selectedAddress4 = {
          id: selectedAddress4.id,
          name: selectedAddress4.name
        };
      }
      if(selectedAddress5){
        dropdownData.selectedAddress5 = {
          id: selectedAddress5.id,
          name: selectedAddress5.name
        };
      }
      if(selectedAddress6){
        dropdownData.selectedAddress6 = {
          id: selectedAddress6.id,
          name: selectedAddress6.name
        };
      }

      let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, additionalData, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });

      // docCopy.additionalData = JSON.stringify(dropdownData);
  
      // //TODO: Add TradeId's to save.
      // try {
      //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
      //   if (res) {
      //     axios.post(`/document/${document.id}/generate`)
      //     getDocuments();
      //     setSaveSuccess(true);
      //     setHasUnsavedChanges(false);
      //   }
      // } catch (err) {
      //   recordFrontEndError('CAPTICert.jsx', 'Error saving doc')
      // }
    }

//Autofill Address Details After Selection
function AutoFillAddress(addressSelected, selectionInstance){
  var newValues = documentValues;
  if(selectionInstance){
    if(selectionInstance === "physical-address-1"){
      newValues.owner_name = addressSelected.label;
      newValues.owner_line_1 = addressSelected.address;
      newValues.owner_city = addressSelected.city;
      newValues.owner_state = addressSelected.state;
      newValues.owner_zip = addressSelected.zip;
    }

    if(selectionInstance === "mailing-address-1"){
      newValues.owner_mailing_name = addressSelected.label;
      newValues.owner_mailing_line_1 = addressSelected.address;
      newValues.owner_mailing_city = addressSelected.city;
      newValues.owner_mailing_state = addressSelected.state;
      newValues.owner_mailing_zip = addressSelected.zip;
    }

    if(selectionInstance === "physical-address-2"){
      newValues.owner_full_name = addressSelected.label;
      newValues.new_owner_line_1 = addressSelected.address;
      newValues.new_owner_city = addressSelected.city;
      newValues.new_owner_state = addressSelected.state;
      newValues.new_owner_zip = addressSelected.zip;
    }

    if(selectionInstance === "mailing-address-2"){
      newValues.new_owner_mailing_line_1 = addressSelected.address;
      newValues.new_owner_mailing_city = addressSelected.city;
      newValues.new_owner_mailing_state = addressSelected.state;
      newValues.new_owner_mailing_zip = addressSelected.zip;
    }

    if(selectionInstance === "street-address"){
      newValues.legal_owner_name = addressSelected.label;
      newValues.legal_owner_line_1 = addressSelected.address;
      newValues.legal_owner_city = addressSelected.city;
      newValues.legal_owner_state = addressSelected.state;
      newValues.legal_owner_zip = addressSelected.zip;
    }

    if(selectionInstance === "lessee-address"){
      newValues.lessee_address = addressSelected.address+" "+addressSelected.city+", "+addressSelected.state+" "+addressSelected.zip;
    }
  }
  setDocumentValues(newValues);
  if(document){
    previewPayloadDocument(documentValues);
  }
  setHasUnsavedChanges(true);
  setSaveSuccess(false);
}

const onCloseClick = e => {
  e.preventDefault()
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setdocumentUrl("");
  setDocument(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
  setDocumentLoaded(false);
  setDataLoaded(false);
  setAssignedProduct(undefined);
  setSelectedAddress1(undefined);
  setSelectedAddress2(undefined);
  setSelectedAddress3(undefined);
  setSelectedAddress4(undefined);
  setSelectedAddress5(undefined);
  setSelectedAddress6(undefined);
  setAddressOptions(undefined);
  setinputAddressVal(undefined);
  setDocumentFocus(undefined);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

  return (
    <>
      <div
        className="modal fade"
        // id="ca-pti-cert"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                CA - PERMANENT TRAILER IDENTIFICATION (PTI) CERTIFICATION
              </h5>
              <br />
              { hasClickedClose ? (
                <div className="d-flex">
                    <p className="m-1 pt-1 text-white">Save Changes?</p>
                    <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                    <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}
                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit', fontSize: '12px'}}>
                
                <div className="text-center">
                  <button 
                  type="button"
                  className="btn btn-primary mx-auto"
                  onClick={() => {
                    GlobalAutofill();
                    AutofillProductDetails(assignedProduct);
                    previewPayloadDocument(documentValues);
                  }}>Reload Autofill Values</button>
                </div>
                <br />
                { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Product Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                        </div>
                    </div>
                ):(
                    <div className="m-2 p-2 border">
                        <span>No Assigned Product</span>
                    </div>
                )}
                  <div className="row">
                    <h5>Equipment</h5>
                  </div>

                  <div className="row">
                    <label htmlFor="stock" style={{ fontSize: '16px', fontWeight: 'bold'}}>PERMANENT TRAILER IDENTIFICATION (PTI) APPLICATION FOR:</label>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                            <FormControlLabel 
                              className="px-3" 
                              control={<input type="checkbox"
                                checked={ documentValues.app_title }
                                name="app_title"
                                onChange={handleDocumentValuesChange}
                                onBlur={(e) => updatePreview(e)}
                                />} 
                              label="Certificate of Title" />
                            <FormControlLabel 
                              className="px-3" 
                              control={<input type="checkbox"
                                checked={ documentValues.app_transfer }
                                name="app_transfer"
                                onChange={handleDocumentValuesChange}
                                onBlur={(e) => updatePreview(e)}
                                />} 
                              label="Transfer without Certificate of Title" />
                            <FormControlLabel 
                              className="px-3" 
                              control={<input type="checkbox"
                                checked={ documentValues.app_addition }
                                name="app_addition"
                                onChange={handleDocumentValuesChange}
                                onBlur={(e) => updatePreview(e)}
                                />} 
                              label="Addition of Legal Owner Without Certificate of Title" />
                        </FormControl>
                      </div>
                    </div>


                    <label htmlFor="stock" style={{ fontSize: '16px', fontWeight: 'bold'}}>1. TRAILER DESCRIPTION</label>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">PTI Plate Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="pti_plate_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.pti_plate_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Year / Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year_make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year_make ?? '' }
                          />
                      </div>
                    </div>

                    <label htmlFor="stock" style={{ fontSize: '16px', fontWeight: 'bold'}}>2. CERTIFICATION</label>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Phone</label>
                        <input
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          onFocus={removeFormatting}
                          className="form-control rounded-pill mt-1"
                          name="phone"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.phone ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>


                    <h5>PERMANENT TRAILER IDENTIFICATION (Page 2)</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">PTI Plate Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="app_pti_plate_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.app_pti_plate_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="app_vin"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.app_vin ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Year / Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="app_year_make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.app_year_make ?? '' }
                          />
                      </div>
                    </div>


                  <label htmlFor="stock" style={{ fontSize: '16px', fontWeight: 'bold'}}>1. TRAILER OWNER(S) OF RECORD</label>
                  
                  {addressOptions && addressOptions.length > 0 ? (  
                  <div className="d-flex justify-content-evenly pb-2">
                    <div className="mb-3 col-md-12 pt-4">
                      <Autocomplete
                        style={{ width: '100%'}}
                        value={ (selectedAddress1 && selectedAddress1.name) ?? null}
                        name="physical-address-1"
                        onChange={(event, newValue) => {
                          setSelectedAddress1(newValue);
                          AutoFillAddress(newValue, "physical-address-1");
                        }}
                        inputValue={inputAddressVal}
                        onInputChange={(event, newInputValue) => {
                          setinputAddressVal(newInputValue);
                        }}
                        options={addressOptions}
                        sx={{ width: 400 }}
                        renderInput={(params) => <TextField {...params} label="PHYSICAL ADDRESS" />}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.name}
                            </li>
                          );
                        }}
                      />
                    </div>
                  </div>
                  ):(
                    <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Addresses Found</h5>
                    </div>
                  )}
                  
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock" style={{ fontSize: '12px'}}>TRUE FULL NAME (LAST, FIRST, MIDDLE OR BUSINESS NAME)</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="owner_name"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.owner_name ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock" style={{ fontSize: '12px'}}>TRUE FULL NAME (LAST, FIRST, MIDDLE)</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="owner_name_2"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.owner_name_2 ?? '' }
                        />
                    </div>
                  </div>
                  {/* <hr></hr> */}

                  <div className="d-flex justify-content-evenly pb-2">
                    <div className="p-2 col-md-8">
                      <label htmlFor="stock">PHYSICAL ADDRESS (INCLUDE ST., AVE., RD., CT., ETC.)</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="owner_line_1"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.owner_line_1 ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">APT./SPACE/STE.</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="owner_line_2"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.owner_line_2 ?? '' }
                        />
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">City</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="owner_city"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.owner_city ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">State</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="owner_state"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.owner_state ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">Zip</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="owner_zip"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.owner_zip ?? '' }
                        />
                    </div>
                  </div>
                  <hr></hr>


                  {addressOptions && addressOptions.length > 0 ? (  
                  <div className="d-flex justify-content-evenly pb-2">
                    <div className="mb-3 col-md-12 pt-4">
                      <Autocomplete
                        style={{ width: '100%'}}
                        value={ (selectedAddress2 && selectedAddress2.name) ?? null}
                        name="mailing-address-1"
                        onChange={(event, newValue) => {
                          setSelectedAddress2(newValue);
                          AutoFillAddress(newValue, "mailing-address-1");
                        }}
                        inputValue={inputAddressVal}
                        onInputChange={(event, newInputValue) => {
                          setinputAddressVal(newInputValue);
                        }}
                        options={addressOptions}
                        sx={{ width: 400 }}
                        renderInput={(params) => <TextField {...params} label="MAILING ADDRESS" />}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.name}
                            </li>
                          );
                        }}
                      />
                    </div>
                  </div>  
                  ):(
                    <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Addresses Found</h5>
                    </div>
                  )}

                  <div className="d-flex justify-content-evenly pb-2">
                    <div className="p-2 col-md-8">
                      <label htmlFor="stock">MAILING ADDRESS (IF DIFFERENT FROM PHYSICAL ADDRESS)</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="owner_mailing_line_1"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.owner_mailing_line_1 ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">APT./SPACE/STE.</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="owner_mailing_line_2"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.owner_mailing_line_2 ?? '' }
                        />
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">City</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="owner_mailing_city"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.owner_mailing_city ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">State</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="owner_mailing_state"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.owner_mailing_state ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">Zip</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="owner_mailing_zip"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.owner_mailing_zip ?? '' }
                        />
                    </div>
                  </div>
                  <hr></hr>

                  {/* <label htmlFor="stock" style={{ fontSize: '16px', fontWeight: 'bold'}}>2. TRAILER OWNER(S) OF RECORD RELEASE OF OWNERSHIP</label> */}
                  {/* <label htmlFor="stock" style={{ fontSize: '16px', fontWeight: 'bold'}}>3. TRAILER OWNER(S) OF RECORD ADDING LEGAL OWNER ONLY</label> */}

                  <label htmlFor="stock" style={{ fontSize: '16px', fontWeight: 'bold'}}>4. NEW TRAILER OWNER(S) – Complete transfer within 10 days of taking possession of trailer</label>
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">PURCHASE PRICE/MARKET VALUE</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="purchase_price"
                        onFocus={removeFormatting}
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.purchase_price ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">PURCHASE DATE</label>
                      <input
                        type="date"
                        className="form-control rounded-pill mt-1"
                        name="purchase_date"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.purchase_date ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">EQUIPMENT NUMBER (OPTIONAL)</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="equipment_number"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.equipment_number ?? '' }
                        />
                    </div>
                  </div>


                  {addressOptions && addressOptions.length > 0 ? (  
                  <div className="d-flex justify-content-evenly pb-2">
                    <div className="mb-3 col-md-12 pt-4">
                      <Autocomplete
                        style={{ width: '100%'}}
                        value={ (selectedAddress3 && selectedAddress3.name) ?? null}
                        name="physical-address-2"
                        onChange={(event, newValue) => {
                          setSelectedAddress3(newValue);
                          AutoFillAddress(newValue, "physical-address-2");
                        }}
                        inputValue={inputAddressVal}
                        onInputChange={(event, newInputValue) => {
                          setinputAddressVal(newInputValue);
                        }}
                        options={addressOptions}
                        sx={{ width: 400 }}
                        renderInput={(params) => <TextField {...params} label="PHYSICAL ADDRESS" />}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.name}
                            </li>
                          );
                        }}
                      />
                    </div>
                  </div>                    
                  ):(
                    <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Addresses Found</h5>
                    </div>
                  )}


                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">TRUE FULL NAME (LAST, FIRST, MIDDLE OR BUSINESS NAME)</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="owner_full_name"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.owner_full_name ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">DRIVER LICENSE/ID CARD NUMBER</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="owner_id"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.owner_id ?? '' }
                        />
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">TRUE FULL NAME (LAST, FIRST, MIDDLE)</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="owner_full_name_2"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.owner_full_name_2 ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">DRIVER LICENSE/ID CARD NUMBER</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="owner_id_2"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.owner_id_2 ?? '' }
                        />
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly pb-2">
                    <div className="p-2 col-md-8">
                      <label htmlFor="stock">PHYSICAL ADDRESS (INCLUDE ST., AVE., RD., CT., ETC.)</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="new_owner_line_1"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.new_owner_line_1 ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">APT./SPACE/STE.</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="new_owner_line_2"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.new_owner_line_2 ?? '' }
                        />
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">City</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="new_owner_city"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.new_owner_city ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">State</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="new_owner_state"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.new_owner_state ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">Zip</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="new_owner_zip"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.new_owner_zip ?? '' }
                        />
                    </div>
                  </div>
                  <hr></hr>


                  {addressOptions && addressOptions.length > 0 ? (  
                  <div className="d-flex justify-content-evenly pb-2">
                    <div className="mb-3 col-md-12 pt-4">
                      <Autocomplete
                        style={{ width: '100%'}}
                        value={ (selectedAddress4 && selectedAddress4.name) ?? null}
                        name="mailing-address-2"
                        onChange={(event, newValue) => {
                          setSelectedAddress4(newValue);
                          AutoFillAddress(newValue, "mailing-address-2");
                        }}
                        inputValue={inputAddressVal}
                        onInputChange={(event, newInputValue) => {
                          setinputAddressVal(newInputValue);
                        }}
                        options={addressOptions}
                        sx={{ width: 400 }}
                        renderInput={(params) => <TextField {...params} label="MAILING ADDRESS" />}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.name}
                            </li>
                          );
                        }}
                      />
                    </div>
                  </div>                    
                  ):(
                    <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Addresses Found</h5>
                    </div>
                  )}


                  <div className="d-flex justify-content-evenly pb-2">
                    <div className="p-2 col-md-8">
                      <label htmlFor="stock">MAILING ADDRESS (IF DIFFERENT FROM PHYSICAL ABOVE)</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="new_owner_mailing_line_1"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.new_owner_mailing_line_1 ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">APT./SPACE/STE.</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="new_owner_mailing_line_2"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.new_owner_mailing_line_2 ?? '' }
                        />
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">City</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="new_owner_mailing_city"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.new_owner_mailing_city ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">State</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="new_owner_mailing_state"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.new_owner_mailing_state ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">Zip</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="new_owner_mailing_zip"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.new_owner_mailing_zip ?? '' }
                        />
                    </div>
                  </div>
                  <hr></hr>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">Signature Date</label>
                      <input
                        type="date"
                        className="form-control rounded-pill mt-1"
                        name="owner_signature_date"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.owner_signature_date ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">Daytime Telephone Number</label>
                      <input
                        type="tel"
                        pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                        onFocus={removeFormatting}
                        className="form-control rounded-pill mt-1"
                        name="owner_phone"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.owner_phone ?? '' }
                        />
                    </div>
                  </div>
                  <hr></hr>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">Signature Date (2)</label>
                      <input
                        type="date"
                        className="form-control rounded-pill mt-1"
                        name="owner_signature_date_2"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.owner_signature_date_2 ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">Daytime Telephone Number (2)</label>
                      <input
                        type="tel"
                        pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                        onFocus={removeFormatting}
                        className="form-control rounded-pill mt-1"
                        name="owner_phone_2"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.owner_phone_2 ?? '' }
                        />
                    </div>
                  </div>
                  <hr></hr>

                  <label htmlFor="stock" style={{ fontSize: '16px', fontWeight: 'bold'}}>5. NEW LEGAL OWNER (If no legal owner, write “NONE”)</label>
                  
                  {addressOptions && addressOptions.length > 0 ? (  
                  <div className="d-flex justify-content-evenly pb-2">
                    <div className="mb-3 col-md-12 pt-4">
                      <Autocomplete
                        style={{ width: '100%'}}
                        value={ (selectedAddress5 && selectedAddress5.name) ?? null}
                        name="street-address"
                        onChange={(event, newValue) => {
                          setSelectedAddress5(newValue);
                          AutoFillAddress(newValue, "street-address");
                        }}
                        inputValue={inputAddressVal}
                        onInputChange={(event, newInputValue) => {
                          setinputAddressVal(newInputValue);
                        }}
                        options={addressOptions}
                        sx={{ width: 400 }}
                        renderInput={(params) => <TextField {...params} label="NEW OWNER ADDRESS" />}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.name}
                            </li>
                          );
                        }}
                      />
                    </div>
                  </div>            
                  ):(
                    <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Addresses Found</h5>
                    </div>
                  )}

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">NAME OF NEW LEGAL OWNER</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="legal_owner_name"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.legal_owner_name ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">ELECTRONIC LIENHOLDER ID NUMBER</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="elt_number"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.elt_number ?? '' }
                        />
                    </div>
                  </div>
                  <hr></hr>
                  
                  <div className="d-flex justify-content-evenly pb-2">
                    <div className="p-2 col-md-8">
                      <label htmlFor="stock">NEW LEGAL ADDRESS - STREET OR P.O. BOX ADDRESS</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="legal_owner_line_1"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.legal_owner_line_1 ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">APT./SPACE/STE.</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="legal_owner_line_2"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.legal_owner_line_2 ?? '' }
                        />
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly mb-3">
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">City</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="legal_owner_city"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.legal_owner_city ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">State</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="legal_owner_state"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.legal_owner_state ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">Zip</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="legal_owner_zip"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.legal_owner_zip ?? '' }
                        />
                    </div>
                  </div>
                  <hr></hr>

                  {addressOptions && addressOptions.length > 0 ? (  
                  <div className="d-flex justify-content-evenly pb-2">
                    <div className="mb-3 col-md-12 pt-4">
                      <Autocomplete
                        style={{ width: '100%'}}
                        value={ (selectedAddress6 && selectedAddress6.name) ?? null}
                        name="lessee-address"
                        onChange={(event, newValue) => {
                          setSelectedAddress6(newValue);
                          AutoFillAddress(newValue, "lessee-address");
                        }}
                        inputValue={inputAddressVal}
                        onInputChange={(event, newInputValue) => {
                          setinputAddressVal(newInputValue);
                        }}
                        options={addressOptions}
                        sx={{ width: 400 }}
                        renderInput={(params) => <TextField {...params} label="LESSEE ADDRESS" />}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.name}
                            </li>
                          );
                        }}
                      />
                    </div>
                  </div>            
                  ):(
                    <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Addresses Found</h5>
                    </div>
                  )}

                  <label htmlFor="stock" style={{ fontSize: '16px', fontWeight: 'bold'}}>6. LEASED VEHICLES</label>
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                      <label htmlFor="stock" style={{ fontSize: '12px'}}>LESSEE ADDRESS (IF DIFFERENT FROM OWNER ADDRESS ABOVE)</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="lessee_address"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.lessee_address ?? '' }
                        />
                    </div>
                  </div>

                  <label htmlFor="stock" style={{ fontSize: '16px', fontWeight: 'bold'}}>7. DEALER’S RELEASE OF ACQUIRED VEHICLE</label>
                  
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">NAME OF BUYER</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="buyer_name"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.buyer_name ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">DATE SOLD</label>
                      <input
                        type="date"
                        className="form-control rounded-pill mt-1"
                        name="sell_date"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.sell_date ?? '' }
                        />
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">NAME OF DEALERSHIP</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="dealer_name"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.dealer_name ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">DEALER NUMBER</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="dealer_number"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.dealer_number ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">R/S NUMBER</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="rs_number"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.rs_number ?? '' }
                        />
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">PRINTED NAME OF DEALER AGENT</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="agent_name"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.agent_name ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">SALESPERSON NUMBER</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="salesperson_number"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.salesperson_number ?? '' }
                        />
                    </div>
                  </div>
                  <hr></hr>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">NAME OF BUYER (2)</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="buyer_name_2"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.buyer_name_2 ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">DATE SOLD (2)</label>
                      <input
                        type="date"
                        className="form-control rounded-pill mt-1"
                        name="sell_date_2"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.sell_date_2 ?? '' }
                        />
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">NAME OF DEALERSHIP (2)</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="dealer_name_2"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.dealer_name_2 ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">DEALER NUMBER (2)</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="dealer_number_2"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.dealer_number_2 ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-4">
                      <label htmlFor="stock">R/S NUMBER (2)</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="rs_number_2"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.rs_number_2 ?? '' }
                        />
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">PRINTED NAME OF DEALER AGENT (2)</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="agent_name_2"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.agent_name_2 ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">SALESPERSON NUMBER (2)</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="salesperson_number_2"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.salesperson_number_2 ?? '' }
                        />
                    </div>
                  </div>

                </div>
                <hr></hr>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default CAPTICert

import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const SDTitleAppGlobalAutofill = (documentValues, order, property=null, value=null, location) => {

  var newValues = documentValues;

  if(property?.includes('customer') || property === null){

    if(order && order.customer){

      if(order && order.customer && order.customer.name){

        newValues.type_toggle = "vehicle";
        newValues.title_type_toggle = "new";
        newValues.units_toggle = "miles";
        newValues.odometer_brand_toggle = "actual";

        let customerName = order.customer.name;
        newValues.owner_lessor_trust = customerName;
        newValues.d_mailing_name = customerName;
        newValues.e_contact_name = customerName;
        newValues.h_name_1 = customerName;
        newValues.h_name_2 = customerName;

        newValues.e_email_address = order.customer.email;
        newValues.e_phone_number = order.customer.phone;
    
        if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner){
            newValues.owner_lessor_trust_2 = cosigner.name;
          }
        }
      }
      
      if(order && order.customer && order.customer.deliveryAddress){
        newValues.address = order.customer.deliveryAddress.addressLine1;
        if(order.customer.deliveryAddress.addressLine1 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
          newValues.address = newValues.address+" "+order.customer.deliveryAddress.addressLine2;
        }
        newValues.city = order.customer.deliveryAddress.city;
        newValues.state = order.customer.deliveryAddress.state;
        newValues.zip_code = order.customer.deliveryAddress.zip;
      }

      if(order && order.customer && order.customer.mailingAddress){
        newValues.d_mailing_address = order.customer.mailingAddress.addressLine1;
        if(order.customer.mailingAddress.addressLine1 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
          newValues.d_mailing_address = newValues.d_mailing_address+" "+order.customer.mailingAddress.addressLine2;
        }
        newValues.d_mailing_city = order.customer.mailingAddress.city;
        newValues.d_mailing_state = order.customer.mailingAddress.state;
        newValues.d_mailing_zip = order.customer.mailingAddress.zip;
      }
    }

  }

  if(property?.includes('documentationDate') || property === null){
    
    if(order && order.documentationDate){
      newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }

  }

  if(property?.includes('location') || property === null){

    if(location){
      newValues.f_dealer_name = "TEC EQUIPMENT, INC.";
    }

  }

  return newValues
}

export const SDTitleAppAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
  var newValues = documentValues;

  if(property?.includes('vin') || property === null){
    newValues.vin_hin = (selectedProduct.vin).toString().toUpperCase();
  }

  if(property?.includes('year') || property === null){
    newValues.year = (selectedProduct.year);
  }

  if(property?.includes('make') || property === null){
    newValues.make = (selectedProduct.make).toString().toUpperCase();
  }

  if(property?.includes('model') || property === null){
    newValues.model = (selectedProduct.model).toString().toUpperCase();
  }

  if(property?.includes('vehicleType') || property === null){
    newValues.body_type = (selectedProduct.vehicleType);
  }

  if(property?.includes('price') || property === null){
    newValues.f_purchase_price = formatter.format(selectedProduct.price);
  }

  if(property?.includes('fundingDetail') || property?.includes('fundingMethod') || property?.includes('shipTo') || property === null){

    if(selectedProduct.fundingDetail && (selectedProduct.fundingDetail.fundingMethod === "tec-financing" || selectedProduct.fundingDetail.fundingMethod === "finance-only" || selectedProduct.fundingDetail.fundingMethod === "direct-deal")){
      newValues.g_lienholder_name_1 = selectedProduct.fundingDetail.finalizedLendingOption.bankName;
      newValues.g_lienholder_address_1 = selectedProduct.fundingDetail.finalizedLendingOption.lienAddress;
      newValues.g_lienholder_city_1 = selectedProduct.fundingDetail.finalizedLendingOption.lienCity;
      newValues.g_lienholder_state_1 = selectedProduct.fundingDetail.finalizedLendingOption.lienState;
      newValues.g_lienholder_zip_1 = selectedProduct.fundingDetail.finalizedLendingOption.lienZip;   
    }else if(selectedProduct.fundingDetail && selectedProduct.fundingDetail.fundingMethod === "cash-with-lien"){
      newValues.g_lienholder_name_1 = selectedProduct.fundingDetail.cashLienHolder.bankName;
      newValues.g_lienholder_address_1 = selectedProduct.fundingDetail.cashLienHolder.addressLine1+" "+selectedProduct.fundingDetail.cashLienHolder.addressLine2;
      newValues.g_lienholder_city_1 = selectedProduct.fundingDetail.cashLienHolder.city;
      newValues.g_lienholder_state_1 = selectedProduct.fundingDetail.cashLienHolder.state;
      newValues.g_lienholder_zip_1 = selectedProduct.fundingDetail.cashLienHolder.zip;
    }else if(selectedProduct.shipTo){
      newValues.g_lienholder_name_1 = selectedProduct.shipTo.name;
      newValues.g_lienholder_address_1 = selectedProduct.shipTo.addressLine1+" "+selectedProduct.shipTo.addressLine2;
      newValues.g_lienholder_city_1 = selectedProduct.shipTo.city;
      newValues.g_lienholder_state_1 = selectedProduct.shipTo.state;
      newValues.g_lienholder_zip_1 = selectedProduct.shipTo.zip;
    }

  }

  return newValues;
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  if(payload.type_toggle){
    // Reset all
    payload.vehicle_selected = "no";
    payload.boat_selected = "no";

    switch(payload.type_toggle){
      case "vehicle":
        payload.vehicle_selected = "yes";
        break;
      case "boat":
        payload.boat_selected = "yes";
        break;
      default:
    }
  }

  if(payload.title_type_toggle){
    // Reset all
    payload.new_title_selected = "no";
    payload.title_transfer_selected = "no";
    payload.repossession_selected = "no";
    payload.interstate_selected = "no";
    payload.oos_selected = "no";
    payload.abandoned_selected = "no";
    payload.operation_selected = "no";
    payload.underpaid_selected = "no";

    switch(payload.title_type_toggle){
      case "new":
        payload.new_title_selected = "yes";
        break;
      case "transfer":
        payload.title_transfer_selected = "yes";
        break;
      case "repo":
        payload.repossession_selected = "yes";
        break;
      case "interstate":
        payload.interstate_selected = "yes";
        break;
      case "oos-trans":
        payload.oos_selected = "yes";
        break;
      case "abandoned":
        payload.abandoned_selected = "yes";
        break;
      case "law":
        payload.operation_selected = "yes";
        break;
      case "unpaid-bill":
        payload.underpaid_selected = "yes";
        break;
      default:
    }
  }

  if(payload.brand_toggle){
    // Reset all
    payload.manu_buy_back_selected = "no";
    payload.manu_buy_rebuilt_selected = "no";
    payload.parts_only_selected = "no";
    payload.manu_buy_salvage_selected = "no";
    payload.manu_buy_junking_cert_selected = "no";
    payload.junk_cert_selected = "no";
    payload.salvage_selected = "no";
    payload.recovered_selected = "no";

    switch(payload.brand_toggle){
      case "man-buy-back":
        payload.manu_buy_back_selected = "yes";
        break;
      case "man-buy-back-rebuilt":
        payload.manu_buy_rebuilt_selected = "yes";
        break;
      case "parts":
        payload.parts_only_selected = "yes";
        break;
      case "man-buy-back-salv":
        payload.manu_buy_salvage_selected = "yes";
        break;
      case "man-buy-back-junk":
        payload.manu_buy_junking_cert_selected = "yes";
        break;
      case "junk-cert":
        payload.junk_cert_selected = "yes";
        break;
      case "salvage-total-loss":
        payload.salvage_selected = "yes";
        break;
      case "recovered-theft":
        payload.recovered_selected = "yes";
        break;
      default:
    }
  }

  if(payload.units_toggle){
    // Reset all
    payload.miles_selected = "no";
    payload.kilos_selected = "no";

    switch(payload.units_toggle){
      case "miles":
        payload.miles_selected = "yes";
        break;
      case "kilos":
        payload.kilos_selected = "yes";
        break;
      default:
    }
  }

  if(payload.odometer_brand_toggle){
    // Reset all
    payload.act_mileage_selected = "no";
    payload.exceeds_limits_selected = "no";
    payload.not_act_miles_selected = "no";

    switch(payload.odometer_brand_toggle){
      case "actual":
        payload.act_mileage_selected = "yes";
        break;
      case "exceeds":
        payload.exceeds_limits_selected = "yes";
        break;
      case "not-actual":
        payload.not_act_miles_selected = "yes";
        break;
      default:
    }
  }

  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  // if(payload.date_of_sale){
  //   payload.date_of_sale = moment.utc(payload.date_of_sale).format("MM/DD/YY");
  // }

  if(payload.date){
    payload.date = moment.utc(payload.date).format("MM/DD/YY");
  }

  return payload;
}
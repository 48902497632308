import moment from 'moment'
import { formatNameLastMiddleFirst } from "../../../../../../../utlis/formatters";

export const OregonBillOfSaleGlobalAutofill = (documentValues, order, property=null, value=null, location, allLocations=null, salesReps=null, unsuedparam=null, productCount=null, assignedObjectType=null ) => {

    var newValues = documentValues;

    if(property ==='customer.name'){
      let customerName
      if(order.customer.type === "entity"){
        customerName = value.toString().toUpperCase();
      }else{
        customerName = formatNameLastMiddleFirst(value).toString().toUpperCase();
      }
      if(order.customer.dba){
        customerName += " dba "+order.customer.dba;
      }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner && cosigner.name){
              customerName += " AND "+cosigner.name.toString().toUpperCase();
          }
      }
      if(assignedObjectType && assignedObjectType === 'product'){
        newValues.buyer_name_last_first_middle = customerName;
      }else if(assignedObjectType && assignedObjectType === 'tradein'){
        newValues.sellers_name = customerName;
      }
  }
    if(property ==='customer.dba'){
      let customerName
      if(order.customer.type === "entity"){
        customerName = order.customer.name.toString().toUpperCase();
      }else{
        customerName = formatNameLastMiddleFirst(value).toString().toUpperCase();
      }
        customerName += " dba "+value;
      if(assignedObjectType && assignedObjectType === 'product'){
        newValues.buyer_name_last_first_middle = customerName;
      }else if(assignedObjectType && assignedObjectType === 'tradein'){
        newValues.sellers_name = customerName;
      }
  }
    if(property ==='customer.cosigner[0].name'){
      let customerName
      if(order.customer.type === "entity"){
        customerName = order.customer.name.toString().toUpperCase();
      }else{
        customerName = formatNameLastMiddleFirst(value).toString().toUpperCase();
      }
      if(value && value.length > 0){
          let cosigner = value[0];
          if(cosigner && cosigner.name){
              customerName += " AND "+cosigner.name.toString().toUpperCase();
          }
      }
      if(assignedObjectType && assignedObjectType === 'product'){
        newValues.buyer_name_last_first_middle = customerName;
      }else if(assignedObjectType && assignedObjectType === 'tradein'){
        newValues.sellers_name = customerName;
      }
  }
    if(property ==='location'){
      let tecName
      let tecAddress
      if(value.state === "CA"){
        tecName = "TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
      }else{
        tecName = "TEC EQUIPMENT, INC.";
      }
      tecAddress = value.address+" "+value.city+" "+value.state+" "+value.zip;
      if(assignedObjectType && assignedObjectType === 'product'){
        newValues.sellers_name = tecName;
        newValues.sellers_address = tecAddress;
      }else if(assignedObjectType && assignedObjectType === 'tradein'){
        newValues.buyer_name_last_first_middle = tecName;
        newValues.buyers_address = tecAddress;
      }
  }
    if(property ==='customer.deliveryAddress.addressLine1'){
      let deliveryAddress = '';
      let addressLine = '';
      addressLine = value;
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        addressLine +=" "+order.customer.deliveryAddress.addressLine2;
      }
      addressLine = addressLine.toString().toUpperCase()+", "+order.customer.deliveryAddress.city.toString().toUpperCase()+", "+order.customer.deliveryAddress.state.toString().toUpperCase()+" "+order.customer.deliveryAddress.zip.toString().toUpperCase();
      deliveryAddress = addressLine;
      if(assignedObjectType && assignedObjectType === 'product'){
        newValues.buyers_address = deliveryAddress;
      }else if(assignedObjectType && assignedObjectType === 'tradein'){
        newValues.sellers_address = deliveryAddress;
    }
  }
    if(property ==='customer.deliveryAddress.addressLine2'){
      let deliveryAddress = '';
      let addressLine = (order.customer.deliveryAddress.addressLine1+" "+value);

      addressLine = addressLine.toString().toUpperCase()+", "+order.customer.deliveryAddress.city.toString().toUpperCase()+", "+order.customer.deliveryAddress.state.toString().toUpperCase()+" "+order.customer.deliveryAddress.zip.toString().toUpperCase();
      deliveryAddress = addressLine;
      if(assignedObjectType && assignedObjectType === 'product'){
        newValues.buyers_address = deliveryAddress;
      }else if(assignedObjectType && assignedObjectType === 'tradein'){
        newValues.sellers_address = deliveryAddress;
    }
  }
    if(property ==='customer.deliveryAddress.city'){
      let addressLine=order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2
      addressLine = addressLine.toString().toUpperCase()+", "+value.toString().toUpperCase()+", "+order.customer.deliveryAddress.state.toString().toUpperCase()+" "+order.customer.deliveryAddress.zip.toString().toUpperCase();
      let deliveryAddress = addressLine;
      if(assignedObjectType && assignedObjectType === 'product'){
        newValues.buyers_address = deliveryAddress;
      }else if(assignedObjectType && assignedObjectType === 'tradein'){
        newValues.sellers_address = deliveryAddress;
    }
  }
    if(property ==='customer.deliveryAddress.state'){
      let addressLine=order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2
      addressLine = addressLine.toString().toUpperCase()+", "+order.customer.deliveryAddress.city.toString().toUpperCase()+", "+value.toString().toUpperCase()+" "+order.customer.deliveryAddress.zip.toString().toUpperCase();
      let deliveryAddress = addressLine;
      if(assignedObjectType && assignedObjectType === 'product'){
        newValues.buyers_address = deliveryAddress;
      }else if(assignedObjectType && assignedObjectType === 'tradein'){
        newValues.sellers_address = deliveryAddress;
    }
  }
    if(property ==='customer.deliveryAddress.zip'){
      let addressLine=order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2
      addressLine = addressLine.toString().toUpperCase()+", "+order.customer.deliveryAddress.city.toString().toUpperCase()+", "+order.customer.deliveryAddress.state.toString().toUpperCase()+" "+value.toString().toUpperCase();
      let deliveryAddress = addressLine;
      if(assignedObjectType && assignedObjectType === 'product'){
        newValues.buyers_address = deliveryAddress;
      }else if(assignedObjectType && assignedObjectType === 'tradein'){
        newValues.sellers_address = deliveryAddress;
    }
  }
    if(property ==='documentationDate'){
      newValues.purchase_date = moment.utc(value).format("YYYY-MM-DD");
  }
    if(property !== null && value !== null){
      return newValues;
  }

    var customerName = '';
    if(order && order.customer && order.customer.name){

      if(order.customer.type === "entity"){
        customerName = order.customer.name.toString().toUpperCase();
      }else{
        customerName = formatNameLastMiddleFirst(order.customer.name).toString().toUpperCase();
      }

      if(order.customer.dba){
        customerName += " dba "+order.customer.dba;
      }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner && cosigner.name){
              customerName += " AND "+cosigner.name.toString().toUpperCase();
          }
      }
    }

    var tecName = '';
    var tecAddress = '';
    if(location){
      if(location.state === "CA"){
        tecName = " TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
      }else{
        tecName = "TEC EQUIPMENT, INC.";
      }
      tecAddress = location.address+" "+location.city+" "+location.state+" "+location.zip;
    }

    var deliveryAddress = '';
    if(order && order.customer && order.customer.deliveryAddress){
      var addressLine = '';
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
      }else{
        addressLine = order.customer.deliveryAddress.addressLine1;
      }

      addressLine = addressLine.toString().toUpperCase()+", "+order.customer.deliveryAddress.city.toString().toUpperCase()+", "+order.customer.deliveryAddress.state.toString().toUpperCase()+" "+order.customer.deliveryAddress.zip.toString().toUpperCase();
      deliveryAddress = addressLine;
      // newValues.buyers_address = addressLine;
    }

    if(assignedObjectType && assignedObjectType === 'product'){
      newValues.buyer_name_last_first_middle = customerName;
      newValues.buyers_address = deliveryAddress;

      newValues.sellers_name = tecName;
      newValues.sellers_address = tecAddress;
      
    }else if(assignedObjectType && assignedObjectType === 'tradein'){

      newValues.buyer_name_last_first_middle = tecName;
      newValues.buyers_address = tecAddress;

      newValues.sellers_name = customerName;
      newValues.sellers_address = deliveryAddress;

    }

    if(order && order.documentationDate && documentValues.purchase_date === undefined){
      newValues.purchase_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }
    return newValues
}

export const OregonBillOfSaleAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
    var newValues = documentValues;

    if(property === 'year' && value !== null){
      newValues.year = (value).toString().trim().replace(/['"]+/g, '');
  }

  if(property === 'make' && value !== null){
      newValues.make = (value).toString().trim();
  }

  if(property === 'model' && value !== null){
      newValues.model = (value).toString().toUpperCase();
  }

  if(property === 'vin' && value !== null){
    newValues.vin = (value).toString().toUpperCase();
}

  if(property === 'state' && value !== null){
    newValues.registration_province = (value);
}

  if(property !== null && value !== null){
      return newValues;
  }

  if(selectedProduct){

    
    newValues.year = (selectedProduct.year).trim().replace(/['"]+/g, '');
    newValues.make = (selectedProduct.make).trim();
    newValues.model = (selectedProduct.model).trim().toString().toUpperCase();
    newValues.vin = (selectedProduct.vin);
    newValues.registration_province = (selectedProduct.registrationAddress?.state);
    
  }

    return newValues
}


export const OregonBillOfSaleAutofillTradeinDetails = (selectedTradein, documentValues, property=null, value= null)=>{
  var newValues = documentValues;

  if(property === 'tradein.vin' && value !== null){
    newValues.vin = value;
  }

  if(property === 'tradein.year' && value !== null){
    newValues.year = (value).toString().trim().replace(/['"]+/g, '');
  }

  if(property === 'tradein.make' && value !== null){
      newValues.make = (value).toString().trim();
  }

  if(property === 'tradein.model' && value !== null){
      newValues.model = (value).toString().trim();
  }

  if(property === 'tradein.plateNumber' && value !== null){
    newValues.plate_Number = (value).toString().trim();
  }

  if(property !== null && value !== null){
      return newValues;
  }

  newValues.year = (selectedTradein.year).trim().replace(/['"]+/g, '');
  newValues.make = (selectedTradein.make).trim();
  newValues.model = (selectedTradein.model).trim().toString().toUpperCase();
  newValues.vin = (selectedTradein.vin);
  newValues.plate_number = (selectedTradein.plateNumber);
  return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  if(payload.purchase_date){
    payload.purchase_date = moment.utc(payload.purchase_date).format("MM/DD/YY");
  }

  return payload;
}
import React, { useState, useEffect, useCallback, useMemo } from "react";
import _ from "lodash"
// import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from 'moment'
import * as MaterialComponents from '@mui/material';

import { useFetch } from "../../../../../../utlis/api";
import { useOrder } from "../../../../../../redux/orders/order"
import { recordFrontEndError } from './../../../../../../utlis/api';
import { formatter } from "../../../../../../utlis/formatters";

const DocumentTradeScheduleModal = ({ initializeDocumentModal, setInitializeDocumentModal, document_id, location, getDocuments, passedDocumentSlug, locations, setDocumentFocus }) => {

  const documentSlug = "tec-invoice-trade-schedule";

  let { id }        = useParams();
  const order       = useOrder()
  const axios       = useFetch()

  var initialDocumentValues = useMemo(()=>{ return initialDocumentValues= {
    customer_name: undefined,
    date: undefined,
    location_address: undefined, // Header Location Details / System Autofill
    location_phone: undefined, // Header Location Details / System Autofill
    location_email: undefined, // Header Location Details / System Autofill
    tec_footer_label: undefined, // Footer / System Autofill
    pg_no: undefined, // Footer / System Autofill

  }},[]);

  const [ document, setDocument ]                           = useState(undefined);
  const [ documentValues, setDocumentValues ]               = useState(initialDocumentValues);
  const [ documentValuesPaginated, setDocumentValuesPaginated ] = useState([]);
  const [ documentUrl, setdocumentUrl ]                     = useState("");
  const [ selectedTradeins, setSelectedTradeins ]           = useState([]);
  const [ previewChanges, setPreviewChanges ]               = useState(false);
  const [ unsavedChanges, setUnsavedChanges ]               = useState(false);
  const [ invoiceTradeinOptions, setInvoiceTradeinOptions ] = useState([])
  const [ saveSuccess, setSaveSuccess ]                     = useState(false);
  const [ dataLoaded, setDataLoaded ]                       = useState(false);
  const [ autofillLoaded, setAutofillLoaded ]               = useState(false);
  const [updatingPreview, setUpdatingPreview]               = useState(false);
  // const [rotatedSuccess, setRotatedSuccess]              = useState(false);
  const [updateForm, setUpdateForm]                         = useState(0);

  // var formatter = new Intl.NumberFormat('en-US', {
  //   style: 'currency',
  //   currency: 'USD',
  // });

  const Autofill=useCallback(()=>{
    if(order){
      if(order.customer){
        if(documentValues.customer_name === undefined){
          documentValues['customer_name'] = order.customer.name;
          if(order.customer.dba){
            documentValues['customer_name'] = order.customer.name+' dba '+order.customer.dba;
          }
          if(documentValues.date === undefined){
            documentValues['date'] =  moment.utc().format("YYYY-MM-DD");
          }else{
            documentValues['date'] =  moment.utc(documentValues.date).format("YYYY-MM-DD");
          }
        }
      
        if(order.customer.cosigners && order.customer.cosigners.length > 0 && documentValues.co_signer === undefined){
          let cosigner = order.customer.cosigners[0];
          documentValues['co_signer'] = cosigner.name;
          if(documentValues.date_2){
            documentValues['date_2'] =  moment.utc(documentValues.date_2).format("YYYY-MM-DD");
          }
        }
      }
    }
    setAutofillLoaded(true);
  },[ documentValues, order ])


  function reloadCustomerValues(){
    let newValues = documentValues;
    if(order){
      if(order.customer){
        // if(documentValues.customer_name === undefined){
          newValues['customer_name'] = order.customer.name;
          if(order.customer.dba){
            newValues['customer_name'] = order.customer.name+' dba '+order.customer.dba;
          }
            newValues['date'] =  moment.utc().format("YYYY-MM-DD");
          
        // }
      
        if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          newValues['co_signer'] = cosigner.name;
          newValues['date_2'] =  moment.utc().format("YYYY-MM-DD");
        }
      }
    }
    setDocumentValues(newValues);
    setPreviewChanges(true);
    setUpdateForm((Number(updateForm) + 1));
  }

const loadDocument= useCallback(async(documentId)=>{
  if(documentId && documentId != null && documentId !== undefined){
    try {
      let res = await axios.get(`/order/${id}/document/${documentId}`)
      if(res?.data.documentTemplate.slug === documentSlug){
        setDocument(res?.data)
        setDataLoaded(false);
        setInvoiceTradeinOptions([]);
      }
    } catch (err) {
      recordFrontEndError('DocumentTradeScheduleModal.jsx', 'Error loading document: ' + err)
    }

  }
},[axios, id])

  

  //Each time the document_id we receive from our parent changes,
  //load the document...
  useEffect(() => {
    // if ( document_id && ( !document || document === undefined )) {
    if(document_id && passedDocumentSlug && passedDocumentSlug === documentSlug && ( !document || document === undefined || (document && document.id !== document_id))){
      setdocumentUrl("");
      setInvoiceTradeinOptions([]);
      setDocumentValues(initialDocumentValues);
      loadDocument(document_id);
    }else if(passedDocumentSlug && passedDocumentSlug === documentSlug && initializeDocumentModal){
      setdocumentUrl("");
      setInvoiceTradeinOptions([]);
      setInitializeDocumentModal(false);
      Autofill();
    }

  }, [ order, document_id, loadDocument, document, Autofill, autofillLoaded, documentSlug, initialDocumentValues, initializeDocumentModal, setInitializeDocumentModal, passedDocumentSlug ])

  const streamDocument=useCallback(async()=>{
    try {
      let response = await axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
      const file = new Blob(
        [response.data], 
        {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
      setdocumentUrl(fileURL)
    } catch(err) {
      recordFrontEndError('DocumentTradeScheduleModal.jsx', 'Error streaming document: ' + err)
    }
    
        
  },[axios, document])

  const loadData=useCallback(()=>{
    if(document){
      if(document.payload){
        var documentPayload = JSON.parse(document.payload);
        if(documentPayload && Object.keys(documentPayload).length > 0){
          setDocumentValues(documentPayload);
        }
      }
      streamDocument();
    }

    setDataLoaded(true);
  },[ document, streamDocument ])

  //Effect to load the data from the document
  //each time the document changes.
  useEffect(() => {
    if ( document && !dataLoaded ){
      loadData();

      if(document && document.itemIds){
        var itemIds = document.itemIds.split(',');
        var sproducts = [];
        itemIds.forEach(itemId => {
          var foundProduct = order.tradeins.find((trade) => Number(trade.id) === Number(itemId));
          let str = `${foundProduct.vin.toString().toUpperCase()}`;
          let obj = {id: foundProduct.id, name: str}
          sproducts.push(obj);
        })
        setSelectedTradeins(sproducts);
      }
    }
  }, [ document, loadData, order, dataLoaded ]);

  //Effect to calculate the invoiceProductOptions
  //based on products in the order.
  useEffect(() => {

    if ( _.isEmpty( order.tradeins ) === false && invoiceTradeinOptions && invoiceTradeinOptions.length === 0  ) {

      const invoiceTradeinOptions =
        order.tradeins.map(el => {
          if ( !el.invoiceId ) {
            return { id: el.id, name: (el.vin.toString().toUpperCase())};
          }
          return null;
        })

      setInvoiceTradeinOptions(invoiceTradeinOptions)
    }

  }, [ order, invoiceTradeinOptions ])

  const onSaveClick = event => {
    event.preventDefault();
    setUpdatingPreview(true);
    updateDocument(document_id);
  }
  
  async function getDocumentTemplateBySlug(slug){
    return await axios.get(`/document-template/${slug}/by-slug`);
  }
  
  // refactor nested axios calls for try catch
  async function updateDocument(documentId){
    setUpdatingPreview(true);
    if ( documentId == null || documentId === undefined ){
      
      getDocumentTemplateBySlug(documentSlug).then(response => {
        let invoiceTemplate = response?.data;
        if(invoiceTemplate && invoiceTemplate.id){
          let documentDraftData = {
            DocumentStatusId: 5, //Draft
            DocumentTemplateId: invoiceTemplate.id, //TODO Improve: Change out with found template tec-sales-order-schedule-a                                                                                
            OrderId: id,
            Payload: JSON.stringify({}),
          }
          
       
          axios.post(`/document`, documentDraftData).then((res) => {
            setDocument(res?.data);

            documentValuesPaginated.forEach(page => {
              if(page.date){
                page['date'] =  moment.utc(page.date).format("MM/DD/YYYY");
              }
            
              if(page.co_signer && page.co_signer !== ""){
                if(page.date_2){
                  page['date_2'] =  moment.utc(page.date_2).format("MM/DD/YYYY");
                }
              }else{
                page['date_2'] =  "";
              }
            })
            
            clearLines();
            const payloadString = JSON.stringify(documentValues);
            var docCopy = JSON.parse(JSON.stringify(res?.data));
            docCopy.payload = payloadString;
            delete docCopy.documentStatus;
            
            if(selectedTradeins && selectedTradeins.length > 0){
              var itemIds = [];
              selectedTradeins.forEach(tradein => {
                itemIds.push(tradein.id);
              })
              docCopy.itemIds = `${itemIds}`;
            }
  
            axios.put(`/document/${res?.data.id}/prepare`, docCopy).then(res => {
              axios.post(`/document/${docCopy.id}/generate-schedule`, documentValuesPaginated).then(resp => {
                setSaveSuccess(true);
                setUpdatingPreview(false);
                setUnsavedChanges(false);
              });
            }).catch(err => {
            });
          });

        }else{
          // Throw Error (Mark)
        }
      });



    }else{

      documentValuesPaginated.forEach(page => {
        if(page.date){
          page['date'] =  moment.utc(page.date).format("MM/DD/YYYY");
        }
      
        if(page.co_signer && page.co_signer !== ""){
          if(page.date_2){
            page['date_2'] =  moment.utc(page.date_2).format("MM/DD/YYYY");
          }
        }else{
          page['date_2'] =  "";
        }
      })

      // Update Document
      if(document){
        clearLines();
        const payloadString = JSON.stringify(documentValues);
        var docCopy = JSON.parse(JSON.stringify(document));
        docCopy.payload = payloadString;
        delete docCopy.documentStatus;
        
        if(selectedTradeins && selectedTradeins.length > 0){
          var itemIds = [];
          selectedTradeins.forEach(tradein => {
            itemIds.push(tradein.id);
          })
          docCopy.itemIds = `${itemIds}`;
        }

        await axios.put(`/document/${document.id}/prepare`, docCopy).then(res => {
          axios.post(`/document/${document.id}/generate-schedule`, documentValuesPaginated).then(res => {
            setSaveSuccess(true); 
            setUpdatingPreview(false);
            setUnsavedChanges(false);
          });
        }).catch(err => {
        });
      }
    }
  }

    
  const onCustomerNameChange = (newValue) => {
    let updatedObject = JSON.parse(JSON.stringify(documentValues));
    updatedObject.customer_name = newValue;
    setDocumentValues(updatedObject);
  }

  const onCoSignerChange = (newValue) => {
    let updatedObject = JSON.parse(JSON.stringify(documentValues));
    updatedObject.co_signer = newValue;
    setDocumentValues(updatedObject);
  }

  const onDateChange = (newValue) => {
    let updatedObject = JSON.parse(JSON.stringify(documentValues));
    updatedObject.date = newValue;
    setDocumentValues(updatedObject);
  }

  const onDate2Change = (newValue) => {
    let updatedObject = JSON.parse(JSON.stringify(documentValues));
    updatedObject.date_2 = newValue;
    setDocumentValues(updatedObject);
  }


  const generateInvoice = async event => {
    event.preventDefault();

    setUpdatingPreview(true);

    function clearLinesLocal(){
      //any lines missing?
      for (let i=1; i<=18; i++){
        documentValues[`stock_${i}`] = "";
        documentValues[`year_${i}`] = "";
        documentValues[`make_${i}`] = "";
        documentValues[`model_${i}`] = "";
        documentValues[`vin_${i}`] = "";
        documentValues[`balance_owed_to_${i}`] = "";
        documentValues[`balance_owed_${i}`] = "";
        documentValues[`cash_to_customer_${i}`] = "";
        documentValues[`trade_allowance_${i}`] = "";
        documentValues[`final_trade_allowance_${i}`] = "";
      }
    }
     
    // Get Location Address & Autofill Address
    if(location && location.address){
      documentValues['location_address'] = location.address+" "+location.city+" "+location.state+" "+location.zip;
    }
      
    if(location && location.phone){
      documentValues['location_phone'] = location.phone;
    }

    if(location && location.email){
      documentValues['location_email'] = location.email;
    }

    if(location && location.state){
      if(location.state === "CA"){
          documentValues['tec_footer_label'] = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
      }else{
          documentValues['tec_footer_label'] = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC";
      }
    }

    let totalBalanceOwed = 0;
    let totalAllowanceAtTerms = 0;
    let totalCashRefund = 0;
    let totalTradeFinal = 0;
    let documentPagesArray=[]
    let currentWriteLine=0
    selectedTradeins.forEach((item, i) => {
      
      currentWriteLine++

      let selectedProduct = item;
      let tradein = (order.tradeins.filter(item => {
        return item.id === selectedProduct.id
      }));
      if(tradein && tradein.length === 1) {
        tradein = tradein[0];
      };

      documentValues[`stock_${currentWriteLine}`] = tradein.serialNumber.toUpperCase();
      documentValues[`year_${currentWriteLine}`] = tradein.year;
      documentValues[`make_${currentWriteLine}`] = tradein.make.toUpperCase();
      documentValues[`model_${currentWriteLine}`] = tradein.model.toUpperCase();
      documentValues[`vin_${currentWriteLine}`] = tradein.vin.toUpperCase();
      documentValues[`trade_at_terms_${currentWriteLine}`] = formatter.format(tradein.tradeAllowanceAtTerms);
      documentValues[`trade_term_decutions_${currentWriteLine}`] = formatter.format(tradein.cashRefundToCustomer);
      
      if(tradein.balanceOwedTo && tradein.balanceOwed){
        documentValues[`balance_owed_to_${currentWriteLine}`] = tradein.balanceOwedTo;
        totalBalanceOwed += Number(tradein.balanceOwed)
        documentValues[`balance_owed_${currentWriteLine}`] = formatter.format(tradein.balanceOwed);
      }

      totalAllowanceAtTerms += Number(tradein.tradeAllowanceAtTerms)
      documentValues[`trade_allowance_${currentWriteLine}`] = formatter.format(tradein.tradeAllowanceAtTerms);
      
      totalCashRefund += tradein.cashRefundToCustomer
      documentValues[`cash_to_customer_${currentWriteLine}`] = formatter.format(tradein.cashRefundToCustomer);

      let rawFinalcTradeCredit = (Number(tradein.tradeAllowanceAtTerms) - Number(tradein.balanceOwed) - Number(tradein.cashRefundToCustomer));
      let finalTradeAllowance = 0;

      finalTradeAllowance =  Number(rawFinalcTradeCredit);

      totalTradeFinal += Number(finalTradeAllowance);
      documentValues[`final_trade_allowance_${currentWriteLine}`] = formatter.format(finalTradeAllowance);

      if(currentWriteLine === 18){
        documentPagesArray.push({...documentValues});
        clearLinesLocal();
        currentWriteLine = 0;
      }

      if(i === (selectedTradeins.length - 1) && currentWriteLine > 0 ){
        documentPagesArray.push({...documentValues});
        clearLinesLocal();
        currentWriteLine = 0;
      } 
    });

    documentPagesArray.forEach(page => {
        page.balance_owed_sum = formatter.format(totalBalanceOwed);
        page.trade_allowance_sum = formatter.format(totalAllowanceAtTerms);
        page.cash_to_customer_sum = formatter.format(totalCashRefund);
        page.final_trade_allowance_sum = formatter.format(totalTradeFinal);

        if(page.date){
          page['date'] =  moment.utc(page.date).format("MM/DD/YYYY");
        }
        
        if(page.co_signer && page.co_signer !== ""){
          if(page.date_2){
            page['date_2'] =  moment.utc(page.date_2).format("MM/DD/YYYY");
          }
        }
    });

    setDocumentValuesPaginated(documentPagesArray);
    try{
      let response = await axios
      .post(`/order/${id}/preview-schedule-paginated/${documentSlug}`,
      documentPagesArray,
        { responseType: 'blob' }
      )
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'},
        );
        const fileURL = URL.createObjectURL(file);
        documentPagesArray.forEach(page => {
          if(page.date){
            page['date'] =  moment.utc(page.date).format("YYYY-MM-DD");
          }
          
          if(page.co_signer && page.co_signer !== ""){
            if(page.date_2){
              page['date_2'] =  moment.utc(page.date_2).format("YYYY-MM-DD");
            }
          }
        });
        setdocumentUrl(fileURL);
        setPreviewChanges(false);
        setUnsavedChanges(true);
        setUpdatingPreview(false);
        setSaveSuccess(false);
    } catch(err){
      recordFrontEndError('DocumentTradeScheduleModal.jsx', 'Error generating trade-in invoice: ' +err )
    }
      

      
  }

  function clearLines(){
    let newValues = documentValues;

    let totalRows = 18;
    for(var row = 1; row <= totalRows; row++){
      newValues['stock_'+row] = '';
      newValues['year_'+row] = '';
      newValues['make_'+row] = '';
      newValues['model_'+row] = '';
      newValues['vin_'+row] = '';

      newValues['balance_owed_to_'+row] = '';
      newValues['balance_owed_'+row] = '';
      newValues['trade_allowance_'+row] = '';
      newValues['cash_to_customer_'+row] = '';
      newValues['final_trade_allowance_'+row] = '';;
    }

    newValues['balance_owed_sum'] = '';
    newValues['trade_allowance_sum'] = '';
    newValues['cash_to_customer_sum'] = '';
    newValues['final_trade_allowance_sum'] = '';

    setDocumentValues(newValues);
  }
 
  const onSelectedTradeinChange = (e, newValue) => {
    clearLines();
    setSelectedTradeins(newValue);
    setPreviewChanges(true);
    setSaveSuccess(false);
  }

  const resetVals = (e) => {
    // e.preventDefault();
    setDocumentValuesPaginated([]);
    setInvoiceTradeinOptions([]);
    setDocument(undefined);
    setdocumentUrl("");
    setSelectedTradeins([]);
    setPreviewChanges(false);
    setUnsavedChanges(false);
    setDocumentValues(initialDocumentValues);
    getDocuments(true);
    setUpdatingPreview(false);
    // setAttemptingRotate(false);
    // setRotatedSuccess(false);
    setSaveSuccess(false);
    setDataLoaded(false);
    setAutofillLoaded(false);
  }

  
  return (
    <>
      <div
        className="modal fade"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        {/* <div className="modal-dialog modal-dialog-centered modal-lg"> */}
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              { document ? (
                <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
                >
                  Edit Invoice - Trade-In Schedule
                </h5>
              ):(
                <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
                >
                  Generate Invoice - Trade-In Schedule
                </h5>
              )}
              <br />
              <button
                type="button"
                className="btn-close bg-white"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => resetVals(e)}
              ></button>
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
              <div className="container-fluid mt-0">
                <div className="row" style={{ height: '70vh'}}>
                  <div className="col-md-3 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}} key={updateForm}>
                    <div>
                      <button 
                      type="button"
                      className="btn btn-primary mx-auto"
                      onClick={() => {
                        reloadCustomerValues();
                      }}>Reapply Customer Values</button>
                    </div>
                    <br />

                    <br />
                    <Autocomplete
                      value={ selectedTradeins ?? null}
                      multiple
                      id="tags-outlined"
                      onChange={(event, newValue) => {
                        setSaveSuccess(false);
                        onSelectedTradeinChange(event, newValue);
                      }}
                      options={(invoiceTradeinOptions ?? [])}
                      getOptionLabel={(option) => option.name}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Tradeins"
                        />
                      )}
                    />
                    <br />
                    <div className="row">
                      <div className="col-md-12">
                        <label htmlFor="stock">Customer Name</label>
                        <input
                          value={ ((documentValues && documentValues.customer_name) ?? '') }
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="customer_name"
                          onChange={(e, newValue) => {
                            setSaveSuccess(false);
                            onCustomerNameChange(e.target.value);
                          }}
                          onBlur={(e) => {
                            setPreviewChanges(true);}}
                          />
                      </div>
                      </div>
                      <div className="row">
                      <div className="col-md-12">
                        <label htmlFor="stock">Date</label>
                        <input
                          value={ ((documentValues && documentValues.date) ?? '') }
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date"
                          onChange={(e, newValue) => {
                            setSaveSuccess(false);
                            onDateChange(e.target.value);
                          }}                        
                          onBlur={(e) => {
                            setPreviewChanges(true);}}
                          />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <label htmlFor="stock">Co-Signer Name</label>
                        <input
                          value={ ((documentValues && documentValues.co_signer) ?? '') }
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="co_signer"
                          onChange={(e, newValue) => {
                            setSaveSuccess(false);
                            onCoSignerChange(e.target.value);
                          }}
                          onBlur={(e) => {
                            setPreviewChanges(true);}}
                          />
                      </div>
                      </div>
                      <div className="row">
                      <div className="col-md-12">
                        <label htmlFor="stock">Date</label>
                        <input
                          value={ ((documentValues && documentValues.date_2) ?? '') }
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date_2"
                          onChange={(e, newValue) => {
                            setSaveSuccess(false);
                            onDate2Change(e.target.value);
                          }}                        
                          onBlur={(e) => {
                            setPreviewChanges(true);}}
                          />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9" style={{ overflowY: 'scroll'}}>
                    <h5>Invoice Preview               
                      { previewChanges ? (
                      <button className="btn btn-small btn-primary mx-3" 
                          type="button"
                          onClick={generateInvoice}
                          disabled={updatingPreview}
                          // data-bs-dismiss="modal"
                        >
                          Update Preview
                        </button>
                      ):null}
                    </h5>
                    { documentUrl !== "" && documentUrl !== undefined && documentUrl ? ( 
                      <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                    ):null}
                  </div>
                </div>
              </div>
            </div>
            { unsavedChanges ? (
            <div className="text-center m-3 mb-4">
              <button className="btn btn-danger mx-3" 
                type="button"
                // onClick={generateInvoice}
                data-bs-dismiss="modal"
                onClick={e => {
                  resetVals();
                }}
              >
                Cancel
              </button>
              {saveSuccess ? (
              <button className="btn btn-success mx-3" >
                Saved Invoice
              </button>
              ) : (
              <button className="btn btn-primary mx-3" 
                type="button"
                onClick={e => onSaveClick(e)}
                disabled={updatingPreview}
                // data-bs-dismiss="modal"
              >
                Save
              </button>
              ) }
            </div>
            ): (
              <div className="text-center m-3 mb-4">
              <button className="btn btn-primary mx-3" 
                type="button"
                // onClick={generateInvoice}
                data-bs-dismiss="modal"
                onClick={e => {
                  resetVals();
                }}
              >
                Close
              </button>
            </div>
            )}
            {/* {document && saveSuccess ? (
            <div className="text-center m-3 mb-4">
              <button className="btn btn-primary mx-3" 
                type="button"
                onClick={e => rotateForPrinting()}
                data-bs-dismiss="modal"
              >
                Rotate for Printing
              </button>
            </div>
            ): attemptingRotate ? (
              <div className="text-center m-3 mb-4"> Rotate for Printing </div>
            ):null} */}
          </div>
        </div>
      </div>
    </>
  );
}

const {
  Autocomplete,
  TextField,
} = MaterialComponents

export default DocumentTradeScheduleModal

import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  useParams
} from "react-router-dom";
import { useFetch,recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { removeChars, formatter, formatPhoneNumber, formatUpperCase } from "../../../../../../../utlis/formatters";
import { getAllNames } from "../../../../../../../utlis/documentHelpers";
import { useOrder } from "../../../../../../../redux/orders/order"
import {  Checkbox, FormGroup } from '@mui/material';
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { SDTitleAppGlobalAutofill, SDTitleAppAutofillProductDetails, preProcessPayload } from "../DocumentAutofill/SDTitleApp"

const SDTitleApp = ({document_id, getDocuments, passedDocumentSlug, locations, setDocumentFocus, location}) => {
  const documentSlug = "sd-title-app";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  // const dispatch = useDispatch();

  // const stateAbbrev = States.map(state => {
  //   return state.abbreviation;
  // });

  const order = useOrder();

  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");


  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);
  // const [ locations, setLocations ] = useState([]);//Redux
  const [ nameOptions, setNameOptions ] = useState([]);//Redux

  // Data Structure & Init //
  
  var initialDocumentStructure = {
  }

  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);

  const moneyFields = useMemo(()=>{return[]},[]);
  const phoneFields = useMemo(()=>{return[]},[]);
  const upperCaseFields = useMemo(()=>{return["vin_hin", "secondary_vin", "model", "f_vinhin_1", "f_vinhin_2"]},[]);
  const checkboxes = useMemo(()=>{return[
    "f_tax_exempt",
    "f_non_profit",
    "f_rental_vehicle",
    "f_title_only",
    "f_bill_of_sale"
  ]},[]);
  // const radios = [
  //   "type_toggle",
  //   "group_1_toggle",
  //   "group_2_toggle",
  // ];

  // X1- Current General On Change
  const handleDocumentValuesChange = e =>{
    // e.preventDefault();
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatPhoneNumber(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }

    // if(name === 'odometer'){
    //   setDocumentValues({...documentValues,[name]:removeChars(value)});
    // }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('SDTitleApp.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);
  

  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");


      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
      setNameOptions(getAllNames(order, null));
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, order, documentLoaded, loadDocument ])

  const [assignedProduct, setAssignedProduct] = useState(undefined);
  // const [selectedAddress1, setSelectedAddress1] = useState(undefined);
  // const [selectedAddress2, setSelectedAddress2] = useState(undefined);
  // const [selectedAddress3, setSelectedAddress3] = useState(undefined);
  // const [selectedAddress4, setSelectedAddress4] = useState(undefined);
  // const [selectedAddress5, setSelectedAddress5] = useState(undefined);
  // const [selectedAddress6, setSelectedAddress6] = useState(undefined);
  // const [addressOptions, setAddressOptions] = useState(undefined);
  // const [inputAddressVal, setinputAddressVal] = useState(undefined);


  // const [tradeinVal, setTradeinVal] = useState(undefined);
  const [selectedTradein, setselectedTradein] = useState(undefined);
  const [selectedTradein2, setselectedTradein2] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);


  const _filterOptions = createFilterOptions();

  const [optionCount, setOptionCount] = React.useState(0);
  const filterOptions = React.useCallback((options, state) => {
    const results = _filterOptions(options, state);

    if (optionCount !== results.length) {
      setOptionCount(results.length);
    }

    return results;
  }, [ _filterOptions, optionCount ]);

  if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
    let getTradeinOptions = [];
    order.tradeins.forEach(tradein => {
      let str = `${tradein.vin.toString().toUpperCase()}`;
      let obj = {id: tradein.id, name: str}
      getTradeinOptions.push(obj);
    })
    setTradeinOptions(getTradeinOptions);
  }

 // Autofill //

 const GlobalAutofill = useCallback(async () => {
  let newValues = SDTitleAppGlobalAutofill(documentValues, order, null, null, location);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  // var newValues = documentValues;
  
  // if(order && order.customer){

  //   if(order && order.customer && order.customer.name){

  //     // newValues.phone_number = order.customer.phone;

  //     newValues.type_toggle = "vehicle";
  //     newValues.title_type_toggle = "new";
  //     // newValues.brand_toggle = "";
  //     newValues.units_toggle = "miles";
  //     newValues.odometer_brand_toggle = "actual";

  //     let customerName = order.customer.name;
  //     newValues.owner_lessor_trust = customerName;
  //     newValues.d_mailing_name = customerName;
  //     newValues.e_contact_name = customerName;
  //     newValues.h_name_1 = customerName;
  //     newValues.h_name_2 = customerName;

  //     newValues.e_email_address = order.customer.email;
  //     newValues.e_phone_number = order.customer.phone;
  
  //     if(order.customer.cosigners && order.customer.cosigners.length > 0){
  //       let cosigner = order.customer.cosigners[0];
  //       if(cosigner){
  //         newValues.owner_lessor_trust_2 = cosigner.name;
  //       }
  //     }
  //   }
    
  //   if(order && order.customer && order.customer.deliveryAddress){
  //     // newValues.special_mailing_name_and_address = order.customer.name;
  //     newValues.address = order.customer.deliveryAddress.addressLine1;
  //     if(order.customer.deliveryAddress.addressLine1 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
  //       newValues.address = newValues.address+" "+order.customer.deliveryAddress.addressLine2;
  //     }
  //     newValues.city = order.customer.deliveryAddress.city;
  //     newValues.state = order.customer.deliveryAddress.state;
  //     newValues.zip_code = order.customer.deliveryAddress.zip;
  //   }

  //   if(order && order.customer && order.customer.mailingAddress){
  //     newValues.d_mailing_address = order.customer.mailingAddress.addressLine1;
  //     if(order.customer.mailingAddress.addressLine1 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
  //       newValues.d_mailing_address = newValues.d_mailing_address+" "+order.customer.mailingAddress.addressLine2;
  //     }
  //     newValues.d_mailing_city = order.customer.mailingAddress.city;
  //     newValues.d_mailing_state = order.customer.mailingAddress.state;
  //     newValues.d_mailing_zip = order.customer.mailingAddress.zip;
  //   }
  // }
  
  // if(order && order.documentationDate){
  //   newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  // }

  if(location){
    newValues.f_dealer_name = "TEC EQUIPMENT, INC.";
  }

  setDocumentValues(newValues);
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ documentValues, location, order ])

// const preProcessPayload = useCallback((payload) => {

//   if(payload.type_toggle){
//     // Reset all
//     payload.vehicle_selected = "no";
//     payload.boat_selected = "no";

//     switch(payload.type_toggle){
//       case "vehicle":
//         payload.vehicle_selected = "yes";
//         break;
//       case "boat":
//         payload.boat_selected = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.title_type_toggle){
//     // Reset all
//     payload.new_title_selected = "no";
//     payload.title_transfer_selected = "no";
//     payload.repossession_selected = "no";
//     payload.interstate_selected = "no";
//     payload.oos_selected = "no";
//     payload.abandoned_selected = "no";
//     payload.operation_selected = "no";
//     payload.underpaid_selected = "no";

//     switch(payload.title_type_toggle){
//       case "new":
//         payload.new_title_selected = "yes";
//         break;
//       case "transfer":
//         payload.title_transfer_selected = "yes";
//         break;
//       case "repo":
//         payload.repossession_selected = "yes";
//         break;
//       case "interstate":
//         payload.interstate_selected = "yes";
//         break;
//       case "oos-trans":
//         payload.oos_selected = "yes";
//         break;
//       case "abandoned":
//         payload.abandoned_selected = "yes";
//         break;
//       case "law":
//         payload.operation_selected = "yes";
//         break;
//       case "unpaid-bill":
//         payload.underpaid_selected = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.brand_toggle){
//     // Reset all
//     payload.manu_buy_back_selected = "no";
//     payload.manu_buy_rebuilt_selected = "no";
//     payload.parts_only_selected = "no";
//     payload.manu_buy_salvage_selected = "no";
//     payload.manu_buy_junking_cert_selected = "no";
//     payload.junk_cert_selected = "no";
//     payload.salvage_selected = "no";
//     payload.recovered_selected = "no";

//     switch(payload.brand_toggle){
//       case "man-buy-back":
//         payload.manu_buy_back_selected = "yes";
//         break;
//       case "man-buy-back-rebuilt":
//         payload.manu_buy_rebuilt_selected = "yes";
//         break;
//       case "parts":
//         payload.parts_only_selected = "yes";
//         break;
//       case "man-buy-back-salv":
//         payload.manu_buy_salvage_selected = "yes";
//         break;
//       case "man-buy-back-junk":
//         payload.manu_buy_junking_cert_selected = "yes";
//         break;
//       case "junk-cert":
//         payload.junk_cert_selected = "yes";
//         break;
//       case "salvage-total-loss":
//         payload.salvage_selected = "yes";
//         break;
//       case "recovered-theft":
//         payload.recovered_selected = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.units_toggle){
//     // Reset all
//     payload.miles_selected = "no";
//     payload.kilos_selected = "no";

//     switch(payload.units_toggle){
//       case "miles":
//         payload.miles_selected = "yes";
//         break;
//       case "kilos":
//         payload.kilos_selected = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.odometer_brand_toggle){
//     // Reset all
//     payload.act_mileage_selected = "no";
//     payload.exceeds_limits_selected = "no";
//     payload.not_act_miles_selected = "no";

//     switch(payload.odometer_brand_toggle){
//       case "actual":
//         payload.act_mileage_selected = "yes";
//         break;
//       case "exceeds":
//         payload.exceeds_limits_selected = "yes";
//         break;
//       case "not-actual":
//         payload.not_act_miles_selected = "yes";
//         break;
//       default:
//     }
//   }

//   Object.keys(payload).forEach(key => {
//     if(moneyFields.indexOf(key) >= 0){
//         payload[key] = formatMoneyValue(payload[key]);
//     }else if(phoneFields.indexOf(key) >= 0){
//         payload[key] = formatPhoneNumber(payload[key]);
//     }else if(upperCaseFields.indexOf(key) >= 0){
//         payload[key] = formatUpperCase(payload[key]);
//     }
//   });
//   setDocumentValues(JSON.parse(JSON.stringify(payload)));
//   Object.keys(payload).forEach(key => {
//     if(checkboxes.indexOf(key) >= 0){
//       payload[key] = payload[key] ? "yes": "no";
//     }
//   });

//   // if(payload.date_of_sale){
//   //   payload.date_of_sale = moment.utc(payload.date_of_sale).format("MM/DD/YY");
//   // }

//   if(payload.date){
//     payload.date = moment.utc(payload.date).format("MM/DD/YY");
//   }

//   return payload;
// },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])


const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });
    
    if(!document){
      return null;
    }

      var docCopy = JSON.parse(JSON.stringify(document));
      docCopy.payload =  JSON.parse(JSON.stringify(payload));
      docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
      docCopy.payload =  JSON.stringify(docCopy.payload);
        delete docCopy.documentStatus;
      try{
        let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
        setdocumentUrl(fileURL)
        } catch (err) {
          recordFrontEndError('SDTitleApp.jsx', 'Error previewing document payload: ' + err)
        };  
    
},[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


    //Autofill Product Details After Selection
    const AutofillProductDetails = useCallback(async (selectedProduct) => {
      let newValues = SDTitleAppAutofillProductDetails(selectedProduct, documentValues);

      if(newValues){
        setDocumentValues(newValues);
        previewPayloadDocument(documentValues);
        setHasUnsavedChanges(true);
        if(document){
          previewPayloadDocument(documentValues);
        }
        setSaveSuccess(false);
      }

      // var newValues = documentValues;
      // newValues.vin_hin = (selectedProduct.vin).toString().toUpperCase();
      // newValues.year = (selectedProduct.year);
      // newValues.make = (selectedProduct.make).toString().toUpperCase();
      // newValues.model = (selectedProduct.model).toString().toUpperCase();
      // newValues.body_type = (selectedProduct.vehicleType);
      // newValues.f_purchase_price = selectedProduct.price;

      // if(selectedProduct.fundingDetail && (selectedProduct.fundingDetail.fundingMethod === "tec-financing" || selectedProduct.fundingDetail.fundingMethod === "finance-only" || selectedProduct.fundingDetail.fundingMethod === "direct-deal")){
      //   newValues.g_lienholder_name_1 = selectedProduct.fundingDetail.finalizedLendingOption.bankName;
      //   newValues.g_lienholder_address_1 = selectedProduct.fundingDetail.finalizedLendingOption.lienAddress;
      //   newValues.g_lienholder_city_1 = selectedProduct.fundingDetail.finalizedLendingOption.lienCity;
      //   newValues.g_lienholder_state_1 = selectedProduct.fundingDetail.finalizedLendingOption.lienState;
      //   newValues.g_lienholder_zip_1 = selectedProduct.fundingDetail.finalizedLendingOption.lienZip;   
      // }else if(selectedProduct.fundingDetail && selectedProduct.fundingDetail.fundingMethod === "cash-with-lien"){
      //   newValues.g_lienholder_name_1 = selectedProduct.fundingDetail.cashLienHolder.bankName;
      //   newValues.g_lienholder_address_1 = selectedProduct.fundingDetail.cashLienHolder.addressLine1+" "+selectedProduct.fundingDetail.cashLienHolder.addressLine2;
      //   newValues.g_lienholder_city_1 = selectedProduct.fundingDetail.cashLienHolder.city;
      //   newValues.g_lienholder_state_1 = selectedProduct.fundingDetail.cashLienHolder.state;
      //   newValues.g_lienholder_zip_1 = selectedProduct.fundingDetail.cashLienHolder.zip;
      // }else if(selectedProduct.shipTo){
      //   newValues.g_lienholder_name_1 = selectedProduct.shipTo.name;
      //   newValues.g_lienholder_address_1 = selectedProduct.shipTo.addressLine1+" "+selectedProduct.shipTo.addressLine2;
      //   newValues.g_lienholder_city_1 = selectedProduct.shipTo.city;
      //   newValues.g_lienholder_state_1 = selectedProduct.shipTo.state;
      //   newValues.g_lienholder_zip_1 = selectedProduct.shipTo.zip;
      // }

      // setDocumentValues(newValues);
      // // setProductToggleAutoFill(false);
      // if(document){
      //   previewPayloadDocument(documentValues);
      // }
      // setHasUnsavedChanges(true);
      // setSaveSuccess(false);
    },[ document, documentValues, previewPayloadDocument ]);

  // Document BREAD + Preview // TODO: Move to Redux

  useEffect(() => {
    if(document && document.payload && !dataLoaded){
      var existingPayload = false;
      var documentPayload = JSON.parse(document.payload);
      if(documentPayload && Object.keys(documentPayload).length > 0){
    
        existingPayload = true;
    
        // if(documentPayload.date_of_sale){
        //   documentPayload.date_of_sale = moment.utc(documentPayload.date_of_sale).format("YYYY-MM-DD");
        // }

        if(documentPayload.date){
          documentPayload.date = moment.utc(documentPayload.date).format("YYYY-MM-DD");
        }

    
        
        setDocumentValues(documentPayload);

        // Loading Use Effect
        // if(document.additionalData){
        //   const methodMap = { 
        //     selectedAddress1: setSelectedAddress1, 
        //     selectedAddress2: setSelectedAddress2, 
        //     selectedAddress3: setSelectedAddress3, 
        //     selectedAddress4: setSelectedAddress4, 
        //     selectedAddress5: setSelectedAddress5, 
        //     selectedAddress6: setSelectedAddress6, 
        //   };

        //   let parsedAdditionalData = JSON.parse(document.additionalData);
        //   Object.keys(parsedAdditionalData).forEach(function(key) {
        //     if(methodMap[key]){
        //       methodMap[key](parsedAdditionalData[key]);
        //     }
        //   });
        // }

      }
      var foundAssignedProduct = null;
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
        foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
      }
      
      // setAddressOptions(getOrderLevelAddresses(order));
      if(foundAssignedProduct){
          setAssignedProduct(foundAssignedProduct);
          // setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
          setNameOptions(getAllNames(order, foundAssignedProduct));
      }
      
        
        if(!existingPayload){
          GlobalAutofill();
          previewPayloadDocument(documentValues);
          if(foundAssignedProduct){
            AutofillProductDetails(foundAssignedProduct)
          }
          // Load First Tradein
          if(order && order.tradeins && order.tradeins.length > 0){
            let tradein = order.tradeins[0];
            if(tradein){
              setselectedTradein(tradein);
            }
          }

          if(order && order.tradeins && order.tradeins.length > 1){
            let tradein = order.tradeins[1];
            if(tradein){
              setselectedTradein2(tradein);
            }
          }
        }else{
          // if(document && document.itemIds){
          //   var itemIds = document.itemIds.split(',');
          //     var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
          //     let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
          //     let obj = {id: foundProduct.id, name: str}
          //     setproductVal(obj);
          //     if(foundProduct){
          //       setAddressOptions(updateAddressOptions(foundProduct, order));
          //     }
          //     streamDocument();
          // }
    
        }
        
      setDataLoaded(true);
    }
  },[ documentValues, document, dataLoaded, order, AutofillProductDetails, GlobalAutofill, previewPayloadDocument ])


  function ReloadAutoFillValues(){  
    var foundAssignedProduct = null;
    if(document && document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }

    GlobalAutofill();
    if(foundAssignedProduct){
      AutofillProductDetails(foundAssignedProduct)
    }
  }

  
    // function streamDocument(){
    //   axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
    //     .then(response => {
    //       const file = new Blob(
    //         [response.data], 
    //         {type: 'application/pdf'});
    //         const fileURL = URL.createObjectURL(file);
    //         // window.open(fileURL);
    //       setdocumentUrl(fileURL)
    //     }).catch(err => {
    //       // console.log(err);
    //     })
    // }

    const onTradeinChange = (tradeinObj) => {
      if(order && order.tradeins && order.tradeins.length > 0){
        let tradein = order.tradeins.find(t => t.id = tradeinObj.id);
        if(tradein){
          setselectedTradein(tradein);
          settradeinProcessed(false);
        }
      }

      // calculateTotals(selectedProducts, tradeinArray);
      // updateDocumentTemplateId(selectedProducts, tradeinArray);
    }
  

    const onTradeinChange2 = (tradeinObj) => {
      if(order && order.tradeins && order.tradeins.length > 0){
        let tradein = order.tradeins.find(t => t.id = tradeinObj.id);
        if(tradein){
          setselectedTradein2(tradein);
          settradeinProcessed(false);
        }
      }

      // calculateTotals(selectedProducts, tradeinArray);
      // updateDocumentTemplateId(selectedProducts, tradeinArray);
    }
  
  
    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();
  

      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
    

      // const payload = JSON.parse(JSON.stringify(documentValues));
      // var docCopy = document;
      // docCopy.payload = payload;
      // docCopy.payload = preProcessPayload(docCopy.payload);
      // docCopy.payload =  JSON.stringify(docCopy.payload);
      // delete docCopy.documentStatus;
      
      // if(selectedProduct && selectedProduct.id){
      //   docCopy.itemIds = `${selectedProduct.id}`;
      // }
  
      //Save Dropdown Selections
      // let dropdownData = {};
      // if(selectedAddress1){
      //   dropdownData.selectedAddress1 = {
      //     id: selectedAddress1.id,
      //     name: selectedAddress1.name
      //   };
      // }
      // if(selectedAddress2){
      //   dropdownData.selectedAddress2 = {
      //     id: selectedAddress2.id,
      //     name: selectedAddress2.name
      //   };
      // }
      // if(selectedAddress3){
      //   dropdownData.selectedAddress3 = {
      //     id: selectedAddress3.id,
      //     name: selectedAddress3.name
      //   };
      // }
      // if(selectedAddress4){
      //   dropdownData.selectedAddress4 = {
      //     id: selectedAddress4.id,
      //     name: selectedAddress4.name
      //   };
      // }
      // if(selectedAddress5){
      //   dropdownData.selectedAddress5 = {
      //     id: selectedAddress5.id,
      //     name: selectedAddress5.name
      //   };
      // }
      // if(selectedAddress6){
      //   dropdownData.selectedAddress6 = {
      //     id: selectedAddress6.id,
      //     name: selectedAddress6.name
      //   };
      // }

      // docCopy.additionalData = JSON.stringify(dropdownData);
      // let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, null, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });
      // try {
      //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
      //   if (res){
      //     await axios.post(`/document/${document.id}/generate`) 
      //     getDocuments();
      //     setSaveSuccess(true);
      //     setHasUnsavedChanges(false);
      //   };
      //   // console.log(res);
      // } catch (err) {
      //   recordFrontEndError('SDTitleApp.jsx', 'Error saving document: ' + err)
      // };
      
    }

    const [tradeinProcessed, settradeinProcessed] = useState(false);

    useEffect(() => {
      if(selectedTradein && !tradeinProcessed){
        settradeinProcessed(true);
        let newValues = documentValues;
        if(newValues){
          newValues.make_2 = (selectedTradein.make).toString().toUpperCase();
          newValues.vin_2 = (selectedTradein.vin).toString().toUpperCase();
          newValues.year_2 = selectedTradein.year;
          newValues.body_type_2 = selectedTradein.seriesBodyType;
          newValues.license_plate_number = selectedTradein.plateNumber;
          newValues.trade_leased_toggle = selectedTradein.balanceOwed &&  Number(selectedTradein.balanceOwed) > 0 ? "yes" : "no";
          newValues.trade_in_allowance = selectedTradein.finalTradeAllowance;
          setDocumentValues(newValues);
          if(document){
            previewPayloadDocument(documentValues);
          }
          setHasUnsavedChanges(true);
          setSaveSuccess(false);
        }
      }
    },[ selectedTradein, document, documentValues, previewPayloadDocument, tradeinProcessed, settradeinProcessed ])

    const [tradeinProcessed2, settradeinProcessed2] = useState(false);

    useEffect(() => {
      if(selectedTradein2 && !tradeinProcessed2){
        settradeinProcessed2(true);
        let newValues = documentValues;
        if(newValues){
          newValues.make_2 = (selectedTradein2.make).toString().toUpperCase();
          newValues.vin_2 = (selectedTradein2.vin).toString().toUpperCase();
          newValues.year_2 = selectedTradein2.year;
          newValues.body_type_2 = selectedTradein2.seriesBodyType;
          newValues.license_plate_number = selectedTradein2.plateNumber;
          newValues.trade_leased_toggle = selectedTradein2.balanceOwed &&  Number(selectedTradein2.balanceOwed) > 0 ? "yes" : "no";
          newValues.trade_in_allowance = selectedTradein2.finalTradeAllowance;
          setDocumentValues(newValues);
          if(document){
            previewPayloadDocument(documentValues);
          }
          setHasUnsavedChanges(true);
          setSaveSuccess(false);
        }
      }
    },[ selectedTradein2, document, documentValues, previewPayloadDocument, tradeinProcessed2, settradeinProcessed2 ])
// Component Helpers //

  // useEffect(() => {

  // },[ selectedProduct, productToggleAutoFill ])

  // useEffect(() => {
  //   //Autofill Tradein Details After Selection
  //   if(selectedLocation !== undefined && locationToggleAutoFill){
  //     var newValues = documentValues;
  //     newValues.seller_address = selectedLocation.address;
  //     newValues.seller_city = selectedLocation.city;
  //     newValues.seller_state = selectedLocation.state;
  //     newValues.seller_zip = selectedLocation.zip;
  //     newValues.seller_phone = formatPhoneNumber(selectedLocation.phone);
  
  //     if(selectedLocation.state === "CA"){
  //       newValues.seller_name_signature = "TEC OF CALIFORNIA, INC";
  //       newValues.copy_seller_name_signature = "TEC OF CALIFORNIA, INC";
  //     }else{
  //       newValues.seller_name_signature = "TEC EQUIPMENT, INC.";
  //       newValues.copy_seller_name_signature = "TEC EQUIPMENT, INC.";
  //     }
  
  //     setDocumentValues(newValues);
  //     setLocationToggleAutoFill(false);
  //     if(document){
  //       previewPayloadDocument(documentValues);
  //     }
  //     setHasUnsavedChanges(true);
  //     setSaveSuccess(false);
  //   }
  // },[ selectedLocation, locationToggleAutoFill ])
  
  //Autofill Address Details After Selection
  // function AutoFillAddress(addressSelected, selectionInstance){
  //   var newValues = documentValues;
  //   if(selectionInstance){
  //     if(selectionInstance === "physical-address"){
  //       newValues.address_line_1 = addressSelected.address;
  //       // newValues.address_line_2 = addressSelected.addressLine2;
  //       newValues.city = addressSelected.city;
  //       newValues.state = addressSelected.state;
  //       newValues.zip = addressSelected.zip;
  //     }

  //     if(selectionInstance === "mailing-address"){
  //       newValues.mailing_address_line_1 = addressSelected.address;
  //       // newValues.mailing_address_line_2 = addressSelected.addressLine2;
  //       newValues.mailing_city = addressSelected.city;
  //       newValues.mailing_state = addressSelected.state;
  //       newValues.mailing_zip = addressSelected.zip;
  //     }

  //     if(selectionInstance === "lessee-address"){
  //       newValues.lessee_address_line_1 = addressSelected.address;
  //       // newValues.lessee_address_line_2 = addressSelected.addressLine2;
  //       newValues.lessee_city = addressSelected.city;
  //       newValues.lessee_state = addressSelected.state;
  //       newValues.lessee_zip = addressSelected.zip;
  //     }

  //     if(selectionInstance === "located-address"){
  //       newValues.trailer_coach_address = addressSelected.address;
  //       newValues.trailer_coach_city = addressSelected.city;
  //       newValues.trailer_coach_state = addressSelected.state;
  //       newValues.trailer_coach_zip = addressSelected.zip;
  //     }

  //     if(selectionInstance === "physical2-address"){
  //       newValues.lien_address_line_1 = addressSelected.address;
  //       // newValues.lien_address_line_2 = addressSelected.addressLine2;
  //       newValues.lien_city = addressSelected.city;
  //       newValues.lien_state = addressSelected.state;
  //       newValues.lien_zip = addressSelected.zip;
  //     }

  //     if(selectionInstance === "mailing2-address"){
  //       newValues.lien_mailing_address_line_1 = addressSelected.address;
  //       // newValues.lien_mailing__address_line_2 = addressSelected.addressLine2;
  //       newValues.lien_mailing_city = addressSelected.city;
  //       newValues.lien_mailing_state = addressSelected.state;
  //       newValues.lien_mailing_zip = addressSelected.zip;
  //     }
  //   }
  //   setDocumentValues(newValues);
  //   if(document){
  //     previewPayloadDocument(documentValues);
  //   }
  //   setHasUnsavedChanges(true);
  //   setSaveSuccess(false);
  // }

  const onCloseClick = e => {
    e.preventDefault()
    setHasUnsavedChanges(false);
    setSaveSuccess(false);
    setdocumentUrl("");
    setDocument(undefined);
    setNameOptions([]);
    setPreviousDocumentId(0);
    setDocumentValues(initialDocumentStructure);
    setDocumentLoaded(false);
    setDataLoaded(false);
    setAssignedProduct(undefined);
    // setSelectedAddress1(undefined);
    // setSelectedAddress2(undefined);
    // setSelectedAddress3(undefined);
    // setSelectedAddress4(undefined);
    // setSelectedAddress5(undefined);
    // setSelectedAddress6(undefined);
    // setAddressOptions(undefined);
    // setinputAddressVal(undefined);
    setDocumentFocus(undefined);
  }
  
  const [hasClickedClose, setHasClickedClose] = useState(false);
  
  const closeClicked = e => {
    if( hasUnsavedChanges ){
        setHasClickedClose(true);
    } else {
        onCloseClick(e);
    }
  }
  
  const preCloseSave = e => {
    handleSubmit(e)
    setHasClickedClose(false);
  }
  
  const preCloseClose = e => {
    setHasClickedClose(false);
    onCloseClick(e)
  }

  return (
    <>
      <div
        className="modal fade"
        // id="ca-statement-of-facts"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                South Dakota - Motor Vehicle/Boat Title & Registration Application
              </h5>
              <br />
              { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1 pt-1 text-white">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                  </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}


                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}


                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ fontSize: '12px', overflowY: 'scroll', height: 'inherit'}}>
               
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      ReloadAutoFillValues();
                      // GlobalAutofill();
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>
                  <br />
                  { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Product Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                        </div>
                    </div>
                  ):(
                    <div className="m-2 p-2 border">
                        <span>No Assigned Product</span>
                    </div>
                  )}

                  <div className="row">
                    <h5>Dealer's Report of Sale</h5>
                  </div>

                  <Autocomplete
                      style={{ width: '100%'}}
                      value={ (documentValues.name) ?? null}
                      name="owner_name"
                      onBlur={(e) => updatePreview(e)}
                      onChange={(event, newValue) => {
                        let updatedObject = documentValues;
                        updatedObject.name = newValue.name;
                        setDocumentValues(updatedObject);
                        // setDocumentValues({...documentValues,["owner_name"]:newValue.name});
                      }}
                      // inputValue={inputAddressVal}
                      // onInputChange={(event, newInputValue) => {
                      //   setinputAddressVal(newInputValue);
                      // }}
                      options={nameOptions}
                      sx={{ width: 400 }}
                      renderInput={(params) => <TextField {...params} label="Purchaser Name" />}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                    />

                  <div className="row">
                    <br />
                    <h5>Section A - Application Request</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="type_toggle"
                            value={(documentValues.type_toggle ?? "none")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel className="p-3" value="vehicle" control={<Radio />} label="Motor Vehicle" />
                            <FormControlLabel className="p-3" value="boat" control={<Radio />} label="Boat" />
                            <FormControlLabel className="p-3" value="none" control={<Radio />} label="None" />                          
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                    
                
                  <div className="row">
                    <br />
                    <h5>Application Type Selection</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                      <FormControl className="px-3 pt-2">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="title_type_toggle"
                            value={(documentValues.title_type_toggle ?? "none")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel className="p-3" value="new" control={<Radio />} label="New Title" />
                            <FormControlLabel className="p-3" value="transfer" control={<Radio />} label="Title Transfer" />
                            <FormControlLabel className="p-3" value="repo" control={<Radio />} label="Repossession" />
                            <FormControlLabel className="p-3" value="interstate" control={<Radio />} label="Interstate Title" />
                            <FormControlLabel className="p-3" value="oos-trans" control={<Radio />} label="Out-of-State Title Transfer" />
                            <FormControlLabel className="p-3" value="abandoned" control={<Radio />} label="Abandoned Title" />
                            <FormControlLabel className="p-3" value="law" control={<Radio />} label="Operation by Law" />
                            <FormControlLabel className="p-3" value="unpaid-bill" control={<Radio />} label="Unpaid Repair Bill" />
                            <FormControlLabel className="p-3" value="none" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <br />
                    <h5>Section B - Vehicle or Boat Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">VIN/HIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin_hin"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin_hin ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Model</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="model"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.model ?? '' }
                          />
                      </div>
                    </div>
                  </div>


                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Secondary VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="secondary_vin"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.secondary_vin ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Secondary Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Secondary Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Primary Color</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="primary_color"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.primary_color ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Secondary Color</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="secondary_color"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.secondary_color ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Previous State/Brand</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="previous_state_brand"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.previous_state_brand ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Body Type</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="body_type"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.body_type ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Weight/CC</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="weight_cc"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.weight_cc ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Fuel Type</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="fuel_type"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.fuel_type ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <br />
                    <h5>Brand</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                      <FormControl className="px-3 pt-2">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="brand_toggle"
                            value={(documentValues.brand_toggle ?? "none")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel className="p-3" value="man-buy-back" control={<Radio />} label="Manufacturer Buy Back" />
                            <FormControlLabel className="p-3" value="man-buy-back-rebuilt" control={<Radio />} label="Manufacturer Buy Back-Rebuilt" />
                            <FormControlLabel className="p-3" value="parts" control={<Radio />} label="Parts Only" />
                            <FormControlLabel className="p-3" value="man-buy-back-salv" control={<Radio />} label="Manufacturer Buy Back-Salvage" />
                            <FormControlLabel className="p-3" value="man-buy-back-junk" control={<Radio />} label="Manufacturer Buy Back-Junking Certificate" />
                            <FormControlLabel className="p-3" value="junk-cert" control={<Radio />} label="Junking Certificate" />
                            <FormControlLabel className="p-3" value="salvage-total-loss" control={<Radio />} label="Salvage Total Loss" />
                            <FormControlLabel className="p-3" value="recovered-theft" control={<Radio />} label="Recovered Theft" />
                            <FormControlLabel className="p-3" value="none" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>


                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Odometer</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="odometer"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.odometer ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-9">
                        <FormControl className="px-3 pt-2">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="units_toggle"
                            value={(documentValues.units_toggle ?? "none")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel className="p-3" value="miles" control={<Radio />} label="Miles" />
                            <FormControlLabel className="p-3" value="kilos" control={<Radio />} label="Kilometers" />
                            <FormControlLabel className="p-3" value="none" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>


                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="odometer_brand_toggle"
                            value={(documentValues.odometer_brand_toggle ?? "none")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel className="p-3" value="actual" control={<Radio />} label="Actual Mileage" />
                            <FormControlLabel className="p-3" value="exceeds" control={<Radio />} label="Exceeds Mechanical Limits" />
                            <FormControlLabel className="p-3" value="not-actual" control={<Radio />} label="Not Actual Mileage" />
                            <FormControlLabel className="p-3" value="none" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Boat Length Feet</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="boat_length_ft"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.boat_length_ft ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Boat Length Inches</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="boat_length_in"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.boat_length_in ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Hull Material</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="hull_material"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.hull_material ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Boat Type</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="boat_type"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.boat_type ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Boat Propulsion</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="propulsion"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.propulsion ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Type of Use</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="type_of_use"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.type_of_use ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <br />
                    <h5>Section C - Owner & Address Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">1. Owner/Lessor/Trust</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_lessor_trust"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_lessor_trust ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Type of Ownership</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="type_of_ownership"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.type_of_ownership ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Customer Type</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="customer_type"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.customer_type ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Identification #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="identification"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.identification ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.address ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="zip_code"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.zip_code ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">2. Owner/Lessor/Trust</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_lessor_trust_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_lessor_trust_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Type of Ownership</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="type_of_ownership_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.type_of_ownership_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Customer Type</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="customer_type_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.customer_type_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Identification #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="identification_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.identification_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="address_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.address_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="city_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.city_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="state_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.state_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="zip_code_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.zip_code_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">3. Owner/Lessor/Trust</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_lessor_trust_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_lessor_trust_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Type of Ownership</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="type_of_ownership_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.type_of_ownership_3 ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Customer Type</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="customer_type_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.customer_type_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Identification #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="identification_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.identification_3 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="address_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.address_3 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="city_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.city_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="state_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.state_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="zip_code_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.zip_code_3 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">4. Owner/Lessor/Trust</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_lessor_trust_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_lessor_trust_4 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Type of Ownership</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="type_of_ownership_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.type_of_ownership_4 ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Customer Type</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="customer_type_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.customer_type_4 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Identification #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="identification_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.identification_4 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="address_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.address_4 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="city_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.city_4 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="state_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.state_4 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="zip_code_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.zip_code_4 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <br />
                    <h5>Section D - Title Mailing Address Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="d_mailing_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.d_mailing_name ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  
                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="d_mailing_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.d_mailing_address ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="d_mailing_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.d_mailing_city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="d_mailing_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.d_mailing_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="d_mailing_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.d_mailing_zip ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <br />
                    <h5>Section E - Contact Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Contact Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="e_contact_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.e_contact_name ?? '' }
                          />
                      </div>

                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Email Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="e_email_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.e_email_address ?? '' }
                          />
                      </div>

                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Phone #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="e_phone_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.e_phone_number ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <br />
                    <h5>Section F - Motor Vehicle/Boat Purchase Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="f_tax_exempt"
                            checked={(documentValues && documentValues.f_tax_exempt ? documentValues.f_tax_exempt : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="Tax Exempt" 
                          />
                        </FormGroup>
                        <label htmlFor="stock">List #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="f_list"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.f_list ?? '' }
                          />
                      </div>

                      <div className="p-2 col-md-4">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="f_non_profit"
                            checked={(documentValues && documentValues.f_non_profit ? documentValues.f_non_profit : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="Non-profit donated vehicle/corporation #" 
                          />
                        </FormGroup>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="f_corp_num"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.f_corp_num ?? '' }
                          />
                      </div>

                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Purchase Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="f_purchase_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.f_purchase_date ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="f_rental_vehicle"
                            checked={(documentValues && documentValues.f_rental_vehicle ? documentValues.f_rental_vehicle : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="Rental Vehicle/SD Sales Tax #" 
                          />
                        </FormGroup>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="f_tax_num"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.f_tax_num ?? '' }
                          />
                      </div>

                      <div className="p-2 col-md-4">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="f_title_only"
                            checked={(documentValues && documentValues.f_title_only ? documentValues.f_title_only : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="Title Only" 
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="f_bill_of_sale"
                            checked={(documentValues && documentValues.f_bill_of_sale ? documentValues.f_bill_of_sale : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="1. Purchase Price [Check Box Toggle] Bill of Sale not available/NADA" 
                          />
                        </FormGroup>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="f_purchase_price"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.f_purchase_price ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">2. Less Trade-in Allowance</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="f_less_trade_allowance"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.f_less_trade_allowance ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">3. Difference</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="f_difference"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.f_difference ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">4. Tax</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="f_tax"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.f_tax ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">5. Tax Penalty & Interest</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="f_tax_pen_int"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.f_tax_pen_int ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">6. Credit for Tax Paid to Another State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="f_credit_tax_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.f_credit_tax_state ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">8. Late Fee</label>
                        <input
                          type="text"
                          disab
                          className="form-control rounded-pill mt-1"
                          name="f_late_fee"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.f_late_fee ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">9. Lien Fee</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="f_lien_fee"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.f_lien_fee ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">10. Balance Due</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="f_balance_due"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.f_balance_due ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Dealer Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="f_dealer_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.f_dealer_name ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  { tradeinOptions && tradeinOptions.length > 0 ? (
                    <div className="row my-3">

                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ ( selectedTradein && selectedTradein.vin ? selectedTradein.vin.toUpperCase() : null)}
                          onChange={(event, newValue) => {
                            if(newValue !==  null) {
                              onTradeinChange(newValue);
                            }
                          }}
                          getOptionLabel={(option)=>(option.name ? option.name : '')}
                          // inputValue={inputTradeinVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputDocument(newInputValue);
                          // }}
                          //id="controllable-states-demo"
                          filterOptions={filterOptions}
                          options={tradeinOptions}
                          sx={{ width: 230}}
                          renderInput={(params) => <TextField {...params} label="SELECT TRADE IN" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />

                  </div>

                  ):(
                    <p>No Trade Ins Found On Order</p>
                  )}

                  <div className="row">
                    <label htmlFor="stock">1st Trade-in Information</label>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="f_year_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.f_year_1 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="f_make_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.f_make_1 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Title #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="f_title_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.f_title_1 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">VIN/HIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="f_vinhin_1"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.f_vinhin_1 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  { tradeinOptions && tradeinOptions.length > 0 ? (
                    <div className="row my-3">

                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ ( selectedTradein && selectedTradein.vin ? selectedTradein.vin.toUpperCase() : null)}
                          onChange={(event, newValue) => {
                            if(newValue !==  null) {
                              onTradeinChange2(newValue);
                            }
                          }}
                          getOptionLabel={(option)=>(option.name ? option.name : '')}
                          // inputValue={inputTradeinVal}
                          // onInputChange={(event, newInputValue) => {
                          //   setinputDocument(newInputValue);
                          // }}
                          //id="controllable-states-demo"
                          filterOptions={filterOptions}
                          options={tradeinOptions}
                          sx={{ width: 230}}
                          renderInput={(params) => <TextField {...params} label="SELECT TRADE IN" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />

                  </div>

                  ):(
                    <p>No Trade Ins Found On Order</p>
                  )}

                  <div className="row">
                    <label htmlFor="stock">2nd Trade-in Information</label>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="f_year_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.f_year_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="f_make_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.f_make_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Title #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="f_title_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.f_title_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">VIN/HIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="f_vinhin_2"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.f_vinhin_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <br />
                    <h5>Section G - Lienholder Information</h5>
                    <label htmlFor="stock">1st Lienholder</label>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="g_lienholder_name_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.g_lienholder_name_1 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="g_lienholder_address_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.g_lienholder_address_1 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="g_lienholder_city_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.g_lienholder_city_1 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="g_lienholder_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.g_lienholder_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="g_lienholder_zip_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.g_lienholder_zip_1 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <label htmlFor="stock">2nd Lienholder</label>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="g_lienholder_name_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.g_lienholder_name_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="g_lienholder_address_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.g_lienholder_address_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="g_lienholder_city_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.g_lienholder_city_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="g_lienholder_state_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.g_lienholder_state_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="g_lienholder_zip_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.g_lienholder_zip_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  
                  <div className="row">
                    <br />
                    <h5>Section H - Unsworn Declaration</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Signed on Date*</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="h_signed_date_special"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.h_signed_date_special ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Signed on Time</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="h_date_time"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.h_date_time ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Printed Name 1</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="h_name_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.h_name_1 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Printed Name 2</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="h_name_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.h_name_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Printed Name 3</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="h_name_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.h_name_3 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Printed Name 4</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="h_name_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.h_name_4 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default SDTitleApp

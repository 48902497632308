import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import "./Style/productmodal.css"
import { useOrder } from "../../../../../../../redux/orders/order"
import { recordFrontEndError } from "../../../../../../../utlis/api"
import * as api from "../../../../../../../utlis/api";

function DeleteProductModal() {  
  const history   = useHistory();
  const order   = useOrder();
  const product_id = useSelector(state => state?.productFocus.product_focus_id );

  const deleteProduct = async (e) => {
    e.preventDefault();
    try{
      await api.deleteProduct(order.id, product_id)
    } catch (err){
      recordFrontEndError('ProductSection.js', 'Error deleting product: ' + err)
    } 
    history.push(`/order/${order.id}`);
  }

  return (
    <>
      <div
        className="modal fade"
        id="productmodaldeleteconfirm"
        tabIndex="-1"
        aria-labelledby="exampledownpaymentmodel"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header bg-primary text-white">
              <h5
                className="modal-title text-capitalize"
                id="exampledownpaymentmodel"
              >
                Delete Product
              </h5>
              <button
                type="button"
                className="btn-close bg-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="px-3 py-3">
                <h4 className="container text-secondary text-center my-3">
                  Are you sure you want to delete this product?
                </h4>
              </div>
            </div>
            <div className="modal-footer text-center">
              <div className="mx-auto">
                <button
                  type="button"
                  className="btn btn-primary mx-3"
                  onClick={(e) => deleteProduct(e)}
                  data-bs-dismiss="modal"
                >
                  Delete
                </button>
                  <button
                  type="button"
                  className="btn btn-secondary mx-3"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeleteProductModal;

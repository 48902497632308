import React, {  useEffect, useState } from "react";
import CardContent from '@mui/material/CardContent';
import Navbar from "../../../Components/Navbar/Navbar";
import { useFetch } from "../../../utlis/api";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as api from '../../../utlis/api'
import { Checkbox, CircularProgress } from "@mui/material";
import * as checkOut from './CheckedOutHelpers'
import moment from "moment";



const AllCheckedOut = () => {

  // Base level styling on table cells
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  // Base level styling on table rows
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  // React Variables
  const axios     = useFetch()
  const [isLoading, setIsLoading]=useState(false);
  const [orders, setOrders] = useState(undefined)
  const [allSelected, setAllSelected] = useState(false)
  // const [noneSelected, setNoneSelected] = useState(false)

  // Row on change event: selectItem (and unselect)
  const selectItem = (id)=>{
    let ordersCopy= [...orders]
    let index= orders.findIndex(order=>Number(order.id)===Number(id))
    let updatedOrder= {...ordersCopy[index], selected:!ordersCopy[index]?.selected}
    ordersCopy[index]=updatedOrder
    let allSelected = ordersCopy.every(order=>order.selected)
    setAllSelected(allSelected)
    setOrders(ordersCopy)
  }

  // Select/Unselect All (on change checkbox)
  const selectAll= () =>{
    if(orders?.length>0){
      let selectAllArr=orders.map((order,i)=>{
        return{...order, selected:!allSelected}
      })
      setOrders(selectAllArr)
    }
    setAllSelected(!allSelected)
  }

  // Uncheck out orders
  const uncheckOutOrder = async (type)=>{
    let res
    setIsLoading(true)

    for(const order of orders){
      if(order?.selected) {
        await axios.get(`order/${order.id.toString()}/uncheckout`)
      }
    }
    res = await checkOut.fetchCheckedOutOrders('MINE')
    if (res) setOrders([...res])

    setIsLoading(false)
  }

    // Uncheck out orders
    const uncheckAllOrders = async ()=>{
      let res
      setIsLoading(true)
  

      await axios.get(`uncheckout-all-orders`);
   
      res = await checkOut.fetchCheckedOutOrders('MINE')
      if (res) setOrders([...res])
  
      setIsLoading(false)
    }

  // REACT UE: Load checked out orders into table.
  useEffect(()=>{

    const fetchAllCheckedOutOrders=async ()=>{
      try{
          setIsLoading(true)
        let res = await checkOut.fetchCheckedOutOrders('ALL')
        if (res) setOrders([...res])
        
      } catch (err){
          api.recordFrontEndError('AllCheckedOut.jsx', 'Error getting all checked out orders: ' + err )
      }
      setIsLoading(false)
      }

    if(orders===undefined){
      fetchAllCheckedOutOrders() 
    }
  }, [orders])

  return (
    <>
    <Navbar title="All Checked Out Orders" />
      <div className="mx-4 mt-2">
        <h3>All Checked Out Orders</h3>
        {isLoading?(<CircularProgress style={{marginLeft:'11rem'}}/>):(
          <div className="d-flex align-items-center" style={{gap:'1rem'}}>
            {/* <button onClick={()=>uncheckOutOrder('ALL')} className="btn btn-success ma-4 pa-4">Un-Checkout All Orders Out</button> */}
            <button disabled={orders?.every(order=>order.selected===false)} onClick={()=>uncheckOutOrder('SELECTED')} className="btn btn-success ma-4 pa-4">Un-Checkout Selected Orders</button>
            <button onClick={()=>uncheckAllOrders()} className="btn btn-success ma-4 pa-4">Un-Checkout All Orders</button>
          </div>
        )}
      </div>
      <br />
        <CardContent style={{ width: "100%" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
              <StyledTableCell><Checkbox className="btn btn-default" checked={allSelected} onChange={(e) => {selectAll()}}/></StyledTableCell>
                <StyledTableCell align="left">Un-Checkout Order</StyledTableCell>
                <StyledTableCell align="left">Checked Out By</StyledTableCell>
                <StyledTableCell align="left">Order Id</StyledTableCell>
                <StyledTableCell align="left">Customer Name</StyledTableCell>
                <StyledTableCell align="left">Order Status</StyledTableCell>
                <StyledTableCell align="left">Checked Out At</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders && orders.length > 0 ? ( orders.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    <Checkbox className="btn btn-default" checked={row.selected} onChange={(e) => {selectItem(row.id)}}/>
                  </StyledTableCell>
                <StyledTableCell ><button className="btn btn-primary"onClick={async (e)=>{
                  e.preventDefault()
                  let res = await axios.get(`order/${row.id.toString()}/uncheckout`)
                  res = await checkOut.fetchCheckedOutOrders('ALL')
                  if (res) setOrders([...res])
                  }}>Un-Checkout Order</button></StyledTableCell>
                  <StyledTableCell align="left">
                    {row.checkedOutByUserName}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.id}</StyledTableCell>
                  <StyledTableCell align="left">{row?.customer}</StyledTableCell>
                  <StyledTableCell align="left">{row?.orderStatus}</StyledTableCell>
                  <StyledTableCell align="left">{row?.checkedOut??(<>
                  <p>{moment?.(row.checkedOut).format('MMM Do YYY')}</p>
                  <p>{moment?.(row.checkedOut).format('h:m:s A')}</p>
                  </>)}</StyledTableCell>
                </StyledTableRow>
              ))):null}
            </TableBody>
          </Table>
        </TableContainer>
        </CardContent>
    </>
  );
}

export default AllCheckedOut;

import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";

export const NVPowerOfAttorneyGlobalAutofill = (documentValues, order, property=null, value=null) => {

    var newValues = documentValues;
    
  if(property ==='customer.name'){
    if(order.customer.type !== "entity" && order.customer.dba){
      newValues.applicant_name = value+" / "+order.customer.dba;
    }else{
      newValues.applicant_name = value;
    }
    if(order.customer.dba){
      newValues.applicant_name += " dba "+order.customer.dba;
    }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
      let cosigner = order.customer.cosigners[0];
      if(cosigner && cosigner.name){
          newValues.applicant_name += " AND "+cosigner.name;
      }
    }
  }
  if(property ==='customer.dba'){
    if(order.customer.type !== "entity" && order.customer.dba){
      newValues.applicant_name = order.customer.name+" / "+order.customer.dba;
    }else{
      newValues.applicant_name = order.customer.name;
    }
    if(value && value!==''){
      newValues.applicant_name+=" dba "+value
    }
  }
  if(property ==='customer.cosigner[0].name'){
    if(order.customer.type !== "entity" && order.customer.dba){
      newValues.applicant_name = order.customer.name+" / "+order.customer.dba;
    }else{
      newValues.applicant_name = order.customer.name;
    }
    if(value && value!==''){
      newValues.applicant_name+=  " AND "+value;
    }
  }
  if(property ==='documentationDate'){
    newValues.delivery_date = moment.utc(value).format("YYYY-MM-DD");
  }
    if(property !== null && value !== null){
      return newValues;
  }

    if(order && order.customer && order.customer.name){
      if(order.customer.type !== "entity" && order.customer.dba){
        newValues.applicant_name = order.customer.name+" / "+order.customer.dba;
      }else{
        newValues.applicant_name = order.customer.name;
      }
  
      if(order.customer.dba){
        newValues.applicant_name += " dba "+order.customer.dba;
      }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
            newValues.applicant_name += " AND "+cosigner.name;
        }
      }
    }
  
    if(order && order.documentationDate && documentValues.delivery_date === undefined){
      newValues.delivery_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }
  
    return newValues
}

export const NVPowerOfAttorneyAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
  var newValues = documentValues;

  if(property === 'year' && value !== null){
    newValues.year = (value).toString().trim().replace(/['"]+/g, '');
}

if(property === 'make' && value !== null){
    newValues.make = (value).toString().trim();
}

if(property === 'model' && value !== null){
    newValues.model = (value).toString().toUpperCase();
}

if(property === 'vin' && value !== null){
  newValues.vin = (value).toString().toUpperCase();
}

if(property === 'vehicleType' && value !== null){
    newValues.vehicle_type = (value).toString().trim();;
}
if(property === 'type' && value !== null){
if(selectedProduct.type.toUpperCase() === "NEW"){
  newValues.new_used_toggle = "new";
}else{
  newValues.new_used_toggle = "used";
}
}

if(property !== null && value !== null){
    return newValues;
}
  
  newValues.vin = (selectedProduct.vin).toString().toUpperCase();
  newValues.year = (selectedProduct.year).trim();
  newValues.make = (selectedProduct.make).trim();
  newValues.model = (selectedProduct.model).toString().toUpperCase();

  if(selectedProduct.type.toUpperCase() === "NEW"){
    newValues.new_used_toggle = "new";
  }else{
    newValues.new_used_toggle = "used";
  }

  newValues.vehicle_type = selectedProduct.vehicleType;
return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  // Data point formatting
  

  // X1.2- Field Type Specific Preview Formatting
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });
if(payload.delivery_date){
    payload.delivery_date = moment.utc(payload.delivery_date).format("MM/DD/YY");
  }
  return payload;
}
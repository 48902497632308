import { useState } from "react";

import { Autocomplete, TextField, Modal, CircularProgress } from '@mui/material';
import NumberFormat from 'react-number-format'
import {useSelector}from 'react-redux'
import FormError from "../../../../../../Components/commonElements/FormError"
import moment from "moment";
import * as formatters from '../../../../../../utlis/formatters'
import { addTestingTag } from "../../../../../../utlis/testingHelpers";

const FundingPaymentModal = props => {
const{showSpinner}=useSelector(state=>state.spinners)
  const {
    editedOrDefaultFundingPayment,
    editedFundingPaymentErrors,
    formattedAmount,
    formattedAdminFee,
    formattedGapAmount,
    formattedFundingReserveAmount,
    formattedNettedPayoffAmount,
    isEditMode,
    isOpen,
    onDocumentNumberChange,
    onDateReceivedChange,
    onAmountChange,
    onAdminFeeChange,
    onGapAmountChange,
    onFundingReserveAmountChange,
    onNettedPayoffAmountChange,
    onFormSubmit,
    onPaymentMethodChange,
    onStatusChange,
    onStatusInputChange,
    onCloseModal,
    isValidForSubmit,
    showSuccessFeedback,
    statusInputValue,
    hasUnsavedChanges,
    paymentMethods
  } = props

  const [hasClickedClose, setHasClickedClose] = useState(false);

  function closeClicked(){
    if(hasUnsavedChanges){
      setHasClickedClose(true);
    }else{
      onCloseModal();
    }
  }

  const preCloseSave = e => {
    onFormSubmit(e)
    setHasClickedClose(false);
  }

  const preCloseClose = e => {
    setHasClickedClose(false);
    onCloseModal()
  }

  function getPaymentMethodObject(id){
    if(paymentMethods && paymentMethods.length > 0){
      return paymentMethods.find(item => item.id === id);
    }
    return null;
  }


  const formatPayment = (e, updateFunction)=>{
    const {value:amount} = e?.target
    let formattedAmount = Number(formatters.removeChars(amount)).toFixed(2)
    updateFunction(Number(formatters.removeChars(formattedAmount)).toFixed(2),Number(formatters.removeChars(formattedAmount)).toFixed(2))
  }

  return (
    <Modal
      style={{ overflow: 'scroll' }}
      open={ isOpen }
      onClose={ onCloseModal }
      aria-labelledby=""
      aria-describedby="">
      <div
        id="fundingpaymentsmodal"
        tabIndex="-1">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header bg-primary text-white">
              <h5
                className="modal-title text-capitalize"
                id="examplefundingpaymentmodel"
              >
                { isEditMode ? 'Edit Funding Payment' : 'Add Funding Payment' }
              </h5>
              { hasClickedClose ? (
                <div className="d-flex">
                  <p className="m-1">Save Changes?</p>
                  <button className="m-1 btn btn-sm btn-success" data-testid="fundingPayment.control.saveYes" aria-label="save" onClick={preCloseSave}>Yes</button>
                  <button className="m-1 btn btn-sm btn-danger" data-testid="fundingPayment.control.saveNo" aria-label="cancel" onClick={preCloseClose}>No</button>
                </div>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  data-testid="fundingPayment.control.save"
                  // data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={ closeClicked }
                ></button>
              )}
            </div>

            {/* MODLE BODY START HERE */}
            <div className="modal-body">
              {/* <div className="d-flex">
                {isValidForSubmit ? (
                <button
                  type="button"
                  className={ "btn mx-auto "+(showSuccessFeedback ? "btn-success" : "btn-primary")}
                  onClick={ onFormSubmit } 
                  data-bs-dismiss="modal"
                >
                  { showSuccessFeedback ? "FundingPayment Saved" : "Save" }
                </button>
                ) : (
                  <button
                    type="button"
                    className="btn mx-auto btn-primary"
                    onClick={ onFormSubmit } 
                    data-bs-dismiss="">
                  Save
                </button>
                )}
              </div> */}
              <div className="px-3">
                <div className="container fw-bold text-secondary my-3">
                  <label htmlFor="stock" className="mb-1 text-uppercase">Status</label>
                  <Autocomplete
                      className=""
                      value={ editedOrDefaultFundingPayment.status }
                      onChange={ onStatusChange }
                      aria-label="funding payment status"
                      inputValue={ statusInputValue }
                      onInputChange={ onStatusInputChange }
                      options={['Due At Delivery', 'Received']}
                      renderInput={(params) => <TextField {...addTestingTag(params, 'fundingPayment.status')} label="STATUS" />}
                  />
                  <FormError validate={ editedFundingPaymentErrors.status }>
                    Status is required.
                  </FormError>
                </div>
                <div className="container fw-bold text-secondary my-3 ">
                  <label htmlFor="stock" className="mb-1 text-uppercase">Applied Amount ("Proceeds")</label>
                  <NumberFormat
                    onBlur={e=>formatPayment(e, onAmountChange)}
                    className="form-control rounded-pill mt-1 "
                    value={ formattedAmount }
                    name="amount"
                    aria-label="funding payment applied amount"
                    thousandSeparator={true}
                    prefix={'$'}
                    data-testid="fundingPayment.amount"
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      onAmountChange(formattedValue, value)
                    }}
                  />
                  <FormError validate={ editedFundingPaymentErrors.amount }>
                    Amount is required.
                  </FormError>
                </div>
                <div className="container fw-bold text-secondary my-3 ">
                  <label htmlFor="stock" className="mb-1">Admin Fee</label>
                  <NumberFormat
                    className="form-control rounded-pill mt-1 "
                    value={ formattedAdminFee }
                    name="adminFee"
                    aria-label="admin fee"
                    thousandSeparator={true}
                    data-testid="fundingPayment.adminFee"
                    prefix={'$'}
                    onBlur={e=>formatPayment(e, onAdminFeeChange)}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      onAdminFeeChange(formattedValue, value)
                    }}
                  />
                </div>
                <div className="container fw-bold text-secondary my-3 ">
                  <label htmlFor="stock" className="mb-1">GAP</label>
                  <NumberFormat
                    className="form-control rounded-pill mt-1 "
                    value={ formattedGapAmount }
                    name="gapAmount"
                    aria-label="gap amount"
                    thousandSeparator={true}
                    data-testid="fundingPayment.gapAmount"
                    prefix={'$'}
                    onBlur={e=>formatPayment(e, onGapAmountChange)}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      onGapAmountChange(formattedValue, value)
                    }}
                  />
                </div>
                <div className="container fw-bold text-secondary my-3 ">
                  <label htmlFor="stock" className="mb-1">Funding Reserve</label>
                  <NumberFormat
                    className="form-control rounded-pill mt-1 "
                    value={ formattedFundingReserveAmount }
                    name="fundingReserveAmount"
                    aria-label="funding reserve amount"
                    thousandSeparator={true}
                    data-testid="fundingPayment.fundingReserveAmount"
                    prefix={'$'}
                    onBlur={e=>formatPayment(e, onFundingReserveAmountChange)}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      onFundingReserveAmountChange(formattedValue, value)
                    }}
                  />
                </div>
                <div className="container fw-bold text-secondary my-3 ">
                  <label htmlFor="stock" className="mb-1">Netted Payoff Amount</label>
                  <NumberFormat
                    className="form-control rounded-pill mt-1 "
                    value={ formattedNettedPayoffAmount }
                    name="nettedPayoffAmount"
                    aria-label="netted payoff amount"
                    thousandSeparator={true}
                    data-testid="fundingPayment.nettedPayoffAmount"
                    prefix={'$'}
                    onBlur={e=>formatPayment(e, onNettedPayoffAmountChange)}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      onNettedPayoffAmountChange(formattedValue, value)
                    }}
                  />
                </div>
                <div className="container fw-bold text-secondary my-3 ">
                  { paymentMethods && paymentMethods.length > 0 ? (
                    <Autocomplete
                      value={ getPaymentMethodObject(editedOrDefaultFundingPayment.paymentMethodId)??null }
                      onChange={ onPaymentMethodChange }
                      options={paymentMethods??null}
                      aria-label="payment method"
                      getOptionLabel={ option => option.name }
                      renderInput={(params) => <TextField {...addTestingTag(params, 'fundingPayment.paymentMethodId')} label="PAYMENT METHOD" />}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                      />
                    ):null}
                  <FormError validate={ editedFundingPaymentErrors.paymentMethodId }>
                    Payment method is required.
                  </FormError>
                </div>
                <div className="container fw-bold text-secondary my-3 ">
                  <label htmlFor="type" className="mb-1">Document Number</label>
                  <input
                    type="text"
                    className="form-control rounded-pill mt-1 "
                    name="documentNumber"
                    aria-label="document number"
                    maxLength={50}
                    onChange={(e) => onDocumentNumberChange(e)}
                    value={ editedOrDefaultFundingPayment.documentNumber } 
                    data-testid="fundingPayment.documentNumber"
                  />
                  <FormError validate={ editedFundingPaymentErrors.documentNumber }>
                    Document number is required.
                  </FormError>
                </div>
                <div className="container fw-bold text-secondary my-3 ">
                  <label htmlFor="stock">Date Received</label>
                  <input
                    type="date"
                    className="form-control rounded-pill mt-1 "
                    name="dateReceived"
                    aria-label="date received"
                    onChange={(e) => onDateReceivedChange(e)}
                    data-testid="fundingPayment.dateReceived"
                    value={ editedOrDefaultFundingPayment.dateReceived ? moment.utc(editedOrDefaultFundingPayment.dateReceived).format("YYYY-MM-DD") : "" }
                  />
                  <FormError validate={ editedFundingPaymentErrors.dateReceived }>
                    Date Received is required.
                  </FormError>
                </div>
              </div>
            </div>

            {/* MODAL BODY END HERE */}

            <div className="modal-footer text-center">
              <div className="mx-auto">
              {showSpinner?<CircularProgress/>:(isValidForSubmit ? (
                <button
                  type="button"
                  className={ "btn mx-auto "+(showSuccessFeedback ? "btn-success" : "btn-primary")}
                  onClick={ onFormSubmit } 
                  aria-label="submit"
                  data-bs-dismiss="modal"
                  data-testid="fundingPayment.control.saveBottom"
                >
                  { showSuccessFeedback ? "Funding Payment Saved" : "Save" }
                </button>
                ) : (
                  <button
                    type="button"
                    className="btn mx-auto btn-primary"
                    onClick={ onFormSubmit } 
                    aria-label="save"
                    data-testid="fundingPayment.control.saveBottom"
                    data-bs-dismiss="">
                  Save
                </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default FundingPaymentModal

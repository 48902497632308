import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import { removeChars, formatter, formatPhoneNumber, formatUpperCase } from "../../../../../../../utlis/formatters";
import { getOrderLevelAddresses, updateAddressOptions } from "../../../../../../../utlis/documentHelpers";
import { useOrder } from "../../../../../../../redux/orders/order"
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { CAPowerOfAttorneyGlobalAutofill, CAPowerOfAttorneyAutofillProductDetails, CAPowerOfAttorneyAutofillTradeDetails, preProcessPayload } from "../DocumentAutofill/CAPowerOfAttorney"

const CAPowerOfAttorney = ({document_id, location, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "ca-poa";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //

  const axios = useFetch()
  let { id } = useParams();
  // const dispatch = useDispatch();

  // const stateAbbrev = States.map(state => {
  //   return state.abbreviation;
  // });

  const order = useOrder();
  
  const [documentUrl, setdocumentUrl] = useState("");
  const [document, setDocument] = useState(undefined);
  const [previousDocumentId, setPreviousDocumentId] = useState(0);

  // Data Structure & Init //

  // X1 Reference Arrays - TODO: Possibly move these to back end as part of the template stucture
const moneyFields = useMemo(()=>{return[]},[]);
const phoneFields = useMemo(()=>{return[]},[]);
const checkboxes = useMemo(()=>{return[]},[]);
const upperCaseFields = useMemo(()=>{return["vin"]},[]);
  
  var initialDocumentStructure = {
    mc_engine_number: undefined,
    license_plate: undefined,
    make: undefined,
    year: undefined,
    vin: undefined,
    owner_name: undefined,
    owner_name_2: undefined,
    poa_name: undefined,
    owner_city: undefined,
    owner_state: undefined,
    owner_zip: undefined,
    owner_id: undefined,
    owner_id_2: undefined,
    owner_date: undefined,
    owner_date_2: undefined,
    owner_zip_2: undefined,
    owner_state_2: undefined,
    owner_city_2: undefined,
    copy_vin: undefined,
    copy_year: undefined,
    copy_make: undefined,
    copy_license_plate: undefined,
    copy_mc_engine_number: undefined,
    copy_owner_name: undefined,
    copy_owner_name_2: undefined,
    copy_poa_name: undefined,
    copy_owner_city: undefined,
    copy_owner_city_2: undefined,
    copy_owner_state: undefined,
    copy_owner_zip: undefined,
    copy_owner_date: undefined,
    copy_owner_date_2: undefined,
    copy_owner_id: undefined,
    copy_owner_id_2: undefined,
    copy_owner_state_2: undefined
  }
  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  const handleDocumentValuesChange = e =>{
    e.preventDefault();
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatPhoneNumber(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  
  const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('CAPowerOfAttorney.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])

  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, documentLoaded, loadDocument ])
  const [assignedProduct, setAssignedProduct] = useState(undefined);
  const [assignedTrade, setAssignedTrade] = useState(undefined);

  // const [productVal, setproductVal] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  // const [tradeinVal, setTradeinVal] = useState(undefined);
  // const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  // const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);

  const [selectedAddress1, setSelectedAddress1] = useState(undefined);
  const [selectedAddress2, setSelectedAddress2] = useState(undefined);
  const [addressOptions, setAddressOptions] = useState(undefined);
  const [inputAddressVal, setinputAddressVal] = useState(undefined);

  // Dropdowns //

  // if(order && order.products && order.products.length > 0 && productOptions === undefined){
  //   let getProductOptions = [];
  //   order.products.map(product => {
  //     let str = `${product.stockNumber.toString().toUpperCase()}`;
  //     let obj = {id: product.id, name: str}
  //     getProductOptions.push(obj);
  //   })
  //   setproductOptions(getProductOptions);
  // }

  // if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
  //   let getTradeinOptions = [];
  //   order.tradeins.map(tradein => {
  //     let str = `${tradein.year} ${tradein.make} ${tradein.model.toString().toUpperCase()}`;
  //     let obj = {id: tradein.id, name: str}
  //     getTradeinOptions.push(obj);
  //   })
  //   setTradeinOptions(getTradeinOptions);
  // }

 // Autofill //

 const GlobalAutofill = useCallback(async () => {
  let newValues = CAPowerOfAttorneyGlobalAutofill(documentValues, order);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

    // var newValues = documentValues;

    // if(order && order.customer && order.customer.name){
    //   newValues.owner_name = order.customer.name
    //   newValues.copy_owner_name = order.customer.name
    // } 
    
    // if(order.customer.dba){
    //   newValues.owner_name += " dba "+order.customer.dba;
    //   newValues.copy_owner_name += " dba "+order.customer.dba;
    // }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
    //     let cosigner = order.customer.cosigners[0];
    //     if(cosigner && cosigner.name){
    //       newValues.owner_name += " AND "+cosigner.name;
    //       newValues.copy_owner_name += " AND "+cosigner.name;
    //     }
    // }

    // // if(order && order.documentationDate){
    // //   newValues.owner_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    // //   newValues.owner_date_2 = moment.utc(order.documentationDate).format("YYYY-MM-DD");      
    // //   newValues.copy_owner_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    // //   newValues.copy_owner_date_2 = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    // // }

    // setDocumentValues(newValues);
    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  },[ documentValues, order ])

//   const preProcessPayload = useCallback((payload) => {

//     // X1.2- Field Type Specific Preview Formatting
//     Object.keys(payload).forEach(key => {
//       if(moneyFields.indexOf(key) >= 0){
//           payload[key] = formatMoneyValue(payload[key]);
//       }else if(phoneFields.indexOf(key) >= 0){
//           payload[key] = formatPhoneNumber(payload[key]);
//       }else if(upperCaseFields.indexOf(key) >= 0){
//           payload[key] = formatUpperCase(payload[key]);
//       }
//     });
//     setDocumentValues(JSON.parse(JSON.stringify(payload)));
//     Object.keys(payload).forEach(key => {
//       if(checkboxes.indexOf(key) >= 0){
//         payload[key] = payload[key] ? "yes": "no";
//       }
//     });

//     payload.copy_vin = payload.vin;
//     payload.copy_mc_engine_number = payload.mc_engine_number;
//     payload.copy_license_plate = payload.license_plate;
//     payload.copy_make = payload.make;
//     payload.copy_year = payload.year;
//     payload.copy_owner_name = payload.owner_name;
//     payload.copy_owner_name_2 = payload.owner_name_2;
//     payload.copy_poa_name = payload.poa_name;
//     payload.copy_owner_city = payload.owner_city;
//     payload.copy_owner_state = payload.owner_state;
//     payload.copy_owner_zip = payload.owner_zip;
//     payload.copy_owner_id = payload.owner_id;
//     payload.copy_owner_id_2 = payload.owner_id_2;
//     payload.copy_owner_date = payload.owner_date;
//     payload.copy_owner_date_2 = payload.owner_date_2;
//     payload.copy_owner_zip_2 = payload.owner_zip_2;
//     payload.copy_owner_state_2 = payload.owner_state_2;
//     payload.copy_owner_city_2 = payload.owner_city_2;


//     // Data point formatting
//     if(payload.owner_date){
//       payload.owner_date = moment.utc(payload.owner_date).format("MM/DD/YY");
//     }
  
//     if(payload.owner_date_2){
//       payload.owner_date_2 = moment.utc(payload.owner_date_2).format("MM/DD/YY");
//     }

//     if(payload.copy_owner_date){
//       payload.copy_owner_date = moment.utc(payload.copy_owner_date).format("MM/DD/YY");
//     }
  
//     if(payload.copy_owner_date_2){
//       payload.copy_owner_date_2 = moment.utc(payload.copy_owner_date_2).format("MM/DD/YY");
//     }

//     return payload;
// },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])


const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
  Object.keys(payload).forEach(key => {
    if (payload[key] === null || payload[key] === undefined) {
      delete payload[key];
    }
  });
  
  var docCopy = JSON.parse(JSON.stringify(document));
  docCopy.payload =  JSON.parse(JSON.stringify(payload));
  docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
  docCopy.payload =  JSON.stringify(docCopy.payload);
  delete docCopy.documentStatus;
  try{
    let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
    const file = new Blob(
      [response.data], 
      {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      // window.open(fileURL);
      setdocumentUrl(fileURL)
  } catch (err) {
    recordFrontEndError('CAPowerOfAttorney.jsx', 'Error previewing document payload: ' + err)
  }
},[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


  const AutofillProductDetails = useCallback(async (selectedProduct) => {
    let newValues = CAPowerOfAttorneyAutofillProductDetails(selectedProduct, documentValues, null, null, location);

    if(newValues){
      setDocumentValues(newValues);
      previewPayloadDocument(documentValues);
      setHasUnsavedChanges(true);
      if(document){
        previewPayloadDocument(documentValues);
      }
      setSaveSuccess(false);
    }

    // var newValues = documentValues;
    // newValues.year = (selectedProduct.year).trim();
    // newValues.make = (selectedProduct.make).trim();
    // newValues.vin = (selectedProduct.vin.toUpperCase());

    // newValues.copy_year = (selectedProduct.year).trim();
    // newValues.copy_make = (selectedProduct.make).trim();
    // newValues.copy_vin = (selectedProduct.vin.toUpperCase());
    
    // if(location){
    //   if(location.state === "CA"){
    //     newValues.poa_name = "TEC OF CALIFORNIA, INC";
    //     newValues.copy_poa_name = "TEC OF CALIFORNIA, INC";
    //   }else{
    //     newValues.poa_name = "TEC EQUIPMENT, INC.";
    //     newValues.copy_poa_name = "TEC EQUIPMENT, INC.";
    //   }
    // }



    // setDocumentValues(newValues);
    // // setProductToggleAutoFill(false);
    // if(document){
    //   previewPayloadDocument(documentValues);
    // }
    // // setAddressOptions(updateAddressOptions(selectedProduct, order));
    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  },[document, documentValues, location, previewPayloadDocument]);

  const AutofillTradeDetails = useCallback(async (selectedTradein) => {

    let newValues = CAPowerOfAttorneyAutofillTradeDetails(selectedTradein, documentValues, null, null, location);

    if(newValues){
      setDocumentValues(newValues);
      previewPayloadDocument(documentValues);
      setHasUnsavedChanges(true);
      if(document){
        previewPayloadDocument(documentValues);
      }
      setSaveSuccess(false);
    }

    // var newValues = documentValues;
    // newValues.year = (selectedTradein.year).trim();
    // newValues.make = (selectedTradein.make).trim();
    // newValues.vin = (selectedTradein.vin.toUpperCase());

    // newValues.copy_year = (selectedTradein.year).trim();
    // newValues.copy_make = (selectedTradein.make).trim();
    // newValues.copy_vin = (selectedTradein.vin.toUpperCase());
    
    // if(location){
    //   if(location.state === "CA"){
    //     newValues.poa_name = "TEC OF CALIFORNIA, INC";
    //     newValues.copy_poa_name = "TEC OF CALIFORNIA, INC";
    //   }else{
    //     newValues.poa_name = "TEC EQUIPMENT, INC.";
    //     newValues.copy_poa_name = "TEC EQUIPMENT, INC.";
    //   }
    // }

    // setDocumentValues(newValues);
    // // setProductToggleAutoFill(false);
    // if(document){
    //   previewPayloadDocument(documentValues);
    // }
    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  },[document, documentValues, location, previewPayloadDocument]);

  useEffect(() => {
    if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){

      existingPayload = true;

      // Init Data Points
      if(documentPayload.owner_date){
        documentPayload.owner_date = moment.utc(documentPayload.owner_date).format("YYYY-MM-DD");
      }

      if(documentPayload.owner_date_2){
        documentPayload.owner_date_2 = moment.utc(documentPayload.owner_date_2).format("YYYY-MM-DD");
      }

      if(documentPayload.copy_owner_date){
        documentPayload.copy_owner_date = moment.utc(documentPayload.copy_owner_date).format("YYYY-MM-DD");
      }

      if(documentPayload.copy_owner_date_2){
        documentPayload.copy_owner_date_2 = moment.utc(documentPayload.copy_owner_date_2).format("YYYY-MM-DD");
      }
    
      setDocumentValues(documentPayload);

      // Loading Use Effect
      if(document.additionalData){
        const methodMap = { 
          selectedAddress1: setSelectedAddress1, 
          selectedAddress2: setSelectedAddress2, 
        };

        let parsedAdditionalData = JSON.parse(document.additionalData);
        Object.keys(parsedAdditionalData).forEach(function(key) {
          if(methodMap[key]){
            methodMap[key](parsedAdditionalData[key]);
          }
        });
      }


    }

    var foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }
    
    if(foundAssignedProduct){
      setAssignedProduct(foundAssignedProduct);
    }
    
    var foundAssignedTradein = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'tradein'){
      foundAssignedTradein = order.tradeins.find((trade) => trade.id === document.assignedObjectId);
    }
    
    setAddressOptions(getOrderLevelAddresses(order));
    if(foundAssignedProduct){
        setAssignedProduct(foundAssignedProduct);
        setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
    }
    
    if(!existingPayload){
      GlobalAutofill();
      previewPayloadDocument(documentValues);
      if(foundAssignedProduct){
        AutofillProductDetails(foundAssignedProduct)
      }
      if(foundAssignedTradein){
        AutofillTradeDetails(foundAssignedTradein)
      }
    }
    
    setDataLoaded(true);
  }
},[ documentValues, document, dataLoaded, order, AutofillProductDetails, AutofillTradeDetails, GlobalAutofill, previewPayloadDocument ])


  const updatePreview = e => {
    e.preventDefault();
    previewPayloadDocument(documentValues);
  }

  const handleSubmit = async event => {
    event.preventDefault();

    let payload = JSON.parse(JSON.stringify(documentValues));

    payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);


    //Save Dropdown Selections
    let dropdownData = {};
    if(selectedAddress1){
      dropdownData.selectedAddress1 = {
        id: selectedAddress1.id,
        name: selectedAddress1.name
      };
    }
    if(selectedAddress2){
      dropdownData.selectedAddress2 = {
        id: selectedAddress2.id,
        name: selectedAddress2.name
      };
    }
    let additionalData = JSON.stringify(dropdownData);

    DocumentHandleSubmit(document, payload, additionalData, () => {
      getDocuments();
      setSaveSuccess(true);
      setHasUnsavedChanges(false);
    });

    // docCopy.additionalData = JSON.stringify(dropdownData);

    // try {
    //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
    //   if (res) {
    //     axios.post(`/document/${document.id}/generate`)
    //     getDocuments();
    //     setSaveSuccess(true);
    //     setHasUnsavedChanges(false);
    //   }
    // } catch (err) {
    //   recordFrontEndError('CAPowerOfAttorney.jsx', 'Error saving document: ' + err)
    // }
  }


  // Component Helpers //

  // useEffect(() => {
    //Autofill Product Details After Selection

  // },[ selectedProduct, productToggleAutoFill ])

  // useEffect(() => {
  //   //Autofill Tradein Details After Selection
  //   if(selectedTradein !== undefined && tradeinToggleAutoFill){
  //     var newValues = documentValues;
  //     newValues.trade_year = (selectedTradein.year);
  //     newValues.trade_make = (selectedTradein.make);
  //     newValues.trade_model = (selectedTradein.model);
  //     newValues.trade_body_type = (selectedTradein.seriesBodyType);
  //     newValues.trade_serial_number = (selectedTradein.serialNumber);
  //     newValues.balance_owner = (order.customer.name); //Need specific field for TRADE IN OWNER?
  //     newValues.balance_owner_address = (order.customer.deliveryAddress.addressLine1);
  //     newValues.amount = (selectedTradein.balanceOwed);
  //     newValues.trade_allowance = (selectedTradein.tradeAllowanceAtTerms);
  //     newValues.balance_owed = (selectedTradein.balanceOwed);
  //     newValues.cash_refund = (selectedTradein.cashRefundToCustomer);
  //     newValues.net_trade_allowance = (selectedTradein.finalTradeAllowance);
  //     // newValues.cash_down_payment = (selectedTradein);

  //     setDocumentValues(newValues);
  //     setTradeinToggleAutoFill(false);
  //     previewPayloadDocument(documentValues);
  //   }
  // },[ selectedTradein, tradeinToggleAutoFill ])

  //Autofill Address Details After Selection
  function AutoFillAddress(addressSelected, selectionInstance){
    var newValues = documentValues;
    if(selectionInstance){
      if(selectionInstance === "owner-1"){
        newValues.owner_city = addressSelected.city;
        newValues.owner_state = addressSelected.state;
        newValues.owner_zip = addressSelected.zip;
      }

      if(selectionInstance === "owner-2"){
        newValues.owner_city_2 = addressSelected.city;
        newValues.owner_state_2 = addressSelected.state;
        newValues.owner_zip_2 = addressSelected.zip;
      }
    }
    setDocumentValues(newValues);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  const onCloseClick = e => {
    e.preventDefault()
    setHasUnsavedChanges(false);
    setSaveSuccess(false);
    setdocumentUrl("");
    setDocument(undefined);
    setPreviousDocumentId(0);
    setDocumentValues(initialDocumentStructure);
    setDocumentLoaded(false);  
    setDataLoaded(false);
    setAssignedProduct(undefined);
    setAssignedTrade(undefined);
    setSelectedAddress1(undefined);
    setSelectedAddress2(undefined);
    setAddressOptions(undefined);
    setinputAddressVal(undefined);
    setDocumentFocus(undefined);

  }
  
  const [hasClickedClose, setHasClickedClose] = useState(false);
  
  const closeClicked = e => {
    if( hasUnsavedChanges ){
        setHasClickedClose(true);
    } else {
        onCloseClick(e);
    }
  }
  
  const preCloseSave = e => {
    handleSubmit(e)
    setHasClickedClose(false);
  }
  
  const preCloseClose = e => {
    setHasClickedClose(false);
    onCloseClick(e)
  }

  return (
    <>
      <div
        className="modal fade"
        // id="ca-poa"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                CA - Power of Attorney
              </h5>
              <br />
              { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1 pt-1 text-white">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                  </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}
                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                
                <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                    GlobalAutofill();
                    if(assignedProduct){
                      AutofillProductDetails(assignedProduct);
                    }

                    if(assignedTrade){
                      AutofillTradeDetails(assignedTrade);
                    }

                    previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                </div>

                <br />

                { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Product Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                        </div>
                    </div>
                ):null}

                { assignedTrade ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Trade In Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                          <h5>VIN: { assignedTrade.vin.toUpperCase() }</h5>
                          <h5>YMM: { assignedTrade.year+" "+assignedTrade.make+" "+assignedTrade.model.toUpperCase() }</h5>
                        </div>
                    </div>
                ):null}

                  <div className="row">
                    <h5>Equipment</h5>
                  </div>
                  
                  <div className="row">
                    <label htmlFor="stock"><b>VEHICLE/VESSEL DESCRIPTION</b></label>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>
                    </div>


                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">License Plate</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="license_plate"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.license_plate ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">MC Engine Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mc_engine_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mc_engine_number ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Owner Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_name ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">POA Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="poa_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.poa_name ?? '' }
                          />
                      </div>
                    </div>

                    {addressOptions && addressOptions.length > 0 ? (  
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress1 && selectedAddress1.name) ?? null}
                          name="buyer-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress1(newValue);
                            AutoFillAddress(newValue, "owner-1");
                            setSelectedAddress2(newValue);
                            AutoFillAddress(newValue, "owner-2");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Owner Address" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>          ):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Owner City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Owner State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Owner Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_zip ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly mb-3">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Owner Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="owner_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_date ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                      <label htmlFor="stock">Dealer #/ID</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_id"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_id ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    {addressOptions && addressOptions.length > 0 ? (  
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress2 && selectedAddress2.name) ?? null}
                          name="buyer-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress2(newValue);
                            AutoFillAddress(newValue, "owner-2");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Owner Address (2)" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>                   ):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Owner City (2)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_city_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_city_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Owner State (2)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_state_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_state_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Owner Zip (2)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_zip_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_zip_2 ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Owner Date (2)</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="owner_date_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_date_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                      <label htmlFor="stock">Dealer #/ID (2)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="owner_id_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.owner_id_2 ?? '' }
                          />
                      </div>
                    </div>

                  </div>
                  <hr></hr>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default CAPowerOfAttorney

// import { loadOrder, getDocumentsForExport } from "../../../utlis/api";
// import moment from 'moment';

//   const downloadFile = ({ dataPayload, fileName, fileType }) => {
//     const blob = new Blob([dataPayload], { type: fileType })
//     const url = URL.createObjectURL(blob)
    
//     const link = document.createElement('a')
//     link.href = url
//     link.download = fileName
//     link.click()
    
//     URL.revokeObjectURL(url)
//   }
  
// const exportToJson = async (orderId) => {
//     let dataPayload = await loadOrder(orderId);
//     dataPayload.data.id = orderId;
//     dataPayload.data.exportOrderId = orderId;
//     dataPayload.data.exportSource = process?.env?.REACT_APP_SERVER_TAG;
//     dataPayload.data.exportedAt = moment.utc();

//     let response = await getDocumentsForExport(orderId);
//     if (response?.data) {
//       dataPayload.data.documents = response.data;
//     }

//     downloadFile({
//       dataPayload: JSON.stringify(dataPayload.data),
//       fileName: 'FAST_ORDER_ID_'+orderId+'_'+moment.utc().format('YYYY-MM-DD')+'.json',
//       fileType: 'text/json',
//     })
//   }

const isIndexPageSearchDisabled = (searchTerm, searchSegment, startDate, endDate) => {
    return (searchTerm && searchTerm !== "" && searchSegment !== "date") || (startDate && endDate && searchSegment === "date") ? false : true;
}

export { 
    isIndexPageSearchDisabled
}
import React from "react";
import { Accordion, Card} from "react-bootstrap";
import "./Style/orderquote.css";

const ProductAdditions = ({ additions }) => {

  return (
    <div className="container-fluid">
      { additions && additions.length > 0 ? (
        <div className="row">
          <Accordion defaultActiveKey="a1">
            <Card className="p-3">
              <Card.Header style={{ backgroundColor: '#2d7ecf', color: 'white', padding: '1rem' }}>
                <span style={{ paddingLeft: '15px', fontWeight: 'bold' }}>ADDITIONS</span>
              </Card.Header>

              <Accordion.Collapse eventKey="a1">
                <div className="p-4">
                  <div className="d-flex justify-content-between">
                      <div style={{ width: '100%' }}>
                      <Accordion>
                        {additions.map((addition, index) => (
                          <Accordion.Item eventKey={addition.id}>
                            <Accordion.Header>Addition# {index + 1}</Accordion.Header>
                              <Accordion.Body>
                              <div>
                                <div className="p-4 border-start border-5 border-primary">
                                  <div className="d-flex justify-content-between">
                                    <div className="p-4">
                                      <p className="text-secondary">{ addition.serialNumber }</p>
                                      <h5>SERIAL NUMBER</h5>
                                    </div>

                                    <div className="p-4">
                                      <p className="text-secondary">{ addition.type }</p>
                                      <h5>TYPE</h5>
                                    </div>
                                  </div>
                                </div>
                              
                                <div className="p-4 bg-light border-start border-5 border-primary">
                                  <div className="d-flex justify-content-between">
                                    <div className="p-4">
                                      <p className="text-secondary">{ addition.description }</p>
                                      <h5>DESCRIPTION/NOTES</h5>
                                    </div>
                                  </div>
                                </div>
                                <hr></hr>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                        </Accordion>
                      </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      ):('')}
    </div>
  );
}

export default ProductAdditions;

import React, { useState, useEffect, useCallback } from "react";
import * as GridComponents from "@material-ui/data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import { Menu, Link, CircularProgress } from '@mui/material';
import { useHistory } from 'react-router';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu';
import Navbar from "../../../Components/Navbar/Navbar";
import PopupState, {bindTrigger, bindMenu } from 'material-ui-popup-state'
import moment from 'moment'
import FormControl from '@mui/material/FormControl';
import { setOrder } from "../../../redux/orders/order";
import { setOrders } from "../../../redux/orders/orders";
import{setIsLoading} from '../../../redux/spinners/spinners'
import { useFetch } from "../../../utlis/api";
import { isIndexPageSearchDisabled } from "../../../utlis/indexPages";
import {MenuItem} from "@material-ui/core";
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/material/Select';
import {InputLabel, Input, Box } from '@mui/material';

const Order = () => {

  let match = useRouteMatch("/order");

  const dispatch  = useDispatch();
  const history   = useHistory();
  const axios     = useFetch()
  const orders    = useSelector((state) => state?.ordersState);
  const role = useSelector((state) => state?.role.role);
  const {isLoading} = useSelector((state)=>state.spinners)

  const pageSize = 10;
  const [currentPage, setCurrentPage] = React.useState(0);
  const [currentSearch, setCurrentSearch] = React.useState("");
  const [rowCount, setRowCount] = React.useState(0);
  const [pageState, setPageState] = useState('uninitalized');
  const [filterSegment, setFilterSegment] = useState('none');


  const today = moment.utc(new Date()).format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(today);


  //ACTION CREATORS
  const refreshOrders = useCallback((axios, page=currentPage, filter=currentSearch, filterCategory=filterSegment, startDay=startDate, endDay=endDate) => {
    setPageState('loading');
    page = page === 0 ? 1 : page;

    let connstring = `/order?pageNumber=`+page+`&pageSize=10`;

    if(role && role !== 'master' && role !== 'default'){
      connstring += `&sim=${role}`;
    }

    if(filterCategory !== "none"){
      connstring += `&filterSegment=`+filterCategory.trim();
    }

    if(filterSegment === "date" && startDay !== null && endDay !== null){
      connstring += `&startDate=`+startDay+`&endDate=`+endDay;
    }else if(filter && filter !== ""){
      connstring += `&filter=`+filter.trim();
    }

    dispatch(setOrders([]))

    axios
      .get(connstring)
      .then( res => {
        if (res?.status === 200) {
          dispatch(setOrders(res?.data.data))
          setRowCount(res?.data.count)
        }
        setPageState('ready');
      });

  },[currentPage, currentSearch, role, filterSegment, startDate, endDate, dispatch])


const searchUpdatePage = (e) => {
  const { code } = e;
  if((code === "Enter" || code === "NumpadEnter") && currentSearch && currentSearch !== ""){
    refreshOrders(axios, 1, currentSearch);
  }
}


const updatePage = useCallback((pageNumber=currentPage) => {

  setCurrentPage(pageNumber);
  let newPage = (pageNumber+1);
  refreshOrders(axios, newPage, currentSearch);

},[ axios, refreshOrders, currentPage, currentSearch])



  const startOrder = (axios) => {
    dispatch(setIsLoading(true))
    axios.post(`/new-order`, {})
      .then( res => {
        // dispatch(setOrder(res?.data))
        history.push(`/order/${ res.data.id }`);
        dispatch(setIsLoading(false))
      });
  }
    
  useEffect(() => {
      if(match?.isExact === true && pageState === 'uninitalized'){
      dispatch(setOrder({}))
      refreshOrders(axios);
    }else if(match === null){
      setPageState('uninitalized');
    }
  }, [refreshOrders, axios, match, pageState, dispatch])


  const startNewOrder = () => {
    startOrder(axios)
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    )
  }

  const setCustomDateRange = (date, marker) =>{
    if(marker === 'start') {
      setStartDate(date);
    }
    if(marker === 'end') {
      setEndDate(date);
    }
  }

  // function isSearchDisabled(searchTerm, searchSegment, startDate, endDate){
  //   return (currentSearch && currentSearch !== "" && filterSegment !== "date") || (startDate && endDate && filterSegment === "date") ? false : true;
  // }

  function resetSearch(){
    setCurrentSearch('');
    setStartDate(null);
    setEndDate(today);
    refreshOrders(axios, 1, "", "none", null, null);
  }
  
  //Columns for the Data Grid component...
  const columns = [
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      filterable: false,
      renderCell: params => {
  
        return (
  
        <div style={{ borderColor: '#fff' }}>
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <React.Fragment>
                <IconButton color="primary" aria-label="upload picture" {...bindTrigger(popupState)} component="span">
                  <MenuIcon />
                </IconButton>
                <Menu {...bindMenu(popupState)}>
                  <Link to='/' href={`/order/${params.id}`} style={{ textDecoration: 'none' }}>
                    <MenuItem disabled={(pageState !== 'ready')} className="m-2" onClick={popupState.close}>VIEW/EDIT ORDER</MenuItem>
                  </Link>
                  {/* <br />
                    <MenuItem className="m-2" onClick={e => {
                      exportToJson(params.id);
                    }}>EXPORT ORDER</MenuItem> */}
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        </div>
        );
      },
    },
    { field: "id", 
      headerName: "ID",
      sortable: false,
    },
    {
      field: "customer",
      headerName: "Customer",
      sortable: false,
      flex: 1
    },
    {
      field: "contactNames",
      headerName: "Add. Contacts",
      sortable: false,
      flex: 1
    },
    {
      field: "orderStatus",
      headerName: "Status",
      sortable: false,
      flex: 1
    },
    {
      field: "salesRepName",
      headerName: "Sales Rep.",
      sortable: false,
      flex: 1
    },
    {
      field: "salesManagerName",
      headerName: "Sales Manager",
      sortable: false,
      flex: 1
    },
    {
      field: "orderDate",
      headerName: "Order Date",
      sortable: false,
      flex: 1,
      valueFormatter: (params) => { return moment.utc(params.value).format("MM/DD/YYYY")},
      valueGetter: (params) => { return moment.utc(params.value).format("MM/DD/YYYY")}
    },
    {
      field: "fundedStatus",
      headerName: "Funded Status",
      sortable: false,
      flex: 1,
    },
    {
      field: "stockNumbers",
      headerName: "Stock Numbers",
      sortable: false,
      flex: 1,
    },
        {
      field: "vinNumbers",
      headerName: "VIN Numbers",
      sortable: false,
      flex: 1,
    }
  ];

  return (
    <>
      <Navbar title="Sales Orders" />
      <div className="mx-4 mt-2">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <h3>Latest Sales Orders</h3>
            <div className="mx-3">
              {isLoading ? <CircularProgress style={{placeSelf:'center'}}/>:
                <>
                  <button
                    onClick={startNewOrder}
                    type="button"
                    className="btn btn-success btn-sm text-uppercase mx-2"
                    >
                    Start New Order
                  </button>
                  {/* <button
                    onClick={() => fileInputRef.current.click()}
                    type="button"
                    className="btn btn-warning btn-sm text-uppercase mx-2"
                    >
                    Import Order
                    <input  ref={fileInputRef} type="file" hidden onChange={importOrder} />
                  </button> */}
                </>
              }
            </div>
          </div>
        </div>
      </div>

      <Card>
        <CardContent style={{ width: "100%" }}>

          <div className="d-flex justify-evenly">
            <div>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small" className="my-2 px-3">
                <Select
                  value={filterSegment}
                  onChange={e => {
                    let { value } = e.target;
                    setFilterSegment(value);
                    if(value === "none"){
                      resetSearch();
                    }

                    if(value === "date"){
                      setCurrentSearch('');
                    }else{
                      setStartDate(null);
                      setEndDate(today);
                    }
                  }}
                  
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                  data-testid="indexPage.orders.control.filterSegment"
                  // disabled={filterSegment === 'none'}
                  style={{ width: '250px'}}
                >
                  <MenuItem value="none" ><em>Reset Search</em></MenuItem>
                  <MenuItem value="customer" ><em>Customer & Contacts</em></MenuItem>
                  <MenuItem value="status" ><em>Status</em></MenuItem>
                  <MenuItem value="sales-rep" ><em>Sales Rep</em></MenuItem>
                  <MenuItem value="sales-manager"><em>Sales Manager</em></MenuItem>
                  <MenuItem value="date"><em>Order Date</em></MenuItem>
                  <MenuItem value="funding-status" ><em>Funding Status</em></MenuItem>
                  <MenuItem value="stock-number" ><em>Stock Number</em></MenuItem>
                  <MenuItem value="vin" ><em>Vin</em></MenuItem>

                </Select>
              </FormControl>
            </div>

              { filterSegment === "date" ? (
                <>
                  <div className="mb-1 pb-1 mx-1">
                    <Box mr={2} sx={{height: '50%'}}>
                      <InputLabel shrink={'true'} size={'small'} mt={2}>Start Date</InputLabel>
                      <Input
                      size={'small'}
                      id="start-date"
                      type="date"
                      label="Start Date"
                      value={startDate}
                      inputProps={{}}
                      onChange={(e) => setCustomDateRange(e.target.value, 'start')}
                    />
                  </Box>
                </div>                
                <div className="mb-1 pb-1 mx-1">
                  <Box>
                    <InputLabel shrink={'true'} size={'small'}>End Date</InputLabel>
                    <Input
                      size={'small'}
                      id="end-date"
                      type="date"
                      label="End Date"
                      value={endDate}
                      onChange={(e) => setCustomDateRange(e.target.value, 'end')}
                      inputProps={{ min: startDate}}
                    />
                  </Box>
                </div>
              </>
              ):(
                <div className="my-1 py-1" style={{ width: 500 }}>
                  <input
                    type="text"
                    className="form-control rounded-pill"s
                    onChange={e => setCurrentSearch(e.target.value)}
                    onKeyUp={searchUpdatePage}
                    onFocus={e => { if(filterSegment === 'none') setFilterSegment('customer'); }}
                    value={currentSearch}
                    placeholder="Start Search"
                    aria-label="Search"
                    disabled={pageState === "loading"}
                    />  
                </div>
              )}
            <div className="my-1 px-2 py-1">
              <button className="btn btn-primary" disabled={isIndexPageSearchDisabled(currentSearch, filterSegment, startDate, endDate)} onClick={ e => {
                if(!isIndexPageSearchDisabled(currentSearch, filterSegment, startDate, endDate)){
                  refreshOrders(axios, 1, currentSearch);
                }    
              }} ><SearchIcon/> Search</button>           
            </div>
          </div>

            {/* { pageState !== 'ready' ? (
                <div style={{ width: "100%" }} className="text-center my-5">
                  <CircularProgress />
                </div>
              ):(
            <> */}

              <DataGrid
                {...orders} 
                page={currentPage}
                rows={orders.slice(0, pageSize)}
                // paginationModel={{
                //   pageSize: 10,
                //   page: 0,
                // }}
                rowCount={rowCount}
                paginationMode="server"
                disableColumnFilter
                onPageChange={(newPage) => updatePage(newPage)}
                columns={columns}
                pageSize={pageSize}
                autoHeight 
                disableSelectionOnClick
                components={{
                  Toolbar: CustomToolbar,
                }}
                loading={pageState === "loading"}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [
                        {
                          columnField: 'ID',
                          operatorValue: 'contains',
                          value: '',
                        },
                      ],
                    },
                  },
                }}
              />  
            {/* </>
          )} */}
        </CardContent>
      </Card>
    </>
  );
}

const {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
} = GridComponents

export default Order;
import _ from 'lodash'
import { useContext } from "react"
// import { useSelector } from "react-redux";
import axios from "axios";

import { AppContext } from "../contexts"
import * as formatters from "./formatters.js"
import {calculateTotalTradeCreditTotal, calculateTotalProductSubtotal, calculateOrderSubtotal, calculateTotalDownpayment, calculateGrandSubtotal, calculateVehicleSalesTax, calculateNonVehicleSalesTaxTotal} from "./orderCalculations";

const rootapi = (process?.env?.REACT_APP_API_URL ?? null)

//Axios client to send all requests...
let client = null

//Called by App.js to set the client instance with the given tokens...
export const createAxiosInstance = ( idToken, accessToken ) => {

  let bearerToken;
  if ( _.isEmpty( idToken ) === false ) {
    bearerToken = 'Bearer '+ idToken;
  } else{
    bearerToken = 'Bearer ';
  }

  //Set the Axios instance...
  const instance = axios.create({
    baseURL: rootapi,
    headers: {
      'Authorization': bearerToken,
      'accessToken': _.isEmpty( accessToken ) ? null : accessToken
    }
  })

  //Add responseType: 'blob' for pdfs...
  instance.postPdf = (url, data) => {
    return instance.post(url, data, {
      responseType: 'blob'
    })
  }

  //Set the global client reference...
  client = instance

  return instance
}

//TODO: Add global failure handling

/* ME (User) */
export const getMe = async () => {

  return await client.get(`/me`)
}

export const getUserById = async (userId) => {
  return await client.get(`/user/${userId}`)
}

export const getMyDefaultRegionCode = async () => {

  return client.get(`/my-default-region-code`)
}

export const getMyDefaultLocationCode = async () => {

  return client.get(`/my-default-location-code`)
}

/* ORDER */
export const getOrderStatus = () => {

  //TODO: How do we get the order status without the orderId
  return client.get(`/orderStatus`).then(res => res.data)
}

export const getProduct = async (orderId, productId) => {
  try {
    return client.get(`/order/${orderId}/product/${productId}`)
  } catch (err){
    recordFrontEndError('api.js', 'Error getting product: ' + err)
  }
} 

export const deleteProduct = async (orderId, productId) => {
  try {
    return client.delete(`/order/${orderId}/product/${productId}`)
  } catch (err){
    recordFrontEndError('api.js', 'Error deleting product: ' + err)
  }
} 

export const addFob = async (orderId, productId, fobAddressObj) => {
  try {
    await client.post(`/order/${orderId}/product/${productId}/fob-address`, fobAddressObj)
  } catch (err){
    recordFrontEndError('api.js', 'Error adding fob: ' + err)
  }
}

export const updateFob = async (orderId, productId, fobAddressObj) => {
  try {
    await client.put(`/order/${orderId}/product/${productId}/fob-address`, fobAddressObj)
  } catch (err){
    recordFrontEndError('api.js', 'Error updating fob: ' + err)
  }
} 

export const addProductRegistration = async (orderId, productId, fobAddressObj) => {
  try {
    await client.post(`/order/${orderId}/product/${productId}/registration-address`, fobAddressObj)
  } catch (err){
    recordFrontEndError('api.js', 'Error adding registration: ' + err)
  }
}

export const updateProductRegistration = async (orderId, productId, fobAddressObj) => {
  try {
    await client.put(`/order/${orderId}/product/${productId}/registration-address`, fobAddressObj)
  } catch (err){
    recordFrontEndError('api.js', 'Error updating registration address: ' + err)
  }
} 

export const updateShipTo = async (orderId, productId, shipToObj) => {
  try {
    await client.put(`/order/${orderId}/product/${productId}/ship-to`, shipToObj)
  } catch (err){
    recordFrontEndError('api.js', 'Error updating ship-to: ' + err)
  }
} 

export const deleteShipTo = async (orderId, productId) => {
  try {
    await client.delete(`/order/${orderId}/product/${productId}/ship-to`)
  } catch (err){
    recordFrontEndError('api.js', 'Error deleting ship-to: ' + err)
  }
} 

export const addShipTo = async (orderId, productId, shipToObj) => {
  try {
    await client.post(`/order/${orderId}/product/${productId}/ship-to`, shipToObj)
  } catch (err){
    recordFrontEndError('api.js', 'Error adding ship-to: ' + err)
  }
} 

export const updateSoldTo = async (orderId, productId, soldToObj) => {
  try {
    await client.put(`/order/${orderId}/product/${productId}/sold-to`, soldToObj)
  } catch (err){
    recordFrontEndError('api.js', 'Error updating sold-to: ' + err)
  }
} 

export const deleteSoldTo = async (orderId, productId) => {
  try {
    await client.delete(`/order/${orderId}/product/${productId}/sold-to`)
  } catch (err){
    recordFrontEndError('api.js', 'Error deleting sold-to: ' + err)
  }
} 

export const addSoldTo = async (orderId, productId, soldToObj) => {
  try {
    await client.post(`/order/${orderId}/product/${productId}/sold-to`, soldToObj)
  } catch (err){
    recordFrontEndError('api.js', 'Error adding sold-to: ' + err)
  }
} 

export const addProductAddition = async (orderId, productId, additionObject) => {
  try {
    await client.post(`/order/${orderId}/product/${productId}/addition`, additionObject)
  } catch (err){
    recordFrontEndError('api.js', 'Error adding product addition: ' + err)
  }
}
export const updateProductAddition = async (orderId, productId, additionId, additionObject) => {
  try{
    await client.put(`/order/${orderId}/product/${productId}/addition/${additionId}`, additionObject)
  } catch (err) {
    recordFrontEndError('api.js', 'Error updating product additions: ' + err)
  }
}
export const deleteProductAddition = async (orderId, productId, additionId) => {
  try{
    await client.delete(`/order/${orderId}/product/${productId}/addition/${additionId}`)
  } catch (err) {
    recordFrontEndError('api.js', 'Error deleting product addition: ' + err )
  }
}
export const addProductUpgrade = async (orderId, productId, upgradeObject) => {
  try{
    await client.post(`/order/${orderId}/product/${productId}/upgrade`, upgradeObject)
  } catch (err) {
    recordFrontEndError('api.js', 'Error adding product upgrade: ' + err )
  }
}
export const updateProductUpgrade = async (orderId, productId, upgradeId, upgradeObject) => {
  try{
    await client.put(`/order/${orderId}/product/${productId}/upgrade/${upgradeId}`, upgradeObject)
  } catch (err) {
    recordFrontEndError('api.js', 'Error updating product upgrades: ' + err)
  }
}
export const deleteProductUpgrade = async (orderId, productId, upgradeId) => {
  try{
    await client.delete(`/order/${orderId}/product/${productId}/upgrade/${upgradeId}`)
  } catch (err) {
    recordFrontEndError('api.js', 'Error deleting product upgrade: ' + err )
  }
}
export const addProductDeposit = async (orderId, productId, depositObject) => {
  try{
    await client.post(`/order/${orderId}/product/${productId}/deposit`, depositObject)
  } catch (err) {
    recordFrontEndError('api.js', 'Error adding product deposit: ' + err)
  }
}
export const updateProductDeposit = async (orderId, productId, depositId, depositObject) => {
  try{
    await client.put(`/order/${orderId}/product/${productId}/deposit/${depositId}`, depositObject)
  } catch (err) {
    recordFrontEndError('api.js', 'Error updating product deposit: ' + err)
  }
}
export const deleteProductDeposit = async (orderId, productId, depositIdNum) => {
  try{
    await client.delete(`/order/${orderId}/product/${productId}/deposit/${depositIdNum}`)
  } catch (err) {
    recordFrontEndError('api.js', 'Error deleting product deposit: ' + err)
  }
    
}
export const updateExtendedWarranty = async (extWarrantyObj, id=null) => {
    if(id===null) id= extWarrantyObj.id
  try{
    await client
    .put(`/extendedWarranty/${id}`, extWarrantyObj)
  } catch (err) {
    recordFrontEndError('api.js', 'Error updating extended warranty: ' + err)
  }
}
 export const addExtendedWarranty = async (extWarrantyObj) => {
  try{
    await client.post(`/extendedWarranty`, extWarrantyObj)
  } catch (err) {
    recordFrontEndError('api.js', 'Error adding extended warranty: ' + err)
  }
  } 
 export const deleteExtendedWarranty = async (warranty_id) => {
  try{
    await client.delete(`/ExtendedWarranty/${warranty_id}`)
  } catch (err) {
    recordFrontEndError('api.js', 'Error deleting extended warranty: ' + err)
  }
  }
  export const editGapInsurance = async (gapInsuranceObj) => {
    try{
      await client.put(`/gapInsurance/${gapInsuranceObj.id}`, gapInsuranceObj)
    } catch (err) {
      recordFrontEndError('api.js', 'Error editing gap insurance: ' + err)
    }
  }
  export const addGapInsurance = async (gapInsuranceObj) => {
    try{
      await client.post(`/gapInsurance`, gapInsuranceObj)
    } catch (err) {
      recordFrontEndError('api.js', 'Error deleting gap insurance: ' + err)
    }
  }
  export const deleteGapInsurance = async (gap_id) => {
    try{
      await client.delete(`/GapInsurance/${gap_id}`)
    } catch (err) {
      recordFrontEndError('api.js', 'Error deleting GAP insurance: ' + err)
    }
    }
  export const addLineItemFee = async (newLineItemFeeObj) => {
    try {
      return await client.post(`/lineItemFee`, newLineItemFeeObj)
    }catch (err) {
      recordFrontEndError('api.js', 'Error adding tax/fee: ' + err)
    }
    }
  export const editLineItemFee = async (lineItemFeeId, newObj) => {
    try {
      return await client.put(`/lineItemFee/${lineItemFeeId}`, newObj)
    }catch (err) {
      recordFrontEndError('api.js', 'Error editing tax/fees: ' + err )
    }
    }
  export const getProductFundingDetails = async (id,product_id) => {
    try {
      return await client.get(`/order/${id}/product/${product_id}`)
    }catch (err) {
      recordFrontEndError('api.js', 'Error adding product funding detail: ' + err )
    }
    }
  export const addFundingDetail = async (id,product_id, fundingDetailObj) => {
    try {
      await client.put(`/order/${id}/product/${product_id}/funding-details`, fundingDetailObj)
    }catch (err) {
      recordFrontEndError('api.js', 'Error adding funding detail: ' + err )
    }
    }
  export const updateFundingDetails = async (id,product_id, fundingDetailObj) => {
    try {
      await client.put(`/order/${id}/product/${product_id}/funding-details`,  fundingDetailObj)
    }catch (err) {
      recordFrontEndError('api.js', 'Error updating funding detail: ' + err )
    }
    }
  export const addProductLienHolder = async (id, product_id, lienHolderObj) => {
    try {
      await client.put(`/order/${id}/product/${product_id}/lien-holder`, lienHolderObj)
    }catch (err) {
      recordFrontEndError('api.js', 'Error adding product lien holder: ' + err )
    }
  }
  export const finalizeFunding = async (id,product_id) => {
    try {
      await client.post(`/order/${id}/product/${product_id}/finalize-funding`)
    }catch (err) {
      recordFrontEndError('api.js', 'Error finalizing funding: ' + err )
    }
    }
  export const unFinalizeFunding = async (id,product_id) => {
    try {
      await client.post(`/order/${id}/product/${product_id}/unfinalize-funding`)
    }catch (err) {
      recordFrontEndError('api.js', 'Error un-finalizing finding: ' + err )
    }
    }
  export const getProductLenderOptions = async (id, product_id) => {
    try {
      return client.get(`/order/${id}/product/${product_id}/lending-option`)
    }catch (err) {
      recordFrontEndError('api.js', 'Error getting product lender options: ' + err )
    }
    }
  export const getLenderTerms = async (id, product_id,lender_option_id) => {
    try {
      return client.get(`/order/${id}/product/${product_id}/lending-option/${lender_option_id}`)
    }catch (err) {
      recordFrontEndError('api.js', 'Error getting lender terms: ' + err )
    }
    }
  export const finalizeLenderTerms = async (id, product_id,lender_option_id) => {
    try {
      await client.post(`/order/${id}/product/${product_id}/lending-option/${lender_option_id}/finalize`)
    }catch (err) {
      recordFrontEndError('api.js', 'Error finalizing lender terms: ' + err )
    }
    }
  export const unFinalizeLenderTerms = async (id, product_id,lender_option_id) => {
    try {
      await client.post(`/order/${id}/product/${product_id}/lending-option/${lender_option_id}/unfinalize`)
    }catch (err) {
      recordFrontEndError('api.js', 'Error unfinalizing lender terms: ' + err )
    }
    }
  export const updateLenderTerms = async (id, product_id,lender_option_id, lenderTermsObject) => {
    try {
      await client.put(`/order/${id}/product/${product_id}/lending-option/${lender_option_id}/terms`, lenderTermsObject)
    }catch (err) {
      recordFrontEndError('api.js', 'Error updating lender terms: ' + err )
    }
    }
  export const copyProductFinancing = async (orderId, product_id, selectedProductFunding) => {
    try {
      await client.put(`order/${orderId}/product/${product_id}/copy-financing`,{copy_product_id: selectedProductFunding})
    }catch (err) {
      recordFrontEndError('api.js', 'Error copying product financing: ' + err )
    }
    }
  export const getInventorySelection = async (inputVal) => {
    try {
      return await client.get(`/chad-inventory?stockno=${inputVal}`)
    }catch (err) {
      recordFrontEndError('api.js', 'Error getting filtered inventory: ' + err )
    }
    }
  export const duplicateProduct = (id, product_id, selectedStockNo, selectedVin) => {
    try {
      client.post(`/order/${id}/product/${product_id}/duplicate`, {stockNumber: selectedStockNo, vin: selectedVin})
    }catch (err) {
      recordFrontEndError('api.js', 'Error duplicating product: ' + err )
    }
    }
  export const getLenders = async () => {
    try {
      return await client.get(`/lendingsource`)
    }catch (err) {
      recordFrontEndError('api.js', 'Error getting lending source: ' + err )
    }
  }
  export const getTopLenders = async () => {
    try {
      return await client.get(`/top-lending-sources`)
    }catch (err) {
      recordFrontEndError('api.js', 'Error getting lending source: ' + err )
    }
  }
  export const getProductLenderOption = async (id, product_id) => {
    try {
      return await client.get(`/order/${id}/product/${product_id}/lending-option`)
    }catch (err) {
      recordFrontEndError('api.js', 'Error getting product lenders: ' + err )
    }
    }
  export const addLender = async (id, product_id, lender_id) => {
    try {
      await  client.post(`/order/${id}/product/${product_id}/lending-option-by-source/${lender_id}`)
    }catch (err) {
      recordFrontEndError('api.js', 'Error adding product lender: ' + err )
    }
    }
  export const addDocument = async (documentDraftData) => {
    try {
      await client.post(`/document`, documentDraftData)
    }catch (err) {
      recordFrontEndError('api.js', 'Error adding document: ' + err )
    }
    }
  export const getDocumentTemplates = async () => {
    try {
      return await client.get(`/document-template`)
    }catch (err) {
      recordFrontEndError('api.js', 'Error getting documents: ' + err )
    }
    }
  export const deleteDocument = async (documentId) => {
    try {
      await client.delete(`/document/${documentId}`)
    }catch (err) {
      recordFrontEndError('api.js', 'Error deleting documents: ' + err )
    }
    }
 
    export const getTecLocation = async () => {
    try {
      return await client.get(`/tec-location`)
    }catch (err) {
      recordFrontEndError('api.js', 'Error getting tec-locations: ' + err )
    }
    }
    export const getTecRegion = async (locationCode) => {
    try {
      return await client.get(`/tec-region-by-location-code/${locationCode}`)
    }catch (err) {
      recordFrontEndError('api.js', 'Error getting TEC region: ' + err )
    }
    }
    export const getDocuments = async (orderId) => {
    try {
      return await client.get(`/order/${orderId}/document`)
    }catch (err) {
      recordFrontEndError('api.js', 'Error getting order documents: ' + err )
    }
    }
    export const getDocumentsForExport = async (orderId) => {
      try {
        return await client.get(`/order/${orderId}/document-export`)
      }catch (err) {
        recordFrontEndError('api.js', 'Error getting order documents: ' + err )
      }
      }
    export const updateOrderStatus = async (orderId, newStatusSlug) => {
    try {
      await client.put(`/order/${orderId}/status`, {orderStatusSlug: newStatusSlug})
    }catch (err) {
      recordFrontEndError('api.js', "Error updating order status"+ err)
    }
    }
    export const loadOrder = async (orderId, token) => {
    try {
      return await client.get(`/order/${orderId}`, token)
    }catch (err) {
      recordFrontEndError('api.js', "Error loading order"+ err)
    }
    }
    export const getMyCheckedOut = async () => {
    try {
      return await client.get('/my-checked-out-orders')
    }catch (err) {
      recordFrontEndError('api.js', "Error loading order"+ err)
    }
    }
    export const getAllCheckedOut = async () => {
    try {
      return await client.get('/all-checked-out-orders')
    }catch (err) {
      recordFrontEndError('api.js', "Error loading order"+ err)
    }
    }
    export const unCheckedOutOrder = async (orderId) => {
    try {
      return await client.get(`order/${orderId}/uncheckout`)
    }catch (err) {
      recordFrontEndError('api.js', "Error loading order"+ err)
    }
    }

export const updateOrderValues = (id, order) => {
  if(order && id){
    let finalValues = {
      ProductSubtotal: Number(calculateTotalProductSubtotal(order, order.products)).toFixed(2),

      TradeInCredit:  Number(calculateTotalTradeCreditTotal(order.tradeins, order) < 0 ? Math.abs(calculateTotalTradeCreditTotal(order.tradeins, order)) : (calculateTotalTradeCreditTotal(order.tradeins, order))).toFixed(2),

      VehicleSalesTaxTotal: Number(calculateVehicleSalesTax(order.products, order.tradeins, order)).toFixed(2),

      NonVehicleSalesTaxTotal: Number(calculateNonVehicleSalesTaxTotal(order.products, order)).toFixed(2),

      OrderSalesTaxTotal: Number(Number(calculateVehicleSalesTax(order.products, order.tradeins, order))+Number(calculateNonVehicleSalesTaxTotal(order.products, order))).toFixed(2),

      Subtotal: Number(calculateOrderSubtotal(order, order.products, order.tradeins, order.adminFee)).toFixed(2),
      
      TotalDownPayment: Number(calculateTotalDownpayment(order.downPayments)).toFixed(2),

      TotalAmountFinanced: Number(calculateGrandSubtotal(order)).toFixed(2),

      BalanceDue: 0.00,
    }

    return client.put(`/order/${id}/update-final-values`, finalValues)
  }
}

export const getSalesManagers = () => {
  return client.get(`/sales-managers`)
}

export const getSalesReps = () => {
  return client.get('/sales-reps').then(res => res.data)
}

export const getOrder = orderId => {
  const url = `/order/${ orderId }`

  return client.get(url).then(res => res?.data).catch(err => {
    // recordFrontEndError('api.js', 'Error on getOrder() API call. '+err);
  })
}

export const getOrderForExport = async (orderId) => {
  const url = `/order/${orderId}/export`

  const response = client.get(url).then(res => res.data)
  return response
}

export const addImportedOrder = async (order) => {
  const url = '/import-order'
  
  const response = await client.post(url, JSON.parse(order)).catch(err => null);
  return response.data
} 

export const updateOrderDetails = (orderId, orderDetailsObj ) => {
  const url = `/order/${ orderId }/details`

  return client.put(url, orderDetailsObj)
}

/* LOCATION */
export const getLocations = () => {

  return client.get('location').then( res => {

    //Get a list of location names from the response...
    const locationNames =
      _.defaultTo(res?.data, [])
      .sort((a, b) => a.name.localeCompare(b.name))

    return locationNames
  })
}

/* LOCATION */
export const getMyLocations = () => {

  return client.get('my-tec-locations').then( res => {

    //Get a list of location names from the response...
    const locationNames =
      _.defaultTo(res?.data, [])
      .sort((a, b) => a.description.localeCompare(b.description))

    return locationNames
  })
}

/* CUSTOMER */
export const updateExistingCustomer = (orderId, customerId, customerObj) => {
  const url = `/order/${ orderId }/customer/${customerId }`

  return client.put(url, customerObj).catch(err => { })
}

/* CONTACTS */
export const deleteCustomerContact = async (orderId, customerId, contactId) => {
  const url = `/order/${orderId}/customer/${customerId}/add-contact/${contactId}`

  return client.delete(url).catch(err => { })
}

export const saveCustomerContact = async (orderId, customerId, newContactObj) => {

  //TODO: Comment
  const formattedContactObj =
    formatters.formatContactData(newContactObj);
  const url = `/order/${orderId}/customer/${customerId}/add-contact`

  return client.post(url, formattedContactObj).catch(err => null)
}

export const updateCustomerContact = async (orderId, customerId, contactId, contactObj) => {
  const newContactObj = formatters.formatContactData(contactObj);
  const url           = `/order/${orderId}/customer/${customerId}/add-contact/${ contactId }`

  return client.put(url, newContactObj).catch(err => null)
}

/* COSIGNERS */
export const saveNewCustomerCosigner =  (orderId, customerId, cosigner) => {
  const url = `/order/${orderId}/customer/${customerId}/co-signer`

   return client.post(url, cosigner)
}

export const updateCustomerCosigner =  (orderId, customerId, cosignerId, cosignerObj) => {


  let formattedCosignerObj = formatters.formatCosignerData(cosignerObj);
  const url                = `/order/${orderId}/customer/${customerId}/co-signer/${cosignerId}`

  return client.put(url, formattedCosignerObj)
}

export const getCosignersAndContacts = async ( orderId, customerId ) => {
  const url = `/order/${orderId}/customer/${ customerId }`
  //res.data will include cosisgners and contacts...
  return client.get(url).then(res => res.data)
}

export const deleteCustomerCosigner = async (orderId, customerId, cosignerId) => {
  const url = `/order/${orderId}/customer/${customerId}/co-signer/${cosignerId}`

  return client.delete(url).catch(err => { })
}


export const getCustomerContacts = async ( orderId, customerId ) => {
  const url = `/order/${ orderId }/customer/${ customerId }`

  return client.get(url).then(res => res.data.contacts )
}

export const addCustomer = async (orderId, customer) => {
  return client.post(`/order/${orderId}/customer`, customer)
}

export const saveCustomer = async ( orderId, customerObj ) => {
  return client.post(`/order/${ orderId }/customer`, customerObj)
}

/* DOWNPAYMENTS */
export const updateDownpayment = (orderId, downpaymentId, downpaymentObj) => {
  return (
    client
    .put(`/order/${ orderId }/downpayment/${ downpaymentObj.id }`, downpaymentObj)
  )
}

export const saveNewDownpayment = ( orderId, downpaymentObj ) => {
  return (
    client
    .post(`/order/${ orderId }/downpayment`, downpaymentObj)
  )
}

export const deleteDownpayment = (orderId, downpaymentId) => {

  const url = `/order/${orderId}/downpayment/${downpaymentId}`

  return client.delete(url)
}

/* FUNDING PAYMENTS */
export const updateFundingPayment = (orderId, fundingPaymentId, fundingPaymentObj) => {
  return (
    client
    .put(`/order/${ orderId }/funding-payment/${ fundingPaymentObj.id }`, fundingPaymentObj)
  )
}

export const saveNewFundingPayment = ( orderId, fundingPaymentObj ) => {
  return (
    client
    .post(`/order/${ orderId }/funding-payment`, fundingPaymentObj)
  )
}

export const deleteFundingPayment = (orderId, fundingPaymentId) => {

  const url = `/order/${orderId}/funding-payment/${fundingPaymentId}`

  return client.delete(url)
}

//Simply gets the axios instance from the AppContext
//and abstracts the need to import useContext, as
//well as the AppContext.
//TODO: Deprecate, put all API calls inside this file.
const useFetch = () => {

  const { axios } = useContext(AppContext)

  return axios
}

export const saveTradein = (orderId, tradeinData) => {
  const url = `/order/${ orderId }/tradein`
  return client.post(url, tradeinData)
}

export const updateTradein = (orderId, tradeinId, tradeinData) => {
  const url = `/order/${ orderId }/tradein/${ tradeinId }`
  return client.put(url, tradeinData)
}

export const getProductLenders = () => {
  return (
    client.get(`/lendingsource`)
    .then(res => res.data)
    .catch(err => [])
  )
}

export const recordFrontEndError = (component, errorMessage) => {
  var newURL = window.location.protocol + "//" + window.location.host + "/" + window.location.pathname + window.location.search;

  var payload = {
    path: newURL,
    component: component,
    errorMessage: errorMessage
  }

  return (
    client.post(`/front-end-error`, payload)
    .then(res => res.data)
    .catch(err => [])
  )
}

export const updateFinanceDashboardKpis = (period="month", startDate=null, endDate=null) => {

  let paramString = "";

  if (period === 'custom') {
    paramString += `?start_date=${startDate}&end_date=${endDate}`
  }

  if (period !== 'custom'){ 
    paramString +=`?time_period=${period}`
  }

  return client.get(`/order/finance-dashboard-kpis`+paramString)
}

export const updateFinanceDashboardFinanceRequests = (period="month", regionCode="", locationCode="", overrideRole=false, role="", page=1, filter=null, startDate=null, endDate=null, filterSegment=null) => {
  var connstring = `/order/finance-dashboard-finance-requests?`;


  if(!period || period === ""){
    period = "month";
  }
  // connstring += `&time_period=${period}`;

  
  if (period === 'custom') {
    connstring += `start_date=${startDate}&end_date=${endDate}`
  }

  if (period !== 'custom'){ 
    connstring +=`time_period=${period}`
  }

  if ( regionCode && regionCode !== "" ) { //TODO: Add comment
    connstring += `&region_code=${regionCode}`;
  }

  if ( locationCode && locationCode !== "" ) { //TODO: Add comment
    connstring += `&location_code=${locationCode}`;
  }

  if(overrideRole && role !== ""){
    connstring += `&sim=${role}`;
  }

  if(filterSegment && filterSegment !== "none"){
    connstring += `&filterSegment=`+filterSegment.trim();
  }

  if(filter && filter !== ""){
    connstring += `&filter=${filter}`;
  }

  return client.get(connstring+`&pageNumber=`+page+`&pageSize=5`);
}

export const saveUserAccessLocationGrant = (payload) => {
  return client.post(`/LocationAccessGrant`, payload)
}

export const updateUserAccessLocationGrant = (payload) => {
  return client.put(`/LocationAccessGrant/${payload.id}`, payload)
}

export const deleteUserAccessLocationGrant = (id) => {
  return client.delete(`/LocationAccessGrant/${id}`)
}

export const createProductGroup = (id, payload) => {
  return client.post(`/order/${id}/create-product-group`, payload)
}

export const updateProductGroup = (id, payload) => {
  return client.put(`/order/${id}/update-product-group`, payload)
}

export const clearProductGroups = (id) => {
  return client.post(`/order/${id}/clear-product-groups`)
}

export const clearExtraProductGroups = (id) => {
  return client.post(`/order/${id}/clear-extra-product-groups`)
}

export const getServerInformation = () => {
  return client.get(`/server`)
}

export const saveProductDetails = (orderId, productId, payload, disableIntercepterRefresh=false) => {
  let path =`/order/${orderId}/product/${productId}`;
  if(disableIntercepterRefresh === true){
    path += '?nir=true'
  }
  return client.put(path, payload)
}

export const saveNewProduct = (orderId, payload) => {
  return client.post(`/order/${orderId}/product`, payload)
}

export const prepareDocument = (id, payload) => {
  return client.put(`/document/${id}/prepare`, payload);
}

export const generateDocument = (id) => {
  return client.post(`/document/${id}/generate`)
}
export const getCustomerLookup = async (inputVal) => {
  try {
    return await client.get(`/customer-lookup?name=${inputVal}`)
  }catch (err) {
    recordFrontEndError('api.js', 'Error getting filtered inventory: ' + err )
  }
}

export { rootapi, useFetch }

import { SET_STATS, SET_FINANCE_REQUESTS, SET_PERIOD, SET_REGION_CODE, SET_LOCATION_CODE, SET_FINANCE_REQUESTS_TOTAL_PAGES, SET_START_DATE, SET_END_DATE, SET_SEARCH_FILTER, SET_SEARCH_FILTER_SEGMENT, SET_SEARCH_STATE } from "./financeDashboardTypes"


const intialState = {
    stats: {
        "bookedDeals": {
            "ordersCount": 0,
            "itemsCount": 0,
            "tradeInsCount": 0,
            "totalFinanced": ""
        },
        "currentOrders": {
            "quotes": 0,
            "managerReview": 0,
            "financingReview": 0,
            "creditApproval": 0,
            "documentRequests": 0,
            "booked": 0,
            "funded": 0
        },
        "topLenders": [],
    },
    financeRequests: [],
    financeRequeststotalPages: 0,
    period: "month",
    startDate: null,
    endDate: null,
    regionCode: null,
    locationCode: null,
    filter: null,
    filterSegment: "none",
    searchState: "uninitalized"
}


export const financeDashboardReducer = (store = intialState , action) => {
     switch (action.type) {
         case SET_STATS:
             return {
                ...store,
                 stats: action.payload
             }
        case SET_FINANCE_REQUESTS:
            return {...store, financeRequests: action.payload}
        case SET_FINANCE_REQUESTS_TOTAL_PAGES:
            return {
                ...store,
                financeRequeststotalPages: action.payload
            }
        case SET_PERIOD:
            return {
                ...store,
                period: action.payload
            }
        case SET_START_DATE:
            return {
                ...store,
                startDate: action.payload
            }
        case SET_END_DATE:
            return {
                ...store,
                endDate: action.payload
            }
        case SET_SEARCH_FILTER:
            return {
                ...store,
                filter: action.payload
            }
        case SET_SEARCH_FILTER_SEGMENT:
            return {
                ...store,
                filterSegment: action.payload
            }
        case SET_REGION_CODE:
            return {
                ...store,
                regionCode: action.payload
            }
        case SET_LOCATION_CODE:
            return {
                ...store,
                locationCode: action.payload
            }
        case SET_SEARCH_STATE:
            return {
                ...store,
                searchState: action.payload
            }
         default:
           return store
     }
}
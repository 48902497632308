import React, { useState, useEffect } from "react";
// React Router components:
import { Link as RouterLink } from "react-router-dom";
// Fetch utilities:
import { useFetch, recordFrontEndError } from "../../../utlis/api";
// MaterialUI components:
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Tooltip from "@mui/material/Tooltip";
// MaterialUI Icons:
import NotificationsIcon from "@mui/icons-material/Notifications";
// Moment functions:
// import moment from "moment";

const NotificationBell = ({
  iconColor = "error", 
  userIndex = 0, 
}) => {
  // Declare a component state variable to hold a ref value.
  const axios = useFetch()
  const [anchorEl, setAnchorEl] = useState(null);
  // Declare open/close handler functions. 
  const open = Boolean(anchorEl);
  const handleOpen = (event) => {
    getNotifications();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);
  // Declare your 'id' for when the menu is open.
  const id = open ? "navbar-notifications-open" : undefined;
  // Declare a component state variable to hold a fetched list of notifications.
  const [notifications, setNotifications] = useState([]);
  // Declare a component state variable to hold a filtered list of new notifications.
  const [newNotifications, setNewNotifications] = useState(( notifications ? notifications.filter(id => id.status !== "read"):[]));
  // Declare a component state variable for the number of new/unread notifications.
  const [newNotificationsCount, setNewNotificationsCount] = useState(newNotifications.length);
  // Declare dynamic values for conditional rendering of 'newNotificationsCount'.
  const newNotificationsTooltip = `You have ${newNotificationsCount} new notifications!`;
  const noNotificationsTooltip = "No new notifications";
  // Declare a function to mark notifications as read and handle them.
  const handleMarkAsRead = (id) => {
    axios
    .put(`/user/${userIndex}/alert/${id}/read`)
    .then(() => {
      getNotifications();
      return
    })
    .catch((err) => {
      recordFrontEndError('NotificationBell.jsx', 'Error on axios put to "markAsRead": '+err)

      return false;
    })
  }
  // Declare your fetch here, it will be invoked inside of a useEffect and handleOpen.
  const getNotifications = () => {
    axios
      .get(`/user/${userIndex}/alert`)
      .then((res) => {
        setNotifications(res?.data.data);
        return res?.data;
      })
      .catch((err) => {
        recordFrontEndError('NotificationBell.jsx', 'Error on axios get to get notifications: '+err)
        return false;
      })
  };
  // This updates the newNotifications whenever there is a change to notifications
  useEffect(() => {
    if(notifications && notifications.length > 0){
      setNewNotifications(notifications.filter(id => id.status !== "read"));
    }
  },[ notifications ])

  // This updates the newNotificationsCount whenever there is a change to newNotifications
  useEffect(() => {
    setNewNotificationsCount(newNotifications.length);
  },[ newNotifications ])

  // This is currently set to do a fetch onPageLoad and a periodic fetch every 60 seconds.
  // useEffect(() => {
  //   // Having a getNotifications() here will make it fetch on load.
  //   getNotifications();
  //   // Having a setInterval() with another getNotifications() here will make it fetch periodically.
  //   // const interval = setInterval(() => getNotifications(), 60000);

  //   return () => clearInterval(interval);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[]); // Leave this dependency array empty.

  return (
    <Box id={"navbar-notifications-wrapper"}>
      <Tooltip
        id={"navbar-notifications-tooltip"}
        title={newNotificationsCount ? newNotificationsTooltip : noNotificationsTooltip}
      >
        <span> 
          <IconButton
          id={"navbar-notifications-icon-button"}
          onClick={handleOpen}
          disabled={true}
        >
          <Badge
            id={"navbar-notifications-badge"}
            badgeContent={newNotificationsCount}
            color="error"
          >
            <NotificationsIcon id={"navbar-notifications-icon"}/>
          </Badge>
        </IconButton>
        </span>
        
      </Tooltip>
      <Menu
        id={`${id}-menu`}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: -260,
        }}
        // vvv^^^ anchorOriginis offset by 0.5 * (widthOfMenu(300) - widthOfIcon(40)).
        MenuListProps={{ 
          sx: {
            width: 300,
          },
        }}
      >
        <MenuList
          id={`${id}-menu-list`}
          sx={{ width: '100%', maxWidth: 300, bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby={`${id}-menu-list-subheader`}
          subheader={
            <ListSubheader
              id={`${id}-menu-list-subheader`}
              component="div"
            >
              <ListItemText
                id={`${id}-menu-list-subheader-text`}
                primary="Notifications"
                primaryTypographyProps={{ variant: "h6" }}
              />
            </ListSubheader>
          }
        >
          <Divider id={`${id}-menu-list-divider-0`} />
          <ListItem
            id={`${id}-menu-item-0`}
            dense
          >
            <ListItemText
              id={`${id}-menu-item-0-text`}
              primary="Unread Alerts"
              primaryTypographyProps={{ variant: "button" }}
            />
          </ListItem>
          <Divider id={`${id}-menu-list-divider-1`} />
          {newNotifications.map((item, index) => (
            <MenuItem
              key={`${index}`}
              id={`${id}-menu-item-1-new-alert-${index}`}
              sx={{
                fontWeight: "bold",
                color: "error",
              }}
              onClick={()=>handleMarkAsRead(item.id)}
            >
              {item.message}
            </MenuItem>
          ))}
          <ListItem 
            id={`${id}-menu-item-1-no-new-alerts`}
            hidden={newNotificationsCount !== 0}
          >
            <ListItemText
              id={`${id}-menu-list-item-1-no-new-alerts-text`}
              primary="All Caught Up!"
              primaryTypographyProps={{ variant: "string" }}
              hidden={newNotificationsCount !== 0}
            />
          </ListItem>
          <Divider id={`${id}-menu-list-divider-2`}  />
          <ListItem
            id={`${id}-menu-item-2`}
            dense
          >
            <ListItemText
              id={`${id}-menu-item-2-text`}
              primary="Previous Alerts"
              primaryTypographyProps={{ variant: "button" }}
            />
          </ListItem>
          <Divider id={`${id}-menu-list-divider-3`} />
          {notifications ? notifications.filter(item => item.status === "read" ).map((item, index) => (
            <MenuItem
              key={`${index}`}
              id={`${id}-menu-item-3-previous-alert-${index}`}
              onClick={handleClose}
            >
              {item.message}
            </MenuItem>
          )):null}
          <ListItem
            id={`${id}-menu-item-3-empty-inbox`}
            hidden={newNotificationsCount !== 0}
          >
            <ListItemText
              id={`${id}-menu-item-3-empty-inbox-text`}
              hidden={newNotificationsCount !== 0}
              primary="No unread notifications"
              primaryTypographyProps={{ variant: "string" }}
            />
          </ListItem>
          <ListItem
            id={`${id}-menu-item-3-no-older-alerts`}
            hidden={newNotificationsCount === 0}
          >
            <ListItemText
              id={`${id}-menu-list-item-3-no-older-alerts-text`}
              hidden={newNotificationsCount === 0}
              primary="No older notifications"
              primaryTypographyProps={{ variant: "string" }}
            />
          </ListItem>
          <Divider id={`${id}-menu-list-divider-4`} />
          <MenuItem
            id={`${id}-menu-item-4-see-all-button`}
            component={RouterLink}
            to="/me/notifications"
            // dense
            sx={{ textAlign: "center" }}
            onClick={handleClose}
          >
            <ListItemText
              id={`${id}-menu-item-4-see-all-button-text`}
              primary="See All Notifications"
              primaryTypographyProps={{ variant: "button" }}
            />
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  )
};

export default NotificationBell;

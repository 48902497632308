export const SET_STATS = "SET_STATS";
export const SET_FINANCE_REQUESTS = "SET_FINANCE_REQUESTS";
export const SET_FINANCE_REQUESTS_TOTAL_PAGES = "SET_FINANCE_REQUESTS_TOTAL_PAGES";
export const SET_PERIOD = "SET_PERIOD";
export const SET_REGION_CODE = "SET_REGION_CODE";
export const SET_LOCATION_CODE = "SET_LOCATION_CODE";
export const SET_START_DATE = "SET_START_DATE";
export const SET_END_DATE = "SET_END_DATE";
export const SET_SEARCH_FILTER = "SET_SEARCH_FILTER";
export const SET_SEARCH_FILTER_SEGMENT = "SET_SEARCH_FILTER_SEGMENT";
export const SET_SEARCH_STATE = "SET_SEARCH_STATE"; 
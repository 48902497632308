// import _ from "lodash"
import { useSelector } from "react-redux"

import { buildReduxActionsAndSelectors } from "../"
import { handleStateDefault } from "../"
import { SET_ORDER } from "../orders/order.js"
import * as api from "../../utlis/api"
import { setShowTradeInSpinner } from "../spinners/spinners"

const TRADEIN_REDUX_PREFIX  = "TI"
const SET_TRADEIN_FOCUS_ID  = "SET_TRADEIN_FOCUS_ID"
const RESET_VALUES          = "RESET_TRADEIN_MODAL_VALUES"

const tradeinInitialState = {
  tradeinFocusId: null,
  modal: {
    isOpen: false,
    editMode: false,
    editedTradein: {},
    editedOwedToAddress: {},
    lastSaveTimestamp: null,
    isValidTradeInForm: false,
    isValidAddForm: true,
    isValidEditForm: false,
    applyMileage: false,
    owedToInput: '',
    owedToInputError: '',
    productLenders: [],
    hasUnsavedChanges: false,
    errors: {
      editedTradein: {},
      editedOwedToAddress: {}
    },
    saveTradeinSuccess: false,
    selectedLender: null,
    tradein: null,
  }
}

const TRADEIN_ENTITY_FIELDS = {
  editedTradein: [
    'balanceOwed',
    'balanceOwedTo',
    'cashRefundToCustomer',
    'dolClientNumber',
    'description',
    'finalTradeAllowance',
    'goodUntil',
    'make',
    'model',
    'applyMileage',
    'plateNumber',
    'serialNumber',
    'seriesBodyType',
    'tradeAllowanceAtTerms',
    'vin',
    'year',
  ],
  editedOwedToAddress: [
    'owedToAddress',
    'owedToAddress2',
    'owedToCity',
    'owedToState',
    'owedToZip'
  ]
}

const buildOptions = {
  fields: TRADEIN_ENTITY_FIELDS,
  entities: [
    'editedTradein',
    'editedOwedToAddress',
  ],
  //For generating selectors
  reduxPath: "tradeins.modal",
  reduxPrefix: TRADEIN_REDUX_PREFIX,
  statePath: "modal"
}

export const tradeinsReducer = (state=tradeinInitialState, action) => {

  const __reduxPrefix = action.__prefix

  switch ( action.type ) {
    case SET_TRADEIN_FOCUS_ID:
      return {
        ...state,
        tradeinFocusId: action.payload
      }
    case RESET_VALUES:
      return {
        ...tradeinInitialState,
        modal: {
          ...tradeinInitialState.modal,
          productLenders: state.modal.productLenders,
          //Keep when the form was last saved...
          lastSaveTimestamp: state.modal.lastSaveTimestamp
        }
      }
    case SET_ORDER:
      return {
        ...tradeinInitialState,
        //Keep the focus id set...
        tradeinFocusId: state.tradeinFocusId,
        modal: {
          ...tradeinInitialState.modal,
          isOpen: state.modal.isOpen,
          productLenders: state.modal.productLenders,
          //And when the form was last saved...
          lastSaveTimestamp: state.modal.lastSaveTimestamp
        }
      }
    default:
      //Handle all generated state updates...
      if ( __reduxPrefix === TRADEIN_REDUX_PREFIX ) {
        return handleStateDefault(state, action)
      }
  }

  return state
}

export const rdx =
  buildReduxActionsAndSelectors(tradeinInitialState, buildOptions)

rdx.resetValues = () => {
  return {
    type: RESET_VALUES
  }
}

const useTradeinFocusId = () => {
  return useSelector(state => state.tradeins.tradeinFocusId)
}

rdx.useTradeinFocusId = useTradeinFocusId

rdx.setTradeinFocusId = id => {
  return {
    type: SET_TRADEIN_FOCUS_ID,
    payload: id
  }
}

rdx.getProductLenders = () => {
  return dispatch => {
    api.getProductLenders()
    .then(lenders => {
      const options = lenders.map(option => ({
          id: option.id,
          name: option.bankName,
          data: option
        }))
      dispatch(rdx.setProductLenders(options))
      return options
    })
  }
}

rdx.saveTradein = (orderId, tradeinData) => {
  return dispatch => {
    api.saveTradein(orderId, tradeinData)
    .then( res => {
      dispatch(rdx.setTradeinFocusId(res?.data?.id))
      dispatch(rdx.setSaveTradeinSuccess(true))
      dispatch(rdx.setHasUnsavedChanges(false))

      //Now we should be doing an edit
      dispatch(rdx.setLastSaveTimestamp(new Date().getTime()))
    })
  }
}

rdx.updateTradein = (orderId, tradeinId, tradeinData) => {
  return dispatch => {
    dispatch(setShowTradeInSpinner(true))
    api.updateTradein(orderId, tradeinId, tradeinData)
    .then(() => {
      dispatch(setShowTradeInSpinner(false))
      dispatch(rdx.setSaveTradeinSuccess(true))
      dispatch(rdx.setHasUnsavedChanges(false))

      dispatch(rdx.setLastSaveTimestamp(new Date().getTime()))
      
    })
  }
}


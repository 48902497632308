import React, { useState, useEffect } from "react";
import { recordFrontEndError } from "../../../../../../../utlis/api";
import { useDispatch, useSelector} from "react-redux";
// import {
//   useParams
// } from "react-router-dom";
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import States from './States';
import { editProduct, editProductFob } from "../../../../../../../redux/products";
import { useOrder } from "../../../../../../../redux/orders/order";
import { newProductFob, updateProductFob, saveProductSection, getOtherProductsInGroup, filterDocumentsForProduct, filterProductDocumentsForOrder } from "../../../../../../../utlis/dataSaveAndCopyLibrary";
import { updateCopySection, updateCopySubSection, updateObjectId, updateObjectGroupId, updateChangesArray, updateGroupChangesArray, updateStatus, updateSaveFunction } from "../../../../../../../redux/saveAndCopy"
import { setActiveModal, setActiveModalTab } from "../../../../../../../redux/app/appSaver";
import { addTestingTag } from "../../../../../../../utlis/testingHelpers";


const ModalProductFob = ({ product, preCloseClose, documents, setValue }) => {
  
  // let { id }      = useParams();

  const dispatch        = useDispatch()
  
  const order               = useOrder();
 
  const [invalidFOB, setInvalidFOB] = useState(false);
  
  const locations = JSON.parse(localStorage.getItem("alllocations"));

  const saveStatus          = useSelector((state) => state?.copyChangesToDocumentsRedux.status);

  const changesArray = useSelector((state) => state?.copyChangesToDocumentsRedux.changesArray);
  
  const [dataChangeArray, setDataChangeArray]   = useState([]);
  function updateDataChangeArray(e, passedProp=null, passedValue=null){
    e.preventDefault();

    let { name: prop, value} = e.target;

    if(passedProp){
      prop = passedProp;
    }

    if(passedValue){
      value = passedValue;
    }

    let doNotCopyProperties = [];

    if(dataChangeArray && !doNotCopyProperties.includes(prop) && prop !== undefined && prop !== null){

      if(dataChangeArray["fobAddress."+prop]?.oldValue){

        setDataChangeArray((prev)=>({
          ...prev,
          ["fobAddress."+prop]: {
            "newValue": value,
            "oldValue": dataChangeArray["fobAddress."+prop].oldValue
          }
        }))
        
      }else{
        const oldValue = product?.fobAddress[prop];

        setDataChangeArray((prev)=>({
          ...prev,
          ["fobAddress."+prop]: {
            "newValue": value,
            "oldValue": oldValue ? oldValue : '--'
          }
        }))

      }
    }
  }
  const appData = useSelector((state) => state?.appData);

  useEffect(() => {

    if(appData?.activeModal === 'product' && appData?.activeModalTab === 'fob'){
      dispatch(updateChangesArray(dataChangeArray));
    }

  }, [ appData, dataChangeArray, dispatch ])

  useEffect(() => {

    if(appData?.activeModal === 'product' && appData?.activeModalTab === 'fob'){
      if(product?.fobAddress?.addressLine1 === null || product?.fobAddress?.city === null || product?.fobAddress?.state === null || product?.fobAddress?.zip === null){
        setInvalidFOB(true);
      }
    }

  }, [appData, product?.fobAddress])

  const copyDealerLocation= (e) => {
    e.preventDefault()
    const foundLocation = locations.find(loc=>loc.locationCode===order.tecLocationCode);
    if(!foundLocation || !foundLocation?.address){
      return;
    }

    let payload = {
      addressLine1 : foundLocation.address,
      addressLine2 : '',
      city: foundLocation.city,
      zip : foundLocation.zip,
      state : foundLocation.state,
    }
    
    dispatch(editProduct({prop:'fobAddress', value:{...product.fobAddress, ...payload}}))
    dispatch(updateStatus('unsaved'))

    dispatch(updateChangesArray( (prev) => ({
      ...prev,
      addressLine1: {
        "newValue": foundLocation.address,
        "oldValue": product?.fobAddress?.addressLine1 ? product?.fobAddress?.addressLine1 : "--"
      },
      addressLine2: {
        "newValue": "--",
        "oldValue": product?.fobAddress?.addressLine2 ? product?.fobAddress?.addressLine2 : "--"
      },
      city: {
        "newValue": foundLocation.city,
        "oldValue": product?.fobAddress?.city ? product?.fobAddress?.city : "--"
      },
      zip: {
        "newValue": foundLocation.zip,
        "oldValue": product?.fobAddress?.zip ? product?.fobAddress?.zip : "--"
      },
      state: {
        "newValue": foundLocation.state,
        "oldValue": product?.fobAddress?.state ? product?.fobAddress?.state : "--"
      },
    })));

  }

  const stateAbbrev = States.map(state => {
    return state.abbreviation;
  });
  const formatZipCode = (value) => {
    if(value && value !== undefined && value !== null) {
      let zipCode = value.replace(/[^\d]/g, "");
      zipCode = zipCode.trim().replace(/(\d{5})/gi,"$1-").replace(/-$/gi,"");
      if(zipCode.length > 10) {
        zipCode = zipCode.slice(0, 10);
      }
      return zipCode;
    }
  }

  const onEdit = (e, newValue=null, passedName=null )=>{

      let {value, name} = e.target
      let prop = name;

      if (newValue){
        value = newValue;
      }

      if(passedName){
        prop = passedName;
      }

      if(prop === 'zip') value = formatZipCode(value);

      updateDataChangeArray(e, passedName, newValue);

      dispatch(editProductFob({value: value, prop: prop, id: product.fobAddress.id}));

      dispatch(updateStatus('unsaved'));
    }

  const handleSubmit = async event => {
    event.preventDefault();

    dispatch(updateStatus('saving'));
    
    let fobAddressObj = {
      addressLine1: product?.fobAddress?.addressLine1?.toUpperCase()??'',
      addressLine2: product?.fobAddress?.addressLine2?.toUpperCase()??'',
      city: product?.fobAddress?.city?.toUpperCase()??'',
      state: product?.fobAddress?.state ?? '',
      zip:  product?.fobAddress?.zip ?? '',
    }

    if(
      fobAddressObj?.addressLine1 === "" ||
      fobAddressObj?.city === "" ||
      fobAddressObj?.state === "" ||
      fobAddressObj?.zip === "" || (Number(fobAddressObj?.zip?.length) !== 5 && Number(fobAddressObj?.zip?.length !== 9))
    ){
      dispatch(updateStatus('failed'));
      setInvalidFOB(true);
    }else {
    if(fobAddressObj){
      dispatch(updateCopySection('product'));
      dispatch(updateCopySubSection('fob'));
      dispatch(updateObjectId(product.id));
      dispatch(updateObjectGroupId(product.productGroupId));      
      try {
        if(product.fobAddress && product.fobAddress.id){
          dispatch(updateSaveFunction(updateProductFob));
          saveProductSection(event, order, product, 'fob', changesArray, updateProductFob, () => {
            dispatch(updateStatus('saved'));
            if(!product?.registrationAddress?.county || product?.registrationAddress?.county === ""){
              dispatch(setActiveModalTab('registration-address'));
              setValue(2);
            }
          });
        }else{
          dispatch(updateSaveFunction(newProductFob));
          newProductFob(order, product, () => {
            dispatch(updateStatus('saved'));
            dispatch(setActiveModalTab('registration-address'));
            setValue(2);
          })
        }
      } catch (err) {
        recordFrontEndError('ModalProductFob.js', 'Error saving FOB address: ' + err )
      }
    }
    }
  }

  return (
    <>
      { appData?.activeModal === 'product' && appData?.activeModalTab === 'fob' ? (
        <form>
          <div className="container">
            <div className="row border-bottom">
              <div className="d-flex text-capitalize justify-content-between m-2">
                <h5>Product FOB Address</h5>
                <button className="btn btn-primary " data-testid="product.fob.control.copyDealerLocation" onClick={e=> copyDealerLocation(e)} >Copy Dealer Location</button>
                { saveStatus === 'unsaved' ? (
                    <div className="d-flex">
                      <p className="m-1">Save Changes?</p>
                      <SaveChangesYesButton onClick={handleSubmit} product={product} datatestid="product.fob.control.saveYes" />
                      <button className="m-1 btn btn-sm btn-danger" aria-label="cancel" data-testid="product.fob.control.saveNo" onClick={preCloseClose}>No</button>
                    </div>
                  ):(
                    <div>
                      {saveStatus === 'saving' ? (
                        <CircularProgress style={{ placeSelf: "center" }} />
                      ):( saveStatus === 'saved' ? (
                        <SavedProductButton product={product} documents={documents} />
                        ):(
                        <SaveProductButton onClick={handleSubmit} product={product} datatestid="product.fob.control.saveTop" />
                      ))}
                    </div>
                  )}
              </div>
            </div>
            <br />
            <div className="row">
            <div className="mb-3 col-md-6">
              <div>
                <div>
                  <label htmlFor="address1" className="form-label">
                    Address Line 1
                  </label>
                  <input
                    type="text"
                    aria-label="fob address line 1"
                    className="rounded-pill form-control "
                    value={product?.fobAddress?.addressLine1 ??''}
                    onChange={(e) => onEdit(e)}
                    data-testid="product.fob.addressLine1"
                    name="addressLine1"
                    />
                    <span style={{display: (invalidFOB && (!Boolean(product?.fobAddress?.addressLine1)||(product?.fobAddress?.addressLine1===''))) ? 'block' : 'none'}} className="text-danger small">Address is required.</span>
                </div>
                <div>
                  <label htmlFor="address2" className="form-label">
                    Address Line 2
                  </label>
                  <input
                    type="text"
                    className="rounded-pill form-control "
                    //onChange={e => dispatch(updateDeliveryAddress2(e.target.value))}
                    aria-label="fob address line 2"
                    value={product?.fobAddress?.addressLine2 ?? ''}
                    data-testid="product.fob.addressLine2"
                    onChange={(e) => onEdit(e)}
                    name="addressLine2"
                    />
                </div>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="city" className="rounded-pill form-label">
                      City
                    </label>
                    <input
                      type="text"
                      className="rounded-pill form-control "
                      aria-label="fob address city"
                      data-testid="product.fob.city"
                      value={product?.fobAddress?.city ?? ''}
                      onChange={(e) => onEdit(e)}
                      name="city"
                      />
                      <span style={{display: (invalidFOB && (!Boolean(product?.fobAddress?.city)||(product?.fobAddress?.city===''))) ? 'block' : 'none'}} className="text-danger small">City is required.</span>
                  </div>
                  <div className="mb-3 col-md-6 mt-3">
                    <Autocomplete
                      value={product?.fobAddress?.state ?? ''}
                      aria-label='fob address state'
                      onChange={(e, newValue) => {
                        onEdit(e, newValue, 'state')
                      }}
                      options={stateAbbrev??[]}
                      sx={{ width: 150 }}
                      name="state"
                      renderInput={(params) => <TextField {...addTestingTag(params, 'product.fob.state')} label="STATE" />}
                    />
                    <span style={{display: (invalidFOB && (!Boolean(product?.fobAddress?.state)||(product?.fobAddress?.state===''))) ? 'block' : 'none'}} className="text-danger small">State is required.</span>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="zip" className="form-label">
                      Zip
                    </label>
                    <input
                      type="text"
                      aria-label='fob address zip'
                      className="rounded-pill form-control"
                      name="zip"
                      data-testid="product.fob.zip"
                      value={formatZipCode(product?.fobAddress?.zip) ?? ''}
                      onChange={(e) => onEdit(e)}
                    />
                    <span style={{display: (invalidFOB && (!Boolean(product?.fobAddress?.zip)||(product?.fobAddress?.zip==='') ||(product?.fobAddress?.zip?.length !==5 && product?.fobAddress?.zip?.length !==9))) ? 'block' : 'none'}} className="text-danger small">Zip is required and must be 5 or 9 digits long.</span>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div className="text-center">
            { saveStatus === 'saving' ?
            <CircularProgress style={{ placeSelf: "center" }} />
            :( saveStatus === 'saved' ? (
              <SavedProductButton product={product} documents={documents} preCloseClose={preCloseClose} />
            ):(
              <SaveProductButton onClick={handleSubmit} product={product} datatestid="product.fob.control.saveBottom" />
            ))}
          </div>
        </form>
      ):null}
    </>
  );
}

const SaveProductButton = ({ onClick, product, datatestid }) => {
  return (
    <>
      <button aria-label='Save' onClick={onClick} data-testid={datatestid} className="btn btn-primary text-uppercase">Save</button>
    </>
  )
}

const SaveChangesYesButton = ({ onClick, product, datatestid }) => {
  return (
    <>
      <button aria-label='Yes' onClick={onClick} data-testid={datatestid} className="btn btn-success text-uppercase">Yes</button>
    </>
  )
}

const SavedProductButton = ({ product, documents, preCloseClose }) => {

  const order   = useOrder();
  const dispatch  = useDispatch();
  const changesArray = useSelector((state) => state?.copyChangesToDocumentsRedux.changesArray);

  function filterDocuments(){
    let productDocuments = filterDocumentsForProduct(product.id, documents);
    let orderDocuments = filterProductDocumentsForOrder(product.id, documents);

    let allFilteredDocuments = orderDocuments.concat(productDocuments);
    return allFilteredDocuments;
  }

  function prepRedux(){
    dispatch(updateCopySection('product'));
    dispatch(updateCopySubSection('fob'));
    dispatch(setActiveModalTab(null));
  }

  function prepGroupCopy(){
    let copyChangesArray = [];
    copyChangesArray.id = product.id;
    copyChangesArray.stockNumber = product.stockNumber;
    copyChangesArray.propertySaved = false;
    copyChangesArray.docsSavedCount = 0;
    Object.entries(changesArray).forEach( value => {
      copyChangesArray[value[0]] = value[1];
    });


    dispatch(updateGroupChangesArray([copyChangesArray]));
    dispatch(updateStatus('copy-to-group'));
    dispatch(setActiveModal('copy-to-product-group'));
  }

  function prepDocumentCopy(){
    let copyChangesArray = [];
    copyChangesArray.id = product.id;
    copyChangesArray.stockNumber = product.stockNumber;
    copyChangesArray.propertySaved = false;
    copyChangesArray.docsSavedCount = 0;
    Object.entries(changesArray).forEach( value => {
      copyChangesArray[value[0]] = value[1];
    });
    dispatch(updateGroupChangesArray([copyChangesArray]));
    dispatch(updateStatus('copy'));
    dispatch(setActiveModal('copy-to-documents'));
  }

  return (
    <>
      <span className="btn btn-sm btn-success text-uppercase mx-2">Product Saved</span>
      { getOtherProductsInGroup(order, product.id, product.productGroupId)?.length > 0 ? (
        <>
          <button aria-label='Copy To Group' 
            onClick={ (e)=>{
              e.preventDefault()
              prepRedux();
              prepGroupCopy();
              preCloseClose();
            }} data-bs-dismiss="modal" data-bs-toggle="modal" data-testid="product.fob.control.copyToGroup" data-bs-target="#copychangestogroupmodal" className="btn btn-primary text-uppercase mx-2">Copy to Group</button>
        </>       
        ): filterDocuments()?.length > 0 ? (
        <>
          <button aria-label='Copy to Documents' 
            onClick={ (e)=>{
              e.preventDefault();
              prepRedux();
              prepDocumentCopy();
              preCloseClose();
            }} data-bs-dismiss="modal" data-bs-toggle="modal"  data-testid="product.fob.control.copyToDocument" data-bs-target="#copychangestodocuments" className="btn btn-sm btn-primary text-uppercase mx-2">Copy to Documents</button>
        </>
      ):null}
    </>
  )
}

export default ModalProductFob;

import React, {useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  useParams,
} from "react-router-dom";
import { useFetch} from "../../../../../../utlis/api";
import { Checkbox } from "@material-ui/core";
import { useOrder } from "../../../../../../redux/orders/order"
import { CircularProgress } from "@mui/material";
import * as api from '../../../../../../utlis/api.js'


const DocumentSetModal = ({documents, updateOrderDocsInfo, updateDocSet}) => {
  const axios = useFetch()
  let { id } = useParams();
  const order = useOrder();

  const appData = useSelector((state) => state?.appData);
  const locations = JSON.parse(localStorage.getItem("alllocations"));

  const [location, setLocation] = useState(null)
  const [loadingLocation, setLoadingLocation] = useState(false)


  useEffect(() => {
    async function updateLocation(locationCode){

        setLoadingLocation(true);

        let foundLocation = locations.find(l => l.locationCode === locationCode);
        if(foundLocation){
          try{
            let res = await api.getTecRegion(foundLocation?.locationCode)
            let region = res?.data;
            foundLocation.email = region?.email;
            setLocation(foundLocation);
            setLoadingLocation(false);
        }catch(err) {}
      }

    }

    if(locations?.length > 0 && (location === null || location?.locationCode !== order?.tecLocationCode) && loadingLocation === false && order?.tecLocationCode){
      updateLocation(order.tecLocationCode);
    }

  },[loadingLocation, location, locations, order.tecLocationCode]);


  var initDocumentSet = useMemo(()=>{return initDocumentSet= [
    {"name": "Sales Order", "slug": "sales-order", "selected": true},
    {"name": "Invoice", "slug": "invoice", "selected": false},
    {"name": "Power Of Attorney", "slug": "power-of-attorney", "selected": true},
  ]},[]);

  const [isLoading,setIsLoading]=useState(false)
  const [isRefreshing, setIsRefreshing]=useState(false)
  const [isGenerating, setIsGenerating]=useState(false)
  const [documentSet, setDocumentSet] = useState(undefined);
  const [saveSuccess, setSaveSuccess] = useState(false);
  
  const checkForDocument=useCallback((selectedDocumentTemplate, assignedObjectId=null, hardType=null)=>{
    // getDocuments();
    let documentFound = false;
    if(documents && documents.length > 0){
      documents.forEach(document => {
        if(document?.documentTemplate?.type === selectedDocumentTemplate?.type){
          if(document?.documentTemplate?.type === "order" || document?.documentTemplate?.type === "order-selected" || document?.documentTemplate?.type === "special-sales" || document?.documentTemplate?.type === "special"){
            if(document?.documentTemplate?.slug === selectedDocumentTemplate?.slug){
              documentFound = true;
            }
          } else {
            // Check if product or trade in is assigned.
            if(assignedObjectId && document?.assignedObjectId === assignedObjectId && hardType && document?.assignedObjectType === hardType){
              if(document?.documentTemplate?.slug === selectedDocumentTemplate?.slug){
                documentFound = true;
              }
            }
          }
        }
      });
    }
    return documentFound;
  },[documents])

  const onDocumentSetToggle = e =>{
    const {name,checked}=e.target;
    const newArr = documentSet.map(obj => {
      if (obj.slug === name) {
        return {...obj, selected: checked};
      }
    
      return obj;
    });

    setDocumentSet(newArr);
    setSaveSuccess(false);
  }



  const [productSet, setProductSet] = useState(undefined);
  const onProductSetToggle = e =>{
    const {id, name ,checked}=e.target;
    let holderSet = productSet;
    const newArr = holderSet.map(obj => {
      if (Number(obj.id) === Number(id)) {
        obj.documentSet = obj.documentSet.map(doc => {
          if(doc.slug === name){
            return {...doc, selected: checked};
          }
          return doc;
        });
      }
      return obj;
    });
    setProductSet(newArr);
    setSaveSuccess(false);
  }
  
  const [productDocsLoaded, setProductDocsLoaded] = useState(false);

  function loadProductSet(productSet){
    setProductSet(undefined);
    setProductSet(productSet);
    setProductDocsLoaded(true);
  }

// come back to refactor for try/catch (too many re-renders firing record error)
  const prepDocSet=useCallback((fullProduct, product)=>{
    axios.get(`/order/${id}/product/${product.id}/prepare-document-set`)
    .then(response => {
      product.documentSet = response.data
        // product.documentSet
      for (var i = 0; i < product.documentSet.length; i++) {
        product.documentSet[i].selected = true;
        if(checkForDocument(product.documentSet[i], product.id ,'product')){
          product.documentSet[i].found = true;
          product.documentSet[i].selected = false;

        }

        if(product.documentSet[i].slug === "tec-insurance-request"){
          if(fullProduct.fundingDetail && fullProduct.fundingDetail.fundingMethod !== "tec-financing" && fullProduct.fundingDetail.fundingMethod !== "finance-only" && fullProduct.fundingDetail.fundingMethod !== "direct-deal" && fullProduct.fundingDetail.fundingMethod !== "cash-with-lien"){
            product.documentSet[i].selected = false;
          }
        }

        if(product.documentSet[i].slug === "tec-sold-as-is"){
          if(product.type.toString().toUpperCase() === 'USED'){
            product.documentSet[i].selected = true;
          }else{
            product.documentSet[i].selected = false;
          }
        }

        // Filter out old document versions by slug
        if(
          product.documentSet[i].slug === "ca-title"
        ){
          delete product.documentSet[i];
        }
        

        // Filter out tax exempt docs
        if(order?.customer?.isExemptSalesTax === false){
          if(product.documentSet[i].slug === "ca-resale-cert" ||
             product.documentSet[i].slug === "tec-ca-affidavit-exempt-sales-tax" ||
             product.documentSet[i].slug === "ca-delivery-statement" ||
             product.documentSet[i].slug === "ca-sales-tax-statement" ||
             product.documentSet[i].slug === "ca-sales-tax-affidavit"
          ){
            delete product.documentSet[i];
          }
        }

      }

      for (var j = 0; j < product.documentSet.length; j++) {

        if(product.documentSet[j].slug === "tec-tax-waiver"){
          if(order.location.state === "CA"){
            product.documentSet[j].selected = true;
          }else{
            // Remove from doc set
            product.documentSet.splice(j, 1);
          }
        }

        if(product.documentSet[j].slug === "tec-tax-waiver"){
          if(order.location.state === "CA"){
            product.documentSet[j].selected = true;
          }else{
            // Remove from doc set
            product.documentSet.splice(j, 1);
          }
        }

        if(product.documentSet[j].slug === "tec-disclosure-applicability"){
          if(order.location.state === "CA"){
            product.documentSet[j].selected = true;
          }else{
            product.documentSet.splice(j, 1);
          }
        }

        if(product.documentSet[j].slug === "tec-statement-of-error-notary"){
          product.documentSet.splice(j, 1);
        }

        if(product.documentSet[j].slug === "tec-poa-no-notary"){
          product.documentSet.splice(j, 1);
        }


        if(product.documentSet[j].slug === "tec-ca-affidavit-exempt-sales-tax"){
          product.documentSet.splice(j, 1);
        }

        // Filter out tax exempt docs
        if(order?.customer?.isExemptSalesTax === false){
          if(product.documentSet[j].slug === "ca-resale-cert" ||
              product.documentSet[j].slug === "tec-ca-affidavit-exempt-sales-tax" ||
              product.documentSet[j].slug === "ca-delivery-statement" ||
              product.documentSet[j].slug === "ca-sales-tax-statement" ||
              product.documentSet[j].slug === "ca-sales-tax-affidavit"
          ){
            product.documentSet.splice(j, 1);
          }
        }

        // Filter out old document versions by slug
        if(
          product.documentSet[j].slug === "ca-title"
        ){
          product.documentSet.splice(j, 1);
        }

      }
        
      // allProducts.push(product);
    }).catch(err => {})
    return product;
  },[axios, checkForDocument,id,order])

  const getProductSet = useCallback(async () => {
    var allProducts = [];
    for (var i = 0; i < order.products.length; i++) {
      var fullProduct = order.products[i];
      var product = {"id": order.products[i].id, "ymm": order.products[i].year+" "+order.products[i].make+" "+order.products[i].model, "type": order.products[i].type, "stockNumber": order.products[i].stockNumber, "stateRegistered": order.products[i].registrationAddress?.state };
      allProducts.push(prepDocSet(fullProduct, product))
    } 
    loadProductSet(allProducts);
    return(err)=>{
      console.error(err)
    }
  },[order, prepDocSet])

  useEffect(() => {
    if(appData?.activeModal === 'document-set-modal'){

      if(order !== undefined && order.products !== undefined && order.products.length > 0 && !productDocsLoaded){
        setProductDocsLoaded(true);
        getProductSet();
      }

    }
  },[order, productDocsLoaded, getProductSet, appData?.activeModal])


  const [tradeinSet, setTradeinSet] = useState(undefined);
  const onTradeinSetToggle = e =>{
    const {id, name ,checked}=e.target;
    const newArr = tradeinSet.map(obj => {
      if (Number(obj.id) === Number(id)) {
        obj.documentSet = obj.documentSet.map(doc => {
          if(doc.slug === name){
            return {...doc, selected: checked};
          }
          return doc;
        });
        return obj;
      }
      return obj;
    });
    setTradeinSet(newArr);
  }
  
  function loadTradeinSet(allTradeins){
    setTradeinSet(undefined);
    setTradeinSet(allTradeins);
  }

  const [tradeinDocsLoaded, setTradeinDocsLoaded] = useState(false);


  // come back to refactor for try/catch (too many re-renders firing record error)
  function getTradeinDocSet(tradein){
    axios.get(`/order/${id}/tradein/${tradein.id}/prepare-document-set`)
    .then(response => {
      tradein.documentSet = response.data
      for (var i = 0; i < tradein.documentSet.length; i++) {
        tradein.documentSet[i].selected = true;
        if(checkForDocument(tradein.documentSet[i], tradein.id ,'tradein')){
          tradein.documentSet[i].found = true;
          tradein.documentSet[i].selected = false;
        }
        // if(tradein.documentSet[i].slug === 'tec-poa' || tradein.documentSet[i].slug === 'tec-bill-of-sale' || tradein.documentSet[i].slug === 'tec-poa-no-notary'){
        //   delete tradein.documentSet[i];
        // }
      }
    }).catch(err => {})
    return tradein;
  }

  const getTradeInDocs = async () => {
    var allTradeins = [];
    if(order.tradeins && order.tradeins.length > 0){
      for (var i = 0; i < order.tradeins.length; i++) {
        var tradein = {"id": order.tradeins[i].id, "ymm": order.tradeins[i].year+" "+order.tradeins[i].make+" "+order.tradeins[i].model };
        allTradeins.push(getTradeinDocSet(tradein));
        // await axios.get(`/order/${id}/tradein/${order.tradeins[i].id}/prepare-document-set`)
        // .then(response => {
        //   tradein.documentSet = response.data

        //   for (var i = 0; i < tradein.documentSet.length; i++) {
        //     tradein.documentSet[i].selected = true;
        //     if(checkForDocument(tradein.documentSet[i], tradein.id ,'tradein')){
        //       tradein.documentSet[i].found = true;
        //     }
        //   }

        //   allTradeins.push(tradein);
        // }).catch(err => {})
      } 
    }
    loadTradeinSet(allTradeins);
    // updateList++;
  }
  if(order !== undefined && order.tradeins !== undefined && order.tradeins.length > 0 && !tradeinDocsLoaded){
    setTradeinDocsLoaded(true);
    getTradeInDocs();
  }

  const createDocumentSet = event => {
    event.preventDefault();
    setIsGenerating(true)

    for (var i = 0; i < documentSet.length; i++) {
      if(documentSet[i] && documentSet[i].selected && !documentSet[i].found && !checkForDocument(documentSet[i])){

      let orderDraftData = {
        DocumentStatusId: 5, //Draft
        DocumentTemplateId: documentSet[i].id,
        OrderId: id,
        Payload: JSON.stringify({}),
        AssignedObjectType: 'order',
      }

      axios
      .post(`/document`, orderDraftData)
      .then((res) => {
        // setSaveSuccess(true);
        // updateOrderDocsInfo(true);
      })
      .catch((err) => {});
      documentSet[i].found = true;
      documentSet[i].selected = false;

      }else{
        if(checkForDocument(documentSet[i])){
          documentSet[i].found = true;
          documentSet[i].selected = false;
        }
      }
    }
    setDocumentSet(documentSet);

    if(productSet != null && productSet !== undefined && productSet.length > 0){
      for (var s = 0; s < productSet.length; s++) {
        for (var x = 0; x < productSet[s].documentSet.length; x++) {
          if(productSet[s] && productSet[s].documentSet[x] && productSet[s].documentSet[x].selected && !productSet[s].documentSet[x].found && !checkForDocument(productSet[s].documentSet[x], productSet[s].id, 'product')){

            let draftData = {
              DocumentStatusId: 5, //Draft
              DocumentTemplateId: productSet[s].documentSet[x].id,
              OrderId: id,
              Payload: JSON.stringify({}),
              AssignedObjectId: productSet[s].id,
              AssignedObjectType: 'product',
            }
            
            axios
            .post(`/document`, draftData)
            .then((res) => {
              // if(tradeinSet == null || tradeinSet === undefined || tradeinSet.length === 0){
                // if(s === (productSet.length - 1) && x === (productSet[s].documentSet.length - 1)){
                //   setSaveSuccess(true);
                //   updateOrderDocsInfo();
                //   // history.push(`/order/${id}`);
                // }
              // }
                // setInterval(() => {
              //   setSaveSuccess(false);
              // }, 5000);
              // if (res.status === 200) {
                // setOrder(SET_ORDER , res?.tradein);
                // history.push(`/order/${id}`);
                // }
              })
              .catch((err) => {});
              productSet[s].documentSet[x].found = true;
              productSet[s].documentSet[x].selected = false;
          }else{
            if(checkForDocument(productSet[s].documentSet[x], productSet[s].id, 'product')){
              productSet[s].documentSet[x].found = true;
              productSet[s].documentSet[x].selected = false;
            }
          }
        }
      }
      setProductSet(productSet);
    }

    if(tradeinSet != null && tradeinSet !== undefined && tradeinSet.length > 0){
      for (var t = 0; t < tradeinSet.length; t++) {
        for (var y = 0; y < tradeinSet[t].documentSet.length; y++) {
          if(tradeinSet[t].documentSet[y].selected && !checkForDocument(tradeinSet[t].documentSet[y], tradeinSet[t].id, 'tradein')){

            let draftData = {
              DocumentStatusId: 5, //Draft
              DocumentTemplateId: tradeinSet[t].documentSet[y].id,
              OrderId: id,
              Payload: JSON.stringify({}),
              AssignedObjectId: tradeinSet[t].id,
              AssignedObjectType: 'tradein',
            }
            
            axios
            .post(`/document`, draftData)
            .then((res) => {
              // if(s === tradeinSet.length && x === tradeinSet[s].documentSet.length){
                setSaveSuccess(true);
                updateOrderDocsInfo();
  
                // history.push(`/order/${id}`);
              // }
              
              // if (res.status === 200) {
                // setOrder(SET_ORDER , res?.tradein);
                // history.push(`/order/${id}`);
                // }
              })
              .catch((err) => {});
              tradeinSet[t].documentSet[y].found = true;
              tradeinSet[t].documentSet[y].selected = false;
          }else{
            if(checkForDocument(tradeinSet[t].documentSet[y], tradeinSet[t].id, 'tradein')){
              tradeinSet[t].documentSet[y].found = true;
              tradeinSet[t].documentSet[y].selected = false;
            }
          }
        }
      }
      setTradeinSet(tradeinSet);
    }
    setSaveSuccess(true);
    updateOrderDocsInfo(true);
    setIsGenerating(false)
    // setTimeout(reloadDocSet(), 3000);
    // reloadDocSet();
    // history.push(`/order/${id}`);
    // window.location.reload();
  }

  // come back to refactor for try/catch (too many re-renders firing record error)
  const reloadOrderSet = useCallback(()=>{
    setIsLoading(true)
    setDocumentSet(undefined)
    axios.get(`/order/${id}/prepare-document-set`)
      .then(response => {
        var documentTemplates = response.data;
        for (var t = 0; t < documentTemplates.length; t++) { 
          documentTemplates[t].selected = true;
          if(checkForDocument(documentTemplates[t])){
            documentTemplates[t].found = true;
            documentTemplates[t].selected = false;
          }
          // if(documentTemplates[t].slug === 'tec-poa' || documentTemplates[t].slug === 'tec-bill-of-sale' || documentTemplates[t].slug === 'tec-poa-no-notary'){
          //   delete documentTemplates[t];
          // }
        }
        setDocumentSet(documentTemplates)
        setIsLoading(false)
      }).catch(err => {});
      setTimeout(function() {
        //your code to be executed after 1 second
      }, 2500);
  },[ axios, checkForDocument, id ])

  const [previousUpdateId, setPreviousUpdateId] = useState(0);

 async function reloadDocSet (){
    setIsRefreshing(true)
    setDocumentSet(initDocumentSet);
    reloadOrderSet();
    await getProductSet();
    await getTradeInDocs();
    setIsRefreshing(false)
  }

  if(updateDocSet !== previousUpdateId){
    setPreviousUpdateId(updateDocSet);
    reloadDocSet();
  }

      // history.push(`/order/${id}`);
  useEffect(() => {
    if(appData?.activeModal === 'document-set-modal'){

      if(!documentSet){
        setDocumentSet(initDocumentSet);
        reloadOrderSet();
      }

    }
  }, [order, documentSet, updateDocSet, initDocumentSet, reloadOrderSet, appData?.activeModal]);




  function resetModal(){
    setDocumentSet(undefined);
    // setDocumentsLoaded(false);
    setSaveSuccess(false);
    // setProductsSet(undefined);
    setProductSet(undefined);
    setProductDocsLoaded(false);
  }

  return (
    <>
      <div
        className="modal fade"
        id="documentsetmodal"
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        // data-bs-backdrop="static" 
        data-bs-keyboard="false"
        data-testid="modal.generateDocument.topDiv"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h4
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                Generate Document Set
              </h4>

              <button
                type="button"
                className="btn-close bg-white"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={e => resetModal()}
                data-testid="modal.generateDocument.control.closeButtonTop"
              ></button>
            </div>

            { appData?.activeModal === 'document-set-modal' ? (

              <div className="modal-body mx-auto p-3" style={{ width: '100%'}}>
                <div className="text-center">
                {isRefreshing?<div style={{display:'flex', flexDirection:'column'}}>
                  <CircularProgress style={{alignSelf:'center'}}/>
                </div>:<button
                  type="button"
                  className="btn btn-primary"
                  onClick={e => reloadDocSet()}
                  data-testid="modal.generateDocument.control.reloadButton"
                  >Reload</button>}
                </div>
                {/* <button onClick={updateList++}>Refresh</button> */}
                <div className="mx-auto text-left" style={{ width: '50%'}}>
                  <h4 className="text-center">Order Docs</h4>
                  <h5 className="text-center">Selling Location: "{location.state}"</h5>
                  { isLoading?<div style={{display:'flex', flexDirection:'column'}}><CircularProgress style={{alignSelf:'center'}}/></div>:(documentSet !== undefined && documentSet.length > 0 ? documentSet.map(documentTemplate => (
                    <div key={documentTemplate.slug}>
                        <p>
                          <Checkbox disabled={ ( documentTemplate.found ?? false ) } checked={ (documentTemplate.selected) } onChange={onDocumentSetToggle} name={documentTemplate.slug} />
                          {documentTemplate.found ? ( 
                            <span className="text-success">{documentTemplate.name} (Found)</span>
                          ):(
                            <span>{documentTemplate.name}</span>
                          )}
                        </p>
                    </div>
                  )) : null)}
                  <hr></hr>

                    {productSet !== undefined ? ( <h4 className="text-center">Product Docs</h4> ):null}
                    {isLoading?<div style={{display:'flex', flexDirection:'column'}}><CircularProgress style={{alignSelf:'center'}}/></div>:( productSet !== undefined && productSet.length > 0 ? productSet.map(product => (
                      <div key={product.id}>
                        <h5 className="text-center">Stock Number: "{ product.stockNumber ? product.stockNumber.toString().toUpperCase() : "None" }"</h5>
                        <h5 className="text-center">State Registered: "{ product.registrationAddress?.state ? product.registrationAddress?.state : "None" }"</h5>
                        { product.documentSet !== undefined && product.documentSet.length > 0 ? product.documentSet.map(productDoc => (
                          <div key={productDoc.id}>
                            <p>
                              <Checkbox disabled={ ( productDoc.found ?? false ) } checked={ (productDoc.selected) } id={product.id+""} onChange={onProductSetToggle} name={productDoc.slug} />
                              {productDoc.found ? ( 
                                <span className="text-success">{productDoc.name} (Found)</span>
                              ):(
                                <span>{productDoc.name}</span>
                              )}
                            </p>
                          </div>
                        )) : null}
                        <hr></hr>
                      </div>
                    )) : null)}

                    {tradeinSet !== undefined && tradeinSet.length > 0 ? ( <h4 className="text-center">Tradein Docs</h4> ):null}
                    {isLoading?<div style={{display:'flex', flexDirection:'column'}}><CircularProgress style={{alignSelf:'center'}}/></div>:(tradeinSet !== undefined && tradeinSet.length > 0 ? tradeinSet.map(tradein => (
                      <div key={tradein.id}>
                        <h5 className="text-center">{ tradein.ymm }</h5>
                        { tradein.documentSet !== undefined && tradein.documentSet.length > 0 ? tradein.documentSet.map(tradeinDoc => (
                          <div key={tradeinDoc.id}>
                            <p>
                              <Checkbox disabled={ ( tradeinDoc.found ?? false ) } checked={ (tradeinDoc.selected) } id={tradein.id+""} onChange={onTradeinSetToggle} name={tradeinDoc.slug} />
                              {tradeinDoc.found ? ( 
                                <span className="text-success">{tradeinDoc.name} (Found)</span>
                              ):(
                                <span>{tradeinDoc.name}</span>
                              )}
                            </p>
                          </div>
                        )) : null}
                        <hr></hr>
                      </div>
                    )) : null)}

                    <div className="text-center">
                      {isGenerating?<div style={{display:'flex', flexDirection:'column'}}><CircularProgress style={{alignSelf:'center'}}/></div>:(saveSuccess ? (
                        <span className="btn btn-success" data-testid="modal.generateDocument.control.generatedSetNoAction">Generated Set</span>
                      ):(
                        <button className="btn btn-primary" onClick={createDocumentSet} data-testid="modal.generateDocument.control.generateSet">Generate Set</button>
                      ))}
                    </div>
                </div>
              </div>

            ):null}
          </div>
        </div>
      </div>
    </>
  );
}

export default DocumentSetModal
import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash"
import { FormatListBulleted, Star } from "@material-ui/icons";
import { recordFrontEndError, useFetch } from "../../../../utlis/api";
import { useDispatch, useSelector } from "react-redux";
import {setIsLoading} from '../../../../redux/spinners/spinners'
import { useHistory } from 'react-router';
import { CircularProgress } from '@mui/material';
import { formatter } from "../../../../utlis/formatters";

const FinanceBookedDetails = () => {

  const dispatch  = useDispatch();
  const history   = useHistory();
  const axios     = useFetch()

  const {isLoading} = useSelector((state)=>state.spinners)
  const [statsLoaded, setStatsLoaded] = useState()
  const financeDashboardState =
    useSelector((state) => state?.financeDashboardState.stats)

  const startNewOrder = async () => {
    dispatch(setIsLoading(true))
    try {
      let res = await axios.post(`/new-order`, {})
      history.push('/order/'+res.data.id);
      dispatch(setIsLoading(false))
    } catch (err) {
      recordFrontEndError('FinanceBookedDetails.jsx', 'Error starting new order: ' + err)
    }
  }

  //Effect to tell whether we've loaded the stats...
  useEffect(() => {
    if ( _.isNil( financeDashboardState ) ) {
      setStatsLoaded(false)
    } else {
      setStatsLoaded(true)
    }
  }, [ financeDashboardState ])

  //Using the useMemo hook here to memoize the lender items
  //based on the financeDashboardState...
  const lenderItems = useMemo(() => {

    if(financeDashboardState && financeDashboardState.topLenders && financeDashboardState.topLenders.length > 0){

        let lenders = financeDashboardState.topLenders;
        // Sort
        lenders = lenders.sort((a, b) => b.totalLendingAmount - a.totalLendingAmount);
        
        // Return top 3
        let topThree = lenders.slice(0, 3);
        return topThree.map((topLender, t) => (
          <div className="mx-auto px-2" key={t} >
          <div className="d-flex justify-content-between mt-2">
            <p className="m-0 fw-bold">{topLender.lender}</p>
            <p className="m-0 fw-bold">{formatter.format(topLender.totalLendingAmount)}</p>
          </div>
          <div className='divider-line'></div>
        </div>
      ))
    }
  }, [ financeDashboardState ])



  return (
    <div className="row mainDiv">
      <div className="col-8 ">
        <div className="d-flex">
          <FormatListBulleted />
          <p className="m-0 px-1 fw-bold">Booked Details</p>
        </div>
        <div className="p-2 bg-light text-dark rounded-3 mt-1">
          <div className="d-flex justify-content-evenly text-center">
            <div className="">
            <p className="m-0">Total</p>
            {statsLoaded && financeDashboardState && financeDashboardState.bookedDeals && <h1 style={{color:'#058a00'}}>{financeDashboardState.bookedDeals.totalOrdersBooked}</h1> }
            </div>
            <div className='divider-horizontal'></div>
            <div className="">
              <p className="m-0">Items Sold</p>
              {statsLoaded && <h1 style={{color:'#1641a5'}}>{financeDashboardState.bookedDeals.totalProductsBooked}</h1> }
            </div>
            <div className='divider-horizontal'></div>
            <div className="">
              <p className="m-0">Trade Ins</p>
            {statsLoaded && <h1 style={{color:'#ecae04'}}>{financeDashboardState.bookedDeals.totalTradeinsBooked}</h1> }
            </div>
            <div className='divider-horizontal'></div>
            <div className="">
              <p className="m-0">Financed</p>
            {statsLoaded && <h1 style={{color:'#a600b2'}}>{ financeDashboardState.bookedDeals.totalFinancedBooked ? formatter.format(financeDashboardState.bookedDeals.totalFinancedBooked) :  formatter.format(0.00)}</h1> }
            </div>
          </div>
        </div>
        <div className="pt-4 text-center mx-auto mt-3">
        {isLoading?
          <CircularProgress style={{ placeSelf: "center" }} />
        :
          <button
            onClick={ startNewOrder }
            type="button"
            className="btn btn-success text-uppercase">
            Start New Order
          </button>}
        </div>
      </div>

      <div className="col-4">
        <div className="d-flex">
          <Star />
          <p className="m-0 px-1">Top Lenders</p>
        </div>
        <div className="p-2 bg-light rounded text-dark mt-1" style={{ minHeight: '100px'}}>
          { lenderItems }
        </div>
      </div>
    </div>
  );
};

export default FinanceBookedDetails;

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
// import {salesPriceWithUpgradeCalc} from "../../../../../../../utlis/productCalculations";
import { removeChars, formatter, formatUpperCase } from "../../../../../../../utlis/formatters";
import { getOrderLevelAddresses, updateAddressOptions } from "../../../../../../../utlis/documentHelpers";
import { useOrder } from "../../../../../../../redux/orders/order"
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { CASection837SalesTaxAffidavitGlobalAutofill, CASection837SalesTaxAffidavitAutofillProductDetails, preProcessPayload } from "../DocumentAutofill/CASection837SalesTaxAffidavit"

const CASection837SalesTaxAffidavit = ({document_id, location, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "ca-sales-tax-affidavit";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  // const dispatch = useDispatch();

  // const stateAbbrev = States.map(state => {
  //   return state.abbreviation;
  // });

  const order = useOrder();

  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);

  // Data Structure & Init //
  
  var initialDocumentStructure = {

    cbset_1: "",
    sales_tax_section_1_cb: undefined,
    sales_tax_section_2_cb: undefined,

    cbset_2: "",
    vehicle_truck_cb: undefined,
    vehicle_truck_tractor_cb: undefined,
    vehicle_trailer_cb: undefined,
    vehicle_semitrailer_cb: undefined,
    vehicle_trailer_coach_cb: undefined,
    vehicle_aux_dolly_cb: undefined,

    make_model: undefined,
    vin: undefined,
    year: undefined,
    purchase_price: undefined,
    unladen_weight: undefined,
    manufacturer: undefined,
    manufacture_place: undefined,
    delivery_date: undefined,

    cbset_3: "",
    moved_30_days_cb: undefined,
    moved_75_days_cb: undefined,

    date_moved: undefined,

    from_dealer: undefined,
    from_dealer_address: undefined,
    delivery_dealer: undefined,
    delivery_dealer_address: undefined,

    cbset_4: "",
    lease_cb: undefined,
    no_lease_cb: undefined,
    
    lessee_name_address: undefined,
    vehicle_registered_state: undefined,
    registration_number: undefined,

    cbset_5: "",
    purchaser_corporation_cb: undefined,
    purchaser_llc_cb: undefined,
    purchaser_partnership_cb: undefined,
    purchaser_sole_proprietor_cb: undefined,

    cbset_6: "",
    ca_resident_cb: undefined,
    non_ca_resident_cb: undefined,

    cbset_7: "",
    usage_outside_ca_cb: undefined,
    usage_outside_ca_with_icc_cb: undefined,
    
    purchaser_name: undefined,
    title: undefined,
    phone: undefined,
    
    date: undefined,
    signator_name: undefined,
    manufacturer_toggle: undefined
  }
  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  // X1
  const removeFormatting = e =>{
    const {name,value}=e.target;
    setDocumentValues({...documentValues,[name]:removeChars(value)});
  }

  // X1 Reference Arrays
  const moneyFields = useMemo(()=>{return["purchase_price"]},[]);
  const phoneFields = useMemo(()=>{return["phone"]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "make_model"]},[]);
  const checkboxes = useMemo(()=>{return[]},[]);

  // X1- Current General On Change
  const handleDocumentValuesChange = e =>{
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }

    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  
  const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('CASection837SalesTaxAffidavit.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, documentLoaded, loadDocument ])
  const [assignedProduct, setAssignedProduct] = useState(undefined);

  const [locationVal, setLocationVal] = useState(undefined);
  const [selectedLocation, setselectedLocation] = useState(undefined);
  const [inputLocationVal, setinputLocationVal] = useState(undefined);
  const [locationOptions, setLocationOptions] = useState(undefined);
  const [locationToggleAutoFill, setLocationToggleAutoFill] = useState(false);

  const [location2Val, setLocation2Val] = useState(undefined);
  const [selectedLocation2, setselectedLocation2] = useState(undefined);
  const [inputLocation2Val, setinputLocation2Val] = useState(undefined);
  const [location2ToggleAutoFill, setLocation2ToggleAutoFill] = useState(false);

  // const [manufacturerOptions, setManufacturerOptions] = useState(undefined);

  const [selectedLesseeAddress, setSelectedLesseeAddress] = useState(undefined);
  const [addressOptions, setAddressOptions] = useState(undefined);
  const [inputLesseeAddressVal, setInputLesseeAddressVal] = useState(undefined);

  // Dropdowns
if(locations && locations.length > 0 && locationOptions === undefined){
  let getLocationOptions = [];
  locations.map(location => {
    let str = `${location.description}`;
    let obj = {locationCode: location.locationCode, description: str}
    getLocationOptions.push(obj);
    return null;
  })
  setLocationOptions(getLocationOptions);
}

function getManufacturers(){
  let manufacturers = [
    {name: "Mack Trucks Inc", location: "Greensboro, NC"},
    {name: "Volvo Trucks North America", location: "Greensboro, NC"},
    {name: "Wabash National, L.P.", location: "Lafayette, IN"},
    {name: "Dragon Products LLC", location: "Beaumont, TX"},
    {name: "BWS Manufacturing LTD.", location: "Centreville, New Brunswick, Canada E7K 3E9"},
    {name: "Pro Fab US", location: "Cuauhtemoc, Chih"},
    {name: "MAC Manufacturing Inc", location: "Salem, OH"},
    {name: "Transcraft Corporation", location: "Cadiz, KY"}
  ];

  return manufacturers;
}
// 
// function calcCASalesTax(product){
// const calcCASalesTax = useCallback((product) => {

//   // Base Price
//   let basePrice = salesPriceWithUpgradeCalc(product);
//   let adminFee = order.adminFee ? order.adminFee : 0;
//   let docFee = 0;
//   if(product.lineItemFee){
//     docFee = product.lineItemFee.docFee ? product.lineItemFee.docFee : 0;
//   }
//   return Number(basePrice)+Number(adminFee)+Number(docFee);
// },[ order ]);

 // Autofill //

 const GlobalAutofill = useCallback(async () => {
  let newValues = CASection837SalesTaxAffidavitGlobalAutofill(documentValues, order, null, null, location);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  // var newValues = documentValues;

  // if(location){
  //   if(location.state === "CA"){
  //     newValues.from_dealer = "TEC OF CALIFORNIA, INC";
  //     // newValues.delivery_dealer = "TEC OF CALIFORNIA, INC";
  //   }else{
  //     newValues.from_dealer = "TEC EQUIPMENT, INC";
  //     // newValues.delivery_dealer = "TEC EQUIPMENT, INC";
  //   }

  //   newValues.from_dealer_address = location.address+" - "+location.city+", "+location.state+", "+location.zip;
  //   // newValues.delivery_dealer_address = location.address+" - "+location.city+", "+location.state+", "+location.zip;
  // }

  // if(order && order.customer && order.documentationDate){
  //   newValues.delivery_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  // }

  // setDocumentValues(newValues);
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
 },[documentValues, location, order])

//  const preProcessPayload = useCallback((payload) => {

//   if(payload.cbset_1 !== undefined || payload.cbset_1 !== null){
//     payload.sales_tax_section_1_cb = "no";
//     payload.sales_tax_section_2_cb = "no";
//     switch(payload.cbset_1){
//       case "6388":
//         payload.sales_tax_section_1_cb = "yes";
//         break;
//       case "6388.5":
//         payload.sales_tax_section_2_cb = "yes";
//         break;
//       default:
//     }
//   }else{
//     delete payload.cbset_1;
//   }

//   if(payload.cbset_2 !== undefined || payload.cbset_2 !== null){
//     payload.vehicle_truck_cb = "no";
//     payload.vehicle_truck_tractor_cb = "no";
//     payload.vehicle_trailer_cb = "no";
//     payload.vehicle_semitrailer_cb = "no";
//     payload.vehicle_trailer_coach_cb = "no";
//     payload.vehicle_auxiliary_dolly_cb = "no";

//     switch(payload.cbset_2){
//       case "truck":
//         payload.vehicle_truck_cb = "yes";
//         break;
//       case "truck_tractor":
//         payload.vehicle_truck_tractor_cb = "yes";
//         break;
//       case "trailer":
//         payload.vehicle_trailer_cb = "yes";
//         break;
//       case "semitrailer":
//         payload.vehicle_semitrailer_cb = "yes";
//         break;
//       case "trailer_coach":
//         payload.vehicle_trailer_coach_cb = "yes";
//         break;
//       case "auxiliary_dolly":
//         payload.vehicle_auxiliary_dolly_cb = "yes";
//         break;
//       default:
//     }
//   }else{
//     delete payload.cbset_2;
//   }

//   if(payload.cbset_3 !== undefined || payload.cbset_3 !== null){
//     payload.moved_30_days_cb = "no";
//     payload.moved_75_days_cb = "no";
//     switch(payload.cbset_3){
//       case "moved_30_days":
//         payload.moved_30_days_cb = "yes";
//         break;
//       case "moved_75_days":
//         payload.moved_75_days_cb = "yes";
//         break;
//       default:
//     }
//   }else{
//     delete payload.cbset_3;
//   }

//   if(payload.cbset_4 !== undefined || payload.cbset_4 !== null){
//     payload.lease_cb = "no";
//     payload.no_lease_cb = "no";
//     switch(payload.cbset_4){
//       case "is":
//         payload.lease_cb = "yes";
//         break;
//       case "is_not":
//         payload.no_lease_cb = "yes";
//         break;
//       default:
//     }
//   }else{
//     delete payload.cbset_4;
//   }


//   if(payload.cbset_5 !== undefined || payload.cbset_5 !== null){
//     payload.purchaser_corportation_cb = "no";
//     payload.purchaser_llc_cb = "no";
//     payload.purchaser_partnership_cb = "no";
//     payload.purchaser_sole_proprietor_cb = "no";

//     switch(payload.cbset_5){
//       case "corporation":
//         payload.purchaser_corportation_cb = "yes";
//         break;
//       case "llc":
//         payload.purchaser_llc_cb = "yes";
//         break;
//       case "partnership":
//         payload.purchaser_partnership_cb = "yes";
//         break;
//       case "sole_proprietor":
//         payload.purchaser_sole_proprietor_cb = "yes";
//         break;
//       default:
//     }
//   }else{
//     delete payload.cbset_5;
//   }

//   if(payload.cbset_6 !== undefined || payload.cbset_6 !== null){
//     payload.ca_resident_cb = "no";
//     payload.non_ca_resident_cb = "no";

//     switch(payload.cbset_6){
//       case "ca_resident":
//         payload.ca_resident_cb = "yes";
//         break;
//       case "non_ca_resident":
//         payload.non_ca_resident_cb = "yes";
//         break;
//       default:
//     }
//   }else{
//     delete payload.cbset_6;
//   }

//   if(payload.cbset_7 !== undefined || payload.cbset_7 !== null){
//     payload.usage_outside_ca_cb = "no";
//     payload.usage_outside_ca_with_icc_cb = "no";

//     switch(payload.cbset_7){
//       case "usage_outside_ca":
//         payload.usage_outside_ca_cb = "yes";
//         break;
//       case "usage_outside_ca_with_icc":
//         payload.usage_outside_ca_with_icc_cb = "yes";
//         break;
//       default:
//     }
//   }else{
//     delete payload.cbset_7;
//   }




//   // X1.2- Field Type Specific Preview Formatting
//   Object.keys(payload).forEach(key => {
//     if(moneyFields.indexOf(key) >= 0){
//         payload[key] = formatMoneyValue(payload[key]);
//     }else if(phoneFields.indexOf(key) >= 0){
//         payload[key] = formatPhoneNumber(payload[key]);
//     }else if(upperCaseFields.indexOf(key) >= 0){
//         payload[key] = formatUpperCase(payload[key]);
//     }
//   });
//   setDocumentValues(JSON.parse(JSON.stringify(payload)));
//   Object.keys(payload).forEach(key => {
//     if(checkboxes.indexOf(key) >= 0){
//       payload[key] = payload[key] ? "yes": "no";
//     }
//   });

//   // Data point formatting
//   if(payload.delivery_date){
//     payload.delivery_date = moment.utc(payload.delivery_date).format("MM/DD/YY");
//   }

//   if(payload.date_moved){
//     payload.date_moved = moment.utc(payload.date_moved).format("MM/DD/YY");
//   }

//   if(payload.date){
//     payload.date = moment.utc(payload.date).format("MM/DD/YY");
//   }

//   return payload;
// },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])


const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
  Object.keys(payload).forEach(key => {
    if (payload[key] === null || payload[key] === undefined) {
      delete payload[key];
    }
  });

  if(document){
    var docCopy = JSON.parse(JSON.stringify(document));
    docCopy.payload =  JSON.parse(JSON.stringify(payload));
    docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
    docCopy.payload =  JSON.stringify(docCopy.payload);
    delete docCopy.documentStatus;

    try {
      let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
      const file = new Blob(
        [response.data], 
        {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      // window.open(fileURL);
      setdocumentUrl(fileURL)
    } catch (err) {
      recordFrontEndError('CASection837SalesTaxAffidavit.jsx', 'Error previewing document payload: ' + err)
    };  
  }
},[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


 const AutofillProductDetails = useCallback(async (selectedProduct) => {
  let newValues = CASection837SalesTaxAffidavitAutofillProductDetails(selectedProduct, documentValues);

  if(newValues){
    setDocumentValues(newValues);
    previewPayloadDocument(documentValues);
    setHasUnsavedChanges(true);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setSaveSuccess(false);
  }

  // var newValues = documentValues;
  // newValues.year = (selectedProduct.year).trim();
  // newValues.make_model = (selectedProduct.make).trim()+" "+(selectedProduct.model).trim().toString().toUpperCase();
  // newValues.vin = (selectedProduct.vin);
  // newValues.vehicle_registered_state = selectedProduct.registrationAddress?.state;
  // newValues.purchase_price = formatter.format(calcCASalesTax(selectedProduct));

  // if(selectedProduct.shipTo){

  //   var addressLine = '';
  //   if(selectedProduct.shipTo.addressLine2 != null && selectedProduct.shipTo.addressLine2 !== undefined && selectedProduct.shipTo.addressLine2 !== ''){
  //     addressLine = selectedProduct.shipTo.name+" "+(selectedProduct.shipTo.addressLine1+" "+selectedProduct.shipTo.addressLine2);
  //   }else{
  //     addressLine = selectedProduct.shipTo.name+" "+selectedProduct.shipTo.addressLine1;
  //   }

  //   addressLine = addressLine+" "+(selectedProduct.shipTo.city+", "+selectedProduct.shipTo.state+" "+selectedProduct.shipTo.zip);

  //   newValues.lessee_name_address = addressLine;
  // }

  // setDocumentValues(newValues);
  // // setProductToggleAutoFill(false);
  // if(document){
  //   previewPayloadDocument(documentValues);
  // }
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[document, documentValues, previewPayloadDocument]);

  // Document BREAD + Preview // TODO: Move to Redux
  useEffect(() => {
    if(document && document.payload && !dataLoaded){
      var existingPayload = false;
      var documentPayload = JSON.parse(document.payload);
      if(documentPayload && Object.keys(documentPayload).length > 0){
        existingPayload = true;
    
        //Init Data Points
        if(documentPayload.delivery_date){
          documentPayload.delivery_date = moment.utc(documentPayload.delivery_date).format("YYYY-MM-DD");
        }

        if(documentPayload.date_moved){
          documentPayload.date_moved = moment.utc(documentPayload.date_moved).format("YYYY-MM-DD");
        }

        if(documentPayload.date){
          documentPayload.date = moment.utc(documentPayload.date).format("YYYY-MM-DD");
        }
    
        setDocumentValues(documentPayload);

        // Loading Use Effect
        if(document.additionalData){
          const methodMap = { 
            locationVal: setLocationVal, 
            selectedLocation: setselectedLocation, 
            location2Val: setLocation2Val, 
            selectedLocation2: setselectedLocation2, 
            selectedLesseeAddress: setSelectedLesseeAddress, 
          };

          let parsedAdditionalData = JSON.parse(document.additionalData);
          Object.keys(parsedAdditionalData).forEach(function(key) {
            if(methodMap[key]){
              methodMap[key](parsedAdditionalData[key]);
            }
          });
        }


      }

      var foundAssignedProduct = null;
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
        foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
      }

      setAddressOptions(getOrderLevelAddresses(order));
      if(foundAssignedProduct){
          setAssignedProduct(foundAssignedProduct);
          setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
      }

      // setAddressOptions(getOrderLevelAddresses(order));
      // setManufacturerOptions(getManufacturers());
      if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
      }
      setDataLoaded(true);
    }else{
      setAddressOptions(getOrderLevelAddresses(order));
    }

  },[ documentValues, document, dataLoaded, AutofillProductDetails, GlobalAutofill, order, previewPayloadDocument ])

  
    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();

      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
  
      // const payload = JSON.parse(JSON.stringify(documentValues));
      // var docCopy = document;
      // docCopy.payload = payload;
      // docCopy.payload = preProcessPayload(docCopy.payload);
      // docCopy.payload =  JSON.stringify(docCopy.payload);
      // delete docCopy.documentStatus;
  
      //Save Dropdown Selections
      let dropdownData = {};
      if(locationVal){
        dropdownData.locationVal = {
          locationCode: locationVal.locationCode,
          description: locationVal.description
        };
      }
      if(selectedLocation){
        dropdownData.selectedLocation = {
          locationCode: selectedLocation.locationCode,
          description: selectedLocation.description
        };
      }
      if(location2Val){
        dropdownData.location2Val = {
          locationCode: location2Val.locationCode,
          description: location2Val.description
        };
      }
      if(selectedLocation2){
        dropdownData.selectedLocation2 = {
          locationCode: selectedLocation2.locationCode,
          description: selectedLocation2.description
        };
      }
      if(selectedLesseeAddress){
        dropdownData.selectedLesseeAddress = {
          id: selectedLesseeAddress.id,
          name: selectedLesseeAddress.name
        };
      }

      let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, additionalData, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });
  

      // docCopy.additionalData = JSON.stringify(dropdownData);

      // try {
      //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
      //   if (res) {
      //     axios.post(`/document/${document.id}/generate`)
      //     getDocuments();
      //     setSaveSuccess(true);
      //     setHasUnsavedChanges(false);
      //   }
      // } catch (err) {
      //   recordFrontEndError('CASection837SalesTaxAffidavit.jsx', 'Error saving document: ' + err)
      // };
      
    }

useEffect(() => {
  //Autofill Tradein Details After Selection
  if(selectedLocation !== undefined && locationToggleAutoFill){
    var newValues = documentValues;
    if(selectedLocation.state === "CA"){
      newValues.from_dealer = "TEC OF CALIFORNIA, INC";
    }else{
      newValues.from_dealer = "TEC EQUIPMENT, INC";
    }
    newValues.from_dealer_address = selectedLocation.address+" - "+selectedLocation.city+", "+selectedLocation.state+", "+selectedLocation.zip;

    setDocumentValues(newValues);
    setLocationToggleAutoFill(false);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
},[ selectedLocation, locationToggleAutoFill, document, documentValues, previewPayloadDocument ])

useEffect(() => {
  //Autofill Tradein Details After Selection
  if(selectedLocation2 !== undefined && location2ToggleAutoFill){
    var newValues = documentValues;

    if(selectedLocation2.state === "CA"){
      newValues.delivery_dealer = "TEC OF CALIFORNIA, INC";
    }else{
      newValues.delivery_dealer = "TEC EQUIPMENT, INC";
    }
    newValues.delivery_dealer_address = selectedLocation2.address+" - "+selectedLocation2.city+", "+selectedLocation2.state+", "+selectedLocation2.zip;

    setDocumentValues(newValues);
    setLocation2ToggleAutoFill(false);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
},[ selectedLocation2, location2ToggleAutoFill, document, documentValues, previewPayloadDocument ])

//Autofill Address Details After Selection
function AutoFillAddress(addressSelected, selectionInstance){
  var newValues = documentValues;
  if(selectionInstance){
    if(selectionInstance === "lesse-name-address"){
      newValues.lessee_name_address = addressSelected.label+"            "+addressSelected.address+" "+addressSelected.city+" "+addressSelected.state+", "+addressSelected.zip;
    }
  }
  setDocumentValues(newValues);
  if(document){
    previewPayloadDocument(documentValues);
  }
  setHasUnsavedChanges(true);
  setSaveSuccess(false);
}

const onCloseClick = e => {
  e.preventDefault()
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setdocumentUrl("");
  setDocument(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
  setDocumentLoaded(false);
  setDataLoaded(false);
  setAssignedProduct(undefined);

  setLocationVal(undefined);
  setselectedLocation(undefined);
  setinputLocationVal(undefined);
  setLocationOptions(undefined);
  setLocationToggleAutoFill(false);
  setLocation2Val(undefined);
  setselectedLocation2(undefined);
  setinputLocation2Val(undefined);
  setLocation2ToggleAutoFill(false);
  // setManufacturerOptions(undefined);
  setSelectedLesseeAddress(undefined);
  setAddressOptions(undefined);
  setInputLesseeAddressVal(undefined);
  setDocumentFocus(undefined);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

  return (
    <>
      <div
        className="modal fade"
        // id="ca-section-6388"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                CDTFA 837 - Sales Tax Affidavit
              </h5>
              <br />
              { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1 pt-1 text-white">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                  </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}
                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>

                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill();
                      AutofillProductDetails(assignedProduct);
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>

                  <br />

                  { assignedProduct ? (
                      <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                          Product Snapshot
                          <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                          <h5>SN: { assignedProduct.stockNumber }</h5>
                          <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                          <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                          </div>
                      </div>
                  ):(
                      <div className="m-2 p-2 border">
                          <span>No Assigned Product</span>
                      </div>
                  )}

                  <div className="row">
                    <h5>Equipment</h5>
                  </div>
                  <hr></hr>


                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-12">
                        <label htmlFor="customerName" className="form-label">
                        I have purchased a vehicle or trailer, the sale and use of which is exempt from California sales and use tax per section
                        </label>
                        <br />
                        <FormControl className="px-3 pt-2">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="cbset_1"
                            value={ (documentValues.cbset_1 ?? "" )}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel className="px-3" value="6388" control={<Radio />} label="6388" />
                            <FormControlLabel className="px-3" value="6388.5" control={<Radio />} label="6388.5" />
                            <FormControlLabel className="px-3" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  
                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-12">
                        <label htmlFor="customerName" className="form-label">
                        The vehicle is a:
                        </label>
                        <br />
                        <FormControl className="px-3 pt-2">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="cbset_2"
                            value={ (documentValues.cbset_2 ?? "" )}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel className="px-3" value="truck" control={<Radio />} label="Truck" />
                            <FormControlLabel className="px-3" value="truck_tractor" control={<Radio />} label="Truck Tractor" />
                            <FormControlLabel className="px-3" value="trailer" control={<Radio />} label="Trailer" />
                            <FormControlLabel className="px-3" value="semitrailer" control={<Radio />} label="Semitrailer" />
                            <FormControlLabel className="px-3" value="trailer_coach" control={<Radio />} label="Trailer Coach" />
                            <FormControlLabel className="px-3" value="auxiliary_dolly" control={<Radio />} label="Auxiliary Dolly" />
                            <FormControlLabel className="px-3" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-12">
                        <label htmlFor="customerName" className="form-label">
                        Described As:
                        </label>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Make and Model</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make_model"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make_model ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Purchase Price</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchase_price"
                          onFocus={removeFormatting}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchase_price ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Unladen Weight</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="unladen_weight"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.unladen_weight ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (documentValues.manufacturer) ?? null}
                          name="manufacturer"
                          onChange={(event, newValue) => {
                            let newValues = documentValues;
                            newValues.manufacturer = newValue.name;
                            newValues.manufacture_place = newValue.location;
                            setDocumentValues(newValues);
                          }}
                          onBlur={(e) => updatePreview(e)}
                          options={getManufacturers()}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="MANUFACTURER" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.name}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-9">
                        <label htmlFor="stock">MANUFACTURER/REMANUFACTURER</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="manufacturer"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.manufacturer ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Date of Delivery</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="delivery_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.delivery_date ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">PLACE OF MANUFACTURE/REMANUFACTURE</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="manufacture_place"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.manufacture_place ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-9">
                        <label htmlFor="customerName" className="form-label" style={{ fontSize: "12px",}}>
                        The vehicle or trailer was moved outside California within ___ days of delivery (check one). 
                        </label>
                        <br />
                        <FormControl className="px-3 pt-2">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="cbset_3"
                            value={ (documentValues.cbset_3 ?? "" )}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel className="px-3" value="moved_30_days" control={<Radio />} label="30 Days" />
                            <FormControlLabel className="px-3" value="moved_75_days" control={<Radio />} label="75 Days" />
                            <FormControlLabel className="px-3" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Date Moved</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date_moved"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date_moved ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-12">
                        <label htmlFor="customerName" className="form-label">
                        Seller and Deliverer of Vehicle or Trailer:
                        </label>
                      </div>
                    </div>

                    <div className="row">
                      <h5>Locations</h5>
                      {locationOptions && locationOptions.length > 0 ? (  
                      <div className="d-flex justify-content-evenly">
                          <div className="mb-3 col-md-12 pt-4">
                            <Autocomplete
                              style={{ width: '100%'}}
                              value={ (locationVal && locationVal.description) ?? null}
                              onChange={(event, newValue) => {
                                  setLocationVal(newValue);
                                  if(newValue && newValue.locationCode){
                                    setselectedLocation(locations.find((location) => location.locationCode === newValue.locationCode));
                                    setLocationToggleAutoFill(true);
                                  }
                                  // resetVariables();
                              }}
                              inputValue={inputLocationVal}
                              onInputChange={(event, newInputValue) => {
                                  setinputLocationVal(newInputValue);
                              }}
                              //id="controllable-states-demo"
                              options={locationOptions}
                              sx={{ width: 400 }}
                              renderInput={(params) => <TextField {...params} label="LOCATION" />}
                              renderOption={(props, option) => {
                                  return (
                                  <li {...props} key={option.locationCode}>
                                      {option.description}
                                  </li>
                                  );
                              }}
                              />
                          </div>
                        </div>):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Locations Found</h5>
                      </div>
                    )}
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Selling Dealer</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="from_dealer"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.from_dealer ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">(dealer or manufacturer/ remanufacturer’s address - street, city, state, ZIP Code)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="from_dealer_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.from_dealer_address ?? '' }
                          />
                      </div>
                    </div>

                    {locationOptions && locationOptions.length > 0 ? (  
                    <div className="d-flex justify-content-evenly">
                      <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                          style={{ width: '100%'}}
                          value={ (location2Val && location2Val.description) ?? null}
                          onChange={(event, newValue) => {
                              setLocation2Val(newValue);
                              if(newValue && newValue.locationCode){
                                setselectedLocation2(locations.find((location) => location.locationCode === newValue.locationCode));
                                setLocation2ToggleAutoFill(true);
                              }
                              // resetVariables();
                          }}
                          inputValue={inputLocation2Val}
                          onInputChange={(event, newInputValue) => {
                              setinputLocationVal(newInputValue);
                          }}
                          //id="controllable-states-demo"
                          options={locationOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="LOCATION" />}
                          renderOption={(props, option) => {
                              return (
                              <li {...props} key={option.locationCode}>
                                  {option.description}
                              </li>
                              );
                          }}
                          />
                      </div>
                    </div>    ):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Locations Found</h5>
                      </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Delivered By</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="delivery_dealer"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.delivery_dealer ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">(California dealer or manufacturer/ remanufacturer’s address - street, city, state, ZIP Code)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="delivery_dealer_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.delivery_dealer_address ?? '' }
                          />
                      </div>
                    </div>

                  </div>
                  <hr></hr>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-12">
                        <label htmlFor="customerName" className="form-label">
                        Leasing and Registration Information:
                        </label>
                      </div>
                    </div>

                    <div className="p-2 col-12">
                      <label htmlFor="customerName" className="form-label mx-3" style={{ fontSize: "14px",}}>
                      The vehicle or trailer described above ___ being leased. If being leased, please indicate name and address of lessee: 
                      </label>
                      <br />
                      <FormControl className="px-3 pt-2">
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="cbset_4"
                          value={ (documentValues.cbset_4 ?? "" )}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                        >
                          <FormControlLabel className="px-3" value="is" control={<Radio />} label="IS" />
                          <FormControlLabel className="px-3" value="is_not" control={<Radio />} label="IS NOT" />
                          <FormControlLabel className="px-3" value="" control={<Radio />} label="None" />
                        </RadioGroup>
                      </FormControl>
                    </div>

                    { documentValues && documentValues.cbset_4 && documentValues.cbset_4 ===  "is" && addressOptions && addressOptions.length > 0 ? (
                      <div className="d-flex justify-content-evenly">
                        <div className="p-2 col-md-12">
                        <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedLesseeAddress && selectedLesseeAddress.name) ?? null}
                            name="lesse-name-address"
                            onChange={(event, newValue) => {
                              setSelectedLesseeAddress(newValue);
                              AutoFillAddress(newValue, "lesse-name-address");
                            }}
                            inputValue={inputLesseeAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setInputLesseeAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Lessee" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>
                      ):null}

                      { documentValues && documentValues.cbset_4 && documentValues.cbset_4 ===  "is" ? (
                      <div className="d-flex justify-content-evenly">
                        <div className="p-2 col-md-12">
                          <label htmlFor="stock">(name and address of lessee)</label>
                          <input
                            type="text"
                            className="form-control rounded-pill mt-1"
                            name="lessee_name_address"
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                            value={ documentValues.lessee_name_address ?? '' }
                            />
                        </div>
                      </div>
                    ):null}


                    <hr></hr>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">(state where registered)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vehicle_registered_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vehicle_registered_state ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">(please provide owner’s or lessee’s USDOT number,  FMC number or UCR filing)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="registration_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.registration_number ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-12">
                        <label htmlFor="customerName" className="form-label">
                        Purchaser Information:
                        </label>
                      </div>
                    </div>

                    <div className="p-2 col-12">
                      <label htmlFor="customerName" className="form-label mx-3" style={{ fontSize: "14px",}}>
                      The purchaser is a: 
                      </label>
                      <br />
                      <FormControl className="px-3 pt-2">
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="cbset_5"
                          value={ (documentValues.cbset_5 ?? "" )}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                        >
                          <FormControlLabel className="px-3" value="corporation" control={<Radio />} label="Corporation" />
                          <FormControlLabel className="px-3" value="llc" control={<Radio />} label="Limited Liability Company" />
                          <FormControlLabel className="px-3" value="partnership" control={<Radio />} label="Partnership" />
                          <FormControlLabel className="px-3" value="sole_proprietor" control={<Radio />} label="Sole Proprietor" />
                          <FormControlLabel className="px-3" value="" control={<Radio />} label="None" />
                        </RadioGroup>
                      </FormControl>
                    </div>


                    <div className="p-2 col-12">
                      <label htmlFor="customerName" className="form-label mx-3" style={{ fontSize: "14px",}}>
                      Who is a: 
                      </label>
                      <br />
                      <FormControl className="px-3 pt-2">
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="cbset_6"
                          value={ (documentValues.cbset_6 ?? "" )}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                        >
                          <FormControlLabel className="px-3" value="ca_resident" control={<Radio />} label="California resident" />
                          <FormControlLabel className="px-3" value="non_ca_resident" control={<Radio />} label="non-California resident" />
                          <FormControlLabel className="px-3" value="" control={<Radio />} label="None" />
                        </RadioGroup>
                      </FormControl>
                    </div>

                    <div className="p-2 col-12">
                      <label htmlFor="customerName" className="form-label mx-3" style={{ fontSize: "14px",}}>
                      The vehicle or trailer was purchased for use:
                      </label>
                      <br />
                      <FormControl className="px-3 pt-2">
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="cbset_7"
                          value={ (documentValues.cbset_7 ?? "" )}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                        >
                          <FormControlLabel className="px-3" value="usage_outside_ca" control={<Radio />} label="Exclusively outside California (section 6388)" />
                          <FormControlLabel className="px-3" value="usage_outside_ca_with_icc" control={<Radio />} label="Exclusively outside California or exclusively in interstate or foreign commerce, or both (section 6388.5)." />
                          <FormControlLabel className="px-3" value="" control={<Radio />} label="None" />
                        </RadioGroup>
                      </FormControl>
                    </div>

                  </div>
                  <hr></hr>


                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Purchaser Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Title</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="title"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.title ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Phone</label>
                        <input
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          onFocus={removeFormatting}
                          className="form-control rounded-pill mt-1"
                          name="phone"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.phone ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default CASection837SalesTaxAffidavit

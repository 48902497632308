

export const setStats =  (type,data) => {
     return {
         type,
         payload: data
     }
}

export const setFinanceRequests =  (type,data) => {
    return {
        type,
        payload: data
    }
}

export const setPeriod =  (type,data) => {
    return {
        type,
        payload: data
    }
}

export const setRegionCode =  (type,data) => {
    return {
        type,
        payload: data
    }
}

export const setLocationCode =  (type,data) => {
    return {
        type,
        payload: data
    }
}

export const setFinanceRequestsTotalPages =  (type,data) => {
    return {
        type,
        payload: data
    }
}

export const setStartDate =  (type,data) => {
    return {
        type,
        payload: data
    }
}

export const setEndDate =  (type,data) => {
    return {
        type,
        payload: data
    }
}

export const setFilter =  (type,data) => {
    return {
        type,
        payload: data
    }
}

export const setFilterSegment =  (type,data) => {
    return {
        type,
        payload: data
    }
}

export const setSearchState =  (type,data) => {
    return {
        type,
        payload: data
    }
}



import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  useParams
} from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useOrder } from "../../../../../../../redux/orders/order"
import { formatter, removeChars, formatUpperCase } from "../../../../../../../utlis/formatters";
import { getOrderLevelAddresses, updateAddressOptions } from "../../../../../../../utlis/documentHelpers";
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { NevadaBillOfSaleGlobalAutofill, NevadaBillOfSaleAutofillProductDetails, preProcessPayload } from "../DocumentAutofill/NVBillOfSale"

const NVBillOfSale = ({document_id, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "nv-bill-of-sale";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  // App Logic & Data //
  let { id } = useParams();
  // const dispatch = useDispatch();

  // const stateAbbrev = States.map(state => {
  //   return state.abbreviation;
  // });

  const order = useOrder();
  // const customer = useSelector((state) => state?.orderState.customer);
  // const products = useSelector((state) => state?.orderState.products);
  // const tradeins = useSelector((state) => state?.orderState.tradeins);
  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);

  // Data Structure & Init //
  
  var initialDocumentStructure = {
    amount_text: undefined, 
    amount: undefined, 
    buyer_first: undefined, 
    buyer_mi: undefined, 
    buyer_last: undefined, 
    buyer_id: undefined, 
    buyer_address: undefined, 
    buyer_mailing_zip: undefined, 
    buyer_mailing_state: undefined, 
    buyer_mailing_city: undefined, 
    buyer_mailing_street: undefined, 
    buyer_first_2: undefined, 
    buyer_mi_2: undefined, 
    buyer_last_2: undefined, 
    buyer_id_2: undefined, 
    buyer_email: undefined, 
    buyer_phone: undefined, 
    vin: undefined, 
    make: undefined, 
    model: undefined, 
    year: undefined, 
    rebuilt_cb: undefined, 
    lienholder_name: undefined, 
    seller_mi: undefined, 
    seller_last: undefined, 
    bos_and: undefined, 
    buyer_city: undefined, 
    buyer_state: undefined, 
    buyer_state_2: undefined, 
    buyer_city_2: undefined, 
    buyer_street_2: undefined, 
    buyer_zip_2: undefined, 
    buyer_mailing_zip_2: undefined, 
    buyer_address_state_2: undefined, 
    buyer_mailing_city_2: undefined, 
    buyer_mailing_street_2: undefined, 
    seller_id: undefined, 
    lienholder_id: undefined, 
    seller_state: undefined, 
    lienholder_state: undefined, 
    lienholder_zip: undefined, 
    lienholder_city: undefined, 
    lienholder_street: undefined, 
    seller_first: undefined, 
    seller_street: undefined, 
    seller_city: undefined, 
    seller_zip: undefined, 
    bos_or: undefined, 
    buyer_zip: undefined
  }

  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  // X1
  const removeFormatting = e =>{
    const {name,value}=e.target;
    setDocumentValues({...documentValues,[name]:removeChars(value)});
  }

  // X1 Reference Arrays - TODO: Possibly move these to back end as part of the template stucture
  const moneyFields = useMemo(()=>{return["amount"]},[]);
  const phoneFields = useMemo(()=>{return["buyer_phone"]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "model"]},[]);
  const checkboxes = useMemo(()=>{return[
    "rebuilt_cb"
  ]},[]);
  // const radios = [
  //   "and_or_toggle", 
  // ];

  // X1- Current General On Change
  const handleDocumentValuesChange = e =>{
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  
  // const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('NVBillOfSale.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      // setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, loadDocument ])
  const [assignedProduct, setAssignedProduct] = useState(undefined);
  // const [productVal, setproductVal] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  // const [tradeinVal, setTradeinVal] = useState(undefined);
  // const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  // const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);

  const [selectedAddress1, setSelectedAddress1] = useState(undefined);
  const [selectedAddress2, setSelectedAddress2] = useState(undefined);
  const [selectedAddress3, setSelectedAddress3] = useState(undefined);
  const [selectedAddress4, setSelectedAddress4] = useState(undefined);
  const [selectedAddress5, setSelectedAddress5] = useState(undefined);
  const [addressOptions, setAddressOptions] = useState(undefined);
  const [inputAddressVal, setinputAddressVal] = useState(undefined);

  // const [vehicleTypeOptions, setVehicleTypeOptions] = useState(undefined);
  // const [colorOptions, setColorOptions] = useState(undefined);
  // const [ownerTypeOptions, setOwnerTypeOptions] = useState(undefined);
  // const [idTypeOptions, setIdTypeOptions] = useState(undefined);
  // const [phoneTypeOptions, setPhoneTypeOptions] = useState(undefined);
  // const [dealerTypeOptions, setDealerTypeOptions] = useState(undefined);
  // const [weightRatingOptions, setWeightRatingOptions] = useState(undefined);
  // const [fuelTypeOptions, setFuelTypeOptions] = useState(undefined);
  // const [motoStyleOptions, setMotoStyleOptions] = useState(undefined);
  // const [useTypeOptions, setUseTypeOptions] = useState(undefined);

// Dropdowns //colorOptions

// if(order && order.products && order.products.length > 0 && productOptions === undefined){
//   let getProductOptions = [];
//   order.products.map(product => {
//     let str = `${product.stockNumber.toString().toUpperCase()}`;
//     let obj = {id: product.id, name: str}
//     getProductOptions.push(obj);
//   })
//   setproductOptions(getProductOptions);
// }

// if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
//   let getTradeinOptions = [];
//   order.tradeins.map(tradein => {
//     let str = `${tradein.year} ${tradein.make} ${tradein.model.toString().toUpperCase()}`;
//     let obj = {id: tradein.id, name: str}
//     getTradeinOptions.push(obj);
//   })
//   setTradeinOptions(getTradeinOptions);
// }



 // Autofill //

 const GlobalAutofill = useCallback(async () => {
  let newValues = NevadaBillOfSaleGlobalAutofill(documentValues, order);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  // var newValues = documentValues;
  
  // if(order && order.customer && order.customer.email){
  //     newValues.email_address = order.customer.email;
  // }

  // if(order && order.documentationDate && documentValues.delivery_date === undefined){
  //   newValues.delivery_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  // }

  // setDocumentValues(newValues);
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ documentValues, order ])


  // Document BREAD + Preview // TODO: Move to Redux

  // const preProcessPayload = useCallback((payload) => {

    
  //   if(payload.and_or_toggle){
  //     // Reset all
  //     payload.bos_and_cb = "no";
  //     payload.bos_or_cb = "no";
      
  //     switch(payload.and_or_toggle){
  //       case "and":
  //       payload.bos_and_cb = "yes";
  //       break;
  //       case "or":
  //         payload.bos_or_cb = "yes";
  //       break;
        
  //       default:
  //       }
  //     }
      
  //   // X1.2- Field Type Specific Preview Formatting
  //   Object.keys(payload).forEach(key => {
  //     if(moneyFields.indexOf(key) >= 0){
  //         payload[key] = formatMoneyValue(payload[key]);
  //     }else if(phoneFields.indexOf(key) >= 0){
  //         payload[key] = formatPhoneNumber(payload[key]);
  //     }else if(upperCaseFields.indexOf(key) >= 0){
  //         payload[key] = formatUpperCase(payload[key]);
  //     }
  //   });

  //   // Data point formatting
  //   if(payload.amount){
  //     payload.amount = removeChars(payload.amount);
  //   }

  //   setDocumentValues(JSON.parse(JSON.stringify(payload)));
  //   Object.keys(payload).forEach(key => {
  //     if(checkboxes.indexOf(key) >= 0){
  //       payload[key] = payload[key] ? "yes": "no";
  //     }
  //   });    
  //   // Data point formatting
  //   if(payload.changes_date){
  //     payload.changes_date = moment.utc(payload.changes_date).format("MM/DD/YY");
  //   }

  //   if(payload.delivery_date){
  //     payload.delivery_date = moment.utc(payload.delivery_date).format("MM/DD/YY");
  //   }
      
  //   return payload;
  // },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])


  const previewPayloadDocument = useCallback(async (documentValues) => {
    var payload = documentValues;
      Object.keys(payload).forEach(key => {
        if (payload[key] === null || payload[key] === undefined) {
          delete payload[key];
        }
      });
      
      var docCopy = JSON.parse(JSON.stringify(document));
      docCopy.payload =  JSON.parse(JSON.stringify(payload));
      docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
      docCopy.payload =  JSON.stringify(docCopy.payload);
      delete docCopy.documentStatus;
      try {
        let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
        setdocumentUrl(fileURL)
      } catch (err) {
        recordFrontEndError('NVBillOfSale.jsx', 'Error previewing document payload: ' + err)
      };  
    },[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


  const AutofillProductDetails = useCallback(async (selectedProduct) => {
    let newValues = NevadaBillOfSaleAutofillProductDetails(selectedProduct, documentValues);

    if(newValues){
      setDocumentValues(newValues);
      previewPayloadDocument(documentValues);
      setHasUnsavedChanges(true);
      if(document){
        previewPayloadDocument(documentValues);
      }
      setSaveSuccess(false);
    }
    // var newValues = documentValues;
    // newValues.vin = (selectedProduct.vin).toString().toUpperCase();
    // newValues.year = (selectedProduct.year).trim();
    // newValues.make = (selectedProduct.make).trim();
    // newValues.model = (selectedProduct.model).toString().toUpperCase();

    // setDocumentValues(newValues);
    // // setProductToggleAutoFill(false);
    // if(document){
    //   previewPayloadDocument(documentValues);
    // }
    // // setAddressOptions(updateAddressOptions(selectedProduct, order));
    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  },[ document, documentValues, previewPayloadDocument ]);

  useEffect(() => {
    if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){
  
      existingPayload = true;
  
      if(documentPayload.changes_date){
        documentPayload.changes_date = moment.utc(documentPayload.changes_date).format("YYYY-MM-DD");
      }
      
        
      if(documentPayload.delivery_date){
        documentPayload.delivery_date = moment.utc(documentPayload.delivery_date).format("YYYY-MM-DD");
      }
  
      setDocumentValues(documentPayload);

      // Loading Use Effect
      if(document.additionalData){
        const methodMap = { 
          selectedAddress1: setSelectedAddress1, 
          selectedAddress2: setSelectedAddress2, 
          selectedAddress3: setSelectedAddress3, 
          selectedAddress4: setSelectedAddress4, 
          selectedAddress5: setSelectedAddress5, 
        };

        let parsedAdditionalData = JSON.parse(document.additionalData);
        Object.keys(parsedAdditionalData).forEach(function(key) {
          if(methodMap[key]){
            methodMap[key](parsedAdditionalData[key]);
          }
        });
      }


    }
  
    var foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }
    
    setAddressOptions(getOrderLevelAddresses(order));
    if(foundAssignedProduct){
        setAssignedProduct(foundAssignedProduct);
        setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
    }
      if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
      }else{
        // if(document && document.itemIds){
        //   var itemIds = document.itemIds.split(',');
        //     var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
        //     let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
        //     let obj = {id: foundProduct.id, name: str}
        //     setproductVal(obj);
        //     if(foundProduct){
        //       setAddressOptions(updateAddressOptions(foundProduct, order));
        //     }
        //     streamDocument();
        // }
  
      }
      
      setDataLoaded(true);
    }
  },[ documentValues, document, dataLoaded, AutofillProductDetails, GlobalAutofill, order, previewPayloadDocument ])





  
    // function streamDocument(){
    //   axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
    //     .then(response => {
    //       const file = new Blob(
    //         [response.data], 
    //         {type: 'application/pdf'});
    //         const fileURL = URL.createObjectURL(file);
    //         // window.open(fileURL);
    //       setdocumentUrl(fileURL)
    //     }).catch(err => {
    //     })
    // }
  
    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();
  

      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
    

      // const payload = JSON.parse(JSON.stringify(documentValues));
      // var docCopy = document;
      // docCopy.payload = payload;
      // docCopy.payload = preProcessPayload(docCopy.payload);
      // docCopy.payload =  JSON.stringify(docCopy.payload);
      // delete docCopy.documentStatus;
      
      // if(selectedProduct && selectedProduct.id){
      //   docCopy.itemIds = `${selectedProduct.id}`;
      // }
  
      //Save Dropdown Selections
      let dropdownData = {};
      if(selectedAddress1){
        dropdownData.selectedAddress1 = {
          id: selectedAddress1.id,
          name: selectedAddress1.name
        };
      }
      if(selectedAddress2){
        dropdownData.selectedAddress2 = {
          id: selectedAddress2.id,
          name: selectedAddress2.name
        };
      }
      if(selectedAddress3){
        dropdownData.selectedAddress3 = {
          id: selectedAddress3.id,
          name: selectedAddress3.name
        };
      }
      if(selectedAddress4){
        dropdownData.selectedAddress4 = {
          id: selectedAddress4.id,
          name: selectedAddress4.name
        };
      }
      if(selectedAddress5){
        dropdownData.selectedAddress5 = {
          id: selectedAddress5.id,
          name: selectedAddress5.name
        };
      }

      let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, additionalData, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });
      // docCopy.additionalData = JSON.stringify(dropdownData);
        
      // try {
      //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
      //   if (res) {
      //     await axios.post(`/document/${document.id}/generate`) 
      //     getDocuments();
      //     setSaveSuccess(true);
      //     setHasUnsavedChanges(false);
      //   };
      // } catch (err) {
      //   recordFrontEndError('NVBillOfSale.jsx', 'Error saving document: ' + err)
      // };
      
    }

// Component Helpers //
// useEffect(() => {
  //Autofill Product Details After Selection

// },[ selectedProduct, productToggleAutoFill ])

// useEffect(() => {
//   //Autofill Tradein Details After Selection
//   if(selectedTradein !== undefined && tradeinToggleAutoFill){
//     var newValues = documentValues;
//     newValues.trade_year = (selectedTradein.year);
//     newValues.trade_make = (selectedTradein.make);
//     newValues.trade_model = (selectedTradein.model);
//     newValues.trade_body_type = (selectedTradein.seriesBodyType);
//     newValues.trade_serial_number = (selectedTradein.serialNumber);
//     newValues.balance_owner = (order.customer.name); //Need specific field for TRADE IN OWNER?
//     newValues.balance_owner_address = (order.customer.deliveryAddress.addressLine1);
//     newValues.amount = (selectedTradein.balanceOwed);
//     newValues.trade_allowance = (selectedTradein.tradeAllowanceAtTerms);
//     newValues.balance_owed = (selectedTradein.balanceOwed);
//     newValues.cash_refund = (selectedTradein.cashRefundToCustomer);
//     newValues.net_trade_allowance = (selectedTradein.finalTradeAllowance);
//     // newValues.cash_down_payment = (selectedTradein);

//     setDocumentValues(newValues);
//     setTradeinToggleAutoFill(false);
//     if(document){
//       previewPayloadDocument(documentValues);
//     }
//   }
// },[ selectedTradein, tradeinToggleAutoFill ])

//Autofill Address Details After Selection
function AutoFillAddress(addressSelected, selectionInstance){
  var newValues = documentValues;
  if(selectionInstance){
    if(selectionInstance === "new-owner-address"){
      // newValues.buyer_first = addressSelected.label;
      newValues.buyer_address = addressSelected.address;
      newValues.buyer_city = addressSelected.city;
      newValues.buyer_state = addressSelected.state;
      newValues.buyer_zip = addressSelected.zip;
    }
    if(selectionInstance === "new-owner-mailing-address"){
      newValues.buyer_mailing_street = addressSelected.address;
      newValues.buyer_mailing_city = addressSelected.city;
      newValues.buyer_mailing_state = addressSelected.state;
      newValues.buyer_mailing_zip = addressSelected.zip;
    }
    if(selectionInstance === "second-owner-address"){
      // newValues.buyer_first_2 = addressSelected.label;
      newValues.buyer_street_2 = addressSelected.address;
      newValues.buyer_city_2 = addressSelected.city;
      newValues.buyer_state_2 = addressSelected.state;
      newValues.buyer_zip_2 = addressSelected.zip;
    }
    if(selectionInstance === "second-owner-mailing-address"){
      newValues.buyer_mailing_street_2 = addressSelected.address;
      newValues.buyer_mailing_city_2 = addressSelected.city;
      newValues.buyer_mailing_state_2 = addressSelected.state;
      newValues.buyer_mailing_zip_2 = addressSelected.zip;
    }
    if(selectionInstance === "lienholder-address"){
      newValues.lienholder_name = addressSelected.label;
      newValues.lienholder_street = addressSelected.address;
      newValues.lienholder_city = addressSelected.city;
      newValues.lienholder_state = addressSelected.state;
      newValues.lienholder_zip = addressSelected.zip;
    }
  }
  setDocumentValues(newValues);
  if(document){
    previewPayloadDocument(documentValues);
  }
  setHasUnsavedChanges(true);
  setSaveSuccess(false);
}

const onCloseClick = e => {
  e.preventDefault()
  setDataLoaded(false);
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setAssignedProduct(undefined);
  setdocumentUrl("");
  setDocument(undefined);
  setDocumentFocus(undefined);
  setSelectedAddress1(undefined);
  setSelectedAddress2(undefined);
  setSelectedAddress3(undefined);
  setSelectedAddress4(undefined);
  setSelectedAddress5(undefined);
  setAddressOptions(undefined);
  setinputAddressVal(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

  return (
    <>
      <div
        className="modal fade"
        // id="wa-title-app"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                NV - Bill of Sale
              </h5>
              <br />
              { hasClickedClose ? (
                <div className="d-flex">
                    <p className="m-1 pt-1 text-white">Save Changes?</p>
                    <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                    <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                </div>
                ): hasUnsavedChanges ? (
                    <button
                    type="button"
                    className="btn-close bg-white"
                    aria-label="Close"
                    // onClick={() => setdocumentUrl("")}
                    onClick={closeClicked}
                    ></button>
                ):(
                  <button
                    type="button"
                    className="btn-close bg-white"
                    aria-label="Close"
                    data-bs-dismiss="modal"
                    // onClick={() => setdocumentUrl("")}
                    onClick={preCloseClose}
                  ></button>
                )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ fontSize: '12px', overflowY: 'scroll', height: 'inherit'}}>
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill();                    
                      AutofillProductDetails(assignedProduct);
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>

                  { assignedProduct ? (
                      <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                          Product Snapshot
                          <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                          <h5>SN: { assignedProduct.stockNumber }</h5>
                          <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                          <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                          </div>
                      </div>
                    ):(
                      <div className="m-2 p-2 border">
                          <span>No Assigned Product</span>
                      </div>
                  )}

                  <div className="row">
                    {/* <h5>Vehicle Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                      <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                        <RadioGroup   
                          row                         
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="form_purpose_toggle"
                          value={(documentValues.form_purpose_toggle ?? "")}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                        >
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="lease" control={<Radio />} label="Lease" />
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="plate" control={<Radio />} label="Plate Transfer" />
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="title" control={<Radio />} label="Title purposes only" />
                          <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                        </RadioGroup>
                      </FormControl>

                      { documentValues.form_purpose_toggle === "plate" ? (
                        <div className="col-md-6">
                          <label htmlFor="stock">Plate Number</label>
                          <input
                            type="text"
                            className="form-control rounded-pill mt-1"
                            name="plate_number"
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                            value={ documentValues.plate_number ?? '' }
                            />
                        </div>
                      ):null}
                      </div>
                    </div> */}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">That in consideration of</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="amount_text"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.amount_text ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Dollar Amount</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="amount"
                          onFocus={removeFormatting}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.amount ?? '' }
                          />
                      </div>                    
                    </div>
                    <hr></hr>

                    <label htmlFor="stock"><b>New Owner Information</b></label>
                    { addressOptions && addressOptions.length > 0 ? (
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress1 && selectedAddress1.name) ?? null}
                          name="new-owner-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress1(newValue);
                            AutoFillAddress(newValue, "new-owner-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="ADDRESS" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Full Legal Name (First)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_first"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_first ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Middle</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_mi"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_mi ?? '' }
                          />
                      </div> 
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Last</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_last"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_last ?? '' }
                          />
                      </div>               
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">AND / OR</label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup     
                            row                       
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="and_or_toggle"
                            value={(documentValues.and_or_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="and" control={<Radio />} label="AND" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="or" control={<Radio />} label="OR" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="NONE" />
                          </RadioGroup>
                        </FormControl>
                      </div>        
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Nevada Driver’s License, Identification Card Number, Date of Birth, or FEIN for businesses</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_id"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_id ?? '' }
                          />
                      </div>                     
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Physical Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_address ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_city ?? '' }
                          />
                      </div> 
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_state ?? '' }
                          />
                      </div>               
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_zip ?? '' }
                          />
                      </div>          
                    </div>

                    {addressOptions && addressOptions.length > 0 ? (
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress2 && selectedAddress2.name) ?? null}
                          name="new-owner-mailing-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress2(newValue);
                            AutoFillAddress(newValue, "new-owner-mailing-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="MAILING ADDRESS" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>):(
                        <div  className="text-center mx-auto">
                          <h5 style={{ color: "red" }}> No Addresses Found</h5>
                        </div>
                      )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Mailing Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_mailing_street"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_mailing_street ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_mailing_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_mailing_city ?? '' }
                          />
                      </div> 
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_mailing_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_mailing_state ?? '' }
                          />
                      </div>               
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_mailing_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_mailing_zip ?? '' }
                          />
                      </div>          
                    </div>

                    {addressOptions && addressOptions.length > 0 ? (
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress3 && selectedAddress3.name) ?? null}
                          name="new-owner-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress3(newValue);
                            AutoFillAddress(newValue, "second-owner-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="2ND BUYER" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Full Legal Name of 2nd Buyer (First)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_first_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_first_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Middle</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_mi_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_mi_2 ?? '' }
                          />
                      </div> 
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Last</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_last_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_last_2 ?? '' }
                          />
                      </div>                    
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Nevada Driver’s License, Identification Card Number, Date of Birth, or FEIN for businesses</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_id_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_id_2 ?? '' }
                          />
                      </div>                     
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Physical Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_street_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_street_2 ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_city_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_city_2 ?? '' }
                          />
                      </div> 
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_state_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_state_2 ?? '' }
                          />
                      </div>               
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_zip_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_zip_2 ?? '' }
                          />
                      </div>          
                    </div>

                    {addressOptions && addressOptions.length > 0 ? (
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress4 && selectedAddress4.name) ?? null}
                          name="new-owner-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress4(newValue);
                            AutoFillAddress(newValue, "second-owner-mailing-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="2ND BUYER MAILING ADDRESS" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Mailing Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_mailing_street_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_mailing_street_2 ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_mailing_city_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_mailing_city_2 ?? '' }
                          />
                      </div> 
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_mailing_state_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_mailing_state_2 ?? '' }
                          />
                      </div>               
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_mailing_zip_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_mailing_zip_2 ?? '' }
                          />
                      </div>          
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Telephone</label>
                        <input
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          onFocus={removeFormatting}
                          className="form-control rounded-pill mt-1"
                          name="buyer_phone"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_phone ?? '' }
                          />
                      </div> 
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Email</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_email"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_email ?? '' }
                          />
                      </div>                       
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>                    
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year ?? '' }
                          />
                      </div>    
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div> 
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Model</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="model"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.model ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.rebuilt_cb }
                              name="rebuilt_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="Rebuilt" />
                        </FormControl>
                      </div>                 
                    </div>

                    {addressOptions && addressOptions.length > 0 ? (
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress5 && selectedAddress5.name) ?? null}
                          name="new-owner-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress5(newValue);
                            AutoFillAddress(newValue, "lienholder-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="LIENHOLDER" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Lienholder Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lienholder_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lienholder_name ?? '' }
                          />
                      </div>   
                    </div>   

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">NV Driver’s License, Identification Card Number, Date of Birth, NV ELT # or FEIN for businesses</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lienholder_id"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lienholder_id ?? '' }
                          />
                      </div> 
                    </div> 

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Lienholder Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lienholder_street"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lienholder_street ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lienholder_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lienholder_city ?? '' }
                          />
                      </div> 
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lienholder_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lienholder_state ?? '' }
                          />
                      </div>               
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="lienholder_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.lienholder_zip ?? '' }
                          />
                      </div>          
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Seller’s Full Legal Name (First)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_first"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_first ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Middle</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_mi"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_mi ?? '' }
                          />
                      </div> 
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Last</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_last"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_last ?? '' }
                          />
                      </div>                      
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Nevada Driver’s License, Identification Card Number, Date of Birth, or FEIN for businesses</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_id"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_id ?? '' }
                          />
                      </div>                     
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Mailing Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_street"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_street ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_city ?? '' }
                          />
                      </div> 
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_state ?? '' }
                          />
                      </div>               
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="seller_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.seller_zip ?? '' }
                          />
                      </div>          
                    </div>
                    
                <hr></hr>
                <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Date of Signature</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="delivery_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.delivery_date ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6"></div>
                    </div>
                  </div>
                  <hr/>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default NVBillOfSale

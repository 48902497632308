import { configureStore } from '@reduxjs/toolkit'
import { createStore, applyMiddleware } from 'redux';
import { rootReducer } from './rootReducer';
import thunk from "redux-thunk"

const logger = state => next => action => {
  return next(action)
}

export const setupStore = preloadedState => {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  })
}

const store = createStore(
	rootReducer,
	applyMiddleware(thunk, logger)
)


export default store;

import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";
import { autoCountYMM } from './docUtils.js';

export const TECWAWeightSlipTrailersGlobalAutofill = (documentValues, order, property=null, value=null, location) => {

    // setAddressOptions(getOrderLevelAddresses(order));
  
    var newValues = documentValues;

    if(property==='customer.name'){
      newValues.name = value;
      if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          newValues.name += " AND "+cosigner.name;
        }
      }
  }
    if(property==='customer.cosigner[0].name'){
      newValues.name = order.customer.name;
      if(value!==''){
          newValues.name += " AND "+value;
        }
  }
    if(property==='customer.deliveryAddress.addressLine1'){
      newValues.customer_line_2 = value
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        newValues.customer_line_2 += " "+order.customer.deliveryAddress.addressLine2;
      }
  }
    if(property==='customer.deliveryAddress.addressLine2'){
        newValues.customer_line_2 = order.customer.deliveryAddress.addressLine1+" "+value;
  }
    if(property==='customer.deliveryAddress.city'){
      newValues.customer_line_3 = value+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
  }
    if(property==='customer.deliveryAddress.state'){
      newValues.customer_line_3 = order.customer.deliveryAddress.city+", "+value+" "+order.customer.deliveryAddress.zip;
  }
    if(property==='customer.deliveryAddress.zip'){
      newValues.customer_line_3 = order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+value;
  }
    if(property==='documentationDate'){
      newValues.date = moment.utc(value).format("YYYY-MM-DD");
  }
    if(property==='location'){
        newValues.location_address = value.address+" "+value.city+" "+value.state+" "+value.zip;
        newValues.location_phone = value.phone;
        newValues.location_email = value.email;
        if(value.state === "CA"){
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
          // setDocumentValues({...documentValues,['tec_footer_label']:"TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT"});
        }else{
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
          // setDocumentValues({...documentValues,['tec_footer_label']:"TECEQUIPMENT.COM | TEC EQUIPMENT, INC"});
        }
  }

    if(property !== null && value !== null){
      return newValues;
  }

    if(order && order.customer && order.customer.name){
      if(order.customer.type === "entity"){
        newValues.name = order.customer.name;
      }else{
        // if(order.customer.dba){
        //   newValues.name = order.customer.name+" dba "+order.customer.dba;
        // }else{
          newValues.name = order.customer.name;
        // } 
      }
      
      if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          newValues.name += " AND "+cosigner.name;
        }
      }
  
      if(order.customer.deliveryAddress){
        var addressLine = '';
        if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
          addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
        }else{
          addressLine = order.customer.deliveryAddress.addressLine1;
        }
  
        newValues.customer_line_2 = addressLine
        newValues.customer_line_3 = order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
      }
    }
  
      // Get Location Address & Autofill Address
      if(location !== undefined){
        newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
        // setDocumentValues({...documentValues,['location_address']:location.address+" "+location.city+" "+location.state+" "+location.zip});
      }
    
      if(location && location.phone){
        newValues.location_phone = location.phone;
        // setDocumentValues({...documentValues,['location_phone']:location.phone});
      }
      
      if(location && location.email){
        newValues.location_email = location.email;
        // setDocumentValues({...documentValues,['location_email']:location.email});
      }
    
      if(location){
        if(location.state === "CA"){
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
          // setDocumentValues({...documentValues,['tec_footer_label']:"TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT"});
        }else{
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
          // setDocumentValues({...documentValues,['tec_footer_label']:"TECEQUIPMENT.COM | TEC EQUIPMENT, INC"});
        }
      }
  
      if(order && order.documentationDate && documentValues.date === undefined){
        newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
      }
  
    return newValues
}

export const TECWAWeightSlipTrailersAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null, location=null, order=null, autofillProductDetails=true, productCount=0) => {
    var newValues = documentValues;

    if(property === 'year' && value !== null){
      if(autofillProductDetails){
        newValues.ymm = autoCountYMM((value).toString().trim(), (selectedProduct.make).trim(), (selectedProduct.model).toString().toUpperCase());
      }else{
        newValues.ymm = autoCountYMM((value).toString().trim(), (selectedProduct.make).trim(), (selectedProduct.model).toString().toUpperCase(), productCount);
      }
    }

    if(property === 'make' && value !== null){
        if(autofillProductDetails){
          newValues.ymm = autoCountYMM((selectedProduct.year).trim(), (value).toString().trim(), (selectedProduct.model).toString().toUpperCase());
        }else{
          newValues.ymm = autoCountYMM((selectedProduct.year).trim(), (value).toString().trim(), (selectedProduct.model).toString().toUpperCase(), productCount);
        }
    }


    if(property === 'vin' && value !== null){
      newValues.vin = (value).toString().toUpperCase();
    }

    if(property === 'model' && value !== null){
      if(autofillProductDetails){
        newValues.ymm = autoCountYMM((selectedProduct.year).trim(), (selectedProduct.make).toString().trim(), (value).toString().toUpperCase());
      }else{
        newValues.ymm = autoCountYMM((selectedProduct.year).trim(), (selectedProduct.make).toString().trim(), (value).toString().toUpperCase(), productCount);
      }
    }

    if(property !== null && value !== null){
        return newValues;
    }
  
    if(autofillProductDetails){
      newValues.ymm = (selectedProduct.year).trim()+" "+(selectedProduct.make).trim()+" "+(selectedProduct.model).toString().toUpperCase();
      newValues.vin = (selectedProduct.vin).toString().toUpperCase();
    }else{
      newValues.ymm = "("+productCount+") "+(selectedProduct.year).trim()+" "+(selectedProduct.make).trim()+" "+(selectedProduct.model).toString().toUpperCase();
    }
    
    return newValues
}

export const TECWAWeightSlipTrailersAutofillTradeDetails = (selectedTradein, documentValues, property=null, value= null)=>{
    var newValues = documentValues;

    if(property === 'tradein.year' && value !== null){
        newValues.ymm = autoCountYMM((value).toString().trim(), (selectedTradein.make).trim(), (selectedTradein.model).toString().toUpperCase());
    }

    if(property === 'tradein.make' && value !== null){
          newValues.ymm = autoCountYMM((selectedTradein.year).trim(), (value).toString().trim(), (selectedTradein.model).toString().toUpperCase());
    }

    if(property === 'tradein.model' && value !== null){
          newValues.ymm = autoCountYMM((selectedTradein.year).trim(), (selectedTradein.make).trim(), (value).toString().trim());
    }

    if(property === 'tradein.vin' && value !== null){
      newValues.vin = value.trim().toUpperCase();
    }  

    if(property !== null && value !== null){
      return newValues;
    }

    newValues.ymm = (selectedTradein.year).trim()+" "+(selectedTradein.make).trim()+" "+(selectedTradein.model).toString().toUpperCase();
    newValues.vin = (selectedTradein.vin.toUpperCase());
   
    return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  // X1.2- Field Type Specific Preview Formatting
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  // Data point formatting
  if(payload.date){
    payload.date = moment.utc(payload.date).format("MM/DD/YY");
  }

  return payload;
}
import React from "react";
import Button from "@mui/material/Button";
import { useFetch } from "../../../utlis/api";

const DataMigration146 = () => {

    const axios     = useFetch()

    const runMigration = (axios, save=false) => {
        axios.get(`/data-migration/1.4.6/${save}`)
    }

    return (
    <>
        <Button onClick={ () => runMigration(axios, false)}>Run Data Migration 1.4.6</Button>
        <br />
        <br />
        <Button onClick={() => runMigration(axios, true)}>Run & Save Data Migration 1.4.6</Button>
    </>
    );
}


export default DataMigration146;
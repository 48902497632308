import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useFetch,recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { removeChars, formatter, formatPhoneNumber, formatUpperCase } from "../../../../../../../utlis/formatters";
import { getAllNames } from "../../../../../../../utlis/documentHelpers";
import { useOrder } from "../../../../../../../redux/orders/order"
import {  Checkbox, FormGroup } from '@mui/material';
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { NEForm13GlobalAutofill, NEForm13AutofillProductDetails, preProcessPayload } from "../DocumentAutofill/NEForm13"

const NEForm13 = ({document_id, getDocuments, passedDocumentSlug, locations, setDocumentFocus, location}) => {
  const documentSlug = "ne-form-13";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  // App Logic & Data //
  let { id } = useParams();
  const order = useOrder();

  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  const [document, setDocument] = useState(undefined);
  const [previousDocumentId, setPreviousDocumentId] = useState(0);
  const [ nameOptions, setNameOptions ] = useState([]);//Redux

  // Data Structure & Init //
  
  var initialDocumentStructure = {
  }

  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);

  const moneyFields = useMemo(()=>{return[]},[]);
  const phoneFields = useMemo(()=>{return[]},[]);
  const upperCaseFields = useMemo(()=>{return[]},[]);
  const checkboxes = useMemo(()=>{return[
    "section_c_1_tax_exempt_selected",
    "section_c_1_tax_exempt_2_selected"
  ]},[]);
  // const radios = [
  //   "type_toggle",
  //   "group_1_toggle",
  //   "group_2_toggle",
  // ];

  // X1- Current General On Change
  const handleDocumentValuesChange = e =>{
    // e.preventDefault();
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatPhoneNumber(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }

    // if(name === 'odometer'){
    //   setDocumentValues({...documentValues,[name]:removeChars(value)});
    // }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('NEForm13.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);
  

  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");


      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
      setNameOptions(getAllNames(order, null));
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, order, documentLoaded, loadDocument ])

  const [assignedProduct, setAssignedProduct] = useState(undefined);
  // const [selectedAddress1, setSelectedAddress1] = useState(undefined);
  // const [selectedAddress2, setSelectedAddress2] = useState(undefined);
  // const [selectedAddress3, setSelectedAddress3] = useState(undefined);
  // const [selectedAddress4, setSelectedAddress4] = useState(undefined);
  // const [selectedAddress5, setSelectedAddress5] = useState(undefined);
  // const [selectedAddress6, setSelectedAddress6] = useState(undefined);
  // const [addressOptions, setAddressOptions] = useState(undefined);
  // const [inputAddressVal, setinputAddressVal] = useState(undefined);

 // Autofill //

 const GlobalAutofill = useCallback(async () => {

  let newValues = NEForm13GlobalAutofill(documentValues, order, null, null, location);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  // var newValues = documentValues;
  
  // if(order && order.customer){

  //   if(order && order.customer && order.customer.name){

  //     // newValues.phone_number = order.customer.phone;

  //     // if(order.customer.type === "entity"){
  //       newValues.name_1 = order.customer.name;
  //       newValues.legal_name = order.customer.name;
  //       if(order.customer.dba){
  //         newValues.name_1 = order.customer.name+" dba "+order.customer.dba;
  //         newValues.legal_name = order.customer.name+" dba "+order.customer.dba;
  //       }
  //     // }
  
  //     if(order.customer.cosigners && order.customer.cosigners.length > 0){
  //       let cosigner = order.customer.cosigners[0];
  //       if(cosigner){
  //         newValues.name_1 += " and "+cosigner.name;
  //         newValues.legal_name += " and "+cosigner.name;
  //       }
  //     }
  //   }
    
  //   if(order && order.customer && order.customer.deliveryAddress){
  //     newValues.street_address_do_not_use_po_box = order.customer.deliveryAddress.addressLine1;
  //     if(order.customer.deliveryAddress.addressLine1 !== null && order.customer.deliveryAddress.addressLine2 !== null && order.customer.deliveryAddress.addressLine2 !== ''){
  //       newValues.street_address_do_not_use_po_box = newValues.street_address_do_not_use_po_box+" "+order.customer.deliveryAddress.addressLine2;
  //     }
  //     newValues.city_1 = order.customer.deliveryAddress.city;
  //     newValues.state_1 = order.customer.deliveryAddress.state;
  //     newValues.zip_1 = order.customer.deliveryAddress.zip;
  //   }
  // }

  // //   if(order && order.customer && order.customer.mailingAddress){
  // //     newValues.address = order.customer.mailingAddress.addressLine1;
  // //     if(order.customer.mailingAddress.addressLine1 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
  // //       newValues.address = newValues.address+" "+order.customer.mailingAddress.addressLine2;
  // //     }
  // //     newValues.city = order.customer.mailingAddress.city;
  // //     newValues.state = order.customer.mailingAddress.state;
  // //     newValues.zip_code = order.customer.mailingAddress.zip;
  // //   }
  // // }
  
  // if(order && order.documentationDate){
  //   newValues.date_of_sellers_original_purchase = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  //   newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  // }

  // if(location){
  //   if(location.state === "CA"){
  //     newValues.name_2 = "TEC OF CALIFORNIA, INC.";
  //   }else{
  //     newValues.name_2 = "TEC EQUIPMENT, INC.";
  //   }
  //   newValues.street_or_other_mailing_address = location.address;
  //   newValues.city_2 = location.city;
  //   newValues.state_2 = location.state;
  //   newValues.zip_code_2 = location.zip;
  // }

  // setDocumentValues(newValues);
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[documentValues, location, order])

// const preProcessPayload = useCallback((payload) => {

//   if(payload.cert_type_toggle){
//     // Reset all
//     payload.single_selected = "no";
//     payload.blanket_selected = "no";

//     switch(payload.cert_type_toggle){
//       case "single":
//         payload.single_selected = "yes";
//         break;
//       case "blanket":
//         payload.blanket_selected = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.check_toggle){
//     // Reset all
//     payload.purchase_check_tax_exempt_selected = "no";
//     payload.exempt_check_tax_exempt_selected = "no";
//     payload.contractor_check_tax_exempt_selected = "no";

//     switch(payload.check_toggle){
//       case "resale":
//         payload.purchase_check_tax_exempt_selected = "yes";
//         break;
//       case "exempt":
//         payload.exempt_check_tax_exempt_selected = "yes";
//         break;
//       case "contractor":
//         payload.contractor_check_tax_exempt_selected = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.business_toggle){
//     // Reset all
//     payload.wholesaler_selected = "no";
//     payload.retailer_selected = "no";
//     payload.manufacturer_selected = "no";
//     payload.lessor_selected = "no";

//     switch(payload.business_toggle){
//       case "wholesaler":
//         payload.wholesaler_selected = "yes";
//         break;
//       case "retail":
//         payload.retailer_selected = "yes";
//         break;
//       case "manu":
//         payload.manufacturer_selected = "yes";
//         break;
//       case "lessor":
//         payload.lessor_selected = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.tax_paid_by_seller_toggle){
//     // Reset all
//     payload.yes_tax_paid_selected = "no";
//     payload.no_tax_not_paid_selected = "no";

//     switch(payload.tax_paid_by_seller_toggle){
//       case "yes":
//         payload.yes_tax_paid_selected = "yes";
//         break;
//       case "no":
//         payload.no_tax_not_paid_selected = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.item_dep_toggle){
//     // Reset all
//     payload.yes_depreciable_selected = "no";
//     payload.no_not_depreciable_selected = "no";

//     switch(payload.item_dep_toggle){
//       case "yes":
//         payload.yes_depreciable_selected = "yes";
//         break;
//       case "no":
//         payload.no_not_depreciable_selected = "yes";
//         break;
//       default:
//     }
//   }

//   Object.keys(payload).forEach(key => {
//     if(moneyFields.indexOf(key) >= 0){
//         payload[key] = formatMoneyValue(payload[key]);
//     }else if(phoneFields.indexOf(key) >= 0){
//         payload[key] = formatPhoneNumber(payload[key]);
//     }else if(upperCaseFields.indexOf(key) >= 0){
//         payload[key] = formatUpperCase(payload[key]);
//     }
//   });
//   setDocumentValues(JSON.parse(JSON.stringify(payload)));
//   Object.keys(payload).forEach(key => {
//     if(checkboxes.indexOf(key) >= 0){
//       payload[key] = payload[key] ? "yes": "no";
//     }
//   });

//   if(payload.date_of_sellers_original_purchase){
//     payload.date_of_sellers_original_purchase = moment.utc(payload.date_of_sellers_original_purchase).format("MM/DD/YY");
//   }

//   if(payload.date){
//     payload.date = moment.utc(payload.date).format("MM/DD/YY");
//   }
  
//   return payload;
// },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])


const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });
    
    if(!document){
      return null;
    }

      var docCopy = JSON.parse(JSON.stringify(document));
      docCopy.payload =  JSON.parse(JSON.stringify(payload));
      docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
      docCopy.payload =  JSON.stringify(docCopy.payload);
        delete docCopy.documentStatus;
      try{
        let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
        setdocumentUrl(fileURL)
        } catch (err) {
          recordFrontEndError('NEForm13.jsx', 'Error previewing document payload: ' + err)
        };  
    
},[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


    //Autofill Product Details After Selection
    const AutofillProductDetails = useCallback(async (selectedProduct) => {
      let newValues = NEForm13AutofillProductDetails(selectedProduct, documentValues);

      if(newValues){
        setDocumentValues(newValues);
        previewPayloadDocument(documentValues);
        setHasUnsavedChanges(true);
        if(document){
          previewPayloadDocument(documentValues);
        }
        setSaveSuccess(false);
      }

      // var newValues = documentValues;

      // let ymm = (selectedProduct.year)+" "+(selectedProduct.make).toString().toUpperCase()+" "+(selectedProduct.model).toString().toUpperCase();

      // newValues.description_of_property_or_service_purchased = ymm;
      // newValues.description_of_product_sold = ymm;
      // newValues.descripton_of_items_purchased_2 = ymm;
      // newValues.description_of_items_sold = ymm;
      

      // // newValues.year = (selectedProduct.year);
      // // newValues.vin_hin = (selectedProduct.vin).toString().toUpperCase();
      // // newValues.make = (selectedProduct.make).toString().toUpperCase();
      // // newValues.selling_price = selectedProduct.price;
      // // newValues.model = (selectedProduct.model).toString().toUpperCase();
      // // newValues.body_type = (selectedProduct.vehicleType);

      // // if(selectedProduct.fundingDetail && (selectedProduct.fundingDetail.fundingMethod === "tec-financing" || selectedProduct.fundingDetail.fundingMethod === "finance-only")){
      // //   newValues.name = selectedProduct.fundingDetail.finalizedLendingOption.bankName;
      // //   newValues.address_3 = selectedProduct.fundingDetail.finalizedLendingOption.lienAddress;
      // //   newValues.city_3 = selectedProduct.fundingDetail.finalizedLendingOption.lienCity;
      // //   newValues.state_3 = selectedProduct.fundingDetail.finalizedLendingOption.lienState;
      // //   newValues.zip_code_3 = selectedProduct.fundingDetail.finalizedLendingOption.lienZip;   
      // // }else if(selectedProduct.fundingDetail && selectedProduct.fundingDetail.fundingMethod === "cash-with-lien"){
      // //   newValues.name = selectedProduct.fundingDetail.cashLienHolder.bankName;
      // //   newValues.address_3 = selectedProduct.fundingDetail.cashLienHolder.addressLine1+" "+selectedProduct.fundingDetail.cashLienHolder.addressLine2;
      // //   newValues.city_3 = selectedProduct.fundingDetail.cashLienHolder.city;
      // //   newValues.state_3 = selectedProduct.fundingDetail.cashLienHolder.state;
      // //   newValues.zip_code_3 = selectedProduct.fundingDetail.cashLienHolder.zip;
      // // }else if(selectedProduct.shipTo){
      // //   newValues.name = selectedProduct.shipTo.name;
      // //   newValues.address_3 = selectedProduct.shipTo.addressLine1+" "+selectedProduct.shipTo.addressLine2;
      // //   newValues.city_3 = selectedProduct.shipTo.city;
      // //   newValues.state_3 = selectedProduct.shipTo.state;
      // //   newValues.zip_code_3 = selectedProduct.shipTo.zip;
      // // }

      // setDocumentValues(newValues);
      // // setProductToggleAutoFill(false);
      // if(document){
      //   previewPayloadDocument(documentValues);
      // }
      // setHasUnsavedChanges(true);
      // setSaveSuccess(false);
    },[ document, documentValues, previewPayloadDocument ]);

  // Document BREAD + Preview // TODO: Move to Redux

  useEffect(() => {
    if(document && document.payload && !dataLoaded){
      var existingPayload = false;
      var documentPayload = JSON.parse(document.payload);
      if(documentPayload && Object.keys(documentPayload).length > 0){
    
        existingPayload = true;
    
        if(documentPayload.date_of_sellers_original_purchase){
          documentPayload.date_of_sellers_original_purchase = moment.utc(documentPayload.date_of_sellers_original_purchase).format("YYYY-MM-DD");
        }

        if(documentPayload.date){
          documentPayload.date = moment.utc(documentPayload.date).format("YYYY-MM-DD");
        }

    
        
        setDocumentValues(documentPayload);

        // Loading Use Effect
        // if(document.additionalData){
        //   const methodMap = { 
        //     selectedAddress1: setSelectedAddress1, 
        //     selectedAddress2: setSelectedAddress2, 
        //     selectedAddress3: setSelectedAddress3, 
        //     selectedAddress4: setSelectedAddress4, 
        //     selectedAddress5: setSelectedAddress5, 
        //     selectedAddress6: setSelectedAddress6, 
        //   };

        //   let parsedAdditionalData = JSON.parse(document.additionalData);
        //   Object.keys(parsedAdditionalData).forEach(function(key) {
        //     if(methodMap[key]){
        //       methodMap[key](parsedAdditionalData[key]);
        //     }
        //   });
        // }

      }
      var foundAssignedProduct = null;
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
        foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
      }
      
      // setAddressOptions(getOrderLevelAddresses(order));
      if(foundAssignedProduct){
          setAssignedProduct(foundAssignedProduct);
          // setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
          setNameOptions(getAllNames(order, foundAssignedProduct));
      }
      
        
        if(!existingPayload){
          GlobalAutofill();
          previewPayloadDocument(documentValues);
          if(foundAssignedProduct){
            AutofillProductDetails(foundAssignedProduct)
          }
        }else{
          // if(document && document.itemIds){
          //   var itemIds = document.itemIds.split(',');
          //     var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
          //     let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
          //     let obj = {id: foundProduct.id, name: str}
          //     setproductVal(obj);
          //     if(foundProduct){
          //       setAddressOptions(updateAddressOptions(foundProduct, order));
          //     }
          //     streamDocument();
          // }
    
        }
        
      setDataLoaded(true);
    }
  },[ documentValues, document, dataLoaded, order, AutofillProductDetails, GlobalAutofill, previewPayloadDocument ])


  function ReloadAutoFillValues(){  
    var foundAssignedProduct = null;
    if(document && document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }

    GlobalAutofill();
    if(foundAssignedProduct){
      AutofillProductDetails(foundAssignedProduct)
    }
  }


  
    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();

      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
    
  
      // const payload = JSON.parse(JSON.stringify(documentValues));
      // var docCopy = document;
      // docCopy.payload = payload;
      // docCopy.payload = preProcessPayload(docCopy.payload);
      // docCopy.payload =  JSON.stringify(docCopy.payload);
      // delete docCopy.documentStatus;
      
      // if(selectedProduct && selectedProduct.id){
      //   docCopy.itemIds = `${selectedProduct.id}`;
      // }
  
      //Save Dropdown Selections
      // let dropdownData = {};
      // if(selectedAddress1){
      //   dropdownData.selectedAddress1 = {
      //     id: selectedAddress1.id,
      //     name: selectedAddress1.name
      //   };
      // }
      // if(selectedAddress2){
      //   dropdownData.selectedAddress2 = {
      //     id: selectedAddress2.id,
      //     name: selectedAddress2.name
      //   };
      // }
      // if(selectedAddress3){
      //   dropdownData.selectedAddress3 = {
      //     id: selectedAddress3.id,
      //     name: selectedAddress3.name
      //   };
      // }
      // if(selectedAddress4){
      //   dropdownData.selectedAddress4 = {
      //     id: selectedAddress4.id,
      //     name: selectedAddress4.name
      //   };
      // }
      // if(selectedAddress5){
      //   dropdownData.selectedAddress5 = {
      //     id: selectedAddress5.id,
      //     name: selectedAddress5.name
      //   };
      // }
      // if(selectedAddress6){
      //   dropdownData.selectedAddress6 = {
      //     id: selectedAddress6.id,
      //     name: selectedAddress6.name
      //   };
      // }

      // docCopy.additionalData = JSON.stringify(dropdownData);
      // let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, null, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });

      
    }

// Component Helpers //

  // useEffect(() => {

  // },[ selectedProduct, productToggleAutoFill ])

  // useEffect(() => {
  //   //Autofill Tradein Details After Selection
  //   if(selectedLocation !== undefined && locationToggleAutoFill){
  //     var newValues = documentValues;
  //     newValues.seller_address = selectedLocation.address;
  //     newValues.seller_city = selectedLocation.city;
  //     newValues.seller_state = selectedLocation.state;
  //     newValues.seller_zip = selectedLocation.zip;
  //     newValues.seller_phone = formatPhoneNumber(selectedLocation.phone);
  
  //     if(selectedLocation.state === "CA"){
  //       newValues.seller_name_signature = "TEC OF CALIFORNIA, INC";
  //       newValues.copy_seller_name_signature = "TEC OF CALIFORNIA, INC";
  //     }else{
  //       newValues.seller_name_signature = "TEC EQUIPMENT, INC.";
  //       newValues.copy_seller_name_signature = "TEC EQUIPMENT, INC.";
  //     }
  
  //     setDocumentValues(newValues);
  //     setLocationToggleAutoFill(false);
  //     if(document){
  //       previewPayloadDocument(documentValues);
  //     }
  //     setHasUnsavedChanges(true);
  //     setSaveSuccess(false);
  //   }
  // },[ selectedLocation, locationToggleAutoFill ])
  
  //Autofill Address Details After Selection
  // function AutoFillAddress(addressSelected, selectionInstance){
  //   var newValues = documentValues;
  //   if(selectionInstance){
  //     if(selectionInstance === "physical-address"){
  //       newValues.address_line_1 = addressSelected.address;
  //       // newValues.address_line_2 = addressSelected.addressLine2;
  //       newValues.city = addressSelected.city;
  //       newValues.state = addressSelected.state;
  //       newValues.zip = addressSelected.zip;
  //     }

  //     if(selectionInstance === "mailing-address"){
  //       newValues.mailing_address_line_1 = addressSelected.address;
  //       // newValues.mailing_address_line_2 = addressSelected.addressLine2;
  //       newValues.mailing_city = addressSelected.city;
  //       newValues.mailing_state = addressSelected.state;
  //       newValues.mailing_zip = addressSelected.zip;
  //     }

  //     if(selectionInstance === "lessee-address"){
  //       newValues.lessee_address_line_1 = addressSelected.address;
  //       // newValues.lessee_address_line_2 = addressSelected.addressLine2;
  //       newValues.lessee_city = addressSelected.city;
  //       newValues.lessee_state = addressSelected.state;
  //       newValues.lessee_zip = addressSelected.zip;
  //     }

  //     if(selectionInstance === "located-address"){
  //       newValues.trailer_coach_address = addressSelected.address;
  //       newValues.trailer_coach_city = addressSelected.city;
  //       newValues.trailer_coach_state = addressSelected.state;
  //       newValues.trailer_coach_zip = addressSelected.zip;
  //     }

  //     if(selectionInstance === "physical2-address"){
  //       newValues.lien_address_line_1 = addressSelected.address;
  //       // newValues.lien_address_line_2 = addressSelected.addressLine2;
  //       newValues.lien_city = addressSelected.city;
  //       newValues.lien_state = addressSelected.state;
  //       newValues.lien_zip = addressSelected.zip;
  //     }

  //     if(selectionInstance === "mailing2-address"){
  //       newValues.lien_mailing_address_line_1 = addressSelected.address;
  //       // newValues.lien_mailing__address_line_2 = addressSelected.addressLine2;
  //       newValues.lien_mailing_city = addressSelected.city;
  //       newValues.lien_mailing_state = addressSelected.state;
  //       newValues.lien_mailing_zip = addressSelected.zip;
  //     }
  //   }
  //   setDocumentValues(newValues);
  //   if(document){
  //     previewPayloadDocument(documentValues);
  //   }
  //   setHasUnsavedChanges(true);
  //   setSaveSuccess(false);
  // }

  const onCloseClick = e => {
    e.preventDefault()
    setHasUnsavedChanges(false);
    setSaveSuccess(false);
    setdocumentUrl("");
    setDocument(undefined);
    setNameOptions([]);
    setPreviousDocumentId(0);
    setDocumentValues(initialDocumentStructure);
    setDocumentLoaded(false);
    setDataLoaded(false);
    setAssignedProduct(undefined);
    // setSelectedAddress1(undefined);
    // setSelectedAddress2(undefined);
    // setSelectedAddress3(undefined);
    // setSelectedAddress4(undefined);
    // setSelectedAddress5(undefined);
    // setSelectedAddress6(undefined);
    // setAddressOptions(undefined);
    // setinputAddressVal(undefined);
    setDocumentFocus(undefined);
  }
  
  const [hasClickedClose, setHasClickedClose] = useState(false);
  
  const closeClicked = e => {
    if( hasUnsavedChanges ){
        setHasClickedClose(true);
    } else {
        onCloseClick(e);
    }
  }
  
  const preCloseSave = e => {
    handleSubmit(e)
    setHasClickedClose(false);
  }
  
  const preCloseClose = e => {
    setHasClickedClose(false);
    onCloseClick(e)
  }

  return (
    <>
      <div
        className="modal fade"
        // id="ca-statement-of-facts"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                Nebraska Resale or Exempt Sale Certificate - Form 13
              </h5>
              <br />
              { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1 pt-1 text-white">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                  </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}


                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}


                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ fontSize: '12px', overflowY: 'scroll', height: 'inherit'}}>
               
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      ReloadAutoFillValues();
                      // GlobalAutofill();
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>
                  <br />
                  { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Product Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                        </div>
                    </div>
                  ):(
                    <div className="m-2 p-2 border">
                        <span>No Assigned Product</span>
                    </div>
                  )}

                  <div className="row">
                    <h5>Dealer's Report of Sale</h5>
                  </div>

                  <Autocomplete
                      style={{ width: '100%'}}
                      value={ (documentValues.name) ?? null}
                      name="owner_name"
                      onBlur={(e) => updatePreview(e)}
                      onChange={(event, newValue) => {
                        let updatedObject = documentValues;
                        updatedObject.name = newValue.name;
                        setDocumentValues(updatedObject);
                        // setDocumentValues({...documentValues,["owner_name"]:newValue.name});
                      }}
                      // inputValue={inputAddressVal}
                      // onInputChange={(event, newInputValue) => {
                      //   setinputAddressVal(newInputValue);
                      // }}
                      options={nameOptions}
                      sx={{ width: 400 }}
                      renderInput={(params) => <TextField {...params} label="Purchaser Name" />}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                    />

                  <div className="row">
                    <br />
                    <h5>Name and Mailing Address of Purchaser</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.name_1 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Legal Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="legal_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.legal_name ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Street or Other Mailing Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="address_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.address_1 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="city_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.city_1 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}

                          value={ documentValues.state_1 ?? '' }

                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="zip_code_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.zip_code_1 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <br />
                    <h5>Name and Mailing Address of Seller</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="name_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.name_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Street or Other Mailing Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="address_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.address_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="city_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.city_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.state_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="zip_code_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.zip_code_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>


                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                      <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="cert_type_toggle"
                            value={(documentValues.cert_type_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="single" control={<Radio />} label="Single Purchase" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="blanket" control={<Radio />} label="Blanket" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                        { documentValues && documentValues.cert_type_toggle && documentValues.cert_type_toggle === "single" ? (
                          <>
                            <label htmlFor="stock">Invoice/Purchase Order Number</label>
                            <input
                            type="text"
                            className="form-control rounded-pill mt-1"
                            name="invoice_or_po_number"
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                            value={ documentValues.invoice_or_po_number ?? '' }
                            />
                          </>
                        ):null}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="check_toggle"
                            value={(documentValues.check_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="resale" control={<Radio />} label="Purchase for Resale" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="exempt" control={<Radio />} label="Exempt Purchase" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="contractor" control={<Radio />} label="Contractor" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  
                  <div className="row">
                    <h5>Section A - Nebraska Resale Certificate</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                      <label htmlFor="stock">Description of Property or Service Purchased</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="description_of_property_or_service_purchased"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.description_of_property_or_service_purchased ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                      <label htmlFor="stock">I further certify that we are engaged in business as a:</label>
                      <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="business_toggle"
                            value={(documentValues.business_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="wholesaler" control={<Radio />} label="Wholesaler" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="retail" control={<Radio />} label="Retail" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="manu" control={<Radio />} label="Manufacturer" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="lessor" control={<Radio />} label="Lessor" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                      <label htmlFor="stock">Description of Product Sold</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="description_of_product_sold"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.description_of_product_sold ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                      <label htmlFor="stock">Nebraska Sales Tax ID Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="nebraska_sales_tax_permit_no"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.nebraska_sales_tax_permit_no ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                      <label htmlFor="stock">If None, state the reason</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="if_none_state_reason"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.if_none_state_reason ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Foreign State Sales Tax Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="foreign_state_sales_tax_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.foreign_state_sales_tax_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.state ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <h5>Section B - Nebraska Exempt Sale Certificate</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                      <label htmlFor="stock">Exemption Category</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="exemption_category"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.exemption_category ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Description of Property or Service Purchased</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="descripton_of_items_purchased_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.descripton_of_items_purchased_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Intended Use of Property or Service Purchased</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="intended_use_of_items_purchased"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.intended_use_of_items_purchased ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                      <label htmlFor="stock">Nebraska Certificate of Exemption State ID Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="nebraska_exempt_cert_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.nebraska_exempt_cert_number ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Description of Item Sold</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="description_of_items_sold"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.description_of_items_sold ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Date of Seller's Original Purchase</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date_of_sellers_original_purchase"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date_of_sellers_original_purchase ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="tax_paid_by_seller_toggle"
                            value={(documentValues.tax_paid_by_seller_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="no" control={<Radio />} label="No" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div className="p-2 col-md-6">
                      <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="item_dep_toggle"
                            value={(documentValues.item_dep_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="no" control={<Radio />} label="No" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="section_c_1_tax_exempt"
                            checked={(documentValues && documentValues.section_c_1_tax_exempt ? documentValues.section_c_1_tax_exempt : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="1. Purchase of building materials or fixtures" 
                          />
                        </FormGroup>
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Nebraska Sales or Use Tax ID Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="nebraska_sales_or_use_tax_permit_no"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.nebraska_sales_or_use_tax_permit_no ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="section_c_1_tax_exempt_2"
                            checked={(documentValues && documentValues.section_c_1_tax_exempt_2 ? documentValues.section_c_1_tax_exempt_2 : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="2. Purchase made by an Option 2 contractor..." 
                          />
                        </FormGroup>
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Behalf Of</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="exempt_entity"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.exempt_entity ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Option 2 Contractor</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="option_2_contractor"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.option_2_contractor ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Title</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="title"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.title ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date ?? '' }
                          />
                      </div>
                    </div>
                  </div>
          
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default NEForm13

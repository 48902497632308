import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import _ from "lodash"

import { useFetch } from "../../../../../../utlis/api";

import { rdx } from "../../../../../../redux/downpayments"
import { useOrder } from "../../../../../../redux/orders/order"
import { updateChangesArray } from "../../../../../../redux/saveAndCopy"

import DownpaymentModal from "./DownpaymentModal"
import { setShowDownPaymentSpinner } from "../../../../../../redux/spinners/spinners";



const DownpaymentModalContainer = () => {
  const dispatch        = useDispatch();
  const history         = useHistory();
  const { id: orderId } = useParams();
  const axios     = useFetch();

  const order = useOrder();

  const editedDownpayment       = useEditedDownpayment();
  const editedDownpaymentErrors = useEditedDownpaymentErrors();
  const downpaymentId           = useDownpaymentFocusId();
  const formattedAmount         = useFormattedAmount();
  const isEditMode              = useIsEditMode();
  const isOpen                  = useIsOpen();
  const isValidForSubmit        = useIsValidForSubmit();
  // const lastClosedTimestamp     = useLastClosedTimestamp();
  const showSuccessFeedback     = useShowSuccessFeedback();
  const statusInputValue        = useStatusInputValue();
  const statusInputValueError   = useStatusInputValueError();
  const hasUnsavedChanges        = useHasUnsavedChanges();

  const appData = useSelector((state) => state?.appData);
  // const changesArray = useSelector((state) => state?.copyChangesToDocumentsRedux.changesArray);

  function updateDataChangeArray(e, prop=null, value=null){
    if(e !== null){
      e.preventDefault();
      prop = e.target.name;
      value = e.target.value;
    }

    changeDataChangeArray(prop, value);
  }

  const [dataChangeArray, setDataChangeArray]   = useState([]);
  function changeDataChangeArray(prop, value){

    let doNotCopyProperties = [];

    if(dataChangeArray && !doNotCopyProperties.includes(prop) && prop !== undefined  && prop !== null){

      if(dataChangeArray["downpayment."+prop]?.oldValue){

        setDataChangeArray((prev)=>({
          ...prev,
          ["downpayment."+prop]: {
            "newValue": value,
            "oldValue": dataChangeArray["downpayment."+prop].oldValue
          }
        }))
        
      }else{
        const oldValue = editedOrDefaultDownpayment[prop];

        setDataChangeArray((prev)=>({
          ...prev,
          ["downpayment."+prop]: {
            "newValue": value,
            "oldValue": oldValue ? oldValue : '--'
          }
        }))

      }
    }
  }

  useEffect(() => {

    if(appData?.activeModal === 'downpayment'){
      dispatch(updateChangesArray(dataChangeArray));
    }

  }, [ appData, dataChangeArray, dispatch ])


  //Values to apply when not present on the editedDownpayment or the order downpayment...
  const DOWNPAYMENT_EMPTY_DEFAULTS = {
    status: "",
    amount: "",
    paymentMethod: "",
    documentNumber: "",
  }

  //Get the downpayment from the order each time the downpaymentId or order changes
  const downpaymentFromOrder = useMemo(() => {
    if ( _.isNil( downpaymentId ) ) {
      return {}
    }

    const orderDownpayments = _.defaultTo(order.downPayments, [])
    const downpayment =
      _.defaultTo(orderDownpayments.find(d => d.id === downpaymentId ), {})

    return downpayment

  }, [ order, downpaymentId ])

  //Effect to determine whether we're in 'Add' or 'Edit' mode each
  //time the user opens the modal or the order changes
  useEffect(() => {

    if(appData?.activeModal === 'downpayment'){


      if ( _.isEmpty( downpaymentFromOrder ) === false ) {
        dispatch(rdx.setIsEditMode(true))
        dispatch(rdx.setFormattedAmount(downpaymentFromOrder.amount))
      }

    }

  }, [isOpen, downpaymentFromOrder, dispatch, appData?.activeModal])

  const handleSave = downpaymentData => {
    if ( downpaymentData.id ) {
      dispatch(rdx.updateDownpayment(orderId, downpaymentData.id, downpaymentData))
    } else {
      dispatch(rdx.saveNewDownpayment(orderId, downpaymentData))
    }
  }

  //Will show validation errors
  const validateForm = () => {

    const REQUIRED_NON_EMPTY_DOWNPAYMENT_FIELDS = [ 'status', 'amount' ]

    const currentDownpaymentData =
      _.defaults(_.cloneDeep(editedDownpayment), downpaymentFromOrder)

    let isValid = true
    REQUIRED_NON_EMPTY_DOWNPAYMENT_FIELDS.forEach( field => {
      if ( _.isNil( currentDownpaymentData[ field ] ) ) {
        isValid = false
        dispatch(rdx.showEditedDownpaymentError(field))
      }
    })

    dispatch(rdx.setIsValidForSubmit(isValid))

    return isValid
  }

  const onFormSubmit = async e => {

    e.preventDefault();
    dispatch(setShowDownPaymentSpinner(true))
    //Will show validation errors if not already present...
    const isValidForm = validateForm()

    if ( isValidForm ) {

      //Clone the edited downpayment to avoid modifying values from redux
      const editedDownpaymentObj = _.cloneDeep(editedDownpayment)

      /* Choose keys from the editedDownpayment, then from the downpayment on the order, then defaults */
      const downpaymentData =
        _.defaults({}, editedDownpaymentObj, downpaymentFromOrder, DOWNPAYMENT_EMPTY_DEFAULTS)


      delete downpaymentData.paymentMethod;

      handleSave(downpaymentData);
      return;

      // if ( saved ) {
      //   history.push(`/order/${ orderId }`);
      // }
    }

    history.push(`/order/${ orderId }`);
  }

  const onCloseModal = () => {
      dispatch(rdx.setHasUnsavedChanges(false))
      dispatch(rdx.setLastClosedTimestamp())
      dispatch(rdx.setIsOpen(false))
      dispatch(rdx.resetDownpaymentModal())
    // }
  }

  //Helper for showing and removing errors and updating the fields value...
  const DEFAULT_FIELD_VALIDATOR = v => _.isEmpty( v ) === false

  const handleDownpaymentFieldChange = ( field, value, validate=DEFAULT_FIELD_VALIDATOR ) => {
    dispatch(rdx.setEditedDownpayment(field, value))

    const isValidField = validate(value)

    if ( !isValidField && !editedDownpaymentErrors[ field ] ) {
      dispatch(rdx.showEditedDownpaymentError(field))
    } else if ( isValidField && editedDownpaymentErrors[ field ]) {
      dispatch(rdx.hideEditedDownpaymentError(field))
    }
    dispatch(rdx.setShowSuccessFeedback(false))
    dispatch(rdx.setHasUnsavedChanges(true))
  }

  const onAmountChange = ( formattedValue, value ) => {

    dispatch(rdx.setFormattedAmount(formattedValue))

    const validator = value => {
      return value !== '0' && _.isEmpty(value) === false
    }

    handleDownpaymentFieldChange('amount', value, validator);

    updateDataChangeArray(null, 'amount', value);

    dispatch(rdx.setShowSuccessFeedback(false))
  }

  const onStatusChange = ( e, value ) => {
    handleDownpaymentFieldChange('status', value)
    dispatch(rdx.setShowSuccessFeedback(false));
    updateDataChangeArray(null, 'status', value);
  }

  const onStatusInputChange = ( e, newInputValue ) => {
    dispatch(rdx.setStatusInputValue(newInputValue))

    if ( _.isEmpty( newInputValue ) && _.isNil( statusInputValueError ) ) {
      dispatch(rdx.setStatusInputValueError(true))

    } else if ( _.isEmpty( newInputValue ) === false && statusInputValueError ) {
      dispatch(rdx.setStatusInputValueError(null))
    }
    dispatch(rdx.setShowSuccessFeedback(false));
    updateDataChangeArray(e);
  }

  const onPaymentMethodChange = ( e, value ) => {
    handleDownpaymentFieldChange('paymentMethodId', value.id)
    updateDataChangeArray(null, 'paymentMethod', value);
    dispatch(rdx.setShowSuccessFeedback(false));
  }

  const onDocumentNumberChange = e => {
    handleDownpaymentFieldChange('documentNumber', e.target.value)
    dispatch(rdx.setShowSuccessFeedback(false));
    updateDataChangeArray(e);
  }

  const onDateReceivedChange = e => {
    handleDownpaymentFieldChange('dateReceived', e.target.value)
    dispatch(rdx.setShowSuccessFeedback(false));
    updateDataChangeArray(e);
  }

  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {

    if(appData?.activeModal === 'downpayment'){

      if(paymentMethods && paymentMethods.length === 0){
        axios.get('/payment-method').then( res => {
          setPaymentMethods(res.data)
        })
      }

    }

  },[paymentMethods, setPaymentMethods, axios, appData?.activeModal]);

  //Keys will be applied from left to right
  const editedOrDefaultDownpayment =
    _.defaults({}, editedDownpayment, downpaymentFromOrder, DOWNPAYMENT_EMPTY_DEFAULTS)

  return (
    <DownpaymentModal
      editedOrDefaultDownpayment={ editedOrDefaultDownpayment }
      editedDownpaymentErrors={ editedDownpaymentErrors }
      downpaymentId={ downpaymentId }
      formattedAmount={ formattedAmount }
      isEditMode={ isEditMode }
      isOpen={ isOpen }
      onDocumentNumberChange={ onDocumentNumberChange }
      onDateReceivedChange={ onDateReceivedChange }
      onAmountChange={ onAmountChange }
      onCloseModal={ onCloseModal }
      onFormSubmit={ onFormSubmit }
      onPaymentMethodChange={ onPaymentMethodChange }
      onStatusInputChange={ onStatusInputChange }
      onStatusChange={ onStatusChange }
      isValidForSubmit={ isValidForSubmit }
      showSuccessFeedback={ showSuccessFeedback }
      statusInputValue={ statusInputValue }
      statusInputValueError={ statusInputValueError }
      hasUnsavedChanges={ hasUnsavedChanges }
      paymentMethods={ paymentMethods }
    />
  )
}

const {
  useDownpaymentFocusId,
  useEditedDownpayment,
  useFormattedAmount,
  useEditedDownpaymentErrors,
  useIsOpen,
  useIsEditMode,
  useIsValidForSubmit,
  // useLastClosedTimestamp,
  useShowSuccessFeedback,
  // useSelectedDownpaymentId,
  useStatusInputValue,
  useStatusInputValueError,
  useHasUnsavedChanges
} = rdx

export default DownpaymentModalContainer

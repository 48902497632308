import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  useParams
} from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import { useOrder } from "../../../../../../../redux/orders/order"
import { removeChars, formatter, formatPhoneNumber, formatUpperCase } from "../../../../../../../utlis/formatters";
import { getOrderLevelAddresses, updateAddressOptions } from "../../../../../../../utlis/documentHelpers";
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { WAReleaseOfInterestPowerOfAttorneyGlobalAutofill, WAReleaseOfInterestPowerOfAttorneyAutofillProductDetails, WAReleaseOfInterestPowerOfAttorneyAutofillTradeDetails, preProcessPayload } from "../DocumentAutofill/WAReleaseOfInterestPowerOfAttorney"


const WAReleaseOfInterestPowerOfAttorney = ({document_id, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "wa-release-interest-poa";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  // const dispatch = useDispatch();

  // const stateAbbrev = States.map(state => {
  //   return state.abbreviation;
  // });

  const order = useOrder();
  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);

  // Data Structure & Init //
  var initialDocumentStructure = {
    vin: undefined, 
    year: undefined, 
    make: undefined, 
    model: undefined, 
    reset: undefined, 
    license_plate: undefined, 
    holder_name: undefined, 
    holder_name_2: undefined, 
    holder_title_2: undefined, 
    holder_title: undefined, 
    registered_owner_2: undefined, 
    registered_owner: undefined, 
    registered_owner_license_number: undefined, 
    registered_owner_license_number_2: undefined, 
    registered_owner_phone: undefined, 
    registered_owner_phone_2: undefined, 
    poa_name: undefined, 
    poa_id_number: undefined, 
    poa_phone: undefined, 
    poa_phone_2: undefined, 
    poa_id_number_2: undefined, 
    poa_name_2: undefined
  }
  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);

  // X1
  const removeFormatting = e =>{
    const {name,value}=e.target;
    setDocumentValues({...documentValues,[name]:removeChars(value)});
  }

  const moneyFields = useMemo(()=>{return["market_value", "changes_cost", "current_market_value"]},[]);
  const phoneFields = useMemo(()=>{return["registered_owner_phone", "registered_owner_phone_2"]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "window_vin"]},[]);
  const checkboxes = useMemo(()=>{return[
    "unladen_weight_changed", 
    "motive_power_changed", 
    "body_type_changed", 
    "axels_changed",
    "same_person",
    "name_misspelled",
    "changing_name"
  ]},[]);
  // const radios = [
  //   "exempt_reason", 
  //   "smog_exempt", 
  //   "smog_exempt_powered", 
  //   "smog_exempt", 
  //   "smog_exempt_transfer", 
  //   "trans_title"
  // ];

  // X1- Current General On Change
  const handleDocumentValuesChange = e =>{
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  
  const [documentLoaded, setDocumentLoaded] = useState(false);  

const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('WaReleaseOfInterestPowerOfAttorney.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, documentLoaded, loadDocument ])

  const [assignedProduct, setAssignedProduct] = useState(undefined);
  const [assignedTrade, setAssignedTrade] = useState(undefined);

  // const [selectedAddress, setSelectedAddress] = useState(undefined);
  const [addressOptions, setAddressOptions] = useState(undefined);
  const [inputAddressVal, setinputAddressVal] = useState(undefined);

  const [selectedLienHolder, setSelectedLienHolder] = useState(undefined);
  const [selectedRegisteredOwner, setSelectedRegisteredOwner] = useState(undefined);


  
  // const [vehicleTypeSelection, setVehicleTypeSelection] = useState("product");

// Dropdowns //

// if(order && order.products && order.products.length > 0 && productOptions === undefined){
//   let getProductOptions = [];
//   order.products.map(product => {
//     let str = `${product.stockNumber.toString().toUpperCase()}`;
//     let obj = {id: product.id, name: str}
//     getProductOptions.push(obj);
//   })
//   setproductOptions(getProductOptions);
// }

// if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
//   let getTradeinOptions = [];
//   order.tradeins.map(tradein => {
//     let str = `${tradein.year} ${tradein.make} ${tradein.model.toString().toUpperCase()}`;
//     let obj = {id: tradein.id, name: str}
//     getTradeinOptions.push(obj);
//   })
//   setTradeinOptions(getTradeinOptions);
// }

// function getOrderLevelAddresses(order){
//   let getAddressOptions = [];
//   if(order.customer && order.customer.deliveryAddress){
//     var addressLine = '';
//     if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
//       addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
//     }else{
//       addressLine = order.customer.deliveryAddress.addressLine1;
//     }
//     let city = order.customer.deliveryAddress.city;
//     let state = order.customer.deliveryAddress.state;
//     let zip = order.customer.deliveryAddress.zip;

//     let customerName = order.customer.name;
//     if(order.customer.dba && order.customer.dba !== ""){
//       customerName = order.customer.name+" dba "+order.customer.dba
//     }

//     let obj = {slug: "customer-delivery", name: "Customer", label: customerName, address: addressLine, city: city, state: state, zip: zip};
//     getAddressOptions.push(obj);
//   }

//   // if(order.customer && order.customer.mailingAddress){

//   //   if(order.customer.mailingAddress.addressLine2 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
//   //     addressLine = (order.customer.mailingAddress.addressLine1+" "+order.customer.mailingAddress.addressLine2);
//   //   }else{
//   //     addressLine = order.customer.mailingAddress.addressLine1;
//   //   }
//   //   let city = order.customer.mailingAddress.city;
//   //   let state = order.customer.mailingAddress.state;
//   //   let zip = order.customer.mailingAddress.zip;

//   //   let obj = {slug: "customer-mailing", name: "Customer Mailing", address: addressLine, city: city, state: state, zip: zip};
//   //   getAddressOptions.push(obj);
//   // }
//   return getAddressOptions;
// }

 // Autofill //

 const GlobalAutofill = useCallback(async () => {
  let newValues = WAReleaseOfInterestPowerOfAttorneyGlobalAutofill(documentValues, order);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  // var newValues = documentValues;
  
  // if(order && order.customer && order.customer.name){
  //   newValues.registered_owner = order.customer.name;
  //   if(order.customer.dba){
  //     newValues.registered_owner = order.customer.name+" dba "+order.customer.name;
  //   }
  //   newValues.registered_owner_phone = formatPhoneNumber(order.customer.phone);

  // }

  // if(order && order.customer){
  //   let customer = order.customer;
  //   if(customer && customer.cosigners && customer.cosigners.length > 0){
  //     let cosigner = customer.cosigners[0];
  //     if(cosigner && cosigner.name){
  //       newValues.registered_owner_2 = cosigner.name;
  //       newValues.registered_owner_phone_2 = formatPhoneNumber(cosigner.phone);
  //     }
  //   }
  // }
  

  // setDocumentValues(newValues);
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ documentValues, order ])

// const preProcessPayload = useCallback((payload) => {

//   // Data point formatting
//   if(payload.changes_date){
//     payload.changes_date = moment.utc(payload.changes_date).format("MM/DD/YY");
//   }

//   if(payload.vin){
//     payload.copy_vin = payload.vin;
//   }

//   if(payload.year_make){
//     payload.copy_year_make = payload.year_make;
//   }

//   if(payload.license_plate){
//     payload.copy_license_plate = payload.license_plate;
//   }

//   if(payload.exempt_reason){
//     // Reset all
//     payload.family_transfer = "no";
//     payload.addition_deletion_family_member = "no";
//     payload.gift = "no";
//     payload.court_order = "no";
//     payload.inheritance = "no";

//     switch(payload.exempt_reason){
//       case "family_transfer":
//         payload.family_transfer = "yes";
//         break;
//       case "addition_deletion_family_member":
//         payload.addition_deletion_family_member = "yes";
//         break;
//       case "gift":
//         payload.gift = "yes";
//         break;
//       case "court_order":
//         payload.court_order = "yes";
//         break;        
//       case "inheritance":
//         payload.inheritance = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.smog_exempt){
//     // Reset all
//     payload.last_smog_recent = "no";

//     payload.powered = "no";
//       payload.power_electric = "no";
//       payload.power_diesel = "no";
//       payload.power_other = "no";

//     payload.outside_ca_nv_mx = "no";

//     payload.transfer = "no";
//       payload.close_member_transfer = "no";
//       payload.sole_propietorship_transfer = "no";
//       payload.lease_company_transfer = "no";
//       payload.lessor_transfer = "no";
//       payload.lessor_operator_transfer = "no";
//       payload.registered_owner_add_transfer = "no";


//     switch(payload.smog_exempt){
//       case "last_smog_recent":
//         payload.last_smog_recent = "yes";
//         break;
//       case "powered":
//         payload.powered = "yes";

//         if(payload.smog_exempt_powered){
//           switch(payload.smog_exempt_powered){
//             case "power_electric":
//               payload.power_electric = "yes";
//               break;
//             case "power_diesel":
//               payload.power_diesel = "yes";
//               break;
//             case "power_other":
//               payload.power_other = "yes";
//               break;
//             default:
//           }

//           if(payload.smog_exempt_powered !== "power_other"){
//             payload.power_other_text = "";
//           }
//         }

//         break;
//       case "outside_ca_nv_mx":
//         payload.outside_ca_nv_mx = "yes";
//         break;
//       case "transfer":
//         payload.transfer = "yes";

//         if(payload.smog_exempt_powered){
//           switch(payload.smog_exempt_powered){
//             case "close_member_transfer":
//               payload.close_member_transfer = "yes";
//               break;
//             case "sole_propietorship_transfer":
//               payload.sole_propietorship_transfer = "yes";
//               break;
//             case "lease_company_transfer":
//               payload.lease_company_transfer = "yes";
//               break;
//             case "lessor_transfer":
//               payload.lessor_transfer = "yes";
//               break;
//             case "lessor_operator_transfer":
//               payload.lessor_operator_transfer = "yes";
//               break;
//             case "registered_owner_add_transfer":
//               payload.registered_owner_add_transfer = "yes";
//               break;
//           default:
//           }
//         }

//         break;        
//       case "inheritance":
//         payload.inheritance = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.trans_title){
//     // Reset all
//     payload.transfer_only = "no";
//     payload.title_only = "no";


//     switch(payload.trans_title){
//       case "transfer_only":
//         payload.transfer_only = "yes";
//         break;
//       case "title_only":
//         payload.title_only = "yes";
//         break;
//       default:
//     }
//   }

//   // X1.2- Field Type Specific Preview Formatting
//   Object.keys(payload).forEach(key => {
//     if(moneyFields.indexOf(key) >= 0){
//         payload[key] = formatMoneyValue(payload[key]);
//     }else if(phoneFields.indexOf(key) >= 0){
//         payload[key] = formatPhoneNumber(payload[key]);
//     }else if(upperCaseFields.indexOf(key) >= 0){
//         payload[key] = formatUpperCase(payload[key]);
//     }
//   });
//   setDocumentValues(JSON.parse(JSON.stringify(payload)));
//   Object.keys(payload).forEach(key => {
//     if(checkboxes.indexOf(key) >= 0){
//       payload[key] = payload[key] ? "yes": "no";
//     }
//   });

//   return payload;
// },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])


const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });
    
    var docCopy = JSON.parse(JSON.stringify(document));
    docCopy.payload =  JSON.parse(JSON.stringify(payload));
    docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
    docCopy.payload =  JSON.stringify(docCopy.payload);
    delete docCopy.documentStatus;

    try {
      let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
      const file = new Blob(
        [response.data], 
        {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
      setdocumentUrl(fileURL)
    } catch (err) {
      recordFrontEndError('WAReleaseOfInterestPowerOfAttorney.jsx', 'Error previewing document payload: ' + err)
    };  
  },[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])

  // Document BREAD + Preview // TODO: Move to Redux

  const AutofillProductDetails = useCallback((selectedProduct) => {
    let newValues = WAReleaseOfInterestPowerOfAttorneyAutofillProductDetails(selectedProduct, documentValues);

    if(newValues){
      setDocumentValues(newValues);
      setHasUnsavedChanges(true);
      if(document){
        previewPayloadDocument(documentValues);
      }
      setSaveSuccess(false);
    }
  
    // var newValues = documentValues;
    // newValues.vin = (selectedProduct.vin).toString().toUpperCase();
    // newValues.make = selectedProduct.make;
    // newValues.model = (selectedProduct.model).toString().toUpperCase();
    // newValues.year = (selectedProduct.year);



    // setDocumentValues(newValues);
    // // setProductToggleAutoFill(false);
    // if(document){
    //   previewPayloadDocument(documentValues);
    // }
    // // setAddressOptions(updateAddressOptions(selectedProduct, order));
    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  },[ document, documentValues, previewPayloadDocument ])

  const AutofillTradeDetails = useCallback((selectedTradein) => {
    let newValues = WAReleaseOfInterestPowerOfAttorneyAutofillTradeDetails(selectedTradein, documentValues);

    if(newValues){
      setDocumentValues(newValues);
      setHasUnsavedChanges(true);
      if(document){
        previewPayloadDocument(documentValues);
      }
      setSaveSuccess(false);
    }
    // var newValues = documentValues;
    // newValues.vin = (selectedTradein.vin).toString().toUpperCase();
    // newValues.make = selectedTradein.make;
    // newValues.model = (selectedTradein.model).toString().toUpperCase();
    // newValues.year = (selectedTradein.year);
    
    // setDocumentValues(newValues);
    // // setTradeinToggleAutoFill(false);
    // if(document){
    //   previewPayloadDocument(documentValues);
    // }
    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  },[ document, documentValues, previewPayloadDocument ])


  useEffect(() => {
    if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){
  
      existingPayload = true;
  
      if(documentPayload.changes_date){
        documentPayload.changes_date = moment.utc(documentPayload.changes_date).format("YYYY-MM-DD");
      }
      
      setDocumentValues(documentPayload);

      // Loading Use Effect
      if(document.additionalData){
        const methodMap = { 
          selectedLienHolder: setSelectedLienHolder, 
          selectedRegisteredOwner: setSelectedRegisteredOwner, 
        };

        let parsedAdditionalData = JSON.parse(document.additionalData);
        Object.keys(parsedAdditionalData).forEach(function(key) {
          if(methodMap[key]){
            methodMap[key](parsedAdditionalData[key]);
          }
        });
      }

    }

    // if(document.assignedObjectType && document.assignedObjectId){
    //   setVehicleTypeSelection(document.assignedObjectType)
    // }

    var foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }

    if(foundAssignedProduct){
      setAssignedProduct(foundAssignedProduct);
    }

    var foundAssignedTradein = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'tradein'){
      foundAssignedTradein = order.tradeins.find((trade) => trade.id === document.assignedObjectId);
    }


    setAddressOptions(getOrderLevelAddresses(order));
    if(foundAssignedProduct){
        setAssignedProduct(foundAssignedProduct);
        setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
    }

      if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
        if(foundAssignedTradein){
            AutofillTradeDetails(foundAssignedTradein)
        }
      }else{
        // if(document && document.itemIds){
        //   var itemIds = document.itemIds.split(',');
        //   var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
        //   let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
        //   let obj = {id: foundProduct.id, name: str}
        //   setproductVal(obj);
        //   if(foundProduct){
        //     setAddressOptions(updateAddressOptions(foundProduct, order));
        //   }
        //   streamDocument();
        //   setVehicleTypeSelection("product")
        // }

        // Single(First) Document Payload Exists
        // if(document && document.tradeinIds){
        //   var tradeinIds = document.tradeinIds.split(',');
        //   var foundTradein = order.tradeins.find((trade) => trade.id === Number(tradeinIds[0]));
        //   if(foundTradein){
        //     let str = `${foundTradein.year} ${foundTradein.make} ${foundTradein.model.toString().toUpperCase()}`;
        //     let option = {id: foundTradein.id, name: str}
        //     setTradeinVal(option);
        //     streamDocument(foundTradein);
        //     setVehicleTypeSelection("tradein")
        //   }
        // }

      }
      
      setDataLoaded(true);
    }
  },[ documentValues, document, dataLoaded, AutofillProductDetails, AutofillTradeDetails, GlobalAutofill, order, previewPayloadDocument ])



  
    // function streamDocument(){
    //   axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
    //     .then(response => {
    //       const file = new Blob(
    //         [response.data], 
    //         {type: 'application/pdf'});
    //         const fileURL = URL.createObjectURL(file);
    //         // window.open(fileURL);
    //       setdocumentUrl(fileURL)
    //     }).catch(err => {
    //     })
    // }
  
    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();

      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
    

      // const payload = JSON.parse(JSON.stringify(documentValues));
      // var docCopy = document;
      // docCopy.payload = payload;
      // docCopy.payload = preProcessPayload(docCopy.payload);
      // docCopy.payload =  JSON.stringify(docCopy.payload);
      // delete docCopy.documentStatus;
      
      // if(selectedProduct && selectedProduct.id){
      //   docCopy.itemIds = `${selectedProduct.id}`;
      // }

      // if(selectedTradein && selectedTradein.id){
      //   docCopy.tradeinIds = `${selectedTradein.id}`;
      // }
  
      //Save Dropdown Selections
      let dropdownData = {};
      if(selectedLienHolder){
        dropdownData.selectedLienHolder = {
          id: selectedLienHolder.id,
          name: selectedLienHolder.name
        };
      }
      if(selectedRegisteredOwner){
        dropdownData.selectedRegisteredOwner = {
          id: selectedRegisteredOwner.id,
          name: selectedRegisteredOwner.name
        };
      }
      let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, additionalData, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });

      // docCopy.additionalData = JSON.stringify(dropdownData);

      // try {
      //   let res  = await axios.put(`/document/${document.id}/prepare`, docCopy)
      //   if (res) {
      //     await axios.post(`/document/${document.id}/generate`) 
      //     getDocuments();
      //     setSaveSuccess(true);
      //     setHasUnsavedChanges(false);
      //   };
      // } catch (err) {
      //   recordFrontEndError('WAReleaseOfInterestPowerOfAttorney.jsx', 'Error saving document: ' + err)
      // };
      
    }

// Component Helpers //
// useEffect(() => {
  //Autofill Product Details After Selection
  // const AutofillProductDetails = useCallback(async (selectedProduct) => {

// },[ selectedProduct, productToggleAutoFill ])

// useEffect(() => {
  //Autofill Tradein Details After Selection
  // const AutofillTradeDetails = useCallback(async (selectedTradein) => {

// },[ selectedTradein, tradeinToggleAutoFill ])

//Autofill Address Details After Selection
function AutoFillAddress(addressSelected, selectionInstance){
  var newValues = documentValues;
  if(selectionInstance){
    if(selectionInstance === "registered-owner"){
      if(addressSelected.label){
        newValues.registered_owner = addressSelected.label;
        newValues.registered_owner_phone = formatPhoneNumber(addressSelected.phone);

        if(addressSelected.cosigner){
          newValues.registered_owner_2 = addressSelected.cosigner.name;
          newValues.registered_owner_phone_2 = formatPhoneNumber(addressSelected.cosigner.phone);
        }
      }
    }

    if(selectionInstance === "leinholder"){
      if(addressSelected.label){
        newValues.holder_name = addressSelected.label;
      }
    }
  }
  setDocumentValues(newValues);
  if(document){
    previewPayloadDocument(documentValues);
  }
  setHasUnsavedChanges(true);
  setSaveSuccess(false);
}

const onCloseClick = e => {
  e.preventDefault()
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setdocumentUrl("");
  setDocument(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
  setDocumentLoaded(false);
  setDataLoaded(false);
  setAssignedProduct(undefined);
  setAssignedTrade(undefined);
  setAddressOptions(undefined);
  setinputAddressVal(undefined);
  setSelectedLienHolder(undefined);
  setSelectedRegisteredOwner(undefined);
  // setVehicleTypeSelection('product');
  setHasClickedClose(false);
  setDocumentFocus(undefined);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

  return (
    <>
      <div
        className="modal fade"
        // id="wa-release-poa"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                WA - Release of Interest / Power of Attorney
              </h5>
              <br />
              { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1 pt-1 text-white">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                  </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}
                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ fontSize: '12px', overflowY: 'scroll', height: 'inherit'}}>
                 
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill();
                      if(assignedProduct){
                        AutofillProductDetails(assignedProduct);
                      }
                      if(assignedTrade){
                          AutofillTradeDetails(assignedTrade);
                      }                      
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>
                  <br />

                  { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Product Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                        </div>
                    </div>
                  ):null}

                  { assignedTrade ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Trade In Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                          <h5>VIN: { assignedTrade.vin.toUpperCase() }</h5>
                          <h5>YMM: { assignedTrade.year+" "+assignedTrade.make+" "+assignedTrade.model.toUpperCase() }</h5>
                        </div>
                    </div>
                  ):null}


                  <div className="row">
                    <h5>Top Information</h5>

                    {/* <div className="mb-3 col-md-12 ">
                      <label htmlFor="customerName" className="form-label">
                        Vehicle Type
                      </label>
                      <br />
                      <FormControl className="px-3 pt-2">
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={vehicleTypeSelection !== undefined ? vehicleTypeSelection : "product"}
                          onChange={e => {
                            setVehicleTypeSelection(e.target.value)
                          }}
                        >
                          <FormControlLabel className="px-3" value="product" control={<Radio />} label="Product" />
                          <FormControlLabel className="px-3" value="tradein" control={<Radio />} label="Tradein" />
                        </RadioGroup>
                      </FormControl>
                    </div> */}

                    {/* { vehicleTypeSelection === "product" && productOptions && productOptions.length > 0 ? (
                      <div className="d-flex justify-content-evenly">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (productVal && productVal.name) ?? null}
                            onChange={(event, newValue) => {
                              setproductVal(newValue);
                              if(newValue && newValue.id){
                                setselectedProduct(order.products.find((product) => product.id === newValue.id));
                              }
                              setProductToggleAutoFill(true);
                              // resetVariables();
                            }}
                            inputValue={inputProductVal}
                            onInputChange={(event, newInputValue) => {
                              setinputProductVal(newInputValue);
                            }}
                            //id="controllable-states-demo"
                            options={productOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="PRODUCT" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>
                    ):null}

                  { vehicleTypeSelection === "tradein" && tradeinOptions && tradeinOptions.length > 0 ? (
                    <div className="d-flex justify-content-evenly">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (tradeinVal && tradeinVal.name) ?? null}
                          onChange={(event, newValue) => {
                            setTradeinVal(newValue);
                            if(newValue && newValue.id){
                              setselectedTradein(order.tradeins.find((tradein) => tradein.id === newValue.id));
                            }
                            setTradeinToggleAutoFill(true);
                            // resetVariables();
                          }}
                          inputValue={inputTradeinVal}
                          onInputChange={(event, newInputValue) => {
                            setinputTradeinVal(newInputValue);
                          }}
                          //id="controllable-states-demo"
                          options={tradeinOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="TRADE IN" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>
                    ):null} */}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">License/ Registration number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="license_plate"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.license_plate ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-8">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Model Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Model</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="model"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.model ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                    <h5>Section Release of Interest - Lienholder</h5>

                    <div className="row">
                      { addressOptions && addressOptions.length > 0 ? (
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedLienHolder && selectedLienHolder.name) ?? null}
                            name="leinholder"
                            onChange={(event, newValue) => {
                              setSelectedLienHolder(newValue);
                              AutoFillAddress(newValue, "leinholder");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Select Leinholder" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>):(
                    <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Addresses Found</h5>
                    </div>
                    )}
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Leinholder Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="holder_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.holder_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Title of Buisness</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="holder_title"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.holder_title ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">2 - Leinholder Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="holder_name_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.holder_name_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">2 - Title of Buisness</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="holder_title_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.holder_title_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                    <h5>Section Release of Interest - Registered owner</h5>

                    <div className="row">                      
                    { addressOptions && addressOptions.length > 0 ? (
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedRegisteredOwner && selectedRegisteredOwner.name) ?? null}
                            name="registered-owner"
                            onChange={(event, newValue) => {
                              setSelectedRegisteredOwner(newValue);
                              AutoFillAddress(newValue, "registered-owner");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Select Registered Owner" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>):(
                    <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Products Found</h5>
                    </div>
                   )}
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Registered Owner Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="registered_owner"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.registered_owner ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Drivers License or ID card number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="registered_owner_license_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.registered_owner_license_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Phone Number</label>
                        <input
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          onFocus={removeFormatting}
                          className="form-control rounded-pill mt-1"
                          name="registered_owner_phone"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.registered_owner_phone ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">2 - Registered Owner Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="registered_owner_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.registered_owner_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">2 - Drivers License or ID card number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="registered_owner_license_number_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.registered_owner_license_number_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">2 - Phone Number</label>
                        <input
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          onFocus={removeFormatting}
                          className="form-control rounded-pill mt-1"
                          name="registered_owner_phone_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.registered_owner_phone_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
            { saveSuccess ? (
              <span
                className="btn btn-success mx-auto"
              >
                Saved Document
              </span>
            ):(
              <button
              type="button"
              className="btn btn-primary mx-auto"
              // data-bs-dismiss="modal"
              onClick={handleSubmit}
              >
              Save
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default WAReleaseOfInterestPowerOfAttorney

import * as api from '../../../../../../../utlis/api.js'
import { recordFrontEndError } from "../../../../../../../utlis/api"

export const DocumentHandleSubmit = async (document, documentValues, additionalData, _callback) => {


    var saveDocument = new Promise((resolve) => {

        
        let payload = JSON.parse(JSON.stringify(documentValues));
        var docCopy = document;

        if(payload === null || payload === undefined){
            resolve();
        }

        if(typeof yourVariable !== 'object'){
            payload = Object.entries(payload);
        }

        let filteredPayload = {};
        
        payload.forEach( (field, index) => {
            if(field[1] !== null){
                filteredPayload[field[0]] = field[1];
            }
        })

        docCopy.payload =  JSON.stringify(filteredPayload);
        delete docCopy.documentStatus;

        if(additionalData !== null){
            docCopy.additionalData = additionalData;
        }


        try {
          api.prepareDocument(document.id, docCopy).then( res => {
              api.generateDocument(document.id).then( () => {
                  resolve();
              }).catch( () => {
                resolve();
              });
          }).catch( () => {
            resolve();
          });
        }catch (err) {
          recordFrontEndError('DocumentHandleSubmit.js', 'Error saving document: ' + err);
          resolve();
        };

    });

    saveDocument.then( () => {
        _callback();
    });
    
  }
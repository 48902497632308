import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  useParams
} from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { formatter, removeChars, formatUpperCase } from "../../../../../../../utlis/formatters";
import { getOrderLevelAddresses, updateAddressOptions } from "../../../../../../../utlis/documentHelpers";
import { useOrder } from "../../../../../../../redux/orders/order";
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { AZ5000GlobalAutofill, AZ5000AutofillProductDetails, preProcessPayload } from "../DocumentAutofill/AZ5000AF"

const AZ5000 = ({document_id, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "az-5000";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  // const dispatch = useDispatch();

  // const stateAbbrev = States.map(state => {
  //   return state.abbreviation;
  // });

  const order = useOrder();
  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);

  // Data Structure & Init //
  
  var initialDocumentStructure = {
    vendors_name: undefined,
    address: undefined, 
    city: undefined, 
    state: undefined, 
    zip: undefined, 
    single_trans_cert_cb: undefined, 
    period_from_cb: undefined, 
    period_from: undefined, 
    period_through: undefined, 
    trans_with_business_cb: undefined, 
    transaction_with_native_cb: undefined, 
    ssn_ein: undefined, 
    other_tax_number: undefined, 
    no_license_reason: undefined, 
    trans_with_us_gov: undefined, 
    trans_with_foreign: undefined, 
    name_of_tribe: undefined, 
    tribal_license_number: undefined, 
    tribal_number: undefined, 
    percise_nature_business: undefined, 
    reason_for_exemption_1_cb: undefined, 
    reason_for_exemption_2_cb: undefined, 
    reason_for_exemption_3_cb: undefined, 
    reason_for_exemption_4_cb: undefined, 
    reason_for_exemption_5_cb: undefined, 
    reason_for_exemption_5_air_cert_cb: undefined, 
    reason_for_exemption_5_air_permit_cb: undefined, 
    reason_for_exemption_5_foreign_cb: undefined, 
    reason_for_exemption_6_cb: undefined, 
    reason_for_exemption_6_manu_cb: undefined, 
    reason_for_exemption_6_job_printing_cb: undefined, 
    reason_for_exemption_6_refine_cb: undefined, 
    reason_for_exemption_6_extract_ore_cb: undefined, 
    reason_for_exemption_6_extract_oil_cb: undefined, 
    reason_for_exemption_7_cb: undefined, 
    reason_for_exemption_9_cb: undefined, 
    reason_for_exemption_11_cb: undefined, 
    reason_for_exemption_12_cb: undefined, 
    reason_for_exemption_13_cb: undefined, 
    reason_for_exemption_14_cb: undefined, 
    reason_for_exemption_15_cb: undefined, 
    reason_for_exemption_14_deduction: undefined, 
    reason_for_exemption_15_deduction: undefined, 
    reason_for_exemption_14_desc: undefined, 
    reason_for_exemption_15_desc: undefined, 
    section_e_desc: undefined, 
    date: undefined, 
    title: undefined, 
    name: undefined, 
    tpt_number: undefined
  }

  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  // X1
  // const removeFormatting = e =>{
  //   const {name,value}=e.target;
  //   setDocumentValues({...documentValues,[name]:removeChars(value)});
  // }

  // X1 Reference Arrays - TODO: Possibly move these to back end as part of the template stucture
  const moneyFields = useMemo(()=>{return[""]},[]);
  const phoneFields = useMemo(()=>{return[""]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "model"]},[]);
  const checkboxes = useMemo(()=>{return[
    "reason_for_exemption_1_cb", 
    "reason_for_exemption_2_cb", 
    "reason_for_exemption_3_cb", 
    "reason_for_exemption_4_cb", 
    "reason_for_exemption_5_cb", 
    "reason_for_exemption_5_air_cert_cb", 
    "reason_for_exemption_5_air_permit_cb", 
    "reason_for_exemption_5_foreign_cb", 
    'reason_for_exemption_6_cb', 
    "reason_for_exemption_6_manu_cb", 
    "reason_for_exemption_6_job_printing_cb", 
    "reason_for_exemption_6_refine_cb", 
    "reason_for_exemption_6_extract_ore_cb", 
    "reason_for_exemption_6_extract_oil_cb", 
    "reason_for_exemption_7_cb", 
    "reason_for_exemption_9_cb", 
    "reason_for_exemption_11_cb", 
    "reason_for_exemption_12_cb", 
    "reason_for_exemption_13_cb", 
    "reason_for_exemption_14_cb", 
    'reason_for_exemption_15_cb', 
    "single_trans_cert_cb", 
    "period_from_cb", 
    "trans_with_business_cb", 
    "transaction_with_native_cb", 
  ]},[]);
  // const radios = [
  //   "", 
  // ];

  // X1- Current General On Change
  const handleDocumentValuesChange = e =>{
    // e.preventDefault();
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  
  const [documentLoaded, setDocumentLoaded] = useState(false);  
  
  const [dataLoaded, setDataLoaded] = useState(false);
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('AZ5000.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])

  useEffect(() => {


    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, documentLoaded, axios, id, loadDocument ])

  const [assignedProduct, setAssignedProduct] = useState(undefined);
  // const [productVal, setproductVal] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  const [selectedAddress1, setSelectedAddress1] = useState(undefined);
  const [selectedAddress2, setSelectedAddress2] = useState(undefined);
  // const [selectedAddress3, setSelectedAddress3] = useState(undefined);
  // const [selectedAddress4, setSelectedAddress4] = useState(undefined);
  const [addressOptions, setAddressOptions] = useState(undefined);
  const [inputAddressVal, setinputAddressVal] = useState(undefined);

  // const [locationVal, setLocationVal] = useState(undefined);
  // const [selectedLocation, setselectedLocation] = useState(undefined);
  // const [inputLocationVal, setinputLocationVal] = useState(undefined);
  const [locationOptions, setLocationOptions] = useState(undefined);
  // const [locationToggleAutoFill, setLocationToggleAutoFill] = useState(false);

// Dropdowns //colorOptions

// if(order && order.products && order.products.length > 0 && productOptions === undefined){
//   let getProductOptions = [];
//   order.products.map(product => {
//     let str = `${product.stockNumber.toString().toUpperCase()}`;
//     let obj = {id: product.id, name: str}
//     getProductOptions.push(obj);
//     return product;
//   })
//   setproductOptions(getProductOptions);
// }


if(locations && locations.length > 0 && locationOptions === undefined){
  let getLocationOptions = [];
  locations.map(location => {
    let str = `${location.description}`;
    let obj = {locationCode: location.locationCode, description: str}
    getLocationOptions.push(obj);
    return location;
  })
  setLocationOptions(getLocationOptions);
}

 // Autofill //

const GlobalAutofill = useCallback(async () => {
  let newValues = AZ5000GlobalAutofill(documentValues, order);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

},[ documentValues, order ])

const previewPayloadDocument = useCallback(async (documentValues) => {

  if(document){

    var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });
    
    var docCopy = JSON.parse(JSON.stringify(document));
    docCopy.payload =  JSON.parse(JSON.stringify(payload));
    docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
    docCopy.payload =  JSON.stringify(docCopy.payload);
    delete docCopy.documentStatus;
    
    try {
      let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
      const file = new Blob(
        [response.data], 
        {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        setdocumentUrl(fileURL)
      } catch (err) {
        recordFrontEndError('AZ5000.jsx', 'Error previewing document payload: ' + err)
      }
  }
      
},[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])

const AutofillProductDetails = useCallback(async (selectedProduct) => {
  if(selectedProduct){

    let newValues = AZ5000AutofillProductDetails(selectedProduct, documentValues);
    
    if(newValues){
      setDocumentValues(newValues);
      previewPayloadDocument(documentValues);
      setHasUnsavedChanges(true);
      if(document){
        previewPayloadDocument(documentValues);
      }
      setSaveSuccess(false);
    }
    
  }
  // var newValues = documentValues;
  // newValues.vin = (selectedProduct.vin).toString().toUpperCase();
  // newValues.year = (selectedProduct.year).trim();
  // newValues.make = (selectedProduct.make).trim();
  // newValues.model = (selectedProduct.model).toString().toUpperCase();

  // newValues.section_e_desc = selectedProduct.year+" "+selectedProduct.make+" "+(selectedProduct.model).toString().toUpperCase()+" VIN "+(selectedProduct.vin).toString().toUpperCase();

  // newValues.body_type = selectedProduct.vehicleType;

  // if(selectedProduct.shipTo){
  //   newValues.name = selectedProduct.shipTo.name;
  //   newValues.address = selectedProduct.shipTo.addressLine1;
  //   if(selectedProduct.shipTo.addressLine2){
  //     newValues.address = newValues.address+" "+selectedProduct.shipTo.addressLine2;
  //   }
  //   newValues.city = selectedProduct.shipTo.city;
  //   newValues.state = selectedProduct.shipTo.state;
  //   newValues.zip = selectedProduct.shipTo.zip;
  // }


  // if(selectedProduct.soldTo){
  //   newValues.vendors_name = selectedProduct.soldTo.name;
  // }

  // setDocumentValues(newValues);
  // // setProductToggleAutoFill(false);
  // if(document){
  //   previewPayloadDocument(documentValues);
  // }
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[document, documentValues, previewPayloadDocument]);

function getAssignedProduct(){
  var foundAssignedProduct = null;
  if(document && document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
    foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
  }

  // setAddressOptions(getOrderLevelAddresses(order));
  if(foundAssignedProduct){
    setAssignedProduct(foundAssignedProduct);
    // setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
    return foundAssignedProduct;
  }
  return null;
}

  // Document BREAD + Preview // TODO: Move to Redux
  useEffect(() => {
    if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){
  
      existingPayload = true;
  
      if(documentPayload.date){
        documentPayload.date = moment.utc(documentPayload.date).format("YYYY-MM-DD");
      }

      if(documentPayload.period_from){
        documentPayload.period_from = moment.utc(documentPayload.period_from).format("YYYY-MM-DD");
      }

      if(documentPayload.period_through){
        documentPayload.period_through = moment.utc(documentPayload.period_through).format("YYYY-MM-DD");
      }
      
      // Loading Use Effect
      if(document.additionalData){
        const methodMap = { 
          selectedAddress1: setSelectedAddress1, 
          selectedAddress2: setSelectedAddress2 
        };

        let parsedAdditionalData = JSON.parse(document.additionalData);
        Object.keys(parsedAdditionalData).forEach(function(key) {
          if(methodMap[key]){
            methodMap[key](parsedAdditionalData[key]);
          }
        });
      }
  
      setDocumentValues(documentPayload);
    }

    var foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }

    setAddressOptions(getOrderLevelAddresses(order));
    if(foundAssignedProduct){
      setAssignedProduct(foundAssignedProduct);
      setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
    }
  
      if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct);
        }
      }
      
      setDataLoaded(true);
    }
  },[documentValues, document, dataLoaded, order, previewPayloadDocument, GlobalAutofill, AutofillProductDetails])


  

  
    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();

      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
  
      //Save Dropdown Selections
      let dropdownData = {};
      if(selectedAddress1){
        dropdownData.selectedAddress1 = {
          id: selectedAddress1.id,
          name: selectedAddress1.name
        };
      }
      if(selectedAddress2){
        dropdownData.selectedAddress2 = {
          id: selectedAddress2.id,
          name: selectedAddress2.name
        };
      }
      let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, additionalData, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });
      
    }


//Autofill Address Details After Selection
function AutoFillAddress(addressSelected, selectionInstance){
  var newValues = documentValues;
  if(selectionInstance){
    if(selectionInstance === "purchaser-address"){
      newValues.address = addressSelected.address;
      newValues.city = addressSelected.city;
      newValues.state = addressSelected.state;
      newValues.zip = addressSelected.zip;
      newValues.name = addressSelected.label;
    }

    if(selectionInstance === "vendor-address"){
      newValues.vendors_name = addressSelected.label;
    }

  }
  setDocumentValues(newValues);
  if(document){
    previewPayloadDocument(documentValues);
  }
  setHasUnsavedChanges(true);
  setSaveSuccess(false);
}

const onCloseClick = e => {
  e.preventDefault()
  setDataLoaded(false);
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setAssignedProduct(undefined);
  setdocumentUrl("");
  setDocument(undefined);
  setDocumentFocus(undefined);
  setSelectedAddress1(undefined);
  setSelectedAddress2(undefined);
  setAddressOptions(undefined);
  setinputAddressVal(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
  setLocationOptions(undefined);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}


  return (
    <>
      <div
        className="modal fade"
        // id="wa-title-app"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                AZ - 5000 Transaction Privilege Tax Exemption Certificate
              </h5>
              <br />
              { hasClickedClose ? (
                <div className="d-flex">
                    <p className="m-1 pt-1 text-white">Save Changes?</p>
                    <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                    <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                </div>
                ): hasUnsavedChanges ? (
                    <button
                    type="button"
                    className="btn-close bg-white"
                    aria-label="Close"
                    // onClick={() => setdocumentUrl("")}
                    onClick={closeClicked}
                    ></button>
                ):(
                  <button
                    type="button"
                    className="btn-close bg-white"
                    aria-label="Close"
                    data-bs-dismiss="modal"
                    // onClick={() => setdocumentUrl("")}
                    onClick={preCloseClose}
                  ></button>
                )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ fontSize: '12px', overflowY: 'scroll', height: 'inherit'}}>
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill();
                      AutofillProductDetails(getAssignedProduct());
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>
                  <br />

                  { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                      Product Snapshot
                      <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                      </div>
                    </div>
                  ):(
                      <div className="m-2 p-2 border">
                          <span>No Assigned Product</span>
                      </div>
                  )}

                  <div className="row">


                  { addressOptions && addressOptions.length > 0 ? (
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress1 && selectedAddress1.name) ?? null}
                          name="purchaser-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress1(newValue);
                            AutoFillAddress(newValue, "purchaser-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Purchaser Address" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>
                    ):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}
                    <label htmlFor="stock"><b>A. Purchaser’s Name and Address:</b></label>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.name ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.address ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.zip ?? '' }
                          />
                      </div>
                    </div>

                    { addressOptions && addressOptions.length > 0 ? (
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress2 && selectedAddress2.name) ?? null}
                          name="vendor-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress2(newValue);
                            AutoFillAddress(newValue, "vendor-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Vendor" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>
                    ):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Vendors Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vendors_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vendors_name ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>
                    
                    <label htmlFor="stock"><b>B. Check Applicable Box:</b></label>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.single_trans_cert_cb }
                              name="single_trans_cert_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="Single Transaction Certificate" />
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.period_from_cb }
                              name="period_from_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="Period From" />
                        </FormControl>
                      </div>
                    </div>
                
                  { documentValues.period_from_cb ? (
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">From</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="period_from"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.period_from ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Through</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="period_through"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.period_through ?? '' }
                          />
                      </div>
                    </div>
                  ):null}
                  <hr></hr>

                  <label htmlFor="stock"><b>C. Choose one transaction type per Certificate:</b></label>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.trans_with_business_cb }
                              name="trans_with_business_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="Transactions with a Business" />
                        </FormControl>
                      </div>
                      <div className="p-2 col-md-8">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.transaction_with_native_cb }
                              name="transaction_with_native_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="Transactions with Native Americans & Native American Businesses  (See reason #12.)" />
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">AZ Transaction Privilege Tax(TPT) License #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="tpt_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.tpt_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Tribal Business License Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="tribal_license_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.tribal_license_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Tribal Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="tribal_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.tribal_number ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">SSN / EIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="ssn_ein"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.ssn_ein ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Name of Tribe</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="name_of_tribe"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.name_of_tribe ?? '' }
                          />
                      </div>
                    </div>

                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Other Tax License Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="other_tax_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.other_tax_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.trans_with_us_gov_cb }
                              name="trans_with_us_gov_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="Transactions with a U.S. Government entity (See reasons #9 and #10.)" />
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">If no license, provide reason:</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="no_license_reason"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.no_license_reason ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.trans_with_foreign_cb }
                              name="trans_with_foreign_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="Transaction with a Foreign Diplomat  (See reason #13.)" />
                        </FormControl>
                      </div>
                    </div>

                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Precise Nature of Purchaser’s Business.</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="percise_nature_business"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.percise_nature_business ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    <label htmlFor="stock"><b>D. Reason for Exemption:</b></label>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.reason_for_exemption_1_cb }
                              name="reason_for_exemption_1_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="1. Tangible personal property to be leased or rented in the ordinary course of the purchaser's licensed business." />
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.reason_for_exemption_2_cb }
                              name="reason_for_exemption_2_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="2. Tangible personal property to be incorporated into a taxable contracting project, or a maintenance, repair, replacement or alteration project." />
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.reason_for_exemption_3_cb }
                              name="reason_for_exemption_3_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="3. Food, drink, or condiments purchased by a restaurant business." />
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.reason_for_exemption_4_cb }
                              name="reason_for_exemption_4_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="4. Pipes or valves four inches in diameter or greater to be used for transportation of oil, natural gas, artificial gas, water or coal slurry." />
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.reason_for_exemption_5_cb }
                              name="reason_for_exemption_5_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="5. Aircraft, navigational and communication instruments and related accessories sold or leased to:" />
                        </FormControl>
                      </div>
                    </div>

                      { documentValues.reason_for_exemption_5_cb ? (
                        <div>
                          <div className="d-flex justify-content-evenly">
                          <div className="p-2 col-md-1 pl-4"></div>
                            <div className="p-2 col-md-11 pl-4">
                              <FormControl className="px-3 pt-2">
                                <FormControlLabel 
                                  className="px-3" 
                                  control={<input type="checkbox"
                                    checked={ documentValues.reason_for_exemption_5_air_cert_cb }
                                    name="reason_for_exemption_5_air_cert_cb"
                                    onChange={handleDocumentValuesChange}
                                    onBlur={(e) => updatePreview(e)}
                                    className="m-2"
                                    />}
                                  label="Airlines holding a federal certificate of public convenience and necessity; or" />
                              </FormControl>
                            </div>
                          </div>

                          <div className="d-flex justify-content-evenly">
                          <div className="p-2 col-md-1 pl-4"></div>
                            <div className="p-2 col-md-11 pl-4">
                              <FormControl className="px-3 pt-2">
                                <FormControlLabel 
                                  className="px-3" 
                                  control={<input type="checkbox"
                                    checked={ documentValues.reason_for_exemption_5_foreign_cb }
                                    name="reason_for_exemption_5_foreign_cb"
                                    onChange={handleDocumentValuesChange}
                                    onBlur={(e) => updatePreview(e)}
                                    className="m-2"
                                    />}
                                  label="Any foreign government or nonresidents of Arizona who will not use such property in Arizona other than in removing such property from this state." />
                              </FormControl>
                            </div>
                          </div>

                          <div className="d-flex justify-content-evenly">
                          <div className="p-2 col-md-1 pl-4"></div>
                            <div className="p-2 col-md-11 pl-4">
                              <FormControl className="px-3 pt-2">
                                <FormControlLabel 
                                  className="px-3" 
                                  control={<input type="checkbox"
                                    checked={ documentValues.reason_for_exemption_5_air_permit_cb }
                                    name="reason_for_exemption_5_air_permit_cb"
                                    onChange={handleDocumentValuesChange}
                                    onBlur={(e) => updatePreview(e)}
                                    className="m-2"
                                    />}
                                  label="Airlines holding a foreign air carrier permit for air transportation; or" />
                              </FormControl>
                            </div>
                          </div>
                        </div>

                      ):null}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.reason_for_exemption_6_cb }
                              name="reason_for_exemption_6_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="6. Machinery or equipment used directly in the following business activities:" />
                        </FormControl>
                      </div>
                    </div>

                    { documentValues.reason_for_exemption_6_cb ? (
                        <div>
                          <div className="d-flex justify-content-evenly">
                          <div className="p-2 col-md-1 pl-4"></div>
                            <div className="p-2 col-md-11 pl-4">
                              <FormControl className="px-3 pt-2">
                                <FormControlLabel 
                                  className="px-3" 
                                  control={<input type="checkbox"
                                    checked={ documentValues.reason_for_exemption_6_manu_cb }
                                    name="reason_for_exemption_6_manu_cb"
                                    onChange={handleDocumentValuesChange}
                                    onBlur={(e) => updatePreview(e)}
                                    className="m-2"
                                    />}
                                  label="Manufacturing, processing or fabricating." />
                              </FormControl>
                            </div>
                          </div>

                          <div className="d-flex justify-content-evenly">
                            <div className="p-2 col-md-1 pl-4"></div>
                            <div className="p-2 col-md-11 pl-4">
                              <FormControl className="px-3 pt-2">
                                <FormControlLabel 
                                  className="px-3" 
                                  control={<input type="checkbox"
                                    checked={ documentValues.reason_for_exemption_6_job_printing_cb }
                                    name="reason_for_exemption_6_job_printing_cb"
                                    onChange={handleDocumentValuesChange}
                                    onBlur={(e) => updatePreview(e)}
                                    className="m-2"
                                    />}
                                  label="Job printing." />
                              </FormControl>
                            </div>
                          </div>

                          <div className="d-flex justify-content-evenly">
                            <div className="p-2 col-md-1 pl-4"></div>
                            <div className="p-2 col-md-11 pl-4">
                              <FormControl className="px-3 pt-2">
                                <FormControlLabel 
                                  className="px-3" 
                                  control={<input type="checkbox"
                                    checked={ documentValues.reason_for_exemption_6_refine_cb }
                                    name="reason_for_exemption_6_refine_cb"
                                    onChange={handleDocumentValuesChange}
                                    onBlur={(e) => updatePreview(e)}
                                    className="m-2"
                                    />}
                                  label="Refining or metallurgical operations." />
                              </FormControl>
                            </div>
                          </div>

                          <div className="d-flex justify-content-evenly">
                            <div className="p-2 col-md-1 pl-4"></div>
                            <div className="p-2 col-md-11 pl-4">
                              <FormControl className="px-3 pt-2">
                                <FormControlLabel 
                                  className="px-3" 
                                  control={<input type="checkbox"
                                    checked={ documentValues.reason_for_exemption_6_extract_ore_cb }
                                    name="reason_for_exemption_6_extract_ore_cb"
                                    onChange={handleDocumentValuesChange}
                                    onBlur={(e) => updatePreview(e)}
                                    className="m-2"
                                    />}
                                  label="Extraction of ores or minerals from the earth for commercial purposes." />
                              </FormControl>
                            </div>
                          </div>

                          <div className="d-flex justify-content-evenly">
                            <div className="p-2 col-md-1 pl-4"></div>
                            <div className="p-2 col-md-11 pl-4">
                              <FormControl className="px-3 pt-2">
                                <FormControlLabel 
                                  className="px-3" 
                                  control={<input type="checkbox"
                                    checked={ documentValues.reason_for_exemption_6_extract_oil_cb }
                                    name="reason_for_exemption_6_extract_oil_cb"
                                    onChange={handleDocumentValuesChange}
                                    onBlur={(e) => updatePreview(e)}
                                    className="m-2"
                                    />}
                                  label="Extraction of, or drilling for, oil or gas from the earth for commercial purposes." />
                              </FormControl>
                            </div>
                          </div>

                        </div>

                      ):null}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.reason_for_exemption_7_cb }
                              name="reason_for_exemption_7_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="7. Other income producing capital assets.  (Cities only.)" />
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.reason_for_exemption_8_cb }
                              name="reason_for_exemption_8_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="8. Food, drink or condiments for consumption within the premises of any prison, jail or other institution under the jurisdiction of the state department of corrections, the department of public safety, the department of juvenile corrections or a county sheriff.  Food, drink, condiments   or accessories purchased by a school district for consumption at a public school within the district during school hours." />
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.reason_for_exemption_9_cb }
                              name="reason_for_exemption_9_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="9. Tangible personal property sold or leased directly to the United States Government or its departments or agencies by a manufacturer, modifier, assembler or repairer.   (Retail, personal property rental and mining classifications only.)" />
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.reason_for_exemption_10_cb }
                              name="reason_for_exemption_10_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="10. Fifty percent of the gross proceeds or gross income from the sale of tangible personal property directly to the United States Government or its departments or agencies.  (Retail classification only.)" />
                        </FormControl>
                      </div>
                    </div>
                    <hr></hr>


                    <label htmlFor="stock"><b>Page 2</b></label>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                      <label htmlFor="stock">Your Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Arizona Transaction Privilege Tax License Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="tpt_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.tpt_number ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.reason_for_exemption_11_cb }
                              name="reason_for_exemption_11_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="11. Electricity or natural gas sold to a business that is at least 51% manufacturing or smelting if at least 51% of the electricity or natural is used in the manufacturing or smelting operation.  (Utilities classification only.)" />
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.reason_for_exemption_12_cb }
                              name="reason_for_exemption_12_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="12. Sale or lease of tangible personal property to affiliated Native Americans if the solicitation for sale, signing of the contract, delivery of the goods and payment for the goods all occur on the reservation.  NOTE:  The vendor shall retain adequate documentation to substantiate the transaction." />
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.reason_for_exemption_13_cb }
                              name="reason_for_exemption_13_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="Foreign diplomat.  NOTE:  Limited to authorization on the U.S. Department of State Diplomatic Tax Exemption Card.  The vendor shall retain a copy of the U.S. Department of State Diplomatic Tax Exemption Card and any other documentation issued by the U.S. Department of State.  Motor vehicle purchases or leases must be pre-authorized by the Office of Foreign Missions (“OFM”).  See “Vehicle Tax Exemption”  at www.sta" />
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.reason_for_exemption_14_cb }
                              name="reason_for_exemption_14_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="14.* Other Deduction:  Cite the Arizona Revised Statutes authority for the deduction." />
                        </FormControl>
                      </div>
                    </div>

                    { documentValues.reason_for_exemption_14_cb ? (
                    <div>
                      <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-1"></div>
                        <div className="p-2 col-md-11">
                          <label htmlFor="stock">A.R.S. §</label>
                          <input
                            type="text"
                            className="form-control rounded-pill mt-1"
                            name="reason_for_exemption_14_deduction"
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                            value={ documentValues.reason_for_exemption_14_deduction ?? '' }
                            />
                        </div>
                      </div>
                      <div className="d-flex justify-content-evenly">
                        <div className="p-2 col-md-1"></div>
                        <div className="p-2 col-md-11">
                          <label htmlFor="stock">Description</label>
                          <input
                            type="text"
                            className="form-control rounded-pill mt-1"
                            name="reason_for_exemption_14_desc"
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                            value={ documentValues.reason_for_exemption_14_desc ?? '' }
                            />
                        </div>
                      </div>
                    </div>
                    ):null}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.reason_for_exemption_15_cb }
                              name="reason_for_exemption_15_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="15.* Other Cities Deduction:  Cite the Model City Tax Code authority for the deduction." />
                        </FormControl>
                      </div>
                    </div>

                    { documentValues.reason_for_exemption_15_cb ? (
                    <div>
                      <div className="d-flex justify-content-evenly">
                        <div className="p-2 col-md-1"></div>
                        <div className="p-2 col-md-11">
                          <label htmlFor="stock">M.C.T.C §</label>
                          <input
                            type="text"
                            className="form-control rounded-pill mt-1"
                            name="reason_for_exemption_15_deduction"
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                            value={ documentValues.reason_for_exemption_15_deduction ?? '' }
                            />
                        </div>
                      </div>
                      <div className="d-flex justify-content-evenly">
                        <div className="p-2 col-md-1"></div>
                        <div className="p-2 col-md-11">
                          <label htmlFor="stock">Description</label>
                          <input
                            type="text"
                            className="form-control rounded-pill mt-1"
                            name="reason_for_exemption_15_desc"
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                            value={ documentValues.reason_for_exemption_15_desc ?? '' }
                            />
                        </div>
                      </div>
                    </div>
                    ):null}
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock"><b>E. Describe the tangible personal property or service purchased or leased and its use below.</b></label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="section_e_desc"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.section_e_desc ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    <label htmlFor="stock"><b>F. Certification</b></label>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Printed Full Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Title</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="title"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.title ?? '' }
                          />
                      </div>
                    </div>








                  </div>
                <hr></hr>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default AZ5000
import React, { useEffect, useState } from "react";
import CardContent from '@mui/material/CardContent';
import Navbar from "../../../Components/Navbar/Navbar";
import { useFetch } from "../../../utlis/api";
import moment from 'moment';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as api from '../../../utlis/api'
import { Checkbox, CircularProgress } from "@mui/material";
import * as checkOut from './CheckedOutHelpers.js'

const MyCheckedOut = () => {

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const axios     = useFetch()
  const [isLoading, setIsLoading]=useState(false);
  const [orders, setOrders] = useState(undefined)
  const [allSelected, setAllSelected] = useState(false)

  const selectItem = (id)=>{
    let ordersCopy= [...orders]
    let index= orders.findIndex(order=>Number(order.id)===Number(id))
    let updatedOrder= {...ordersCopy[index], selected:!ordersCopy[index]?.selected}
    ordersCopy[index]=updatedOrder
      let allSelected = ordersCopy.every(order=>order.selected)
      setAllSelected(allSelected)
      setOrders(ordersCopy)
  }

  const selectAll= () =>{
    if(orders?.length>0){
      let selectAllArr=orders.map((order,i)=>{
        return{...order, selected:!allSelected}
      })
      setOrders(selectAllArr)
    }
    setAllSelected(!allSelected)
  }

  const checkOutOrder= async (type)=>{
    let res
    setIsLoading(true)
    switch (type) {
      case 'ALL':
        for(const order of orders){
          await axios.get(`order/${order.id.toString()}/uncheckout`)
        }
        res = await checkOut.fetchCheckedOutOrders('MINE')
        if (res) setOrders([...res])
          break;
        
      case 'SELECTED':
        for(const order of orders){
          if(order?.selected) {
            await axios.get(`order/${order.id.toString()}/uncheckout`)
          }
        }
        res = await checkOut.fetchCheckedOutOrders('MINE')
          if (res) setOrders([...res])
        break;
              
      default:
        break;
    }
    setIsLoading(true)
  }
  useEffect(()=>{

    const fetchMyCheckedOutOrders=async ()=>{
      setIsLoading(true)
      try{
        let res = await checkOut.fetchCheckedOutOrders('MINE')
        if (res) setOrders([...res])
        
      } catch (err){
          api.recordFrontEndError('AllCheckedOut.jsx', 'Error getting all checked out orders: ' + err )
      }
      setIsLoading(false)
      }
    if(orders===undefined){
      fetchMyCheckedOutOrders() 
    }
  }, [orders])

  return (
    <>
    <Navbar title="My Checked Out Orders" />
      <div className="mx-4 mt-2">
        {/* <div className="d-flex justify-content-between"> */}
          {/* <div className="d-flex align-items-center"> */}
            <h3>My Checked Out Orders</h3>
          {/* </div> */}
        {/* <div className="d-flex justify-content-between"> */}
          {isLoading?(<CircularProgress style={{marginLeft:'11rem'}}/>):(
          <div className="d-flex align-items-center" style={{gap:'1rem'}}>
            <button onClick={e=>checkOutOrder('ALL')} className="btn btn-success ma-4 pa-4">Un-Check All My Orders Out</button>
            <button onClick={e=>checkOutOrder("SELECTED")} className="btn btn-success ma-4 pa-4">Un-Check Selected Orders Out</button>
        </div>
        )}
        </div>
          {/* </div> */}
      {/* </div> */}
      <br />

      {/* <Card> */}
        <CardContent style={{ width: "100%" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell><Checkbox className="btn btn-default" checked={allSelected} onChange={(e) => {selectAll()}}/></StyledTableCell>
                <StyledTableCell align="center">Un-Checkout Order</StyledTableCell>
                <StyledTableCell align="left">Order ID</StyledTableCell>
                <StyledTableCell align="left">Customer</StyledTableCell>
                <StyledTableCell align="left">Order Status</StyledTableCell>
                <StyledTableCell align="left">Checked Out At</StyledTableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {orders && orders.length > 0 ? ( orders.map((row) => (
                <StyledTableRow key={row?.id}>
                <StyledTableCell component="th" scope="row">
                  <Checkbox className="btn btn-default" checked={row?.selected} onChange={(e) => {selectItem(row?.id)}}/>
                  {/* <button className="btn btn-default btn-text" style={{ color: 'red'}} onClick={() => {deleteUserLocationAccessGrant(row.id);}}>Delete</button> */}
                </StyledTableCell>
                <StyledTableCell align="center"><button className="btn btn-primary" onClick={async (e)=>{
                  e.preventDefault()
                  let res = await axios.get(`order/${row.id.toString()}/uncheckout`)
                  res = await checkOut.fetchCheckedOutOrders('MINE')
                  if (res) setOrders([...res])
                  }}>Un-Checkout Order</button></StyledTableCell>
                <StyledTableCell align="left">{row?.id}</StyledTableCell>
                <StyledTableCell align="left">{row?.customer?.length>0?row?.customer: 'New Customer'}</StyledTableCell>
                <StyledTableCell align="left">{row?.orderStatus?? ''}</StyledTableCell>
                <StyledTableCell align="left">{moment(row?.checkedOutAt).format("YYYY-MM-DD HH:mm:ss") ??''}</StyledTableCell>
              </StyledTableRow>
              ))):null}
            </TableBody>
          </Table>
        </TableContainer>
        </CardContent>
    </>
  );
}

export default MyCheckedOut;

import React from "react";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const HelpIcon = () => {
  return (
    <Tooltip title="Help">
      <span> 
        <IconButton 
        disabled={true}
        >
          <HelpOutlineIcon />
        </IconButton>
      </span>
    </Tooltip>
  )
};

export default HelpIcon;

import React,{ useState, useEffect, useCallback} from "react";
import { recordFrontEndError }  from "../../../../../../../utlis/api";
// import * as api from "../../../../../../../utlis/api";
import CloseIcon from "@material-ui/icons/Close";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import RemoveIcon from "@material-ui/icons/Remove";
import { TextField, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Button } from '@mui/material';
import { editTaxesAndFees } from "../../../../../../../redux/products";
import NumberFormat from "react-number-format";
import { formatNumber, removeChars, formatter } from "../../../../../../../utlis/formatters";
import { newTaxAndFeesLineItem, updateTaxAndFeesLineItem, saveProductSection, getOtherProductsInGroup, filterDocumentsForProduct, filterProductDocumentsForOrder} from "../../../../../../../utlis/dataSaveAndCopyLibrary";
import { useOrder } from "../../../../../../../redux/orders/order"
import { updateCopySection, updateCopySubSection, updateObjectId, updateObjectGroupId, updateChangesArray, updateGroupChangesArray, updateStatus, updateSaveFunction } from "../../../../../../../redux/saveAndCopy"
import { setActiveModal, setActiveModalTab } from "../../../../../../../redux/app/appSaver";
import { addTestingTag } from "../../../../../../../utlis/testingHelpers";
import { salesPriceWithUpgradeCalc, salesPriceWithUpgradePlusEWandGAPCalc } from "../../../../../../../utlis/productCalculations";
import { enableTireTax } from "../../../../../../../utlis/fastLogicUtils";

function ModalProductTaxFee({ documents, location, product, resetTaxFeesFunc, setHasUnsavedChanges, hasClickedOut, setHasClickedOut, preCloseClose }) {
  const filter    = createFilterOptions();
  const {showSpinner}=useSelector(state=>state.spinners)
  const dispatch = useDispatch()
  const order = useOrder();

  const changesArray = useSelector((state) => state?.copyChangesToDocumentsRedux.changesArray);

  const preCloseSave = e => {
    handleSubmit(e)
    setHasClickedOut(false);
    setHasUnsavedChanges(false);
  }

  const [initialValues, setInitialValues] = useState({});

  const [dataChangeArray, setDataChangeArray]   = useState([]);
  function updatedataChangeArray(prop, value){
    let doNotCopyProperties = [];

    if(dataChangeArray && !doNotCopyProperties.includes(prop) && prop !== undefined && prop !== null){

      if(dataChangeArray["fees."+prop]?.oldValue){

        setDataChangeArray((prev)=>({
          ...prev,
          ["fees."+prop]: {
            "newValue": value,
            "oldValue": dataChangeArray["fees."+prop].oldValue
          }
        }))
        
      }else{
        const oldValue = product?.lineItemFee[prop];

        setDataChangeArray((prev)=>({
          ...prev,
          ["fees."+prop]: {
            "newValue": value,
            "oldValue": oldValue ? oldValue : '--'
          }
        }))

      }
    }
  }
  const appData = useSelector((state) => state?.appData);

  useEffect(() => {

    if(appData?.activeModal === 'product' && appData?.activeModalTab === 'tax-and-fee'){
      dispatch(updateChangesArray(dataChangeArray));
    }

  }, [ appData, dataChangeArray, dispatch ])


  const handleSubmit = async e =>{
    e.preventDefault();

      try {

        var payloadProduct = JSON.parse(JSON.stringify(product));
        payloadProduct.location = location;
        
        
        if(payloadProduct?.lineItemFee?.id > 0){


          saveProductSection(e, order, payloadProduct, 'tax-and-fee', changesArray, updateTaxAndFeesLineItem, () => {
            setSaveSuccess(true);
            setHasUnsavedChanges(false);
            setHasClickedOut(false);

            dispatch(updateCopySection('product'));
            dispatch(updateCopySubSection('tax-and-fee'));
            dispatch(updateObjectId(product.id));
            dispatch(updateObjectGroupId(product.productGroupId));
            dispatch(updateSaveFunction(updateTaxAndFeesLineItem));
          });
  

        }else{
          newTaxAndFeesLineItem(order, payloadProduct, () => {
            setSaveSuccess(true);
            setHasUnsavedChanges(false);
            setHasClickedOut(false);
          });
        }
       
      } catch (err) {
        recordFrontEndError('ModalProductTaxFee.js', 'Error editing tax/fees: ' + err )
      }
    
  };

  const [saveSuccess, setSaveSuccess] = useState(false);

  // const salesPriceWithUpgradeCalc = useCallback(() => {
  //   const {upgrades} = product;
  //   // const {additions, upgrades} = product;
  //   let total = 0;
  //   if(upgrades && upgrades.length) {
  //     upgrades.forEach(upgrade => {
  //       total = total + upgrade.price;
  //     });
  //   }
  //   return Number(product.price) + total;
  // },[product])

  const getTotalSubjectToFet = useCallback(() => {
    const {upgrades} = product;

    let total = 0;
    if(upgrades && upgrades.length) {
      upgrades.forEach(upgrade => {
        if(upgrade.subjectToFet === true){
          total += upgrade.price;
        }
      });
    }
    return Number(product.price) + total;
  },[product])

  const fetCalc = useCallback(() => {
    const totalSubjectToFet = getTotalSubjectToFet();
    const tireCreditVal = product?.lineItemFee?.tireCredit;
    return (totalSubjectToFet * 0.12) - tireCreditVal; 
  },[ product, getTotalSubjectToFet ])

  const catCalc = useCallback(() => {
    const salesUpgradePrice = salesPriceWithUpgradePlusEWandGAPCalc(product);
    return Math.round(((salesUpgradePrice * (Number(product?.lineItemFee?.catTaxPercent) ?? 0) / 100))); 
  },[product])

  const luxuryTaxCalc = useCallback(() => {
    const salesUpgradePrice = salesPriceWithUpgradeCalc(product);
    return Math.round(salesUpgradePrice * ((product?.lineItemFee?.luxuryTaxPercent ??0.5) / 100)); 
  },[product])

  const additionalFeesSubtotalCalc = useCallback(() => {
    let etl = Number(removeChars(product?.lineItemFee?.etlFee))

    let tire = (product?.lineItemFee?.enableTireTax === true || enableTireTax(order, product)) ? Number(removeChars(product?.lineItemFee?.tireTaxTotal)) : 0;
    let oos = Number(removeChars(product?.lineItemFee?.oosDeliveryFee))
    let doc = Number(removeChars(product?.lineItemFee?.docFee))
    let bank =Number(removeChars(product?.lineItemFee?.bankFee))
    let additional =Number(removeChars(product?.lineItemFee?.additionalTax))
    var total = etl + doc + oos + tire + additional + bank;

    if(product?.lineItemFee?.applyTitleFee || product?.lineItemFee?.applyRegistrationFee ){
      total += Number(removeChars(product?.lineItemFee?.titleFee));
    }else{
      if(product && product.lineItemFee && product.lineItemFee.titleFee && (product.lineItemFee.applyTitleFee || product.lineItemFee.applyRegistrationFee)){
        total += Number(removeChars(product.lineItemFee.titleFee));
      }
    }

    return total;
    
  },[ product, order])

  const taxAndFeeTotalCalc = useCallback(() => {
    let total = 0;

    if(product && product.lineItemFee && product.lineItemFee.applyFet === true){
      total = Number(total) + Number(removeChars((product?.lineItemFee?.fetTotal ?? 0)));
    }

    if(product && product.registrationAddress?.state && product.registrationAddress?.state === "OR"){
      total = Number(total) + Number(removeChars((product?.lineItemFee?.catTaxTotal ?? 0)));
    }

    // if(order && order.customer && order.customer.deliveryAddress && order.customer.deliveryAddress.state === "OR"){
    if(product && product.registrationAddress?.state && product.registrationAddress?.state === "OR"){
      if(product.lineItemFee.applyLuxuryTax === true){
        total = Number(total) + Number(removeChars((product.lineItemFee.luxuryTaxTotal ?? 0)));
      }
    }

    total = Number(total) + Number(removeChars((additionalFeesSubtotalCalc() ?? 0)));

    return total;
  },[product,additionalFeesSubtotalCalc])

  const productSubtotalCalc = useCallback(() => {
    const salesUpgradePrice = salesPriceWithUpgradeCalc(product);
    const taxAndFeeTotal=taxAndFeeTotalCalc()
    return salesUpgradePrice + taxAndFeeTotal;
  },[product, taxAndFeeTotalCalc])
  
  // const resetValsOnClose = useCallback(() => {
  //   // setFormInput(initialState);
  //   setapplyFet(undefined);
  //   setapplyLuxuryTax(undefined);
  //   setapplyTitleFee(undefined);
  //   setapplyRegistrationFee(undefined);
  //   setSaveSuccess(false);
  //   // setsalesPriceWithUpgrade(0);
  //   // setfetCalculation(0);
  //   // setcatCalculation(0);
  //   // setluxuryTaxCalculation(0);
  //   setadditionalFeesSubtotal(0);
  //   // settaxAndFeeTotal(0);
  //   // setproductSubtotal(0);
  //   // settireCredit(undefined);
  //   setcatTaxPercent(undefined);
  //   setcatTaxTotal(undefined);
  //   setFetTotal(undefined);
  //   setluxuryTaxPercent(undefined);
  //   setluxuryTaxTotal(undefined);
  //   setetlFee(undefined);
  //   settireTax(undefined);
  //   settitleFee(undefined);
  //   setoosDeliveryFee(undefined);
  //   setdocFee(undefined);
  //   setbankFee(undefined);
  //   setadditionalTax(undefined);
  //   setnotes(undefined);
  //   setFetExemptReason(undefined);
  //   setcursorFlag(true);
  //   setkeyVal(undefined)
  // },[])

  const handleEditTaxesAndFees = (e=null, prop=null, options=null)=>{
    if(prop === "enableTireTax"){
      let { value } = options;
      dispatch(editTaxesAndFees({ prop: prop, value: value }));
      updatedataChangeArray(prop, value);
      console.log("handleEditTaxesAndFees", options, prop);
    }else if (options){
      let { value } = options
      dispatch(editTaxesAndFees({prop:prop, value:Number(value)}));
      updatedataChangeArray(prop, value);
    }else if(e?.target) {

      let {value,checked} = e?.target;

      let formattedValue = null;

      if (prop === 'applyFet' || prop === 'applyLuxuryTax' || prop === 'applyRegistrationFee' || prop === 'applyTitleFee' ){
        formattedValue = checked;
        dispatch(editTaxesAndFees({prop:prop, value:formattedValue}))
        updatedataChangeArray(prop, formattedValue);

        if (prop === 'applyFet'){
          if(checked===true){
            dispatch(editTaxesAndFees({prop:'fetOverrideToggle', value: ( initialValues?.lineItemFee?.fetOverrideToggle ?? 0 ) }));
            updatedataChangeArray('fetOverrideToggle', ( initialValues?.lineItemFee?.fetOverrideToggle ?? 0 ));

            dispatch(editTaxesAndFees({prop:'fetOverrideReason', value: ( initialValues?.lineItemFee?.fetOverrideReason ?? '' )}));
            updatedataChangeArray('fetOverrideReason', ( initialValues?.lineItemFee?.fetOverrideReason ?? '' ));

            dispatch(editTaxesAndFees({prop:'fetExemptReason', value: ( initialValues?.lineItemFee?.fetExemptReason ?? '' )}));
            updatedataChangeArray('fetExemptReason', ( initialValues?.lineItemFee?.fetExemptReason ?? '' ));

            dispatch(editTaxesAndFees({prop:'fetTotal', value: (initialValues?.lineItemFee?.fetTotal ?? fetCalc() )}));
            updatedataChangeArray('fetTotal', (initialValues?.lineItemFee?.fetTotal ?? fetCalc()));

            dispatch(editTaxesAndFees({prop:'tireCredit', value: ( initialValues?.lineItemFee?.tireCredit ?? 0 )}));
            updatedataChangeArray('tireCredit', ( initialValues?.lineItemFee?.tireCredit ?? 0 ));

          setInitialValues(prevState => ({
                ...prevState,                        
                lineItemFee: {
                  ...prevState.lineItemFee,
                  fetTotal: (initialValues?.lineItemFee?.fetTotal??fetCalc())
                }
              }));
          }else{
            dispatch(editTaxesAndFees({prop:'fetOverrideToggle', value:false}));
            updatedataChangeArray('fetOverrideToggle', false);

            dispatch(editTaxesAndFees({prop:'fetOverrideReason', value:undefined}));
            updatedataChangeArray('fetOverrideReason', undefined);

            dispatch(editTaxesAndFees({prop:'fetExemptReason', value:undefined}));
            updatedataChangeArray('fetExemptReason', undefined);

            dispatch(editTaxesAndFees({prop:'fetTotal', value:null}));
            updatedataChangeArray('fetTotal', null);

            dispatch(editTaxesAndFees({prop:'tireCredit', value:null}));
            updatedataChangeArray('tireCredit', null);

          }
        
        
        }
        else if (prop === 'applyLuxuryTax'){
          if(checked){
            dispatch(editTaxesAndFees({prop:'luxuryTaxOverrideToggle', value: ( initialValues?.lineItemFee?.luxuryTaxOverrideToggle ?? 0 )}));
            updatedataChangeArray('luxuryTaxOverrideToggle', ( initialValues?.lineItemFee?.luxuryTaxOverrideToggle ?? 0 ));
    
            dispatch(editTaxesAndFees({prop:'luxuryTaxOverrideReason', value: ( initialValues?.lineItemFee?.luxuryTaxOverrideReason ?? '' )}));
            updatedataChangeArray('luxuryTaxOverrideReason', ( initialValues?.lineItemFee?.luxuryTaxOverrideReason ?? '' ));

            dispatch(editTaxesAndFees({prop:'luxuryTaxExemptReason', value: ( initialValues?.lineItemFee?.luxuryTaxExemptReason ?? '' )}));
            updatedataChangeArray('luxuryTaxExemptReason', ( initialValues?.lineItemFee?.luxuryTaxExemptReason ?? '' ));

            dispatch(editTaxesAndFees({prop:'luxuryTaxTotal', value:(initialValues?.lineItemFee?.luxuryTaxTotal ?? luxuryTaxCalc(product))}));
            updatedataChangeArray('luxuryTaxTotal', (initialValues?.lineItemFee?.luxuryTaxTotal ?? luxuryTaxCalc(product)));

            dispatch(editTaxesAndFees({prop:'luxuryTaxPercent', value: ( initialValues?.lineItemFee?.luxuryTaxPercent ?? 0 )}));
            updatedataChangeArray('luxuryTaxPercent', ( initialValues?.lineItemFee?.luxuryTaxPercent ?? 0 ));

            setInitialValues(prevState => ({
              ...prevState,                        
              lineItemFee: {
                ...prevState.lineItemFee,
                luxuryTaxTotal: (initialValues?.lineItemFee?.luxuryTaxTotal??luxuryTaxCalc(product))
              }
            }));
          } else {
            dispatch(editTaxesAndFees({prop:'luxuryTaxOverrideToggle', value:false}));
            updatedataChangeArray('luxuryTaxOverrideToggle', false);

            dispatch(editTaxesAndFees({prop:'luxuryTaxOverrideReason', value:undefined}));
            updatedataChangeArray('luxuryTaxOverrideReason', undefined);

            dispatch(editTaxesAndFees({prop:'luxuryTaxExemptReason', value:undefined}));
            updatedataChangeArray('luxuryTaxExemptReason', undefined);

            dispatch(editTaxesAndFees({prop:'luxuryTaxTotal', value:null}));
            updatedataChangeArray('luxuryTaxTotal', null);

            dispatch(editTaxesAndFees({prop:'luxuryTaxPercent', value:null}));
            updatedataChangeArray('luxuryTaxPercent', null);

          }
    }
      } else if (prop === 'tireCredit'){
          value = value.toFixed(2);
          let val = '$' + (removeChars(value));
          dispatch(editTaxesAndFees({prop:prop, value:val}))
          updatedataChangeArray(prop, val);

          dispatch(editTaxesAndFees({prop:'fetOverrideToggle', value:false}));
          updatedataChangeArray('fetOverrideToggle', false);

          dispatch(editTaxesAndFees({prop:'fetOverrideReason', value:null}))
          updatedataChangeArray('fetOverrideReason', null);

      }else if(prop === 'catTaxPercent' || prop === 'luxuryTaxPercent') {
        formattedValue = Number(removeChars(value)).toFixed(3);
        updatedataChangeArray(prop, formattedValue);
        dispatch(editTaxesAndFees({prop:prop, value: formattedValue}));

      } else {
        // Remaining
        updatedataChangeArray(prop, value);
        dispatch(editTaxesAndFees({prop:prop, value:value}));
      } 
  }

    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  const handleBlurFormat=(e, prop)=> {
    let value = removeChars(e.target.value);
    if(typeof value === 'number'){
      value = Number(value).toFixed(2);
      let formattedValue = formatter.format(value);
      dispatch(editTaxesAndFees({value: formattedValue, prop: value}))
    }
  }

  const updateFetTotal = useCallback(() => {
    dispatch(editTaxesAndFees({prop:'fetTotal', value:fetCalc()}))
    // setFetTotal(formatter.format(fetCalc()));
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  },[dispatch, fetCalc, setHasUnsavedChanges, setSaveSuccess ]);

  const formatCatTaxPercent = e => {
    let value = Number(removeChars(e.target.value));
    dispatch(editTaxesAndFees({prop:'catTaxPercent', value: value}))
    dispatch(editTaxesAndFees({prop:'catTaxTotal', value: catCalc(product)}))
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  const formatLuxuryTaxPercent = e => {
    let value = Number(removeChars(e.target.value)).toFixed(3);
    dispatch(editTaxesAndFees({prop:'luxuryTaxPercent', value: value}))
    dispatch(editTaxesAndFees({prop:'luxuryTaxTotal', value: luxuryTaxCalc(product)}))
    if (product?.lineItemFee?.luxuryTaxPercent && (Number(product?.lineItemFee?.luxuryTaxPercent).toFixed(3) !== Number(initialValues?.lineItemFee?.luxuryTaxPercent).toFixed(3))) {
      dispatch(editTaxesAndFees({prop:'luxuryTaxOverrideToggle', value:true}))
    }else {
      dispatch(editTaxesAndFees({prop:'luxuryTaxOverrideToggle', value:false}))
    }
  }

  const formatLuxuryTaxTotal = e => {
    if(e.target.value !== undefined) {
      dispatch(editTaxesAndFees({value:removeChars(e.target.value), prop:'luxuryTaxTotal'}))
      if (product?.lineItemFee?.luxuryTaxTotal && (Number(product?.lineItemFee?.luxuryTaxTotal).toFixed(2) === Number(initialValues?.lineItemFee?.luxuryTaxTotal).toFixed(2))) {
        dispatch(editTaxesAndFees({prop:'luxuryTaxOverrideToggle', value:false}))
      }else {
        dispatch(editTaxesAndFees({prop:'luxuryTaxOverrideToggle', value:true}))
      }
    }
  }
  
  const formatFetTotal = (e, options) => {
    if(e.target.value !== undefined) {
      dispatch(editTaxesAndFees({value:removeChars(e.target.value), prop:'fetTotal'}))
      if (product?.lineItemFee?.fetTotal && (Number(product?.lineItemFee?.fetTotal).toFixed(2) === fetCalc().toFixed(2))) {
        dispatch(editTaxesAndFees({prop:'fetOverrideToggle', value:false}))
      }else {
        dispatch(editTaxesAndFees({prop:'fetOverrideToggle', value:true}))
      }
    }
  }

  const onFetExemptReasonChange = (newValue) => {
    let {title} = newValue
    dispatch(editTaxesAndFees({prop:'fetExemptReason', value:title}))
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  const formatInputTireCreditNumber = e => {
    if(e.target.value !== undefined) {
      dispatch(editTaxesAndFees({value:Number(removeChars(e.target.value)), prop:'tireCredit'}))
      updateFetTotal();
    }
  }

  const fetExemptionReasons = [
    { title: 'Municipality' },
    { title: 'Under 33K GVW' },
    { title: 'Canadian Resident' },
    { title: 'Concrete Truck' },
    { title: 'Farm Truck' },
    { title: 'Indian Reservation' },
    { title: 'Sale to Licensed Dealer' },
    { title: 'Off Highway Use' },
    { title: 'Out of Country Purchaser' },
    { title: "Drop Shipped to Delivery Address" },
  ]

  // eslint-disable-next-line no-unused-vars
  const [initalizedApplyFet, setInitalizedApplyFet] = useState(false);

  useEffect(() => {
      
    if(appData?.activeModal === 'product' && appData?.activeModalTab === 'tax-and-fee'){

      
      if (!Object.keys(initialValues).length && product?.lineItemFee !==null) {
          setInitialValues({...product});
          if(product.registrationAddress?.state === "OR"){
            dispatch(editTaxesAndFees({prop:'catTaxTotal', value: catCalc(product)}))
          }
      }

      // if(!initalizedApplyFet && product?.lineItemFee?.updatedAt === null && product?.type?.toLowerCase() === "new"){
      //   setInitalizedApplyFet(true);
      //   dispatch(editTaxesAndFees({prop:'applyFet', value:true}))
      //   updateFetTotal();
      // }

      if(!initalizedApplyFet && !product?.lineItemFee?.fetTotal && product?.lineItemFee?.applyFet === true && product?.type?.toLowerCase() === "new"){
        setInitalizedApplyFet(true);
        updateFetTotal();
      }

    }

}, [ appData, initialValues, product, dispatch, catCalc, initalizedApplyFet, updateFetTotal]);

  return (
    <>
      { appData?.activeModal === 'product' && appData?.activeModalTab === 'tax-and-fee' ? (
        <>
          <form style={{overflowY: "scroll", overflowX: "hidden", maxHeight: "550px", width: '102%'}}>
            <div className="container">
              {/* ------------------------------ Row 1: Taxes & Fees ------------------------------------ */}
              <div className="row border-bottom mb-3 text-capitalize">
                <div className="d-flex justify-content-between m-2">
                  <h5>Taxes & Fees</h5>
                  { hasClickedOut ? (
                      <div className="d-flex">
                        <p className="m-1">Save Changes?</p>
                        <button className="m-1 btn btn-sm btn-success" aria-label="save" data-testid="product.taxfee.control.saveYes" onClick={preCloseSave}>Yes</button>
                        <button className="m-1 btn btn-sm btn-danger" aria-label='cancel' data-testid="product.taxfee.control.saveNo" onClick={preCloseClose}>No</button>
                      </div>
                    ):(
                      <div>
                        {showSpinner?<CircularProgress/>: (saveSuccess ? (
                          <span className="btn btn-success text-uppercase">Saved Product Info</span>
                        ):(
                          <button onClick={handleSubmit} className="btn btn-primary text-uppercase" data-testid="product.taxfee.control.saveTop" aria-label='save'>Save</button>
                        ))}
                      </div>
                    )}
                </div>
              </div>
              {/* ------------------------------ Row 2: FET ------------------------------------ */}
              { (product?.type?.toString().toLowerCase() === "new") ? (
              <div className="row ">
                <h6>FET</h6>

                <FormGroup className="m-2">
                  <FormControlLabel 
                    control={<Checkbox color="default"
                    aria-label="apply fet to line item fee"
                      checked={product?.lineItemFee?.applyFet}
                      data-testid="product.taxfee.toggleApplyFet"
                      onChange={(e) => { 
                        e.stopPropagation()
                        handleEditTaxesAndFees(e, 'applyFet') }} />
                    } 
                    label="Apply FET to New Unit"
                    style={{cursor: 'default'}}
                  />
                </FormGroup>
                {/* { applyFet || (product && product.lineItemFee && product.lineItemFee.applyFet) ? ( */}
                { product?.lineItemFee?.applyFet ? (
                  <div className="row">
                  <div className="col-3 mx-3 bg-light my-2">
                    <h6>${formatNumber(getTotalSubjectToFet())}</h6>
                    <h6 className="text-secondary text-uppercase">Total Subject to FET</h6>
                  </div>
                  <div className="col-1">
                    <CloseIcon />
                  </div>
                  <div className="col-1 mt-1">
                    <h6>12%</h6>
                  </div>
                  <div className="col-1 ">
                    <RemoveIcon />
                  </div>
                  <div className="col-2 ">
                    <h6>Tire Credit</h6>
                    {/* <input
                      type="text"
                      onChange={e => handleEditTaxesAndFees(e,'tireCredit') }
                      onBlur={e => formatInputTireCreditNumber(e)}
                      name="tireCredit"
                      className="form-control rounded-pill text-uppercase"
                      value={'$' + formatNumber(product?.lineItemFee?.tireCredit) ??'$'}
                      /> */}
                      <NumberFormat
                              className="rounded-pill form-control"
                              value={ Number(product?.lineItemFee?.tireCredit).toFixed(2)??Number().toFixed(2)}
                              name="tireCredit"
                              aria-label='line item fee tire credit'
                              thousandSeparator={true}
                              decimalScale={2}
                              data-testid="product.taxfee.fet.tireCredit"
                              prefix={'$'}
                              // format={format}
                              onValueChange={ options =>handleEditTaxesAndFees(null,'tireCredit', options) }
                              onBlur={e => formatInputTireCreditNumber(e)}
                            />
                  </div>
                  <div className=" col-1 ">
                    <ArrowForwardIcon />
                  </div>
                  <div className="col-2 ">
                  {/* <h6>${formatNumber(fetCalculation)}</h6> */}
                    <h6 className="text-uppercase">FET Total</h6>
                    {/* <input
                      type="text"
                      onChange={e => onFetTotalChange(e)}
                      onBlur={e => formatFetTotal(e)}
                      name="fetTotal"
                      className="form-control rounded-pill text-uppercase"
                      value={fetTotal !== undefined ? fetTotal : (lineItemFeeExists && product.lineItemFee.fetTotal  ? '$' + formatNumber(product.lineItemFee.fetTotal) : ('$'))}
                      /> */}
                      <NumberFormat
                              className="rounded-pill form-control"
                              value={ Number(product.lineItemFee.fetTotal).toFixed(2)?? Number().toFixed(2)}
                              name="fetTotal"
                              aria-label='line item fee fet total'
                              thousandSeparator={true}
                              data-testid="product.taxfee.fet.fetTotal"
                              decimalScale={2}
                              prefix={'$'}
                              // format={format}
                              onValueChange={ options =>handleEditTaxesAndFees(null,'fetTotal', options) }
                              onBlur={(e, options) => formatFetTotal(e, options)}
                            />
                  </div>
                </div>
                ):(
                  <div className="col-md-3" style={{float: 'right',}}>
                    <br />
                    <Autocomplete
                        value={product.lineItemFee?.fetExemptReason ?? null}
                        aria-label='line item fee fet exempt reason'
                        onChange={(event, newValue) => {
                          onFetExemptReasonChange(newValue);
                          // if (typeof newValue === 'string') {
                          //   setFetExemptReason({
                          //     title: newValue,
                          //   });
                          // } else if (newValue && newValue.inputValue) {
                          //   // Create a new value from the user input
                          //   setFetExemptReason({
                          //     title: newValue.inputValue,
                          //   });
                          // } else {
                          //   setFetExemptReason(newValue);
                          // }
                        }}
                        freeSolo
                        getOptionLabel={(option) => {
                          // Value selected with enter, right from the input
                          if (typeof option === 'string') {
                            return option;
                          }
                          // Add "xxx" option created dynamically
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          // Regular option
                          return option.title;
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                  
                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some((option) => inputValue === option.title);
                          if (inputValue !== '' && !isExisting) {
                            filtered.push({
                              inputValue,
                              title: `Add "${inputValue}"`,
                            });
                          }
                  
                          return filtered;
                        }}
                        // inputValue={inputStatusVal}
                        // onInputChange={(event, newInputValue) => {
                        //   setinputStatusVal(newInputValue);
                        //   newInputValue === '' ? setstatusErrorShow('block') : setstatusErrorShow('none');
                        //   if(inputValFix) {
                        //     setstatusErrorShow('none');
                        //     setinputValFix(false);
                        //   }
                        // }}
                        //id="controllable-states-demo"
                        options={fetExemptionReasons}
                        selectOnFocus
                        // clearOnBlur
                        // handleHomeEndKeys
                        //sx={{ width: 350 }}
                        renderInput={(params) => <TextField {...addTestingTag(params, 'product.taxfee.fet.fetExemptReason')} label="FET Exemption Reason" />}
                        renderOption={(props, option) => <li {...props}>{option.title}</li>}
                    />
                  </div>
                )}

                { (product?.lineItemFee?.fetTotal !== null && product?.lineItemFee?.fetTotal!== fetCalc())? (
                  <div className="col-12 mx-2 pt-3">
                    <label htmlFor="stock">FET Override Reason (Internal Notes)</label>
                    <textarea
                      onChange={e => handleEditTaxesAndFees(e, 'fetOverrideReason')}
                      maxLength={100}
                      aria-label='line item fee fet over-ride reason'
                      value={ product?.lineItemFee?.fetOverrideReason ?? ''}
                      name='fetOverrideReason'
                      className="form-control rounded-lg mt-1 "
                      rows="5"
                      data-testid="product.taxfee.fet.fetOverrideReason"
                    ></textarea>
                  </div>
                ):null}


              </div>
              ):<p>Product "USED" - No FET</p>}
              <hr className="my-3" />
              {/* ------------------------------ Row 4: CAT TAX ------------------------------------ */}
              {product && product.registrationAddress?.state && product.registrationAddress?.state === 'OR' ? (
                <>
              <div className="row ">
                <h6>CAT TAX (OREGON)</h6>
                <div className="col-2 mx-3 mt-4 bg-light my-2 ">
                  <h6 data-testid="product.taxfee.calc.cat.salesPrice">${formatNumber(salesPriceWithUpgradePlusEWandGAPCalc(product))}</h6>
                  <h6 className="text-secondary text-uppercase">Sales Price</h6>
                </div>
                <div className="col-1 mx-2 mt-5">
                  <CloseIcon />
                </div>
                <div className=" col-3 mt-0 mt-4 text-uppercase">
                  <h6>CAT Tax %</h6>
                    <NumberFormat
                      className="rounded-pill form-control"
                      aria-label='line item fee cat tax percent'
                      value={ product?.lineItemFee?.catTaxPercent?? ''}
                      name="luxuryTaxTotal"
                      thousandSeparator={true}
                      decimalScale={3}
                      data-testid="product.taxfee.cat.catTaxPercent"
                      suffix={'%'}
                      // format={format}
                      onValueChange={ options =>handleEditTaxesAndFees(null,'catTaxPercent', options) }
                      onBlur={e => formatCatTaxPercent(e)}
                    />
                </div>
                <div className=" col-2 mt-5 ms-2">
                  <ArrowForwardIcon />
                </div>
                <div className="col-3 mx-2 mt-4 ">
                  <h6 className="text-uppercase">CAT Tax</h6>
                  <h6 data-testid="product.taxfee.calc.cat.catTaxTotal">${Number(product.lineItemFee.catTaxTotal)>0?Number(product.lineItemFee.catTaxTotal).toFixed(2).toLocaleString(): Number(catCalc(product)).toFixed(2).toLocaleString()}</h6>
                </div>
              </div>
              </>
              ) : <p> Product State Registered Not Oregon - No CAT </p> }
              <hr className="my-3" />
              {/* ------------------------------ Row 5 ------------------------------------ */}
              
              <div className="row">
              
              { product && product.registrationAddress?.state && product.registrationAddress?.state === "OR" ? (
                <>
                <h6 className=" text-uppercase">Privilege TAX (OREGON)</h6>
                <FormGroup className="m-2">
                  <FormControlLabel 
                    className=" text-uppercase"
                    control={<Checkbox color="default"
                    data-testid="product.taxfee.control.luxTax.applyLuxuryTax"
                    aria-label='apply luxury tax to line item fee'
                      checked={product?.lineItemFee?.applyLuxuryTax}
                      onChange={(e) => { 
                        e.stopPropagation()
                        handleEditTaxesAndFees(e, 'applyLuxuryTax') }} />
                    } 
                    label="Apply Luxury Tax (Unit under 7,500 Miles & Class 6 or smaller)" 
                    style={{cursor: 'default'}}
                  />
                </FormGroup>
                  </>
                  
              ):<p> Product State Registered Not Oregon - No Privilege Tax</p>}

              { product.registrationAddress?.state && product.registrationAddress?.state === "OR" && product?.lineItemFee?.applyLuxuryTax === true ? (
                <div className="row ">
                  <div className="col-2 mx-3 mt-4 bg-light my-2">
                    <h6 data-testid="product.taxfee.calc.luxTax.salesPrice">${formatNumber(salesPriceWithUpgradeCalc(product))}</h6>
                    <h6 className="text-secondary text-uppercase">Sales Price</h6>
                  </div>
                  <div className="col-1 mx-2 mt-5">
                    <CloseIcon />
                  </div>
                  <div className=" col-3 mt-0 mt-4 text-uppercase">
                    <h6>Privilege Tax %</h6>
                    <NumberFormat
                      className="rounded-pill form-control"
                      value={ product?.lineItemFee?.luxuryTaxPercent?? Number().toFixed(2)}
                      name="luxuryTaxPercent"
                      aria-label='line item fee luxury tax percent'
                      thousandSeparator={true}
                      decimalScale={3}
                      data-testid="product.taxfee.luxTax.luxuryTaxPercent"
                      suffix={'%'}
                      // format={format}
                      onValueChange={ options =>handleEditTaxesAndFees(null,'luxuryTaxPercent', options) }
                      onBlur={e => formatLuxuryTaxPercent(e)}
                    />
                  </div>
                  <div className=" col-2 mt-5 ms-2">
                    <ArrowForwardIcon />
                  </div>
                  <div className="col-3 mx-2 mt-4 ">
                    <h6 className="">Privilege TAX</h6>
                            <NumberFormat
                              className="rounded-pill form-control"
                              value={Number(product.lineItemFee.luxuryTaxTotal).toFixed(2)??Number().toFixed(2)}
                              name="luxuryTaxTotal"
                              aria-label='line item fee luxury tax total'
                              thousandSeparator={true}
                              data-testid="product.taxfee.luxTax.luxuryTaxTotal"
                              decimalScale={2}
                              prefix={'$'}
                              // format={format}
                              onValueChange={ options =>handleEditTaxesAndFees(null,'luxuryTaxTotal', options) }
                              onBlur={e => formatLuxuryTaxTotal(e)}
                            />

                  </div>

                </div>
              ):null}
              {/* <hr className="my-3" /> */}
                
              { (product?.lineItemFee?.luxuryTaxTotal!==null && product?.lineItemFee?.luxuryTaxTotal!== luxuryTaxCalc(product)) && product.registrationAddress?.state === "OR" ? (
                  <div className="col-12 mx-2 pt-3">
                    <label htmlFor="stock">Privilege Tax Override Reason (Internal Notes)</label>
                    <textarea
                      onChange={e => handleEditTaxesAndFees(e,'luxuryTaxOverrideReason')}
                      maxLength={100}
                      data-testid="product.taxfee.luxTax.luxuryTaxOverrideReason"
                      aria-label='line item fee luxury tax over-ride reason'
                      value={product?.lineItemFee?.luxuryTaxOverrideReason ?? ''}
                      name='luxuryTaxOverrideReason'
                      className="form-control rounded-lg mt-1"
                      rows="5"
                    ></textarea>
              {/* <hr className="my-3" /> */}
                  </div>
              ):null}

              </div>
              {/* ------------------------------ Row 5 ------------------------------------ */}
              {/* {order.customer && order.customer.deliveryAddress && order.customer.deliveryAddress.state ? ((order.customer.deliveryAddress.state === 'OR') ? ( */}
              {/* <> */}
              <hr className="my-3" />

              <div className="row ">
                <div className="col-md-4">
                <h6>License &amp; Registration</h6>
                <FormGroup className="m-2">
                  <FormControlLabel 
                    control={<Checkbox color="default"
                    aria-label="apply title fee to line item fee"
                      checked={product?.lineItemFee?.applyTitleFee}
                      data-testid="product.taxfee.control.applyTitleFee"
                      onChange={(e) => { 
                        // e.stopPropagation()
                        handleEditTaxesAndFees(e,'applyTitleFee') }}/>
                      } 
                    label="Apply Title Fee" 
                    style={{cursor: 'default'}}
                    onClick={(e) =>{}}
                  />
                    {/* <div> */}
                    <FormControlLabel
                    control={
                      <Checkbox type="checkbox" id='applyRegistrationFee' color="default" 
                      data-testid="product.taxfee.control.applyRegistrationFee"
                      checked={product?.lineItemFee?.applyRegistrationFee}
                      aria-label="apply registration fee to line item fee"
                      onChange={(e) => { 
                        e.stopPropagation()
                        handleEditTaxesAndFees(e,'applyRegistrationFee') }
                      } 
                      />
                    }
                    label='Apply Registration Fee'
                    style={{cursor: 'default'}}
                    // onClick={(e) => e.preventDefault()}
                    />
                  {/* <label for='applyRegistrationFee'>Apply Registration Fee</label>
                      </div>  */}

                </FormGroup>
                    
                </div>
                <div className="col-md-8"></div>
              </div>
              <hr className="my-3" />
              {/* </>
              ) : '') : '' } */}
              {/* ------------------------------ Row 6 ------------------------------------ */}
              <div className="row">
                <h6 className="text-uppercase">Additional fees</h6>
                <div className="d-flex mb-3 ">
                  <div className="col-4 mx-3">
                    <label htmlFor="stock">ELT Fee</label>
                    {/* <input
                      onBlur={(e) => { 
                        formatInputetlFeeNumber(e);
                        setcursorFlag(true);
                        }
                      }
                      onChange={e => { 
                        onetlFeeChange(e);
                        const savedPos = e.target.selectionStart;
                        setTimeout(() => {
                          resetCursor(e, savedPos);
                        }, 0);
                        }
                      }
                      name='etlFee'
                      type="text"
                      onKeyDown={e => setkeyVal(e.key)}
                      className="form-control rounded-pill mt-1 "
                      value={etlFee !== undefined ? etlFee : (lineItemFeeExists  ? '$' + formatNumber(product.lineItemFee.etlFee) : ('$'))}
                    /> */}
                    <NumberFormat
                              className="rounded-pill form-control"
                              // value={ Number(product?.lineItemFee?.etlFee).toFixed(2)??Number().toFixed(2)}
                              value={ product?.lineItemFee?.etlFee ?? 0}
                              name="etlFee"
                              aria-label='line item etl fee'
                              data-testid="product.taxfee.etlFee"
                              thousandSeparator={true}
                              decimalScale={2}
                              prefix={'$'}
                              fixedDecimalScale
                              // format={format}
                              onValueChange={ options =>handleEditTaxesAndFees(null,'etlFee', options) }
                              onBlur={e => handleBlurFormat(e, 'etlFee')}
                            />
                  </div>
                  { (product?.lineItemFee?.enableTireTax === true || enableTireTax(order, product)) ? (
                  <div className="col-4 mx-3">
                    <label htmlFor="type">Tire Tax Total</label>
                    <NumberFormat
                      className="rounded-pill form-control"
                      // value={ Number(product?.lineItemFee?.tireTaxTotal).toFixed(2)??Number().toFixed(2)}
                      value={product?.lineItemFee?.tireTaxTotal ?? 0}
                      name="tireTax"
                      aria-label='line item fee tire tax'
                      thousandSeparator={true}
                      data-testid="product.taxfee.tireTaxTotal"
                      decimalScale={2}
                      prefix={'$'}
                      fixedDecimalScale
                      // format={format}
                      onValueChange={ options =>handleEditTaxesAndFees(null,'tireTaxTotal', options) }
                      onBlur={e => handleBlurFormat(e, 'tireTaxTotal')}
                    />
                    { !enableTireTax(order, product) ? (
                      <Button
                        sx={{ width: 'fit-content', margin: 'auto'}}
                        disabled={enableTireTax(order, product)}
                        onClick={
                          () => {
                            handleEditTaxesAndFees(
                              null,
                              "enableTireTax",
                              {value: false}
                            );
                            handleEditTaxesAndFees(
                              null,
                              "tireTaxTotal",
                              {value: 0.00}
                            );
                          }
                        }
                      >Disable Tire Tax</Button>
                    ):null}
                  </div>
                  ): (
                    <div className="col-4 mx-3">
                      <Button
                        sx={{ width: 'fit-content', maxHeight: '2rem'}}
                        variant="contained"
                        onClick={
                          () => {
                            handleEditTaxesAndFees(
                              null,
                              "enableTireTax",
                              {value: true}
                            )
                          }
                        }
                      >Enable Tire Tax</Button>
                    </div>
                  )}
                  </div>
                <div className="d-flex mb-3">
                  <div className="col-4 mx-3">
                    <label htmlFor="stock">License / Title Fees</label>
                    {/* <input
                      type="text"
                      disabled={(!product.lineItemFee.applyTitleFee && !product.lineItemFee.applyRegistrationFee) }
                      onKeyDown={e => setkeyVal(e.key)}
                      onBlur={e => { 
                        formatInputTitleFeeNumber(e);
                        setcursorFlag(true);
                        }
                      }
                      onChange={e => { 
                        ontitleFeeChange(e);
                        const savedPos = e.target.selectionStart;
                        setTimeout(() => {
                          resetCursor(e, savedPos);
                        }, 0);
                        }
                      }
                      // value={product.titleFee ?? 0.00}
                      value={(applyTitleFee || applyRegistrationFee) && titleFee !== undefined ? titleFee : ((applyTitleFee || applyRegistrationFee) && lineItemFeeExists  ? '$' + formatNumber(product.lineItemFee.titleFee) : ('$'))}
                      name="licenceFee"
                      className="form-control rounded-pill mt-1 "
                    /> */}
                    <NumberFormat
                      className="rounded-pill form-control"
                      value={ (product?.lineItemFee?.applyTitleFee || product?.lineItemFee?.applyRegistrationFee) ? ( product?.lineItemFee?.titleFee ?? 0 ) : 0 }
                      isAllowed={()=>product?.lineItemFee?.applyTitleFee || product?.lineItemFee?.applyRegistrationFee }
                      name="licenseFee"
                      aria-label='line item fee license fee'
                      thousandSeparator={true}
                      decimalScale={2}
                      prefix={'$'}
                      data-testid="product.taxfee.licenseFee"
                      fixedDecimalScale
                      style={(!product?.lineItemFee?.applyTitleFee && !product?.lineItemFee?.applyRegistrationFee) ? {  backgroundColor:"#e9ecef" } : { opacity: 1 }}
                      // format={format}
                      onValueChange={ options =>handleEditTaxesAndFees(null,'titleFee', options) }
                      onBlur={e => handleBlurFormat(e, 'titleFee')}
                      />
                  </div>
                  <div className="col-4 mx-3">
                    <label htmlFor="type">Out of State Delivery Fee</label>
                    {/* <input
                      type="text"
                      onKeyDown={e => setkeyVal(e.key)}
                      onChange={e => { 
                        onoosDeliveryFeeChange(e);
                        const savedPos = e.target.selectionStart;
                        setTimeout(() => {
                          resetCursor(e, savedPos);
                        }, 0);
                        }
                      }
                      onBlur={e => { 
                        formatInputoosDeliveryFeeNumber(e);
                        setcursorFlag(true);
                        }
                      }
                      value={oosDeliveryFee !== undefined ? oosDeliveryFee : (lineItemFeeExists  ? '$' + formatNumber(product.lineItemFee.oosDeliveryFee) : ('$'))}
                      name='OutStateDeliveryFees'
                      className="form-control rounded-pill mt-1 "
                    /> */}
                    <NumberFormat
                              className="rounded-pill form-control"
                              // value={ Number(product?.lineItemFee?.oosDeliveryFee).toFixed(2)??Number().toFixed(2)}
                              value={ product?.lineItemFee?.oosDeliveryFee ?? 0}
                              name="OutStateDeliveryFees"
                              aria-label='line item fee out of state delivery fee'
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale
                              data-testid="product.taxfee.oosDeliveryFee"
                              prefix={'$'}
                              // format={format}
                              onValueChange={ options =>handleEditTaxesAndFees(null,'oosDeliveryFee', options) }
                              onBlur={e => handleBlurFormat(e, 'oosDeliveryFee')}
                            />
                  </div>
                  <div className="col-4 mx-3">
                    <label htmlFor="type" className="ms-3">
                      <h6 data-testid="product.taxfee.calc.feeSubtotal">${formatNumber(additionalFeesSubtotalCalc())}</h6>
                    </label>
                    <h6 className="ms-3 mt-2">Subtotal</h6>
                  </div>
                </div>
                
                <div className="d-flex mb-3">
                  <div className="col-4 mx-3">
                  {/* {order.customer && order.customer.deliveryAddress && order.customer.deliveryAddress.state ? ((order.customer.deliveryAddress.state === 'CA') ? ( */}
                    <>
                    <label htmlFor="stock">Documentation Fee</label>
                    {/* <input
                      type="text"
                      onKeyDown={e => setkeyVal(e.key)}
                      onChange={e => { 
                        onDocChange(e);
                        const savedPos = e.target.selectionStart;
                        setTimeout(() => {
                          resetCursor(e, savedPos);
                        }, 0);
                        }
                      }
                      onBlur={e => { 
                        formatInputDocFeeNumber(e);
                        setcursorFlag(true);
                        }
                      }
                      value={docFee !== undefined ? docFee : (lineItemFeeExists  ? '$' + formatNumber(product.lineItemFee.docFee) : ('$'))}
                      name='docFee'
                      className="form-control rounded-pill mt-1 "
                    /> */}
                    <NumberFormat
                              className="rounded-pill form-control"
                              value={ product?.lineItemFee?.docFee ?? 0 }
                              name="docFee"
                              data-testid="product.taxfee.docFee"
                              aria-label='line item fee documentation fee'
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale
                              prefix={'$'}
                              // format={format}
                              onValueChange={ options =>handleEditTaxesAndFees(null,'docFee', options) }
                              onBlur={e => handleBlurFormat(e, 'docFee')}
                            />
                    </>
                    {/* ) : '') : '' } */}
                  </div>
                  <div className="col-4 mx-3">
                    <label htmlFor="type">Miscellaneous</label>
                    {/* <input
                      type="text"
                      onKeyDown={e => setkeyVal(e.key)}
                      onChange={e => { 
                        onadditionalTaxChange(e);
                        const savedPos = e.target.selectionStart;
                        setTimeout(() => {
                          resetCursor(e, savedPos);
                        }, 0);
                        }
                      }
                      onBlur={e => { 
                        formatInputAdditionalTaxNumber(e);
                        setcursorFlag(true);
                        }
                      }
                      value={additionalTax !== undefined ? additionalTax : (lineItemFeeExists  ? '$' + formatNumber(product.lineItemFee.additionalTax) : ('$'))}
                      name='additionalTax'
                      className="form-control rounded-pill mt-1 "
                    /> */}
                    <NumberFormat
                              className="rounded-pill form-control"
                              // value={ Number(product?.lineItemFee?.additionalTax).toFixed(2)??Number().toFixed(2)}
                              value={ product?.lineItemFee?.additionalTax ?? 0}
                              name="additionalTax"
                              aria-label='line item fee additional tax'
                              thousandSeparator={true}
                              decimalScale={2}
                              data-testid="product.taxfee.additionalTax"
                              fixedDecimalScale
                              prefix={'$'}
                              // format={format}
                              onValueChange={ options =>handleEditTaxesAndFees(null,'additionalTax', options) }
                              onBlur={e => handleBlurFormat(e, 'additionalTax')}
                            />
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <div className="col-4 mx-3">
                    <>
                    <label htmlFor="stock">Bank Fee</label>
                    {/* <input
                      type="text"
                      onKeyDown={e => setkeyVal(e.key)}
                      onChange={e => { 
                        onBankFeeChange(e);
                        const savedPos = e.target.selectionStart;
                        setTimeout(() => {
                          resetCursor(e, savedPos);
                        }, 0);
                        }
                      }
                      onBlur={e => { 
                        formatInputBankFeeNumber(e);
                        setcursorFlag(true);
                        }
                      }
                      value={bankFee !== undefined ? bankFee : (lineItemFeeExists  ? '$' + formatNumber(product.lineItemFee.bankFee) : ('$'))}
                      name='bankFee'
                      className="form-control rounded-pill mt-1 "
                    /> */}
                    <NumberFormat
                              className="rounded-pill form-control"
                              // value={ Number(product?.lineItemFee?.bankFee).toFixed(2)??Number().toFixed(2)}
                              value={ product?.lineItemFee?.bankFee ?? 0}
                              name="bankFee"
                              aria-label='line item fee bank fee'
                              thousandSeparator={true}
                              decimalScale={2}
                              data-testid="product.taxfee.bankFee"
                              fixedDecimalScale
                              prefix={'$'}
                              // format={format}
                              onValueChange={ options =>handleEditTaxesAndFees(null,'bankFee', options) }
                              onBlur={e => handleBlurFormat(e, 'bankFee')}
                            />
                    </>
                  </div>
                  <div className="col-4 mx-3">
                  </div>
                </div>
                <div className="d-flex mb-3 ">
                  <div className="col-12 mx-2">
                    <label htmlFor="stock">Description / Notes (<span style={{color:'red',}}>Shown on invoice</span>)</label>
                    <textarea
                      onChange={e => handleEditTaxesAndFees(e,'description')}
                      maxLength={2500}
                      data-testid="product.taxfee.description"
                      aria-label='line item fee description'
                      value={product?.lineItemFee?.description ?? ''}
                      name='Description'
                      className="form-control rounded-lg mt-1 "
                      rows="5"
                    ></textarea>
                  </div>
                </div>
              </div>
              <hr className='my-3' />
              {/* ------------------------------ Row 6 ------------------------------------ */}
              <div className="row">
                <div className="d-flex mb-3">
                  <div className="col-4 mx-3">
                    <h4 data-testid="product.taxfee.calc.salesPriceTotal">${formatNumber(salesPriceWithUpgradeCalc(product))}</h4>
                    <h6 className="text-secondary text-capitalize">Sales Price Total</h6>
                  </div>
                  <div className="col-4 mx-3">
                    <h4 data-testid="product.taxfee.calc.feeTotal">${formatNumber(taxAndFeeTotalCalc())}</h4>
                    <h6 className="text-secondary text-capitalize">Tax &amp; Fee Total</h6>
                  </div>
                  <div className="col-4 mx-3">
                    <h4 data-testid="product.taxfee.calc.productSubtotal">${formatNumber(productSubtotalCalc())}</h4>
                    <h6 className="text-secondary text-capitalize">Product Subtotal</h6>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="modal-footer justify-content-center mt-2">
            {showSpinner?<CircularProgress/>:( saveSuccess ? (
              <>
                <SavedProductButton product={product} documents={documents} preCloseClose={preCloseClose} />

              {/* <span className="btn btn-success text-uppercase" disabled='true'>Saved Product Info</span>
              
              { getOtherProductsInGroup(order, product.id, product.productGroupId)?.length > 0 ? (
                <>
                  <button aria-label='Copy To Group' 
                  onClick={ (e)=>{
                    e.preventDefault()
                    let copydataChangeArray = [];
                    copydataChangeArray.id = product.id;
                    copydataChangeArray.stockNumber = product.stockNumber;
                    copydataChangeArray.propertySaved = false;
                    copydataChangeArray.docsSavedCount = 0;
                    Object.entries(changesArray).forEach( value => {
                      copydataChangeArray[value[0]] = value[1];
                    });
                    dispatch(updateStatus('copy'));
                    dispatch(updateGroupChangesArray([copydataChangeArray]));
                    dispatch(setActiveModal('copy-to-product-group'));
                  }} data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#copychangestogroupmodal" className="my-2 btn  btn-block btn-sm btn-primary text-uppercase mx-2">Copy To Group</button><br />
                </>
              ): filterDocuments()?.length > 0 ? (
                <>
                  <button aria-label='Copy to Documents' 
                  onClick={ (e)=>{
                    e.preventDefault();
                    let copydataChangeArray = [];
                    copydataChangeArray.id = product.id;
                    copydataChangeArray.stockNumber = product.stockNumber;
                    copydataChangeArray.propertySaved = false;
                    copydataChangeArray.docsSavedCount = 0;
                    Object.entries(changesArray).forEach( value => {
                      copydataChangeArray[value[0]] = value[1];
                    });
                    // ModalHelper.initalizeCopyToDocuments(ModalHelper.dataChangeArray);
                    // ModalHelper.handleClose();
                    dispatch(updateStatus('copy'));
                    dispatch(updateGroupChangesArray([copydataChangeArray]));
                    dispatch(updateCopySection('product'));
                    dispatch(updateCopySubSection('tax-and-fee'));
                    dispatch(setActiveModal('copy-to-documents'));
                  }} data-bs-toggle="modal" data-bs-target="#copychangestodocuments" className="my-2 btn  btn-block btn-sm btn-primary text-uppercase mx-2">Copy to Documents</button><br />
                </>
              ):null} */}
              </>
            ):(
              <button onClick={handleSubmit} data-testid="product.taxfee.control.saveBottom" className="btn btn-primary text-uppercase" aria-label='save'>Save</button>
          ) )}
          </div>
        </>
      ):null}
    </>
  );
}

const SavedProductButton = ({ product, documents, preCloseClose }) => {

  const order   = useOrder();
  const dispatch  = useDispatch();
  const changesArray = useSelector((state) => state?.copyChangesToDocumentsRedux.changesArray);

  function filterDocuments(){
    let productDocuments = filterDocumentsForProduct(product.id, documents);
    let orderDocuments = filterProductDocumentsForOrder(product.id, documents);

    let allFilteredDocuments = orderDocuments.concat(productDocuments);
    return allFilteredDocuments;
  }

  function prepRedux(){
    dispatch(updateCopySection('product'));
    dispatch(updateCopySubSection('tax-and-fee'));
    dispatch(setActiveModalTab(null));
  }

  function prepGroupCopy(){
    dispatch(updateGroupChangesArray([changesArray]));
    dispatch(updateStatus('copy-to-group'));
    dispatch(setActiveModal('copy-to-product-group'));
  }

  function prepDocumentCopy(){
    let copyChangesArray = [];
    copyChangesArray.id = product.id;
    copyChangesArray.stockNumber = product.stockNumber;
    copyChangesArray.propertySaved = false;
    copyChangesArray.docsSavedCount = 0;
    Object.entries(changesArray).forEach( value => {
      copyChangesArray[value[0]] = value[1];
    });
    dispatch(updateGroupChangesArray([copyChangesArray]));
    dispatch(updateStatus('copy'));
    dispatch(setActiveModal('copy-to-documents'));
  }

  return (
    <>
      <span className="btn btn-sm btn-success text-uppercase mx-2">Product Saved</span>
      { getOtherProductsInGroup(order, product.id, product.productGroupId)?.length > 0 ? (
        <>
          <button aria-label='Copy Changes to Other Products In Group' 
            onClick={ (e)=>{
              e.preventDefault()
              prepRedux();
              prepGroupCopy();
              preCloseClose();
            }} data-bs-dismiss="modal" data-bs-toggle="modal" data-testid="product.taxfee.control.copyToGroup" data-bs-target="#copychangestogroupmodal" className="btn btn-primary text-uppercase mx-2">Copy To Group</button>
        </>       
        ): filterDocuments()?.length > 0 ? (
        <>
          <button aria-label='Copy Changes to Documents' 
            onClick={ (e)=>{
              e.preventDefault();
              prepRedux();
              prepDocumentCopy();
              preCloseClose();
            }} data-bs-dismiss="modal" data-bs-toggle="modal"  data-testid="product.taxfee.control.copyToDocument" data-bs-target="#copychangestodocuments" className="btn btn-sm btn-primary text-uppercase mx-2">Copy to Documents</button>
        </>
      ):null}
    </>
  )
}

export default ModalProductTaxFee;
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams} from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import { removeChars, formatter, formatPhoneNumber, formatUpperCase } from "../../../../../../../utlis/formatters";
import { getOrderLevelAddresses, updateAddressOptions } from "../../../../../../../utlis/documentHelpers";
import { useOrder } from "../../../../../../../redux/orders/order"
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { CACertificateOfNonOperationGlobalAutofill, CACertificateOfNonOperationAutofillProductDetails, preProcessPayload } from "../DocumentAutofill/CACertificateOfNonOperation"

const CACertificateOfNonOperation = ({document_id, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "ca-non-opp";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  
  // App Logic & Data //
  let { id } = useParams();
  const order = useOrder();
  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);
  
  // Data Structure & Init //
  
  var initialDocumentStructure = {
    address: undefined,
    city: undefined,
    state: undefined,
    zip: undefined,
    phone: undefined,
    from_month: undefined,
    from_day: undefined,
    from_year: undefined,
    to_month: undefined,
    to_day: undefined,
    to_year: undefined,
    vin: undefined,
    vin_2: undefined,
    vin_3: undefined,
    vin_4: undefined,
    vin_5: undefined,
    vin_6: undefined,
    vin_7: undefined,
    license_plate: undefined,
    license_plate_2: undefined,
    license_plate_3: undefined,
    license_plate_4: undefined,
    license_plate_5: undefined,
    license_plate_6: undefined,
    license_plate_7: undefined,
    make: undefined,
    make_2: undefined,
    make_3: undefined,
    make_4: undefined,
    make_5: undefined,
    make_6: undefined,
    make_7: undefined,
    equip: undefined,
    equip_2: undefined,
    equip_3: undefined,
    equip_4: undefined,
    equip_5: undefined,
    equip_6: undefined,
    equip_7: undefined,
    copy_from_month: undefined,
    copy_from_day: undefined,
    copy_from_year: undefined,
    copy_to_month: undefined,
    copy_to_day: undefined,
    copy_to_year: undefined,
    copy_vin: undefined,
    copy_vin_2: undefined,
    copy_vin_3: undefined,
    copy_vin_4: undefined,
    copy_vin_5: undefined,
    copy_vin_6: undefined,
    copy_vin_7: undefined,
    copy_make: undefined,
    copy_make_2: undefined,
    copy_make_3: undefined,
    copy_make_4: undefined,
    copy_make_5: undefined,
    copy_make_6: undefined,
    copy_make_7: undefined,
    copy_license_plate: undefined,
    copy_license_plate_2: undefined,
    copy_license_plate_3: undefined,
    copy_license_plate_4: undefined,
    copy_license_plate_5: undefined,
    copy_license_plate_6: undefined,
    copy_license_plate_7: undefined,
    copy_address: undefined,
    copy_city: undefined,
    copy_state: undefined,
    copy_zip: undefined,
    date_certified: undefined,
    area_code: undefined,
    copy_area_code: undefined,
    copy_phone: undefined,
    copy_date_certified: undefined,
    planned_license_plate: undefined,
    planned_license_plate_2: undefined,
    planned_license_plate_3: undefined,
    planned_license_plate_4: undefined,
    planned_license_plate_5: undefined,
    planned_license_plate_6: undefined,
    planned_license_plate_7: undefined,
    planned_license_plate_8: undefined,
    planned_license_plate_9: undefined,
    planned_date_certified: undefined,
    planned_area_code: undefined,
    planned_phone: undefined,
    copy_planned_date_certified: undefined,
    copy_planned_area_code: undefined,
    copy_planned_phone: undefined,
    copy_planned_license_plate: undefined,
    copy_planned_license_plate_2: undefined,
    copy_planned_license_plate_3: undefined,
    copy_planned_license_plate_4: undefined,
    copy_planned_license_plate_5: undefined,
    copy_planned_license_plate_6: undefined,
    copy_planned_license_plate_7: undefined,
    copy_planned_license_plate_8: undefined,
    copy_planned_license_plate_9: undefined,
    planned_vin: undefined,
    planned_vin_2: undefined,
    planned_vin_3: undefined,
    planned_vin_4: undefined,
    planned_vin_5: undefined,
    planned_vin_6: undefined,
    planned_vin_7: undefined,
    planned_vin_8: undefined,
    planned_vin_9: undefined,
    planned_make: undefined,
    planned_make_2: undefined,
    planned_make_3: undefined,
    planned_make_4: undefined,
    planned_make_5: undefined,
    planned_make_7: undefined,
    planned_make_6: undefined,
    planned_make_8: undefined,
    planned_make_9: undefined,
    planned_equip: undefined,
    planned_equip_2: undefined,
    planned_equip_3: undefined,
    planned_equip_4: undefined,
    planned_equip_5: undefined,
    planned_equip_6: undefined,
    planned_equip_7: undefined,
    planned_equip_8: undefined,
    planned_equip_9: undefined,
    copy_planned_vin: undefined,
    copy_planned_vin_2: undefined,
    copy_planned_vin_3: undefined,
    copy_planned_vin_4: undefined,
    copy_planned_vin_5: undefined,
    copy_planned_vin_6: undefined,
    copy_planned_vin_7: undefined,
    copy_planned_vin_8: undefined,
    copy_planned_vin_9: undefined,
    copy_planned_make: undefined,
    copy_planned_make_2: undefined,
    copy_planned_make_3: undefined,
    copy_planned_make_4: undefined,
    copy_planned_make_5: undefined,
    copy_planned_make_6: undefined,
    copy_planned_make_7: undefined,
    copy_planned_make_8: undefined,
    copy_planned_make_9: undefined,
    copy_equip: undefined,
    copy_equip_2: undefined,
    copy_equip_3: undefined,
    copy_equip_4: undefined,
    copy_equip_5: undefined,
    copy_equip_6: undefined,
    copy_equip_7: undefined,
    copy_equip_8: undefined,
    copy_equip_9: undefined,
    copy_planned_equip: undefined,
    copy_planned_equip_2: undefined,
    copy_planned_equip_3: undefined,
    copy_planned_equip_4: undefined,
    copy_planned_equip_5: undefined,
    copy_planned_equip_6: undefined,
    copy_planned_equip_7: undefined,
    copy_planned_equip_8: undefined,
    copy_planned_equip_9: undefined,
  }
  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);

  const moneyFields = useMemo(()=>{return[]},[]);
  const phoneFields = useMemo(()=>{return[]},[]);
  const checkboxes = useMemo(()=>{return[]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "vin_2", "vin_3", "vin_4", "vin_5", "vin_6", "vin_7", "planned_vin", "planned_vin_2", "planned_vin_3", "planned_vin_4", "planned_vin_5", "planned_vin_6", "planned_vin_7", "planned_vin_8", "planned_vin_9"]},[]);

  // X1- Current General On Change
  const handleDocumentValuesChange = e =>{
    e.preventDefault();
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatPhoneNumber(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  
  const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('CACertificateOfNonOperation.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, documentLoaded, loadDocument ])
  const [assignedProduct, setAssignedProduct] = useState(undefined);
  // const [productVal, setproductVal] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  // const [selectedProductPlanned, setselectedProductPlanned] = useState(undefined);
  // const [productPlannedVal, setproductPlannedVal] = useState(undefined);
  // const [inputProductPlannedVal, setinputProductPlannedVal] = useState(undefined);
  // const [productPlannedToggleAutoFill, setProductPlannedToggleAutoFill] = useState(false);

  // const [tradeinVal, setTradeinVal] = useState(undefined);
  // const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  // const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const [addressOptions, setAddressOptions] = useState(undefined);
  const [inputAddressVal, setinputAddressVal] = useState(undefined);




// Dropdowns //

  // if(order && order.products && order.products.length > 0 && productOptions === undefined){
  //   let getProductOptions = [];
  //   order.products.map(product => {
  //     let str = `${product.stockNumber.toString().toUpperCase()}`;
  //     let obj = {id: product.id, name: str}
  //     getProductOptions.push(obj);
  //   })
  //   setproductOptions(getProductOptions);
  // }

  // if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
  //   let getTradeinOptions = [];
  //   order.tradeins.map(tradein => {
  //     let str = `${tradein.year} ${tradein.make} ${tradein.model.toString().toUpperCase()}`;
  //     let obj = {id: tradein.id, name: str}
  //     getTradeinOptions.push(obj);
  //   })
  //   setTradeinOptions(getTradeinOptions);
  // }

  // function getOrderLevelAddresses(order){
  //   let getAddressOptions = [];
  //   if(order.customer && order.customer.deliveryAddress){
  //     var addressLine = '';
  //     if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
  //       addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
  //     }else{
  //       addressLine = order.customer.deliveryAddress.addressLine1;
  //     }
  //     let city = order.customer.deliveryAddress.city;
  //     let state = order.customer.deliveryAddress.state;
  //     let zip = order.customer.deliveryAddress.zip;

  //     let obj = {slug: "customer-delivery", name: "Customer Delivery", address: addressLine, city: city, state: state, zip: zip};
  //     getAddressOptions.push(obj);
  //   }

  //   if(order.customer && order.customer.mailingAddress){

  //     if(order.customer.mailingAddress.addressLine2 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
  //       addressLine = (order.customer.mailingAddress.addressLine1+" "+order.customer.mailingAddress.addressLine2);
  //     }else{
  //       addressLine = order.customer.mailingAddress.addressLine1;
  //     }
  //     let city = order.customer.mailingAddress.city;
  //     let state = order.customer.mailingAddress.state;
  //     let zip = order.customer.mailingAddress.zip;

  //     let obj = {slug: "customer-mailing", name: "Customer Mailing", address: addressLine, city: city, state: state, zip: zip};
  //     getAddressOptions.push(obj);
  //   }
  //   return getAddressOptions;
  // }

 // Autofill //

const GlobalAutofill = useCallback(async () => {
  let newValues = CACertificateOfNonOperationGlobalAutofill(documentValues, order);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  // var newValues = documentValues;
  // if(order && order.customer && order.customer.phone){
  //   newValues.area_code = getAreaCode(order.customer.phone);
  //   newValues.phone = getPhoneNumber(order.customer.phone);
  //   newValues.planned_area_code = getAreaCode(order.customer.phone);
  //   newValues.planned_phone = getPhoneNumber(order.customer.phone);
  // }

  // if(order && order.documentationDate){
  //   newValues.date_certified = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  //   newValues.copy_date_certified = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  //   newValues.planned_date_certified = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  //   newValues.copy_planned_date_certified = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  // }

  // setDocumentValues(newValues);
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ documentValues, order ])

// const preProcessPayload = useCallback((payload) => {

//   // X1.2- Field Type Specific Preview Formatting
//   Object.keys(payload).forEach(key => {
//     if(moneyFields.indexOf(key) >= 0){
//         payload[key] = formatMoneyValue(payload[key]);
//     }else if(phoneFields.indexOf(key) >= 0){
//         payload[key] = formatPhoneNumber(payload[key]);
//     }else if(upperCaseFields.indexOf(key) >= 0){
//         payload[key] = formatUpperCase(payload[key]);
//     }
//   });
//   setDocumentValues(JSON.parse(JSON.stringify(payload)));
//   Object.keys(payload).forEach(key => {
//     if(checkboxes.indexOf(key) >= 0){
//       payload[key] = payload[key] ? "yes": "no";
//     }
//   });

//   // Data point formatting
//   if(payload.date_certified){
//     payload.date_certified = moment.utc(payload.date_certified).format("MM/DD/YY");
//   }

//   if(payload.planned_date_certified){
//     payload.planned_date_certified = moment.utc(payload.planned_date_certified).format("MM/DD/YY");
//   }

//   if(payload.copy_date_certified){
//     payload.copy_date_certified = moment.utc(payload.copy_date_certified).format("MM/DD/YY");
//   }

//   if(payload.copy_planned_date_certified){
//     payload.copy_planned_date_certified = moment.utc(payload.copy_planned_date_certified).format("MM/DD/YY");
//   }

//   Object.keys(payload).forEach(key => {
//     if(!key.includes("copy_")){
//       payload["copy_"+key] = payload[key]
//     }
//   });

//   return payload;
// },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])


const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });

    if(document){
      var docCopy = JSON.parse(JSON.stringify(document));
      docCopy.payload =  JSON.parse(JSON.stringify(payload));
      docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
      docCopy.payload =  JSON.stringify(docCopy.payload);
      delete docCopy.documentStatus;
      
      try{
        let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
          const fileURL = URL.createObjectURL(file);
          setdocumentUrl(fileURL)
      } catch (err) {
        recordFrontEndError('CACertificateOfNonOperations.jsx', ' Error previewing document payload: ' + err)
      }
    }
},[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


const AutofillProductDetails = useCallback(async (selectedProduct) => {

  let newValues = CACertificateOfNonOperationAutofillProductDetails(selectedProduct, documentValues);

  if(newValues){
  setDocumentValues(newValues);
  setDocumentValues(newValues);
  // setProductToggleAutoFill(false);
  // if(document){
  // }
    setDocumentValues(newValues);
  // setProductToggleAutoFill(false);
  // if(document){
  // }
  previewPayloadDocument(documentValues);
  previewPayloadDocument(documentValues);
  // setAddressOptions(updateAddressOptions(selectedProduct, order));
    previewPayloadDocument(documentValues);
  // setAddressOptions(updateAddressOptions(selectedProduct, order));
    setHasUnsavedChanges(true);
    previewPayloadDocument(documentValues);
    setSaveSuccess(false);
  }

  // var newValues = documentValues;
  // newValues.vin = (selectedProduct.vin);
  // newValues.make = (selectedProduct.make).trim();
  // /// selectedProductPlanned
  // newValues.planned_vin = (selectedProduct.vin);
  // newValues.planned_make = (selectedProduct.make).trim();

  // setDocumentValues(newValues);
  // // setProductToggleAutoFill(false);
  // // if(document){
  // // }
  // previewPayloadDocument(documentValues);
  // // setAddressOptions(updateAddressOptions(selectedProduct, order));
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ documentValues, previewPayloadDocument ]);


  // Document BREAD + Preview // TODO: Move to Redux
  useEffect(() => {
    if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){
  
      existingPayload = true;
  
      if(documentPayload.date_certified){
        documentPayload.date_certified = moment.utc(documentPayload.date_certified).format("YYYY-MM-DD");
      }

      if(documentPayload.planned_date_certified){
        documentPayload.planned_date_certified = moment.utc(documentPayload.planned_date_certified).format("YYYY-MM-DD");
      }

      if(documentPayload.copy_date_certified){
        documentPayload.copy_date_certified = moment.utc(documentPayload.copy_date_certified).format("YYYY-MM-DD");
      }

      if(documentPayload.copy_planned_date_certified){
        documentPayload.copy_planned_date_certified = moment.utc(documentPayload.copy_planned_date_certified).format("YYYY-MM-DD");
      }
      
      setDocumentValues(documentPayload);

      // Loading Use Effect
      if(document.additionalData){
        const methodMap = { 
          selectedAddress: setSelectedAddress, 
        };

        let parsedAdditionalData = JSON.parse(document.additionalData);
        Object.keys(parsedAdditionalData).forEach(function(key) {
          if(methodMap[key]){
            methodMap[key](parsedAdditionalData[key]);
          }
        });
      }


    }
    var foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }

    setAddressOptions(getOrderLevelAddresses(order));
    if(foundAssignedProduct){
        setAssignedProduct(foundAssignedProduct);
        setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
    }
  
      if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
      }else{
        // if(document && document.itemIds){
        //   var itemIds = document.itemIds.split(',');
        //     var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
        //     let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
        //     let obj = {id: foundProduct.id, name: str}
        //     setproductVal(obj);
        //     if(foundProduct){
        //       setAddressOptions(updateAddressOptions(foundProduct, order));
        //     }
        //     streamDocument();
        // }
  
      }
      
      setDataLoaded(true);
    }
  },[ documentValues, document, dataLoaded, AutofillProductDetails, GlobalAutofill, order, previewPayloadDocument ])

    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();

      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);

  
      //Save Dropdown Selections
      let dropdownData = {};
      if(selectedAddress){
        dropdownData.selectedAddress = {
          id: selectedAddress.id,
          name: selectedAddress.name
        };
      }
      // docCopy.additionalData = JSON.stringify(dropdownData);
      let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, additionalData, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });

      // try {
      //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
      //   if (res) {
      //     await axios.post(`/document/${document.id}/generate`)
      //     getDocuments();
      //     setSaveSuccess(true);
      //     setHasUnsavedChanges(false);
      //   }
      // } catch (err) {
      //   recordFrontEndError('CACertificateOfNonOperation.jsx', 'Error saving document: ' + err)
      // }
    }

// Component Helpers //
// useEffect(() => {
  //Autofill Product Details After Selection

//Autofill Address Details After Selection
function AutoFillAddress(addressSelected, selectionInstance){
  var newValues = documentValues;
  if(selectionInstance){
    if(selectionInstance === "fl1-address"){
      newValues.address = addressSelected.address;
      newValues.city = addressSelected.city;
      newValues.state = addressSelected.state;
      newValues.zip = addressSelected.zip;
    }
  }



  setDocumentValues(newValues);
  previewPayloadDocument(documentValues);
  setHasUnsavedChanges(true);
  setSaveSuccess(false);
}

const onCloseClick = e => {
  e.preventDefault()
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setdocumentUrl("");
  setDocument(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
  setDocumentLoaded(false);
  setDataLoaded(false);
  setAssignedProduct(undefined);
  setSelectedAddress(undefined);
  setAddressOptions(undefined);
  setinputAddressVal(undefined);
  setDocumentFocus(undefined);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

  return (
    <>
      <div
        className="modal fade"
        // id="CACertificateOfNonOperation"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                Certificate of Non-Operation
              </h5>
              <br />
              { hasClickedClose ? (
                <div className="d-flex">
                    <p className="m-1 pt-1 text-white">Save Changes?</p>
                    <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                    <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}
                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill();
                      AutofillProductDetails(assignedProduct);
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>

                  <br />

                  { assignedProduct ? (
                      <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                          Product Snapshot
                          <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                          <h5>SN: { assignedProduct.stockNumber }</h5>
                          <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                          <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                          </div>
                      </div>
                  ):(
                      <div className="m-2 p-2 border">
                          <span>No Assigned Product</span>
                      </div>
                  )}
                  
                  <h5>CA - CERTIFICATE OF NON-OPERATION</h5>
                  <div className="row">
                    <label htmlFor="stock"><b>THE VEHICLE(S) LISTED BELOW WAS (WERE) STORED </b></label>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">From Month</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="from_month"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.from_month ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">From Day</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="from_day"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.from_day ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">From Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="from_year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.from_year ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly mb-2">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">To Month</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="to_month"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.to_month ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">To Day</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="to_day"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.to_day ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">To Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="to_year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.to_year ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                    <label htmlFor="stock"><b>AT THE FOLLOWING LOCATION:</b></label>
                    {/* <div className="row"> */}
                    {/* <h5>Address Selection</h5> */}
                    {addressOptions && addressOptions.length > 0 ? (  
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress && selectedAddress.name) ?? null}
                          name="fl1-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress(newValue);
                            AutoFillAddress(newValue, "fl1-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          //id="controllable-states-demo"
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="ADDRESS" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.address ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly mb-3">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.zip ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>
                  </div>
                  
                  <div className="row">
                    <h5>Equipment</h5>

                    <label htmlFor="stock"><b>SELECTED VEHICLES</b></label>
                    <div className="d-flex justify-content-evenly" style={{ fontSize: '12px'}}>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">1 - License Plate #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="license_plate"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.license_plate ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">EQUIPMENT NO. (Optional)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="equip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.equip ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly" style={{ fontSize: '12px'}}>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">2 - License Plate #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="license_plate_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.license_plate_2 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin_2"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin_2 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make_2 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">EQUIPMENT NO. (Optional)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="equip_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.equip_2 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly" style={{ fontSize: '12px'}}>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">3 - License Plate #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="license_plate_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.license_plate_3 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin_3"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin_3 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make_3 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">EQUIPMENT NO. (Optional)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="equip_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.equip_3 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly" style={{ fontSize: '12px'}}>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">4 - License Plate #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="license_plate_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.license_plate_4 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin_4"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin_4 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make_4 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">EQUIPMENT NO. (Optional)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="equip_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.equip_4 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly" style={{ fontSize: '12px'}}>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">5 - License Plate #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="license_plate_5"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.license_plate_5 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin_5"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin_5 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make_5"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make_5 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">EQUIPMENT NO. (Optional)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="equip_5"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.equip_5 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly" style={{ fontSize: '12px'}}>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">6 - License Plate #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="license_plate_6"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.license_plate_6 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin_6"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin_6 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make_6"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make_6 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">EQUIPMENT NO. (Optional)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="equip_6"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.equip_6 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly mb-3" style={{ fontSize: '12px'}}>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">7 - License Plate #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="license_plate_7"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.license_plate_7 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin_7"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin_7 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make_7"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make_7 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">EQUIPMENT NO. (Optional)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="equip_7"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.equip_7 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly" style={{ fontSize: '12px'}}>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date_certified"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date_certified ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Area Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="area_code"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.area_code ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Telephone #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="phone"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.phone ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                    </div>

                    <hr></hr>
                    <hr></hr>

                  <h5>PLANNED NON-OPERATION CERTIFICATE</h5>

                  {/* <div className="row"> */}
                    <h5>Equipment</h5>
                    {/* {productOptions && productOptions.length > 0 ? (   
                    <div className="d-flex justify-content-evenly">
                        <div className="mb-3 col-md-12 pt-4">
                            <Autocomplete
                            style={{ width: '100%'}}
                            value={ (productPlannedVal && productPlannedVal.name) ?? null}
                            onChange={(event, newValue) => {
                                setproductPlannedVal(newValue);
                                setselectedProductPlanned(order.products.find((product) => product.id === newValue.id));
                                setProductPlannedToggleAutoFill(true);
                                // resetVariables();
                            }}
                            inputValue={inputProductVal}
                            onInputChange={(event, newInputValue) => {
                                setinputProductPlannedVal(newInputValue);
                            }}
                            //id="controllable-states-demo"
                            options={productOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="PLANNED PRODUCT" />}
                            renderOption={(props, option) => {
                                return (
                                <li {...props} key={option.id}>
                                    {option.name}
                                </li>
                                );
                            }}
                            />
                        </div>
                    </div>):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Products Found</h5>
                      </div>
                    )} */}

                    <label htmlFor="stock"><b>SELECTED VEHICLES</b></label>
                    <div className="d-flex justify-content-evenly" style={{ fontSize: '12px'}}>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">1 - License Plate #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_license_plate"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_license_plate ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_vin"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_vin ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_make ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">EQUIPMENT NO. (Optional)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_equip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_equip ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly" style={{ fontSize: '12px'}}>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">2 - License Plate #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_license_plate_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_license_plate_2 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_vin_2"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_vin_2 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_make_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_make_2 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">EQUIPMENT NO. (Optional)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_equip_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_equip_2 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly" style={{ fontSize: '12px'}}>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">3 - License Plate #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_license_plate_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_license_plate_3 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_vin_3"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_vin_3 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_make_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_make_3 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">EQUIPMENT NO. (Optional)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_equip_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_equip_3 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly" style={{ fontSize: '12px'}}>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">4 - License Plate #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_license_plate_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_license_plate_4 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_vin_4"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_vin_4 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_make_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_make_4 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">EQUIPMENT NO. (Optional)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_equip_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_equip_4 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly" style={{ fontSize: '12px'}}>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">5 - License Plate #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_license_plate_5"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_license_plate_5 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_vin_5"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_vin_5 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_make_5"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_make_5 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">EQUIPMENT NO. (Optional)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_equip_5"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_equip_5 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly" style={{ fontSize: '12px'}}>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">6 - License Plate #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_license_plate_6"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_license_plate_6 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_vin_6"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_vin_6 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_make_6"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_make_6 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">EQUIPMENT NO. (Optional)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_equip_6"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_equip_6 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly mb-3" style={{ fontSize: '12px'}}>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">7 - License Plate #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_license_plate_7"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_license_plate_7 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_vin_7"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_vin_7 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_make_7"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_make_7 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">EQUIPMENT NO. (Optional)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_equip_7"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_equip_7 ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly" style={{ fontSize: '12px'}}>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="planned_date_certified"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_date_certified ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Area Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_area_code"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_area_code ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Telephone #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="planned_phone"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.planned_phone ?? '' }
                          style={{ fontSize: '12px'}}
                          />
                      </div>
                    </div>

                </div>
                <hr></hr>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default CACertificateOfNonOperation

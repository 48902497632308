import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFetch } from "../../../../../../utlis/api";
import { setActiveModal } from "../../../../../../redux/app/appSaver";

const DocumentViewModal = ( { document } ) => {
  let { id } = useParams();
  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  const [fetchingDocument, setFetchingDocument] = useState(false);
  const dispatch        = useDispatch()

  const activeModal = useSelector((state) => state?.appData.activeModal);

  //come back to add recordfrontenderror and try/catch
  useEffect(() => {
    const setDocumentUrlVal = () => {
      setFetchingDocument(true);
      if(document && document.id){

      // axios.get(`/order/${id}/tradein/24/print-all-documents`, {responseType: 'blob'})
        axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
        .then(response => {
            const file = new Blob(
              [response.data], 
            {type: 'application/pdf'},
          );
          const fileURL = URL.createObjectURL(file);
          setdocumentUrl(fileURL);
          setFetchingDocument(false);
        }).catch(err => {
        })
      }
    }

    if(document && document.id && document.documentTemplate && documentUrl === "" && fetchingDocument === false && activeModal === "document-view"){
      setdocumentUrl("");
      setDocumentUrlVal();
    }
  }, [document, axios, id, documentUrl, fetchingDocument, activeModal]);

  // const [previousDocumentId, setPreviousDocumentId] = useState(0);

  const resetVals = event => {
    event.preventDefault();
    // setPreviousDocumentId(0);
    setdocumentUrl("");
    dispatch(setActiveModal(null));
  }


  // if(document && document.id !== previousDocumentId){
  //   setdocumentUrl("");
  //   setPreviousDocumentId(document);
  // }
  
  return (
    <>
      <div
        className="modal fade"
        id="viewDocumentModal"
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg" style={{ maxWidth: '1100px'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                View Document
              </h5>
              <div>
              </div>
              <br />
              <button
                type="button"
                className="btn-close bg-white"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetVals}
                data-testid="modal.viewDocument.control.closeButtonTop"
              ></button>
            </div>
            <div className="modal-body" style={{height: '75vh'}}>
              <embed data-testid="modal.viewDocument.embededPreview" width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DocumentViewModal

// import _ from "lodash"
// import { useSelector } from "react-redux"

import { SET_ORDER} from "../orders/order.js"
import { buildReduxActionsAndSelectors } from "../"
import { handleStateDefault } from "../"
import * as api from "../../utlis/api"

const customerInitialState = {
  modal: {
    action: 'Add',
    contacts: [],
    cosigners: [],
    editedSalesTaxExemption: null,
    deletedContactIds: {},
    deletedCosignerIds: {},
    addedContacts: [{}],
    addedCosigners: [],
    editedCosigners: [],
    editedContacts: [],
    editedCustomerInfo: {},
    editedDeliveryAddress: {},
    editedMailingAddress: {},
    hasUnsavedChanges: false,
    //Each map within will contain index keys
    //for the error associated with the item at an idx.
    errors: {
      addedContacts: {},
      addedCosigners: {},
      editedCustomerInfo: {},
      editedContacts: {},
      editedCosigners: {},
      editedDeliveryAddress: {},
      editedMailingAddress: {},
    },
    isLoadingCosignersAndContacts: false,
    isMailingAddressChecked: null,
    isOpen: false,
    isSameAsDeliveryAddressChecked: false,
    isValidAddForm: false,
    isValidEditForm: false,
    isValidTaxInfo: false,
    lastSaveTimestamp: null,
    saveCustomerSuccess: false,
    shouldShowNewContactFields: false,
    shouldShowNewCosignerFields: false
  }
}

//Build all *generated* action creators at Runtime based on the initial state.

const CUSTOMER_MODAL_REDUX_PREFIX = "CM"

const CUSTOMER_VALID_ENTITY_FIELDS = {
  editedDeliveryAddress: [
    'addressLine1',
    'addressLine2',
    'city',
    'state',
    'zip'
  ],
  editedCustomerInfo: [
    'name',
    'phone',
    'email'
  ]
}

const buildOptions = {
  fields: CUSTOMER_VALID_ENTITY_FIELDS,
  entities: [
    'editedCustomerInfo',
    'editedDeliveryAddress',
    'editedMailingAddress',
    'editedTaxInfo',
    'deletedContactIds', //track deleted contacts
    'deletedCosignerIds',//track deleted cosigners
  ],
  lists: [ //So we can edit list entities...
    'addedContacts',
    'addedCosigners',
    'editedContacts',
    'editedCosigners',
  ],
  reduxPath: "customer.modal",  //For generating selectors
  reduxPrefix: CUSTOMER_MODAL_REDUX_PREFIX,
  statePath: "modal"            //Generate actions on this path only
}

export const rdx =
  buildReduxActionsAndSelectors(customerInitialState, buildOptions)

// const CLEAR_CUSTOMER_INFO    = 'CLEAR_CUSTOMER_INFO'

// export function clearCustomerInfo({ }) {
//     return {
//       type: CLEAR_CUSTOMER_INFO
//     };
// }

/* CUSTOMER MODAL REDUCER */
export const customerReducer = ( state=customerInitialState, action ) => {

  const __reduxPrefix = action.__prefix

  switch ( action.type ) {
    case RESET_CUSTOMER_MODAL:
 

      return {
        ...state,
        modal: {
          ...customerInitialState.modal,
          //Keep the same action as before it was closed...
          // lastClosedTimestamp: state.modal.lastClosedTimestamp
        }
      }
    // case CLEAR_CUSTOMER_INFO:
    //   return {
    //     ...state,
    //     modal: {
    //       ...state.modal,
    //       editedCustomerInfo: [{}],
    //     }
    //   };
    case SET_ORDER:
      return {
        ...state,
        modal: {
          ...state.modal,
          cosigners: state.modal.cosigners,
          contacts: state.modal.contacts,
          addedCosigners: [],
          addedContacts: [{}],
          shouldShowNewContactFields: false,
          shouldShowNewCosignerFields: false,
        }
      }
    /* All custom types */
    case CLOSE_CUSTOMER_MODAL:
      return {
        ...customerInitialState,
        //Maintain the last save timestamp on close
        modal: {
          ...customerInitialState.modal,
          editedCosigners: state.modal.editedCosigners,
          editedContacts: state.modal.editedContacts,
          lastSaveTimestamp: state.lastSaveTimestamp
        }
      }
      case ADD_NEW_COSIGNER:
        return {
          ...state,
          modal:{
            ...state.modal,
            cosigners: [{name:'',phone:"", status:'new', errors:{ name:false, phone:false}},...state.modal.cosigners]
        }
      }
      case EDIT_COSIGNER:
        const { i, field, value } = action.payload;
      let arrCopy = [...state.modal.cosigners]
      arrCopy[i][field]=value
      // arrCopy[i].errors[field]=false
      if(arrCopy[i]?.status!=='new' || !arrCopy[i]?.status) arrCopy[i].status='edit'
      return {
        ...state,
        modal: {
          ...state.modal,
          cosigners: arrCopy,
        }
      };
      case DELETE_COSIGNER:
      return {
        ...state,
        modal: {
          ...state.modal,
          cosigners: action.payload
        }
      };

    default:
      //Handle all generated state updates...
      if ( __reduxPrefix === CUSTOMER_MODAL_REDUX_PREFIX ) {
        return handleStateDefault(state, action)
      }
  }

  return state
}

/* CUSTOM CUSTOMER MODAL ACTIONS */
const CLOSE_CUSTOMER_MODAL    = 'CLOSE_CUSTOMER_MODAL'
const OPEN_CUSTOMER_MODAL     = 'OPEN_CUSTOMER_MODAL'
// const HIDE_NEW_CONTACT_FIELDS = 'HIDE_NEW_CONTACT_FIELDS'
// const SHOW_NEW_CONTACT_FIELDS = 'SHOW_NEW_CONTACT_FIELDS'
const RESET_CUSTOMER_MODAL = 'RESET_CUSTOMER_MODAL'


/* DATA ACTIONS */
// const DELETE_CONTACT_DONE                         = 'DELETE_CONTACT_DONE'
const GET_CUSTOMER_COSIGNERS_AND_CONTACTS_STARTED = 'GET_CUSTOMER_COSIGNERS'
const GET_CUSTOMER_COSIGNERS_AND_CONTACTS_DONE    = 'GET_CUSTOMER_CONTACTS_DONE'
const GET_CUSTOMER_COSIGNERS_ERROR    = 'GET_CUSTOMER_CONTACTS_ERROR'
// const GET_CUSTOMER_CONTACTS_STARTED   = 'GETING_CUSTOMER_CONTACTS'
// const GET_CUSTOMER_CONTACTS_DONE      = 'GET_CUSTOMER_CONTACTS_DONE'
// const GET_CUSTOMER_CONTACTS_ERROR     = 'GET_CUSTOMER_CONTACTS_ERROR'
const POST_CUSTOMER_STARTED           = 'POST_CUSTOMER_STARTED'
const POST_CUSTOMER_SUCCEEDED         = 'POST_CUSTOMER_SUCCEEDED'
const POST_CUSTOMER_ERROR             = 'POST_CUSTOMER_ERROR'
const ADD_NEW_COSIGNER                = 'ADD_NEW_COSIGNER'
const EDIT_COSIGNER                = 'EDIT_COSIGNER'
const DELETE_COSIGNER                = 'DELETE_COSIGNER'

// const deleteContactDone = deletedIndex => {
//   return {
//     type: DELETE_CONTACT_DONE,
//   }
// }

// function addCosigner(obj){
//   return {
//     type:ADD_NEW_COSIGNER,
//     payload:obj
//   }
// }
// function reducer(state = customerInitialState, action) {
//   switch (action.type) {
//     case ADD_NEW_COSIGNER:
//       return {
//         ...state,
//         items: [...state.items, action.payload]
//       };
//     default:
//       return state;
//   }
// }

rdx.saveCustomer = ({
  orderId,
  customerId, //null if a new customer
  customerObj,
  newContacts=[],
  editedContacts=[],
  cosigners,
  deletedContacts=[],
  deletedCosigners,
  }) => {

  return dispatch => {
    //Save the existing customer or create a new customer...
    var saveCustomerDone = null;

    delete customerObj.id;
    if(customerId){
      saveCustomerDone = api.updateExistingCustomer(orderId, customerId, customerObj)
    }else{
      saveCustomerDone = api.addCustomer(orderId, customerObj);
    }

    saveCustomerDone
    .then( res => {
      const customerIdFromSave = customerId ? customerId :
        res?.data.id

      if ( !customerIdFromSave ) {
        return; //Unable to update additional fields without the customer id...
      }

      const editContactsDone = editedContacts.map( contact => {
        return api.updateCustomerContact(orderId,customerIdFromSave, contact.id, contact)
      })
      const cosignersDone = cosigners.length>0 ? cosigners.map( cosigner => {
        let {status, errors, ...signerObj} =cosigner
        let formattedObj = {...signerObj, phone:signerObj.phone.replace(/[^\d]/g, "")}
        try{
          if (status==='edit'){
            return api.updateCustomerCosigner(orderId, customerIdFromSave, signerObj.id, signerObj)
          } 
          if(status==='new'){
            return api.saveNewCustomerCosigner(orderId, customerIdFromSave, formattedObj)
          }
          else return null
        }catch(err){
          throw err
        }
      }):[]

      const saveNewContactsDone = newContacts.map(contact => {
        return api.saveCustomerContact(orderId, customerIdFromSave, contact)
      })

      // const saveNewCosignersDone = newCosigners.map(cosigner => {
      // })

      const deleteContactsDone = deletedContacts.map(contactId => {
        return api.deleteCustomerContact(orderId, customerIdFromSave, contactId)
      })

      const deleteCosignersDone = deletedCosigners.map(cosignerId => {
        return api.deleteCustomerCosigner(orderId, customerIdFromSave, cosignerId)
      })

      Promise.all([
        editContactsDone,
        saveNewContactsDone,
        cosignersDone,
        deleteContactsDone,
        deleteCosignersDone,
      ])
      .catch(err => {
      })
      .then(() => {
        dispatch(rdx.setEditedCustomerInfoName(''))
        // window.location.reload(false);
        //Tell the user & refresh the order
        dispatch(rdx.setSaveCustomerSuccess(true))
        dispatch(rdx.setHasUnsavedChanges(false))
        dispatch(rdx.setLastSaveTimestamp(new Date().getTime()))
      })
      // dispatch(setShowSpinner(false))
    })

  }
}

rdx.closeCustomerModal = () => {
  return {
    type: CLOSE_CUSTOMER_MODAL,
  }
}

rdx.openCustomerModal = () => {
  return {
    type: OPEN_CUSTOMER_MODAL,
  }
}


rdx.resetCustomerModal = () => {
  return {
    type: RESET_CUSTOMER_MODAL
  }
}

/* CUSTOMER COSIGNER ACTIONS */

rdx.setGetCosignersAndContactsDone = ( cosigners, contacts ) => {
  return {
    type: GET_CUSTOMER_COSIGNERS_AND_CONTACTS_DONE,
    payload: { cosigners, contacts }
  }
}

rdx.setGetCosignersAndContactsError = err => {
  return {
    type: GET_CUSTOMER_COSIGNERS_ERROR,
    payload: err
  }
}

rdx.setGetCosignersAndContactsStarted = () => {
  return {
    type: GET_CUSTOMER_COSIGNERS_AND_CONTACTS_STARTED
  }
}

rdx.getCosignersAndContacts = options => {

  const {
    orderId,
    customerId,
  } = options

  return dispatch => {

    dispatch(rdx.setGetCosignersAndContactsStarted())

    api.getCosignersAndContacts(orderId, customerId)
      .then( data => {
        const { cosigners, contacts } = data
        dispatch(rdx.setGetCosignersAndContactsDone(cosigners, contacts))
      })
      .catch( err => dispatch(rdx.setGetCosignersAndContactsError(err)))
  }
}

rdx.deleteCustomerCosigner = (orderId, customerId, contactId) => {
  return dispatch => {
    api.deleteCustomerCosigner(orderId,customerId, contactId)
  }
}

rdx.deleteCustomerContact = (orderId, customerId, cosignerId) => {
  return dispatch => {
    api.deleteCustomerContact(orderId,customerId, cosignerId)
  }
}

rdx.saveNewCustomerCosigner = (orderId, customerId, cosigner) => {
  return dispatch => {
    api.saveNewCustomerCosigner(orderId, customerId, cosigner)
    .then(() => {
      dispatch(rdx.setSaveCustomerSuccess(true))
      dispatch(rdx.setHasUnsavedChanges(false))
    })
  }
}

rdx.saveNewCustomer = ( orderId, customerData ) => {

  return dispatch => {
    dispatch({ type: POST_CUSTOMER_STARTED })
    
    api.addCustomer(orderId, customerData)
    .then(customer => {
      dispatch({
        type: POST_CUSTOMER_SUCCEEDED,
        payload: customer
      })
    })
    .catch( err => {
      dispatch({
        type: POST_CUSTOMER_ERROR,
        payload: err
      })
    })
  }
}

/* CONTACTS */
// rdx.useContacts = () => {
//   return useSelector(state => state.order?.customer?.contacts)
// }

// rdx.useContacts = useContacts

// rdx.useContacts = () => {
//   return useSelector(state => state.order?.customer?.contacts, []);
// }

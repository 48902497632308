import moment from 'moment'
import { formatNameLastMiddleFirst, formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";

export const SD45DayExtGlobalAutofill  = (documentValues, order, property=null, value=null) => {

    var newValues = documentValues;

    if(order && order.customer){
      
      if(property?.includes('customer') || property === null){

        if(order && order.customer && order.customer.name){
          newValues.phone_number = order.customer.phone;

          if(order.customer.type === "entity"){
            newValues.purchasers_name = order.customer.name;
            if(order.customer.dba){
              newValues.purchasers_name = order.customer.name+" dba "+order.customer.dba;
            }
          }else{
            newValues.purchasers_name = formatNameLastMiddleFirst(order.customer.name);
            if(order.customer.dba){
              newValues.purchasers_name = order.customer.name+" dba "+order.customer.dba;
            }
          }
        }

        if(order && order.customer && order.customer.deliveryAddress){
          newValues.address = order.customer.deliveryAddress.addressLine1;
          if(order.customer.deliveryAddress.addressLine1 !== null && order.customer.deliveryAddress.addressLine2 !== null && order.customer.deliveryAddress.addressLine2 !== ''){
            newValues.address = newValues.address+" "+order.customer.deliveryAddress.addressLine2;
          }

          newValues.address = newValues.address+" "+order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
        }

      }
    }

    if(property?.includes('documentationDate') || property === null){

      if(order && order.documentationDate){
        newValues.date_of_sale = moment.utc(order.documentationDate).format("YYYY-MM-DD");
        newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
      }

    }
    
    return newValues
}

export const SD45DayExtAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
  var newValues = documentValues;

  if(property === 'year' || property === null){
    newValues.year = (selectedProduct.year);
  }

  if(property === 'vin' || property === null){
    newValues.vinhin = (selectedProduct.vin).toString().toUpperCase();
  }

  if(property === 'make' || property === null){
    newValues.make = (selectedProduct.make).toString().toUpperCase();
  }

  if(property === 'model' || property === null){
    newValues.model = (selectedProduct.model).toString().toUpperCase();
  }

  if(property?.includes('fundingDetail') || property?.includes('fundingMethod') || property?.includes('shipTo') || property === null){
    if(selectedProduct.fundingDetail && (selectedProduct.fundingDetail.fundingMethod === "tec-financing" || selectedProduct.fundingDetail.fundingMethod === "finance-only" || selectedProduct.fundingDetail.fundingMethod === "direct-deal")){
      newValues.lienholder_name = selectedProduct.fundingDetail.finalizedLendingOption.bankName;
      newValues.address_2 = selectedProduct.fundingDetail.finalizedLendingOption.lienAddress;
      newValues.city = selectedProduct.fundingDetail.finalizedLendingOption.lienCity;
      newValues.state = selectedProduct.fundingDetail.finalizedLendingOption.lienState;
      newValues.zip_code = selectedProduct.fundingDetail.finalizedLendingOption.lienZip;   
    }else if(selectedProduct.fundingDetail && selectedProduct.fundingDetail.fundingMethod === "cash-with-lien"){
      newValues.lienholder_name = selectedProduct.fundingDetail.cashLienHolder.bankName;
      newValues.address_2 = selectedProduct.fundingDetail.cashLienHolder.addressLine1+" "+selectedProduct.fundingDetail.cashLienHolder.addressLine2;
      newValues.city = selectedProduct.fundingDetail.cashLienHolder.city;
      newValues.state = selectedProduct.fundingDetail.cashLienHolder.state;
      newValues.zip_code = selectedProduct.fundingDetail.cashLienHolder.zip;
    }else if(selectedProduct.shipTo){
      newValues.lienholder_name = selectedProduct.shipTo.name;
      newValues.address_2 = selectedProduct.shipTo.addressLine1+" "+selectedProduct.shipTo.addressLine2;
      newValues.city = selectedProduct.shipTo.city;
      newValues.state = selectedProduct.shipTo.state;
      newValues.zip_code = selectedProduct.shipTo.zip;
    }
  }

  return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  if(payload.date_of_sale){
    payload.date_of_sale = moment.utc(payload.date_of_sale).format("MM/DD/YY");
  }

  if(payload.date){
    payload.date = moment.utc(payload.date).format("MM/DD/YY");
  }

  return payload;
}
export const GET_ORDER_STATUSES   = "GET_ORDER_STATUSES";
export const LOAD_ORDER_STATUSES  = "LOAD_ORDER_STATUSES";

export const getOrderStatuses = () => {
    return {
        type: GET_ORDER_STATUSES
    }
}

const defaultOrdersState = {
    order_statuses: [],
};

export const orderStatusesReducer = (store = defaultOrdersState , action) => {
    switch (action.type) {
        // case SET_PRODUCT_FOCUS: 
        //     return {
        //         ...store,
        //         product_focus_id: action.payload.id
        //     }
        case GET_ORDER_STATUSES: 
            return store.order_statuses;
        default:
            return store
     }
}

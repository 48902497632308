import React from "react";
import { useDispatch } from "react-redux";
import { Accordion, Card, Dropdown } from "react-bootstrap";
import ProductAdditions from "./ProductAdditions";
import ProductUpgrades from "./ProductUpgrades";
import ProductDeposits from "./ProductDeposits";
import { Divider } from '@mui/material';
import "./Style/orderquote.css";
import { useOrder } from "../../../../../../redux/orders/order"
import { setActiveModal, setActiveModalTab } from '../../../../../../redux/app/appSaver';

import { formatter, formatNumber } from "../../../../../../utlis/formatters";
import { setProductFocus } from "../../../../../../redux/products/productFocusReducer";
import { setProduct } from "../../../../../../redux/products";
import ProductInfoTemplate from "./ProductInfoTemplate";
import {getTotalSalesPrice, calcTaxFeeSubtotal, calculateProductSubtotal} from "../../../../../../utlis/productCalculations";
import { enableTireTax } from "../../../../../../utlis/fastLogicUtils";


const ProductSection = ({location, isOpen, setIsOpen, products}) => {
  const dispatch  = useDispatch();
  let order       = useOrder();

  // const [selectedProduct, setselectedProduct] = useState(undefined);

  // const deleteProduct = async (e, orderId, productId) => {
  //   e.preventDefault();
  //   try{
  //     await api.deleteProduct(orderId, productId)
  //   } catch (err){
  //     recordFrontEndError('ProductSection.js', 'Error deleting product: ' + err)
  //   } 
  //   history.push(`/order/${id}`);
  // }

  const calcTotalFandI = (product) => {
    if(product) {
      let adminFeeVal = product && product.fundingDetail && product.fundingDetail.finalizedLendingOption && product.fundingDetail.finalizedLendingOption.adminFee ? product.fundingDetail.finalizedLendingOption.adminFee : 0;
      let reserveVal = product && product.fundingDetail && product.fundingDetail.finalizedLendingOption && product.fundingDetail.finalizedLendingOption.reserve ? product.fundingDetail.finalizedLendingOption.reserve : 0;
      let insIncomeVal = product && product.fundingDetail && product.fundingDetail.finalizedLendingOption && product.fundingDetail.finalizedLendingOption.insuranceIncome ? product.fundingDetail.finalizedLendingOption.insuranceIncome : 0;
      return adminFeeVal + reserveVal + insIncomeVal;
    } else {
      return 0;
    }
  }

  return (
    <div>
    <div>
      <Accordion defaultActiveKey="0">
        
        {products ? ( products.sort((a, b) => a.stockNumber?.localeCompare(b.stockNumber, undefined, { numeric: true })).map(product => (
            <Card className="p-3" key={product.id}>
            { /*Suggestion (Michael) -
              Right now, every render we have to calculate the product subtotal.
              We could useEffect, and dispatch an event so we only have to calculate the
              value with the order or product changes...
            */} 
              <ProductInfoTemplate ymm={product.type+" "+product.year.replace('"', '').replace('"', '')+" "+product.make+" "+product.model} stockNum={product.stockNumber} eventKey={product.id} salePrice={getTotalSalesPrice(product)} subTotal={formatter.format(calculateProductSubtotal(order, product))} 
              isOpen={isOpen} setIsOpen={setIsOpen} selectedProduct={isOpen.selectedProduct}
              > 
                <Dropdown.Item
                  data-bs-toggle="modal"
                  data-bs-target="#productModal"
                  onClick={(e) => {
                    dispatch(setProduct(product));
                    dispatch(setActiveModal('product'));
                    dispatch(setActiveModalTab('details'));
                  }}>
                  <b>Edit Product</b> 
                </Dropdown.Item>
                <Divider />
                {/*<Dropdown.Item
                  onClick={(e) => deleteProduct(e, id, product.id)}>
                  <b>Delete Product</b> 
                </Dropdown.Item>*/}
                <Dropdown.Item
                  data-bs-toggle="modal"
                  data-bs-target="#duplicateProductModal"
                  onClick={(e) => {
                    dispatch(setProductFocus(product));
                  }
                }
                >
                  <b>Duplicate Product</b>
                </Dropdown.Item>
                <Divider />
                <Dropdown.Item
                  data-bs-toggle="modal"
                  data-bs-target="#productmodaldeleteconfirm"
                  onClick={e => dispatch(setProductFocus(product))}
                >
                  <b>Delete Product</b>
                </Dropdown.Item>
              </ProductInfoTemplate>
              <Accordion.Collapse eventKey={ product.id } className="p-3" in={ isOpen.selectedProduct === product.id }>
                <div className="p-4">
                  <h5 className="mb-2">PRODUCT DETAILS</h5>
                  {/* <div className="p-4"> */}
                    {/* <div className="d-flex justify-content-between">
                      <div className="p-4">
                        <p className="text-secondary textCapitalize">YMM</p>
                        { product && product.year ?(
                        <h5>{product.year} {product.make} {product.model}</h5>
                        ):('')}
                      </div>
                      <div className="p-4">
                        <p className="text-secondary textCapitalize">SALE PRICE</p>
                        { product && product.price ?(
                        <h5>{ formatter.format(product.price) }</h5>
                        ):('')}
                      </div>
                      <div className="p-4">
                        <p className="text-secondary textCapitalize">SUBTOTAL</p>
                        { product && product.subtotal ?(
                        <h5></h5>
                        ):('')}
                      </div>
                      <div className="p-4">
                        <p className="text-secondary textCapitalize">STOCK NO.</p>
                        { product && product.stockNumber ?(
                        <h5>{product.stockNumber}</h5>
                        ):('')}
                      </div>
                    </div> */}
                  {/* </div> */}

                  <div className="p-4">
                    <div className="d-flex justify-content-between">
                      <div className="p-1">
                        { product && product.vin ?(
                          <h5 className="text-uppercase">{ product.vin }</h5>
                        ):null}
                        <p className="text-secondary pt-3">VIN</p>
                      </div>
                      {/*(product.type.toString().toLowerCase() === 'new' && product.lineItemFee && product.lineItemFee.applyFet == true) ? (
                      <div className="p-1">
                        { product && product.lineItemFee && product.lineItemFee.fet ?(
                          <h5>{ formatter.format(product.lineItemFee.fet) }</h5>
                        ):null}
                        <p className="text-secondary pt-3">FET</p>
                      </div>
                        ) : null */}
                      {/*(product.type.toString().toLowerCase() === 'new' && product.lineItemFee && product.lineItemFee.applyFet == false) ? (
                      <div className="p-1">
                        { product && product.lineItemFee ?(
                          <h5>{ product.lineItemFee.fetExemptReason ?? "--"}</h5>
                        ):null}
                        <p className="text-secondary pt-3">*FET Exempt</p>
                      </div>
                        ) : null */}
                      <div className="p-1">
                        { product && product.registrationAddress?.state ?(
                          <h5>{ product.registrationAddress?.state }</h5>
                        ):null}
                        <p className="text-secondary pt-3">State Registered</p>
                      </div>
                      { (product?.lineItemFee?.enableTireTax === true || enableTireTax(order, product)) ? (
                        <div className="p-1">
                        { product && product.lineItemFee && product.lineItemFee.tireTaxTotal ? (
                        <h5>{formatter.format(product.lineItemFee.tireTaxTotal ?? "--")}</h5>
                        ): <h5>--</h5>}
                        <p className="text-secondary pt-3">Tire Tax Total</p>
                      </div>
                      ) : null }
                      <div className="p-1">
                        { product && product.tecSalesDepartmentCode ?(
                          <h5>{ product.tecSalesDepartmentCode }</h5>
                        ): <h5>--</h5>}
                        <p className="text-secondary pt-3">Sales Department Code</p>
                      </div>
                      
                    </div>
                  </div>

                  <div className="p-4">
                    <div className="p-1">
                      { product && product.lineItemFee && product.lineItemFee.description ?(
                      <h5>{product.lineItemFee.description}</h5>
                      ): <h5>--</h5>}
                      <p className="text-secondary pt-3">Product Description / Notes <span className="text-danger">(Shown on Invoice)</span></p>
                    </div>
                  </div>

                  <div className="p-4">
                    <div className="d-flex justify-content-between">
                  {product && product.extendedWarranty ? (
                  <div className="p-4">
                    <h5 className="pt-3 text-uppercase">Extended Warranty</h5>
                    <div className="d-flex justify-content-between">
                    <div className="p-1">
                      <h5>{'$' + formatNumber(product.extendedWarranty.price)}</h5>
                      <p className="text-secondary pt-3">Warranty Price</p>
                    </div>
                    <div className="p-1">
                      <h5>{product.extendedWarranty.provider}</h5>
                      <p className="text-secondary pt-3">Provider</p>
                    </div>
                    <div className="p-1 mx-5">
                      <h5>{product.extendedWarranty.description}</h5>
                      <p className="text-secondary pt-3">Warranty Description</p>
                    </div>
                    </div>
                  </div>
                  ):null}

                  { product && product.gapInsurance ? (
                    <div className="p-4">
                      <h5 className="pt-3 text-uppercase">GAP Waiver</h5>
                      <div className="d-flex justify-content-between">
                      <div className="p-1">
                        <h5>{product.gapInsurance.provider}</h5>
                        <p className="text-secondary pt-3">Provided By</p>
                      </div>
                      <div className="p-1 mx-5">
                        <h5>{'$' + formatNumber(product.gapInsurance.amount)}</h5>
                        <p className="text-secondary pt-3">Amount</p>
                      </div>
                      </div>
                    </div>
                  ):null}
                  </div>
                  </div>

                  { product && product.productNotes ?(
                    <div className="p-4">
                      <p className="text-secondary pt-3">Product Notes</p>
                      <h5 className="text-uppercase">{ product.productNotes }</h5>
                    </div>
                  ):null}



                  <ProductAdditions additions={ product.additions } />
                  <br />
                  <ProductUpgrades upgrades={ product.upgrades } />
                  <br />
                  <ProductDeposits deposits={ product.deposits } />

                  {product && (product.shipTo || product.soldTo) ? (
                  <div className="p-4">
                    <div className="d-flex justify-content-around">
                      <div className="p-1 pt-1">
                          {product && product.shipTo && product.shipTo.addressLine1 ? (
                            
                              <h5 className="">{ product.shipTo.addressLine1 }</h5>
                              ) : null}
                          { product && product.shipTo && product.shipTo.addressLine2 ? (
                            <h5 className="">{ product.shipTo.addressLine2 }</h5>
                            ):('')}
                          {product && product.shipTo ? (
                            <h5 className="">{ product.shipTo.city}, { product.shipTo.state} { product.shipTo.zip}</h5>
                          ):('')}
                          {product && product.shipTo ? (
                          <p className="mx-0 text-secondary pt-3">Ship To</p>
                          ):('')}
                        </div>
                        <div className="p-1 pt-1">
                          {product && product.soldTo && product.soldTo.addressLine1 ? (
                            
                              <h5 className="">{ product.soldTo.addressLine1 }</h5>
                              ) : null}
                          { product && product.soldTo && product.soldTo.addressLine2 ? (
                            <h5 className="">{ product.soldTo.addressLine2 }</h5>
                            ):('')}
                          {product && product.soldTo ? (
                            <h5 className="">{ product.soldTo.city}, { product.soldTo.state} { product.soldTo.zip}</h5>
                          ):('')}
                          {product && product.soldTo ? (
                          <p className="mx-0 text-secondary pt-3">Sold To</p>
                          ):('')}
                        </div>
                      </div>
                  </div>
                  ) : null }
                { order?.customer?.isExemptSalesTax === false ? (
                  <div className="p-4">
                    <div className="d-flex justify-content-between">
                      <div className="p-1">
                          <h5 className="text-uppercase">{ '$' + formatNumber(getTotalSalesPrice(product)) }</h5>
                        <p className="text-secondary pt-3">Total Subject to Sales Tax</p>
                      </div>
                      <div className="p-1">
                        {order && order.customer && order.customer.salesTaxCounty ? (
                          <h5 className="text-uppercase">{ order.customer.salesTaxCounty }</h5>
                          ) : <h5>--</h5> }
                        <p className="text-secondary pt-3">Sales Tax County</p>
                      </div>
                      <div className="p-1">
                        {order && order.customer && order.customer.salesTaxPercent ? (
                          <h5>{ order.customer.salesTaxPercent + '%' }</h5>
                          ) : <h5>--</h5> }
                        <p className="text-secondary pt-3">Sales Tax %</p>
                      </div>
                      <div className="p-1">
                          <h5>{'$' + formatNumber(getTotalSalesPrice(product) * (order.customer.salesTaxPercent / 100)) }</h5>
                        <p className="text-secondary pt-3">Sales Tax</p>
                      </div>
                    </div>
                  </div> 
                  /*) : ((product && product.lineItemFee && product.lineItemFee.fetExemptReason) ? (
                    <div className="p-4">
                    <div className="d-flex justify-content-between">
                      <div className="p-1">
                          <h5 className="text-uppercase">{ product.lineItemFee.fetExemptReason }</h5>
                        <p className="text-secondary pt-3">FET Exemption Reason</p>
                      </div>
                    </div>
                  </div>*/
                  ):null}
                    <div>
                    {(product.type.toString().toLowerCase() === 'new' && product.lineItemFee && product.lineItemFee.applyFet === true) ? (
                    <div className="p-4">
                      <h5 className="mb-3">FET</h5>
                      <div className="d-flex justify-content-between">
                        <div className="p-1">
                            <h5 className="text-uppercase">{ '$' + formatNumber(getTotalSalesPrice(product)) }</h5>
                          <p className="text-secondary pt-3">Total Subject to FET</p>
                        </div>
                        <div className="p-1">
                            <h5>12%</h5>
                          <p className="text-secondary pt-3">FET Tax %</p>
                        </div>
                        {product && product.lineItemFee && product.lineItemFee.tireCredit ? (
                        <div className="p-1">
                            <h5>{ '$' + formatNumber(product.lineItemFee.tireCredit) }</h5>
                          <p className="text-secondary pt-3">Tire Credit</p>
                        </div>
                        ) : null }
                        {product && product.lineItemFee && product.lineItemFee.tireCredit ? (
                        <div className="p-1">
                            <h5>{'$' + formatNumber(product.lineItemFee.fetTotal) }</h5>
                          <p className="text-secondary pt-3">FET Total</p>
                        </div>
                        ) : null }
                      </div>
                  </div> 
                    ) : null }

                  {product && product.registrationAddress?.state && product.registrationAddress?.state === 'OR' ? (
                  <div className="p-4">
                    <h5 className="mb-3">CAT Tax (Oregon)</h5>
                    <div className="d-flex justify-content-between">
                      <div className="p-1">
                          <h5 className="text-uppercase">{ '$' + formatNumber(getTotalSalesPrice(product)) }</h5>
                        <p className="text-secondary pt-3">Sales Price</p>
                      </div>
                      
                      <div className="p-1">
                        {product && product.lineItemFee && product.lineItemFee.catTaxPercent ? (
                          <h5>{ product.lineItemFee.catTaxPercent + '%' }</h5>
                          ) : ( <h5>--</h5> )}
                        <p className="text-secondary pt-3">Cat Tax %</p>
                      </div>
                      
                      <div className="p-1">
                        {product && product.lineItemFee && product.lineItemFee.catTaxTotal ? (
                          <h5>{'$' + formatNumber(product.lineItemFee.catTaxTotal) }</h5>
                          ) : ( <h5>--</h5> ) }
                        <p className="text-secondary pt-3">Cat Tax</p>
                      </div>
                    </div>
                    </div>
                  ) : null }
                  {(product && product.registrationAddress?.state && product.registrationAddress?.state === 'OR') && (product && product.lineItemFee && product.lineItemFee.applyLuxuryTax === true) ? (
                  <div className="p-4">
                    <h5 className="mb-3">Luxury Tax (Oregon)</h5>
                    <div className="d-flex justify-content-between">
                      <div className="p-1">
                          <h5 className="text-uppercase">{ '$' + formatNumber(getTotalSalesPrice(product)) }</h5>
                        <p className="text-secondary pt-3">Sales Price</p>
                      </div>
                      <div className="p-1">
                        {product && product.lineItemFee && product.lineItemFee.luxuryTaxPercent ? (
                          <h5>{ product.lineItemFee.luxuryTaxPercent + '%' }</h5>
                          ) : (<h5>--</h5>) }
                        <p className="text-secondary pt-3">Luxury Tax %</p>
                      </div>
                      <div className="p-1">
                        {product && product.lineItemFee && product.lineItemFee.luxuryTaxTotal ? (
                          <h5>{'$' + formatNumber(product.lineItemFee.luxuryTaxTotal) }</h5>
                          ) : (<h5>--</h5>) }
                        <p className="text-secondary pt-3">Luxury Tax</p>
                      </div>
                    </div>
                  </div>
                  ) : null }
                </div>
                  {/* )} */}

                  <div className="p-4">
                    <div className="d-flex justify-content-around">
                    { product && product.lineItemFee && product.lineItemFee.applyTitleFee === true ? (
                      <h5 style={{ color: 'green' }}>APPLY TITLE FEE</h5>
                    ):(
                      <h5 style={{ color: '#ccc' }}>APPLY TITLE FEE</h5>
                    )}
                    { product && product.lineItemFee && product.lineItemFee.applyRegistrationFee === true ? (
                      <h5 style={{ color: 'green' }}>APPLY REGISTRATION FEE</h5>
                    ):(
                      <h5 style={{ color: '#ccc' }}>APPLY REGISTRATION FEE</h5>
                    )}
                    </div>
                  </div>
                  {product && product.lineItemFee ? (
                  <div className="p-4">
                    <div className="d-flex justify-content-between">
                      {product && product.lineItemFee && product.lineItemFee.etlFee ? (
                      <div className="p-1">
                          <h5 className="text-uppercase">{'$' + formatNumber(product.lineItemFee.etlFee) }</h5>
                        <p className="text-secondary pt-3">ELT Fee</p>
                      </div>
                      ) : null }
                      {/*product && product.lineItemFee && product.lineItemFee.tireTaxTotal ? (
                      <div className="p-1">
                          <h5>{ '$' + formatNumber(product.lineItemFee.tireTaxTotal) }</h5>
                        <p className="text-secondary pt-3">Tire Tax Total</p>
                      </div>
                      ) : null */}
                      {product && product.lineItemFee && product.lineItemFee.titleFee ? (
                      <div className="p-1">
                          <h5>{ '$' + formatNumber(product.lineItemFee.titleFee)}</h5>
                        <p className="text-secondary pt-3">License / Title Fees</p>
                      </div>
                      ) : null }
                      {product && product.lineItemFee && product.lineItemFee.oosDeliveryFee ? (
                      <div className="p-1">
                          <h5>{ '$' + formatNumber(product.lineItemFee.oosDeliveryFee) }</h5>
                        <p className="text-secondary pt-3">Out of State Delivery Fee</p>
                      </div>
                      ) : null }
                      {product && product.lineItemFee && product.lineItemFee.docFee ? (
                      <div className="p-1">
                          <h5>{ '$' + formatNumber(product.lineItemFee.docFee) }</h5>
                        <p className="text-secondary pt-3">Documentation Fee</p>
                      </div>
                      ) : null }
                      {product && product.lineItemFee && product.lineItemFee.bankFee ? (
                      <div className="p-1">
                          <h5>{ '$' + formatNumber(product.lineItemFee.bankFee) }</h5>
                        <p className="text-secondary pt-3">Bank Fee</p>
                      </div>
                      ) : null }
                      {product && product.lineItemFee && product.lineItemFee.additionalTax ? (
                      <div className="p-1">
                          <h5>{ '$' + formatNumber(product.lineItemFee.additionalTax) }</h5>
                        <p className="text-secondary pt-3">Miscellaneous</p>
                      </div>
                      ) : null }

                      
                    </div>
                  </div>
                  ) : null }

                  <div className="p-4">
                    <div className="p-1">
                      <h5>{'$' + formatNumber(calcTaxFeeSubtotal(order, product))}</h5>
                      <p className="text-secondary pt-3">Tax &amp; Fee Total</p>
                    </div>
                  </div>
                  {product && product.fundingDetail && product.fundingDetail.fundingMethod ? (
                  <div className="p-4">
                    <div className="d-flex justify-content-around">
                      <div className="p-1 pt-1">
                          {product && product.fundingDetail && product.fundingDetail.fundingMethod ? (
                          <h5 className="">{ product.fundingDetail.fundingMethod.toUpperCase() }</h5>
                          ) : <h5>--</h5> }
                          <p className="mx-0 text-secondary pt-3">Funding Method</p>
                        </div>
                        <div className="p-1 pt-1">
                          {product && product.fundingDetail && product.fundingDetail.status ? (
                          <h5 className="">{ product.fundingDetail.status }</h5>
                          ) : <h5>--</h5> }
                          <p className="mx-0 text-secondary pt-3">Status</p>
                        </div>
                      </div>
                  </div>
                  ) : null }
                  {product && product.fundingDetail && product.fundingDetail.cashLienHolder && product.fundingDetail.fundingMethod === "cash-with-lien" ? (
                  <div className="p-4">
                    <div className="p-1">
                      {product && product.fundingDetail && product.fundingDetail && product.fundingDetail.cashLienHolder ? (
                      <h5 className="">{ product.fundingDetail.cashLienHolder.bankName }</h5>
                      ) : null }
                      {product && product.fundingDetail && product.fundingDetail.fundingMethod && product.fundingDetail.cashLienHolder ? (
                      <h5 className="">{ product.fundingDetail.cashLienHolder.addressLine1 }</h5>
                      ) : null }
                      {product && product.fundingDetail && product.fundingDetail.fundingMethod && product.fundingDetail.cashLienHolder ? (
                      <h5 className="">{ product.fundingDetail.cashLienHolder.addressLine2 }</h5>
                      ) : null }
                      {product && product.fundingDetail && product.fundingDetail.fundingMethod && product.fundingDetail.cashLienHolder ? (
                      <h5 className="">{ product.fundingDetail.cashLienHolder.city }</h5>
                      ) : null }
                      {product && product.fundingDetail && product.fundingDetail.fundingMethod && product.fundingDetail.cashLienHolder ? (
                      <h5 className="">{ product.fundingDetail.cashLienHolder.state }</h5>
                      ) : null }
                      {product && product.fundingDetail && product.fundingDetail.fundingMethod && product.fundingDetail.cashLienHolder ? (
                      <h5 className="">{ product.fundingDetail.cashLienHolder.zip }</h5>
                      ) : null }
                      <p className="mx-0 text-secondary pt-3">Lien Details</p>
            
                    </div>
                  </div>
                  ) : null }

                  {product && product.fundingDetail && product.fundingDetail.cashLienHolder && product.fundingDetail.fundingMethod === "direct-deal" ? (
                  <div className="p-4">
                    <div className="d-flex justify-content-between">
                    {product && product.fundingDetail && product.fundingDetail.directDealAdminFee ? (
                      <div className="p-1">
                          <h5 className="text-uppercase">{'$' + formatNumber(product.fundingDetail.directDealAdminFee) }</h5>
                        <p className="text-secondary pt-3">Admin Fee</p>
                      </div>
                    ) : <h5>--</h5> }
                    {product && product.fundingDetail && product.fundingDetail.directDealReserve ? (
                      <div className="p-1">
                          <h5>{ '$' + formatNumber(product.fundingDetail.directDealReserve)}</h5>
                        <p className="text-secondary pt-3">Reserve</p>
                      </div>
                      ) : <h5>--</h5> }
                      {product && product.fundingDetail && product.fundingDetail.directDealInsuranceIncome ? (
                      <div className="p-1">
                          <h5>{ '$' + formatNumber(product.fundingDetail.directDealInsuranceIncome) }</h5>
                        <p className="text-secondary pt-3">Insurance Income</p>
                      </div>
                      ) : <h5>--</h5> }
                    </div>
                  </div>
                  ) : null }

                  {product && product.fundingDetail && product.fundingDetail.finalizedLendingOption && (product.fundingDetail.fundingMethod === 'tec-financing' || product.fundingDetail.fundingMethod === 'finance-only' || product.fundingDetail.fundingMethod === 'direct-deal')  ? (
                  <div className="p-4">
                    <div className="d-flex justify-content-around">
                      <div className="p-1">
                        <h5>{ product.fundingDetail.finalizedLendingOption.code }</h5>
                        <h5>{ product.fundingDetail.finalizedLendingOption.bankName }</h5>
                        <h5>{ product.fundingDetail.finalizedLendingOption.lienAddress }</h5>
                        <h5>{ `${product.fundingDetail.finalizedLendingOption.lienCity}, ${product.fundingDetail.finalizedLendingOption.lienState} ${product.fundingDetail.finalizedLendingOption.lienZip}` }</h5>
                        <p className="text-secondary pt-3">Finalized Lending Option</p>
                      </div>
                      <div className="p-1">
                        <h5>{ product.fundingDetail.finalizedLendingOption.lossPayeeCode }</h5>
                        <h5>{ product.fundingDetail.finalizedLendingOption.lossPayee }</h5>
                        <h5>{ product.fundingDetail.finalizedLendingOption.lossPayeeAddress }</h5>
                        <h5>{ `${product.fundingDetail.finalizedLendingOption.lossPayeeCity}, ${product.fundingDetail.finalizedLendingOption.lossPayeeState} ${product.fundingDetail.finalizedLendingOption.lossPayeeZip}` }</h5>
                        <p className="text-secondary pt-3">Loss Payee</p>
                      </div>
                    </div>
                  </div>
                  ) : null }

                  {product && product.fundingDetail && product.fundingDetail.finalizedLendingOption && (product.fundingDetail.fundingMethod === 'tec-financing' || product.fundingDetail.fundingMethod === 'finance-only') ? (
                  <div className="p-4">
                    <h5 className="mb-4">Financing Details / Reserve Report</h5>
                    <div className="d-flex justify-content-between">
                      <div className="p-1">
                        {product && product.fundingDetail && product.fundingDetail.finalizedLendingOption.term ? (
                          <h5 className="text-uppercase">{'$' + formatNumber(product.fundingDetail.finalizedLendingOption.term) }</h5>
                          ) : <h5>--</h5> }
                        <p className="text-secondary pt-3">Term</p>
                      </div>
                      <div className="p-1">
                          {product && product.fundingDetail && product.fundingDetail.finalizedLendingOption.adminFee ? (
                          <h5>{ '$' + formatNumber(product.fundingDetail.finalizedLendingOption.adminFee)}</h5>
                          ) : <h5>--</h5> }
                        <p className="text-secondary pt-3">Admin Fee</p>
                      </div>
                      <div className="p-1">
                        {product && product.fundingDetail && product.fundingDetail.finalizedLendingOption.customerRate ? (
                          <h5>{ '$' + formatNumber(product.fundingDetail.finalizedLendingOption.customerRate) }</h5>
                        ) : <h5>--</h5> }
                        <p className="text-secondary pt-3">Customer Rate</p>
                      </div>
                      <div className="p-1">
                      {product && product.fundingDetail && product.fundingDetail.finalizedLendingOption.reserve ? (
                          <h5 className="text-uppercase">{'$' + formatNumber(product.fundingDetail.finalizedLendingOption.reserve) }</h5>
                          ) : <h5>--</h5> }
                        <p className="text-secondary pt-3">Reserve</p>
                      </div>
                      <div className="p-1">
                        {product && product.fundingDetail && product.fundingDetail.finalizedLendingOption.dealerRate ? (
                          <h5>{ product.fundingDetail.finalizedLendingOption.dealerRate + '%'}</h5>
                          ) : <h5>--</h5> }
                        <p className="text-secondary pt-3">Dealer Rate</p>
                      </div>
                      <div className="p-1">
                        {product && product.fundingDetail && product.fundingDetail.finalizedLendingOption.insuranceIncome ? (
                          <h5>{ '$' + formatNumber(product.fundingDetail.finalizedLendingOption.insuranceIncome) }</h5>
                          ) : <h5>--</h5> }
                        <p className="text-secondary pt-3">Insurance Income</p>
                      </div>
                    </div>
                  </div>
                  ) : null }

                  {product && product.fundingDetail && product.fundingDetail.finalizedLendingOption && (product.fundingDetail.fundingMethod === 'tec-financing' || product.fundingDetail.fundingMethod === 'finance-only') ? (
                  <div className="p-4">
                    <div className="p-1">
                      <h5>{ '$' + formatNumber(calcTotalFandI(product)) }</h5>
                      <p className="text-secondary pt-3">Total F &amp; I</p>
                    </div>
                  </div>
                  ) : null }

                </div>
              </Accordion.Collapse>
            </Card>
        ))):null}
      </Accordion>
      </div>
      {/* <div
        className="modal fade"
        id="productmodaldeleteconfirm"
        tabIndex="-1"
        aria-labelledby="exampledownpaymentmodel"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header bg-primary text-white">
            <h5
              className="modal-title text-capitalize"
              id="exampledownpaymentmodel"
            >
              Delete Product
            </h5>
            <button
              type="button"
              className="btn-close bg-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="px-3 py-3">
              <h4 className="container text-secondary text-center my-3">
                Are you sure you want to delete this product?
              </h4>
            </div>
          </div>
          <div className="modal-footer text-center">
            <div className="mx-auto">
              <button
                type="button"
                className="btn btn-primary mx-3"
                onClick={(e) => deleteProduct(e, id, selectedProduct.id)}
                data-bs-dismiss="modal"
              >
                Delete
              </button>
                <button
                type="button"
                className="btn btn-secondary mx-3"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
  </div> */}
  </div>
  );
}

export default ProductSection;

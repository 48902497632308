import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";

export const NVErasureAffidavitGlobalAutofill = (documentValues, order, property=null, value=null) => {

  var newValues = documentValues;
  
//   if(property ==='customer.email'){
//     newValues.email_address =value;
// }
  if(property ==='documentationDate'){
    newValues.signature_date = moment.utc(value).format("YYYY-MM-DD");
}
if(property !== null && value !== null){
  return newValues;
}

  // if(order && order.customer && order.customer.email){
  //     newValues.email_address = order.customer.email;
  // }

  if(order && order.documentationDate && documentValues.signature_date === undefined){
    newValues.signature_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  }

  return newValues
}

export const NVErasureAffidavitAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
    var newValues = documentValues;

    if(property === 'year' && value !== null){
      newValues.year = (value).toString().trim().replace(/['"]+/g, '');
  }

  if(property === 'make' && value !== null){
      newValues.make = (value).toString().trim();
  }

  if(property === 'model' && value !== null){
      newValues.model = (value).toString().trim();
  }
if(property !== null && value !== null){
      return newValues;
  }

    newValues.vin = (selectedProduct.vin).toString().toUpperCase();
    newValues.year = (selectedProduct.year).trim();
    newValues.make = (selectedProduct.make).trim();
    newValues.model = (selectedProduct.model).toString().toUpperCase();

    return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  // Data point formatting
  


  // if(payload.form_purpose_toggle){
  //   // Reset all
  //   payload.transfer_title_cb = "no";
  //   payload.transfer_plate_cb = "no";
  //   payload.transfer_lease_cb = "no";

  //   switch(payload.form_purpose_toggle){
  //     case "lease":
  //       payload.transfer_lease_cb = "yes";
  //       break;
  //     case "plate":
  //       payload.transfer_plate_cb = "yes";
  //       break;
  //     case "title":
  //       payload.transfer_title_cb = "yes";
  //       break;
  //     default:
  //   }
  // }
  
  // X1.2- Field Type Specific Preview Formatting
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

if(payload.signature_date){
    payload.signature_date = moment.utc(payload.signature_date).format("MM/DD/YY");
  }
  
  return payload;
}
export const enableTireTax = (order, product) => {
    var doEnableTireTax = false;
    var validStates = ["CA", "AZ"];

    if(validStates.indexOf(order?.tecLocationState) >= 0){
      doEnableTireTax = true;
    }

    if(validStates.indexOf(order?.customer?.deliveryAddress?.state) >= 0){
      doEnableTireTax = true;
    }

    if(validStates.indexOf(product?.fobAddress?.state) >= 0){
      doEnableTireTax = true;
    }

    if(validStates.indexOf(product?.registrationAddress?.state) >= 0){
      doEnableTireTax = true;
    }

    return doEnableTireTax;
};

import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase, getAreaCode, getPhoneNumber, removeChars } from "../../../../../../../utlis/formatters";

export const CACertificateOfNonOperationGlobalAutofill = (documentValues, order, property=null, value=null) => {

  var newValues = documentValues;

  if(property==='customer.phone'){
    let phoneNumber= removeChars(value)
    newValues.area_code = getAreaCode(phoneNumber);
    newValues.phone = getPhoneNumber(phoneNumber);
    newValues.planned_area_code = getAreaCode(phoneNumber);
    newValues.planned_phone = getPhoneNumber(phoneNumber);
  }
  if(property==='documentationDate'){
    newValues.date_certified = moment.utc(value).format("YYYY-MM-DD");
    newValues.copy_date_certified = moment.utc(value).format("YYYY-MM-DD");
    newValues.planned_date_certified = moment.utc(value).format("YYYY-MM-DD");
    newValues.copy_planned_date_certified = moment.utc(value).format("YYYY-MM-DD");
  }

  if(property !== null && value !== null){
    return newValues;
  }


  if(order && order.customer && order.customer.phone){
    newValues.area_code = getAreaCode(order.customer.phone);
    newValues.phone = getPhoneNumber(order.customer.phone);
    newValues.planned_area_code = getAreaCode(order.customer.phone);
    newValues.planned_phone = getPhoneNumber(order.customer.phone);
  }

  if(order && order.documentationDate){
    newValues.date_certified = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    newValues.copy_date_certified = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    newValues.planned_date_certified = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    newValues.copy_planned_date_certified = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  }

return newValues
}

export const CACertificateOfNonOperationAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
  var newValues = documentValues;

  if(property === 'make' && value !== null){
    newValues.make = (value).toString().trim();
    newValues.copy_make = (value).toString().trim();
    newValues.planned_make = (value).toString().trim();
    newValues.copy_planned_make = (value).toString().trim();
  }

  if(property !== null && value !== null){
    return newValues;
  }

  newValues.vin = (selectedProduct.vin);
  newValues.make = (selectedProduct.make).trim();
  /// selectedProductPlanned
  newValues.planned_vin = (selectedProduct.vin);
  newValues.planned_make = (selectedProduct.make).trim();

  return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  // X1.2- Field Type Specific Preview Formatting
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  // Data point formatting
  if(payload.date_certified){
    payload.date_certified = moment.utc(payload.date_certified).format("MM/DD/YY");
  }

  if(payload.planned_date_certified){
    payload.planned_date_certified = moment.utc(payload.planned_date_certified).format("MM/DD/YY");
  }

  if(payload.copy_date_certified){
    payload.copy_date_certified = moment.utc(payload.copy_date_certified).format("MM/DD/YY");
  }

  if(payload.copy_planned_date_certified){
    payload.copy_planned_date_certified = moment.utc(payload.copy_planned_date_certified).format("MM/DD/YY");
  }

  Object.keys(payload).forEach(key => {
    if(!key.includes("copy_")){
      payload["copy_"+key] = payload[key]
    }
  });

  return payload;
}
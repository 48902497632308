// import * as api from '../../../../../../../utlis/api.js'
import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase, getLastName, getFirstName, getMiddleName } from "../../../../../../../utlis/formatters";


export const IRS8300GlobalAutofill = (documentValues, order, property=null, value=null, location=null, locations) => {
    var newValues = documentValues;
  
    // if(property ==='customer.name'){
    //   newValues.last_name = getLastName(value);
    //   newValues.first_name = getFirstName(value);
    //   newValues.mi = (getMiddleName(value))?.charAt(0);
    //   newValues.customer_last_name = getLastName(value);
    //   newValues.customer_first_name = getFirstName(value);;
    //   newValues.customer_mi = (getMiddleName(value))?.charAt(0);
    // }
    // if(property ==='customer.dba'){
    //   newValues.customer_dba = value;
    // }
    // if(property ==='customer.deliveryAddress.addressLine1'){
    //   newValues.address = value;
    //   newValues.customer_address = value;
    //   if(order.customer.deliveryAddress.addressLine2 && order.customer.deliveryAddress.addressLine2 !== ""){
    //     newValues.address += " "+order.customer.deliveryAddress.addressLine2;
    //     newValues.customer_address += " "+order.customer.deliveryAddress.addressLine2;
    //   }
    // }
    // if(property ==='customer.deliveryAddress.addressLine2'){
    //     newValues.address = order.customer.deliveryAddress.addressLine1+ " "+value;
    //     newValues.customer_address = order.customer.deliveryAddress.addressLine1+ " "+value;
    // }
    // if(property ==='customer.deliveryAddress.city'){
    //   newValues.city = value;
    //   newValues.customer_city = value;
    // }
    // if(property ==='customer.deliveryAddress.state'){
    //   newValues.state = value;
    //   newValues.customer_state = value;
    // }
    // if(property ==='customer.deliveryAddress.zip'){
    //   newValues.zip = value;
    //   newValues.customer_zip = value;
    // }
    // if(property ==='location'){
    //     if(value){
    //       newValues.seller_address = value.address;
    //       newValues.seller_city = formatUpperCase(value.city);
    //       newValues.seller_state = value.state;
    //       newValues.seller_zip = value.zip;
    //     }
    // }

    // if(property !== null && value !== null){
    //   return newValues;
    // }

    let buyer_last_name = "";
    let buyer_middle_initial = "";
    let buyer_first_name = "";
    let buyer_address = "";
    let buyer_city = "";
    let buyer_state = "";
    let buyer_zip = "";
    let buyer_dba = "";
  
    if(order?.customer?.name){
      buyer_last_name = getLastName(order.customer.name);
      buyer_middle_initial = (getMiddleName(order.customer.name))?.charAt(0);
      buyer_first_name = getFirstName(order.customer.name);
  
      if(order?.customer?.dba){
        buyer_dba = order?.customer?.dba;
      }

      // if(order.customer.type === "entity"){
      //   buyer_first_name = order.customer.name.toString().toUpperCase();
      //   buyer_middle_initial = "";
      //   buyer_last_name = "";
      // }
  
      buyer_address = order.customer.deliveryAddress.addressLine1;
      if(order.customer.deliveryAddress.addressLine2 && order.customer.deliveryAddress.addressLine2 !== ""){
        buyer_address += " "+order.customer.deliveryAddress.addressLine2;
      }
      buyer_city = formatUpperCase(order.customer.deliveryAddress.city);
      buyer_state = order.customer.deliveryAddress.state;
      buyer_zip = order.customer.deliveryAddress.zip;
    }
  
    //////////////////////////////
  
    let seller_name = "";
    let seller_address = "";
    let seller_city = "";
    let seller_state = "";
    let seller_zip = "";
  
    seller_name = "TEC EQUIPMENT, INC.";
  
      
    if(locations && locations.length > 0 && order.tecLocationCode){
      let foundLocation = locations.find(l => l.locationCode === order.tecLocationCode);
      if(foundLocation){
        seller_address = foundLocation.address;
        seller_city = formatUpperCase(foundLocation.city);
        seller_state = foundLocation.state;
        seller_zip = foundLocation.zip;
      }
    }
  
    // Part 1
    newValues.last_name = buyer_last_name;
    newValues.first_name = buyer_first_name;
    newValues.mi = buyer_middle_initial;
    newValues.address = buyer_address;
    newValues.city = buyer_city;
    newValues.state = buyer_state;
    newValues.zip = buyer_zip;
  
    // Part 2
    newValues.customer_last_name = order?.customer?.type === "entity" ? buyer_dba : buyer_last_name;
    newValues.customer_first_name = order?.customer?.type === "entity" ? "" : buyer_first_name;
    newValues.customer_mi = order?.customer?.type === "entity" ? "" : buyer_middle_initial;
    newValues.customer_address = buyer_address;
    newValues.customer_city = buyer_city;
    newValues.customer_state = buyer_state;
    newValues.customer_zip = buyer_zip;
    newValues.customer_dba = buyer_dba;
  
    // Part 4
    newValues.seller_name = seller_name;
    newValues.seller_address = seller_address;
    newValues.seller_city = seller_city;
    newValues.seller_state = seller_state;
    newValues.seller_zip = seller_zip;
  
    return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields, dateFields) => {


  // Process money, phone and uppercase fields for document preview 
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });


  // Process checkboxes for document preview
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });
  
  // Format Date Fields for the document
  dateFields?.forEach(datefield => {
    if(payload[datefield]){
      payload[datefield] = moment.utc(payload[datefield]).format("MMDDYYYY");
    }
  });

  return payload;
}
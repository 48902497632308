import React, {useEffect, useState} from 'react'
import { Card, Dropdown } from "react-bootstrap";
import { Menu } from "@material-ui/icons";
import "./documentStyles.css"
import * as api from '../../../../../../utlis/api'

const DocumentInfoTemplate = ({ type, displayNumber, status, createdAt, document, children, isNewCard , isDeletingDoc, isDocActionDisabled }) => {

  const [createdByName, setCreatedByName] = useState(null)
  const [fetchingCreatedByName, setFetchingCreatedByName] = useState(false)

  
  useEffect( () => {
    async function retrieveCreatedBy(userId){
      setFetchingCreatedByName(true);
      let name = "";
  
      let res = await api.getUserById(userId);
      if(res?.data){
        console.log('got user', res, res?.data?.name, name)
        if(res?.data.name){
          name = res?.data.name;
          setCreatedByName(name);
        }
        setFetchingCreatedByName(false);
      }
    }

    if(!document?.createdBy?.name && fetchingCreatedByName === false && createdByName === null){

      if(document?.createdById){

        retrieveCreatedBy(document?.createdById);

      }

    }
  }, [createdByName, document, fetchingCreatedByName]);

  return (
    <Card.Header className={`${isDocActionDisabled ? 'disabled-cursor' : ''}`} style={{ backgroundColor: '#fff', border: 'none', borderRadius: '0px'}}>
      <div className='row'>
        <div className="d-flex align-items-center justify-content-between p-2">
          <Dropdown style={{ display: 'inline-table' }} className='col-md-1'>
            <Dropdown.Toggle data-testid="documentContextMenu" style={{ backgroundColor: '#fff', color: isDeletingDoc === document?.id ? 'lightgray' : isNewCard ? 'white' : 'black', border: 'none', paddingTop: '1px' }} disabled={isDocActionDisabled}>
              <Menu />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              { children }
            </Dropdown.Menu>
          </Dropdown>
          <div className='col-md-5'>
          <h4 className="text-uppercase" data-testid="documentTitle" >{ type }</h4>
          <p className="text-uppercase text-grey"  data-testid="stockNumber" >{ displayNumber }</p>
          </div>
          <div className='col-md-2'>
            <h5 className="text-uppercase" data-testid="documentStatus">{ status }</h5>
            <p className={`mx-0 pt-3 ${isNewCard ? 'initial-new-card-text' : 'text-secondary'}`}>Status</p>
          </div>
          <div className="pe-3 col-md-2">
            <h5 className="text-uppercase" data-testid="createdByName">{ document && document.createdBy && document.createdBy.name ? document.createdBy.name : ( createdByName ? createdByName : '--') }</h5>
            <p className={`mx-0 pt-3 ${isNewCard ? 'initial-new-card-text' : 'text-secondary'}`}>Created By</p>
          </div>
          <div className='col-md-2'>
            <h5 className="text-uppercase" data-testid="createdAt">{ createdAt }</h5>
            <p className={`mx-0 pt-3 ${isNewCard ? 'initial-new-card-text' : 'text-secondary'}`}>Created Date</p>
          </div>
        </div>
      </div>
    </Card.Header>
  );
};

export default DocumentInfoTemplate;

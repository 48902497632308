function getOrderLevelAddresses(order){
  let getAddressOptions = [];
  if(order && order.customer && order.customer.deliveryAddress){
    var addressLine = '';
    if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
      addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
    }else{
      addressLine = order.customer.deliveryAddress.addressLine1;
    }
    let city = order.customer.deliveryAddress.city;
    let state = order.customer.deliveryAddress.state;
    let zip = order.customer.deliveryAddress.zip;

    let customerName = order.customer.name;
    if(order.customer.dba && order.customer.dba !== ""){
      customerName = customerName+" dba "+order.customer.dba;
    }

    let obj = {slug: "customer-delivery", name: "Customer Delivery", type: order.customer.type, phone: (order.customer.phone ?? null), label: customerName, address: addressLine, city: city, state: state, zip: zip};
    
    if(order.customer && order.customer.cosigners && order.customer.cosigners.length > 0){
      let cosigner = order.customer.cosigners[0];
      obj.cosigner = cosigner;
    }
    
    getAddressOptions.push(obj);
  }

  if(order && order.customer && order.customer.deliveryAddress && order.customer.cosigners && order.customer.cosigners.length > 0){
    addressLine = '';
    if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
      addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
    }else{
      addressLine = order.customer.deliveryAddress.addressLine1;
    }
    let city = order.customer.deliveryAddress.city;
    let state = order.customer.deliveryAddress.state;
    let zip = order.customer.deliveryAddress.zip;

    let customerName = order.customer.name;

    let cosigner = order.customer.cosigners[0];
    if(cosigner && cosigner.name){
      customerName = order.customer.name+" and "+cosigner.name;
    }


    let obj = {slug: "customer-cosigner-delivery", name: "Customer (No DBA) and Co-signer Delivery", type: order.customer.type, phone: (order.customer.phone ?? null), label: customerName, address: addressLine, city: city, state: state, zip: zip};
    
    if(order.customer.cosigners && order.customer.cosigners.length > 0){
      let cosigner = order.customer.cosigners[0];
      obj.cosigner = cosigner;
    }
    
    getAddressOptions.push(obj);
  }

  if(order.customer && order.customer.cosigners && order.customer.cosigners.length > 0){
    let cosigner = order.customer.cosigners[0];
    if(cosigner){
      let customerName = cosigner.name;

      // var addressLine = '';
      // if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        //   addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
        // }else{
          //   addressLine = order.customer.deliveryAddress.addressLine1;
          // }
          // let city = order.customer.deliveryAddress.city;
          // let state = order.customer.deliveryAddress.state;
          // let zip = order.customer.deliveryAddress.zip;
          
          // if(order.customer.dba && order.customer.dba !== ""){
            // customerName = customerName+" dba "+order.customer.dba
      // }
      
      let obj = {slug: "cosigner-no-address", name: "CO-SIGNER (No Address)", type: "Individual", phone: (cosigner.phone ?? null), label: customerName, address: "", city: "", state: "", zip: ""};
      
      
      getAddressOptions.push(obj);
    }
  }

  if(order.customer && order.customer.cosigners && order.customer.cosigners.length > 0){
    let cosigner = order.customer.cosigners[0];
    if(cosigner){
      let customerName = cosigner.name;

      addressLine = '';
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
          addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
        }else{
            addressLine = order.customer.deliveryAddress.addressLine1;
          }
          let city = order.customer.deliveryAddress.city;
          let state = order.customer.deliveryAddress.state;
          let zip = order.customer.deliveryAddress.zip;

      let obj = {slug: "cosigner-delivery-address", name: "CO-SIGNER (WITH CUSTOMER DELIVERY ADDRESS)", type: "Individual", phone: (cosigner.phone ?? null), label: customerName, address: addressLine, city: city, state: state, zip: zip};
      
      
      getAddressOptions.push(obj);
    }
  }


  if(order && order.customer && order.customer.mailingAddress){

    if(order.customer.mailingAddress.addressLine2 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
      addressLine = (order.customer.mailingAddress.addressLine1+" "+order.customer.mailingAddress.addressLine2);
    }else{
      addressLine = order.customer.mailingAddress.addressLine1;
    }
    let city = order.customer.mailingAddress.city;
    let state = order.customer.mailingAddress.state;
    let zip = order.customer.mailingAddress.zip;

    let customerName = order.customer.name;
    if(order.customer.dba && order.customer.dba !== ""){
      customerName = customerName+" dba "+order.customer.dba
    }

    let obj = {slug: "customer-mailing", name: "Customer Mailing", type: order.customer.type, phone: (order.customer.phone ?? null), label: customerName, address: addressLine, city: city, state: state, zip: zip};
    
    if(order.customer.cosigners && order.customer.cosigners > 0){
      let cosigner = order.customer.cosigners[0];
      obj.cosigner = cosigner;
    }
    
    getAddressOptions.push(obj);
  }

  return getAddressOptions;
}

function updateAddressOptions(selectedProduct, order){
  let getAddressOptions = getOrderLevelAddresses(order);
  // Find Product from order
   
  if(selectedProduct.shipTo){
    var addressLine = '';
    if(selectedProduct.shipTo.addressLine2 != null && selectedProduct.shipTo.addressLine2 !== undefined && selectedProduct.shipTo.addressLine2 !== ''){
      addressLine = (selectedProduct.shipTo.addressLine1+" "+selectedProduct.shipTo.addressLine2);
    }else{
      addressLine = selectedProduct.shipTo.addressLine1;
    }
    let city = selectedProduct.shipTo.city;
    let state = selectedProduct.shipTo.state;
    let zip = selectedProduct.shipTo.zip;

    let obj = {slug: "product-ship-to", name: "Selected Product Ship To", type: "entity", label: selectedProduct.shipTo.name, address: addressLine, city: city, state: state, zip: zip};
    getAddressOptions.push(obj);
  }

  if(selectedProduct.soldTo){
    addressLine = '';
    if(selectedProduct.soldTo.addressLine2 != null && selectedProduct.soldTo.addressLine2 !== undefined && selectedProduct.soldTo.addressLine2 !== ''){
      addressLine = (selectedProduct.soldTo.addressLine1+" "+selectedProduct.soldTo.addressLine2);
    }else{
      addressLine = selectedProduct.soldTo.addressLine1;
    }
    let city = selectedProduct.soldTo.city;
    let state = selectedProduct.soldTo.state;
    let zip = selectedProduct.soldTo.zip;

    let obj = {slug: "product-sold-to", name: "Selected Product Sold To", type: "entity", label: selectedProduct.soldTo.name, address: addressLine, city: city, state: state, zip: zip};
    getAddressOptions.push(obj);
  }

  
  if(selectedProduct.fundingDetail && selectedProduct.fundingDetail.finalizedAt){
    if(selectedProduct.fundingDetail.fundingMethod === "tec-financing" || selectedProduct.fundingDetail.fundingMethod === "finance-only" || selectedProduct.fundingDetail.fundingMethod === "direct-deal"){
      if(selectedProduct.fundingDetail.finalizedLendingOption){
        addressLine = '';
        addressLine = selectedProduct.fundingDetail.finalizedLendingOption.lienAddress;
        let city = selectedProduct.fundingDetail.finalizedLendingOption.lienCity;
        let state = selectedProduct.fundingDetail.finalizedLendingOption.lienState;
        let zip = selectedProduct.fundingDetail.finalizedLendingOption.lienZip;
        let obj = {slug: "product-lendor-lien", name: "Selected Product Lender Address (Lien)", type: "entity", label: selectedProduct.fundingDetail.finalizedLendingOption.bankName, address: addressLine, city: city, state: state, zip: zip};   
        getAddressOptions.push(obj);

        addressLine = selectedProduct.fundingDetail.finalizedLendingOption.lossPayeeAddress;
        city = selectedProduct.fundingDetail.finalizedLendingOption.lossPayeeCity;
        state = selectedProduct.fundingDetail.finalizedLendingOption.lossPayeeState;
        zip = selectedProduct.fundingDetail.finalizedLendingOption.lossPayeeZip;
        obj = {slug: "product-lendor-losspayee", name: "Selected Product Lender Address (LossPayee)", type: "entity", label: selectedProduct.fundingDetail.finalizedLendingOption.lossPayee, address: addressLine, city: city, state: state, zip: zip};   
        getAddressOptions.push(obj);
      }
    }
    
    if(selectedProduct.fundingDetail.fundingMethod === "cash-with-lien" && selectedProduct.fundingDetail.cashLienHolder){
      addressLine = '';
      if(selectedProduct.fundingDetail.cashLienHolder.addressLine2 != null && selectedProduct.fundingDetail.cashLienHolder.addressLine2 !== undefined && selectedProduct.fundingDetail.cashLienHolder.addressLine2 !== ''){
        addressLine = (selectedProduct.fundingDetail.cashLienHolder.addressLine1+" "+selectedProduct.fundingDetail.cashLienHolder.addressLine2);
      }else{
        addressLine = selectedProduct.fundingDetail.cashLienHolder.addressLine1;
      }
      let city = selectedProduct.fundingDetail.cashLienHolder.city;
      let state = selectedProduct.fundingDetail.cashLienHolder.state;
      let zip = selectedProduct.fundingDetail.cashLienHolder.zip;

      let obj = {slug: "product-lendor-cash", name: "Selected Product Lender Address (Cash w/Lien)", type: "entity", label: selectedProduct.fundingDetail.cashLienHolder.bankName, address: addressLine, city: city, state: state, zip: zip};
      getAddressOptions.push(obj);
    }
  }

  if(selectedProduct?.fobAddress?.addressLine1){
    const fobAddress = selectedProduct.fobAddress
    let streetAddress;

    if(fobAddress?.addressLine2){
      streetAddress = (fobAddress?.addressLine1+" "+fobAddress.addressLine2);
    }else{
      streetAddress = fobAddress?.addressLine1;
    }

    let obj = {slug: "fob", name: "FOB Address", type: order.customer.type, label: "FOB Address", address: streetAddress, city: fobAddress?.city, state: fobAddress?.state, zip: fobAddress?.zip};
  
    getAddressOptions.push(obj);
  }

  return getAddressOptions;
}

function getAllNames(order, product){
  let names = [];

  let customerName = order.customer.name;
  if(order.customer.dba && order.customer.dba !== ""){
    customerName = customerName+" dba "+order.customer.dba
  }

  names.push({name: customerName});

  if(product){
    if(product.shipTo){
      names.push({name: product.shipTo.name});
    }

  if(product.soldTo){
    names.push({name: product.soldTo.name});
  }

  if(order.customer.cosigners && order.customer.cosigners > 0){
    let cosigner = order.customer.cosigners[0];
    names.push({name: cosigner.name});
  }
  
  if(product.fundingDetail && product.fundingDetail.finalizedAt){
    if(product.fundingDetail.fundingMethod === "tec-financing" || product.fundingDetail.fundingMethod === "finance-only" || product.fundingDetail.fundingMethod === "direct-deal"){
      if(product.fundingDetail.finalizedLendingOption){
        names.push({name: product.fundingDetail.finalizedLendingOption.bankName});
        names.push({name: product.fundingDetail.finalizedLendingOption.lossPayee});
      }
    }
    
    if(product.fundingDetail.fundingMethod === "cash-with-lien" && product.fundingDetail.cashLienHolder){
      names.push({name: product.fundingDetail.cashLienHolder.bankName});
    }
  }
  }

  return names;
}


export { 
  getOrderLevelAddresses,
  updateAddressOptions,
  getAllNames
}
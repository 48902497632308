import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { formatter } from "../../../../../../utlis/formatters";


import "./Style/orderquote.css";
import { useOrder } from "../../../../../../redux/orders/order"

const ProductUpgrades = ({ upgrades }) => {

  // var formatter = new Intl.NumberFormat('en-US', {
  //   style: 'currency',
  //   currency: 'USD',
  // });

  const order = useOrder();

  return (
    <div className="container-fluid">
      { upgrades && upgrades.length > 0 ? (
        <div className="row">
          <Accordion defaultActiveKey="a1">
            <Card className="p-3">
              <Card.Header style={{ backgroundColor: '#2d7ecf', color: 'white', padding: '1rem' }}>
                <span style={{ paddingLeft: '15px', fontWeight: 'bold' }}>UPGRADES</span>
              </Card.Header>
              {/* <SectionTemplate title="UPGRADES" eventKey="a1"> */}
                {/* <Dropdown.Item>
                  <div
                    type="button"
                    className="btn btn-primary px-1 mb-auto mt-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#productmodal"
                    >
                    Add Upgrade
                  </div>
                </Dropdown.Item> */}
              {/* </SectionTemplate> */}
              <Accordion.Collapse eventKey="a1">
                <div className="p-4">
                  <div className="d-flex justify-content-between">
                    
                      <div style={{ width: '100%' }}>
                      <Accordion>
                        {upgrades.map((upgrade, index) => (
                          <Accordion.Item eventKey={upgrade.id}>
                            <Accordion.Header>Upgrade# {index + 1}</Accordion.Header>
                              <Accordion.Body>
                              <div className=" border-start border-5 border-primary">
                                <div className="p-4">
                                  <div className="d-flex justify-content-between">

                                    <div className="p-4">
                                      <p className="text-secondary">{ upgrade.type }</p>
                                      <h5>TYPE</h5>
                                    </div>

                                    <div className="p-4">
                                      <p className="text-secondary">{ formatter.format(upgrade.price) }</p>
                                      <h5>PRICE</h5>
                                    </div>
                                    
                                    <div className="p-4">
                                      <p className="text-secondary">{ upgrade.serialNumber }</p>
                                      <h5>SERIAL NUMBER</h5>
                                    </div>

                                    <div className="p-4">
                                      <p className="text-secondary">{ upgrade.stockNumber }</p>
                                      <h5>STOCK NUMBER</h5>
                                    </div>
                                  </div>
                                </div>

                                <div className="p-4 bg-light">
                                  <div className="d-flex justify-content-between">
                                    <div className="p-4">
                                      <p className="text-secondary">{ upgrade.description }</p>
                                      <h5>DESCRIPTION/NOTES</h5>
                                    </div>
                                  </div>
                                </div>

                                <div className="p-4">
                                  <div className="d-flex justify-content-between">

                                    <div className="p-4">
                                      { upgrade.subjectToFet === true ? (
                                        <h5 style={{ color: 'green' }}>SUBJECT TO FET</h5>
                                      ):(
                                        <h5 style={{ color: '#ccc' }}>SUBJECT TO FET</h5>
                                      )}
                                    </div>
                                    { order?.customer?.isExemptSalesTax === false ? (
                                      <div className="p-4">
                                        { upgrade.subjectToSalesTax === true ? (
                                        <h5 style={{ color: 'green' }}>SUBJECT TO SALES TAX</h5>
                                      ):(
                                        <h5 style={{ color: '#ccc' }}>SUBJECT TO SALES TAX</h5>
                                      )}
                                      </div>
                                    ):null}

                                    {/* <div className="p-4">
                                      <h5>INCLUDED IN SALES PRICE</h5>
                                    </div> */}

                                  </div>
                                </div>
                                <hr></hr>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                        </Accordion>
                      </div>
                    
                  </div>
                </div>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      ):null}
    </div>
  );
}

export default ProductUpgrades;

import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  useParams
} from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import { formatter, removeChars, formatPhoneNumber, formatUpperCase } from "../../../../../../../utlis/formatters";
import { updateAddressOptions, getOrderLevelAddresses } from "../../../../../../../utlis/documentHelpers";
import { useOrder } from "../../../../../../../redux/orders/order";
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { TECDoAGlobalAutofill, TECDoAAutofillProductDetails, TECDoAAutofillTradeDetails, preProcessPayload } from "../DocumentAutofill/TECDoA"

const TECDoA = ({document_id, location, getDocuments, passedDocumentSlug, setDocumentFocus}) => {
  const documentSlug = "tec-disclosure-applicability";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  const order = useOrder();
  const axios = useFetch()

  const [documentUrl, setdocumentUrl] = useState("");
  const [document, setDocument] = useState(undefined);
  const [previousDocumentId, setPreviousDocumentId] = useState(0);

  // Data Structure & Init //
  var initialDocumentStructure = {
    location_address: undefined, 
    location_phone: undefined, 
    location_email: undefined, 
    tec_footer_label: undefined, 
    pg_no: undefined, 
    printed_name: undefined, 
    as_a_purchaser_line_1: undefined, 
    as_a_purchaser_line_2: undefined, 
    customer_line_1: undefined, 
    customer_line_2: undefined, 
    customer_line_3: undefined, 
    date: undefined
  }
  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);

  // const removeFormatting = e =>{
  //   const {name,value}=e.target;
  //   setDocumentValues({...documentValues,[name]:removeChars(value)});
  // }

  const moneyFields = useMemo(()=>{return["market_value", "changes_cost", "current_market_value"]},[]);
  const phoneFields = useMemo(()=>{return[]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "window_vin"]},[]);
  const checkboxes = useMemo(()=>{return[
    "unladen_weight_changed", 
    "motive_power_changed", 
    "body_type_changed", 
    "axels_changed",
    "same_person",
    "name_misspelled",
    "changing_name"
  ]},[]);
  // const radios = [
  //   "exempt_reason", 
  //   "smog_exempt", 
  //   "smog_exempt_powered", 
  //   "smog_exempt", 
  //   "smog_exempt_transfer", 
  //   "trans_title"
  // ];

  const handleDocumentValuesChange = e =>{
    // e.preventDefault();
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatPhoneNumber(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  
  const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('TECDoA.jsx', 'Error loading document: ' +err)
      }
    }
},[axios, id])
  
const [previewLoaded, setPreviewLoaded] = useState(false);
const [dataLoaded, setDataLoaded] = useState(false);
useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, documentLoaded, loadDocument ])
  const [assignedProduct, setAssignedProduct] = useState(undefined);
  const [assignedTrade, setAssignedTrade] = useState(undefined);

  // const [productVal, setproductVal] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  // const [tradeinVal, setTradeinVal] = useState(undefined);
  // const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  // const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const [addressOptions, setAddressOptions] = useState(undefined);
  const [inputAddressVal, setinputAddressVal] = useState(undefined);


// Dropdowns //

// if(order && order.products && order.products.length > 0 && productOptions === undefined){
//   let getProductOptions = [];
//   order.products.map(product => {
//     let str = `${product.stockNumber.toString().toUpperCase()}`;
//     let obj = {id: product.id, name: str}
//     getProductOptions.push(obj);
//   })
//   setproductOptions(getProductOptions);
// }

// if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
//   let getTradeinOptions = [];
//   order.tradeins.map(tradein => {
//     let str = `${tradein.year} ${tradein.make} ${tradein.model.toString().toUpperCase()}`;
//     let obj = {id: tradein.id, name: str}
//     getTradeinOptions.push(obj);
//   })
//   setTradeinOptions(getTradeinOptions);
// }

// function getOrderLevelAddresses(order){
//   let getAddressOptions = [];
//   if(order.customer && order.customer.deliveryAddress){
//     var addressLine = '';
//     if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
//       addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
//     }else{
//       addressLine = order.customer.deliveryAddress.addressLine1;
//     }
//     let city = order.customer.deliveryAddress.city;
//     let state = order.customer.deliveryAddress.state;
//     let zip = order.customer.deliveryAddress.zip;

//     let obj = {slug: "customer-delivery", name: "Customer Delivery", address: addressLine, city: city, state: state, zip: zip};
//     getAddressOptions.push(obj);
//   }

//   if(order.customer && order.customer.mailingAddress){

//     if(order.customer.mailingAddress.addressLine2 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
//       addressLine = (order.customer.mailingAddress.addressLine1+" "+order.customer.mailingAddress.addressLine2);
//     }else{
//       addressLine = order.customer.mailingAddress.addressLine1;
//     }
//     let city = order.customer.mailingAddress.city;
//     let state = order.customer.mailingAddress.state;
//     let zip = order.customer.mailingAddress.zip;

//     let obj = {slug: "customer-mailing", name: "Customer Mailing", address: addressLine, city: city, state: state, zip: zip};
//     getAddressOptions.push(obj);
//   }
//   return getAddressOptions;
// }

 // Autofill //

 const GlobalAutofill = useCallback(async () => {
  let newValues = TECDoAGlobalAutofill(documentValues, order, null, null, location);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  // // setAddressOptions(getOrderLevelAddresses(order));

  // var newValues = documentValues;
  
  // if(order && order.customer && order.customer.name){
  //   if(order.customer.type === "entity"){
  //     newValues.customer_line_1 = order.customer.name;
  //     newValues.printed_name = order.customer.name;
  //   }else{
  //     if(order.customer.dba){
  //       newValues.printed_name = order.customer.name+" dba "+order.customer.dba;
  //       newValues.customer_line_1 = order.customer.name+" dba "+order.customer.dba;
  //     }else{
  //       newValues.printed_name = order.customer.name;
  //       newValues.customer_line_1 = order.customer.name;
  //     } 
  //   }
    
  //   if(order.customer.cosigners && order.customer.cosigners.length > 0){
  //     let cosigner = order.customer.cosigners[0];
  //     if(cosigner && cosigner.name){
  //       newValues.printed_name += " AND "+cosigner.name;
  //     }
  //   }

  //   if(order.customer.deliveryAddress){
  //     var addressLine = '';
  //     if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
  //       addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
  //     }else{
  //       addressLine = order.customer.deliveryAddress.addressLine1;
  //     }

  //     newValues.customer_line_2 = addressLine
  //     newValues.customer_line_3 = order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
  //   }
  // }

    // Get Location Address & Autofill Address
    if(location !== undefined){
      newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
      // setDocumentValues({...documentValues,['location_address']:location.address+" "+location.city+" "+location.state+" "+location.zip});
    }
  
    if(location && location.phone){
      newValues.location_phone = location.phone;
      // setDocumentValues({...documentValues,['location_phone']:location.phone});
    }
    
    if(location && location.email){
      newValues.location_email = location.email;
      // setDocumentValues({...documentValues,['location_email']:location.email});
    }
  
    if(location){
      if(location.state === "CA"){
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
        // setDocumentValues({...documentValues,['tec_footer_label']:"TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT"});
      }else{
      newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
        // setDocumentValues({...documentValues,['tec_footer_label']:"TECEQUIPMENT.COM | TEC EQUIPMENT, INC"});
      }
    }

    // if(order && order.documentationDate && documentValues.date === undefined){
    //   newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    // }

  setDocumentValues(newValues);
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ documentValues, order, location ])


// const preProcessPayload = useCallback((payload) => {

//   // X1.2- Field Type Specific Preview Formatting
//   Object.keys(payload).forEach(key => {
//     if(moneyFields.indexOf(key) >= 0){
//         payload[key] = formatMoneyValue(payload[key]);
//     }else if(phoneFields.indexOf(key) >= 0){
//         payload[key] = formatPhoneNumber(payload[key]);
//     }else if(upperCaseFields.indexOf(key) >= 0){
//         payload[key] = formatUpperCase(payload[key]);
//     }
//   });
//   setDocumentValues(JSON.parse(JSON.stringify(payload)));
//   Object.keys(payload).forEach(key => {
//     if(checkboxes.indexOf(key) >= 0){
//       payload[key] = payload[key] ? "yes": "no";
//     }
//   });

//   // Data point formatting
//   if(payload.date){
//     payload.date = moment.utc(payload.date).format("MM/DD/YY");
//   }

//   return payload;
// },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])


const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
  Object.keys(payload).forEach(key => {
    if (payload[key] === null || payload[key] === undefined) {
      delete payload[key];
    }
  });
  
  var docCopy = JSON.parse(JSON.stringify(document));
  docCopy.payload =  JSON.parse(JSON.stringify(payload));
  docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
  docCopy.payload =  JSON.stringify(docCopy.payload);
  delete docCopy.documentStatus;
  try {
    let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
    const file = new Blob(
      [response.data], 
      {type: 'application/pdf'});
    const fileURL = URL.createObjectURL(file);
      // window.open(fileURL);
    setdocumentUrl(fileURL)
  } catch (err) {
    recordFrontEndError('TECDoA.jsx', 'Error previewing document payload: ' + err)
  };  
},[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


  //Autofill Product Details After Selection
  const AutofillProductDetails = useCallback(async (selectedProduct, autofillProductDetails=true, productCount) => {
    let newValues = TECDoAAutofillProductDetails(selectedProduct, documentValues, null, null, null, null, autofillProductDetails, productCount);

    if(newValues){
      setDocumentValues(newValues);
      setHasUnsavedChanges(true);
      setSaveSuccess(false);
    }
    
    // var newValues = documentValues;
    // if(autofillProductDetails){
    //   newValues.as_a_purchaser_line_1 = (selectedProduct.year).trim()+" "+(selectedProduct.make).trim()+" "+(selectedProduct.model).toString().toUpperCase();
    //   newValues.as_a_purchaser_line_2 = (selectedProduct.vin).toString().toUpperCase();
    // }else{
    //   newValues.as_a_purchaser_line_1 = "("+productCount+") "+(selectedProduct.year).trim()+" "+(selectedProduct.make).trim()+" "+(selectedProduct.model).toString().toUpperCase();
    // }
    

    // setDocumentValues(newValues);
    // // setProductToggleAutoFill(false);
    // // if(document){
    // //   previewPayloadDocument(documentValues);
    // // }
    // // setAddressOptions(updateAddressOptions(selectedProduct, order));
    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  },[ documentValues ]);

  //Autofill Tradein Details After Selection
  const AutofillTradeDetails = useCallback(async (selectedTradein) => {
    let newValues = TECDoAAutofillTradeDetails(selectedTradein, documentValues);

    if(newValues){
      setDocumentValues(newValues);
      setHasUnsavedChanges(true);
      setSaveSuccess(false);
    }

    // var newValues = documentValues;
    // newValues.as_a_purchaser_line_1 = (selectedTradein.year).trim()+" "+(selectedTradein.make).trim()+" "+(selectedTradein.model).toString().toUpperCase();
    // newValues.as_a_purchaser_line_2 = (selectedTradein.vin.toUpperCase());
    // // newValues.trade_model = (selectedTradein.model);
    // // newValues.trade_body_type = (selectedTradein.seriesBodyType);
    // // newValues.trade_serial_number = (selectedTradein.serialNumber);
    // // newValues.balance_owner = (order.customer.name); //Need specific field for TRADE IN OWNER?
    // // newValues.balance_owner_address = (order.customer.deliveryAddress.addressLine1);
    // // newValues.amount = (selectedTradein.balanceOwed);
    // // newValues.trade_allowance = (selectedTradein.tradeAllowanceAtTerms);
    // // newValues.balance_owed = (selectedTradein.balanceOwed);
    // // newValues.cash_refund = (selectedTradein.cashRefundToCustomer);
    // // newValues.net_trade_allowance = (selectedTradein.finalTradeAllowance);
    // // newValues.cash_down_payment = (selectedTradein);

    // setDocumentValues(newValues);
    // // setTradeinToggleAutoFill(false);
    // // if(document){
    // //   previewPayloadDocument(documentValues);
    // // }
    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  },[ documentValues ]);

  const updatePreview = e => {
    e.preventDefault();
    previewPayloadDocument(documentValues);
  }

  useEffect(() => {
    if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){
  
      existingPayload = true;
  
      if(documentPayload.date){
        documentPayload.date = moment.utc(documentPayload.date).format("YYYY-MM-DD");
      }
      
      setDocumentValues(documentPayload);

      // Loading Use Effect
      if(document.additionalData){
        const methodMap = { 
          selectedAddress: setSelectedAddress, 
        };

        let parsedAdditionalData = JSON.parse(document.additionalData);
        Object.keys(parsedAdditionalData).forEach(function(key) {
          if(methodMap[key]){
            methodMap[key](parsedAdditionalData[key]);
          }
        });
      }
    }

    // if(document.assignedObjectType && document.assignedObjectId){
    //   setVehicleTypeSelection(document.assignedObjectType)
    // }
    var foundAssignedOrder = null;
    if(document.assignedObjectType && document.assignedObjectType === 'order'){
      foundAssignedOrder = true;
    }
  
    var foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }
    
    if(foundAssignedProduct){
      setAssignedProduct(foundAssignedProduct);
    }
    
    var foundAssignedTradein = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'tradein'){
      foundAssignedTradein = order.tradeins.find((trade) => trade.id === document.assignedObjectId);
    }
    

    setAddressOptions(getOrderLevelAddresses(order));
    if(foundAssignedProduct){
        setAssignedProduct(foundAssignedProduct);
        setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
    }
    
      if(!existingPayload){
        GlobalAutofill();
        // previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
        if(foundAssignedTradein){
          AutofillTradeDetails(foundAssignedTradein)
        }
        if(foundAssignedOrder){
          // Autofill "See Schedule A"
          var copyDocumentValues = documentValues;
          copyDocumentValues.as_a_purchaser_line_2 = "See Schedule A";
          setDocumentValues(copyDocumentValues);
          setHasUnsavedChanges(true);
          setSaveSuccess(false);

          //Get First Product
          if(order && order.products && order.products.length > 0){
            let firstProduct = order.products[0];
            AutofillProductDetails(firstProduct, false, order.products.length);
          }
        }
      }else{
        // if(document && document.itemIds){
        //   var itemIds = document.itemIds.split(',');
        //     var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
        //     let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
        //     let obj = {id: foundProduct.id, name: str}
        //     setproductVal(obj);
        //     if(foundProduct){
        //       setAddressOptions(updateAddressOptions(foundProduct, order));
        //     }
        //     streamDocument();
        // }
  
      }
      
      setDataLoaded(true);
    }else if(document && document.payload && dataLoaded && !previewLoaded){
      setPreviewLoaded(true);
      previewPayloadDocument(documentValues);
    }

  },[ documentValues, document, dataLoaded, AutofillProductDetails, AutofillTradeDetails, GlobalAutofill, order, previewPayloadDocument, previewLoaded ])


  
  // function streamDocument(){
  //   axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
  //     .then(response => {
  //       const file = new Blob(
  //         [response.data], 
  //         {type: 'application/pdf'});
  //         const fileURL = URL.createObjectURL(file);
  //         // window.open(fileURL);
  //       setdocumentUrl(fileURL)
  //     }).catch(err => {
  //     })
  // }
  

  
  
  const handleSubmit = async event => {
    event.preventDefault();

    let payload = JSON.parse(JSON.stringify(documentValues));

    payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);  

    // const payload = JSON.parse(JSON.stringify(documentValues));
    // var docCopy = document;
    // docCopy.payload = payload;
    // docCopy.payload = preProcessPayload(docCopy.payload);
    // docCopy.payload =  JSON.stringify(docCopy.payload);
    // delete docCopy.documentStatus;
    
    // if(selectedProduct && selectedProduct.id){
    //   docCopy.itemIds = `${selectedProduct.id}`;
    // }

    //Save Dropdown Selections
      let dropdownData = {};
      if(selectedAddress){
        dropdownData.selectedAddress = {
          id: selectedAddress.id,
          name: selectedAddress.name
        };
      }
      // docCopy.additionalData = JSON.stringify(dropdownData);

      let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, additionalData, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });

    // try {
    //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
    //   if (res) {
    //     await axios.post(`/document/${document.id}/generate`) 
    //     getDocuments();
    //     setSaveSuccess(true);
    //     setHasUnsavedChanges(false);
    //   };
    // } catch (err) {
    //   recordFrontEndError('TECDoA.jsx', 'Error saving document')
    // };
    
  }



//Autofill Address Details After Selection
function AutoFillAddress(addressSelected, selectionInstance){
  var newValues = documentValues;
  if(selectionInstance){
    if(selectionInstance === "customer-address"){
      newValues.customer_line_1 = addressSelected.label;
      if(addressSelected.address){
        newValues.customer_line_2 = addressSelected.address;
        newValues.customer_line_3 = addressSelected.city+", "+addressSelected.state+" "+addressSelected.zip;
      }
    }
  }
  setDocumentValues(newValues);
  if(document){
    previewPayloadDocument(documentValues);
  }
  setHasUnsavedChanges(true);
  setSaveSuccess(false);
}

const onCloseClick = e => {
  e.preventDefault()
  setPreviewLoaded(false);
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setdocumentUrl("");
  setDocument(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
  setDocumentLoaded(false);
  setDataLoaded(false);
  setAssignedProduct(undefined);
  setAssignedTrade(undefined);
  setSelectedAddress(undefined);
  setAddressOptions(undefined);
  setinputAddressVal(undefined);
  setDocumentFocus(undefined);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

  return (
    <>
      <div
        className="modal fade"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                TEC Disclosure of Regulation Applicability
              </h5>
              <br />
              { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1 pt-1 text-white">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                  </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}
                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill();
                      AutofillProductDetails(assignedProduct);
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>
                  <br />

                  { assignedProduct ? (
                      <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                          Product Snapshot
                          <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                          <h5>SN: { assignedProduct.stockNumber }</h5>
                          <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                          <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                          </div>
                      </div>
                  ):null}

                  { assignedTrade ? (
                      <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                          Trade In Snapshot
                          <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                            <h5>VIN: { assignedTrade.vin.toUpperCase() }</h5>
                            <h5>YMM: { assignedTrade.year+" "+assignedTrade.make+" "+assignedTrade.model.toUpperCase() }</h5>
                          </div>
                      </div>
                  ):null}

                  { addressOptions && addressOptions.length > 0 ? (
                  <div className="row">
                    <div className="d-flex justify-content-evenly pb-2">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress && selectedAddress.name) ?? null}
                          name="customer-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress(newValue);
                            AutoFillAddress(newValue, "customer-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="Addresses" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  ):(
                    <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Addresses Found</h5>
                    </div>
                  )}
                  
                  <div className="row">
                    <h5>Customer Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Customer Line 1</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="customer_line_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.customer_line_1 ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Customer Line 2</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="customer_line_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.customer_line_2 ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Customer Line 3</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="customer_line_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.customer_line_3 ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">As a purchaser of </label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="as_a_purchaser_line_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.as_a_purchaser_line_1 ?? '' }
                          />
                      </div>
                    </div>

                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">As a purchaser of (Line 2)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="as_a_purchaser_line_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.as_a_purchaser_line_2 ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-8">
                        <label htmlFor="stock">Printed Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="printed_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.printed_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default TECDoA
export const autoYMM = (year, make, model)=>{
    return (year)+" "+(make).toString().toUpperCase()+" "+(model).toString().toUpperCase();
   }
export const autoYearMake = (year, make)=>{
    return (year).trim()+" "+(make).trim()
}
export const autoMakeModel = (make, model)=>{
    return (make).trim()+" "+(model).trim().toString().toUpperCase()
}
export const autoSecurityAddress = (address, city, state, zip)=>{
    return address+ " "+(city+", "+state+" "+zip)
    
}
export const autoCityStateZip = (city, state, zip)=>{
    return city+", "+state+" "+zip;
}

export const autoCountYMM = (year, make, model, productCount=null)=>{
    if (productCount){
        return "("+productCount+") "+(year).trim()+" "+(make).trim()+" "+(model).toString().toUpperCase();
    }else return (year).trim()+" "+(make).trim()+" "+(model).toString().toUpperCase();
}

export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
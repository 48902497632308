export const SET_ORDERS = "SET_ORDERS";

export const setOrders =  (data) => {
    return {
        type: SET_ORDERS, 
        payload: data 
    }
}

const defaultOrdersState = [];

export const ordersReducer = (store = defaultOrdersState , action) => {
    switch (action.type) {
        case SET_ORDERS:
            return action.payload
        default:
            return store
     }
}
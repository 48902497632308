import React, { useMemo, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from 'moment'
import Pagination from '@mui/material/Pagination';
import { useEffect } from "react";
import { CircularProgress, TextField, Typography } from "@mui/material";
import { formatter, wordSplit } from "../../../../utlis/formatters";
import * as api from '../../../../utlis/api'
import { useDispatch} from "react-redux";
import { setShowDashboardSpinner } from "../../../../redux/spinners/spinners";
import { setFinanceRequests, setFinanceRequestsTotalPages, setFilter, setFilterSegment, setSearchState } from "../../../../redux/finance_dashboard/financeDashboardAction";
import { SET_FINANCE_REQUESTS, SET_FINANCE_REQUESTS_TOTAL_PAGES, SET_SEARCH_FILTER_SEGMENT, SET_SEARCH_FILTER, SET_SEARCH_STATE } from "../../../../redux/finance_dashboard/financeDashboardTypes";
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {MenuItem, IconButton} from "@material-ui/core";
// import {InputLabel, Input, Box } from '@mui/material';
// import { useFetch } from "../../../../utlis/api";
import { isIndexPageSearchDisabled } from "../../../../utlis/indexPages";
// import { useFetch } from "../../../../utlis/api";


const FinanceRequest = ({initFinanceRequests, setInitFinanceRequests, loadFinanceRequests}) => {

  const dispatch              = useDispatch();
  // const axios     = useFetch()

  const financeDashboardFinanceRequests = useSelector(state => state?.financeDashboardState.financeRequests);
  const totalPages = useSelector(state => state?.financeDashboardState.financeRequeststotalPages);

  const financeDashboardPeriod = useSelector(state => state?.financeDashboardState.period);
  const startDate = useSelector(state => state?.financeDashboardState.startDate);
  const endDate = useSelector(state => state?.financeDashboardState.endDate);
  const search = useSelector(state => state?.financeDashboardState.filter);
  const filterSegment = useSelector(state => state?.financeDashboardState.filterSegment);
  const searchState = useSelector(state => state?.financeDashboardState.searchState);

  const financeDashboardRegionCode = useSelector(state => state?.financeDashboardState.regionCode);
  const financeDashboardLocationCode = useSelector(state => state?.financeDashboardState.locationCode);
  const role = useSelector((state) => state?.role.role);
  const me = useSelector((state) => state?.me);
  const {showDashboardSpinner}=useSelector(state=>state.spinners)

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  // const [search, SetSearch] = useState('');

  // const [filterSegment, setFilterSegment] = useState('none');
  // const [currentSearch, setCurrentSearch] = React.useState("");
  // const [pageState, setPageState] = useState('uninitalized');

  const financeRequests = useMemo(() => {

    const displayStockNumbers = (request) => {
      if(!request.stockNumbers || request.stockNumbers.length === 0){
        return '0'
      }
      if (request.stockNumbers && request.stockNumbers.length >1) {
        return `${request.stockNumbers[0]}, ...`
      }
      return request.stockNumbers[0]
  }
  
      return currentPage.map(( financeRequest, idx ) =>
        <div key={ idx } className="border-4 border-success border-start d-flex shadow justify-content-between align-items-center my-2 pe-3">
          <div className="p-1" style={{width: '100%', maxWidth: '15rem'}}>
  
            <span className="text-secondary text-uppercase">
              { moment.utc(financeRequest.statusChangeAt).fromNow() }
            </span>
            <p className="m-0">
              {moment.utc(financeRequest.orderDate).format("MMM DD, YYYY")}
            </p>
            { financeRequest.orderStatus === "Quote" ? (
              <h5 style={{ color: 'green' }}>{financeRequest.orderStatus}</h5>
            ):null}
            { financeRequest.orderStatus === "Manager Review" ? (
              <h5 style={{ color: 'orange' }}>{financeRequest.orderStatus}</h5>
            ):null}
            { financeRequest.orderStatus === "Finance Review" ? (
              <h5 style={{ color: 'limegreen' }}>{financeRequest.orderStatus}</h5>
            ):null}
            { financeRequest.orderStatus === "Credit Approval" ? (
              <h5 style={{ color: 'green' }}>{financeRequest.orderStatus}</h5>
            ):null}
            { financeRequest.orderStatus === "Docs Requested" ? (
              <h5 style={{ color: 'orange' }}>Document Request</h5>
            ):null}
            { financeRequest.orderStatus === "Docs Generated" ? (
              <h5 style={{ color: 'darkcyan' }}>{financeRequest.orderStatus}</h5>
            ):null}
            { financeRequest.orderStatus === "Docs Signed" ? (
              <h5 style={{ color: 'green' }}>{financeRequest.orderStatus}</h5>
            ):null}
            { financeRequest.orderStatus === "Funding Request" ? (
              <h5 style={{ color: 'dark blue' }}>{financeRequest.orderStatus}</h5>
            ):null}
            { financeRequest.orderStatus === "Funded" ? (
              <h5 style={{ color: 'darkgreen' }}>{financeRequest.orderStatus}</h5>
            ):null}
            { financeRequest.orderStatus === "Booking Request" ? (
              <h5 style={{ color: 'orangered' }}>{financeRequest.orderStatus}</h5>
            ):null}
            { financeRequest.orderStatus === "Booked" ? (
              <h5 style={{ color: 'black' }}>{financeRequest.orderStatus}</h5>
            ):null}
          </div>
  
          <div className="p-1" style={{width: '100%', maxWidth: '15rem'}}>
            <h5 className="text-dark">{ (financeRequest.customer ? wordSplit(financeRequest.customer, 15)  : "New Customer")}</h5>
            <p className="m-0">Customer</p>
          </div>
          <div className="p-1" style={{width: '100%', maxWidth: '15rem'}}>
          <h5 className="text-dark">{formatter.format(financeRequest.totalAmountFinanced)}</h5>
            <p className="m-0">Total</p>
          </div>
          <div className="p-1" style={{width: '100%', maxWidth: '15rem'}}>
            <h5 className="text-dark">{financeRequest.units}</h5>
            <p className="m-0">Units</p>
          </div>
          <div className="p-1" style={{width: '100%', maxWidth: '15rem'}}>
            <h5 className="text-dark">{financeRequest.trades}</h5>
            <p className="m-0">Trades</p>
          </div>
          <div className="p-1" style={{width: '100%', maxWidth: '15rem'}}>
            <h5 className="text-dark">{displayStockNumbers(financeRequest)}</h5>
            <p className="m-0">Stock Number</p>
          </div>
          <div className="p-1" style={{width: '100%', maxWidth: '15rem'}}>
            <Link to={'/order/'+financeRequest.id} style={{pointerEvents:( financeRequest?.checkedOutByUserId !== me.id && financeRequest?.checkedOutByUserId !== null && role !== 'master' )&&'none'}}>
              <button className=" fw-bold btn btn-primary" disabled={ financeRequest?.checkedOutByUserId !== me.id && financeRequest?.checkedOutByUserId !== null && role !== 'master' }>OPEN ORDER</button>
            </Link>
            {financeRequest?.checkedOutByUserName && 
              <Typography variant="subtitle2">
                Checked out by: {financeRequest?.checkedOutByUserName}
              </Typography>
            }
          </div>
        </div>
      );
    }, [ currentPage, me, role])
  

    const [updatingFinanceRequestPage, setUpdatingFinanceRequestPage] = useState(false);

    useEffect(() => {

      let foundFinanceRequests = (financeDashboardFinanceRequests ?? undefined);

      // //tempfix to avoid infinite loop 
      // if(updatingFinanceRequestPage === true) return
  
      if(foundFinanceRequests?.length > 0 && (updatingFinanceRequestPage === false || initFinanceRequests === true )){
        setInitFinanceRequests(false);
        setUpdatingFinanceRequestPage(true);
        if(foundFinanceRequests.length < 5){
          setCurrentPage(foundFinanceRequests);
        }else{
          let firstItem = 0;
          let lastItem = firstItem + 5;
          setCurrentPage(foundFinanceRequests.slice(firstItem, lastItem));
        }
        dispatch(setSearchState(SET_SEARCH_STATE, 'ready'));
      }

    },[currentPage, dispatch, financeDashboardFinanceRequests, initFinanceRequests, setInitFinanceRequests, updatingFinanceRequestPage]);

  const refreshOrders = useCallback(async (page=currentPageNumber, filter=search, filterCategory=filterSegment) => {
      dispatch(setSearchState(SET_SEARCH_STATE, 'loading'));

      dispatch(setShowDashboardSpinner(true));

      api.updateFinanceDashboardFinanceRequests(financeDashboardPeriod, financeDashboardRegionCode, financeDashboardLocationCode, (role !== me?.role ? true : false), role, page, filter, startDate, endDate, filterCategory)
        .then( res => {
          let foundFinanceRequests = res?.data.data;
          dispatch(setFinanceRequests(SET_FINANCE_REQUESTS , foundFinanceRequests))
          dispatch(setFinanceRequestsTotalPages(SET_FINANCE_REQUESTS_TOTAL_PAGES , res?.data.totalPages))

          if(foundFinanceRequests?.length < 5){
            setCurrentPage(foundFinanceRequests);
          }else if(foundFinanceRequests?.length >= 5){
            let firstItem = 0;
            let lastItem = firstItem + 5;
            setCurrentPage(foundFinanceRequests.slice(firstItem, lastItem));
          }else{
            setCurrentPage([]);
          }
          dispatch(setSearchState(SET_SEARCH_STATE, 'ready'));
          dispatch(setShowDashboardSpinner(false));
        });
  },[currentPageNumber, search, filterSegment, dispatch, financeDashboardPeriod, financeDashboardRegionCode, financeDashboardLocationCode, role, me?.role, startDate, endDate])

    
    const updatePage = useCallback((event, pageNumber) => {
      setCurrentPageNumber(pageNumber);
      refreshOrders(pageNumber);

    },[ refreshOrders ])

  const searchUpdatePage = (e) => {
    const { code } = e;
    if((code === "Enter" || code === "NumpadEnter") && search && search !== ""){
      refreshOrders(1, search);
    }
  }

  function resetSearch(){
    // SetSearch('');
    dispatch(setFilter( SET_SEARCH_FILTER, ''));
    dispatch(setFilterSegment( SET_SEARCH_FILTER_SEGMENT, 'none'));
    refreshOrders(1, "", "none");
  }

  return (
    <div className="mx-3">
      {/* <div id='searchBar' style={{paddingTop: '1rem', display:'flex', alignItems:'center', justifyContent:'space-between', gap:'1rem'}}> */}
      {/* <div style={{ width: '100%' }}> */}
      {/*   <Button variant='contained' startIcon={<SearchIcon/>} onClick={ e => refreshOrders( 1, search ) } >
          search
        </Button>
        <input type='text' onKeyUp={searchUpdatePage} className="form-control text-uppercase" style={{borderRadius:'10px'}} placeholder="Search Stock Number, Customer Name, DBA, Additional Contact, Cosigner, Sales Rep or Sales Manager" value={search} onChange={e=>SetSearch(e.target.value)}/>
      
      </div> */}

      <div className="d-flex justify-content mx-auto pt-2">
        <div>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small" className=" my-2 px-3">
            <Select
              className="form-control rounded-pill"
              value={filterSegment}
              onChange={e => {
                let { value } = e.target;
                // setFilterSegment(value);
                dispatch(setFilterSegment( SET_SEARCH_FILTER_SEGMENT, value));

                if(value === "none"){
                  resetSearch();
                }

              }}
              
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
              data-testid="dashboard.finance.control.filterSegment"
              // disabled={filterSegment === 'none'}
              style={{ width: '250px'}}
            >
              <MenuItem value="none" ><em>Reset Search</em></MenuItem>
              <MenuItem value="customer-dba-contact" ><em>Customer / DBA / Contact</em></MenuItem>
              <MenuItem value="invoice-number" ><em>Invoice Number</em></MenuItem>
              <MenuItem value="sales-rep-manager" ><em>Sales Rep or Manager</em></MenuItem>
              {/* <MenuItem value="total-order-value" ><em>Total Order Value</em></MenuItem> */}
              {/* <MenuItem value="unit-count" ><em>Unit Count</em></MenuItem> */}
              {/* <MenuItem value="trade-count"><em>Trade Count</em></MenuItem> */}
              <MenuItem value="stock-number-vin" ><em>Stock Number / VIN</em></MenuItem>
              <MenuItem value="checked-out-by" ><em>Checked Out By</em></MenuItem>

            </Select>
          </FormControl>
        </div>


        <div className="my-1 py-1" style={{ width: '95vw' }}>
          <TextField id="standard-basic" label={ search && search.length > 0 && search !== "" ? "Press Enter" : "Start Search" } variant="standard"
            type="text"
            style={{ width: '100%'}}
            // className="form-control rounded-pill"
            onChange={e => {
              // SetSearch(e.target.value)
              dispatch(setFilter( SET_SEARCH_FILTER, e.target.value));

            }}
            onKeyUp={searchUpdatePage}
            onFocus={e => { if(filterSegment === 'none') {
              // setFilterSegment('customer'); 
              dispatch(setFilterSegment( SET_SEARCH_FILTER_SEGMENT, 'customer-dba-contact'));

            }}}
            value={search}
            // placeholder="Start Search"
            aria-label="Search"
            disabled={searchState === "loading"}
            />  
        </div>

        <div className="my-1 px-2 py-1">
          <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" className="btn btn-primary" disabled={isIndexPageSearchDisabled(search, filterSegment) || searchState === "loading"} onClick={ e => {
            if(!isIndexPageSearchDisabled(search, filterSegment) && searchState !== "loading"){
              refreshOrders(1, search);
            }    
          }} ><SearchIcon/></IconButton>   

{/* <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
        <DirectionsIcon />
      </IconButton> */}

        </div>
      </div>

      <div style={{ marginLeft: '1rem', marginRight: '1rem', display: 'flow-root', width: '100%' }}>
          <div id='title'>
          <h4 className="my-3 text-uppercase" style={{ float: 'left'}}>Latest Finance Requests</h4>
          </div>
          { totalPages > 1 ? (
            <div className="p-2 m-2" style={{ float: 'right'}}>
              <Pagination count={totalPages} 
                page={currentPageNumber}
                onChange={updatePage}
                />
            </div>
          ):null}
      </div>
      { !showDashboardSpinner ? (
        <div>
          { financeRequests }
        </div>
      ) : <div className="d-flex justify-content-center px-2 align-items-center" style={{width:'100vw'}}><CircularProgress style={{  placeSelf: "center" }}/></div>}
    </div>
  );
};

export default FinanceRequest;

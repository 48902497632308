// import * as api from '../../../../../../../utlis/api.js'
import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";
import { autoSecurityAddress } from './docUtils.js';



export const TECCertOOSSingleGlobalAutofill = (documentValues, order, property=null, value=null, location) => {

    let newValues = documentValues;
    let customerName = "";
    
    if(property ==='customer.name'){
      if(order.customer.type === "entity"){
        customerName = value;
      }else{
        if(order.customer.dba){
          customerName = value+" dba "+order.customer.dba;
        }else{
          customerName = value;
        } 
      }
      
      if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          customerName += " AND "+cosigner.name;
        }
      }
  
      newValues.purchaser_name = customerName;
      newValues.customer_name_1 = customerName;
      newValues.customer_name_2 = customerName;
      newValues.customer_name_3 = customerName;
  }
    if(property ==='customer.dba'){
      if(order.customer.type === "entity"){
        customerName = order.customer.name;
      }else{
        if(value){
          customerName = order.customer.name+" dba "+value;
        }else{
          customerName = order.customer.name;
        } 
      }
      
      if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          customerName += " AND "+cosigner.name;
        }
      }
  
      newValues.purchaser_name = customerName;
      newValues.customer_name_1 = customerName;
      newValues.customer_name_2 = customerName;
      newValues.customer_name_3 = customerName;
  }
    if(property ==='customer.cosigner[0].name'){
      if(order.customer.type === "entity"){
        customerName = order.customer.name;
      }else{
        if(order.customer.dba){
          customerName = order.customer.name+" dba "+order.customer.dba;
        }else{
          customerName = order.customer.name;
        } 
      }
      
      if(value !==''){
          customerName += " AND "+value;
      }
  
      newValues.purchaser_name = customerName;
      newValues.customer_name_1 = customerName;
      newValues.customer_name_2 = customerName;
      newValues.customer_name_3 = customerName;
  }
    if(property ==='customer.deliveryAddress.addressLine1'){
      let addressLine = '';
        if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
          addressLine = (value+" "+order.customer.deliveryAddress.addressLine2);
        }else{
          addressLine = value;
        }
  
        newValues.customer_address_1 = addressLine+" "+order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip
        newValues.customer_address_2 = addressLine+" "+order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip
  }
    if(property ==='customer.deliveryAddress.addressLine2'){
      let addressLine = '';
        if(value != null && value !== undefined && value !== ''){
          addressLine = (order.customer.deliveryAddress.addressLine1+" "+value);
        }else{
          addressLine = order.customer.deliveryAddress.addressLine1;
        }
  
        newValues.customer_address_1 = addressLine+" "+order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip
        newValues.customer_address_2 = addressLine+" "+order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip
  }
    if(property ==='customer.deliveryAddress.city'){
      let addressLine = '';
        if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
          addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
        }else{
          addressLine = order.customer.deliveryAddress.addressLine1;
        }
  
        newValues.customer_address_1 = addressLine+" "+value+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip
        newValues.customer_address_2 = addressLine+" "+value+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip
  }
    if(property ==='customer.deliveryAddress.state'){
      let addressLine = '';
        if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
          addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
        }else{
          addressLine = order.customer.deliveryAddress.addressLine1;
        }
  
        newValues.customer_address_1 = addressLine+" "+order.customer.deliveryAddress.city+", "+value+" "+order.customer.deliveryAddress.zip
        newValues.customer_address_2 = addressLine+" "+order.customer.deliveryAddress.city+", "+value+" "+order.customer.deliveryAddress.zip
        newValues.state_of_1 = value;
        newValues.state_of_2 = value;
  }
    if(property ==='customer.deliveryAddress.zip'){
      let addressLine = '';
        if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
          addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
        }else{
          addressLine = order.customer.deliveryAddress.addressLine1;
        }
  
        newValues.customer_address_1 = addressLine+" "+order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+value
        newValues.customer_address_2 = addressLine+" "+order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+value
  }
    if(property ==='location'){
        newValues.location_address = value.address+" "+value.city+" "+value.state+" "+value.zip;
        newValues.location = value.state;
        newValues.location_phone = value.phone;
        newValues.location_email = value.email;
        if(value.state === "CA"){
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
        }else{
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
      }
  }
    if(property !== null && value !== null){
      return newValues;
  }

    if(order && order.customer && order.customer.name){
      if(order.customer.type === "entity"){
        customerName = order.customer.name;
      }else{
        if(order.customer.dba){
          customerName = order.customer.name+" dba "+order.customer.dba;
        }else{
          customerName = order.customer.name;
        } 
      }
      
      if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          customerName += " AND "+cosigner.name;
        }
      }
  
      newValues.purchaser_name = customerName;
      newValues.customer_name_1 = customerName;
      newValues.customer_name_2 = customerName;
      newValues.customer_name_3 = customerName;
  
      if(order.customer.deliveryAddress){
        var addressLine = '';
        if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
          addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
        }else{
          addressLine = order.customer.deliveryAddress.addressLine1;
        }
  
        newValues.customer_address_1 = addressLine+" "+order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip
        newValues.customer_address_2 = addressLine+" "+order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip
        newValues.state_of_1 = order.customer.deliveryAddress.state;
        newValues.state_of_2 = order.customer.deliveryAddress.state;
      }
  
    }
  
      if(location !== undefined){
        newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
        newValues.location = location.state;
      }
    
      if(location && location.phone){
        newValues.location_phone = location.phone;
      }
      
      if(location && location.email){
        newValues.location_email = location.email;
      }
    
      if(location){
        if(location.state === "CA"){
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
        }else{
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
        }
      }
  
      if(order && order.documentationDate && documentValues.date === undefined){
        newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
      }
    return newValues
}

export const TECCertOOSSingleAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null, location=null, order=null, autofillProductDetails=true) => {
  var newValues = documentValues;

  if(property === 'year' && value !== null){
    newValues.year = (value).toString().trim().replace(/['"]+/g, '');
}

if(property === 'make' && value !== null){
    newValues.make = (value).toString().trim();
}

if(property === 'model' && value !== null){
    newValues.model = (value).toString().trim();
}
if(property === 'fobAddress.addressLine1' && value !== null){

  let formatAddress
  if(selectedProduct?.fobAddress?.addressLine2){
    formatAddress=(value).toString().trim() + ' '+ selectedProduct?.fobAddress?.addressLine2
  }else formatAddress =(value).toString().trim()

  newValues.customer_address_3 = autoSecurityAddress( formatAddress, selectedProduct.fobAddress?.city, selectedProduct.fobAddress?.state, selectedProduct.fobAddress?.zip )
}
if(property === 'fobAddress?.addressLine2' && value !== null){

  let formatAddress = selectedProduct.fobAddress?.addressLine1 + ' '+ (value).toString().trim()
  

  newValues.customer_address_3 = autoSecurityAddress( formatAddress, selectedProduct.fobAddress?.city, selectedProduct.fobAddress?.state, selectedProduct.fobAddress?.zip )
}
if(property === 'fobAddress?.city' && value !== null){

  let formatAddress
  if(selectedProduct?.fobAddress?.addressLine2){
    formatAddress=selectedProduct.fobAddress?.addressLine1 + ' '+ selectedProduct?.fobAddress?.addressLine2
  }else formatAddress =selectedProduct.fobAddress?.addressLine1

  newValues.customer_address_3 = autoSecurityAddress( formatAddress, selectedProduct?.fobAddress?.addressLine2, (value).toString().trim(), selectedProduct.fobAddress?.state, selectedProduct.fobAddress?.zip )
}
if(property === 'fobAddress?.state' && value !== null){

  let formatAddress
  if(selectedProduct?.fobAddress?.addressLine2){
    formatAddress=selectedProduct.fobAddress?.addressLine1 + ' '+ selectedProduct?.fobAddress?.addressLine2
  }else formatAddress =selectedProduct.fobAddress?.addressLine1

  newValues.customer_address_3 = autoSecurityAddress( formatAddress, selectedProduct?.fobAddress?.addressLine2, selectedProduct.fobAddress?.city, (value).toString().trim(), selectedProduct.fobAddress?.zip )
}
if(property === 'fobAddress?.zip' && value !== null){

  let formatAddress
  if(selectedProduct?.fobAddress?.addressLine2){
    formatAddress=selectedProduct.fobAddress?.addressLine1 + ' '+ selectedProduct?.fobAddress?.addressLine2
  }else formatAddress =selectedProduct.fobAddress?.addressLine1

  newValues.customer_address_3 = autoSecurityAddress( formatAddress, selectedProduct?.fobAddress?.addressLine2, selectedProduct.fobAddress?.city, selectedProduct.fobAddress?.state, (value).toString().trim() )
}


if(property !== null && value !== null){
    return newValues;
}

  if(autofillProductDetails){
    newValues.year = (selectedProduct.year).trim();
    newValues.make = (selectedProduct.make).trim();
    newValues.model = (selectedProduct.model).toString().toUpperCase();
    newValues.vin = (selectedProduct.vin).toString().toUpperCase();

    newValues.customer_address_3 = selectedProduct?.fobAddress?.addressLine1+" "+selectedProduct?.fobAddress?.addressLine2+" "+selectedProduct?.fobAddress?.city+", "+selectedProduct?.fobAddress?.state+" "+selectedProduct?.fobAddress?.zip;

  }
  

  return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  // X1.2- Field Type Specific Preview Formatting
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  // Data point formatting
  if(payload.date){
    payload.date = moment.utc(payload.date).format("MM/DD/YY");
  }

  return payload;
}
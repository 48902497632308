import React from "react";
import { useParams } from "react-router-dom";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import { useOrder } from "../../../../../../../../redux/orders/order"
import { addProductUpgrade,editProductUpgrade,deleteProductUpgrade } from './../../../../../../../../redux/products';
import NumberFormat from "react-number-format";
import { useDispatch, useSelector} from "react-redux";
import * as api from "../../../../../../../../utlis/api"
import { removeChars } from "../../../../../../../../utlis/formatters";
import { updateChangesArray, updateStatus } from "../../../../../../../../redux/saveAndCopy"

const Upgrades = ({ product }) => {
  let { id }  = useParams();
  const dispatch = useDispatch();
  // const order = useOrder();

  const changesArray = useSelector((state) => state?.copyChangesToDocumentsRedux.changesArray);


  const onPriceChange= (options, sourceInfo)=>{
    if ( sourceInfo.source === 'prop' ) {
      return;
    }
    const { floatValue: formatted } = options
    let {id:index} = sourceInfo.event.target
    dispatch(editProductUpgrade({index: index, prop:'price',  value:removeChars(formatted)}))
    dispatch(updateStatus('unsaved'));
  }
  
  const addNewUpgrade = (e) => {
    e.preventDefault();
    let initUpgrade = {
      description:'',
      includedInSalesPrice: false,
      lineItemId: undefined,
      notes: "",
      price: undefined,
      productId: product.id,
      serialNumber: '',
      stockNumber: '',
      subjectToFet: false,
      subjectToSalesTax: false,
      type: '',
      invalid:false
    }

    if(changesArray && Array.isArray(changesArray)){

      let newArray = JSON.parse(JSON.stringify(changesArray));


      if(!newArray.upgrades){
        newArray.upgrades = [initUpgrade];
        dispatch(updateChangesArray(newArray));
      }else{
        newArray.upgrades.push(initUpgrade);
        dispatch(updateChangesArray(newArray));
      }
    }


    dispatch(addProductUpgrade({upgradeObject:initUpgrade, orderId: id, productId: product.id}))
    dispatch(updateStatus('unsaved'));
  }
   
  const deleteUpgrade = (e, upgradeIndex) => {
    e.preventDefault();
    let {id:upgradeId}=e.target
    let upgradesCopy = [...product.upgrades]
    let filteredArr = upgradesCopy.filter((upgrade, i)=>{
      let filtered
      if (upgrade?.id) {
        filtered = (upgrade?.id.toString()!==upgradeId)
        if (!filtered) api.deleteProductUpgrade(id, product.id, upgrade?.id)
      }
      if(!upgrade?.id ){
        filtered = (i !== upgradeIndex)
      } 
        return filtered
      })
      dispatch(deleteProductUpgrade(filteredArr))
      dispatch(updateStatus('unsaved'));
  }
  
  const editUpgrade = (e, id=null, prop) =>{
    let { value, checked, id:index } = e.target
    if (prop === 'subjectToFet' ||prop === 'subjectToSalesTax'){
      value = checked
    }

    if(changesArray && Array.isArray(changesArray)){

      let newArray = JSON.parse(JSON.stringify(changesArray));

      // If no upgrades array, create it.
      if(!newArray.upgrades){
        newArray.upgrades = [];
        dispatch(updateChangesArray(newArray));
      }

      // ??
      if(!newArray.upgrades[index]){
        newArray.upgrades.push([]);
        dispatch(updateChangesArray(newArray));
      }

      if(newArray.upgrades[index] && !newArray.upgrades[index][prop]){
        newArray.upgrades[index][prop] = {};
        dispatch(updateChangesArray(newArray));
      }


      if((newArray?.upgrades?.length - 1) >= index){
        // if(newArray["upgrades"][index]["upgrade.enabled"]?.oldValue){
        //   dispatch(updateChangesArray( (prev) => ({
        //     ...prev,
        //     "upgrades": prev["upgrades"][index]["upgrade.enabled"] =   
        //     {
        //       "newValue": "yes",
        //       "oldValue": newArray["upgrades"][index]["upgrade.enabled"].oldValue
        //     }
        //   })));
        // }else{
        //   dispatch(updateChangesArray( (prev) => ({
        //     ...prev,
        //     "upgrades": prev["upgrades"][index]["upgrade.enabled"] =
        //     {
        //       "newValue": "yes",
        //       "oldValue":  product?.upgrades && product?.upgrades.length > 0 && product.upgrades[index] ? "yes" : "no"
        //     }
        //   })));
        // }

        if(newArray["upgrades"][index][prop]?.oldValue){
          newArray["upgrades"][index][prop] = {
            "newValue": value,
            "oldValue":  newArray["upgrades"][index][prop].oldValue
          }
          dispatch(updateChangesArray(newArray));
        }else{
          if(newArray["upgrades"][index][prop]){

            newArray["upgrades"][index][prop] = {
              "newValue": value,
              "oldValue":  product?.upgrades && product?.upgrades.length > 0 && product.upgrades[index] ? product.upgrades[index][prop] : '--'
            }
            dispatch(updateChangesArray(newArray));
          }
        }
      }
    }

    dispatch(editProductUpgrade({index:index, prop:prop,  value:value}))
    // dispatch(editProductUpgrade({index, prop,  value}))
    dispatch(updateStatus('unsaved'));
  }

  return (
    <div>
        {/* ------------------------------ Row 8 ------------------------------------ */}
        <div className="row">
          <div className="col-12 bg-primary py-3 text-white d-flex" style={{marginLeft: "-20px"}}>
          <h6 style={{marginRight: "10px", marginLeft: "50px"}}>Upgrades (Priced Separately)</h6>
            <AddCircleOutlineIcon 
              style={{cursor: "pointer"}} 
              aria-label='add new upgrade'
              onClick={e => addNewUpgrade(e)}
              data-testid="product.upgrade.control.addUpgradeButton"
            />
         
          </div>
          { product?.upgrades?.length > 0 && product?.upgrades.map((upgrade, index) => {
        return (
          <div className="row mt-3" key={index}>
            <div className="d-flex mb-3 ">
              <div className="col-5 mx-3">
                <label htmlFor="stock">SERIAL # <span style={{ color: 'red' }}>*</span></label>
                <input
                  type="text"
                  id={index}
                  className="form-control rounded-pill mt-1 "
                  value={upgrade?.serialNumber ?? ''}
                  aria-label='upgrade serial number'
                  data-testid="product.upgrade.serialNumber"
                  onChange={(e) => {
                    editUpgrade(e, upgrade?.id, 'serialNumber')
                    
                  }}
                />
                <span style={{display: (upgrade?.invalid&&(!Boolean(upgrade?.serialNumber)||upgrade?.serialNumber==='')) ? 'block' : 'none'}} className="text-danger small">Serial number is required.</span>
              </div>
              <div className="col-5 mx-3">
                <label htmlFor="stock">TYPE <span style={{ color: 'red' }}>*</span></label>
                <input
                  type="text"
                  id={index}
                  className="form-control rounded-pill mt-1 "
                  value={upgrade?.type ?? ''}
                  aria-label='upgrade type'
                  data-testid="product.upgrade.type"
                  onChange={(e) => {
                    editUpgrade(e, upgrade?.id, 'type')
                    
                  }}
                />
                <span style={{display: (upgrade?.invalid&&(!Boolean(upgrade?.type)|| upgrade?.type==='')) ? 'block' : 'none'}}className="text-danger small">Type is required.</span>
              </div>
              </div>
              <div className="row">
              <div className="col-5 mx-3">
                <label htmlFor="stock">SALES PRICE <span style={{ color: 'red' }}>*</span></label>
                 <NumberFormat
                    className="rounded-pill form-control"
                    value={ Number(upgrade?.price).toFixed(2)??''}
                    name="salesPrice"
                    aria-label='upgrade sales price'
                    thousandSeparator={true}
                    data-testid="product.upgrade.salesPrice"
                    decimalScale={2}
                    prefix={'$'}
                    // format={format}
                    id={index}
                    onBlur={()=>{dispatch(editProductUpgrade({index: index, prop:'price',  value:Number(upgrade?.price).toFixed(2)}))}}
                    onValueChange={ onPriceChange }
                  />
                <span style={{display: (upgrade?.invalid && !Boolean(upgrade?.price)) ? 'block' : 'none'}}className="text-danger small">Price is required.</span>
              </div>
              <div className="col-5 mx-3">
                <label htmlFor="stock">STOCK # <span style={{ color: 'red' }}>*</span></label>
                <input
                  type="text"
                  id={index}
                  aria-label='upgrade stock number'
                  data-testid="product.upgrade.stockNumber"
                  className="form-control rounded-pill mt-1 "
                  value={ upgrade?.stockNumber ?? ''}
                  onChange={(e) => editUpgrade(e, upgrade?.id, 'stockNumber')}
                />
                <span style={{display: (upgrade?.invalid&&(!Boolean(upgrade?.stockNumber) || upgrade?.stockNumber==='')) ? 'block' : 'none'}} className="text-danger small">Stock number is required.</span>
              </div>
              <div className="row mt-3">
              <div className="col-3 mx-3">
                <input
                    type="checkbox"
                    style={{padding:'10px'}}
                    className="custom-control-input-group-lg"
                    checked={upgrade?.subjectToFet}
                    aria-label='upgrade subject to fet'
                    id={index}
                    data-testid="product.upgrade.subjectToFet"
                    onChange={(e) => editUpgrade(e, upgrade?.id, 'subjectToFet')}
                    // onBlur={}
                    />
                <label htmlFor="salesTax" style={{paddingLeft:'10px'}}>
                    <p> SUBJECT TO FET </p>
                </label>
                </div>
                  <div className="col-3 mx-3">
                    <input
                      type="checkbox"
                      style={{padding:'10px'}}
                      id={index}
                      className="custom-control-input-group-lg"
                      checked={ upgrade?.subjectToSalesTax }
                      data-testid="product.upgrade.subjectToSalesTax"
                      aria-label='upgrade subject to sales tax'
                      onChange={(e) => editUpgrade(e, upgrade?.id, 'subjectToSalesTax')}
                    />
                    <label htmlFor="salesTax" style={{paddingLeft:'10px'}}>
                        <p> SUBJECT TO SALES TAX </p>
                    </label>  
                  </div>
              </div>
              <div className="row">
                <div className="col-12 mx-3">
                <label htmlFor="stock">DESCRIPTION/NOTES</label>
                  <textarea
                    maxLength="250"
                    className="form-control rounded-lg mt-1 "
                    rows="5"
                    data-testid="product.upgrade.description"
                    name="upgradeNotes"
                    aria-label='upgrade note'
                    id={index}
                    value={upgrade?.description ?? ''}
                    onChange={(e) => editUpgrade(e, upgrade?.id, 'description')}
                  ></textarea>
                </div>
              </div>
              <div className="text-center mt-3">
                <button className="btn btn-primary" data-testid="product.upgrade.control.deleteUpgradeButton" id={upgrade?.id} aria-label='delete upgrade' onClick={e => deleteUpgrade(e, index)}>
                  DELETE UPGRADE
                </button>
              </div>
            </div>
          </div>
        );
        })
      }
        </div>

        <hr className="my-3" style={{width: "99%"}} />
    </div>
  );
}

export default Upgrades;

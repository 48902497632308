export const GET_ORDER_DETAILS  = "GET_ORDER_DETAILS";
export const SET_ORDER_DETAILS  = "SET_ORDER_DETAILS";
export const SAVE_ORDER_DETAILS = "SAVE_ORDER_DETAILS";

const defaultOrdersState = {
    order_id: null,
};

//TODO: Port to redux...
export const detailsReducer = (store = defaultOrdersState , action) => {
    switch (action.type) {
        default:
            return store
     }
}

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
// import ErrorBoundary from "./components/ErrorBoundary";
import "./index.css";
import store from "./redux/store";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

require('dotenv').config();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <ErrorBoundary> */}
      <React.StrictMode>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </React.StrictMode>
      {/* </ErrorBoundary> */}
    </Provider>
  </React.StrictMode>, 
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

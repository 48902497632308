import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useFetch,recordFrontEndError } from "../../../../../../../utlis/api";
import { Autocomplete, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {  Checkbox, FormGroup } from '@mui/material';
import { removeChars, formatter, formatPhoneNumber, formatUpperCase } from "../../../../../../../utlis/formatters";
import { getAllNames } from "../../../../../../../utlis/documentHelpers";
import { useOrder } from "../../../../../../../redux/orders/order"
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { IATitleAppGlobalAutofill, IATitleAppAutofillProductDetails, preProcessPayload } from "../DocumentAutofill/IATitleApp"

const IATitleApp = ({document_id, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "ia-title-app";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  // const dispatch = useDispatch();

  // const stateAbbrev = States.map(state => {
  //   return state.abbreviation;
  // });

  const order = useOrder();

  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");


  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);
  const [ nameOptions, setNameOptions ] = useState([]);

  // Data Structure & Init //
  
  var initialDocumentStructure = {
  }

  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);

  const moneyFields = useMemo(()=>{return["purchase_price", "sale_price"]},[]);
  const phoneFields = useMemo(()=>{return[]},[]);
  const upperCaseFields = useMemo(()=>{return["vin_hin", "secondary_vin", "model", "f_vinhin_1", "f_vinhin_2"]},[]);
  const checkboxes = useMemo(()=>{return[
    "ut04_selected",
    "ut05_selected",
    "ut06_selected",
    "ut07_selected",
    "second_elt_ident_selected",
    "third_elt_ident_selected",
  ]},[]);


  // X1- Current General On Change
  const handleDocumentValuesChange = e =>{
    // e.preventDefault();
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatPhoneNumber(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }

    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('IATitleApp.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);
  

  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");


      setPreviousDocumentId(document_id);
      setDocumentLoaded(true);
      loadDocument(document_id);
      setNameOptions(getAllNames(order, null));
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, order, documentLoaded, loadDocument ])

  const [assignedProduct, setAssignedProduct] = useState(undefined);

 // Autofill //

 const GlobalAutofill = useCallback(async () => {

  let newValues = IATitleAppGlobalAutofill(documentValues, order);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
},[ documentValues, order ])


const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });
    
    if(!document){
      return null;
    }

      var docCopy = JSON.parse(JSON.stringify(document));
      docCopy.payload =  JSON.parse(JSON.stringify(payload));
      docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
      docCopy.payload =  JSON.stringify(docCopy.payload);
        delete docCopy.documentStatus;
      try{
        let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
        setdocumentUrl(fileURL)
        } catch (err) {
          recordFrontEndError('IATitleApp.jsx', 'Error previewing document payload: ' + err)
        };  
    
},[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


    //Autofill Product Details After Selection
    const AutofillProductDetails = useCallback(async (selectedProduct) => {
      let newValues = IATitleAppAutofillProductDetails(selectedProduct, documentValues);

      if(newValues){
        setDocumentValues(newValues);
        previewPayloadDocument(documentValues);
        setHasUnsavedChanges(true);
        if(document){
          previewPayloadDocument(documentValues);
        }
        setSaveSuccess(false);
      }


    },[ document, documentValues, previewPayloadDocument ]);

  // Document BREAD + Preview // TODO: Move to Redux

  useEffect(() => {
    if(document && document.payload && !dataLoaded){
      var existingPayload = false;
      var documentPayload = JSON.parse(document.payload);
      if(documentPayload && Object.keys(documentPayload).length > 0){
        existingPayload = true;
        setDocumentValues(documentPayload);
      }
      var foundAssignedProduct = null;
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
        foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
      }
      
      // setAddressOptions(getOrderLevelAddresses(order));
      if(foundAssignedProduct){
          setAssignedProduct(foundAssignedProduct);
          // setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
          setNameOptions(getAllNames(order, foundAssignedProduct));
      }
      
        
        if(!existingPayload){
          GlobalAutofill();
          previewPayloadDocument(documentValues);
          if(foundAssignedProduct){
            AutofillProductDetails(foundAssignedProduct)
          }
        }
        
      setDataLoaded(true);
    }
  },[ documentValues, document, dataLoaded, order, AutofillProductDetails, GlobalAutofill, previewPayloadDocument ])


  function ReloadAutoFillValues(){  
    var foundAssignedProduct = null;
    if(document && document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }

    GlobalAutofill();
    if(foundAssignedProduct){
      AutofillProductDetails(foundAssignedProduct)
    }
  }


  
    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();

      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
    
  

      DocumentHandleSubmit(document, payload, null, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });


      
    }

// Component Helpers //

 

  const onCloseClick = e => {
    e.preventDefault()
    setHasUnsavedChanges(false);
    setSaveSuccess(false);
    setdocumentUrl("");
    setDocument(undefined);
    setNameOptions([]);
    setPreviousDocumentId(0);
    setDocumentValues(initialDocumentStructure);
    setDocumentLoaded(false);
    setDataLoaded(false);
    setAssignedProduct(undefined);
    setDocumentFocus(undefined);
  }
  
  const [hasClickedClose, setHasClickedClose] = useState(false);
  
  const closeClicked = e => {
    if( hasUnsavedChanges ){
        setHasClickedClose(true);
    } else {
        onCloseClick(e);
    }
  }
  
  const preCloseSave = e => {
    handleSubmit(e)
    setHasClickedClose(false);
  }
  
  const preCloseClose = e => {
    setHasClickedClose(false);
    onCloseClick(e)
  }

  return (
    <>
      <div
        className="modal fade"
        // id="ca-statement-of-facts"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                Iowa Title Application
              </h5>
              <br />
              { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1 pt-1 text-white">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                  </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}


                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}


                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ fontSize: '12px', overflowY: 'scroll', height: 'inherit'}}>
               
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      ReloadAutoFillValues();
                      // GlobalAutofill();
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>
                  <br />
                  { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Product Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                        </div>
                    </div>
                  ):(
                    <div className="m-2 p-2 border">
                        <span>No Assigned Product</span>
                    </div>
                  )}

                  <div className="row">
                    <h5>Dealer's Report of Sale</h5>
                  </div>

                  <Autocomplete
                      style={{ width: '100%'}}
                      value={ (documentValues.name) ?? null}
                      name="owner_name"
                      onBlur={(e) => updatePreview(e)}
                      onChange={(event, newValue) => {
                        let updatedObject = documentValues;
                        updatedObject.name = newValue.name;
                        setDocumentValues(updatedObject);
                        // setDocumentValues({...documentValues,["owner_name"]:newValue.name});
                      }}
                      // inputValue={inputAddressVal}
                      // onInputChange={(event, newInputValue) => {
                      //   setinputAddressVal(newInputValue);
                      // }}
                      options={nameOptions}
                      sx={{ width: 400 }}
                      renderInput={(params) => <TextField {...params} label="Purchaser Name" />}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                    />

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">D/R Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="dr_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dr_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Registration Month</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="reg_month"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.reg_month ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Apply For</label>
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="title_type_toggle"
                            value={(documentValues.title_type_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="regular" control={<Radio />} label="Regular Title" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="salvage" control={<Radio />} label="Salvage Title" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <br />
                    <h5>Owner Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Owner #1 - First Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="first_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.first_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Middle Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="middle_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.middle_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Last Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="last_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.last_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Birth Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="dob"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dob ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Iowa DL/ID or SSN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="ssn"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.ssn ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">FEIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="fein_num"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.fein_num ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Bona fide Residence Address of Owner #1</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="address_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.address_1 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="city_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.city_1 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="state_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.state_1 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="zip_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.zip_1 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">County</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="county_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.county_1 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Mailing Address of Owner #1</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_address_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_address_1 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_city_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_city_1 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_state_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_state_1 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_zip_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_zip_1 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">County</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_county_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_county_1 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Ownership Status</label>
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="owner_status_toggle"
                            value={(documentValues.owner_status_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="or" control={<Radio />} label="OR" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="and" control={<Radio />} label="AND" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Owner #2 - First Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="first_name_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.first_name_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Middle Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="middle_name_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.middle_name_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Last Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="last_name_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.last_name_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Birth Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="dob_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dob_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Iowa DL/ID or SSN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="ssn_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.ssn_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">FEIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="fein_num_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.fein_num_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Bona fide Residence Address of Owner #1</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="address_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.address_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="city_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.city_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="zip_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.zip_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">County</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="county_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.county_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Mailing Address of Owner #2</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_address_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_address_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_city_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_city_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_zip_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_zip_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">County</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_county_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_county_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="primary_user_selected"
                            checked={(documentValues && documentValues.primary_user_selected ? documentValues.primary_user_selected : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="Check if there is a designated Primary User to establish Iowa residency and complete information on Page 2." 
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="three_owners_selected"
                            checked={(documentValues && documentValues.three_owners_selected ? documentValues.three_owners_selected : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="Check if there are three owners and also complete Owner #3 information on Page 3." 
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Check if title or registration... [Checkbox]</label>
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="plates_mailed_selected"
                            checked={(documentValues && documentValues.plates_mailed_selected ? documentValues.plates_mailed_selected : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="Check if title or registration/plates are to be mailed to any address other than the owner's address and provide address on Page 3." 
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Model</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="model"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.model ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Type</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="body_type"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.body_type ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Style</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="style"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.style ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Color</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="color"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.color ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Fuel</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="fuel"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.fuel ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Cylinders</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="cylinders"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.cylinders ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Tonnage</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="tonnage"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.tonnage ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">GVWR</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="gvwr"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.gvwr ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Sq. Footage</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="sqft"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.sqft ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Plate Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="plate_num"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.plate_num ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Plate Type</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="plate_type"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.plate_type ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">New / Used</label>
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="new_used_toggle"
                            value={(documentValues.new_used_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="new" control={<Radio />} label="New" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="used" control={<Radio />} label="Used" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">VIN of Traded Vehicle #1</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin_of_traded"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin_of_traded ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">VIN of Traded Vehicle #2</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin_of_traded_2"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin_of_traded_2 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Trailer Empty Weight</label>
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="trailer_weight_toggle"
                            value={(documentValues.trailer_weight_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="over2" control={<Radio />} label="Over 2,000 lbs." />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="under2" control={<Radio />} label="2,000 lbs or less" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Purchase Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="purchase_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchase_date ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="row">
                    <br />
                    <h5>Security Interest Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Security Interest Holders</label>
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="sec_interest_toggle"
                            value={(documentValues.sec_interest_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="one" control={<Radio />} label="One" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="two" control={<Radio />} label="Two" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="three" control={<Radio />} label="Three" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="none" control={<Radio />} label="None" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="Clear" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">First Security Interest</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="first_sec_interest"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.first_sec_interest ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Address (Street, City, State, Zip Code)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="sec_inerest_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.sec_inerest_address ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">FEIN / SS Number / ELT Number [Checkbox]</label>
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">FEIN / SS Number / ELT Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="elt_ident"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.elt_ident ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Check her if... [Checkbox]</label>
                      </div>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="row">
                    <br />
                    <h5>Purchase Price</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Purchase Price</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchase_price"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchase_price ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">[Checkbox]</label>
                        <label htmlFor="stock">Exemption Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="exemption_code"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.exemption_code ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">I claim a business trade... [Checkbox]</label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Date 1</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date_1 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Date 2</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Date 3</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date_3 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">By Line</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="by_line"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.by_line ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">[CB] I authorize the Iowa..</label>
                        <label htmlFor="stock">[CB] I authorize this app..</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="auth_to"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.auth_to ?? '' }
                          />
                      <label htmlFor="stock">[CB] Yes, I would like..</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vol_contribution_amount"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vol_contribution_amount ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="row">
                    <br />
                    <h5>For Dealer Use Only</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Sale Price</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="sale_price"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.sale_price ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Less Trade-In</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="less_trade_in"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.less_trade_in ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Less charges exempt from fee for new registration</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="less_charge_new_reg"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.less_charge_new_reg ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Less rebate applied to purchase price of the vehicle</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="less_rebate"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.less_rebate ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Equals fee for new registration price</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="equals_fee"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.equals_fee ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Date registration applied for Card issued</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="card_issued"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.card_issued ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">If none, so state</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="none_line"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.none_line ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Registration fee collected</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="reg_fee"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.reg_fee ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date_4 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Dealer Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="dealer_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dealer_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Dealership Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="dealership_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dealership_name ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Authorized Representative and Title</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="auth_rep_name_and_title"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.auth_rep_name_and_title ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <hr></hr>

                  <h5>Page 2</h5>

                  <div className="row">
                    <br />
                    <h5>Primary User Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Primary User #1 - First Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="first_name_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.first_name_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Middle Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="middle_name_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.middle_name_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Last Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="last_name_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.last_name_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Birth Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="dob_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dob_3 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Iowa DL/ID or SSN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="ssn_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.ssn_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">FEIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="fein_num_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.fein_num_3 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Bona fide Residence Address of Primary User #1</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="address_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.address_3 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="city_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.city_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="zip_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.zip_3 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">County</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="county_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.county_3 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Mailing Address of Primary User #1</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_address_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_address_3 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_city_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_city_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_zip_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_zip_3 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">County</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_county_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_county_3 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Primary #2 - First Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="first_name_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.first_name_4 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Middle Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="middle_name_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.middle_name_4 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Last Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="last_name_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.last_name_4 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Birth Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="dob_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dob_4 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Iowa DL/ID or SSN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="ssn_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.ssn_4 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">FEIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="fein_num_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.fein_num_4 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Bona fide Residence Address of Owner #1</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="address_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.address_4 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="city_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.city_4 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="zip_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.zip_4 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">County</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="county_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.county_4 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Mailing Address of Owner #2</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_address_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_address_4 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_city_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_city_4 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_zip_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_zip_4 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">County</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_county_4"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_county_4 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <h5>Fee for New Registration - Exemptions</h5>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">UT01 - Transfer by gift, please explain [Checkbox - ut01_selected]</label>
                        <label htmlFor="stock">Explain Gift</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="gift_exp"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.gift_exp ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">UT02 - Purchase is one of the following nonprofit or government organizations: </label>
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="ut02_toggle"
                            value={(documentValues.ut02_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="a" control={<Radio />} label="a. Rehabilitation facility" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="b" control={<Radio />} label="b. Rehabilitation" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="c" control={<Radio />} label="c. Care facility" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="d" control={<Radio />} label="d. Care Facility (residential) for the mentally ill." />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="e" control={<Radio />} label="e. Educational institution" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="f" control={<Radio />} label="f. Free-standing hospice facility." />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="g" control={<Radio />} label="g. Government" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="h" control={<Radio />} label="h. Hospital licensed under Iowa Code 135B." />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="i" control={<Radio />} label="i. Community health center" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="j" control={<Radio />} label="j. Migrant health center." />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="k" control={<Radio />} label="k. Coummunity mental health center" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="l" control={<Radio />} label="l. Legal aid organization." />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="m" control={<Radio />} label="m. Non-profit museum" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="n" control={<Radio />} label="n. Non-profit art center." />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="o" control={<Radio />} label="o. Non-profit organ procurement organization" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">UT03</label>
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="ut03_toggle"
                            value={(documentValues.ut03_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="a" control={<Radio />} label="a. Vehicle trasnferred from a sole..." />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="b" control={<Radio />} label="b. Corporate merge - vehicle transferred..." />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="Clear" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Termination Date of Prior Business</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="term_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.term_date ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Date of Creation of New Entity</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date_new_entity"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date_new_entity ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="ut04_selected"
                            checked={(documentValues && documentValues.ut04_selected ? documentValues.ut04_selected : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="UT04 - Purchased by a licensed dealership for resale." 
                          />
                        </FormGroup>
                        <label htmlFor="stock">Dealer License Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="dealer_license_num"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dealer_license_num ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="ut05_selected"
                            checked={(documentValues && documentValues.ut05_selected ? documentValues.ut05_selected : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="UT05 - Purchased for rental." 
                          />
                        </FormGroup>
                        <label htmlFor="stock">Purchaser's Sales Tax Permit number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="permit_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.permit_number ?? '' }
                          />
                      </div>
                    </div>
                  </div>


                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="ut06_selected"
                            checked={(documentValues && documentValues.ut06_selected ? documentValues.ut06_selected : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="UT06 - Leased vehicle used solely in interstate commerce." 
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="ut07_selected"
                            checked={(documentValues && documentValues.ut07_selected ? documentValues.ut07_selected : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="UT07 - Vehicle registered and/or operated under Iowa Code 326 (reciprocity) with gross weight of 13 tons or more and with 25 percent of the mileage outside of Iowa. Both weight and mileage must be met for the first four years of operation to be eligible for the exemption." 
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">UT08</label>
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="ut08_toggle"
                            value={(documentValues.ut08_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="a" control={<Radio />} label="a. Manufactured housing or mobile home." />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="b" control={<Radio />} label="b. Inheritance or court order (eg divorce)" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="c" control={<Radio />} label="c. Vehicle purchased outside Iowa with no intent to use in Iowa. (A 'move-in')" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="d" control={<Radio />} label="d. Homemade vehicle" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="e" control={<Radio />} label="e. Sales, use or occupational tax paid to another state at time of purchase." />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="f" control={<Radio />} label="f. Name dropped" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="g" control={<Radio />} label="g. Name added." />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="h" control={<Radio />} label="h. Even trade or down trade." />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="i" control={<Radio />} label="i. Delivered to a resident Native American Indeian on the reservation." />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="j" control={<Radio />} label="j. In-transit title, fee to be paid in the title-holder's state of residence." />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="k" control={<Radio />} label="k. Transfer to or from a living or irrevocable trust." />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="s" control={<Radio />} label="s. Salvage vehicle." />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="other" control={<Radio />} label="l. Other, please explain:" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>

                      { documentValues && documentValues.ut08_toggle === "other" ? (
                      <>
                        <label htmlFor="stock">Other Reason</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="other_reason"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.other_reason ?? '' }
                          />
                      </>
                      ):null}
                      </div>
                    </div>
                  </div>

                  <h5>Page 3</h5>

                  <div className="row">
                    <br />
                    <h5>Additional Owner Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Owner #3 - First Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="first_name_5"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.first_name_5 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Middle Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="middle_name_5"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.middle_name_5 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Last Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="last_name_5"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.last_name_5 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Birth Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="dob_5"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dob_5 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Iowa DL/ID or SSN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="ssn_5"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.ssn_5 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">FEIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="fein_num_5"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.fein_num_5 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Bona fide Residence Address of Owner #1</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="address_5"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.address_5 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="city_5"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.city_5 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="zip_5"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.zip_5 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">County</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="county_5"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.county_5 ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="row">
                    <br />
                    <h5>One-Time Mailing Address</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">One Time Mailing Address</label>
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup   
                            row                         
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="otm_type_toggle"
                            value={(documentValues.otm_type_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="both" control={<Radio />} label="Both" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="title" control={<Radio />} label="For Title" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="reg" control={<Radio />} label="Registration/Plates" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="" control={<Radio />} label="Clear" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">OTM Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="otm_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.otm_name ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">OTM Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="otm_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.otm_address ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="otm_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.otm_city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">State</label>
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Zip Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="otm_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.otm_zip ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">County</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="otm_county"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.otm_county ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="row">
                    <br />
                    <h5>Additional Trade-In Vehicles</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Additional Trade-In Vehicle(s), if any (VIN)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="trade_vins"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.trade_vins ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="row">
                    <br />
                    <h5>Additional Security Interest Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Second Security Interest</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="second_sec_int_held"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.second_sec_int_held ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="second_elt_ident_selected"
                            checked={(documentValues && documentValues.second_elt_ident_selected ? documentValues.second_elt_ident_selected : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="FEIN / SSN / ELT Number" 
                          />
                        </FormGroup>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="second_fein"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.second_fein ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Address (Street, City, State, Zip Code)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="second_sec_interest_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.second_sec_interest_address ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Third Security Interest</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="third_sec_int_held"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.third_sec_int_held ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock"></label>
                        <FormGroup className="m-2">
                          <FormControlLabel 
                            control={<Checkbox color="default" 
                            name="third_elt_ident_selected"
                            checked={(documentValues && documentValues.third_elt_ident_selected ? documentValues.third_elt_ident_selected : false)}
                            onChange={(e) => { handleDocumentValuesChange(e) }} />} 
                            label="FEIN / SSN / ELT Number" 
                          />
                        </FormGroup>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="third_fein"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.third_fein ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Address (Street, City, State, Zip Code)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="third_sec_interest_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.third_sec_interest_address ?? '' }
                          />
                      </div>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="row">
                    <br />
                    <h5>Additional Explanation or Instructions</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Additional Trade-In Vehicle(s), if any (VIN)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="additonal_exp"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.additonal_exp ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default IATitleApp

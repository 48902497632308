import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  useParams
} from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { removeChars, formatter, formatPhoneNumber, formatUpperCase } from "../../../../../../../utlis/formatters";
import { getOrderLevelAddresses, updateAddressOptions } from "../../../../../../../utlis/documentHelpers";
import { useOrder, rdx } from "../../../../../../../redux/orders/order"
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { OregonVINGlobalAutofill, OregonVINAutofillProductDetails, preProcessPayload } from "../DocumentAutofill/OregonVIN"

const OregonVINModal = ({document_id, location, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "or-vin-inspect";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  const order = useOrder();
  const salesReps = useSalesReps();
  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  const [document, setDocument] = useState(undefined);
  const [previousDocumentId, setPreviousDocumentId] = useState(0);
  
  // Data Structure & Init //
  var initialDocumentStructure = {
    // "CLEAR FORM": undefined,
    name: undefined, // Customer Information
    odl_id_number: undefined, // Customer Information
    state_of_issue: undefined,  // Customer Information
    phone_area: undefined, 
    phone_number: undefined,  // Customer Information?
    address: undefined, // Customer Information
    city: undefined, // Customer Information
    state: undefined, // Customer Information
    zip: undefined, // Customer Information
    
    license_plate: undefined, // Vehicle Information
    registration_province: undefined,  // Vehicle Information
    year: undefined,  // Vehicle Information
    make: undefined,  // Vehicle Information
    body_style: undefined,  // Vehicle Information
    model: undefined,  // Vehicle Information
    
    weight_exceeded: undefined, // Radio
    loaded_weight: undefined, 
    
    vin: undefined, // VIN Inspection
    
    // VIN Checkbox Sets
    // VIN LOCATION
    // VIN TYPE
    // VIN ATTACHED BY
    // VIN CONDITION OF VIN
    // VIN FED STANDARDS STICKER
    
    inspector_name: undefined, // Inspector Details
    agency_dealership: undefined,  // Inspector Details
    inspector_address: undefined,  // Inspector Details
    // inspector_city: undefined,  // Inspector Details
    // inspector_state: undefined,  // Inspector Details
    // inspector_zip: undefined,  // Inspector Details
    
    // inspector_area_code: undefined,  // Inspector Details
    inspector_number: undefined,  // Inspector Details
    
    dealer_number: undefined,  // Inspector Details
    inspection_date: undefined,  // Inspector Details
    
    referral_reason: undefined, // Law Enforcement Referral
    referral_date: undefined,  // Law Enforcement Referral
    
    inspecting_officer_name: undefined,
    agency_name: undefined, 
    agency_address: undefined, 
    agency_city: undefined, 
    agency_state: undefined, 
    agency_zip: undefined, 

    lev_compliant: undefined, 
    // Shaded Area For DMV Use Only
    // assigned_vin: undefined, 
    // assigned_vin_placement: undefined, 
    // assigned_control_number: undefined, 
    // counter_number: undefined, 
    // remarks: undefined, 
    remarks_2: undefined, 
    dpsst_number: undefined, 
    hidden_vin_check: undefined, 
    hidden_vin_check_invalid_reason: undefined, 
    ncic_led_check_frame: undefined, 
    ncic_led_check_body: undefined, 
    ncic_led_check_engine: undefined, 
    agency_custody: undefined, 
    date: undefined, 
    assign_pre_vin: undefined, 
    assign_vin_numberless: undefined, 
    no_vin_assignment: undefined, 
    windshield_visible: undefined, 
    body_left: undefined, 
    body_right: undefined, 
    engine_compartment: undefined, 
    trunk: undefined, 
    frame_vin: undefined, 
    body_vin: undefined, 
    engine_vin: undefined, 
    frame: undefined, 
    engine: undefined, 
    location_other: undefined, 
    metal_plate: undefined, 
    stamped_on_body: undefined, 
    stamped_on_frame: undefined, 
    label: undefined, 
    vin_type_other: undefined, 
    vin_location_other: undefined, 
    type_other: undefined, 
    not_visible: undefined, 
    rosette_rivets: undefined, 
    round_rivets: undefined, 
    screws: undefined, 
    adhesive: undefined, 
    stamped: undefined, 
    vin_attachment_other: undefined, 
    attachment_other: undefined, 
    condition_ok: undefined, 
    condition_atered: undefined, 
    condition_damaged: undefined, 
    condition_missing: undefined, 
    condition_cannot_locate: undefined, 
    condition_new: undefined, 
    condition_assigned_dmv: undefined, 
    vin_condition_other: undefined, 
    condition_other: undefined, 
    fed_agree: undefined, 
    fed_disagree: undefined, 
    fed_damaged: undefined, 
    fed_missing: undefined, 
    fed_na: undefined, 
    vin_fed_other: undefined, 
    other_fed: undefined, 
  }
  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);

  const removeFormatting = e =>{
    const {name,value}=e.target;
    setDocumentValues({...documentValues,[name]:removeChars(value)});
  }

  const moneyFields = useMemo(()=>{return[]},[]);
  const phoneFields = useMemo(()=>{return["inspector_number"]},[]);
  const upperCaseFields = useMemo(()=>{return["vin"]},[]);
  const checkboxes = useMemo(()=>{return[
  ]},[]);
  // const radios = [
  // ];

  // X1- Current General On Change
  const handleDocumentValuesChange = e =>{
    // e.preventDefault();
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  // const [documentLoaded, setDocumentLoaded] = useState(false);
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch(err) {
        recordFrontEndError('OregonVinModal.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])

  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
  if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
    setdocumentUrl("");
    setPreviousDocumentId(document_id);
    // setDocumentLoaded(true);
    loadDocument(document_id);
  }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, loadDocument ])
  const [assignedProduct, setAssignedProduct] = useState(undefined);
  const [productOptions, setproductOptions] = useState(undefined);
  const [tradeinOptions, setTradeinOptions] = useState(undefined);

  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const [inputAddressVal, setinputAddressVal] = useState(undefined);
  const [addressOptions, setAddressOptions] = useState(undefined);

// Dropdowns //

if(order && order.products && order.products.length > 0 && productOptions === undefined){
  let getProductOptions = [];
  order.products.map(product => {
    let str = `${product.stockNumber.toString().toUpperCase()}`;
    let obj = {id: product.id, name: str}
    getProductOptions.push(obj);
    return null;
  })
  setproductOptions(getProductOptions);
}

if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
  let getTradeinOptions = [];
  order.tradeins.map(tradein => {
    let str = `${tradein.year} ${tradein.make} ${tradein.model.toString().toUpperCase()}`;
    let obj = {id: tradein.id, name: str}
    getTradeinOptions.push(obj);
    return null;
  })
  setTradeinOptions(getTradeinOptions);
}

  // Autofill //

//   const preProcessPayload = useCallback((payload) => {

//     if(payload.weight_exceeded === "yes"){
//       payload.weight_exceeded_yes = "yes";
//       payload.weight_exceeded_no = "no";
//     }else if(payload.weight_exceeded === "no"){
//       payload.weight_exceeded_yes = "no";
//       payload.weight_exceeded_no = "yes";
//     }else{
//       payload.weight_exceeded_yes = "";
//       payload.weight_exceeded_no = "";
//     }

//     if(payload.loaded_weight === "yes"){
//       payload.loaded_weight_yes = "yes";
//       payload.loaded_weight_no = "no";
//     }else if(payload.loaded_weight === "no"){
//       payload.loaded_weight_yes = "no";
//       payload.loaded_weight_no = "yes";
//     }else{
//       payload.loaded_weight_yes = "";
//       payload.loaded_weight_no = "";
//     }

//     // X1.2- Field Type Specific Preview Formatting
//     Object.keys(payload).forEach(key => {
//       if(moneyFields.indexOf(key) >= 0){
//           payload[key] = formatMoneyValue(payload[key]);
//       }else if(phoneFields.indexOf(key) >= 0){
//           payload[key] = formatPhoneNumber(payload[key]);
//       }else if(upperCaseFields.indexOf(key) >= 0){
//           payload[key] = formatUpperCase(payload[key]);
//       }
//     });
//     setDocumentValues(JSON.parse(JSON.stringify(payload)));
//     Object.keys(payload).forEach(key => {
//       if(checkboxes.indexOf(key) >= 0){
//         payload[key] = payload[key] ? "yes": "no";
//       }
//     });

//     if(payload.inspection_date){
//       payload.inspection_date = moment.utc(payload.inspection_date).format("MM/DD/YY");
//     }

//     return payload;
// },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])


  const GlobalAutofill = useCallback(async () => {
    let newValues = OregonVINGlobalAutofill(documentValues, order, null, null, location, null, salesReps);
    if(newValues){
      setDocumentValues(newValues);
      setHasUnsavedChanges(true);
      setSaveSuccess(false);
    }

    // function getRepByEmployeeId(employeeId){
    //   if(salesReps && salesReps.length > 0){
    //     let foundRep = salesReps.find(rep => rep.employee_ID === employeeId);
    //     if(foundRep){
    //       return foundRep.preferredName ? foundRep.preferredName+" "+foundRep.lastName : foundRep.firstName+" "+foundRep.lastName;
    //     }
    //   }
    //   return "";
    // }

    // var newValues = documentValues;
    // if(order && order.customer && order.customer.name){
    //   // newValues.name = formatNameLastMiddleFirst(order.customer.name).toString().toUpperCase();
    //   if(order.customer.type === "entity"){
    //     newValues.name = order.customer.name.toString().toUpperCase();
    //   }else{
    //     newValues.name = formatNameLastMiddleFirst(order.customer.name).toString().toUpperCase();
    //   }

    //   if(order.customer.dba){
    //     newValues.name += " dba "+order.customer.dba;
    //   }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
    //     let cosigner = order.customer.cosigners[0];
    //     if(cosigner && cosigner.name){
    //         newValues.name += " AND "+cosigner.name;
    //     }
    //   }
    // }

    // if(order && order.customer && order.customer.phone){
    //   newValues.phone_area = getAreaCode(order.customer.phone);
    //   newValues.phone_number = getPhoneNumber(order.customer.phone);
    // }

    // if(order && order.salesRepId){
    //   newValues.inspector_name = getRepByEmployeeId(order.salesRepId);
    // }

    if(location && location.state){
      newValues.state_of_issue = location.state;
    }

    if(location && location.state){
      if(location.state === "OR"){
        newValues.agency_dealership = "TEC EQUIPMENT, INC.";
        newValues.inspector_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
        // newValues.inspector_city = location.city;
        // newValues.inspector_state = location.state;
        // newValues.inspector_zip = location.zip;
        // newValues.inspector_area_code = getAreaCode(location.phone);
        newValues.inspector_number = formatPhoneNumber(location.phone);
        newValues.dealer_number = "555";
      }
    }

    // if(order && order.customer && order.customer.deliveryAddress){
    //   var addressLine = '';
    //   if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
    //     addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
    //   }else{
    //     addressLine = order.customer.deliveryAddress.addressLine1;
    //   }

    //   addressLine = addressLine.toString().toUpperCase()+", "+order.customer.deliveryAddress.city.toString().toUpperCase()+", "+order.customer.deliveryAddress.state.toString().toUpperCase()+" "+order.customer.deliveryAddress.zip.toString().toUpperCase();

    //   newValues.address = addressLine;
    // }


    // // if(order && order.customer && order.customer.deliveryAddress){
    // //   var addressLine = '';
    // //   if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
    // //     addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
    // //   }else{
    // //     addressLine = order.customer.deliveryAddress.addressLine1;
    // //   }
    // //   newValues.address = addressLine;
    // //   newValues.city_state_zip = (order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
    // // }

    // // if(order && order.customer && order.customer.mailingAddress){
    // //   var addressLine = '';
    // //   if(order.customer.mailingAddress.addressLine2 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
    // //     addressLine = (order.customer.mailingAddress.addressLine1+" "+order.customer.mailingAddress.addressLine2);
    // //   }else{
    // //     addressLine = order.customer.mailingAddress.addressLine1;
    // //   }
    // //   newValues.mailing_address = addressLine;
    // //   newValues.mailing_city_state_zip = (order.customer.mailingAddress.city+", "+order.customer.mailingAddress.state+" "+order.customer.mailingAddress.zip);
    // // }

    setDocumentValues(newValues);
    // setHasUnsavedChanges(true);
  },[documentValues, order, location, salesReps])

  const previewPayloadDocument = useCallback(async (documentValues) => {
    var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });
    
    var docCopy = JSON.parse(JSON.stringify(document));
    docCopy.payload =  JSON.parse(JSON.stringify(payload));
    docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
    docCopy.payload =  JSON.stringify(docCopy.payload);
    delete docCopy.documentStatus;

    try {
      let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
      const file = new Blob(
        [response.data], 
        {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
      setdocumentUrl(fileURL)
    } catch(err) {
      recordFrontEndError('OregonVinModal.jsx', 'Error previewing document payload: ' + err)
    };  
  },[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


  const AutofillProductDetails = useCallback(async (selectedProduct) => {
    let newValues = OregonVINAutofillProductDetails(selectedProduct, documentValues);

    if(newValues){
      setDocumentValues(newValues);
      previewPayloadDocument(documentValues);
      setHasUnsavedChanges(true);
      previewPayloadDocument(documentValues);
      setSaveSuccess(false);
    }
    // var newValues = documentValues;
    // newValues.year = (selectedProduct.year).trim().replace(/['"]+/g, '');
    // newValues.make = (selectedProduct.make).trim();
    // newValues.model = (selectedProduct.model).trim().toString().toUpperCase();
    // newValues.body_style = (selectedProduct.vehicleType);

    // setDocumentValues(newValues);
    // previewPayloadDocument(documentValues);
    // // setAddressOptions(updateAddressOptions(selectedProduct, order));
    // setHasUnsavedChanges(true);
  },[ documentValues, previewPayloadDocument ]);
 
  useEffect(() => {
    if(document && document.payload && !dataLoaded){
      var existingPayload = false;
      var documentPayload = JSON.parse(document.payload);
      if(documentPayload && Object.keys(documentPayload).length > 0){
    
        existingPayload = true;

        // Loading Use Effect
        if(document.additionalData){
          const methodMap = { 
            selectedAddress: setSelectedAddress, 
          };

          let parsedAdditionalData = JSON.parse(document.additionalData);
          Object.keys(parsedAdditionalData).forEach(function(key) {
            if(methodMap[key]){
              methodMap[key](parsedAdditionalData[key]);
            }
          });
        }

    
        if(documentPayload.inspection_date){
          documentPayload.inspection_date = moment.utc(documentPayload.inspection_date).format("YYYY-MM-DD");
        }
        
        setDocumentValues(documentPayload);
      }

      var foundAssignedProduct = null;
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
        foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
      }
      
      setAddressOptions(getOrderLevelAddresses(order));
      if(foundAssignedProduct){
          setAssignedProduct(foundAssignedProduct);
          setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
      }
      
      if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
      }else{
        // if(document && document.itemIds){
        //   var itemIds = document.itemIds.split(',');
        //     var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
        //     let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
        //     let obj = {id: foundProduct.id, name: str}
        //     // setproductVal(obj);
        //     // if(foundProduct){
        //     //   setAddressOptions(updateAddressOptions(foundProduct, order));
        //     // }
        //     streamDocument();
        // }
  
      }
        
      setDataLoaded(true);
    }
  },[ documentValues, document, dataLoaded, AutofillProductDetails, GlobalAutofill, order, previewPayloadDocument ])


    

  // function streamDocument(){
  //   axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
  //     .then(response => {
  //       const file = new Blob(
  //         [response.data], 
  //         {type: 'application/pdf'});
  //         const fileURL = URL.createObjectURL(file);
  //         // window.open(fileURL);
  //       setdocumentUrl(fileURL)
  //     }).catch(err => {
  //     })
  // }

  const updatePreview = e => {
    e.preventDefault();
    // updateFormatting(e);
    previewPayloadDocument(documentValues);
  }



  const handleSubmit = async event => {
    event.preventDefault();

    let payload = JSON.parse(JSON.stringify(documentValues));

    payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
  

    // const payload = JSON.parse(JSON.stringify(documentValues));
    // var docCopy = document;
    // docCopy.payload = payload;
    // docCopy.payload = preProcessPayload(docCopy.payload);
    // docCopy.payload =  JSON.stringify(docCopy.payload);
    // delete docCopy.documentStatus;
    
    // if(selectedProduct && selectedProduct.id){
    //   docCopy.itemIds = `${selectedProduct.id}`;
    // }

    //Save Dropdown Selections
    let dropdownData = {};
    if(selectedAddress){
      dropdownData.selectedAddress = {
        id: selectedAddress.id,
        name: selectedAddress.name
      };
    }

    let additionalData = JSON.stringify(dropdownData);

    DocumentHandleSubmit(document, payload, additionalData, () => {
      getDocuments();
      setSaveSuccess(true);
      setHasUnsavedChanges(false);
    });

    // docCopy.additionalData = JSON.stringify(dropdownData);

    // try {
    //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
    //     if (res) {
    //       await axios.post(`/document/${document.id}/generate`)
    //       getDocuments();
    //       setSaveSuccess(true);
    //       setHasUnsavedChanges(false);
    //   };
    // } catch (err) {
    //   recordFrontEndError('OregonVinModal.jsx', 'Error saving document: ' + err)
    // };
    
  };

// useEffect(() => {
  //Autofill Product Details After Selection
  // if(selectedProduct !== undefined && productToggleAutoFill){

// },[ selectedProduct, productToggleAutoFill ])

  //Autofill Tradein Details After Selection
// if(selectedTradein !== undefined && tradeinToggleAutoFill){
//   var newValues = documentValues;
//   newValues.trade_year = (selectedTradein.year);
//   newValues.trade_make = (selectedTradein.make);
//   newValues.trade_model = (selectedTradein.model);
//   newValues.trade_body_type = (selectedTradein.seriesBodyType);
//   newValues.trade_serial_number = (selectedTradein.serialNumber);
//   newValues.balance_owner = (order.customer.name); //Need specific field for TRADE IN OWNER?
//   newValues.balance_owner_address = (order.customer.deliveryAddress.addressLine1);
//   newValues.amount = (selectedTradein.balanceOwed);
//   newValues.trade_allowance = (selectedTradein.tradeAllowanceAtTerms);
//   newValues.balance_owed = (selectedTradein.balanceOwed);
//   newValues.cash_refund = (selectedTradein.cashRefundToCustomer);
//   newValues.net_trade_allowance = (selectedTradein.finalTradeAllowance);
//   // newValues.cash_down_payment = (selectedTradein);

//   setDocumentValues(newValues);
//   setTradeinToggleAutoFill(false);
//   previewPayloadDocument(documentValues);
// }

  //Autofill Address Details After Selection
  function AutoFillAddress(addressSelected, selectionInstance){
    var newValues = documentValues;
    if(selectionInstance){
      if(selectionInstance === "main-address"){
        newValues.address = addressSelected.address.toString().toUpperCase()+", "+(addressSelected.city+", "+addressSelected.state+" "+addressSelected.zip);
        // newValues.city_state_zip = (addressSelected.city+", "+addressSelected.state+" "+addressSelected.zip);
      }

      // if(addressInstance === "owner-lessee-address"){
        
      // }
    }



    setDocumentValues(newValues);
    previewPayloadDocument(documentValues);
    // setAddressInstance(undefined);
  }

  const onCloseClick = e => {
    e.preventDefault()
    setDataLoaded(false);
    setHasUnsavedChanges(false);
    setSaveSuccess(false);
    setAssignedProduct(undefined);
    setproductOptions(undefined);
    setTradeinOptions(undefined);
    setdocumentUrl("");
    setDocumentFocus(undefined);
    setDocument(undefined);
    setPreviousDocumentId(0);
    setDocumentValues(initialDocumentStructure);
    setSelectedAddress(undefined);
    setinputAddressVal(undefined);
    setAddressOptions(undefined);
    setHasClickedClose(false);
  }
  
  const [hasClickedClose, setHasClickedClose] = useState(false);
  
  const closeClicked = e => {
    if( hasUnsavedChanges ){
        setHasClickedClose(true);
    } else {
        onCloseClick(e);
    }
  }
  
  const preCloseSave = e => {
    handleSubmit(e)
    setHasClickedClose(false);
  }
  
  const preCloseClose = e => {
    setHasClickedClose(false);
    onCloseClick(e)
  }

  return (
    <>
      <div
        className="modal fade"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                Oregon VIN Inspection
              </h5>
              <br />
              { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1 pt-1 text-white">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                  </div>
              ): hasUnsavedChanges ? (
              <button
                type="button"
                className="btn-close bg-white"
                aria-label="Close"
                onClick={closeClicked}
              ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  onClick={preCloseClose}
                ></button>
                )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>

            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill();
                      AutofillProductDetails(assignedProduct);
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>


                  { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                      Product Snapshot
                      <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                      </div>
                    </div>
                  ):(
                    <div className="m-2 p-2 border">
                      <span>No Assigned Product</span>
                    </div>
                  )}

                  <div className="row">
                    <h5>Main Address Selection</h5>
                    {addressOptions && addressOptions.length > 0 ? (
                    <div className="d-flex justify-content-evenly">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress && selectedAddress.name) ?? null}
                          name="mailing-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress(newValue);
                            AutoFillAddress(newValue, "main-address");
                            // setAddressToggleAutoFill(true);
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          //id="controllable-states-demo"
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="ADDRESS" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Addresses Found</h5>
                      </div>
                    )}
                  </div>

                  <div className="row">
                    <h5>Customer Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-4">
                        <label htmlFor="stock">Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-4">
                        <label htmlFor="stock">ODL/ID/Customer #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="odl_id_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.odl_id_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-4">
                        <label htmlFor="stock">State of Issue</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="state_of_issue"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.state_of_issue ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                    <div className="p-2">
                        <label htmlFor="stock">Area Code</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="phone_area"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.phone_area ?? '' }
                          />
                      </div>
                      <div className="p-2">
                        <label htmlFor="stock">Phone #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="phone_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.phone_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-6">
                        <label htmlFor="stock">Address Line</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.address ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                    <h5>Vehicle Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2">
                        <label htmlFor="stock">Plate Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="license_plate"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.license_plate ?? '' }
                          />
                      </div>
                      <div className="p-2">
                        <label htmlFor="stock">State/Province of Registration</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="registration_province"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.registration_province ?? '' }
                          />
                      </div>
                      <div className="p-2">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                      <div className="p-2">
                        <label htmlFor="stock">Body Style</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="body_style"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.body_style ?? '' }
                          />
                      </div>
                      <div className="p-2">
                        <label htmlFor="stock">Model</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="model"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.model ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">

                      <div className="mb-3 p-2 col-md-12 ">
                        <label htmlFor="customerName" className="form-label">
                        If this is a motorized vehicle, is the gross vehicle weight rating (GVWR) over 26,000 pounds?
                        </label>
                        <br />
                        <FormControl className="px-3 pt-2">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="weight_exceeded"
                            value={(documentValues.weight_exceeded ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel className="px-3" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel className="px-3" value="no" control={<Radio />} label="No" />
                            <FormControlLabel className="px-3" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>

                      {/* <div className="p-2">
                        <label htmlFor="stock">weight_exceeded [Radio Yes/No] - If this is a motorized vehicle, is the gross vehicle weight rating (GVWR) over 26,000 pounds?</label>
                      </div> */}
                    </div>

                    <div className="d-flex justify-content-evenly">

                      <div className="mb-3 p-2 col-md-12 ">
                        <label htmlFor="customerName" className="form-label">
                        If this is a non-motorized vehicle, is the loaded weight over 8,000 pounds?
                        </label>
                        <br />
                        <FormControl className="px-3 pt-2">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="loaded_weight"
                            value={(documentValues.loaded_weight ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel className="px-3" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel className="px-3" value="no" control={<Radio />} label="No" />
                            <FormControlLabel className="px-3" value="" control={<Radio />} label="None" />
                          </RadioGroup>
                        </FormControl>
                      </div>

                      {/* <div className="p-2">
                        <label htmlFor="stock">loaded_weight [Radio Yes/No] - If this is a non-motorized vehicle, is the loaded weight over 8,000 pounds?</label>
                      </div> */}
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                    <h5>Inspector Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-6">
                        <label htmlFor="stock">Name of Inspector</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="inspector_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.inspector_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-6">
                        <label htmlFor="stock">Agency or Dealership</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="agency_dealership"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.agency_dealership ?? '' }
                          />
                      </div>
                    </div>                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-8">
                        <label htmlFor="stock">Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="inspector_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.inspector_address ?? '' }
                          />
                      </div>
                      <div className="p-2 col-4">
                        <label htmlFor="stock">Telephone #</label>
                        <input
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          onFocus={removeFormatting}
                          className="form-control rounded-pill mt-1"
                          name="inspector_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.inspector_number ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-6">
                        <label htmlFor="stock">Oregon Dealer Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="dealer_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dealer_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-6">
                        <label htmlFor="stock">Date of Inspection</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="inspection_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.inspection_date ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
            { saveSuccess ? (
              <span
                className="btn btn-success mx-auto"
              >
                Saved Document
              </span>
            ):(
              <button
              type="button"
              className="btn btn-primary mx-auto"
              // data-bs-dismiss="modal"
              onClick={handleSubmit}
              >
              Save
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

const {
  useSalesReps
} = rdx

export default OregonVINModal

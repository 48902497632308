import React,{useState} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Logo from "../../../assets/loginPage-logoTransparent.png";
import { CircularProgress } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";

//Order page
const Login = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { instance } = useMsal();

  const msalLogin = async () => {
    setIsLoading(true)
    await instance.loginRedirect(loginRequest).then(res => {
      setIsLoading(false);
    })
  }

  return (
    <Box 
    id={`login-page`}
    sx={classes.loginPageOuterWrapper}
    >
      <Box 
        id={``}
        sx={classes.loginPageContentWrapper}
      >
        <img 
          id={`login-page-logo`}
          sx={classes.loginPageLogoImage}
          src={Logo}
          alt="TEC Logo"
          />
          <span>{ ( process?.env?.REACT_APP_CURRENT_APP_VERSION ?? "") }</span>

        {isLoading?<CircularProgress/>:<Button 
          id={`login-page-login-button`}
          variant="contained"
          onClick={ () => {
            localStorage.clear();
            msalLogin();
          }}
        >
          Sign In
        </Button>}
      </Box>
    </Box>
  );
};

const classes = {
  loginPageOuterWrapper: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
  loginPageContentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 5,
  },
  loginPageLogoImage: {
    /* The img width/height must go inside <img /> tag.  Alternatively, width of
     loginPageWrapper container can be used to constrain <img />. */
  },
}

export default Login;

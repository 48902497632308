import React, { useEffect } from 'react'

import Card from "@mui/material/Card";

import Navbar from "../../../Components/Navbar/Navbar";
import importedHistory from "../../../version_history.json";
import { useDispatch } from "react-redux"
import { setOrder } from "../../../redux/orders/order";

const VersionHistory = () => {
  const dispatch  = useDispatch()

  useEffect(() => {
    dispatch(setOrder({}))
  }, [ dispatch ])

  return (
    <div>
      <Navbar title="FAST Version History" />
      <Card className="p-3 mx-auto" style={{ maxWidth: "75vw", }}>
        <p>Current Version { (process?.env?.REACT_APP_CURRENT_APP_VERSION ?? null) }</p>
        { importedHistory && importedHistory.length > 0 ? (importedHistory.map(entry => (
          <div className="p-3">
            <h5 style={{ fontSize: "14px", }}>Version: { entry.Version } (Date: { entry.Date })</h5>
            <ul>
              { entry.Updates && entry.Updates.length > 0 ? (entry.Updates.map(update => (
                <li style={{ fontSize: "12px", }}>{ update }</li>
                ))):null}
            </ul>
          </div>

        ))):null}

      </Card>
    </div>
  );
};

export default VersionHistory;

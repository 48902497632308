import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";
import { autoCountYMM } from './docUtils.js';

export const TECCertOOSMultiGlobalAutofill = (documentValues, order, property=null, value=null, location) => {

  let newValues = documentValues;
  let customerName = "";
  
  if(property ==='customer.name'){
    if(order.customer.type === "entity"){
      customerName = value;
    }else{
      if(order.customer.dba){
        customerName = value+" dba "+order.customer.dba;
      }else{
        customerName = value;
      } 
    }
    
    if(order.customer.cosigners && order.customer.cosigners.length > 0){
      let cosigner = order.customer.cosigners[0];
      if(cosigner && cosigner.name){
        customerName += " AND "+cosigner.name;
      }
    }
    newValues.purchaser_name = customerName;
    newValues.customer_name_1 = customerName;
    newValues.customer_name_2 = customerName;
    newValues.customer_name_3 = customerName;
}
  if(property ==='customer.dba'){
    if(order.customer.type === "entity"){
      customerName = order.customer.name;
    }else{
      if(value){
        customerName = order.customer.name+" dba "+value;
      }else{
        customerName = order.customer.name;
      } 
    }
    
    if(order.customer.cosigners && order.customer.cosigners.length > 0){
      let cosigner = order.customer.cosigners[0];
      if(cosigner && cosigner.name){
        customerName += " AND "+cosigner.name;
      }
    }
    newValues.purchaser_name = customerName;
    newValues.customer_name_1 = customerName;
    newValues.customer_name_2 = customerName;
    newValues.customer_name_3 = customerName;
}
  if(property ==='customer.cosigner[0].name'){
    if(order.customer.type === "entity"){
      customerName = order.customer.name;
    }else{
      if(order.customer.dba){
        customerName = order.customer.name+" dba "+order.customer.dba;
      }else{
        customerName = order.customer.name;
      } 
    }
    
    if(value !==''){
        customerName += " AND "+value;
    }
    newValues.purchaser_name = customerName;
    newValues.customer_name_1 = customerName;
    newValues.customer_name_2 = customerName;
    newValues.customer_name_3 = customerName;
}
  if(property ==='customer.deliveryAddress.addressLine1'){
    let addressLine = '';
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        addressLine = (value+" "+order.customer.deliveryAddress.addressLine2);
      }else{
        addressLine = value;
      }

      let addressFormatted = addressLine+" "+formatUpperCase(order.customer.deliveryAddress.city)+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip

      newValues.customer_address_1 = addressFormatted;
      newValues.customer_address_2 = addressFormatted;
}
  if(property ==='customer.deliveryAddress.addressLine2'){
    let addressLine = '';
      if(value != null && value !== undefined && value !== ''){
        addressLine = (order.customer.deliveryAddress.addressLine1+" "+value);
      }else{
        addressLine = order.customer.deliveryAddress.addressLine1;
      }

      let addressFormatted = addressLine+" "+formatUpperCase(order.customer.deliveryAddress.city)+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip

      newValues.customer_address_1 = addressFormatted;
      newValues.customer_address_2 = addressFormatted;
}
  if(property ==='customer.deliveryAddress.city'){
    let addressLine = '';
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
      }else{
        addressLine = order.customer.deliveryAddress.addressLine1;
      }

      let addressFormatted = addressLine+" "+formatUpperCase(value)+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip

      newValues.customer_address_1 = addressFormatted;
      newValues.customer_address_2 = addressFormatted;
}
  if(property ==='customer.deliveryAddress.state'){
    let addressLine = '';
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
      }else{
        addressLine = order.customer.deliveryAddress.addressLine1;
      }

      let addressFormatted = addressLine+" "+formatUpperCase(order.customer.deliveryAddress.city)+", "+value+" "+order.customer.deliveryAddress.zip

      newValues.customer_address_1 = addressFormatted;
      newValues.customer_address_2 = addressFormatted;
      newValues.state_of_1 = value;
      newValues.state_of_2 = value;
}
  if(property ==='customer.deliveryAddress.zip'){
    let addressLine = '';
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
      }else{
        addressLine = order.customer.deliveryAddress.addressLine1;
      }

      let addressFormatted = addressLine+" "+formatUpperCase(order.customer.deliveryAddress.city)+", "+order.customer.deliveryAddress.state+" "+value

      newValues.customer_address_1 = addressFormatted;
      newValues.customer_address_2 = addressFormatted;
}
  if(property ==='location'){
      newValues.location_address = value.address+" "+value.city+" "+value.state+" "+value.zip;
      newValues.location = value.state;
      newValues.location_phone = value.phone;
      newValues.location_email = value.email;
      if(value.state === "CA"){
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
      }else{
      newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
      }
}
  if(property ==='documentationDate'){
    newValues.date = moment.utc(value).format("YYYY-MM-DD");
}

  if(property !== null && value !== null){
    return newValues;
}

  if(order && order.customer && order.customer.name){
    if(order.customer.type === "entity"){
      customerName = order.customer.name;
    }else{
      if(order.customer.dba){
        customerName = order.customer.name+" dba "+order.customer.dba;
      }else{
        customerName = order.customer.name;
      } 
    }
    
    if(order.customer.cosigners && order.customer.cosigners.length > 0){
      let cosigner = order.customer.cosigners[0];
      if(cosigner && cosigner.name){
        customerName += " AND "+cosigner.name;
      }
    }

    newValues.purchaser_name = customerName;
    newValues.customer_name_1 = customerName;
    newValues.customer_name_2 = customerName;
    newValues.customer_name_3 = customerName;

    if(order.customer.deliveryAddress){
      var addressLine = '';
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
      }else{
        addressLine = order.customer.deliveryAddress.addressLine1;
      }

      let addressFormatted = addressLine+" "+formatUpperCase(order.customer.deliveryAddress.city)+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip

      newValues.customer_address_1 = addressFormatted;
      newValues.customer_address_2 = addressFormatted;
      newValues.state_of_1 = order.customer.deliveryAddress.state;
      newValues.state_of_2 = order.customer.deliveryAddress.state;
    }
  }

    if(location !== undefined){
      newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
      newValues.location = location.state;
    }
  
    if(location && location.phone){
      newValues.location_phone = location.phone;
    }
    
    if(location && location.email){
      newValues.location_email = location.email;
    }
  
    if(location){
      if(location.state === "CA"){
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
      }else{
      newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
      }
    }

    if(order && order.documentationDate && documentValues.date === undefined){
      newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }

  return newValues
}

export const TECCertOOSMultiAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null, location=null, order=null, autofillProductDetails=true, productCount=0) => {
    var newValues = documentValues;

    if(property === 'year' && value !== null){
      if(autofillProductDetails){
        newValues.as_a_purchaser_line_1 = autoCountYMM((value).toString().trim(), (selectedProduct.make).trim(), (selectedProduct.model).toString().toUpperCase());
      }else{
        newValues.as_a_purchaser_line_1 = autoCountYMM((value).toString().trim(), (selectedProduct.make).trim(), (selectedProduct.model).toString().toUpperCase(), productCount);
      }
  }

  if(property === 'make' && value !== null){
      if(autofillProductDetails){
        newValues.as_a_purchaser_line_1 = autoCountYMM((selectedProduct.year).trim(), (value).toString().trim(), (selectedProduct.model).toString().toUpperCase());
      }else{
        newValues.as_a_purchaser_line_1 = autoCountYMM((selectedProduct.year).trim(), (value).toString().trim(), (selectedProduct.model).toString().toUpperCase(), productCount);
      }
  }

  if(property === 'model' && value !== null){
      if(autofillProductDetails){
        newValues.as_a_purchaser_line_1 = autoCountYMM((selectedProduct.year).trim(), (selectedProduct.make).trim(), (value).toString().trim());
      }else{
        newValues.as_a_purchaser_line_1 = autoCountYMM((selectedProduct.year).trim(), (selectedProduct.make).trim(), (value).toString().trim(), productCount);
      }
  }
if(property !== null && value !== null){
      return newValues;
  }

  if(selectedProduct){
    if(autofillProductDetails){
      newValues.as_a_purchaser_line_1 = (selectedProduct.year).trim()+" "+(selectedProduct.make).trim()+" "+(selectedProduct.model).toString().toUpperCase();
      newValues.as_a_purchaser_line_2 = (selectedProduct.vin).toString().toUpperCase();
    }else{
      newValues.as_a_purchaser_line_1 = "("+productCount+") "+(selectedProduct.year).trim()+" "+(selectedProduct.make).trim()+" "+(selectedProduct.model).toString().toUpperCase();
    }
  }
    
    return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  // X1.2- Field Type Specific Preview Formatting
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  // Data point formatting
  if(payload.date){
    payload.date = moment.utc(payload.date).format("MM/DD/YY");
  }

  return payload;
}
// import * as formatter from '../../../../../../../utlis/formatters.js'
import moment from 'moment'
import { removeChars, formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";
import {calculateProductWarrantyTotal, calculateProductSubtotal} from "../../../../../../../utlis/productCalculations";
import {calculateTotalProductDeposits, calculateNonVehicleSalesTaxTotal, calculateTotalTradeCredit, calculateTotalDownpayment, calculateVehicleSalesTax} from "../../../../../../../utlis/orderCalculations";
import { enableTireTax } from "../../../../../../../utlis/fastLogicUtils";

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const TECSalesOrderGlobalAutofill  = (document, documentValues, order, property=null, value=null, location, locations=null, salesReps) => {
    function getRepByEmployeeId(employeeId){
      if(salesReps && salesReps.length > 0){
        let foundRep = salesReps.find(rep => rep.employee_ID === employeeId);
        if(foundRep){
          return foundRep.preferredName ? foundRep.preferredName+" "+foundRep.lastName : foundRep.firstName+" "+foundRep.lastName;
        }
      }
      return "";
    }

    var newValues = documentValues;

    if(property ==='customer.name'){
      newValues.purchaser_name = value;
      if(order.customer.dba){
        newValues.purchaser_name += " dba "+order.customer.dba;
      }
  }
    if(property ==='customer.dba'){
        newValues.purchaser_name = order.customer.name+ " dba "+value;
  }
    if(property ==='customer.email'){
      newValues.purchaser_email = value;
  }
    if(property ==='customer.phone'){
      newValues.purchaser_phone = formatPhoneNumber(value);
  }
    if(property ==='customer.deliveryAddress.addressLine1'){
      newValues.purchaser_address = order.customer.deliveryAddress.addressLine1;
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        newValues.purchaser_address +=" "+order.customer.deliveryAddress.addressLine2;
      }
  }
    if(property ==='customer.deliveryAddress.addressLine2'){
      newValues.purchaser_address = (order.customer.deliveryAddress.addressLine1+" "+value);
  }
    if(property ==='customer.deliveryAddress.city'){
      newValues.purchaser_city_state_zip = (value+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
  }
    if(property ==='customer.deliveryAddress.state'){
      newValues.purchaser_city_state_zip = (order.customer.deliveryAddress.city+", "+value+" "+order.customer.deliveryAddress.zip);
  }
    if(property ==='customer.deliveryAddress.zip'){
      newValues.purchaser_city_state_zip = (order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+value);
  }
    if(property ==='salesRepId'){
      let salesrep = getRepByEmployeeId(value);
      newValues.salesperson = salesrep;
  }
    if(property ==='documentationDate'){
      newValues.documentation_date = moment.utc(value).format("YYYY-MM-DD");
      newValues.purchaser_signature_date = moment.utc(value).format("YYYY-MM-DD");
  }
    if(property ==='specialInstructions'){
      newValues.special_instructions = value;
  }
  //   if(property ==='products'){
  //     let cashTotal = calculateTotalProductDeposits(value) + calculateTotalDownpayment(order.downPayments);
  //     newValues.cash_down_payment = "("+formatter.format(cashTotal)+")";
  // }
    if(property?.includes('downpayment')){
      let cashTotal = calculateTotalProductDeposits(order.products) + calculateTotalDownpayment(order.downPayments);
      newValues.cash_down_payment = "("+formatter.format(cashTotal)+")";
      let downpaymentTotal = order.downPayments.reduce(function(prev, current) {
        return prev + +current.amount
    }, 0);
    if(order && downpaymentTotal && downpaymentTotal > 0){
      newValues.down_payment = order.downpaymentTotal;
    }
  }
    if(property ==='adminFee'){
      if(value){
        newValues.admin_fee = formatter.format(value);
      }else{
        newValues.admin_fee = formatter.format(0);
      }
  }
    if(property === 'location'){
        newValues.location_address = value.address+" "+value.city+" "+value.state+" "+value.zip;
        newValues.location_phone = value.phone;
        newValues.location_email = value.email;
        if(value.state === "CA"){
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
        }else{
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
        }
  }

    if(property !== null){
        // newValues = calculateTotalsFunction(document, null, null, order, newValues, location)
        return newValues;
    }

    if(order && order.customer && order.customer.name){
      newValues.purchaser_name = order.customer.name;

      if(order.customer.dba){
        newValues.purchaser_name += " dba "+order.customer.dba;
      }
    }

    if(order && order.customer && order.customer.phone){
      newValues.purchaser_phone = formatPhoneNumber(order.customer.phone);
    }

    if(order && order.customer && order.customer.email){
      newValues.purchaser_email = order.customer.email;
    }

    if(order && order.customer && order.customer.deliveryAddress){
      var addressLine = '';
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
      }else{
        addressLine = order.customer.deliveryAddress.addressLine1;
      }
      newValues.purchaser_address = addressLine;
    }

    if(order && order.customer && order.customer.deliveryAddress){
      newValues.purchaser_city_state_zip = (order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
    }

    if(order && order.salesRepId){
      let salesrep = getRepByEmployeeId(order.salesRepId);
      newValues.salesperson = salesrep;
    }

    if(order && order.documentationDate && documentValues.documentation_date === undefined){
      newValues.documentation_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
      newValues.purchaser_signature_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }

    if(order && order.specialInstructions){
      newValues.special_instructions = order.specialInstructions;
    }


    if(order && order.downPayments){
      var cashTotal = calculateTotalProductDeposits(order.products) + calculateTotalDownpayment(order.downPayments);
      newValues.cash_down_payment = "("+formatter.format(cashTotal)+")";
    }

    let downpaymentTotal = 0;
    if(order.downPayments){
      downpaymentTotal = order.downPayments.reduce(function(prev, current) {
          return prev + +current.amount
      }, 0);
    }

    if(order && downpaymentTotal && downpaymentTotal > 0){
      newValues.down_payment = order.downpaymentTotal;
    }

    if(order && order.adminFee){
      newValues.admin_fee = formatter.format(order.adminFee);
    }else{
      newValues.admin_fee = formatter.format(0);
    }
    
    if(location !== undefined){
      newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
    }
  
    if(location && location.phone){
      newValues.location_phone = location.phone;
    }
    
    if(location && location.email){
      newValues.location_email = location.email;
    }
  
    if(location){
      if(location.state === "CA"){
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
      }else{
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
      }
    }

    return newValues
}

 export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
      if (typeof payload[key] === 'string' || payload[key] instanceof String){
        payload[key] = formatMoneyValue(payload[key]);
      }
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });
  
  // Data point formatting
  if(payload.documentation_date){
    payload.documentation_date = moment.utc(payload.documentation_date).format("MM/DD/YY");
  }

  if(payload.purchaser_signature_date){
    payload.purchaser_signature_date = moment.utc(payload.purchaser_signature_date).format("MM/DD/YY");
  }
  // if(payload.down_payment){
  //   if(payload.down_payment < 0){
  //     payload.down_payment = 
  //     setDocumentValues({...documentValues,["down_payment"]:"("+formatMoneyValue(Math.abs(payload.down_payment))+")"});
  //   }else{
  //     setDocumentValues({...documentValues,["down_payment"]:formatMoneyValue(payload.down_payment)});
  //   }
  // }

  // if(payload.net_trade_allowance){
  //   if(payload.net_trade_allowance < 0){
  //     setDocumentValues({...documentValues,["net_trade_allowance"]:"("+formatMoneyValue(Math.abs(payload.net_trade_allowance))+")"});
  //   }else{
  //     setDocumentValues({...documentValues,["net_trade_allowance"]:formatMoneyValue(payload.net_trade_allowance)});
  //   }
  // }

  return payload;
}

function getProducts(selected_products, order){
  let products = [];
  if(selected_products && selected_products.length > 0){
    selected_products.forEach((item, i) => {
      let selectedProduct = item;
      let product = (order.products.filter(item => {
        return item.id === selectedProduct.id
      }));
      if(product && product.length === 1) {
        product = product[0];
      };
      
      if(product){
        products.push(product);
      }
    });
  }
  return products;
}

function getTradeins(selected_tradins, order){
  let tradeins = [];
  if(selected_tradins && selected_tradins.length > 0){
    selected_tradins.forEach((item, i) => {
      let selectedTradein = item;
      let tradein = order.tradeins.filter(item => {
        return item.id === selectedTradein.id
      });
      if(tradein && tradein.length === 1) {
        tradein = tradein[0];
      };
      
      if(tradein){
        tradeins.push(tradein);
      }
    });
  }
  return tradeins;
}

function calculateBalanceAllowanceTotal(tradeinsSelected){
  var balanceOwedTotal = 0;
  if(tradeinsSelected && tradeinsSelected.length > 0){
    tradeinsSelected.forEach((tradein, i) => {
      balanceOwedTotal += Number(tradein.balanceOwed);
    });
  }
  return balanceOwedTotal;
}

function calculateTradeAllowanceTotal(tradeinsSelected){
  var tradeAllowanceTotal = 0;
  if(tradeinsSelected && tradeinsSelected.length > 0){
    tradeinsSelected.forEach((tradein, i) => {
      tradeAllowanceTotal += Number(tradein.tradeAllowanceAtTerms);
    });
  }
  return tradeAllowanceTotal;
}

function calculateCashRefundTotal(tradeinsSelected){
  var cashRefundTotal = 0;
  if(tradeinsSelected && tradeinsSelected.length > 0){
    tradeinsSelected.forEach((tradein, i) => {
      cashRefundTotal += Number(tradein.cashRefundToCustomer);
    });
  }
  return cashRefundTotal;
}

function calculateProductBasePriceSum(productsSelected){
  var salePriceTotal = 0;
  if(productsSelected && productsSelected.length > 0){
    productsSelected.forEach((product, i) => {
      salePriceTotal += Number(product.price);
    });  }
  return salePriceTotal;
}

function calculateBankFeeSum(productsSelected){
  var bankFeeSum = 0;
  if(productsSelected && productsSelected.length > 0){
    productsSelected.forEach((product, i) => {
      if(product && product.lineItemFee && product.lineItemFee.bankFee > 0){
        bankFeeSum += Number(product.lineItemFee.bankFee);
      }
    });  
  }
  return bankFeeSum;
}

function calculateFetTotal(productsSelected){
  var fetTotal = 0;
  if(productsSelected && productsSelected.length > 0){
    productsSelected.forEach((product, i) => {
      if(product && product.lineItemFee && product.lineItemFee.applyFet === true){
          fetTotal += (product && product.lineItemFee && product.lineItemFee.applyFet && product.type && product.type.toLowerCase() === "new" ? (product.lineItemFee.fetTotal ?? 0) : 0);
      }
    });
  }
  return fetTotal;
}

function calculateMiscTotal(productsSelected){
  var miscTotal = 0;
    if(productsSelected && productsSelected.length > 0){
      productsSelected.forEach((product, i) => {
        if(product && product.lineItemFee && product.lineItemFee.additionalTax > 0){
          miscTotal += Number(product.lineItemFee.additionalTax ?? 0);
        }

        if(product && product.gapInsurance && product.gapInsurance.amount > 0){
          miscTotal += Number(product.gapInsurance.amount);
        }

        if(product && product.upgrades && product.upgrades.length > 0){
          product.upgrades.forEach((upgrade, i) => {
            if(upgrade && upgrade.price){
              miscTotal += Number(upgrade.price);
            }
          });
        }
      });
    }
  return miscTotal;
}

function calculateDeliveryTotal(productsSelected){
  var deliveryTotal = 0;
    if(productsSelected && productsSelected.length > 0){
      productsSelected.forEach((product, i) => {
        if(product && product.lineItemFee && product.lineItemFee.oosDeliveryFee > 0){
          deliveryTotal += product.lineItemFee.oosDeliveryFee ?? 0;
        }
      });
    }
  return deliveryTotal;
}

function calculateTireTaxTotal(productsSelected, order){
  var tireTaxTotal = 0;
  // if(order.customer && order.customer.deliveryAddress && (order.customer.deliveryAddress.state === "CA" || order.customer.deliveryAddress.state === "AZ")){
  // if (
  //   locationState &&
  //   locationState &&
  //   (locationState === "CA" || locationState === "AZ")
  // ) {
    
  if (productsSelected && productsSelected.length > 0) {
    productsSelected.forEach((product, i) => {
      if (
        product &&
        product.lineItemFee &&
        product.lineItemFee.tireTaxTotal > 0 &&
        (product?.lineItemFee?.enableTireTax === true || enableTireTax(order, product))
      ) {
        tireTaxTotal += product.lineItemFee.tireTaxTotal ?? 0;
      }
    });
  }
  
  return tireTaxTotal;
}

function calculateCatTaxTotal(productsSelected, order){
  var catTaxTotal = 0;
    if(productsSelected && productsSelected.length > 0){
      productsSelected.forEach((product, i) => {
        if(product && product.registrationAddress?.state && product.registrationAddress?.state === "OR" && product.lineItemFee && product.lineItemFee.catTaxTotal > 0){
            catTaxTotal += product.lineItemFee.catTaxTotal ?? 0;
        }
      });
    }
  // }
  return catTaxTotal;
}

function calculateLuxuryTaxTotal(productsSelected, order){
  var luxuryTaxTotal = 0;
    if(productsSelected && productsSelected.length > 0){
      productsSelected.forEach((product, i) => {
        if(product && product.registrationAddress?.state && product.registrationAddress?.state === "OR"){
          if(product && product.lineItemFee && product.lineItemFee.applyLuxuryTax && product.lineItemFee.luxuryTaxTotal > 0){
              luxuryTaxTotal += product.lineItemFee.luxuryTaxTotal ?? 0;
          }
        }
      });
    }
  // }
  return luxuryTaxTotal;
}

function calculateLicenseTitleTotal(productsSelected){
  var licenseTitleTotal = 0;
    if(productsSelected && productsSelected.length > 0){
      productsSelected.forEach((product, i) => {
        if(product && product.lineItemFee && (product.lineItemFee.applyTitleFee || product.lineItemFee.applyRegistrationFee) && product.lineItemFee.titleFee){
            licenseTitleTotal += Number(product.lineItemFee.titleFee) ?? 0;
        }
      });
    }
  return licenseTitleTotal;
}

function calculateWarrantyTotal(productsSelected){
  var warrantyTotal = 0;
    if(productsSelected && productsSelected.length > 0){
      productsSelected.forEach((product, i) => {
        warrantyTotal = Number(warrantyTotal) + Number(calculateProductWarrantyTotal(product));
      });
    }
  return warrantyTotal;
}

function calculateEltTotal(productsSelected, order){
  var eltTotal = 0;
  if(productsSelected && productsSelected.length > 0){
    productsSelected.forEach((product, i) => {
      if(product && product.lineItemFee){
        eltTotal += Number((product.lineItemFee.etlFee ?? 0) + (product.lineItemFee.docFee ?? 0));
      }
    });
  }
  return eltTotal;
}

function calculateCashDeliveredPrice(productsSelected, tradeCredit, adminFee, order){
  var cashDeliveryPrice = 0;
  if(productsSelected && productsSelected.length > 0){
    productsSelected.forEach((product, i) => {
      cashDeliveryPrice += Number(calculateProductSubtotal(order, product));
    });
  }
  cashDeliveryPrice += Number(adminFee);
  return cashDeliveryPrice;
}

export const calculateTotalsFunction = (document, productArray=null, tradeinArray=null, order, documentValues, location) => {

  // if(productArray === null && document){
  //   if(document && document.itemIds){
  //     let foundProductOptions = [];
  //     var itemIds = document.itemIds.split(',');
  //     if(itemIds.length > 0){
  //       itemIds.forEach((product_id, i) => {
  //         var foundProduct = order.products.find((product) => product.id === Number(product_id));
  //         if(foundProduct){
  //           foundProductOptions.push(foundProduct);
  //         }
  //       });
  //       productArray = foundProductOptions;
  //     }
  //   }
  // }

  // if(tradeinArray === null && document){
  //   if(document && document.tradeinIds){
  //     let foundTradeinOptions = [];
  //     var tradeinIds = document.tradeinIds.split(',');
  //     if(tradeinIds.length > 0){
  //       tradeinIds.forEach((tradein_id, i) => {
  //         var foundTradein = order.tradeins.find((tradein) => tradein.id === Number(tradein_id));
  //         if(foundTradein){
  //           foundTradeinOptions.push(foundTradein);
  //         }
  //       });
  //       tradeinArray = foundTradeinOptions;
  //     }
  //   }
  // }

  let productsSelected = getProducts(productArray, order);
  let tradeinsSelected = getTradeins(tradeinArray, order);
  // setDocumentValues(initialDocumentStructure);
  var newValues = documentValues;
  // First Product Details
  if(productsSelected && productsSelected.length > 0){
    newValues.quantity = 1;
    if(productsSelected[0] && productsSelected[0].year){
      newValues.year = (productsSelected[0].year);
    }
    newValues.make = (productsSelected[0].make).trim();
    newValues.model = (productsSelected[0].model).trim().toString().toUpperCase();
    newValues.stock_number = (productsSelected[0].stockNumber).toString().toUpperCase();
    newValues.mileage = (productsSelected[0].mileage);
    newValues.serial_number = (productsSelected[0].serialNumber);
    newValues.vin = (productsSelected[0].vin).toString().toUpperCase();
    if(productsSelected[0].type && productsSelected[0].vehicleType){
      newValues.vehicle_type = (productsSelected[0].type.toString().toUpperCase())+" "+(productsSelected[0].vehicleType.toString().toUpperCase());
    }
    if(productsSelected[0].fundingDetail){
      if(productsSelected[0].fundingDetail.fundingMethod === 'tec-financing' || productsSelected[0].fundingDetail.fundingMethod === 'finance-only' || productsSelected[0].fundingDetail.fundingMethod === "direct-deal"){
        if(productsSelected[0].fundingDetail.finalizedLendingOption){
          newValues.lienholder = productsSelected[0].fundingDetail.finalizedLendingOption.bankName;
        }
      }
      if(productsSelected[0].fundingDetail.fundingMethod === 'cash-with-lien'){
        if(productsSelected[0].fundingDetail.cashLienHolder){  
          newValues.lienholder = productsSelected[0].fundingDetail.cashLienHolder.bankName;
        }
      }
    }

  }
  newValues.special_instructions = (order.specialInstructions && order.specialInstructions !== undefined ? order.specialInstructions : "");

  if(productsSelected && productsSelected.length > 0){
    productsSelected.forEach((product, i) => {
      if(product.extendedWarranty && product.extendedWarranty.description){
        newValues.special_instructions += "\n\n"+product.extendedWarranty.description
      }
    });
  }

  if(tradeinsSelected && tradeinsSelected.length > 0){
    // First Tradein Details
    newValues.trade_year = (tradeinsSelected[0].year).trim().replace(/['"]+/g, '');
    newValues.trade_make = (tradeinsSelected[0].make).trim();
    newValues.trade_model = (tradeinsSelected[0].model).trim().toString().toUpperCase();
    newValues.trade_vin = (tradeinsSelected[0].vin).toString().toUpperCase();
    newValues.trade_body_type = tradeinsSelected[0].seriesBodyType;
    newValues.balance_owner = tradeinsSelected[0].balanceOwedTo;
    if(tradeinsSelected[0].owedToAddress && tradeinsSelected[0].owedToCity && tradeinsSelected[0].owedToState && tradeinsSelected[0].owedToZip){
      newValues.balance_owner_address = tradeinsSelected[0].owedToAddress+" "+(tradeinsSelected[0].owedToAddres2 ? (tradeinsSelected[0].owedToAddres2 ?? "")+" " : "")+tradeinsSelected[0].owedToCity+", "+tradeinsSelected[0].owedToState+" "+tradeinsSelected[0].owedToZip;
    }
    if(tradeinsSelected[0].goodUntil && moment.utc(tradeinsSelected[0].goodUntil)){
      newValues.good_until = moment.utc(tradeinsSelected[0].goodUntil).format("MM/DD/YY");
    }  
  }

  // Trade In Calcualtions

      // Trade Allowance
      let tradeAllowanceTotal = calculateTradeAllowanceTotal(tradeinsSelected);
      if(tradeAllowanceTotal < 0){
        newValues.trade_allowance = formatter.format(Math.abs(tradeAllowanceTotal));
      }else{
        newValues.trade_allowance = "("+formatter.format(tradeAllowanceTotal)+")";
      }

      // Balance Owed
      let balanceOwedTotal = calculateBalanceAllowanceTotal(tradeinsSelected);
      newValues.amount = formatter.format(balanceOwedTotal);
      newValues.balance_owed = formatter.format(balanceOwedTotal);

      // Cash Refund
      let cashRefundTotal = calculateCashRefundTotal(tradeinsSelected);
      newValues.cash_refund = formatter.format(cashRefundTotal);

      // NET Trade Allowance
      let tradeCredit = calculateTotalTradeCredit(tradeinsSelected);
      if(tradeCredit < 0){
        newValues.net_trade_allowance = formatter.format(Math.abs(tradeCredit));
      }else{
        newValues.net_trade_allowance = "("+formatter.format(tradeCredit)+")";
      }

  // Product Calculations

    // Unit Price Total
    // let salePriceTotal = calculateSalePriceTotal(productsSelected);
    let salePriceTotal = calculateProductBasePriceSum(productsSelected);
    newValues.sale_price = formatter.format(salePriceTotal);

    // Sales Tax
    let salesTaxTotal = calculateVehicleSalesTax(productsSelected, tradeinsSelected, order);
    if(order && order.customer && order.customer.deliveryAddress && order.customer.deliveryAddress.state === "WA"){
        let nonVehicleSalesTaxCalculated =  Number(calculateNonVehicleSalesTaxTotal(productsSelected, order));
        salesTaxTotal += Number(nonVehicleSalesTaxCalculated);
    }
    newValues.sales_tax = formatter.format(salesTaxTotal);

    // Fet 
    let fetTotal = calculateFetTotal(productsSelected);
    newValues.fet = formatter.format(fetTotal);

    
    // ELT Total
    let eltTotal = calculateEltTotal(productsSelected, order);
    if(eltTotal && eltTotal > 0){
      newValues.elt_doc_fee = formatter.format(eltTotal);
    }

    // Bank Fee
    let bankFeeTotal = calculateBankFeeSum(productsSelected);
    if(bankFeeTotal && bankFeeTotal > 0){
      if(order?.adminFee > 0){
        newValues.admin_fee = formatter.format((Number(order.adminFee)+Number(bankFeeTotal)));
      }else{
        newValues.admin_fee = formatter.format(Number(bankFeeTotal));
      }
    }

  // Misc
  let miscTotal = calculateMiscTotal(productsSelected);
  newValues.misc_tax = formatter.format(miscTotal);

  // Delivery
  let deliveryTotal = calculateDeliveryTotal(productsSelected);
  newValues.delivery = formatter.format(deliveryTotal);

  // if (
  //   location &&
  //   location.state &&
  //   (location.state === "CA" || location.state === "AZ")
  // ) {
  // if(productsSelected.enableTireTax === true){
    // Tire Tax (CALIFORNIA, ARIZONA)
  let tireTaxTotal = calculateTireTaxTotal(productsSelected, order);
  if (tireTaxTotal && tireTaxTotal > 0) {
    newValues.tire_tax = formatter.format(tireTaxTotal);
  }
  // }

    // Cat Tax (OREGON ONLY)
    let catTaxTotal = calculateCatTaxTotal(productsSelected, order);
    if(catTaxTotal && catTaxTotal > 0){
      newValues.cat_tax = formatter.format(catTaxTotal);
    }

    // Luxury Tax (OREGON ONLY)
    let luxuryTaxTotal = calculateLuxuryTaxTotal(productsSelected, order);
    if(luxuryTaxTotal && luxuryTaxTotal > 0){
      newValues.luxury_tax = formatter.format(luxuryTaxTotal);
    }
    
    // Warranty
    let warrantyTotal = calculateWarrantyTotal(productsSelected);
    if(warrantyTotal && warrantyTotal > 0){
      newValues.extended_warranty = formatter.format(warrantyTotal);
    }

    // License / Title Fee's
    let licenseTitleTotal = calculateLicenseTitleTotal(productsSelected);
    newValues.license_fee = formatter.format(licenseTitleTotal);

    // Cash Delivery
    let cashDeliveryPrice = calculateCashDeliveredPrice(productsSelected, tradeCredit, removeChars(newValues.admin_fee), order);
    if(salesTaxTotal){
      cashDeliveryPrice += Number(salesTaxTotal);
    }
    newValues.cash_delivery_price = formatter.format(cashDeliveryPrice);

    // Down Payment
    let downPaymentTotal = calculateTotalDownpayment(order.downPayments);
    let productDepositTotal = calculateTotalProductDeposits(productsSelected);
    let adjustedTotalDownPayment = Number(downPaymentTotal) + Number(productDepositTotal) + Number(tradeCredit);
    if(adjustedTotalDownPayment < 0){
      newValues.down_payment = formatter.format(Math.abs(adjustedTotalDownPayment)); // Calculate
    }else{
      newValues.down_payment = "("+formatter.format(adjustedTotalDownPayment)+")"; // Calculate
    }

    // Balance Due
    newValues.unpaid_balance_due = formatter.format(0.00);
    if(adjustedTotalDownPayment){
      newValues.unpaid_balance_due = formatter.format(cashDeliveryPrice - adjustedTotalDownPayment);
    }else{
      newValues.unpaid_balance_due = formatter.format(cashDeliveryPrice);
    }

    // Order Delivery Date
    if(order && order.documentationDate && documentValues.documentation_date === undefined){
      newValues.documentation_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }

    // Set new values
    // setDocumentValues(newValues);
    return newValues;
}
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

// MSAL components:
import { useMsal } from "@azure/msal-react";
import { callMsGraph } from "../../graphUser";
import { loginRequest } from "../../authConfig";

// MaterialUI components:
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

// Custom sub-components:
import Sidebar from "./components/Sidebar";
import NotificationsMenu from "./components/NotificationBell";
import HelpMenu from "./components/HelpIcon";
import { useFetch } from "../../utlis/api";
import { capitalizeFirstLetter } from "../../utlis/formatters";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import {MenuItem} from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import { setRole } from "../../redux/role/roleAction";
import { getMyDefaultRegionCode, getMyDefaultLocationCode } from "../../utlis/api";
import { updateDefaultRegionCode, updateDefaultLocationCode } from "../../redux/me";

const Navbar = ({title, setTrainingOverride}) => {
  const axios = useFetch()
  const dispatch  = useDispatch()

  const activeDirectoryRole = useSelector((state) => state?.role.activeDirectoryRole);
  const role = useSelector((state) => state?.role.role);
  const me = useSelector((state) => state?.me);

  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);

  const [myRole, setMyRole] = useState('finance-admin');


  useEffect(() => {
    if(graphData == null){
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
      }).then((response) => {
        callMsGraph(response.accessToken).then(response => setGraphData(response));
      });
    }

    if(activeDirectoryRole){
      setMyRole(activeDirectoryRole);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ activeDirectoryRole, myRole ]);

  // const [updatingMe, setUpdatingMe] = useState(false);
  const [updatingMyDefaultRegionCode, setUpdatingMyDefaultRegionCode] = useState(false);
  const [updatingMyDefaultLocationCode, setUpdatingMyDefaultLocationCode] = useState(false);


  // const findMe = useCallback(async () => {
  //   let newMe = await getMe();
  //   if(newMe?.data){
  //     dispatch(updateMe(newMe.data));
  //   }
  // },[dispatch])

  const findUserDefaultRegionCode = useCallback(async () => {
    let newDefaultRegionCode = await getMyDefaultRegionCode();
    if(newDefaultRegionCode?.data){
      dispatch(updateDefaultRegionCode(newDefaultRegionCode.data));
    }
  },[dispatch])

  const findUserDefaultLocationCode = useCallback(async () => {
    let newDefaultLocationCode = await getMyDefaultLocationCode();
    if(newDefaultLocationCode?.data){
      dispatch(updateDefaultLocationCode(newDefaultLocationCode.data));
    }
  },[dispatch])

  useEffect(() => {
    // if(me?.id === null && !updatingMe){
    //   setUpdatingMe(true);
    //   findMe();
    // }else 
    if(me && !me?.userdefaultTecRegionCode && !updatingMyDefaultRegionCode){
      setUpdatingMyDefaultRegionCode(true);
      findUserDefaultRegionCode();
    }else if(me && !me?.defaultTecLocationCode && !updatingMyDefaultLocationCode){
      setUpdatingMyDefaultLocationCode(true);
      findUserDefaultLocationCode();
    }
  }, [ axios, me, dispatch, updatingMyDefaultRegionCode, updatingMyDefaultLocationCode, findUserDefaultRegionCode, findUserDefaultLocationCode ]);

  return (
    <Box
      id="navbar-wrapper"
      sx={{
        flexGrow: 1,
        position: "-webkit-sticky",
        top: 0,
        bottom: 0,
        zIndex: 5,
        mb: 2,
      }}
    >
      <AppBar
        id="navbar-appbar"
        sx={{
          backgroundColor: "#F7F7F7",
          color: "black",
          px: 1,
        }}
        position="static" 
      >
        <Toolbar id="navbar-toolbar">
          <Sidebar
            id="navbar-sidebar"
          />
          <Typography
            id="navbar-title"
            variant="h6"
            sx={{
              flexGrow: 1,
              fontWeight: "bold",
              mx: 2,
            }}
          >
           {title}
          </Typography>
          { activeDirectoryRole === 'master' ? (
          <div style={{ width: '300px'}} className='px-3'>
            <FormControl className="m-3" fullWidth>
              <InputLabel id="role-testing">Role Testing</InputLabel>
              <Select
                labelId="role-testing"
                id="role-testing"
                value={role}
                label="Select a role for Testing"
                disabled={activeDirectoryRole !== 'master'}
                // style={{display:'flex', flexDirection:'column'}}
                onChange={e =>{
                  // setSelectedRole(e.target.value);
                  if(activeDirectoryRole && activeDirectoryRole === 'master'){
                    if(setTrainingOverride){
                      setTrainingOverride(false);
                    }
                    dispatch(setRole(e.target.value));
                  }
                }}
              >
                {/* <MenuItem value={'default'}>Default (Assigned Role)</MenuItem> */}
                <MenuItem value={'master'}>Master</MenuItem>
                <MenuItem value={'finance-manager'}>Finance Manager</MenuItem>
                <MenuItem value={'finance-admin'}>Finance Admin</MenuItem>
              </Select>
            </FormControl>
          </div>
          ):null}
          <Box
            id="navbar-menu-cluster"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              id="navbar-notifications-menu"
              sx={{ mx: 1 }}
            >
              <NotificationsMenu userIndex={ (me && me.id ? me.id : 2) }/>
            </Box>
            <Box
              id="navbar-help-menu"
              sx={{ mx: 1 }}
            >
              <HelpMenu />
            </Box>
            <Box 
              id="navbar-user-info-area"
              sx={{ display: "flex", alignContent: "center" }}
            >
              {/* TODO: Future Development: possibly make this Avatar/Name Cluster 
              into a popover/menu that would link to logout and other functions */}
              <Box
                id="navbar-user-info-area-avatar"
                sx={{ mx: 1, my: "auto" }}
              >
                {graphData ? <Avatar alt={graphData.displayName} /> : <Avatar />}
              </Box>
              <Box
                id="navbar-user-info-area-card"
                sx={{ mx: 1, my: "auto" }}
              >
                <Typography
                  id="navbar-user-info-area-user-name"
                  variant="subtitle1"
                >
                  {graphData ? graphData.displayName : "Loading..."}
                </Typography>
                <Typography
                  id="navbar-user-info-area-job-title"
                  variant="subtitle2">
                    {capitalizeFirstLetter(role)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  )
};

export default Navbar;

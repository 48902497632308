import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  useParams
} from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import { useOrder, rdx } from "../../../../../../../redux/orders/order"
import { removeChars, formatter, formatUpperCase } from "../../../../../../../utlis/formatters";
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { TECDepositReceiptGlobalAutofill, TECDepositReceiptAutofillProductDetails, TECDepositReceiptAutofillDeposit, preProcessPayload } from "../DocumentAutofill/TECDepositReceipt"

const TECDepositReceipt = ({document_id, location, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "tec-deposit-receipt";

  // App Logic & Data //
  let { id } = useParams();
  const order = useOrder();
  const salesReps = useSalesReps();
  const axios = useFetch()
  
  var initialDocumentStructure = {
    location_address: undefined, // Header Location Details / System Autofill
    location_phone: undefined, // Header Location Details / System Autofill
    location_email: undefined, // Header Location Details / System Autofill
    tec_footer_label: undefined, // Footer / System Autofill
    pg_no: undefined, // Footer / System Autofill
    
    purchaser_address: undefined,
    purchaser_city_state_zip: undefined,
    sales_person: undefined,
    purchaser_email: undefined,
    purchaser_phone: undefined,
    purchaser_name: undefined,
    
    vehicle_type: undefined,
    vehicle_model: undefined,
    vehicle_make: undefined,
    vehicle_year: undefined,
    vehicle_quantity: undefined,
    estimated_delivery_date: undefined,
    serial_number: undefined,
    stock_number: undefined,
    payment_type: undefined,
    total: undefined,
    document_number: undefined,
  }
  
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [documentUrl, setdocumentUrl] = useState("");
  const [document, setDocument] = useState(undefined);
  const [previousDocumentId, setPreviousDocumentId] = useState(0);
  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  

  
   // X1
   const removeFormatting = e =>{
    const {name,value}=e.target;
    setDocumentValues({...documentValues,[name]:removeChars(value)});
  }

  // X1 Reference Arrays - TODO: Possibly move these to back end as part of the template stucture
  const moneyFields = useMemo(()=>{return["total"]},[]);
  const phoneFields = useMemo(()=>{return["purchaser_phone"]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "vehicle_model", "vehicle_make", "document_number", "payment_type"]},[]);
  const checkboxes = useMemo(()=>{return[
  ]},[]);
  // const radios = [
  // ];

  const handleDocumentValuesChange = e =>{
    const {name,value}=e.target;

    // const savedPos = e.target.selectionStart;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }

    if(name === 'vehicle_year') {
        let newStr = value.replace(/[^0-9]/g,'');
        newStr = newStr.length > 4 ? documentValues[name].replace(/[^0-9]/g,'') : newStr;
        setDocumentValues({...documentValues,[name]:newStr.replace('"','')});
    } else if(name === 'vehicle_quantity' || name === 'serial_number' || name === 'stock_number') {
        let newStr = value.replace(/[^0-9]/g,'');
        setDocumentValues({...documentValues,[name]:newStr});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  
  // const [documentLoaded, setDocumentLoaded] = useState(false);
    
const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('TECDepositReceipt.jsx', ' Error loading document: ' + err)
      }
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      // setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, loadDocument ])
  const [assignedProduct, setAssignedProduct] = useState(undefined);
  // const [productVal, setproductVal] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  // const [tradeinVal, setTradeinVal] = useState(undefined);
  // const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  // const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);

// Dropdowns //

  // if(order && order.products && order.products.length > 0 && productOptions === undefined){
  //   let getProductOptions = [];
  //   order.products.map(product => {
  //     // let str = `${product.year.replace('"', '').replace('"', '')} ${product.make} ${product.model.toString().toUpperCase()} - ${product.stockNumber.toString().toUpperCase()}`;
  //     let str = `${product.stockNumber.toString().toUpperCase()}`;
  //     let obj = {id: product.id, name: str}
  //     getProductOptions.push(obj);
  //   })
  //   setproductOptions(getProductOptions);
  // }

  // if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
  //   let getTradeinOptions = [];
  //   order.tradeins.map(tradein => {
  //     let str = `${tradein.year} ${tradein.make} ${tradein.model}`;
  //     let obj = {id: tradein.id, name: str}
  //     getTradeinOptions.push(obj);
  //   })
  //   setTradeinOptions(getTradeinOptions);
  // }

  // Autofill //
  const GlobalAutofill = useCallback(async () => {
    let newValues = TECDepositReceiptGlobalAutofill(documentValues, order, null, null, location, null, salesReps);
    if(newValues){
      setDocumentValues(newValues);
      setHasUnsavedChanges(true);
      setSaveSuccess(false);
    }
  
    // function getRepByEmployeeId(employeeId){
    //   if(salesReps && salesReps.length > 0){
    //     let foundRep = salesReps.find(rep => rep.employee_ID === employeeId);
    //     if(foundRep){
    //       return foundRep.preferredName ? foundRep.preferredName+" "+foundRep.lastName : foundRep.firstName+" "+foundRep.lastName;
    //     }
    //   }
    //   return "";
    // }

    // var newValues = documentValues;
    // if(order && order.customer && order.customer.name){
    //   newValues.purchaser_name = order.customer.name;
    //   if(order.customer.dba){
    //     newValues.purchaser_name += " dba "+order.customer.dba;
    //   }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
    //     let cosigner = order.customer.cosigners[0];
    //     if(cosigner && cosigner.name){
    //       newValues.purchaser_name += " AND "+cosigner.name;
    //     }
    //   }
    // }

    // if(order && order.customer && order.customer.phone){
    //   newValues.purchaser_phone = order.customer.phone;
    // }

    // if(order && order.customer && order.customer.email){
    //   newValues.purchaser_email = order.customer.email;
    // }


    // if(order && order.customer && order.customer.deliveryAddress){
    //   var newAddr = '';
    //   if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== ''){
    //     newAddr = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
    //   }else{
    //     newAddr = order.customer.deliveryAddress.addressLine1;
    //   }
    //   newValues.purchaser_address = newAddr;
    // }

    // if(order && order.customer && order.customer.deliveryAddress){
    //   newValues.purchaser_city_state_zip = (order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
    // }

    // if(order && order.salesRepId){
    //   newValues.sales_person = getRepByEmployeeId(order.salesRepId);
    // }


    // if(order && order.documentationDate ){
    //   newValues.estimated_delivery_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    // }
    
    // Get Location Address & Autofill Address
    // if(location !== undefined){
    //   newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
    // }
  
    // if(location && location.phone){
    //   newValues.location_phone = location.phone;
    // }
    
    // if(location && location.email){
    //   newValues.location_email = location.email;
    // }
  
    // if(location){
    //   if(location.state === "CA"){
    //     newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
    //   }else{
    //     newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
    //   }
    // }

    // newValues.pg_no = "Pg #1";
    // setDocumentValues(newValues);
    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  },[documentValues, order, location, salesReps])


  // const preProcessPayload = useCallback((payload) => {

    
  //   // if(payload.q1_toggle){
  //     //   // Reset all
  //     //   payload.q_1_true_cb = "no";
  //     //   payload.q_1_false_cb = "no";
      
  //     //   switch(payload.q1_toggle){
  //       //     case "true":
  //       //       payload.q_1_true_cb = "yes";
  //       //       break;
  //       //     case "false":
  //       //       payload.q_1_false_cb = "yes";
  //       //       break;
  //       //     default:
  //       //   }
  //       // }
      
  //   // X1.2- Field Type Specific Preview Formatting
  //   Object.keys(payload).forEach(key => {
  //     if(moneyFields.indexOf(key) >= 0){
  //         payload[key] = formatMoneyValue(payload[key]);
  //     }else if(phoneFields.indexOf(key) >= 0){
  //         payload[key] = formatPhoneNumber(payload[key]);
  //     }else if(upperCaseFields.indexOf(key) >= 0){
  //         payload[key] = formatUpperCase(payload[key]);
  //     }
  //   });
  //   setDocumentValues(JSON.parse(JSON.stringify(payload)));
  //   Object.keys(payload).forEach(key => {
  //     if(checkboxes.indexOf(key) >= 0){
  //       payload[key] = payload[key] ? "yes": "no";
  //     }
  //   });

  //   // Data point formatting
  //   if(payload.estimated_delivery_date){
  //     payload.estimated_delivery_date = moment.utc(payload.estimated_delivery_date).format("MM/DD/YY");
  //   }
      
  //   return payload;
  // },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])


  const previewPayloadDocument = useCallback(async (documentValues) => {
    var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });
    
    if(document){
      var docCopy = JSON.parse(JSON.stringify(document));
      docCopy.payload =  JSON.parse(JSON.stringify(payload));
      docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
      docCopy.payload =  JSON.stringify(docCopy.payload);
      delete docCopy.documentStatus;
      
      try {
        let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
        setdocumentUrl(fileURL)
      } catch (err) {
        recordFrontEndError('TECDepositReceipt.jsx', 'Error previewing document payload: ' + err)
      };  
    }
  },[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])

const [depositToggleAutoFill, setDepositToggleAutoFill] = useState(false);

//Autofill Product Details After Selection
const AutofillProductDetails = useCallback(async (selectedProduct) => {
  let newValues = TECDepositReceiptAutofillProductDetails(selectedProduct, documentValues);

  if(newValues){
    setDocumentValues(newValues);
  //   setHasUnsavedChanges(true);
  //   if(document){
  //     previewPayloadDocument(documentValues);
  //   }
  //   setSaveSuccess(false);
  }

  // var newValues = documentValues;
  // newValues.vehicle_year = (selectedProduct.year).trim().replace('"','').replace('"','');
  // newValues.vehicle_make = (selectedProduct.make).trim();
  // newValues.vehicle_model = (selectedProduct.model).trim().toUpperCase();
  // newValues.stock_number = (selectedProduct.stockNumber).toUpperCase();
  // // newValues.mileage = (selectedProduct.mileage);
  // newValues.serial_number = (selectedProduct.vin).toUpperCase();
  // newValues.vehicle_type = (selectedProduct.vehicleType);
  // newValues.vehicle_quantity = 1;

  // // if(selectedProduct.deposits && selectedProduct.deposits.length > 0){
  // //   let getDepositOptions = [];
  // //   selectedProduct.deposits.map(deposit => {
  // //     let str = `${formatter.format(deposit.amount)}`; // Or Applied Amount
  // //     let obj = {id: deposit.id, name: str}
  // //     getDepositOptions.push(obj);
  // //     return null;
  // //   })
  // //   setDepositOptions(getDepositOptions);
  // // }
  
  // setDocumentValues(newValues);

  // // setProductToggleAutoFill(false);
  // if(document){
  //   previewPayloadDocument(documentValues);
  // }

  //Autofill Deposit Details After Selection
  if(selectedProduct && selectedProduct.deposit && selectedProduct.deposit.length > 0){
    let firstDeposit = selectedProduct.deposit[0];
    newValues = TECDepositReceiptAutofillDeposit(firstDeposit, documentValues);

    if(newValues){
      setDocumentValues(newValues);
      setHasUnsavedChanges(true);
      if(document){
        previewPayloadDocument(documentValues);
      }
      setSaveSuccess(false);
      setselectedDeposit(firstDeposit);
      depositToggleAutoFill(true);
    }
  }

  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ depositToggleAutoFill, document, documentValues, previewPayloadDocument ]);

const previewDocument = useCallback( async () => {

  try {
    let response = await axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
    const file = new Blob(
      [response.data], 
      {type: 'application/pdf'});
    const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
    setdocumentUrl(fileURL)
    } catch (err) {
      recordFrontEndError('TECDepositReceipt.jsx', 'Error previewing document: ' + err)
    }
  },[axios, document]); 

function generateDepositOptions(product){
  if(product.deposits && product.deposits.length > 0){
    let getDepositOptions = [];
    product.deposits.map(deposit => {
      let str = `${formatter.format(deposit.amount)}`; // Or Applied Amount
      let obj = {id: deposit.id, name: str}
      getDepositOptions.push(obj);
      return null;
    })
    setDepositOptions(getDepositOptions);
  }
}

useEffect(() => {
  if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){

      existingPayload = true;

      if(documentPayload.estimated_delivery_date){
        documentPayload.estimated_delivery_date = moment.utc(documentPayload.estimated_delivery_date).format("YYYY-MM-DD");
      }



      
      setDocumentValues(documentPayload);
    }

    var foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }

    if(foundAssignedProduct){
      setAssignedProduct(foundAssignedProduct);
      generateDepositOptions(foundAssignedProduct);
      // Loading Use Effect
      if(document.additionalData){
        // const methodMap = { 
        //   depositVal: setdepositVal, 
        // };

        let parsedAdditionalData = JSON.parse(document.additionalData);
        Object.keys(parsedAdditionalData).forEach(function(key) {
          if(key === "depositVal"){
            let value = parsedAdditionalData[key];
            setdepositVal(value);
            if(value && value.id){
              var foundProduct = order.products.find((product) => product.id === document.assignedObjectId)
              if(foundProduct && foundProduct.deposits && foundProduct.deposits.length > 0){
                var foundDeposit = foundProduct.deposits.find((deposit) => deposit.id === value.id);
                if(foundDeposit){
                  setselectedDeposit(foundDeposit);
                }
              }
            }
          }
        });
      }
      
    }

    // setAddressOptions(getOrderLevelAddresses(order));
    if(!existingPayload){
      GlobalAutofill();
      previewPayloadDocument(documentValues);
      if(foundAssignedProduct){
        AutofillProductDetails(foundAssignedProduct)
      }
    }else{
      // if(document && document.itemIds){
      //   var itemIds = document.itemIds.split(',');
      //     var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
      //     let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
      //     let obj = {id: foundProduct.id, name: str}
      //     setproductVal(obj);
      //     // if(foundProduct){
      //     //   setAddressOptions(updateAddressOptions(foundProduct, order));
      //     // }
      // }

      previewDocument();
    }
    
    setDataLoaded(true);
  }
},[ assignedProduct, documentValues, document, dataLoaded, AutofillProductDetails, GlobalAutofill, order, previewPayloadDocument, previewDocument ])

  
  // Document BREAD + Preview // TODO: Move to Redux


  
  // Document BREAD + Preview // TODO: Move to Redux
  

  



  // function fixFormatting(name, value){
  //   if(name === 'vehicle_year') {
  //     let newStr = value.replace(/[^0-9]/g,'');
  //     newStr = newStr.length > 4 ? documentValues[name].replace(/[^0-9]/g,'') : newStr;
  //     setDocumentValues({...documentValues,[name]:newStr.replace('"','')});
  //   } else if(name === 'total') {
  //       let newStr = value.replace(/[^0-9]/g,'');
  //       setDocumentValues({...documentValues,[name]:newStr});
  //   } else if(name === 'vehicle_quantity' || name === 'serial_number' || name === 'stock_number' || name === 'total' || name === 'pg_no') {
  //       let newStr = value.replace(/[^0-9]/g,'');
  //       setDocumentValues({...documentValues,[name]:newStr});
  //   } else if(name === 'vehicle_model' || name === 'vehicle_make' || name === 'document_number' || name === 'payment_type'){
  //     let newStr = value;
  //     setDocumentValues({...documentValues,[name]:newStr.toUpperCase()});    
  //   } else if(name === 'purchaser_phone'){
  //       let newStr = value;
  //       setDocumentValues({...documentValues,[name]:formatPhoneNumber(newStr)});
  //   }else{
  //     setDocumentValues({...documentValues,[name]:value});
  //   }
  // }

  // Component Events

  const updatePreview = e => {
    e.preventDefault();
    // const {name,value}=e.target;
    // fixFormatting(name,value);
    
    previewPayloadDocument(documentValues);
    // saveDocument().then(r => { 
    //   regenerateDocument().then(res => {
    //     streamDocument()
    //   })
    // })
  }


  const handleSubmit = async event => {
    event.preventDefault();


    let payload = JSON.parse(JSON.stringify(documentValues));

    payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
  

    // const payload = JSON.parse(JSON.stringify(documentValues));
    // var docCopy = document;
    // docCopy.payload = payload;

    // if(docCopy.payload.estimated_delivery_date){
    //   docCopy.payload.estimated_delivery_date = moment.utc(docCopy.payload.estimated_delivery_date).format("MM/DD/YY");
    // }
    // docCopy.payload =  JSON.stringify(docCopy.payload);
    // delete docCopy.documentStatus;
    
    // if(selectedProduct && selectedProduct.id){
    //   docCopy.itemIds = `${selectedProduct.id}`;
    // }

    //Save Dropdown Selections
    let dropdownData = {};
    if(depositVal){
      dropdownData.depositVal = {
        id: depositVal.id,
        name: depositVal.name
      };
    }

    let additionalData = JSON.stringify(dropdownData);

    DocumentHandleSubmit(document, payload, additionalData, () => {
      getDocuments();
      setSaveSuccess(true);
      setHasUnsavedChanges(false);
    });

    // docCopy.additionalData = JSON.stringify(dropdownData);
    // try {
    //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
    //   if (res) {
    //     await axios.post(`/document/${document.id}/generate`) 
    //     getDocuments();
    //     setSaveSuccess(true);
    //     setHasUnsavedChanges(false);
    //   };
    // } catch (err) {
    //   recordFrontEndError('TECDepositReceipt.jsx', 'Error saving document')
    // };
    
  };

// Component Helpers //
const [depositVal, setdepositVal] = useState(undefined);
const [selectedDeposit, setselectedDeposit] = useState(undefined);
const [inputDepositVal, setinputDepositVal] = useState(undefined);
const [depositOptions, setDepositOptions] = useState(undefined);





//Autofill Deposit Details After Selection
if(selectedDeposit !== undefined && depositToggleAutoFill){
  var newValues = documentValues;
  newValues.total = formatter.format(selectedDeposit.amount);
  newValues.payment_type = (selectedDeposit.paymentMethodObj.name).trim();
  newValues.document_number = (selectedDeposit.documentNumber).trim();


  setDocumentValues(newValues);
  setDepositToggleAutoFill(false);
  if(document){
    previewPayloadDocument(documentValues);
  }
}

const onCloseClick = e => {
  e.preventDefault()
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setdocumentUrl("");
  setDocument(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
  // setDocumentLoaded(false);
  setDataLoaded(false);
  setAssignedProduct(undefined);
  setdepositVal(undefined);
  setselectedDeposit(undefined);
  setinputDepositVal(undefined);
  setDepositOptions(undefined);
  setDepositToggleAutoFill(false);
  setDocumentFocus(undefined);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

return (
    <>
      <div
        className="modal fade"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                TEC Deposit Receipt
              </h5>
              <br />
              { hasClickedClose ? (
                <div className="d-flex">
                    <p className="m-1 pt-1 text-white">Save Changes?</p>
                    <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                    <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                </div>
              ): hasUnsavedChanges ? (
              <button
                type="button"
                className="btn-close bg-white"
                aria-label="Close"
                // onClick={() => setdocumentUrl("")}
                onClick={closeClicked}
              ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill();
                      AutofillProductDetails(assignedProduct);
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>

                  { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                      Product Snapshot
                      <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                      </div>
                    </div>
                  ):(
                    <div className="m-2 p-2 border">
                      <span>No Assigned Product</span>
                    </div>
                  )}

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Purchaser Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Purchaser Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_address ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Purchaser Email</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_email"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_email ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Purchaser City State Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_city_state_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_city_state_zip ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly pb-3">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Purchaser Phone</label>
                        <input
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          onFocus={removeFormatting}
                          className="form-control rounded-pill mt-1"
                          name="purchaser_phone"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.purchaser_phone ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Salesperson</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="sales_person"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.sales_person ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    <h5>Vehicle Information</h5>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-2">
                        <label htmlFor="stock">Vehicle Quantity</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vehicle_quantity"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vehicle_quantity ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-2">
                        <label htmlFor="stock">Vehicle Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vehicle_year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vehicle_year ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Vehicle Type</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vehicle_type"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vehicle_type ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Vehicle Model</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vehicle_model"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vehicle_model ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Vehicle Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vehicle_make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vehicle_make ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Stock Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="stock_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.stock_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Estimated Delivery Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="estimated_delivery_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.estimated_delivery_date ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly pb-3">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Serial Number (VIN)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="serial_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.serial_number ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    <h5>Deposit Information</h5>
                    { depositOptions  && depositOptions.length > 0 ? (
                    <div className="d-flex justify-content-evenly">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (depositVal && depositVal.name) ?? null}
                          onChange={(event, newValue) => {
                            setdepositVal(newValue);
                            if(newValue && newValue.id){
                              var foundProduct = order.products.find((product) => product.id === assignedProduct.id)
                              if(foundProduct && foundProduct.deposits && foundProduct.deposits.length > 0){
                                var foundDeposit = foundProduct.deposits.find((deposit) => deposit.id === newValue.id);
                                if(foundDeposit){
                                  setselectedDeposit(foundDeposit);
                                }
                              }
                            }
                            setDepositToggleAutoFill(true);
                          }}
                          inputValue={inputDepositVal}
                          onInputChange={(event, newInputValue) => {
                            setinputDepositVal(newInputValue);
                          }}
                          //id="controllable-states-demo"
                          options={depositOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="DEPOSIT" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                          />
                      </div>
                    </div>):(
                    <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Deposits Found</h5>
                    </div>
                  )}

                    <div className="d-flex justify-content-evenly pb-3">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Total</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="total"
                          onFocus={removeFormatting}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => {updatePreview(e)}}
                          value={ documentValues.total ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Payment Type</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="payment_type"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.payment_type ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Document Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="document_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.document_number ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                   </div>
                <hr></hr>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
            { saveSuccess ? (
              <span
                className="btn btn-success mx-auto"
              >
                Saved Document
              </span>
            ):(
              <button
              type="button"
              className="btn btn-primary mx-auto"
              // data-bs-dismiss="modal"
              onClick={handleSubmit}
              >
              Save
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

const {
  useSalesReps
} = rdx

export default TECDepositReceipt

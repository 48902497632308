import React, { useEffect, useState, useCallback } from "react";
import * as GridComponents from "@material-ui/data-grid";
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { setOrders } from "../../../redux/orders/orders";
import moment from 'moment'
import { isIndexPageSearchDisabled } from "../../../utlis/indexPages";

import Navbar from "../../../Components/Navbar/Navbar";
import { useFetch } from "../../../utlis/api";
import { useRouteMatch } from "react-router-dom";
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import {MenuItem} from "@material-ui/core";
import SearchIcon from '@mui/icons-material/Search';
import {InputLabel, Input, Box } from '@mui/material';

const FinanceRequest = () => {

  let match = useRouteMatch("/finance-requests");

  const dispatch  = useDispatch();
  const axios     = useFetch()
  const orders    = useSelector((state) => state?.ordersState);
  const role = useSelector((state) => state?.role.role);

  const pageSize = 10;
  const [currentPage, setCurrentPage] = React.useState(0);
  const [currentSearch, setCurrentSearch] = React.useState("");
  const [rowCount, setRowCount] = React.useState(0);
  const [pageState, setPageState] = useState('uninitalized');
  const [filterSegment, setFilterSegment] = useState('none');

  const today = moment.utc(new Date()).format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(today);


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }


    //ACTION CREATORS
    const refreshOrders = useCallback((axios, page=currentPage, filter=currentSearch, filterCategory=filterSegment, startDay=startDate, endDay=endDate) => {
      setPageState('loading');

      page = page === 0 ? 1 : page;
  
      let connstring = `/order/finance-requests?pageNumber=`+page+`&pageSize=10`;
      // if(filter && filter !== ""){
      //   connstring += `&filter=`+filter.trim();
      // }

      if(role && role !== 'master' && role !== 'default'){
        connstring += `&sim=${role}`;
      }

      if(filterCategory !== "none"){
        connstring += `&filterSegment=`+filterCategory.trim();
      }
  
      if(filterSegment === "date" && startDay !== null && endDay !== null){
        connstring += `&startDate=`+startDay+`&endDate=`+endDay;
      }else if(filter && filter !== ""){
        connstring += `&filter=`+filter.trim();
      }
  
      dispatch(setOrders([]))


      axios
        .get(connstring)
        .then( res => {
          if (res?.status === 200) {
            dispatch(setOrders(res?.data.data))
            setRowCount(res?.data.count)
          }
          setPageState('ready');
        });
      },[currentPage, currentSearch, role, filterSegment, startDate, endDate, dispatch])


      const searchUpdatePage = (e) => {
        const { code } = e;
        // const { value } = e.target;
        if((code === "Enter" || code === "NumpadEnter") && currentSearch && currentSearch !== ""){
          refreshOrders(axios, currentPage, currentSearch);
        }
      }


      const updatePage = useCallback((pageNumber=currentPage) => {

        setCurrentPage(pageNumber);
        let newPage = (pageNumber+1);
        refreshOrders(axios, newPage, currentSearch);
      
      },[ axios, refreshOrders, currentPage, currentSearch])

      useEffect(() => {
        if(match?.isExact === true && pageState === 'uninitalized'){
          refreshOrders(axios);
        }else if(match === null){
          setPageState('uninitalized');
        }
      }, [refreshOrders, axios, match, pageState])

      const setCustomDateRange = (date, marker) =>{
        if(marker === 'start') {
          setStartDate(date);
        }
        if(marker === 'end') {
          setEndDate(date);
        }
      }

    // function isSearchDisabled(searchTerm, searchSegment, startDate, endDate){
    //   return (currentSearch && currentSearch !== "" && filterSegment !== "date") || (startDate && endDate && filterSegment === "date") ? false : true;
    // }

    function resetSearch(){
      setCurrentSearch('');
      setStartDate(null);
      setEndDate(today);
      refreshOrders(axios, 1, "", "none", null, null);
    }

  return (
    <>
      <Navbar title="Finance Requests" />
      <div className="mx-4 mt-2">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <h3>Latest Finance Requests</h3>
            <div className="mx-3">
            </div>
          </div>
        </div>
      </div>

      <div className="mx-4">
        <div style={{ height: 480, width: "100%" }}>

        <div className="d-flex justify-evenly">
            <div>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small" className="my-2 px-3">
                <Select
                  value={filterSegment}
                  onChange={e => {
                    let { value } = e.target;
                    setFilterSegment(value);
                    if(value === "none"){
                      resetSearch();
                    }

                    if(value === "date"){
                      setCurrentSearch('');
                    }else{
                      setStartDate(null);
                      setEndDate(today);
                    }
                  }}
                  
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                  data-testid="indexPage.financeRequests.control.filterSegment"
                  // disabled={filterSegment === 'none'}
                  style={{ width: '250px'}}
                >
                  <MenuItem value="none" ><em>Reset Search</em></MenuItem>
                  <MenuItem value="customer" ><em>Customer & Contacts</em></MenuItem>
                  {/* <MenuItem value="status" ><em>Status</em></MenuItem> */}
                  <MenuItem value="sales-rep" ><em>Sales Rep</em></MenuItem>
                  <MenuItem value="sales-manager"><em>Sales Manager</em></MenuItem>
                  <MenuItem value="date"><em>Order Date</em></MenuItem>
                  <MenuItem value="funding-status" ><em>Funding Status</em></MenuItem>
                  <MenuItem value="stock-number" ><em>Stock Number</em></MenuItem>
                  {/* <MenuItem value="vin" ><em>Vin</em></MenuItem> */}

                </Select>
              </FormControl>
            </div>

            { filterSegment === "date" ? (
                <>
                  <div className="mb-1 pb-1 mx-1">
                    <Box mr={2} sx={{height: '50%'}}>
                      <InputLabel shrink={'true'} size={'small'} mt={2}>Start Date</InputLabel>
                      <Input
                      size={'small'}
                      id="start-date"
                      type="date"
                      label="Start Date"
                      value={startDate}
                      inputProps={{}}
                      onChange={(e) => setCustomDateRange(e.target.value, 'start')}
                    />
                  </Box>
                </div>                
                <div className="mb-1 pb-1 mx-1">
                  <Box>
                    <InputLabel shrink={'true'} size={'small'}>End Date</InputLabel>
                    <Input
                      size={'small'}
                      id="end-date"
                      type="date"
                      label="End Date"
                      value={endDate}
                      onChange={(e) => setCustomDateRange(e.target.value, 'end')}
                      inputProps={{ min: startDate}}
                    />
                  </Box>
                </div>
              </>
              ):(

                <div className="my-1 py-1" style={{ width: 500 }}>
                  <input
                    type="text"
                    className="form-control rounded-pill"
                    onChange={e => setCurrentSearch(e.target.value)}
                    onKeyUp={searchUpdatePage}
                    onFocus={e => { if(filterSegment === 'none') setFilterSegment('customer'); }}
                    value={currentSearch}
                    placeholder="Start Search"
                    aria-label="Search"
                    disabled={pageState === "loading"}
                    />  
                </div>

              )}
            <div className="my-1 px-2 py-1">
              <button className="btn btn-primary" disabled={isIndexPageSearchDisabled(currentSearch, filterSegment, startDate, endDate)} onClick={ e => {
                if(!isIndexPageSearchDisabled(currentSearch, filterSegment, startDate, endDate)){
                  refreshOrders(axios, 1, currentSearch);
                }    
              }} ><SearchIcon/> Search</button>           
            </div>
          </div>

                          
            <DataGrid
              {...orders}
              page={currentPage} 
              rows={orders.slice(0, pageSize)}
              // paginationModel={{
              //   pageSize: 10,
              //   page: 0,
              // }}
              rowCount={rowCount}
              paginationMode="server"
              disableColumnFilter
              onPageChange={(newPage) => updatePage(newPage)}
              columns={columns}
              pageSize={pageSize}
              autoHeight 
              disableSelectionOnClick
              components={{
                Toolbar: CustomToolbar,
              }}
              loading={pageState === "loading"}
              initialState={{
                filter: {
                  filterModel: {
                    items: [
                      {
                        columnField: 'ID',
                        operatorValue: 'contains',
                        value: '',
                      },
                    ],
                  },
                },
              }}
            />
        </div>
      </div>

    </>
  );
}

const columns = [
  {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return (
        
      <div>
      <Select
          IconComponent={MenuIcon}
          id="demo-simple-select"
          value={''}
          label="Age"
        >
          <Link to='/' href={`/order/${params.id}`} style={{ textDecoration: 'none' }}>
            <MenuItem>VIEW/EDIT ORDER</MenuItem>
          </Link>
        </Select>
      </div>
      );
    },
  },
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "customer",
    headerName: "Customer",
    width: 200,
    sortable: false,
    // editable: true,
  },
  {
    field: "orderStatus",
    headerName: "Status",
    sortable: false,
    width: 200,
    // editable: true,
  },
  {
    field: "salesRepName",
    headerName: "Sales Rep.",
    sortable: false,
    width: 200,
  },
  {
    field: "salesManagerName",
    headerName: "Sales Manager",
    sortable: false,
    width: 200,
  },
  {
    field: "orderDate",
    headerName: "Order Date",
    sortable: false,
    width: 200,
    valueFormatter: (params) => { return moment.utc(params.value).format("MM/DD/YYYY")},
    valueGetter: (params) => { return moment.utc(params.value).format("MM/DD/YYYY")}
  },
  {
    field: "fundedStatus",
    headerName: "Funded Status",
    sortable: false,
    flex: 1,
  },
  {
    field: "stockNumbers",
    headerName: "Stock Numbers",
    sortable: false,
    flex: 1,
  }
];

const {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton
} = GridComponents

export default FinanceRequest
import {
  calculateProductSubtotal, 
  calculateProductDepositTotal,
  getWeightedAdminFee,
  getWeightedTradeCredit,
  getWeightedTradeAllowance,
  vehicleTaxableAmountUpgradeAdminWeighted,
  getSalesTaxTotal
} from "./productCalculations.js";

const roundTwoDecimals = (number) =>{
  return (Math.round(number * 100) / 100);
}

const calculateTotalProductSubtotal = (order, products) => {
  var productSubtotal = 0;
  if(products && products.length > 0){
    products.forEach(product => {
      var psubtotal = roundTwoDecimals(calculateProductSubtotal(order, product));
      productSubtotal = Number(psubtotal) + Number(roundTwoDecimals(productSubtotal));
    });
  }
  return roundTwoDecimals(productSubtotal);
} 

const calculateTotalTradeCredit = (tradeins) => {
  if(tradeins && tradeins.length > 0){

    return tradeins.reduce(function(prev, current) {
      return prev + +(current.tradeAllowanceAtTerms - current.balanceOwed - current.cashRefundToCustomer)
    }, 0);
  }
  return 0;
}

const calculateTotalTradeCreditTotal = (tradeins, order) => {
  if(tradeins && tradeins.length > 0){
    let tradeCreditTotal = 0;
    tradeCreditTotal = tradeins.reduce(function(prev, current) {
      return prev + +(current.tradeAllowanceAtTerms - current.balanceOwed - current.cashRefundToCustomer)
    }, 0);
    return Number(tradeCreditTotal);
  }
  return 0;
}

const calculateOrderSubtotal = (order, products, tradeins, adminFee) => {
  var productSubtotal = (products && products.length > 0) ? calculateTotalProductSubtotal(order, products) : 0;
  var validAdminFee = adminFee && adminFee > 0 ? adminFee : 0;

  //Sales Tax
  if(order?.customer?.isExemptSalesTax === false){
    var vehicleSalesTaxTotal = calculateVehicleSalesTax(order.products, order.tradeins, order);
    productSubtotal = parseFloat(productSubtotal) + parseFloat(vehicleSalesTaxTotal);
  }

  //Non Vehicle Sales Tax WA
  if(order && order.customer && !order.customer.isExemptSalesTax && order.customer.deliveryAddress && order.customer.deliveryAddress.state === "WA"){
    var noVehicleSalesTaxTotal = calculateNonVehicleSalesTaxTotal(order.products, order);
    productSubtotal = parseFloat(productSubtotal) + parseFloat(noVehicleSalesTaxTotal);
  }

  return parseFloat(productSubtotal) + parseFloat(validAdminFee);
}

const calculateTotalProductDeposits = (products) => {
  var productDepositTotal = 0;
  if(products && products.length > 0){
    products.forEach(product => {
      var ptotal = calculateProductDepositTotal(product);
      productDepositTotal += ptotal;

    });
  }
  
  return productDepositTotal;
}

const calculateTotalDownpayment = (downPayments) => {
  // Set a variable to track the subtotal
  let downpaymentsSubtotal = 0
  // If the order has downpayments...
  if (downPayments && downPayments.length > 0) {
    //  add the downpayment amount to the subtotal.
    downPayments.forEach(item => downpaymentsSubtotal += item.amount);
    // Return the subtotal.
    return downpaymentsSubtotal;
  };
  // Otherwise, return zero.
  return 0;
}

const calculateBalanceDue = (order) => {
  if(order){
    if(order.products && order.products.length > 0 && order.products[0].fundingDetail 
      && (order.products[0].fundingDetail.fundingMethod === "tec-financing" ||
      order.products[0].fundingDetail.fundingMethod === "finance-only" ||
      order.products[0].fundingDetail.fundingMethod === "direct-deal"
    )){
      return 0.00;
    }
    var subtotal = calculateOrderSubtotal(order, order.products, order.tradeins, order.adminFee);

    let netTradeAndDownPayment = calculateTotalTradeCreditTotal(order.tradeins, order);

    if(netTradeAndDownPayment < 0){
      return Number(subtotal) + Number(Math.abs(netTradeAndDownPayment));
    }else{
      return Number(subtotal) - Number(netTradeAndDownPayment);
    }
  }
  return 0.00;
}

const calculateTotalFinancedAmount = (order) => {
  if(order){
    if(order.products && order.products.length > 0 && order.products[0].fundingDetail 
      && (order.products[0].fundingDetail.fundingMethod === "tec-financing" ||
      order.products[0].fundingDetail.fundingMethod === "finance-only" ||
      order.products[0].fundingDetail.fundingMethod === "direct-deal"
    )){
      var subtotal = calculateOrderSubtotal(order, order.products, order.tradeins, order.adminFee);
      let netTradeAndDownPayment = calculateTotalTradeCreditTotal(order.tradeins, order);
      if(netTradeAndDownPayment < 0){
        return Number(subtotal) + Number(Math.abs(netTradeAndDownPayment));
      }else{
        return Number(subtotal) - Number(netTradeAndDownPayment);
      }
    }
  }
  return 0.00;
}

const calculateGrandSubtotal = (order) => {
  if(order){
    if(order.products && order.products.length > 0){
      let subtotal = calculateOrderSubtotal(order, order.products, order.tradeins, order.adminFee);
      let netTradeCredit = calculateTotalTradeCreditTotal(order.tradeins, order);

      if(netTradeCredit < 0){
        subtotal = Number(subtotal) + Number(Math.abs(netTradeCredit));
      }else{
        subtotal = Number(subtotal) - Number(netTradeCredit);
      }

      let totalDeposits = calculateTotalProductDeposits(order.products);
      if(totalDeposits){
        subtotal =  Number(subtotal) - Number(totalDeposits);
      }
      let totalDownpayments =  calculateTotalDownpayment(order.downPayments);
      if(totalDownpayments){
        subtotal =  Number(subtotal) - Number(totalDownpayments);
      }
      return subtotal;
    }
  }
  return 0.00;
}


const getFirstProductLendor =  (products) => {
  if(products && products.length > 0){
    var product = products[0];
    if(product && product.fundingDetail && (product.fundingDetail.fundingMethod === "tec-financing" || product.fundingDetail.fundingMethod === "finance-only" || product.fundingDetail.fundingMethod === "direct-deal")){
      if(product.fundingDetail.finalizedLendingOption){
        return product.fundingDetail.finalizedLendingOption.bankName;
      }
    }
    if(product && product.fundingDetail && product.fundingDetail.fundingMethod === "cash-with-lien"){
      if(product.fundingDetail.cashLienHolder){
        return product.fundingDetail.cashLienHolder.bankName;
      }
    }
  }
}

const calculateVehicleSalesTax = (products, tradeins, order) => {
  let totalSalesTax = 0;
  if(order){
    if(products && products.length > 0){
      products.forEach((product) => {
        let weightedAdminFee = getWeightedAdminFee(order);
        let taxableAmount = vehicleTaxableAmountUpgradeAdminWeighted(product, order.customer, weightedAdminFee);
        
        
        let weightedTradeCredit = 0;
        let subTrade = false;
        if(product.registrationAddress?.state === "NV" || product.registrationAddress?.state === "AZ" || product.registrationAddress?.state === "WA"){
          weightedTradeCredit = getWeightedTradeAllowance(order, products, product, tradeins);
          subTrade = true;
        }else{
          weightedTradeCredit = getWeightedTradeCredit(order, products, product, tradeins);
        }
        
        let salesTax = getSalesTaxTotal(order, taxableAmount, weightedTradeCredit, subTrade);

        salesTax = (Math.round(salesTax * 100) / 100);
        totalSalesTax = Number(totalSalesTax) + Number(salesTax);
      });
    }
  }
  let formattedTotalSalesTax = (Math.round(totalSalesTax * 100) / 100);
  return formattedTotalSalesTax;
}

const calculateNonVehicleSalesTaxTotal = (products, order) => {
  let totalNonVehicleSalesTax = 0;
  if(order){
    if(products && products.length > 0){
      products.forEach((product) => {
        if(product.extendedWarranty && order?.customer?.nonVehicleSalesTaxPercent && order?.customer?.nonVehicleSalesTaxPercent > 0){
          let productTaxable = Number(product.extendedWarranty.price);

          if(product?.gapInsurance?.amount){
            productTaxable = Number(product.gapInsurance.amount);
          }

          let productTaxed = (productTaxable * order.customer.nonVehicleSalesTaxPercent) / 100;
          totalNonVehicleSalesTax += Number(productTaxed.toFixed(2));
        }
      });
    }
  }
  let formattedNonVehicleTotalSalesTax = Number(totalNonVehicleSalesTax.toFixed(2));
  return formattedNonVehicleTotalSalesTax;
}

export { 
  calculateTotalProductSubtotal,
  calculateTotalTradeCredit,
  calculateTotalTradeCreditTotal,
  calculateOrderSubtotal,
  calculateTotalDownpayment,
  calculateBalanceDue,
  calculateTotalFinancedAmount,
  calculateTotalProductDeposits,
  getFirstProductLendor,
  calculateGrandSubtotal,
  calculateNonVehicleSalesTaxTotal,
  calculateVehicleSalesTax
}
import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  useParams
} from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useOrder } from "../../../../../../../redux/orders/order"
import { formatter, removeChars, formatPhoneNumber, formatUpperCase } from "../../../../../../../utlis/formatters";
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { NVVehicleInspectionCertificateGlobalAutofill, NVVehicleInspectionCertificateAutofillProductDetails, preProcessPayload } from "../DocumentAutofill/NVVehicleInspectionCertificate"


const NVVehicleInspectionCertificate = ({document_id, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "nv-vehicle-inspection-cert";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();

  const order = useOrder();
  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);

  // Data Structure & Init //
  var initialDocumentStructure = {
    year: undefined, 
    make: undefined, 
    model: undefined, 
    body_type: undefined, 
    vin: undefined, 
    odo_reading: undefined, 
    cylinders: undefined, 
    rotor: undefined, 
    remarks_2: undefined, 
    remarks: undefined, 
    id_number: undefined, 
    authorized_officer_name: undefined, 
    date: undefined, 
    agency: undefined, 
    nv_authorized_agency: undefined, 
    dmv_inspection_address: undefined, 
    dmv_inspection_zip: undefined, 
    dmv_inspection_state: undefined, 
    dmv_inspection_city: undefined, 
    fcs_label_yes_cb: undefined, 
    fcs_label_no_cb: undefined, 
    mileage_exceeded_cb: undefined, 
    odo_reading_incorrect_cb: undefined, 
    model_year_exemption_cb: undefined, 
    cylinders_active_cb: undefined, 
    fuel_gas_cb: undefined, 
    fuel_diesel_cb: undefined, 
    fuel_propane_cb: undefined, 
    fuel_electric_cb: undefined, 
    fuel_other_cb: undefined, 
    enforcement_agency_enabled_cb: undefined, 
    nv_agency_enabled_cb: undefined, 
    rotor_active_cb: undefined, 
    trailer_living_quarters_yes: undefined, 
    trailer_living_quarters_no: undefined,
    safety_label_toggle: undefined,
    odometer_toggle: undefined,
    living_quarters_toggle: undefined,
    fuel_toggle: undefined
  }

  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  // X1
  // const removeFormatting = e =>{
  //   const {name,value}=e.target;
  //   setDocumentValues({...documentValues,[name]:removeChars(value)});
  // }

  // X1 Reference Arrays - TODO: Possibly move these to back end as part of the template stucture
  const moneyFields = useMemo(()=>{return[""]},[]);
  const phoneFields = useMemo(()=>{return[""]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "model"]},[]);
  const checkboxes = useMemo(()=>{return[
    "cylinders_active_cb",
    "rotor_active_cb",
    "enforcement_agency_enabled_cb",
    "nv_agency_enabled_cb"
  ]},[]);
  // const radios = [
  //   "safety_label_toggle", 
  //   "odometer_toggle",
  //   "living_quarters_toggle",
  //   "fuel_toggle"
  // ];

  // X1- Current General On Change
  const handleDocumentValuesChange = e =>{
    // e.preventDefault();
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatPhoneNumber(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
  setSaveSuccess(false);
  }
  
  // const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('NVVehicleInspectionCertificate.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      // setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, loadDocument ])
  const [assignedProduct, setAssignedProduct] = useState(undefined);

 // Autofill //

 const GlobalAutofill = useCallback(async () => {
  let newValues = NVVehicleInspectionCertificateGlobalAutofill(documentValues, order);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  // var newValues = documentValues;
  
  // if(order && order.customer && order.customer.name){
  //     newValues.printed = order.customer.email;
  // }

  // if(order && order.documentationDate && documentValues.date === undefined){
  //   newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  // }


  // setDocumentValues(newValues);
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ documentValues, order ])


// const preProcessPayload = useCallback((payload) => {

//   // Data point formatting


//   if(payload.safety_label_toggle){
//     // Reset all
//     payload.fcs_label_yes_cb = "no";
//     payload.fcs_label_no_cb = "no";

//     switch(payload.safety_label_toggle){
//       case "yes":
//         payload.fcs_label_yes_cb = "yes";
//         break;
//       case "no":
//         payload.fcs_label_no_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.odometer_toggle){
//     // Reset all
//     payload.mileage_exceeded_cb = "no";
//     payload.odo_reading_incorrect_cb = "no";
//     payload.model_year_exemption_cb = "no";

//     switch(payload.odometer_toggle){
//       case "1":
//         payload.mileage_exceeded_cb = "yes";
//         break;
//       case "2":
//         payload.odo_reading_incorrect_cb = "yes";
//         break;
//       case "3":
//         payload.model_year_exemption_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.living_quarters_toggle){
//     // Reset all
//     payload.trailer_living_quarters_yes_cb = "no";
//     payload.trailer_living_quarters_no_cb = "no";

//     switch(payload.living_quarters_toggle){
//       case "yes":
//         payload.trailer_living_quarters_yes_cb = "yes";
//         break;
//       case "no":
//         payload.trailer_living_quarters_no_cb = "yes";
//         break;
//       default:
//     }
//   }

//   if(payload.fuel_toggle){
//     // Reset all
//     payload.fuel_gas_cb = "no";
//     payload.fuel_diesel_cb = "no";
//     payload.fuel_propane_cb = "no";
//     payload.fuel_electric_cb = "no";
//     payload.fuel_other_cb = "no";

//     switch(payload.fuel_toggle){
//       case "gas":
//         payload.fuel_gas_cb = "yes";
//         break;
//       case "diesel":
//         payload.fuel_diesel_cb = "yes";
//         break;
//       case "propane":
//         payload.fuel_propane_cb = "yes";
//         break;
//       case "electric":
//         payload.fuel_electric_cb = "yes";
//         break;
//       case "other":
//         payload.fuel_other_cb = "yes";
//         break;
//       default:
//     }
//   }
  

//     // X1.2- Field Type Specific Preview Formatting
//     Object.keys(payload).forEach(key => {
//       if(moneyFields.indexOf(key) >= 0){
//           payload[key] = formatMoneyValue(payload[key]);
//       }else if(phoneFields.indexOf(key) >= 0){
//           payload[key] = formatPhoneNumber(payload[key]);
//       }else if(upperCaseFields.indexOf(key) >= 0){
//           payload[key] = formatUpperCase(payload[key]);
//       }
//     });
//     setDocumentValues(JSON.parse(JSON.stringify(payload)));
//     Object.keys(payload).forEach(key => {
//       if(checkboxes.indexOf(key) >= 0){
//         payload[key] = payload[key] ? "yes": "no";
//       }
//     });
//   if(payload.date){
//     payload.date = moment.utc(payload.date).format("MM/DD/YY");
//   }
//   return payload;
// },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])

const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });
    
    var docCopy = JSON.parse(JSON.stringify(document));
    docCopy.payload =  JSON.parse(JSON.stringify(payload));
    docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
    docCopy.payload =  JSON.stringify(docCopy.payload);
    delete docCopy.documentStatus;
    try {
      let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
      const file = new Blob(
        [response.data], 
        {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
      setdocumentUrl(fileURL)
    } catch (err) {
      recordFrontEndError('NVVehicleInspectionCertificate.jsx', 'Error previewing document payload: ' + err)
    };  
},[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


const AutofillProductDetails = useCallback(async (selectedProduct) => {
  let newValues = NVVehicleInspectionCertificateAutofillProductDetails(selectedProduct, documentValues);

  if(newValues){
    setDocumentValues(newValues);
    previewPayloadDocument(documentValues);
    setHasUnsavedChanges(true);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setSaveSuccess(false);
  }
  
  // var newValues = documentValues;
  // newValues.vin = (selectedProduct.vin).toString().toUpperCase();
  // newValues.year = (selectedProduct.year).trim();
  // newValues.make = (selectedProduct.make).trim();
  // newValues.model = (selectedProduct.model).toString().toUpperCase();

  // newValues.body_type = selectedProduct.vehicleType;

  // setDocumentValues(newValues);
  // // setProductToggleAutoFill(false);
  // if(document){
  //   previewPayloadDocument(documentValues);
  // }
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
  // // setAddressOptions(updateAddressOptions(selectedProduct, order));
},[ document, documentValues, previewPayloadDocument ]);

  // Document BREAD + Preview // TODO: Move to Redux

  useEffect(() => {
    if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){
  
      existingPayload = true;
  
      if(documentPayload.date){
        documentPayload.date = moment.utc(documentPayload.date).format("YYYY-MM-DD");
      }
      
  
      setDocumentValues(documentPayload);
    }

      var foundAssignedProduct = null;
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
        foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
      }

      if(foundAssignedProduct){
          setAssignedProduct(foundAssignedProduct);
      }
  
      // setAddressOptions(getOrderLevelAddresses(order));
      if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
      }else{
        // if(document && document.itemIds){
        //   var itemIds = document.itemIds.split(',');
        //     var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
        //     let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
        //     let obj = {id: foundProduct.id, name: str}
        //     setproductVal(obj);
        //     if(foundProduct){
        //       setAddressOptions(updateAddressOptions(foundProduct, order));
        //     }
        //     streamDocument();
        // }
  
      }
      
      setDataLoaded(true);
    }
  },[ documentValues, document, dataLoaded, AutofillProductDetails, GlobalAutofill, order, previewPayloadDocument ])




  
    // function streamDocument(){
    //   axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
    //     .then(response => {
    //       const file = new Blob(
    //         [response.data], 
    //         {type: 'application/pdf'});
    //         const fileURL = URL.createObjectURL(file);
    //         // window.open(fileURL);
    //       setdocumentUrl(fileURL)
    //     }).catch(err => {
    //     })
    // }
  
    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();
  
      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
    

      // const payload = JSON.parse(JSON.stringify(documentValues));
      // var docCopy = document;
      // docCopy.payload = payload;
      // docCopy.payload = preProcessPayload(docCopy.payload);
      // docCopy.payload =  JSON.stringify(docCopy.payload);
      // delete docCopy.documentStatus;
      
      // if(selectedProduct && selectedProduct.id){
      //   docCopy.itemIds = `${selectedProduct.id}`;
      // }
  
      // let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, null, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });

      //TODO: Add TradeId's to save
      // try {
      //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
      //   if (res) {
      //     axios.post(`/document/${document.id}/generate`)
      //     getDocuments();
      //     setSaveSuccess(true);
      //     setHasUnsavedChanges(false);
      //   }
      // } catch (err) {
      //   recordFrontEndError('NVVehicleInspectionCertificate.jsx', 'Error saving document: ' + err)
      // };
      
    }

// Component Helpers //
// useEffect(() => {
  //Autofill Product Details After Selection

// },[ selectedProduct, productToggleAutoFill ])

// useEffect(() => {
//   //Autofill Tradein Details After Selection
//   if(selectedTradein !== undefined && tradeinToggleAutoFill){
//     var newValues = documentValues;
//     newValues.trade_year = (selectedTradein.year);
//     newValues.trade_make = (selectedTradein.make);
//     newValues.trade_model = (selectedTradein.model);
//     newValues.trade_body_type = (selectedTradein.seriesBodyType);
//     newValues.trade_serial_number = (selectedTradein.serialNumber);
//     newValues.balance_owner = (order.customer.name); //Need specific field for TRADE IN OWNER?
//     newValues.balance_owner_address = (order.customer.deliveryAddress.addressLine1);
//     newValues.amount = (selectedTradein.balanceOwed);
//     newValues.trade_allowance = (selectedTradein.tradeAllowanceAtTerms);
//     newValues.balance_owed = (selectedTradein.balanceOwed);
//     newValues.cash_refund = (selectedTradein.cashRefundToCustomer);
//     newValues.net_trade_allowance = (selectedTradein.finalTradeAllowance);
//     // newValues.cash_down_payment = (selectedTradein);

//     setDocumentValues(newValues);
//     setTradeinToggleAutoFill(false);
//     if(document){
//       previewPayloadDocument(documentValues);
//     }
//   }
// },[ selectedTradein, tradeinToggleAutoFill ])

//Autofill Address Details After Selection
// function AutoFillAddress(addressSelected, selectionInstance){
//   var newValues = documentValues;
//   if(selectionInstance){
//     if(selectionInstance === "wa-primary-address"){
//       newValues.wa_primary_res_address = addressSelected.address+" "+addressSelected.city+" "+addressSelected.state+", "+addressSelected.zip;
//     }

//   }
//   setDocumentValues(newValues);
//   if(document){
//     previewPayloadDocument(documentValues);
//   }
//   setHasUnsavedChanges(true);
//   setSaveSuccess(false);
// }

const onCloseClick = e => {
  e.preventDefault()
  setDataLoaded(false);
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setAssignedProduct(undefined);
  setdocumentUrl("");
  setDocument(undefined);
  setDocumentFocus(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

  return (
    <>
      <div
        className="modal fade"
        // id="wa-title-app"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                NV - Vehicle Inspection Certificate
              </h5>
              <br />
              { hasClickedClose ? (
                <div className="d-flex">
                    <p className="m-1 pt-1 text-white">Save Changes?</p>
                    <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                    <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                </div>
              ): hasUnsavedChanges ? (
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}
                  onClick={closeClicked}
                ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
                )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ fontSize: '12px', overflowY: 'scroll', height: 'inherit'}}>
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill();
                      AutofillProductDetails(assignedProduct);
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>

                  <div className="row">

                  { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Product Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                        </div>
                    </div>
                  ):(
                    <div className="m-2 p-2 border">
                        <span>No Assigned Product</span>
                    </div>
                 )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Model</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="model"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.model ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Body Type</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="body_type"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.body_type ?? '' }
                          />
                      </div>
                    </div>

                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Federal Certification Safety Label and Emissions Label (LSVs only)</label>
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup     
                            row                       
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="safety_label_toggle"
                            value={(documentValues.safety_label_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="no" control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div className="p-2 col-md-8">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Odometer Reading</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="odo_reading"
                          maxLength="6"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.odo_reading ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">If the vehicle’s odometer apparatus only displays five numbers, please put an X in the first box.  Not all LSVs will have an odometer.</label>
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup     
                            row                       
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="odometer_toggle"
                            value={(documentValues.odometer_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="1" control={<Radio />} label="1. The mileage stated is in excess of its mechanical limits." />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="2" control={<Radio />} label="2.	The odometer reading is not the actual mileage. WARNING – ODOMETER DISCREPANCY" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="3" control={<Radio />} label="3. Exempt – Model year over 9 years old." />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-3">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.cylinders_active_cb }
                              name="cylinders_active_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="Cylinders" />
                        </FormControl>
                      </div>
                      { documentValues.cylinders_active_cb ? (
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Cylinders</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="cylinders"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.cylinders ?? '' }
                          />
                      </div>
                      ):(
                        <div className="p-2 col-md-3"></div>
                      )}
                      <div className="p-2 col-md-3">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.rotor_active_cb }
                              name="rotor_active_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="Rotor" />
                        </FormControl>
                      </div>
                      { documentValues.rotor_active_cb ? (
                      <div className="p-2 col-md-3">
                        <label htmlFor="stock">Rotor</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="rotor"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.rotor ?? '' }
                          />
                      </div>
                      ):(
                        <div className="p-2 col-md-3"></div>
                      )}
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">If vehicle being inspected is a trailer, does it include living quarters?</label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup     
                            row                       
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="living_quarters_toggle"
                            value={(documentValues.living_quarters_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="no" control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Fuel:</label>
                        <br />
                        <FormControl className="px-2 pt-1" style={{ fontSize: '12px' }}>
                          <RadioGroup     
                            row                       
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="fuel_toggle"
                            value={(documentValues.fuel_toggle ?? "")}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                          >
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="gas" control={<Radio />} label="Gas" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="diesel" control={<Radio />} label="Diesel" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="propane" control={<Radio />} label="Propane" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="electric" control={<Radio />} label="Electric" />
                            <FormControlLabel style={{ fontSize: '12px' }} className="p-2" value="other" control={<Radio />} label="Other" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>

                    {documentValues.fuel_toggle && documentValues.fuel_toggle === "other" ? (
                      <div>
                        <div className="d-flex justify-content-evenly">
                          <div className="p-2 col-md-12">
                            <label htmlFor="stock">Remarks</label>
                            <input
                              type="text"
                              className="form-control rounded-pill mt-1"
                              name="remarks"
                              maxLength="6"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              value={ documentValues.remarks ?? '' }
                              />
                          </div>
                        </div>
                        <div className="d-flex justify-content-evenly">
                          <div className="p-2 col-md-12">
                            <label htmlFor="stock">Remarks Line 2</label>
                            <input
                              type="text"
                              className="form-control rounded-pill mt-1"
                              name="remarks_2"
                              maxLength="6"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              value={ documentValues.remarks_2 ?? '' }
                              />
                          </div>
                        </div>
                      </div>
                    ):null}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Printed Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="authorized_officer_name"
                          maxLength="6"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.authorized_officer_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Badge or ID Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="id_number"
                          maxLength="6"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.id_number ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>


                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock"><b>(If other than DMV Inspection Station)</b></label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="dmv_inspection_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dmv_inspection_address ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="dmv_inspection_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dmv_inspection_city ?? '' }
                          />
                      </div> 
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="dmv_inspection_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dmv_inspection_state ?? '' }
                          />
                      </div>               
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="dmv_inspection_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dmv_inspection_zip ?? '' }
                          />
                      </div>          
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.enforcement_agency_enabled_cb }
                              name="enforcement_agency_enabled_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="Enforcement Agency" />
                        </FormControl>
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Enforcement Agency</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="agency"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.agency ?? '' }
                          />
                      </div>        
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <FormControl className="px-3 pt-2">
                          <FormControlLabel 
                            className="px-3" 
                            control={<input type="checkbox"
                              checked={ documentValues.nv_agency_enabled_cb }
                              name="nv_agency_enabled_cb"
                              onChange={handleDocumentValuesChange}
                              onBlur={(e) => updatePreview(e)}
                              className="m-2"
                              />}
                            label="Nevada Authorized Agency" />
                        </FormControl>
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Nevada Authorized Agency</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="nv_authorized_agency"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.nv_authorized_agency ?? '' }
                          />
                      </div>        
                    </div>

                   


                
                <hr></hr> 
                <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Date of Signature</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6"></div>
                    </div>
                </div>
                <hr/>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default NVVehicleInspectionCertificate

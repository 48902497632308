import moment from 'moment'
import { removeChars, formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";

export const NevadaBillOfSaleGlobalAutofill = async (documentValues, order, property=null, value=null) => {

    var newValues = documentValues;
    
    if(property ==='customer.email'){

      newValues.buyer_email =value;
  }

    if(property ==='documentationDate'){
      newValues.delivery_date = moment.utc(value).format("YYYY-MM-DD");
    }
    
    if(property !== null && value !== null){
      return newValues;
    }

    if(order && order.customer && order.customer.email){
        newValues.buyer_email = order.customer.email;
    }
  
    if(order && order.documentationDate && documentValues.delivery_date === undefined){
      newValues.delivery_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }
  
    return newValues
}

export const NevadaBillOfSaleAutofillProductDetails = async (selectedProduct, documentValues, property=null, value=null) => {
  var newValues = documentValues;

  if(property === 'vin' && value !== null){
    newValues.vin = (value).toString().toUpperCase();
  }

  if(property === 'year' && value !== null){
    newValues.year = (value);
  }

  if(property === 'make' && value !== null){
      newValues.make = (value).toString().trim();
  }

  if(property === 'model' && value !== null){
      newValues.model = (value).toString().toUpperCase();
  }
  
  if(property !== null && value !== null){
    return newValues;
  }

  newValues.vin = (selectedProduct.vin).toString().toUpperCase();
  newValues.year = (selectedProduct.year).trim();
  newValues.make = (selectedProduct.make).trim();
  newValues.model = (selectedProduct.model).toString().toUpperCase();

  return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

    
    if(payload.and_or_toggle){
      // Reset all
      payload.bos_and_cb = "no";
      payload.bos_or_cb = "no";
      
      switch(payload.and_or_toggle){
        case "and":
        payload.bos_and_cb = "yes";
        break;
        case "or":
          payload.bos_or_cb = "yes";
        break;
        
        default:
        }
      }
      
    // X1.2- Field Type Specific Preview Formatting
    Object.keys(payload).forEach(key => {
      if(moneyFields.indexOf(key) >= 0){
          payload[key] = formatMoneyValue(payload[key]);
      }else if(phoneFields.indexOf(key) >= 0){
          payload[key] = formatPhoneNumber(payload[key]);
      }else if(upperCaseFields.indexOf(key) >= 0){
          payload[key] = formatUpperCase(payload[key]);
      }
    });

    // Data point formatting
    if(payload.amount){
      payload.amount = removeChars(payload.amount);
    }

    Object.keys(payload).forEach(key => {
      if(checkboxes.indexOf(key) >= 0){
        payload[key] = payload[key] ? "yes": "no";
      }
    });    
    // Data point formatting
    if(payload.changes_date){
      payload.changes_date = moment.utc(payload.changes_date).format("MM/DD/YY");
    }

    if(payload.delivery_date){
      payload.delivery_date = moment.utc(payload.delivery_date).format("MM/DD/YY");
    }
      
    return payload;
}
import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";


export const CAPowerOfAttorneyGlobalAutofill = (documentValues, order, property=null, value=null) => {

    var newValues = documentValues;

//   if(property==='customer.name'){
//     newValues.owner_name = value
//     newValues.copy_owner_name = value
//     if(order?.customer?.dba && order?.customer?.dba !== null && order?.customer?.dba !== ''){
//       newValues.owner_name += " dba "+order.customer.dba;
//       newValues.copy_owner_name += " dba "+order.customer.dba;
//     }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
//         let cosigner = order.customer.cosigners[0];
//         if(cosigner && cosigner.name!==''){
//           newValues.owner_name += " AND "+cosigner.name;
//           newValues.copy_owner_name += " AND "+cosigner.name;
//         }
//     }
//   }
//   if(property==='customer.dba'){
//     newValues.owner_name = order.customer.name
//     newValues.copy_owner_name = order.customer.name
//     if(order?.customer?.dba && order?.customer?.dba !== null && order?.customer?.dba !== ''){
//       newValues.owner_name+= " dba "+value;
//       newValues.copy_owner_name += " dba "+value;
//     } else if(order.customer.cosigners && order.customer.cosigners !== null && order.customer.cosigners.length > 0){
//       let cosigner = order.customer.cosigners[0];
//       if(cosigner && cosigner.name){
//         newValues.owner_name += " AND "+cosigner.name;
//         newValues.copy_owner_name += " AND "+cosigner.name;
//       }
//     }
// }
// if(property==='customer.cosigner[0].name'){
//   newValues.owner_name = order.customer.name
//   newValues.copy_owner_name = order.customer.name
//   if(value!=='' && order.customer.dba===''){
//     newValues.owner_name += " AND "+value;
//     newValues.copy_owner_name += " AND "+value;
//   } else if(order.customer.dba!==''){
//     newValues.owner_name +=" dba "+order.customer.dba;
//     newValues.copy_owner_name += " dba "+order.customer.dba;
//   }
// }
//   if(property !== null && value !== null){
//     return newValues;
// }


  if(property?.includes('customer') || property === null){

    if(order && order.customer && order.customer.name){
      newValues.owner_name = order.customer.name
      newValues.copy_owner_name = order.customer.name
      if(order?.customer?.dba && order?.customer?.dba !== null && order?.customer?.dba !== ''){
        newValues.owner_name += " dba "+order.customer.dba;
        newValues.copy_owner_name += " dba "+order.customer.dba;
      }
      
      if(order.customer.cosigners && order.customer.cosigners !== null && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner && cosigner.name){
            newValues.owner_name += " AND "+cosigner.name;
            newValues.copy_owner_name += " AND "+cosigner.name;
          }
      }
    } 

  }
    
  
return newValues
}

  export const CAPowerOfAttorneyAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null, location) => {
    var newValues = documentValues;

    if(property === 'year' && value !== null){
      newValues.year = (value).toString().trim().replace(/['"]+/g, '');
    }

    if(property === 'make' && value !== null){
        newValues.make = (value).toString().trim();
    }

    if(property === 'location' && value !== null){
      if(value.state === "CA"){
        newValues.poa_name = "TEC OF CALIFORNIA, INC";
        newValues.copy_poa_name = "TEC OF CALIFORNIA, INC";
      }else{
        newValues.poa_name = "TEC EQUIPMENT, INC.";
        newValues.copy_poa_name = "TEC EQUIPMENT, INC.";
      }
    }

    if(property !== null && value !== null){
      return newValues;
    }

    newValues.year = (selectedProduct.year).trim();
    newValues.make = (selectedProduct.make).trim();
    newValues.vin = (selectedProduct.vin.toUpperCase());

    newValues.copy_year = (selectedProduct.year).trim();
    newValues.copy_make = (selectedProduct.make).trim();
    newValues.copy_vin = (selectedProduct.vin.toUpperCase());
      
    if(location){
      if(location.state === "CA"){
        newValues.poa_name = "TEC OF CALIFORNIA, INC";
        newValues.copy_poa_name = "TEC OF CALIFORNIA, INC";
      }else{
        newValues.poa_name = "TEC EQUIPMENT, INC.";
        newValues.copy_poa_name = "TEC EQUIPMENT, INC.";
      }
    }

    return newValues
  }


export const CAPowerOfAttorneyAutofillTradeDetails = (selectedTradein, documentValues, property=null, value= null, location)=>{
  var newValues = documentValues;  
  
  if(property === 'tradein.vin' && value !== null){
    newValues.vin = (value).toString().toUpperCase();
    newValues.copy_vin = (value).toString().toUpperCase();
  }

  if(property === 'tradein.year' && value !== null){
    newValues.year = (value).toString().trim();
    newValues.copy_year = (value).toString().trim();
  }

  if(property === 'tradein.make' && value !== null){
    newValues.make = (value).toString().trim();
    newValues.copy_make = (value).toString().trim();
  }

  if(property === 'location' && value !== null){
    if(value.state === "CA"){
      newValues.poa_name = "TEC OF CALIFORNIA, INC";
      newValues.copy_poa_name = "TEC OF CALIFORNIA, INC";
    }else{
      newValues.poa_name = "TEC EQUIPMENT, INC.";
      newValues.copy_poa_name = "TEC EQUIPMENT, INC.";
    }
  }

  if(property !== null && value !== null){
    return newValues;
  }

  newValues.year = (selectedTradein.year).trim();
  newValues.make = (selectedTradein.make).trim();
  newValues.vin = (selectedTradein.vin.toUpperCase());

  newValues.copy_year = (selectedTradein.year).trim();
  newValues.copy_make = (selectedTradein.make).trim();
  newValues.copy_vin = (selectedTradein.vin.toUpperCase());
  
  if(location){
    if(location.state === "CA"){
      newValues.poa_name = "TEC OF CALIFORNIA, INC";
      newValues.copy_poa_name = "TEC OF CALIFORNIA, INC";
    }else{
      newValues.poa_name = "TEC EQUIPMENT, INC.";
      newValues.copy_poa_name = "TEC EQUIPMENT, INC.";
    }
  }

  return newValues
}

  export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

    // X1.2- Field Type Specific Preview Formatting
    Object.keys(payload).forEach(key => {
      if(moneyFields.indexOf(key) >= 0){
          payload[key] = formatMoneyValue(payload[key]);
      }else if(phoneFields.indexOf(key) >= 0){
          payload[key] = formatPhoneNumber(payload[key]);
      }else if(upperCaseFields.indexOf(key) >= 0){
          payload[key] = formatUpperCase(payload[key]);
      }
    });
    Object.keys(payload).forEach(key => {
      if(checkboxes.indexOf(key) >= 0){
        payload[key] = payload[key] ? "yes": "no";
      }
    });

    payload.copy_vin = payload.vin;
    payload.copy_mc_engine_number = payload.mc_engine_number;
    payload.copy_license_plate = payload.license_plate;
    payload.copy_make = payload.make;
    payload.copy_year = payload.year;
    payload.copy_owner_name = payload.owner_name;
    payload.copy_owner_name_2 = payload.owner_name_2;
    payload.copy_poa_name = payload.poa_name;
    payload.copy_owner_city = payload.owner_city;
    payload.copy_owner_state = payload.owner_state;
    payload.copy_owner_zip = payload.owner_zip;
    payload.copy_owner_id = payload.owner_id;
    payload.copy_owner_id_2 = payload.owner_id_2;
    payload.copy_owner_date = payload.owner_date;
    payload.copy_owner_date_2 = payload.owner_date_2;
    payload.copy_owner_zip_2 = payload.owner_zip_2;
    payload.copy_owner_state_2 = payload.owner_state_2;
    payload.copy_owner_city_2 = payload.owner_city_2;


    // Data point formatting
    if(payload.owner_date){
      payload.owner_date = moment.utc(payload.owner_date).format("MM/DD/YY");
    }
  
    if(payload.owner_date_2){
      payload.owner_date_2 = moment.utc(payload.owner_date_2).format("MM/DD/YY");
    }

    if(payload.copy_owner_date){
      payload.copy_owner_date = moment.utc(payload.copy_owner_date).format("MM/DD/YY");
    }
  
    if(payload.copy_owner_date_2){
      payload.copy_owner_date_2 = moment.utc(payload.copy_owner_date_2).format("MM/DD/YY");
    }

    return payload;
}
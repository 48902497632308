import {
  calculateVehicleSalesTax,
  calculateTotalDownpayment,
  calculateTotalTradeCredit as calculateTotalTradeCreditTotal,
} from "./orderCalculations.js";
import moment from "moment";
import {
  formatter,
  formatPhoneNumber,
  capitalizeFirstLetter,
  splitString,
} from "./formatters";
import { enableTireTax } from "./fastLogicUtils.js";

const productGrouper = (selectedProducts, order) => {
  // Set an object to hold arrays of product.id values for similar products
  let productGrouperArrays = {};
  // Check to make sure that you have selected products.
  if (selectedProducts && selectedProducts.length > 0) {
    // Loop through the products to count the quantity of each type (YMM) of product.
    selectedProducts.forEach((item, i) => {
      let selectedProduct = item;
      let product = (order.products.filter(item => {
        return item.id === selectedProduct.id;
      }));
      if(product && product.length === 1) {
        product = product[0];
      };
      // If YMM product type has been previously recognized...
      if (productGrouperArrays.hasOwnProperty(`${product.year} ${product.make} ${product.model}`)) {
        // add the current product.id to the product's existing YMM grouping array.
        productGrouperArrays[`${product.year} ${product.make} ${product.model}`].push(product.id);
      };
      //// If YMM product type has NOT been previously recognized...
      if (!productGrouperArrays.hasOwnProperty(`${product.year} ${product.make} ${product.model}`)) {
        // add the current product.id to a new YMM grouping array.
        productGrouperArrays[`${product.year} ${product.make} ${product.model}`] = [product.id,];
      };
    });
  };
  // Return the object with the grouped arrays.
  return productGrouperArrays;
};

const maxLinesCalculator = (selectedProducts, order, productGrouperArray) => {
  //  Set a variable to keep track of the number of lines needed.
  let maxLinesCount = 0;
  // Set an object to keep track of grouped items that have already been calculated.
  let quantityItemsAlreadyCounted = {};
  // Check to make sure that you have selected products.
  if (selectedProducts && selectedProducts.length > 0) {
    // Loop through the products to count how many lines are needed for each type (YMM) of product.
    selectedProducts.forEach((item, i) => {
      let selectedProduct = item;
      let product = (order.products.filter(item => {
        return item.id === selectedProduct.id;
      }));
      if(product && product.length === 1) {
        product = product[0];
      };
      // For each product, use it's type's (YMM) quantity from the previously calculated productGrouper() function.
      const quantity = productGrouperArray[`${product.year} ${product.make} ${product.model}`].length;
      // If it is a singular product...
      if (quantity === 1  ) {
        // add 3 lines.
        maxLinesCount += 3;
      };
      // If it is a quantity product...
      if (quantity > 1) {
        // and has NOT yet been counted...
        if (!quantityItemsAlreadyCounted.hasOwnProperty(`${product.year} ${product.make} ${product.model}`)) {
          // mark it as counted...
          quantityItemsAlreadyCounted[`${product.year} ${product.make} ${product.model}`] = true;
          // and add 2 lines for the product description, and 2 lines for each line item.
          maxLinesCount += (quantity + 1) * 2;
        };
      };
      // If it is a quantity product and it has already been counted, it will be skiped so that it is not counted twice.
    });
  };
  // Return the line count.
  return maxLinesCount;
};

const calculateInvoicePriceSubtotal = (selectedProducts, order) => {
  //  Set a variable to keep track of the price subtotal.
  let invoiceSubtotal = 0;
  // Check to make sure that you have selected products.
  if (selectedProducts && selectedProducts.length > 0) {
    // Loop through the products to find each product price.
    selectedProducts.forEach(item => {
      let selectedProduct = item;
      let product = (order.products.filter(item => {
        return item.id === selectedProduct.id;
      }));
      if(product && product.length === 1) {
        product = product[0];
      };
      let psubtotal = 0;
      // Find the product price...
      if (product.price != null) {
        psubtotal = product.price
      };
      
      //Look and add-in updgrade prices
      if(product.upgrades && product.upgrades.length > 0){
        product.upgrades.forEach(upgrade => {
          if(upgrade.price != null) {
            psubtotal += Number(upgrade.price)
          };
        });
      }

      // and add it to the total.
      invoiceSubtotal += psubtotal;
    });
  };
  // Return the sutotal.
  return invoiceSubtotal;
};

const calculateInvoiceLineItemFeeAmountSubtotal = (selectedProducts, order) => {
  //  Set a variable to keep track of the lineItemFeeAmount subtotal.
  let invoiceLineItemFeeAmountSubtotal = 0;
  // Check to make sure that you have selected products.
  if(selectedProducts && selectedProducts.length > 0){
    // Loop through the products to find each product lineItemFeeAmount.
    selectedProducts.forEach(item => {
      let selectedProduct = item;
      let product = (order.products.filter(item => {
        return item.id === selectedProduct.id;
      }));
      if(product && product.length === 1) {
        product = product[0];
      };
      // Declare a temp lineItemFeeAmount value.
      let pLineItemFeeAmount = 0;
      // Check if there is a lineItemFee property.
      if (product.lineItemFee) {
        // Find the product lineItemFeeAmount...
        if (product.lineItemFee.amount) {
          // and if it is there, set the the temp lineItemFeeAmount to the product lineItemFeeAmount...
          pLineItemFeeAmount = product.lineItemFee.amount;
        };
      };
      // Add the temp value to the subtotal.
      invoiceLineItemFeeAmountSubtotal += pLineItemFeeAmount;
    });
  };
  // Return the subtotal.
  return invoiceLineItemFeeAmountSubtotal;
};

const calculateInvoiceCATTaxSubtotal = (selectedProducts, order) => {
  //  Set a variable to keep track of the catTax subtotal.
  let invoiceCATTaxSubtotal = 0;
  // Check to make sure that you have selected products.
  if(selectedProducts && selectedProducts.length > 0){
    // Loop through the products to find each product catTax.
    selectedProducts.forEach(item => {
      let selectedProduct = item;
      let product = (order.products.filter(item => {
        return item.id === selectedProduct.id;
      }));
      if(product && product.length === 1) {
        product = product[0];
      };
      // Declare a temp catTax value.
      let pCATTaxTotal = 0;
      // Check if there is a lineItemFee property.
      if (product.lineItemFee && product.registrationAddress?.state === "OR") {
        // Find the product catTax...
        if (product.lineItemFee.catTaxTotal != null) {
          // and if it is there, set the the temp catTax to the product catTax...
          pCATTaxTotal = product.lineItemFee.catTaxTotal;
        };
      };
      // Add the temp value to the subtotal.
      invoiceCATTaxSubtotal += pCATTaxTotal;
    });
  };
  // Return the subtotal.
  return invoiceCATTaxSubtotal;
};

const calculateInvoiceLuxuryTaxSubtotal = (selectedProducts, order) => {
  //  Set a variable to keep track of the luxuryTax subtotal.
  let invoiceLuxuryTaxSubtotal = 0;
  // Check to make sure that you have selected products.
  if(selectedProducts && selectedProducts.length > 0){
    // Loop through the products to find each product luxuryTax.
    selectedProducts.forEach(item => {
      let selectedProduct = item;
      let product = (order.products.filter(item => {
        return item.id === selectedProduct.id;
      }));
      if(product && product.length === 1) {
        product = product[0];
      };
      // Declare a temp luxuryTax value.
      let pLuxuryTaxTotal = 0;
      // Check is there is a lineItemFee property.
      if (product.lineItemFee) {
        // Find the product luxuryTax...
        if (product.lineItemFee.luxuryTaxTotal != null) {
          // and set the temp luxuryTax to the product luxuryTax.
          pLuxuryTaxTotal = product.lineItemFee.luxuryTaxTotal;
        };
        // If applyLuxuryTax is true...
        if (product.lineItemFee.applyLuxuryTax) {
          // add it to the total.
          invoiceLuxuryTaxSubtotal += pLuxuryTaxTotal;
        };
      };
    });
  };
  // Return the subtotal.
  return invoiceLuxuryTaxSubtotal;
};

const calculateInvoiceRegistrationFeeSubtotal = (selectedProducts, order) => {
  //  Set a variable to keep track of the registrationFee subtotal.
  let invoiceRegistrationFeeSubtotal = 0;
  // Check to make sure that you have selected products.
  if(selectedProducts && selectedProducts.length > 0){
    // Loop through the products to find each product registrationFee.
    selectedProducts.forEach(item => {
      let selectedProduct = item;
      let product = (order.products.filter(item => {
        return item.id === selectedProduct.id;
      }));
      if(product && product.length === 1) {
        product = product[0];
      };
      // Declare a temp registrationFee value.
      let pRegistrationFee = 0;
      // Check is there is a lineItemFee property.
      if (product.lineItemFee) {
        // Find the product registrationFee...
        if (product.lineItemFee.registrationFee != null) {
          // and set the temp registrationFee to the product registrationFee.
          pRegistrationFee = product.lineItemFee.registrationFee;
        };
        // If applyRegistrationFee is true...
        if (product.lineItemFee.applyRegistrationFee) {
          // add it to the total.
          invoiceRegistrationFeeSubtotal += pRegistrationFee;
        };
      };
    });
  };
  // Return the subtotal.
  return invoiceRegistrationFeeSubtotal;
};

const calculateInvoiceTitleFeeSubtotal = (selectedProducts, order) => {
  //  Set a variable to keep track of the titleFee subtotal.
  let invoiceTitleFeeSubtotal = 0;
  // Check to make sure that you have selected products.
  if(selectedProducts && selectedProducts.length > 0){
    // Loop through the products to find each product titleFee.
    selectedProducts.forEach(item => {
      let selectedProduct = item;
      let product = (order.products.filter(item => {
        return item.id === selectedProduct.id;
      }));
      if(product && product.length === 1) {
        product = product[0];
      };
      // Declare a temp titleFee value.
      let pTitleFee = 0;
      // Check is there is a lineItemFee property.
      if (product.lineItemFee) {
        // Find the product titleFee...
        if (product.lineItemFee.titleFee != null) {
          // and set the temp titleFee to the product titleFee.
          pTitleFee = product.lineItemFee.titleFee;
        };
        // If applyTitleFee is true...
        if (product.lineItemFee.applyTitleFee || product.lineItemFee.applyRegistrationFee) {
          // add it to the total.
          invoiceTitleFeeSubtotal += pTitleFee;
        };
      };
    });
  };
  // Return the subtotal.
  return invoiceTitleFeeSubtotal;
};

const calculateInvoiceEltTotal = (selectedProducts, order) => {
  //  Set a variable to keep track of the eltFee subtotal.
  var totalElt = 0;
  // Check to make sure that you have selected products.
  if(selectedProducts && selectedProducts.length > 0){
    // Loop through the products to find each product eltFee.
    selectedProducts.forEach(item => {
      let selectedProduct = item;
      let product = (order.products.filter(item => {
        return item.id === selectedProduct.id;
      }));
      if(product && product.length === 1) {
        product = product[0];
      };
      if(product.lineItemFee) {
        if (product.lineItemFee.etlFee != null) {
        totalElt += product.lineItemFee.etlFee;
        };
      };
    });
  }
  return totalElt;
};

const calculateInvoiceTotalTireCreditSubtotal = (selectedProducts, order) => {
  //  Set a variable to keep track of the totalTireCredit subtotal.
  let invoiceTotalTireCreditSubtotal = 0;
  // Check to make sure that you have selected products.
  if(selectedProducts && selectedProducts.length > 0){
    // Loop through the products to find each product totalTireCredit.
    selectedProducts.forEach(item => {
      let selectedProduct = item;
      let product = (order.products.filter(item => {
        return item.id === selectedProduct.id;
      }));
      if(product && product.length === 1) {
        product = product[0];
      };
      // Declare a temp totalTireCredit value.
      let pTotalTireCredit = 0;
      // Check is there is a lineItemFee property.
      if (product.lineItemFee) {
        // Find the product totalTireCredit...
        if (product.lineItemFee.totalTireCredit != null) {
          // and if it is there, set the the temp totalTireCredit to the product totalTireCredit...
          pTotalTireCredit = product.lineItemFee.totalTireCredit;
        };
      };
      // Add the temp value to the subtotal.
      invoiceTotalTireCreditSubtotal += pTotalTireCredit;
    });
  };
  // Return the subtotal.
  return invoiceTotalTireCreditSubtotal;
};

const calculateInvoiceFETSubtotal = (selectedProducts, order) => {
  //  Set a variable to keep track of the FET subtotal.
  let invoiceFETSubtotal = 0;
  // Check to make sure that you have selected products.
  if(selectedProducts && selectedProducts.length > 0){
    // Loop through the products to find each product FET.
    selectedProducts.forEach(item => {
      let selectedProduct = item;
      let product = (order.products.filter(item => {
        return item.id === selectedProduct.id;
      }));
      if(product && product.length === 1) {
        product = product[0];
      };
      // Declare a temp fet value.
      let pFET = 0;
      if (product.lineItemFee && product.type && product.type.toLowerCase() === 'new') {
        // Find the product FET...
        if (product.lineItemFee.applyFet && product.lineItemFee.fetTotal != null) {
          pFET = product.lineItemFee.fetTotal;
        };
        // and add it to the total.
        invoiceFETSubtotal += pFET;
      }
    });
  };
  // Return the sutotal.
  return invoiceFETSubtotal;
};

const calculateTotalTradeCredit = (tradein) => {
  if(tradein){
    let tradeCreditTotal = (tradein.tradeAllowanceAtTerms - tradein.balanceOwed - tradein.cashRefundToCustomer);
    return Number(tradeCreditTotal);
  }
  return 0;
}

// * "tireTaxTotal": 0.0000,
const calculateInvoiceTireTaxTotalSubtotal = (selectedProducts, order) => {
  //  Set a variable to keep track of the caDmvDocFee subtotal.
  let invoiceTireTaxTotalSubtotal = 0;
  // Check to make sure that you have selected products.
  if(selectedProducts && selectedProducts.length > 0){
    // Loop through the products to find each product tireTaxTotal.
    selectedProducts.forEach(item => {
      let selectedProduct = item;
      let product = (order.products.filter(item => {
        return item.id === selectedProduct.id;
      }));
      if(product && product.length === 1) {
        product = product[0];
      };
      // Declare a temp tireTaxTotal value.
      let pTireTaxTotal = 0;
      // Check is there is a lineItemFee property.
      if (product.lineItemFee) {
        // Find the product tireTaxTotal...
        if (product.lineItemFee.tireTaxTotal != null && (product?.lineItemFee?.enableTireTax === true || enableTireTax(order, product))) {
          // and if it is there, set the the temp tireTaxTotal to the product tireTaxTotal...
          pTireTaxTotal = product.lineItemFee.tireTaxTotal;
        };
      };
      // Add the temp value to the subtotal.
      invoiceTireTaxTotalSubtotal += pTireTaxTotal;
    });
  };
  // Return the subtotal.
  return invoiceTireTaxTotalSubtotal;
};

const calculateInvoiceSalesTaxTotalSubtotal = (selectedProducts, order) => {

  var products = [];
  selectedProducts.forEach(item => {
        let selectedProduct = item;
        let product = (order.products.filter(item => {
          return item.id === selectedProduct.id;
        }));
        if(product && product.length === 1) {
          product = product[0];
        };
        products.push(product)
  });

  return calculateVehicleSalesTax(products, order.tradeins, order)
  // //  Set a variable to keep track of the salesTaxTotal subtotal.
  // let invoiceSalesTaxTotalSubtotal = 0;
  // // Check to make sure that you have selected products.
  // if(selectedProducts && selectedProducts.length > 0){
  //   // Loop through the products to find each product salesTaxTotal.
  //   selectedProducts.forEach(item => {
  //     let selectedProduct = item;
  //     let product = (order.products.filter(item => {
  //       return item.id === selectedProduct.id;
  //     }));
  //     if(product && product.length === 1) {
  //       product = product[0];
  //     };


  //     // Declare a temp salesTaxTotal value.
  //     let pSalesTaxTotal = vehicleTaxableAmountUpgradeNoAdmin(product, order.customer);
  //     // Check is there is a lineItemFee property.
  //     // if (product.lineItemFee) {
  //     //   // Find the product salesTaxTotal...
  //     //   if (product.lineItemFee.salesTaxTotal != null ) {
  //     //     // and if it is there, set the the temp salesTaxTotal to the product salesTaxTotal...
  //     //     pSalesTaxTotal = product.lineItemFee.salesTaxTotal
  //     //     // pSalesTaxTotal = product.salesTaxPercent * 0.01 * product.price; // TODO: this is currently set to calculate based off of the percentage rather than sales tax total
  //     //   };
  //     // };
  //     // Add the temp value to the subtotal.
  //     invoiceSalesTaxTotalSubtotal += Number(pSalesTaxTotal);
  //   });
  // };

  // if(order.adminFee && order.customer && order.customer.deliveryAddress && order.customer.deliveryAddress.state !== "AZ"){
  //   invoiceSalesTaxTotalSubtotal = Number(invoiceSalesTaxTotalSubtotal) + Number(order.adminFee);
  // }

  // if(order.customer && order.customer.deliveryAddress && (order.customer.deliveryAddress.state === "AZ" || order.customer.deliveryAddress.state === "WA" || order.customer.deliveryAddress.state === "NV")){
    
  //   // Subtract trade credit
  //   var tradeCredit = calculateTotalTradeCreditTotal(order.tradeins, order);
  //   if(tradeCredit > 0){
  //     invoiceSalesTaxTotalSubtotal = Number(invoiceSalesTaxTotalSubtotal) - Number(tradeCredit);
  //   }
  // }

  // Return the subtotal.
  // return (invoiceSalesTaxTotalSubtotal * order.customer.vehicleSalesTaxPercent) / 100
};


const calculateInvoiceNonVehicleSalesTaxTotalSubtotal = (selectedProducts, order) => {
  //  Set a variable to keep track of the salesTaxTotal subtotal.
  let invoiceSalesTaxTotalSubtotal = 0;
  // Check to make sure that you have selected products.
  if(selectedProducts && selectedProducts.length > 0){
    // Loop through the products to find each product salesTaxTotal.
    selectedProducts.forEach(item => {
      let selectedProduct = item;
      let product = (order.products.filter(item => {
        return item.id === selectedProduct.id;
      }));
      if(product && product.length === 1) {
        product = product[0];
      };

      let pSalesTaxTotal = 0;

      if(product && product.extendedWarranty){
        pSalesTaxTotal += Number(product.extendedWarranty.price);
      }

      // Declare a temp salesTaxTotal value.
      // let pSalesTaxTotal = nonvehicleTaxableAmountUpgradeNoAdmin(product);
      // Check is there is a lineItemFee property.
      // if (product.lineItemFee) {
      //   // Find the product salesTaxTotal...
      //   if (product.lineItemFee.salesTaxTotal != null ) {
      //     // and if it is there, set the the temp salesTaxTotal to the product salesTaxTotal...
      //     pSalesTaxTotal = product.lineItemFee.salesTaxTotal
      //     // pSalesTaxTotal = product.salesTaxPercent * 0.01 * product.price; // TODO: this is currently set to calculate based off of the percentage rather than sales tax total
      //   };
      // };
      // Add the temp value to the subtotal.
      invoiceSalesTaxTotalSubtotal += Number(pSalesTaxTotal);
    });
  };

  // Return the subtotal.
  return (invoiceSalesTaxTotalSubtotal * order.customer.nonVehicleSalesTaxPercent) / 100
};

const calculateInvoiceCADMVDocFeeSubtotal = (selectedProducts, order) => {
  //  Set a variable to keep track of the caDmvDocFee subtotal.
  let invoiceCADMVDocFeeSubtotal = 0;
  // Check to make sure that you have selected products.
  if(selectedProducts && selectedProducts.length > 0){
    // Loop through the products to find each product caDmvDocFee.
    selectedProducts.forEach(item => {
      let selectedProduct = item;
      let product = (order.products.filter(item => {
        return item.id === selectedProduct.id;
      }));
      if(product && product.length === 1) {
        product = product[0];
      };
      // Declare a temp caDmvDocFee value.
      let pCADMVDocFee = 0;
      // Check is there is a lineItemFee property.
      if (product.lineItemFee) {
        // Find the product caDmvDocFee...
        if (product.lineItemFee.caDmvDocFee != null) {
          // and if it is there, set the the temp caDmvDocFee to the product caDmvDocFee...
          pCADMVDocFee = product.lineItemFee.caDmvDocFee;
        };
      };
      // Add the temp value to the subtotal.
      invoiceCADMVDocFeeSubtotal += pCADMVDocFee;
    });
  };
  // Return the subtotal.
  return invoiceCADMVDocFeeSubtotal;
};

const calculateInvoiceDocFeeSubtotal = (selectedProducts, order) => {
  //  Set a variable to keep track of the docFee subtotal.
  let invoiceDocFeeSubtotal = 0;
  // Check to make sure that you have selected products.
  if(selectedProducts && selectedProducts.length > 0){
    // Loop through the products to find each product docFee.
    selectedProducts.forEach(item => {
      let selectedProduct = item;
      let product = (order.products.filter(item => {
        return item.id === selectedProduct.id;
      }));
      if(product && product.length === 1) {
        product = product[0];
      };
      // Declare a temp docFee value.
      let pDocFee = 0;
      // Check is there is a lineItemFee property.
      if (product.lineItemFee) {
        // Find the product Doc Fee...
        if (product.lineItemFee.docFee != null) {
          // and if it is there, set the the temp docFee to the product docFee...
          pDocFee = product.lineItemFee.docFee;
        };
      };
      // Add the temp value to the subtotal.
      invoiceDocFeeSubtotal += pDocFee;
    });
  };
  // Return the subtotal.
  return invoiceDocFeeSubtotal;
};

const calculateInvoiceOosDeliveryFeeSubtotal = (selectedProducts, order) => {
  //  Set a variable to keep track of the oosDeliveryFee subtotal.
  let invoiceOosDeliveryFeeSubtotal = 0;
  // Check to make sure that you have selected products.
  if(selectedProducts && selectedProducts.length > 0){
    // Loop through the products to find each product oosDeliveryFee.
    selectedProducts.forEach(item => {
      let selectedProduct = item;
      let product = (order.products.filter(item => {
        return item.id === selectedProduct.id;
      }));
      if(product && product.length === 1) {
        product = product[0];
      };
      // Declare a temp oosDeliveryFee value.
      let pOosDeliveryFee = 0;
      // Check is there is a lineItemFee property.
      if (product.lineItemFee) {
        // Find the product oosDeliveryFee...
        if (product.lineItemFee.oosDeliveryFee != null) {
          // and if it is there, set the the temp oosDeliveryFee to the product oosDeliveryFee...
          pOosDeliveryFee = product.lineItemFee.oosDeliveryFee;
        };
      };
      // Add the temp value to the subtotal.
      invoiceOosDeliveryFeeSubtotal += pOosDeliveryFee;
    });
  };
  // Return the subtotal.
  return invoiceOosDeliveryFeeSubtotal;
};

const calculateInvoiceAdditionalTaxSubtotal = (selectedProducts, order) => {
  //  Set a variable to keep track of the additionalTax subtotal.
  let invoiceAdditionalTaxSubtotal = 0;
  // Check to make sure that you have selected products.
  if(selectedProducts && selectedProducts.length > 0){
    // Loop through the products to find each product additionalTax.
    selectedProducts.forEach(item => {
      let selectedProduct = item;
      let product = (order.products.filter(item => {
        return item.id === selectedProduct.id;
      }));
      if(product && product.length === 1) {
        product = product[0];
      };
      // Declare a temp additionalTax value.
      let pAdditionalTax = 0;
      // Check is there is a lineItemFee property.
      if (product.lineItemFee) {
        // Find the product additionalTax...
        if (product.lineItemFee.additionalTax != null) {
          // and if it is there, set the the temp additionalTax to the product additionalTax...
          pAdditionalTax = product.lineItemFee.additionalTax;
        };
      };
      // Add the temp value to the subtotal.
      invoiceAdditionalTaxSubtotal += pAdditionalTax;
    });
  };
  // Return the subtotal.
  return invoiceAdditionalTaxSubtotal;
};

const calculateInvoiceBankFeeSubtotal = (selectedProducts, order) => {
  //  Set a variable to keep track of the bank fee total.
  let bankFeeTotal = 0;
  // Check to make sure that you have selected products.
  if(selectedProducts && selectedProducts.length > 0){
    // Loop through the products to find each product caDmvDocFee.
    selectedProducts.forEach(item => {
      let selectedProduct = item;
      let product = (order.products.filter(item => {
        return item.id === selectedProduct.id;
      }));
      if(product && product.length === 1) {
        product = product[0];
      };
      // Check is there is a lineItemFee property.
      if (product.lineItemFee && product.lineItemFee.bankFee) {
          bankFeeTotal += Number(product.lineItemFee.bankFee);
      };
    });
  };
  // Return the subtotal.
  return bankFeeTotal;
};

const ApplyBlankLine = () => {
  return {
    column_1: "",
    column_2: "",
    column_3: "",
    column_4: "",
    column_5: "",
  };
}

const generateInvoice = (order, doc, documentValues, grouping = null, selectedProducts = null, salesReps, location) => {
  let showSalesTaxPerUnitPrice =  documentValues.showSalesTaxPerUnitPrice.toLowerCase()==="false"?false:true;
  // documentValues[`showSalesTaxPerUnitPrice`] = showSalesTaxPerUnitPrice.toString();
  if(grouping === null){
    let parsedAdditionalData = JSON.parse(doc?.additionalData);
    if(parsedAdditionalData?.invoiceGroupingType){
      grouping = parsedAdditionalData?.invoiceGroupingType;
    }
  }

  if(selectedProducts === null){
    let parsedItemIds = doc?.itemIds?.toString().split(',');
    if(parsedItemIds?.length > 0){
      selectedProducts = [];
      parsedItemIds.forEach(item => {
        // let foundProduct = order?.products?.find(p => p.id === item);
        var foundProduct = order.products.find((product) => Number(product.id) === Number(item));
        if(foundProduct){
          selectedProducts.push(foundProduct);
        }
      })
    }
  }


  const ApplyLocation = ()=>{

    if(location && location.address){
      documentValues['location_address'] = location.address+" "+location.city+" "+location.state+" "+location.zip;
    }
      
    if(location && location.phone){
      documentValues['location_phone'] = location.phone;
    }

    if(location && location.email){
      documentValues['location_email'] = location.email;
    }

    if(location && location.state){
      if(location.state === "CA"){
          documentValues['tec_footer_label'] = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
      }else{
          documentValues['tec_footer_label'] = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC";
      }
    }
  };

  const ApplyHeaderData = ()=>{

    function getRepByEmployeeId(employeeId){
      if(salesReps && salesReps.length > 0){
        let foundRep = salesReps.find(rep => rep.employee_ID === employeeId);
        if(foundRep){
          return foundRep.preferredName ? foundRep.preferredName+" "+foundRep.lastName : foundRep.firstName+" "+foundRep.lastName;
        }
      }
      return "";
    }

    // if(documentValues.invoice_date === undefined){
    //   documentValues['invoice_date'] =  moment.utc().format("MM/DD/YYYY");
    // }
    if(order){

      documentValues['invoice_number'] = (order?.id ?? "").toString();

      if(order && order.salesRepId && documentValues.salesperson === undefined){
        documentValues['salesperson'] = getRepByEmployeeId(order.salesRepId);
      }
      
      if(order && order.documentationDate && documentValues.documentation_date === undefined){
        documentValues['documentation_date'] = moment.utc(order.documentationDate).format("MM/DD/YYYY");
      }

      if(order && order.dateFinalized && documentValues.date_finalized === undefined){
        documentValues['date_finalized'] = moment.utc(order.dateFinalized).format("MM/DD/YYYY");
      }

      if(order && order.customer && order.customer.email && documentValues.email === undefined){
        documentValues['email'] = order.customer.email;
      }

      if(order.customer){
        documentValues['sold_to_label'] = "SOLD TO";

        var line = 1;
        if(order.customer.mailingAddress){
          documentValues['ship_to_label'] = "MAILING ADDRESS";
          line = 1;
          if(order.customer.mailingAddress && documentValues.ship_to_name === undefined){
            documentValues['ship_to_name'] = order.customer.name;
            
            documentValues['ship_to_address'+line] = order.customer.mailingAddress.addressLine1;
            if(order.customer.mailingAddress.addressLine2 != null && order.customer.mailingAddress.addressLine2 !== undefined &&order.customer.mailingAddress.addressLine2 !== ''){
              line++;
              documentValues['ship_to_address'+line] = order.customer.mailingAddress.addressLine2;
            }
            line++;
            documentValues['ship_to_address'+line] = capitalizeFirstLetter(order.customer.mailingAddress.city)+", "+order.customer.mailingAddress.state+" "+order.customer.mailingAddress.zip;
            if(order.customer && order.customer.phone){
              line++;      
              documentValues['ship_to_address'+line] = formatPhoneNumber(order.customer.phone);
            }
          }

          if(order.customer.deliveryAddress && documentValues.sold_to_name === undefined){
            documentValues['sold_to_name'] = order.customer.name;
            if(order.customer.dba){
              documentValues['sold_to_name'] = order.customer.name+" dba "+order.customer.dba;
            }
            
            line = 1
            if(order.customer.cosigners && order.customer.cosigners.length > 0){
              let cosigner = order.customer.cosigners[0];
              documentValues['sold_to_address'+line] = cosigner.name;
              line++;
            }

            documentValues['sold_to_address'+line] = order.customer.deliveryAddress.addressLine1;
            if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined &&order.customer.deliveryAddress.addressLine2 !== ''){
              line++;
              documentValues['sold_to_address'+line] = order.customer.deliveryAddress.addressLine2;
            }
            line++;
            documentValues['sold_to_address'+line] = capitalizeFirstLetter(order.customer.deliveryAddress.city)+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
          }
        }else{
          // Set Sold To == Delivery Address.
          if(order.customer.deliveryAddress && documentValues.sold_to_name === undefined){
            documentValues['sold_to_name'] = order.customer.name;
            if(order.customer.dba){
              documentValues['sold_to_name'] = order.customer.name+" dba "+order.customer.dba;
            }

            var addressline = 1;
            if(order.customer.cosigners && order.customer.cosigners.length > 0){
              let cosigner = order.customer.cosigners[0];
              documentValues['sold_to_address'+addressline] = cosigner.name;
            }

            addressline++;
            documentValues['sold_to_address'+addressline] = order.customer.deliveryAddress.addressLine1;
            if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined &&order.customer.deliveryAddress.addressLine2 !== ''){
              addressline++;
              documentValues['sold_to_address'+addressline] = order.customer.deliveryAddress.addressLine2;
            }
            addressline++;
            documentValues['sold_to_address'+addressline] = capitalizeFirstLetter(order.customer.deliveryAddress.city)+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
            if(order.customer && order.customer.phone){
              addressline++;      
              documentValues['sold_to_address'+addressline] = formatPhoneNumber(order.customer.phone);
            }
            
          }

        }
      }
    }
  };

  const MatchAndGroup = () => {
    let productArray = [];

    // Loop products and build matchingKey and assign it to each product.
    selectedProducts.forEach((product) => {

      // Pull full product object;
      let foundProduct = order.products.find((item) => item.id === Number(product.id))
      if(foundProduct){

        let sortedFobValues=[]
        let sortedWarrantyValues=[]
        let sortedGapInsuranceValues=[]
        let sortedLineItemFeeValues=[]
        let sortedFundingDetailValues=[]
        let sortedCashLienHolderValues=[]
        let sortedFinalizedLendingOptionValues=[]
        let sortedAdditionsValues=[]
        let sortedUpgradesValues=[]

        // Pull shallow level product properties we want to match on.
        let {fobAddress,make,model,price,type,vehicleType,year, extendedWarranty,gapInsurance, lineItemFee, fundingDetail,upgrades, additions } = foundProduct;
        
        if(fundingDetail && fundingDetail.fundingMethod === 'cash-with-lien'){
          let cashLienHolder=(fundingDetail?{...fundingDetail.cashLienHolder}:{})
          Object.keys(cashLienHolder).reduce((result,key)=>{
            result[key]=cashLienHolder[key]
            if(key!=='productId'&& key!=='id' && key!=='createdAt' && key!=='fundingDetailId'){
              sortedCashLienHolderValues.push(cashLienHolder[key])}
            return result
          },{})
        }
        

        if(fundingDetail && (fundingDetail.fundingMethod === 'tec-financing' || fundingDetail.fundingMethod === 'finance-only' || fundingDetail.fundingMethod === 'direct-deal')){
          let finalizedLendingOption=(fundingDetail?{...fundingDetail.finalizedLendingOption}:{})
          Object.keys(finalizedLendingOption).reduce((result,key)=>{
            result[key]=finalizedLendingOption[key]
            if(key!=='productId'&& key!=='id' && key!=='createdAt' && key!=='fundingDetailId'){
              sortedFinalizedLendingOptionValues.push(finalizedLendingOption[key])}
            return result
          },{})
        }
        

        if(fobAddress===null) fobAddress={};
        if(fundingDetail===null) fundingDetail={};
        if(extendedWarranty===null) extendedWarranty={}
        if(gapInsurance===null) gapInsurance={}  
        if(lineItemFee===null) lineItemFee={}

        Object.keys(fobAddress).reduce((result,key)=>{
          result[key]=fobAddress[key]
          if(key!=='updatedAt' && key!=='id' && key!=='createdAt'){
            sortedFobValues.push(fobAddress[key])}
          return result
        },{})

        Object.keys(extendedWarranty).reduce((result,key)=>{
          result[key]=extendedWarranty[key]
          if(key!=='productId' && key!=='id' && key!=='createdAt' && key!=='updatedAt'){
            sortedWarrantyValues.push(extendedWarranty[key])}
          return result
        },{})

        Object.keys(gapInsurance).reduce((result,key)=>{
          result[key]=gapInsurance[key]
          if(key!=='productId'&& key!=='id' && key!=='createdAt' && key!=='updatedAt'){
            sortedGapInsuranceValues.push(gapInsurance[key])}
          return result
        },{})
        
        Object.keys(lineItemFee).reduce((result,key)=>{
          result[key]=lineItemFee[key]
          if(key!=='lineItemId'&& key!=='id' && key!=='createdAt' &&key !== 'updatedAt' && key !== 'luxuryTaxOverrideToggle'){
            sortedLineItemFeeValues.push(lineItemFee[key])}
          return result
        },{})


        if(fundingDetail){
          Object.keys(fundingDetail).reduce((result,key)=>{
            result[key]=fundingDetail[key]
            if(key!=='productId'&& key!=='id' && key!=='createdAt' && key!=='cashLienHolder' && key!=='finalizedLendingOption' && key !== 'finalizedAt' && key!=='updatedAt'){
              sortedFundingDetailValues.push(fundingDetail[key])}
            return result
          },{})
        }

        if(upgrades.length>0){
          upgrades.sort(function(a, b) {
            if(a.type.toLowerCase() < b.type.toLowerCase()) return -1;
            if(a.type.toLowerCase() > b.type.toLowerCase()) return 1;
            return 0;
            }).forEach((upgrade)=>{
            Object.keys(upgrade).reduce((result,key)=>{
              result[key]=upgrade[key]
              if(key!=='productId'&& key!=='id' && key!=='createdAt' && key!=='id' && key !== 'updatedAt'&& key !== 'refNumber'){
                sortedUpgradesValues.push(upgrade[key])}
              return result
            },{})
          })
        }

        if(additions.length>0){
        additions.sort(function(a, b) {
          if(a.type.toLowerCase() < b.type.toLowerCase()) return -1;
          if(a.type.toLowerCase() > b.type.toLowerCase()) return 1;
          return 0;
          }).forEach((addition)=>{
            Object.keys(addition).reduce((result,key)=>{
              result[key]=addition[key]
              if(key!=='itemId'&& key!=='id' && key!=='createdAt' && key!=='id' && key !== 'updatedAt'&& key !== 'refNumber'){
                sortedAdditionsValues.push(addition[key])}
              return result
            },{})
          })
        }

        let matchingKey = 
        // fob+", "+
        // fobZip+", "+
        make+", "+
        model+", "+
        price+", "+
        // stateRegistered+", "+
        type+", "+
        vehicleType+", "+
        year+", "+
        sortedFobValues.join(", ")+", "+
        sortedWarrantyValues.join(", ")+", "+
        sortedGapInsuranceValues.join(", ")+", "+
        sortedLineItemFeeValues.join(", ")+", "+
        sortedFundingDetailValues.join(", ")+", "+
        sortedCashLienHolderValues.join(", ")+", "+
        sortedFinalizedLendingOptionValues.join(", ")+", "+
        sortedUpgradesValues.join(", ")+", "+upgrades.length+", "+
        sortedAdditionsValues.join(", ")+ ", "+additions.length;
        
        product.matchingKey = matchingKey;
        productArray.push(product);
      }
    });

    // Group based off of matchingKey
    var groupedByYmmArray = Object.values(productArray.reduce((result, {
      matchingKey,
      id,
      name
    }) => {
      // Create new group
      if (!result[matchingKey]) result[matchingKey] = {
          name,
          matchingKey,
          id,
          products: []
      };
      // Append to group
      result[matchingKey].products.push({
        id,
      });
      return result;
    }, {}));
  
    // Update products with qty counts based off matching.
    let findProduct;
    //go through each group of products
    groupedByYmmArray.forEach((group)=>{
      //go through each product in the group
      group.products.forEach((product, i)=>{
          //find the product in the order
          findProduct = order.products.find((item) => item.id === Number(product.id))
          if(findProduct)
          // edit the product
          findProduct.quantity=group.products.length
      })
    })
    return groupedByYmmArray;

  };

  const ApplyLine=(lineNumber, lineObject)=>{
    documentValues[`order_summary_line_${lineNumber}_qty`] = lineObject.column_1;
    documentValues[`order_summary_line_${lineNumber}_stock_no`] = lineObject.column_2;
    documentValues[`order_summary_line_${lineNumber}_desc`] = lineObject.column_3;
    documentValues[`order_summary_line_${lineNumber}_unit_price`] = lineObject.column_4;
    documentValues[`order_summary_line_${lineNumber}_total_price`] = lineObject.column_5;  
  };
  
  const ApplyLines = (startingLineNumber=1, lineArray) => {
    let lineNumber = startingLineNumber;
    if(lineArray && lineArray.length > 0){
      for(var l = 0; l < lineArray.length && lineNumber <= 25; l++){
        ApplyLine(lineNumber, lineArray[l]);
        lineNumber++;
      }
    }
    return lineNumber;
  };

  for(var clearLine = 1; clearLine <= 25; clearLine++){
    documentValues[`order_summary_line_${clearLine}_qty`] = "";
    documentValues[`order_summary_line_${clearLine}_stock_no`] = "";
    documentValues[`order_summary_line_${clearLine}_desc`] = "";
    documentValues[`order_summary_line_${clearLine}_unit_price`] =  "";
    documentValues[`order_summary_line_${clearLine}_total_price`] = "";
  }

  if(grouping === 'match'){
    var groupedSelectedProductArray = MatchAndGroup();
  }else{
    groupedSelectedProductArray = selectedProducts;
  }

  ApplyLocation();
  ApplyHeaderData();
    
    // let lineCounter = 0;

    //TODO need to make invoice version with selected products passed in.
    let subtotalDownpayment = calculateTotalDownpayment(order.downPayments);

    // The switch will decide which type of invoice to generate.
    // If it fits on a single page, generate a single page invoice. 
    let lastProductLine = 1;
    let preparedProductGroups = [];
    let subtotal = 0;

    const ApplyPreparedProductGroups = (totalLinesAvailable, productGroups) => {
      let linesRemaining = totalLinesAvailable;
      productGroups.forEach((productGroup) => {
        linesRemaining = linesRemaining - productGroup.lines.length;
        if(linesRemaining >= 0){
          lastProductLine = ApplyLines(lastProductLine, productGroup.lines);
        }else{
          // Push last used line forward based off difference
          lastProductLine += Math.abs(linesRemaining) - 1;

          //Reset page total
          linesRemaining = totalLinesAvailable;
          linesRemaining = linesRemaining - productGroup.lines.length;
          lastProductLine = ApplyLines(lastProductLine, productGroup.lines);
        }
      });
    }

    function checkYears(productArray){
      let yearsMatch = true;
      let year = productArray[0].year;
      productArray.forEach((product, i) => {
        if(product.year){
          if(product.year !== year){
            yearsMatch = false;
          }
        }else{
          yearsMatch = false;
        }
      });
      return yearsMatch;
    }

    function checkModels(productArray){
      let modelsMatch = true;
      let model = productArray[0].model;
      productArray.forEach((product, i) => {
        if(product.model){
          if(product.model.toUpperCase() !== model.toUpperCase()){
            modelsMatch = false;
          }
        }else{
          modelsMatch = false;
        }
      });
      return modelsMatch;
    }

    const findProductFromId = (id)=>{
      let foundProduct = (order.products.filter(item => {
        return item.id === id
      }));
      if(foundProduct && foundProduct.length === 1) {
        foundProduct = foundProduct[0];
      };
      return foundProduct;
    };
    
    const GroupAllProducts = () => { // TODO: still need to consider pagination

      preparedProductGroups = [];
      // Loop through the products to list them.
      let allProductsArray = [];
      selectedProducts.forEach((item, i) => {
        let product = findProductFromId(item.id);
        allProductsArray.push(product);
      });

      let yearsMatch = checkYears(allProductsArray);
      let modelsMatch = checkModels(allProductsArray);

      let productLines = listMultipleUnit(allProductsArray, yearsMatch, modelsMatch);
      preparedProductGroups.push({lines: productLines});
    };

    const generateSinglePageInvoice = () => { // TODO: still need to consider pagination
      // Loop through the products to list them.
      groupedSelectedProductArray.forEach((item, i) => {
        let selectedProduct = item;
        let groupedProductArray = [];
        if(selectedProduct.products && selectedProduct.products.length > 1){
          selectedProduct.products.forEach(gproduct => {
            let foundGProduct = findProductFromId(gproduct.id);
            groupedProductArray.push(foundGProduct);
          })
        }
        let product = findProductFromId(selectedProduct.id);
        if(product){
          if(groupedProductArray.length === 0){
            groupedProductArray.push(product);
          }
          let productLines = listSingleProduct(product, groupedProductArray);
          preparedProductGroups.push({productId: product.id, lines: productLines});
        }
      });
    };

    const listSingleProduct = (product, groupedProductArray) => {

      let productLines = [];

      let subtotalPrice = calculateInvoicePriceSubtotal(groupedProductArray, order);
      let subtotalCATTax = calculateInvoiceCATTaxSubtotal(groupedProductArray, order);
      let subtotalLuxuryTax = calculateInvoiceLuxuryTaxSubtotal(groupedProductArray, order);
      let subtotalTitleFee = calculateInvoiceTitleFeeSubtotal(groupedProductArray, order);
      let subtotalELT = calculateInvoiceEltTotal(groupedProductArray, order);
      let subtotalFET =calculateInvoiceFETSubtotal(groupedProductArray, order);
      let subtotalTireTaxTotal = calculateInvoiceTireTaxTotalSubtotal(groupedProductArray, order);
      let subtotalDocFee = calculateInvoiceDocFeeSubtotal(groupedProductArray, order);
      let subtotalBankFee = calculateInvoiceBankFeeSubtotal(groupedProductArray, order);
      let subtotalOosDeliveryFee = calculateInvoiceOosDeliveryFeeSubtotal(groupedProductArray, order);
      let subtotalAdditionalTax = calculateInvoiceAdditionalTaxSubtotal(groupedProductArray, order);

      subtotal = (Number(subtotal) + Number(subtotalPrice) + Number(subtotalFET));

      let productLineTotal = 0;
      productLineTotal += Number(product.price);

      var line_1 = '';
      var line_2 = '';
      var line_3 = '';
      var line_4 = '';

      var groupQty = product.quantity && product.quantity > 1 ? product.quantity : 1;

      if(product.type && product.type.toLowerCase() === "new"){
        if(product && product.lineItemFee && product.lineItemFee.applyFet){
          productLineTotal += Number(product.lineItemFee.fetTotal);
        }

        // New
        if(product.lineItemFee && product.lineItemFee.applyFet && product.lineItemFee.applyFet === true && product.lineItemFee !== null && product.lineItemFee.fetTotal !== null){
          line_1 = `FET       ${formatter.format((product.lineItemFee && product.lineItemFee.fetTotal ? product.lineItemFee.fetTotal : 0))}`;       
        }else if(product.lineItemFee && product.lineItemFee.applyFet === false && product.lineItemFee.fetExemptReason){
          // Exempt
          line_1 = `*FET EXEMPT`;
        }
        line_2 = "";
        line_3 = formatter.format(product.price ? product.price : 0)
        line_4 = `${formatter.format(productLineTotal * groupQty)}`;
      }else{
        // Used
        line_1 = formatter.format(product.price ? product.price : 0)
        line_2 = `${formatter.format(productLineTotal * groupQty)}`;
        line_3 = "";
        line_4 = "";
      }

      var formatProductYear = product?.year ? product.year.replace('"', '').replace('"', '') : "";

      var groupQtyLabel = groupQty > 1 ? groupQty+" " : "";
        const splitStockNumber = splitString(product?.stockNumber, 15)
      var product_line_1 = {
        column_1: `${ (product.type ? groupQtyLabel+product.type.toUpperCase() : '[Product Type Missing]')}`,
        column_2: (product && product.stockNumber && groupQty === 1 ? splitStockNumber[0]?.toUpperCase() : "**"),
        column_3: `${formatProductYear} ${product?.make} ${product?.model?.toString().toUpperCase()}`,
        column_4: line_1,
        column_5: line_2,
      };

      
      
      productLines.push(product_line_1);

      var caTypeLabel = (order.customer.deliveryAddress.state === 'CA' && product && product.vehicleType ? product.vehicleType.toUpperCase()+" - " : "");
      let product_line_2 = {
        column_1: "",
        column_2: splitStockNumber[1]?.toUpperCase() ??'',
        column_3: groupQty > 1 ? `${caTypeLabel} See Schedule A` : `${caTypeLabel}VIN# ${ ( product.vin ? product.vin.toUpperCase() : "" ) }`,
        column_4: line_3,
        column_5: line_4,
      };
      
      productLines.push(product_line_2);

      // let product_line_3 = {
      //   column_1: "",
      //   column_2: splitStockNumber[2]?.toUpperCase() ??'',
      //   column_3: product.fobAddress.addressLine1? 'FOB: '+product.fobAddress.addressLine1+(product.fobAddress?.addressLine2 ? ' '+product.fobAddress.addressLine2 :''):'',
      //   column_4: '',
      //   column_5: "",
      // };
      
      // productLines.push(product_line_3);
      
      // let product_line_4 = {
      //   column_1: "",
      //   column_2: '',
      //   column_3: product.fobAddress? product.fobAddress.city+', '+product.fobAddress.state+' '+product.fobAddress.zip:'',
      //   column_4: '',
      //   column_5: "",
      // };
      
      // productLines.push(product_line_4);
      
      
      if(product.additions && product.additions.length > 0){
        product.additions.forEach(addition => {
          let addition_line_1 = {
            column_1: "",
            column_2: "",
            column_3: addition.type ? addition.type.toUpperCase() : "",
            column_4: "",
            column_5: "",
          };
          
          
          
          productLines.push(addition_line_1);


          let addition_line_2 = {
            column_1: "",
            column_2: "",
            column_3: addition.serialNumber ? "S/N: "+addition.serialNumber.toUpperCase() : "",
            column_4: "",
            column_5: "",
          };
          
          
          
          productLines.push(addition_line_2);
        });
      }

      // let fobNullCheck = !Object.keys(product.fobAddress).some(key => {
      //   if (key === 'name') {
      //     return false;
      //   }
      //   return product.fobAddress[key] === null;
      // });
      // console.log('fobcheck', fobNullCheck)
if(product?.fobAddress?.addressLine1){
      let product_line_3 = {
        column_1: "",
        column_2: splitStockNumber[2]?.toUpperCase() ??'',
        column_3: 'FOB: '+product.fobAddress.addressLine1+(product.fobAddress?.addressLine2 ? ' '+product.fobAddress.addressLine2 :''),
        column_4: '',
        column_5: "",
      };
      

      

      productLines.push(product_line_3);

      


      let product_line_4 = {
        column_1: "",
        column_2: '',
        column_3: product.fobAddress.city+', '+product.fobAddress.state+' '+product.fobAddress.zip,
        column_4: '',
        column_5: "",
      };
      
      productLines.push(product_line_4);
}
      if(product.upgrades && product.upgrades.length > 0){
        product.upgrades.forEach(upgrade => {
          

          productLineTotal += Number(upgrade.price);

          const splitUpgradeType = splitString(upgrade?.type,30)

          let upgrade_line_1 = {
            column_1: splitUpgradeType[0]?.toUpperCase() ?? "",
            column_2: "",
            column_3: upgrade.serialNumber ? "REF "+upgrade.serialNumber.toUpperCase() : "",
            column_4: upgrade.price ? `${formatter.format(upgrade.price)}` : "",
            column_5: upgrade.price ? `${formatter.format(upgrade.price * groupQty)}` : "",
          };
          
          
          
          productLines.push(upgrade_line_1);
          if(splitUpgradeType.length>1){
            let upgrade_line_2 = {
              column_1: splitUpgradeType[1]?.toUpperCase() ?? "",
              column_2: "",
              column_3: "",
              column_4: "",
              column_5: "",
            };

            

            productLines.push(upgrade_line_2);
          }

        });
      }

      if(product.extendedWarranty && product?.extendedWarranty?.price > 0){

        let extendedWarrantyPerUnitPrice = product.extendedWarranty.price ? product.extendedWarranty.price : 0;

        let providerLine = product.extendedWarranty.provider ? "Provided by: "+product.extendedWarranty.provider : "";

        let extended_warranty_line = {
          column_1: "Extended Warranty",
          column_2: "",
          column_3: providerLine,
          column_4: formatter.format(extendedWarrantyPerUnitPrice),
          column_5: formatter.format(extendedWarrantyPerUnitPrice * groupQty),
        };
        
        
        
        productLines.push(extended_warranty_line);

      }

      if(product?.gapInsurance && product?.gapInsurance?.amount > 0){

        let gap_insurance_line = {
          column_1: "GAP Waiver",
          column_2: "",
          column_3: product.gapInsurance.provider ? "Provided by: "+product.gapInsurance.provider : "",
          column_4: formatter.format(product.gapInsurance.amount),
          column_5: formatter.format(product.gapInsurance.amount * groupQty),
        };
        
        
        
        productLines.push(gap_insurance_line);

      }

      // Handle Elt.
      if (subtotalELT > 0) {
        subtotal = Number(subtotal) + Number(subtotalELT);
        let product_line_FILING_FEE = {
            column_1: "Electronic Filing Service Fee",
            column_2: "",
            column_3: "",
            column_4: formatter.format(subtotalELT / groupQty),
            column_5: formatter.format(subtotalELT),
        };
        
        productLines.push(product_line_FILING_FEE);
      };

    // if (location && (location.state === "CA" || location.state === "AZ")) {
    if(product?.lineItemFee?.enableTireTax === true || enableTireTax(order, product)){
      subtotal = Number(subtotal) + Number(subtotalTireTaxTotal);
      // Handle CA & AZ tire tax.
      if (subtotalTireTaxTotal !== 0) {
        let product_line_TIRE_TAX = {
          column_1: "Tire Tax",
          column_2: "",
          column_3: "",
          column_4: formatter.format(subtotalTireTaxTotal / groupQty),
          column_5: formatter.format(subtotalTireTaxTotal),
        };

        productLines.push(product_line_TIRE_TAX);
      }
    }

      // Handle Oregon CAT tax.
      if (subtotalCATTax !== 0) {
        subtotal = Number(subtotal) + Number(subtotalCATTax);
        let product_line_CAT_TAX = {
          column_1: "Estimated OR CAT", 
          column_2: "", 
          column_3: "",
          column_4: formatter.format(subtotalCATTax / groupQty),
          column_5: formatter.format(subtotalCATTax),
        };

        
        productLines.push(product_line_CAT_TAX);
      };

      if (order.customer.deliveryAddress.state === "OR") {
        // Handle Oregon Luxury tax.
        if (subtotalLuxuryTax !== 0) { // applyLuxuryTax is already considered in the subtotalLuxuryTax calculation.
          subtotal = Number(subtotal) + Number(subtotalLuxuryTax);
          let product_line_LUXURY_TAX = {
            column_1: "OR Vehicle Privilege & Use Tax", 
            column_2: "", 
            column_3: "",
            column_4: formatter.format(subtotalLuxuryTax / groupQty),
            column_5: formatter.format(subtotalLuxuryTax),
          };

          
          productLines.push(product_line_LUXURY_TAX);
        };
      };

      // Handle License/Title Fees.
      if (subtotalTitleFee !== 0) { // applyTitleFee is already considered in the subtotalTitleFee calculation.
        subtotal = Number(subtotal) + Number(subtotalTitleFee);
        let product_line_LICENSE_TITLE_FEES = {
          column_1: "License/Title Fees",
          column_2: "",
          column_3: (product.lineItemFee && product.lineItemFee.applyTitleFee === true && product.lineItemFee.applyRegistrationFee === false ? "Title Transfer Only" : ""),
          column_4: formatter.format(subtotalTitleFee / groupQty),
          column_5: formatter.format(subtotalTitleFee),
        };

        
        productLines.push(product_line_LICENSE_TITLE_FEES);
      };

      // Handle Document Fees.
      if (subtotalDocFee !== 0) {
        subtotal = Number(subtotal) + Number(subtotalDocFee);
        let product_line_DOC_FEE = {
          column_1: location.state === "CA" ? "CA DMV Doc Fee" : (location.state === "WA" ? "Negotiable Documentary Service Fee" : "DMV Doc Fee"),
          column_2: "",
          column_3: "",
          // column_4: formatter.format((first_product.lineItemFee && first_product.lineItemFee.docFee ? first_product.lineItemFee.docFee : 0) / groupQty),
          column_4: formatter.format(subtotalDocFee / groupQty),
          column_5: formatter.format(subtotalDocFee),
        };

        
        productLines.push(product_line_DOC_FEE);
      };

      // Handle Document Fees.
      if (subtotalBankFee !== 0) {
        subtotal = Number(subtotal) + Number(subtotalBankFee);
        let product_line_BANK_FEE = {
          column_1: "Bank Fee",
          column_2: "",
          column_3: "",
          column_4: formatter.format(subtotalBankFee / groupQty),
          column_5: formatter.format(subtotalBankFee),
        };

        
        productLines.push(product_line_BANK_FEE);
      };

      // Handle Document Fees.
      if (subtotalOosDeliveryFee > 0) {
        subtotal = Number(subtotal) + Number(subtotalOosDeliveryFee);
        let product_line_DELIVERY_FEE = {
          column_1: "Delivery Fee",
          column_2: "",
          column_3: "",
          // column_4: formatter.format((first_product.lineItemFee && first_product.lineItemFee.oosDeliveryFee ? first_product.lineItemFee.oosDeliveryFee : 0)),
          column_4: formatter.format(subtotalOosDeliveryFee / groupQty),
          column_5: formatter.format(subtotalOosDeliveryFee),
        };

        
        productLines.push(product_line_DELIVERY_FEE);
      };

      // Handle Document Fees.
      if (subtotalAdditionalTax > 0) {
        subtotal = Number(subtotal) + Number(subtotalAdditionalTax);
        let product_line_MISC_FEE = {
          column_1: "Miscellaneous",
          column_2: "",
          column_3: "",
          // column_4: formatter.format((first_product.lineItemFee && first_product.lineItemFee.additionalTax ? first_product.lineItemFee.additionalTax : 0)),
          column_4: formatter.format(subtotalAdditionalTax / groupQty),
          column_5: formatter.format(subtotalAdditionalTax),
        };

        
        productLines.push(product_line_MISC_FEE);
      };

      
      productLines.push(ApplyBlankLine());


      return productLines;
    };

    const listMultipleUnit = (productArray, yearsMatch=false, modelsMatch=false) => {
      
      let productLines = [];

      let displayYear = '';
      if(yearsMatch){
        displayYear = productArray[0].year+" ";
      }

      let displayModel = '';
      if(modelsMatch){
        displayModel = productArray[0].model.toUpperCase()+" ";
      }

      let displayLabel = '';
      if(displayYear !== ''){
        displayLabel = displayYear+displayModel;
      }else{
        displayLabel = displayModel;
      }
      // subtotal = 0;

      // let subtotalPrice = calculateInvoicePriceSubtotal(productArray, order);
      let subtotalCATTax = calculateInvoiceCATTaxSubtotal(productArray, order);
      let subtotalLuxuryTax = calculateInvoiceLuxuryTaxSubtotal(productArray, order);
      let subtotalTitleFee = calculateInvoiceTitleFeeSubtotal(productArray, order);
      let subtotalELT = calculateInvoiceEltTotal(productArray, order);
      let subtotalFET =calculateInvoiceFETSubtotal(productArray, order);
      let subtotalTireTaxTotal = calculateInvoiceTireTaxTotalSubtotal(productArray, order);
      // let subtotalSalesTaxTotal = calculateInvoiceSalesTaxTotalSubtotal(productArray, order);
      // let subtotalNonVehicleSalesTaxTotal = calculateInvoiceNonVehicleSalesTaxTotalSubtotal(productArray, order);
      let subtotalDocFee = calculateInvoiceDocFeeSubtotal(productArray, order);
      let subtotalBankFee = calculateInvoiceBankFeeSubtotal(productArray, order);
      let subtotalOosDeliveryFee = calculateInvoiceOosDeliveryFeeSubtotal(productArray, order);
      let subtotalAdditionalTax = calculateInvoiceAdditionalTaxSubtotal(productArray, order);

      // subtotal = Number(subtotal) + Number(subtotalPrice) + Number(subtotalFET);

      var groupQty = productArray && productArray.length > 0 ? productArray.length : 1;

      let hasNew = false;
      let hasUsed = false;
      let typeLabel = "";
      let productLineTotal = 0;
      let extendedWarrantyTotal = 0;
      let extendedWarrantyProviders = [];
      let gapInsuranceTotal = 0;
      let gapInsuranceProviders = [];
      productArray.forEach((product, i) => {
        if(product.type && product.type.toUpperCase() === 'NEW'){
          hasNew = true;
        }

        if(product.type && product.type.toUpperCase() === 'USED'){
          hasUsed = true;
        }

        productLineTotal += Number(product.price);

        product.upgrades.forEach(upgrade => {
          productLineTotal += Number(upgrade.price);
        });

        if(product.extendedWarranty && product.extendedWarranty.price && product.extendedWarranty.price > 0){
          extendedWarrantyTotal += Number(product.extendedWarranty.price);
        }

        if(product.extendedWarranty && product.extendedWarranty.provider && product.extendedWarranty.provider !== ''){
          extendedWarrantyProviders.push(product.extendedWarranty.provider);
        }

        if(product.gapInsurance && product.gapInsurance.amount && product.gapInsurance.amount > 0){
          gapInsuranceTotal += Number(product.gapInsurance.amount);
        }

        if(product.gapInsurance && product.gapInsurance.provider && product.gapInsurance.provider !== ''){
          gapInsuranceProviders.push(product.gapInsurance.provider);
        }
      });

      if(hasNew && hasUsed){
        typeLabel = "NEW & USED";
      }else if(hasNew){
        typeLabel = "NEW";
      }else if(hasUsed){
        typeLabel = "USED";
      }

      let productLineTotalNoFet = productLineTotal;

      var fetLine = '';
      if(subtotalFET > 0){
        let perUnitFET = subtotalFET / groupQty;
        productLineTotal += Number(subtotalFET);
        fetLine = `FET       ${formatter.format(perUnitFET)}`;       
      }
     
      var product_line_1 = {
        column_1: `${ groupQty + " " + typeLabel}`,
        column_2: "**",
        column_3: `${displayLabel}MULTIPLE UNITS`,
        column_4: fetLine,
        column_5: "",
      };

      
      
      productLines.push(product_line_1);

      let product_line_2 = {
        column_1: "",
        column_2: "",
        column_3: `See Schedule A`,
        column_4: `${formatter.format(productLineTotalNoFet / groupQty)}`,
        column_5: `${formatter.format(productLineTotal)}`,
      };
      
      productLines.push(product_line_2);

      let fobProduct = productArray.find(prod=> (prod.fobAddress.city !== null && prod.fobAddress.addressLine1!== null))
      console.log('fob product', fobProduct)
      if(fobProduct){
        let product_line_3 = {
          column_1: "",
          column_2: "",
          column_3: 'FOB: '+fobProduct.fobAddress.addressLine1+( fobProduct.fobAddress?.addressLine2 ? ' '+fobProduct.fobAddress.addressLine2 :''),
          column_4: '',
          column_5: '',
        };
      
      
        productLines.push(product_line_3);
      

        let product_line_4 = {
          column_1: "",
          column_2: "",
          column_3: fobProduct.fobAddress.city+', '+fobProduct.fobAddress.state+' '+fobProduct.fobAddress.zip,
          column_4: '',
          column_5: '',
        };
      
        productLines.push(product_line_4);
      }

      if(extendedWarrantyTotal && extendedWarrantyTotal > 0){

        let extended_warranty_line = {
          column_1: "Extended Warranty",
          column_2: "",
          column_3: "Provided by: "+extendedWarrantyProviders.join(', '),
          column_4: formatter.format(extendedWarrantyTotal /  groupQty),
          column_5: formatter.format(extendedWarrantyTotal),
        };
        
        
        
        productLines.push(extended_warranty_line);

      }

      if(gapInsuranceTotal && gapInsuranceTotal > 0){

        let gap_insurance_line = {
          column_1: "GAP Waiver",
          column_2: "",
          column_3: "Provided by: "+gapInsuranceProviders.join(', '),
          column_4: formatter.format(gapInsuranceTotal / groupQty),
          column_5: formatter.format(gapInsuranceTotal),
        };
        
        
        
        productLines.push(gap_insurance_line);

      }

      // 
      // productLines.push(ApplyBlankLine());

      // Handle Elt.
      if (subtotalELT > 0) {
        // subtotal = Number(subtotal) + Number(subtotalELT);
        let product_line_FILING_FEE = {
            column_1: "Electronic Filing Service Fee",
            column_2: "",
            column_3: "",
            // column_4: formatter.format((first_product.lineItemFee && first_product.lineItemFee.etlFee ? first_product.lineItemFee.etlFee : 0)),
            column_4: formatter.format(subtotalELT / groupQty),
            column_5: formatter.format(subtotalELT),
        };
        
        productLines.push(product_line_FILING_FEE);
      };

    // if (order.customer.deliveryAddress.state === "CA" || order.customer.deliveryAddress.state === "AZ") {
    // if (location && (location.state === "CA" || location.state === "AZ")) {

    var anyTireTaxEnabled = false;
    productArray.forEach(p => {
      if(p?.lineItemFee?.enableTireTax){
        anyTireTaxEnabled = true;
      }
    })

    if(anyTireTaxEnabled === true || enableTireTax(order)){
      // Handle CA & AZ tire tax.
      if (subtotalTireTaxTotal !== 0) {
        let product_line_TIRE_TAX = {
          column_1: "Tire Tax",
          column_2: "",
          column_3: "",
          // column_4: formatter.format((first_product.lineItemFee && first_product.lineItemFee.tireTaxTotal ? first_product.lineItemFee.tireTaxTotal : 0) / groupQty),
          column_4: formatter.format(subtotalTireTaxTotal / groupQty),
          column_5: formatter.format(subtotalTireTaxTotal),
        };

        productLines.push(product_line_TIRE_TAX);
      }
    }

      // Handle Oregon CAT tax.
      if (subtotalCATTax !== 0) {
        // subtotal = Number(subtotal) + Number(subtotalCATTax);
        let product_line_CAT_TAX = {
          column_1: "Estimated OR CAT", 
          column_2: "", 
          column_3: "",
          // column_4: formatter.format((first_product.lineItemFee && first_product.lineItemFee.catTaxTotal ? first_product.lineItemFee.catTaxTotal : 0) / groupQty),
          column_4: formatter.format(subtotalCATTax / groupQty),
          column_5: formatter.format(subtotalCATTax),
        };
        
        
        productLines.push(product_line_CAT_TAX);
      };

      if (first_product && first_product.registrationAddress?.state === "OR") {
        // Handle Oregon Luxury tax.
        if (subtotalLuxuryTax !== 0) { // applyLuxuryTax is already considered in the subtotalLuxuryTax calculation.
          // subtotal = Number(subtotal) + Number(subtotalLuxuryTax);
          let product_line_LUXURY_TAX = {
            column_1: "OR Vehicle Privilege & Use Tax", 
            column_2: "", 
            column_3: "",
            // column_4: formatter.format((first_product.lineItemFee && first_product.lineItemFee.luxuryTaxTotal ? first_product.lineItemFee.luxuryTaxTotal : 0) / groupQty),
            column_4: formatter.format(subtotalLuxuryTax / groupQty),
            column_5: formatter.format(subtotalLuxuryTax),
          };

          
          productLines.push(product_line_LUXURY_TAX);
        };
      };

      // Handle License/Title Fees.
      if (subtotalTitleFee !== 0) { // applyTitleFee is already considered in the subtotalTitleFee calculation.
        // subtotal = Number(subtotal) + Number(subtotalTitleFee);
        let product_line_LICENSE_TITLE_FEES = {
          column_1: "License/Title Fees",
          column_2: "",
          column_3: "",
          // column_4: formatter.format((first_product.lineItemFee && first_product.lineItemFee.titleFee ? first_product.lineItemFee.titleFee : 0) / groupQty),
          column_4: formatter.format(subtotalTitleFee / groupQty),
          column_5: formatter.format(subtotalTitleFee),
        };

        
        productLines.push(product_line_LICENSE_TITLE_FEES);
      };

      // Handle Document Fees.
      if (subtotalDocFee !== 0) {
        // subtotal = Number(subtotal) + Number(subtotalDocFee);
        let product_line_DOC_FEE = {
          column_1: location.state === "CA" ? "CA DMV Doc Fee" : (location.state === "WA" ? "Negotiable Documentary Service Fee" : "DMV Doc Fee"),
          column_2: "",
          column_3: "",
          // column_4: formatter.format((first_product.lineItemFee && first_product.lineItemFee.docFee ? first_product.lineItemFee.docFee : 0) / groupQty),
          column_4: formatter.format(subtotalDocFee / groupQty),
          column_5: formatter.format(subtotalDocFee),
        };

        
        productLines.push(product_line_DOC_FEE);
      };

      // Handle Document Fees.
      if (subtotalBankFee !== 0) {
        // subtotal = Number(subtotal) + Number(subtotalDocFee);
        let product_line_BANK_FEE = {
          column_1: "Bank Fee",
          column_2: "",
          column_3: "",
          // column_4: formatter.format((first_product.lineItemFee && first_product.lineItemFee.BANKFee ? first_product.lineItemFee.docFee : 0) / groupQty),
          column_4: formatter.format(subtotalBankFee / groupQty),
          column_5: formatter.format(subtotalBankFee),
        };

        
        productLines.push(product_line_BANK_FEE);
      };

      // Handle Document Fees.
      if (subtotalOosDeliveryFee > 0) {
        // subtotal = Number(subtotal) + Number(subtotalOosDeliveryFee);
        let product_line_DELIVERY_FEE = {
          column_1: "Delivery Fee",
          column_2: "",
          column_3: "",
          // column_4: formatter.format((first_product.lineItemFee && first_product.lineItemFee.oosDeliveryFee ? first_product.lineItemFee.oosDeliveryFee : 0)),
          column_4: formatter.format(subtotalOosDeliveryFee / groupQty),
          column_5: formatter.format(subtotalOosDeliveryFee),
        };

        
        productLines.push(product_line_DELIVERY_FEE);
      };

      // Handle Document Fees.
      if (subtotalAdditionalTax > 0) {
        // subtotal = Number(subtotal) + Number(subtotalAdditionalTax);
        let product_line_MISC_FEE = {
          column_1: "Miscellaneous",
          column_2: "",
          column_3: "",
          // column_4: formatter.format((first_product.lineItemFee && first_product.lineItemFee.additionalTax ? first_product.lineItemFee.additionalTax : 0)),
          column_4: formatter.format(subtotalAdditionalTax / groupQty),
          column_5: formatter.format(subtotalAdditionalTax),
        };

        
        productLines.push(product_line_MISC_FEE);
      };

      
      productLines.push(ApplyBlankLine());


      return productLines;
    };

    const ApplyFirstProductShipSold = ()=>{
      // Set FOB, ShipTo, SoldTo based on first product.
      let first_product = null;
      if(selectedProducts && selectedProducts.length > 0){
        first_product = findProductFromId(selectedProducts[0].id)
      }
      if(first_product){
       
        // Set Sold To == Mailing Address.
        if(first_product.soldTo){
          documentValues['sold_to_label'] = "SOLD TO";
          documentValues['sold_to_name'] = first_product.soldTo.name;
    
          var addressLineSoldTo = 1;
          documentValues['sold_to_address'+addressLineSoldTo] = first_product.soldTo.addressLine1;
          if(first_product.soldTo.addressLine2 !== null && first_product.soldTo.addressLine2 !== undefined &&first_product.soldTo.addressLine2 !== ''){
            addressLineSoldTo++;
            documentValues['sold_to_address'+addressLineSoldTo] = first_product.soldTo.addressLine2;
          }
          addressLineSoldTo++;
          documentValues['sold_to_address'+addressLineSoldTo] = capitalizeFirstLetter(first_product.soldTo.city)+", "+first_product.soldTo.state+" "+first_product.soldTo.zip;
          if(order.customer && order.customer.phone){
            addressLineSoldTo++;      
            documentValues['sold_to_address'+addressLineSoldTo] = formatPhoneNumber(order.customer.phone);
          }
  
          addressLineSoldTo++;      
          documentValues['sold_to_address'+addressLineSoldTo] = ""
        }
  
        if(first_product.shipTo && (first_product.shipTo.name || first_product.shipTo.addressLine1)){
          documentValues['ship_to_label'] = "SHIP TO";
          documentValues['ship_to_name'] = first_product.shipTo.name;
    
          var addressLineShipTo = 1;
          documentValues['ship_to_address'+addressLineShipTo] = first_product.shipTo.addressLine1;
          if(first_product.shipTo.addressLine2 != null && first_product.shipTo.addressLine2 !== undefined &&first_product.shipTo.addressLine2 !== ''){
            addressLineShipTo++;
            documentValues['ship_to_address'+addressLineShipTo] = first_product.shipTo.addressLine2;
          }
          addressLineShipTo++;
          documentValues['ship_to_address'+addressLineShipTo] = capitalizeFirstLetter(first_product.shipTo.city)+", "+first_product.shipTo.state+" "+first_product.shipTo.zip;
        }
      }
    };

    generateSinglePageInvoice();

    ApplyFirstProductShipSold();

    let subtotalLines = [];


    let subtotalSalesTaxTotal = calculateInvoiceSalesTaxTotalSubtotal(selectedProducts, order);
    let subtotalNonVehicleSalesTaxTotal = calculateInvoiceNonVehicleSalesTaxTotalSubtotal(selectedProducts, order);

    // const ApplyBlankLine = () => {
    //   return {
    //     column_1: "",
    //     column_2: "",
    //     column_3: "",
    //     column_4: "",
    //     column_5: "",
    //   };
    // }

    // Handle Sales Tax isExemptSalesTax === true.
    if (order.customer.isExemptSalesTax === true) { // TODO: this might not need to choose by state, isExemptSalesTax === true might be enough
      let product_line_SALES_TAX_1 = {
        column_1: "Sales Tax", 
        column_2: "", 
        column_3: location?.state === "CA" ? "Exempt from CA Sales Tax" : "Exempt from Sales Tax", 
        column_4: "", 
        column_5: "",
      };
  
      
      subtotalLines.push(product_line_SALES_TAX_1);
    };

    // Handle all other cases for Sales Tax
    if (order?.customer?.isExemptSalesTax === false && order.customer.deliveryAddress.state !== "WA" && subtotalSalesTaxTotal > 0) {
      subtotal = Number(subtotal) + Number(subtotalSalesTaxTotal);
      let product_line_SALES_TAX_1 = {
        column_1: "Sales Tax", 
        column_2: order.customer && !order.customer.isExemptSalesTax && order.customer.vehicleSalesTaxPercent && order.customer.vehicleTaxCounty ? (order.customer.vehicleSalesTaxPercent+"% - "+order.customer.vehicleTaxCounty ) : "",
        column_3: "", 
        column_4: showSalesTaxPerUnitPrice ? formatter.format(subtotalSalesTaxTotal / selectedProducts.length) : "",
        column_5: formatter.format(subtotalSalesTaxTotal),
      };

      
      subtotalLines.push(product_line_SALES_TAX_1);

    };

    // Handle all other cases for Sales Tax
    if (order?.customer?.isExemptSalesTax === false && order.customer.deliveryAddress.state === "WA" && subtotalSalesTaxTotal > 0) {
      subtotal = Number(subtotal) + Number(subtotalSalesTaxTotal);
      let product_line_SALES_TAX_WA_1 = {
        column_1: "State and Local Sales Tax "+(order.customer && !order.customer.isExemptSalesTax && order.customer.vehicleSalesTaxPercent ? (order.customer.vehicleSalesTaxPercent+"% "+(order.customer.vehicleTaxCounty ?  "- "+order.customer.vehicleTaxCounty : "") ) : ""), 
        column_2: "", 
        column_3: "",
        column_4: showSalesTaxPerUnitPrice ? formatter.format(subtotalSalesTaxTotal / selectedProducts.length) : "",
        column_5: formatter.format(subtotalSalesTaxTotal),
      };

      
      subtotalLines.push(product_line_SALES_TAX_WA_1);

    }

    if (order.customer && order.customer.deliveryAddress.state === "WA" && (order.customer.isExemptSalesTax === false || order.customer.isExemptSalesTax === null) && subtotalNonVehicleSalesTaxTotal > 0) {
      subtotal = Number(subtotal) + Number(subtotalNonVehicleSalesTaxTotal);
      let product_line_SALES_TAX_WA_2 = {
        column_1: "Non-Vehicle Sales State and Local Sales Tax "+(order.customer && !order.customer.isExemptSalesTax && order.customer.nonVehicleSalesTaxPercent ? (order.customer.nonVehicleSalesTaxPercent+"% "+(order.customer.nonVehicleTaxCounty ? "- "+order.customer.nonVehicleTaxCounty : "") ) : ""), 
        column_2: "", 
        column_3: "",
        column_4: showSalesTaxPerUnitPrice ? formatter.format(subtotalNonVehicleSalesTaxTotal / selectedProducts.length) : "",
        column_5: formatter.format(subtotalNonVehicleSalesTaxTotal),
      };

      
      subtotalLines.push(product_line_SALES_TAX_WA_2);

    };


    let first_product = null;
    if(selectedProducts && selectedProducts.length > 0){
      first_product = findProductFromId(selectedProducts[0].id)
    }

    
    // Handle Document Fees.
    if (order.adminFee && order.adminFee > 0) {
      let product_line_ADMIN_FEE = {
        column_1: "Administration Fee",
        column_2: "",
        column_3: "",
        column_4: "",
        column_5: formatter.format(order.adminFee),
      };

      
      subtotalLines.push(product_line_ADMIN_FEE);
    };

    //Calculate Subtotal
    var adminFee = order.adminFee && order.adminFee > 0 ? order.adminFee : 0;
    subtotal = Number(subtotal) + Number(adminFee);

      // Add extendeded warranty and gap insurance subtotals.
      selectedProducts.forEach((selectedProduct, i) => {
        let foundProduct = findProductFromId(selectedProduct.id)
        if(foundProduct && foundProduct.extendedWarranty){
          subtotal = Number(subtotal) + Number(foundProduct.extendedWarranty.price);
        }
        if(foundProduct && foundProduct.gapInsurance && foundProduct.gapInsurance.amount){
          subtotal = Number(subtotal) + Number(foundProduct.gapInsurance.amount);
        }
      });


    
    subtotalLines.push(ApplyBlankLine());

    let product_line_SUBTOTAL = {
      column_1: "Subtotal",
      column_2: "",
      column_3: "",
      column_4: "",
      column_5: formatter.format(subtotal),
    };

    
    subtotalLines.push(product_line_SUBTOTAL);

    
    // Handle Down Payments.
    if (subtotalDownpayment !== 0 && order && order.downPayments && order.downPayments.length > 0) {

        // 
        let downpaymentsRecievedArray = order.downPayments.filter(item => item.status.toUpperCase() === 'RECEIVED');
        let downpaymentsDueAtDeliveryArray = order.downPayments.filter(item => item.status.toUpperCase() === 'DUE AT DELIVERY');

        // New Print
        downpaymentsRecievedArray.forEach(downpaymentsRecieved => {
          subtotalLines.push(ApplyBlankLine());

          let downPaymentLine3 = '';
            if(downpaymentsRecieved.status){
              downPaymentLine3 += capitalizeFirstLetter(downpaymentsRecieved.status.toLowerCase());
            }
            
            if(downpaymentsRecieved.paymentMethod){
              downPaymentLine3 += " "+downpaymentsRecieved.paymentMethod.name.toLowerCase();
            }
            
            if(downpaymentsRecieved.documentNumber){
              downPaymentLine3 += " number: "+downpaymentsRecieved.documentNumber;
            }
            
            let downPaymentLine3Column4 = '';
            if(downpaymentsRecieved.dateReceived){
              downPaymentLine3Column4 += " - Date Received: "+moment.utc(downpaymentsRecieved.dateReceived).format('MM/DD/YYYY');
            }
            
            let product_line_DOWNPAYMENT_Received = {
              column_1: "Down Payment Received", 
              column_2: "",
              column_3: downPaymentLine3, 
              column_4: downPaymentLine3Column4, 
              column_5: `(${formatter.format(downpaymentsRecieved.amount)})`,
            };
            
            
            subtotalLines.push(product_line_DOWNPAYMENT_Received);

        });
        
        // New Print
        downpaymentsDueAtDeliveryArray.forEach(downpaymentDue => {
          subtotalLines.push(ApplyBlankLine());

          let downPaymentLine3 = '';
            if(downpaymentDue.status){
              downPaymentLine3 += capitalizeFirstLetter(downpaymentDue.status.toLowerCase());
            }
            
            if(downpaymentDue.paymentMethod){
              downPaymentLine3 += " "+downpaymentDue.paymentMethod.name.toLowerCase();
            }
            
            if(downpaymentDue.documentNumber){
              downPaymentLine3 += " number: "+downpaymentDue.documentNumber;
            }
            
            let product_line_DOWNPAYMENT_Due= {
              column_1: "Down Payment Due at Delivery", 
              column_2: "",
              column_3: downPaymentLine3, 
              column_4: "", 
              column_5: `(${formatter.format(downpaymentDue.amount)})`,
            };
            
            
            subtotalLines.push(product_line_DOWNPAYMENT_Due);

        });
    }

    // Handle Deposits.
    if(selectedProducts && selectedProducts.length > 0){
      // 
      subtotalLines.push(ApplyBlankLine());
    }

    selectedProducts.forEach((selectedProduct, i) => {
      let foundProduct = findProductFromId(selectedProduct.id)
      if(foundProduct && foundProduct.deposits && foundProduct.deposits.length > 0){
        foundProduct.deposits.forEach((deposit, index) => {
          if (deposit && deposit.appliedAmount) {

            let line3 = "";
            if(deposit.paymentMethodObj){
              if(deposit.paymentMethodObj.name.toLowerCase() === 'wire'){
                line3 = "Received Wire: " + deposit.documentNumber;
              }else{
                line3 = "Received " + deposit.paymentMethodObj.name.toLowerCase() + " number: " + deposit.documentNumber;
              }
            }


            let product_line_DEPOSIT = {
              column_1: "Deposit", 
              column_2: "",
              column_3: line3, 
              column_4: "",
              column_5: `(${formatter.format(foundProduct.deposits[index].appliedAmount)})`,
            };

            
            subtotalLines.push(product_line_DEPOSIT);
          }
        });
      }
    });

    let totalNetTradeAllowance = calculateTotalTradeCreditTotal(order.tradeins);
    let tradeInCredit = "";
    if(totalNetTradeAllowance < 0){
      tradeInCredit = formatter.format(Math.abs(totalNetTradeAllowance));
    }else{
      tradeInCredit = "("+formatter.format(totalNetTradeAllowance)+")";
    }

    // Handle Trade Ins
    if(order.tradeins && order.tradeins.length === 1) {
        order.tradeins.forEach((tradein, i) => {
          
          subtotalLines.push(ApplyBlankLine());

          let ymm = tradein.year+" "+tradein.make.toString().toUpperCase()+" "+tradein.model.toString().toUpperCase();
          
          
          // let totalNetTradeAllowance = calculateTotalTradeCredit(tradein);
          // let tradeInCredit = "";
          // if(totalNetTradeAllowance < 0){
          //   tradeInCredit = formatter.format(Math.abs(totalNetTradeAllowance));
          // }else{
          //   tradeInCredit = "("+formatter.format(totalNetTradeAllowance)+")";
          // }
          let product_line_TRADE_LINE_1 = {
            column_1: "Trade In", 
            column_2: "",
            column_3: ymm, 
            column_4: "Trade In Value: "+formatter.format(tradein.tradeAllowanceAtTerms),
            column_5: `${tradeInCredit}`,
          };
    
          
          subtotalLines.push(product_line_TRADE_LINE_1);

          let product_line_TRADE_LINE_2 = {
            column_1: "", 
            column_2: "", 
            column_3: tradein.vin ? "VIN #"+tradein.vin.toString().toUpperCase() : "",
            column_4: tradein.cashRefundToCustomer ? "Cash Refund: "+formatter.format(tradein.cashRefundToCustomer) : "Cash Refund: "+formatter.format(0),
            column_5: "",
          };
    
          
          subtotalLines.push(product_line_TRADE_LINE_2);


          let product_line_TRADE_LINE_3 = {
            column_1: "", 
            column_2: "", 
            column_3: tradein.balanceOwedTo ? "Owed To: "+tradein.balanceOwedTo : "",
            column_4: tradein.balanceOwed && tradein.balanceOwed > 0 ? "Payoff Value: "+formatter.format(tradein.balanceOwed) : "",
            column_5: "",
          };
    
          
          subtotalLines.push(product_line_TRADE_LINE_3);

        });
    }else if(order.tradeins && order.tradeins.length > 1){

      let product_line_TRADE_LINE_MULTI = {
        column_1: "", 
        column_2: "", 
        column_3: "See Trade Schedule",
        column_4: "",
        column_5: `${tradeInCredit}`,
      };

      // 
      subtotalLines.push(product_line_TRADE_LINE_MULTI);
    }

    // Add Lien Holder from First Product and Notes.
    if (first_product && first_product.fundingDetail && first_product.fundingDetail.fundingMethod && first_product.fundingDetail.fundingMethod === "cash-with-lien" && first_product.fundingDetail.cashLienHolder && first_product.fundingDetail.cashLienHolder.bankName) { 
      documentValues[`lien_holder`] = "Lienholder: "+first_product.fundingDetail.cashLienHolder.bankName;
    }else if (first_product && first_product.fundingDetail && first_product.fundingDetail.fundingMethod && (first_product.fundingDetail.fundingMethod === "tec-financing" || first_product.fundingDetail.fundingMethod === "finance-only" || first_product.fundingDetail.fundingMethod === "direct-deal") && first_product.fundingDetail.finalizedLendingOption && first_product.fundingDetail.finalizedLendingOption.bankName) { 
      documentValues[`lien_holder`] = "Lienholder: "+first_product.fundingDetail.finalizedLendingOption.bankName;
    };

    var finalNotes = '';
    var hasNotes = false;
    if (first_product && first_product.lineItemFee) {
      
      var notes = "";

      if(first_product.lineItemFee.description && first_product.lineItemFee.description !== ""){
        notes = notes+first_product.lineItemFee.description+"\n\n";
        hasNotes = true;
      }
      
      if(hasNotes){
        finalNotes = notes;
        hasNotes = true;
      }

      if(first_product.lineItemFee.applyFet === false && first_product.lineItemFee.fetExemptReason && first_product.type.toString().toLowerCase() === "new"){
        finalNotes = finalNotes+"*FET Exemption: "+first_product.lineItemFee.fetExemptReason+"\n\n";
        hasNotes = true;
      }
      
      if(first_product.extendedWarranty && first_product.extendedWarranty.description){
        finalNotes = finalNotes+"Warranty: "+first_product.extendedWarranty.description+"\n\n";
        hasNotes = true;
      }
      
      if(hasNotes){
        documentValues[`notes`] = "NOTES: \n\n"+finalNotes;
      }

    };

    const taxExemptReasons = JSON.parse(localStorage.getItem("taxExemptReasons"));

    if(order && order.customer && order.customer.isExemptSalesTax === true && order.customer.salesTaxExemptionReasonId && taxExemptReasons && taxExemptReasons.length > 0){
      let salesTaxExemptionReason = taxExemptReasons.find(item => item.id === order.customer.salesTaxExemptionReasonId);
      if(salesTaxExemptionReason && salesTaxExemptionReason.description){
        // exemptionValidationReason = salesTaxExemptionReason.description;
        finalNotes = finalNotes+"Exemption Validation: "+salesTaxExemptionReason.description+"\n\n";
        hasNotes = true;

        if(order.customer.sterResaleNumber && order.customer.sterResaleNumber !== ''){
          finalNotes = finalNotes+"Resale Number: "+order.customer.sterResaleNumber+"\n\n";
        }

        if(order.customer.sterDealerNumber && order.customer.sterDealerNumber !== ''){
          finalNotes = finalNotes+"Dealer Number: "+order.customer.sterDealerNumber+"\n\n";
        }

        if(order.customer.sterMcNumber && order.customer.sterMcNumber !== ''){
          finalNotes = finalNotes+"MC Number: "+order.customer.sterMcNumber+"\n\n";
        }

        if(order.customer.sterDotNumber && order.customer.sterDotNumber !== ''){
          finalNotes = finalNotes+"DOT Number: "+order.customer.sterDotNumber+"\n\n";
        }
      }
    }
    
    if(hasNotes){
      documentValues[`notes`] = "NOTES: \n\n"+finalNotes;
    }

    // Calculate Deposit Total
    let depositAppliedAmountSum = 0;
    selectedProducts.forEach((selectedProduct, i) => {
      let foundProduct = findProductFromId(selectedProduct.id)
      if(foundProduct && foundProduct.deposits && foundProduct.deposits.length > 0){
        foundProduct.deposits.forEach(deposit => {
          depositAppliedAmountSum += (Number(deposit.appliedAmount) ?? 0);
        });
      }
    });
    
    let totalNetTradeAllowanceTotal = calculateTotalTradeCreditTotal(order.tradeins);
    
    // Calculate Balance Due
    let balanceDue = Number(subtotal) - Number(subtotalDownpayment) - Number(depositAppliedAmountSum) - Number(totalNetTradeAllowanceTotal);
    documentValues.balance_due = formatter.format(balanceDue);
    
    let maxLines = 25;
    if(grouping === 'all'){
      GroupAllProducts();
    }
    ApplyPreparedProductGroups(maxLines, preparedProductGroups);
    lastProductLine = ApplyLines(lastProductLine, subtotalLines);

    return documentValues;
    // previewPage(id, documentValues);
}

export {
  productGrouper,
  maxLinesCalculator,
  calculateInvoicePriceSubtotal,
  calculateInvoiceLineItemFeeAmountSubtotal,
  calculateInvoiceCATTaxSubtotal,
  calculateInvoiceLuxuryTaxSubtotal,
  calculateInvoiceRegistrationFeeSubtotal,
  calculateInvoiceTitleFeeSubtotal,
  calculateInvoiceEltTotal,
  calculateInvoiceTotalTireCreditSubtotal,
  calculateInvoiceFETSubtotal,
  calculateInvoiceTireTaxTotalSubtotal,
  calculateInvoiceSalesTaxTotalSubtotal,
  calculateInvoiceCADMVDocFeeSubtotal,
  calculateInvoiceDocFeeSubtotal,
  calculateInvoiceOosDeliveryFeeSubtotal,
  calculateInvoiceAdditionalTaxSubtotal,
  calculateInvoiceNonVehicleSalesTaxTotalSubtotal,
  calculateTotalTradeCredit,
  calculateInvoiceBankFeeSubtotal,
  generateInvoice
}
import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
// import moment from 'moment'
import * as MaterialComponents from '@mui/material';
import { useOrder, rdx } from "../../../../../../redux/orders/order"
import { Checkbox } from "@material-ui/core";
import { useFetch,recordFrontEndError } from "../../../../../../utlis/api";
// import {
//   calculateTotalDownpayment,
//   calculateTotalTradeCredit as calculateTotalTradeCreditTotal
// } from "../../../../../../utlis/orderCalculations";
import {
  generateInvoice as generateInoiceLibraryFunction,
} from "../../../../../../utlis/invoiceCalculations";
// import { formatter, formatPhoneNumber, capitalizeFirstLetter, splitString } from "../../../../../../utlis/formatters";
import { setShowInvoiceSpinner } from "../../../../../../redux/spinners/spinners";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
// import { addConsoleHandler } from "selenium-webdriver/lib/logging";
import * as api from "../../../../../../utlis/api"


const DocumentInvoiceModal = ({ initializeDocumentModal, setInitializeDocumentModal, document_id, location, getDocuments, setDocumentFocus, passedDocumentSlug,taxExemptReasons }) => {
  let { id }        = useParams();
  const order       = useOrder();
  const axios       = useFetch();
  const dispatch = useDispatch()
  const {showInvoiceSpinner} = useSelector((state)=>state.spinners)
  const salesReps = useSalesReps();

  var initialDocumentValues = {};

  const [ document, setDocument ]                               = useState(undefined);
  const [ documentValues, setDocumentValues ]                   = useState(initialDocumentValues);
  const [ documentUrl, setdocumentUrl ]                         = useState("");
  const [ dataLoaded, setDataLoaded ]                           = useState(false);
  const [ selectedProducts, setSelectedProducts ]               = useState([]);
  const [ previewChanges, setPreviewChanges ]                   = useState(false);
  const [ unsavedChanges, setUnsavedChanges ]                   = useState(false);
  const [ invoiceProductOptions, setInvoiceProductOptions ]     = useState([])
  const [saveSuccess, setSaveSuccess]                           = useState(false);
  const [certificateInitalized, setCertificateInitalized]       = useState(false);
  // const [maxPages, setMaxPages]                                 = useState(1);
  // const [invoiceLineCount, setInvoiceLineCount]                 = useState(0);

  const [groupingType, setGroupingType]                         = useState('none');
  const [certificateLineToggle, setCertificateLineToggle]       = useState(false);
  const [showSalesTaxPerUnitPrice, setShowSalesTaxPerUnitPrice] = useState(true);

  // Find Product by Id
  // const findProductFromId=useCallback((id)=>{
  //   let foundProduct = (order.products.filter(item => {
  //     return item.id === id
  //   }));
  //   if(foundProduct && foundProduct.length === 1) {
  //     foundProduct = foundProduct[0];
  //   };
  //   return foundProduct;
  // },[ order ]);

  // Load a preview of the last saved version of a document.
  const streamDocument=useCallback( async ()=>{
    try{
    let response = await axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
          const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
        setdocumentUrl(fileURL)
    } catch (err) {
      recordFrontEndError('DocumentInvoiceModal.jsx' , 'Error streaming document: ' + err)
    }
  },[document,setdocumentUrl,axios])
  
  // Load in the document object.
  const loadDocument=useCallback( async (documentId)=>{ 
    dispatch(setShowInvoiceSpinner(true))
    if(documentId && documentId != null && documentId !== undefined){
      try{
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug){
          setDocument(res?.data)
          setInvoiceProductOptions([]);
        }
      } catch (err) {
        recordFrontEndError('DocumentInvoiceModal.jsx', 'Error loading document: ' + err )
      }
    }
  },[id,axios,setDocument, dispatch])

  // Load in the data payload on a saved document.
  const loadData=useCallback(()=>{
    var existingPayload = false;
    if(document.payload){
      var documentPayload = JSON.parse(document.payload);
      if(documentPayload && Object.keys(documentPayload).length > 0){
        existingPayload = true;
        setDocumentValues(documentPayload);
        if(documentPayload[`showSalesTaxPerUnitPrice`] !== undefined){
          setShowSalesTaxPerUnitPrice((documentPayload[`showSalesTaxPerUnitPrice`] === "true" ? true : false));
        }
      }
    }
    
    if(existingPayload){
      if(document && document.itemIds){
        var itemIds = document.itemIds.split(',');
        var sproducts = [];
        itemIds.forEach(itemId => {
          var foundProduct = order.products.find((product) => product.id === Number(itemId));
          if(foundProduct && foundProduct.stockNumber){
            let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
            let obj = {id: foundProduct.id, name: str}
            sproducts.push(obj);
          }
        })
        setSelectedProducts(sproducts);

        streamDocument();
      }
     
    }

    // Load in form controls
    if(document && document.additionalData){
      let {invoiceGroupingType, invoiceCertificateLineToggle, invoiceShowSalesTaxPerUnitPrice} = JSON.parse(document.additionalData);
      if(invoiceGroupingType){
        setGroupingType(invoiceGroupingType);
      }

      if(invoiceCertificateLineToggle !== null && invoiceCertificateLineToggle !== undefined){
        setCertificateLineToggle(invoiceCertificateLineToggle);
      }

      if(invoiceShowSalesTaxPerUnitPrice !== null && invoiceShowSalesTaxPerUnitPrice !== undefined){
        setShowSalesTaxPerUnitPrice(invoiceShowSalesTaxPerUnitPrice);
      }
    }
    
    setDataLoaded(true);
  },[document, order,streamDocument])

  // Initalize Document
  useEffect(() => {

    if(document_id && passedDocumentSlug && (passedDocumentSlug === "tec-invoice-template" || passedDocumentSlug === "tec-invoice-template-v2") && ( !document || document === undefined || (document && document.id !== document_id))){
      loadDocument(document_id);
    }else if(passedDocumentSlug && passedDocumentSlug && initializeDocumentModal){
      setdocumentUrl("");
      setInvoiceProductOptions([]);
      setInitializeDocumentModal(false);
      var sproducts = [];
      order?.products?.forEach(product => {
        let str = `${product.stockNumber.toString().toUpperCase()}`;
        let obj = {id: product.id, name: str}
        sproducts.push(obj);
      });
      setSelectedProducts(sproducts);
    }

    if(!certificateInitalized){
      if(order && order.customer && order.customer.iccNumber && order.customer.iccNumber !== ""){
        setCertificateLineToggle(true);
        setCertificateInitalized(true);
      }

      if(order && order.customer && order.customer.salesTaxExemptionReasonId){
        setCertificateLineToggle(true);
        setCertificateInitalized(true);
      }
    }

  }, [document_id, certificateInitalized, order, loadDocument, document, passedDocumentSlug, initializeDocumentModal, setInitializeDocumentModal, invoiceProductOptions])

  // Initalize Data
  useEffect(() => {
    if ( document && !dataLoaded ){
      loadData();
    }
  }, [ document, loadData, order, dataLoaded ])

  const previewPage=useCallback(async (id, filteredValues)=>{
    try {
      let response = await axios.post(`/order/${id}/preview-invoice`,
        filteredValues,
        { responseType: 'blob' }
        )
      if (response && response.data) {
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'},
          );
          const fileURL = URL.createObjectURL(file);
          setdocumentUrl(fileURL);
          setPreviewChanges(false);
          dispatch(setShowInvoiceSpinner(false));
          setUnsavedChanges(true);
        }
      } catch (err) {
        recordFrontEndError('DocumentInvoiceModal.jsx', 'Error previewing page: ' + err)
      }
    ;
  },[axios, dispatch]);

  function formattedOptions(){
    return [{id:'selectAll', name:'Select All', options: invoiceProductOptions}, ...invoiceProductOptions]
  }

  // const [orderLocation, setOrderLocation] = useState(undefined);

  // useEffect(() => {
  //   if(orderLocation === undefined && order && order.tecLocationCode){
  //     let foundLocation = locations.find(l => l.locationCode === order.tecLocationCode);
  //     if(foundLocation){
  //       setOrderLocation(foundLocation);
  //     }
  //   }
  // }, [product]);

  // const ApplyLine=useCallback((lineNumber, lineObject)=>{
  //   documentValues[`order_summary_line_${lineNumber}_qty`] = lineObject.column_1;
  //   documentValues[`order_summary_line_${lineNumber}_stock_no`] = lineObject.column_2;
  //   documentValues[`order_summary_line_${lineNumber}_desc`] = lineObject.column_3;
  //   documentValues[`order_summary_line_${lineNumber}_unit_price`] = lineObject.column_4;
  //   documentValues[`order_summary_line_${lineNumber}_total_price`] = lineObject.column_5;
  
  // },[ documentValues ]);

  // const ApplyLines=useCallback((startingLineNumber=1, lineArray)=>{
  //   let lineNumber = startingLineNumber;
  //   if(lineArray && lineArray.length > 0){
  //     for(var l = 0; l < lineArray.length && lineNumber <= 25; l++){
  //       ApplyLine(lineNumber, lineArray[l]);
  //       lineNumber++;
  //     }
  //   }
  //   return lineNumber;
  // },[ ApplyLine ]);

  // Applying location data points to document values.
  // const ApplyLocation=useCallback(()=>{

  //   if(location && location.address){
  //     documentValues['location_address'] = location.address+" "+location.city+" "+location.state+" "+location.zip;
  //   }
      
  //   if(location && location.phone){
  //     documentValues['location_phone'] = location.phone;
  //   }

  //   if(location && location.email){
  //     documentValues['location_email'] = location.email;
  //   }

  //   if(location && location.state){
  //     if(location.state === "CA"){
  //         documentValues['tec_footer_label'] = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
  //     }else{
  //         documentValues['tec_footer_label'] = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC";
  //     }
  //   }
  // },[ location, documentValues ]);

  // const ApplyHeaderData=useCallback(()=>{

  //   function getRepByEmployeeId(employeeId){
  //     if(salesReps && salesReps.length > 0){
  //       let foundRep = salesReps.find(rep => rep.employee_ID === employeeId);
  //       if(foundRep){
  //         return foundRep.preferredName ? foundRep.preferredName+" "+foundRep.lastName : foundRep.firstName+" "+foundRep.lastName;
  //       }
  //     }
  //     return "";
  //   }

  //   // if(documentValues.invoice_date === undefined){
  //   //   documentValues['invoice_date'] =  moment.utc().format("MM/DD/YYYY");
  //   // }
  //   if(order){
  //     if(order && order.salesRepId && documentValues.salesperson === undefined){
  //       documentValues['salesperson'] = getRepByEmployeeId(order.salesRepId);
  //     }
      
  //     if(order && order.documentationDate && documentValues.documentation_date === undefined){
  //       documentValues['documentation_date'] = moment.utc(order.documentationDate).format("MM/DD/YYYY");
  //     }

  //     if(order && order.dateFinalized && documentValues.date_finalized === undefined){
  //       documentValues['date_finalized'] = moment.utc(order.dateFinalized).format("MM/DD/YYYY");
  //     }

  //     if(order && order.customer && order.customer.email && documentValues.email === undefined){
  //       documentValues['email'] = order.customer.email;
  //     }

  //     if(order.customer){
  //       documentValues['sold_to_label'] = "SOLD TO";

  //       var line = 1;
  //       if(order.customer.mailingAddress){
  //         documentValues['ship_to_label'] = "MAILING ADDRESS";
  //         line = 1;
  //         if(order.customer.mailingAddress && documentValues.ship_to_name === undefined){
  //           documentValues['ship_to_name'] = order.customer.name;
            
  //           documentValues['ship_to_address'+line] = order.customer.mailingAddress.addressLine1;
  //           if(order.customer.mailingAddress.addressLine2 != null && order.customer.mailingAddress.addressLine2 !== undefined &&order.customer.mailingAddress.addressLine2 !== ''){
  //             line++;
  //             documentValues['ship_to_address'+line] = order.customer.mailingAddress.addressLine2;
  //           }
  //           line++;
  //           documentValues['ship_to_address'+line] = capitalizeFirstLetter(order.customer.mailingAddress.city)+", "+order.customer.mailingAddress.state+" "+order.customer.mailingAddress.zip;
  //           if(order.customer && order.customer.phone){
  //             line++;      
  //             documentValues['ship_to_address'+line] = formatPhoneNumber(order.customer.phone);
  //           }
  //         }

  //         if(order.customer.deliveryAddress && documentValues.sold_to_name === undefined){
  //           documentValues['sold_to_name'] = order.customer.name;
  //           if(order.customer.dba){
  //             documentValues['sold_to_name'] = order.customer.name+" dba "+order.customer.dba;
  //           }
            
  //           line = 1
  //           if(order.customer.cosigners && order.customer.cosigners.length > 0){
  //             let cosigner = order.customer.cosigners[0];
  //             documentValues['sold_to_address'+line] = cosigner.name;
  //             line++;
  //           }

  //           documentValues['sold_to_address'+line] = order.customer.deliveryAddress.addressLine1;
  //           if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined &&order.customer.deliveryAddress.addressLine2 !== ''){
  //             line++;
  //             documentValues['sold_to_address'+line] = order.customer.deliveryAddress.addressLine2;
  //           }
  //           line++;
  //           documentValues['sold_to_address'+line] = capitalizeFirstLetter(order.customer.deliveryAddress.city)+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
  //         }
  //       }else{
  //         // Set Sold To == Delivery Address.
  //         if(order.customer.deliveryAddress && documentValues.sold_to_name === undefined){
  //           documentValues['sold_to_name'] = order.customer.name;
  //           if(order.customer.dba){
  //             documentValues['sold_to_name'] = order.customer.name+" dba "+order.customer.dba;
  //           }

  //           var addressline = 1;
  //           if(order.customer.cosigners && order.customer.cosigners.length > 0){
  //             let cosigner = order.customer.cosigners[0];
  //             documentValues['sold_to_address'+addressline] = cosigner.name;
  //           }

  //           addressline++;
  //           documentValues['sold_to_address'+addressline] = order.customer.deliveryAddress.addressLine1;
  //           if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined &&order.customer.deliveryAddress.addressLine2 !== ''){
  //             addressline++;
  //             documentValues['sold_to_address'+addressline] = order.customer.deliveryAddress.addressLine2;
  //           }
  //           addressline++;
  //           documentValues['sold_to_address'+addressline] = capitalizeFirstLetter(order.customer.deliveryAddress.city)+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
  //           if(order.customer && order.customer.phone){
  //             addressline++;      
  //             documentValues['sold_to_address'+addressline] = formatPhoneNumber(order.customer.phone);
  //           }
            
  //         }

  //       }
  //     }
  //   }
  // },[ order, documentValues, salesReps ]);

  // const ApplyFirstProductShipSold=useCallback(()=>{
  //   // Set FOB, ShipTo, SoldTo based on first product.
  //   let first_product = null;
  //   if(selectedProducts && selectedProducts.length > 0){
  //     first_product = findProductFromId(selectedProducts[0].id)
  //   }
  //   if(first_product){
  //     // if(first_product?.fobAddress?.addressLine1){
  //     //   documentValues['fob'] = first_product?.fobAddress?.addressLine1;
  //     //   if(first_product.fobAddress.addressLine2 !== null && first_product.fobAddress.addressLine2 !== undefined &&first_product.fobAddress.addressLine2 !== ''){
  //     //     documentValues['fob_2'] = first_product?.fobAddress?.addressLine2;
  //     //     documentValues['fob_3'] = capitalizeFirstLetter(first_product?.fobAddress.city)+", "+first_product?.fobAddress.state+" "+first_product?.fobAddress.zip;
  //     //   }else{
  //     //     documentValues['fob_2'] = capitalizeFirstLetter(first_product?.fobAddress.city)+", "+first_product?.fobAddress.state+" "+first_product?.fobAddress.zip;
  //     //   }
  //     // }else{
  //     //   if(first_product.fob && documentValues.fob === undefined){
  //     //     documentValues['fob'] = (first_product?.fob ?? "");
  //     //   }
  
  //     //   if(first_product.fobZip && documentValues.fob_2 === undefined){
  //     //     documentValues['fob_2'] = (first_product?.fobZip ?? "");
  //     //   }
  //     // }

      
  //     // Set Sold To == Mailing Address.
  //     if(first_product.soldTo){
  //       documentValues['sold_to_label'] = "SOLD TO";
  //       documentValues['sold_to_name'] = first_product.soldTo.name;
  
  //       var addressLineSoldTo = 1;
  //       documentValues['sold_to_address'+addressLineSoldTo] = first_product.soldTo.addressLine1;
  //       if(first_product.soldTo.addressLine2 !== null && first_product.soldTo.addressLine2 !== undefined &&first_product.soldTo.addressLine2 !== ''){
  //         addressLineSoldTo++;
  //         documentValues['sold_to_address'+addressLineSoldTo] = first_product.soldTo.addressLine2;
  //       }
  //       addressLineSoldTo++;
  //       documentValues['sold_to_address'+addressLineSoldTo] = capitalizeFirstLetter(first_product.soldTo.city)+", "+first_product.soldTo.state+" "+first_product.soldTo.zip;
  //       if(order.customer && order.customer.phone){
  //         addressLineSoldTo++;      
  //         documentValues['sold_to_address'+addressLineSoldTo] = formatPhoneNumber(order.customer.phone);
  //       }

  //       addressLineSoldTo++;      
  //       documentValues['sold_to_address'+addressLineSoldTo] = ""
  //     }

  //     if(first_product.shipTo && (first_product.shipTo.name || first_product.shipTo.addressLine1)){
  //       documentValues['ship_to_label'] = "SHIP TO";
  //       documentValues['ship_to_name'] = first_product.shipTo.name;
  
  //       var addressLineShipTo = 1;
  //       documentValues['ship_to_address'+addressLineShipTo] = first_product.shipTo.addressLine1;
  //       if(first_product.shipTo.addressLine2 != null && first_product.shipTo.addressLine2 !== undefined &&first_product.shipTo.addressLine2 !== ''){
  //         addressLineShipTo++;
  //         documentValues['ship_to_address'+addressLineShipTo] = first_product.shipTo.addressLine2;
  //       }
  //       addressLineShipTo++;
  //       documentValues['ship_to_address'+addressLineShipTo] = capitalizeFirstLetter(first_product.shipTo.city)+", "+first_product.shipTo.state+" "+first_product.shipTo.zip;
  //     }
  //   }
  // },[ order, documentValues, findProductFromId, selectedProducts ]);

  // const ApplyBlankLine = () => {
  //   return {
  //     column_1: "",
  //     column_2: "",
  //     column_3: "",
  //     column_4: "",
  //     column_5: "",
  //   };
  // }

  // const MatchAndGroup = useCallback(() => {
  //     let productArray = [];

  //     // Loop products and build matchingKey and assign it to each product.
  //     selectedProducts.forEach((product) => {

  //       // Pull full product object;
  //       let foundProduct = order.products.find((item) => item.id === Number(product.id))
  //       if(foundProduct){

  //         let sortedFobValues=[]
  //         let sortedWarrantyValues=[]
  //         let sortedGapInsuranceValues=[]
  //         let sortedLineItemFeeValues=[]
  //         let sortedFundingDetailValues=[]
  //         let sortedCashLienHolderValues=[]
  //         let sortedFinalizedLendingOptionValues=[]
  //         let sortedAdditionsValues=[]
  //         let sortedUpgradesValues=[]

  //         // Pull shallow level product properties we want to match on.
  //         let {fobAddress,make,model,price,stateRegistered,type,vehicleType,year, extendedWarranty,gapInsurance, lineItemFee, fundingDetail,upgrades, additions } = foundProduct;
          
  //         if(fundingDetail && fundingDetail.fundingMethod === 'cash-with-lien'){
  //           let cashLienHolder=(fundingDetail?{...fundingDetail.cashLienHolder}:{})
  //           Object.keys(cashLienHolder).reduce((result,key)=>{
  //             result[key]=cashLienHolder[key]
  //             if(key!=='productId'&& key!=='id' && key!=='createdAt' && key!=='fundingDetailId'){
  //               sortedCashLienHolderValues.push(cashLienHolder[key])}
  //             return result
  //           },{})
  //         }
          

  //         if(fundingDetail && (fundingDetail.fundingMethod === 'tec-financing' || fundingDetail.fundingMethod === 'finance-only' || fundingDetail.fundingMethod === 'direct-deal')){
  //           let finalizedLendingOption=(fundingDetail?{...fundingDetail.finalizedLendingOption}:{})
  //           Object.keys(finalizedLendingOption).reduce((result,key)=>{
  //             result[key]=finalizedLendingOption[key]
  //             if(key!=='productId'&& key!=='id' && key!=='createdAt' && key!=='fundingDetailId'){
  //               sortedFinalizedLendingOptionValues.push(finalizedLendingOption[key])}
  //             return result
  //           },{})
  //         }
          

  //         if(fobAddress===null) fobAddress={};
  //         if(fundingDetail===null) fundingDetail={};
  //         if(extendedWarranty===null) extendedWarranty={}
  //         if(gapInsurance===null) gapInsurance={}  
  //         if(lineItemFee===null) lineItemFee={}

  //         Object.keys(fobAddress).reduce((result,key)=>{
  //           result[key]=fobAddress[key]
  //           if(key!=='updatedAt' && key!=='id' && key!=='createdAt'){
  //             sortedFobValues.push(fobAddress[key])}
  //           return result
  //         },{})

  //         Object.keys(extendedWarranty).reduce((result,key)=>{
  //           result[key]=extendedWarranty[key]
  //           if(key!=='productId' && key!=='id' && key!=='createdAt' && key!=='updatedAt'){
  //             sortedWarrantyValues.push(extendedWarranty[key])}
  //           return result
  //         },{})

  //         Object.keys(gapInsurance).reduce((result,key)=>{
  //           result[key]=gapInsurance[key]
  //           if(key!=='productId'&& key!=='id' && key!=='createdAt' && key!=='updatedAt'){
  //             sortedGapInsuranceValues.push(gapInsurance[key])}
  //           return result
  //         },{})
          
  //         Object.keys(lineItemFee).reduce((result,key)=>{
  //           result[key]=lineItemFee[key]
  //           if(key!=='lineItemId'&& key!=='id' && key!=='createdAt' &&key !== 'updatedAt' && key !== 'luxuryTaxOverrideToggle'){
  //             sortedLineItemFeeValues.push(lineItemFee[key])}
  //           return result
  //         },{})


  //         if(fundingDetail){
  //           Object.keys(fundingDetail).reduce((result,key)=>{
  //             result[key]=fundingDetail[key]
  //             if(key!=='productId'&& key!=='id' && key!=='createdAt' && key!=='cashLienHolder' && key!=='finalizedLendingOption' && key !== 'finalizedAt' && key!=='updatedAt'){
  //               sortedFundingDetailValues.push(fundingDetail[key])}
  //             return result
  //           },{})
  //         }

  //         if(upgrades.length>0){
  //           upgrades.sort(function(a, b) {
  //             if(a.type.toLowerCase() < b.type.toLowerCase()) return -1;
  //             if(a.type.toLowerCase() > b.type.toLowerCase()) return 1;
  //             return 0;
  //             }).forEach((upgrade)=>{
  //             Object.keys(upgrade).reduce((result,key)=>{
  //               result[key]=upgrade[key]
  //               if(key!=='productId'&& key!=='id' && key!=='createdAt' && key!=='id' && key !== 'updatedAt'&& key !== 'refNumber'){
  //                 sortedUpgradesValues.push(upgrade[key])}
  //               return result
  //             },{})
  //           })
  //         }

  //         if(additions.length>0){
  //         additions.sort(function(a, b) {
  //           if(a.type.toLowerCase() < b.type.toLowerCase()) return -1;
  //           if(a.type.toLowerCase() > b.type.toLowerCase()) return 1;
  //           return 0;
  //           }).forEach((addition)=>{
  //             Object.keys(addition).reduce((result,key)=>{
  //               result[key]=addition[key]
  //               if(key!=='itemId'&& key!=='id' && key!=='createdAt' && key!=='id' && key !== 'updatedAt'&& key !== 'refNumber'){
  //                 sortedAdditionsValues.push(addition[key])}
  //               return result
  //             },{})
  //           })
  //         }

  //         let matchingKey = 
  //         // fob+", "+
  //         // fobZip+", "+
  //         make+", "+
  //         model+", "+
  //         price+", "+
  //         stateRegistered+", "+
  //         type+", "+
  //         vehicleType+", "+
  //         year+", "+
  //         sortedFobValues.join(", ")+", "+
  //         sortedWarrantyValues.join(", ")+", "+
  //         sortedGapInsuranceValues.join(", ")+", "+
  //         sortedLineItemFeeValues.join(", ")+", "+
  //         sortedFundingDetailValues.join(", ")+", "+
  //         sortedCashLienHolderValues.join(", ")+", "+
  //         sortedFinalizedLendingOptionValues.join(", ")+", "+
  //         sortedUpgradesValues.join(", ")+", "+upgrades.length+", "+
  //         sortedAdditionsValues.join(", ")+ ", "+additions.length;
          
  //         product.matchingKey = matchingKey;
  //         productArray.push(product);
  //       }
  //     });

  //     // Group based off of matchingKey
  //     var groupedByYmmArray = Object.values(productArray.reduce((result, {
  //       matchingKey,
  //       id,
  //       name
  //     }) => {
  //       // Create new group
  //       if (!result[matchingKey]) result[matchingKey] = {
  //           name,
  //           matchingKey,
  //           id,
  //           products: []
  //       };
  //       // Append to group
  //       result[matchingKey].products.push({
  //         id,
  //       });
  //       return result;
  //     }, {}));
    
  //     // Update products with qty counts based off matching.
  //     let findProduct;
  //     //go through each group of products
  //     groupedByYmmArray.forEach((group)=>{
  //       //go through each product in the group
  //       group.products.forEach((product, i)=>{
  //           //find the product in the order
  //           findProduct = order.products.find((item) => item.id === Number(product.id))
  //           if(findProduct)
  //           // edit the product
  //           findProduct.quantity=group.products.length
  //       })
  //     })
  //     return groupedByYmmArray;

  // },[selectedProducts,order ]);

  // API - get document template via slug - Move to help api's
  const getDocumentTemplateBySlug = useCallback( async (slug)=>{
    return await axios.get(`/document-template/${slug}/by-slug`);
  },[axios])

  const [fetchingSalesReps, setFetchingSalesReps] = useState(false);

  useEffect(() => {

    if((!salesReps || salesReps.length === 0) && fetchingSalesReps === false){
      setFetchingSalesReps(true);
      api.getSalesReps().then( reps => {
        dispatch(rdx.setSalesReps(reps));
      }).finally( () => {
        setFetchingSalesReps(false);
      })
    }

  }, [dispatch, fetchingSalesReps, salesReps])

  // Monster Function - Generate and Preview latest document values and per selected page.
  const generateInvoice = useCallback((grouping)=>{
    dispatch(setShowInvoiceSpinner(true))

    let newValues = null;
    documentValues.showSalesTaxPerUnitPrice = showSalesTaxPerUnitPrice.toString();
    // var passedSalesReps = salesReps;
    // console.log('passedSalesReps', passedSalesReps, passedSalesReps.length);

    // // If sales reps array is empty, load them in:
    // if(!passedSalesReps || passedSalesReps.length === 0){
    //   console.log('generateInvoice1');
    //   passedSalesReps = api.getSalesReps().then( reps => {
    //     console.log('generateInvoice2', reps);
    //     return reps;
    //   })

    //   console.log('generateInvoice3', salesReps, passedSalesReps);
    //   dispatch(rdx.setSalesReps(passedSalesReps));
    //   passedSalesReps = passedSalesReps;
    //   newValues = generateInoiceLibraryFunction(order, document, documentValues, grouping, selectedProducts, passedSalesReps, location);
    // }else{
    //   console.log('generateInvoice4', passedSalesReps);
      newValues = generateInoiceLibraryFunction(order, document, documentValues, grouping, selectedProducts, salesReps, location);
    // }


    // let newValues = generateInoiceLibraryFunction(order, document, documentValues, grouping, selectedProducts, passedSalesReps, location);
  // for(var clearLine = 1; clearLine <= 25; clearLine++){
  //   documentValues[`order_summary_line_${clearLine}_qty`] = "";
  //   documentValues[`order_summary_line_${clearLine}_stock_no`] = "";
  //   documentValues[`order_summary_line_${clearLine}_desc`] = "";
  //   documentValues[`order_summary_line_${clearLine}_unit_price`] =  "";
  //   documentValues[`order_summary_line_${clearLine}_total_price`] = "";
  // }

  // if(grouping === 'match'){
  //   var groupedSelectedProductArray = MatchAndGroup();
  // }else{
  //   groupedSelectedProductArray = selectedProducts;
  // }

  // ApplyLocation();
  // ApplyHeaderData();
    
  //   let lineCounter = 0;

  //   //TODO need to make invoice version with selected products passed in.
  //   let subtotalDownpayment = calculateTotalDownpayment(order.downPayments);

  //   // The switch will decide which type of invoice to generate.
  //   // If it fits on a single page, generate a single page invoice. 
  //   let lastProductLine = 1;
  //   let preparedProductGroups = [];
  //   let subtotal = 0;

  //   const ApplyPreparedProductGroups = (totalLinesAvailable, productGroups) => {
  //     let linesRemaining = totalLinesAvailable;
  //     productGroups.forEach((productGroup) => {
  //       linesRemaining = linesRemaining - productGroup.lines.length;
  //       if(linesRemaining >= 0){
  //         lastProductLine = ApplyLines(lastProductLine, productGroup.lines);
  //       }else{
  //         // Push last used line forward based off difference
  //         lastProductLine += Math.abs(linesRemaining) - 1;

  //         //Reset page total
  //         linesRemaining = totalLinesAvailable;
  //         linesRemaining = linesRemaining - productGroup.lines.length;
  //         lastProductLine = ApplyLines(lastProductLine, productGroup.lines);
  //       }
  //     });
  //   }

  //   function checkYears(productArray){
  //     let yearsMatch = true;
  //     let year = productArray[0].year;
  //     productArray.forEach((product, i) => {
  //       if(product.year){
  //         if(product.year !== year){
  //           yearsMatch = false;
  //         }
  //       }else{
  //         yearsMatch = false;
  //       }
  //     });
  //     return yearsMatch;
  //   }

  //   function checkModels(productArray){
  //     let modelsMatch = true;
  //     let model = productArray[0].model;
  //     productArray.forEach((product, i) => {
  //       if(product.model){
  //         if(product.model.toUpperCase() !== model.toUpperCase()){
  //           modelsMatch = false;
  //         }
  //       }else{
  //         modelsMatch = false;
  //       }
  //     });
  //     return modelsMatch;
  //   }
    
  //   const GroupAllProducts = () => { // TODO: still need to consider pagination
  //     preparedProductGroups = [];
  //     // Loop through the products to list them.
  //     let allProductsArray = [];
  //     selectedProducts.forEach((item, i) => {
  //       let product = findProductFromId(item.id);
  //       allProductsArray.push(product);
  //     });

  //     let yearsMatch = checkYears(allProductsArray);
  //     let modelsMatch = checkModels(allProductsArray);

  //     let productLines = listMultipleUnit(allProductsArray, yearsMatch, modelsMatch);
  //     preparedProductGroups.push({lines: productLines});
  //   };

  //   const generateSinglePageInvoice = () => { // TODO: still need to consider pagination
  //     // Loop through the products to list them.
  //     groupedSelectedProductArray.forEach((item, i) => {
  //       let selectedProduct = item;
  //       let groupedProductArray = [];
  //       if(selectedProduct.products && selectedProduct.products.length > 1){
  //         selectedProduct.products.forEach(gproduct => {
  //           let foundGProduct = findProductFromId(gproduct.id);
  //           groupedProductArray.push(foundGProduct);
  //         })
  //       }
  //       let product = findProductFromId(selectedProduct.id);
  //       if(product){
  //         if(groupedProductArray.length === 0){
  //           groupedProductArray.push(product);
  //         }
  //         let productLines = listSingleProduct(product, groupedProductArray);
  //         preparedProductGroups.push({productId: product.id, lines: productLines});
  //       }
  //     });
  //   };

  //   const listSingleProduct = (product, groupedProductArray) => {
  //     let productLines = [];

  //     let subtotalPrice = calculateInvoicePriceSubtotal(groupedProductArray, order);
  //     let subtotalCATTax = calculateInvoiceCATTaxSubtotal(groupedProductArray, order);
  //     let subtotalLuxuryTax = calculateInvoiceLuxuryTaxSubtotal(groupedProductArray, order);
  //     let subtotalTitleFee = calculateInvoiceTitleFeeSubtotal(groupedProductArray, order);
  //     let subtotalELT = calculateInvoiceEltTotal(groupedProductArray, order);
  //     let subtotalFET =calculateInvoiceFETSubtotal(groupedProductArray, order);
  //     let subtotalTireTaxTotal = calculateInvoiceTireTaxTotalSubtotal(groupedProductArray, order);
  //     let subtotalDocFee = calculateInvoiceDocFeeSubtotal(groupedProductArray, order);
  //     let subtotalBankFee = calculateInvoiceBankFeeSubtotal(groupedProductArray, order);
  //     let subtotalOosDeliveryFee = calculateInvoiceOosDeliveryFeeSubtotal(groupedProductArray, order);
  //     let subtotalAdditionalTax = calculateInvoiceAdditionalTaxSubtotal(groupedProductArray, order);

  //     subtotal = (Number(subtotal) + Number(subtotalPrice) + Number(subtotalFET));

  //     let productLineTotal = 0;
  //     productLineTotal += Number(product.price);

  //     var line_1 = '';
  //     var line_2 = '';
  //     var line_3 = '';
  //     var line_4 = '';

  //     var groupQty = product.quantity && product.quantity > 1 ? product.quantity : 1;

  //     if(product.type && product.type.toLowerCase() === "new"){
  //       if(product && product.lineItemFee && product.lineItemFee.applyFet){
  //         productLineTotal += Number(product.lineItemFee.fetTotal);
  //       }

  //       // New
  //       if(product.lineItemFee && product.lineItemFee.applyFet && product.lineItemFee.applyFet === true && product.lineItemFee !== null && product.lineItemFee.fetTotal !== null){
  //         line_1 = `FET       ${formatter.format((product.lineItemFee && product.lineItemFee.fetTotal ? product.lineItemFee.fetTotal : 0))}`;       
  //       }else if(product.lineItemFee && product.lineItemFee.applyFet === false && product.lineItemFee.fetExemptReason){
  //         // Exempt
  //         line_1 = `*FET EXEMPT`;
  //       }
  //       line_2 = "";
  //       line_3 = formatter.format(product.price ? product.price : 0)
  //       line_4 = `${formatter.format(productLineTotal * groupQty)}`;
  //     }else{
  //       // Used
  //       line_1 = formatter.format(product.price ? product.price : 0)
  //       line_2 = `${formatter.format(productLineTotal * groupQty)}`;
  //       line_3 = "";
  //       line_4 = "";
  //     }

  //     var groupQtyLabel = groupQty > 1 ? groupQty+" " : "";
  //       const splitStockNumber = splitString(product?.stockNumber, 15)
  //     var product_line_1 = {
  //       column_1: `${ (product.type ? groupQtyLabel+product.type.toUpperCase() : '[Product Type Missing]')}`,
  //       column_2: (product && product.stockNumber && groupQty === 1 ? splitStockNumber[0]?.toUpperCase() : "**"),
  //       column_3: `${product.year.replace('"', '').replace('"', '')} ${product.make} ${product.model.toString().toUpperCase()}`,
  //       column_4: line_1,
  //       column_5: line_2,
  //     };

  //     lineCounter++;
      
  //     productLines.push(product_line_1);

  //     // 0.2.011.6:
  //     // - Added Type to VIN label for CA only.
  //     // - Added See Schedule A for product groups.
  //     var caTypeLabel = (order.customer.deliveryAddress.state === 'CA' && product && product.vehicleType ? product.vehicleType.toUpperCase()+" - " : "");
  //     let product_line_2 = {
  //       column_1: "",
  //       column_2: splitStockNumber[1]?.toUpperCase() ??'',
  //       column_3: groupQty > 1 ? `${caTypeLabel} See Schedule A` : `${caTypeLabel}VIN# ${ ( product.vin ? product.vin.toUpperCase() : "" ) }`,
  //       column_4: line_3,
  //       column_5: line_4,
  //     };
  //     lineCounter++;
  //     productLines.push(product_line_2);

  //     let product_line_3 = {
  //       column_1: "",
  //       column_2: splitStockNumber[2]?.toUpperCase() ??'',
  //       column_3: product.fobAddress.addressLine1? 'FOB: '+product.fobAddress.addressLine1+(product.fobAddress?.addressLine2 ? ' '+product.fobAddress.addressLine2 :''):'',
  //       column_4: '',
  //       column_5: "",
  //     };
  //     lineCounter++;
  //     productLines.push(product_line_3);
      
  //     let product_line_4 = {
  //       column_1: "",
  //       column_2: '',
  //       column_3: product.fobAddress? product.fobAddress.city+', '+product.fobAddress.state+' '+product.fobAddress.zip:'',
  //       column_4: '',
  //       column_5: "",
  //     };
  //     lineCounter++;
  //     productLines.push(product_line_4);
      
      
  //     if(product.additions && product.additions.length > 0){
  //       product.additions.forEach(addition => {
  //         let addition_line_1 = {
  //           column_1: "",
  //           column_2: "",
  //           column_3: addition.type ? addition.type.toUpperCase() : "",
  //           column_4: "",
  //           column_5: "",
  //         };
          
  //         lineCounter++;
          
  //         productLines.push(addition_line_1);


  //         let addition_line_2 = {
  //           column_1: "",
  //           column_2: "",
  //           column_3: addition.serialNumber ? "S/N: "+addition.serialNumber.toUpperCase() : "",
  //           column_4: "",
  //           column_5: "",
  //         };
          
  //         lineCounter++;
          
  //         productLines.push(addition_line_2);
  //       });
  //     }

  //     if(product.upgrades && product.upgrades.length > 0){
  //       product.upgrades.forEach(upgrade => {
  //         lineCounter++;

  //         productLineTotal += Number(upgrade.price);

  //         const splitUpgradeType = splitString(upgrade?.type,30)

  //         let upgrade_line_1 = {
  //           column_1: splitUpgradeType[0]?.toUpperCase() ?? "",
  //           column_2: "",
  //           column_3: upgrade.serialNumber ? "REF "+upgrade.serialNumber.toUpperCase() : "",
  //           column_4: upgrade.price ? `${formatter.format(upgrade.price)}` : "",
  //           column_5: upgrade.price ? `${formatter.format(upgrade.price * groupQty)}` : "",
  //         };
          
  //         lineCounter++;
          
  //         productLines.push(upgrade_line_1);
  //         if(splitUpgradeType.length>1){
  //           let upgrade_line_2 = {
  //             column_1: splitUpgradeType[1]?.toUpperCase() ?? "",
  //             column_2: "",
  //             column_3: "",
  //             column_4: "",
  //             column_5: "",
  //           };

  //           lineCounter++;

  //           productLines.push(upgrade_line_2);
  //         }

  //       });
  //     }

  //     if(product.extendedWarranty){

  //       let extendedWarrantyPerUnitPrice = product.extendedWarranty.price ? product.extendedWarranty.price : 0;

  //       let providerLine = product.extendedWarranty.provider ? "Provided by: "+product.extendedWarranty.provider : "";

  //       let extended_warranty_line = {
  //         column_1: "Extended Warranty",
  //         column_2: "",
  //         column_3: providerLine,
  //         column_4: formatter.format(extendedWarrantyPerUnitPrice),
  //         column_5: formatter.format(extendedWarrantyPerUnitPrice * groupQty),
  //       };
        
  //       lineCounter++;
        
  //       productLines.push(extended_warranty_line);

  //     }

  //     if(product.gapInsurance){

  //       let gap_insurance_line = {
  //         column_1: "GAP Insurance",
  //         column_2: "",
  //         column_3: product.gapInsurance.provider ? "Provided by: "+product.gapInsurance.provider : "",
  //         column_4: formatter.format(product.gapInsurance.amount),
  //         column_5: formatter.format(product.gapInsurance.amount * groupQty),
  //       };
        
  //       lineCounter++;
        
  //       productLines.push(gap_insurance_line);

  //     }

  //     // Handle Elt.
  //     if (subtotalELT > 0) {
  //       subtotal = Number(subtotal) + Number(subtotalELT);
  //       let product_line_FILING_FEE = {
  //           column_1: "Electronic Filing Service Fee",
  //           column_2: "",
  //           column_3: "",
  //           column_4: formatter.format(subtotalELT / groupQty),
  //           column_5: formatter.format(subtotalELT),
  //       };
  //       lineCounter++;
  //       productLines.push(product_line_FILING_FEE);
  //     };

  //     if (location && (location.state === "CA" || location.state === "AZ")) {
  //       subtotal = Number(subtotal) + Number(subtotalTireTaxTotal);
  //       // Handle CA & AZ tire tax.
  //       if (subtotalTireTaxTotal !== 0) {
  //         let product_line_TIRE_TAX = {
  //           column_1: "Tire Tax", 
  //           column_2: "", 
  //           column_3: "",
  //           column_4: formatter.format(subtotalTireTaxTotal / groupQty),
  //           column_5: formatter.format(subtotalTireTaxTotal),
  //         };

  //         lineCounter++;
  //         productLines.push(product_line_TIRE_TAX);

  //       };
  //     }

  //     // Handle Oregon CAT tax.
  //     if (subtotalCATTax !== 0) {
  //       subtotal = Number(subtotal) + Number(subtotalCATTax);
  //       let product_line_CAT_TAX = {
  //         column_1: "Estimated OR CAT", 
  //         column_2: "", 
  //         column_3: "",
  //         column_4: formatter.format(subtotalCATTax / groupQty),
  //         column_5: formatter.format(subtotalCATTax),
  //       };

  //       lineCounter++;
  //       productLines.push(product_line_CAT_TAX);
  //     };

  //     if (order.customer.deliveryAddress.state === "OR") {
  //       // Handle Oregon Luxury tax.
  //       if (subtotalLuxuryTax !== 0) { // applyLuxuryTax is already considered in the subtotalLuxuryTax calculation.
  //         subtotal = Number(subtotal) + Number(subtotalLuxuryTax);
  //         let product_line_LUXURY_TAX = {
  //           column_1: "OR Vehicle Privilege & Use Tax", 
  //           column_2: "", 
  //           column_3: "",
  //           column_4: formatter.format(subtotalLuxuryTax / groupQty),
  //           column_5: formatter.format(subtotalLuxuryTax),
  //         };

  //         lineCounter++;
  //         productLines.push(product_line_LUXURY_TAX);
  //       };
  //     };

  //     // Handle License/Title Fees.
  //     if (subtotalTitleFee !== 0) { // applyTitleFee is already considered in the subtotalTitleFee calculation.
  //       subtotal = Number(subtotal) + Number(subtotalTitleFee);
  //       let product_line_LICENSE_TITLE_FEES = {
  //         column_1: "License/Title Fees",
  //         column_2: "",
  //         column_3: (product.lineItemFee && product.lineItemFee.applyTitleFee === true && product.lineItemFee.applyRegistrationFee === false ? "Title Transfer Only" : ""),
  //         column_4: formatter.format(subtotalTitleFee / groupQty),
  //         column_5: formatter.format(subtotalTitleFee),
  //       };

  //       lineCounter++;
  //       productLines.push(product_line_LICENSE_TITLE_FEES);
  //     };

  //     // Handle Document Fees.
  //     if (subtotalDocFee !== 0) {
  //       subtotal = Number(subtotal) + Number(subtotalDocFee);
  //       let product_line_DOC_FEE = {
  //         column_1: location.state === "CA" ? "CA DMV Doc Fee" : (location.state === "WA" ? "Negotiable Documentary Service Fee" : "DMV Doc Fee"),
  //         column_2: "",
  //         column_3: "",
  //         // column_4: formatter.format((first_product.lineItemFee && first_product.lineItemFee.docFee ? first_product.lineItemFee.docFee : 0) / groupQty),
  //         column_4: formatter.format(subtotalDocFee / groupQty),
  //         column_5: formatter.format(subtotalDocFee),
  //       };

  //       lineCounter++;
  //       productLines.push(product_line_DOC_FEE);
  //     };

  //     // Handle Document Fees.
  //     if (subtotalBankFee !== 0) {
  //       subtotal = Number(subtotal) + Number(subtotalBankFee);
  //       let product_line_BANK_FEE = {
  //         column_1: "Bank Fee",
  //         column_2: "",
  //         column_3: "",
  //         column_4: formatter.format(subtotalBankFee / groupQty),
  //         column_5: formatter.format(subtotalBankFee),
  //       };

  //       lineCounter++;
  //       productLines.push(product_line_BANK_FEE);
  //     };

  //     // Handle Document Fees.
  //     if (subtotalOosDeliveryFee > 0) {
  //       subtotal = Number(subtotal) + Number(subtotalOosDeliveryFee);
  //       let product_line_DELIVERY_FEE = {
  //         column_1: "Delivery Fee",
  //         column_2: "",
  //         column_3: "",
  //         // column_4: formatter.format((first_product.lineItemFee && first_product.lineItemFee.oosDeliveryFee ? first_product.lineItemFee.oosDeliveryFee : 0)),
  //         column_4: formatter.format(subtotalOosDeliveryFee / groupQty),
  //         column_5: formatter.format(subtotalOosDeliveryFee),
  //       };

  //       lineCounter++;
  //       productLines.push(product_line_DELIVERY_FEE);
  //     };

  //     // Handle Document Fees.
  //     if (subtotalAdditionalTax > 0) {
  //       subtotal = Number(subtotal) + Number(subtotalAdditionalTax);
  //       let product_line_MISC_FEE = {
  //         column_1: "Miscellaneous",
  //         column_2: "",
  //         column_3: "",
  //         // column_4: formatter.format((first_product.lineItemFee && first_product.lineItemFee.additionalTax ? first_product.lineItemFee.additionalTax : 0)),
  //         column_4: formatter.format(subtotalAdditionalTax / groupQty),
  //         column_5: formatter.format(subtotalAdditionalTax),
  //       };

  //       lineCounter++;
  //       productLines.push(product_line_MISC_FEE);
  //     };

  //     lineCounter++;
  //     productLines.push(ApplyBlankLine());


  //     return productLines;
  //   };

  //   const listMultipleUnit = (productArray, yearsMatch=false, modelsMatch=false) => {
  //     let productLines = [];

  //     let displayYear = '';
  //     if(yearsMatch){
  //       displayYear = productArray[0].year+" ";
  //     }

  //     let displayModel = '';
  //     if(modelsMatch){
  //       displayModel = productArray[0].model.toUpperCase()+" ";
  //     }

  //     let displayLabel = '';
  //     if(displayYear !== ''){
  //       displayLabel = displayYear+displayModel;
  //     }else{
  //       displayLabel = displayModel;
  //     }
  //     // subtotal = 0;

  //     // let subtotalPrice = calculateInvoicePriceSubtotal(productArray, order);
  //     let subtotalCATTax = calculateInvoiceCATTaxSubtotal(productArray, order);
  //     let subtotalLuxuryTax = calculateInvoiceLuxuryTaxSubtotal(productArray, order);
  //     let subtotalTitleFee = calculateInvoiceTitleFeeSubtotal(productArray, order);
  //     let subtotalELT = calculateInvoiceEltTotal(productArray, order);
  //     let subtotalFET =calculateInvoiceFETSubtotal(productArray, order);
  //     let subtotalTireTaxTotal = calculateInvoiceTireTaxTotalSubtotal(productArray, order);
  //     // let subtotalSalesTaxTotal = calculateInvoiceSalesTaxTotalSubtotal(productArray, order);
  //     // let subtotalNonVehicleSalesTaxTotal = calculateInvoiceNonVehicleSalesTaxTotalSubtotal(productArray, order);
  //     let subtotalDocFee = calculateInvoiceDocFeeSubtotal(productArray, order);
  //     let subtotalBankFee = calculateInvoiceBankFeeSubtotal(productArray, order);
  //     let subtotalOosDeliveryFee = calculateInvoiceOosDeliveryFeeSubtotal(productArray, order);
  //     let subtotalAdditionalTax = calculateInvoiceAdditionalTaxSubtotal(productArray, order);

  //     // subtotal = Number(subtotal) + Number(subtotalPrice) + Number(subtotalFET);

  //     var groupQty = productArray && productArray.length > 0 ? productArray.length : 1;

  //     let hasNew = false;
  //     let hasUsed = false;
  //     let typeLabel = "";
  //     let productLineTotal = 0;
  //     let extendedWarrantyTotal = 0;
  //     let extendedWarrantyProviders = [];
  //     let gapInsuranceTotal = 0;
  //     let gapInsuranceProviders = [];
  //     productArray.forEach((product, i) => {
  //       if(product.type && product.type.toUpperCase() === 'NEW'){
  //         hasNew = true;
  //       }

  //       if(product.type && product.type.toUpperCase() === 'USED'){
  //         hasUsed = true;
  //       }

  //       productLineTotal += Number(product.price);

  //       product.upgrades.forEach(upgrade => {
  //         productLineTotal += Number(upgrade.price);
  //       });

  //       if(product.extendedWarranty && product.extendedWarranty.price && product.extendedWarranty.price > 0){
  //         extendedWarrantyTotal += Number(product.extendedWarranty.price);
  //       }

  //       if(product.extendedWarranty && product.extendedWarranty.provider && product.extendedWarranty.provider !== ''){
  //         extendedWarrantyProviders.push(product.extendedWarranty.provider);
  //       }

  //       if(product.gapInsurance && product.gapInsurance.amount && product.gapInsurance.amount > 0){
  //         gapInsuranceTotal += Number(product.gapInsurance.amount);
  //       }

  //       if(product.gapInsurance && product.gapInsurance.provider && product.gapInsurance.provider !== ''){
  //         gapInsuranceProviders.push(product.gapInsurance.provider);
  //       }
  //     });

  //     if(hasNew && hasUsed){
  //       typeLabel = "NEW & USED";
  //     }else if(hasNew){
  //       typeLabel = "NEW";
  //     }else if(hasUsed){
  //       typeLabel = "USED";
  //     }

  //     let productLineTotalNoFet = productLineTotal;

  //     var fetLine = '';
  //     if(subtotalFET > 0){
  //       let perUnitFET = subtotalFET / groupQty;
  //       productLineTotal += Number(subtotalFET);
  //       fetLine = `FET       ${formatter.format(perUnitFET)}`;       
  //     }
     
  //     var product_line_1 = {
  //       column_1: `${ groupQty + " " + typeLabel}`,
  //       column_2: "**",
  //       column_3: `${displayLabel}MULTIPLE UNITS`,
  //       column_4: fetLine,
  //       column_5: "",
  //     };

  //     lineCounter++;
      
  //     productLines.push(product_line_1);

  //     let product_line_2 = {
  //       column_1: "",
  //       column_2: "",
  //       column_3: `See Schedule A`,
  //       column_4: `${formatter.format(productLineTotalNoFet / groupQty)}`,
  //       column_5: `${formatter.format(productLineTotal)}`,
  //     };
  //     lineCounter++;
  //     productLines.push(product_line_2);

  //     let product_line_3 = {
  //       column_1: "",
  //       column_2: "",
  //       column_3: productArray[0].fobAddress.addressLine1? 'FOB: '+productArray[0].fobAddress.addressLine1+( productArray[0].fobAddress?.addressLine2 ? ' '+productArray[0].fobAddress.addressLine2 :''):'',
  //       column_4: '',
  //       column_5: '',
  //     };
  //     lineCounter++;
      
  //     productLines.push(product_line_3);
      
  //     let product_line_4 = {
  //       column_1: "",
  //       column_2: "",
  //       column_3: productArray[0].fobAddress?productArray[0].fobAddress.city+', '+productArray[0].fobAddress.state+' '+productArray[0].fobAddress.zip:'',
  //       column_4: '',
  //       column_5: '',
  //     };
  //     lineCounter++;
  //     productLines.push(product_line_4);


  //     if(extendedWarrantyTotal && extendedWarrantyTotal > 0){

  //       let extended_warranty_line = {
  //         column_1: "Extended Warranty",
  //         column_2: "",
  //         column_3: "Provided by: "+extendedWarrantyProviders.join(', '),
  //         column_4: formatter.format(extendedWarrantyTotal /  groupQty),
  //         column_5: formatter.format(extendedWarrantyTotal),
  //       };
        
  //       lineCounter++;
        
  //       productLines.push(extended_warranty_line);

  //     }

  //     if(gapInsuranceTotal && gapInsuranceTotal > 0){

  //       let gap_insurance_line = {
  //         column_1: "GAP Insurance",
  //         column_2: "",
  //         column_3: "Provided by: "+gapInsuranceProviders.join(', '),
  //         column_4: formatter.format(gapInsuranceTotal / groupQty),
  //         column_5: formatter.format(gapInsuranceTotal),
  //       };
        
  //       lineCounter++;
        
  //       productLines.push(gap_insurance_line);

  //     }

  //     // lineCounter++;
  //     // productLines.push(ApplyBlankLine());

  //     // Handle Elt.
  //     if (subtotalELT > 0) {
  //       // subtotal = Number(subtotal) + Number(subtotalELT);
  //       let product_line_FILING_FEE = {
  //           column_1: "Electronic Filing Service Fee",
  //           column_2: "",
  //           column_3: "",
  //           // column_4: formatter.format((first_product.lineItemFee && first_product.lineItemFee.etlFee ? first_product.lineItemFee.etlFee : 0)),
  //           column_4: formatter.format(subtotalELT / groupQty),
  //           column_5: formatter.format(subtotalELT),
  //       };
  //       lineCounter++;
  //       productLines.push(product_line_FILING_FEE);
  //     };

  //     // if (order.customer.deliveryAddress.state === "CA" || order.customer.deliveryAddress.state === "AZ") {
  //     if (location && (location.state === "CA" || location.state === "AZ")) {
  //       // Handle CA & AZ tire tax.
  //       if (subtotalTireTaxTotal !== 0) {
  //         let product_line_TIRE_TAX = {
  //           column_1: "Tire Tax", 
  //           column_2: "", 
  //           column_3: "",
  //           // column_4: formatter.format((first_product.lineItemFee && first_product.lineItemFee.tireTaxTotal ? first_product.lineItemFee.tireTaxTotal : 0) / groupQty),
  //           column_4: formatter.format(subtotalTireTaxTotal / groupQty),
  //           column_5: formatter.format(subtotalTireTaxTotal),
  //         };

  //         lineCounter++;
  //         productLines.push(product_line_TIRE_TAX);

  //       };
  //     }

  //     // Handle Oregon CAT tax.
  //     if (subtotalCATTax !== 0) {
  //       // subtotal = Number(subtotal) + Number(subtotalCATTax);
  //       let product_line_CAT_TAX = {
  //         column_1: "Estimated OR CAT", 
  //         column_2: "", 
  //         column_3: "",
  //         // column_4: formatter.format((first_product.lineItemFee && first_product.lineItemFee.catTaxTotal ? first_product.lineItemFee.catTaxTotal : 0) / groupQty),
  //         column_4: formatter.format(subtotalCATTax / groupQty),
  //         column_5: formatter.format(subtotalCATTax),
  //       };
        
  //       lineCounter++;
  //       productLines.push(product_line_CAT_TAX);
  //     };

  //     if (first_product && first_product.stateRegistered === "OR") {
  //       // Handle Oregon Luxury tax.
  //       if (subtotalLuxuryTax !== 0) { // applyLuxuryTax is already considered in the subtotalLuxuryTax calculation.
  //         // subtotal = Number(subtotal) + Number(subtotalLuxuryTax);
  //         let product_line_LUXURY_TAX = {
  //           column_1: "OR Vehicle Privilege & Use Tax", 
  //           column_2: "", 
  //           column_3: "",
  //           // column_4: formatter.format((first_product.lineItemFee && first_product.lineItemFee.luxuryTaxTotal ? first_product.lineItemFee.luxuryTaxTotal : 0) / groupQty),
  //           column_4: formatter.format(subtotalLuxuryTax / groupQty),
  //           column_5: formatter.format(subtotalLuxuryTax),
  //         };

  //         lineCounter++;
  //         productLines.push(product_line_LUXURY_TAX);
  //       };
  //     };

  //     // Handle License/Title Fees.
  //     if (subtotalTitleFee !== 0) { // applyTitleFee is already considered in the subtotalTitleFee calculation.
  //       // subtotal = Number(subtotal) + Number(subtotalTitleFee);
  //       let product_line_LICENSE_TITLE_FEES = {
  //         column_1: "License/Title Fees",
  //         column_2: "",
  //         column_3: "",
  //         // column_4: formatter.format((first_product.lineItemFee && first_product.lineItemFee.titleFee ? first_product.lineItemFee.titleFee : 0) / groupQty),
  //         column_4: formatter.format(subtotalTitleFee / groupQty),
  //         column_5: formatter.format(subtotalTitleFee),
  //       };

  //       lineCounter++;
  //       productLines.push(product_line_LICENSE_TITLE_FEES);
  //     };

  //     // Handle Document Fees.
  //     if (subtotalDocFee !== 0) {
  //       // subtotal = Number(subtotal) + Number(subtotalDocFee);
  //       let product_line_DOC_FEE = {
  //         column_1: location.state === "CA" ? "CA DMV Doc Fee" : (location.state === "WA" ? "Negotiable Documentary Service Fee" : "DMV Doc Fee"),
  //         column_2: "",
  //         column_3: "",
  //         // column_4: formatter.format((first_product.lineItemFee && first_product.lineItemFee.docFee ? first_product.lineItemFee.docFee : 0) / groupQty),
  //         column_4: formatter.format(subtotalDocFee / groupQty),
  //         column_5: formatter.format(subtotalDocFee),
  //       };

  //       lineCounter++;
  //       productLines.push(product_line_DOC_FEE);
  //     };

  //     // Handle Document Fees.
  //     if (subtotalBankFee !== 0) {
  //       // subtotal = Number(subtotal) + Number(subtotalDocFee);
  //       let product_line_BANK_FEE = {
  //         column_1: "Bank Fee",
  //         column_2: "",
  //         column_3: "",
  //         // column_4: formatter.format((first_product.lineItemFee && first_product.lineItemFee.BANKFee ? first_product.lineItemFee.docFee : 0) / groupQty),
  //         column_4: formatter.format(subtotalBankFee / groupQty),
  //         column_5: formatter.format(subtotalBankFee),
  //       };

  //       lineCounter++;
  //       productLines.push(product_line_BANK_FEE);
  //     };

  //     // Handle Document Fees.
  //     if (subtotalOosDeliveryFee > 0) {
  //       // subtotal = Number(subtotal) + Number(subtotalOosDeliveryFee);
  //       let product_line_DELIVERY_FEE = {
  //         column_1: "Delivery Fee",
  //         column_2: "",
  //         column_3: "",
  //         // column_4: formatter.format((first_product.lineItemFee && first_product.lineItemFee.oosDeliveryFee ? first_product.lineItemFee.oosDeliveryFee : 0)),
  //         column_4: formatter.format(subtotalOosDeliveryFee / groupQty),
  //         column_5: formatter.format(subtotalOosDeliveryFee),
  //       };

  //       lineCounter++;
  //       productLines.push(product_line_DELIVERY_FEE);
  //     };

  //     // Handle Document Fees.
  //     if (subtotalAdditionalTax > 0) {
  //       // subtotal = Number(subtotal) + Number(subtotalAdditionalTax);
  //       let product_line_MISC_FEE = {
  //         column_1: "Miscellaneous",
  //         column_2: "",
  //         column_3: "",
  //         // column_4: formatter.format((first_product.lineItemFee && first_product.lineItemFee.additionalTax ? first_product.lineItemFee.additionalTax : 0)),
  //         column_4: formatter.format(subtotalAdditionalTax / groupQty),
  //         column_5: formatter.format(subtotalAdditionalTax),
  //       };

  //       lineCounter++;
  //       productLines.push(product_line_MISC_FEE);
  //     };

  //     lineCounter++;
  //     productLines.push(ApplyBlankLine());


  //     return productLines;
  //   };

  //   generateSinglePageInvoice();

  //   ApplyFirstProductShipSold();

  //   let subtotalLines = [];


  //   let subtotalSalesTaxTotal = calculateInvoiceSalesTaxTotalSubtotal(selectedProducts, order);
  //   let subtotalNonVehicleSalesTaxTotal = calculateInvoiceNonVehicleSalesTaxTotalSubtotal(selectedProducts, order);


  //   // Handle Sales Tax isExemptSalesTax === true.
  //   if (order.customer.isExemptSalesTax === true) { // TODO: this might not need to choose by state, isExemptSalesTax === true might be enough
  //     let product_line_SALES_TAX_1 = {
  //       column_1: "Sales Tax", 
  //       column_2: "", 
  //       column_3: location?.state === "CA" ? "Exempt from CA Sales Tax" : "Exempt from Sales Tax", 
  //       column_4: "", 
  //       column_5: "",
  //     };
  
  //     lineCounter++;
  //     subtotalLines.push(product_line_SALES_TAX_1);

  //     // let exemptionValidationReason = '';

  //     // if(order && order.customer && order.customer.iccNumber){
  //     //   exemptionValidationReason = order.customer.iccNumber;
  //     // }

  //     // if(order && order.customer && order.customer.salesTaxExemptionReasonId && taxExemptReasons && taxExemptReasons.length > 0){
  //     //   let salesTaxExemptionReason = taxExemptReasons.find(item => item.id === order.customer.salesTaxExemptionReasonId);
  //     //   if(salesTaxExemptionReason && salesTaxExemptionReason.description){
  //     //     exemptionValidationReason = salesTaxExemptionReason.description;
  //     //   }
  //     // }

  //     // if(certificateLineToggle){
  //     //   let product_line_SALES_TAX_2 = {
  //     //     column_1: "",
  //     //     column_2: "",
  //     //     column_3: `Exemption Validation: ${exemptionValidationReason}`,
  //     //     column_4: "",
  //     //     column_5: "",
  //     //   };
        
  //     //   lineCounter++;
  //     //   subtotalLines.push(product_line_SALES_TAX_2);
  //     // }
  //   };

  //   // Handle all other cases for Sales Tax
  //   if (order?.customer?.isExemptSalesTax === false && order.customer.deliveryAddress.state !== "WA" && subtotalSalesTaxTotal > 0) {
  //     subtotal = Number(subtotal) + Number(subtotalSalesTaxTotal);
  //     let product_line_SALES_TAX_1 = {
  //       column_1: "Sales Tax", 
  //       column_2: order.customer && !order.customer.isExemptSalesTax && order.customer.vehicleSalesTaxPercent && order.customer.vehicleTaxCounty ? (order.customer.vehicleSalesTaxPercent+"% - "+order.customer.vehicleTaxCounty ) : "",
  //       column_3: "", 
  //       column_4: showSalesTaxPerUnitPrice ? formatter.format(subtotalSalesTaxTotal / selectedProducts.length) : "",
  //       column_5: formatter.format(subtotalSalesTaxTotal),
  //     };

  //     lineCounter++;
  //     subtotalLines.push(product_line_SALES_TAX_1);

  //   };

  //   // Handle all other cases for Sales Tax
  //   if (order?.customer?.isExemptSalesTax === false && order.customer.deliveryAddress.state === "WA" && subtotalSalesTaxTotal > 0) {
  //     subtotal = Number(subtotal) + Number(subtotalSalesTaxTotal);
  //     let product_line_SALES_TAX_WA_1 = {
  //       column_1: "State and Local Sales Tax "+(order.customer && !order.customer.isExemptSalesTax && order.customer.vehicleSalesTaxPercent ? (order.customer.vehicleSalesTaxPercent+"% "+(order.customer.vehicleTaxCounty ?  "- "+order.customer.vehicleTaxCounty : "") ) : ""), 
  //       column_2: "", 
  //       column_3: "",
  //       column_4: showSalesTaxPerUnitPrice ? formatter.format(subtotalSalesTaxTotal / selectedProducts.length) : "",
  //       column_5: formatter.format(subtotalSalesTaxTotal),
  //     };

  //     lineCounter++;
  //     subtotalLines.push(product_line_SALES_TAX_WA_1);

  //   }

  //   if (order.customer && order.customer.deliveryAddress.state === "WA" && (order.customer.isExemptSalesTax === false || order.customer.isExemptSalesTax === null) && subtotalNonVehicleSalesTaxTotal > 0) {
  //     subtotal = Number(subtotal) + Number(subtotalNonVehicleSalesTaxTotal);
  //     let product_line_SALES_TAX_WA_2 = {
  //       column_1: "Non-Vehicle Sales State and Local Sales Tax "+(order.customer && !order.customer.isExemptSalesTax && order.customer.nonVehicleSalesTaxPercent ? (order.customer.nonVehicleSalesTaxPercent+"% "+(order.customer.nonVehicleTaxCounty ? "- "+order.customer.nonVehicleTaxCounty : "") ) : ""), 
  //       column_2: "", 
  //       column_3: "",
  //       column_4: showSalesTaxPerUnitPrice ? formatter.format(subtotalNonVehicleSalesTaxTotal / selectedProducts.length) : "",
  //       column_5: formatter.format(subtotalNonVehicleSalesTaxTotal),
  //     };

  //     lineCounter++;
  //     subtotalLines.push(product_line_SALES_TAX_WA_2);

  //   };


  //   let first_product = null;
  //   if(selectedProducts && selectedProducts.length > 0){
  //     first_product = findProductFromId(selectedProducts[0].id)
  //   }

    
  //   // Handle Document Fees.
  //   if (order.adminFee && order.adminFee > 0) {
  //     let product_line_ADMIN_FEE = {
  //       column_1: "Administration Fee",
  //       column_2: "",
  //       column_3: "",
  //       column_4: "",
  //       column_5: formatter.format(order.adminFee),
  //     };

  //     lineCounter++;
  //     subtotalLines.push(product_line_ADMIN_FEE);
  //   };

  //   //Calculate Subtotal
  //   var adminFee = order.adminFee && order.adminFee > 0 ? order.adminFee : 0;
  //   subtotal = Number(subtotal) + Number(adminFee);

  //     // Add extendeded warranty and gap insurance subtotals.
  //     selectedProducts.forEach((selectedProduct, i) => {
  //       let foundProduct = findProductFromId(selectedProduct.id)
  //       if(foundProduct && foundProduct.extendedWarranty){
  //         subtotal = Number(subtotal) + Number(foundProduct.extendedWarranty.price);
  //       }
  //       if(foundProduct && foundProduct.gapInsurance && foundProduct.gapInsurance.amount){
  //         subtotal = Number(subtotal) + Number(foundProduct.gapInsurance.amount);
  //       }
  //     });


  //   lineCounter++;
  //   subtotalLines.push(ApplyBlankLine());

  //   let product_line_SUBTOTAL = {
  //     column_1: "Subtotal",
  //     column_2: "",
  //     column_3: "",
  //     column_4: "",
  //     column_5: formatter.format(subtotal),
  //   };

  //   lineCounter++;
  //   subtotalLines.push(product_line_SUBTOTAL);

    
  //   // Handle Down Payments.
  //   if (subtotalDownpayment !== 0 && order && order.downPayments && order.downPayments.length > 0) {
  //     // if(order.downPayments.length === 1){
  //     //   lineCounter++;
  //     //   subtotalLines.push(ApplyBlankLine());
        
  //     //   let downPaymentLine3 = '';
  //     //   if(order.downPayments[0].status){
  //     //     downPaymentLine3 += capitalizeFirstLetter(order.downPayments[0].status.toLowerCase());
  //     //   }
        
  //     //   if(order.downPayments[0].paymentMethod){
  //     //     downPaymentLine3 += " "+order.downPayments[0].paymentMethod.name.toLowerCase();
  //     //   }
        
  //     //   if(order.downPayments[0].documentNumber){
  //     //     downPaymentLine3 += " number: "+order.downPayments[0].documentNumber;
  //     //   }
        
  //     //   let product_line_DOWNPAYMENT = {
  //     //     column_1: "Down Payment", 
  //     //     column_2: "",
  //     //     column_3: downPaymentLine3, 
  //     //     column_4: "", 
  //     //     column_5: `(${formatter.format(subtotalDownpayment)})`,
  //     //   };
        
  //     //   lineCounter++;
  //     //   subtotalLines.push(product_line_DOWNPAYMENT);
  //     // }else if(order.downPayments.length > 1){
  //       lineCounter++;
  //       let downpaymentsRecievedArray = order.downPayments.filter(item => item.status.toUpperCase() === 'RECEIVED');
  //       let downpaymentsDueAtDeliveryArray = order.downPayments.filter(item => item.status.toUpperCase() === 'DUE AT DELIVERY');

  //       // New Print
  //       downpaymentsRecievedArray.forEach(downpaymentsRecieved => {
  //         subtotalLines.push(ApplyBlankLine());

  //         let downPaymentLine3 = '';
  //           if(downpaymentsRecieved.status){
  //             downPaymentLine3 += capitalizeFirstLetter(downpaymentsRecieved.status.toLowerCase());
  //           }
            
  //           if(downpaymentsRecieved.paymentMethod){
  //             downPaymentLine3 += " "+downpaymentsRecieved.paymentMethod.name.toLowerCase();
  //           }
            
  //           if(downpaymentsRecieved.documentNumber){
  //             downPaymentLine3 += " number: "+downpaymentsRecieved.documentNumber;
  //           }
            
  //           let downPaymentLine3Column4 = '';
  //           if(downpaymentsRecieved.dateReceived){
  //             downPaymentLine3Column4 += " - Date Received: "+moment.utc(downpaymentsRecieved.dateReceived).format('MM/DD/YYYY');
  //           }
            
  //           let product_line_DOWNPAYMENT_Received = {
  //             column_1: "Down Payment Received", 
  //             column_2: "",
  //             column_3: downPaymentLine3, 
  //             column_4: downPaymentLine3Column4, 
  //             column_5: `(${formatter.format(downpaymentsRecieved.amount)})`,
  //           };
            
  //           lineCounter++;
  //           subtotalLines.push(product_line_DOWNPAYMENT_Received);

  //       });
        
  //       // New Print
  //       downpaymentsDueAtDeliveryArray.forEach(downpaymentDue => {
  //         subtotalLines.push(ApplyBlankLine());

  //         let downPaymentLine3 = '';
  //           if(downpaymentDue.status){
  //             downPaymentLine3 += capitalizeFirstLetter(downpaymentDue.status.toLowerCase());
  //           }
            
  //           if(downpaymentDue.paymentMethod){
  //             downPaymentLine3 += " "+downpaymentDue.paymentMethod.name.toLowerCase();
  //           }
            
  //           if(downpaymentDue.documentNumber){
  //             downPaymentLine3 += " number: "+downpaymentDue.documentNumber;
  //           }
            
  //           let product_line_DOWNPAYMENT_Due= {
  //             column_1: "Down Payment Due at Delivery", 
  //             column_2: "",
  //             column_3: downPaymentLine3, 
  //             column_4: "", 
  //             column_5: `(${formatter.format(downpaymentDue.amount)})`,
  //           };
            
  //           lineCounter++;
  //           subtotalLines.push(product_line_DOWNPAYMENT_Due);

  //       });

  //       // if(downpaymentsRecievedArray.length > 0){
  //       //   lineCounter++;
  //       //   subtotalLines.push(ApplyBlankLine());

  //       //   let downPaymentLine3 = '';
  //       //   // if(downpaymentsRecievedArray.length === 1){
  //       //     if(downpaymentsRecievedArray[0].status){
  //       //       downPaymentLine3 += capitalizeFirstLetter(downpaymentsRecievedArray[0].status.toLowerCase());
  //       //     }
            
  //       //     if(downpaymentsRecievedArray[0].paymentMethod){
  //       //       downPaymentLine3 += " "+downpaymentsRecievedArray[0].paymentMethod.name.toLowerCase();
  //       //     }
            
  //       //     if(downpaymentsRecievedArray[0].documentNumber){
  //       //       downPaymentLine3 += " number: "+downpaymentsRecievedArray[0].documentNumber;
  //       //     }
  //       //   // }

  //       //   let sum = 0;
  //       //   downpaymentsRecievedArray.forEach(item => {
  //       //     sum += Number(item.amount);
  //       //   })
          
  //       //   let product_line_DOWNPAYMENT_Received = {
  //       //     column_1: "Down Payments Received", 
  //       //     column_2: "",
  //       //     column_3: downPaymentLine3, 
  //       //     column_4: "", 
  //       //     column_5: `(${formatter.format(sum)})`,
  //       //   };
          
  //       //   lineCounter++;
  //       //   subtotalLines.push(product_line_DOWNPAYMENT_Received);
  //       // }

  //       // if(downpaymentsDueAtDeliveryArray.length > 0){
  //       //   let sum = 0;
  //       //   downpaymentsDueAtDeliveryArray.forEach(item => {
  //       //     sum += Number(item.amount);
  //       //   })
          
  //       //   let product_line_DOWNPAYMENT_Due_at_Delivery = {
  //       //     column_1: "Down Payments Due at Delivery", 
  //       //     column_2: "",
  //       //     column_3: "", 
  //       //     column_4: "", 
  //       //     column_5: `(${formatter.format(sum)})`,
  //       //   };
          
  //       //   lineCounter++;
  //       //   subtotalLines.push(product_line_DOWNPAYMENT_Due_at_Delivery);
  //       // }
  //     // }
  //   }

  //   // Handle Deposits.
  //   if(selectedProducts && selectedProducts.length > 0){
  //     lineCounter++;
  //     subtotalLines.push(ApplyBlankLine());
  //   }

  //   selectedProducts.forEach((selectedProduct, i) => {
  //     let foundProduct = findProductFromId(selectedProduct.id)
  //     if(foundProduct && foundProduct.deposits && foundProduct.deposits.length > 0){
  //       foundProduct.deposits.forEach((deposit, index) => {
  //         if (deposit && deposit.appliedAmount) {

  //           let line3 = "";
  //           if(deposit.paymentMethodObj){
  //             if(deposit.paymentMethodObj.name.toLowerCase() === 'wire'){
  //               line3 = "Received Wire: " + deposit.documentNumber;
  //             }else{
  //               line3 = "Received " + deposit.paymentMethodObj.name.toLowerCase() + " number: " + deposit.documentNumber;
  //             }
  //           }


  //           let product_line_DEPOSIT = {
  //             column_1: "Deposit", 
  //             column_2: "",
  //             column_3: line3, 
  //             column_4: "",
  //             column_5: `(${formatter.format(foundProduct.deposits[index].appliedAmount)})`,
  //           };

  //           lineCounter++;
  //           subtotalLines.push(product_line_DEPOSIT);
  //         }
  //       });
  //     }
  //   });

  //   let totalNetTradeAllowance = calculateTotalTradeCreditTotal(order.tradeins);
  //   let tradeInCredit = "";
  //   if(totalNetTradeAllowance < 0){
  //     tradeInCredit = formatter.format(Math.abs(totalNetTradeAllowance));
  //   }else{
  //     tradeInCredit = "("+formatter.format(totalNetTradeAllowance)+")";
  //   }

  //   // Handle Trade Ins
  //   if(order.tradeins && order.tradeins.length === 1) {
  //       order.tradeins.forEach((tradein, i) => {
  //         lineCounter++;
  //         subtotalLines.push(ApplyBlankLine());

  //         let ymm = tradein.year+" "+tradein.make.toString().toUpperCase()+" "+tradein.model.toString().toUpperCase();
  //         lineCounter++;
          
  //         // let totalNetTradeAllowance = calculateTotalTradeCredit(tradein);
  //         // let tradeInCredit = "";
  //         // if(totalNetTradeAllowance < 0){
  //         //   tradeInCredit = formatter.format(Math.abs(totalNetTradeAllowance));
  //         // }else{
  //         //   tradeInCredit = "("+formatter.format(totalNetTradeAllowance)+")";
  //         // }
  //         let product_line_TRADE_LINE_1 = {
  //           column_1: "Trade In", 
  //           column_2: "",
  //           column_3: ymm, 
  //           column_4: "Trade In Value: "+formatter.format(tradein.tradeAllowanceAtTerms),
  //           column_5: `${tradeInCredit}`,
  //         };
    
  //         lineCounter++;
  //         subtotalLines.push(product_line_TRADE_LINE_1);

  //         let product_line_TRADE_LINE_2 = {
  //           column_1: "", 
  //           column_2: "", 
  //           column_3: tradein.vin ? "VIN #"+tradein.vin.toString().toUpperCase() : "",
  //           column_4: tradein.cashRefundToCustomer ? "Cash Refund: "+formatter.format(tradein.cashRefundToCustomer) : "Cash Refund: "+formatter.format(0),
  //           column_5: "",
  //         };
    
  //         lineCounter++;
  //         subtotalLines.push(product_line_TRADE_LINE_2);


  //         let product_line_TRADE_LINE_3 = {
  //           column_1: "", 
  //           column_2: "", 
  //           column_3: tradein.balanceOwedTo ? "Owed To: "+tradein.balanceOwedTo : "",
  //           column_4: tradein.balanceOwed && tradein.balanceOwed > 0 ? "Payoff Value: "+formatter.format(tradein.balanceOwed) : "",
  //           column_5: "",
  //         };
    
  //         lineCounter++;
  //         subtotalLines.push(product_line_TRADE_LINE_3);

  //       });
  //   }else if(order.tradeins && order.tradeins.length > 1){

  //     let product_line_TRADE_LINE_MULTI = {
  //       column_1: "", 
  //       column_2: "", 
  //       column_3: "See Trade Schedule",
  //       column_4: "",
  //       column_5: `${tradeInCredit}`,
  //     };

  //     lineCounter++;
  //     subtotalLines.push(product_line_TRADE_LINE_MULTI);
  //   }

  //   // Add Lien Holder from First Product and Notes.
  //   if (first_product && first_product.fundingDetail && first_product.fundingDetail.fundingMethod && first_product.fundingDetail.fundingMethod === "cash-with-lien" && first_product.fundingDetail.cashLienHolder && first_product.fundingDetail.cashLienHolder.bankName) { 
  //     documentValues[`lien_holder`] = "Lienholder: "+first_product.fundingDetail.cashLienHolder.bankName;
  //   }else if (first_product && first_product.fundingDetail && first_product.fundingDetail.fundingMethod && (first_product.fundingDetail.fundingMethod === "tec-financing" || first_product.fundingDetail.fundingMethod === "finance-only" || first_product.fundingDetail.fundingMethod === "direct-deal") && first_product.fundingDetail.finalizedLendingOption && first_product.fundingDetail.finalizedLendingOption.bankName) { 
  //     documentValues[`lien_holder`] = "Lienholder: "+first_product.fundingDetail.finalizedLendingOption.bankName;
  //   };

  //   var finalNotes = '';
  //   var hasNotes = false;
  //   if (first_product && first_product.lineItemFee) {
      
  //     var notes = "";

  //     if(first_product.lineItemFee.description && first_product.lineItemFee.description !== ""){
  //       notes = notes+first_product.lineItemFee.description+"\n\n";
  //       hasNotes = true;
  //     }
      
  //     if(hasNotes){
  //       finalNotes = notes;
  //       hasNotes = true;
  //     }

  //     if(first_product.lineItemFee.applyFet === false && first_product.lineItemFee.fetExemptReason && first_product.type.toString().toLowerCase() === "new"){
  //       finalNotes = finalNotes+"*FET Exemption: "+first_product.lineItemFee.fetExemptReason+"\n\n";
  //       hasNotes = true;
  //     }
      
  //     if(first_product.extendedWarranty && first_product.extendedWarranty.description){
  //       finalNotes = finalNotes+"Warranty: "+first_product.extendedWarranty.description+"\n\n";
  //       hasNotes = true;
  //     }
      
  //     if(hasNotes){
  //       documentValues[`notes`] = "NOTES: \n\n"+finalNotes;
  //     }

  //   };

  //   if(order && order.customer && order.customer.isExemptSalesTax === true && order.customer.salesTaxExemptionReasonId && taxExemptReasons && taxExemptReasons.length > 0){
  //     let salesTaxExemptionReason = taxExemptReasons.find(item => item.id === order.customer.salesTaxExemptionReasonId);
  //     if(salesTaxExemptionReason && salesTaxExemptionReason.description){
  //       // exemptionValidationReason = salesTaxExemptionReason.description;
  //       finalNotes = finalNotes+"Exemption Validation: "+salesTaxExemptionReason.description+"\n\n";
  //       hasNotes = true;

  //       if(order.customer.sterResaleNumber && order.customer.sterResaleNumber !== ''){
  //         finalNotes = finalNotes+"Resale Number: "+order.customer.sterResaleNumber+"\n\n";
  //       }

  //       if(order.customer.sterDealerNumber && order.customer.sterDealerNumber !== ''){
  //         finalNotes = finalNotes+"Dealer Number: "+order.customer.sterDealerNumber+"\n\n";
  //       }

  //       if(order.customer.sterMcNumber && order.customer.sterMcNumber !== ''){
  //         finalNotes = finalNotes+"MC Number: "+order.customer.sterMcNumber+"\n\n";
  //       }

  //       if(order.customer.sterDotNumber && order.customer.sterDotNumber !== ''){
  //         finalNotes = finalNotes+"DOT Number: "+order.customer.sterDotNumber+"\n\n";
  //       }
  //     }
  //   }
    
  //   if(hasNotes){
  //     documentValues[`notes`] = "NOTES: \n\n"+finalNotes;
  //   }

  //   // Calculate Deposit Total
  //   let depositAppliedAmountSum = 0;
  //   selectedProducts.forEach((selectedProduct, i) => {
  //     let foundProduct = findProductFromId(selectedProduct.id)
  //     if(foundProduct && foundProduct.deposits && foundProduct.deposits.length > 0){
  //       foundProduct.deposits.forEach(deposit => {
  //         depositAppliedAmountSum += (Number(deposit.appliedAmount) ?? 0);
  //       });
  //     }
  //   });
    
  //   let totalNetTradeAllowanceTotal = calculateTotalTradeCreditTotal(order.tradeins);
    
  //   // Calculate Balance Due
  //   let balanceDue = Number(subtotal) - Number(subtotalDownpayment) - Number(depositAppliedAmountSum) - Number(totalNetTradeAllowanceTotal);
  //   documentValues.balance_due = formatter.format(balanceDue);
    
  //   let maxLines = 25;
  //   var maxPagesCalc = Math.ceil(lineCounter / maxLines);
  //   // if(maxPagesCalc === 1){
  //     // }else if(maxPagesCalc > 1){
  //   if(grouping === 'all'){
  //     GroupAllProducts();
  //   }
  //   ApplyPreparedProductGroups(maxLines, preparedProductGroups);
  //   lastProductLine = ApplyLines(lastProductLine, subtotalLines);

  //   //Prep Pages
  //   setInvoiceLineCount(lineCounter);
  //   setMaxPages(maxPagesCalc);
    previewPage(id, newValues);


  },[dispatch, document, documentValues, id, location, order, previewPage, salesReps, selectedProducts, showSalesTaxPerUnitPrice]);
  //getPageValues,axios ,  renamePageFields,



  // Update the document preview.
  useEffect(() => {
    if ( selectedProducts && selectedProducts.length > 0 && previewChanges && location ){
      generateInvoice(groupingType);
      setPreviewChanges(false);
    }
  }, [ generateInvoice, selectedProducts, previewChanges, location, groupingType ])

  // Initalize the product options.
  useEffect(() => {
    if(order && order.products && order.products.length > 0 && invoiceProductOptions && invoiceProductOptions.length === 0){
      const invoiceProductOptions =
        order.products.map(el => {
          if ( !el.invoiceId ) {
            return { id: el.id, name: (el.stockNumber.toString().toUpperCase()), year: el.year, make: el.make, model: el.model};
          }
          return el;
        })

      setInvoiceProductOptions(invoiceProductOptions)
    }
dispatch(setShowInvoiceSpinner(false))
  }, [ order, invoiceProductOptions, setInvoiceProductOptions, dispatch ])

  // Click - Save Event
  const onSaveClick = event => {
    event.preventDefault();
    updateDocument(document_id);
    // saveAllPages();
  }
  
  // Original Document Save

  //very nested axios calls come back to implement try catch
  async function updateDocument(documentId){
    var slug = 'tec-invoice-template-v2';
    // if(maxPages > 1){
    //   slug = "tec-invoice-template-p-"+maxPages;
    // }
    
    if ( documentId == null || documentId === undefined ){
      getDocumentTemplateBySlug(slug).then(response => {
        let invoiceTemplate = response?.data;
        if(invoiceTemplate && invoiceTemplate.id){

          let documentDraftData = {
            DocumentStatusId: 5, //Draft
            DocumentTemplateId: invoiceTemplate.id, //TODO Improve: Change out with found template tec-invoice-template                                                                                
            OrderId: id,
            AssignedObjectType: 'order',
            Payload: JSON.stringify({}),
            AdditionalData: JSON.stringify({}),
          }
          
          if(!document || !document.id){
            axios.post(`/document`, documentDraftData).then((res) => {
              
              // axios.get(`/order/${order.id}/document/${res?.data.id}`, documentDraftData).then((res) => {
                loadDocument(res?.data.id);
              // });
              
              const payloadString = JSON.parse(JSON.stringify(documentValues));
              var docCopy = JSON.parse(JSON.stringify(res?.data));

              ///
              let cleanPayload = {};

              Object.entries(payloadString).forEach( (value, index, array) => {
                if(value[1] !== ""){
                  cleanPayload[value[0]] = value[1];
                }
              })


              docCopy.payload = JSON.stringify(cleanPayload);

              ///
              delete docCopy.documentStatus;
              docCopy.additionalData = JSON.stringify({
                invoiceCertificateLineToggle: certificateLineToggle,
                invoiceShowSalesTaxPerUnitPrice: showSalesTaxPerUnitPrice,
                invoiceGroupingType: groupingType});
              
              if(selectedProducts && selectedProducts.length > 0){
                var itemIds = [];
                selectedProducts.forEach(product => {
                  itemIds.push(product.id);
                })
                docCopy.itemIds = `${itemIds}`;
              }
              setSaveSuccess(true);

              axios.put(`/document/${res?.data.id}/prepare`, docCopy).then(res => {
                axios.post(`/document/${docCopy.id}/generate`);
                setUnsavedChanges(false);
              }).catch(err => {
              });
            });
          
          }
        }else{
          // Throw Error
        }
      });



    }else{
      // Update Document
      if(document){

        
        var docCopy = JSON.parse(JSON.stringify(document));
        const payloadString = JSON.parse(JSON.stringify(documentValues));
        console.log(docCopy.showSalesTaxPerUnitPrice) 

   
        let cleanPayload = {};

        Object.entries(payloadString).forEach( (value, index, array) => {
          if(value[1] !== ""){
            cleanPayload[value[0]] = value[1];
          }
        })


        docCopy.payload = JSON.stringify(cleanPayload);

        delete docCopy.documentStatus;

        docCopy.additionalData = JSON.stringify({
          invoiceCertificateLineToggle: certificateLineToggle,
          invoiceShowSalesTaxPerUnitPrice: showSalesTaxPerUnitPrice,
          invoiceGroupingType: groupingType});
        
        if(selectedProducts && selectedProducts.length > 0){
          var itemIds = [];
          selectedProducts.forEach(product => {
            itemIds.push(product.id);
          })
          docCopy.itemIds = `${itemIds}`;
        }

        await axios.put(`/document/${document.id}/prepare`, docCopy).then(res => {
          axios.post(`/document/${document.id}/generate`);
          setUnsavedChanges(false);
        }).catch(err => {
        });
      }
    }
  }

  // Autocomplete - Change Event
  const onSelectedProductChange = (e, newValue) => {
    if(newValue.some((x=>x.id === 'selectAll'))){
      setSelectedProducts(invoiceProductOptions);
    } else{
      setSelectedProducts(newValue);
    }
    setPreviewChanges(true);
    setSaveSuccess(false);
  }

  // Check Function - Show Cert HTML
  function showCertToggle(){
    if(order){
      if(order.customer){
        if(order.customer.isExemptSalesTax){
          return true;
        }
      }
    } 
    return false;
  }

  //Reset State Variables
  const resetVals = (e) => {
    // e.preventDefault();
    setDocument(undefined);
    setDocumentValues(initialDocumentValues);
    setdocumentUrl("");
    setDataLoaded(false);
    setSelectedProducts([]);
    setPreviewChanges(false);
    setUnsavedChanges(false);
    setInvoiceProductOptions([]);
    setSaveSuccess(false);
    setCertificateInitalized(false);
    setCertificateLineToggle(false);
    setDocumentFocus(undefined);
    getDocuments(true);
  }

  
  return (
    <>
      <div
        className="modal fade"
        id="documentsInvoiceModal"
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
        data-testid="modal.editInvoice.topDiv"
        data-testcontext={"documentSlug: " + document?.documentTemplate?.slug + ", documentId: " + document?.id}
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                Generate Invoice - Please select the products for this invoice.
              </h5>
              <br />
              <button
                type="button"
                className="btn-close bg-white"
                data-bs-dismiss="modal"
                disabled={showInvoiceSpinner}
                aria-label="Close"
                data-testid="modal.editInvoice.control.closeButtonTop"
                onClick={(e) => resetVals(e)}
              />
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
              <div className="container-fluid mt-0">
                <div className="row" style={{ height: '70vh'}}>
                  <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                    <br />
                    <Autocomplete
                      value={ selectedProducts ?? null}
                      multiple
                      disabled={showInvoiceSpinner}
                      id="tags-outlined"
                      onChange={(event, newValue) => {
                        onSelectedProductChange(event, newValue);
                        setDocumentValues(initialDocumentValues);
                      }}
                      onBlur={(e) => setPreviewChanges(true)}
                      // options={(invoiceProductOptions ?? [])}
                      options={formattedOptions()}
                      getOptionLabel={(option) => option.name}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Products"
                        />
                      )}
                    />
                    <br />

                    { showCertToggle() ? (
                    <p><Checkbox 
                      checked={certificateLineToggle} 
                      disabled={showInvoiceSpinner}
                      onChange={(e, newValue) => {
                        setCertificateLineToggle(newValue);
                        setPreviewChanges(true);
                        }
                      } /> Show/Hide Certificate Line
                      </p>
                    ):null}
                      <br />
                      <p><Checkbox 
                      checked={showSalesTaxPerUnitPrice}
                      disabled={showInvoiceSpinner}
                      onChange={(e, newValue) => {
                        setShowSalesTaxPerUnitPrice(newValue);
                        setPreviewChanges(true);
                        }
                      } /> Show/Hide Sales Tax Unit Price
                      </p>
                      <br />
                    
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">Group Products</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={groupingType}
                        // onBlur={(e) => setPreviewChanges(true)}
                        disabled={showInvoiceSpinner}
                        onChange={(e) => {
                          setGroupingType(e.target.value);
                        }}
                      >
                        <FormControlLabel value="none" control={<Radio disabled={showInvoiceSpinner} />} label="None" />
                        <FormControlLabel value="match" control={<Radio disabled={showInvoiceSpinner} />} label="Matching" />
                        <FormControlLabel value="all" control={<Radio disabled={showInvoiceSpinner} />} label="All (Multi Unit)" />
                      </RadioGroup>
                    </FormControl>

                  </div>
                  <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                    <h5 className="d-flex">Invoice Preview               
                      {/* { previewChanges ? ( */}
                      <button className="btn btn-small btn-primary mx-3" 
                          type="button"
                          disabled={showInvoiceSpinner}
                          onClick={() => {generateInvoice(groupingType)}}
                          data-testid="modal.editInvoice.control.updatePreviewButton"
                        >
                          Update Preview
                        </button>
                    </h5>
                    { showInvoiceSpinner? <div style={{display:'flex', justifyContent:'center', flexDirection:'column', height:'100%'}}><CircularProgress style={{alignSelf:'center'}}/></div> :(documentUrl !== "" && documentUrl !== undefined && documentUrl ? ( 
                      <embed data-testid="modal.editInvoice.embededPreview" width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                    ):null)}
                  </div>
                </div>
              </div>
            </div>
            { unsavedChanges ? (
            <div className="text-center m-3 mb-4">
              <button className="btn btn-danger mx-3" 
                type="button"
                data-bs-dismiss="modal"
                disabled={showInvoiceSpinner}
                onClick={e => {
                  resetVals(e);
                }}
                data-testid="modal.editInvoice.control.cancelButton"
              >
                Cancel
              </button>
              {saveSuccess ? (
              <button className="btn btn-success mx-3" data-testid="modal.editInvoice.control.savedInvoiceButtonNoAction">
                Saved Invoice
              </button>
              ) : (
              <button className="btn btn-primary mx-3" 
                type="button"
                disabled={showInvoiceSpinner}
                onClick={e => onSaveClick(e)}
                data-testid="modal.editInvoice.control.saveButton"
              >
                Save
              </button>
              ) }
            </div>
            ): (
              <div className="text-center m-3 mb-4">
              <button className="btn btn-primary mx-3" 
                type="button"
                data-bs-dismiss="modal"
                data-testid="modal.editInvoice.control.closeButtonBottom"
                disabled={showInvoiceSpinner}
                onClick={e => {
                  resetVals(e);
                }}
              >
                Close
              </button>
            </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const {
  useSalesReps
} = rdx

const {
  Autocomplete,
  TextField,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  FormControlLabel
} = MaterialComponents

export default DocumentInvoiceModal
import React from "react";
import { useFetch, recordFrontEndError } from "../../../../../../utlis/api";
import {useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Accordion, Card, Dropdown } from "react-bootstrap";
import { Divider } from '@mui/material';
import moment from 'moment'
import { useOrder } from "../../../../../../redux/orders/order"
import { formatter, formatNumber } from "../../../../../../utlis/formatters";
import { setActiveModal } from '../../../../../../redux/app/appSaver';

import "./Style/qoutebodytradeins.css";
// import QuoteBodyTradeInsPart1 from "./QuoteBodyTradeInsPart1";
// import QuoteBodyTradeInsPart2 from "./QuoteBodyTradeInsPart2";

import { rdx } from "../../../../../../redux/tradeins";

import TradeinInfoTemplate from "./TradeinInfoTemplate";

const TradeInSection = ({isOpen, setIsOpen, tray}) => {

  const dispatch  = useDispatch();
  const history   = useHistory();
  const axios     = useFetch()
  let { id }      = useParams();
  let order       = useOrder();

  //DELETE_TRADE_IN_TRADE_IN_SECTION
  const deleteTradein = async (e, orderId, tradeinId) => {
    e.preventDefault();
    try {
      await axios.delete(`/order/${orderId}/tradein/${tradeinId}`)
    } catch (err) {
      recordFrontEndError('TradeInSection.jsx', 'Error deleting trade-in: ' + err)
    }
        //TODO: Make sure deletes return a 204
        history.push(`/order/${id}`);
  }

  const calcFinalTradeAllowance = (tradein) => {
    let val = tradein.tradeAllowanceAtTerms - tradein.balanceOwed - tradein.cashRefundToCustomer;
    val = val >= 0 ? '$' + formatNumber(val) : '($' + formatNumber(val) + ')';
    return val;
  }

  return (
    <div>
      <Accordion defaultActiveKey="0">
        {order && order.tradeins ? ( order.tradeins.sort((a, b) => a.vin?.localeCompare(b.vin, undefined, { numeric: true })).map(tradein => (
          <Card className="p-3" key={tradein.id+'t'}>
            <TradeinInfoTemplate eventKey={tradein.id} tradein={tradein}
            isOpen={isOpen} setIsOpen={setIsOpen} selectedProduct={isOpen.selectedProduct}
            >
              <Dropdown.Item
              data-bs-toggle="modal"
              data-bs-target="#tradeinModal"
              onClick={(e) => {
                dispatch(rdx.setTradeinFocusId(tradein.id))
                dispatch(rdx.setIsOpen(true))
                dispatch(setActiveModal('tradein'))
              }}>
                <b>Edit Tradein</b>
              </Dropdown.Item>
              <Divider />
              <Dropdown.Item
                onClick={(e) => deleteTradein(e, id, tradein.id)}>
                <b>Delete Trade In</b> 
              </Dropdown.Item>
            </TradeinInfoTemplate>
            <Accordion.Collapse eventKey={tradein.id} className="p-3" in={isOpen.selectedProduct===tradein.id}>
              <div className="p-4">
                <h5 className="mb-2">TRADEIN DETAILS</h5>

                <div className="p-4">
                  <div className="d-flex justify-content-between">

                    <div className="p-1" style={{width: '100%', maxWidth: '15rem'}}>
                      { tradein && tradein.serialNumber ?(
                        <h5>{tradein.serialNumber}</h5>
                      ):null}
                      <p className="text-secondary text-uppercase pt-3">Serial #</p>
                    </div>

                    <div className="p-1" style={{width: '100%', maxWidth: '15rem'}}>
                      { tradein && tradein.vin ?(
                        <h5>{tradein.vin}</h5>
                      ):null}
                      <p className="text-secondary text-uppercase pt-3">VIN</p>
                    </div>

                    <div className="p-1" style={{width: '100%', maxWidth: '15rem'}}>
                      { tradein && tradein.plateNumber ?(
                        <h5>{tradein.plateNumber}</h5>
                      ):null}
                      <p className="text-secondary text-uppercase pt-3">Vehicle Plate #</p>
                    </div>

                  </div>
                </div>
                {tradein && (tradein.seriesBodyType || tradein.dolClientNumber) ? (
                <div className="p-4">
                  <div className="d-flex justify-content-around">
                  { tradein && tradein.seriesBodyType ?(
                    <div className="p-1" style={{width: '100%', maxWidth: '15rem'}}>
                        <h5>{tradein.seriesBodyType}</h5>
                      <p className="text-secondary text-uppercase pt-3">Body Type</p>
                    </div>
                    ):null}
                    { tradein && tradein.dolClientNumber ?(
                    <div className="p-1" style={{width: '100%', maxWidth: '15rem'}}>
                        <h5>{tradein.dolClientNumber}</h5>
                      <p className="text-secondary text-uppercase pt-3">DOL Client Number</p>
                    </div>
                    ):null}
                  </div>
                </div>
                ) : null }
                { tradein && tradein.description ? (
                <div className="p-4">
                    
                    <div className="p-1">
                        <h5>{tradein.description}</h5>
                      <p className="text-secondary text-uppercase pt-3">Description</p>
                    </div>
                    
                </div>
                ):null}

                <div className="p-4">
                  <h5 className="mb-2">PAYOFF DETAILS</h5>
                  <br />
                  <div className="d-flex justify-content-between">
                    <div style={{width: '100%', maxWidth: '15rem'}}>
                      { tradein && tradein.balanceOwed ?(
                        <h5>{ formatter.format(tradein.balanceOwed) }</h5>
                        ):(
                          <h5>--</h5>
                        )}
                      <p className="text-secondary textCapitalize pt-3">BALANCE OWED</p>
                    </div>
                    <div style={{width: '100%', maxWidth: '15rem'}}>
                      { tradein && tradein.goodUntil ?(
                        <h5>{ moment.utc(tradein.goodUntil).format("MM/DD/YY")}</h5>
                      ):(
                        <h5>--</h5>
                      )}
                      <p className="text-secondary textCapitalize pt-3">GOOD UNTIL</p>
                    </div>
                    <div style={{width: '100%', maxWidth: '15rem'}} className="pb-5 mb-2">
                      { tradein && tradein.balanceOwedTo ?(
                        <div>
                          <h5>{tradein.balanceOwedTo}</h5>
                          <span>{tradein.owedToAddress}<br />
                          {tradein.owedToCity}, {tradein.owedToState} {tradein.owedToZip}
                          </span>
                        </div>
                      ):(
                        <h5>--</h5>
                      )}
                      <p className="text-secondary textCapitalize pt-3">PAYOFF OWNER</p>
                    </div>
                  </div>
                  <br />

                  <div className="d-flex justify-content-between">

                    <div className="p-1" style={{width: '100%', maxWidth: '15rem'}}>
                      { tradein && tradein.tradeAllowanceAtTerms ?(
                        <h5>{ formatter.format(tradein.tradeAllowanceAtTerms) }</h5>
                      ):(
                        <h5>--</h5>
                      )}
                      <p className="text-secondary text-uppercase pt-3">ALLOWANCE AT TERMS</p>
                    </div>

                    <div className="p-1" style={{width: '100%', maxWidth: '15rem'}}>
                      { tradein && tradein.cashRefundToCustomer ?(
                        <h5>{ formatter.format(tradein.cashRefundToCustomer) }</h5>
                      ):(
                        <h5>--</h5>
                      )}
                      <p className="text-secondary textCapitalize pt-3">CASH TO CUSTOMER</p>
                    </div>

                    <div className="p-1" style={{width: '100%', maxWidth: '15rem'}}>
                      { tradein && tradein.tradeAllowanceAtTerms ?(
                        <h5>{ calcFinalTradeAllowance(tradein) }</h5>
                      ):(
                        <h5>--</h5>
                      )}
                      <p className="text-secondary textCapitalize pt-3">FINAL TRADE ALLOWANCE</p>
                    </div>

                  </div>
                </div>
              </div>
            </Accordion.Collapse>
          </Card>
        ))):('')}
      </Accordion>
    </div>
  );
}

export default TradeInSection;

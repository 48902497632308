import React, { useEffect, useMemo, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import _ from "lodash";
import moment from "moment";

import { useOrder } from "../../../../../../redux/orders/order";
import { rdx } from "../../../../../../redux/tradeins";

import TradeinModal from "./TradeinModal";

import { formatter } from "../../../../../../utlis/formatters";
import { updateCopySection, updateCopySubSection, updateChangesArray, updateGroupChangesArray, updateStatus,  } from "../../../../../../redux/saveAndCopy"
import { setActiveModal } from "../../../../../../redux/app/appSaver";

function TradeinModalContainer() {
const log=false
  const { id: orderId } = useParams()
  const dispatch        = useDispatch()
  // const history         = useHistory()
  const order           = useOrder()

  const isOpen                    = useIsOpen()
  const editMode                  = useEditMode() //true or false

  const editedTradein             = useEditedTradein()
  const editedTradeinErrors       = useEditedTradeinErrors()

  const editedOwedToAddress       = useEditedOwedToAddress()
  const editedOwedToAddressErrors = useEditedOwedToAddressErrors()

  const tradeinFromOrder          = useTradein()
  const tradeinId                 = useTradeinFocusId()

  const isValidTradeInForm        = useIsValidTradeInForm()
  // const lastSaveTimestamp         = useLastSaveTimestamp()
  const saveTradeinSuccess        = useSaveTradeinSuccess()
  //conditionally logging to remove error
log&&console.log(saveTradeinSuccess)
  const productLenders            = useProductLenders()
  const owedToInput               = useOwedToInput()
  const owedToInputError          = useOwedToInputError()
  const selectedLender            = useSelectedLender()

  const hasUnsavedChanges        = useHasUnsavedChanges()

  const applyMileage        = useApplyMileage()

  const [saveSuccess, setSaveSuccess] = useState(false);
  const [showCopyToDocuments, setShowCopyToDocuments] = useState(false);

  /* Use this object to pull values from the edit or the existing tradein */
  const editedOrDefaultTradein = useMemo(() => {
    const obj =
      _.defaultsDeep(
        {},
        editedTradein,            //Edit values...
        tradeinFromOrder,         //The original values...
        TRADE_IN_FIELDS_DEFAULTS  //Default values...
      )
    return obj
  }, [ editedTradein, tradeinFromOrder ])

  const appData = useSelector((state) => state?.appData);
  // const changesArray = useSelector((state) => state?.copyChangesToDocumentsRedux.changesArray);

// function updateDataChangeArray(e){
//   e.preventDefault();

//   const { name: prop, value} = e.target;

//   let doNotCopyProperties = [];

//   if(changesArray && !doNotCopyProperties.includes(prop)){

//     let newValues = JSON.parse(JSON.stringify(changesArray));

//     if(newValues[prop]?.oldValue){

//       newValues[prop] = {
//         "newValue": value,
//         "oldValue": newValues[prop].oldValue
//       };

//     }else{

//       const oldValue = editedOrDefaultTradein[prop];

//       newValues[prop] = {
//         "newValue": value,
//         "oldValue": oldValue ? oldValue :"--"
//       };

//     }

//     dispatch(updateChangesArray(newValues));

//   }
// }

const [dataChangeArray, setDataChangeArray]   = useState([]);
function updateDataChangeArray(e=null, prop=null, value=null){
  if(e !== null){
    e.preventDefault();
    prop = e.target.name;
    value = e.target.value;
  }

  let doNotCopyProperties = [];

  if(dataChangeArray && !doNotCopyProperties.includes(prop) && prop !== undefined){

    if(dataChangeArray["tradein."+prop]?.oldValue){

      setDataChangeArray((prev)=>({
        ...prev,
        ["tradein."+prop]: {
          "newValue": value,
          "oldValue": dataChangeArray["tradein."+prop].oldValue
        }
      }))
      
    }else{
      const oldValue = editedOrDefaultTradein[prop];

      setDataChangeArray((prev)=>({
        ...prev,
        ["tradein."+prop]: {
          "newValue": value,
          "oldValue": oldValue ? oldValue : '--'
        }
      }))

    }
  }
}

useEffect(() => {

  if(appData?.activeModal === 'tradein'){
    dispatch(updateChangesArray(dataChangeArray));
  }

}, [ appData, dataChangeArray, dispatch ])




  //Pull values from the edit first, then the unedited version
  const editedOrDefaultOwedToAddress = useMemo(() => {
    const addressFromTradein =
      _.pick(tradeinFromOrder, OWED_TO_ADDRESS_FIELDS)

    return (
      _.defaultsDeep(
        {},
        editedOwedToAddress,
        addressFromTradein,
        TRADE_IN_OWED_TO_ADDRESS_DEFAULTS
      ))
  }, [ tradeinFromOrder, editedOwedToAddress ])

  //Effect to find the tradein if it exists...
  useEffect(() => {

    if(appData?.activeModal === 'tradein'){
      
        //Find the tradein...
        let tradein  =
           _.defaultTo(order.tradeins, [])
          .find(t => t.id === tradeinId );

        //If we can't find the tradein (because we're in add mode)
        //set the tradein to be an empty object.
        if ( !tradein ) {
          tradein = {}
        } else {
          dispatch(rdx.setEditMode(true))
        }

        //Format the tradein from the order to match the shape of the editedTradein
        const formattedFields =
          NUMBER_FIELDS.reduce((fields, field ) => ({
            ...fields,
            [ field ]: { formatted: tradein[ field ], value: tradein[ field ]}
          }), {})

        //Format the fields
        const tradeinFromOrder = {
          ...tradein,
          ...formattedFields,
          //Set good until to the current date by default...
          goodUntil: moment().utc(),
        }

        dispatch(rdx.setTradein(tradeinFromOrder));

      }

  }, [order, tradeinId, dispatch, appData?.activeModal])

  /* Effect to fetch product lenders */
  useEffect(() => {

    if(appData?.activeModal === 'tradein'){

      dispatch(rdx.getProductLenders());

    }

  }, [appData?.activeModal, dispatch])

  useEffect(() => {

    if(appData?.activeModal === 'tradein'){

      if (! selectedLender ) {
        return;
      }

      dispatch(rdx.setEditedTradein(
        'balanceOwedTo',
        selectedLender.data.bankName
      ))

      dispatch(rdx.setEditedOwedToAddress(
        'owedToAddress',
        selectedLender.data.lienAddress
      ))

      dispatch(rdx.setEditedOwedToAddress(
        'owedToCity',
        selectedLender.data.lienCity
      ))

      dispatch(rdx.setEditedOwedToAddress(
        'owedToState', selectedLender.data.lienState
      ))

      dispatch(rdx.setEditedOwedToAddress(
        'owedToZip', selectedLender.data.lienZip
      ));

    }

  }, [selectedLender, dispatch, appData?.activeModal])

  /* Event handlers */
  const onBalanceOwedBlur = e => {

    const value = editedTradein.balanceOwed?.value

    /* If there is no balance remove all the errors... */
    if ( _.isEmpty( value ) || value === '0' ) {
      const tradeinHideFields = [ 'goodUntil', 'balanceOwed' ]

      for ( const field of OWED_TO_ADDRESS_FIELDS ) {
        if ( editedOwedToAddressErrors[ field ] ) {
          dispatch(rdx.hideEditedTradeinError(field))
        }
      }

      for ( const field of tradeinHideFields ) {
        if ( editedOwedToAddressErrors[ field ] ) {
          dispatch(rdx.hideEditedTradeinError(field))
        }
      }
    }
  }

  const handleClose = () => {
    //Close the modal...
    dispatch(rdx.setHasUnsavedChanges(false))
    dispatch(rdx.setIsOpen(false))
    dispatch(rdx.resetValues())
    setSaveSuccess(false);
    setShowCopyToDocuments(false);
  }

  // determine if trade in credits info should be required based on status
  const validateTradeInCreditsStatus = () => {

    const VALID_STATUSES_FOR_TRADE_IN_CREDIT = [
      'Booked',
      'Booking Request',
      'Credit Approval',
      'Docs Generated',
      'Docs Requested',
      'Docs Signed',
      'Finance Review',
      'Funding Request',
      'Funded',
    ]

    const { name: orderStatus } = order.orderStatus;

    const isValidTradeinCreditStatus =
      VALID_STATUSES_FOR_TRADE_IN_CREDIT.includes(orderStatus)

    return isValidTradeinCreditStatus
  }

  const TRADE_IN_FIELD_VALIDATORS = {
    cashRefundToCustomer: DEFAULT_NOT_EMPTY_NUMBER_FIELD_VALIDATOR,
    vin:  v => _.isEmpty(v) === false && v.length === 17 ,
    year: v => _.isEmpty(v) === false && v.length === 4,
    make: DEFAULT_NOT_EMPTY_FIELD_VALIDATOR,
    model: DEFAULT_NOT_EMPTY_FIELD_VALIDATOR,
    mileage: DEFAULT_NOT_EMPTY_NUMBER_FIELD_VALIDATOR,
    balanceOwed: DEFAULT_NOT_EMPTY_NUMBER_FIELD_VALIDATOR,
    goodUntil: DEFAULT_NOT_EMPTY_FIELD_VALIDATOR,
    tradeAllowanceAtTerms: value => {
      const shouldValidateTradeInAllowance = validateTradeInCreditsStatus()
      //Validate if the source of the change is not due a prop change...
      if ( shouldValidateTradeInAllowance ) {
        return _.isEmpty( value ) === false && value !== '0'
      }
      return true
    }
  }

  //Fields validators for required fields...
  const TRADE_IN_FIELD_VALIDATORS_REQUIRED_FIELDS =
    _.pick(
      TRADE_IN_FIELD_VALIDATORS,
      [ 'vin', 'year', 'make', 'model', 'goodUntil' ]
    )


  //Inputs
  const onEditedTradeinChange = ( field, value ) => {

    dispatch(rdx.setEditedTradein(field, value))

    const validate  = TRADE_IN_FIELD_VALIDATORS[ field ]

    if(validate){
      const isValid   = validate(value)
      if ( isValid && editedTradeinErrors[ field ] ) {          /* Hide the error */
        dispatch(rdx.hideEditedTradeinError(field))
      } else if ( !isValid && !editedTradeinErrors[ field ]) {  /* Show the error */
        dispatch(rdx.showEditedTradeinError(field))
      }
    }
    dispatch(rdx.setHasUnsavedChanges(true))
    setSaveSuccess(false);
    setShowCopyToDocuments(false);
  }

  const onDolClientNumberChange = e => {
    const dolClientNumber = e.target.value.replace(/\D/g,'');
    onEditedTradeinChange('dolClientNumber', dolClientNumber);
    updateDataChangeArray(e);
  }

  const onBalanceOwedChange = (e, sourceInfo) => {
    if ( sourceInfo === undefined || sourceInfo?.source === 'prop' ) {
      return; //Ignore changes that are due to prop changes...
    }
    let { value, formattedValue: formatted } = e;

    // value = Number(value).ToFixed(2);
    value = (Math.round(Number(value) * 100) / 100).toFixed(2);

    formatted = formatter.format(value);

    onEditedTradeinChange('balanceOwed', { value, formatted });
    updateDataChangeArray(null, 'balanceOwed', value);

  }

  const onBalanceOwedToChange = (e) => {
    let { value } = e.target;
    onEditedTradeinChange('balanceOwedTo', value);
    updateDataChangeArray(null, 'balanceOwedTo', value);
  }

  const onCashRefundToCustomerChange = (event, sourceInfo) => {
    if ( sourceInfo === undefined || sourceInfo?.source === 'prop' ) {
      return; //Ignore changes that are due to prop changes...
    }
    let { value, formattedValue: formatted } = event;

    value = (Math.round(Number(value) * 100) / 100).toFixed(2);

    formatted = formatter.format(value);

    onEditedTradeinChange('cashRefundToCustomer', { value, formatted })
    updateDataChangeArray(null, 'cashRefundToCustomer', value);

  }

  const onDescriptionChange = e => {
    onEditedTradeinChange('description', e.target.value);
    updateDataChangeArray(e);
  }

  const onFinalTradeAllowanceChange = e => {
    onEditedTradeinChange('finalTradeAllowance', e.target.value);
    updateDataChangeArray(e);
  }

  const onGoodUntilChange = e => {
    onEditedTradeinChange('goodUntil', e.target.value);
    updateDataChangeArray(e);
  }

  const onMakeChange = e => {
    onEditedTradeinChange('make', e.target.value);
    updateDataChangeArray(e);
  }

  const onModelChange = e => {
    onEditedTradeinChange('model', e.target.value);
    updateDataChangeArray(e);
  }

  const onPlateNumberChange = e => {
    onEditedTradeinChange('plateNumber', e.target.value);
    updateDataChangeArray(e);
  }

  const onSeriesBodyTypeChange = e => {
    onEditedTradeinChange('seriesBodyType', e.target.value);
    updateDataChangeArray(e);
  }

  const onSerialNumberChange = e => {
    const serialNumber = e.target.value.replace(/\D/g,'');
    onEditedTradeinChange('serialNumber', serialNumber);
    updateDataChangeArray(e);
  }

  const onMileageChange = (options, sourceInfo) => {
    if ( sourceInfo.source === 'prop' ) {
      return; //Ignore changes that are due to prop changes...
    }

    const { value, formattedValue: formatted } = options

    onEditedTradeinChange('mileage', { value, formatted });
    
    updateDataChangeArray(null, 'mileage', value);
  }

  const onApplyMileageChange = (options) => {

    let isChecked = options.target.checked
    onEditedTradeinChange('applyMileage', isChecked )
    if(isChecked === false){
      onEditedTradeinChange('mileage', {value: 0, formatted: 0})
      updateDataChangeArray(null, 'mileage', 0);
    }
    updateDataChangeArray(null, 'applyMileage', isChecked);
  }

  const initiateCopyToDocuments = () => {
    dispatch(updateCopySection('tradein'));
    dispatch(updateCopySubSection('details'));
    let copyChangesArray = [];
    copyChangesArray.id = editedOrDefaultTradein.id;
    copyChangesArray.stockNumber = editedOrDefaultTradein.stockNumber;
    copyChangesArray.propertySaved = false;
    copyChangesArray.docsSavedCount = 0;
    Object.entries(dataChangeArray).forEach( value => {
      copyChangesArray[value[0]] = value[1];
    });
    dispatch(updateGroupChangesArray([copyChangesArray]));
    dispatch(updateStatus('copy'));
    dispatch(setActiveModal('copy-to-documents'));
  }

  const onTradeAllowanceAtTermsChange = (options, sourceInfo) => {
    if ( sourceInfo.source === 'prop' ) {
      return; //Ignore changes that are due to prop changes...
    }

    let { value, formattedValue: formatted } = options
    value = (Math.round(Number(value) * 100) / 100).toFixed(2);

    formatted = formatter.format(value);

    /* Validate the allowance depending on the status of the order*/
    const validator = () => {

      const shouldValidateTradeInAllowance = validateTradeInCreditsStatus()

      //Validate if the source of the change is not due a prop change...
      if ( shouldValidateTradeInAllowance ) {
        return _.isEmpty( value ) === false && value !== '0'
      }
      return true
    }

    onEditedTradeinChange('tradeAllowanceAtTerms', { value, formatted }, validator);
    updateDataChangeArray(null, 'tradeAllowanceAtTerms', value);
  }

  const onSelectedLenderChange = ( event, newValue ) => {
    dispatch(rdx.setSelectedLender(newValue))
  }

  const onVinChange = e => {
    const vin       = e.target.value;
    const editedVin = vin.replace(/[^a-zA-Z0-9]/g, '');
    const validator = vin => vin.length <= 17;
    onEditedTradeinChange('vin', editedVin, validator);
    updateDataChangeArray(e);
  }

  const onYearChange = e => {
    const year        = e.target.value;
    const editedYear  = year.replace(/[^0-9]/g,'');
    onEditedTradeinChange('year', editedYear);
    updateDataChangeArray(e);
  }

  /* OwedToAddress */
  const onEditedOwedToAddressChange = (field, value, validate=DEFAULT_NOT_EMPTY_FIELD_VALIDATOR) => {

    dispatch(rdx.setEditedOwedToAddress(field, value))

    const isValid = validate(field)

    if ( isValid && editedOwedToAddressErrors[ field ] ) {         /* Hide the error */
      dispatch(rdx.hideEditedOwedToAddressError(field))
    } else if ( !isValid && !editedOwedToAddressErrors[ field ]) { /* Show the error */
      dispatch(rdx.showEditedOwedToAddressError(field))
    }
    dispatch(rdx.setHasUnsavedChanges(true))
    setSaveSuccess(false);
    setShowCopyToDocuments(false);
  }

  const onOwedToAddress1Change = e => {
    onEditedOwedToAddressChange('owedToAddress', e.target.value);
    updateDataChangeArray(e);
  }

  const onOwedToAddress2Change = e => {
    onEditedOwedToAddressChange('owedToAddress2', e.target.value);
    updateDataChangeArray(e);
  }

  const onOwedToCityChange = e => {
    onEditedOwedToAddressChange('owedToCity', e.target.value);
    updateDataChangeArray(e);
  }

  const onOwedToInputChange = (event, newInputValue) => {
    dispatch(rdx.setOwedToInput(newInputValue))
    if ( _.isEmpty( newInputValue ) && !owedToInputError ) {
      dispatch(rdx.setOwedToInputError(true))
    }
  }

  const onOwedToStateChange = ( e, newValue ) => {
    onEditedOwedToAddressChange('owedToState', newValue);
    updateDataChangeArray(e);
  }

  const onOwedToZipChange = e => {
    const zip = e.target.value.slice(0,9)
    onEditedOwedToAddressChange('owedToZip', zip);
    updateDataChangeArray(e);
  }

  /* Helpers */
  const calcFinalTradeAllowance = () => {

    const tradeAllowanceAtTerms =
      _.defaultTo(Number(editedOrDefaultTradein.tradeAllowanceAtTerms?.value), 0)

    const balanceOwed =
      _.defaultTo(Number(editedOrDefaultTradein.balanceOwed?.value), 0)

    const cashRefundToCustomer =
      _.defaultTo(Number(editedOrDefaultTradein.cashRefundToCustomer?.value), 0)

    var subtotal = (tradeAllowanceAtTerms - balanceOwed).toFixed(2) - cashRefundToCustomer;
    return subtotal;
  }

  const handleSubmit = async e => {

    e.preventDefault()

    //Format values either from the order or the edit...
    const tradein = {
      ...editedOrDefaultTradein,
      ...editedOrDefaultOwedToAddress,
      balanceOwed: Number(editedOrDefaultTradein.balanceOwed?.value),
      cashRefundToCustomer: Number(editedOrDefaultTradein.cashRefundToCustomer?.value),
      finalTradeAllowance: Number(calcFinalTradeAllowance()),
      mileage: editedOrDefaultTradein.mileage?.value,
      titleNumber: '[DEPRECATED]',
      tradeAllowanceAtTerms: Number(editedOrDefaultTradein.tradeAllowanceAtTerms?.value),
      type: '[DEPRECATED]',
    }

    //Validate the payoff details...
    const isValidPayoffDetails = validateFormPayoffDetails()

    //Validate the form ( will show errors )...
    const isValidTradeInForm = validateForm()

    if ( isValidPayoffDetails === false ) {
      showPayoffDetailsErrorsOnSubmit()
    }

    if ( !isValidTradeInForm || !isValidPayoffDetails ) {
      //TODO: Can show the user a helpful tip...
      return
    }

    //Submit
    if ( editMode ) {
      dispatch(rdx.updateTradein(orderId, tradein.id, tradein))
    } else {
      dispatch(rdx.saveTradein(orderId, tradein))
    }

    setTimeout( () => {
      setSaveSuccess(true);
      setShowCopyToDocuments(true);
    }, 2500)

  }

  //Will show errors if fields if errors exist...
  const validateForm = () => {

    let invalidFields = []

    //Check tradein fields...
    const invalidTradeinFields =
      _.keys(TRADE_IN_FIELD_VALIDATORS_REQUIRED_FIELDS)
      .filter( field => {

        const validator     = TRADE_IN_FIELD_VALIDATORS[ field ]
        const fieldIsValid  = validator(editedOrDefaultTradein[ field ])

        return fieldIsValid === false
      })

    invalidFields = [ ...invalidFields, ...invalidTradeinFields ]

    //Show or hide errors...
    _.keys(TRADE_IN_FIELD_VALIDATORS).forEach( field => {
      if ( invalidTradeinFields.includes(field) ) {
        //Show error
        if ( ! editedTradeinErrors[ field ] ) {
          dispatch(rdx.showEditedTradeinError(field))
        }
      } else if ( editedTradeinErrors[ field ] ) {
        //Hide error
        dispatch(rdx.hideEditedTradeinError(field))
      }
    })

    //Check tradein address fields if the balance owed was updated...
    if ( editedOrDefaultTradein.balanceOwed?.value > 0 ) {

      //Check tradein address fields...
      const invalidOwedToAddressFields =
        REQUIRED_OWED_TO_ADDRESS_FIELDS
        .filter( field => _.isEmpty(editedOrDefaultOwedToAddress[ field ]) )

      invalidFields = [ ...invalidFields, ...invalidOwedToAddressFields ]

      //Show or remove errors...
      REQUIRED_OWED_TO_ADDRESS_FIELDS.forEach(field => {
        if ( invalidOwedToAddressFields.includes(field) ) {
          //Show
          if ( !editedOwedToAddressErrors[ field ]) {
            dispatch(rdx.showEditedOwedToAddressError(field))
          }
        } else if ( editedOwedToAddressErrors[ field ] ) {
          //Hide
          dispatch(rdx.hideEditedOwedToAddressError(field))
        }
      })
    }

    //check status and if required validate trade in credits
    const isTradeInCreditsStatusRequired = validateTradeInCreditsStatus()

    const TRADE_IN_CREDIT_REQUIRED_NUMBER_FIELDS = [
      'tradeAllowanceAtTerms',
      'cashRefundToCustomer',
    ]

    if ( isTradeInCreditsStatusRequired ) {

      const invalidTradeinCreditFields =
        TRADE_IN_CREDIT_REQUIRED_NUMBER_FIELDS
        .filter( field => {

          const validate  = TRADE_IN_FIELD_VALIDATORS[ field ]
          const value     = editedOrDefaultTradein[ field ]

          return validate(value) === false
        })

      invalidFields = [ ...invalidFields, ...invalidTradeinCreditFields ]

      //Show errors for trade in credit fields...
      TRADE_IN_CREDIT_REQUIRED_NUMBER_FIELDS.forEach(field => {
        if ( invalidTradeinCreditFields.includes(field) ) {
          //Show
          if ( !editedTradeinErrors[ field ]) {
            dispatch(rdx.showEditedTradeinError(field))
          }
        } else if ( editedTradeinErrors[ field ] ) {
          //Hide
          dispatch(rdx.hideEditedTradeinError(field))
        }
      })
    }

    if ( _.isEmpty( invalidFields ) === false ) {
      return false;
    }

    return _.isEmpty( invalidFields )
  }

  //Should know all the fields that are invalid before
  const validateFormPayoffDetails = () => {

    //Balance owed
    const invalidFields = []
    if ( _.isNil( editedOrDefaultTradein.balanceOwed?.value ) ) {
      invalidFields.push('balanceOwed')
    }

    //Good until
    if ( _.isEmpty( editedOrDefaultTradein.goodUntil ) ) {
      invalidFields.push('goodUntil')
    }

    //Owed to address
    // const anyAddressFieldEmpty =
    //     REQUIRED_OWED_TO_ADDRESS_FIELDS.forEach( field => {
    //       if ( _.isNil( editedOrDefaultOwedToAddress[ field ]) ) {
    //         invalidFields.push(field)
    //       }
    //     })

    if ( _.isEmpty(invalidFields) === false ) {
      return false
    }

    //Valid
    return true
  }

  /* Show errors for empty fields relating to the payoff details */
  const showPayoffDetailsErrorsOnSubmit = () => {

    const PAYOFF_DETAILS_REQUIRED_FIELDS = [ 'balanceOwedTo', 'goodUntil' ]

    //balanceOwedTo + goodUntil fields...
    PAYOFF_DETAILS_REQUIRED_FIELDS.forEach( field => {
      if ( _.isEmpty( editedOrDefaultTradein[ field ] ) ) {
        dispatch(rdx.showEditedOwedToAddressError(field))
      }
    })

    //OwedToAddress check
    REQUIRED_OWED_TO_ADDRESS_FIELDS.forEach( field => {
      if ( _.isEmpty( editedOrDefaultTradein[ field ] ) ) {
        dispatch(rdx.showEditedOwedToAddressError(field))
      }
    })

    //TODO: Zip
  }



  // const formatter = new Intl.NumberFormat('en-US', {
  //   style: 'currency',
  //   currency: 'USD',
  // })


  return (
    <TradeinModal
      editMode={ editMode }
      formatter={ formatter }
      editedOrDefaultTradein={ editedOrDefaultTradein }
      editedOrDefaultOwedToAddress={ editedOrDefaultOwedToAddress }
      editedOwedToAddressErrors={ editedOwedToAddressErrors }
      editedTradeinErrors={ editedTradeinErrors }
      // resetValues={ rdx.resetValues }
      isOpen={ isOpen }
      isValidTradeInForm={ isValidTradeInForm }
      handleSubmit={ handleSubmit }
      onBalanceOwedBlur={ onBalanceOwedBlur }
      onBalanceOwedToChange={ onBalanceOwedToChange }
      handleClose={ handleClose }
      onBalanceOwedChange={ onBalanceOwedChange }
      onDescriptionChange={ onDescriptionChange }
      onDolClientNumberChange={ onDolClientNumberChange }
      onFinalTradeAllowanceChange={ onFinalTradeAllowanceChange }
      onGoodUntilChange={ onGoodUntilChange }
      owedToInput={ owedToInput }
      onOwedToAddress1Change={ onOwedToAddress1Change }
      onOwedToAddress2Change={ onOwedToAddress2Change }
      onOwedToCityChange={ onOwedToCityChange }
      onOwedToStateChange={ onOwedToStateChange }
      onOwedToInputChange={ onOwedToInputChange }
      onOwedToZipChange={ onOwedToZipChange }
      onPlateNumberChange={ onPlateNumberChange }
      onMakeChange={ onMakeChange }
      onMileageChange={ onMileageChange }
      onModelChange={ onModelChange }
      onSelectedLenderChange={ onSelectedLenderChange }
      onSerialNumberChange={ onSerialNumberChange }
      onSeriesBodyTypeChange={ onSeriesBodyTypeChange }
      onTradeAllowanceAtTermsChange={ onTradeAllowanceAtTermsChange }
      productLenders={ productLenders }
      onCashRefundToCustomerChange={ onCashRefundToCustomerChange }
      calcFinalTradeAllowance={ calcFinalTradeAllowance }
      onVinChange={ onVinChange }
      onYearChange={ onYearChange }
      saveTradeinSuccess={ saveSuccess }
      showCopyToDocuments={ showCopyToDocuments }
      selectedLender={ selectedLender }
      hasUnsavedChanges={ hasUnsavedChanges }
      applyMileage={ applyMileage }
      onApplyMileageChange={ onApplyMileageChange }
      initiateCopyToDocuments={initiateCopyToDocuments}
    />
  )
}

const DEFAULT_NOT_EMPTY_FIELD_VALIDATOR   = v => _.isEmpty(v) === false

const TRADE_IN_FIELDS_DEFAULTS  = {
  balanceOwed: { formatted: "", value: "" },
  balanceOwedTo: "",
  cashRefundToCustomer: { formatted: "", value: "" },
  dolClientNumber: "",
  description: "",
  finalTradeAllowance: { formatted: "", value: "" },
  goodUntil: "",
  make: "",
  mileage: { formatted: "", value: "" },
  model: "",
  plateNumber: "",
  serialNumber: "",
  seriesBodyType: "",
  tradeAllowanceAtTerms: { formatted: "", value: "" },
  vin: "",
  year: "",
}

const TRADE_IN_OWED_TO_ADDRESS_DEFAULTS  = {
  owedToAddress: "",
  owedToAddress2: "",
  owedToCity: "",
  owedToState: "",
  owedToZip: ""
}

const NUMBER_FIELDS = [
  'balanceOwed',
  'cashRefundToCustomer',
  'finalTradeAllowance',
  'mileage',
  'tradeAllowanceAtTerms'
]

const DEFAULT_NOT_EMPTY_NUMBER_FIELD_VALIDATOR = v => {
  return _.isEmpty(v.value.toString()) === false
}

const OWED_TO_ADDRESS_FIELDS = [
  'owedToAddress',
  'owedToAddress2',
  'owedToCity',
  'owedToState',
  'owedToZip'
]

const REQUIRED_OWED_TO_ADDRESS_FIELDS =
  _.without(OWED_TO_ADDRESS_FIELDS, 'owedToAddress2')


const {
  useEditMode,
  useEditedOwedToAddress,
  useEditedOwedToAddressErrors,
  useEditedTradein,
  useEditedTradeinErrors,
  // useFormPreviouslySubmitted,
  useIsOpen,
  useIsValidTradeInForm,
  // useLastSaveTimestamp,
  useOwedToInput,
  useOwedToInputError,
  useProductLenders,
  useSaveTradeinSuccess,
  useSelectedLender,
  useTradeinFocusId,
  useTradein,
  useHasUnsavedChanges,
  useApplyMileage
} = rdx

export default TradeinModalContainer

import {createSlice} from '@reduxjs/toolkit'


export const meSlice = createSlice({
   name: 'me',
   initialState:{
      id: null,
      defaultTecRegionCode: null,
      defaultTecLocationCode: null
   },
   reducers:{
      updateMe: (state, action)=>{
         return action.payload
      },
      updateDefaultRegionCode: (state, action) => {
         state.defaultTecRegionCode = action.payload;
         return state;
      },
      updateDefaultLocationCode: (state, action) => {
         state.defaultTecLocationCode = action.payload;
         return state;
      }
    }
})

export const { updateMe, updateDefaultRegionCode, updateDefaultLocationCode }= meSlice.actions

export default meSlice.reducer
import { graphConfig } from "./authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function callMsGraph(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer)
    headers.append("Content-Type", "application/json")
    // const data = {
    //     securityEnabledOnly: false
    // }

    //  const options = {
    //     method: "POST",
    //     headers: headers,
    //     body: JSON.stringify(data) 
    // };

    const options = {
        method: "GET",
        headers: headers,
        // body: JSON.stringify(data) 
    };


// return fetch(graphConfig.groupName+"71ef2e41-bd50-4cb2-80b9-f83b5daaf78b", options)
return fetch(graphConfig.graphMeEndpoint, options)
.then(response => response.json())
        .catch(error => console.log(error));

}

// import _ from "lodash"
import { useSelector } from "react-redux"

import * as api from "../../utlis/api"
import { buildReduxActionsAndSelectors } from ".."
import { handleStateDefault } from ".."

const FUNDINGPAYMENT_MODAL_REDUX_PREFIX = 'DP'

//Define all valid entity fields
const FUNDINGPAYMENT_VALID_ENTITY_FIELDS = {
  editedFundingPayment: [
    'status',
    'amount',
    'adminFee',
    'gapAmount',
    'fundingReserveAmount',
    'nettedPayoffAmount',
    'dateReceived',
    'paymentMethodId',
    'documentNumber'
  ]
}

const fundingPaymentsInitialState = {
  selectedFundingPaymentId: null,
  modal: {
    fundingPayment: {},
    fundingPaymentFocusId: '',
    editedFundingPayment: {},
    formattedAmount: '',
    formattedAdminFee: '',
    formattedGapAmount: '',
    formattedFundingReserveAmount: '',
    formattedNettedPayoffAmount: '',
    isEditMode: false,
    isOpen: false,
    openFundingPaymentModal: false,
    isValidForSubmit: false,
    lastClosedTimestamp: null,
    lastSaveTimestamp: null,
    showSuccessFeedback: false,
    statusInputValue: "",
    statusInputValueError: null,
    hasUnsavedChanges: false,
    errors: {
      editedFundingPayment: {},
    }
  },
  section: {
  }
}

//Generate actions and selectors based on initial state and build options
const buildOptions = {
  fields: FUNDINGPAYMENT_VALID_ENTITY_FIELDS,
  entities: [ 'editedFundingPayment' ],
  reduxPath: "fundingPayments.modal",
  reduxPrefix: FUNDINGPAYMENT_MODAL_REDUX_PREFIX,
  statePath: "modal"
}

export const rdx =
  buildReduxActionsAndSelectors(fundingPaymentsInitialState, buildOptions)

const useSelectedFundingPaymentId = () => {
  return useSelector(state => state.order.selectedFundingPaymentId)
}

rdx.useSelectedFundingPaymentId = useSelectedFundingPaymentId

/* CONSTANTS */
const RESET_FUNDINGPAYMENT_MODAL = 'RESET_FUNDINGPAYMENT_MODAL'

export const fundingPaymentsReducer = ( state=fundingPaymentsInitialState, action ) => {

  const __reduxPrefix = action.__prefix

  switch ( action.type ) {

    case RESET_FUNDINGPAYMENT_MODAL:

      return {
        ...state,
        modal: {
          ...fundingPaymentsInitialState.modal,
          //Keep the same action as before it was closed...
          lastClosedTimestamp: state.modal.lastClosedTimestamp
        }
      }
    default:
      //Handle all generated state updates...
      if ( __reduxPrefix === FUNDINGPAYMENT_MODAL_REDUX_PREFIX) {
        return handleStateDefault(state, action)
      }
  }

  return state
}

rdx.deleteFundingPayment = (orderId, fundingPaymentId) => {

  return dispatch => {
    api.deleteFundingPayment(orderId, fundingPaymentId)
    .then(() => {
      dispatch(rdx.setLastSaveTimestamp(new Date().getTime()))
    })
  }
}

rdx.resetFundingPaymentModal = () => {
  return {
    type: RESET_FUNDINGPAYMENT_MODAL
  }
}

rdx.saveNewFundingPayment = ( orderId, fundingPaymentObj ) => {

  return dispatch => {
    api.saveNewFundingPayment(orderId,fundingPaymentObj)
    .then(res => {
      dispatch(rdx.setFundingPaymentFocusId(res.data?.id))
      setTimeout(() => {
        dispatch(rdx.showSuccessFeedback())
      }, 2000)
      setTimeout(() => {
      }, 2000)
    })
  }
}

rdx.updateFundingPayment = ( orderId, fundingPaymentId, fundingPaymentObj ) => {
  return dispatch => {

    api.updateFundingPayment(orderId, fundingPaymentId, fundingPaymentObj)
    .then( res => {
      //Success feedback
      dispatch(rdx.showSuccessFeedback())
    })
  }
}

rdx.getPaymentMethods = ( ) => {
  // return dispatch => {

    // var thing = api.getPaymentMethods()
    // return thing;
  // }
}

rdx.showSuccessFeedback = () => {
  return dispatch => {
    dispatch(rdx.setShowSuccessFeedback(true))
    dispatch(rdx.setHasUnsavedChanges(false))
    // setTimeout(() => {
    //   dispatch(rdx.setShowSuccessFeedback(false))
    // }, 2000)
  }
}

export default rdx

import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const CABillOfSaleGlobalAutofill = (documentValues, order, property=null, value=null) => {
    var newValues = documentValues;
  
  if(property==='customer.name'){
    newValues.buyer_name = value;
    newValues.copy_buyer_name = value;
    if(order.customer.cosigners && order.customer.cosigners.length > 0){
      let cosigner = order.customer.cosigners[0];
      if(cosigner && cosigner.name){
        newValues.buyer_name += " AND "+cosigner.name;
        newValues.copy_buyer_name += " AND "+cosigner.name;
      }
      }
  }
  if(property==='customer.cosigners'){
    newValues.buyer_name = order.customer.name;
    newValues.copy_buyer_name = order.customer.name;
    if(value && value.length > 0){
      let cosigner = value[0];
      if(cosigner && cosigner.name){
        newValues.buyer_name += " AND "+cosigner.name;
        newValues.copy_buyer_name += " AND "+cosigner.name;
      }
      }
  }
  if(property==='documentationDate'){
    newValues.buy_date = moment.utc(value).format("YYYY-MM-DD");
    newValues.sell_date = moment.utc(value).format("YYYY-MM-DD");
    newValues.sell_date_2 = moment.utc(value).format("YYYY-MM-DD");
    newValues.copy_sell_date = moment.utc(value).format("YYYY-MM-DD");
    newValues.copy_sell_date_2 = moment.utc(value).format("YYYY-MM-DD");
  }

  if(property !== null && value !== null){
    return newValues;
}
      newValues.seller_name = "TEC OF CALIFORNIA, INC";
      newValues.copy_seller_name = "TEC OF CALIFORNIA, INC";
      if(order && order.customer && order.customer.name){
        newValues.buyer_name = order.customer.name;
        newValues.copy_buyer_name = order.customer.name;
      }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          newValues.buyer_name += " AND "+cosigner.name;
          newValues.copy_buyer_name += " AND "+cosigner.name;
        }
      }
  
      if(order && order.documentationDate){
        newValues.buy_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
        newValues.sell_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
        newValues.sell_date_2 = moment.utc(order.documentationDate).format("YYYY-MM-DD");
        newValues.copy_sell_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
        newValues.copy_sell_date_2 = moment.utc(order.documentationDate).format("YYYY-MM-DD");
      }
  
      return newValues
}

export const CABillOfSaleAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
    var newValues = documentValues;
    
    if(property === 'year' && value !== null){
      newValues.year = (value).toString().trim().replace(/['"]+/g, '');
  }

  if(property === 'make' && value !== null){
      newValues.make = (value).toString().trim();
      newValues.copy_make = (value).toString().trim();  
  }

  if(property === 'model' && value !== null){
      newValues.model = (value).toString().trim();
      newValues.copy_model = (value).toString().trim();
    }

  if(property === 'price' && value !== null){
      newValues.sales_price =  formatter.format((value).toString().trim());
      newValues.copy_selling_price = formatter.format((value).toString().trim());
    }

  if(property !== null && value !== null){
      return newValues;
  }


  if(selectedProduct){

    newValues.make = (selectedProduct.make).trim();
    newValues.model = (selectedProduct.year);
    newValues.identification_number = (selectedProduct.vin.toUpperCase());
    newValues.selling_price = formatter.format(selectedProduct.price);;
    
    newValues.copy_make = (selectedProduct.make).trim();
    newValues.copy_model = (selectedProduct.year);
    newValues.copy_identification_number = (selectedProduct.vin.toUpperCase());
    newValues.copy_selling_price = formatter.format(selectedProduct.price);;
  }
    
  
    return newValues
}

export const CABillOfSaleAutofillTradeDetails = (selectedTradein, documentValues, property=null, value= null)=>{
    var newValues = documentValues;
    if(property === 'tradein.make' && value !== null){
      newValues.make = (value).toString().trim();
      newValues.copy_make = (value).toString().trim();  
    }

    if(property === 'tradein.year' && value !== null){
      newValues.model = (value).toString().trim();
      newValues.copy_model = (value).toString().trim();
    }

    if(property !== null && value !== null){
      return newValues;
    }

    if(selectedTradein){
      newValues.make = (selectedTradein.make).trim();
      newValues.model = (selectedTradein.year)
      newValues.identification_number = (selectedTradein.vin.toUpperCase());
      
      newValues.copy_make = (selectedTradein.make).trim();
      newValues.copy_model = (selectedTradein.year)
      newValues.copy_identification_number = (selectedTradein.vin.toUpperCase());
    }
    
  
    return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  // X1.2- Field Type Specific Preview Formatting
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });

  if(payload.selling_price){
    payload.selling_price = payload['selling_price'].replace("$","");
  }

  if(payload.gift_value){
    payload.gift_value = payload['gift_value'].replace("$","");
  }
  
  payload.copy_identification_number = payload.identification_number;
  payload.copy_model = payload.model;
  payload.copy_make = payload.make;
  payload.copy_license_plate = payload.license_plate;
  payload.copy_mc_engine_number = payload.mc_engine_number;
  payload.copy_month = payload.month;
  payload.copy_day = payload.day;
  payload.copy_year = payload.year;
  payload.copy_selling_price = payload.selling_price;
  payload.copy_gift_value = payload.gift_value;
  payload.copy_relationship_type = payload.relationship_type;
  payload.copy_seller_name = payload.seller_name;
  payload.copy_seller_name_signature = payload.seller_name_signature;
  payload.copy_seller_name_signature_2 = payload.seller_name_signature_2;
  payload.copy_seller_id = payload.seller_id;
  payload.copy_seller_id_2 = payload.seller_id_2;
  payload.copy_sell_date = payload.sell_date;
  payload.copy_sell_date_2 = payload.sell_date_2;
  payload.copy_seller_address = payload.seller_address;
  payload.copy_seller_city = payload.seller_city;
  payload.copy_seller_state = payload.seller_state;
  payload.copy_seller_zip = payload.seller_zip;
  payload.copy_seller_phone = payload.seller_phone;
  payload.copy_buyer_name = payload.buyer_name;
  payload.copy_buyer_print_name = payload.buyer_print_name;
  payload.copy_buyer_print_name_2 = payload.buyer_print_name_2;
  payload.copy_buyer_address = payload.buyer_address;
  payload.copy_buyer_city = payload.buyer_city;
  payload.copy_buyer_state = payload.buyer_state;
  payload.copy_buyer_zip = payload.buyer_zip;

  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });
  
  if(payload.buy_date){
    payload.month = moment.utc(payload.buy_date).format("MM");
    payload.day = moment.utc(payload.buy_date).format("DD");
    payload.year_buy_date = moment.utc(payload.buy_date).format("YYYY");
    payload.copy_month = moment.utc(payload.buy_date).format("MM");
    payload.copy_day = moment.utc(payload.buy_date).format("DD");
    payload.copy_year_buy_date = moment.utc(payload.buy_date).format("YYYY");
  }

  if(payload.sell_date){
    payload.sell_date = moment.utc(payload.sell_date).format("MM/DD/YY");
  }

  if(payload.sell_date_2){
    payload.sell_date_2 = moment.utc(payload.sell_date_2).format("MM/DD/YY");
  }

  if(payload.copy_sell_date){
    payload.copy_sell_date = moment.utc(payload.copy_sell_date).format("MM/DD/YY");
  }

  if(payload.copy_sell_date_2){
    payload.copy_sell_date_2 = moment.utc(payload.copy_sell_date_2).format("MM/DD/YY");
  }

  return payload;
}
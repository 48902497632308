import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";
import { formatter } from './docUtils.js';


export const TECCaliforniaSalesTaxExemptionCertificateGlobalAutofill  = (documentValues, order, property=null, value=null, location) => {
    var newValues = documentValues;

    if(property ==='customer.name'){
      newValues.company_name = value;
      newValues.purchaser_name = value;
      if(order.customer.dba){
        newValues.company_name += " dba "+order.customer.dba;
        newValues.purchaser_name += " dba "+order.customer.dba;
      }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          newValues.purchaser_name += " AND "+cosigner.name;
        }
      }
  }
    if(property ==='customer.dba'){
      newValues.company_name = order.customer.name;
      newValues.purchaser_name = order.customer.name;
      if(value){
        newValues.company_name += " dba "+value;
        newValues.purchaser_name += " dba "+value;
      }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          newValues.purchaser_name += " AND "+cosigner.name;
        }
      }
  }
    if(property ==='customer.cosigners'){
      newValues.company_name = order.customer.name;
      newValues.purchaser_name = order.customer.name;
      if(order.customer.dba){
        newValues.company_name += " dba "+order.customer.dba;
        newValues.purchaser_name += " dba "+order.customer.dba;
      }else if(value && value.length > 0){
        let cosigner = value[0];
        if(cosigner && cosigner.name){
          newValues.purchaser_name += " AND "+cosigner.name;
        }
      }
  }
    if(property ==='location'){
        newValues.location_address = value.address+" "+value.city+" "+value.state+" "+value.zip;
        newValues.location_phone = value.phone;
        newValues.location_email = value.email;
        if(value.state === "CA"){
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
        }else{
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
        }
  }
    if(property ==='documentationDate'){
      newValues.date = moment.utc(value).format("YYYY-MM-DD");
  }
    if(property !== null && value !== null){
      return newValues;
  }

    if(order && order.customer && order.customer.name){
      newValues.company_name = order.customer.name;
      newValues.purchaser_name = order.customer.name;

      if(order.customer.dba){
        newValues.company_name += " dba "+order.customer.dba;
        newValues.purchaser_name += " dba "+order.customer.dba;
      }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          newValues.purchaser_name += " AND "+cosigner.name;
        }
      }
    }

    if(location !== undefined){
      newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
    }

    if(location && location.phone){
      newValues.location_phone = location.phone;
    }
    
    if(location && location.email){
      newValues.location_email = location.email;
    }

    if(location){
      if(location.state === "CA"){
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
      }else{
      newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
      }
    }

    if(order && order.documentationDate && documentValues.date === undefined){
      newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }
    return newValues
}

export const TECCaliforniaSalesTaxExemptionCertificateAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null, location=null, order=null, autofillProductDetails=true) => {
    var newValues = documentValues;

    if(property === 'year' && value !== null){
      newValues.year = (value).toString().trim().replace(/['"]+/g, '');
  }

  if(property === 'make' && value !== null){
      newValues.make = (value).toString().trim();
  }

  if(property === 'model' && value !== null){
      newValues.model = (value).toString().trim();
  }

  if(property === 'price' && value !== null){
      newValues.sales_price =  formatter.format(value);
  }

  if(property !== null && value !== null){
      return newValues;
  }

    newValues.year = (selectedProduct.year).trim();
    newValues.make = (selectedProduct.make).trim();
    newValues.model = (selectedProduct.model).trim().toString().toUpperCase();
    if(autofillProductDetails){
      newValues.vin = (selectedProduct.vin);
    }    
    newValues.sale_price = formatter.format(selectedProduct.price);

    return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {
    
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });
  
  if(payload.date){
    payload.date = moment.utc(payload.date).format("MM/DD/YY");
  }

  return payload;
}
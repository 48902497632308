import React from "react";
import { useOrder } from "../../../../../../redux/orders/order"
import { formatter } from "../../../../../../utlis/formatters";

const TradeinSummary = () => {
    const order = useOrder();
    // var formatter = new Intl.NumberFormat('en-US', {
    //     style: 'currency',
    //     currency: 'USD',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    //   });


      function calculateTradeInCredit(tradein){
        return (tradein.tradeAllowanceAtTerms - tradein.balanceOwed - tradein.cashRefundToCustomer);
      }

    return (
    <>
    { order && order.tradeins && order.tradeins.length > 0 ? (
        <div className="col-12">
            <div className="shadow-sm border-start border-5 border-primary p-2" style={{ fontSize: '12px'}}>
                <h5 className="p-2">TRADE IN SUMMARY</h5>
                <div className="row px-4">
                    <div className="col-12 d-flex justify-content-between" style={{ fontSize: '12px'}}>
                    <h6 className="text-uppercase col-md-2 px-1 mb-0" style={{ fontWeight: 'bolder', fontSize: '12px' }}>YMM</h6>
                    <h6 className="text-uppercase col-md-2 px-1 mb-0" style={{ fontWeight: 'bolder', fontSize: '12px' }}>VIN</h6>
                    <h6 className="text-uppercase col-md-2 px-1 mb-0" style={{ textAlign: 'right', fontWeight: 'bolder', fontSize: '12px' }}>PAY OFF AMT.</h6>
                    <h6 className="text-uppercase col-md-2 px-1 mb-0" style={{ textAlign: 'right', fontWeight: 'bolder', fontSize: '12px' }}>TRADE IN VALUE</h6>
                    <h6 className="text-uppercase col-md-2 px-1 mb-0" style={{ textAlign: 'right', fontWeight: 'bolder', fontSize: '12px' }}>REFUND TO CUST.</h6>
                    <h6 className="text-uppercase col-md-2 px-1 mb-0" style={{ textAlign: 'right', fontWeight: 'bolder', fontSize: '12px' }}>(NET TRADE) / OA</h6>
                </div>
                <hr style={{ margin: '5px'}}></hr>
            </div>
            <div className="row">
                <div className="col-12">
                    {order && order.tradeins ? (order.tradeins.sort((a, b) => a.vin?.localeCompare(b.vin, undefined, { numeric: true })).map(tradein => (
                    <div className="row px-4" key={'t'+tradein.id}>
                        <div className="col-12 d-flex justify-content-between" style={{ fontSize: '12px', fontWeight: '400'}}>
                            <h6 className="text-uppercase col-md-2 px-1" style={{ fontSize: '12px', fontWeight: '400' }}>{ tradein.year } { tradein.make } { tradein.model }</h6>
                            <h6 className="text-uppercase col-md-2 px-1" style={{ fontSize: '12px', fontWeight: '400' }}>{ tradein.vin }</h6>
                            <h6 className="text-uppercase col-md-2 px-1" style={{ textAlign: 'right', fontSize: '12px', fontWeight: '400' }}>{ formatter.format(tradein.balanceOwed) }</h6>
                            <h6 className="text-uppercase col-md-2 px-1" style={{ textAlign: 'right', fontSize: '12px', fontWeight: '400' }}>{ formatter.format(tradein.tradeAllowanceAtTerms) }</h6>
                            <h6 className="text-uppercase col-md-2 px-1" style={{ textAlign: 'right', fontSize: '12px', fontWeight: '400' }}>{ formatter.format(tradein.cashRefundToCustomer) }</h6>
                            { calculateTradeInCredit(tradein) < 0 ? (
                            <h6 className="text-uppercase col-md-2 px-1" style={{ textAlign: 'right', fontSize: '12px', fontWeight: '400' }}>{ formatter.format(Math.abs(calculateTradeInCredit(tradein))) }</h6>
                            ):(
                            <h6 className="text-uppercase col-md-2 px-1" style={{ textAlign: 'right', fontSize: '12px', fontWeight: '400' }}>({ formatter.format(calculateTradeInCredit(tradein)) })</h6>
                            )}
                            </div>
                    </div>
                    ))):('')}
                </div>
            </div>
            </div>
        </div>
    ) : ('')}
    </>
    );
};
  
export default TradeinSummary;
  

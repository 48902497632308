import React from 'react'
import { Card, Dropdown } from "react-bootstrap";
import { Menu } from "@material-ui/icons";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { formatter } from "../../../../../../utlis/formatters";

import { ArrowLeft } from '@material-ui/icons';
import { ArrowDropDown } from '@material-ui/icons';

const ProductInfoTemplate = ({ eventKey, children, salePrice, ymm, stockNum, subTotal, isOpen, setIsOpen, selectedProduct }) => {
    // var sectionState = {
    //   // to share the state, we must maintain it ourselves
    //   open: false
    // };

    // var formatter = new Intl.NumberFormat('en-US', {
    //   style: 'currency',
    //   currency: 'USD',
    // });


const handleToggle = useAccordionButton(eventKey, ()=>{
  if(isOpen.selectedProduct === eventKey){
    setIsOpen((prev)=>({
      ...prev,
      selectedProduct: undefined
    }))
  }else{
    setIsOpen((prev)=>({
      ...prev,
      selectedProduct: eventKey
    }))
  }
})

  return (
    <Card.Header style={{ backgroundColor: '#fff', border: 'none', borderRadius: '0px'}}>
      <div className='row'>
        <div className="d-flex align-items-center justify-content-between p-2">
          <Dropdown style={{ display: 'inline-table' }}>
            <Dropdown.Toggle style={{ backgroundColor: '#fff', color: 'black', border: 'none', paddingTop: '1px' }}>
              <Menu />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              { children }
            </Dropdown.Menu>
          </Dropdown>
          <div>
            <h5 className="text-uppercase">{ ymm }</h5>
            <p className="mx-0 text-secondary pt-3">YMM</p>
          </div>
          <div>
            <h5 className="text-uppercase">{ stockNum }</h5>
            <p className="mx-0 text-secondary pt-3">Stock #</p>
          </div>
          <div>
            <h5 className="text-uppercase">{ formatter.format(salePrice) }</h5>
            <p className="mx-0 text-secondary pt-3">Sales Price</p>
          </div>
          <div>
            <h5 className="text-uppercase">{ subTotal }</h5>
            <p className="mx-0 text-secondary pt-3">Subtotal</p>
          </div>
          {/* <CustomToggle eventKey={eventKey} tabOpen={sectionState.open}>
            { eventKey===selectedProduct ? ( <ArrowDropDown /> ):( <ArrowLeft /> ) }
          </CustomToggle> */}
          <button
      type="button"
      style={{ backgroundColor: '#fff', color: 'grey', border: 'none', maxWidth: '25px', float: 'right' }}
      onClick={(e)=>handleToggle(eventKey)}
    >
      { (eventKey===selectedProduct && isOpen.product) ? ( <ArrowDropDown /> ) :( <ArrowLeft /> ) }
    </button>
        </div>
      </div>
    </Card.Header>
  );
};

// function CustomToggle({ children, eventKey, tabOpen, sectionState }) {

//   var decoratedOnClickx = useAccordionButton(eventKey, () => {
//     tabOpen = !tabOpen;
//   });

//   return (
//     <button
//       type="button"
//       style={{ backgroundColor: '#fff', color: 'grey', border: 'none', maxWidth: '25px', float: 'right' }}
//       onClick={(e)=>handleToggle(eventKey)}
//     >
//       { tabOpen.open ? ( <ArrowDropDown /> ) :( <ArrowLeft /> ) }
//     </button>
//   );
// }

export default ProductInfoTemplate;

import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";
import { autoCountYMM } from './docUtils.js';

export const TECDoAGlobalAutofill = (documentValues, order, property=null, value=null, location) => {

  
    var newValues = documentValues;
    
    if(property ==='customer.name'){
      if(order.customer.type === "entity"){
        newValues.customer_line_1 = value;
        newValues.printed_name = value;
      }else{
        if(order.customer.dba){
          newValues.printed_name = value+" dba "+order.customer.dba;
          newValues.customer_line_1 = value+" dba "+order.customer.dba;
        }else{
          newValues.printed_name = value;
          newValues.customer_line_1 = value;
        } 
      }
      
      if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          newValues.printed_name += " AND "+cosigner.name;
        }
      }
  }
    if(property ==='customer.dba'){
      if(order.customer.type === "entity"){
        newValues.customer_line_1 = order.customer.name;
        newValues.printed_name = order.customer.name;
      }else{
        if(value){
          newValues.printed_name = order.customer.name+" dba "+value;
          newValues.customer_line_1 = order.customer.name+" dba "+value;
        }else{
          newValues.printed_name = order.customer.name;
          newValues.customer_line_1 = order.customer.name;
        } 
      }
      
      if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          newValues.printed_name += " AND "+cosigner.name;
        }
      }
  }
    if(property ==='customer.cosigners'){
      if(order.customer.type === "entity"){
        newValues.customer_line_1 = order.customer.name;
        newValues.printed_name = order.customer.name;
      }else{
        if(order.customer.dba){
          newValues.printed_name = order.customer.name+" dba "+order.customer.dba;
          newValues.customer_line_1 = order.customer.name+" dba "+order.customer.dba;
        }else{
          newValues.printed_name = order.customer.name;
          newValues.customer_line_1 = order.customer.name;
        } 
      }
      
      if(value && value.length > 0){
        let cosigner = value[0];
        if(cosigner && cosigner.name){
          newValues.printed_name += " AND "+cosigner.name;
        }
      }
  }
    if(property ==='customer.deliveryAddress.addressLine1'){
      let addressLine = '';
        if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
          addressLine = (value+" "+order.customer.deliveryAddress.addressLine2);
        }else{
          addressLine = value;
        }
  
        newValues.customer_line_2 = addressLine
  }
    if(property ==='customer.deliveryAddress.addressLine2'){
      let addressLine = '';
        if(value != null && value !== undefined && value !== ''){
          addressLine = (order.customer.deliveryAddress.addressLine1+" "+value);
        }else{
          addressLine = order.customer.deliveryAddress.addressLine1;
        }
  
        newValues.customer_line_2 = addressLine
  }
    if(property ==='customer.deliveryAddress.city'){
      newValues.customer_line_3 = value+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
  }
    if(property ==='customer.deliveryAddress.state'){
      newValues.customer_line_3 = order.customer.deliveryAddress.city+", "+value+" "+order.customer.deliveryAddress.zip;
  }
    if(property ==='customer.deliveryAddress.zip'){
      newValues.customer_line_3 = order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+value;
  }
    if(property ==='location'){
        newValues.location_address = value.address+" "+value.city+" "+value.state+" "+value.zip;
        newValues.location_phone = value.phone;
        newValues.location_email = value.email;
        if(value.state === "CA"){
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
        }else{
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
        }
  }
    if(property ==='documentationDate'){
      newValues.date = moment.utc(value).format("YYYY-MM-DD");
  }

    if(property !== null && value !== null){
      return newValues;
  }

    if(order && order.customer && order.customer.name){
      if(order.customer.type === "entity"){
        newValues.customer_line_1 = order.customer.name;
        newValues.printed_name = order.customer.name;
      }else{
        if(order.customer.dba){
          newValues.printed_name = order.customer.name+" dba "+order.customer.dba;
          newValues.customer_line_1 = order.customer.name+" dba "+order.customer.dba;
        }else{
          newValues.printed_name = order.customer.name;
          newValues.customer_line_1 = order.customer.name;
        } 
      }
      
      if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          newValues.printed_name += " AND "+cosigner.name;
        }
      }
  
      if(order.customer.deliveryAddress){
        var addressLine = '';
        if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
          addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
        }else{
          addressLine = order.customer.deliveryAddress.addressLine1;
        }
  
        newValues.customer_line_2 = addressLine
        newValues.customer_line_3 = order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
      }
    }
  
      if(location !== undefined){
        newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
      }
    
      if(location && location.phone){
        newValues.location_phone = location.phone;
      }
      
      if(location && location.email){
        newValues.location_email = location.email;
      }
    
      if(location){
        if(location.state === "CA"){
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
        }else{
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
        }
      }
  
      if(order && order.documentationDate && documentValues.date === undefined){
        newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
      }
  
    return newValues
}

export const TECDoAAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null, location=null, order=null, autofillProductDetails=true, productCount=0) => {
    var newValues = documentValues;

    if(property === 'year' && value !== null){
      if(autofillProductDetails){
        newValues.as_a_purchaser_line_1 = autoCountYMM((value).toString().trim(), (selectedProduct.make).trim(), (selectedProduct.model).toString().toUpperCase());
      }else{
        newValues.as_a_purchaser_line_1 = autoCountYMM((value).toString().trim(), (selectedProduct.make).trim(), (selectedProduct.model).toString().toUpperCase(), productCount);
      }
  }

  if(property === 'make' && value !== null){
      if(autofillProductDetails){
        newValues.as_a_purchaser_line_1 = autoCountYMM((selectedProduct.year).trim(), (value).toString().trim(), (selectedProduct.model).toString().toUpperCase());
      }else{
        newValues.as_a_purchaser_line_1 = autoCountYMM((selectedProduct.year).trim(), (value).toString().trim(), (selectedProduct.model).toString().toUpperCase(), productCount);
      }
  }

  if(property === 'model' && value !== null){
      if(autofillProductDetails){
        newValues.as_a_purchaser_line_1 = autoCountYMM((selectedProduct.year).trim(), (selectedProduct.make).trim(), (value).toString().trim());
      }else{
        newValues.as_a_purchaser_line_1 = autoCountYMM((selectedProduct.year).trim(), (selectedProduct.make).trim(), (value).toString().trim(), productCount);
      }
  }

  if(property !== null && value !== null){
      return newValues;
  }

    if(autofillProductDetails){
      newValues.as_a_purchaser_line_1 = (selectedProduct.year).trim()+" "+(selectedProduct.make).trim()+" "+(selectedProduct.model).toString().toUpperCase();
      newValues.as_a_purchaser_line_2 = (selectedProduct.vin).toString().toUpperCase();
    }else{
      newValues.as_a_purchaser_line_1 = "("+productCount+") "+(selectedProduct.year).trim()+" "+(selectedProduct.make).trim()+" "+(selectedProduct.model).toString().toUpperCase();
    }
    

    return newValues
}

export const TECDoAAutofillTradeDetails = (selectedTradein, documentValues, property=null, value= null)=>{
    var newValues = documentValues;

    if(property === 'tradein.year' && value !== null){
      newValues.as_a_purchaser_line_1 = autoCountYMM((value).toString().trim(), (selectedTradein.make).trim(), (selectedTradein.model).toString().toUpperCase());
    }

    if(property === 'tradein.make' && value !== null){
      newValues.as_a_purchaser_line_1 = autoCountYMM((selectedTradein.year).trim(), (value).toString().trim(), (selectedTradein.model).toString().toUpperCase());
    }

    if(property === 'tradein.model' && value !== null){
      newValues.as_a_purchaser_line_1 = autoCountYMM((selectedTradein.year).trim(), (selectedTradein.make).trim(), (value).toString().trim());
    }

    if(property === 'tradein.vin' && value !== null){
      newValues.as_a_purchaser_line_2 = value.toUpperCase();
    }

    if(property !== null && value !== null){
      return newValues;
    }

    newValues.as_a_purchaser_line_1 = (selectedTradein.year).trim()+" "+(selectedTradein.make).trim()+" "+(selectedTradein.model).toString().toUpperCase();
    newValues.as_a_purchaser_line_2 = (selectedTradein.vin.toUpperCase());
    
    return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  // X1.2- Field Type Specific Preview Formatting
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  // Data point formatting
  if(payload.date){
    payload.date = moment.utc(payload.date).format("MM/DD/YY");
  }

  return payload;
}
import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash"
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Box } from '@mui/material';
import {
  DataGrid,
  GridToolbarFilterButton
} from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';

import * as api from '../../../../../../../utlis/api'
import { recordFrontEndError } from '../../../../../../../utlis/api'
import { updateChangesArray } from "../../../../../../../redux/saveAndCopy"

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
  return (
      <Box
      sx={{
          p: 0.5,
          pb: 0,
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'flex-start',
          flexWrap: 'wrap',
      }}
      >
      <div className="w-100">
          <GridToolbarFilterButton />
      </div>
      <TextField
          variant="standard"
          value={props.value}
          data-testid="product.financing.addLendersModal.control.lenderSearchInput"
          onChange={props.onChange}
          placeholder="Search…"
          InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
              <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
              >
              <ClearIcon fontSize="small" />
              </IconButton>
          ),
          }}
          sx={{
          width: {
              xs: 1,
              sm: 'auto',
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          '& .MuiSvgIcon-root': {
              mr: 0.5,
          },
          '& .MuiInput-underline:before': {
              borderBottom: 1,
              borderColor: 'divider',
          },
          }}
      />
      </Box>
  );
}

QuickSearchToolbar.propTypes = {
clearSearch: PropTypes.func.isRequired,
onChange: PropTypes.func.isRequired,
value: PropTypes.string.isRequired,
};

function ProductFinanceModal({product, resetCheckboxesVal, resetProductFinanceFunc, updateLenderInfoFromChild}) {

  let { id }      = useParams();
  const history   = useHistory();
  const dispatch = useDispatch();

  const [ searchText, setSearchText ]                 = useState('');   //Redux
  const [ rows, setRows ]                             = useState(null); //Redux
  const [ lenders, setLenders ]                       = useState(null); //Redux
  const [ topLenders, setTopLenders ]                 = useState(null); //Redux
  const [ selections, setselections ]                 = useState([]);   //Redux
  const [ resetCheckboxes, setresetCheckboxes ]       = useState([]);   //Redux
  const [ productLenders, setproductLenders ]         = useState(null); //Redux
  const [ lenderIdIndexes, setlenderIdIndexes ]       = useState([]); //Redux
  const [ listMode, setListMode ]       = useState('a-to-z'); //Redux
  
  const appData = useSelector((state) => state?.appData);

  const [dataChangeArray, setDataChangeArray]   = useState([]);
  function updateReduxChangeArray(prop=null, value=null){
    // e.preventDefault();

    // const { name: prop, value} = e.target;
    if(prop === null || value === null){
      return;
    }

    let doNotCopyProperties = [];

    if(dataChangeArray && !doNotCopyProperties.includes(prop)){

      if(dataChangeArray[prop]?.oldValue){

        setDataChangeArray((prev)=>({
          ...prev,
          [prop]: {
            "newValue": value,
            "oldValue": dataChangeArray[prop].oldValue
          }
        }))
        
      }else{
        const oldValue = product?.fobAddress[prop];

        setDataChangeArray((prev)=>({
          ...prev,
          [prop]: {
            "newValue": value,
            "oldValue": oldValue ? oldValue : '--'
          }
        }))

      }
    }
  }

  useEffect(() => {

    if(appData?.activeModal === 'product' && appData?.activeModalTab === 'financing'){
      dispatch(updateChangesArray(dataChangeArray));
    }

  }, [ appData, dataChangeArray, dispatch ])

  const getLenders = useCallback(async () => {
    try{
      let response = await api.getLenders()
      if (response){
        setRows(response.data);
        setLenders(response.data);
      }
      }catch(err){
        recordFrontEndError('ProductFinanceModal.jsx', 'Error getting lenders: '+ err)
    }
  },[setRows,setLenders])

  const getTopLenders = useCallback(async () => {
    try{
      let response = await api.getTopLenders()
      if (response){
        // setRows(response.data);
        setTopLenders(response.data);
      }
      }catch(err){
        recordFrontEndError('ProductFinanceModal.jsx', 'Error getting lenders: '+ err)
    }
  },[setTopLenders])

  const getProductLenders = useCallback(async () => {

    //revist try catch
    if(id && product.id){
      try {
        let response = await api.getProductLenderOption(id, product.id)
        setproductLenders(response.data);
      } catch (err) {
        recordFrontEndError('ProductFinanceModal.jsx', 'Error getting product lenders: '+ err)
      }
    }
    

  },[setproductLenders,product.id,id])

  const addLenders = async (e, lenders) => {
    lenders.forEach(async lender => {
      try{
          await api.addLender(id, product.id,lender.id)
          setlenderIdIndexes([...lenderIdIndexes, lender.id]);
          history.push(`/order/${id}`);
          getSelectedLenderIndexes([...productLenders, lender]);
        }catch(err){
          recordFrontEndError('ProductFinanceModal.jsx', 'Error adding lender: '+ err)
        }
        getProductLenders();
      })
      // pass information to update lender list in ProductFinance
      updateLenderInfoFromChild(true);
    setresetCheckboxes([]);
    setSearchText('');
  }
   

  const columns = [
    { field: 'code', headerName: 'Code', width: 130},
    { 
      field: 'fullLienNameAddress', 
      headerName: 'Bank Name and Address', 
      width: 275,
      renderCell: (params) => {
        return (
          <div>
            <div>{params.row.bankName}</div>
            <div>{params.row.lienAddress}</div>
            <div>{params.row.lienCity}, {params.row.lienState}, {params.row.lienZip}</div>
          </div>
        )
      }
    },
    { field: 'lossPayeeCode', headerName: 'Loss Payee Code', width: 140},
    { 
      field: 'fullLossNameAddress', 
      headerName: 'Payee Loss Name and Address', 
      width: 275,
      renderCell: (params) => {
        return (
          <div>
            <div>{params.row.lossPayee}</div>
            <div>{params.row.lossPayeeAddress}</div>
            <div>{params.row.lossPayeeCity}, {params.row.lossPayeeState}, {params.row.lossPayeeZip}</div>
          </div>
        )
      }
    },
  ];

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = lenders.filter((row) => {
      return Object.keys(row).some((field) => {
          if(field === "code") {
            if(row[field] !== null){
              return searchRegex.test(row[field].toString());
             }
          }return null
      });
    });
    setRows(filteredRows);
  };

  const getSelectedLenderIndexes =useCallback((productLenderVals) => {
    if(productLenderVals && productLenderVals.length > 0) {
      let lenderIdIndexesVals = [];
      rows.forEach((lender, index) => {
        productLenderVals.forEach((productLender, productIndex) => {
          if(lender.lossPayeeCode === productLender.lossPayeeCode) {
            lenderIdIndexesVals.push(lender.id);
          }
        })
      })
      setlenderIdIndexes(lenderIdIndexesVals);
    }
  },[setlenderIdIndexes,rows])

  const resetVals = e => {
    setlenderIdIndexes(null);
    setproductLenders(null);
  }

  //Effect to fetch product lenders...
  useEffect(() => {
    resetProductFinanceFunc.current = resetVals;
    // get lenders selected for product
    if ( _.isNil( productLenders ) && product?.id) {
      getProductLenders();
    }
  }, [ productLenders, product.id, getProductLenders, resetProductFinanceFunc ])

  //Effect to fetch lenders when the component mounts...
  useEffect(() => {
    if(lenders == null){
      getLenders();
    }
  }, [lenders,getLenders])

  useEffect(() => {
    if(topLenders == null){
      getTopLenders();
    }
  }, [topLenders, getTopLenders])

  //Effect to fetch lenders when `resetCheckboxes` is set to true...
  useEffect(() => {
    //DataGrid selectionModel unchecks boxes when
    //value is [], then allows selection when value is null
    if ( resetCheckboxes && _.isEmpty( resetCheckboxes ) ) {
      setresetCheckboxes(resetCheckboxesVal);// reset rows to all lenders
      getLenders();
    }

  }, [ resetCheckboxes,getLenders, resetCheckboxesVal ])

  //Effect to set the lenderIdIndexes based on the productLenders
  useEffect(() => {
    if( _.isNil( lenderIdIndexes) && _.isEmpty( productLenders ) === false ) {
      getSelectedLenderIndexes(productLenders);
    }
  }, [ lenderIdIndexes, productLenders,getSelectedLenderIndexes ])

  return (

    <>
    { !product?.id ? null : (
      <div
        className="modal fade"
        id="productFinanceModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header bg-primary my-0 py-1">
              <div className="col-12 d-flex">
                <h5
                  className="modal-title text-white col-3 my-2"
                  id="exampleModalLabel"
                >
                  Add Lender
                </h5>
                <button
                  type="button"
                  data-testid="product.financing.lendersModal.control.close"
                  className="btn-close bg-white mt-2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  data-bs-toggle="modal"
                  data-bs-target="#productModal"
                  onClick={(e) => {
                    setresetCheckboxes([]);
                    setSearchText('');
                  }
                }
                ></button>
              </div>
            </div>
            <div className="modal-body"  style={{height: "650px"}}>
                <div className="container">
                    <div className="row border-bottom">
                        <div className="d-flex text-capitalize">
                            <div className="my-2">
                              <h5>Lenders</h5>
                              <button
                                type="button"
                                data-testid="product.financing.addLendersModal.control.sortAtoZ"
                                className="btn btn-sm btn-primary mx-2 px-2"
                                aria-label="Sort A to Z"
                                disabled={listMode === 'a-to-z'}
                                onClick={(e) => {
                                  setRows(lenders);
                                  setListMode('a-to-z');
                                }
                              }
                              >A to Z</button>
                              <button
                                type="button"
                                data-testid="product.financing.addLendersModal.control.topLenders"
                                className="btn btn-sm btn-primary mx-2 px-2"
                                aria-label="Top Lenders"
                                disabled={listMode === 'top'}
                                onClick={(e) => {
                                  setRows(topLenders);
                                  setListMode('top');
                                }
                              }
                              >Top Lenders</button>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid row mt-4">
                        <Box sx={{ height: 450, width: 1 }}>
                            <DataGrid
                                components={{ Toolbar: QuickSearchToolbar }}
                                rows={rows?? ''}
                                columns={columns}
                                componentsProps={{
                                    toolbar: {
                                        value: searchText,
                                        onChange: (event) => requestSearch(event.target.value),
                                        clearSearch: () => requestSearch(''),
                                    },
                                }}
                                disableColumnFilter
                                onSelectionChange={(newSelection) => {
                                    setselections(newSelection.rows);
                                }}
                                checkboxSelection
                                onSelectionModelChange={(ids) => {
                                    const selectedIDs = new Set(ids);
                                    const selectedRows = rows.filter((row) =>
                                        selectedIDs.has(row.id),
                                    );
                                    setselections(selectedRows);
                                }}
                                selectionModel={resetCheckboxes}
                                isRowSelectable={params => (_.isNil( lenderIdIndexes ) ? [] : !lenderIdIndexes.includes(params.row.id))}
                              
                                pageSize={20}
                                rowsPerPageOptions={[20]}
                                rowHeight={100}
                            />
                        </Box>
                        <div className="modal-footer justify-content-center mt-2">
                            <button 
                                type="submit" 
                                data-testid="product.financing.lendersModal.control.addLenders"
                                onClick={e => {
                                   addLenders(e, selections)
                                   if(productLenders === null) {
                                    getSelectedLenderIndexes(productLenders);
                                    updateReduxChangeArray('selectedLenders', productLenders);
                                   } else {
                                    let mergedLenders = [...productLenders, ...selections];

                                    getSelectedLenderIndexes(mergedLenders);
                                    updateReduxChangeArray('selectedLenders', mergedLenders);
                                   }
                                }
                              } 
                              value={selections??''}
                                data-bs-dismiss="modal" 
                                className="btn btn-primary"
                                aria-label="Close"
                                data-bs-toggle="modal"
                                data-bs-target="#productModal"
                            >
                                Add Lenders
                            </button>
                        </div>
                    </div>
                    
                </div>
            </div>  
          </div>
        </div>
      </div>
      )}
    </>
    
  );
}

export default ProductFinanceModal

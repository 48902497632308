import React, {useState, useEffect,useRef} from 'react'
import * as api from '../../../../../../utlis/api'
import { CircularProgress } from '@mui/material'
import { useCallback } from 'react';

export default function DeleteDocsModal({order, orderdocuments, updateOrderDocsInfo}) {

  const prevOrderDocLength = useRef(orderdocuments?.length);
  const [isLoading, setIsLoading] = useState(false)
  const [orderDocs, setOrderDocs] = useState(undefined)
  const [productDocs, setProductDocs] = useState(undefined)
  const [tradeInDocs, setTradeInDocs] = useState(undefined)
  const [deletedDocs, setDeletedDocs] = useState(undefined)

  const deleteDocument = async (e) => {
    const deleteArray= orderDocs.filter(doc=>doc.delete===true)
      .concat(productDocs.filter(doc=>doc.delete===true)
      .concat(tradeInDocs.filter(doc=>doc.delete===true)))
    e.preventDefault();
    setIsLoading(true)
    try {
      for(const document of deleteArray){
        await api.deleteDocument(document.id)
      }
      await updateOrderDocsInfo(true);
    } catch (err) {
      api.recordFrontEndError('Page.jsx', 'Error deleting document: ' + err)
    }
    setOrderDocs(undefined)
    setProductDocs(undefined)
    setTradeInDocs(undefined)
    setIsLoading(false)
    setDeletedDocs(deleteArray.length)
  }

  const resetVals=()=>{
    setOrderDocs(undefined)
    setProductDocs(undefined)
    setTradeInDocs(undefined)
    setDeletedDocs(undefined)
    setIsLoading(false)
  }

  const updateDocs = useCallback(async()=>{
    let orderCopy=[]
      let productCopy=[]
      let tradeInCopy=[]
      //filter docs into categories
      orderdocuments?.forEach(doc=>{
          try{
            if(doc.assignedObjectType==='order') orderCopy.push({...doc, delete:true})
            else if(doc.assignedObjectType==='product')productCopy.push({...doc, delete:true})
            else if (doc.assignedObjectType==='tradein')tradeInCopy.push({...doc, delete:true})
          }catch (err) {}
        })
      setOrderDocs(orderCopy)
      setProductDocs(productCopy)
      setTradeInDocs(tradeInCopy)
  },[orderdocuments])


  const docToggle=(e, type)=>{
    setDeletedDocs(undefined)
    const {checked, id} = e.target
    if(type==='order'){
      let arrayCopy=[...orderDocs]
      let foundItem = arrayCopy.find((o)=>o.id.toString()===id.toString())
      foundItem.delete=checked
      setOrderDocs(arrayCopy)
    }
    else if(type==='product'){
      let arrayCopy=[...productDocs]
      let foundItem = arrayCopy.find((o)=>o.id.toString()===id.toString())
      foundItem.delete=checked
      setProductDocs(arrayCopy)
    }
    else if(type==='tradeIn'){
      let arrayCopy=[...tradeInDocs]
      let foundItem = arrayCopy.find((o)=>o.id.toString()===id.toString())
      foundItem.delete=checked
      setTradeInDocs(arrayCopy)
    }
  }

  const renderDocLabel=(doc)=>{
    const {assignedObjectId} = doc
    const {name:templateName} = doc.documentTemplate
    //combine pproducts and tradeins to one array
    let combinedArray= order?.products?.concat(order?.tradeins) || order?.tradeins?.concat(order?.products)
    let foundItem = combinedArray?.find((item)=>item.id===assignedObjectId)
    if(foundItem?.stockNumber){
        return (
          <>
          <nobr style={{fontWeight:'bold'}}>
            {templateName}
          </nobr>
          <div>
            {foundItem.stockNumber}
          </div>
          </>
        )
    }
    else if(foundItem?.dolClientNumber){
        return(
          <>
          <nobr style={{fontWeight:'bold'}}>
            {templateName}
          </nobr>
          <div>
            {foundItem.serialNumber}
          </div>
          </>
        )
    }
  }

  useEffect(()=>{
    if(orderdocuments?.length!==prevOrderDocLength.current||(!orderDocs&&!productDocs&&!tradeInDocs)){
      updateDocs()
    }
      prevOrderDocLength.current = orderdocuments?.length;
  },[orderDocs, orderdocuments, productDocs, tradeInDocs, updateDocs])


  return (
    <div
    className="modal fade"
    id="deleteAllDocModal"
    tabIndex="-1"
    aria-labelledby="exampledownpaymentmodel"
    aria-hidden="true"
    data-bs-backdrop="static" 
    data-bs-keyboard="false"
    
  >
  <div className="modal-dialog modal-lg modal-dialog-centered" style={{minWidth:'75%'}} >
    <div className="modal-content">
      <div className="modal-header bg-primary text-white">
        <h5
          className="modal-title text-capitalize"
          id="exampledownpaymentmodel"
        >
          Delete Multiple Documents
        </h5>
        <button
          type="button"
          className="btn-close bg-white"
          data-bs-dismiss="modal"
          aria-label="Close"
          disabled={isLoading}
          data-testid="modal.deleteAllDocuments.closeButtonTop"
          onClick={(e)=>{
            // e.preventDefault()
            resetVals()
          }}
        ></button>
      </div>
      <div className="modal-body">
        <div className="px-3 py-3" >
        {/* <Autocomplete
                      value={ deleteArray ?? null}
                      multiple
                      disabled={isLoading}
                      id="tags-outlined"
                      onChange={(e, newValue) => {
                        addToDeleteArray(e, newValue)
                        // onSelectedProductChange(event, newValue);
                        // setDocumentValues(initialDocumentValues);
                      }}
                    //   onBlur={(e) => setPreviewChanges(true)}
                    getOptionLabel={(option)=> option?.documentTemplate?.name??''}
                      options={modifiedDocArray??[]}
                    //   getOptionLabel={(option) => option.name}
                      filterSelectedOptions
                      renderInput={(params) => {
                        return (
                        <TextField
                          {...params}
                          label="Select Documents"
                        />
                      )}}
                    /> */}
                    {/* <div style={{height:'50vh', overflowX:'hidden'}}>  */}
                      <div style={{display:'flex',flexDirection:'column',alignItems:'center', justifyContent:'space-evenly',alignContent:'space-evenly',gap:'1.25rem', textAlign:'center', width:'inherit'}}>
                        <h4>Delete All (Selected) Documents</h4>
                      <div style={{flexDirection:'row', gap:'1rem', height: '40vh', fontSize: '11px'}} class="mx-auto text-center d-flex justify-content-evenly">

                      <div>
                          <h6>Order</h6>
                          <div style={{display:'flex',flexDirection:'column', maxHeight: '90%', textAlign:'center', gap:'.3rem', overflowY: 'scroll', width: 'inherit', minWidth: '33%', padding: '1rem'}} className="col-md-4">
                              { orderDocs?.length > 0 ? ( orderDocs?.map((doc)=>{
                                  return (
                                    <div key={doc?.id} style={{display:'flex',padding:'.25rem', flexDirection:"row", gap:"1rem"}}>
                                  <input checked={doc?.delete}  id={doc?.id} type='checkbox' onChange={(e)=>docToggle(e, 'order')} aria-label='order document'/>
                                    <label htmlFor={doc?.id} style={{textAlign:'start', fontWeight:'bold'}}><nobr>{doc?.documentTemplate?.name}</nobr></label>
                                    </div>//
                              )})
                            ):(<span>No Order Docs Found</span>)}
                          </div>
                        </div>

                        <div>
                          <h6>Product</h6>
                          <div style={{display:'flex',flexDirection:'column', maxHeight: '90%', textAlign:'center', gap:'.3rem', overflowY: 'scroll', width: 'inherit', minWidth: '33%', padding: '1rem'}} className="col-md-4">
                              { productDocs?.length > 0 ? ( productDocs?.map((doc)=>{
                                  return (
                                    <div key={doc?.id} style={{display:'flex',padding:'.25rem', gap:'1rem', flexDirection:"row"}}>
                                  <input checked={doc?.delete} id={doc?.id} type='checkbox' aria-label='product document' onChange={(e)=>docToggle(e, 'product')}/>
                                  <label htmlFor={doc?.id} style={{textAlign:'start'}}>{renderDocLabel(doc)}</label>
                                    </div>
                              )})
                            ):(<span>No Product Docs Found</span>)}
                          </div>
                        </div>

                        <div>
                          <h6>Trade In</h6>
                          <div style={{display:'flex',flexDirection:'column', maxHeight: '90%', textAlign:'center', gap:'.3rem', overflowY: 'scroll', width: 'inherit', minWidth: '33%', padding: '1rem'}} className="col-md-4">
                            { tradeInDocs?.length > 0 ? ( tradeInDocs?.map((doc)=>{ 
                                return (
                                  <div key={doc?.id} style={{display:'flex',padding:'.25rem', flexDirection:"row", justifyContent:'space-between', gap:'1rem'}}>
                                <input checked={doc?.delete} id={doc?.id} type='checkbox' aria-label='trade-in document' onChange={(e)=>docToggle(e, 'tradeIn')}/>
                                <label htmlFor={doc?.id} style={{textAlign:'start'}}>{renderDocLabel(doc)}</label>
                                </div>
                              )})
                            ):(<span>No Tradein Docs Found</span>)}
                          </div>
                        </div>
                      </div>
                    {deletedDocs&&(<h4 className='text-danger'>{deletedDocs} Documents deleted</h4>)}
                    </div>
                    {/* </div> */}
        </div>
      </div>
      <div className="modal-footer text-center">
        <div className="mx-auto">
        {isLoading?(<div style={{display:'flex',width:'inherit', flexDirection:'column', alignItems:'center'}}><CircularProgress/></div>):(<>
          {orderdocuments?.length>0&&(<button
            type="button"
            disabled={isLoading}
            className="btn btn-primary mx-3"
            aria-label='delete'
            onClick={(e) => deleteDocument(e)}
            data-testid="modal.deleteAllDocuments.deleteButton"
          >
            Delete
          </button>)}
            <button
            type="button"
            disabled={isLoading}
            className="btn btn-secondary mx-3"
            data-bs-dismiss="modal"
            aria-label='cancel'
            data-testid="modal.deleteAllDocuments.closeButtonBottom"
            onClick={()=>{
              resetVals()
            }}
          >
            {orderdocuments?.length>0?'Cancel':'Close'}
          </button>
          </>)}
        </div>
      </div>
    </div>
  </div>
</div>
  )
}

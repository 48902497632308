import moment from 'moment'
import { salesPriceWithUpgradeCalc } from '../../../../../../../utlis/productCalculations.js';
import { formatPhoneNumber, formatMoneyValue, formatUpperCase,getAreaCode, getPhoneNumber, getPhoneNumberAlt, removeChars } from "../../../../../../../utlis/formatters";


var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const CADeliveryStatementGlobalAutofill = (documentValues, order, property=null, value=null, location=null) => {

    var newValues = documentValues;
    // if(order && order.customer && order.customer.name){
    //   newValues.purchaser_name = order.customer.name;
    // }

if(property==='location'){
  if(value.state.toString().toUpperCase() === "CA"){
    newValues.dealer_name = "TEC OF CALIFORNIA, INC.";
    newValues.cdtfa_permit_number = "91-1757479"
  }else{
    newValues.dealer_name = "TEC EQUIPMENT, INC.";
  }
  newValues.dealer_address = value.address;
      newValues.dealer_city = value.city;
      newValues.dealer_state = value.state;
      newValues.dealer_zip = value.zip;
      newValues.area_code = getAreaCode(value.phone);
      newValues.phone = getPhoneNumberAlt(value.phone);
}
if(property==='address'&& location!==null){
  newValues.dealer_address = value;
}
if(property==='city'&& location!==null){
  newValues.dealer_city = value
}
if(property==='state'&& location!==null){
  newValues.dealer_state = value;
}
if(property==='zip'&& location!==null){
  newValues.dealer_zip = value
}
if(property==='area_code'&& location!==null){
  newValues.area_code = getAreaCode(value)
}
if(property==='phone'&& location!==null){
  newValues.dealer_city =getPhoneNumberAlt(value)
}
if(property==='customer.name'){
  newValues.purchaser_name =value
  if(order.customer.dba && order.customer.dba !== ""){
    newValues.purchaser_name += " / " + order.customer.dba;
  }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
    let cosigner = order.customer.cosigners[0];
    if(cosigner && cosigner.name){
        newValues.purchaser_name += " AND "+cosigner.name;
    }
  }
}
if(property==='customer.dba'){
    newValues.purchaser_name = order.customer.name
    if(value!==''){
      newValues.purchaser_name+= " / " + value
    } else if (value==='' && order.customer.cosigners && order.customer.cosigners.length>0 && order.customer.cosigners[0].name!==''){
      newValues.purchaser_name+= " AND "+order.customer.cosigners[0].name
    }
}
if(property==='customer.cosigner[0].name'){
    newValues.purchaser_name = order.customer.name
    if(value !==''){
        newValues.purchaser_name += " AND "+value;
  } else if (value === '' && order.customer.dba!==''){
    newValues.purchaser_name+= " / " + order.customer.dba
  }
}
if(property==='customer.phone'){
  let phoneNumber = removeChars(value)
  newValues.purchaser_area_code = getAreaCode(phoneNumber)
  newValues.purchaser_phone = getPhoneNumber(phoneNumber)
}

if(property !== null && value !== null){
  return newValues;
}

    if(location){
      if(location.state && location.state === "CA"){
        newValues.dealer_name = "TEC OF CALIFORNIA, INC.";
      }else{
        newValues.dealer_name = "TEC EQUIPMENT, INC.";
      }
      newValues.dealer_address = location.address;
      newValues.dealer_city = location.city;
      newValues.dealer_state = location.state;
      newValues.dealer_zip = location.zip;
      newValues.area_code = getAreaCode(location.phone);
      newValues.phone = getPhoneNumberAlt(location.phone);

      if(location.state && location.state === "CA"){
        newValues.cdtfa_permit_number = "91-1757479";
      }
    }

    if(order && order.customer){
      newValues.purchaser_name = order.customer.name;
      newValues.purchaser_area_code = getAreaCode(order.customer.phone)
      newValues.purchaser_phone = getPhoneNumber(order.customer.phone)
      if(order.customer.dba && order.customer.dba !== ""){
        newValues.purchaser_name += " / " + order.customer.dba;
      }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
            newValues.purchaser_name += " AND "+cosigner.name;
        }
      }
    }

    return newValues
}

const calcAZandCASalesTax = (product,order) => {

  // Base Price
  let basePrice = salesPriceWithUpgradeCalc(product);

  let adminFee = order?.adminFee ? order?.adminFee : 0;

  let docFee = 0;
  if(product.lineItemFee){
    docFee = product.lineItemFee.docFee ? product.lineItemFee.docFee : 0;
  }
  return Number(basePrice)+Number(adminFee)+Number(docFee);

}

export const CADeliveryStatementAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null, location=null, order=null ) => {
    var newValues = documentValues;

    if(property === 'year' && value !== null){
      newValues.year = (value).toString().trim().replace(/['"]+/g, '');
  }

  if(property === 'make' && value !== null){
      newValues.make = (value).toString().trim();
  }

  if(property === 'model' && value !== null){
      newValues.model = (value).toString().trim();
  }
  
  
  if(property === 'price' && value !== null){
    newValues.sales_price =  formatter.format((value).toString().trim());
    if(selectedProduct && ((location.state && (location.state === "CA" || location.state === "AZ")) || (order && order.customer && order.customer.deliveryAddress && order.customer.deliveryAddress.state === "CA"))){
      let calculatedPrice = calcAZandCASalesTax(selectedProduct, order);
      if(typeof calculatedPrice === 'number'){
        newValues.purchase_price = formatMoneyValue(calculatedPrice);
      }else{
        newValues.purchase_price = "";
      }
    }else{
      newValues.purchase_price = "";
    }
  }
  if(property === 'location' && value !== null){
      if(selectedProduct && ((value.state && (value.state === "CA" || value.state === "AZ")) || (order && order.customer && order.customer.deliveryAddress && order.customer.deliveryAddress.state === "CA"))){
        let calculatedPrice = calcAZandCASalesTax(selectedProduct, order);
        if(typeof calculatedPrice === 'number'){
          newValues.purchase_price = formatMoneyValue(calculatedPrice);
        }else{
          newValues.purchase_price = "";
        }      
      }else{
        newValues.purchase_price = "";
      }   
  }

  if(property !== null && value !== null){
      return newValues;
  }

  if(selectedProduct){
    newValues.year = (selectedProduct.year).trim();
    newValues.make = (selectedProduct.make).trim();
    newValues.model = (selectedProduct.model).trim().toString().toUpperCase();
    newValues.stock_number = (selectedProduct.stockNumber);
    newValues.mileage = (selectedProduct.mileage);
    
    newValues.serial_number = (selectedProduct.serialNumber);
    newValues.vin = (selectedProduct.vin);

    
    newValues.sale_price = formatter.format(selectedProduct.price);

    if((location.state && (location.state === "CA" || location.state === "AZ")) || (order && order.customer && order.customer.deliveryAddress && order.customer.deliveryAddress.state === "CA")){
      let calculatedPrice = calcAZandCASalesTax(selectedProduct, order);
      if(typeof calculatedPrice === 'number'){
        newValues.purchase_price = formatMoneyValue(calculatedPrice);
      }else{
        newValues.purchase_price = "";
      } 
    }else{
      newValues.purchase_price = "";
    }
  }

    return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  if(payload.seller_type){

    // Reset all
    payload.salesperson_cb = "no";
    payload.employee_cb = "no";
    payload.partner_cb = "no";
    payload.other_cb = "no";

    switch(payload.seller_type){
      case "salesperson_cb":
        payload.salesperson_cb = "yes";
        break;
      case "employee_cb":
        payload.employee_cb = "yes";
        break;
      case "partner_cb":
        payload.partner_cb = "yes";
        break;
      case "other_cb":
        payload.other_cb = "yes";
        break;
      default:
    }

    if(payload.seller_type !== "other_cb"){
      payload.other_explain = "";
    }

    payload.date = moment.utc(payload.date).format("MM/DD/YY");
  }

  // X1.2- Field Type Specific Preview Formatting
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  // Data point formatting
  if(payload.purchase_date){
    payload.purchase_date = moment.utc(payload.purchase_date).format("MM/DD/YY");
  }

  if(payload.delivery_date){
    payload.delivery_date = moment.utc(payload.delivery_date).format("MM/DD/YY");
  }

  if(payload.date){
    payload.date = moment.utc(payload.date).format("MM/DD/YY");
  }
  
  return payload;
}
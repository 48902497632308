import {createSlice} from '@reduxjs/toolkit'


export const allLocationsSlice = createSlice({
   name: 'allLocations',
   initialState:[],
   reducers:{
      setAllLocations: (state, action)=>{
         return action.payload
      },
      // updateDefaultRegionCode: (state, action) => {
      //    state.defaultTecRegionCode = action.payload;
      //    return state;
      // },
      // updateDefaultLocationCode: (state, action) => {
      //    state.defaultTecLocationCode = action.payload;
      //    return state;
      // }
    }
})

export const { setAllLocations }= allLocationsSlice.actions

export default allLocationsSlice.reducer
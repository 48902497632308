import * as api from "./api.js";
import { enableTireTax } from "./fastLogicUtils.js";

const salesPriceWithUpgradeCalc = (product) => {
    if(product){
      const {upgrades} = product;
      let total = 0;
      if(upgrades && upgrades.length > 0) {
        upgrades.forEach(upgrade => {
          total = Number(total) + Number(upgrade.price);
        });
      }
      total = Number(product.price) + Number(total);
      return total;
    }
    return 0.00;
  }

const salesPriceWithUpgradePlusEWandGAPCalc = (product) => {
    if(product){
      const {upgrades} = product;
      let total = 0;
      if(upgrades && upgrades.length > 0) {
        upgrades.forEach(upgrade => {
          total = Number(total) + Number(upgrade.price);
        });
      }
      total = Number(product.price) + Number(total);
      if(product?.extendedWarranty?.price > 0){
        total = Number(product.extendedWarranty.price) + Number(total);
      }
      if(product?.gapInsurance?.amount > 0){
        total = Number(product.gapInsurance.amount) + Number(total);
      }

      return total;
    }
    return 0.00;
  }

  const salesPriceWithTaxableUpgradeCalc = (product) => {
    if(product){
      const {upgrades} = product;
      let total = 0;
      if(upgrades && upgrades.length > 0) {
        upgrades.forEach(upgrade => {
          if(upgrade.subjectToSalesTax){
            total = total + upgrade.price;
          }
        });
      }
        return product.price + total;
    }
    return 0.00;
  }

  const catCalc = (product, percent=null) => {
    const salesUpgradePrice = salesPriceWithUpgradeCalc(product);
    if(percent === null){
      percent = (product && product.lineItemFee && product.lineItemFee.catTax ? product.lineItemFee.catTax : 0);
    }
    return (salesUpgradePrice * (percent / 100)); 
  }

  const luxuryTaxCalc = (product) => {
    const salesUpgradePrice = salesPriceWithUpgradeCalc(product);
    return (salesUpgradePrice * ((product && product.lineItemFee && product.lineItemFee.luxuryTax ? product.lineItemFee.luxuryTax : 0) / 100)); 
  }

  const calcTaxFeeSubtotal = (order, product) => {
    if(product && product.lineItemFee){
      var subtotal = 0;
      
      subtotal = subtotal + (product.lineItemFee.oosDeliveryFee ?  product.lineItemFee.oosDeliveryFee : 0);
      subtotal = subtotal + (product.lineItemFee.docFee ?  product.lineItemFee.docFee : 0);
      subtotal = subtotal + (product.lineItemFee.additionalTax ?  product.lineItemFee.additionalTax : 0);
      subtotal = subtotal + (product.lineItemFee.etlFee ?  product.lineItemFee.etlFee : 0);
      subtotal = subtotal + (product.lineItemFee.titleFee ?  product.lineItemFee.titleFee : 0);

    // if (
    //   order &&
    //   order.tecLocationState &&
    //   (order.tecLocationState === "CA" || order.tecLocationState === "AZ")
    // ) {
    if(product?.lineItemFee?.enableTireTax === true || enableTireTax(order, product)){
      subtotal =
        subtotal +
        (product.lineItemFee.tireTaxTotal
          ? product.lineItemFee.tireTaxTotal
          : 0);
    }

    if (
      product &&
      product.registrationAddress?.state &&
      product.registrationAddress?.state === "OR"
    ) {
      subtotal =
        subtotal +
        (product.lineItemFee.catTaxTotal ? product.lineItemFee.catTaxTotal : 0);
    }

    if (
      product &&
      product.registrationAddress?.state &&
      product.registrationAddress?.state === "OR"
    ) {
      if (
        product &&
        product.lineItemFee &&
        product.lineItemFee.applyLuxuryTax === true
      ) {
        subtotal =
          subtotal +
          (product.lineItemFee.luxuryTaxTotal
            ? product.lineItemFee.luxuryTaxTotal
            : 0);
      }
    }

    return subtotal;
  }
  return 0;
};

const calculateProductWarrantyTotal = (product) => {
  if(product && product.extendedWarranty){
      return product.extendedWarranty.price;
    }
    return 0;
}

const calculateProductGapTotal = (product) => {
  if(product && product.gapInsurance && product.gapInsurance.amount){
    return product.gapInsurance.amount;
  }
  return 0;
}

export const roundTwoDecimals = (number) =>{
  return (Math.round(number * 100) / 100);
}

const calculateProductSubtotal = (order, product) => {
  const salesUpgradePrice = roundTwoDecimals(salesPriceWithUpgradeCalc(product));
  const calcTaxFeeSubtotalValue = roundTwoDecimals(calcTaxFeeSubtotal(order, product));
  const calculateProductWarrantyTotalVal = roundTwoDecimals(calculateProductWarrantyTotal(product));
  const calculateProductGapTotalVal = roundTwoDecimals(calculateProductGapTotal(product));
  var finalSubtotal = 0;
  finalSubtotal = Number(salesUpgradePrice)+Number(calculateProductWarrantyTotalVal)+Number(calcTaxFeeSubtotalValue)+Number(calculateProductGapTotalVal);

  finalSubtotal += roundTwoDecimals(getFetTotal(product));

  return roundTwoDecimals(finalSubtotal);
}

const calculateProductNoUpgradeSubtotal = (order, product) => {
  let unitPrice = product.price;

    const calcTaxFeeSubtotalValue = calcTaxFeeSubtotal(order, product);
    const calculateProductWarrantyTotalVal = calculateProductWarrantyTotal(product);
    const calculateProductGapTotalVal = calculateProductGapTotal(product);
    var finalSubtotal = 0;
    finalSubtotal = Number(unitPrice)+Number(calculateProductWarrantyTotalVal)+Number(calcTaxFeeSubtotalValue)+Number(calculateProductGapTotalVal);
    finalSubtotal += getFetTotal(product);
    return finalSubtotal;
}

const getFetTotal = (product) => {
  var fetTotal = 0;
  if(product && product.lineItemFee && product.lineItemFee.applyFet && product.lineItemFee.applyFet === true){
    fetTotal = (product.lineItemFee.fetTotal && product.lineItemFee.fetTotal > 0) ? product.lineItemFee.fetTotal : 0;
  }
  return fetTotal;
}

const calculateProductDepositTotal = (product) => {
  if(product.deposits && product.deposits.length > 0){
    return product.deposits.reduce(function(prev, current) {
      return prev + +current.appliedAmount
    }, 0);
  }
  return 0;
}

const getTotalSalesPrice = (product) => {
  const {upgrades} = product;

  let total = 0;
  if(upgrades && upgrades.length > 0) {
      upgrades.forEach(upgrade => {
          total = total + upgrade.price;
      });
  }
  return Number(product.price) + total;
}

function vehicleTaxableAmountUpgradeNoAdmin(product, order){
  if(product){
    let total = salesPriceWithTaxableUpgradeCalc(product);
    if(product.lineItemFee && product.lineItemFee.docFee && product.registrationAddress?.state && product.registrationAddress?.state !== "AZ" && product.registrationAddress?.state !== "WA"){
        total = Number(total) + Number(product.lineItemFee.docFee);
    }

    if(product.registrationAddress?.state === "NV" || product.registrationAddress?.state === "WA" || product.registrationAddress?.state === "AZ"){
      let weightedTradeCredit = getWeightedTradeAllowance(order, order.products, product, order.tradeins);
      total = Number(total) - Number(weightedTradeCredit);
    }

    if(product?.extendedWarranty?.isTaxable === true){
      total = Number(total) + Number(product?.extendedWarranty?.price);
    }

    return total;
  }
  return 0.00;
}

function vehicleTaxableAmountNoUpgradeAdminWeighted(product, customer, adminFee){
  if(product){
    let total = product.price;

    if(adminFee && customer && customer.deliveryAddress && customer.deliveryAddress.state !== "AZ"){
      total = Number(total) + Number(adminFee);
    }
    
    // if(product.lineItemFee && product.lineItemFee.docFee && customer && customer.deliveryAddress && customer.deliveryAddress.state !== "AZ" && customer.deliveryAddress.state !== "WA"){
      if(product.lineItemFee && product.lineItemFee.docFee && product.registrationAddress?.state && product.registrationAddress?.state !== "AZ" && product.registrationAddress?.state !== "WA"){
        total = Number(total) + Number(product.lineItemFee.docFee);
    }

    if(product?.extendedWarranty?.isTaxable === true){
      total = Number(total) + Number(product?.extendedWarranty?.price);
    }
    return total;
  }
  return 0.00;
}

function vehicleTaxableAmountUpgradeAdminWeighted(product, customer, adminFee){
  if(product){
    let total = salesPriceWithTaxableUpgradeCalc(product);

    if(adminFee && customer && customer.deliveryAddress && customer.deliveryAddress.state !== "AZ"){
      total = Number(total) + Number(adminFee);
    }

    if(product.lineItemFee && product.lineItemFee.docFee && product.registrationAddress?.state && product.registrationAddress?.state !== "AZ" && product.registrationAddress?.state !== "WA"){
        total = Number(total) + Number(product.lineItemFee.docFee);
    }

    if(product?.extendedWarranty?.isTaxable === true){
      total = Number(total) + Number(product?.extendedWarranty?.price);
    }
    return total;
  }
  return 0.00;
}

function nonVehicleTaxableAmount(product, customer){
  if(product){
    let total = 0;

    if(product.extendedWarranty){
      total += Number(product.extendedWarranty.price);
    }

    if(product.gapInsurance){
      total += Number(product.gapInsurance.amount);
    }
    return total;
  }
  return 0.00;
}

function getSalesTaxTotal(order, taxableAmount=0, tradeCredit=0, subtractTradeCredit=false){
  let vehicleSalesTaxTotal = 0;
  if(order?.customer?.isExemptSalesTax === false){
    let amount = taxableAmount;
    // if(order.customer && order.customer.deliveryAddress && (order.customer.deliveryAddress.state === "AZ" || order.customer.deliveryAddress.state === "WA" || order.customer.deliveryAddress.state === "NV")){
    if(subtractTradeCredit === true){
      // Subtract trade credit
      if(tradeCredit && tradeCredit > 0){
        tradeCredit = Number(tradeCredit).toFixed(2);
        amount = Number(amount) - Number(tradeCredit);
      }
    }
    amount = Number(amount).toFixed(2);
    vehicleSalesTaxTotal =  (amount * order.customer.vehicleSalesTaxPercent) / 100;
  }
  return Number(vehicleSalesTaxTotal).toFixed(2);
}

function getWeightedAdminFee(order){
  if(order && order.products && order.products.length && order.products.length > 0){
    let productWeight = Number( 1 / order.products.length);
    return ((order.adminFee ?? 0) * productWeight);
  }
  return 0;
}


export function calculateProductWeight(order, allProducts, passedProduct){ 
    let totalTaxableAmount = 0;
    let selectedTaxableAmount = 0;
    let weight = 0;
    if (allProducts && allProducts.length>0){
    allProducts.forEach((product) => {
      let productTaxableAmount = 0;
      productTaxableAmount = vehicleTaxableAmountUpgradeNoAdmin(product, order);
      if(product.id === passedProduct.id){
        if(productTaxableAmount){
          selectedTaxableAmount = productTaxableAmount;
        }
      }
      totalTaxableAmount += productTaxableAmount;
    });
  }
  
  if(totalTaxableAmount > 0){
    weight = Number(selectedTaxableAmount) / Number(totalTaxableAmount);
  }  
  
  return weight ;
}

export function getTotalTradeCredit(tradeins){
  let totalTaxCredit = 0;
  if(tradeins && tradeins.length > 0){
    tradeins.forEach(tradein => {
      let tradeCredit = (tradein.tradeAllowanceAtTerms - tradein.balanceOwed - tradein.cashRefundToCustomer);
      totalTaxCredit = Number(totalTaxCredit) + Number(tradeCredit);
    })
  }
  return totalTaxCredit;
}

function getWeightedTradeCredit(order, allProducts, product, tradeins){
  let productWeight = calculateProductWeight(order, allProducts, product);
  let totalTradeCredit = getTotalTradeCredit(tradeins);
  return totalTradeCredit * productWeight;
}

export function getTotalTradeAllowance(tradeins){
  let totalTaxCredit = 0;
  if(tradeins && tradeins.length > 0){
    tradeins.forEach(tradein => {
      let tradeCredit = tradein.tradeAllowanceAtTerms;
      totalTaxCredit = Number(totalTaxCredit) + Number(tradeCredit);
    })
  }
  return totalTaxCredit;
}

// Used on sales to NV only
function getWeightedTradeAllowance(order, allProducts, product, tradeins){
  // let productWeight = calculateProductWeight(order, allProducts, product); //Loops in NV,AZ,WA edge case with taxable amount.
  let productWeight = Number( 1 / allProducts?.length);
  let totalTradeCredit = getTotalTradeAllowance(tradeins);
  return totalTradeCredit * productWeight;
}

function matchAndGroup(products){
  // if(selectedProducts){

    
    // let AllProducts = [];

    // if(products === null){
    //   //Get All Products in the same array for matching
    //   order.products.forEach(product => {
    //     AllProducts.push(product);
    //   });
    //   order.groupedProducts.forEach(group => {
    //     group.products.forEach(product => {
    //       AllProducts.push(product);
    //     });    
    //   });
    // }else{
    //   AllProducts = products;
    // }
    
    let productArray = [];
    // Loop products and build matchingKey and assign it to each product.
    products.forEach((product) => {

      // Pull full product object;
      let foundProduct = products.find((item) => item.id === Number(product.id))
      if(foundProduct){

        let sortedFobValues=[]
        let sortedShipToValues=[]
        let sortedSoldToValues=[]
        let sortedRegistrationAddressValues=[]
        let sortedWarrantyValues=[]
        let sortedGapInsuranceValues=[]
        let sortedLineItemFeeValues=[]
        let sortedFundingDetailValues=[]
        let sortedCashLienHolderValues=[]
        let sortedFinalizedLendingOptionValues=[]
        let sortedAdditionsValues=[]
        let sortedUpgradesValues=[]

        // Pull shallow level product properties we want to match on.
        let {fobAddress,shipTo,soldTo,registrationAddress,make,model,price,type,vehicleType,year, extendedWarranty,gapInsurance, lineItemFee, fundingDetail,upgrades, additions } = foundProduct;

        
        if(fundingDetail && fundingDetail.fundingMethod === 'cash-with-lien'){
          let cashLienHolder=(fundingDetail?{...fundingDetail.cashLienHolder}:{})
          Object.keys(cashLienHolder).reduce((result,key)=>{
            result[key]=cashLienHolder[key]
            if(key!=='productId'&& key!=='id' && key!=='createdAt' && key!=='fundingDetailId'){
              sortedCashLienHolderValues.push(cashLienHolder[key])}
            return result
          },{})
        }
        

        if(fundingDetail && (fundingDetail.fundingMethod === 'tec-financing' || fundingDetail.fundingMethod === 'finance-only' || fundingDetail.fundingMethod === 'direct-deal')){
          let finalizedLendingOption=(fundingDetail?{...fundingDetail.finalizedLendingOption}:{})
          Object.keys(finalizedLendingOption).reduce((result,key)=>{
            result[key]=finalizedLendingOption[key]
            if(key!=='productId'&& key!=='id' && key!=='createdAt' && key!=='fundingDetailId'){
              sortedFinalizedLendingOptionValues.push(finalizedLendingOption[key])}
            return result
          },{})
        }
        

        if(fobAddress===null) fobAddress={};
        if(shipTo===null) shipTo={};
        if(soldTo===null) soldTo={};
        if(registrationAddress===null) registrationAddress={};
        if(fundingDetail===null) fundingDetail={};
        if(extendedWarranty===null) extendedWarranty={}
        if(gapInsurance===null) gapInsurance={}  
        if(lineItemFee===null) lineItemFee={}

        Object.keys(fobAddress).reduce((result,key)=>{
          result[key]=fobAddress[key]
          if(key!=='updatedAt' && key!=='id' && key!=='createdAt'){
            sortedFobValues.push(fobAddress[key])}
          return result
        },{})

        Object.keys(shipTo).reduce((result,key)=>{
          result[key]=shipTo[key]
          if(key!=='updatedAt' && key!=='id' && key!=='createdAt'){
            sortedShipToValues.push(shipTo[key])}
          return result
        },{})

        Object.keys(soldTo).reduce((result,key)=>{
          result[key]=soldTo[key]
          if(key!=='updatedAt' && key!=='id' && key!=='createdAt'){
            sortedSoldToValues.push(soldTo[key])}
          return result
        },{})

        Object.keys(registrationAddress).reduce((result,key)=>{
          result[key]=registrationAddress[key]
          if(key!=='updatedAt' && key!=='id' && key!=='createdAt'){
            sortedRegistrationAddressValues.push(registrationAddress[key])}
          return result
        },{})


        Object.keys(extendedWarranty).reduce((result,key)=>{
          result[key]=extendedWarranty[key]
          if(key!=='productId' && key!=='id' && key!=='createdAt' && key!=='updatedAt'){
            sortedWarrantyValues.push(extendedWarranty[key])}
          return result
        },{})

        Object.keys(gapInsurance).reduce((result,key)=>{
          result[key]=gapInsurance[key]
          if(key!=='productId'&& key!=='id' && key!=='createdAt' && key!=='updatedAt'){
            sortedGapInsuranceValues.push(gapInsurance[key])}
          return result
        },{})
        
        Object.keys(lineItemFee).reduce((result,key)=>{
          result[key]=lineItemFee[key]
          if(key!=='lineItemId'&& key!=='id' && key!=='createdAt' &&key !== 'updatedAt' && key !== 'luxuryTaxOverrideToggle'){
            sortedLineItemFeeValues.push(lineItemFee[key])}
          return result
        },{})


        if(fundingDetail){
          Object.keys(fundingDetail).reduce((result,key)=>{
            result[key]=fundingDetail[key]
            if(key!=='productId'&& key!=='id' && key!=='createdAt' && key!=='cashLienHolder' && key!=='finalizedLendingOption' && key !== 'finalizedAt' && key!=='updatedAt'){
              sortedFundingDetailValues.push(fundingDetail[key])}
            return result
          },{})
        }

        if(upgrades.length>0){
          upgrades.sort(function(a, b) {
            if(a.type.toLowerCase() < b.type.toLowerCase()) return -1;
            if(a.type.toLowerCase() > b.type.toLowerCase()) return 1;
            return 0;
            }).forEach((upgrade)=>{
            Object.keys(upgrade).reduce((result,key)=>{
              result[key]=upgrade[key]
              if(key!=='productId'&& key!=='id' && key!=='createdAt' && key!=='id' && key !== 'updatedAt'&& key !== 'refNumber'){
                sortedUpgradesValues.push(upgrade[key])}
              return result
            },{})
          })
        }

        if(additions.length>0){
        additions.sort(function(a, b) {
          if(a.type.toLowerCase() < b.type.toLowerCase()) return -1;
          if(a.type.toLowerCase() > b.type.toLowerCase()) return 1;
          return 0;
          }).forEach((addition)=>{
            Object.keys(addition).reduce((result,key)=>{
              result[key]=addition[key]
              if(key!=='itemId'&& key!=='id' && key!=='createdAt' && key!=='id' && key !== 'updatedAt'&& key !== 'refNumber'){
                sortedAdditionsValues.push(addition[key])}
              return result
            },{})
          })
        }

        let matchingKey = 
        // fob+", "+
        // fobZip+", "+
        make+", "+
        model+", "+
        price+", "+
        // stateRegistered+", "+
        type+", "+
        vehicleType+", "+
        year+", "+
        sortedFobValues.join(", ")+", "+
        sortedShipToValues.join(", ")+", "+
        sortedSoldToValues.join(", ")+", "+
        sortedRegistrationAddressValues.join(", ")+", "+
        sortedWarrantyValues.join(", ")+", "+
        sortedGapInsuranceValues.join(", ")+", "+
        sortedLineItemFeeValues.join(", ")+", "+
        sortedFundingDetailValues.join(", ")+", "+
        sortedCashLienHolderValues.join(", ")+", "+
        sortedFinalizedLendingOptionValues.join(", ")+", "+
        sortedUpgradesValues.join(", ")+", "+upgrades.length+", "+
        sortedAdditionsValues.join(", ")+ ", "+additions.length;
        
        let copyProduct = JSON.parse(JSON.stringify(product));

        copyProduct.matchingKey = matchingKey;
        productArray.push(copyProduct);
      }
    });

    // Group based off of matchingKey
    var groupedByYmmArray = Object.values(productArray.reduce((result, {
      matchingKey,
      id,
      name
    }) => {
      // Create new group
      if (!result[matchingKey]) result[matchingKey] = {
          name,
          matchingKey,
          id,
          products: []
      };
      // Append to group
      result[matchingKey].products.push({
        id,
      });
      return result;
    }, {}));
  
    // Update products with qty counts based off matching.
    let findProduct;
    //go through each group of products
    groupedByYmmArray.forEach((group)=>{
      //go through each product in the group
      group.products.forEach((product, i)=>{
          //find the product in the order
          findProduct = products.find((item) => item.id === Number(product.id))
          if(findProduct?.quantity){
            // edit the product
            findProduct.quantity=group.products.length
          }
      })
    })
    return groupedByYmmArray;

};

function createProductGroup(order, group){
  let productGroupId = null;

  // Determine if a new group is needed.
  group.products.forEach(product => {
    let foundProduct = order.products.find(p => p.id === product.id);
    if(foundProduct){
      if(foundProduct.productGroupId && productGroupId === null){
        productGroupId = foundProduct.productGroupId;
      }
    }
  });

  let payload = {
    productGroupId: productGroupId,
    products: group.products
  }

  // Create
  api.createProductGroup(order.id, payload)

}

export { 
  calculateProductGapTotal,
  salesPriceWithUpgradeCalc,
  salesPriceWithUpgradePlusEWandGAPCalc,
  catCalc,
  getFetTotal,
  luxuryTaxCalc,
  calcTaxFeeSubtotal,
  calculateProductWarrantyTotal,
  calculateProductSubtotal,
  calculateProductNoUpgradeSubtotal,
  calculateProductDepositTotal,
  getTotalSalesPrice,
  vehicleTaxableAmountUpgradeNoAdmin,
  vehicleTaxableAmountNoUpgradeAdminWeighted,
  vehicleTaxableAmountUpgradeAdminWeighted,
  nonVehicleTaxableAmount,
  salesPriceWithTaxableUpgradeCalc,
  getWeightedAdminFee,
  getWeightedTradeCredit,
  getSalesTaxTotal,
  matchAndGroup,
  createProductGroup,
  getWeightedTradeAllowance
}
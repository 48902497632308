import React from 'react'
import { Card, Dropdown } from "react-bootstrap";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

import { ArrowLeft } from '@material-ui/icons';
import { ArrowDropDown } from '@material-ui/icons';

const DocumentSubSectionTemplate = ({ title, recCount, eventKey, children, mode,isOpen, setIsOpen,documentSubSection, setItemSearch, setDocSearch }) => {

    const handleToggle = useAccordionButton(eventKey, ()=>{
      setIsOpen((prev)=>({
            ...prev,
            documentSubSection: (isOpen?.documentSubSection===eventKey ? undefined : eventKey )
      }))
      setItemSearch('')
      setDocSearch('')
    })
  return (
    <Card.Header className='m-2' style={{ backgroundColor: 'white', color: '#2d7ecf', border: '2px #2d7ecf solid' }}>
      <Dropdown style={{ display: 'inline-table' }}>
        <Dropdown.Menu>
            { children }
        </Dropdown.Menu>
      </Dropdown>
        <span style={{ paddingLeft: '15px', fontWeight: 'bold' }}>{title} 
        </span>
          <button
      type="button"
      style={{ backgroundColor: 'white', color: '#2d7ecf', border: 'none', maxWidth: '25px', float: 'right' }}
      onClick={(e) => handleToggle(eventKey)}
    >
      { eventKey===isOpen?.documentSubSection && isOpen.documents ? ( <ArrowDropDown /> ) : ( <ArrowLeft /> ) }
    </button>
    </Card.Header>
  );
};

export default DocumentSubSectionTemplate;
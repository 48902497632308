import { formatPhoneNumber, formatMoneyValue, formatUpperCase, getFirstName, getLastName, getMiddleName } from "../../../../../../../utlis/formatters";

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const IATitleAppGlobalAutofill = (documentValues, order, property=null, value=null) => {

  var newValues = documentValues;
  
  if(property ==='customer.name'){
    if(order.customer.type === "entity"){
      newValues.name_as_it_appears_on_the_title = value;
      newValues.first_name = value;
    }else{
      newValues.first_name = getFirstName(value);
      newValues.middle_name = getMiddleName(value);
      newValues.last_name = getLastName(value);
    }
    if(order && order.customer && order.customer.deliveryAddress){
      newValues.special_mailing_name_and_address = value;
    }
  }
  if(property ==='customer.cosigner[0].name'){
    if(value){
        newValues.first_name_2 = getFirstName(value);
        newValues.middle_name_2 = getMiddleName(value);
        newValues.last_name_2 = getLastName(value);
    }
  }
  if(property ==='customer.deliveryAddress.addressLine1'){
    newValues.address_1 = value
    if(order.customer.deliveryAddress.addressLine1 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
      newValues.address_1 +=" "+order.customer.deliveryAddress.addressLine2;
    }
  }
  if(property ==='customer.deliveryAddress.addressLine2'){
    newValues.address_1 = order.customer.deliveryAddress.addressLine1 +' '+value
  }
  if(property ==='customer.deliveryAddress.city'){
    newValues.city_1 = value;
  }
  if(property ==='customer.deliveryAddress.state'){
      newValues.state_1 = value;
  }
  if(property ==='customer.deliveryAddress.zip'){
      newValues.zip_1 = value;
  }
  if(property ==='customer.mailingAddress.addressLine1'){
    newValues.mailing_address_1 = value
    if(order.customer.mailingAddress.addressLine1 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
      newValues.mailing_address_1 +=" "+order.customer.mailingAddress.addressLine2;
    }
  }
  if(property ==='customer.mailingAddress.addressLine2'){
    newValues.mailing_address_1 = order.customer.mailingAddress.addressLine1 +' '+value
  }
  if(property ==='customer.mailingAddress.city'){
    newValues.mailing_city_1 = value;
  }
  if(property ==='customer.mailingAddress.state'){
      newValues.mailing_state_1 = value;
  }
  if(property ==='customer.mailingAddress.zip'){
      newValues.mailing_zip_1 = value;
  }

  if(property !== null && value !== null){
    return newValues;
  }

  if(order && order.customer){

    if(order && order.customer && order.customer.name){

      if(order.customer.type === "entity"){
        newValues.name_as_it_appears_on_the_title = order.customer.name;
        newValues.first_name = order.customer.name;
        // if(order.customer.dba){
        //   newValues.name = order.customer.name+" dba "+order.customer.dba;
        // }
      }else{
        newValues.first_name = getFirstName(order.customer.name);
        newValues.middle_name = getMiddleName(order.customer.name);
        newValues.last_name = getLastName(order.customer.name);

        // if(order.customer.dba){
        //   newValues.name = order.customer.name+" dba "+order.customer.dba;
        // }
      }
  
      if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner){
          newValues.first_name_2 = getFirstName(cosigner.name);
          newValues.middle_name_2 = getMiddleName(cosigner.name);
          newValues.last_name_2 = getLastName(cosigner.name);
        }
      }
    }
    
    if(order && order.customer && order.customer.deliveryAddress){
      newValues.special_mailing_name_and_address = order.customer.name;
      newValues.address_1 = order.customer.deliveryAddress.addressLine1;
      if(order.customer.deliveryAddress.addressLine1 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        newValues.address_1 = newValues.address_1+" "+order.customer.deliveryAddress.addressLine2;
      }
      newValues.city_1 = order.customer.deliveryAddress.city;
      newValues.state_1 = order.customer.deliveryAddress.state;
      newValues.zip_1 = order.customer.deliveryAddress.zip;
    }

    if(order && order.customer && order.customer.mailingAddress){
      newValues.mailing_address_1 = order.customer.mailingAddress.addressLine1;
      if(order.customer.mailingAddress.addressLine1 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
        newValues.mailing_address_1 = newValues.mailing_address_1+" "+order.customer.mailingAddress.addressLine2;
      }
      newValues.mailing_city_1 = order.customer.mailingAddress.city;
      newValues.mailing_state_1 = order.customer.mailingAddress.state;
      newValues.mailing_zip_1 = order.customer.mailingAddress.zip;
    }
  }
  
  return newValues
}


export const IATitleAppAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
      var newValues = documentValues;

      if(property === 'year' && value !== null){
        newValues.year = (value).toString().trim().replace(/['"]+/g, '');
    }
  
    if(property === 'make' && value !== null){
        newValues.make = (value).toString().trim();
    }
  
    if(property === 'model' && value !== null){
        newValues.model = (value).toString().trim();
    }
  
    if(property === 'vehicleType' && value !== null){
        newValues.body_style = (value);
    }
    if(property === 'price' && value !== null){
        newValues.sales_price = formatter.format((value).toString().trim());
        newValues.purchase_price = formatter.format((value).toString().trim());
    }
  
    if(property !== null && value !== null){
        return newValues;
    }
      newValues.year = (selectedProduct.year);
      newValues.vin = (selectedProduct.vin).toString().toUpperCase();
      newValues.make = (selectedProduct.make).toString().toUpperCase();
      newValues.model = (selectedProduct.model).toString().toUpperCase();
      newValues.body_type = (selectedProduct.vehicleType);
      newValues.purchase_price = formatter.format(selectedProduct.price);
      newValues.sale_price = formatter.format(selectedProduct.price);
      
      return newValues
    }

    export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {


      if(payload.title_type_toggle){
        // Reset all
        payload.reg_title_selected = "no";
        payload.salvage_selected = "no";
    
        switch(payload.title_type_toggle){
          case "regular":
            payload.reg_title_selected = "yes";
            break;
          case "salvage":
            payload.salvage_selected = "yes";
            break;
          default:
        }
      }
    
      if(payload.owner_status_toggle){
        // Reset all
        payload.owner_status_or = "no";
        payload.owner_status_and = "no";
    
        switch(payload.owner_status_toggle){
          case "or":
            payload.owner_status_or = "yes";
            break;
          case "and":
            payload.owner_status_and = "yes";
            break;
          default:
        }
      }
    
      if(payload.new_used_toggle){
        // Reset all
        payload.new_selected = "no";
        payload.used_selected = "no";
    
        switch(payload.new_used_toggle){
          case "new":
            payload.new_selected = "yes";
            break;
          case "used":
            payload.used_selected = "yes";
            break;
          default:
        }
      }
    
      if(payload.trailer_weight_toggle){
        // Reset all
        payload.over_2k_selected = "no";
        payload.under_2k_selected = "no";
    
        switch(payload.trailer_weight_toggle){
          case "over2":
            payload.over_2k_selected = "yes";
            break;
          case "under2":
            payload.under_2k_selected = "yes";
            break;
          default:
        }
      }
    
      if(payload.sec_interest_toggle){
        // Reset all
        payload.three_selected = "no";
        payload.two_selected = "no";
        payload.one_selected = "no";
        payload.none_selected = "no";
    
        switch(payload.sec_interest_toggle){
          case "one":
            payload.three_selected = "yes";
            break;
          case "two":
            payload.two_selected = "yes";
            break;
          case "three":
            payload.one_selected = "yes";
            break;
          case "none":
            payload.none_selected = "yes";
            break;
          default:
        }
      }
    
      if(payload.ut02_toggle){
        // Reset all
        payload.ut02_a_selected = "no";
        payload.ut02_b_selected = "no";
        payload.ut02_c_selected = "no";
        payload.ut02_d_selected = "no";
        payload.ut02_e_selected = "no";
        payload.ut02_f_selected = "no";
        payload.ut02_g_selected = "no";
        payload.ut02_h_selected = "no";
        payload.ut02_i_selected = "no";
        payload.ut02_j_selected = "no";
        payload.ut02_k_selected = "no";
        payload.ut02_l_selected = "no";
        payload.ut02_m_selected = "no";
        payload.ut02_n_selected = "no";
        payload.ut02_o_selected = "no";
    
        switch(payload.ut02_toggle){
          case "a":
            payload.ut02_a_selected = "yes";
            break;
          case "b":
            payload.ut02_b_selected = "yes";
            break;
          case "c":
            payload.ut02_c_selected = "yes";
            break;
          case "d":
            payload.ut02_d_selected = "yes";
            break;
          case "e":
            payload.ut02_e_selected = "yes";
            break;
          case "f":
            payload.ut02_f_selected = "yes";
            break;
          case "g":
            payload.ut02_g_selected = "yes";
            break;
          case "h":
            payload.ut02_h_selected = "yes";
            break;
          case "i":
            payload.ut02_i_selected = "yes";
            break;
          case "j":
            payload.ut02_j_selected = "yes";
            break;
          case "k":
            payload.ut02_k_selected = "yes";
            break;
          case "l":
            payload.ut02_l_selected = "yes";
            break;
          case "m":
            payload.ut02_m_selected = "yes";
            break;
          case "n":
            payload.ut02_n_selected = "yes";
            break;
          case "o":
            payload.ut02_o_selected = "yes";
            break;
          default:
        }
      }
    
      if(payload.ut03_toggle){
        // Reset all
        payload.ut03_a_selected = "no";
        payload.ut03_b_selected = "no";
    
        switch(payload.ut03_toggle){
          case "a":
            payload.ut03_a_selected = "yes";
            break;
          case "b":
            payload.ut03_b_selected = "yes";
            break;
          default:
        }
      }
    
      if(payload.ut08_toggle){
        // Reset all
        payload.ut08_a_selected = "no";
        payload.ut08_b_selected = "no";
        payload.ut08_c_selected = "no";
        payload.ut08_d_selected = "no";
        payload.ut08_e_selected = "no";
        payload.ut08_f_selected = "no";
        payload.ut08_g_selected = "no";
        payload.ut08_h_selected = "no";
        payload.ut08_i_selected = "no";
        payload.ut08_j_selected = "no";
        payload.ut08_k_selected = "no";
        payload.ut08_l_selected = "no";
    
        switch(payload.ut08_toggle){
          case "a":
            payload.ut08_a_selected = "yes";
            break;
          case "b":
            payload.ut08_b_selected = "yes";
            break;
          case "c":
            payload.ut08_c_selected = "yes";
            break;
          case "d":
            payload.ut08_d_selected = "yes";
            break;
          case "e":
            payload.ut08_e_selected = "yes";
            break;
          case "f":
            payload.ut08_f_selected = "yes";
            break;
          case "g":
            payload.ut08_g_selected = "yes";
            break;
          case "h":
            payload.ut08_h_selected = "yes";
            break;
          case "i":
            payload.ut08_i_selected = "yes";
            break;
          case "j":
            payload.ut08_j_selected = "yes";
            break;
          case "k":
            payload.ut08_k_selected = "yes";
            break;
          case "other":
            payload.ut08_l_selected = "yes";
            break;
          default:
        }
      }
    
      if(payload.otm_type_toggle){
        // Reset all
        payload.otm_title_selected = "no";
        payload.otm_reg_plate_selected = "no";
    
        switch(payload.otm_type_toggle){
          case "both":
            payload.otm_title_selected = "yes";
            payload.otm_reg_plate_selected = "yes";
            break;
          case "title":
            payload.otm_title_selected = "yes";
            break;
          case "reg":
            payload.otm_reg_plate_selected = "yes";
            break;
          default:
        }
      }
    
      Object.keys(payload).forEach(key => {
        if(moneyFields.indexOf(key) >= 0){
            payload[key] = formatMoneyValue(payload[key]);
        }else if(phoneFields.indexOf(key) >= 0){
            payload[key] = formatPhoneNumber(payload[key]);
        }else if(upperCaseFields.indexOf(key) >= 0){
            payload[key] = formatUpperCase(payload[key]);
        }
      });
      Object.keys(payload).forEach(key => {
        if(checkboxes.indexOf(key) >= 0){
          payload[key] = payload[key] ? "yes": "no";
        }
      });
    
      // if(payload.date_of_sale){
      //   payload.date_of_sale = moment.utc(payload.date_of_sale).format("MM/DD/YY");
      // }
    
      // if(payload.date){
      //   payload.date = moment.utc(payload.date).format("MM/DD/YY");
      // }
    
      return payload;
    }
import React, { useEffect } from 'react'
import NavbarOrder from "../../../Components/Navbar/NavbarOrder"
import Page from "./components/Page"
import { useDispatch, useSelector } from "react-redux"
import { refreshOrder } from "../../../redux/orders/order"
import OrderContext from "../../../Components/OrderContext"
import { setOrder } from "../../../redux/orders/order";
import { useOrder } from "../../../redux/orders/order"
import * as formatter from '../../../utlis/formatters'
import * as api from '../../../utlis/api'
import {Modal, Box, Typography} from '@mui/material'
import { useParams } from "react-router-dom"
import { setIsLoading } from '../../../redux/spinners/spinners';
import { CircularProgress } from "@mui/material";

const OrderQuote = () => {

  const { id }    = useParams()
  const dispatch  = useDispatch()
  const order     = useOrder()
  const role = useSelector(state=>state.role.role)
  const me = useSelector((state) => state?.me);
  const {isLoading}=useSelector(state=>state.spinners)

  //Effect to load in the order each time the page changes...
  useEffect(() => {
    if ( order && order.id && Number(order.id) !== Number(id) ) {
      dispatch(setOrder({}))
    }

    if(order && id && (order.id === undefined || order.id === null)){
      dispatch(setIsLoading(true))
      localStorage.setItem("location", null);
      localStorage.setItem("alllocations", null);
      localStorage.setItem("taxExemptReasons", null);
      dispatch(refreshOrder(id ,false , true))
    }
  }, [ id, dispatch, order ])


  return (
    <>
    <OrderContext.Provider value={{ refreshOrder }}>
      { isLoading ? (
        <>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div style={{display:'flex', flexDirection:'column', marginTop: '5rem'}}><CircularProgress style={{alignSelf:'center'}}/></div>
        </>
      ):(
        <>
          { me && Object.hasOwn(me, 'id') && order && Object.hasOwn(order, 'id') && order.id !== null && me?.id !== order.checkedOutByUserId && role && role !== 'master' ? (
            <Modal
            open="true"
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-title">
                  This order is checked out by <br />
                  <b>
                    {order.checkedOutByUserName}
                  </b>
                </Typography>
                <br />
                <button className="btn btn-primary mt-2" onClick={()=>window.location='/'}>Return to Dashboard</button>
              </Box>
            </Modal>
          ): (order?.checkedOutByUserName && me?.id === order.checkedOutByUserId) || (role && role === 'master') ? (
            <>
                <NavbarOrder />
                <Typography variant='h6' style={{textAlign:'center', backgroundColor:'#10589E', color:'white', padding:'0.33rem 0'}}>
                  Currently Checked Out By: {order?.checkedOutByUserName} &nbsp; 
                  {(role === 'master' || me?.id === order?.checkedOutByUserId) && order && order?.id ? ( 
                    <>
                      <button className="btn btn-primary btn-sm" style={{ marginTop: '-5px' }} type="button" 
                        onClick={() => {
                          api.unCheckedOutOrder(order.id);
                          window.location = "/";
                        }}>

                        {me?.id === order?.checkedOutByUserId?'Un-checkout Order': `Un-checkout ${formatter.getFirstName(order?.checkedOutByUserName)}`}
                      
                      </button>
                      </>
                  ):null}
                </Typography>
                <div>
                  <Page />
                </div>
            </>
          ):null}
        </>
      )}
      </OrderContext.Provider>
    </>
  )
}

export default OrderQuote

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign:'center',
};
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import { removeChars, formatUpperCase } from "../../../../../../../utlis/formatters";
import { useOrder, rdx } from "../../../../../../../redux/orders/order"
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { TECSalesOrderGlobalAutofill, preProcessPayload, calculateTotalsFunction } from "../DocumentAutofill/TECSalesOrder"
import { setActiveModal } from "../../../../../../../redux/app/appSaver";

const TECSalesOrder = ({document_id, location, getDocuments, passedDocumentSlug, setDocumentFocus}) => {

  //Set initial doc slug
  const documentSlug = "tec-sales-order-single";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [loadedInitialPreview, setLoadedInitialPreview] = useState(false);
  const activeModal = useSelector((state) => state?.appData.activeModal);
  const [fetchingDocument, setFetchingDocument] = useState(false);

  // Order Id
  let { id } = useParams();

  const order = useOrder();

  const salesReps = useSalesReps();
  const dispatch        = useDispatch()

  // useEffect(() => {

    

  //     dispatch(rdx.getSalesReps())

    

  // }, [appData?.activeModal, dispatch])

  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);
  
  var initialDocumentStructure = {
    location_address: undefined, // Header Location Details / System Autofill
    location_phone: undefined, // Header Location Details / System Autofill
    location_email: undefined, // Header Location Details / System Autofill
    tec_footer_label: undefined, // Footer / System Autofill
    pg_no: undefined, // Footer / System Autofill

    purchaser_name: undefined, 
    documentation_date: undefined, 
    salesperson: undefined, 
    purchaser_address: undefined, 
    purchaser_city_state_zip: undefined, 
    purchaser_phone: undefined, 
    purchaser_email: undefined, 
    // purchaser_fax: undefined, 
    quantity: undefined, 
    year: undefined, 
    make: undefined, 
    model: undefined, 
    vehicle_type: undefined, 
    mileage: undefined, // Missing AF Data Point
    serial_number: undefined, // Missing AF Data Point
    stock_number: undefined, 
    vin: undefined,
    special_instructions: undefined,
    trade_year: undefined, 
    trade_make: undefined, 
    trade_model: undefined, 
    trade_body_type: undefined, 
    trade_serial_number: undefined, 
    balance_owner: undefined, //Balance Owed To
    balance_owner_address: undefined, //FIX: Char Limit on PDF Field? Rework into 2/3 Lines?
    amount: undefined, // Balance Owed*
    good_until: undefined, 
    trade_allowance: undefined, 
    balance_owed: undefined, // Balance Owed* (Also Amount)
    cash_refund: undefined, // Weird Bug
    net_trade_allowance: undefined,  // Weird Bug
    cash_down_payment: undefined,  // Missing AF Data Point
    lienholder: undefined,  // Missing AF Data Point
    delivery: undefined,
    // lienholder_address: undefined,  // Missing AF Data Point
    // lienholder_city_state_zip: undefined,  // Missing AF Data Point
    fet: undefined, 
    sale_price: undefined, 
    // documentation_fee: undefined, // Missing AF Data Point
    elt_doc_fee: undefined,  // Missing AF Data Point
    subtotal: undefined,  // Missing AF Data Point
    tire_tax: undefined,  // Missing AF Data Point
    sales_tax: undefined,  // Missing AF Data Point
    cat_tax: undefined,  // Missing AF Data Point
    luxury_tax: undefined,  // Missing AF Data Point
    misc_tax: undefined,  // Missing AF Data Point
    admin_fee: undefined,  // Missing AF Data Point
    extended_warranty: undefined,  // Missing AF Data Point
    license_fee: undefined,  // Missing AF Data Point
    cash_delivery_price: undefined,  // Missing AF Data Point
    down_payment: undefined,  // Missing AF Data Point
    unpaid_balance_due: undefined,  // Missing AF Data Point
    is_extended: false,
    // sales_manager_signature: undefined, 
    // salesperson_signature: undefined, 
    // purchaser_signature: undefined, 
    // purchaser_signature_date: undefined, 
    // salesperson_signature_date: undefined, 
    // sales_manager_signature_date: undefined
  }
  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);

  const moneyFields = useMemo(()=>{return["fet", "sale_price", "delivery", "tire_tax", "sales_tax", "cat_tax", "luxury_tax", 
  "misc_tax", "admin_fee", "extended_warranty", "license_fee", "cash_delivery_price",
  "unpaid_balance_due", "cash_down_payment", "cash_refund", "balance_owed", "trade_allowance", 
  "amount", "elt_doc_fee", "subtotal"]},[]);
  const phoneFields = useMemo(()=>{return["purchaser_phone", "purchaser_fax"]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "model"]},[]);
  const checkboxes = useMemo(()=>{return[
    "",
  ]},[]);
  // const radios = [
  //   "", 
  // ];

  const removeFormatting = e =>{
    const {name,value}=e.target;
    setDocumentValues({...documentValues,[name]:removeChars(value)});
  }

  // Input Change Handler
  const handleDocumentValuesChange = e =>{
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);

    // if(name === "is_extended"){

    //   updateDocumentId(selectedProducts, selectedTradeins, value);
    // }
  }

  // Load Document
  // const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        setFetchingDocument(true);
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res){
          setDocument(res?.data);
          setFetchingDocument(false);
        }
      } catch (err) {
        recordFrontEndError('TECSalesOrder.jsx', 'Error loading document: ' + err)
      }

    }
},[axios, id])

const [selectedProducts, setselectedProducts] = useState([]);
// const [productVal, setproductVal] = useState(undefined);
// const [inputProductVal, setinputProductVal] = useState(undefined);
const [productOptions, setproductOptions] = useState(undefined);
// const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

// const [tradeinVal, setTradeinVal] = useState(undefined);
const [selectedTradeins, setselectedTradeins] = useState([]);
// const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
const [tradeinOptions, setTradeinOptions] = useState(undefined);
// const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);
  
  // Load Data
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {

    // console.log('so', document_id, previousDocumentId, passedDocumentSlug)

    if(fetchingDocument === false && document_id && document_id !== previousDocumentId && passedDocumentSlug && activeModal === 'document-edit' && passedDocumentSlug?.includes('tec-sales-order')){
      // if(
      //   passedDocumentSlug === "tec-sales-order-single" ||
      //   passedDocumentSlug === "tec-sales-order-multi" || 
      //   passedDocumentSlug === "tec-sales-order-multi-trade-multi" ||
      //   passedDocumentSlug === "tec-sales-order-single-trade-multi" ||
      //   passedDocumentSlug === "tec-sales-order-single-extended" ||
      //   passedDocumentSlug === "tec-sales-order-multi-extended" || 
      //   passedDocumentSlug === "tec-sales-order-multi-trade-multi-extended" ||
      //   passedDocumentSlug === "tec-sales-order-single-trade-multi-extended"
      // ){
        setdocumentUrl("");
        setproductOptions(undefined);
        setTradeinOptions(undefined);
        setPreviousDocumentId(document_id);
        // setDocumentLoaded(true);
        loadDocument(document_id);
      // }
    }else if(!passedDocumentSlug?.includes('tec-sales-order') && fetchingDocument === true){
      setFetchingDocument(false);
    }
  },[document_id, previousDocumentId, passedDocumentSlug, documentSlug, loadDocument, activeModal, fetchingDocument])
  // Data

  const [extendedWas, setExtendedWas] = useState(false);





// Dropdowns //
  if(order && order.products && order.products.length > 0 && productOptions === undefined){
    let getProductOptions = [];
    order.products.map(product => {
      let str = `${product.stockNumber.toString().toUpperCase()}`;
      let obj = {id: product.id, name: str}
      getProductOptions.push(obj);
      return product;
    })
    setproductOptions(getProductOptions);
  }

  if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
    let getTradeinOptions = [];
    order.tradeins.map(tradein => {
      let str = `${tradein.year} ${tradein.make} ${tradein.model.toString().toUpperCase()}`;
      let obj = {id: tradein.id, name: str}
      getTradeinOptions.push(obj);
      return tradein;
    })
    setTradeinOptions(getTradeinOptions);
  }

 // Autofill //

//  const preProcessPayload = useCallback((payload) => {


    
//   // X1.2- Field Type Specific Preview Formatting
//   Object.keys(payload).forEach(key => {
//     if(moneyFields.indexOf(key) >= 0){
//       if (typeof payload[key] === 'string' || payload[key] instanceof String){
//         payload[key] = formatMoneyValue(payload[key]);
//       }
//     }else if(phoneFields.indexOf(key) >= 0){
//         payload[key] = formatPhoneNumber(payload[key]);
//     }else if(upperCaseFields.indexOf(key) >= 0){
//         payload[key] = formatUpperCase(payload[key]);
//     }
//   });
//   setDocumentValues(JSON.parse(JSON.stringify(payload)));
//   Object.keys(payload).forEach(key => {
//     if(checkboxes.indexOf(key) >= 0){
//       payload[key] = payload[key] ? "yes": "no";
//     }
//   });
  
//   // Data point formatting
//   if(payload.documentation_date){
//     payload.documentation_date = moment.utc(payload.documentation_date).format("MM/DD/YY");
//   }

//   if(payload.purchaser_signature_date){
//     payload.purchaser_signature_date = moment.utc(payload.purchaser_signature_date).format("MM/DD/YY");
//   }
//   // if(payload.down_payment){
//   //   if(payload.down_payment < 0){
//   //     payload.down_payment = 
//   //     setDocumentValues({...documentValues,["down_payment"]:"("+formatMoneyValue(Math.abs(payload.down_payment))+")"});
//   //   }else{
//   //     setDocumentValues({...documentValues,["down_payment"]:formatMoneyValue(payload.down_payment)});
//   //   }
//   // }

//   // if(payload.net_trade_allowance){
//   //   if(payload.net_trade_allowance < 0){
//   //     setDocumentValues({...documentValues,["net_trade_allowance"]:"("+formatMoneyValue(Math.abs(payload.net_trade_allowance))+")"});
//   //   }else{
//   //     setDocumentValues({...documentValues,["net_trade_allowance"]:formatMoneyValue(payload.net_trade_allowance)});
//   //   }
//   // }

//   return payload;
// },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])

 const previewPayloadDocumentSOM = useCallback(async () => {

  var payload = documentValues;
  Object.keys(payload).forEach(key => {
    if (payload[key] === null || payload[key] === undefined) {
      delete payload[key];
    }
  });
  
  if(document){
    if(document.documentTemplate.slug === "tec-sales-order-single" 
      || document.documentTemplate.slug === 'tec-sales-order-single-trade-multi'
      || document.documentTemplate.slug === 'tec-sales-order-multi'
      || document.documentTemplate.slug === 'tec-sales-order-multi-trade-multi'
      || document.documentTemplate.slug === "tec-sales-order-single-extended" 
      || document.documentTemplate.slug === 'tec-sales-order-single-trade-multi-extended'
      || document.documentTemplate.slug === 'tec-sales-order-multi-extended'
      || document.documentTemplate.slug === 'tec-sales-order-multi-trade-multi-extended'
      ){

      var docCopy = JSON.parse(JSON.stringify(document));
      docCopy.payload =  JSON.parse(JSON.stringify(payload));
      docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);

      docCopy.payload =  JSON.stringify(docCopy.payload);
      delete docCopy.documentStatus;
      try {
        let response = await axios.post(`/document/${document_id}/preview`, docCopy, {responseType: 'blob' })
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        setdocumentUrl(fileURL)
      } catch (err) {
          recordFrontEndError('TECSalesOrder.jsx', 'Error previewing document payload: ' + err)
      };  
    }
  }
},[axios, checkboxes, document, documentValues, document_id, moneyFields, phoneFields, upperCaseFields]);


 const GlobalAutofill = useCallback(async () => {
    if(!salesReps || salesReps?.length === 0){
      dispatch(rdx.getSalesReps());
    }
    let newValues = TECSalesOrderGlobalAutofill(document, documentValues, order, null, null, location, null, salesReps);
    if(newValues){
      setDocumentValues(newValues);
      setHasUnsavedChanges(true);
      setSaveSuccess(false);
    }

    // function getRepByEmployeeId(employeeId){
    //   if(salesReps && salesReps.length > 0){
    //     let foundRep = salesReps.find(rep => rep.employee_ID === employeeId);
    //     if(foundRep){
    //       return foundRep.preferredName ? foundRep.preferredName+" "+foundRep.lastName : foundRep.firstName+" "+foundRep.lastName;
    //     }
    //   }
    //   return "";
    // }

    // var newValues = documentValues;
    // if(order && order.customer && order.customer.name){
    //   newValues.purchaser_name = order.customer.name;

    //   if(order.customer.dba){
    //     newValues.purchaser_name += " dba "+order.customer.dba;
    //   }
    // }

    // if(order && order.customer && order.customer.phone){
    //   newValues.purchaser_phone = formatPhoneNumber(order.customer.phone);
    // }

    // if(order && order.customer && order.customer.email){
    //   newValues.purchaser_email = order.customer.email;
    // }

    // if(order && order.customer && order.customer.deliveryAddress){
    //   var addressLine = '';
    //   if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
    //     addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
    //   }else{
    //     addressLine = order.customer.deliveryAddress.addressLine1;
    //   }
    //   newValues.purchaser_address = addressLine;
    // }

    // if(order && order.customer && order.customer.deliveryAddress){
    //   newValues.purchaser_city_state_zip = (order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
    // }

    // if(order && order.salesRepId){
    //   newValues.salesperson = getRepByEmployeeId(order.salesRepId);
    // }

    // if(order && order.documentationDate && documentValues.documentation_date === undefined){
    //   newValues.documentation_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    //   newValues.purchaser_signature_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    // }

    // if(order && order.specialInstructions){
    //   newValues.special_instructions = order.specialInstructions;
    // }


    // if(order && order.downPayments){
    //   var cashTotal = calculateTotalProductDeposits(order.products) + calculateTotalDownpayment(order.downPayments);
    //   newValues.cash_down_payment = "("+formatter.format(cashTotal)+")";
    // }

    // let downpaymentTotal = 0;
    // if(order.downPayments){
    //   downpaymentTotal = order.downPayments.reduce(function(prev, current) {
    //       return prev + +current.amount
    //   }, 0);
    // }

    // if(order && downpaymentTotal && downpaymentTotal > 0){
    //   newValues.down_payment = order.downpaymentTotal;
    // }

    // if(order && order.adminFee){
    //   newValues.admin_fee = formatter.format(order.adminFee);
    // }else{
    //   newValues.admin_fee = formatter.format(0);
    // }
    
    // Get Location Address & Autofill Address
    // if(location !== undefined){
    //   newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
    // }
  
    // if(location && location.phone){
    //   newValues.location_phone = location.phone;
    // }
    
    // if(location && location.email){
    //   newValues.location_email = location.email;
    // }
  
    // if(location){
    //   if(location.state === "CA"){
    //     newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
    //   }else{
    //     newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
    //   }
    // }

    // newValues.pg_no = "Pg #1";
    // setDocumentValues(newValues);
    // setHasUnsavedChanges(true);
 },[salesReps, document, documentValues, order, location, dispatch])


  useEffect(() => {
    if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){

      existingPayload = true;

      if(documentPayload.documentation_date){
        documentPayload.documentation_date = moment.utc(documentPayload.documentation_date).format("YYYY-MM-DD");
      }

      if(documentPayload.purchaser_signature_date){
        documentPayload.purchaser_signature_date = moment.utc(documentPayload.purchaser_signature_date).format("YYYY-MM-DD");
      }
      
      setDocumentValues(documentPayload);
    }

      if(!existingPayload){
        GlobalAutofill();
        setselectedProducts(productOptions);
      }else{
        if(document && document.itemIds){
          let foundProductOptions = [];
          var itemIds = document.itemIds.split(',');
          if(itemIds.length > 0){
            itemIds.forEach((product_id, i) => {
              var foundProduct = productOptions.find((product) => product.id === Number(product_id));
              if(foundProduct){
                foundProductOptions.push(foundProduct);
              }
            });
            setselectedProducts(foundProductOptions);
          }
        }

        if(document && document.tradeinIds){
          let foundTradeinOptions = [];
          var tradeinIds = document.tradeinIds.split(',');
          if(tradeinIds.length > 0){
            tradeinIds.forEach((tradein_id, i) => {
              var foundTradein = tradeinOptions.find((tradein) => tradein.id === Number(tradein_id));
              if(foundTradein){
                foundTradeinOptions.push(foundTradein);
              }
            });
            setselectedTradeins(foundTradeinOptions);
          }
        }
        // previewPayloadDocumentSOM();
      }
      
      setDataLoaded(true);
    }

    // if(dataLoaded && !loadedInitialPreview){
    //   setLoadedInitialPreview(true);
    //   previewPayloadDocumentSOM();
    // }

  },[ document, dataLoaded, loadedInitialPreview, setLoadedInitialPreview, GlobalAutofill, previewPayloadDocumentSOM, productOptions, tradeinOptions ])


  // async function getDocumentTemplateBySlug(slug){
  const getDocumentTemplateBySlug = useCallback((slug) => {
    return axios.get(`/document-template/${slug}/by-slug`);
  },[ axios ])

  const updateDocumentId = useCallback((productsSelected, tradeinsSelected, isExtended=false) => {

    let multiProduct = 0;
    if(productsSelected && productsSelected.length > 1){
      multiProduct = 1;
    }

    let multiTrade = 0;
    if(tradeinsSelected && tradeinsSelected.length > 1){
      multiTrade = 1;
    }

    let slug = 'tec-sales-order-single';
    if(multiProduct && !multiTrade){
      slug = 'tec-sales-order-multi';
    } else if(multiProduct && multiTrade){
      slug = 'tec-sales-order-multi-trade-multi';
    }else if(!multiProduct && multiTrade){
      slug = 'tec-sales-order-single-trade-multi';
    }

    if(isExtended === "true"){
      slug += '-extended';
    }
    
    // let documentTemplateId = 0;
    // if(slug !== document.documentTemplate.slug){
        return getDocumentTemplateBySlug(slug).then(async response => {
          let template = response?.data;
          if(template && template.id){
            if(document && document.id && template.id){
              try {
                await axios.put(`/document/${document.id}/update-document-template/${template.id}`)
                let updatedObject = document;
                updatedObject.documentTemplateId = template.id;
                setDocument(updatedObject);
                // setDocument({...document,['documentTemplateId']:template.id});
                previewPayloadDocumentSOM()
                // setDocument(undefined);
                // documentTemplateId = template.id;
              } catch (err) {
                recordFrontEndError('TECSalesOrder.jsx', 'Error updating document id: ' + err)
              };
            }
          }else{
            // Throw Error (Mark)
          }
        });
    // }
    // return documentTemplateId;
  },[ axios, document, getDocumentTemplateBySlug, previewPayloadDocumentSOM ])

  useEffect(() => {
    if(document && document.documentTemplate && document.documentTemplate.slug.includes('sales-order')){
      if(documentValues && documentValues.is_extended !== extendedWas){
        setExtendedWas(documentValues.is_extended);
        updateDocumentId(selectedProducts, selectedTradeins, documentValues.is_extended);
      }
    }

  },[ documentValues, extendedWas, selectedProducts, selectedTradeins, updateDocumentId, document ])

  function updateDocumentTemplateId(productsSelected, tradeinsSelected){
    updateDocumentId(productsSelected, tradeinsSelected, documentValues.is_extended);
  }
  
  // function previewPayloadDocumentSOM(){

  const updatePreview = e => {
    e.preventDefault();
    previewPayloadDocumentSOM();
  }

  const handleSubmit = async event => {
    event.preventDefault();

    let payload = JSON.parse(JSON.stringify(documentValues));

    payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
  
  

    // const payload = JSON.parse(JSON.stringify(documentValues));
    var docCopy = document;
    // docCopy.payload = payload;
    // docCopy.payload = preProcessPayload(docCopy.payload);     
    // docCopy.payload =  JSON.stringify(docCopy.payload);
    // delete docCopy.documentStatus;

    let productIds = [];
    if(selectedProducts && selectedProducts.length > 0){
      selectedProducts.forEach((product, i) => {
        productIds.push(product.id);
      });
      
      docCopy.itemIds = `${productIds.join(",")}`;
    }

    let tradeinIds = [];
    if(selectedTradeins && selectedTradeins.length > 0){
      selectedTradeins.forEach((tradein, i) => {
        tradeinIds.push(tradein.id);
      });
      
      docCopy.tradeinIds = `${tradeinIds.join(",")}`;
    }

    
  // let additionalData = JSON.stringify(dropdownData);

  DocumentHandleSubmit(docCopy, payload, null, () => {
    getDocuments();
    setSaveSuccess(true);
    setHasUnsavedChanges(false);
  });

    // if(selectedTradeins && selectedTradeins.id){
    //   docCopy.tradeinIds = `${selectedTradeins.id}`;
    // }TradeinIds

    //TODO: Add TradeId's to save.
    // try {
    //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
    //   if (res) {
    //     await axios.post(`/document/${document.id}/generate`) 
    //     getDocuments();
    //     setSaveSuccess(true);
    //     setHasUnsavedChanges(false);
    //   };
    // } catch (err) {
    //   recordFrontEndError('TECSalesOrder.jsx', 'Error saving document: ' + err)
    // };

    
  }

// Component Helpers //

// function calculateBalanceAllowanceTotal(tradeinsSelected){
//   var balanceOwedTotal = 0;
//   if(tradeinsSelected && tradeinsSelected.length > 0){
//     tradeinsSelected.forEach((tradein, i) => {
//       balanceOwedTotal += Number(tradein.balanceOwed);
//     });
//   }
//   return balanceOwedTotal;
// }

// function calculateTradeAllowanceTotal(tradeinsSelected){
//   var tradeAllowanceTotal = 0;
//   if(tradeinsSelected && tradeinsSelected.length > 0){
//     tradeinsSelected.forEach((tradein, i) => {
//       tradeAllowanceTotal += Number(tradein.tradeAllowanceAtTerms);
//     });
//   }
//   return tradeAllowanceTotal;
// }

// function calculateCashRefundTotal(tradeinsSelected){
//   var cashRefundTotal = 0;
//   if(tradeinsSelected && tradeinsSelected.length > 0){
//     tradeinsSelected.forEach((tradein, i) => {
//       cashRefundTotal += Number(tradein.cashRefundToCustomer);
//     });
//   }
//   return cashRefundTotal;
// }

// function calculateProductBasePriceSum(productsSelected){
//   var salePriceTotal = 0;
//   if(productsSelected && productsSelected.length > 0){
//     productsSelected.forEach((product, i) => {
//       salePriceTotal += Number(product.price);
//     });  }
//   return salePriceTotal;
// }

// function calculateBankFeeSum(productsSelected){
//   var bankFeeSum = 0;
//   if(productsSelected && productsSelected.length > 0){
//     productsSelected.forEach((product, i) => {
//       if(product && product.lineItemFee && product.lineItemFee.bankFee > 0){
//         bankFeeSum += Number(product.lineItemFee.bankFee);
//       }
//     });  
//   }
//   return bankFeeSum;
// }

// function calculateFetTotal(productsSelected){
//   var fetTotal = 0;
//   if(productsSelected && productsSelected.length > 0){
//     productsSelected.forEach((product, i) => {
//       if(product && product.lineItemFee && product.lineItemFee.applyFet === true){
//           fetTotal += (product && product.lineItemFee && product.lineItemFee.applyFet && product.type && product.type.toLowerCase() === "new" ? (product.lineItemFee.fetTotal ?? 0) : 0);
//       }
//     });
//   }
//   return fetTotal;
// }

// function calculateMiscTotal(productsSelected){
//   var miscTotal = 0;
//     if(productsSelected && productsSelected.length > 0){
//       productsSelected.forEach((product, i) => {
//         if(product && product.lineItemFee && product.lineItemFee.additionalTax > 0){
//           miscTotal += Number(product.lineItemFee.additionalTax ?? 0);
//         }

//         if(product && product.gapInsurance && product.gapInsurance.amount > 0){
//           miscTotal += Number(product.gapInsurance.amount);
//         }

//         if(product && product.upgrades && product.upgrades.length > 0){
//           product.upgrades.forEach((upgrade, i) => {
//             if(upgrade && upgrade.price){
//               miscTotal += Number(upgrade.price);
//             }
//           });
//         }
//       });
//     }
//   return miscTotal;
// }

// function calculateDeliveryTotal(productsSelected){
//   var deliveryTotal = 0;
//     if(productsSelected && productsSelected.length > 0){
//       productsSelected.forEach((product, i) => {
//         if(product && product.lineItemFee && product.lineItemFee.oosDeliveryFee > 0){
//           deliveryTotal += product.lineItemFee.oosDeliveryFee ?? 0;
//         }
//       });
//     }
//   return deliveryTotal;
// }

// function calculateTireTaxTotal(productsSelected, locationState){
//   var tireTaxTotal = 0;
//   // if(order.customer && order.customer.deliveryAddress && (order.customer.deliveryAddress.state === "CA" || order.customer.deliveryAddress.state === "AZ")){
//   if(locationState && locationState && (locationState === "CA" || locationState === "AZ")){
//       if(productsSelected && productsSelected.length > 0){
//       productsSelected.forEach((product, i) => {
//         if(product && product.lineItemFee && product.lineItemFee.tireTaxTotal > 0){
//           tireTaxTotal += product.lineItemFee.tireTaxTotal ?? 0;
//         }
//       });
//     }
//   }
//   return tireTaxTotal;
// }

// function calculateCatTaxTotal(productsSelected, order){
//   var catTaxTotal = 0;
//     if(productsSelected && productsSelected.length > 0){
//       productsSelected.forEach((product, i) => {
//         if(product && product.registrationAddress?.state && product.registrationAddress?.state === "OR" && product.lineItemFee && product.lineItemFee.catTaxTotal > 0){
//             catTaxTotal += product.lineItemFee.catTaxTotal ?? 0;
//         }
//       });
//     }
//   // }
//   return catTaxTotal;
// }

// function calculateLuxuryTaxTotal(productsSelected, order){
//   var luxuryTaxTotal = 0;
//     if(productsSelected && productsSelected.length > 0){
//       productsSelected.forEach((product, i) => {
//         if(product && product.registrationAddress?.state && product.registrationAddress?.state === "OR"){
//           if(product && product.lineItemFee && product.lineItemFee.applyLuxuryTax && product.lineItemFee.luxuryTaxTotal > 0){
//               luxuryTaxTotal += product.lineItemFee.luxuryTaxTotal ?? 0;
//           }
//         }
//       });
//     }
//   // }
//   return luxuryTaxTotal;
// }

// function calculateLicenseTitleTotal(productsSelected){
//   var licenseTitleTotal = 0;
//     if(productsSelected && productsSelected.length > 0){
//       productsSelected.forEach((product, i) => {
//         if(product && product.lineItemFee && (product.lineItemFee.applyTitleFee || product.lineItemFee.applyRegistrationFee) && product.lineItemFee.titleFee){
//             licenseTitleTotal += Number(product.lineItemFee.titleFee) ?? 0;
//         }
//       });
//     }
//   return licenseTitleTotal;
// }

// function calculateWarrantyTotal(productsSelected){
//   var warrantyTotal = 0;
//     if(productsSelected && productsSelected.length > 0){
//       productsSelected.forEach((product, i) => {
//         warrantyTotal = Number(warrantyTotal) + Number(calculateProductWarrantyTotal(product));
//       });
//     }
//   return warrantyTotal;
// }

// function calculateEltTotal(productsSelected, order){
//   var eltTotal = 0;
//   if(productsSelected && productsSelected.length > 0){
//     productsSelected.forEach((product, i) => {
//       if(product && product.lineItemFee){
//         eltTotal += Number((product.lineItemFee.etlFee ?? 0) + (product.lineItemFee.docFee ?? 0));
//       }
//     });
//   }
//   return eltTotal;
// }

// function calculateCashDeliveredPrice(productsSelected, tradeCredit, adminFee){
//   var cashDeliveryPrice = 0;
//   if(productsSelected && productsSelected.length > 0){
//     productsSelected.forEach((product, i) => {
//       cashDeliveryPrice += Number(calculateProductSubtotal(order, product));
//     });
//   }
//   cashDeliveryPrice += Number(adminFee);
//   return cashDeliveryPrice;
// }

// function getProducts(selected_products){
//   let products = [];
//   if(selected_products && selected_products.length > 0){
//     selected_products.forEach((item, i) => {
//       let selectedProduct = item;
//       let product = (order.products.filter(item => {
//         return item.id === selectedProduct.id
//       }));
//       if(product && product.length === 1) {
//         product = product[0];
//       };
      
//       if(product){
//         products.push(product);
//       }
//     });
//   }
//   return products;
// }

// function getTradeins(selected_tradins){
//   let tradeins = [];
//   if(selected_tradins && selected_tradins.length > 0){
//     selected_tradins.forEach((item, i) => {
//       let selectedTradein = item;
//       let tradein = order.tradeins.filter(item => {
//         return item.id === selectedTradein.id
//       });
//       if(tradein && tradein.length === 1) {
//         tradein = tradein[0];
//       };
      
//       if(tradein){
//         tradeins.push(tradein);
//       }
//     });
//   }
//   return tradeins;
// }

function formattedOptions(){
  return [{id:'selectAll', name:'Select All', options: productOptions}, ...productOptions]
}

const onProductChange = (productArray) => {


  if(productArray.some((x => x.id === 'selectAll'))){
    setselectedProducts(productOptions);
    calculateTotals(productOptions, selectedTradeins);
    updateDocumentTemplateId(productOptions, selectedTradeins);
  } else{
    setselectedProducts(productArray);
    calculateTotals(productArray, selectedTradeins);
    updateDocumentTemplateId(productArray, selectedTradeins);
  }
}

const onTradeinChange = (tradeinArray) => {
  setselectedTradeins(tradeinArray);
  calculateTotals(selectedProducts, tradeinArray);
  updateDocumentTemplateId(selectedProducts, tradeinArray);
}

function calculateTotals(productArray, tradeinArray){
  setDocumentValues(initialDocumentStructure);
  let updatedValues = calculateTotalsFunction(document, productArray, tradeinArray, order, documentValues, location);
  setDocumentValues(updatedValues);
}

// function calculateTotals(productArray, tradeinArray){
//   let productsSelected = getProducts(productArray);
//   let tradeinsSelected = getTradeins(tradeinArray);
//   setDocumentValues(initialDocumentStructure);
//   var newValues = documentValues;
//   // First Product Details
//   if(productsSelected && productsSelected.length > 0){
//     newValues.quantity = 1;
//     if(productsSelected[0] && productsSelected[0].year){
//       newValues.year = (productsSelected[0].year);
//     }
//     newValues.make = (productsSelected[0].make).trim();
//     newValues.model = (productsSelected[0].model).trim().toString().toUpperCase();
//     newValues.stock_number = (productsSelected[0].stockNumber).toString().toUpperCase();
//     newValues.mileage = (productsSelected[0].mileage);
//     newValues.serial_number = (productsSelected[0].serialNumber);
//     newValues.vin = (productsSelected[0].vin).toString().toUpperCase();
//     if(productsSelected[0].type && productsSelected[0].vehicleType){
//       newValues.vehicle_type = (productsSelected[0].type.toString().toUpperCase())+" "+(productsSelected[0].vehicleType.toString().toUpperCase());
//     }
//     if(productsSelected[0].fundingDetail){
//       if(productsSelected[0].fundingDetail.fundingMethod === 'tec-financing' || productsSelected[0].fundingDetail.fundingMethod === 'finance-only' || productsSelected[0].fundingDetail.fundingMethod === "direct-deal"){
//         if(productsSelected[0].fundingDetail.finalizedLendingOption){
//           newValues.lienholder = productsSelected[0].fundingDetail.finalizedLendingOption.bankName;
//         }
//       }
//       if(productsSelected[0].fundingDetail.fundingMethod === 'cash-with-lien'){
//         if(productsSelected[0].fundingDetail.cashLienHolder){  
//           newValues.lienholder = productsSelected[0].fundingDetail.cashLienHolder.bankName;
//         }
//       }
//     }

//   }
//   newValues.special_instructions = (order.specialInstructions && order.specialInstructions !== undefined ? order.specialInstructions : "");

//   if(productsSelected && productsSelected.length > 0){
//     productsSelected.forEach((product, i) => {
//       if(product.extendedWarranty && product.extendedWarranty.description){
//         newValues.special_instructions += "\n\n"+product.extendedWarranty.description
//       }
//     });
//   }

//   if(tradeinsSelected && tradeinsSelected.length > 0){
//     // First Tradein Details
//     newValues.trade_year = (tradeinsSelected[0].year).trim().replace(/['"]+/g, '');
//     newValues.trade_make = (tradeinsSelected[0].make).trim();
//     newValues.trade_model = (tradeinsSelected[0].model).trim().toString().toUpperCase();
//     newValues.trade_vin = (tradeinsSelected[0].vin).toString().toUpperCase();
//     newValues.trade_body_type = tradeinsSelected[0].seriesBodyType;
//     newValues.balance_owner = tradeinsSelected[0].balanceOwedTo;
//     if(tradeinsSelected[0].owedToAddress && tradeinsSelected[0].owedToCity && tradeinsSelected[0].owedToState && tradeinsSelected[0].owedToZip){
//       newValues.balance_owner_address = tradeinsSelected[0].owedToAddress+" "+(tradeinsSelected[0].owedToAddres2 ? (tradeinsSelected[0].owedToAddres2 ?? "")+" " : "")+tradeinsSelected[0].owedToCity+", "+tradeinsSelected[0].owedToState+" "+tradeinsSelected[0].owedToZip;
//     }
//     if(tradeinsSelected[0].goodUntil && moment.utc(tradeinsSelected[0].goodUntil)){
//       newValues.good_until = moment.utc(tradeinsSelected[0].goodUntil).format("MM/DD/YY");
//     }  
//   }

//   // Trade In Calcualtions

//       // Trade Allowance
//       let tradeAllowanceTotal = calculateTradeAllowanceTotal(tradeinsSelected);
//       if(tradeAllowanceTotal < 0){
//         newValues.trade_allowance = formatter.format(Math.abs(tradeAllowanceTotal));
//       }else{
//         newValues.trade_allowance = "("+formatter.format(tradeAllowanceTotal)+")";
//       }

//       // Balance Owed
//       let balanceOwedTotal = calculateBalanceAllowanceTotal(tradeinsSelected);
//       newValues.amount = formatter.format(balanceOwedTotal);
//       newValues.balance_owed = formatter.format(balanceOwedTotal);

//       // Cash Refund
//       let cashRefundTotal = calculateCashRefundTotal(tradeinsSelected);
//       newValues.cash_refund = formatter.format(cashRefundTotal);

//       // NET Trade Allowance
//       let tradeCredit = calculateTotalTradeCredit(tradeinsSelected);
//       if(tradeCredit < 0){
//         newValues.net_trade_allowance = formatter.format(Math.abs(tradeCredit));
//       }else{
//         newValues.net_trade_allowance = "("+formatter.format(tradeCredit)+")";
//       }

//   // Product Calculations

//     // Unit Price Total
//     // let salePriceTotal = calculateSalePriceTotal(productsSelected);
//     let salePriceTotal = calculateProductBasePriceSum(productsSelected);
//     newValues.sale_price = formatter.format(salePriceTotal);

//     // Sales Tax
//     let salesTaxTotal = calculateVehicleSalesTax(productsSelected, tradeinsSelected, order);
//     if(order && order.customer && order.customer.deliveryAddress && order.customer.deliveryAddress.state === "WA"){
//         let nonVehicleSalesTaxCalculated =  Number(calculateNonVehicleSalesTaxTotal(productsSelected, order));
//         salesTaxTotal += Number(nonVehicleSalesTaxCalculated);
//     }
//     newValues.sales_tax = formatter.format(salesTaxTotal);

//     // Fet 
//     let fetTotal = calculateFetTotal(productsSelected);
//     newValues.fet = formatter.format(fetTotal);

    
//     // ELT Total
//     let eltTotal = calculateEltTotal(productsSelected, order);
//     if(eltTotal && eltTotal > 0){
//       newValues.elt_doc_fee = formatter.format(eltTotal);
//     }

//     // Bank Fee
//     let bankFeeTotal = calculateBankFeeSum(productsSelected);
//     if(bankFeeTotal && bankFeeTotal > 0){
//       if(order?.adminFee > 0){
//         newValues.admin_fee = formatter.format((Number(order.adminFee)+Number(bankFeeTotal)));
//       }else{
//         newValues.admin_fee = formatter.format(Number(bankFeeTotal));
//       }
//     }

//     // Misc
//     let miscTotal = calculateMiscTotal(productsSelected);
//     newValues.misc_tax = formatter.format(miscTotal);
    
//     // Delivery
//     let deliveryTotal = calculateDeliveryTotal(productsSelected);
//     newValues.delivery = formatter.format(deliveryTotal);
    
//     // if(order && order.customer && order.customer.deliveryAddress && (order.customer.deliveryAddress.state === "CA" || order.customer.deliveryAddress.state === "AZ")){
//     if(location && location.state && (location.state === "CA" || location.state === "AZ")){
//         // Tire Tax (CALIFORNIA, ARIZONA)
//       let tireTaxTotal = calculateTireTaxTotal(productsSelected, location.state);
//       if(tireTaxTotal && tireTaxTotal > 0){
//         newValues.tire_tax = formatter.format(tireTaxTotal);
//       }
//     }

//     // Cat Tax (OREGON ONLY)
//     let catTaxTotal = calculateCatTaxTotal(productsSelected, order);
//     if(catTaxTotal && catTaxTotal > 0){
//       newValues.cat_tax = formatter.format(catTaxTotal);
//     }

//     // Luxury Tax (OREGON ONLY)
//     let luxuryTaxTotal = calculateLuxuryTaxTotal(productsSelected, order);
//     if(luxuryTaxTotal && luxuryTaxTotal > 0){
//       newValues.luxury_tax = formatter.format(luxuryTaxTotal);
//     }
    
//     // Warranty
//     let warrantyTotal = calculateWarrantyTotal(productsSelected);
//     if(warrantyTotal && warrantyTotal > 0){
//       newValues.extended_warranty = formatter.format(warrantyTotal);
//     }

//     // License / Title Fee's
//     let licenseTitleTotal = calculateLicenseTitleTotal(productsSelected);
//     newValues.license_fee = formatter.format(licenseTitleTotal);

//     // Cash Delivery
//     let cashDeliveryPrice = calculateCashDeliveredPrice(productsSelected, tradeCredit, removeChars(newValues.admin_fee));
//     if(salesTaxTotal){
//       cashDeliveryPrice += Number(salesTaxTotal);
//     }
//     newValues.cash_delivery_price = formatter.format(cashDeliveryPrice);

//     // Down Payment
//     let downPaymentTotal = calculateTotalDownpayment(order.downPayments);
//     let productDepositTotal = calculateTotalProductDeposits(productsSelected);
//     let adjustedTotalDownPayment = Number(downPaymentTotal) + Number(productDepositTotal) + Number(tradeCredit);
//     if(adjustedTotalDownPayment < 0){
//       newValues.down_payment = formatter.format(Math.abs(adjustedTotalDownPayment)); // Calculate
//     }else{
//       newValues.down_payment = "("+formatter.format(adjustedTotalDownPayment)+")"; // Calculate
//     }

//     // Balance Due
//     newValues.unpaid_balance_due = formatter.format(0.00);
//     if(adjustedTotalDownPayment){
//       newValues.unpaid_balance_due = formatter.format(cashDeliveryPrice - adjustedTotalDownPayment);
//     }else{
//       newValues.unpaid_balance_due = formatter.format(cashDeliveryPrice);
//     }

//     // Order Delivery Date
//     if(order && order.documentationDate && documentValues.documentation_date === undefined){
//       newValues.documentation_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
//     }

//     // Set new values
//     setDocumentValues(newValues);
// }

const [hasClickedClose, setHasClickedClose] = useState(false);


const onCloseClick = e => {
  e.preventDefault()
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setdocumentUrl("");
  setDocument(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
  setDataLoaded(false);
  setselectedProducts([]);
  setproductOptions(undefined);
  setselectedTradeins([]);
  setTradeinOptions(undefined);
  setDocumentFocus(undefined);
  setHasClickedClose(false);
  setLoadedInitialPreview(false);
  dispatch(setActiveModal(null));
  setFetchingDocument(false);
}


const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

return (
    <>
      <div
        className="modal fade"
        // id="document-edit-modal"
        id="tec-sales-order"
        // id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
        data-testid="modal.editDocument.topDiv"
        data-testcontext={"documentSlug: " + document?.documentTemplate?.slug + ", documentId: " + document?.id}
      >
        <div  className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                TEC Sales Order
              </h5>
              <br />
              { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1 pt-1 text-white">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                  </div>
              ): hasUnsavedChanges ? (
              <button
                type="button"
                className="btn-close bg-white"
                aria-label="Close"
                // onClick={() => setdocumentUrl("")}
                onClick={closeClicked}
              ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                  data-testid="modal.editDocument.control.close"
                ></button>
                )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                <div className="text-center">
                  <button 
                  type="button"
                  className="btn btn-primary mx-auto"
                  data-testid="modal.editDocument.control.reloadAutofillValues"
                  onClick={() => {
                    GlobalAutofill();
                    calculateTotals(selectedProducts, selectedTradeins);
                    previewPayloadDocumentSOM();
                  }}>Reload Autofill Values</button>
                </div>
                <br />
                {/* <h5>Prepare Form Fields</h5> */}
                {/* <form className="container-fluid mt-0"> */}

                <div className="p-2 col-6">
                  <label htmlFor="customerName" className="form-label">
                  Extended
                  </label>
                  <br />
                  <FormControl className="px-3 pt-2">
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="is_extended"
                      value={ (documentValues.is_extended ?? "" )}
                      onChange={(e) => {
                        handleDocumentValuesChange(e);
                        // const {value}=e.target;
                        // updateDocumentId(selectedProducts, selectedTradeins, value);
                      }}
                      // onBlur={(e) => updatePreview(e)}
                    >
                      <FormControlLabel className="px-3" value="true" control={<Radio />} label="Yes" />
                      <FormControlLabel className="px-3" value="false" control={<Radio />} label="No" />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className="row">
                  <h5>Order Details</h5>
                  <div className="col-md-5 mx-3">
                    <label htmlFor="stock">Purchaser Name</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="purchaser_name"
                      // onChange={(e) => onPurchaserNameChange(e)}
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      // value={purchaserNameVal !== undefined ? purchaserNameVal : (payload !== undefined && payload != null && payload.purchaser_name != null ? payload.purchaser_name : '')}
                      value={ documentValues.purchaser_name ?? '' }
                    />
                    <br />
                    <label htmlFor="stock">Phone</label>
                    <input
                      type="tel"
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      onFocus={removeFormatting}
                      className="form-control rounded-pill mt-1"
                      name="purchaser_phone"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.purchaser_phone ?? '' }
                    />
                    <br />
                    <label htmlFor="stock">Fax</label>
                    <input
                      type="tel"
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      onFocus={removeFormatting}
                      className="form-control rounded-pill mt-1"
                      name="purchaser_fax"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.purchaser_fax ?? '' }
                    />
                  </div>
                  <div className="col-md-5 mx-3">
                    <label htmlFor="stock">Address</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="purchaser_address"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.purchaser_address ?? '' }
                      />
                    <br />
                    <label htmlFor="stock">City, State, Zip</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="purchaser_city_state_zip"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.purchaser_city_state_zip ?? '' }
                      />
                    <br />
                    <label htmlFor="stock">Sales Rep</label>
                    <input
                      type="text"
                      className="form-control rounded-pill mt-1"
                      name="salesperson"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => updatePreview(e)}
                      value={ documentValues.salesperson ?? '' }
                      />
                  </div>
                  <div className="p-2 col-md-12">
                    <label htmlFor="stock">Delivery Date</label>
                    <input
                      type="date"
                      className="form-control rounded-pill mt-1"
                      name="documentation_date"
                      onChange={handleDocumentValuesChange}
                      onBlur={(e) => {
                        updatePreview(e)
                      }}
                      value={ documentValues.documentation_date ?? '' }
                      />
                  </div>
                </div>
                <hr></hr>

                <div className="row">
                  <h5>Equipment</h5>
                  { productOptions && productOptions.length > 0 ? (
                    <div className="d-flex justify-content-evenly">
                    <div className="mb-3 col-md-12 pt-4">
                      <Autocomplete
                        value={ selectedProducts ?? null}
                        multiple
                        style={{ width: '100%'}}
                        onChange={(event, newValue) => {
                          onProductChange(newValue);
                        }}
                        options={formattedOptions()}
                        getOptionLabel={(option) => option.name}
                        filterSelectedOptions
                        sx={{ width: 400 }}
                        renderInput={(params) => <TextField {...params} label="PRODUCTS" />}
                      />
                    </div>
                  </div>
                  ):(
                    <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Products Found</h5>
                    </div>
                  )}
                </div>
                <hr></hr>


                <div className="row">
                  <h5>Special Instructions</h5>
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                      <label htmlFor="stock">Special Instructions</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="special_instructions"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.special_instructions ?? '' }
                        />
                    </div>
                  </div>
                </div>
                <hr></hr>

                <div className="row">
                  <h5>Lien Holder</h5>
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                      <label htmlFor="stock">Lien Holder Name</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="lienholder"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.lienholder ?? '' }
                        />
                    </div>
                  </div>
                </div>
                <hr></hr>

               <div className="row">
                  <h5>Trade Details</h5>

                  { order && order.tradeins && order.tradeins.length > 0 ? (
                  <div className="d-flex justify-content-evenly">
                    <Autocomplete
                      multiple
                      style={{ width: '100%'}}
                      value={selectedTradeins ?? ""}
                      onChange={(event, newValue) => {
                        onTradeinChange(newValue);
                      }}
                      options={(tradeinOptions ?? [])}
                      getOptionLabel={(option) => option.name}
                      filterSelectedOptions
                      sx={{ width: 400 }}
                      renderInput={(params) => <TextField {...params} label="TRADE IN" />}
                    />
                  </div>
                  ):(
                    <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Trade Ins Found</h5>
                    </div>
                  )}
                  <br />

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-4">
                      <label htmlFor="stock">Year</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="trade_year"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.trade_year ?? '' }
                        />
                    </div>
                    <div className="p-2 col-4">
                      <label htmlFor="stock">Make</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="trade_make"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.trade_make ?? '' }
                        />
                    </div>
                    <div className="p-2 col-4">
                      <label htmlFor="stock">Model</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="trade_model"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.trade_model ?? '' }
                        />
                    </div>
                  </div>
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">Body Type</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="trade_body_type"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.trade_body_type ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">Serial No.</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="trade_serial_number"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.trade_serial_number ?? '' }
                        />
                    </div>
                  </div>
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                      <label htmlFor="stock">Balance Owed To</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="balance_owner"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.balance_owner ?? '' }
                        />
                    </div>
                  </div>
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-12">
                      <label htmlFor="stock">Address</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="balance_owner_address"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.balance_owner_address ?? '' }
                        />
                    </div>
                  </div>
                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">Amount</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="amount"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        onFocus={removeFormatting}
                        value={ documentValues.amount ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">Good Until</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="good_until"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        value={ documentValues.good_until ?? '' }
                        />
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">Trade In Allowance</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="trade_allowance"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        onFocus={removeFormatting}
                        value={ documentValues.trade_allowance ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">Balance Owed On Trade</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="balance_owed"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        onFocus={removeFormatting}
                        value={ documentValues.balance_owed ?? '' }
                        />
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">Cash Refund To Customer</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="cash_refund"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        onFocus={removeFormatting}
                        value={ documentValues.cash_refund ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">Net Trade In Allowance</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="net_trade_allowance"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        onFocus={removeFormatting}
                        value={ documentValues.net_trade_allowance ?? '' }
                        />
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly">
                    <div className="p-2 col-md-6">
                      <label htmlFor="stock">Cash Down Payment</label>
                      <input
                        type="text"
                        className="form-control rounded-pill mt-1"
                        name="cash_down_payment"
                        onChange={handleDocumentValuesChange}
                        onBlur={(e) => updatePreview(e)}
                        onFocus={removeFormatting}
                        value={ documentValues.cash_down_payment ?? '' }
                        />
                    </div>
                    <div className="p-2 col-md-6">
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="row">
                  <h5>Order Pricing</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">FET</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="fet"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          onFocus={removeFormatting}
                          value={ documentValues.fet ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Sale Price</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="sale_price"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          onFocus={removeFormatting}
                          value={ documentValues.sale_price ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Delivery</label>
                          <input
                            type="text"
                            className="form-control rounded-pill mt-1"
                            name="delivery"
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                            onFocus={removeFormatting}
                            value={ documentValues.delivery ?? '' }
                            />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">ELT Fee / Documentation Fee</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="elt_doc_fee"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          onFocus={removeFormatting}
                          value={ documentValues.elt_doc_fee ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Estimated OR CAT</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="cat_tax"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          onFocus={removeFormatting}
                          value={ documentValues.cat_tax ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">OR Vehicle Privilege & Use Tax</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="luxury_tax"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          onFocus={removeFormatting}
                          value={ documentValues.luxury_tax ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Miscellaneous (Includes Gap Value)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="misc_tax"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          onFocus={removeFormatting}
                          value={ documentValues.misc_tax ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Admin Fee</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="admin_fee"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          onFocus={removeFormatting}
                          value={ documentValues.admin_fee ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                      <label htmlFor="stock">Extended Warranty</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="extended_warranty"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          onFocus={removeFormatting}
                          value={ documentValues.extended_warranty ?? '' }
                          />
                      </div>
                      {/* {order && order.customer && order.customer.deliveryAddress && order.customer.deliveryAddress.state && order.customer.deliveryAddress.state === "CA" ? ( */}
                        <div className="p-2 col-md-6">
                          <label htmlFor="stock">Tire Tax</label>
                          <input
                            type="text"
                            disabled={(location && (location.state !== "CA" || location.state !== "AZ"))}
                            className="form-control rounded-pill mt-1"
                            name="tire_tax"
                            onFocus={removeFormatting}
                            onChange={handleDocumentValuesChange}
                            onBlur={(e) => updatePreview(e)}
                            value={ documentValues.tire_tax ?? '' }
                            />
                        </div>
                      {/* ):(
                        <div className="p-2 col-md-6"></div>
                      )} */}
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">License/Title Fees</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="license_fee"
                          onFocus={removeFormatting}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.license_fee ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Sales Tax</label>
                        <input
                          type="text"
                          disabled={order?.customer?.isExemptSalesTax === true}
                          className="form-control rounded-pill mt-1"
                          name="sales_tax"
                          onFocus={removeFormatting}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.sales_tax ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Total Cash Delivered Price</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="cash_delivery_price"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.cash_delivery_price ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Total Down Payment</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="down_payment"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.down_payment ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Unpaid Cash Balance Due On Delivery</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="unpaid_balance_due"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.unpaid_balance_due ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-">
                        <label htmlFor="stock">Signature Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="purchaser_signature_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => {
                            updatePreview(e)
                          }}
                          value={ documentValues.purchaser_signature_date ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <button className="btn btn-small btn-primary mx-3" 
                    type="button"
                    onClick={(e) => {
                      updateDocumentTemplateId(selectedProducts, selectedTradeins);
                    }}
                  >
                    Update Preview
                  </button>
                  <embed data-testid="modal.editDocument.embededPreview" width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
              data-testid="modal.editDocument.control.savedDocumentButtonNoAction"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            onClick={handleSubmit}
            data-testid="modal.editDocument.control.save"
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

const {
  useSalesReps
} = rdx

export default TECSalesOrder

import moment from 'moment'
import { formatNameLastMiddleFirst, formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";

function AFName(newValues, order, value){
    if(order?.customer?.type === "entity"){
        newValues.name = value.toString().toUpperCase();
    }else if(order?.customer?.type === "individual"){
        newValues.name = formatNameLastMiddleFirst(value).toString().toUpperCase();
    }
    return newValues;
}

function AFDba(newValues, order, value){
    newValues.name += " dba "+value;
    return newValues;
}

function AFCosignerName(newValues, order, value){
    newValues.name += " AND "+value;
    return newValues;
}

export const OregonTitleGlobalAutofill = (documentValues, order, property=null, value=null) => {
    // setAddressOptions(getOrderLevelAddresses(order));
    var newValues = documentValues;

    if(property?.includes('customer') || property === null){

      if(order && order.customer && order.customer.name){
          newValues = AFName(newValues, order, order.customer.name);

          if(order.customer.dba){
              newValues = AFDba(newValues, order, order.customer.dba);
          }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
              let cosigner = order.customer.cosigners[0];
              if(cosigner && cosigner.name){
                  newValues = AFCosignerName(newValues, order, cosigner.name);
              }
          }
      }
      

      if(order && order.customer && order.customer.deliveryAddress){
        var addressLineDelivery = '';
        if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
          addressLineDelivery = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
        }else{
          addressLineDelivery = order.customer.deliveryAddress.addressLine1;
        }
        newValues.address = addressLineDelivery;
        newValues.city_state_zip = (order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
      }

      if(order && order.customer && order.customer.mailingAddress){
        var addressLineMailing = '';
        if(order.customer.mailingAddress.addressLine2 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
          addressLineMailing = (order.customer.mailingAddress.addressLine1+" "+order.customer.mailingAddress.addressLine2);
        }else{
          addressLineMailing = order.customer.mailingAddress.addressLine1;
        }
        newValues.mailing_address = addressLineMailing;
        newValues.mailing_city_state_zip = (order.customer.mailingAddress.city+", "+order.customer.mailingAddress.state+" "+order.customer.mailingAddress.zip);
      }

    }

    if(property?.includes('documentationDate') || property === null){

      if(order && order.documentationDate && documentValues.delivery_date === undefined){
        newValues.delivery_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
      }

    }

    return newValues;
}

export const OregonTitleAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {

  var newValues = documentValues;


  if(selectedProduct){

    if(property?.includes('year') || property === null){
      newValues.year = (selectedProduct.year).trim().replace(/['"]+/g, '');
    }

    if(property?.includes('make') || property === null){
      newValues.make = (selectedProduct.make).trim();
    }

    if(property?.includes('model') || property === null){
      newValues.model = (selectedProduct.model).trim();
    }

    if(property?.includes('stockNumber') || property === null){
      newValues.stock_number = (selectedProduct.stockNumber);
    }

    if(property?.includes('mileage') || property === null){
      newValues.mileage = (selectedProduct.mileage);
    }

    if(property?.includes('serialNumber') || property === null){
      newValues.serial_number = (selectedProduct.serialNumber); // Unique
    }

    if(property?.includes('vin') || property === null){
      newValues.vin = (selectedProduct.vin); // Unique
    }

    if(property?.includes('vehicleType') || property === null){
      newValues.style = (selectedProduct.vehicleType);
    }

    if(property?.includes('fundingDetail') || property?.includes('fundingMethod') || property === null){

      if(selectedProduct.fundingDetail && selectedProduct.fundingDetail.finalizedAt){
        if(selectedProduct.fundingDetail.fundingMethod === "tec-financing" || selectedProduct.fundingDetail.fundingMethod === "finance-only" || selectedProduct.fundingDetail.fundingMethod === "direct-deal"){
          if(selectedProduct.fundingDetail.finalizedLendingOption){
          newValues.security_name = selectedProduct.fundingDetail.finalizedLendingOption.bankName;

          let addressLine = "";
          addressLine = selectedProduct.fundingDetail.finalizedLendingOption.lienAddress;

          addressLine += " "+(selectedProduct.fundingDetail.finalizedLendingOption.lienCity+", "+selectedProduct.fundingDetail.finalizedLendingOption.lienState+" "+selectedProduct.fundingDetail.finalizedLendingOption.lienZip)
          newValues.security_address_city_state_zip = addressLine;
          }
        }

        if(selectedProduct.fundingDetail.fundingMethod === "cash-with-lien"){
        if(selectedProduct.fundingDetail.cashLienHolder){
          newValues.security_name = selectedProduct.fundingDetail.cashLienHolder.bankName;

          let addressLine = "";
          if(selectedProduct.fundingDetail.cashLienHolder.addressLine2 != null && selectedProduct.fundingDetail.cashLienHolder.addressLine2 !== undefined && selectedProduct.fundingDetail.cashLienHolder.addressLine2 !== ''){
            addressLine = (selectedProduct.fundingDetail.cashLienHolder.addressLine1+" "+selectedProduct.fundingDetail.cashLienHolder.addressLine2);
          }else{
            addressLine = selectedProduct.fundingDetail.cashLienHolder.addressLine1;
          }

          addressLine += (selectedProduct.fundingDetail.cashLienHolder.city+", "+selectedProduct.fundingDetail.cashLienHolder.state+" "+selectedProduct.fundingDetail.cashLienHolder.zip)
          newValues.security_address_city_state_zip = addressLine;
        }
        }
      }

    }
  }

  return newValues;
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

      
    if(payload.width_exceeded === "yes"){
      payload.width_exceeded_yes = "yes";
      payload.width_exceeded_no = "no";
    }else if(payload.width_exceeded === "no"){
      payload.width_exceeded_yes = "no";
      payload.width_exceeded_no = "yes";
    }else{
      payload.width_exceeded_yes = "";
      payload.width_exceeded_no = "";
    }
    
    if(payload.weight_exceeded === "yes"){
      payload.weight_exceeded_yes = "yes";
      payload.weight_exceeded_no = "no";
    }else if(payload.weight_exceeded === "no"){
      payload.weight_exceeded_yes = "no";
      payload.weight_exceeded_no = "yes";
    }else{
      payload.weight_exceeded_yes = "";
      payload.weight_exceeded_no = "";
    }
    
    if(payload.mileage_excess === "yes"){
      payload.mileage_excess = "yes";
      payload.mileage_incorrect = "no";
    }else if(payload.mileage_excess === "no"){
      payload.mileage_excess = "no";
      payload.mileage_incorrect = "yes";
    }else{
      payload.mileage_excess = "";
      payload.mileage_incorrect = "";
    }
    
    
    if(payload.owner_checked === "yes"){
      payload.owner_checked = "yes";
      payload.lessee_checked = "no";
    }else if(payload.owner_checked === "no"){
      payload.owner_checked = "no";
      payload.lessee_checked = "yes";
    }else{
      payload.owner_checked = "";
      payload.lessee_checked = "";
    }
    
    if(payload.reg_title_both === "reg"){
      payload.registration_only = "yes";
      payload.title_only = "no";
      payload.both = "no";
    }else if(payload.reg_title_both === "title"){
      payload.registration_only = "no";
      payload.title_only = "yes";
      payload.both = "no";
    }else if(payload.reg_title_both === "both"){
      payload.registration_only = "no";
      payload.title_only = "no";
      payload.both = "yes";
    }
    
    if(payload.joint_owners_agree_ros === "yes"){
      payload.joint_owners_agree_ros_yes = "yes";
      payload.joint_owners_agree_ros_no = "no";
    }else if(payload.joint_owners_agree_ros === "no"){
      payload.joint_owners_agree_ros_yes = "no";
      payload.joint_owners_agree_ros_no = "yes";
    }else{
      payload.joint_owners_agree_ros_yes = "";
      payload.joint_owners_agree_ros_no = "";
    }
    
    if(payload.security_agree_ros === "yes"){
      payload.security_agree_ros_yes = "yes";
      payload.security_agree_ros_no = "no";
    }else if(payload.security_agree_ros === "no"){
      payload.security_agree_ros_yes = "no";
      payload.security_agree_ros_no = "yes";
    }else{
      payload.security_agree_ros_yes = "";
      payload.security_agree_ros_no = "";
    }
    
    if(payload.fuel_toggle){
      // Reset all
      payload.gas_cb = "no";
      payload.diesel_cb = "no";
      payload.hybrid_cb = "no";
      payload.plug_in_hybrid_cb = "no";
      payload.electric_cb = "no";
      payload.flex_fuel_cb = "no";
      payload.propane_cb = "no";
      payload.natural_gas_cb = "no";
      payload.other_cb = "no";
      
      switch(payload.fuel_toggle){
        case "gas":
          payload.gas_cb = "yes";
          break;
          case "diesel":
            payload.diesel_cb = "yes";
            break;
            case "hybrid":
              payload.hybrid_cb = "yes";
              break;
        case "plug_in_hybrid":
          payload.plug_in_hybrid_cb = "yes";
          break;
          case "electric":
            payload.electric_cb = "yes";
            break;
            case "flex_fuel":
          payload.flex_fuel_cb = "yes";
          break;
          case "propane":
            payload.propane_cb = "yes";
            break;
            case "natural_gas":
          payload.natural_gas_cb = "yes";
          break;
          case "other":
            payload.other_cb = "yes";
            break;
            default:
            }
    }
    
      // X1.2- Field Type Specific Preview Formatting
      Object.keys(payload).forEach(key => {
        if(moneyFields && moneyFields.indexOf(key) >= 0){
            payload[key] = formatMoneyValue(payload[key]);
        }else if(phoneFields && phoneFields.indexOf(key) >= 0){
            payload[key] = formatPhoneNumber(payload[key]);
        }else if(upperCaseFields && upperCaseFields.length > 0 && upperCaseFields.indexOf(key) >= 0){
            payload[key] = formatUpperCase(payload[key]);
        }
      });
    //   setDocumentValues(JSON.parse(JSON.stringify(payload)));
      Object.keys(payload).forEach(key => {
        if(checkboxes && checkboxes.indexOf(key) >= 0){
          payload[key] = payload[key] ? "yes": "no";
        }
      });
      
      if(payload.reading_date){
        payload.reading_date = moment.utc(payload.reading_date).format("MM/DD/YY");
      }

      if(payload.dob){
        payload.dob = moment.utc(payload.dob).format("MM/DD/YY");
      }

      if(payload.joint_dob){
        payload.joint_dob = moment.utc(payload.joint_dob).format("MM/DD/YY");
      }

      if(payload.joint_dob_2){
        payload.joint_dob_2 = moment.utc(payload.joint_dob_2).format("MM/DD/YY");
      }

      if(payload.security_dob){
        payload.security_dob = moment.utc(payload.security_dob).format("MM/DD/YY");
      }

      if(payload.secondary_dob){
        payload.secondary_dob = moment.utc(payload.secondary_dob).format("MM/DD/YY");
      }

      if(payload.lessor_dob){
        payload.lessor_dob = moment.utc(payload.lessor_dob).format("MM/DD/YY");
      }

      if(payload.delivery_date){
        payload.delivery_date = moment.utc(payload.delivery_date).format("MM/DD/YY");
      }

      return payload;
}

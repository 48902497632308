import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import { useOrder } from "../../../../../../../redux/orders/order"
import { formatter, removeChars, formatPhoneNumber, formatUpperCase } from "../../../../../../../utlis/formatters";
import { getOrderLevelAddresses, updateAddressOptions } from "../../../../../../../utlis/documentHelpers";
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { NVPowerOfAttorneyGlobalAutofill, NVPowerOfAttorneyAutofillProductDetails, preProcessPayload } from "../DocumentAutofill/NVPowerOfAttorney"

const NVPowerOfAttorney = ({document_id, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "nv-poa";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  // const dispatch = useDispatch();

  // const stateAbbrev = States.map(state => {
  //   return state.abbreviation;
  // });

  const order = useOrder();
  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);

  // Data Structure & Init //
  
  var initialDocumentStructure = {
    applicant_name: undefined, 
    applicant_county: undefined, 
    year: undefined, 
    make: undefined, 
    model: undefined, 
    vin: undefined, 
    aif_name: undefined, 
    aif_county: undefined, 
    aif_state: undefined, 
    applicant_first: undefined, 
    applicant_mi: undefined, 
    applicant_last: undefined, 
    applicant_id: undefined, 
    applicant_street: undefined, 
    applicant_city: undefined, 
    applicant_state_from: undefined, 
    applicant_mailing_city: undefined, 
    applicant_mailing_street: undefined, 
    notary_county: undefined, 
    applicant_zip: undefined, 
    applicant_state: undefined, 
    applicant_mailing_zip: undefined, 
    applicant_mailing_state: undefined
  }

  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  // X1
  // const removeFormatting = e =>{
  //   const {name,value}=e.target;
  //   setDocumentValues({...documentValues,[name]:removeChars(value)});
  // }

  // X1 Reference Arrays - TODO: Possibly move these to back end as part of the template stucture
  const moneyFields = useMemo(()=>{return[""]},[]);
  const phoneFields = useMemo(()=>{return[""]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "model"]},[]);
  const checkboxes = useMemo(()=>{return[
    ""
  ]},[]);
  // const radios = [
  //   "",
  // ];

  // X1- Current General On Change
  const handleDocumentValuesChange = e =>{
    // e.preventDefault();
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatPhoneNumber(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  
  // const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('NVPowerOfAttorney.jsx', 'Error loading document' + err)
      }
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      // setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, loadDocument ])
  const [assignedProduct, setAssignedProduct] = useState(undefined);
  // const [productVal, setproductVal] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  // const [tradeinVal, setTradeinVal] = useState(undefined);
  // const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  // const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);

  const [selectedAddress1, setSelectedAddress1] = useState(undefined);
  const [selectedAddress2, setSelectedAddress2] = useState(undefined);
  // const [selectedAddress3, setSelectedAddress3] = useState(undefined);
  // const [selectedAddress4, setSelectedAddress4] = useState(undefined);
  const [addressOptions, setAddressOptions] = useState(undefined);
  const [inputAddressVal, setinputAddressVal] = useState(undefined);

  // const [vehicleTypeOptions, setVehicleTypeOptions] = useState(undefined);
  // const [colorOptions, setColorOptions] = useState(undefined);
  // const [ownerTypeOptions, setOwnerTypeOptions] = useState(undefined);
  // const [idTypeOptions, setIdTypeOptions] = useState(undefined);
  // const [phoneTypeOptions, setPhoneTypeOptions] = useState(undefined);
  // const [dealerTypeOptions, setDealerTypeOptions] = useState(undefined);
  // const [weightRatingOptions, setWeightRatingOptions] = useState(undefined);
  // const [fuelTypeOptions, setFuelTypeOptions] = useState(undefined);
  // const [motoStyleOptions, setMotoStyleOptions] = useState(undefined);
  // const [useTypeOptions, setUseTypeOptions] = useState(undefined);

// Dropdowns //colorOptions

// if(order && order.products && order.products.length > 0 && productOptions === undefined){
//   let getProductOptions = [];
//   order.products.map(product => {
//     let str = `${product.stockNumber.toString().toUpperCase()}`;
//     let obj = {id: product.id, name: str}
//     getProductOptions.push(obj);
//   })
//   setproductOptions(getProductOptions);
// }

// if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
//   let getTradeinOptions = [];
//   order.tradeins.map(tradein => {
//     let str = `${tradein.year} ${tradein.make} ${tradein.model.toString().toUpperCase()}`;
//     let obj = {id: tradein.id, name: str}
//     getTradeinOptions.push(obj);
//   })
//   setTradeinOptions(getTradeinOptions);
// }


 // Autofill //

 const GlobalAutofill = useCallback(async () => {
  let newValues = NVPowerOfAttorneyGlobalAutofill(documentValues, order);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  // var newValues = documentValues;
  
  // if(order && order.customer && order.customer.name){
  //   if(order.customer.type !== "entity" && order.customer.dba){
  //     newValues.applicant_name = order.customer.name+" / "+order.customer.dba;
  //   }else{
  //     newValues.applicant_name = order.customer.name;
  //   }

  //   if(order.customer.dba){
  //     newValues.applicant_name += " dba "+order.customer.dba;
  //   }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
  //     let cosigner = order.customer.cosigners[0];
  //     if(cosigner && cosigner.name){
  //         newValues.applicant_name += " AND "+cosigner.name;
  //     }
  //   }
  // }

  // if(order && order.documentationDate && documentValues.delivery_date === undefined){
  //   newValues.delivery_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
  // }

  // setDocumentValues(newValues);
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ documentValues, order ])


// const preProcessPayload = useCallback((payload) => {

//   // Data point formatting
  

//   // X1.2- Field Type Specific Preview Formatting
//   Object.keys(payload).forEach(key => {
//     if(moneyFields.indexOf(key) >= 0){
//         payload[key] = formatMoneyValue(payload[key]);
//     }else if(phoneFields.indexOf(key) >= 0){
//         payload[key] = formatPhoneNumber(payload[key]);
//     }else if(upperCaseFields.indexOf(key) >= 0){
//         payload[key] = formatUpperCase(payload[key]);
//     }
//   });
//   setDocumentValues(JSON.parse(JSON.stringify(payload)));
//   Object.keys(payload).forEach(key => {
//     if(checkboxes.indexOf(key) >= 0){
//       payload[key] = payload[key] ? "yes": "no";
//     }
//   });
// if(payload.delivery_date){
//     payload.delivery_date = moment.utc(payload.delivery_date).format("MM/DD/YY");
//   }
//   return payload;
// },[ checkboxes, moneyFields, phoneFields, upperCaseFields ])


  // Document BREAD + Preview // TODO: Move to Redux

const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });
    
    var docCopy = JSON.parse(JSON.stringify(document));
    docCopy.payload =  JSON.parse(JSON.stringify(payload));
    docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
    docCopy.payload =  JSON.stringify(docCopy.payload);
    delete docCopy.documentStatus;
    try {
      let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
      const file = new Blob(
        [response.data], 
        {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
      setdocumentUrl(fileURL)
    } catch (err) {
      recordFrontEndError('NVPowerOfAttorney.jsx', 'Error previewing document payload: ' + err)
    };  
},[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


const AutofillProductDetails = useCallback(async (selectedProduct) => {

  let newValues = NVPowerOfAttorneyAutofillProductDetails(selectedProduct, documentValues);

  if(newValues){
    setDocumentValues(newValues);
    previewPayloadDocument(documentValues);
    setHasUnsavedChanges(true);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setSaveSuccess(false);
  }

  // var newValues = documentValues;
  // newValues.vin = (selectedProduct.vin).toString().toUpperCase();
  // newValues.year = (selectedProduct.year).trim();
  // newValues.make = (selectedProduct.make).trim();
  // newValues.model = (selectedProduct.model).toString().toUpperCase();

  // if(selectedProduct.type.toUpperCase() === "NEW"){
  //   newValues.new_used_toggle = "new";
  // }else{
  //   newValues.new_used_toggle = "used";
  // }

  // newValues.vehicle_type = selectedProduct.vehicle_type;

  // setDocumentValues(newValues);
  // // setProductToggleAutoFill(false);
  // if(document){
  //   previewPayloadDocument(documentValues);
  // }
  // // setAddressOptions(updateAddressOptions(selectedProduct, order));
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ document, documentValues, previewPayloadDocument ]);

useEffect(() => {
  if(document && document.payload && !dataLoaded){
  var existingPayload = false;
  var documentPayload = JSON.parse(document.payload);
  if(documentPayload && Object.keys(documentPayload).length > 0){

    existingPayload = true;

    if(documentPayload.delivery_date){
      documentPayload.delivery_date = moment.utc(documentPayload.delivery_date).format("YYYY-MM-DD");
    }
    
    setDocumentValues(documentPayload);


    // Loading Use Effect
    if(document.additionalData){
      const methodMap = { 
        selectedAddress1: setSelectedAddress1, 
        selectedAddress2: setSelectedAddress2, 
      };

      let parsedAdditionalData = JSON.parse(document.additionalData);
      Object.keys(parsedAdditionalData).forEach(function(key) {
        if(methodMap[key]){
          methodMap[key](parsedAdditionalData[key]);
        }
      });
    }

  }

  var foundAssignedProduct = null;
  if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
    foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
  }

  setAddressOptions(getOrderLevelAddresses(order));
  if(foundAssignedProduct){
      setAssignedProduct(foundAssignedProduct);
      setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
  }
  
  
    if(!existingPayload){
      GlobalAutofill();
      previewPayloadDocument(documentValues);
      if(foundAssignedProduct){
        AutofillProductDetails(foundAssignedProduct)
      }
    }else{
      // if(document && document.itemIds){
      //   var itemIds = document.itemIds.split(',');
      //     var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
      //     let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
      //     let obj = {id: foundProduct.id, name: str}
      //     setproductVal(obj);
      //     if(foundProduct){
      //       setAddressOptions(updateAddressOptions(foundProduct, order));
      //     }
      //     streamDocument();
      // }

    }
    
    setDataLoaded(true);
  }
},[ documentValues, document, dataLoaded, AutofillProductDetails, GlobalAutofill, order, previewPayloadDocument ])





  
    // function streamDocument(){
    //   axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
    //     .then(response => {
    //       const file = new Blob(
    //         [response.data], 
    //         {type: 'application/pdf'});
    //         const fileURL = URL.createObjectURL(file);
    //         // window.open(fileURL);
    //       setdocumentUrl(fileURL)
    //     }).catch(err => {
    //     })
    // }
  
    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();
  
      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
    
    

      // const payload = JSON.parse(JSON.stringify(documentValues));
      // var docCopy = document;
      // docCopy.payload = payload;
      // docCopy.payload = preProcessPayload(docCopy.payload);
      // docCopy.payload =  JSON.stringify(docCopy.payload);
      // delete docCopy.documentStatus;
      
      // if(selectedProduct && selectedProduct.id){
      //   docCopy.itemIds = `${selectedProduct.id}`;
      // }
  
      //Save Dropdown Selections
      let dropdownData = {};
      if(selectedAddress1){
        dropdownData.selectedAddress1 = {
          id: selectedAddress1.id,
          name: selectedAddress1.name
        };
      }
      if(selectedAddress2){
        dropdownData.selectedAddress2 = {
          id: selectedAddress2.id,
          name: selectedAddress2.name
        };
      }

      let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, additionalData, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });

      // docCopy.additionalData = JSON.stringify(dropdownData);

      // try {
      //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
      //   if (res) {
      //     axios.post(`/document/${document.id}/generate`) 
      //     getDocuments();
      //     setSaveSuccess(true);
      //     setHasUnsavedChanges(false);
      //   }
      // } catch (err) {
      //   recordFrontEndError('NVPowerOfAttorney.jsx', 'Error saving document: ' + err)
      // };
      
    }

// Component Helpers //
// useEffect(() => {
  //Autofill Product Details After Selection

// },[ selectedProduct, productToggleAutoFill ])

// useEffect(() => {
//   //Autofill Tradein Details After Selection
//   if(selectedTradein !== undefined && tradeinToggleAutoFill){
//     var newValues = documentValues;
//     newValues.trade_year = (selectedTradein.year);
//     newValues.trade_make = (selectedTradein.make);
//     newValues.trade_model = (selectedTradein.model);
//     newValues.trade_body_type = (selectedTradein.seriesBodyType);
//     newValues.trade_serial_number = (selectedTradein.serialNumber);
//     newValues.balance_owner = (order.customer.name); //Need specific field for TRADE IN OWNER?
//     newValues.balance_owner_address = (order.customer.deliveryAddress.addressLine1);
//     newValues.amount = (selectedTradein.balanceOwed);
//     newValues.trade_allowance = (selectedTradein.tradeAllowanceAtTerms);
//     newValues.balance_owed = (selectedTradein.balanceOwed);
//     newValues.cash_refund = (selectedTradein.cashRefundToCustomer);
//     newValues.net_trade_allowance = (selectedTradein.finalTradeAllowance);
//     // newValues.cash_down_payment = (selectedTradein);

//     setDocumentValues(newValues);
//     setTradeinToggleAutoFill(false);
//     if(document){
//       previewPayloadDocument(documentValues);
//     }
//   }
// },[ selectedTradein, tradeinToggleAutoFill ])

//Autofill Address Details After Selection
function AutoFillAddress(addressSelected, selectionInstance){
  var newValues = documentValues;
  if(selectionInstance){
    if(selectionInstance === "physical-address"){
      newValues.applicant_street = addressSelected.address;
      newValues.applicant_city = addressSelected.city;
      newValues.applicant_state = addressSelected.state;
      newValues.applicant_zip = addressSelected.zip;
    }

    if(selectionInstance === "mailing-address"){
      newValues.applicant_mailing_street = addressSelected.address;
      newValues.applicant_mailing_city = addressSelected.city;
      newValues.applicant_mailing_state = addressSelected.state;
      newValues.applicant_mailing_zip = addressSelected.zip;
    }
  }
  setDocumentValues(newValues);
  if(document){
    previewPayloadDocument(documentValues);
  }
  setHasUnsavedChanges(true);
  setSaveSuccess(false);
}

const onCloseClick = e => {
  e.preventDefault()
  setDataLoaded(false);
  setDataLoaded(false);
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setAssignedProduct(undefined);
  setdocumentUrl("");
  setDocument(undefined);
  setDocumentFocus(undefined);
  setSelectedAddress1(undefined);
  setSelectedAddress2(undefined);
  setAddressOptions(undefined);
  setinputAddressVal(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

  return (
    <>
      <div
        className="modal fade"
        // id="wa-title-app"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                NV - Power Of Attorney
              </h5>
              <br />
              { hasClickedClose ? (
                <div className="d-flex">
                    <p className="m-1 pt-1 text-white">Save Changes?</p>
                    <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                    <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}
                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ fontSize: '12px', overflowY: 'scroll', height: 'inherit'}}>
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill();
                      AutofillProductDetails(assignedProduct);
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>

                  { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Product Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                        </div>
                    </div>
                  ):(
                    <div className="m-2 p-2 border">
                        <span>No Assigned Product</span>
                    </div>
                )}

                  <div className="row">
                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Customer Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="applicant_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.applicant_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">County</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="applicant_county"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.applicant_county ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="applicant_state_from"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.applicant_state_from ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Model</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="model"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.model ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Appointed</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="aif_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.aif_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">County</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="aif_county"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.aif_county ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State of</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="aif_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.aif_state ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">First</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="applicant_first"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.applicant_first ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Middle</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="applicant_mi"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.applicant_mi ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Last</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="applicant_last"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.applicant_last ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Nevada Driver’s License, Identification Card  Number, Date of Birth, or FEIN for a business</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="applicant_id"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.applicant_id ?? '' }
                          />
                      </div>
                    </div>

                    <div className="row">
                      {addressOptions && addressOptions.length > 0 ? (
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress1 && selectedAddress1.name) ?? null}
                            name="wa-primary-address"
                            onChange={(event, newValue) => {
                              setSelectedAddress1(newValue);
                              AutoFillAddress(newValue, "physical-address");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Physical Address" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>):(
                        <div  className="text-center mx-auto">
                          <h5 style={{ color: "red" }}> No Addresses Found</h5>
                        </div>
                      )}
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Physical Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="applicant_street"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.applicant_street ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="applicant_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.applicant_city ?? '' }
                          />
                      </div> 
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="applicant_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.applicant_state ?? '' }
                          />
                      </div>               
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="applicant_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.applicant_zip ?? '' }
                          />
                      </div>          
                    </div>

                    <div className="row">
                      {addressOptions && addressOptions.length > 0 ? (
                      <div className="d-flex justify-content-evenly pb-2">
                        <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                            style={{ width: '100%'}}
                            value={ (selectedAddress2 && selectedAddress2.name) ?? null}
                            name="wa-primary-address"
                            onChange={(event, newValue) => {
                              setSelectedAddress2(newValue);
                              AutoFillAddress(newValue, "mailing-address");
                            }}
                            inputValue={inputAddressVal}
                            onInputChange={(event, newInputValue) => {
                              setinputAddressVal(newInputValue);
                            }}
                            options={addressOptions}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Mailing Address" />}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>):(
                        <div  className="text-center mx-auto">
                          <h5 style={{ color: "red" }}> No Addresses Found</h5>
                        </div>
                      )}
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Mailing Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="applicant_mailing_street"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.applicant_mailing_street ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="applicant_mailing_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.applicant_mailing_city ?? '' }
                          />
                      </div> 
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="applicant_mailing_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.applicant_mailing_state ?? '' }
                          />
                      </div>               
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="applicant_mailing_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.applicant_mailing_zip ?? '' }
                          />
                      </div>          
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">State of Nevada, County</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="notary_county"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.notary_county ?? '' }
                          />
                      </div>
                    </div>
                <hr></hr>
                <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Date of Signature</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="delivery_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.delivery_date ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6"></div>
                    </div>
                  </div>
                  <hr/>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default NVPowerOfAttorney

import React, { useState} from "react";
import { useSelector } from "react-redux";

import moment from 'moment'
import { Autocomplete, TextField } from "@mui/material"
import NumberFormat from "react-number-format"

import Modal from '@mui/material/Modal'
import { Portal } from '@mui/base/Portal';
import * as formatters from "../../../../../../utlis/formatters"
import { AbbreviatedStates } from "../../../../../../utlis/States"
import FormError from "../../../../../../Components/commonElements/FormError"
import { CircularProgress } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
import {  Checkbox, FormGroup } from '@mui/material';
// import { setActiveModal } from "../../../../../../redux/app/appSaver";
import { addTestingTag } from "../../../../../../utlis/testingHelpers";

function TradeinModal(props) {
  // const dispatch        = useDispatch();

  const {
    editMode,
    // formatter,
    editedOrDefaultTradein,
    editedOrDefaultOwedToAddress,
    editedOwedToAddressErrors,
    editedTradeinErrors,
    // resetValues,
    handleClose,
    isOpen,
    // isValidTradeInForm,
    handleSubmit,
    onBalanceOwedBlur,
    onBalanceOwedChange,
    onBalanceOwedToChange,
    onDescriptionChange,
    onDolClientNumberChange,
    onGoodUntilChange,
    owedToInput,
    onOwedToInputChange,
    onOwedToAddress1Change,
    onOwedToAddress2Change,
    onOwedToCityChange,
    onOwedToStateChange,
    onOwedToZipChange,
    onPlateNumberChange,
    onMakeChange,
    onMileageChange,
    onModelChange,
    onSelectedLenderChange,
    onSerialNumberChange,
    onSeriesBodyTypeChange,
    onTradeAllowanceAtTermsChange,
    onCashRefundToCustomerChange,
    calcFinalTradeAllowance,
    onVinChange,
    onYearChange,
    productLenders,
    saveTradeinSuccess,
    showCopyToDocuments,
    selectedLender,
    hasUnsavedChanges,
    onApplyMileageChange,
    initiateCopyToDocuments
  } = props;

  const {showTradeInSpinner}=useSelector(state=>state.spinners);
  const appData = useSelector((state) => state?.appData);

  // maybe using !saved instead of hardcoding true/false? or log it somewhere
  // const [saved, setSaved] = useState(false);

  //just to get rid of the error without messing with other functions
  const [hasClickedClose, setHasClickedClose] = useState(false);

  function closeClicked(){
    if(hasUnsavedChanges){
      setHasClickedClose(true);
    }else{
      handleClose();
      // setSaved(false);
    }
  }

  const preCloseSave = e => {
    // setSaved(true);
    handleSubmit(e);
    setHasClickedClose(false);
  }

  const preCloseClose = e => {
    setHasClickedClose(false);
    handleClose()
    // setSaved(false);
  }

   

  // if(saved != saveTradeinSuccess){
  //   setSaved(saveTradeinSuccess);
  // }
  return (
    <Portal>
      { appData?.activeModal === 'tradein' ? (
        <Modal
          style={{ overflow: 'scroll' }}
          open={ isOpen }
          onClose={ handleClose }
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <div id="tradeinModal">
            <div className="modal-dialog modal-dialog-centered modal-xl ">
              <div className="modal-content">
                <div className="modal-header bg-primary text-white">
                  <h5 className="modal-title textCapitalize" id="exampletrademodal">
                  { editMode ? 'Edit Trade In' : 'Add Trade In' }
                  </h5>
                  { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" aria-label="save" data-testid="tradein.control.saveYes" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" aria-label="cancel" data-testid="tradein.control.saveNo" onClick={preCloseClose}>No</button>
                    </div>
                  ):(
                    <button
                      type="button"
                      className="btn-close bg-white"
                      // data-bs-dismiss="modal"
                      data-testid="tradein.control.close"
                      aria-label="Close"
                      onClick={ closeClicked }
                    ></button>
                  )}
                </div>

                {/* MODLE BODY START HERE */}
                <div className="modal-body">
                  <div className="d-flex">
                    {/* { isValidTradeInForm ? (
                        <button
                          type="button"
                          className="btn btn-primary mx-auto"
                          data-bs-dismiss="modal"
                          onClick={(e) => {
                            setSaved(true);
                            handleSubmit(e)
                          }}
                          >
                          Save
                        </button>
                      ) : ( */}
                        {showTradeInSpinner?<div
            style={{
              display: "flex",
              width: "100vw",
              justifyContent: "center",
            }}
          >
            <CircularProgress style={{ placeSelf: "center" }} />
          </div>:<button
                          type="button"
                          className={"btn mx-auto "+(saveTradeinSuccess ? "btn-success" : "btn-primary")}
                          // data-bs-dismiss=""
                          aria-label="save"
                          data-testid="tradein.control.save"
                          onClick={async (e) => {
                            
                            if(!saveTradeinSuccess){
                              // setSaved(true);
                              await handleSubmit(e)
                            
                            }
                          }}
                          >
                          { saveTradeinSuccess ? "Saved Trade In Data" : "Save" }
                        </button>}

                        { saveTradeinSuccess === true && showCopyToDocuments === true ? (
                          <>
                            <CopyToDocumentsButton initiateCopyToDocuments={initiateCopyToDocuments} handleClose={handleClose} />
                          </>
                        ):null}
                      {/* )} */}
                  </div>
                  <form>
                    <div className="container row mt-2">
                      <div className="mb-2 col ms-5 ">
                        <label htmlFor="vin" className="form-label">
                          VIN
                        </label>
                        <input
                          type="text"
                          className="rounded-pill form-control "
                          name="vin"
                          maxLength="17"
                          aria-label="vin"
                          onChange={ onVinChange}
                          value={ editedOrDefaultTradein.vin }
                          data-testid="tradein.vin"
                        />
                        <FormError validate={ editedTradeinErrors.vin }>
                          VIN must be 17 digits long.
                        </FormError>
                      </div>

                      <div className="mb-3 w-auto col ms-5 me-5">
                        <label htmlFor="customerPhone" className="form-label">
                          YEAR<span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="rounded-pill form-control "
                          id="customerPhone"
                          name="year"
                          aria-label="year"
                          onChange={ onYearChange }
                          value={ editedOrDefaultTradein.year }
                          data-testid="tradein.year"
                        />
                        <FormError validate={ editedTradeinErrors.year }>
                          Year is required.
                        </FormError>
                      </div>
                    </div>
                    {/* ---------------------row 2------------------- */}
                    <div className="container row mt-2">
                      <div className="mb-2 col ms-5 ">
                        <label htmlFor="tradeinPlateNumber" className="form-label">
                          VEHICLE PLATE NUMBER
                        </label>
                        <input
                          type="text"
                          className="rounded-pill form-control "
                          name="tradeinPlateNumber"
                          aria-label="plate number"
                          onChange={ onPlateNumberChange }
                          value={ editedOrDefaultTradein.plateNumber }
                          data-testid="tradein.plateNumber"
                        />
                      </div>

                      <div className="mb-3 w-auto col ms-5 me-5">
                        <label htmlFor="make" className="form-label">
                          MAKE<span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="tel"
                          className="rounded-pill form-control "
                          id="make"
                          name="make"
                          aria-label='make'
                          onChange={ onMakeChange }
                          value={ editedOrDefaultTradein.make }
                          data-testid="tradein.make"
                        />
                        <FormError validate={ editedTradeinErrors.make }>
                          Make is required.
                        </FormError>
                      </div>
                    </div>
                    {/* ---------------------row 3------------------- */}
                    <div className="container row mt-2">
                      <div className="mb-2 col ms-5 ">
                        <label htmlFor="serialNumber" className="form-label">
                          SERIAL #<span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="rounded-pill form-control "
                          name="serialNumber"
                          aria-label="serial number"
                          onChange={ onSerialNumberChange }
                          value={ editedOrDefaultTradein.serialNumber }
                          data-testid="tradein.serialNumber"
                        />
                        <FormError validate={ editedTradeinErrors.serialNumber }>
                          Serial number is required.
                        </FormError>
                      </div>

                      <div className="mb-3 w-auto col ms-5 me-5">
                        <label htmlFor="model" className="form-label">
                          MODEL<span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="tel"
                          className="rounded-pill form-control "
                          id="model"
                          name="model"
                          aria-label="model"
                          onChange={ onModelChange }
                          value={ editedOrDefaultTradein.model }
                          data-testid="tradein.model"
                        />
                        <FormError validate={ editedTradeinErrors.model }>
                          Model is required.
                        </FormError>
                      </div>
                    </div>
                    <div className="container row mt-2">
                      <div className="mb-2 col ms-5 ">
                        {/* <label htmlFor="serialNumber" className="form-label">
                          MILEAGE
                        </label> */}
                        <div className="custom-control custom-checkbox checkbox-lg">
                          <FormGroup className="m-2">
                            <FormControlLabel
                              label="Apply Mileage"
                              control={
                                <Checkbox color="default"
                                aria-label="apply mileage"
                                  checked={ editedOrDefaultTradein.applyMileage }
                                  onChange={onApplyMileageChange}
                                  data-testid="tradein.applyMileage" />
                                }
                              />
                          </FormGroup>
                        </div>
                        { editedOrDefaultTradein.applyMileage === true ? (
                          <>
                            <NumberFormat
                            thousandSeparator={true}
                            className="rounded-pill form-control "
                            name="mileage"
                            aria-label='mileage'
                            value={ editedOrDefaultTradein.mileage?.formatted }
                            onValueChange={ onMileageChange }
                            data-testid="tradein.mileage"
                            />
                            {/* <FormError validate={ editedTradeinErrors.mileage }>
                              Mileage is required.
                            </FormError> */}
                          </>
                        ):null}
                      </div>
                      <div className="mb-3 w-auto col ms-5 me-5">
                        <label htmlFor="model" className="form-label">
                          BODY TYPE
                        </label>
                        <input
                          type="tel"
                          className="rounded-pill form-control "
                          name="seriesBodyType"
                          aria-label="series body type"
                          onChange={ onSeriesBodyTypeChange }
                          value={ editedOrDefaultTradein.seriesBodyType }
                          data-testid="tradein.seriesBodyType"
                        />
                      </div>
                    </div>
                    <div className="container row mt-2">
                      <div className="mb-2 col ms-5 ">
                      <label htmlFor="model" className="form-label">
                          DOL CLIENT NUMBER
                        </label>
                        <input
                          type="tel"
                          className="rounded-pill form-control "
                          name="dolClientNumber"
                          aria-label="dol client number"
                          onChange={ onDolClientNumberChange }
                          value={ editedOrDefaultTradein.dolClientNumber }
                          data-testid="tradein.dolClientNumber"
                        />
                      </div>
                      <div className="mb-3 w-auto col ms-5 me-5"></div>
                    </div>
                    <div className="container row mt-2">
                      <div className="mb-3 w-auto col ms-5 me-5">
                        <label htmlFor="serial" className="form-label">
                          DESCRIPTION (Internal Use Only)
                        </label>
                        <textarea
                          className="form-control rounded-lg"
                          id="notes"
                          aria-label="description"
                          rows="2"
                          cols="50"
                          name="description"
                          maxLength="250"
                          onChange={ onDescriptionChange }
                          value={ editedOrDefaultTradein.description }
                          data-testid="tradein.description"
                          ></textarea>
                        </div>
                    </div>

                    <hr />

                    <div className="container row mt-2">
                      {/*---------------------------- pay oFF details------------------------------- */}
                      <div className="mb-2 col ms-5 ">
                        <h4>Payoff Details</h4>
                        <div className="mb-2">
                          <label
                            htmlFor="balanceOwed"
                            className="form-label "
                          >
                            BALANCE OWED<span style={{ color: 'red' }}>*</span>
                          </label>
                          <NumberFormat
                            className="rounded-pill form-control"
                            value={ editedOrDefaultTradein.balanceOwed?.formatted }
                            name="balanceOwed"
                            aria-label='balance owed'
                            // thousandSeparator={true}
                            // decimalScale={2}
                            prefix={'$'}
                            onBlur={ onBalanceOwedBlur }
                            onValueChange={ onBalanceOwedChange }
                            data-testid="tradein.balanceOwed"
                            // onChange={(e) => onBalanceOwedChange(e)}

                          />
                          <FormError validate={ editedTradeinErrors.balanceOwed }>
                            Balance owed is required.
                          </FormError>
                        </div>

                        <div className="mb-2">
                          <label htmlFor="goodUntil" className="form-label">
                            GOOD UNTIL<span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            type="date"
                            className="rounded-pill form-control"
                            name="goodUntil"
                            aria-label="good until"
                            onChange={ onGoodUntilChange }
                            value={ moment.utc(editedOrDefaultTradein.goodUntil).format("YYYY-MM-DD") }
                            data-testid="tradein.goodUntil"
                            />
                            <FormError validate={ editedTradeinErrors.goodUntil }>
                              Good until date is required.
                            </FormError>
                        </div>
                        <hr />
                        <div className="col-6">
                          <div className="fw-bold text-secondary my-3 ">
                            <Autocomplete
                              value={ selectedLender }
                              getOptionLabel={ value => {
                                return value.name
                              }}
                              aria-label='product lender'
                              onChange={ onSelectedLenderChange }
                              options={ productLenders }
                              sx={{ width: 300 }}
                              renderInput={(params) => <TextField {...addTestingTag(params, 'tradein.selectedLender')} label="SELECT LENDOR" />}
                              renderOption={(props, option) => {
                                return (
                                  <li {...props} key={option.id}>
                                    { option.name }
                                  </li>
                                )
                              }}
                            />
                          </div>
                        </div>
                        <div className="mb-2">
                          <label htmlFor="balanceOwedTo" className="form-label">
                            BALANCE OWED TO<span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="rounded-pill form-control "
                            name="balanceOwedTo"
                            aria-label="balance owed to"
                            onChange={ onBalanceOwedToChange }
                            value={ editedOrDefaultTradein.balanceOwedTo }
                            data-testid="tradein.balanceOwedTo"
                          />
                          <FormError validate={ editedTradeinErrors.balanceOwedTo }>
                            Balance owed to is required.
                          </FormError>
                        </div>

                        <div className="mb-2">
                          <label htmlFor="owedToAddress" className="form-label">
                            ADDRESS<span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="rounded-pill form-control "
                            id="address1"
                            aria-label="owed to address 1"
                            name="owedToAddress"
                            onChange={ onOwedToAddress1Change }
                            value={ editedOrDefaultOwedToAddress.owedToAddress }
                            data-testid="tradein.owedToAddress"
                          />
                          <FormError validate={ editedOwedToAddressErrors.owedToAddress }>
                            Address is required.
                          </FormError>
                        </div>

                        <div className="mb-2">
                          <label htmlFor="address2" className="form-label">
                            ADDRESS LINE 2
                          </label>
                          <input
                            type="text"
                            aria-label="owed to adress 2"
                            className="rounded-pill form-control "
                            id="address2"
                            name="payOffDetailAddressLine2"
                            onChange={ onOwedToAddress2Change }
                            value={ editedOrDefaultOwedToAddress.owedToAddress2 }
                            data-testid="tradein.owedToAddress2"
                          />
                        </div>
                        <div className="row">
                          <div className="mb-3 col-md-6">
                            <label htmlFor="owedToCity" className="rounded-pill form-label">
                              City<span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="rounded-pill form-control "
                              id="city"
                              aria-label='owed to city'
                              name="owedToCity"
                              onChange={ onOwedToCityChange }
                              value={ editedOrDefaultOwedToAddress.owedToCity }
                              data-testid="tradein.owedToCity"
                              />
                            <FormError validate={ editedOwedToAddressErrors.owedToCity }>
                              City is required.
                            </FormError>
                          </div>
                          <div className="mb-3 col-md-6 pt-4">
                            <Autocomplete
                              value={ editedOrDefaultOwedToAddress.owedToState }
                              onChange={ onOwedToStateChange }
                              inputValue={ owedToInput }
                              aria-label='owed to state'
                              onInputChange={ onOwedToInputChange }
                              options={ AbbreviatedStates }
                              sx={{ width: 150 }}
                              renderInput={(params) => <TextField {...addTestingTag(params, 'tradein.owedToState')} label={
                                <span>
                                  STATE<span style={{ color: 'red' }}>*</span>
                                </span>
                              } />}
                            />
                            <FormError validate={ editedOwedToAddressErrors.owedToState }>
                              State is required.
                            </FormError>
                          </div>
                        </div>
                        <div className="row">
                          <div className="mb-3 col-md-6">
                            <label htmlFor="owedToZip" className="form-label">
                              Zip<span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="rounded-pill form-control "
                              id="zip"
                              aria-label='owed to zip'
                              name="owedToZip"
                              value={ formatters.formatZipCode(editedOrDefaultOwedToAddress.owedToZip) }
                              onChange={ onOwedToZipChange }  
                              data-testid="tradein.owedToZip"
                            />
                            <FormError validate={ editedOwedToAddressErrors.owedToZip }>
                              Zip is required and must be 5 or 9 digits long.
                            </FormError>
                          </div>
                        </div>
                      </div>
                      { /* ----------TRADE IN CREDITS ---------- */ }
                      <div className="mb-3 w-auto col ms-5 me-5">
                        <h4>Trade In Credits</h4>
                        <div className="mb-2">
                          <label htmlFor="tradeAllowanceAtTerms" className="form-label">
                            TRADE IN ALLOWANCE(AT TERMS)<span style={{ color: 'red' }}>*</span>
                          </label>
                          <NumberFormat
                            id="tradeAllowance"
                            className="rounded-pill form-control "
                            value={ editedOrDefaultTradein.tradeAllowanceAtTerms?.formatted }
                            name="tradeAllowanceAtTerms"
                            aria-label='trade allowance '
                            thousandSeparator={true}
                            // decimalScale={2}
                            prefix={'$'}
                            onValueChange={ onTradeAllowanceAtTermsChange }  
                            data-testid="tradein.tradeAllowanceAtTerms"
                            // onChange={(e) => onTradeAllowanceAtTermsChange(e)}

                          />
                          <FormError validate={ editedTradeinErrors.tradeAllowanceAtTerms }>
                            Trade in allowance is required.
                          </FormError>
                        </div>
                        <hr />
                        <div className="mb-2">
                          <label htmlFor="cashRefundToCustomer" className="form-label">
                            CASH REFUND TO CUSTOMER<span style={{ color: 'red' }}>*</span>
                          </label>
                          <NumberFormat
                            id="cashRefund"
                            className="rounded-pill form-control "
                            value={ editedOrDefaultTradein.cashRefundToCustomer?.formatted }
                            name="cashRefundToCustomer"
                            aria-label='cash refund to customer'
                            thousandSeparator={true}
                            data-testid="tradein.cashRefundToCustomer"
                            // decimalScale={2}
                            prefix={'$'}
                            onValueChange={ onCashRefundToCustomerChange }
                            // onChange={ (e) => { onCashRefundToCustomerChange(e); } }
                          />
                          <FormError validate={ editedTradeinErrors.cashRefundToCustomer }>
                            Cash refund to customer is required.
                          </FormError>
                        </div>

                        <div className="mb-2">
                          <label htmlFor="finalTradeAllowance" className="form-label">
                            FINAL / NET TRADE IN ALLOWANCE
                          </label>
                          <h5 data-testid="tradein.calculated.finalNetTradeInAllowance" >{calcFinalTradeAllowance() >= 0 ? '$' + formatters.formatNumber(calcFinalTradeAllowance()) : '($' + formatters.formatNumber(calcFinalTradeAllowance()) + ')'}</h5>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                {/* MODLE BODY END HERE */}

                <div className="modal-footer">
                  {/* { isValidTradeInForm ? (
                      <button
                        type="button"
                        className="btn btn-primary mx-auto"
                        data-bs-dismiss="modal"
                        onClick={(e) => {
                          setSaved(true);
                          handleSubmit(e)
                        }}
                      >
                        Save
                      </button>
                    ) : ( */}
                      {showTradeInSpinner?<div
                        style={{
                          display: "flex",
                          width: "100vw",
                          justifyContent: "center",
                        }}
                      >
                      <CircularProgress style={{ placeSelf: "center" }} />
                    </div>:<button
                          type="button"
                          className={"btn mx-auto "+(saveTradeinSuccess ? "btn-success" : "btn-primary")}
                          // data-bs-dismiss=""
                          aria-label='save'
                          disabled={saveTradeinSuccess}
                          data-testid="tradein.control.save"
                          onClick={(e) => {
                            if(!saveTradeinSuccess){
                              // setSaved(true);
                              handleSubmit(e);
                            }
                          }}
                          >
                          { saveTradeinSuccess ? "Saved Trade In Data" : "Save" }
                        </button>}

                        { saveTradeinSuccess === true && showCopyToDocuments === true ? (
                          <>
                            <CopyToDocumentsButton initiateCopyToDocuments={initiateCopyToDocuments} handleClose={handleClose} />
                          </>
                        ):null}

                    {/* )} */}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ):null}
    </Portal>
  )
}

const CopyToDocumentsButton = ({ initiateCopyToDocuments, handleClose }) => {

  return (
    <>
      <button aria-label='Copy to Documents' 
        onClick={ (e)=>{
          e.preventDefault();
          handleClose();
          initiateCopyToDocuments();
      }}
       data-bs-toggle="modal" 
       data-testid="tradein.control.copyToDocuments"
       data-bs-target="#copychangestodocuments" 
       className="my-2 btn  btn-block btn-sm btn-primary text-uppercase mx-2">Copy to Documents</button><br />
    </>
  )

}

export default TradeinModal

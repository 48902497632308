import _ from "lodash"
import validators from "../../../../../../utlis/validation-utils"
import { rdx } from "../../../../../../redux/customers"
import * as formatters from "../../../../../../utlis/formatters"
import { setShowSpinner } from "../../../../../../redux/spinners/spinners"
import { setActiveModal } from "../../../../../../redux/app/appSaver";
import { updateStatus, updateCopySection, updateCopySubSection } from "../../../../../../redux/saveAndCopy"

/*
 * Includes all on* handlers for responding to various
 * user or view actions. Most functions are a wrapper
 * around the call to dispatch()
 */
const newCustomerModalHelper = ( dispatch, history, context ) => {

  //Get values from redux context...
  const {
    order, action: modalAction, addedCosigners,
    deletedCosignerIds, deletedContactIds,
    orderId, addedContacts, isMailingAddressChecked,
    editedContacts, editedCosigners,
    errors: {
      addedCosigners: addedCosignerErrors,
      editedCosigners: editedCosignerErrors,
      editedCustomerInfo: editedCustomerInfoErrors,
      editedDeliveryAddress: editedDeliveryAddressErrors,
      editedMailingAddress: editedMailingAddressErrors
    },
    editedCustomerInfo, 
    editedMailingAddress, editedDeliveryAddress, cosigners,
  } = context

  const customerId                = order.customer?.id
  const cosignersFromOrder        = _.defaultTo(order.customer?.cosigners, [])
  const contactsFromOrder         = _.defaultTo(order.customer?.contacts, [])
  const customerInfoFromOrder     = _.defaults(order.customer)
  const deliveryAddressFromOrder  = _.defaults(order.customer?.deliveryAddress)
  const mailingAddressFromOrder   = _.defaults(order.customer?.mailingAddress)

  return {
    initalizeCopyToDocuments(dataArray){
      dispatch(updateCopySection('customer'));
      dispatch(updateCopySubSection('details'));
      dispatch(updateStatus('copy'));
    },
    copyCustomer(customer){
      if(!customer || customer === null || customer?.length === 0 ){
        return
      }
      
      let emptyMailAddress={
        addressLine1 : '',
        addressLine2 : "",
        city : "",
        createdAt : "",
        id : null,
        name : null,
        state : "",
        updatedAt : null,
        zip : "",
      }

      if(customer.notes===null){
        customer.notes = ''
      }
      dispatch(rdx.setEditedMailingAddress({...emptyMailAddress}))
      dispatch(rdx.setEditedCustomerInfo({...customer}))
      dispatch(rdx.setEditedDeliveryAddress({...customer.deliveryAddress}))
      dispatch(rdx.setEditedMailingAddress({...customer.mailingAddress}))
      dispatch(rdx.setCosigners([...customer.cosigners]))
      dispatch(rdx.setContacts([...customer.contacts]))
      
      if(customer?.mailingAddress?.id){
        dispatch(rdx.setIsMailingAddressChecked(true))
      } else {
        dispatch(rdx.setIsMailingAddressChecked(false))
      }
    },

    getEditedOrDefaultCustomerInfoValue(field) {

      const value =
        getEditedOrDefaultValue(field, editedCustomerInfo, customerInfoFromOrder)

      if ( field === 'type' && _.isEmpty(value) ) {
        return 'individual'
      }
      return value
    },

    getEditedOrDefaultDeliveryAddressValue(field) {
      return getEditedOrDefaultValue(field, editedDeliveryAddress, deliveryAddressFromOrder)
    },

    getEditedOrDefaultMailingAddressValue(field) {
      return getEditedOrDefaultValue(field, editedMailingAddress, mailingAddressFromOrder)
    },

    /* MODAL HANDLERS */
    handleClose() {
      history.push(`/order/${ orderId }`);
      dispatch(rdx.setSaveCustomerSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(false))
      dispatch(setActiveModal(null));
      dispatch(rdx.setIsOpen(false)) //Will reset all values...
      dispatch(rdx.resetCustomerModal())
    },

    /* CUSTOMER INFO ON 'CHANGE' HANDLERS */
    onEditCustomerInfo(field, value, validator=NOT_EMPTY_VALIDATOR) {

      
      const isValidValue = validator(value);
      
      //Show errors
      if ( !isValidValue && !editedCustomerInfoErrors[ field ]) {
        dispatch(rdx.showEditedCustomerInfoError(field))
      } else if ( isValidValue && editedCustomerInfoErrors[ field ]) { //Hide errors...
        dispatch(rdx.hideEditedCustomerInfoError(field))
      }

      dispatch(rdx.setEditedCustomerInfo(field, value))
      dispatch(rdx.setSaveCustomerSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(true))

    },

    onCustomerNameChange(e) {
      this.onEditCustomerInfo('name', e.target.value)
    },

    onDbaChange(e) {
      this.onEditCustomerInfo('dba', e.target.value, ANY_VALUE_VALIDATOR)
    },

    onEmailChange(e) {
      this.onEditCustomerInfo('email', e.target.value, validators.validateEmail)
    },

    onCustomerTypeChange(e) {
      this.onEditCustomerInfo('type', e.target.value)
    },

    onNotesChange(e) {
      const notesValidator = () => true
      this.onEditCustomerInfo('notes', e.target.value, notesValidator)
    },

    onPhoneChange(e) {
      this.onEditCustomerInfo('phone', e.target.value, validators.validatePhone)
    },

    /* DELIVERY ADDRESS */
    onDeliveryAddressEdited( field, e ) {
      const value = field !== 'state' ?
        e.target.value : e

      dispatch(rdx.setEditedDeliveryAddress(field, value))

      if ( field === 'addressLine2' ) {
        return; //
      }

      //Validations...
      const hasErrorForField = editedDeliveryAddressErrors[ field ]

      if ( _.isEmpty( value ) && !hasErrorForField ) {
        dispatch(rdx.showEditedDeliveryAddressError(field))
      } else if ( hasErrorForField ) {
        dispatch(rdx.hideEditedDeliveryAddressError(field))
      }
      dispatch(rdx.setSaveCustomerSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(true))
    },

    onDeliveryZipChange(e) {
      dispatch(rdx.setEditedDeliveryAddress('zip', e.target.value))
      dispatch(rdx.setSaveCustomerSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(true))
    },

    /* MAILING ADDRESS */
    onMailingAddressEdited(field, e) {
      const value = ( field === 'state' ) ? e : e.target.value
      dispatch(rdx.setEditedMailingAddress(field, value))
      dispatch(rdx.setSaveCustomerSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(true))

      if ( _.isEmpty( value ) && !editedMailingAddressErrors[ field ] ) {
        dispatch(rdx.showEditedMailingAddressError(field))
      } else if ( _.isEmpty(value) === false && editedMailingAddressErrors[ field ]) {
        dispatch(rdx.hideEditedMailingAddressError(field))
      }
      
    },

    onMailingAddressToggle(checked) {
      dispatch(rdx.setIsMailingAddressChecked(!checked));
      dispatch(rdx.setSaveCustomerSuccess(false));
      dispatch(rdx.setHasUnsavedChanges(true));
    },

    
    onAcceptMarketingEmailsToggle(checked) {
      this.onEditCustomerInfo('acceptMarketingEmails', !checked)
      dispatch(rdx.setSaveCustomerSuccess(false));
      dispatch(rdx.setHasUnsavedChanges(true));
    },

    /* TAX */
    onIsExemptSalesTaxChange(currentlyExempt) {

      if ( currentlyExempt ) {  //Make not exempt...
        dispatch(rdx.setEditedCustomerInfo({
          isExemptSalesTax: false,
          salesTaxExemptionReasonId: '',
          vehicleSalesTaxPercent: '',
          vehicleTaxCounty: '',
          nonVehicleSalesTaxPercent: '',
          nonVehicleTaxCounty: ''
        }))
      } else {
        dispatch(rdx.setEditedCustomerInfo({
          isExemptSalesTax: true,
          salesTaxExemptionReasonId: '',
          salesTaxCounty: ''
        }))
      }
      dispatch(rdx.setSaveCustomerSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(true))
    },

    onSalesTaxExemptionReasonIdEdit(v) {
      dispatch(rdx.setEditedCustomerInfo('sterResaleNumber', ''))
      dispatch(rdx.setEditedCustomerInfo('sterDealerNumber', ''))
      dispatch(rdx.setEditedCustomerInfo('sterMcNumber', ''))
      dispatch(rdx.setEditedCustomerInfo('sterDotNumber', ''))
      dispatch(rdx.setEditedCustomerInfo('salesTaxExemptionReasonId', v?.id??''))
      dispatch(rdx.setSaveCustomerSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(true))
      //Validate the tax exemption...
      //let validateOnStatus = validateExemptFromSalesTax();
    },
    onSTERResaleNumberEdit(v) {
      dispatch(rdx.setEditedCustomerInfo('sterResaleNumber', v))
      dispatch(rdx.setSaveCustomerSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(true))
    },
    onSTERDealerNumberEdit(v) {
      dispatch(rdx.setEditedCustomerInfo('sterDealerNumber', v))
      dispatch(rdx.setSaveCustomerSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(true))
    },
    onSTERMcNumberEdit(v) {

      dispatch(rdx.setEditedCustomerInfo('sterMcNumber', v))
      dispatch(rdx.setSaveCustomerSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(true))
    },
    onSTERDotNumberEdit(v) {

      dispatch(rdx.setEditedCustomerInfo('sterDotNumber', v))
      dispatch(rdx.setSaveCustomerSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(true))
    },
    onVehicleSalesTaxPercentChange(e) {
      const value = e.target.value.replace(/[^0-9.]/g, "");
      dispatch(rdx.setEditedCustomerInfo('vehicleSalesTaxPercent', value ))
      dispatch(rdx.setSaveCustomerSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(true))

      const deliveryAddress = {
        ...deliveryAddressFromOrder,
        ...editedDeliveryAddress,
      }

      const validateOnStatus = validators.validateExemptFromVehicleSalesTax(deliveryAddress)

      if ( validateOnStatus && _.isEmpty( value ) ) {
        dispatch(rdx.showEditedCustomerInfoError('vehicleSalesTaxPercent'))
      } else if ( editedCustomerInfoErrors.vehicleSalesTaxPercent ) {
        dispatch(rdx.hideEditedCustomerInfoError('vehicleSalesTaxPercent'))
      }
    },

    onNonVehicleSalesTaxPercentChange(e) {
      const value = e.target.value.replace(/[^0-9.]/g, "");
      dispatch(rdx.setEditedCustomerInfo('nonVehicleSalesTaxPercent', value ))
      dispatch(rdx.setSaveCustomerSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(true))

      const deliveryAddress = {
        ...deliveryAddressFromOrder,
        ...editedDeliveryAddress,
      }

      const validateOnStatus = validators.validateExemptFromNonVehicleSalesTax(deliveryAddress)

      if ( validateOnStatus && _.isEmpty( value ) ) {
        dispatch(rdx.showEditedCustomerInfoError('nonVehicleSalesTaxPercent'))
      } else if ( editedCustomerInfoErrors.nonVehicleSalesTaxPercent ) {
        dispatch(rdx.hideEditedCustomerInfoError('nonVehicleSalesTaxPercent'))
      }
    },

    onVehicleSalesTaxPercentBlur(e) {
      dispatch(rdx.setEditedCustomerInfo('vehicleSalesTaxPercent', formatters.formatPercentValue(e.target.value)))
    },

    onNonVehicleSalesTaxPercentBlur(e) {
      dispatch(rdx.setEditedCustomerInfo('nonVehicleSalesTaxPercent', formatters.formatPercentValue(e.target.value)))
    },

    onVehicleTaxCountyChange(e) {
      dispatch(rdx.setEditedCustomerInfo('vehicleTaxCounty', e.target.value))
      dispatch(rdx.setSaveCustomerSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(true))
      //let validateOnStatus = validateExemptFromSalesTax(); //Action creator...
    },

    onNonVehicleTaxCountyChange(e) {
      dispatch(rdx.setEditedCustomerInfo('nonVehicleTaxCounty', e.target.value))
      dispatch(rdx.setSaveCustomerSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(true))
      //let validateOnStatus = validateExemptFromSalesTax(); //Action creator...
    },

    onShowAddValidateErrors() {

      REQUIRED_CUSTOMER_INFO_FIELDS_ADD
        .filter( field => {
          return _.isEmpty( editedCustomerInfo[ field ] ) && _.isNil(editedCustomerInfoErrors[ field ])
        })
        .forEach( field => {
          dispatch(rdx.showEditedCustomerInfoError(field))
        })

      REQUIRED_DELIVERY_FIELDS
        .filter( field => {
          return _.isEmpty( editedDeliveryAddress[ field ] ) && _.isNil(editedDeliveryAddressErrors[ field ])
        })
        .forEach( field => {
          dispatch(rdx.showEditedDeliveryAddressError(field))
        })

    },

    onShowExemptFromTaxErrorOnSubmit(obj) {

      if ( _.isEmpty( obj?.salesTaxExemptionReasonId ) ) {
        dispatch(rdx.showEditedCustomerInfoError('salesTaxExemptionReasonId'))
      }

      if ( _.isEmpty( obj?.vehicleSalesTaxPercent ) ) {
        dispatch(rdx.showEditedCustomerInfoError('vehicleSalesTaxPercent'))
      }

      if ( _.isEmpty( obj?.vehicleTaxCounty ) ) {
        dispatch(rdx.showEditedCustomerInfoError('vehicleTaxCounty'))
      }

      if ( _.isEmpty( obj?.nonVehicleSalesTaxPercent ) ) {
        dispatch(rdx.showEditedCustomerInfoError('nonVehicleSalesTaxPercent'))
      }

      if ( _.isEmpty( obj?.nonVehicleTaxCounty ) ) {
        dispatch(rdx.showEditedCustomerInfoError('nonVehicleTaxCounty'))
      }
    },

    onShowNewCosignerFields(e) {
      dispatch(rdx.setShouldShowNewCosignerFields(true))
    },

    onHideNewCosignerFields(e) {
      dispatch(rdx.setShouldShowNewCosignerFields(false))
    },

    onCosignerNameChange( e, existing=false ) {
      if ( existing ) {
        dispatch(rdx.setEditedCosignerAtIndex('name', e.target.value))
      } else {
        dispatch(rdx.setAddedCosignerAtIndex('name', e.target.value))
      }
      dispatch(rdx.setSaveCustomerSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(true))
    },

    /* CONTACTS */
    onSaveNewContacts() {
      dispatch(rdx.saveAddedContacts())
      dispatch(rdx.setSaveCustomerSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(true))
    },
    onSaveEditedContacts() {
      dispatch(rdx.saveEditedContacts())
      dispatch(rdx.setSaveCustomerSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(true))
    },
    onEditContact(existing, index, field, value) {

      if ( existing ) {
        dispatch(rdx.setEditedContactAtIndex(index,field,value))
      } else {
        dispatch(rdx.setAddedContactAtIndex(index,field,value))
      }
      dispatch(rdx.setSaveCustomerSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(true))
    },

    onDeleteContact(
      contactAlreadyExists,
      e,
      deletedContactIndex,
      contact
    ) {

      // e.preventDefault()

      if ( contactAlreadyExists ) {
        //Add the contact id to the list of ids to remove...
        dispatch(rdx.setDeletedContactIds(contact.id, true))

      } else {
        dispatch(rdx.removeAddedContactAtIndex(deletedContactIndex))
      }
      dispatch(rdx.setSaveCustomerSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(true))
    },

    onShowNewContactFields(e) {
      dispatch(rdx.setShouldShowNewContactFields(true))
    },

    onHideNewContactFields(e) {
      dispatch(rdx.setShouldShowNewContactFields(false))
    },

    onSameAsDeliveryAddressToggle(checked) {
      dispatch(rdx.setSaveCustomerSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(true))

      dispatch(rdx.setIsSameAsDeliveryAddressChecked(!checked))

      if ( !checked ) {

        const mailingAddressObj = _.pick({
          ...deliveryAddressFromOrder,
          ...editedDeliveryAddress
        }, VALID_ADDRESS_FIELDS)

        //Merge the delivery object from the order and the edited values...
        dispatch(rdx.setEditedMailingAddress(mailingAddressObj))
      }
    },

    
    editCosigners(e, i){
  dispatch(rdx.editCosigners(e.target.name, e.target.value, i))
    },

    onEditCosignerChange(existing, e, index, field, cosignerId=null) {
      dispatch(rdx.setSaveCustomerSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(true))

      //To understand what errors are present for the cosigner...
      const cosignerErrorsExistingOrNew = existing ?
        editedCosignerErrors : addedCosignerErrors

      const cosignerErrors =
        cosignerErrorsExistingOrNew[ index ] ? cosignerErrorsExistingOrNew[ index ] : {}

      //For hiding errors...
      const hideEditedOrAddedCosignerErrorAtIndex = existing ?
        rdx.hideEditedCosignerFieldErrorAtIndex : rdx.hideAddedCosignerFieldErrorAtIndex

      //For showing errors...
      const showEditedOrAddedCosignerErrorAtIndex =
        existing ? rdx.showEditedCosignerFieldErrorAtIndex : rdx.showAddedCosignerFieldErrorAtIndex

      const value = ( field === 'phone' ) ?
        e.target.value.replace(/\D/g, '') : e.target.value;

      //Update the cosigner info...
      if ( existing ) {
        dispatch(rdx.setEditedCosignerAtIndex(index, field, value))
      } else {
        dispatch(rdx.setAddedCosignerAtIndex(index, field, value))
      }

      //Determine validators based on the field...
      const isValid =
        ( field === 'phone' ) ? validators.validatePhone(value) : _.isEmpty(value) === false

      const errorAlreadyPresent = cosignerErrors[ field ]

      if ( !isValid && !errorAlreadyPresent ) {
        dispatch(showEditedOrAddedCosignerErrorAtIndex(index, field))
      } else if ( isValid && errorAlreadyPresent ) {
        //Hide the error...
        dispatch(hideEditedOrAddedCosignerErrorAtIndex(index, field))
      }
    },

    onEditContactChange( e, index, field ) {
      //TODO phone, email, name put it in...
      // show validation error if empty or invalid
    },

    onAddCosigner( e, index ) {
      e.preventDefault();
      //Validate cosigner...
      const isValidCosigner = true //validators.validateCosigner(addedCosigners[ index ])

      if ( isValidCosigner ) {
        //Insert an empty cosigner so
        //the user can add another cosigner...
        dispatch(rdx.insertEmptyAddedCosigner())
        dispatch(rdx.setSaveCustomerSuccess(false))
        dispatch(rdx.setHasUnsavedChanges(true))
      }
    },

    onDeleteCosigner(
      cosignerAlreadyExists,
      e,
      deletedCosignerIndex,
      cosigner
    ) {
      // e.preventDefault()

      if ( cosignerAlreadyExists ) {
        //Add the cosigner id to the list of ids to remove...
        dispatch(rdx.setDeletedCosignerIds(cosigner.id, true))

      } else {
        dispatch(rdx.removeAddedCosignerAtIndex(deletedCosignerIndex))
      }
      dispatch(rdx.setSaveCustomerSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(true))
    },

    //Called when the user wants to add a new contact...
    onAddNewContact(e, index, contact) {
      e.preventDefault();

      if ( _.isEmpty( addedContacts[ index ]) ) {
        return;
      }

      //Validate the contact...
      const isValidContact = true//validators.validateContact(addedContacts[ index ])

      //If it's valid... do somthing...
      if ( isValidContact ) {
        dispatch(rdx.insertEmptyAddedContact())
      }
      dispatch(rdx.setSaveCustomerSuccess(false))
      dispatch(rdx.setHasUnsavedChanges(true))
    },

    onValidateTaxExempt() {

        const deliveryAddress = {
          ...deliveryAddressFromOrder,
          ...editedDeliveryAddress
        }

        const customer = {
          isExemptSalesTax: false,
          ...order.customer,
          ...editedCustomerInfo
        }

      return validators.validateTaxExempt(deliveryAddress, customer)
    },

    onValidateAddForm() {

      const anyCustomerInfoFieldEmpty =
        _.some( REQUIRED_CUSTOMER_INFO_FIELDS_ADD, field => _.isEmpty( editedCustomerInfo[ field ] ) )

      const anyDeliveryFieldEmpty =
        _.some( REQUIRED_DELIVERY_FIELDS, field => _.isEmpty( editedDeliveryAddress[ field ] ) )

      if ( anyCustomerInfoFieldEmpty || anyDeliveryFieldEmpty ) {
        return false
      }

      return true;
    },
    onValidateEditForm(customer) {
      // let copyArr=[...cosigners]
      // let invalidCosigner=copyArr?.filter((cosigner,i)=>{
      //   let errs={
      //     phone:false,
      //     name:false
      //   }
      //   if(cosigner?.name==='') errs.name=true
      //   if(cosigner?.phone.replace(/[^\d]/g, "").length!==10) errs.phone=true
      //   dispatch({type:'EDIT_COSIGNER', payload:{i, value:errs, field:'errors'}})
      //   return cosigner?.name==='' || cosigner?.phone.replace(/[^\d]/g, "").length!==10})
      // return _.isEmpty(editedCustomerInfoErrors) && invalidCosigner.length===0;

      const anyCustomerInfoFieldEmpty =
        _.some( REQUIRED_CUSTOMER_INFO_FIELDS_ADD, field => _.isEmpty( customer[ field ] ) )

      const anyDeliveryFieldEmpty =
        _.some( REQUIRED_DELIVERY_FIELDS, field => _.isEmpty( customer?.deliveryAddress[ field ] ) )

        console.log('onValidateEditForm', customer, editedDeliveryAddress, anyDeliveryFieldEmpty)

      if ( anyCustomerInfoFieldEmpty || anyDeliveryFieldEmpty ) {
        return false
      }

      return true;

    },

    onHandleSubmit(e) {
      e.preventDefault();
      dispatch(setShowSpinner(true));

      //Get the customer from the order...

      const modifiedPhone      = formatters.formatPhone(editedCustomerInfo.phone)
      // const modifedDeliveryZip = formatters.formatZipCode(editedCustomerInfo.zip)
      // const modifiedMailingZip = formatters.formatZipCode(editedCustomerInfo.zip)

      const formattedCustomerInfo =
        _.omitBy({
          phone: modifiedPhone
        }, _.isEmpty)

      // const includeMailingAddress =
      //   _.isNil( isMailingAddressChecked ) ?
      //     _.isEmpty( mailingAddressFromOrder.addressLine1 ) === false : isMailingAddressChecked

      /* Only pick fields from entities
         in the VALID_FIELDS and omit fields that are undefined or null.
        */

      const mailingAddress = isMailingAddressChecked ?
        _.omitBy(_.pick({
          ...mailingAddressFromOrder,
          ...editedMailingAddress
        }, VALID_ADDRESS_FIELDS), _.isNil) : null

      const deliveryAddress =
        _.omitBy(_.pick({
          ...deliveryAddressFromOrder,
          ...editedDeliveryAddress,
        }, VALID_ADDRESS_FIELDS), _.isNil)

      const customer = _.omitBy({
        ...customerInfoFromOrder,
        ...editedCustomerInfo,
        ...formattedCustomerInfo,
        deliveryAddress,
        mailingAddress
      }, _.isNil)

      const nonEmptyAddedCosigners =
        _.reject(addedCosigners, _.isEmpty)

      const nonEmptyAddedContacts =
        _.reject(addedContacts, _.isEmpty)

      const editedContactsArr =
        editedContacts.reduce((arr, edit, index ) => {
          if ( _.isEmpty(edit) ) {
            return arr
          } else {
            return [
              ...arr,
              {
                ...contactsFromOrder[ index ],
                ...edit
              }
            ]
          }
        }, [])

      //TODO: Put in function
      const editedCosignersArr =
        editedCosigners.reduce((arr, edit, index ) => {
          if ( _.isEmpty(edit) ) {
            return arr
          } else {
            return [
              ...arr,
              {
                ...cosignersFromOrder[ index ],
                ...edit
              }
            ]
          }
        }, [])

      const deletedCosignerIdsArr =
        _.keys(deletedCosignerIds)

      const deletedContactIdsArr =
        _.keys(deletedContactIds)
let validCosigners= cosigners.filter(cosigner=> !cosigner?.name==='' || cosigner?.phone.replace(/[^\d]/g, "").length===10)
      if ( modalAction === 'Add' ) {

        const isValidForm = this.onValidateAddForm();

        
        


        if ( isValidForm ) {
          let adjustedCustomer = JSON.parse(JSON.stringify(customer));
          if(adjustedCustomer.vehicleSalesTaxPercent){
            adjustedCustomer.vehicleSalesTaxPercent = adjustedCustomer.vehicleSalesTaxPercent.replace(/[^0-9.]/g, "");
          }
          if(adjustedCustomer.nonVehicleSalesTaxPercent){
            adjustedCustomer.nonVehicleSalesTaxPercent = adjustedCustomer.nonVehicleSalesTaxPercent.replace(/[^0-9.]/g, "");
          }

          dispatch(rdx.saveCustomer({
            orderId,
            customerId: null,
            customerObj: adjustedCustomer,
            newContacts: nonEmptyAddedContacts,
            editedContacts: editedContactsArr,
            newCosigners: nonEmptyAddedCosigners,
            editedCosigners: editedCosignersArr,
            deletedContacts: deletedContactIdsArr,
            deletedCosigners: deletedCosignerIdsArr,
            cosigners: validCosigners
          }))
        } else {
          this.onShowAddValidateErrors();
          dispatch(setShowSpinner(false))
        }

      } else if ( modalAction === 'Edit' ) {

        // validate fields in form
        const isValidForm = this.onValidateEditForm(customer);
        
        if ( isValidForm ) {
          let adjustedCustomer = JSON.parse(JSON.stringify(customer));
          if(adjustedCustomer.vehicleSalesTaxPercent){
            adjustedCustomer.vehicleSalesTaxPercent = adjustedCustomer.vehicleSalesTaxPercent.replace(/[^0-9.]/g, "");
          }
          if(adjustedCustomer.nonVehicleSalesTaxPercent){
            adjustedCustomer.nonVehicleSalesTaxPercent = adjustedCustomer.nonVehicleSalesTaxPercent.replace(/[^0-9.]/g, "");
          }
          
          dispatch(rdx.saveCustomer({
            orderId,
            customerId,
            customerObj: adjustedCustomer,
            newContacts: nonEmptyAddedContacts,
            editedContacts: editedContactsArr,
            newCosigners: nonEmptyAddedCosigners,
            editedCosigners: editedCosignersArr,
            deletedContacts: deletedContactIdsArr,
            deletedCosigners: deletedCosignerIdsArr,
            cosigners: validCosigners
    }))
  }else {
    this.onShowAddValidateErrors();
    dispatch(setShowSpinner(false))
  }
}
    }
  }
}

//Export custom hook to provide the ModalHelper
export const useModalHelper = ( dispatch, history, context ) => {
  return newCustomerModalHelper(dispatch, history, context)
}

const VALID_ADDRESS_FIELDS = [
  'addressLine1',
  'addressLine2',
  'city',
  'state',
  'zip'
]

// const REQUIRED_FIELDS_ADD_CUSTOMER = [
//   'name',
//   'phone',
//   'email',
// ]

const REQUIRED_CUSTOMER_INFO_FIELDS_ADD = [
  'name',
  'phone',
  'email',
]

const REQUIRED_DELIVERY_FIELDS = [
  'addressLine1',
  'city',
  'state',
  'zip'
]

function ANY_VALUE_VALIDATOR(value) {
  return true
}

function NOT_EMPTY_VALIDATOR(value) {
  return _.isEmpty(value) === false
}

//For getting either edited values or the values in second object...
function getEditedOrDefaultValue(field, firstObj, secondObj) {

  if ( _.isNil( firstObj[ field ]) === false ) {
    return firstObj[ field ]
  }

  return secondObj[ field ] ? secondObj[ field ] : ''
}

import { formatNameLastMiddleFirst, formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";

export const SDDuplicateTitleGlobalAutofill = (documentValues, order, property=null, value=null) => {

    var newValues = documentValues;
    
    if(property?.includes('customer') || property === null){

      if(order && order.customer){
    
        if(order && order.customer && order.customer.name){
    
          if(order.customer.type === "entity"){
            newValues.name_as_it_appears_on_the_title = order.customer.name;
            newValues.printed_name = order.customer.name;
          }else{
            newValues.name_as_it_appears_on_the_title = formatNameLastMiddleFirst(order.customer.name);
            newValues.printed_name = formatNameLastMiddleFirst(order.customer.name);
          }
      
          if(order.customer.cosigners && order.customer.cosigners.length > 0){
            let cosigner = order.customer.cosigners[0];
            if(cosigner){
              newValues.name_as_it_appears_on_the_title = newValues.name_as_it_appears_on_the_title+" and "+cosigner.name;
              newValues.printed_name_2 = cosigner.name;
            }
          }
        }
        
        if(order && order.customer && order.customer.deliveryAddress){
          newValues.special_mailing_name_and_address = order.customer.name;
          newValues.address = order.customer.deliveryAddress.addressLine1;
          if(order.customer.deliveryAddress.addressLine1 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
            newValues.address = newValues.address+" "+order.customer.deliveryAddress.addressLine2;
          }
          newValues.city = order.customer.deliveryAddress.city;
          newValues.state = order.customer.deliveryAddress.state;
          newValues.zip_code = order.customer.deliveryAddress.zip;
        }
    
        if(order && order.customer && order.customer.mailingAddress){
          newValues.address = order.customer.mailingAddress.addressLine1;
          if(order.customer.mailingAddress.addressLine1 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
            newValues.address = newValues.address+" "+order.customer.mailingAddress.addressLine2;
          }
          newValues.city = order.customer.mailingAddress.city;
          newValues.state = order.customer.mailingAddress.state;
          newValues.zip_code = order.customer.mailingAddress.zip;
        }
      }

    }
    
    return newValues
}

export const SDDuplicateTitleAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
  var newValues = documentValues;

  if(property?.includes('year') || property === null){
    newValues.year = (selectedProduct.year);
  }
  
  if(property?.includes('vin') || property === null){
    newValues.vinhin = (selectedProduct.vin).toString().toUpperCase();
  }

  if(property?.includes('make') || property === null){
    newValues.make = (selectedProduct.make).toString().toUpperCase();
  }

  if(property?.includes('model') || property === null){
    newValues.model = (selectedProduct.model).toString().toUpperCase();
  }

  if(property?.includes('vehicleType') || property === null){
    newValues.body_type = (selectedProduct.vehicleType);
  }

  if(property?.includes('fundingDetail') || property?.includes('fundingMethod') || property?.includes('shipTo') || property === null){

    if(selectedProduct.fundingDetail && (selectedProduct.fundingDetail.fundingMethod === "tec-financing" || selectedProduct.fundingDetail.fundingMethod === "finance-only" || selectedProduct.fundingDetail.fundingMethod === "direct-deal")){
      newValues.name = selectedProduct.fundingDetail.finalizedLendingOption.bankName;
      newValues.address_3 = selectedProduct.fundingDetail.finalizedLendingOption.lienAddress;
      newValues.city_3 = selectedProduct.fundingDetail.finalizedLendingOption.lienCity;
      newValues.state_3 = selectedProduct.fundingDetail.finalizedLendingOption.lienState;
      newValues.zip_code_3 = selectedProduct.fundingDetail.finalizedLendingOption.lienZip;   
    }else if(selectedProduct.fundingDetail && selectedProduct.fundingDetail.fundingMethod === "cash-with-lien"){
      newValues.name = selectedProduct.fundingDetail.cashLienHolder.bankName;
      newValues.address_3 = selectedProduct.fundingDetail.cashLienHolder.addressLine1+" "+selectedProduct.fundingDetail.cashLienHolder.addressLine2;
      newValues.city_3 = selectedProduct.fundingDetail.cashLienHolder.city;
      newValues.state_3 = selectedProduct.fundingDetail.cashLienHolder.state;
      newValues.zip_code_3 = selectedProduct.fundingDetail.cashLienHolder.zip;
    }else if(selectedProduct.shipTo){
      newValues.name = selectedProduct.shipTo.name;
      newValues.address_3 = selectedProduct.shipTo.addressLine1+" "+selectedProduct.shipTo.addressLine2;
      newValues.city_3 = selectedProduct.shipTo.city;
      newValues.state_3 = selectedProduct.shipTo.state;
      newValues.zip_code_3 = selectedProduct.shipTo.zip;
    }

  }

  return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  if(payload.odometer_toggle){
    // Reset all
    payload.miles_selected = "no";
    payload.kilometers_selected = "no";

    switch(payload.odometer_toggle){
      case "miles":
        payload.miles_selected = "yes";
        break;
      case "kilometers":
        payload.kilometers_selected = "yes";
        break;
      default:
    }
  }

  if(payload.declared_toggle){
    // Reset all
    payload.exceeded_mileage_selected = "no";
    payload.not_actual_mileage_selected = "no";

    switch(payload.declared_toggle){
      case "exceeds":
        payload.exceeded_mileage_selected = "yes";
        break;
      case "not-actual":
        payload.not_actual_mileage_selected = "yes";
        break;
      default:
    }
  }

  if(payload.request_duplicate_toggle){
    // Reset all
    payload.moving_state_selected = "no";
    payload.insurance_claim_selected = "no";
    payload.court_order_selected = "no";
    payload.name_change_selected = "no";
    payload.other_selected = "no";

    switch(payload.request_duplicate_toggle){
      case "moving":
        payload.moving_state_selected = "yes";
        break;
      case "insurance":
        payload.insurance_claim_selected = "yes";
        break;
      case "court":
        payload.court_order_selected = "yes";
        break;
      case "namechange":
        payload.name_change_selected = "yes";
        break;
      case "other":
        payload.other_selected = "yes";
        break;
      default:
    }
  }


  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  // if(payload.date_of_sale){
  //   payload.date_of_sale = moment.utc(payload.date_of_sale).format("MM/DD/YY");
  // }

  // if(payload.date){
  //   payload.date = moment.utc(payload.date).format("MM/DD/YY");
  // }

  return payload;
}
import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields, radios, dateFields) => {

  // Process radios for document preview
  radios?.forEach(radio => {
    radio?.options.forEach(option => {
      if(option.value !== "clear"){ //Don't add "None" as a property.
        payload[option.value] = "no";
        if(payload[radio.property] === option.value){
          payload[option.value] = "yes";
        }
      }
    });
  });

  // Process money, phone and uppercase fields for document preview
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  

  // Process checkboxes for document preview
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });
  
  // Format Date Fields for the document
  dateFields?.forEach(datefield => {
    if(payload[datefield]){
      payload[datefield] = moment.utc(payload[datefield]).format("MM/DD/YY");
    }
  });


  return payload;
}
import { SET_ROLE, GET_ROLE, SET_AD_ROLE, GET_AD_ROLE, SET_DEVELOPER_ACCESS, GET_DEVELOPER_ACCESS } from "./roleTypes"

export const setRole = (data) => {
    return {
        type: SET_ROLE, 
        payload: data 
    }
}

export const getRole = () => {
    return {
        type: GET_ROLE
    }
}

export const setDeveloperAccess = (data) => {
    return {
        type: SET_DEVELOPER_ACCESS, 
        payload: data 
    }
}

export const getDeveloperAccess = () => {
    return {
        type: GET_DEVELOPER_ACCESS
    }
}

export const setAdRole = (data) => {
    return {
        type: SET_AD_ROLE, 
        payload: data 
    }
}

export const getAdRole = () => {
    return {
        type: GET_AD_ROLE
    }
}
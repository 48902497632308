import React from "react";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemText from "@mui/material/ListItemText";

// import { useFetch } from "../../../../utlis/api";
import Navbar from "../../../../Components/Navbar/Navbar";

const NotificationsPage = ({ userIndex = 4, notifications }) => {

  return (
    <Box>
      <Navbar title="Notifications" />
      <Box
        sx={{
          mx: 4,
          my: 3,
          display: "flex",
          justifyContent: "space-around"
        }}
      >
        <List
          sx={{
            position: "absolute",
            width: 800,
            height: "100%",
            flexGrow: 1,
            ml: "auto",
            mr: "auto",
            bgcolor: "#F7F7F7",
          }}
          subheader={
            <ListSubheader
              id={`-menu-list-subheader`}
              component="div"
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <ListItemText
                id={`-menu-list-subheader-text`}
                primary="Notifications"
                primaryTypographyProps={{ variant: "h5" }}
              />
            </ListSubheader>
          }
        >
          {notifications.map(n => ( //TODO: add onClick for handleMarkAsRead
            <ListItem>
              <Badge
                id={"navbar-notifications-badge"}
                color="error"
                hidden={n.status === "read"}
              >
                <ListItemText
                  primary={n.message}
                  primaryTypographyProps={{}}
                />
              </Badge>
            </ListItem>
          ))}
          <ListItem>
            <ListItemText
              primary="No more notifications."
            />
          </ListItem>
        </List>
      </Box>
    </Box>
  )
};

export default NotificationsPage;

import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  useParams
} from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { formatter, formatPhoneNumber } from "../../../../../../../utlis/formatters";
import { useOrder } from "../../../../../../../redux/orders/order"
import FASTFormElement from "../FASTFormElement"
import  { processValuesChange } from "../documentHelpers"
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { WASalesTaxGlobalAutofill, WASalesTaxAutofillProductDetails, preProcessPayload } from "../DocumentAutofill/WASalesTax"

const WASalesTax = ({document_id, location, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  
  // Document Definition
  const documentTitle = "WA - Sales Tax";
  const documentSlug = "wa-sales-tax";
  const dateFields = useMemo(()=>{return["date", "exp_date"]},[]);
  const moneyFields = useMemo(()=>{return["sale_price"]},[]);
  const phoneFields = useMemo(()=>{return[]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "model"]},[]);
  const checkboxes = useMemo(()=>{return[
    "watercraft_selected",
    "registered_selected",
    "buyer_selected",
    "sect_2a_selected",
    "sect_2b_selected",
    "sect_2c_selected",
    "sect_2d_selected",
    "sect_2e_selected",
    "sect_3_selected",
    "sect_4a_selected",
    "sect_4b_selected",
    "sect_4c_selected",
    "sect_4d_selected",
    "sect_4e_selected",
    "sect_4f_selected",
    "sect_5a_selected",
    "sect_5b_selected",
    "sect_5c_selected",
    "sect_5d_selected",
    "sect_5e_selected",
    "sect_5f_selected",
    "sect_5g_selected",
    "sect_5h_selected",
    "sect_5i_selected",
    "sect_5j_selected",
    "sect_5k_selected",
    "sect_5l_selected",
    "sect_5m_selected",
    "sect_5n_selected",
    "sect_5o_selected",
    "sect_5p_selected",
    "sect_5q_selected",
    "sect_5r_selected",
    "sect_5s_selected",
    "sect_5t_selected",
    "sect_5u_selected",
    "sect_5v_selected",
    "sect_5w_selected",
    "sect_5x_selected",
    "sect_5y_selected",
    "sect_5z_selected",
    "sect_5aa_selected",
    "sect_5bb_selected"
  ]},[]);
  const radios = useMemo(()=>{return[{
        property: "cert_type_toggle",
        options: [
          {label: "Single Use", value: "single_selected"}, 
          {label: "Blanket Certificate", value: "blanket_selected"},
          {label: "None", value: "clear"}
        ]},
        {
          property: "entity_toggle",
          options: [
            {label: "Individual", value: "entity_individual_selected"}, 
            {label: "Corporation", value: "entity_corporation_selected"},
            {label: "Sole Proprietor", value: "entity_sole_proprietor_selected"},
            {label: "Partnership", value: "entity_partnership_selected"},
            {label: "Other", value: "entity_other_selected"},
            {label: "None", value: "clear"}
        ]}
      ]},[]);

  // Data Structure & Init //
  const initialDocumentStructure = {}

  // App Helpers //
  let { id } = useParams();
  const order = useOrder();
  const axios = useFetch()


  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [documentUrl, setdocumentUrl] = useState("");
  const [document, setDocument] = useState(undefined);
  const [previousDocumentId, setPreviousDocumentId] = useState(0);
  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [assignedProduct, setAssignedProduct] = useState(undefined);
  const [assignedTrade, setAssignedTrade] = useState(undefined);
  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const [selectedLocation, setselectedLocation] = useState(undefined);
  const [locationOptions, setLocationOptions] = useState(undefined);
  const [locationToggleAutoFill, setLocationToggleAutoFill] = useState(false);


  function getOptions(propertyName){
    let foundRadio = radios?.find(radio => radio.property === propertyName);
    return foundRadio?.options;
  }

const handleDocumentValuesChange = e =>{
  // Primary Form Handler
  const {name} = e.target;
  const savedPos = e.target.selectionStart;
  
  setDocumentValues({...documentValues,[name]:processValuesChange(e.target, moneyFields, phoneFields, upperCaseFields, checkboxes)});
    
  if(upperCaseFields.indexOf(name) >= 0){
    setTimeout(() => {
      e.target.setSelectionRange(savedPos, savedPos);
    }, 0)
  }
  
  setHasUnsavedChanges(true);
  setSaveSuccess(false);
}
  
const loadDocument = useCallback(async (documentId) => {
  if(documentId && documentId != null && documentId !== undefined){
    try {
      let res = await axios.get(`/order/${id}/document/${documentId}`)
      if(res?.data.documentTemplate.slug === documentSlug){
        setDocument(res?.data)
      }
    } catch (err) {
      recordFrontEndError('WASalesTax.jsx', 'Error loading document: ' + err)
    }
  }
},[axios, id])

// const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      // setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, loadDocument ])
  // const [assignedProduct, setAssignedProduct] = useState(undefined);
  // const [assignedTrade, setAssignedTrade] = useState(undefined);
  // const [selectedAddress, setSelectedAddress] = useState(undefined);
  // const [selectedLocation, setselectedLocation] = useState(undefined);
  // const [locationOptions, setLocationOptions] = useState(undefined);
  // const [locationToggleAutoFill, setLocationToggleAutoFill] = useState(false);


if(locations && locations.length > 0 && locationOptions === undefined){
  let getLocationOptions = [];
  locations.map(location => {
    let str = `${location.description}`;
    let obj = {locationCode: location.locationCode, description: str}
    getLocationOptions.push(obj);
    return null;
  })
  setLocationOptions(getLocationOptions);
}


 // Autofill //

const GlobalAutofill = useCallback(async () => {

  let newValues = WASalesTaxGlobalAutofill(documentValues, order);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }



  // var newValues = documentValues;
  //   if(order?.customer){
  //     newValues.name = order.customer.name;
  //     newValues.name = order.customer.name;

  //     if(order?.customer?.cosigners && order?.customer?.cosigners?.length > 0){
  //       let cosigner = order.customer.cosigners[0];
  //       if(cosigner && cosigner.name){
  //         newValues.name += " AND "+cosigner.name;
  //       }
  //     }

  //     if(order?.customer?.dba){
  //       newValues.type_business = order?.customer?.dba;
  //     }

  //     if(order?.customer?.mailingAddress?.addressLine1){
  //       newValues.mailing_address = order.customer.mailingAddress.addressLine1;
  //       if(order.customer.mailingAddress.addressLine2 && order.customer.mailingAddress.addressLine2 !== ""){
  //         newValues.mailing_address += " "+order.customer.mailingAddress.addressLine2;
  //       }
  //       newValues.mailing_city = formatUpperCase(order.customer.mailingAddress.city);
  //       newValues.mailing_state = order.customer.mailingAddress.state;
  //       newValues.mailing_zip = order.customer.mailingAddress.zip;
  //     }else{
  //       newValues.mailing_address = order.customer.deliveryAddress.addressLine1;
  //       if(order.customer.deliveryAddress.addressLine2 && order.customer.deliveryAddress.addressLine2 !== ""){
  //         newValues.mailing_address += " "+order.customer.deliveryAddress.addressLine2;
  //       }
  //       newValues.mailing_city = formatUpperCase(order.customer.deliveryAddress.city);
  //       newValues.mailing_state = order.customer.deliveryAddress.state;
  //       newValues.mailing_zip = order.customer.deliveryAddress.zip;
  //     }

  //     newValues.buyer_address = order.customer.deliveryAddress.addressLine1;
  //     if(order.customer.deliveryAddress.addressLine2 && order.customer.deliveryAddress.addressLine2 !== ""){
  //       newValues.buyer_address += " "+order.customer.deliveryAddress.addressLine2;
  //     }
  //     newValues.buyer_city_state_zip = order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+", "+order.customer.deliveryAddress.zip;
   
  //   }

  //   setDocumentValues(newValues);
  //   setHasUnsavedChanges(true);
  //   setSaveSuccess(false);
},[ documentValues, order ])

// const preProcessPayload = useCallback((payload) => {

//   // Process radios for document preview
//   radios.forEach(radio => {
//     radio?.options.forEach(option => {
//       if(option.value !== "clear"){ //Don't add "None" as a property.
//         payload[option.value] = "no";
//         if(payload[radio.property] === option.value){
//           payload[option.value] = "yes";
//         }
//       }
//     });
//   });

//   // Process money, phone and uppercase fields for document preview
//   Object.keys(payload).forEach(key => {
//     if(moneyFields.indexOf(key) >= 0){
//         payload[key] = formatMoneyValue(payload[key]);
//     }else if(phoneFields.indexOf(key) >= 0){
//         payload[key] = formatPhoneNumber(payload[key]);
//     }else if(upperCaseFields.indexOf(key) >= 0){
//         payload[key] = formatUpperCase(payload[key]);
//     }
//   });
  
//   setDocumentValues(JSON.parse(JSON.stringify(payload)));

//   // Process checkboxes for document preview
//   Object.keys(payload).forEach(key => {
//     if(checkboxes.indexOf(key) >= 0){
//       payload[key] = payload[key] ? "yes": "no";
//     }
//   });
  
//   // Format Date Fields for the document
//   dateFields?.forEach(datefield => {
//     if(payload[datefield]){
//       payload[datefield] = moment.utc(payload[datefield]).format("MM/DD/YY");
//     }
//   });

//   return payload;
// },[ checkboxes, moneyFields, phoneFields, upperCaseFields, dateFields, radios ])

const previewPayloadDocument = useCallback(async (documentValues) => {
  var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });

    if(document){
      var docCopy = JSON.parse(JSON.stringify(document));
      docCopy.payload =  JSON.parse(JSON.stringify(payload));
      docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields, dateFields, radios);
      docCopy.payload =  JSON.stringify(docCopy.payload);
      delete docCopy.documentStatus;
      delete docCopy.documentTemplate;

      try {
        let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
          const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
          setdocumentUrl(fileURL)
      } catch (err) {
        recordFrontEndError('WASalesTax.jsx', 'Error previewing document payload: ' + err)
      }
    }
},[axios, checkboxes, dateFields, document, moneyFields, phoneFields, radios, upperCaseFields])


const AutofillProductDetails = useCallback(async (selectedProduct) => {
  let newValues = WASalesTaxAutofillProductDetails(selectedProduct, documentValues);

  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setSaveSuccess(false);
  }


  // var newValues = documentValues;
  // // newValues.year = (selectedProduct.year).trim();
  // newValues.model = (selectedProduct.model).trim().toUpperCase();
  // newValues.make = (selectedProduct.make).trim();
  // newValues.year = (selectedProduct.year);
  // newValues.vin = (selectedProduct.vin.toUpperCase());
  // newValues.sale_price = formatter.format(selectedProduct.price);

  // setDocumentValues(newValues);
  // // setProductToggleAutoFill(false);
  // if(document){
  //   previewPayloadDocument(documentValues);
  // }
  // // setAddressOptions(updateAddressOptions(selectedProduct, order));
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ document, documentValues, previewPayloadDocument ]);

// const AutofillTradeDetails = useCallback(async (selectedTradein) => {
//   var newValues = documentValues;
//   // newValues.year = (selectedProduct.year).trim();
//   newValues.make = (selectedTradein.make).trim();
//   newValues.model = (selectedTradein.year)
//   newValues.identification_number = (selectedTradein.vin.toUpperCase());

//   newValues.copy_make = (selectedTradein.make).trim();
//   newValues.copy_model = (selectedTradein.year)
//   newValues.copy_identification_number = (selectedTradein.vin.toUpperCase());
  

//   setDocumentValues(newValues);
//   // setProductToggleAutoFill(false);
//   if(document){
//     previewPayloadDocument(documentValues);
//   }
//   setHasUnsavedChanges(true);
//   setSaveSuccess(false);
// },[ document, documentValues, previewPayloadDocument ]);

  // Document BREAD + Preview // TODO: Move to Redux
  useEffect(() => {
    if(document && document.payload && !dataLoaded){
    var existingPayload = false;
    var documentPayload = JSON.parse(document.payload);
    if(documentPayload && Object.keys(documentPayload).length > 0){
  
      existingPayload = true;
  
      // Format dateFields for the form
      dateFields?.forEach(datefield => {
        if(documentPayload[datefield]){
          documentPayload[datefield] = moment.utc(documentPayload[datefield]).format("YYYY-MM-DD");
        }
      });

      setDocumentValues(documentPayload);

      // Loading Use Effect
      if(document.additionalData){
        const methodMap = { 
          selectedAddress: setSelectedAddress, 
          selectedLocation: setselectedLocation, 
        };

        let parsedAdditionalData = JSON.parse(document.additionalData);
        Object.keys(parsedAdditionalData).forEach(function(key) {
          if(methodMap[key]){
            if(key === "selectedLocation"){
              // setLocationVal(parsedAdditionalData[key]);
              if(parsedAdditionalData[key].locationCode){
                setselectedLocation(locations.find((location) => location.locationCode === parsedAdditionalData[key].locationCode));
              }
            }else{
              methodMap[key](parsedAdditionalData[key]);
            }
          }
        });
      }
    }

    var foundAssignedProduct = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
    }
    
    // setAddressOptions(getOrderLevelAddresses(order));
    if(foundAssignedProduct){
        setAssignedProduct(foundAssignedProduct);
        // setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
    }
    
    var foundAssignedTradein = null;
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'tradein'){
      foundAssignedTradein = order.tradeins.find((trade) => trade.id === document.assignedObjectId);
    }
    
    if(foundAssignedTradein){
      setAssignedTrade(foundAssignedTradein);
    }
    
    
    if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
        // if(foundAssignedTradein){
        //   AutofillTradeDetails(foundAssignedTradein)
        // }
      }else{
        // if(document && document.itemIds){
        //   var itemIds = document.itemIds.split(',');
        //   var foundProduct = order.products.find((product) => product.id === Number(itemIds[0]));
        //   let str = `${foundProduct.stockNumber.toString().toUpperCase()}`;
        //   let obj = {id: foundProduct.id, name: str}
        //   setproductVal(obj);
        //   if(foundProduct){
        //     setAddressOptions(updateAddressOptions(foundProduct, order));
        //   }
        //   streamDocument();
        // }
        
      }
      
      setDataLoaded(true);
    }
  },[ locations, documentValues, document, dataLoaded, order, AutofillProductDetails, GlobalAutofill, previewPayloadDocument, dateFields ])


  
    // function streamDocument(){
    //   axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
    //     .then(response => {
    //       const file = new Blob(
    //         [response.data], 
    //         {type: 'application/pdf'});
    //         const fileURL = URL.createObjectURL(file);
    //         // window.open(fileURL);
    //       setdocumentUrl(fileURL)
    //     }).catch(err => {
    //     })
    // }
  
    const updatePreview = e => {
      e.preventDefault();
      previewPayloadDocument(documentValues);
    }
  
  
    const handleSubmit = async event => {
      event.preventDefault();

      let payload = JSON.parse(JSON.stringify(documentValues));

      payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields, dateFields, radios);
    
    
      // const payload = JSON.parse(JSON.stringify(documentValues));
      // var docCopy = document;
      // docCopy.payload = payload;
      // docCopy.payload = preProcessPayload(docCopy.payload);
      // docCopy.payload =  JSON.stringify(docCopy.payload);
      // delete docCopy.documentStatus;
      
      // if(selectedProduct && selectedProduct.id){
      //   docCopy.itemIds = `${selectedProduct.id}`;
      // }
      //Save Dropdown Selections
      let dropdownData = {};
      if(selectedAddress){
        dropdownData.selectedAddress = {
          id: selectedAddress.id,
          name: selectedAddress.name
        };
      }
      if(selectedLocation){
        dropdownData.selectedLocation = {
          locationCode: selectedLocation.locationCode,
          description: selectedLocation.description
        };
      }
      // docCopy.additionalData = JSON.stringify(dropdownData);
      let additionalData = JSON.stringify(dropdownData);

      DocumentHandleSubmit(document, payload, additionalData, () => {
        getDocuments();
        setSaveSuccess(true);
        setHasUnsavedChanges(false);
      });
  
      // //TODO: Add TradeId's to save.
      // try {
      //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
      //   if (res) {
      //     await axios.post(`/document/${document.id}/generate`)
      //     getDocuments();
      //     setSaveSuccess(true);
      //     setHasUnsavedChanges(false);
      //   }
      // } catch (err) {
      //   recordFrontEndError('WASalesTax.jsx', 'Error saving document: ' + err)
      // }
    }

// Component Helpers //
// useEffect(() => {
  //Autofill Product Details After Selection

// },[ selectedProduct, productToggleAutoFill ])

// useEffect(() => {
//   //Autofill Tradein Details After Selection
//   if(selectedTradein !== undefined && tradeinToggleAutoFill){
//     var newValues = documentValues;
//     newValues.trade_year = (selectedTradein.year);
//     newValues.trade_make = (selectedTradein.make);
//     newValues.trade_model = (selectedTradein.model);
//     newValues.trade_body_type = (selectedTradein.seriesBodyType);
//     newValues.trade_serial_number = (selectedTradein.serialNumber);
//     newValues.balance_owner = (order.customer.name); //Need specific field for TRADE IN OWNER?
//     newValues.balance_owner_address = (order.customer.deliveryAddress.addressLine1);
//     newValues.amount = (selectedTradein.balanceOwed);
//     newValues.trade_allowance = (selectedTradein.tradeAllowanceAtTerms);
//     newValues.balance_owed = (selectedTradein.balanceOwed);
//     newValues.cash_refund = (selectedTradein.cashRefundToCustomer);
//     newValues.net_trade_allowance = (selectedTradein.finalTradeAllowance);
//     // newValues.cash_down_payment = (selectedTradein);

//     setDocumentValues(newValues);
//     setTradeinToggleAutoFill(false);
//     if(document){
//       previewPayloadDocument(documentValues);
//     }
//   }
// },[ selectedTradein, tradeinToggleAutoFill ])

useEffect(() => {
  //Autofill Tradein Details After Selection
  if(selectedLocation !== undefined && locationToggleAutoFill){
    var newValues = documentValues;
    newValues.seller_address = selectedLocation.address;
    newValues.seller_city = selectedLocation.city;
    newValues.seller_state = selectedLocation.state;
    newValues.seller_zip = selectedLocation.zip;
    newValues.seller_phone = formatPhoneNumber(selectedLocation.phone);

    // newValues.seller_id = "31422";

    // if(selectedLocation.state === "CA"){
    //   newValues.seller_name_signature = "TEC OF CALIFORNIA, INC";
    //   newValues.copy_seller_name_signature = "TEC OF CALIFORNIA, INC";
    // }else{
    //   newValues.seller_name_signature = "TEC EQUIPMENT, INC.";
    //   newValues.copy_seller_name_signature = "TEC EQUIPMENT, INC.";
    // }

    setDocumentValues(newValues);
    setLocationToggleAutoFill(false);
    if(document){
      previewPayloadDocument(documentValues);
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
},[ selectedLocation, locationToggleAutoFill, document, documentValues, previewPayloadDocument ])

//Autofill Address Details After Selection
// function AutoFillAddress(addressSelected, selectionInstance){
//   var newValues = documentValues;
//   if(selectionInstance){
//     if(selectionInstance === "buyer-address"){
//       newValues.buyer_address = addressSelected.address;
//       newValues.buyer_city = addressSelected.city;
//       newValues.buyer_state = addressSelected.state;
//       newValues.buyer_zip = addressSelected.zip;
//       if(order && order.customer){
//         newValues.buyer_print_name = order.customer.name;
//       }
//     }
//   }
//   setDocumentValues(newValues);
//   if(document){
//     previewPayloadDocument(documentValues);
//   }
//   setHasUnsavedChanges(true);
//   setSaveSuccess(false);
// }

const onCloseClick = e => {
  e.preventDefault()
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setdocumentUrl("");
  setDocument(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
  // setDocumentLoaded(false);
  setDataLoaded(false);
  setAssignedProduct(undefined);
  setAssignedTrade(undefined);
  setSelectedAddress(undefined);
  // setAddressOptions(undefined);
  // setinputAddressVal(undefined);
  // setLocationVal(undefined);
  setselectedLocation(undefined);
  // setinputLocationVal(undefined);
  setLocationOptions(undefined);
  setLocationToggleAutoFill(false);
  setDocumentFocus(undefined);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

  return (
    <>
      <div
        className="modal fade"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                { documentTitle }
              </h5>
              <br />
              { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1 pt-1 text-white">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                  </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}
                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                  
                <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                    GlobalAutofill();
                    AutofillProductDetails(assignedProduct);
                    previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                </div>

                <br />

                { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Product Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                        </div>
                    </div>
                ):null}

                { assignedTrade ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                        Trade In Snapshot
                        <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                          <h5>VIN: { assignedTrade.vin.toUpperCase() }</h5>
                          <h5>YMM: { assignedTrade.year+" "+assignedTrade.make+" "+assignedTrade.model.toUpperCase() }</h5>
                        </div>
                    </div>
                ):null}

                  <div className="row">
                    <h5>Equipment</h5>
                  </div>

                  {/* /////////////// */}

                  <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <FASTFormElement 
                          type="Radio" 
                          property="cert_type_toggle" 
                          value={documentValues.cert_type_toggle} 
                          options={getOptions("cert_type_toggle") ?? []}
                          label="This certificate is for:"
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Date</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date ?? '' }
                          />
                      </div>
                    </div>

                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Mailing Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_address ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">City</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_city"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_city ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">State</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_state"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_state ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="mailing_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.mailing_zip ?? '' }
                          />
                      </div>
                    </div>

                    <h4>Nonresident vessel purchases</h4>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Place of residence</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="place_of_residence"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.place_of_residence ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Type of Proof</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="type_of_proof"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.type_of_proof ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Identification Numbers</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="ident_num"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.ident_num ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-4">
                        <label htmlFor="stock">Expiration Date</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="exp_date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.exp_date ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <FASTFormElement 
                          type="Checkbox" 
                          property="watercraft_selected" 
                          value={documentValues.watercraft_selected} 
                          label="Watercraft"
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Make, Model and Serial Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="ident_num"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.ident_num ?? '' }
                          />
                      </div>
                    </div>

                    { documentValues?.watercraft_selected === true ? (
                      <div className="d-flex justify-content-evenly">
                        <div className="p-2 col-md-6">
                          <FASTFormElement 
                            type="Checkbox" 
                            property="registered_selected" 
                            value={documentValues.registered_selected} 
                            label="Registered Coast Guard..."
                            changeValueFunction={handleDocumentValuesChange}
                            onBlurFunction={updatePreview}
                            />
                        </div>
                        <div className="p-2 col-md-6">
                          <FASTFormElement 
                            type="Checkbox" 
                            property="buyer_selected" 
                            value={documentValues.buyer_selected} 
                            label="Buyer is resident of a foriegn country..."
                            changeValueFunction={handleDocumentValuesChange}
                            onBlurFunction={updatePreview}
                            />
                        </div>
                      </div>
                    ):null}

                    <hr></hr>
                    <h3>Page 2</h3>

                    <h4>2. Electric vehicles/vessels:</h4>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_2a_selected" 
                          value={documentValues.sect_2a_selected} 
                          label="a. Batteries or fuel cells..."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                          <FASTFormElement 
                            type="Checkbox" 
                            property="sect_2b_selected" 
                            value={documentValues.sect_2b_selected} 
                            label="b. Tangible personal property..."
                            changeValueFunction={handleDocumentValuesChange}
                            onBlurFunction={updatePreview}
                            />                      </div>
                            </div>
                            <div className="d-flex justify-content-evenly">
                              <div className="p-2 col-md-12">
                          <FASTFormElement 
                            type="Checkbox" 
                            property="sect_2c_selected" 
                            value={documentValues.sect_2c_selected} 
                            label="c. Zero emissions buses."
                            changeValueFunction={handleDocumentValuesChange}
                            onBlurFunction={updatePreview}
                            />                      </div>
                            </div>
                            <div className="d-flex justify-content-evenly">
                              <div className="p-2 col-md-12">
                          <FASTFormElement 
                            type="Checkbox" 
                            property="sect_2d_selected" 
                            value={documentValues.sect_2d_selected} 
                            label="d. Vessels equipped with battery-powered..."
                            changeValueFunction={handleDocumentValuesChange}
                            onBlurFunction={updatePreview}
                            />                      </div>
                            </div>
                            <div className="d-flex justify-content-evenly">
                              <div className="p-2 col-md-12">
                          <FASTFormElement 
                            type="Checkbox" 
                            property="sect_2e_selected" 
                            value={documentValues.sect_2e_selected} 
                            label="e. Batteries and batter packes..."
                            changeValueFunction={handleDocumentValuesChange}
                            onBlurFunction={updatePreview}
                            />
                      </div>
                    </div>

                    <h4>3. Intrastate air transport:</h4>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_3_selected" 
                          value={documentValues.sect_3_selected} 
                          label="Airplanes for use in providing interstate..."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>

                    <h4>4. Interstate or foreign commerce or commercial deep sea fishing business:</h4>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                          <FASTFormElement 
                            type="Checkbox" 
                            property="sect_4a_selected" 
                            value={documentValues.sect_4a_selected} 
                            label="a. Motor vehicle, trailers and component parts..."
                            changeValueFunction={handleDocumentValuesChange}
                            onBlurFunction={updatePreview}
                            />                      </div>
                            </div>
                            <div className="d-flex justify-content-evenly">
                              <div className="p-2 col-md-12">
                          <FASTFormElement 
                            type="Checkbox" 
                            property="sect_4b_selected" 
                            value={documentValues.sect_4b_selected} 
                            label="b. Airplanes, locomotives, railroad cars or watercraft..."
                            changeValueFunction={handleDocumentValuesChange}
                            onBlurFunction={updatePreview}
                            />                      </div>
                            </div>
                            <div className="d-flex justify-content-evenly">
                              <div className="p-2 col-md-12">
                          <FASTFormElement 
                            type="Checkbox" 
                            property="sect_4c_selected" 
                            value={documentValues.sect_4c_selected} 
                            label="c. Labor and services rendered..."
                            changeValueFunction={handleDocumentValuesChange}
                            onBlurFunction={updatePreview}
                            />                      </div>
                            </div>
                            <div className="d-flex justify-content-evenly">
                              <div className="p-2 col-md-12">
                          <FASTFormElement 
                            type="Checkbox" 
                            property="sect_4d_selected" 
                            value={documentValues.sect_4d_selected} 
                            label="d. Items for use connected with private..."
                            changeValueFunction={handleDocumentValuesChange}
                            onBlurFunction={updatePreview}
                            />                      </div>
                            </div>
                            <div className="d-flex justify-content-evenly">
                              <div className="p-2 col-md-12">
                          <FASTFormElement 
                            type="Checkbox" 
                            property="sect_4e_selected" 
                            value={documentValues.sect_4e_selected} 
                            label="e. Watercraft, component parts, labor..."
                            changeValueFunction={handleDocumentValuesChange}
                            onBlurFunction={updatePreview}
                            />                      </div>
                            </div>
                            <div className="d-flex justify-content-evenly">
                              <div className="p-2 col-md-12">

                          <FASTFormElement 
                            type="Text" 
                            property="registered_vessel_name" 
                            value={documentValues.registered_vessel_name} 
                            label="Registered vessel name"
                            changeValueFunction={handleDocumentValuesChange}
                            onBlurFunction={updatePreview}
                            />                      </div>
                            </div>
                            <div className="d-flex justify-content-evenly">
                              <div className="p-2 col-md-12">

                          <FASTFormElement 
                            type="Text" 
                            property="vessel_num" 
                            value={documentValues.vessel_num} 
                            label="Vessel Number"
                            changeValueFunction={handleDocumentValuesChange}
                            onBlurFunction={updatePreview}
                            />                      </div>
                            </div>
                            <div className="d-flex justify-content-evenly">
                              <div className="p-2 col-md-12">


                          <FASTFormElement 
                            type="Checkbox" 
                            property="sect_4f_selected" 
                            value={documentValues.sect_4f_selected} 
                            label="f. Purchases of liquefied natural gas (LNG)..."
                            changeValueFunction={handleDocumentValuesChange}
                            onBlurFunction={updatePreview}
                            />  
                      </div>
                    </div>

                    <hr></hr>
                    <h3>Page 3</h3>

                    <h4>5. Other:</h4>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">                        
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5a_selected" 
                          value={documentValues.sect_5a_selected} 
                          label="a. Waste vegetable oil..."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">       
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5b_selected" 
                          value={documentValues.sect_5b_selected} 
                          label="b. Equipment rental and purchase..."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5c_selected" 
                          value={documentValues.sect_5c_selected} 
                          label="c. Objects of art or cultural value..."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5d_selected" 
                          value={documentValues.sect_5d_selected} 
                          label="Adaptive automobile equipment..."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>
                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5e_selected" 
                          value={documentValues.sect_5e_selected} 
                          label="e. Animal pharmaceuticals..."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>

                    { documentValues?.sect_5e_selected === true ? (
                      <div className="d-flex justify-content-evenly">
                        <div className="p-2 col-md-12">      
                          <FASTFormElement 
                            type="Text" 
                            property="animal_pharma_desc" 
                            value={documentValues.animal_pharma_desc} 
                            label="Describe"
                            changeValueFunction={handleDocumentValuesChange}
                            onBlurFunction={updatePreview}
                            />
                        </div>
                      </div>
                    ):null}
                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5f_selected" 
                          value={documentValues.sect_5f_selected} 
                          label="f. Computer hardware, peripherals..."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>
                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5g_selected" 
                          value={documentValues.sect_5g_selected} 
                          label="g. Labor, services and tangible..."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>
                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5h_selected" 
                          value={documentValues.sect_5h_selected} 
                          label="h. Computer hardware, perpherals..."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>
                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5i_selected" 
                          value={documentValues.sect_5i_selected} 
                          label="i. City, County, Tribal..."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>
                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5j_selected" 
                          value={documentValues.sect_5j_selected} 
                          label="j. Tangible personal property..."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>

                    { documentValues?.sect_5j_selected === true ? (
                      <>
                        <div className="d-flex justify-content-evenly">
                          <div className="p-2 col-md-12">      
                            <FASTFormElement 
                              type="Text" 
                              property="types_of_goods" 
                              value={documentValues.types_of_goods} 
                              label="Types of goods purchased"
                              changeValueFunction={handleDocumentValuesChange}
                              onBlurFunction={updatePreview}
                              />
                          </div>
                        </div>

                        <div className="d-flex justify-content-evenly">
                          <div className="p-2 col-md-6">      
                            <FASTFormElement 
                              type="Text" 
                              property="point_of_delivery" 
                              value={documentValues.point_of_delivery} 
                              label="Point of delivery"
                              changeValueFunction={handleDocumentValuesChange}
                              onBlurFunction={updatePreview}
                              />
                          </div>
                          <div className="p-2 col-md-6">      
                            <FASTFormElement 
                              type="Text" 
                              property="carrier" 
                              value={documentValues.carrier} 
                              label="Carrier/agent"
                              changeValueFunction={handleDocumentValuesChange}
                              onBlurFunction={updatePreview}
                              />
                          </div>
                        </div>
                      </>
                    ):null}
                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5k_selected" 
                          value={documentValues.sect_5k_selected} 
                          label="k. Gases and chemicals used by..."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>
                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5l_selected" 
                          value={documentValues.sect_5l_selected} 
                          label="l. Hog fuel used to produce electricity..."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>
                    
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5m_selected" 
                          value={documentValues.sect_5m_selected} 
                          label="m. Tagible personal property..."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>

                    {/* ///// */}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5n_selected" 
                          value={documentValues.sect_5n_selected} 
                          label="n. Trail grooming services."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5o_selected" 
                          value={documentValues.sect_5o_selected} 
                          label="o. Honey bees, honey bee feed..."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>

                    
                    { documentValues?.sect_5o_selected === true ? (
                      <div className="d-flex justify-content-evenly">
                        <div className="p-2 col-md-12">      
                          <FASTFormElement 
                            type="Text" 
                            property="apiarist_id" 
                            value={documentValues.apiarist_id} 
                            label="Apiarist ID #:"
                            changeValueFunction={handleDocumentValuesChange}
                            onBlurFunction={updatePreview}
                            />
                        </div>
                      </div>
                    ):null}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5p_selected" 
                          value={documentValues.sect_5p_selected} 
                          label="p. Federal credit union purchases."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5q_selected" 
                          value={documentValues.sect_5q_selected} 
                          label="q. Wax, ceramic materials, and labor..."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5r_selected" 
                          value={documentValues.sect_5r_selected} 
                          label="r. Sales of ferry vessels to the state..."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5s_selected" 
                          value={documentValues.sect_5s_selected} 
                          label="s. Joint Municipal Utilities Services Authority."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5t_selected" 
                          value={documentValues.sect_5t_selected} 
                          label="t. Paratransit vehicles purchased by..."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5u_selected" 
                          value={documentValues.sect_5u_selected} 
                          label="u. Large/private airplanes purchased..."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5v_selected" 
                          value={documentValues.sect_5v_selected} 
                          label="v. Standard financial information purchased..."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>



                    <hr></hr>
                    <h3>Page 4</h3>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5w_selected" 
                          value={documentValues.sect_5w_selected} 
                          label="w. Material and supplies directly used in the..."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5x_selected" 
                          value={documentValues.sect_5x_selected} 
                          label="x. Vessel deconstruction services."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5y_selected" 
                          value={documentValues.sect_5y_selected} 
                          label="y. Only for delivered bottled water."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>

                    { documentValues?.sect_5y_selected === "true" ? (
                      <>
                        <div className="d-flex justify-content-evenly">
                          <div className="p-2 col-md-12">      
                            <FASTFormElement 
                              type="Checkbox" 
                              property="sect_5y_no_source_selected" 
                              value={documentValues.sect_5y_no_source_selected} 
                              label="No source of potable water"
                              changeValueFunction={handleDocumentValuesChange}
                              onBlurFunction={updatePreview}
                              />
                          </div>
                        </div>
                        <div className="d-flex justify-content-evenly">
                          <div className="p-2 col-md-12">      
                            <FASTFormElement 
                              type="Checkbox" 
                              property="sect_5y_prescribed_selected" 
                              value={documentValues.sect_5y_prescribed_selected} 
                              label="Prescribed water"
                              changeValueFunction={handleDocumentValuesChange}
                              onBlurFunction={updatePreview}
                              />
                          </div>
                        </div>
                        <div className="d-flex justify-content-evenly">
                          <div className="p-2 col-md-12">      
                            <FASTFormElement 
                              type="Checkbox" 
                              property="sect_5y_snap_selected" 
                              value={documentValues.sect_5y_snap_selected} 
                              label="Purchased with food stamps (SNAP)"
                              changeValueFunction={handleDocumentValuesChange}
                              onBlurFunction={updatePreview}
                              />
                          </div>
                        </div>
                      </>
                    ):null}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5z_selected" 
                          value={documentValues.sect_5z_selected} 
                          label="z. Anaerobic digesters and repair services."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5aa_selected" 
                          value={documentValues.sect_5aa_selected} 
                          label="aa. Purchases of solar energy..."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">      
                        <FASTFormElement 
                          type="Checkbox" 
                          property="sect_5bb_selected" 
                          value={documentValues.sect_5bb_selected} 
                          label="bb. Ride-sharing vehicles to be used..."
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>

                    <h4>Certification:</h4>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <FASTFormElement 
                          type="Radio" 
                          property="entity_toggle" 
                          value={documentValues.entity_toggle} 
                          options={getOptions("entity_toggle") ?? []}
                          label="Type of Entity"
                          changeValueFunction={handleDocumentValuesChange}
                          onBlurFunction={updatePreview}
                          />
                      </div>
                    </div>
                    { documentValues?.entity_toggle === "entity_other_selected" ? (
                      <div className="d-flex justify-content-evenly">
                        <div className="p-2 col-md-12">
                            <FASTFormElement 
                              type="Text" 
                              property="type_desc" 
                              value={documentValues.type_desc} 
                              label="Other Description"
                              changeValueFunction={handleDocumentValuesChange}
                              onBlurFunction={updatePreview}
                              />
                        </div>
                      </div>
                    ):null}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Type of business</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="type_business"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.type_business ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Account ID</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="account_id"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.account_id ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Buyer Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Title</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_title"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_title ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">Street Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_address ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-12">
                        <label htmlFor="stock">City, State, Zip</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_city_state_zip"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_city_state_zip ?? '' }
                          />
                      </div>
                    </div>

                <hr></hr>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default WASalesTax

import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase, getAreaCode, getPhoneNumber, removeChars } from "../../../../../../../utlis/formatters";
import { autoYMM } from './docUtils.js';

export const CAResaleCertGlobalAutofill = (documentValues, order, property=null, value=null, location) => {

    var newValues = documentValues;
    
  if(property==='customer.phone'){
    let phoneNumber=removeChars(value)
    newValues.area_code = getAreaCode(phoneNumber);
    newValues.telephone = getPhoneNumber(phoneNumber);
  }
  if(property==='customer.name'){
    newValues.name_of_purchaser = value;
    newValues.printed_name_signing = value;
    if(order.customer.cosigners && order.customer.cosigners.length > 0){
      let cosigner = order.customer.cosigners[0];
      if(cosigner){
        newValues.name_of_purchaser+=" and "+cosigner.name;
        newValues.printed_name_signing+=" and "+cosigner.name;
      }
    }
  }
  if(property==='customer.cosigner[0].name'){
    newValues.name_of_purchaser = order.customer.name;
    newValues.printed_name_signing = order.customer.name;
    if(value!==''){
        newValues.name_of_purchaser+=" and "+value;
        newValues.printed_name_signing+=" and "+value;
      }
  }
  if(property==='customer.deliveryAddress.addressLine1'){
    if(order.customer.deliveryAddress.addressLine1 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
      newValues.address_of_purchaser = value+" "+order.customer.deliveryAddress.addressLine2;
    }else {
      newValues.address_of_purchaser = value;
    }
    newValues.address_of_purchaser += ' '+ order.customer.deliveryAddress.city+" "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
  }
  if(property==='customer.deliveryAddress.addressLine2'){
      newValues.address_of_purchaser = order.customer.deliveryAddress.addressLine1+" "+value;
    newValues.address_of_purchaser += ' '+order.customer.deliveryAddress.city+" "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
  }
  if(property==='customer.deliveryAddress.city'){
      newValues.address_of_purchaser = order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2;
    newValues.address_of_purchaser += ' '+ value +" "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
  }
  if(property==='customer.deliveryAddress.state'){
      newValues.address_of_purchaser = order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2;
    newValues.address_of_purchaser += ' '+order.customer.deliveryAddress.city+" "+value+" "+order.customer.deliveryAddress.zip;
  }
  if(property==='customer.deliveryAddress.zip'){
      newValues.address_of_purchaser = order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2;
    newValues.address_of_purchaser += ' '+order.customer.deliveryAddress.city+" "+order.customer.deliveryAddress.state+" "+value;
  }
  if(property==='documentationDate'){
    newValues.date = moment.utc(value).format("YYYY-MM-DD");
  }
  if(property==='location'){
    if(value.state === "CA"){
      newValues.vendor_name = "TEC OF CALIFORNIA, INC.";
    }else{
      newValues.vendor_name = "TEC EQUIPMENT, INC.";
    }
  }
  if(property !== null && value !== null){
    return newValues;
}

    if(order && order.customer){
      if(order && order.customer && order.customer.name){
  
        newValues.area_code = getAreaCode(order.customer.phone);
        newValues.telephone = getPhoneNumber(order.customer.phone);
  
        newValues.name_of_purchaser = order.customer.name;
        newValues.printed_name_signing = order.customer.name;
     
        if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner){
            newValues.name_of_purchaser = newValues.name_of_purchaser+" and "+cosigner.name;
            newValues.printed_name_signing = newValues.printed_name_signing+" and "+cosigner.name;
          }
        }
      }
      
      if(order && order.customer && order.customer.deliveryAddress){
        newValues.address_of_purchaser = order.customer.deliveryAddress.addressLine1;
        if(order.customer.deliveryAddress.addressLine1 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
          newValues.address_of_purchaser = newValues.address_of_purchaser+" "+order.customer.deliveryAddress.addressLine2;
        }
        newValues.address_of_purchaser = order.customer.deliveryAddress.city+" "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
      }
    }
    
    if(order && order.documentationDate){
      newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }
  
    if(location){
      if(location.state === "CA"){
        newValues.vendor_name = "TEC OF CALIFORNIA, INC.";
      }else{
        newValues.vendor_name = "TEC EQUIPMENT, INC.";
      }
    }
  
    return newValues
}


export const CaResaleCertAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
  var newValues = documentValues;
  
  if(property === 'year' && value !== null){
    newValues.tangible_personal_property= autoYMM((value).toString().trim().replace(/['"]+/g, ''), selectedProduct.make.trim(), selectedProduct.model.trim())
}

if(property === 'make' && value !== null){
    newValues.tangible_personal_property= autoYMM(selectedProduct.year.trim(), (value).toString().trim(), selectedProduct.model.trim())
}

if(property === 'model' && value !== null){
    newValues.tangible_personal_property= autoYMM(selectedProduct.year.trim(), selectedProduct.make.trim(), (value).toString().trim())
}
  if(property !== null && value !== null){
      return newValues;
  }
  
  newValues.tangible_personal_property = (selectedProduct.year)+" "+(selectedProduct.make).toString().toUpperCase()+" "+(selectedProduct.model).toString().toUpperCase();

  return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields ) => {

  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  // if(payload.date_of_sale){
  //   payload.date_of_sale = moment.utc(payload.date_of_sale).format("MM/DD/YY");
  // }

  if(payload.date){
    payload.date = moment.utc(payload.date).format("MM/DD/YY");
  }

  return payload;
}
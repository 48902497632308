import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";

export const NVVehicleInspectionCertificateGlobalAutofill = (documentValues, order, property=null, value=null) => {

    var newValues = documentValues;
    
  //   if(property ==='customer.email'){
  //     newValues.printed = value;
  // }
    if(property ==='documentationDate'){
      newValues.date = moment.utc(value).format("YYYY-MM-DD");
  }
    if(property !== null && value !== null){
      return newValues;
  }

    // if(order && order.customer && order.customer.name){
    //     newValues.printed = order.customer.email;
    // }
  
    if(order && order.documentationDate && documentValues.date === undefined){
      newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }
  
  
    return newValues
}

export const NVVehicleInspectionCertificateAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
  var newValues = documentValues;
  
  if(property === 'year' && value !== null){
    newValues.year = (value).toString().trim().replace(/['"]+/g, '');
}

if(property === 'make' && value !== null){
    newValues.make = (value).toString().trim();
}

if(property === 'model' && value !== null){
    newValues.model = (value).toString().trim();
}

if(property === 'vehicleType' && value !== null){
    newValues.body_type = (value);
}

if(property !== null && value !== null){
    return newValues;
}

  newValues.vin = (selectedProduct.vin).toString().toUpperCase();
  newValues.year = (selectedProduct.year).trim();
  newValues.make = (selectedProduct.make).trim();
  newValues.model = (selectedProduct.model).toString().toUpperCase();

  newValues.body_type = selectedProduct.vehicleType;

  return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  // Data point formatting


  if(payload.safety_label_toggle){
    // Reset all
    payload.fcs_label_yes_cb = "no";
    payload.fcs_label_no_cb = "no";

    switch(payload.safety_label_toggle){
      case "yes":
        payload.fcs_label_yes_cb = "yes";
        break;
      case "no":
        payload.fcs_label_no_cb = "yes";
        break;
      default:
    }
  }

  if(payload.odometer_toggle){
    // Reset all
    payload.mileage_exceeded_cb = "no";
    payload.odo_reading_incorrect_cb = "no";
    payload.model_year_exemption_cb = "no";

    switch(payload.odometer_toggle){
      case "1":
        payload.mileage_exceeded_cb = "yes";
        break;
      case "2":
        payload.odo_reading_incorrect_cb = "yes";
        break;
      case "3":
        payload.model_year_exemption_cb = "yes";
        break;
      default:
    }
  }

  if(payload.living_quarters_toggle){
    // Reset all
    payload.trailer_living_quarters_yes_cb = "no";
    payload.trailer_living_quarters_no_cb = "no";

    switch(payload.living_quarters_toggle){
      case "yes":
        payload.trailer_living_quarters_yes_cb = "yes";
        break;
      case "no":
        payload.trailer_living_quarters_no_cb = "yes";
        break;
      default:
    }
  }

  if(payload.fuel_toggle){
    // Reset all
    payload.fuel_gas_cb = "no";
    payload.fuel_diesel_cb = "no";
    payload.fuel_propane_cb = "no";
    payload.fuel_electric_cb = "no";
    payload.fuel_other_cb = "no";

    switch(payload.fuel_toggle){
      case "gas":
        payload.fuel_gas_cb = "yes";
        break;
      case "diesel":
        payload.fuel_diesel_cb = "yes";
        break;
      case "propane":
        payload.fuel_propane_cb = "yes";
        break;
      case "electric":
        payload.fuel_electric_cb = "yes";
        break;
      case "other":
        payload.fuel_other_cb = "yes";
        break;
      default:
    }
  }
  

    // X1.2- Field Type Specific Preview Formatting
    Object.keys(payload).forEach(key => {
      if(moneyFields.indexOf(key) >= 0){
          payload[key] = formatMoneyValue(payload[key]);
      }else if(phoneFields.indexOf(key) >= 0){
          payload[key] = formatPhoneNumber(payload[key]);
      }else if(upperCaseFields.indexOf(key) >= 0){
          payload[key] = formatUpperCase(payload[key]);
      }
    });
    Object.keys(payload).forEach(key => {
      if(checkboxes.indexOf(key) >= 0){
        payload[key] = payload[key] ? "yes": "no";
      }
    });
  if(payload.date){
    payload.date = moment.utc(payload.date).format("MM/DD/YY");
  }
  return payload;
}
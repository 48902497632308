import React, { useState, useEffect, useCallback } from "react";
import {

  useParams, 

} from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import { removeChars, formatter, formatNumber } from "../../../../../../../utlis/formatters";
import { useOrder } from "../../../../../../../redux/orders/order"
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { TECPayOffAuthorizationGlobalAutofill, TECPayOffAuthorizationAutofillProductDetails, TECPayOffAuthorizationAutofillTradeDetails } from "../DocumentAutofill/TECPayOffAuthorization"


const TECPayOffAuthorization = ({document_id, location, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "tec-auth-payoff";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();
  const axios = useFetch()
  const order = useOrder();
  const [documentUrl, setdocumentUrl] = useState("");
  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);
  
  var initialDocumentStructure = {
    location_address: undefined, // Header Location Details / System Autofill
    location_phone: undefined, // Header Location Details / System Autofill
    location_email: undefined, // Header Location Details / System Autofill
    tec_footer_label: undefined, // Footer / System Autofill
    pg_no: undefined, // Footer / System Autofill
    balance: undefined, 
    to: undefined,
    name: undefined,
    serial_number: undefined,
    model: undefined,
    make: undefined,
    year: undefined,
    vin: undefined,
    year_2: undefined,
    make_2: undefined,
    releaser_name: undefined,
    releaser_name_2: undefined,
    dol_client_number: undefined,
    dol_client_number_2: undefined,
  }

  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);
  const handleDocumentValuesChange = e =>{
    e.preventDefault();
    const {name,value}=e.target;
    if(name.includes('vin')) {
      let newStr = value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
      newStr = newStr.length > 17 ? newStr.slice(0, -1) : newStr;
      setDocumentValues({...documentValues,[name]:newStr});
    } else if(name.includes('year')) {
        let newStr = value.replace(/[^0-9]/g,'');
        newStr = newStr.length > 4 ? documentValues[name].replace(/[^0-9]/g,'') : newStr;
        setDocumentValues({...documentValues,[name]:newStr});
    } else if(name.includes('dol_client_number')) {
        let newStr = value.replace(/[^0-9]/g,'');
        setDocumentValues({...documentValues,[name]:newStr});
    } else if(name.includes('serial_number')) {
        let newStr = value.toUpperCase();
        setDocumentValues({...documentValues,[name]:newStr});
    } else if(name === 'balance') {
      let val = '$' + (removeChars(value));
      setDocumentValues({...documentValues,[name]:val});
    } else {
        setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  
  // const [documentLoaded, setDocumentLoaded] = useState(false);
    
const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('TECPayOffAuthorization.jsx', 'Error loading document: ' + err)
      }
    }
},[axios, id])
  
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      // setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, loadDocument ])

  const [assignedProduct, setAssignedProduct] = useState(undefined);
  const [assignedTrade, setAssignedTrade] = useState(undefined);
  
  // Autofill //
  const GlobalAutofill = useCallback(async () => {

    let newValues = TECPayOffAuthorizationGlobalAutofill(documentValues, order, null, null, location);
    if(newValues){
      setDocumentValues(newValues);
      setHasUnsavedChanges(true);
      setSaveSuccess(false);
    }

    // var newValues = documentValues;
    // if(order && order.customer && order.customer.name){
    //   newValues.name = order.customer.name;
    //   newValues.releaser_name = order.customer.name;

    //   if(order.customer.dba){
    //     newValues.name += " dba "+order.customer.dba;
    //     newValues.releaser_name += " dba "+order.customer.dba;
    //   }

    //   if(order.customer.cosigners && order.customer.cosigners.length > 0){
    //     let cosigner = order.customer.cosigners[0];
    //     if(cosigner && cosigner.name){
    //       newValues.releaser_name_2 += " AND "+cosigner.name;
    //     }
    //   }
    // }
    
    // Get Location Address & Autofill Address
    if(location !== undefined){
      newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
    }
  
    if(location && location.phone){
      newValues.location_phone = location.phone;
    }
    
    if(location && location.email){
      newValues.location_email = location.email;
    }
  
    if(location){
      if(location.state === "CA"){
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
      }else{
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
      }
    }

    // newValues.pg_no = "Pg #1";
    setDocumentValues(newValues);
    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
    // setGlobalAutofill(false);
  },[ documentValues, order, location ])

  const previewPayloadDocument = useCallback(async (documentValues) => {
    var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });
    if(document){

      var docCopy = JSON.parse(JSON.stringify(document));
      docCopy.payload =  JSON.stringify(payload);
      delete docCopy.documentStatus;
      
      // return axios.put(`/document/${document.id}/prepare`, docCopy);  
      // return axios.post(`/document/${document.id}/preview-payload`, docCopy);
      try {
        let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
        setdocumentUrl(fileURL)
      } catch (err) {
        recordFrontEndError('TECPayOffAuthorization.jsx', 'Error previewing payload document: ' + err)
      };  
    }
  },[ axios, document ])

    //Autofill Product Details After Selection
const AutofillProductDetails = useCallback(async (selectedProduct) => {
  let newValues = TECPayOffAuthorizationAutofillProductDetails(selectedProduct, documentValues);

  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    previewPayloadDocument(documentValues);
    setSaveSuccess(false);
  }


  // var newValues = documentValues;
  // newValues.year = (selectedProduct.year).trim().replace('"', '').replace('"', '');
  // newValues.year_2 = (selectedProduct.year).trim().replace('"', '').replace('"', '');
  // newValues.make = (selectedProduct.make).trim().toString().toUpperCase();
  // newValues.make_2 = (selectedProduct.make).trim().toString().toUpperCase();
  // newValues.model = (selectedProduct.model).trim().toString().toUpperCase();
  // newValues.stock_number = (selectedProduct.stockNumber);
  // // newValues.mileage = (selectedProduct.mileage);
  // // newValues.serial_number = (selectedProduct.serialNumber);
  // newValues.vin = (selectedProduct.vin).trim().toString().toUpperCase();
  
  // newValues.balance = "";
  // newValues.to = "";
  // newValues.serial_number = "";
  // newValues.dol_client_number = "";
  
  // setDocumentValues(newValues);

  // // setProductToggleAutoFill(false);
  // previewPayloadDocument(documentValues);
  // setHasUnsavedChanges(true);
  // setSaveSuccess(false);
},[ documentValues, previewPayloadDocument ]);


  //Autofill Product Details After Selection
  const AutofillTradeDetails = useCallback(async (selectedTradein) => {
    let newValues = TECPayOffAuthorizationAutofillTradeDetails(selectedTradein, documentValues);

    if(newValues){
      setDocumentValues(newValues);
      setHasUnsavedChanges(true);
      previewPayloadDocument(documentValues);
      setSaveSuccess(false);
    }
  

    // var newValues = documentValues;
    // newValues.year = (selectedTradein.year).trim().replace('"', '').replace('"', '');
    // newValues.year_2 = (selectedTradein.year).trim().replace('"', '').replace('"', '');
    // newValues.make = (selectedTradein.make).trim().toString().toUpperCase();
    // newValues.make_2 = (selectedTradein.make).trim().toString().toUpperCase();
    // newValues.model = (selectedTradein.model).trim().toString().toUpperCase();
    // newValues.stock_number = (selectedTradein.stockNumber);
    // // newValues.mileage = (selectedTradein.mileage);
    // // newValues.serial_number = (selectedTradein.serialNumber);
    // newValues.vin = (selectedTradein.vin).trim().toString().toUpperCase();

    // if(selectedTradein.balanceOwed && selectedTradein.balanceOwed > 0){
    //   newValues.balance = formatter.format(selectedTradein.balanceOwed);
    // }

    // if(selectedTradein.balanceOwedTo){
    //   newValues.to = (selectedTradein.balanceOwedTo);
    // }

    // newValues.serial_number = selectedTradein.serialNumber;
    // newValues.dol_client_number = selectedTradein.dolClientNumber;

    // setDocumentValues(newValues);
  
    // // setTradeinToggleAutoFill(false);
    // previewPayloadDocument(documentValues);
    // setHasUnsavedChanges(true);
    // setSaveSuccess(false);
  },[ documentValues, previewPayloadDocument ]);

  const previewDocument = useCallback( async () => {
    try{
      let response = await axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
        setdocumentUrl(fileURL)
      } catch (err) {
        recordFrontEndError('TECPayOffAuthorization.jsx', 'Error previewing document: ' + err)
      }
  },[axios, document]);

  useEffect(() => {
    if(document && document.payload && !dataLoaded){
      var existingPayload = false;
      var documentPayload = JSON.parse(document.payload);
      if(documentPayload && Object.keys(documentPayload).length > 0){
        existingPayload = true;
        setDocumentValues(documentPayload);
      }

      // if(document.assignedObjectType && document.assignedObjectId){
      //   setVehicleTypeSelection(document.assignedObjectType)
      // }
      
      var foundAssignedProduct = null;
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
        foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
      }

      if(foundAssignedProduct){
        setAssignedProduct(foundAssignedProduct);
      }

      var foundAssignedTradein = null;
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'tradein'){
        foundAssignedTradein = order.tradeins.find((trade) => trade.id === document.assignedObjectId);
      }

      if(foundAssignedTradein){
        setAssignedTrade(foundAssignedTradein);
      }

      if(!existingPayload){
        GlobalAutofill();
        previewPayloadDocument(documentValues);
        if(foundAssignedProduct){
          AutofillProductDetails(foundAssignedProduct)
        }
        if(foundAssignedTradein){
          AutofillTradeDetails(foundAssignedTradein)
        }
      }else{
        previewDocument();
      }
      
      setDataLoaded(true);
    }
  },[documentValues, document, dataLoaded, AutofillProductDetails, AutofillTradeDetails, previewDocument, GlobalAutofill, order, previewPayloadDocument, assignedProduct])

  
  // Document BREAD + Preview // TODO: Move to Redux
  




  // Component Events

  const updatePreview = e => {
    e.preventDefault();
    previewPayloadDocument(documentValues);
    // saveDocument().then(r => { 
    //   regenerateDocument().then(res => {
    //     streamDocument()
    //   })
    // })
  }


  const handleSubmit = async event => {
    event.preventDefault();

    const payload = JSON.stringify(documentValues);
    // var docCopy = document;
    // docCopy.payload = payloadString;
    // delete docCopy.documentStatus;
    
    // if(selectedProduct && selectedProduct.id){
    //   docCopy.itemIds = `${selectedProduct.id}`;
    // }

    // if(selectedTradein && selectedTradein.id){
    //   docCopy.tradeinIds = `${selectedTradein.id}`;
    // }

    DocumentHandleSubmit(document, payload, null, () => {
      getDocuments();
      setSaveSuccess(true);
      setHasUnsavedChanges(false);
    });

    // try {
    //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
    //   if (res) {
    //     await axios.post(`/document/${document.id}/generate`) 
    //     getDocuments();
    //     setSaveSuccess(true);
    //     setHasUnsavedChanges(false);
    //   };
    // } catch (err) {
    //   recordFrontEndError('TECPayOffAuthorization.jsx', 'Error saving document: ' + err)
    // };
  };

// Component Helpers //



  const onCloseClick = e => {
    e.preventDefault()
    setHasUnsavedChanges(false);
    setSaveSuccess(false);
    setdocumentUrl("");
    setDocument(undefined);
    setPreviousDocumentId(0);
    setDocumentValues(initialDocumentStructure);
    // setDocumentLoaded(false);
    setDataLoaded(false);
    setAssignedProduct(undefined);
    setAssignedTrade(undefined);
    // setVehicleTypeSelection("product");
    setDocumentFocus(undefined);
  }
  
  const [hasClickedClose, setHasClickedClose] = useState(false);
  
  const closeClicked = e => {
    if( hasUnsavedChanges ){
        setHasClickedClose(true);
    } else {
        onCloseClick(e);
    }
  }
  
  const preCloseSave = e => {
    handleSubmit(e)
    setHasClickedClose(false);
  }
  
  const preCloseClose = e => {
    setHasClickedClose(false);
    onCloseClick(e)
  }

return (
    <>
      <div
        className="modal fade"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                TEC Payoff Authorization
              </h5>
              <br />
              { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1 pt-1 text-white">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                  </div>
              ): hasUnsavedChanges ? (
                  <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}
                  onClick={closeClicked}
                  ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                <div className="text-center">
                    <button 
                      type="button"
                      className="btn btn-primary mx-auto"
                      onClick={() => {
                        GlobalAutofill();
                        previewPayloadDocument(documentValues);
                      }}>Reload Autofill Values</button>
                    </div>
                    <br />

                    { assignedProduct ? (
                        <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                            Product Snapshot
                            <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                            <h5>SN: { assignedProduct.stockNumber }</h5>
                            <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                            <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                            </div>
                        </div>
                    ):null}

                    { assignedTrade ? (
                        <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                            Trade In Snapshot
                            <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                              <h5>VIN: { assignedTrade.vin.toUpperCase() }</h5>
                              <h5>YMM: { assignedTrade.year+" "+assignedTrade.make+" "+assignedTrade.model.toUpperCase() }</h5>
                            </div>
                        </div>
                    ):null}

                    <h5>Vehicle Information</h5>

                    {/* <div className="mb-3 col-md-12 ">
                      <label htmlFor="customerName" className="form-label">
                        Vehicle Type
                      </label>
                      <br />
                      <FormControl className="px-3 pt-2">
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={vehicleTypeSelection !== undefined ? vehicleTypeSelection : "product"}
                          onChange={e => {
                            setVehicleTypeSelection(e.target.value)
                          }}
                          disabled="true"
                        >
                          <FormControlLabel className="px-3" value="product" control={<Radio />} label="Product" />
                          <FormControlLabel className="px-3" value="tradein" control={<Radio />} label="Tradein" />
                        </RadioGroup>
                      </FormControl>
                    </div> */}

                    {/* { vehicleTypeSelection === "product" && productOptions && productOptions.length > 0 ? (
                    <div className="d-flex justify-content-evenly">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (productVal && productVal.name) ?? null}
                          onChange={(event, newValue) => {
                            setproductVal(newValue);
                            if(newValue && newValue.id){
                              setselectedProduct(order.products.find((product) => product.id === newValue.id));
                            }
                            setProductToggleAutoFill(true);
                            // resetVariables();
                          }}
                          inputValue={inputProductVal}
                          onInputChange={(event, newInputValue) => {
                            setinputProductVal(newInputValue);
                          }}
                          //id="controllable-states-demo"
                          options={productOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="PRODUCT" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>
                    ):null}

                    { vehicleTypeSelection === "tradein" && tradeinOptions && tradeinOptions.length > 0 ? (
                    <div className="d-flex justify-content-evenly">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (tradeinVal && tradeinVal.name) ?? null}
                          onChange={(event, newValue) => {
                            setTradeinVal(newValue);
                            if(newValue && newValue.id){
                              setselectedTradein(order.tradeins.find((tradein) => tradein.id === newValue.id));
                            }
                            setTradeinToggleAutoFill(true);
                            // resetVariables();
                          }}
                          inputValue={inputTradeinVal}
                          onInputChange={(event, newInputValue) => {
                            setinputTradeinVal(newInputValue);
                          }}
                          //id="controllable-states-demo"
                          options={tradeinOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="TRADE IN" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>
                    ):null} */}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Lien Holder</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="to"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.to ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Balance Owed</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="balance"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => {
                            let val = formatNumber(documentValues.balance);
                            let updatedObject = documentValues;
                            updatedObject.balance = val;
                            setDocumentValues(updatedObject)
                            // setDocumentValues({...documentValues,['balance']:val});
                            updatePreview(e)
                            } 
                          }
                          value={ documentValues.balance ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Serial Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1 text-uppercase"
                          name="serial_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.serial_number ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Model</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="model"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.model ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year_2 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make_2 ?? '' }
                          />
                      </div>
                    </div>
                    <hr></hr>
                    <h5>Release of Interest</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Releaser Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="releaser_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.releaser_name ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Releaser Name 2</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="releaser_name_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.releaser_name_2 ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">DOL Client Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="dol_client_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dol_client_number ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">DOL Client Number 2</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="dol_client_number_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.dol_client_number_2 ?? '' }
                          />
                      </div>
                   </div>
                  <hr></hr>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
            <span
              className="btn btn-success mx-auto"
            >
              Saved Document
            </span>
          ):(
            <button
            type="button"
            className="btn btn-primary mx-auto"
            // data-bs-dismiss="modal"
            onClick={handleSubmit}
            >
            Save
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default TECPayOffAuthorization

import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  useParams
} from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import { useOrder } from "../../../../../../../redux/orders/order"
import { removeChars, formatter, formatUpperCase } from "../../../../../../../utlis/formatters";
import { getOrderLevelAddresses, updateAddressOptions } from "../../../../../../../utlis/documentHelpers";
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { OregonBillOfSaleGlobalAutofill, OregonBillOfSaleAutofillProductDetails, OregonBillOfSaleAutofillTradeinDetails, preProcessPayload } from "../DocumentAutofill/OregonBillOfSale"


const OregonBillOfSaleModal = ({document_id, location, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {
  const documentSlug = "or-bill-of-sale";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  // App Logic & Data //
  let { id } = useParams();
  const order = useOrder();

  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);
  
  // Data Structure & Init //
  var initialDocumentStructure = {
    year: undefined, 
    make: undefined, 
    vin: undefined, 
    title_number: undefined, 
    buyer_name_last_first_middle: undefined, 
    purchase_date: undefined, 
    buyers_address: undefined, 
    sellers_name: undefined, 
    plate_number: undefined, 
    sellers_address: undefined
  }
  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);

  const moneyFields = useMemo(()=>{return[""]},[]);
  const phoneFields = useMemo(()=>{return[""]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "model"]},[]);
  const checkboxes = useMemo(()=>{return[""]},[]);
  // const radios = [""];

  // X1.1- Current General On Change
  const handleDocumentValuesChange = e =>{
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  // const [documentLoaded, setDocumentLoaded] = useState(false);
    
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('OregonBillOfSale.jsx', 'Error loading documnent: ' + err)
      }
    }
},[axios, id])

  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
  if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
    setdocumentUrl("");
    setPreviousDocumentId(document_id);
    // setDocumentLoaded(true);
    loadDocument(document_id);
  }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, loadDocument ])
  const [assignedProduct, setAssignedProduct] = useState(undefined);
  const [assignedTradein, setAssignedTradein] = useState(undefined);
  // const [productVal, setproductVal] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  // const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  // const [tradeinVal, setTradeinVal] = useState(undefined);
  // const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  // const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState(undefined);
  // const [addressVal, setAddressVal] = useState(undefined);
  const [inputAddressVal, setinputAddressVal] = useState(undefined);
  const [addressOptions, setAddressOptions] = useState(undefined);
  // const [addressToggleAutoFill, setAddressToggleAutoFill] = useState(false);
  // const [addressInstance, setAddressInstance] = useState(undefined);

  const [locationVal, setLocationVal] = useState(undefined);
  const [selectedLocation, setselectedLocation] = useState(undefined);
  const [inputLocationVal, setinputLocationVal] = useState(undefined);
  const [locationOptions, setLocationOptions] = useState(undefined);
  const [locationToggleAutoFill, setLocationToggleAutoFill] = useState(false);
// Dropdowns //

// if(order && order.products && order.products.length > 0 && productOptions === undefined){
//   let getProductOptions = [];
//   order.products.map(product => {
//     let str = `${product.stockNumber.toString().toUpperCase()}`;
//     let obj = {id: product.id, name: str}
//     getProductOptions.push(obj);
//   })
//   setproductOptions(getProductOptions);
// }

// if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
//   let getTradeinOptions = [];
//   order.tradeins.map(tradein => {
//     let str = `${tradein.year} ${tradein.make} ${tradein.model.toString().toUpperCase()}`;
//     let obj = {id: tradein.id, name: str}
//     getTradeinOptions.push(obj);
//   })
//   setTradeinOptions(getTradeinOptions);
// }

if(locations && locations.length > 0 && locationOptions === undefined){
  let getLocationOptions = [];
  locations.map(location => {
    let str = `${location.description}`;
    let obj = {locationCode: location.locationCode, description: str}
    getLocationOptions.push(obj);
    return null;
  })
  setLocationOptions(getLocationOptions);
}


  // function formatNameLastMiddleFirst(name){
  //   let wordArray = name.split(" ");
  //   if(wordArray.length === 2){
  //     return wordArray[1]+", "+wordArray[0];
  //   }else if(wordArray.length === 3){
  //     return wordArray[2]+", "+wordArray[0]+", "+wordArray[1];
  //   }
  //   return name;
  // }

  // function formatPhoneNumber(value) {
  //   if (!value) return value;
  //   let phoneNumber = value.replace(/[^\d]/g, "");
  //   let phoneNumberLength = phoneNumber.length;
  //   phoneNumber = phoneNumberLength > 10 ? phoneNumber.slice(0, -1) : phoneNumber;
  //   if (phoneNumberLength < 4) return phoneNumber;
  //   if (phoneNumberLength < 7) {
  //     return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  //   }
  //   return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
  //     3,
  //     6
  //   )}-${phoneNumber.slice(6, 10)}`;
  // }

  // function getAreaCode(phoneNumber){
  //   return phoneNumber.slice(0, 3);
  // }

  // function getPhoneNumber(phoneNumber){
  //   let number = phoneNumber.slice(3);
  //   return number.slice(0,3)+"-"+number.slice(3);
  // }

  const previewPayloadDocument = useCallback(async (documentValues) => {
    var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });
    
    var docCopy = JSON.parse(JSON.stringify(document));
    docCopy.payload =  JSON.parse(JSON.stringify(payload));
    docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);

    // if(docCopy.payload.estimated_delivery){
    //   docCopy.payload.estimated_delivery = moment.utc(docCopy.payload.estimated_delivery).format("MM/DD/YY");
    // }

    docCopy.payload =  JSON.stringify(docCopy.payload);
    delete docCopy.documentStatus;

    axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
    .then(response => {
      const file = new Blob(
        [response.data], 
        {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
      setdocumentUrl(fileURL)
    });
  },[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])


  //Autofill Product Details After Selection
  const AutofillProductDetails = useCallback(async (selectedProduct) => {
    let newValues = OregonBillOfSaleAutofillProductDetails(selectedProduct, documentValues);

    if(newValues){
      setDocumentValues(newValues);
      previewPayloadDocument(documentValues);
      setHasUnsavedChanges(true);
      previewPayloadDocument(documentValues);
      setSaveSuccess(false);
    }

    // var newValues = documentValues;
    // newValues.year = (selectedProduct.year).trim().replace(/['"]+/g, '');
    // newValues.make = (selectedProduct.make).trim();
    // newValues.model = (selectedProduct.model).trim().toString().toUpperCase();
    // newValues.vin = (selectedProduct.vin);
    // newValues.registration_province = (selectedProduct.registrationAddress?.state);
    


    // setDocumentValues(newValues);
    // previewPayloadDocument(documentValues);
    // // setAddressOptions(updateAddressOptions(selectedProduct, order));
    // setHasUnsavedChanges(true);
  },[ documentValues, previewPayloadDocument ]);

  
  //Autofill Tradein Details After Selection
  const AutofillTradeinDetails = useCallback(async (selectedTradein) => {
    let newValues = OregonBillOfSaleAutofillTradeinDetails(selectedTradein, documentValues);

    if(newValues){
      setDocumentValues(newValues);
      previewPayloadDocument(documentValues);
      setHasUnsavedChanges(true);
      previewPayloadDocument(documentValues);
      setSaveSuccess(false);
    }

    // var newValues = documentValues;
    // newValues.year = (selectedTradein.year).trim().replace(/['"]+/g, '');
    // newValues.make = (selectedTradein.make).trim();
    // newValues.model = (selectedTradein.model).trim().toString().toUpperCase();
    // newValues.vin = (selectedTradein.vin);
    // newValues.plate_number = (selectedTradein.plateNumber);
    // // newValues.registration_province = (selectedProduct.registrationAddress?.state);
    
  
  
    // setDocumentValues(newValues);
    // previewPayloadDocument(documentValues);
    // // setAddressOptions(updateAddressOptions(selectedProduct, order));
    // setHasUnsavedChanges(true);
  },[ documentValues, previewPayloadDocument ]);
  

  // Autofill //
  const GlobalAutofill = useCallback(async (autofillAssigned=false) => {
    let newValues = OregonBillOfSaleGlobalAutofill(documentValues, order, null, null, location, null, null, null, null, document?.assignedObjectType);
    if(newValues){
      setDocumentValues(newValues);
      setHasUnsavedChanges(true);
      setSaveSuccess(false);
    }

    // var newValues = documentValues;

    // var customerName = '';

    // if(order && order.customer && order.customer.name){
    //   // newValues.buyer_name_last_first_middle = formatNameLastMiddleFirst(order.customer.name).toString().toUpperCase();
    //   if(order.customer.type === "entity"){
    //     customerName = order.customer.name.toString().toUpperCase();
    //   }else{
    //     customerName = formatNameLastMiddleFirst(order.customer.name).toString().toUpperCase();
    //   }

    //   if(order.customer.dba){
    //     customerName += " dba "+order.customer.dba;
    //   }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
    //       let cosigner = order.customer.cosigners[0];
    //       if(cosigner && cosigner.name){
    //           customerName += " AND "+cosigner.name.toString().toUpperCase();
    //       }
    //   }
    // }

    // var tecName = '';
    // var tecAddress = '';
    // if(location){
    //   if(location.state === "CA"){
    //     tecName = " TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
    //   }else{
    //     tecName = "TEC EQUIPMENT, INC.";
    //   }
    //   tecAddress = location.address+" "+location.city+" "+location.state+" "+location.zip;
    // }

    // var deliveryAddress = '';
    // if(order && order.customer && order.customer.deliveryAddress){
    //   var addressLine = '';
    //   if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
    //     addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
    //   }else{
    //     addressLine = order.customer.deliveryAddress.addressLine1;
    //   }

    //   addressLine = addressLine.toString().toUpperCase()+", "+order.customer.deliveryAddress.city.toString().toUpperCase()+", "+order.customer.deliveryAddress.state.toString().toUpperCase()+" "+order.customer.deliveryAddress.zip.toString().toUpperCase();
    //   deliveryAddress = addressLine;
    //   // newValues.buyers_address = addressLine;
    // }

    // if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
    //   newValues.buyer_name_last_first_middle = customerName;
    //   newValues.buyers_address = deliveryAddress;

    //   newValues.sellers_name = tecName;
    //   newValues.sellers_address = tecAddress;
      
    // }else if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'tradein'){

    //   newValues.buyer_name_last_first_middle = tecName;
    //   newValues.buyers_address = tecAddress;

    //   newValues.sellers_name = customerName;
    //   newValues.sellers_address = deliveryAddress;

    // }

    // if(order && order.documentationDate && documentValues.purchase_date === undefined){
    //   newValues.purchase_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    // }

    // setDocumentValues(newValues);

    if(autofillAssigned){
      if(assignedProduct){
        AutofillProductDetails(assignedProduct);
      }
      
      if(assignedTradein){
        AutofillTradeinDetails(assignedTradein);
      }
    }

    // docCopy.payload =  JSON.stringify(docCopy.payload);
    // delete docCopy.documentStatus;
    // try {
    //   let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
    //   const file = new Blob(
    //     [response.data], 
    //     {type: 'application/pdf'});
    //   const fileURL = URL.createObjectURL(file);
    //     // window.open(fileURL);
    //   setdocumentUrl(fileURL)
    // } catch (err) {
    //   recordFrontEndError('OregonBillOfSale.jsx', 'Error previewing documnent payload: ', +err)
    // };  
  },[documentValues, order, location, document, assignedProduct, assignedTradein, AutofillProductDetails, AutofillTradeinDetails])

  useEffect(() => {
    if(document && document.payload && !dataLoaded){
      var existingPayload = false;
      var documentPayload = JSON.parse(document.payload);
      if(documentPayload && Object.keys(documentPayload).length > 0){
    
        existingPayload = true;
    
        if(documentPayload.estimated_delivery){
          documentPayload.estimated_delivery = moment.utc(documentPayload.estimated_delivery).format("YYYY-MM-DD");
        }

        if(documentPayload.purchase_date){
          documentPayload.purchase_date = moment.utc(documentPayload.purchase_date).format("YYYY-MM-DD");
        }
        
        // Loading Use Effect
        if(document.additionalData){
          const methodMap = { 
            selectedAddress: setSelectedAddress, 
            selectedLocation: setselectedLocation, 
          };

          let parsedAdditionalData = JSON.parse(document.additionalData);
          Object.keys(parsedAdditionalData).forEach(function(key) {
            if(methodMap[key]){
              if(key === "selectedLocation"){
                setLocationVal(parsedAdditionalData[key]);
                if(parsedAdditionalData[key].id){
                  setselectedLocation(locations.find((location) => location.id === parsedAdditionalData[key].id));
                }
              }else{
                methodMap[key](parsedAdditionalData[key]);
              }
            }
          });
        }

        setDocumentValues(documentPayload);
      }

        var foundAssignedProduct = null;
        if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
          foundAssignedProduct = order.products.find((product) => Number(product.id) === Number(document.assignedObjectId));
        }

        var foundAssignedTradein = null;
        if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'tradein'){
          foundAssignedTradein = order.tradeins.find((tradein) => Number(tradein.id) === Number(document.assignedObjectId));
        }

        setAddressOptions(getOrderLevelAddresses(order));
        if(foundAssignedProduct){
            setAssignedProduct(foundAssignedProduct);
            setAddressOptions(updateAddressOptions(foundAssignedProduct, order));
        }

        if(foundAssignedTradein){
          setAssignedTradein(foundAssignedTradein);
      }
    
        if(!existingPayload){
          GlobalAutofill();
          previewPayloadDocument(documentValues);
          if(foundAssignedProduct){
            AutofillProductDetails(foundAssignedProduct)
          }

          if(foundAssignedTradein){
            AutofillTradeinDetails(foundAssignedTradein)
          }
        }
        
        setDataLoaded(true);
    }
  },[ locations, documentValues, document, dataLoaded, AutofillProductDetails, AutofillTradeinDetails, GlobalAutofill, order, previewPayloadDocument ])

  const updatePreview = e => {
    e.preventDefault();
    // updateFormatting(e);
    previewPayloadDocument(documentValues);
  }

  const handleSubmit = async event => {
    event.preventDefault();

    let payload = JSON.parse(JSON.stringify(documentValues));

    payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);

    // var docCopy = document;
    // docCopy.payload = payload;

    // if(docCopy.payload.purchase_date){
    //   docCopy.payload.purchase_date = moment.utc(docCopy.payload.purchase_date).format("MM/DD/YY");
    // }
    // docCopy.payload =  JSON.stringify(docCopy.payload);
    // delete docCopy.documentStatus;
  

    //Save Dropdown Selections
    let dropdownData = {};
    if(selectedAddress){
      dropdownData.selectedAddress = {
        id: selectedAddress.id,
        name: selectedAddress.name
      };
    }
    if(selectedLocation){
      dropdownData.selectedLocation = {
        locationCode: selectedLocation.locationCode,
        description: selectedLocation.description
      };
    }

    let additionalData = JSON.stringify(dropdownData);

    DocumentHandleSubmit(document, payload, additionalData, () => {
      getDocuments();
      setSaveSuccess(true);
      setHasUnsavedChanges(false);
    });
    // docCopy.additionalData = JSON.stringify(dropdownData);

    // try {
    //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
    //   if(res){
    //     await axios.post(`/document/${document.id}/generate`)
    //     getDocuments();
    //     setSaveSuccess(true);
    //     setHasUnsavedChanges(false);
    //   };
    // } catch (err) {
    //   recordFrontEndError('OregonBillOfSale.jsx', 'Error saving document: ' + err)
    // };
    
  };

  //Autofill Address Details After Selection
  function AutoFillAddress(addressSelected, selectionInstance){
    var newValues = documentValues;
    if(selectionInstance){
      if(selectionInstance === "buyer-address"){
        newValues.buyers_address = addressSelected.address+", "+(addressSelected.city+", "+addressSelected.state+" "+addressSelected.zip);
      }
    }

    setDocumentValues(newValues);
    previewPayloadDocument(documentValues);
  }

  useEffect(() => {
    //Autofill Tradein Details After Selection
    if(selectedLocation !== undefined && locationToggleAutoFill){
      var newValues = documentValues;

      if(selectedLocation.state === "CA"){
        newValues.vendors_name = "TEC OF CALIFORNIA, INC";
      }else{
        newValues.vendors_name = "TEC EQUIPMENT, INC.";
      }
  
      setDocumentValues(newValues);
      setLocationToggleAutoFill(false);
      if(document){
        previewPayloadDocument(documentValues);
      }
    }
  },[ selectedLocation, locationToggleAutoFill, document, documentValues, previewPayloadDocument])


  const onCloseClick = e => {
    e.preventDefault()
    setDataLoaded(false);
    setHasUnsavedChanges(false);
    setSaveSuccess(false);
    setAssignedProduct(undefined);
    setAssignedTradein(undefined);
    setdocumentUrl("");
    setDocumentFocus(undefined);
    setDocument(undefined);
    setPreviousDocumentId(0);
    setDocumentValues(initialDocumentStructure);
    setSelectedAddress(undefined);
    setinputAddressVal(undefined);
    setAddressOptions(undefined);
    setLocationVal(undefined);
    setselectedLocation(undefined);
    setinputLocationVal(undefined);
    setLocationOptions(undefined);
    setLocationToggleAutoFill(false);
    setHasClickedClose(false);
  }
  
  const [hasClickedClose, setHasClickedClose] = useState(false);
  
  const closeClicked = e => {
    if( hasUnsavedChanges ){
        setHasClickedClose(true);
    } else {
        onCloseClick(e);
    }
  }
  
  const preCloseSave = e => {
    handleSubmit(e)
    setHasClickedClose(false);
  }
  
  const preCloseClose = e => {
    setHasClickedClose(false);
    onCloseClick(e)
  }


  return (
    <>
      <div
        className="modal fade"
        id={documentSlug}
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                Oregon Bill of Sale
              </h5>
              <br />
              { hasClickedClose ? (
                <div className="d-flex">
                    <p className="m-1 pt-1 text-white">Save Changes?</p>
                    <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                    <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                </div>
              ): hasUnsavedChanges ? (
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  // onClick={() => setdocumentUrl("")}
                  onClick={closeClicked}
                ></button>
              ):(
                <button
                  type="button"
                  className="btn-close bg-white"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  // onClick={() => setdocumentUrl("")}
                  onClick={preCloseClose}
                ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>

            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                  <div className="text-center">
                    <button 
                    type="button"
                    className="btn btn-primary mx-auto"
                    onClick={() => {
                      GlobalAutofill(true);
                      AutofillProductDetails(assignedProduct);
                      previewPayloadDocument(documentValues);
                    }}>Reload Autofill Values</button>
                  </div>
                  <br />

                  { assignedProduct ? (
                    <div className="m-2 p-2 scrollable" style={{ border: '2px solid #4e4e4e', borderRadius: '2px'}}>
                      Product Snapshot
                      <div className="px-2 py-3" style={{maxHeight: '150px', overflowY: 'scroll'}}>
                        <h5>SN: { assignedProduct.stockNumber }</h5>
                        <h5>YMM: { assignedProduct.year+" "+assignedProduct.make+" "+assignedProduct.model.toUpperCase() }</h5>
                        <h5>Price: { formatter.format(assignedProduct.price) }</h5>
                      </div>
                    </div>
                  ):null}

                  <div className="row">
                    <h5>Buyer Address</h5>
                    { addressOptions && addressOptions.length > 0 ? (
                    <div className="d-flex justify-content-evenly">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                          style={{ width: '100%'}}
                          value={ (selectedAddress && selectedAddress.name) ?? null}
                          name="mailing-address"
                          onChange={(event, newValue) => {
                            setSelectedAddress(newValue);
                            AutoFillAddress(newValue, "buyer-address");
                          }}
                          inputValue={inputAddressVal}
                          onInputChange={(event, newInputValue) => {
                            setinputAddressVal(newInputValue);
                          }}
                          //id="controllable-states-demo"
                          options={addressOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="ADDRESS" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            );
                          }}
                        />
                      </div>
                    </div>):(
                    <div  className="text-center mx-auto">
                      <h5 style={{ color: "red" }}> No Addresses Found</h5>
                    </div>
                  )}
                  </div>


                  <div className="row">
                    <h5>Buyer Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-12">
                        <label htmlFor="stock">Buyer Name(Print Last, First, Middle)</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyer_name_last_first_middle"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyer_name_last_first_middle ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-12">
                        <label htmlFor="stock">Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="buyers_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.buyers_address ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>



                  <div className="row">
                    <h5>Seller Information</h5>

                    { locationOptions && locationOptions.length > 0 ? (
                    <div className="d-flex justify-content-evenly">
                      <div className="mb-3 col-md-12 pt-4">
                          <Autocomplete
                          style={{ width: '100%'}}
                          value={ (locationVal && locationVal.description) ?? null}
                          onChange={(event, newValue) => {
                              setLocationVal(newValue);
                              if(newValue && newValue.locationCode){
                                setselectedLocation(locations.find((location) => location.locationCode === newValue.locationCode));
                                setLocationToggleAutoFill(true);
                              }
                              // resetVariables();
                          }}
                          inputValue={inputLocationVal}
                          onInputChange={(event, newInputValue) => {
                              setinputLocationVal(newInputValue);
                          }}
                          //id="controllable-states-demo"
                          options={locationOptions}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="SELLING LOCATION" />}
                          renderOption={(props, option) => {
                              return (
                              <li {...props} key={option.locationCode}>
                                  {option.description}
                              </li>
                              );
                          }}
                          />
                      </div>
                    </div>
                    ):(
                      <div  className="text-center mx-auto">
                        <h5 style={{ color: "red" }}> No Locations Found</h5>
                      </div>
                    )}

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-12">
                        <label htmlFor="stock">Seller Name</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="sellers_name"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.sellers_name ?? '' }
                          />
                      </div>
                    </div>

                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-12">
                        <label htmlFor="stock">Address</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="sellers_address"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.sellers_address ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                    <h5>Vehicle Information</h5>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2">
                        <label htmlFor="stock">Plate Number</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="plate_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.plate_number ?? '' }
                          />
                      </div>
                      {/* <div className="p-2">
                        <label htmlFor="stock">State/Province of Registration</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="registration_province"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.registration_province ?? '' }
                          />
                      </div> */}
                      <div className="p-2">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="year"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.year ?? '' }
                          />
                      </div>
                      <div className="p-2">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="make"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.make ?? '' }
                          />
                      </div>
                      <div className="p-2">
                        <label htmlFor="stock">Title #</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="title_number"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.title_number ?? '' }
                          />
                      </div>
                      {/* <div className="p-2">
                        <label htmlFor="stock">Model</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="model"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.model ?? '' }
                          />
                      </div> */}
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-12">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="vin"
                          maxLength="17"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.vin ?? '' }
                          />
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
          { saveSuccess ? (
              <span
                className="btn btn-success mx-auto"
              >
                Saved Document
              </span>
            ):(
              <button
              type="button"
              className="btn btn-primary mx-auto"
              // data-bs-dismiss="modal"
              onClick={handleSubmit}
              >
              Save
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default OregonBillOfSaleModal

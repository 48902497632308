import React, {useState} from "react";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Accordion, Card, Dropdown } from "react-bootstrap";
import { Divider } from '@mui/material';
// import { Menu } from "@material-ui/icons";

import FundingPaymentInfoTemplate from "./FundingPaymentInfoTemplate";
import { rdx } from "../../../../../../redux/fundingPayments"
import { useOrder } from "../../../../../../redux/orders/order"

const FundingPaymentSection = (props) => {

  const dispatch        = useDispatch()
  const history         = useHistory()
  const { id: orderId } = useParams()
  const order           = useOrder()
  const [selectedPayment, setSelectedPayment] = useState('')

  const deleteFundingPayment = async (e, fundingPaymentId=null) => {
    e.preventDefault();

    dispatch(rdx.deleteFundingPayment(orderId, fundingPaymentId))
    history.push(`/order/${ orderId }`);
  }
  return (
    <div>
    <div>
      <Accordion defaultActiveKey="0">
        {order && order.fundingPayments ? ( order.fundingPayments.map(fundingPayment => (
          <Card className="p-3">
            <FundingPaymentInfoTemplate amount={ fundingPayment.amount } paymentMethod={ (fundingPayment.paymentMethod ? fundingPayment.paymentMethod.name : '') } status={ fundingPayment.status } documentNumber={ fundingPayment.documentNumber} dateReceived={ fundingPayment.dateReceived } adminFee={fundingPayment.adminFee} gap={fundingPayment.gapAmount} fundingReserve={fundingPayment.fundingReserveAmount} nettedPayoff={fundingPayment.nettedPayoffAmount} >
              <Dropdown.Item
                data-bs-toggle="modal"
                data-bs-target="#fundingpaymentsmodal"
                onClick={(e) => {
                  dispatch(rdx.setOpenFundingPaymentModal(true))
                  dispatch(rdx.setFundingPaymentFocusId(fundingPayment.id))
                }}>
                <b>Edit Funding Payment</b>
              </Dropdown.Item>
              <Divider />
              <Dropdown.Item
               data-bs-toggle="modal"
               data-bs-target="#fundingpaymentsmodaldeleteconfirm"
                onClick={(e) => setSelectedPayment(fundingPayment)}>
                <b>Delete Funding Payment</b> 
              </Dropdown.Item>
            </FundingPaymentInfoTemplate>
             <Accordion.Collapse eventKey={fundingPayment.id} className="p-3">
              <div>
              </div>
            </Accordion.Collapse>
          </Card>
        ))):('')}
      </Accordion>
    </div>
    <div
      className="modal fade"
      id="fundingpaymentsmodaldeleteconfirm"
      tabIndex="-1"
      aria-labelledby="examplefundingpaymentmodel"
      aria-hidden="true"
      data-bs-backdrop="static" 
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header bg-primary text-white">
            <h5
              className="modal-title text-capitalize"
              id="examplefundingpaymentmodel"
            >
              Delete Funding Payment
            </h5>
            <button
              type="button"
              className="btn-close bg-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="px-3 py-3">
              <h4 className="container text-secondary text-center my-3">
                Are you sure you want to delete this funding payment?
              </h4>
            </div>
          </div>
          <div className="modal-footer text-center">
            <div className="mx-auto">
              <button
                type="button"
                className="btn btn-primary mx-3"
                onClick={(e) => deleteFundingPayment(e, selectedPayment.id)}
                data-bs-dismiss="modal"
              >
                Delete
              </button>
                <button
                type="button"
                className="btn btn-secondary mx-3"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default FundingPaymentSection;

import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';
import ModalProductDetail from './ModalProductDetails';
import ModalProductTaxFee from "./ModalProductTaxFee";
import ModalProductShipSold from "./ModalProductShipSold";
import ModalProductFob from "./ModalProductFob";
import ProductFinance from "./ProductFinance";
import ProductFinanceModal from './ProductFinanceModal';
import ProductFinanceLenderTermsModal from './ProductFinanceLenderTermsModal';
import "./Style/productmodal.css";
import { Box, Tab,Tabs, Typography } from '@mui/material';
import { createTheme } from '@material-ui/core/styles';
import { setProduct} from "../../../../../../../redux/products";
import { updateStatus } from "../../../../../../../redux/saveAndCopy";
import { setActiveModal, setActiveModalTab } from "../../../../../../../redux/app/appSaver";
import RegistrationAddressTab from "./RegistrationAddressTab";
import { matchAndGroupProducts } from "../../../../../../../utlis/dataSaveAndCopyLibrary";
import { useOrder } from "../../../../../../../redux/orders/order";
import { refreshOrder } from '../../../../../../../redux/orders/order';

function TabPanel(props) {

  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function ProductModal({departments, location, documents}) {
  let { id }      = useParams();
  const dispatch  = useDispatch();
  const history   = useHistory();
  const order   = useOrder();


  
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [productLoaded, setProductLoaded] = useState(false);
  const product = useSelector(state=> state.product);
  const appData = useSelector((state) => state?.appData);
  const [initialValues, setInitialValues] = useState(undefined);

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const theme = createTheme({
    palette: {
      secondary: {
        main: '#fff'
      }
    }
  });
  const [value, setValue] = useState(0);

  const [clickedOutMode, setClickedOutMode] = useState(undefined);
  const [clickedOutTabIndex, setClickedOutTabIndex] = useState(undefined);
  const [hasClickedOut, setHasClickedOut] = useState(false);


  useEffect(()=>{
    
    if(appData?.activeModal === 'product'){

      if( initialValues === undefined && product?.id ){
        setInitialValues({...product})
      }

    }
  },[product, initialValues, appData?.activeModal])

  const preCloseClose = e => {
    setHasClickedOut(false);
    setHasUnsavedChanges(false);
    if(clickedOutMode === 'tab' && clickedOutTabIndex >=0){
      setValue(clickedOutTabIndex);
      dispatch(setProduct(initialValues));

      dispatch(setActiveModal('product')); 


      switch(clickedOutTabIndex){
        case 0:
          dispatch(setActiveModalTab('details'));
          break;
        case 1:
          dispatch(setActiveModalTab('fob'));
          break;
        case 2:
          dispatch(setActiveModalTab('registration-address'));
          break;
        case 3:
          dispatch(setActiveModalTab('ship-sold'));
          break;
        case 4:
          dispatch(setActiveModalTab('tax-and-fee'));
          break;
        case 5:
          dispatch(setActiveModalTab('financing'));
          break;
        default:
          break;
      }
    }else{
      resetProduct();
      onCloseClick(e);
    }
  }

  const handleChange = (event, newValue) => {
    if(hasUnsavedChanges){
      setHasClickedOut(true);
      setClickedOutMode('tab');
      setClickedOutTabIndex(newValue);
    }else{
      setValue(newValue);
    }
  };

  const resetTab = (index=0) => {
    // reset Tab to 0 value
    setValue(index);
  }

  // set useRef hook for child components to reset values on modal close
  const resetFunc         = React.useRef(null);
  const resetAddFunc      = React.useRef(null);
  const resetUpgradeFunc  = React.useRef(null);
  const resetDepositFunc  = React.useRef(null);
  const resetTaxFeesFunc  = React.useRef(null);
  const resetProductFinanceFunc = React.useRef(null);
  const updateLenderInfoFunction = React.useRef(null);

  // set functions and hooks to tell ProductFinance to update client list
  // on add client from ProductFinanceModal
  const [updateLenderInfo, setupdateLenderInfo] = useState(false);

  const updateLenderInfoFromChild = (val) => {
      setupdateLenderInfo(val);
  }

  function resetProduct(){  
    // reset Tab to 0 value
    setValue(0);
    dispatch(setActiveModalTab(null));
    dispatch(setProduct({}))
    dispatch(updateStatus("init"));
    history.push(`/order/${id}`);
  }

  const onCloseClick = e => {
    e.preventDefault()
    if(product?.fobAddress?.addressLine1 !== null){
      resetProduct();
      matchAndGroupProducts(order, () => {
        dispatch(refreshOrder(order.id, true));
        document.getElementById('closeButton').click();
      });
    }
  }

  const saveStatus = useSelector((state) => state?.copyChangesToDocumentsRedux.status);


  const closeClicked = e => {
    e.preventDefault()
    if( (saveStatus !== 'saved' && saveStatus !== 'init') || product?.fobAddress?.addressLine1 === null){
      setHasClickedOut(true);
    } else {
      onCloseClick(e);
    }
  }

  return (
    <>
      { appData?.activeModal === 'product' && appData?.activeModalTab === 'financing' ? (
        <>
          <ProductFinanceModal 
          product={product}
          updateLenderInfoFromChild={updateLenderInfoFromChild} 
            resetCheckboxesVal={undefined} 
            resetProductFinanceFunc={resetProductFinanceFunc} 
          />
          <ProductFinanceLenderTermsModal
          product={product}
          updateLenderInfoFromChild={updateLenderInfoFromChild} 
          />
        </>
      ):null}
      <div
        className="modal fade"
        id="productModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
        // show={() => resetModal()}
        >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            { appData?.activeModal === 'product' ? (
              <>
                <div className="modal-header bg-primary my-0 py-1">
                  <div className="col-12 d-flex" style={{ justifyContent: 'space-between'}}>
                    <h5
                      className="modal-title text-white col-3 mt-2"
                      id="exampleModalLabel"
                    >
                      { product.id ? 'Edit Product' : 'Add Product'}
                    </h5>
                    <Tabs
                        className="text-white col-8"
                        style={{borderColor: 'white'}}
                        value={value}
                        theme={theme}
                        onChange={handleChange}
                        indicatorColor="secondary"   
                        textColor="inherit" 
                        // variant="fullWidth"
                        aria-label="Product Navigation Tabs"
                        TabIndicatorProps={{
                          title: "indicator",
                          sx: { backgroundColor: "white", height: '5px' }
                          // hidden: true
                        }}
                        >
                        <Tab className="px-4" label="Details" {...a11yProps(0)} data-testid="product.tab.details" disabled={product.id && !product?.fobAddress?.addressLine1} onClick={ () => { 

                          if(hasUnsavedChanges === false && product?.fobAddress?.addressLine1 !== null){
                            dispatch(setActiveModal('product')); 
                            dispatch(updateStatus('init'));
                            dispatch(setActiveModalTab('details'));
                          }
                          }}  />
                        <Tab className="px-4" label="FOB"  data-testid="product.tab.fob" disabled={!product.id} {...a11yProps(1)} onClick={ () => {  
                          if(hasUnsavedChanges === false){
                            dispatch(setActiveModal('product')); 
                            dispatch(updateStatus('init'));
                            dispatch(setActiveModalTab('fob'));
                          }
                          }}  />
                        <Tab className="px-3" label="Reg. Address"  data-testid="product.tab.registrationAddress" disabled={!product.id} {...a11yProps(2)} onClick={ () => {  
                          if(hasUnsavedChanges === false){
                            dispatch(setActiveModal('product')); 
                            dispatch(updateStatus('init'));
                            dispatch(setActiveModalTab('registration-address'));
                          }
                        }}  />
                        <Tab className="px-4" label="Ship - Sold" data-testid="product.tab.shipsold"  disabled={!product.id || (product.id && !product?.fobAddress?.addressLine1)} {...a11yProps(3)} onClick={ () => {

                            if(hasUnsavedChanges === false && product?.fobAddress?.addressLine1 !== null){
                              dispatch(setActiveModal('product')); 
                              dispatch(updateStatus('init'));
                              dispatch(setActiveModalTab('ship-sold'));
                            }

                            }}  />
                        <Tab className="px-4" label="Taxes & Fees" data-testid="product.tab.taxfee"  disabled={!product.id || (product.id && !product?.fobAddress?.addressLine1)} {...a11yProps(4)} onClick={ () => {

                            if(hasUnsavedChanges === false && product?.fobAddress?.addressLine1 !== null){
                              dispatch(setActiveModal('product')); 
                              dispatch(updateStatus('init'));
                              dispatch(setActiveModalTab('tax-and-fee'));
                            }                            }}  />
                        <Tab className="px-4" label="Financing"  data-testid="product.tab.financing"  disabled={!product.id || (product.id && !product?.fobAddress?.addressLine1)} {...a11yProps(5)} onClick={ () => {

                            if(hasUnsavedChanges === false && product?.fobAddress?.addressLine1 !== null){
                              dispatch(setActiveModal('product')); 
                              dispatch(updateStatus('init'));
                              dispatch(setActiveModalTab('financing'));
                            }
                          }} />
                      </Tabs>
                      <button
                        type="button"
                        className="btn-close bg-white mt-2"
                        // data-bs-dismiss="modal"
                        aria-label="Close"
                        data-testid="product.modal.control.closeClicked"
                        onClick={ closeClicked }
                      ></button>
                    {/* )} */}
                      <button
                        type="button"
                        className="btn-close bg-white"
                        id="closeButton"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        style={{ display: 'none' }}
                      ></button>
                  </div>
                </div>
                <div className="modal-body tab-content" style={{padding: "0"}}>
                      {/* <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                        > */}
                        <TabPanel value={value} index={0}>

                              <ModalProductDetail 
                                product={product} 
                                passedDepartments={departments}
                                resetFunc={resetFunc}
                                resetAddFunc={resetAddFunc}
                                resetUpgradeFunc={resetUpgradeFunc}
                                resetDepositFunc={resetDepositFunc}
                                hasUnsavedChanges={hasUnsavedChanges}
                                setHasUnsavedChanges={setHasUnsavedChanges}
                                hasClickedOut={hasClickedOut}
                                setHasClickedOut={setHasClickedOut}
                                preCloseClose={preCloseClose}
                                productLoaded={productLoaded}
                                setProductLoaded={setProductLoaded}
                                documents={documents}
                                location={location}
                                setValue={setValue}
                              />

                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          <ModalProductFob setValue={setValue} product={product} documents={documents} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} hasClickedOut={hasClickedOut} setHasClickedOut={setHasClickedOut} preCloseClose={preCloseClose} />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                          <RegistrationAddressTab product={product} documents={documents} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} hasClickedOut={hasClickedOut} setHasClickedOut={setHasClickedOut} preCloseClose={preCloseClose} />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                          <ModalProductShipSold product={product} documents={documents} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} hasClickedOut={hasClickedOut} setHasClickedOut={setHasClickedOut} preCloseClose={preCloseClose} />
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                          <ModalProductTaxFee location={location} documents={documents} product={product} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} resetTaxFeesFunc={resetTaxFeesFunc} hasClickedOut={hasClickedOut} setHasClickedOut={setHasClickedOut} preCloseClose={preCloseClose} />
                        </TabPanel>
                        <TabPanel value={value} index={5}>
                          <ProductFinance product={product} documents={documents} hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} 
                          updateLenderInfo={updateLenderInfo} 
                          updateLenderInfoFunction={updateLenderInfoFunction} resetTab={resetTab} hasClickedOut={hasClickedOut} setHasClickedOut={setHasClickedOut} preCloseClose={preCloseClose} 
                          updateLenderInfoFromChild={updateLenderInfoFromChild}
                          />
                        </TabPanel>
                      {/* </SwipeableViews> */}
                </div>
              </>
            ):<p>Active Modal: {appData?.activeModal}</p>}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductModal;

import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase, getAreaCode, getPhoneNumber, getPhoneNumberAlt, removeChars } from "../../../../../../../utlis/formatters";
import { salesPriceWithUpgradeCalc } from '../../../../../../../utlis/productCalculations.js';


export const CASalesTaxStatement6247GlobalAutofill = (documentValues, order, property=null, value=null, location) => {
    var newValues = documentValues;

    const TEC_TAX_ID_NUMBER = "91-1757479";

    if(property ==='customer.name'){
      newValues.purchaser_name = value;
      if(order.customer.dba!==''){
        newValues.purchaser_name += " dba "+order.customer.dba;
      }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
            newValues.purchaser_name += " AND "+cosigner.name;
        }
      }
    }
    if(property ==='customer.dba'){
      newValues.purchaser_name = order.customer.name;
      if(value!==''){
        newValues.purchaser_name += " dba "+ value;
      }else if(value===''&&order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
            newValues.purchaser_name += " AND "+cosigner.name;
        }
    }
  }
    if(property ==='customer.cosigner[0].name'){
      newValues.purchaser_name = order.customer.name;
       if(value!=='' && order.customer.dba===''){
            newValues.purchaser_name += " AND "+value;
        }else if(order.customer.dba!==''){
        newValues.purchaser_name += " dba "+ order.customer.dba;
      }
      }
    if(property ==='customer.phone'){
      let phoneNumber=removeChars(value)
      newValues.area_code = getAreaCode(phoneNumber);
      newValues.phone = getPhoneNumber(phoneNumber);
    }
    if(property ==='customer.deliveryAddress.addressLine1'){
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        newValues.ca_address = (value+" "+order.customer.deliveryAddress.addressLine2);
      }else{
        newValues.ca_address = value;
      }
    }
    if(property ==='customer.deliveryAddress.addressLine2'){
        newValues.ca_address = (order.customer.deliveryAddress.addressLine1+" "+value);
    }
    if(property ==='customer.deliveryAddress.city'){
      newValues.ca_address_city = value;
    }
    if(property ==='customer.deliveryAddress.state'){
      newValues.ca_state = value;
    }
    if(property ==='customer.deliveryAddress.zip'){
      newValues.ca_zip = value;
    }
    if(property ==='customer.mailingAddress.addressLine1'){
      if(order.customer.mailingAddress.addressLine2 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
        newValues.usage_address = (value+" "+order.customer.mailingAddress.addressLine2);
      }else{
        newValues.usage_address = value;
      }
    }
    if(property ==='customer.mailingAddress.addressLine2'){
        newValues.usage_address = (order.customer.mailingAddress.addressLine1+" "+value);
    }
    if(property ==='customer.mailingAddress.city'){
      newValues.mailing_city = value;
    }
    if(property ==='customer.mailingAddress.state'){
      newValues.mailing_state = value;
    }
    if(property ==='customer.mailingAddress.zip'){
      newValues.mailing_zip = value;
    }
  if(property==='location'){
    if(value && value.state){
      // newValues.agency_dealership = "TEC EQUIPMENT, INC.";
      newValues.delivery_address = value.address;
      newValues.delivery_city = value.city;
      newValues.deliery_state = value.state;
      newValues.delivery_zip = value.zip;

      if(value.state === "CA"){
        newValues.seller_name = "TEC OF CALIFORNIA, INC";
      }else{
        newValues.seller_name = "TEC EQUIPMENT, INC.";
      }

      if(value.state && value.state === "CA"){
        newValues.seller_cdtfa_number = TEC_TAX_ID_NUMBER;
      }

      newValues.seller_address = value.address;
      newValues.seller_city = value.city;
      newValues.seller_state = value.state;
      newValues.seller_zip = value.zip;
      newValues.seller_area_code = getAreaCode(value.phone);
      newValues.seller_phone = getPhoneNumberAlt(value.phone);
    }
  }

   
  if(property !== null && value !== null){
    return newValues;
  }


    if(order && order.customer){
      newValues.purchaser_name = order.customer.name;
      newValues.area_code = getAreaCode(order.customer.phone);
      newValues.phone = getPhoneNumber(order.customer.phone);
    }
    
    if(order.customer.dba){
      newValues.purchaser_name += " dba "+order.customer.dba;
    }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
      let cosigner = order.customer.cosigners[0];
      if(cosigner && cosigner.name){
          newValues.purchaser_name += " AND "+cosigner.name;
      }
    }

    if(order && order.customer && order.customer.mailingAddress){
      var addressLineMailing = '';
      if(order.customer.mailingAddress.addressLine2 != null && order.customer.mailingAddress.addressLine2 !== undefined && order.customer.mailingAddress.addressLine2 !== ''){
        addressLineMailing = (order.customer.mailingAddress.addressLine1+" "+order.customer.mailingAddress.addressLine2);
      }else{
        addressLineMailing = order.customer.mailingAddress.addressLine1;
      }
      newValues.usage_address = addressLineMailing;
      newValues.mailing_city = order.customer.mailingAddress.city;
      newValues.mailing_state = order.customer.mailingAddress.state;
      newValues.mailing_zip = order.customer.mailingAddress.zip;
    }

    if(location && location.state){
      // newValues.agency_dealership = "TEC EQUIPMENT, INC.";
      newValues.delivery_address = location.address;
      newValues.delivery_city = location.city;
      newValues.deliery_state = location.state;
      newValues.delivery_zip = location.zip;

      if(location.state === "CA"){
        newValues.seller_name = "TEC OF CALIFORNIA, INC";
      }else{
        newValues.seller_name = "TEC EQUIPMENT, INC.";
      }

      if(location.state && location.state === "CA"){
        newValues.seller_cdtfa_number = TEC_TAX_ID_NUMBER;
      }

      newValues.seller_address = location.address;
      newValues.seller_city = location.city;
      newValues.seller_state = location.state;
      newValues.seller_zip = location.zip;
      newValues.seller_area_code = getAreaCode(location.phone);
      newValues.seller_phone = getPhoneNumberAlt(location.phone);
    }

    return newValues
}


export const CASalesTaxStatement6247AutofillProductDetails  = (selectedProduct, documentValues, property=null, value=null, location, order) => {
  var newValues = documentValues;

  if(property === 'year' && value !== null){
    newValues.year = (value).toString().trim().replace(/['"]+/g, '');
}

if(property === 'make' && value !== null){
    newValues.make = (value).toString().trim();
}

if(property === 'model' && value !== null){
    newValues.model = (value).toString().toUpperCase();
}
if(property === 'price' && value !== null){
  if(location.state && location.state === "AZ"){
    newValues.purchase_price = salesPriceWithUpgradeCalc(selectedProduct);
    if(order.adminFee){
      newValues.purchase_price += Number(order.adminFee);
    }
    if(selectedProduct.lineItemFee && selectedProduct.lineItemFee.docFee){
      newValues.purchase_price += Number(selectedProduct.lineItemFee.docFee);
    }
  }
}

if(property !== null && value !== null){
  return newValues;
}

  newValues.year = (selectedProduct.year).trim();
  newValues.make = (selectedProduct.make).trim();
  newValues.model = (selectedProduct.model).trim().toString().toUpperCase();
  newValues.vin = (selectedProduct.vin);

  if(location.state && location.state === "AZ"){
    newValues.purchase_price = salesPriceWithUpgradeCalc(selectedProduct);
    if(order.adminFee){
      newValues.purchase_price += Number(order.adminFee);
    }
    if(selectedProduct.lineItemFee && selectedProduct.lineItemFee.docFee){
      newValues.purchase_price += Number(selectedProduct.lineItemFee.docFee);
    }
  }

  return newValues
}


export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {

  // Field Type Specific Preview Formatting
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  // Data point formatting
  if(payload.purchase_date){
    payload.purchase_date = moment.utc(payload.purchase_date).format("MM/DD/YY");
  }

  if(payload.seller_signature_date){
    payload.seller_signature_date = moment.utc(payload.seller_signature_date).format("MM/DD/YY");
  }

  if(payload.date){
    payload.date = moment.utc(payload.date).format("MM/DD/YY");
  }

  return payload;
}
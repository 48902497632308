import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash"
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { rdx } from "../../../../../../redux/fundingPayments"
import { useOrder } from "../../../../../../redux/orders/order"
import FundingPaymentModal from "./FundingPaymentModal"
import { useFetch } from "../../../../../../utlis/api";

const FundingPaymentModalContainer = () => {
  const dispatch        = useDispatch()
  const history         = useHistory()
  const { id: orderId } = useParams()
  const axios     = useFetch()

  const order = useOrder()

  const editedFundingPayment       = useEditedFundingPayment()
  const editedFundingPaymentErrors = useEditedFundingPaymentErrors()
  const fundingPaymentId           = useFundingPaymentFocusId()
  const formattedAmount            = useFormattedAmount()
  const formattedAdminFee    = useFormattedAdminFee()
  const formattedGapAmount         = useFormattedGapAmount()
  const formattedFundingReserveAmount         = useFormattedFundingReserveAmount()
  const formattedNettedPayoffAmount         = useFormattedNettedPayoffAmount()
  const isEditMode              = useIsEditMode()
  const isOpen                  = useOpenFundingPaymentModal()
  const isValidForSubmit        = useIsValidForSubmit()
  // const lastClosedTimestamp     = useLastClosedTimestamp()
  const showSuccessFeedback     = useShowSuccessFeedback()
  const statusInputValue        = useStatusInputValue()
  const statusInputValueError   = useStatusInputValueError()
  const hasUnsavedChanges        = useHasUnsavedChanges()

  //Values to apply when not present on the editedFundingPayment or the order fundingPayment...
  const FUNDINGPAYMENT_EMPTY_DEFAULTS = {
    status: "",
    amount: "",
    paymentMethod: "",
    documentNumber: "",
  }

  //Get the fundingPayment from the order each time the fundingPaymentId or order changes
  const fundingPaymentFromOrder = useMemo(() => {
    if ( _.isNil( fundingPaymentId ) ) {
      return {}
    }

    const orderFundingPayments = _.defaultTo(order.fundingPayments, [])
    const fundingPayment =
      _.defaultTo(orderFundingPayments.find(d => d.id === fundingPaymentId ), {})

    return fundingPayment

  }, [ order, fundingPaymentId ])

  //Effect to determine whether we're in 'Add' or 'Edit' mode each
  //time the user opens the modal or the order changes
  useEffect(() => {
    if ( _.isEmpty( fundingPaymentFromOrder ) === false ) {
      dispatch(rdx.setIsEditMode(true))
      dispatch(rdx.setFormattedAmount(fundingPaymentFromOrder.amount))
      dispatch(rdx.setFormattedAdminFee(fundingPaymentFromOrder.adminFee))
      dispatch(rdx.setFormattedGapAmount(fundingPaymentFromOrder.gapAmount))
      dispatch(rdx.setFormattedFundingReserveAmount(fundingPaymentFromOrder.fundingReserveAmount))
      dispatch(rdx.setFormattedNettedPayoffAmount(fundingPaymentFromOrder.nettedPayoffAmount))
    }
  }, [ isOpen, fundingPaymentFromOrder,dispatch ])

  const handleSave = fundingPaymentData => {
    if ( fundingPaymentData.id ) {
      dispatch(rdx.updateFundingPayment(orderId, fundingPaymentData.id, fundingPaymentData))
    } else {
      dispatch(rdx.saveNewFundingPayment(orderId, fundingPaymentData))
    }
  }

  //Will show validation errors
  const validateForm = () => {

    const REQUIRED_NON_EMPTY_FUNDINGPAYMENT_FIELDS = [ 'status', 'amount', 'dateReceived', 'paymentMethodId', 'documentNumber'  ]

    const currentFundingPaymentData =
      _.defaults(_.cloneDeep(editedFundingPayment), fundingPaymentFromOrder)

    let isValid = true
    REQUIRED_NON_EMPTY_FUNDINGPAYMENT_FIELDS.forEach( field => {
      if ( _.isNil( currentFundingPaymentData[ field ] ) ) {
        isValid = false
        dispatch(rdx.showEditedFundingPaymentError(field))
      }
    })

    dispatch(rdx.setIsValidForSubmit(isValid))

    return isValid
  }

  const onFormSubmit = async e => {

    e.preventDefault();

    //Will show validation errors if not already present...
    const isValidForm = validateForm()

    if ( isValidForm ) {

      //Clone the edited fundingPayment to avoid modifying values from redux
      const editedFundingPaymentObj = _.cloneDeep(editedFundingPayment)

      /* Choose keys from the editedFundingPayment, then from the fundingPayment on the order, then defaults */
      const fundingPaymentData =
        _.defaults({}, editedFundingPaymentObj, fundingPaymentFromOrder, FUNDINGPAYMENT_EMPTY_DEFAULTS)

      delete fundingPaymentData.paymentMethod;

      handleSave(fundingPaymentData);
      return;

      // if ( saved ) {
      //   history.push(`/order/${ orderId }`);
      // }
    }

    history.push(`/order/${ orderId }`);
  }

  const onCloseModal = () => {
      dispatch(rdx.setHasUnsavedChanges(false))
      dispatch(rdx.setLastClosedTimestamp())
      dispatch(rdx.setIsOpen(false))
      dispatch(rdx.resetFundingPaymentModal())
    // }
  }

  //Helper for showing and removing errors and updating the fields value...
  const DEFAULT_FIELD_VALIDATOR = v => _.isEmpty( v ) === false

  const handleFundingPaymentFieldChange = ( field, value, validate=DEFAULT_FIELD_VALIDATOR ) => {
    dispatch(rdx.setEditedFundingPayment(field, value))

    const isValidField = validate(value)

    if ( !isValidField && !editedFundingPaymentErrors[ field ] ) {
      dispatch(rdx.showEditedFundingPaymentError(field))
    } else if ( isValidField && editedFundingPaymentErrors[ field ]) {
      dispatch(rdx.hideEditedFundingPaymentError(field))
    }
    dispatch(rdx.setShowSuccessFeedback(false))
    dispatch(rdx.setHasUnsavedChanges(true))
  }

  const onAmountChange = ( formattedValue, value ) => {

    dispatch(rdx.setFormattedAmount(formattedValue))

    const validator = value => {
      return value !== '0' && _.isEmpty(value) === false
    }

    handleFundingPaymentFieldChange('amount', value, validator)
    dispatch(rdx.setShowSuccessFeedback(false))
  }

  const onAdminFeeChange = ( formattedValue, value ) => {

    dispatch(rdx.setFormattedAdminFee(formattedValue))

    const validator = value => {
      return value !== '0' && _.isEmpty(value) === false
    }

    handleFundingPaymentFieldChange('adminFee', value, validator)
    dispatch(rdx.setShowSuccessFeedback(false))
  }

  const onGapAmountChange = ( formattedValue, value ) => {

    dispatch(rdx.setFormattedGapAmount(formattedValue))

    const validator = value => {
      return value !== '0' && _.isEmpty(value) === false
    }

    handleFundingPaymentFieldChange('gapAmount', value, validator)
    dispatch(rdx.setShowSuccessFeedback(false))
  }

  const onFundingReserveAmountChange = ( formattedValue, value ) => {

    dispatch(rdx.setFormattedFundingReserveAmount(formattedValue))

    const validator = value => {
      return value !== '0' && _.isEmpty(value) === false
    }

    handleFundingPaymentFieldChange('fundingReserveAmount', value, validator)
    dispatch(rdx.setShowSuccessFeedback(false))
  }

  const onNettedPayoffAmountChange = ( formattedValue, value ) => {

    dispatch(rdx.setFormattedNettedPayoffAmount(formattedValue))

    const validator = value => {
      return value !== '0' && _.isEmpty(value) === false
    }

    handleFundingPaymentFieldChange('nettedPayoffAmount', value, validator)
    dispatch(rdx.setShowSuccessFeedback(false))
  }

  const onStatusChange = ( event, value ) => {
    handleFundingPaymentFieldChange('status', value)
    dispatch(rdx.setShowSuccessFeedback(false))
  }

  const onStatusInputChange = ( event, newInputValue ) => {
    dispatch(rdx.setStatusInputValue(newInputValue))

    if ( _.isEmpty( newInputValue ) && _.isNil( statusInputValueError ) ) {
      dispatch(rdx.setStatusInputValueError(true))

    } else if ( _.isEmpty( newInputValue ) === false && statusInputValueError ) {
      dispatch(rdx.setStatusInputValueError(null))
    }
    dispatch(rdx.setShowSuccessFeedback(false))
  }

  const onPaymentMethodChange = ( event, value ) => {
    handleFundingPaymentFieldChange('paymentMethodId', value?.id)
    dispatch(rdx.setShowSuccessFeedback(false))
  }

  const onDocumentNumberChange = e => {
    handleFundingPaymentFieldChange('documentNumber', e.target.value)
    dispatch(rdx.setShowSuccessFeedback(false))
  }

  const onDateReceivedChange = e => {
    handleFundingPaymentFieldChange('dateReceived', e.target.value)
    dispatch(rdx.setShowSuccessFeedback(false))
  }

  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {

    if(paymentMethods && paymentMethods.length === 0){
      axios.get('/payment-method').then( res => {
        setPaymentMethods(res.data)
      })
    }

  },[paymentMethods, setPaymentMethods, axios]);

  //Keys will be applied from left to right
  const editedOrDefaultFundingPayment =
    _.defaults({}, editedFundingPayment, fundingPaymentFromOrder, FUNDINGPAYMENT_EMPTY_DEFAULTS)

  return (
    <FundingPaymentModal
      editedOrDefaultFundingPayment={ editedOrDefaultFundingPayment }
      editedFundingPaymentErrors={ editedFundingPaymentErrors }
      fundingPaymentId={ fundingPaymentId }
      formattedAmount={ formattedAmount }
      formattedAdminFee={ formattedAdminFee }
      formattedGapAmount={ formattedGapAmount }
      formattedFundingReserveAmount={ formattedFundingReserveAmount }
      formattedNettedPayoffAmount={ formattedNettedPayoffAmount }
      isEditMode={ isEditMode }
      isOpen={ isOpen }
      onDocumentNumberChange={ onDocumentNumberChange }
      onDateReceivedChange={ onDateReceivedChange }
      onAmountChange={ onAmountChange }
      onAdminFeeChange={ onAdminFeeChange }
      onGapAmountChange={ onGapAmountChange }
      onFundingReserveAmountChange={ onFundingReserveAmountChange }
      onNettedPayoffAmountChange={ onNettedPayoffAmountChange }
      onCloseModal={ onCloseModal }
      onFormSubmit={ onFormSubmit }
      onPaymentMethodChange={ onPaymentMethodChange }
      onStatusInputChange={ onStatusInputChange }
      onStatusChange={ onStatusChange }
      isValidForSubmit={ isValidForSubmit }
      showSuccessFeedback={ showSuccessFeedback }
      statusInputValue={ statusInputValue }
      statusInputValueError={ statusInputValueError }
      hasUnsavedChanges={ hasUnsavedChanges }
      paymentMethods={ paymentMethods }
    />
  )
}

const {
  useFundingPaymentFocusId,
  useEditedFundingPayment,
  useFormattedAmount,
  useFormattedAdminFee,
  useFormattedGapAmount,
  useFormattedFundingReserveAmount,
  useFormattedNettedPayoffAmount,
  useEditedFundingPaymentErrors,
  // useIsOpen,
  useOpenFundingPaymentModal,
  useIsEditMode,
  useIsValidForSubmit,
  // useLastClosedTimestamp,
  useShowSuccessFeedback,
  // useSelectedFundingPaymentId,
  useStatusInputValue,
  useStatusInputValueError,
  useHasUnsavedChanges
} = rdx

export default FundingPaymentModalContainer

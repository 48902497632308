
export const TECPowerOfAttorneyGlobalAutofill = (documentValues, order, property=null, value=null, location) => {
    let newValues = documentValues;

    if(property ==='customer.name'){
      if(order.customer && order.customer.type.toString().toLowerCase() === 'entity'){
        let finalName = value.toUpperCase();
        newValues.representative_name = finalName;
      }else{
        newValues.name = value.toUpperCase();
        if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner && cosigner.name){
            newValues.name += " AND "+cosigner.name.toUpperCase();
          }
        }
      }
  }
    if(property ==='customer.dba'){
          newValues.representative_name = value.toUpperCase();
  }
    if(property ==='customer.cosigner[0]'){
      newValues.name = order.customer.name.toUpperCase();
        if(value!==''){
          newValues.name += " AND "+value.toUpperCase();
        }
      }
    if(property ==='location'){
        if(value.state === "CA"){
          newValues.attorney_name = "TEC OF CALIFORNIA, INC.";
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
        }else{
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
          newValues.attorney_name = "TEC EQUIPMENT, INC.";
        }
        newValues.location_address = value.address+" "+value.city+" "+location.state+" "+value.zip;
        newValues.location_phone = value.phone;
        newValues.location_email = value.email;
    }
    if(property !== null && value !== null){
      return newValues;
  }

  if(order){
    if(order.customer && order.customer.type.toString().toLowerCase() === 'entity'){
      var finalName = order.customer.name.toUpperCase();
      // if(order.customer.dba){
      //   finalName = finalName + " dba " + order.customer.dba.toUpperCase()
      // }

      newValues.representative_name = finalName;
    }else{
      newValues.name = order.customer.name.toUpperCase();
      if(order.customer.dba){
        newValues.representative_name = order?.customer?.dba?.toUpperCase();
      } 
      if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          newValues.name += " AND "+cosigner.name.toUpperCase();
        }
      }
    }
  }

  if(location){
    if(location.state === "CA"){
      newValues.attorney_name = "TEC OF CALIFORNIA, INC.";
    }else{
      newValues.attorney_name = "TEC EQUIPMENT, INC.";
    }
  }
  
  // Get Location Address & Autofill Address
  if(location !== undefined){
    newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
  }

  if(location && location.phone){
    newValues.location_phone = location.phone;
  }
  
  if(location && location.email){
    newValues.location_email = location.email;
  }

  if(location){
    if(location.state === "CA"){
      newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
    }else{
      newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
    }
  }

  if(document && document.documentTemplate && document.documentTemplate.slug){
    if(document.documentTemplate.slug === "tec-poa"){
      newValues.toggle_notary = true;
    }else{
      newValues.toggle_notary = false;
    }
  }

  return newValues
}

export const TECPowerOfAttorneyAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null, location=null, order=null, autofillProductDetails=true) => {
  var newValues = documentValues;

  if(property === 'year' && value !== null){
    newValues.make =(value.toString().replace('"', '').replace('"', '')).trim()+" "+(selectedProduct.make).trim().toUpperCase()
  }

  if(property === 'make' && value !== null){
    newValues.make =(selectedProduct.year.replace('"', '').replace('"', '')).trim()+" "+(value).toString().trim().toUpperCase()
  }

  if(property === 'vehicleType' && value !== null){
      newValues.vehicle_type = (value).trim().toUpperCase();
  }

  if(property !== null && value !== null){
      return newValues;
  }

  newValues.make = (selectedProduct.year.replace('"', '').replace('"', '')).trim()+" "+(selectedProduct.make).trim().toUpperCase();
  if(selectedProduct.vehicleType){
    newValues.vehicle_type = (selectedProduct.vehicleType).trim().toUpperCase();
  }

  if(autofillProductDetails){
    if(selectedProduct.vin){
      newValues.vin = (selectedProduct.vin).trim().toUpperCase();
    }  
  }
  
  return newValues
}

export const TECPowerOfAttorneyAutofillTradeDetails = (selectedTradein, documentValues, property=null, value= null)=>{
  var newValues = documentValues;

  if(property === 'tradein.year' && value !== null){
    newValues.make =(value.toString().replace('"', '').replace('"', '')).trim()+" "+(selectedTradein.make).trim().toUpperCase()
  }

  if(property === 'tradein.make' && value !== null){
    newValues.make =(selectedTradein.year.replace('"', '').replace('"', '')).trim()+" "+(value).toString().trim().toUpperCase()
  }

  if(property === 'tradein.vin' && value !== null){
    newValues.vin = value.trim().toUpperCase();
  }

  if(property !== null && value !== null){
      return newValues;
  }

  newValues.make = (selectedTradein.year.replace('"', '').replace('"', '')).trim()+" "+(selectedTradein.make).trim().toUpperCase();
  if(selectedTradein.vin){
    newValues.vin = (selectedTradein.vin).trim().toUpperCase();
  }  
  return newValues;

}
export const TECBillOfSaleGlobalAutofill  = (documentValues, order, property=null, value=null, location, locations=null, salesReps=null, autofillFirstProduct=true) => {

    var newValues = documentValues;

    if(property?.includes('customer') || property === null){

      if(order && order.customer && order.customer.name){
        newValues.purchaser_line_1 = order.customer.name;
    
        if(order.customer.dba){
          newValues.purchaser_line_1 += " dba "+order.customer.dba;
        }
    
        if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner && cosigner.name){
            newValues.purchaser_line_2 = cosigner.name;
          }
        }
      }
    
      if(order && order.customer && order.customer.deliveryAddress){
        var addressLine = '';
        if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
          addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
        }else{
          addressLine = order.customer.deliveryAddress.addressLine1;
        }
        newValues.purchaser_line_3 = addressLine;
      }
  
      if(order && order.customer && order.customer.deliveryAddress){
        newValues.purchaser_line_4 = (order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
      }

    }

    if(property?.includes('location') || property === null){

      if(location !== undefined){
        if(location.state === "CA"){
          newValues.dynamic_text_1 = "TEC of California, Inc. dba Tec Equipment located at "+location.address+", "+location.city+", "+location.state+", "+location.zip+", being owner of aforementioned motor vehicle, hereby sells and assigns all its rights, title and interest in said certificate of title and motor vehicle to:";  
          newValues.dynamic_text_2 = "TEC OF CALIFORNIA, INC., CALIFORNIA DEALER LICENSE NO. 31422";  
        }else{
          newValues.dynamic_text_1 = "TEC Equipment, Inc. located at "+location.address+", "+location.city+", "+location.state+", "+location.zip+", being owner of aforementioned motor vehicle, hereby sells and assigns all its rights, title and interest in said certificate of title and motor vehicle to:";
        }
        if(location.state === "OR"){
          newValues.dynamic_text_2 = "TEC EQUIPMENT, INC., OREGON DEALER LICENSE NO. 555";  
        }
        if(location.state === "WA"){
          newValues.dynamic_text_2 = "TEC EQUIPMENT, INC., WASHINGTON DEALER LICENSE NO. 7936";  
        }
        if(location.state === "NV"){
          newValues.dynamic_text_2 = "TEC EQUIPMENT, INC., NEVADA DEALER LICENSE NO. 29622";  
        }
        if(location.state === "AZ"){
          newValues.dynamic_text_2 = "TEC EQUIPMENT, INC., ARIZONA DEALER LICENSE NO. L00014432";  
        }
      }
  
      // Get Location Address & Autofill Address
      if(location !== undefined){
        newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
      }
    
      if(location && location.phone){
        newValues.location_phone = location.phone;
      }
      
      if(location && location.email){
        newValues.location_email = location.email;
      }
  
      if(location){
        if(location.state === "CA"){
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
        }else{
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
        }
      }

    }
  
    if(autofillFirstProduct || property === null){
  
      var foundAssignedProduct = null;
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
        foundAssignedProduct = order.products.find((product) => product.id === document.assignedObjectId);
      }else if(document.assignedObjectType && document.assignedObjectType === 'order'){
        foundAssignedProduct = order.products[0];
      }
  
      TECBillOfSaleAutofillProductDetails(foundAssignedProduct, false);
    }

    return newValues;
}

export const TECBillOfSaleAutofillProductDetails  = (selectedProduct, documentValues, property=null, value=null, autofillProductDetails=true) => {
    var newValues = documentValues;

    if(selectedProduct){
      
      if(property?.includes('year') || property === null){
        newValues.year = (selectedProduct.year).trim();
      }

      if(property?.includes('make') || property === null){
        newValues.make = (selectedProduct.make).trim().toString().toUpperCase();
      }

      if(property?.includes('vin') || property === null){
        newValues.serial_number = (selectedProduct.vin).trim().toUpperCase();
      }

      if(property?.includes('shipTo') || property === null){

        if(selectedProduct.shipTo){
          newValues.sold_to_line_1 = selectedProduct.shipTo.name;
          newValues.sold_to_line_3 = selectedProduct.shipTo.addressLine1;
          if(selectedProduct.shipTo.addressLine2){
            newValues.sold_to_line_3 = selectedProduct.shipTo.addressLine1+" "+selectedProduct.shipTo.addressLine2;
          }
          newValues.sold_to_line_4 = selectedProduct.shipTo.city+", "+selectedProduct.shipTo.state+" "+selectedProduct.shipTo.zip;
        }

      }

      if(property?.includes('soldTo') || property === null){

        if(selectedProduct.soldTo){
          newValues.purchaser_line_1 = selectedProduct.soldTo.name;
          newValues.purchaser_line_3 = selectedProduct.soldTo.addressLine1;
          if(selectedProduct.soldTo.addressLine2){
            newValues.purchaser_line_3 = selectedProduct.soldTo.addressLine1+" "+selectedProduct.soldTo.addressLine2;
          }
          newValues.purchaser_line_4 = selectedProduct.soldTo.city+", "+selectedProduct.soldTo.state+" "+selectedProduct.soldTo.zip;
        }

      }

      if(property?.includes('fundingDetail') || property?.includes('fundingMethod') || property === null){
    
        if(selectedProduct.fundingDetail){
          if(selectedProduct.fundingDetail.fundingMethod === "tec-financing" || selectedProduct.fundingDetail.fundingMethod === "finance-only" || selectedProduct.fundingDetail.fundingMethod === "direct-deal"){
            if(selectedProduct.fundingDetail.finalizedLendingOption){
              newValues.lienholder_line_1 = selectedProduct.fundingDetail.finalizedLendingOption.bankName;
              newValues.lienholder_line_2 = selectedProduct.fundingDetail.finalizedLendingOption.lienAddress;
              newValues.lienholder_line_3 = selectedProduct.fundingDetail.finalizedLendingOption.lienCity+", "+selectedProduct.fundingDetail.finalizedLendingOption.lienState+" "+selectedProduct.fundingDetail.finalizedLendingOption.lienZip;
            }
          }
      
          if(selectedProduct.fundingDetail.fundingMethod === "cash-with-lien"){
            if(selectedProduct.fundingDetail.cashLienHolder){
              newValues.lienholder_line_1 = selectedProduct.fundingDetail.cashLienHolder.bankName;
              newValues.lienholder_line_2 = selectedProduct.fundingDetail.cashLienHolder.addressLine1+" "+(selectedProduct.fundingDetail.cashLienHolder.addressLine2 ? selectedProduct.fundingDetail.cashLienHolder.addressLine2 : "");
              newValues.lienholder_line_3 = selectedProduct.fundingDetail.cashLienHolder.city+", "+selectedProduct.fundingDetail.cashLienHolder.state+" "+selectedProduct.fundingDetail.cashLienHolder.zip;
            }
          }
      
        }

      }
    
    }

    return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {
  if(payload.serial_number){
    payload.serial_number = payload.serial_number.toString().toUpperCase();
  }
  return payload;
}
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  useParams
} from "react-router-dom";
import { useFetch, recordFrontEndError } from "../../../../../../../utlis/api";
import moment from 'moment'
import { Autocomplete, TextField } from '@mui/material';
import {getWeightedTradeCredit, getWeightedTradeAllowance, getSalesTaxTotal, vehicleTaxableAmountUpgradeAdminWeighted, calculateProductSubtotal, nonVehicleTaxableAmount} from "../../../../../../../utlis/productCalculations";
import { useOrder } from "../../../../../../../redux/orders/order"
import { removeChars, formatter, formatUpperCase } from "../../../../../../../utlis/formatters";
import { DocumentHandleSubmit } from "../DocumentAutofill/DocumentHelpers"
import { TECInsuranceRequestGlobalAutofill, TECInsuranceRequestAutofillProductDetails, preProcessPayload } from "../DocumentAutofill/TECInsuranceRequest"

const TECInsuranceRequest = ({document_id, location, getDocuments, passedDocumentSlug, locations, setDocumentFocus}) => {

  const documentSlug = "tec-insurance-request";
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // App Logic & Data //
  let { id } = useParams();

  const appData = useSelector((state) => state?.appData);

  const order = useOrder();

  const axios = useFetch()
  const [documentUrl, setdocumentUrl] = useState("");
  
  const [document, setDocument] = useState(undefined);

  const [previousDocumentId, setPreviousDocumentId] = useState(0);
  const [showMaxProductAlert,setShowMaxProductAlert]=useState(false);

  // Data Structure & Init //

  var initialDocumentStructure = {
    location_address: undefined, // Header Location Details / System Autofill
    location_phone: undefined, // Header Location Details / System Autofill
    location_email: undefined, // Header Location Details / System Autofill
    tec_footer_label: undefined, // Footer / System Autofill
    pg_no: undefined, // Footer / System Autofill
    date: undefined,
    to: undefined,
    from: undefined,
    requirements_line_1: undefined,
    requirements_line_2: undefined,
    requirements_line_3: undefined,
    completed_email: undefined,
    customer_name: undefined,
    product_year_line_1: undefined,
    product_make_line_1: undefined,
    product_model_line_1: undefined,
    product_vin_line_1: undefined,
    product_total_price_line_1: undefined,
    product_make_line_2: undefined,
    product_model_line_2: undefined,
    product_vin_line_2: undefined,
    product_total_price_line_2: undefined,
    product_year_line_2: undefined,
    product_year_line_3: undefined,
    product_make_line_3: undefined,
    product_model_line_3: undefined,
    product_vin_line_3: undefined,
    product_total_price_line_3: undefined,
    product_year_line_4: undefined,
    product_make_line_4: undefined,
    product_model_line_4: undefined,
    product_vin_line_4: undefined,
    product_total_price_line_4: undefined,
    product_year_line_5: undefined,
    product_make_line_5: undefined,
    product_model_line_5: undefined,
    product_vin_line_5: undefined,
    product_total_price_line_5: undefined,
    product_year_line_6: undefined,
    product_make_line_6: undefined,
    product_model_line_6: undefined,
    product_vin_line_6: undefined,
    product_total_price_line_6: undefined,
    product_year_line_7: undefined,
    product_make_line_7: undefined,
    product_model_line_7: undefined,
    product_vin_line_7: undefined,
    product_total_price_line_7: undefined
  }

  const removeFormatting = e =>{
    const {name,value}=e.target;
    setDocumentValues({...documentValues,[name]:removeChars(value)});
  }

  const [documentValues, setDocumentValues] = useState(initialDocumentStructure);

  const moneyFields = useMemo(()=>{return["product_total_price_line_1"]},[]);
  const phoneFields = useMemo(()=>{return[""]},[]);
  const upperCaseFields = useMemo(()=>{return["vin", "model"]},[]);
  const checkboxes = useMemo(()=>{return[""]},[]);
  // const radios = [""];
  
  
  // X1.1- Current General On Change
  const handleDocumentValuesChange = e =>{
    const {name,value}=e.target;

    if(moneyFields.indexOf(name) >= 0){
      // To eliminate text being added
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(phoneFields.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:removeChars(value)});
    }else if(upperCaseFields.indexOf(name) >= 0){
      const savedPos = e.target.selectionStart;
      setDocumentValues({...documentValues,[name]:formatUpperCase(value)});
      setTimeout(() => {
        e.target.setSelectionRange(savedPos, savedPos);
      }, 0)
    }else if(checkboxes.indexOf(name) >= 0){
      setDocumentValues({...documentValues,[name]:(e.target.checked)});
    }else{
      setDocumentValues({...documentValues,[name]:value});
    }
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }
  
  // const [documentLoaded, setDocumentLoaded] = useState(false);  
  const loadDocument = useCallback(async (documentId) => {
    if(documentId && documentId != null && documentId !== undefined){
      
      try {
        let res = await axios.get(`/order/${id}/document/${documentId}`)
        if(res?.data.documentTemplate.slug === documentSlug){
          setDocument(res?.data)
        }
      } catch (err) {
        recordFrontEndError('TECInsuranceRequest.jsx', 'Error loading document')
      }
    }
},[axios, id])
  
const [previewLoaded, setPreviewLoaded] = useState(false);
const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if(document_id && document_id !== previousDocumentId && passedDocumentSlug && passedDocumentSlug === documentSlug){
      setdocumentUrl("");
      setPreviousDocumentId(document_id);
      // setDocumentLoaded(true);
      loadDocument(document_id);
    }
  },[ document_id, previousDocumentId, passedDocumentSlug, documentSlug, loadDocument ])

  // if(document && document.payload && documentLoaded == false){
  //   setDocumentValues(JSON.parse(document.payload));
  //   setDocumentLoaded(true);
  // }

  // const [documentStream, setDocumentStream] = useState("");
  // const [documentUrl, setdocumentUrl] = useState("");
  const [foundProducts,setFoundProducts] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([]);
  // const [productVal, setproductVal] = useState(undefined);
  // const [inputProductVal, setinputProductVal] = useState(undefined);
  const [productOptions, setproductOptions] = useState(undefined);
  // const [productToggleAutoFill, setProductToggleAutoFill] = useState(false);

  // const [tradeinVal, setTradeinVal] = useState(undefined);
  // const [selectedTradein, setselectedTradein] = useState(undefined);
  // const [inputTradeinVal, setinputTradeinVal] = useState(undefined);
  const [tradeinOptions, setTradeinOptions] = useState(undefined);
  // const [tradeinToggleAutoFill, setTradeinToggleAutoFill] = useState(false);
  const [allEmails, setAllEmails] = useState([])


  // const clearRows = () => {
  //   let newDocCopy = documentValues;
  //   for(var row = 1; row <= 7; row++){
  //     newDocCopy['product_year_line_'+(row)] = null; 
  //     newDocCopy['product_make_line_'+(row)] = null; 
  //     newDocCopy['product_model_line_'+(row)] = null; 
  //     newDocCopy['product_vin_line_'+(row)] = null; 
  //     newDocCopy['product_total_price_line_'+(row)] = null; 
  //   }
  //   setDocumentValues(newDocCopy);
  // }

  const updateEmail =(v)=>{
    let docCopy= {...documentValues}
    docCopy.completed_email=v
    setDocumentValues(docCopy)
  }
  
  const handleStateUpdate=selectedArray=>{
    let newDocCopy = documentValues;    
    setSelectedProducts(selectedArray);

    // Clear all Rows
    selectedProducts.forEach((product,i)=>{ 
      let newLineNumber = (i+1);
      newDocCopy['product_year_line_'+(newLineNumber)] = null; 
      newDocCopy['product_make_line_'+(newLineNumber)] = null; 
      newDocCopy['product_model_line_'+(newLineNumber)] = null; 
      newDocCopy['product_vin_line_'+(newLineNumber)] = null; 
      newDocCopy['product_total_price_line_'+(newLineNumber)] = null; 
      newDocCopy['product_stock_number_line'+(newLineNumber)] = null; 
    });

    // Set all Rows
    selectedArray.forEach((product,i)=>{
      autofillOnSelect(product, i);
    });
    setDocumentValues(newDocCopy) 
  }

  // Dropdowns //

if(order && order.products && order.products.length > 0 && productOptions === undefined){
  let getProductOptions = [];
  order.products.map(product => {
    let str = `${product.stockNumber.toString().toUpperCase()}`;
    let obj = {id: product.id, name: str}
    getProductOptions.push(obj);
    return null;
  })
  setproductOptions(getProductOptions);
}

if(order && order.tradeins && order.tradeins.length > 0 && tradeinOptions === undefined){
  let getTradeinOptions = [];
  order.tradeins.map(tradein => {
    let str = `${tradein.year} ${tradein.make} ${tradein.model.toString().toUpperCase()}`;
    let obj = {id: tradein.id, name: str}
    getTradeinOptions.push(obj);
    return null;
  })
  setTradeinOptions(getTradeinOptions);
}

 // Autofill //
  



const GlobalAutofill = useCallback(async () => {
  let newValues = TECInsuranceRequestGlobalAutofill(documentValues, order, null, null, location);
  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

    // var newValues = documentValues;

    

    // if(order && order.customer && order.customer.name){
    //   newValues.customer_name = order.customer.name;
    //   if(order.customer.dba){
    //     newValues.customer_name += " dba "+order.customer.dba;
    //   }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
    //     let cosigner = order.customer.cosigners[0];
    //     if(cosigner && cosigner.name){
    //       newValues.customer_name += " AND "+cosigner.name;
    //     }
    //   }
    // }

    // Get Location Address & Autofill Address
    if(location !== undefined){
      newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
    }

    if(location && location.phone){
      newValues.location_phone = location.phone;
    }

    if(location && location.email){
      newValues.location_email = location.email;
    }

    if(location){
      if(location.state === "CA"){
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
      }else{
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
      }
    }

    // if(order && order.documentationDate && documentValues.date === undefined){
    //   newValues.date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    // }

    setDocumentValues(newValues);
    // setHasUnsavedChanges(true);
  },[ documentValues, order, location ]);

  // const preProcessPayload = useCallback((payload) => {
  //   // X1.2- Field Type Specific Preview Formatting
  //   Object.keys(payload).forEach(key => {
  //     if(moneyFields.indexOf(key) >= 0){
  //         payload[key] = formatMoneyValue(payload[key]);
  //     }else if(phoneFields.indexOf(key) >= 0){
  //         payload[key] = formatPhoneNumber(payload[key]);
  //     }else if(upperCaseFields.indexOf(key) >= 0){
  //         payload[key] = formatUpperCase(payload[key]);
  //     }
  //   });
  //   setDocumentValues(JSON.parse(JSON.stringify(payload)));
  //   Object.keys(payload).forEach(key => {
  //     if(checkboxes.indexOf(key) >= 0){
  //       payload[key] = payload[key] ? "yes": "no";
  //     }
  //   });

  //   // Data point formatting
  //   if(payload.date){
  //     payload.date = moment.utc(payload.date).format("MM/DD/YY");
  //   }

  //   return payload;
  // },[ checkboxes, moneyFields, phoneFields, upperCaseFields ]);


  const previewPayloadDocument = useCallback(async (documentValues) => {
    var payload = documentValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === null || payload[key] === undefined) {
        delete payload[key];
      }
    });
    
    if(document){
      var docCopy = JSON.parse(JSON.stringify(document));
      docCopy.payload =  JSON.parse(JSON.stringify(payload));
      docCopy.payload = preProcessPayload(docCopy.payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
      docCopy.payload =  JSON.stringify(docCopy.payload);
      delete docCopy.documentStatus;

      
      try {
        let response = await axios.post(`/document/${document.id}/preview`, docCopy, {responseType: 'blob' })
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
        setdocumentUrl(fileURL)
      } catch (err) {
        recordFrontEndError('TECInsuranceRequest.jsx', 'Error previewing document payload: ' + err)
      };  
    }return() => null
  },[axios, checkboxes, document, moneyFields, phoneFields, upperCaseFields])

const autofillOnSelect=useCallback((lastSelected, position)=>{
  var newValues = documentValues;
  try{
    if(lastSelected&& lastSelected.id){
      newValues['product_year_line_'+(position+1)] = lastSelected.year;
      newValues['product_make_line_'+(position+1)] = (lastSelected.make).trim();
      newValues['product_model_line_'+(position+1)] = (lastSelected.model).trim().toString().toUpperCase();
      newValues['product_vin_line_'+(position+1)] = (lastSelected.vin).trim().toString().toUpperCase();

      let productSubtotal = calculateProductSubtotal(order, lastSelected);
      let lineTotal = Number(productSubtotal);

      if(order?.customer?.isExemptSalesTax === false){
        let taxableAmount = vehicleTaxableAmountUpgradeAdminWeighted(lastSelected, order.customer, order.adminFee);

        let weightedTradeCredit = 0;
        let subTrade = false;
        if(lastSelected.registrationAddress?.state === "NV" || lastSelected.registrationAddress?.state === "AZ" || lastSelected.registrationAddress?.state === "WA"){
          weightedTradeCredit = getWeightedTradeAllowance(order, order.products, lastSelected, order.tradeins);
          subTrade = true;
        }else{
          weightedTradeCredit = getWeightedTradeCredit(order, order.products, lastSelected, order.tradeins);
        }


        let vehicleSalesTax = getSalesTaxTotal(order, taxableAmount, weightedTradeCredit, subTrade);
        lineTotal += Number(vehicleSalesTax);
        if(order?.customer?.deliveryAddress?.state === "WA" || lastSelected?.registrationAddress?.state === "WA"){
          let nonVehicleTaxableAmountValue = nonVehicleTaxableAmount(lastSelected, order.customer);
          let nonVehicleTotal = ((Number(nonVehicleTaxableAmountValue) * order.customer.nonVehicleSalesTaxPercent) / 100);
          lineTotal += Number(nonVehicleTotal);
        }
      }
      // newValues['product_total_price_line_'+(position+1)] = formatter.format(calculateProductSubtotal(order, lastSelected));

      newValues['product_total_price_line_'+(position+1)] = formatter.format(lineTotal);
      // newValues['product_stock_number_line'+(position+1)] = lastSelected.stockNumber;
    }
  }catch(e){}
        
  setDocumentValues(newValues);
  previewPayloadDocument(newValues)
  setHasUnsavedChanges(true)
},[ documentValues, previewPayloadDocument, order])

const AutofillProductDetails = useCallback( (selectedProduct) => {
  let newValues = TECInsuranceRequestAutofillProductDetails(selectedProduct, documentValues);

  if(newValues){
    setDocumentValues(newValues);
    setHasUnsavedChanges(true);
    setSaveSuccess(false);
  }

  // var newValues = documentValues;
  // if(selectedProduct.fundingDetail && selectedProduct.fundingDetail.fundingMethod && (selectedProduct.fundingDetail.fundingMethod === "tec-financing" || selectedProduct.fundingDetail.fundingMethod === "finance-only" || selectedProduct.fundingDetail.fundingMethod === "direct-deal") && selectedProduct.fundingDetail.finalizedLendingOption){
  //   newValues.requirements_line_1 = selectedProduct.fundingDetail.finalizedLendingOption.lossPayee ? selectedProduct.fundingDetail.finalizedLendingOption.lossPayee : "";
  //   newValues.requirements_line_2 = selectedProduct.fundingDetail.finalizedLendingOption.lossPayeeAddress ? selectedProduct.fundingDetail.finalizedLendingOption.lossPayeeAddress : "";
  //   newValues.requirements_line_3 = (selectedProduct.fundingDetail.finalizedLendingOption.lossPayeeCity ?? "")+", "+(selectedProduct.fundingDetail.finalizedLendingOption.lossPayeeState ?? "")+" "+(selectedProduct.fundingDetail.finalizedLendingOption.lossPayeeZip ?? "");
  // }

  // if(selectedProduct.fundingDetail && selectedProduct.fundingDetail.fundingMethod && selectedProduct.fundingDetail.fundingMethod === "cash-with-lien" && selectedProduct.fundingDetail.cashLienHolder){
  //   newValues.requirements_line_1 = (selectedProduct.fundingDetail.cashLienHolder.bankName ?? "");
  //   newValues.requirements_line_2 = (selectedProduct.fundingDetail.cashLienHolder.addressLine1 ?? "")+" "+(selectedProduct.fundingDetail.cashLienHolder.addressLine2 ?? "");
  //   newValues.requirements_line_3 = (selectedProduct.fundingDetail.cashLienHolder.city ?? "")+", "+(selectedProduct.fundingDetail.cashLienHolder.state ?? "")+" "+(selectedProduct.fundingDetail.cashLienHolder.zip ?? "");
  // }
  
  // setDocumentValues(newValues);
  // // previewPayloadDocument(documentValues);
  // setHasUnsavedChanges(true);
},[ documentValues ]);


//
useEffect(() => {

  if(document && document.payload && !dataLoaded){
  var existingPayload = false;
  var documentPayload = JSON.parse(document.payload);


  if(documentPayload && Object.keys(documentPayload).length > 0){
    existingPayload = true;
    if(documentPayload.date){
      documentPayload.date = moment.utc(documentPayload.date).format("YYYY-MM-DD");
    }
      
      setDocumentValues(documentPayload);
  }

  

  if(!existingPayload){
    GlobalAutofill()
    if( order && order.products.length > 0 ){
      setSelectedProducts(order.products);
      if(order.products.length > 7){
        let newDocCopy = documentValues;
        newDocCopy['product_year_line_1'] = null; 
        newDocCopy['product_make_line_1'] = null; 
        newDocCopy['product_model_line_1'] = "See Schedule A"; 
        newDocCopy['product_vin_line_1'] = null; 
        newDocCopy['product_total_price_line_1'] = null; 
        setDocumentValues(newDocCopy);
      }else{
        order.products.forEach((product,i)=>{
          autofillOnSelect(product,i);
        });
      }
        if(order.products.length > 0){
          AutofillProductDetails(order.products[0]);
        }
    }  

    // previewPayloadDocument(documentValues);
  }else{
    if(document && document.itemIds){
      var itemIds = document.itemIds.split(',');
    
        var previousSelectedProducts=itemIds.map((product, i)=>{
      
        var foundProduct = order.products.find((product) => product.id === Number(itemIds[i]));
        if (foundProduct){
        return foundProduct}
        return null
      })
      setSelectedProducts(previousSelectedProducts)
    }

  }
if(document.assignedObjectType && document.assignedObjectType === 'order'){
    if(order.products.length > 0){
      setFoundProducts(order.products)
    }
  }
  setDataLoaded(true);
  }else if(document && document.payload && dataLoaded && !previewLoaded){
    setPreviewLoaded(true);
    previewPayloadDocument(documentValues);
  }

},[ documentValues, foundProducts, document, dataLoaded, AutofillProductDetails, GlobalAutofill, order, previewPayloadDocument, selectedProducts, autofillOnSelect, previewLoaded ])
    // function preProcessPayload(payload){
    // function streamDocument(){
    //   axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
    //     .then(response => {
    //       const file = new Blob(
    //         [response.data], 
    //         {type: 'application/pdf'});
    //         const fileURL = URL.createObjectURL(file);
    //         // window.open(fileURL);
    //       setdocumentUrl(fileURL)
    //     }).catch(err => {
    //     })
    // }

  // Document BREAD + Preview // TODO: Move to Redux

  // function saveDocument(){
  //   var payload = documentValues;
  //   Object.keys(payload).forEach(key => {
  //     if (payload[key] === null || payload[key] === undefined) {
  //       delete payload[key];
  //     }
  //   });
  //   document.payload = JSON.stringify(payload);
  //   delete document.documentStatus;
  //   delete document.documentType;
  //   return axios.put(`/document/${document.id}/prepare`, document);
  // }

  // function regenerateDocument(){
  //   if(document !== undefined && document != null){
  //     return axios.post(`/document/${document.id}/generate`);
  //   }
  //   return null
  // }

  // function previewDocument(){
  //   axios.get(`/document/${document.id}/stream`, {responseType: 'blob'})
  //     .then(response => {
  //       const file = new Blob(
  //         [response.data], 
  //         {type: 'application/pdf'});
  //         const fileURL = URL.createObjectURL(file);
  //         // window.open(fileURL);
  //       setDocumentStream(file)
  //       setdocumentUrl(fileURL)
  //     }).catch(err => {
  //       setDocumentStream([])
  //     })
  // }

  // Perform an .GET for the initial render
  // useEffect(()=>{
  //   if (documentUrl === "" && document) {
  //     previewDocument();
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[documentUrl, document]);

  // Component Events

  const updatePreview = e => {
    e.preventDefault();
    // updateFormatting(e);
    previewPayloadDocument(documentValues);
  }


  const handleSubmit = async event => {
    event.preventDefault();

    let payload = JSON.parse(JSON.stringify(documentValues));

    payload = preProcessPayload(payload, checkboxes, moneyFields, phoneFields, upperCaseFields);
  

    // const payload = JSON.parse(JSON.stringify(documentValues));
    var docCopy = document;
    // docCopy.payload = payload;
    // docCopy.payload = preProcessPayload(docCopy.payload);
    // docCopy.payload =  JSON.stringify(docCopy.payload);
    // delete docCopy.documentStatus;
    
    if(selectedProducts && selectedProducts.length > 0){
      var itemIds = [];
      selectedProducts.forEach(product => {
      itemIds.push(product.id);
      })
      docCopy.itemIds = `${itemIds}`;
    }

    // let additionalData = JSON.stringify(dropdownData);

    DocumentHandleSubmit(docCopy, payload, null, () => {
      getDocuments();
      setSaveSuccess(true);
      setHasUnsavedChanges(false);
    });

    // try {
    //   let res = await axios.put(`/document/${document.id}/prepare`, docCopy)
    //   if (res) {
    //     axios.post(`/document/${document.id}/generate`)
    //     getDocuments();
    //     setSaveSuccess(true);
    //     setHasUnsavedChanges(false);
    //   };
    // } catch (err) {
    //   recordFrontEndError('TECInsuranceRequest.jsx', 'Error saving document: ' + err)
    // };
    
  };

// Component Helpers //



  //Autofill Tradein Details After Selection
// if(selectedTradein !== undefined && tradeinToggleAutoFill){
//   var newValues = documentValues;
//   newValues.product_year_line_1 = (selectedTradein.year);
//   newValues.product_make_line_1 = (selectedTradein.make);
//   newValues.product_model_line_1 = (selectedTradein.model);
//   newValues.trade_body_type = (selectedTradein.seriesBodyType);
//   newValues.trade_serial_number = (selectedTradein.serialNumber);
//   newValues.balance_owner = (order.customer.name); //Need specific field for TRADE IN OWNER?
//   newValues.balance_owner_address = (order.customer.deliveryAddress.addressLine1);
//   newValues.amount = (selectedTradein.balanceOwed);
//   newValues.trade_allowance = (selectedTradein.tradeAllowanceAtTerms);
//   newValues.balance_owed = (selectedTradein.balanceOwed);
//   newValues.cash_refund = (selectedTradein.cashRefundToCustomer);
//   newValues.net_trade_allowance = (selectedTradein.finalTradeAllowance);
//   // newValues.cash_down_payment = (selectedTradein);

//   setDocumentValues(newValues);
//   setProductToggleAutoFill(false);
//   previewPayloadDocument(documentValues);
// }

useEffect(()=>{

  const fetchEmails = async ()=>{
    try{
      let regionalEmails = await axios.get('regional-emails')
      let financialEmails = await axios.get('finance-emails')
      let combinedEmails = regionalEmails.data.concat(financialEmails.data)
      setAllEmails(combinedEmails.filter((item, index) => {
        return combinedEmails.indexOf(item) === index
        }))  
        
    } catch (err) {
      recordFrontEndError('TECInsuranceRequest', 'Error fetching all emails: ' + err)
    }
  }

  if(appData?.activeModal === 'document-edit'){

    if(allEmails.length===0){
      fetchEmails()
    }

  }
},[axios, allEmails, appData?.activeModal])

// const [previousDocumentTemplateSlug, setPreviousDocumentTemplateSlug] = useState("");

// if(document && document.documentTemplate && document.documentTemplate.slug !== previousDocumentTemplateSlug){
//   setdocumentUrl("");
//   setPreviousDocumentTemplateSlug(document.documentTemplate.slug);
//   saveDocument().then(r => { 
//     regenerateDocument().then(res => {
//       previewDocument()
//     })
//   })
// }

const onCloseClick = e => {
  e.preventDefault()
  setHasUnsavedChanges(false);
  setSaveSuccess(false);
  setdocumentUrl("");
  setDocument(undefined);
  setPreviousDocumentId(0);
  setDocumentValues(initialDocumentStructure);
  // setDocumentLoaded(false);
  setDataLoaded(false);
  setSelectedProducts([]);
  setFoundProducts([]);
  setproductOptions(undefined);
  setTradeinOptions(undefined);
  setDocumentFocus(undefined);
}

const [hasClickedClose, setHasClickedClose] = useState(false);

const closeClicked = e => {
  if( hasUnsavedChanges ){
      setHasClickedClose(true);
  } else {
      onCloseClick(e);
  }
}

const preCloseSave = e => {
  handleSubmit(e)
  setHasClickedClose(false);
}

const preCloseClose = e => {
  setHasClickedClose(false);
  onCloseClick(e)
}

  return (
    <>
      <div
        className="modal fade"
        id="tec-insurance-request"
        tabIndex="-1"
        aria-labelledby="Finance-Dashboard-Settings-Modal"
        aria-hidden="true"
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" style={{ maxWidth: '95vw'}}>
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5
                className="modal-title text-white mt-2"
                id="exampleModalLabel"
              >
                TEC Insurance Request
              </h5>
              <br />
              { hasClickedClose ? (
                  <div className="d-flex">
                      <p className="m-1 pt-1 text-white">Save Changes?</p>
                      <button className="m-1 btn btn-sm btn-success" onClick={preCloseSave}>Yes</button>
                      <button className="m-1 btn btn-sm btn-danger" data-bs-dismiss="modal" onClick={preCloseClose}>No</button>
                  </div>
              ): hasUnsavedChanges ? (
              <button
                type="button"
                className="btn-close bg-white"
                aria-label="Close"
                // onClick={() => setdocumentUrl("")}
                onClick={closeClicked}
              ></button>
              ):(
              <button
                type="button"
                className="btn-close bg-white"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={preCloseClose}
              ></button>
              )}
            </div>
            <div className="modal-body" style={{height: '75vh', overflowY: 'clip'}}>
            <div className="container-fluid mt-0">
              <div className="row" style={{ height: '70vh'}}>
                <div className="col-md-6 mb-3" style={{ overflowY: 'scroll', height: 'inherit'}}>
                <div className="text-center">
                  <button 
                  type="button"
                  className="btn btn-primary mx-auto"
                  onClick={() => {
                    GlobalAutofill();
                    // AutofillProductDetails(assignedProduct);
                    previewPayloadDocument(documentValues);
                  }}>Reload Autofill Values</button>
                </div>
                <br />
                
                <hr/>
                  
                  <div className="row">
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Date</label>
                        <input
                          type="date"
                          className="form-control rounded-pill mt-1"
                          name="date"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.date ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6"></div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">To</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="to"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.to ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">From</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="from"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.from ?? '' }
                          />
                      </div>
                    </div>

                    <h5>Equipment</h5>
                    
                    <div className="d-flex justify-content-evenly">
                      <div className="mb-3 col-md-12 pt-4">
                        <Autocomplete
                        multiple
                        filterSelectedOptions
                          style={{ width: '100%'}}
                          value={ selectedProducts??[]}
                          onBlur={(e) => updatePreview(e)}
                          onChange={(event, newValue) => {
                            if(newValue.length>=8){
                              setShowMaxProductAlert(true)
                            }else{
                              setShowMaxProductAlert(false)
                              handleStateUpdate(newValue)
                            }
                          }}
                          options={foundProducts??null}
                          getOptionLabel={(product) => (product && product.stockNumber ? product.stockNumber : null)}
                          sx={{ width: 400 }}
                          renderInput={(params) => <TextField {...params} label="PRODUCT" />}
                          renderOption={(props, product) => {
                            return (
                              <li {...props} key={product.id}>
                                {product.stockNumber}
                              </li>
                            );
                          }
                        }
                        />
                      </div>
                    </div>
                    {/* </div> */}
                    {
                    showMaxProductAlert?(
                    <span style={{color:'red'}}>Maximum products selected must be 7 or less.</span>):null
                    }
                    <div>
                      { selectedProducts.length?(selectedProducts.map((product,i)=>{
                        return<>     
                        {/* <h5>Stock# {documentValues['product_stock_number_line'+(i+1)]}</h5>       */}
                    <div className="d-flex justify-content-evenly" key={i}>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Year</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name={`product_year_line_${i+1}`}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues['product_year_line_'+(i+1)] ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Make</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name={`product_make_line_${i+1}`}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues['product_make_line_'+(i+1)] ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Model</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name={`product_model_line_${i+1}`}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues['product_model_line_'+(i+1)] ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">VIN</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name={`product_vin_line_${i+1}`}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues['product_vin_line_'+(i+1)] ?? '' }
                          />
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Total Price</label>
                        <input
                          type="text"
                          onFocus={removeFormatting}
                          className="form-control rounded-pill mt-1"
                          name={`product_total_price_line_${i+1}`}
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues['product_total_price_line_'+(i+1)] ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6"></div>
                    </div><hr/></>})):<div>no products</div>}
                  </div>
                    
                </div>
                <hr></hr>
                <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Requirements Line 1</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="requirements_line_1"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.requirements_line_1 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Requirements Line 2</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="requirements_line_2"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.requirements_line_2 ?? '' }
                          />
                      </div>
                    </div> 
                     <div className="d-flex justify-content-evenly">
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Requirements Line 3</label>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="requirements_line_3"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.requirements_line_3 ?? '' }
                          />
                      </div>
                      <div className="p-2 col-md-6"></div>
                    </div>
                      <div className="p-2 col-md-6">
                        <label htmlFor="stock">Completed Email</label>
                        <Autocomplete
                          id="allEmails"
                          options={allEmails??[]}
                          sx={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} label="Select an email" />}
                          onChange={(e,v)=>updateEmail(v)}
                          value={documentValues.completed_email ?? '' }
                        />
                        <div style={{padding:'5px 0'}}/>
                        <input
                          type="text"
                          className="form-control rounded-pill mt-1"
                          name="completed_email"
                          onChange={handleDocumentValuesChange}
                          onBlur={(e) => updatePreview(e)}
                          value={ documentValues.completed_email ?? '' }
                          />
                    </div>
                </div>
                <div className="col-md-6" style={{ overflowY: 'scroll'}}>
                  <h5>Document Preview</h5>
                  <embed width="100%" height="100%" src={documentUrl !== undefined ? documentUrl : null} />
                </div>
              </div>           
            </div>
          </div>
          <div className="modal-footer">
            { saveSuccess ? (
              <span
                className="btn btn-success mx-auto"
              >
                Saved Document
              </span>
            ):(
              <button
              type="button"
              className="btn btn-primary mx-auto"
              // data-bs-dismiss="modal"
              onClick={handleSubmit}
              >
              Save
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default TECInsuranceRequest

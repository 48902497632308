
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const TECPayOffAuthorizationGlobalAutofill  = (documentValues, order, property=null, value=null, location) => {
    var newValues = documentValues;
   
    if(property ==='customer.name'){
      newValues.name = value;
      newValues.releaser_name = value;

      if(order.customer.dba!==''){
        newValues.name += " dba "+order.customer.dba;
        newValues.releaser_name += " dba "+order.customer.dba;
      } else if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name !== ''){
          newValues.releaser_name_2 = order.customer.name+" AND "+cosigner.name;
        }
      }
  }
    if(property ==='customer.dba'){
      newValues.name = order.customer.name;
      newValues.releaser_name = order.customer.name;
      if(value !==''){
        newValues.name += " dba "+value;
        newValues.releaser_name += " dba "+value;
      } else if(value==='' && order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          newValues.releaser_name_2 = order.customer.name + " AND "+cosigner.name;
        }
      }
  }
    if(property ==='customer.cosigner[0].name'){
      newValues.name = order.customer.name;
      newValues.releaser_name = order.customer.name;

      if(value !=='' && order.customer.dba===''){
          newValues.releaser_name_2 = order.customer.name + " AND "+value;
      } else if(order.customer.dba!==''){
        newValues.name += " dba "+order.customer.dba;
        newValues.releaser_name += " dba "+order.customer.dba;
      }
  }
    if(property ==='location'){
        newValues.location_address = value.address+" "+value.city+" "+value.state+" "+value.zip;
        newValues.location_phone = value.phone;
        newValues.location_email = value.email;
        if(value.state === "CA"){
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
        }else{
          newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
        }
  }
    if(property !== null && value !== null){
      return newValues;
  }
    if(order && order.customer && order.customer.name){
      newValues.name = order.customer.name;
      newValues.releaser_name = order.customer.name;

      if(order.customer.dba){
        newValues.name += " dba "+order.customer.dba;
        newValues.releaser_name += " dba "+order.customer.dba;
      } else if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner && cosigner.name){
          newValues.releaser_name_2 = order.customer.name + " AND "+cosigner.name;
        }
      }
    }
    
    if(location !== undefined){
      newValues.location_address = location.address+" "+location.city+" "+location.state+" "+location.zip;
    }
  
    if(location && location.phone){
      newValues.location_phone = location.phone;
    }
    
    if(location && location.email){
      newValues.location_email = location.email;
    }
  
    if(location){
      if(location.state === "CA"){
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC OF CALIFORNIA, INC. DOING BUSINESS AS TEC EQUIPMENT";
      }else{
        newValues.tec_footer_label = "TECEQUIPMENT.COM | TEC EQUIPMENT, INC.";
      }
    }

    return newValues
}

export const TECPayOffAuthorizationAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
    var newValues = documentValues;

    if(property === 'year' && value !== null){
      newValues.year = (value).toString().trim().replace(/['"]+/g, '');
      newValues.year_2 = (value).toString().trim().replace(/['"]+/g, '');
  }

  if(property === 'make' && value !== null){
      newValues.make = (value).toString().trim();
      newValues.make_2 = (value).toString().trim();
  }

  if(property === 'model' && value !== null){
      newValues.model = (value).toString().trim();
  }

  if(property !== null && value !== null){
      return newValues;
  }

  if(selectedProduct){
    newValues.year = (selectedProduct.year).trim().replace('"', '').replace('"', '');
    newValues.year_2 = (selectedProduct.year).trim().replace('"', '').replace('"', '');
    newValues.make = (selectedProduct.make).trim().toString().toUpperCase();
    newValues.make_2 = (selectedProduct.make).trim().toString().toUpperCase();
    newValues.model = (selectedProduct.model).trim().toString().toUpperCase();
    newValues.stock_number = (selectedProduct.stockNumber);
    // newValues.mileage = (selectedProduct.mileage);
    // newValues.serial_number = (selectedProduct.serialNumber);
    newValues.vin = (selectedProduct.vin).trim().toString().toUpperCase();
  }
    
    newValues.balance = "";
    newValues.to = "";
    newValues.serial_number = "";
    newValues.dol_client_number = "";
    
  return newValues
}
  
export const TECPayOffAuthorizationAutofillTradeDetails = (selectedTradein, documentValues, property=null, value= null)=>{
    var newValues = documentValues;

    if(property === 'tradein.year' && value !== null){
      newValues.year = (value).toString().trim().replace(/['"]+/g, '');
      newValues.year_2 = (value).toString().trim().replace(/['"]+/g, '');
    }

    if(property === 'tradein.make' && value !== null){
        newValues.make = (value).toString().toUpperCase();
        newValues.make_2 = (value).toString().toUpperCase();
    }

    if(property === 'tradein.model' && value !== null){
        newValues.model = (value).toString().toUpperCase();
    }


    if(property === 'tradein.balanceOwed' && value !== null){
      newValues.balance = formatter.format(value);
    }

    if(property === 'tradein.balanceOwedTo' && value !== null){
      newValues.to = ((value).toString().trim());
    }

    if(property === 'tradein.stockNumber' && value !== null){
      newValues.stock_number = (value).toString().trim();
    }

    if(property === 'tradein.serialNumber' && value !== null){
      newValues.serial_number = (value).toString().trim();
    }

    if(property === 'tradein.dolClientNumber' && value !== null){
      newValues.dol_client_number = (value).toString().trim();
    }

    if(property === 'tradein.vin' && value !== null){
      newValues.vin = (value).toString().trim();
    }

    if(property !== null && value !== null){
        return newValues;
    }

    if(selectedTradein){
      newValues.year = (selectedTradein.year).trim().replace('"', '').replace('"', '');
      newValues.year_2 = (selectedTradein.year).trim().replace('"', '').replace('"', '');
      newValues.make = (selectedTradein.make).trim().toString().toUpperCase();
      newValues.make_2 = (selectedTradein.make).trim().toString().toUpperCase();
      newValues.model = (selectedTradein.model).trim().toString().toUpperCase();
      newValues.stock_number = (selectedTradein.stockNumber);
      newValues.vin = (selectedTradein.vin).trim().toString().toUpperCase();
      
      if(selectedTradein.balanceOwed && selectedTradein.balanceOwed > 0){
        newValues.balance = selectedTradein.balanceOwed;
      }
      
      if(selectedTradein.balanceOwedTo){
        newValues.to = (selectedTradein.balanceOwedTo);
      }
      
      newValues.serial_number = selectedTradein.serialNumber;
      newValues.dol_client_number = selectedTradein.dolClientNumber;
    }

    return newValues
}
import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const WAWholeSaleGlobalAutofill = (documentValues, order, property=null, value=null, locations) => {
    var newValues = documentValues;

    if(property ==='customer.name'){
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      newValues.buyer_name = value;
          if(order.customer.cosigners && order.customer.cosigners.length > 0){
            let cosigner = order.customer.cosigners[0];
            if(cosigner && cosigner.name){
              newValues.buyer_name += " AND "+cosigner.name;
            }
          }
        }
        if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'tradein'){
          newValues.seller_name = value;
          if(order.customer.cosigners && order.customer.cosigners.length > 0){
            let cosigner = order.customer.cosigners[0];
            if(cosigner && cosigner.name){
              newValues.seller_name += " AND "+cosigner.name;
            }
          }
        }
        
  }
    if(property ==='customer.cosigners'){
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
        newValues.buyer_name = order.customer.name;
          if(value && value.length > 0){
            let cosigner = value[0];
            if(cosigner && cosigner.name){
              newValues.buyer_name += " AND "+cosigner.name;
            }
          }
        }
        if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'tradein'){
          newValues.seller_name = order.customer.name;
          if(value && value.length > 0){
            let cosigner = value[0];
            if(cosigner && cosigner.name){
              newValues.seller_name += " AND "+cosigner.name;
            }
          }
        }
  }
    if(property ==='customer.dba'){
      newValues.buyer_company_name = value;
  }
    if(property ==='customer.deliveryAddress.addressLine1'){
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
      newValues.buyer_address = value;
        if(order.customer.deliveryAddress.addressLine2 && order.customer.deliveryAddress.addressLine2 !== ""){
          newValues.buyer_address += " "+order.customer.deliveryAddress.addressLine2;
        }
      }
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'tradein'){
        newValues.seller_address = value;
        if(order.customer.deliveryAddress.addressLine2 && order.customer.deliveryAddress.addressLine2 !== ""){
          newValues.seller_address += " "+order.customer.deliveryAddress.addressLine2;
        }
      }
  }
    if(property ==='customer.deliveryAddress.addressLine2'){
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
            newValues.buyer_address = order.customer.deliveryAddress.addressLine1+ " "+value;
        }
        if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'tradein'){
            newValues.seller_address = order.customer.deliveryAddress.addressLine1+ " "+value;
        }
  }
    if(property ==='customer.deliveryAddress.city'){
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
        newValues.buyer_city = formatUpperCase(value);}
        if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'tradein'){
          newValues.seller_city = formatUpperCase(value);
        }
  }
    if(property ==='customer.deliveryAddress.state'){
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
          newValues.buyer_state = value;
        }
        if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'tradein'){
          newValues.seller_state = value;
        }
  }
    if(property ==='customer.deliveryAddress.zip'){
      if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){
          newValues.buyer_zip = value;
        }
        if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'tradein'){
          newValues.seller_zip = value;
        }
  }
    if(property ==='locations'){
      if(locations && locations.length > 0 && value){
        let foundLocation = locations.find(l => l.locationCode === value);
        if(foundLocation){
          newValues.buyer_address = foundLocation.address;
          newValues.buyer_city = formatUpperCase(foundLocation.city);
          newValues.buyer_state = foundLocation.state;
          newValues.buyer_zip = foundLocation.zip;
        }
    }
  }

    if(property !== null && value !== null){
      return newValues;
  }

    // TEC is Seller
    if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'product'){

      newValues.dealer_name = "TEC EQUIPMENT, INC.";
      newValues.seller_name = "TEC EQUIPMENT, INC.";
      if(locations && locations.length > 0 && order.tecLocationCode){
          let foundLocation = locations.find(l => l.locationCode === order.tecLocationCode);
          if(foundLocation){
            newValues.seller_address = foundLocation.address;
            newValues.seller_city = formatUpperCase(foundLocation.city);
            newValues.seller_state = foundLocation.state;
            newValues.seller_zip = foundLocation.zip;

            newValues.dealer_address = foundLocation.address;
            newValues.dealer_city = formatUpperCase(foundLocation.city);
            newValues.dealer_state = foundLocation.state;
            newValues.dealer_zip = foundLocation.zip;
          }
        }

        if(order?.customer){
          newValues.buyer_name = order.customer.name;
          if(order.customer.cosigners && order.customer.cosigners.length > 0){
            let cosigner = order.customer.cosigners[0];
            if(cosigner && cosigner.name){
              newValues.buyer_name += " AND "+cosigner.name;
            }
          }

          if(order?.customer?.dba){
            newValues.buyer_company_name = order?.customer?.dba;
          }

          newValues.buyer_address = order.customer.deliveryAddress.addressLine1;
          if(order.customer.deliveryAddress.addressLine2 && order.customer.deliveryAddress.addressLine2 !== ""){
            newValues.buyer_address += " "+order.customer.deliveryAddress.addressLine2;
          }
          newValues.buyer_city = formatUpperCase(order.customer.deliveryAddress.city);
          newValues.buyer_state = order.customer.deliveryAddress.state;
          newValues.buyer_zip = order.customer.deliveryAddress.zip;
        }

  }

  // Customer Is Seller
  if(document.assignedObjectType && document.assignedObjectId && document.assignedObjectType === 'tradein'){

    if(order?.customer){
      newValues.seller_name = order.customer.name;

      newValues.seller_address = order.customer.deliveryAddress.addressLine1;
      if(order.customer.deliveryAddress.addressLine2 && order.customer.deliveryAddress.addressLine2 !== ""){
        newValues.seller_address += " "+order.customer.deliveryAddress.addressLine2;
      }
      newValues.seller_city = formatUpperCase(order.customer.deliveryAddress.city);
      newValues.seller_state = order.customer.deliveryAddress.state;
      newValues.seller_zip = order.customer.deliveryAddress.zip;
    }

    newValues.buyer_name = "TEC EQUIPMENT, INC.";
    newValues.buyer_company_name = "TEC EQUIPMENT, INC.";
    if(locations && locations.length > 0 && order.tecLocationCode){
        let foundLocation = locations.find(l => l.locationCode === order.tecLocationCode);
        if(foundLocation){
          newValues.buyer_address = foundLocation.address;
          newValues.buyer_city = formatUpperCase(foundLocation.city);
          newValues.buyer_state = foundLocation.state;
          newValues.buyer_zip = foundLocation.zip;
        }
    }

  }

      return newValues
}

export const WAWholeSaleAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
    var newValues = documentValues;

    if(property === 'year' && value !== null){
      newValues.year = (value);
  }

  if(property === 'make' && value !== null){
      newValues.make = (value).toString().trim();
  }

  if(property === 'vehicleType' && value !== null){
      newValues.body_type = (value).trim().toUpperCase();
  }

  if(property === 'price' && value !== null){
    newValues.price = formatter.format(value);
  }

  if(property !== null && value !== null){
      return newValues;
  }

    newValues.year = (selectedProduct.year);
    newValues.make = (selectedProduct.make).trim();
    newValues.vin = (selectedProduct.vin.toUpperCase());
    newValues.body_type = (selectedProduct.vehicleType).trim().toUpperCase();
    newValues.price = formatter.format(selectedProduct.price);
    newValues.stock_number = selectedProduct.stockNumber;

    return newValues
}

export const WAWholeSaleAutofillTradeDetails = (selectedTradein, documentValues, property=null, value= null)=>{
    var newValues = documentValues;

    if(property === 'tradein.year' && value !== null){
      newValues.year = (value).toString().trim().replace(/['"]+/g, '');
    }

    if(property === 'tradein.make' && value !== null){
        newValues.make = (value).toString().trim();
    }

    if(property === 'tradein.seriesBodyType' && value !== null){
        newValues.body_type = (value).trim().toUpperCase();
    }

    if(property === 'tradein.price' && value !== null){
      newValues.price = formatter.format(value);
    }
    
    if(property === 'tradein.vin' && value !== null){
      newValues.vin = value.toUpperCase();
    }

    if(property !== null && value !== null){
        return newValues;
    }

    newValues.year = (selectedTradein.year);
    newValues.make = (selectedTradein.make).trim();
    newValues.vin = (selectedTradein.vin.toUpperCase());
    newValues.body_type = (selectedTradein.SeriesBodyType).trim().toUpperCase();
    newValues.price = formatter.format(selectedTradein.FinalTradeAllowance);

    return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields, radios, dateFields) => {

  // Process radios for document preview
  radios?.forEach(radio => {
    radio?.options.forEach(option => {
      if(option.value !== "clear"){ //Don't add "None" as a property.
        payload[option.value] = "no";
        if(payload[radio.property] === option.value){
          payload[option.value] = "yes";
        }
      }
    });
  });

  // Process money, phone and uppercase fields for document preview
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  

  // Process checkboxes for document preview
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });
  
  // Format Date Fields for the document
  dateFields?.forEach(datefield => {
    if(payload[datefield]){
      payload[datefield] = moment.utc(payload[datefield]).format("MM/DD/YY");
    }
  });


  return payload;
}
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { callMsGraph } from "../../graphUser";
import { loginRequest } from "../../authConfig";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material//Typography";
import Sidebar from "./components/Sidebar";
import HelpMenu from "./components/HelpIcon";
import NotificationsMenu from "./components/NotificationBell";
import { calculateTotalFinancedAmount } from "../../utlis/orderCalculations";
import { useOrder } from "../../redux/orders/order"
import { useFetch } from "../../utlis/api";
import { formatter, capitalizeFirstLetter, wordSplit } from "../../utlis/formatters";

const NavbarOrder = () => {
  const axios             = useFetch()
  const role              = useSelector((state) => state?.role.role);
  const me                = useSelector((state) => state?.me);
  // const appData = useSelector((state) => state?.appData);

  const order = useOrder();
  let customerName = order?.customer?.name
  let cashLienHolder = order?.products?.length>0? order?.products[0]?.fundingDetail?.cashLienHolder?.bankName : null
  let finalizedHolder = order?.products?.length>0?  order?.products[0]?.fundingDetail?.finalizedLendingOption?.bankName : null
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  // const [me, setMe] = useState(undefined);
  
  useEffect(() => {
    if(graphData == null){
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
      }).then((response) => {
        callMsGraph(response.accessToken).then(response => setGraphData(response));
      });
    }
  }, [ accounts, axios, graphData, instance, me]);
  
  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "red",
      }}
    >
      <AppBar
        id="navbar-order-appbar"
        sx={{
          backgroundColor: "white",
          color: "black",
        }}
        elevation={0}
        position="static"
      >
        <Toolbar id="navbar-order-toolbar" >
          <Sidebar id="navbar-order-sidebar" />
          <Typography
            id="navbar-order-title"
            sx={{
              flexGrow: 1,
              fontWeight: "bold",
            }}
            variant="h6"
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                pt: 1,
                mr: 5,
                pr: 5,
              }}
            >
              <Box>
              {/* <p>{ appData?.activeModal ?? '' }</p> */}

                <Typography variant="h4">Sales Order</Typography>
                {!order || !order.orderStatus || !order.orderStatus.name ? (
                <Typography variant="h6" sx={{ color: "rgb(20, 159, 42)"}}>--</Typography>
                ) : (
                  <Typography variant="h6" sx={{ color: "rgb(20, 159, 42)"}}>{ order.orderStatus.name }</Typography>
                )}
              </Box>
              <Box
                sx={{
                  p: 1,
                  px: 3,
                  pt: 1,
                  color: "#fff",
                  backgroundColor: "rgb(20, 159, 42)",
                }}
              >
                {!order || !order.customer || !order.customer.name ? (
                <Typography variant="h5">--</Typography>
                ) : (
                  <Typography variant="h5">{ wordSplit(customerName, 15) }</Typography>
                )}
                <Typography variant="h6">Customer</Typography>
              </Box>
              <Box sx={{ pt: 1 }}>
                <Typography variant="h5">{ formatter.format(calculateTotalFinancedAmount(order)) }</Typography>
                <Typography variant="h6" sx={{ color: "rgb(20, 159, 42)"}}>Financed</Typography>
              </Box>
              <Box sx={{ pt: 1 }}>
              { order.products && order.products.length > 0 && order.products[0].fundingDetail  && (order.products[0].fundingDetail.fundingMethod === "tec-financing" || order.products[0].fundingDetail.fundingMethod === "finance-only" || order.products[0].fundingDetail.fundingMethod === "direct-deal") && order.products[0].fundingDetail.finalizedLendingOption ? (
              <Typography variant="h5">{ wordSplit(finalizedHolder, 15) }</Typography>
              ): order.products && order.products.length > 0 && order.products[0].fundingDetail  && order.products[0].fundingDetail.fundingMethod === "cash-with-lien" && order.products[0].fundingDetail.cashLienHolder ? (
                <Typography variant="h5">{ wordSplit(cashLienHolder, 15) }</Typography>
              ):(
                <Typography variant="h5">--</Typography>
              )}
              <Typography variant="h6" sx={{ color: "rgb(20, 159, 42)"}}>Lender</Typography>
              </Box>
            </Box>
          </Typography>


          <Box
            id="navbar-menu-cluster"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              id="navbar-notifications-menu"
              sx={{ mx: 1 }}
            >
              <NotificationsMenu userIndex={ (me && me.id ? me.id : 0) }/>
            </Box>
            <Box
              id="navbar-help-menu"
              sx={{ mx: 1 }}
            >
              <HelpMenu />
            </Box>
            <Box 
              id="navbar-user-info-area"
              sx={{ display: "flex", alignContent: "center" }}
            >
              {/* TODO: Future Development: possibly make this Avatar/Name Cluster 
              into a popover/menu that would link to logout and other functions */}

              <Box
                id="navbar-user-info-area-avatar"
                sx={{ mx: 1, my: "auto" }}
              >
                {graphData ? <Avatar alt={graphData.displayName} src="/static/images/avatar/1.jpg" /> : <Avatar />}
              </Box>
              <Box
                id="navbar-user-info-area-card"
                sx={{ mx: 1, my: "auto" }}
              >
                <Typography
                  id="navbar-user-info-area-user-name"
                  variant="subtitle1"
                >
                  {graphData ? graphData.displayName : "Loading..."}
                </Typography>
                <Typography
                  id="navbar-user-info-area-job-title"
                  variant="subtitle2">
                  {capitalizeFirstLetter(role)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default NavbarOrder;

import * as api from './api.js'
import { removeChars } from "./formatters.js";
import {
  catCalc,
  matchAndGroup,
  createProductGroup,
} from "./productCalculations.js";
import { enableTireTax } from "./fastLogicUtils.js";


const getTotalSubjectToFet = (product) => {
  const {upgrades} = product;

  let total = 0;
  if(upgrades && upgrades.length) {
    upgrades.forEach(upgrade => {
      if(upgrade.subjectToFet === true){
        total += upgrade.price;
      }
    });
  }
  return Number(product.price) + total;
}

export async function newProductDetails(orderId, product, _callback){
  let applyFet = false;
    let fetTotal = 0;
    let catTaxTotal = 0;
    const catTaxPercent = 0.38;
    const luxTaxPercent = 0.5;

    if(product?.type.toUpperCase() === 'NEW'){
      applyFet = product.type==='NEW' ? true : false;
      const totalSubjectToFet = getTotalSubjectToFet(product);
      fetTotal = Number(totalSubjectToFet) * Number(0.12);
      fetTotal = fetTotal.toFixed(2);
    }

    
    if(product?.registrationAddress?.state === "OR"){
      catTaxTotal = catCalc(product, catTaxPercent);
      catTaxTotal = catTaxTotal.toFixed(2);
    }

    let productSaver = {
        type : product?.type ?? "",
        stockNumber : product?.stockNumber,
        year : removeChars(product?.year),
        make : product?.make ?? "",
        model : product?.model ?? "",
        vin : product?.vin ?? "",
        stateRegistered : 'XX',
        tecSalesDepartmentCode : product?.tecSalesDepartmentCode ?? null,
        price : removeChars(product?.price),
        productNotes : product?.productNotes ?? "",
        vehicleType : product?.vehicleType ?? "",
        LineItemFee:{
          applyFet: applyFet,
          catTaxPercent: catTaxPercent,
          catTaxTotal: catTaxTotal,
          luxuryTaxPercent: luxTaxPercent,
          fetTotal: Number(fetTotal)
        },
        fobAddress:{},
        registrationAddress:{},
        fundingDetail:{
          cashLienHolder:{},
        }
      }
      
    //   let productId;

    
    var saveProductDetails = new Promise(async (resolve) => {
      
      let newProductId = null;

      await api.saveNewProduct(orderId, productSaver).then(res => {
        newProductId = res?.data.productId;

        if(newProductId){
          if(product?.additions?.length>0){
            try{
              for(const addition of product.additions){
                if(addition?.serialNumber!=='' && addition?.type!==''){
                  const {invalid, ...additionObj}= addition
                  api.addProductAddition(orderId, newProductId, additionObj);
                }else {
                  // invalidAdditionArray.push({...addition, invalid:true})
                }
              }
            } catch (err){ 
            }
            
          }
          
          if(product?.upgrades?.length>0){
            try{
              for(const upgrade of product.upgrades){
                if(upgrade?.serialNumber!=='' && upgrade?.type!=='' && upgrade?.price!==undefined && upgrade?.stockNumber!==''){
                  const {invalid, ...upgradeObj}= upgrade
                  api.addProductUpgrade(orderId, newProductId, upgradeObj);
                }else {
                  // invalidUpgradeArray.push({upgrade, invalid:true})
                }
              }
            } catch (err) {
            }
          }

          if(product?.deposits?.length>0){
            try{
              for (const deposit of product.deposits){
                if(deposit?.amount!==undefined && deposit?.status!=='' && deposit.paymentMethodId !== null && deposit.documentNumber!== ''){
                  const {invalid, ...depositObj} = deposit
                  depositObj.amount = removeChars(depositObj.amount);
                  depositObj.appliedAmount = removeChars(depositObj.appliedAmount);
                  api.addProductDeposit(orderId, newProductId, depositObj);
                } else {
                  // invalidDepositArray.push({...deposit, invalid:true})
                }
              }
            } catch (err) {
            }
          }

          // if extended warranty fields are complete, add extended warranty
          if(product?.extendedWarranty?.price !== undefined) {
            try{
              let unformattedPrice = removeChars(product.extendedWarranty.price);
              const extendedWarrantyObj = {
                productId: newProductId,
                provider:product.extendedWarranty.provider,
                price: unformattedPrice,
                description: product.extendedWarranty.description.toUpperCase() ?? ""
              }
              api.addExtendedWarranty(extendedWarrantyObj);
            } catch (err) {
            }
            
          }
          // if gap insurance fields are complete, add gap insurance
          if(product?.gapInsurance?.amount !== undefined ) {
            try {
              let unformattedPrice = removeChars(product.gapInsurance.amount);
              const gapInsuranceObj = {
                productId: newProductId, 
                amount: unformattedPrice,
                provider: product.gapInsurance.provider
              }
              api.addGapInsurance(gapInsuranceObj);
            } catch (err) {
            }
          }
        }
      });
        // get new product from API and use product info to set setProductFocus reducer
        //const newProductId = res.data.productId;
        resolve(newProductId);
    });

    saveProductDetails.then( (newProduct) => {
      _callback(newProduct);
    });

    //   if ( product.id !== undefined ){
    //     try{
    //       let res = await api.getProduct(`/order/${id}/product/${product.id}`)
    //       if (res) {
    //         try{
    //           dispatch(setProduct({...res.data, 
    //             additions:[...invalidAdditionArray, ...res?.data?.additions],
    //             upgrades:[ ...invalidUpgradeArray, ...res?.data?.upgrades],
    //             deposits:[ ...invalidUpgradeArray, ...res?.data?.deposits],
    //           }))
    //         } catch (err){
    //         }
    //         //   setSaveSuccess(true);
    //         //   setHasUnsavedChanges(false);
    //         //   setIsLoading(false)
    //       }
    //       // dispatch(setProductFocus(res.data))
    //       // setnewAdditions([]);
    //       // updateNewAdditions([]);
    //       // setnewUpgrades([]);
    //       // updateNewUpgrades([]);
    //       // setnewDeposits([]);
    //       // updateNewDeposits([]);
    //       // resetAdditionsFunc.current(res.data.additions);
    //       // resetUpgradeFunc.current();
    //       // resetUpgradesFunc.current(res.data.upgrades);
    //       // resetDepositsFunc.current(res.data.deposits);
    //       // resetDepositFunc();
          
    //     }catch(err)  {
    //     };
    //   } 
}

export async function updateProductDetails(e, order, product, _callback) {
  e.preventDefault();



    let productSaver = {
        id : product.id,
        type : product.type,
        stockNumber : product.stockNumber,
        year : removeChars(product.year),
        make :  product.make,
        model : product.model,
        productNotes : product.productNotes,
        vin : product.vin,
        stateRegistered: "XX",
        registrationAddress : product.registrationAddress,
        fobAddress : product.fobAddress,
        tecSalesDepartmentCode : product.tecSalesDepartmentCode,
        vehicleType : product.vehicleType,
        lineItemId: product.lineItemId,
        price : product.price,
        shipTo : product.shipTo,
        soldTo : product.soldTo ,
        createdAt: product.createdAt,
        fundingDetail: product.fundingDetail,
        productGroupId: product.productGroupId,
        extendedWarranty: product.extendedWarranty
    }

  var saveProductDetails = new Promise(async (resolve) => {
    await api
      .saveProductDetails(order.id, product.id, productSaver, true)
      .then(async (res) => {
        // Additions
        if (product?.additions?.length > 0) {
          for (const addition of product.additions) {
            if (addition?.serialNumber !== "" && addition?.type !== "") {
              const { invalid, ...additionObj } = addition;

              if (!additionObj.id) {
                await api.addProductAddition(order.id, product.id, additionObj);
              } else if (additionObj.id) {
                await api.updateProductAddition(
                  order.id,
                  product.id,
                  additionObj.id,
                  additionObj,
                );
              }
            }
          }
        }

            // Upgrades
            if(product?.upgrades?.length>0){
                for(const upgrade of product.upgrades){
                    if(upgrade?.serialNumber !== '' && upgrade?.type !== '' && upgrade?.price !== undefined && upgrade?.stockNumber !== ''){

                        var {invalid, ...upgradeObj} = upgrade;

                        upgradeObj.price = Number(removeChars(upgradeObj.price));

              if (!upgradeObj.id) {
                await api.addProductUpgrade(order.id, product.id, upgradeObj);
              } else if (upgradeObj.id) {
                await api.updateProductUpgrade(
                  order.id,
                  product.id,
                  upgradeObj.id,
                  upgradeObj,
                );
              }
            }
          }
        }

        // Deposits
        if (product?.deposits?.length > 0) {
          for (const deposit of product.deposits) {
            if (
              deposit?.amount !== undefined &&
              deposit?.status !== "" &&
              deposit.documentNumber !== ""
            ) {
              var { ...depositObj } = deposit;
              depositObj.amount = removeChars(depositObj.amount);
              depositObj.appliedAmount = removeChars(depositObj.appliedAmount);
              if (!depositObj?.id) {
                if (depositObj.id === null) {
                  delete depositObj.id;
                }
                await api.addProductDeposit(order.id, product.id, depositObj);
              } else if (depositObj?.id) {
                await api.updateProductDeposit(
                  order.id,
                  product.id,
                  depositObj.id,
                  depositObj,
                );
              }
            }
          }
        }
      });

        // Extended Warranty
        if(product && product?.extendedWarranty && product?.extendedWarranty?.id) {
            let extendedWarranty = product.extendedWarranty;
            // Edit extended warranty
            if(extendedWarranty) {
            let unformattedPrice = removeChars(extendedWarranty.price);
            const extendedWarrantyObj = {
                id: product.extendedWarranty.id,
                productId: product?.id?? null,
                price: unformattedPrice ? unformattedPrice : extendedWarranty.price,
                description: extendedWarranty?.description?.toUpperCase()??null,
                provider: extendedWarranty?.provider?.toUpperCase()?? null,
                isTaxable: extendedWarranty?.isTaxable ?? false
            }
            await api.updateExtendedWarranty(extendedWarrantyObj);
            } 
        }else if(product && product?.extendedWarranty && !product?.extendedWarranty?.id) {
            // Add extended warranty
            let unformattedPrice = 0;
            if(product?.extendedWarranty?.price !== undefined) {
              unformattedPrice = removeChars(product.extendedWarranty.price);
            }
            const extendedWarrantyObj = {
                productId: product?.id ?? null,
                price: unformattedPrice ? unformattedPrice : product?.extendedWarranty?.price,
                description: product.extendedWarranty?.description?.toUpperCase()??null,
                provider: product.extendedWarranty?.provider?.toUpperCase()?? null,
                isTaxable: product.extendedWarranty?.isTaxable ?? false
            }

            await api.addExtendedWarranty(extendedWarrantyObj);
        }

        // GAP Insurance
        if(product && product.gapInsurance && product.gapInsurance.id && product?.gapInsurance?.provider !== undefined && product?.gapInsurance?.amount !== undefined) {
            // Edit gap insurance
            const gapInsuranceObj = {
              id: product?.gapInsurance?.id,
              productId: product?.id,
              amount: removeChars(product?.gapInsurance?.amount),
              provider: product?.gapInsurance?.provider?.toUpperCase()
            }
            await api.editGapInsurance(gapInsuranceObj);
        } else if(product && product?.gapInsurance && !product?.gapInsurance?.id){
            // Add gap insurance
            // if(product?.gapInsurance?.provider !== undefined && product?.gapInsurance?.amount !== undefined) {
              const gapInsuranceObj = {
                  productId: product.id,
              }
              if(product?.gapInsurance?.amount !== undefined) {
                gapInsuranceObj.amount = removeChars(product.gapInsurance.amount);
              }

              if(product?.gapInsurance?.provider !== undefined) {
                gapInsuranceObj.provider = product.gapInsurance.provider;
              }

            await api.addGapInsurance(gapInsuranceObj);
            // }
        }

        resolve();
    });

    // Wait for promise to complete, then call the callback
    saveProductDetails.then( () => {
        _callback();
    });
}

export async function newProductFob(order, product, _callback) {
  let fobAddressObj = {
    addressLine1: product?.fobAddress?.addressLine1?.toUpperCase() ?? "",
    addressLine2: product?.fobAddress?.addressLine2?.toUpperCase() ?? "",
    city: product?.fobAddress?.city?.toUpperCase() ?? "",
    state: product?.fobAddress?.state ?? "",
    zip: product?.fobAddress?.zip ?? "",
  };

  var saveProduct = new Promise(async (resolve) => {
    await api.addFob(order.id, product.id, fobAddressObj);
    resolve();
  });

    saveProduct.then( () => {
        _callback();
    });
}

export async function updateProductFob(e, order, product, _callback) {
  e.preventDefault();
  
  let fobAddressObj = {
      addressLine1: product?.fobAddress?.addressLine1?.toUpperCase()??'',
      addressLine2: product?.fobAddress?.addressLine2?.toUpperCase()??'',
      city: product?.fobAddress?.city?.toUpperCase()??'',
      state: product?.fobAddress?.state ?? '',
      zip:  product?.fobAddress?.zip ?? '',
    }

  var saveProduct = new Promise(async (resolve) => {
    await api.updateFob(order.id, product.id, fobAddressObj);
    resolve();
  });

  saveProduct.then( () => {
      _callback();
  });
}

export async function newProductRegistration(order, product, _callback) {
  let registrationAddressObj = {
      addressLine1: product?.registrationAddress?.addressLine1?.toUpperCase()??'',
      addressLine2: product?.registrationAddress?.addressLine2?.toUpperCase()??'',
      city: product?.registrationAddress?.city?.toUpperCase()??'',
      state: product?.registrationAddress?.state ?? '',
      zip:  product?.registrationAddress?.zip ?? '',
      county:  product?.registrationAddress?.county ?? '',
    }

  var saveProduct = new Promise(async (resolve) => {
    await api.addProductRegistration(
      order.id,
      product.id,
      registrationAddressObj,
    );
    resolve();
  });

  saveProduct.then( () => {
      _callback();
  });
}

export async function updateProductRegistration(e, order, product, _callback){
  e.preventDefault();
  
  let registrationAddressObj = {
      addressLine1: product?.registrationAddress?.addressLine1?.toUpperCase()??'',
      addressLine2: product?.registrationAddress?.addressLine2?.toUpperCase()??'',
      city: product?.registrationAddress?.city?.toUpperCase()??'',
      state: product?.registrationAddress?.state ?? '',
      zip:  product?.registrationAddress?.zip ?? '',
      county:  product?.registrationAddress?.county ?? '',
    }

  var saveProduct = new Promise(async (resolve) => {
      await api.updateProductRegistration(order.id, product.id, registrationAddressObj);
      resolve();
  });

  saveProduct.then( () => {
      _callback();
  });
}

// export async function newProductShipTo(orderId, product, _callback){

//     let shipToObj = {
//         name:  product?.shipTo?.name?.toUpperCase() ?? '',
//         addressLine1: product?.shipTo?.addressLine1?.toUpperCase() ?? '',
//         addressLine2: product?.shipTo?.addressLine2?.toUpperCase() ?? '',
//         city: product?.shipTo?.city?.toUpperCase() ?? '',
//         state: product?.shipTo?.state ?? '',
//         zip: product?.shipTo?.zip ?? '',
//       }
  
//       // let soldToObj = {
//       //   name: product?.soldTo?.name?.toUpperCase() ?? '',
//       //   addressLine1: product?.soldTo?.addressLine1?.toUpperCase() ?? '',
//       //   addressLine2: product?.soldTo?.addressLine2?.toUpperCase() ?? '',
//       //   city: product?.soldTo?.city?.toUpperCase() ?? '',
//       //   state: product?.soldTo?.state ?? '',
//       //   zip: product?.soldTo?.zip  ?? '',
//       // }


//     var saveProduct = new Promise(async (resolve) => {
//         if( !product?.shipTo?.id ){
//             await api.addShipTo(orderId, product.id, shipToObj);
//         }
//         // if( !product?.soldTo?.id ){
//         //   await api.addSoldTo(orderId, product.id, soldToObj);
//         // }

//         resolve();
//     });

//     saveProduct.then( () => {
//         _callback();
//     });
// }

// export async function newProductSoldTo(orderId, product, _callback){

//   // let shipToObj = {
//   //     name:  product?.shipTo?.name.toUpperCase() ?? '',
//   //     addressLine1: product?.shipTo?.addressLine1.toUpperCase() ?? '',
//   //     addressLine2: product?.shipTo?.addressLine2.toUpperCase() ?? '',
//   //     city: product?.shipTo?.city.toUpperCase() ?? '',
//   //     state: product?.shipTo?.state ?? '',
//   //     zip: product?.shipTo?.zip ?? '',
//   //   }

//     let soldToObj = {
//       name: product?.soldTo?.name?.toUpperCase() ?? '',
//       addressLine1: product?.soldTo?.addressLine1?.toUpperCase() ?? '',
//       addressLine2: product?.soldTo?.addressLine2?.toUpperCase() ?? '',
//       city: product?.soldTo?.city?.toUpperCase() ?? '',
//       state: product?.soldTo?.state ?? '',
//       zip: product?.soldTo?.zip  ?? '',
//     }


//   var saveProduct = new Promise(async (resolve) => {
//       // if( !product?.shipTo?.id ){
//       //     await api.addShipTo(orderId, product.id, shipToObj);
//       // }
//       if( !product?.soldTo?.id ){
//         await api.addSoldTo(orderId, product.id, soldToObj);
//       }

//       resolve();
//   });

//   saveProduct.then( () => {
//       _callback();
//   });
// }

export async function updateProductShipSold(e, order, product, _callback) {
  let shipToAddressObj = {
      name:  product?.shipTo?.name.toUpperCase() ?? '',
      addressLine1: product?.shipTo?.addressLine1.toUpperCase() ?? '',
      addressLine2: product?.shipTo?.addressLine2.toUpperCase() ?? '',
      city: product?.shipTo?.city.toUpperCase() ?? '',
      state: product?.shipTo?.state ?? '',
      zip: product?.shipTo?.zip ?? '',
  }

  // Sold To Address Object
  let soldToAddressObj = {
      name: product?.soldTo?.name?.toUpperCase() ?? '',
      addressLine1: product?.soldTo?.addressLine1?.toUpperCase() ?? '',
      addressLine2: product?.soldTo?.addressLine2?.toUpperCase() ?? '',
      city: product?.soldTo?.city?.toUpperCase() ?? '',
      state: product?.soldTo?.state ?? '',
      zip: product?.soldTo?.zip  ?? '',
  }

  // var saveProduct = new Promise(async (resolve) => {

    // console.log('updateProductShipSold', orderId)

  if (!product?.soldTo?.id && soldToAddressObj.addressLine1 !== "") {
    await api.addSoldTo(order.id, product.id, soldToAddressObj);
  } else if (soldToAddressObj.addressLine1 !== "") {
    await api.updateSoldTo(order.id, product.id, soldToAddressObj);
  } else {
    await api.deleteSoldTo(order.id, product.id);
  }

  if (!product?.shipTo?.id && shipToAddressObj.addressLine1 !== "") {
    await api.addShipTo(order.id, product.id, shipToAddressObj);
  } else if (shipToAddressObj.addressLine1 !== "") {
    await api.updateShipTo(order.id, product.id, shipToAddressObj);
  } else {
    await api.deleteShipTo(order.id, product.id);
  }

    // resolve();
  // });

  // saveProduct.then( () => {
    _callback();
  // });


}


// export async function updateProductShipTo(e, orderId, product, _callback){

//     // Ship To Address Object
//     let shipToAddressObj = {
//         name:  product?.shipTo?.name.toUpperCase() ?? '',
//         addressLine1: product?.shipTo?.addressLine1.toUpperCase() ?? '',
//         addressLine2: product?.shipTo?.addressLine2.toUpperCase() ?? '',
//         city: product?.shipTo?.city.toUpperCase() ?? '',
//         state: product?.shipTo?.state ?? '',
//         zip: product?.shipTo?.zip ?? '',
//     }

//     // // Sold To Address Object
//     // let soldToAddressObj = {
//     //     name: product?.soldTo?.name?.toUpperCase() ?? '',
//     //     addressLine1: product?.soldTo?.addressLine1?.toUpperCase() ?? '',
//     //     addressLine2: product?.soldTo?.addressLine2?.toUpperCase() ?? '',
//     //     city: product?.soldTo?.city?.toUpperCase() ?? '',
//     //     state: product?.soldTo?.state ?? '',
//     //     zip: product?.soldTo?.zip  ?? '',
//     // }

//     var saveProduct = new Promise(async (resolve) => {
//         if( product?.shipTo ){
//             await api.updateShipTo(orderId, product.id, shipToAddressObj);
//         }
//         // if( product?.soldTo ){
//         //     await api.updateSoldTo(orderId, product.id, soldToAddressObj);
//         // }
//         resolve();
//     });

//     saveProduct.then( () => {
//         _callback();
//     });
// }

// export async function updateProductSoldTo(e, orderId, product, _callback){

//   // Sold To Address Object
//   let soldToAddressObj = {
//       name: product?.soldTo?.name?.toUpperCase() ?? '',
//       addressLine1: product?.soldTo?.addressLine1?.toUpperCase() ?? '',
//       addressLine2: product?.soldTo?.addressLine2?.toUpperCase() ?? '',
//       city: product?.soldTo?.city?.toUpperCase() ?? '',
//       state: product?.soldTo?.state ?? '',
//       zip: product?.soldTo?.zip  ?? '',
//   }

//   var saveProduct = new Promise(async (resolve) => {
//       // if( product?.shipTo ){
//       //     await api.updateShipTo(orderId, product.id, shipToAddressObj);
//       // }
//       if( product?.soldTo ){
//           await api.updateSoldTo(orderId, product.id, soldToAddressObj);
//       }
//       resolve();
//   });

//   saveProduct.then( () => {
//       _callback();
//   });
// }

export async function newTaxAndFeesLineItem(order, product, _callback) {
  let productSaver = {
    id: product?.lineItemFee?.id,
    LineItemId: product?.lineItemId,
    Description: product?.lineItemFee?.description,
    tireTaxTotal:
      // product?.location?.state === "CA" || product?.location?.state === "AZ"
      (product?.lineItemFee?.enableTireTax === true || enableTireTax(order, product))
        ? removeChars(product?.lineItemFee?.tireTaxTotal)
        : 0,
    oosDeliveryFee: removeChars(product?.lineItemFee?.oosDeliveryFee),
    additionalTax: removeChars(product?.lineItemFee?.additionalTax),
    docFee: removeChars(product?.lineItemFee?.docFee),
    bankFee: removeChars(product?.lineItemFee?.bankFee),
    etlFee: removeChars(product?.lineItemFee?.etlFee),
    applyTitleFee: product?.lineItemFee?.applyTitleFee,
    applyRegistrationFee: product?.lineItemFee?.applyRegistrationFee,
    titleFee:
      product?.lineItemFee?.applyTitleFee === true ||
      product?.lineItemFee?.applyRegistrationFee
        ? removeChars(product?.lineItemFee?.titleFee)
        : 0,
    catTaxPercent:
      product.registrationAddress?.state === "OR"
        ? product?.lineItemFee?.catTaxPercent
        : 0,
    catTaxTotal:
      product.registrationAddress?.state === "OR"
        ? product?.lineItemFee?.catTaxTotal
        : 0,
    luxuryTaxPercent:
      product.registrationAddress?.state === "OR" &&
      product?.lineItemFee?.applyLuxuryTax === true
        ? product?.lineItemFee?.luxuryTaxPercent
        : 0,
    luxuryTaxTotal:
      product.registrationAddress?.state === "OR" &&
      product?.lineItemFee?.applyLuxuryTax === true
        ? product?.lineItemFee?.luxuryTaxTotal
        : 0,
    luxuryTaxOverrideToggle:
      product.registrationAddress?.state === "OR" &&
      product?.lineItemFee?.applyLuxuryTax === true
        ? product?.lineItemFee?.luxuryTaxOverrideToggle
        : 0,
    luxuryTaxOverrideReason:
      product.registrationAddress?.state === "OR" &&
      product?.lineItemFee?.applyLuxuryTax === true
        ? product?.lineItemFee?.luxuryTaxOverrideReason
        : null,
    tireCredit: product?.lineItemFee?.tireCredit,
    applyLuxuryTax: product?.lineItemFee?.applyLuxuryTax,
    applyFet: product?.lineItemFee?.applyFet,
    fetOverrideToggle:
      product?.type?.toString().toLowerCase() === "new" &&
      product?.lineItemFee?.applyFet === true
        ? product?.lineItemFee?.fetOverrideToggle
        : 0,
    fetOverrideReason:
      product?.type?.toString().toLowerCase() === "new" &&
      product?.lineItemFee?.applyFet === true &&
      product?.lineItemFee?.fetOverrideToggle === true
        ? product?.lineItemFee?.fetOverrideReason
        : null,
    fetTotal:
      product?.type?.toString().toLowerCase() === "new" &&
      product?.lineItemFee?.applyFet === true
        ? product?.lineItemFee?.fetTotal
        : 0,
    fetExemptReason:
      product?.type?.toString().toLowerCase() === "new" &&
      product?.lineItemFee?.applyFet === false
        ? product?.lineItemFee?.fetExemptReason
        : "",
        adminFee: "",
        totalPrice: "",
        enableTireTax: (product?.lineItemFee?.enableTireTax || enableTireTax(order, product)) ? true : false
      };

    var saveProduct = new Promise(async (resolve) => {
        await api.addLineItemFee(productSaver);
        resolve();
    }); 

    saveProduct.then( () => {
        _callback();
    });
}

export async function updateTaxAndFeesLineItem(e, order, product, _callback) {
  e.preventDefault();



  let productSaver = {
    id: product?.lineItemFee?.id,
    LineItemId: product?.lineItemId,
    Description: product?.lineItemFee?.description,
    tireTaxTotal:
      // product?.location?.state === "CA" || product?.location?.state === "AZ"
      (product?.lineItemFee?.enableTireTax === true || enableTireTax(order, product))
        ? removeChars(product?.lineItemFee?.tireTaxTotal)
        : 0,
    oosDeliveryFee: removeChars(product?.lineItemFee?.oosDeliveryFee),
    additionalTax: removeChars(product?.lineItemFee?.additionalTax),
    docFee: removeChars(product?.lineItemFee?.docFee),
    bankFee: removeChars(product?.lineItemFee?.bankFee),
    etlFee: removeChars(product?.lineItemFee?.etlFee),
    applyTitleFee: product?.lineItemFee?.applyTitleFee,
    applyRegistrationFee: product?.lineItemFee?.applyRegistrationFee,
    titleFee:
      product?.lineItemFee?.applyTitleFee === true ||
      product?.lineItemFee?.applyRegistrationFee
        ? removeChars(product?.lineItemFee?.titleFee)
        : 0,
    catTaxPercent:
      product.registrationAddress?.state === "OR"
        ? product?.lineItemFee?.catTaxPercent
        : 0,
    catTaxTotal:
      product.registrationAddress?.state === "OR"
        ? product?.lineItemFee?.catTaxTotal
        : 0,
    luxuryTaxPercent:
      product.registrationAddress?.state === "OR" &&
      product?.lineItemFee?.applyLuxuryTax === true
        ? product?.lineItemFee?.luxuryTaxPercent
        : 0,
    luxuryTaxTotal:
      product.registrationAddress?.state === "OR" &&
      product?.lineItemFee?.applyLuxuryTax === true
        ? product?.lineItemFee?.luxuryTaxTotal
        : 0,
    luxuryTaxOverrideToggle:
      product.registrationAddress?.state === "OR" &&
      product?.lineItemFee?.applyLuxuryTax === true
        ? product?.lineItemFee?.luxuryTaxOverrideToggle
        : 0,
    luxuryTaxOverrideReason:
      product.registrationAddress?.state === "OR" &&
      product?.lineItemFee?.applyLuxuryTax === true
        ? product?.lineItemFee?.luxuryTaxOverrideReason
        : null,
    tireCredit: product?.lineItemFee?.tireCredit,
    applyLuxuryTax: product?.lineItemFee?.applyLuxuryTax,
    applyFet: product?.lineItemFee?.applyFet,
    fetOverrideToggle:
      product?.type?.toString().toLowerCase() === "new" &&
      product?.lineItemFee?.applyFet === true
        ? product?.lineItemFee?.fetOverrideToggle
        : 0,
    fetOverrideReason:
      product?.type?.toString().toLowerCase() === "new" &&
      product?.lineItemFee?.applyFet === true &&
      product?.lineItemFee?.fetOverrideToggle === true
        ? product?.lineItemFee?.fetOverrideReason
        : null,
    fetTotal:
      product?.type?.toString().toLowerCase() === "new" &&
      product?.lineItemFee?.applyFet === true
        ? product?.lineItemFee?.fetTotal
        : 0,
    fetExemptReason:
      product?.type?.toString().toLowerCase() === "new" &&
      product?.lineItemFee?.applyFet === false
        ? product?.lineItemFee?.fetExemptReason
        : "",
        adminFee: product?.lineItemFee?.adminFee,
        totalPrice: product?.lineItemFee?.totalPrice,
        enableTireTax: (product?.lineItemFee?.enableTireTax || enableTireTax(order, product)) ? true : false
      };

    var saveProduct = new Promise(async (resolve) => {


        await api.editLineItemFee(product?.lineItemFee?.id, productSaver);
        resolve();
    });

    saveProduct.then( () => {
        _callback();
    });
}

export async function finalizeFinanceFundingDetails(order, product, _callback){
  var save = new Promise(async (resolve) => {
    await api.finalizeFunding(order.id, product.id)
    resolve();
  });

  save.then( () => {
    _callback();
  });
}

export async function unfinalizeFinanceFundingDetails(order, product, _callback){
  var save = new Promise(async (resolve) => {
    await api.unFinalizeFunding(order.id, product.id)
    resolve();
  });

  save.then( () => {
    _callback();
  });
}

export async function updateFinanceFundingDetails(e, order, product, _callback){
  let obj = {...product?.fundingDetail};

  let formatdirectDealAdminFee = 0;
  let formatdirectDealReserve = 0;
  let formatdirectDealInsuranceIncome = 0;
  if(product?.fundingDetail?.fundingMethod === "direct-deal"){
    formatdirectDealAdminFee = removeChars(obj['directDealAdminFee']);
    formatdirectDealReserve = removeChars(obj['directDealReserve']);
    formatdirectDealInsuranceIncome = removeChars(obj['directDealInsuranceIncome']);
  }

  obj.directDealAdminFee = formatdirectDealAdminFee;
  obj.directDealReserve = formatdirectDealReserve;
  obj.directDealInsuranceIncome = formatdirectDealInsuranceIncome;

  let fundingDetailObject = product?.fundingDetailObject;
  delete product.fundingDetailObject;


  var saveProduct = new Promise(async (resolve) => {
    await api.updateFundingDetails(order.id, product.id, product.fundingDetail);
    if (fundingDetailObject?.fundingMethod === "cash-with-lien") {
      await api.addProductLienHolder(
        order.id,
        product.id,
        product.fundingDetail.cashLienHolder,
      );
    }
    resolve();
  });

  saveProduct.then( () => {
    _callback();
});
}

/////

export function extractProperty(subObjectName, string){
  let eArray = string.split('.');
  if(eArray && eArray.length > 0){
    if(eArray[0] === subObjectName){
      return eArray[1];
    }
  }
  return "";
}

export async function saveProductSection(e, order, product, productSection, productChangeArray, updateFunction, _callback){
  e.preventDefault();
  console.log('saveProductSection',updateFunction)


  var save = new Promise(async (resolve) => {

    let updateProduct = JSON.parse(JSON.stringify(product));

    for (var prop in productChangeArray) {

      if(prop !== 'id' && prop !== 'stockNumber'){

        if (Object.prototype.hasOwnProperty.call(productChangeArray, prop)) {
          if(productSection === 'details'){

            // Shallow Details
            if(!prop.includes('extendedWarranty') && !prop.includes('gapInsurance') && !prop.includes('additions')  && !prop.includes('upgrades') && !prop.includes('deposits')){
              updateProduct[prop] = productChangeArray[prop].newValue;
            }
            
            // Extended Warranty
            if(prop.includes('extendedWarranty')){
              //Remove exisiting extended warranty
              if(prop.includes('extendedWarranty.enabled') && !productChangeArray[prop].newValue === 'no' && updateProduct?.extendedWarranty?.id){
                api.deleteExtendedWarranty(updateProduct.extendedWarranty.id);
              }
              // Extended Warranty Property Updates
              if(prop.includes('extendedWarranty') && !prop.includes('extendedWarranty.enabled')){
                if(!updateProduct?.extendedWarranty){
                  updateProduct.extendedWarranty = {};
                }
                updateProduct.extendedWarranty[extractProperty('extendedWarranty', prop)] = productChangeArray[prop].newValue;
              }
            }

            // Gap Insurance
            if(prop.includes('gapInsurance')){
              //Remove exisiting Gap Insurance
              if(prop.includes('gapInsurance.enabled') && !productChangeArray[prop].newValue === 'no' && updateProduct?.gapInsurance?.id){
                api.deleteGapInsurance(updateProduct.gapInsurance.id);
              }
              // Gap Insurance Property Updates
              if(prop.includes('gapInsurance') && !prop.includes('gapInsurance.enabled')){
                if(!updateProduct?.gapInsurance){
                  updateProduct.gapInsurance = {};
                }
                updateProduct.gapInsurance[extractProperty('gapInsurance', prop)] = productChangeArray[prop].newValue;
              }
            }

          }else if(productSection === 'fob'){
            updateProduct.fobAddress[extractProperty('fobAddress', prop)] = productChangeArray[prop].newValue;
          }else if(productSection === 'ship-sold'){

            if(!prop.includes("enabled")){
              let propArray = prop.split('.');
              let object = propArray[0];
              let property = propArray[1];
              
              if(!updateProduct[object]){
                updateProduct[object] = {};
              }
              
              if(!updateProduct[object][property]){
                updateProduct[object][property] = "";
              }

              
              console.log('saveProductSection, ship-sold', productChangeArray, updateProduct, object, property, prop, productChangeArray[prop])
              console.log('saveProductSection, ship-sold2', updateProduct[object], updateProduct[object][property] !== null, productChangeArray[prop]?.newValue);
              
              if(updateProduct[object] && updateProduct[object][property] !== null && productChangeArray[prop]?.newValue){
                console.log('saveProductSection, ship-sold, passed')
                updateProduct[object][property] = productChangeArray[prop].newValue;
                // if(!updateProduct[object]?.id){
                //   console.log('saveProductSection, ship-sold, newProductShipTo')
                //   updateProductShipSold(order.id, updateProduct, () => {})
                // }
              }
            }

            if(prop.includes('shipTo.enabled') && !productChangeArray[prop].newValue === 'no' && updateProduct?.shipTo?.id){
              api.deleteShipTo(order.id, updateProduct.id);
            }

            if(prop.includes('soldTo.enabled') && !productChangeArray[prop].newValue === 'no' && updateProduct?.soldTo?.id){
              api.deleteSoldTo(order.id, updateProduct.id);
            }

          }else if(productSection === 'tax-and-fee'){
          }else if(productSection === 'finance'){
          }
        }
      }
    }

    updateFunction(e, order, updateProduct, () => {
      resolve();
    });
  });

  save.then( () => {
    _callback();
  });
}

export async function updateProductChangeArray(orderId, product, _callback){}

export function getOtherProductsInGroup(order, productId, productGroupId){
    let otherProductsInGroup = [];

    // Find other products in group
    if(order && productId && productGroupId){
        order?.products?.forEach( fproduct => {
            if( fproduct?.id !== productId ){
                if( fproduct?.productGroupId === productGroupId){
                    otherProductsInGroup.push(fproduct);
                }
            }
        })
    }
    
    return otherProductsInGroup;
}

export function filterDocumentsGeneral(documents){

  if(documents && documents.length > 0){

    let filteredDocuments = [];

    documents.forEach(document => {
      if(document && (document?.documentStatus?.toLowerCase() === 'generated' || document?.documentStatus?.toLowerCase() === 'prepared' || document?.documentStatusId === 3 || document?.documentStatusId === 4)){
        filteredDocuments.push(document);
      }
    });

    return filteredDocuments;

  }

  return [];
}

export function filterProductDocumentsForOrder(productId, documents){
  if(documents && documents.length > 0){
    let filteredDocuments = [];

    documents.forEach(document => {

      if(document && document?.assignedObjectType && (document?.assignedObjectType === 'order' || document?.assignedObjectType?.includes('special') || document?.assignedObjectType === null) && (document?.documentStatus?.toLowerCase() === 'generated' || document?.documentStatus?.toLowerCase() === 'prepared' || document?.documentStatusId === 3 || document?.documentStatusId === 4)){
        let itemIdsArray = document?.itemIds !== "" && document?.itemIds?.includes(",") ? document.itemIds.split(",") : document?.itemIds !== "" && Number(document?.itemIds) > 0 ? [Number(document?.itemIds)] : [];

        if(itemIdsArray.length > 0){
          itemIdsArray.forEach(itemId => {
            if(Number(itemId) === Number(productId)){
              filteredDocuments.push(document);
            }
          })
        }
      }
    });
    return filteredDocuments;
  }
  return [];
}

export function filterDocumentsForProduct(productId, documents){
  if(documents && documents.length > 0){

    let filteredDocuments = [];


    documents.forEach(document => {
      if(document && document.assignedObjectId && document.assignedObjectId === productId && document.assignedObjectType && document.assignedObjectType === 'product'){
        if(document?.documentStatus?.toLowerCase() === 'generated' || document?.documentStatus?.toLowerCase() === 'prepared' || document?.documentStatusId === 3 || document?.documentStatusId === 4){
          filteredDocuments.push(document);
        }
      }
    });

    return filteredDocuments;

  }
  return [];
}

export function filterDocumentsForTrade(tradeId, documents){
  if(documents && documents.length > 0){

    let filteredDocuments = [];

    documents.forEach(document => {
      if(document && document.assignedObjectId && document.assignedObjectId === tradeId && document.assignedObjectType && document.assignedObjectType === 'tradein' && (document?.documentStatus?.toLowerCase() === 'generated' || document?.documentStatus?.toLowerCase() === 'prepared' || document?.documentStatusId === 3 || document?.documentStatusId === 4)){
        filteredDocuments.push(document);
      }
    });

    return filteredDocuments;

  }
  return [];
}

export function matchAndGroupProducts(order, _callback){
  let groups = matchAndGroup(order.products);

  // Clear all order product group connections with products.
  api.clearProductGroups(order.id).then(() => {

    var createProductGroups = new Promise((resolve, reject) => {
      groups.forEach((group, index, array) => {
        if(group?.products.length > 1){
          createProductGroup(order, group);
        }
        if (index === array.length -1) resolve();
      })
    });

    createProductGroups.then( () => {
      // Clear all order empty order product groups.
      api.clearExtraProductGroups(order.id);  
    });
  });

  if(_callback){
    _callback();
  }
}

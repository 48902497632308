import {createSlice} from '@reduxjs/toolkit'


export const productSlice= createSlice({
   name:'product',
   initialState:{
      id: null,
      additions:[],
      deposits:[],
      upgrades:[],
      fobAddress:{},
      registrationAddress:{
         addressLine1:'',
         addressLine2:'',
         city:'',
         state:'',
         zip:'',
         county:'',
      },
      lineItemFee: {},
      fundingDetail:{
         cashLienHolder: {
            bankName: "",
            addressLine1:"",
            addressLine2:"",
            city:"",
            state:"AL",
            zip:"",
         }
      },
      createdAt:'',
      department:null,
      departmentId:null,
      stockNumber:null,
      checkFailed:false
   }, 
   reducers:{
      setProduct: (state, action)=>{
         let productObject = null;
            if(action.payload.name==='new'){
               productObject = productSlice.getInitialState();
            } else {
               productObject = action.payload;
            }


            return productObject;
        },
        editProduct:(state,action)=>{
            const {prop, value} = action.payload
            state[prop]=value

        },
        editWarranty: (state, action)=>{
            const {prop, value, obj} = action?.payload
            if(obj!==undefined) state.extendedWarranty=obj
            else state.extendedWarranty[prop] = value
        },
        editGap: (state, action)=>{
         const {prop, value, obj} = action?.payload
         if(obj!==undefined) state.gapInsurance=obj
         else state.gapInsurance[prop] = value
        },
         addProductAddition: (state, action)=>{
            let {additionObject}= action.payload            
            
            if(state?.additions && state.additions.length > 0){
               let currentAdditionsArray = state.additions;
               currentAdditionsArray.push(additionObject);
               state.additions = currentAdditionsArray;
            }else{
               state.additions = [additionObject];
            }
         },
         editProductAddition: (state , action)=>{
            const {index, prop, value} = action.payload;
            let foundAddition = state.additions[index];
            if(foundAddition){
               foundAddition[prop] = value;
               state.additions[index] = foundAddition;
            }
         },
         deleteProductAddition: (state, action)=>{
            state.additions=action.payload
         },
         addProductUpgrade: (state, action)=>{

            let {upgradeObject}= action.payload
            
            if(state?.upgrades && state.upgrades.length > 0){
               let currentUpgradesArray = state.upgrades;
               currentUpgradesArray.push(upgradeObject);
               state.upgrades = currentUpgradesArray;
            }else{
               state.upgrades = [upgradeObject];
            }

         },
         editProductUpgrade: (state , action)=>{
            const {index, prop, value} = action.payload
            let foundUpgrade = state.upgrades[index]
            if(foundUpgrade)foundUpgrade[prop]=value
            if(foundUpgrade){
               foundUpgrade[prop] = value;
               state.upgrades[index] = foundUpgrade;
            }
         },
         deleteProductUpgrade: (state, action)=>{
            state.upgrades=action.payload
         },
         addProductDeposit: (state, action)=>{
            let {depositObject}= action.payload

            if(state?.deposits && state.deposits.length > 0){
               let currentDepositArray = state.deposits;
               currentDepositArray.push(depositObject);
               state.deposits = currentDepositArray;
            }else{
               state.deposits = [depositObject];
            }

         },
         editProductDeposit: (state , action)=>{
            const {index, prop, value} = action.payload
            let foundDeposit = state.deposits[index]
            if(foundDeposit){
               foundDeposit[prop] = value;
               state.deposits[index] = foundDeposit;
            }
         },
         deleteProductDeposit:(state, action) =>{
            state.deposits=action.payload
         },
         editProductFob:(state, action) => {
            let {value,prop, id} = action.payload
            if(!id) return
            console.log('editProductFob', value,prop,  state.fobAddress)
            if (id===state.fobAddress.id)state.fobAddress[prop] = value
         },
         editProductRegistrationAddress:(state, action) => {
            let {value,prop} = action.payload
            console.log('editProductRegistrationAddress', value,prop,  state.registrationAddress)
            state.registrationAddress[prop] = value
         },
         editProductShipTo:(state, action) => {
            const shipToInit={
               name:'',
               addressLine1:'',
               addressLine2:'',
               city:'',
               state:'',
               zip:'',
            }
            let {value,prop} = action?.payload
            if(prop==='init') state.shipTo=shipToInit
            else if(prop==='clear') state.shipTo=null
            else if(prop==='reset') state.shipTo=value
            else state.shipTo[prop] = value
         },
         editProductSoldTo:(state, action) => {
            const soldToInit={
               name:'',
               addressLine1:'',
               addressLine2:'',
               city:'',
               state:'',
               zip:'',
            }
            
            let {value,prop} = action.payload

            // if(!state.soldTo){
            //    state.soldTo = {};
            // }

            if(prop==='init') state.soldTo=soldToInit
            else if(prop==='clear') state.soldTo=null
            else if(prop==='reset') state.soldTo=value
            else state.soldTo[prop] = value
         },
         editTaxesAndFees:(state, action)=>{
            const {prop, value} = action.payload
            if(!state.lineItemFee){
               state.lineItemFee = {};
            }
            state.lineItemFee[prop]=value
         },
         editFundingDetail:(state, action)=>{
            const {prop, value} = action.payload;
            if(!state.fundingDetail){
               state.fundingDetail = {};
            }
            state.fundingDetail[prop] = value;
         },
         editCashLienHolder:(state, action)=>{
            const {prop, value} = action.payload
            if(state?.fundingDetail?.cashLienHolder){
               state.fundingDetail.cashLienHolder[prop]=value;
            }else{
               let newlienHolder = {
                  bankName: "",
                  addressLine1:"",
                  addressLine2:"",
                  city:"",
                  state:"AL",
                  zip:"",
               }
               newlienHolder[prop]=value;
               state.fundingDetail.cashLienHolder = newlienHolder;
            }
         }
    }
})

export const {
   setProduct, 
   editProduct,
   editWarranty,
   editGap,
   addProductAddition,
   editProductAddition,
   deleteProductAddition,
   editProductUpgrade,
   addProductUpgrade,
   deleteProductUpgrade,
   addProductDeposit,
   editProductDeposit,
   deleteProductDeposit,
   editProductFob,
   editProductShipTo, 
   editProductSoldTo, 
   editTaxesAndFees, 
   editFundingDetail, 
   editCashLienHolder,
   editProductRegistrationAddress
}= productSlice.actions

export default productSlice.reducer
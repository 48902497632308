import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase } from "../../../../../../../utlis/formatters";

export const WAVehicleTitleApplicationAdditionalGlobalAutofill = (documentValues, order, property=null, value=null) => {
    var newValues = documentValues;
    if(property ==='customer.email'){
      newValues.email_address = value;
  }
    
    if(property ==='customer.name'){
      newValues.owner_name_biz_address = value;
      if(order.customer.dba){
        newValues.owner_name_biz_address += " dba "+order.customer.dba;
      }
  }
    if(property ==='customer.dba'){
        newValues.owner_name_biz_address = order.customer.name + " dba "+value;
  }
    if(property ==='customer.phone'){
      newValues.phone_num_s1 = formatPhoneNumber(value);
    }
    if(property ==='customer.cosigner'){
      if(value && value.length > 0){
        let cosigner = value[0];
        if(cosigner){
          newValues.owner_type_s2 = "Individual"
          newValues.phone_num_s2 = formatPhoneNumber(cosigner.phone);
          newValues.owner_name_biz_name_s2 = cosigner.name;
        }
      }
  }
  if(property ==='customer.deliveryAddress.addressLine1'){
    let addressLine = value
    if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
      addressLine +=" "+order.customer.deliveryAddress.addressLine2;
  }
  newValues.wa_primary_res_address = addressLine+" "+(order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
}
  if(property ==='customer.deliveryAddress.addressLine2'){
      let addressLine = order.customer.deliveryAddress.addressLine1+ " "+value;
  newValues.wa_primary_res_address = addressLine+" "+(order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
}
  if(property ==='customer.deliveryAddress.city'){
    let addressLine = order.customer.deliveryAddress.addressLine1
    if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
      addressLine +=" "+order.customer.deliveryAddress.addressLine2;
  }
  newValues.wa_primary_res_address = addressLine+" "+(value+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
}
  if(property ==='customer.deliveryAddress.state'){
    let addressLine = order.customer.deliveryAddress.addressLine1
    if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
      addressLine +=" "+order.customer.deliveryAddress.addressLine2;
  }
  newValues.wa_primary_res_address = addressLine+" "+(order.customer.deliveryAddress.city+", "+value+" "+order.customer.deliveryAddress.zip);
}
  if(property ==='customer.deliveryAddress.zip'){
    let addressLine = order.customer.deliveryAddress.addressLine1
    if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
      addressLine +=" "+order.customer.deliveryAddress.addressLine2;
  }
  newValues.wa_primary_res_address = addressLine+" "+(order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+value);
}
  if(property ==='documentationDate'){
    newValues.delivery_date = moment.utc(value).format("YYYY-MM-DD");
}

    if(property !== null && value !== null){
      return newValues;
  }

    if(order && order.customer && order.customer.email){
        newValues.email_address = order.customer.email;
    }
  
    if(order && order.customer){
      if(order.customer.type === "entity"){
        newValues.owner_type = "Business";
      }else{
        newValues.owner_type = "Individual";
      }
      newValues.owner_name_biz_address = order.customer.name;
      newValues.phone_num_s1 = formatPhoneNumber(order.customer.phone);
  
      if(order.customer.dba){
        newValues.owner_name_biz_address = order.customer.name+" dba "+order.customer.dba;
      }
      if(order.customer.cosigners && order.customer.cosigners.length > 0){
        let cosigner = order.customer.cosigners[0];
        if(cosigner){
          newValues.owner_type_s2 = "Individual"
          newValues.phone_num_s2 = formatPhoneNumber(cosigner.phone);
          newValues.owner_name_biz_name_s2 = cosigner.name;
        }
      }
    }
    
    if(order && order.customer && order.customer.deliveryAddress){
      var addressLine = '';
      if(order.customer.deliveryAddress.addressLine2 != null && order.customer.deliveryAddress.addressLine2 !== undefined && order.customer.deliveryAddress.addressLine2 !== ''){
        addressLine = (order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.addressLine2);
      }else{
        addressLine = order.customer.deliveryAddress.addressLine1;
      }
      newValues.wa_primary_res_address = addressLine+" "+(order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip);
    }
  
    if(order && order.documentationDate && documentValues.delivery_date === undefined){
      newValues.delivery_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }
  
    return newValues
}

export const WAVehicleTitleApplicationAdditionalAutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
    var newValues = documentValues;


    if(property === 'vin' && value !== null){
      newValues.vin = (value).toString().trim();
    }

    if(property !== null && value !== null){
        return newValues;
    }

    newValues.vin = (selectedProduct.vin).toString().toUpperCase();

    return newValues
}

export const preProcessPayload=(payload, checkboxes,moneyFields,phoneFields,upperCaseFields)=>{

  if(payload.elt_part_leg2_toggle){
    // Reset all
    payload.elt2_yes_cb = "no";
    payload.elt2_no_cb = "no";

    switch(payload.elt_part_leg2_toggle){
      case "yes":
        payload.elt2_yes_cb = "yes";
        break;
      case "no":
        payload.elt2_no_cb = "yes";
        break;
      default:
    }
  }

  if(payload.elt_part_leg3_toggle){
    // Reset all
    payload.elt3_yes_cb = "no";
    payload.elt3_no_cb = "no";

    switch(payload.elt_part_leg3_toggle){
      case "yes":
        payload.elt3_yes_cb = "yes";
        break;
      case "no":
        payload.elt3_no_cb = "yes";
        break;
      default:
    }
  }


  // X1.2- Field Type Specific Preview Formatting
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  // Data point formatting

  if(payload.exp3){
    payload.exp3 = moment.utc(payload.exp3).format("MM/DD/YY");
  }

  if(payload.exp4){
    payload.exp4 = moment.utc(payload.exp4).format("MM/DD/YY");
  }

  if(payload.exp5){
    payload.exp5 = moment.utc(payload.exp5).format("MM/DD/YY");
  }

  if(payload.exp6){
    payload.exp6 = moment.utc(payload.exp6).format("MM/DD/YY");
  }

  if(payload.exp7){
    payload.exp7 = moment.utc(payload.exp7).format("MM/DD/YY");
  }

  if(payload.legExp2){
    payload.legExp2 = moment.utc(payload.legExp2).format("MM/DD/YY");
  }

  if(payload.legExp3){
    payload.legExp3 = moment.utc(payload.legExp3).format("MM/DD/YY");
  }

  if(payload.delivery_date){
    payload.delivery_date = moment.utc(payload.delivery_date).format("MM/DD/YY");
  }

  return payload;
}
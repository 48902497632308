import { graphConfig } from "./authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function getMyGroups(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer)
    headers.append("Content-Type", "application/json")
    const data = {
        securityEnabledOnly: false
    }

     const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data) 
    };




// "value":["71ef2e41-bd50-4cb2-80b9-f83b5daaf78b","43308de0-7d1c-4314-b218-425fb8530e5e"]



// return fetch(graphConfig.groupName+"71ef2e41-bd50-4cb2-80b9-f83b5daaf78b", options)
return fetch(graphConfig.getMemberGroups, options)
.then(response => response.json())
        .catch(error => console.log(error));

}

import moment from 'moment'
import { formatPhoneNumber, formatMoneyValue, formatUpperCase} from "../../../../../../../utlis/formatters";
import { salesPriceWithUpgradeCalc } from '../../../../../../../utlis/productCalculations.js';
import { formatter } from './docUtils.js';

export const AZ5012GlobalAutofill = (documentValues, order, property=null, value=null) => {

    var newValues = documentValues;
    
    if(property === 'customer.name'){
      newValues.printed = value;
      if(order.customer.type.toLowerCase() === "entity"){
        newValues.entity_name = value;
      }else{
        newValues.purchaser_name = value;
      }
      if(order.customer.dba){
        newValues.purchaser_name += " dba "+order.customer.dba;
      }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner && cosigner.name){
              newValues.purchaser_name += " AND "+cosigner.name;
          }
      }
  }
    if(property === 'customer.dba'){
      newValues.printed = order.customer.name;
      if(order.customer.type.toLowerCase() === "entity"){
        newValues.entity_name = order.customer.name;
      }else{
        newValues.purchaser_name = order.customer.name;
      }
        newValues.purchaser_name += " dba "+value;
      if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner && cosigner.name){
              newValues.purchaser_name += " AND "+cosigner.name;
          }
      }
  }
    if(property === 'customer.cosigner[0].name'){
      newValues.printed = order.customer.name;
      if(order.customer.type.toLowerCase() === "entity"){
        newValues.entity_name = order.customer.name;
      }else{
        newValues.purchaser_name = order.customer.name;
      }
      if(value !==''){
              newValues.purchaser_name += " AND "+value;
          }
      
  }
    if(property === 'customer.deliveryAddress.addressLine1'){
      if(order.customer.type.toLowerCase() === "entity"){
        newValues.full_business_address = value+" "+order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
      }
  }
    if(property === 'customer.deliveryAddress.city'){
      if(order.customer.type.toLowerCase() === "entity"){
        newValues.full_business_address = order.customer.deliveryAddress.addressLine1+" "+value+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
      }
  }
    if(property === 'customer.deliveryAddress.state'){
      if(order.customer.type.toLowerCase() === "entity"){
        newValues.full_business_address = order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.city+", "+value+" "+order.customer.deliveryAddress.zip;
      }
  }
    if(property === 'customer.deliveryAddress.zip'){
      if(order.customer.type.toLowerCase() === "entity"){
        newValues.full_business_address = order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+value;
      }
  }
    if(property === 'customer.phone'){
      if(order.customer.type.toLowerCase() !== "entity"){
        newValues.purchaser_res_phone = formatPhoneNumber(value)
      }
  }
  if(property==='customer.type'){
    if(value==='entity'){
      newValues.entity_name = order.customer.name;
        newValues.full_business_address = order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
      newValues.purchaser_res_phone = '';
    } else if(value==='individual'){
      newValues.purchaser_name = order.customer.name;
      newValues.purchaser_res_phone = formatPhoneNumber(order.customer.phone);
    }
  }

  if(property !== null && value !== null){
    return newValues;
}


    if(order && order.customer && order.customer.name){
        newValues.printed = order.customer.name;
    }
  
    if(order && order.customer){
      if(order.customer.type.toLowerCase() === "entity"){
        newValues.entity_name = order.customer.name;
        newValues.full_business_address = order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
      }else{
        newValues.purchaser_name = order.customer.name;
        // newValues.purchaser_address = order.customer.deliveryAddress.addressLine1+" "+order.customer.deliveryAddress.city+", "+order.customer.deliveryAddress.state+" "+order.customer.deliveryAddress.zip;
        newValues.purchaser_res_phone = formatPhoneNumber(order.customer.phone);
      }
  
      if(order.customer.dba){
        newValues.purchaser_name += " dba "+order.customer.dba;
      }else if(order.customer.cosigners && order.customer.cosigners.length > 0){
          let cosigner = order.customer.cosigners[0];
          if(cosigner && cosigner.name){
              newValues.purchaser_name += " AND "+cosigner.name;
          }
      }
    
    }
  
    if(order && order.documentationDate && documentValues.delivery_date === undefined){
      newValues.delivery_date = moment.utc(order.documentationDate).format("YYYY-MM-DD");
    }
  
    newValues.q4_toggle = "false";
    newValues.q5_toggle = "false";
    newValues.q6_toggle = "false";
    newValues.q7_toggle = "false";
    newValues.q8_toggle = "false";
    newValues.q9_toggle = "false";
    newValues.q10_toggle = "false";
    newValues.q11_toggle = "false";
    newValues.q12_toggle = "false";
    newValues.q13_toggle = "false";
  
  
    return newValues
}

export const AZ5012AutofillProductDetails = (selectedProduct, documentValues, property=null, value=null) => {
  var newValues = documentValues;

  if(property === 'year' && value !== null){
    newValues.year = (value).toString().trim().replace(/['"]+/g, '');
}

if(property === 'make' && value !== null){
    newValues.make = (value).toString().trim();
}

if(property === 'model' && value !== null){
    newValues.model = (value).toString().toUpperCase();
}

if(property === 'vin' && value !== null){
  newValues.vin = (value).toString().toUpperCase();
}

if(property === 'price' && value !== null){
    newValues.sales_price =  formatter.format((salesPriceWithUpgradeCalc(selectedProduct)) + Number((selectedProduct?.lineItemFee?.fetTotal ?? 0)));
}

if(property === 'lineItemFee.fetTotal' && value !== null){
  newValues.sales_price += formatter.format(salesPriceWithUpgradeCalc(selectedProduct) + Number(value))
}

if(property !== null && value !== null){
    return newValues;
}
  
  newValues.vin = (selectedProduct.vin).toString().toUpperCase();
  newValues.year = (selectedProduct.year).trim();
  newValues.make = (selectedProduct.make).trim();
  newValues.model = (selectedProduct.model).toString().toUpperCase();

  newValues.sales_price = salesPriceWithUpgradeCalc(selectedProduct);
  if(selectedProduct.lineItemFee && selectedProduct.lineItemFee.applyFet && selectedProduct.lineItemFee.applyFet === true && selectedProduct.lineItemFee.fetTotal && selectedProduct.lineItemFee.fetTotal > 0){
    newValues.sales_price += Number(selectedProduct.lineItemFee.fetTotal);
  }

  return newValues
}

export const preProcessPayload = (payload, checkboxes, moneyFields, phoneFields, upperCaseFields) => {


  if(payload.q1_toggle){
    // Reset all
    payload.q_1_true_cb = "no";
    payload.q_1_false_cb = "no";

    switch(payload.q1_toggle){
      case "true":
        payload.q_1_true_cb = "yes";
        break;
      case "false":
        payload.q_1_false_cb = "yes";
        break;
      default:
    }
  }

  if(payload.q2_toggle){
    // Reset all
    payload.q_2_true_cb = "no";
    payload.q_2_false_cb = "no";

    switch(payload.q2_toggle){
      case "true":
        payload.q_2_true_cb = "yes";
        break;
      case "false":
        payload.q_2_false_cb = "yes";
        break;
      default:
    }
  }

  if(payload.q3_toggle){
    // Reset all
    payload.q_3_true_cb = "no";
    payload.q_3_false_cb = "no";

    switch(payload.q3_toggle){
      case "true":
        payload.q_3_true_cb = "yes";
        break;
      case "false":
        payload.q_3_false_cb = "yes";
        break;
      default:
    }
  }

  if(payload.q4_toggle){
    // Reset all
    payload.q_4_true_cb = "no";
    payload.q_4_false_cb = "no";

    switch(payload.q4_toggle){
      case "true":
        payload.q_4_true_cb = "yes";
        break;
      case "false":
        payload.q_4_false_cb = "yes";
        break;
      default:
    }
  }

  if(payload.q5_toggle){
    // Reset all
    payload.q_5_true_cb = "no";
    payload.q_5_false_cb = "no";

    switch(payload.q5_toggle){
      case "true":
        payload.q_5_true_cb = "yes";
        break;
      case "false":
        payload.q_5_false_cb = "yes";
        break;
      default:
    }
  }

  if(payload.q6_toggle){
    // Reset all
    payload.q_6_true_cb = "no";
    payload.q_6_false_cb = "no";

    switch(payload.q6_toggle){
      case "true":
        payload.q_6_true_cb = "yes";
        break;
      case "false":
        payload.q_6_false_cb = "yes";
        break;
      default:
    }
  }

  if(payload.q7_toggle){
    // Reset all
    payload.q_7_true_cb = "no";
    payload.q_7_false_cb = "no";

    switch(payload.q7_toggle){
      case "true":
        payload.q_7_true_cb = "yes";
        break;
      case "false":
        payload.q_7_false_cb = "yes";
        break;
      default:
    }
  }

  if(payload.q8_toggle){
    // Reset all
    payload.q_8_true_cb = "no";
    payload.q_8_false_cb = "no";

    switch(payload.q8_toggle){
      case "true":
        payload.q_8_true_cb = "yes";
        break;
      case "false":
        payload.q_8_false_cb = "yes";
        break;
      default:
    }
  }

  if(payload.q9_toggle){
    // Reset all
    payload.q_9_true_cb = "no";
    payload.q_9_false_cb = "no";

    switch(payload.q9_toggle){
      case "true":
        payload.q_9_true_cb = "yes";
        break;
      case "false":
        payload.q_9_false_cb = "yes";
        break;
      default:
    }
  }

  if(payload.q10_toggle){
    // Reset all
    payload.q_10_true_cb = "no";
    payload.q_10_false_cb = "no";

    switch(payload.q10_toggle){
      case "true":
        payload.q_10_true_cb = "yes";
        break;
      case "false":
        payload.q_10_false_cb = "yes";
        break;
      default:
    }
  }

  if(payload.q11_toggle){
    // Reset all
    payload.q_11_true_cb = "no";
    payload.q_11_false_cb = "no";

    switch(payload.q11_toggle){
      case "true":
        payload.q_11_true_cb = "yes";
        break;
      case "false":
        payload.q_11_false_cb = "yes";
        break;
      default:
    }
  }

  if(payload.q12_toggle){
    // Reset all
    payload.q_12_true_cb = "no";
    payload.q_12_false_cb = "no";

    switch(payload.q12_toggle){
      case "true":
        payload.q_12_true_cb = "yes";
        break;
      case "false":
        payload.q_12_false_cb = "yes";
        break;
      default:
    }
  }

  if(payload.q13_toggle){
    // Reset all
    payload.q_13_true_cb = "no";
    payload.q_13_false_cb = "no";

    switch(payload.q13_toggle){
      case "true":
        payload.q_13_true_cb = "yes";
        break;
      case "false":
        payload.q_13_false_cb = "yes";
        break;
      default:
    }
  }


  // X1.2- Field Type Specific Preview Formatting
  Object.keys(payload).forEach(key => {
    if(moneyFields.indexOf(key) >= 0){
        payload[key] = formatMoneyValue(payload[key]);
    }else if(phoneFields.indexOf(key) >= 0){
        payload[key] = formatPhoneNumber(payload[key]);
    }else if(upperCaseFields.indexOf(key) >= 0){
        payload[key] = formatUpperCase(payload[key]);
    }
  });
  Object.keys(payload).forEach(key => {
    if(checkboxes.indexOf(key) >= 0){
      payload[key] = payload[key] ? "yes": "no";
    }
  });

  // Data point formatting
  if(payload.expires_date){
    payload.expires_date = moment.utc(payload.expires_date).format("MM/DD/YY");
  }

  if(payload.expires_date_2){
    payload.expires_date_2 = moment.utc(payload.expires_date_2).format("MM/DD/YY");
  }

  if(payload.delivery_date){
    payload.delivery_date = moment.utc(payload.delivery_date).format("MM/DD/YY");
  }

  return payload;
}
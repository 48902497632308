import {createSlice} from '@reduxjs/toolkit'

export const spinnerSlice= createSlice({
    name:'spinners',
    initialState:{
    isLoading:false,
    showSpinner:false,
    showDownPaymentSpinner:false,
    showFinanceSpinner:false,
    showTradeInSpinner:false,
    showInvoiceSpinner:false,
    showDashboardSpinner:false
    }, reducers:{
        setIsLoading: (state, action)=>{
            state.isLoading=action.payload
        },
        setShowSpinner:(state,action)=>{
            state.showSpinner=action.payload
        },
        setShowFinanceSpinner:(state,action)=>{
            state.showFinanceSpinner=action.payload
        },
        setShowDownPaymentSpinner:(state,action)=>{
            state.showDownPaymentSpinner=action.payload
        },
        setShowTradeInSpinner:(state,action)=>{
            state.showTradeInSpinner=action.payload
        },
        setShowDashboardSpinner:(state,action)=>{
            state.showDashboardSpinner=action.payload
        },
        setShowInvoiceSpinner:(state,action)=>{
            state.showInvoiceSpinner=action.payload
        },
        
    }
})

export const {setIsLoading, setShowSpinner,setShowFinanceSpinner,setShowTradeInSpinner, setShowInvoiceSpinner, setShowDashboardSpinner, setShowDownPaymentSpinner}= spinnerSlice.actions

export default spinnerSlice.reducer